import { call, put, select, takeEvery } from 'redux-saga/effects';
import { getFormValues } from 'redux-form';

import { addNotification } from 'domain/env/EnvActions';
import { parseServerError } from 'lib/helpers';
import { FORM_CARD } from 'domain/const';
import Api, { makeApiGenerator } from 'domain/api';
import { setStorageItem } from 'lib/storage';

import { onFieldErrorAction, setStripeCardTokenInvalidAction } from 'pages/billingPage/form/cardFormActions';
import { buyAnalyticsFormSubmitAction, buyAnalyticsReportAction } from 'pages/common/buyAnalytics/buyAnalyticsActions';
import { updateCardAction } from 'pages/common/buyRtv/buyRtvActions';
import { cardFormOpened_sel, cardHolderNameSel, cardNumberSelector } from 'domain/env';
import { fieldNames, fieldsSel } from 'pages/billingPage/form/cardFormModel';
import { push } from 'connected-react-router';
import { lnk } from 'lib/routes';

const buyArtistAnalyticsReport_apiGen = makeApiGenerator({
  actionCreator: buyAnalyticsReportAction,
  api: Api.buyArtistAnalyticsReport,
  getSuccessData: ({ data, headers, requestData }) => ({
    payload: { ...data },
    requestData: requestData.data,
    headers,
  }),
  returnValue: function returnValue({ data, headers }) {
    return { data, headers };
  },
});

export function* buyAnalyticsReport({ stripeCardToken, billingData, artistId, planType, discount, saveCard = false }) {
  const { cardHolderName } = (yield select(getFormValues(FORM_CARD))) || {};
  const cardNumber = yield select(cardNumberSelector);
  const cardFormOpened = yield select(cardFormOpened_sel);
  if (cardFormOpened ? !stripeCardToken : !cardNumber) {
    const fields = yield select(fieldsSel);
    for (let name of fieldNames) {
      let field = fields.get(name);
      if (field.complete) continue;
      yield put(onFieldErrorAction({ name, error: { message: `Please enter a valid ${field.label}` } }));
    };
    yield put(addNotification({ title: 'Please enter a valid credit card', type: 'error' }));
    return;
  }
  if (!cardHolderName && !(yield select(cardHolderNameSel))) {
    yield put(addNotification({ title: 'Please enter cardholder name', type: 'error' }));
    return;
  }
  const requestData = {
    cardHolderName: cardHolderName || undefined,
    stripeToken: stripeCardToken || undefined,
    discount: discount || undefined,
    country: billingData.country,
    code: billingData.code,
    email: billingData.email,
    subscriptionPlan: planType,
    saveCard,
    artistId: artistId,
  };
  try {
    const { data, headers } = yield call(buyArtistAnalyticsReport_apiGen, { data: requestData });
    if (headers['x-token']) {
      setStorageItem('token', headers['x-token']);
    }
    if (data) {
      yield put(addNotification({ title: 'Thank you for subscribing to ARTBnk!' }));
      if (saveCard && data.card) {
        yield put(updateCardAction(data.card));
      }
    }
  } catch (err) {
    console.log({ err }, 'err');
    if (stripeCardToken) {
      yield put(setStripeCardTokenInvalidAction(true));
    }
    const { message } = parseServerError(err);
    if (message === 'Upgrading from non Member subscription is not supported') {
      yield put(push(lnk('accountPage')));
    }
    yield put(addNotification({ title: message, type: 'error' }));
  }
}

function* onBuyAnalyticsFormSubmit({ payload: { artistId, ...payload } }) {
  yield call(buyAnalyticsReport, {
    stripeCardToken: payload.stripeToken,
    billingData: {
      country: payload.country,
      code: payload.code,
      email: payload.email,
    },
    artistId: artistId,
    saveCard: payload.saveCard,
  });
}

export function* analyticsFormInit() {
  yield takeEvery(buyAnalyticsFormSubmitAction.type, onBuyAnalyticsFormSubmit);
}
