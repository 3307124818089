import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { compose } from 'redux';
import { Field } from 'redux-form';
import { addPopupAction } from 'domain/ui/UIActions';
import { artistEditable_sel } from 'pages/common/artworkForm/ArtworkFormModel';
import FormRowField from 'pages/common/newPage/form/elements/FormRowField';
import { connect } from 'cpcs-reconnect';
import { CREATE_AUTHOR_POPUP, EDIT_AUTHOR_POPUP } from 'domain/const';
import { removeArtistAction } from 'domain/artist';
import { AsyncMultiAutocomplete } from 'components/form/select';
import { setPropTypes } from 'lib/helpers';
import rules from 'components/form/validation';

import injectSheet from 'lib/sheet';
import sheet from './sheet';

const Label = compose(
  injectSheet(sheet),
  setPropTypes({
    classes: PropTypes.shape({
      sLabel: PropTypes.string,
      rtv: PropTypes.string,
    }),
    data: PropTypes.shape({
      rtv: PropTypes.bool,
      label: PropTypes.string,
    }),
  }),
)(
  ({ classes, data }) => <div className={classes.sLabel} title={data.label}>
    { data.rtv && <span className={classes.rtv}>Value - </span>}{data.label}
  </div>,
);

export const formatOptionLabel = (data) => <Label data={data} />;

const parseValue = ({ value }) => value;

function ArtistSelector({ classes, addPopup, editable, onRemove, modifier, ...props }) {
  return (
    <Field
      id="id-artist"
      label="Artist Name"
      name="artist"
      component={FormRowField}
      modifier={modifier}
      Field={AsyncMultiAutocomplete}
      placeholder="Artist Name"
      filterOption={() => true}
      parseValue={parseValue}
      validate={rules.required}
      required
      isMulti={false}
      grouped
      formatOptionLabel={formatOptionLabel}
      {...props}
    >
      <div className={cx(classes.buttonContainer, 'custom-artist-actions', { editable })}>
        {
          !props.disabled &&
            <button
              type="button"
              className={cx(classes.button, classes.add)}
              children=" "
              title="add new artist"
              onClick={() => addPopup({ name: CREATE_AUTHOR_POPUP })}
              data-name="add-new-artist"
            />
        }
        {
          !props.disabled && editable &&
            <button
              type="button"
              className={cx(classes.button, 'artistEdit')}
              children="edit"
              onClick={() => addPopup({ name: EDIT_AUTHOR_POPUP })}
              data-name="edit-user-artist"
            />
        }
        {
          (!props.disabled && editable) &&
            <button
              type="button"
              className={cx(classes.button, 'artistEdit')}
              children="delete"
              onClick={() => onRemove()}
              data-name="remove-artist-from-selector-val"
            />
        }
      </div>
    </Field>
  );
}

ArtistSelector.propTypes = {
  name: PropTypes.string,
  validate: PropTypes.func,
  classes: PropTypes.shape({
    buttonContainer: PropTypes.string.isRequired,
    button: PropTypes.string.isRequired,
    add: PropTypes.string.isRequired,
  }).isRequired,
  onUpdate: PropTypes.func,
  placeholder: PropTypes.string,
  valueNormalize: PropTypes.func,
  disabled: PropTypes.bool,
  editable: PropTypes.bool,
  addPopup: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  modifier: PropTypes.string,
};

export default compose(
  injectSheet(sheet),
  connect({
    addPopup: addPopupAction,
    editable: artistEditable_sel,
    onRemove: removeArtistAction,
  }),
)(ArtistSelector);
