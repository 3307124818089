export default {
  Range: {
    paddingTop: 10,
    '&.first': {
      paddingTop: 0,
    },
  },
  rangeWrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    '&.sizeUnitField:after': {
      display: 'block',
      content: '""',
      height: '100%',
      flex: '1 1 27px',
    },
  },
  /*
    labelLayout
      |-label
      |-formItem (was fieldWrapper)
  */
  separator: {
    margin: [16, 10, 0],
    flex: '0 0 7px',
  },
  errorMessage: {
    flex: '0 0 100%',
  },
};
