import {
  precountParams,
  getItemOffsetLeft,
  countScrollPoints,
} from './lib';

export * from './itemIsVisible';

/**
 * get left, right from position
 * allowedToLeft and allowedToRight counts for newPosition that depends on direction
 * direction: one of ['left', 'right', 'current']
**/
export const getScollParams = (node, position, direction = 'current') => {
  const { left, right } = countScrollPoints(node, position);
  let allowedToLeft = false;
  let allowedToRight = false;
  if (left === 0 && right === 0) {
    return { left, right, allowedToLeft, allowedToRight };
  }
  /**
   * next right position (right after [left or right])
   * check the ribbon overflow
  **/
  const nextRightFrom = ({ right, left, current: position })[direction];
  const { right: nextRight } = countScrollPoints(node, nextRightFrom);

  const newPosition = ({ left, right, current: position })[direction];
  /**
   * allowedToLeft from current position
  **/
  allowedToLeft = newPosition > 0;
  /**
   * allowedToRight from current position
  **/
  allowedToRight = nextRight !== newPosition;

  return { left, right, allowedToLeft, allowedToRight };
};

/**
 * returns slider posiition with visible current slider.
 * it will NOT return slide position, but page position with visible slide
 * like user scrolls to visible slide nativelly
**/
export const getPagePositionWithSlide = (node, itemIndex) => {
  if (!node) return 0;
  const {
    children,
    scrollDistance,
  } = precountParams(node);
  if (scrollDistance === 0) return 0;
  const slidePosition = getItemOffsetLeft(children, itemIndex);
  if (slidePosition === 0) return 0;
  const position = Math.floor(slidePosition / scrollDistance) * scrollDistance;
  return Math.floor(position);
};
