import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'cpcs-reconnect';
// import I from 'immutable';
import injectSheet from 'lib/sheet';
import sheet from './sheet';
// import { insightAvailable } from 'lib/helpers';
// import { Link } from 'react-router-dom';
import PageContainer from 'pages/common/pageContainer';
import { user_sel } from 'domain/env';
// import { lnk } from 'lib/routes';

class InsightPage extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.shape({
      insightFrame: PropTypes.string,
      noAccess: PropTypes.string,
    }).isRequired,
    // profile: PropTypes.instanceOf(I.Collection),
  };

  render() {
    const { /*profile,*/ classes } = this.props;
    return (
      <PageContainer modifier="insightPage">
        {/*
          insightAvailable(profile.subscriptionType) &&
            <iframe className={classes.insightFrame} title="artasaninvestment" src="https://artinvest.artbnk.com" width="100%" height="100%" frameBorder="0"/>
        */}
        {/*
          !insightAvailable(profile.subscriptionType) &&
          <div className={classes.noAccess}>
            <h2>We turned the valuation of art into a science.</h2>
            <h2>Get access to ARTBnk Market Insights when you sign up for a Collector or Patron plan.</h2>
            <h3 className={classes.notSubsc}><Link to={lnk('accountPageBilling')} className={classes.textLink} children="Upgrade Now" /></h3>
          </div>

        */}
        <div className={classes.noAccess}>
          <h2>Under construction</h2>
        </div>
      </PageContainer>
    );
  }
}


export default connect({
  profile: user_sel,
})(
  injectSheet(sheet)(InsightPage),
);
