import React from 'react';
import PropTypes from 'prop-types';
import I from 'immutable';
import MENU_ITEMS from './__mocks__/ArtGalleryContextMenuItems';
import injectSheet from 'lib/sheet';
import styles from './sheet';
import MenuItem from './MenuItem';

function ArtGalleryContextMenu({ classes, gallery, items = MENU_ITEMS, onHide, containerProps, pathname }) {
  const allowedItems = gallery.get('isDefault') ? items.filter(i => i.get('allowForDefault') === true) : items;
  return (
    <div
      className={classes.ArtGalleryContextMenu}
      {...containerProps}
    >
      <ul
        className={classes.list}
      >
        {
          allowedItems.map(item => (
            <li
              key={item.get('slug')}
              className={classes.item}
              onClick={onHide}
            >
              <MenuItem
                item={item}
                gallery={gallery}
                className={classes.link}
                prevPath={pathname}
              />
            </li>
          ))
        }
      </ul>
    </div>
  );
}



ArtGalleryContextMenu.propTypes = {
  classes: PropTypes.shape({
    ArtGalleryContextMenu: PropTypes.string.isRequired,
    list: PropTypes.string.isRequired,
    item: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
  }).isRequired,
  gallery: PropTypes.instanceOf(I.Collection).isRequired,
  pathname: PropTypes.string.isRequired,
  items: PropTypes.instanceOf(I.list),
  onHide: PropTypes.func,
  containerProps: PropTypes.shape({
    tabIndex: PropTypes.number,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
  }),
};

export default injectSheet(styles)(ArtGalleryContextMenu);
