import record, { listOf } from 'cpcs-recordjs';
import { field } from 'cpcs-reconnect';
import * as A from 'domain/rtvValueListPage/RtvValueListPageActions';
import { RtvValue, Pagination } from 'model';

/**
 * State
**/
const State = record('RtvValueList', {
  list: listOf(RtvValue),
  listPagination: Pagination.parse({}),
});

/**
 * selectors
**/
const base = field('rtvValueList');

export const rtvListSelector = base.then(State.$list);
export const rtvPaginationSelector = base.then(State.$listPagination);

/**
 * recuder
**/
export const reducer = {
  rtvValueList(state = new State(), action) {
    switch (action.type) {
      case A.rtvValueListFetchAction.failure:
      case A.rtvValueListAppendPageAction.failure:
        return state.update(
          State.$listPagination.update(v => v ? v.set('loading', false) : v),
        );
      case A.rtvValueListFetchAction.request:
      case A.rtvValueListAppendPageAction.request:
        return state.update(
          State.$listPagination.update(v => v.set('loading', true)),
        );
      case A.rtvValueListAppendPageAction.success:
        return state.apply(
          State.$list.parsedBy('concat', action.payload.content),
          State.$listPagination.parsed(action.payload),
        );
      case A.rtvValueListFetchAction.success:
        return state.apply(
          State.$list.parsed(action.payload.content),
          State.$listPagination.parsed(action.payload),
        );
      default:
        return state;
    }
  },
};
