import React from 'react';
import PropTypes from 'prop-types';
import I from 'immutable';
import { dateToAppFormat } from 'lib/helpers';
import { DARK_BLUE_COLOR } from 'theme/theme';

import injectSheet from 'lib/sheet';

const emptyList = I.List();


function NoteNoTheme({ classes, note }) {
  return (
    <li className={classes.Note}>
      <span className={classes.text} children={note.get('text')} />
      <span className={classes.date}>{dateToAppFormat(note.get('createdAt'))}</span>
    </li>
  );
}

NoteNoTheme.propTypes = {
  classes: PropTypes.object.isRequired,
  note: PropTypes.instanceOf(I.Collection).isRequired,
};

const sheet = {
  Note: {
    marginTop: 10,
    position: 'relative',
    '&:first-child': {
      marginTop: 0,
    },
  },
  text: {
    whiteSpace: 'pre-line',
    overflowWrap: 'break-word',
  },
  date: {
    position: 'absolute',
    left: 'calc(100% + 50px)',
    color: DARK_BLUE_COLOR,
    fontSize: '12px',
    fontStyle: 'italic',
    top: 0,
  },
};

const Note = injectSheet(sheet)(NoteNoTheme);

function notesSolver(notes = emptyList) {
  if (notes.size === 0) return null;
  return (
    <ul>
      {
        notes.map((note, key) =>
          <Note
            note={note}
            key={key}
          />,
        )
      }
    </ul>
  );
}

export default notesSolver;
