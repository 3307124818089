import { PAGE_SECTION_HEADER, PAGE_SECTION_HEADER_LINK, DEFAULT_FONTFAMILY, DEFAULT_FONTFAMILY_3 } from 'theme/theme';

export const pageLayout = {
  sectionHeader: {
    '&.lotPageArtistWorks': {
      display: 'flex',
      justifyContent: 'space-between',
    },
    '&.lotPageComparableList': {
      display: 'flex',
      justifyContent: 'space-between',
    },
    '&.sales-comparables': {
      display: 'grid',
      gridTemplateColumns: '1fr 220px',
      gridTemplateRows: 'max-content max-content',
      gridTemplateAreas: '"title actions" "description actions"',
      '@media screen and (max-width: 900px)': {
        gridTemplateAreas: '"title title" "description description" "actions actions"',
        rowGap: '10px',
      },
    },
  },
  sectionHeaderTitle: {
    lineHeight: '24px',
    color: PAGE_SECTION_HEADER,
    fontFamily: DEFAULT_FONTFAMILY,
    fontSize: '18px',
    fontWeight: 500,
    display: 'inline-block',
    '& .sectionHeaderTitleLink__artist': {
      whiteSpace: 'nowrap',
    },
    '& .see-all': {
      font: `400 12px/24px ${DEFAULT_FONTFAMILY}`,
      textTransform: 'uppercase',
      whiteSpace: 'nowrap',
    },
    '& .see-all__total': {
      fontSize: '13px',
      whiteSpace: 'nowrap',
    },
    '&.sales-comparables': {
      gridArea: 'title',
      font: `700 34px/40px ${DEFAULT_FONTFAMILY_3}`,
    },
  },
  sectionHeaderTitleLink: {
    extend: 'sectionHeaderTitle',
    color: PAGE_SECTION_HEADER_LINK,
    textDecoration: 'underline',
    fontSize: '20px',
    '&:hover': {
      opacity: 0.9,
    },
  },
};
