const editionSolver = (val, data, name, props) => {
  /**
   * val - one of [numbered editionSize artistProof unknown pressProof]
   * depend fields: [numberedNo numberedOf editionSize artistProof pressProof]
  **/
  // if (!val) return null;
  if (!val && data.get('notAccurate')) {
    val = 'unknown';
  }
  if (!data.category || !props.category || !props.category.get(data.category)) {
    return null;
  }
  if (!props.isEdition) {
    return null;
  }
  let dependVals = '';
  if (val === 'numbered') {
    if (![data.get('numberedNo'), data.get('numberedOf')].join('')) {
      return null;
    }
    dependVals = ['no.', data.get('numberedNo'), 'of', data.get('numberedOf')].join(' ');
  } else if (val !== 'unknown') {
    dependVals = [data.get(val)].join('');
  }
  return [
    props.edition.getIn([val, 'title']),
    dependVals ? `(${dependVals})` : '',
  ].join(' ');
};

export default editionSolver;
