import Api, { makeApiGenerator } from 'domain/api';
import {
  fetchArtworkAction,
  fetchAuthorAction,
} from 'domain/artworkPage/ArtworkPageActions';

export const ensureArtwork_apiGen = makeApiGenerator({
  api: Api.getArtwork,
  actionCreator: fetchArtworkAction,
});

export const fetchAuthor_apiGen = makeApiGenerator({
  api: Api.getArtistById,
  actionCreator: fetchAuthorAction,
});
