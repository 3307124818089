import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'cpcs-reconnect';
import I from 'immutable';
import { NavLink } from 'react-router-dom';
import { lnk } from 'lib/routes';
import { routeName_sel } from 'domain/router/RouterModel';
import { user_sel } from 'domain/env';
import { SUBSCRIPTION_TYPE_ENTERPRISE } from 'domain/const';

import injectSheet from 'lib/sheet';
import styles from './sheet.js';

class InsightPage extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.shape({
      TopNav: PropTypes.string,
      navLink: PropTypes.string,
      navLinkActive: PropTypes.string,
    }).isRequired,
    routeName: PropTypes.string,
    profile: PropTypes.instanceOf(I.Collection),
  };

  render() {
    const { classes, routeName, profile } = this.props;
    return (
      <div
        className={classes.TopNav}
      >
        <NavLink
          key="lnkAccount"
          to={lnk('accountPage')}
          exact
          className={classes.navLink}
          activeClassName={classes.navLinkActive}
          children="My Account"
        />
        {
          profile.subscriptionType !== SUBSCRIPTION_TYPE_ENTERPRISE &&
            routeName !== 'upgradeSubscriptionLink' &&
            <NavLink
              key="lnkBilling"
              to={lnk('billing')}
              exact
              className={classes.navLink}
              activeClassName={classes.navLinkActive}
              children="Billing"
            />
        }
        {
          routeName === 'upgradeSubscriptionLink' &&
          <NavLink
            key="upgradeSubscriptionLink"
            to={lnk('upgradeSubscriptionLink')}
            exact
            className={classes.navLink}
            activeClassName={classes.navLinkActive}
            children="Upgrade"
          />
        }
      </div>
    );
  }
}

export default compose(
  injectSheet(styles),
  connect({
    routeName: routeName_sel,
    profile: user_sel,
  }),
)(InsightPage);
