import {
  WHITE,
  DEFAULT_FONTFAMILY,
  DIVIDER_COLOR,
  HEADER_AVA_LETTERS,
  HEADER_DROPDOWN_DEFAULT_FONT,
  HEADER_DROPDOWN_HOVER,
  HEADER_DROPDOWN_SIGNOUT,
  NEW_STYLE,
} from 'theme/theme';

export default {
  UserMenu: {
    position: 'absolute',
    right: 0,
    top: 'calc(100% + 10px)',
    zIndex: 10,
    width: 229,
    background: WHITE,
    boxShadow: `0 0 3px 0 ${DIVIDER_COLOR}`,
    outline: 'none',
  },
  userMenuHeader: {
    display: 'flex',
    margin: [30, 10, 0],
    paddingBottom: 20,
    borderBottom: `1px solid ${DIVIDER_COLOR}`,
    '&:hover': {
      cursor: 'default',
    },
  },
  ava: {
    margin: [0, 0, 0, 20],
    width: 48,
    height: 48,
  },
  nameSurnameLetters: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: [0, 0, 0, 20],
    width: 48,
    height: 48,
    fontSize: 18,
    fontWeight: 500,
    color: WHITE,
    backgroundColor: HEADER_AVA_LETTERS,
  },
  nameSurname: {
    display: 'flex',
    margin: [0, 0, 0, 20],
    alignItems: 'center',
    fontSize: 14,
  },
  userMenuList: {
    margin: [8, 0],
  },
  menuItem: {
    // just li item
  },
  btn: {
    display: 'block',
    color: HEADER_DROPDOWN_DEFAULT_FONT,
    padding: [12, 0, 12, 30],
    width: '100%',
    textAlign: 'left',
    textDecoration: 'none',
    fontFamily: DEFAULT_FONTFAMILY,
    fontSize: 12,
    fontWeight: 500,
    textTransform: 'uppercase',
    outline: 0,
    border: 0,
    '&:hover': {
      cursor: 'pointer',
      background: HEADER_DROPDOWN_HOVER,
    },
  },
  upgrade: {
    color: NEW_STYLE,
  },
  signOut: {
    color: HEADER_DROPDOWN_SIGNOUT,
  },
};
