import { field } from 'theme/form';
import { FOCUSED_STYLE } from 'theme/focus';
import showPass from 'components/form/Password/images/showPass.svg';
import hidePass from 'components/form/Password/images/hidePass.svg';

export default {
  Password: {
    position: 'relative',
  },
  field,
  showHidePass: {
    position: 'absolute',
    top: 8,
    right: 10,
    width: 23,
    height: 22,
    background: {
      image: `url("${hidePass}")`,
      position: 'center center',
      size: '22px',
      repeat: 'no-repeat',
    },
    cursor: 'pointer',
    border: 'none',
    '&:focus': {
      ...FOCUSED_STYLE,
    },
  },
  text: {
    top: 12,
    width: 23,
    height: 14,
    background: `url("${showPass}") left top no-repeat`,
  },
  message: {
    fontSize: 12,
    marginTop: 3,
  },
};
