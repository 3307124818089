export const $updateById = (id, updater) => $listArg => $listArg.update($list =>
  $list.update(
    list =>
      list.map(
        item => (
          item.get('id') === id ? updater(item) : item
        ),
      ),
  ),
);

export const $mergeRecordsListById = ($newList) => $listArg => $listArg.update(
  $list =>
    $newList.reduce(($prev, $newItem) => {
      const [index, $item] = $prev.findEntry($item => $item.get('id') === $newItem.id) || [];
      if ($item) {
        return $prev.set(index, $newItem);
      }
      return $prev.push($newItem);
    }, $list),
);

export const $mergeUnshiftingRecordsListById = ($newList) => $listArg => $listArg.update(
  $list =>
    $newList.reverse().reduce(($prev, $newItem) => {
      const [index, $item] = $prev.findEntry($item => $item.get('id') === $newItem.id) || [];
      if ($item) {
        return $prev.set(index, $newItem);
      }
      return $prev.unshift($newItem);
    }, $list),
);

/**
 * state.apply(
 *   State.$list.update($replaceById(State.$list.type.parse(action.payload))),
 *   // or
 *   $replaceById(State.$list.type.parse(action.payload))(State.$list),
 * ),
**/

export const $replaceById = $newItem => $listArg => $listArg.update(
  $list =>
    $list.find(v => v.id === $newItem.id) ?
      $list.map(v => v.id === $newItem.id ? $newItem : v) :
      $list.push($newItem),
);

export default {
  $replaceById,
  $mergeRecordsListById,
  $mergeUnshiftingRecordsListById,
  $updateById,
};
