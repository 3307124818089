import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { FieldArray } from 'redux-form';
import ProvenanceField from './ProvenanceField';

import injectSheet from 'lib/sheet';
import sheet from './sheet.js';

class Provenance extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    modifier: PropTypes.string,
  };

  renderFields = ({ fields }) => {
    const { classes, modifier } = this.props;
    return (
      <div className={cx(classes.fieldWrapper, modifier)}>
        {
          fields.length > 0 && fields.map((item, index, fields) =>
            <ProvenanceField
              fields={fields}
              key={`${index}/${fields.length}`}
              index={index}
              modifier={modifier}
            />)
        }
        {
          fields.length === 0 &&
          <button
            type="button"
            onClick={() => fields.length < 20 && fields.push({ owner: '', editable: true })}
            className={cx(classes.button, classes.add, classes.expandButton)}
            children="add provenance"
          />
        }
      </div>
    );
  }

  render() {
    const { classes, modifier } = this.props;
    return (
      <li className={cx(classes.formItem, modifier, 'Provenance')}>
        <label
          htmlFor={'provenance'}
          className={cx(classes.label, modifier, 'provenance')}
          children={'Provenance'}
        />
        <FieldArray
          name={'provenance'}
          component={this.renderFields}
        />
      </li>
    );
  }
}

export default injectSheet(sheet)(Provenance);
