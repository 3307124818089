import React from 'react';
import PropTypes from 'prop-types';
import { query_sel } from 'domain/router/RouterModel';
import injectSheet from 'lib/sheet';
import { compose } from 'redux';
import { connect } from 'cpcs-reconnect';
import { reduxForm, Field, SubmissionError } from 'redux-form';
import sheet from './sheet';
import { FORM_CREATE_PASSWORD } from 'domain/const';
import FormRowField from '../common/newPage/form/elements/FormRowField';
import { createPasswordAction } from 'domain/env';
import { parseServerError } from 'lib/helpers';
import { ContactSupportMessage } from 'components/errors/ContactSupport';
import { WrongEmailMessage } from 'components/errors/WrongEmail';
import { sendCreatePasswordEmailAction, sendCreatePasswordEmail_sel } from 'pages/createPassword/createPasswordModel';
import cx from 'classnames';
import validatePassword from './validate';

function submit(data, dispatch) {
    data.confirmPassword = data.newPassword;
    const submitPromise = new Promise((resolve, reject) => {
        dispatch(createPasswordAction({ data, resolve, reject }));
    });
    return submitPromise.catch((err) => {
        const { message } = parseServerError(err);
        if ('Contact Support' === message) {
            throw new SubmissionError({
                email: <ContactSupportMessage />,
            });
        }
        throw new SubmissionError({
            email: <WrongEmailMessage />,
        });
    });
}

function ConfirmationBox(props) {
  const { classes } = props;
  return (
    <div className={classes.ConfirmationBox}>
      <p className={classes.createPasswordText}>We just emailed you! </p>
      <div className={classes.separator}/>
      <p className={classes.regularText}>
        Please check you inbox for your new password creation link. If you have any questions or need assitance, please&nbsp;
        <a href="https://www.artbnk.com/contact-us" target="_blank" rel="noreferrer" className={classes.link}>contact us.</a>
      </p>
    </div>
  );
}

ConfirmationBox.propTypes = {
  classes: PropTypes.shape({
    ConfirmationBox: PropTypes.string.isRequired,
    createPasswordText: PropTypes.string.isRequired,
    separator: PropTypes.string.isRequired,
    regularText: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
  }).isRequired,
};

function TokenExpiredBox(props) {
  const { classes, resendNewLink } = props;
  return (
    <div className={classes.TokenExpiredBox}>
      <p className={cx(classes.createPasswordText)}>Your password creation link has expired. </p>
      <div className={classes.separator}/>
      <p className={classes.regularText}>For security reasons, we will send you a new link
        to create your ARTBnk password.
      </p>
      <button onClick={() => resendNewLink()} className={classes.button}>SEND ME A NEW LINK</button>
    </div>
  );
}

TokenExpiredBox.propTypes = {
  resendNewLink: PropTypes.bool.isRequired,
  classes: PropTypes.shape({
    TokenExpiredBox: PropTypes.string.isRequired,
    createPasswordText: PropTypes.string.isRequired,
    separator: PropTypes.string.isRequired,
    regularText: PropTypes.string.isRequired,
    button: PropTypes.string.isRequired,
  }).isRequired,
};

function CreatePasswordForm_(props) {
  const { classes, handleSubmit, submitting, submitSucceeded } = props;
  if (submitSucceeded) {
    return <ConfirmationBox classes={classes}/>;
  }
  return (
    <div>
      <p className={classes.createPasswordText}>Create a password</p>
      <form
        onSubmit={handleSubmit(submit)}
        className={classes.wrapper}
      >
        <Field
          name="newPassword"
          component={FormRowField}
          placeholder="password"
          rootTag="div"
          modifier="CreatePassword"
          type="password"
        />
        <p className={classes.inputDescription}>new password</p>
        <div className={classes.btnWrapper}>
          <button
            type="submit"
            disabled={submitting}
            className={classes.button}
          >login
          </button>
        </div>
      </form>
    </div>

  );
}

CreatePasswordForm_.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  submitSucceeded: PropTypes.bool.isRequired,
  classes: PropTypes.shape({
    btnWrapper: PropTypes.string.isRequired,
    inputDescription: PropTypes.string.isRequired,
    wrapper: PropTypes.string.isRequired,
    button: PropTypes.string.isRequired,
    createPasswordText: PropTypes.string.isRequired,
  }).isRequired,
};

const CreatePasswordForm = reduxForm({
    form: FORM_CREATE_PASSWORD,
    validate: validatePassword,
  })(CreatePasswordForm_);

class createPassword extends React.Component {
  render() {
    const { classes, query: { token, expired }, resendNewLink, newEmailSent } = this.props;
    return (
      <div className={classes.CreatePassword}>
        <div className={classes.createPasswordContent}>
          <div className={classes.createPasswordForm}>
            <div className={classes.logo}/>
            {
              newEmailSent ? <ConfirmationBox classes={classes}/> :
                (expired === 'expired' ? <TokenExpiredBox classes={classes} resendNewLink={resendNewLink}/> :
                <CreatePasswordForm initialValues={{ token }} classes={classes}/>)
            }
          </div>
          <div className={classes.createPasswordImage}>
            <h3 className={classes.pageTitle}>The Power Of Artificial Intelligence, Machine Learning And 100,000,000 Data
              Points.</h3>
            <p className={classes.pageText}>ARTBnk provides objective, real-time art valuations to enable smart investment
              decisions.</p>
          </div>
        </div>
      </div>
    );
  }
}

createPassword.propTypes = {
  query: PropTypes.shape({
    token: PropTypes.string,
    expired: PropTypes.string,
  }),
  resendNewLink: PropTypes.func.isRequired,
  newEmailSent: PropTypes.bool.isRequired,
  classes: PropTypes.shape({
    CreatePassword: PropTypes.string.isRequired,
    createPasswordContent: PropTypes.string.isRequired,
    createPasswordForm: PropTypes.string.isRequired,
    createPasswordText: PropTypes.string.isRequired,
    createPasswordImage: PropTypes.string.isRequired,
    logo: PropTypes.string.isRequired,
    pageTitle: PropTypes.string.isRequired,
    pageText: PropTypes.string.isRequired,
  }).isRequired,
};

export default compose(
  connect({
    query: query_sel,
    resendNewLink: sendCreatePasswordEmailAction,
    newEmailSent: sendCreatePasswordEmail_sel,
  }),
  injectSheet(sheet),
)(createPassword);
