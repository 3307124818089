import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'cpcs-reconnect';
import cx from 'classnames';

import Fieldset from 'components/fieldset';
import { Definition } from 'components/reports/Definition';
import { ILLIQUID } from 'domain/const';

import Api from 'domain/api';
import { token_sel } from 'domain/env/EnvModel';
import { InfoTooltip } from 'components/Tooltip';
import { ArtistFinancialPerformanceMetricsDefinitions } from 'components/reports/ArtistFinancialPerformanceMetricsDefinitions';

// PDF
import { EVENT_GET_PAGE_COMPONENT } from 'pages/highcharts/helpers/PDFConstants';
import { useListen } from 'pages/highcharts/helpers/EventsContext';


import { DEFAULT_FONTFAMILY_3 } from 'theme/theme';
import injectSheet from 'lib/sheet';



const sheet = {
  ArtistFinancialPerformanceMetrics: {
    marginTop: 32,
    border: '1px solid #E5E5E5',
    padding: [23, 0, 23, 38],
    borderRadius: 4,
    '@media screen and (max-width: 1760px)': {
      padding: [20, 0, 23, 33],
    },
    '&.artistReport': {
      marginTop: 64,
      border: 'none',
      padding: 0,
    },
  },
  title: {
    font: `500 16px/22px ${DEFAULT_FONTFAMILY_3}`,
    '&.artistReport': {
      color: '#000',
      font: `700 24px/32px ${DEFAULT_FONTFAMILY_3}`,
    },
  },
  description: {
    font: `400 13px/15px ${DEFAULT_FONTFAMILY_3}`,
    '&.artistReport': {
      font: `400 16px/24px ${DEFAULT_FONTFAMILY_3}`,
      color: '#737992',
    },
  },
  content: {
    display: 'grid',
    gridTemplateColumns: 'auto 400px',
    gridTemplateAreas: '"chart definitions"',
    columnGap: '70px',
    alignItems: 'start',
    justifyContent: 'space-between',
    '@media screen and (max-width: 1760px)': {
      gridTemplateColumns: '780px 340px',
    },
    '@media screen and (max-width: 1360px)': {
      gridTemplateColumns: 'auto 315px',
    },
    '@media screen and (max-width: 1200px)': {
      gridTemplateColumns: 'auto',
      gridTemplateAreas: '"chart" "definitions"',
    },
    '&.artistReport': {
      display: 'block',
    },
  },
  definitions: {
    gridArea: 'definitions',
    '&.artistReport': {
      display: 'none',
    },
  },
  metricBlocks: {
    marginTop: 40,
    display: 'flex',
    flexWrap: 'wrap',
    gridArea: 'chart',
    width: 'calc(100% + 24px)',
    '@media screen and (max-width: 1360px)': {
      maxWidth: 615,
    },
    '&.artistReport': {
      width: 'calc(100% + 16px)',
      marginTop: 14,
      '@media screen and (max-width: 1360px)': {
        maxWidth: 'unset',
      },
      '@media screen and (max-width: 1200px)': {
        maxWidth: 645,
      },
      '@media screen and (max-width: 900px)': {
        width: 'calc(100% + 8px)',
      },
    },
  },
  metricBlock: {
    display: 'grid',
    marginRight: 24,
    marginTop: 29,
    height: 88,
    boxShadow: '0px 2px 6px rgba(5, 16, 55, 0.1)',
    padding: [13, 16],
    borderBottom: '8px solid #F0F2F6',
    '@media screen and (max-width: 1760px)': {
      padding: [11, 11],
    },
    '&.artistReport': {
      backgroundColor: '#F8F6F5',
      borderBottom: '4px solid #F8F6F5',
      boxShadow: 'none',
      padding: 16,
      marginRight: 16,
      marginTop: 16,
      height: 'auto',
      flex: '0 0 max-content',
      rowGap: '4px',
      '@media screen and (max-width: 900px)': {
        marginRight: 8,
        marginTop: 8,
        flex: '1 1 max-content',
        '&.artist_liquidity_ranking': {
          minWidth: '240px',
        },
      },
      '@media screen and (max-width: 450px)': {
        '&.artist_liquidity_ranking': {
          minWidth: 'unset',
        },
      },
    },
  },
  label: {
    font: `500 11px/13px ${DEFAULT_FONTFAMILY_3}`,
    color: '#777',
    '@media screen and (max-width: 1760px)': {
      fontSize: '10.44px',
    },
    '&.artistReport': {
      color: '#454A5F',
      font: `700 14px/16px ${DEFAULT_FONTFAMILY_3}`,
    },
  },
  value: {
    font: `700 22px/27px ${DEFAULT_FONTFAMILY_3}`,
    '&.liquidity_level': {
      color: '#27272E',
      textTransform: 'capitalize',
    },
    '&.median_compaund_anual_return_car': {
      color: '#304C95',
    },
    '&.artist_self_throught_rate': {
      //
    },
    '&.artist_liquidity_ranking': {
      color: '#304C95',
    },
    '&.artist_returns_ranking': {
      color: '#304C95',
    },
    '@media screen and (max-width: 1760px)': {
      fontSize: '19.14px',
    },
    '&.artistReport': {
      font: `700 24px/32px ${DEFAULT_FONTFAMILY_3}`,
    },
  },
  totalNumber: {
    color: '#8C92AB',
    font: `700 16px/24px ${DEFAULT_FONTFAMILY_3}`,
  },
};

const formatFloat = (v = '') => v ? new Intl.NumberFormat('en-EN', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
}).format(v) : v;

/*
 * artist_liquidity_ranking: 86
 * artist_returns_ranking: 616
 * artist_self_throught_rate: 12.5
 * liquidity_level: "active"
 * median_compaund_anual_return_car: 3.199045020736624
 * risk_rating_cov: 10.898114266984042
**/
const emptyMetrick = {
  artist_id: null,
  artist_self_throught_rate: null,
  liquidity_level: null,
  artist_liquidity_ranking: null,
  artist_total_liquidity_ranking: null,
  median_compaund_anual_return_car: null,
  artist_returns_ranking: null,
  risk_rating_cov: null,
};

function ArtistFinancialPerformanceMetrics({ classes, token, artistId, onSetLiquidity, modifier, artistReport }) {
  const [metric, setMetric] = useState(emptyMetrick);
  useEffect(() => {
    let unmounted = false;
    if (!artistId) return;
    const fetchData = async () => {
      const resp = await Api.getMarketPerformanceSeries({ token, artistId, chartId: 't001' });
      if (!resp.data || !resp.data.data || unmounted) return;
      onSetLiquidity(resp.data.data.liquidity_level);
      let newData = resp.data.data;
      newData = {
        ...newData,
        median_compaund_anual_return_car: formatFloat(newData.median_compaund_anual_return_car),
        artist_self_throught_rate: formatFloat(newData.artist_self_throught_rate),
        risk_rating_cov: formatFloat(newData.risk_rating_cov),
      };
      setMetric(newData);
    };
    fetchData();
    return () => {
      unmounted = true;
      // console.log('CAGRVsHoldingPeriod unmounted', artistId);
    };
  }, [token, artistId, onSetLiquidity]);

  // pdf Events
  const onGetComponent = useCallback((event) => {
    if (event.id === 'AFPM') {
      event.isPropagationStopped = true;
      return { item: metric };
    }
  }, [metric]);
  useListen(EVENT_GET_PAGE_COMPONENT, onGetComponent);

  const liquid = metric.liquidity_level !== ILLIQUID;
  return (
    <div
      className={cx(classes.ArtistFinancialPerformanceMetrics, modifier)}
      data-chart-id="t001"
    >
      <h3 className={cx(classes.title, modifier)}>Artist Financial Performance Metrics</h3>
      <div className={cx(classes.description, modifier)}>
        An overview of the artist’s key performance indicators
        {' '}
        {
          artistReport &&
            <InfoTooltip>
              <ArtistFinancialPerformanceMetricsDefinitions />
            </InfoTooltip>
        }
      </div>
      <div className={cx(classes.content, modifier)}>
        <div className={cx(classes.metricBlocks, modifier)}>
          <div className={cx(classes.metricBlock, modifier, 'liquidity_level')}>
            <div className={cx(classes.label, modifier)}>Liquidity Level</div>
            <div className={cx(classes.value, modifier, 'liquidity_level')}>{metric.liquidity_level}</div>
          </div>
          {
            liquid &&
              <div className={cx(classes.metricBlock, modifier, 'median_compaund_anual_return_car')}>
                <div className={cx(classes.label, modifier)}>Median Compound Growth Rate (CAGR)</div>
                <div className={cx(classes.value, modifier, 'median_compaund_anual_return_car')}>{metric.median_compaund_anual_return_car}%</div>
              </div>
          }
          <div className={cx(classes.metricBlock, modifier, 'artist_self_throught_rate')}>
            <div className={cx(classes.label, modifier)}>Artist Sell-Through Rate</div>
            <div className={cx(classes.value, modifier, 'artist_self_throught_rate')}>{metric.artist_self_throught_rate}%</div>
          </div>
          <div className={cx(classes.metricBlock, modifier, 'artist_liquidity_ranking')}>
            <div className={cx(classes.label, modifier)}>Repeat Sales Ranking</div>
            <div className={cx(classes.value, modifier, 'artist_liquidity_ranking')}>
              {metric.artist_liquidity_ranking}
              <span className={classes.totalNumber}> / {metric.artist_total_liquidity_ranking}</span>
            </div>
          </div>
          {
            liquid &&
              <div className={cx(classes.metricBlock, modifier, 'risk_rating_cov')}>
                <div className={cx(classes.label, modifier)}>Artist Volatility (Risk)</div>
                <div className={cx(classes.value, modifier, 'risk_rating_cov')}>{metric.risk_rating_cov}</div>
              </div>
          }
          {
            liquid &&
              <div className={cx(classes.metricBlock, modifier, 'artist_returns_ranking')}>
                <div className={cx(classes.label, modifier)}>Artist Returns Ranking</div>
                <div className={cx(classes.value, modifier, 'artist_returns_ranking')}>
                  {metric.artist_returns_ranking}
                  <span className={classes.totalNumber}> / {metric.artist_total_returns_ranking}</span>
                </div>
              </div>
          }
        </div>
        <div className={cx(classes.definitions, modifier)}>
          <Fieldset title="ABOUT THIS TABLE" RootTag="section" modifier="report_definitions">
            <Definition id="T001_LIQUIDITY_LEVEL" />
            <Definition id="T001_ARTIST_MEDIAN_COMPOUND_GROWTH_RATE" />
            <Definition id="T001_ARTIST_SELL_THROUGH_RATE" />
            <Definition id="T001_ARTIST_LIQUIDITY_RANKING" />
            <Definition id="T001_ARTIST_RETURN_RANKING" />
            <Definition id="T001_ARTIST_VOLATILITY_RISK" />
          </Fieldset>
        </div>
      </div>
    </div>
  );
}
ArtistFinancialPerformanceMetrics.propTypes = {
  classes: PropTypes.object.isRequired,
  token: PropTypes.string,
  artistId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  onSetLiquidity: PropTypes.func.isRequired,
  modifier: PropTypes.string,
  artistReport: PropTypes.bool,
};

export default compose(
  injectSheet(sheet),
  connect({
    token: token_sel,
  }),
)(ArtistFinancialPerformanceMetrics);
