const stopPropagationOriginal = Event.prototype.stopPropagation;
Event.prototype.stopPropagation = function() {
  this.isPropagationStopped = true;
  stopPropagationOriginal.apply(this, arguments);
};

const JustFinishMainLoop = new Error('just finish .map loop');

// eslint-disable-next-line no-unused-vars
const parseEventListenersToLog = (listeners) => {
  const ret = {};
  ret.length = listeners.length;
  ret.names = listeners
    .reduce(
      ((c,i) =>
        {c.push(i.name);return c;}),
      [],
    )
    .join(',\n    ');
  return ret;
};


const getLog = (logName) => ({ eventName, before, after, events, listenerName }) => {
  console.log(`${logName} ${eventName} '${listenerName}' ${before.length} ${after.length} \n[\n    ${before.names}\n]\n -> \n[\n    ${after.names}\n]\n`, events[eventName]);
};

// eslint-disable-next-line no-unused-vars
const logAdd = getLog('addEventListener');
// eslint-disable-next-line no-unused-vars
const logRm = getLog('removeEventListener');

export class Dispatcher {
  events = {};

  addEventListener(eventName, listener, { name, once = false } = {}) {
    const { events } = this;
    if (!(eventName in events)) {
      events[eventName] = [];
    }
    // const before = parseEventListenersToLog(events[eventName]);
    events[eventName].unshift({ listener, name, once, eventName });
    // const after = parseEventListenersToLog(events[eventName]);
    // logAdd({ eventName, before, after, events, listenerName });
  }

  removeEventListener(eventName, listener) {
    const { events } = this;
    if (!(eventName in events)) {
      events[eventName] = [];
      return;
    }
    // const before = parseEventListenersToLog(events[eventName]);
    events[eventName] = events[eventName].filter(l => (
      !(l.listener === listener /*|| (listenerName && l.name === listenerName)*/)
    ));
    // const after = parseEventListenersToLog(events[eventName]);
    // logRm({ eventName, before, after, events, listenerName });
  }

  dispatch(eventName, event = {}) {
    // console.log('dispatch Event', eventName, event.data || event);
    const { events } = this;
    const listenerList = [...(events[eventName] || []), ...(events['*'] || [])];
    let ret;
    if (event.collect) {
      ret = [];
    }
    try {
      listenerList.forEach(l => {
        // console.log(`dispatcher ${l.name} ${event.key}`, { isPropagationStopped: event.isPropagationStopped });
        const isPropagationStopped = ((typeof event.isPropagationStopped === 'function') ? event.isPropagationStopped() : event.isPropagationStopped);
        if (event.defaultPrevented || isPropagationStopped) {
          throw JustFinishMainLoop;
        } else {
          const result = l.listener(event, eventName);
          if (l.once) this.removeEventListener(l.eventName, l.listener);
          if (event.collect && !l.listener.__skipOnCollect) {
            ret.push(result);
          } else {
            ret = ret || result;
          }
        }
      });
    } catch (e) {
      if (e !== JustFinishMainLoop) {
        console.error(e);
      }
      /* just finish .map loop */
    }
    return ret;
  }

  setListenerName(eventName, listener, newName) {
    const { events } = this;
    const listenerList = events[eventName] || [];
    listenerList.forEach(l => {
      if (l.listener === listener) {
        // console.log(`Dispatcher: change listener name \n    '${l.name}' -> '${newName}'`);
        l.name = newName;
      }
    });
  }

  destroy() {
    this.events = {};
  }
}

global.PDFDebug = !!(global.localStorage ? global.localStorage.getItem('PDFDebug') : false);

global.ARTBnkLogger = new Dispatcher();
global.ARTBnkLogger.addEventListener('*', (event, name) => {
  if (!global.PDFDebug) return;
  const data = (event && event.data) || {};
  switch (name) {
    case 'EVENT_ITEM_DID_MOUNT':
      console.log(name, data.name, data.parts ? data.part : '', data);
      break;
    case 'EVENT_APPEND_TO_PDF':
      console.log(name, event.item.name, data.parts ? data.part : '', event.item.contentSection, event);
      break;
    case 'EVENT_ITEM_DID_UPDATE':
      console.log(name, data.name, data.parts ? data.part : '', data);
      break;
    default:
      console.log(name, event);
  }
});
// const { defaultPrevented, key, keyCode, isPropagationStopped, eventPhase } = e;
// console.log('txt:', { defaultPrevented, key, keyCode, isPropagationStopped, eventPhase, e});
