import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Field, reduxForm } from 'redux-form';
import { compose } from 'redux';
import { connect } from 'cpcs-reconnect';
import FormRowField from 'pages/common/newPage/form/elements/FormRowField';
import { FORM_CHANGE_PASSWORD } from 'domain/const';
import validatePassword, { errorMessages } from './validate';
import { changePasswordAction, hasPassword } from 'domain/env';
import injectSheet from 'lib/sheet';
import sheet from './sheet.js';
import Password from 'components/form/Password';

class ChangePasswordForm extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    changePassword: PropTypes.func.isRequired,
    valid: PropTypes.bool,
    pristine: PropTypes.bool,
    hasPassword: PropTypes.bool,
  };

  onSubmit = (data) => {
    this.props.changePassword(data);
  };

  render() {
    const { classes, hasPassword } = this.props;
    return (
      <form
        onSubmit={this.props.handleSubmit(this.onSubmit)}
        className={classes.ChangePasswordForm}
      >
        <h3
          className={classes.title}
          children="Password"
        />
        {
          hasPassword &&
            <Field
              name="currentPassword"
              modifier="ChangePasswordForm passwordField"
              component={FormRowField}
              placeholder="Current password"
              autoComplete="off"
              Field={Password}
              rootTag="div"
              errorMessages={errorMessages}
            />
        }
        <Field
          name="newPassword"
          modifier="ChangePasswordForm passwordField"
          component={FormRowField}
          placeholder="New password"
          autoComplete="off"
          Field={Password}
          rootTag="div"
          errorMessages={errorMessages}
        />
        <button
          type="submit"
          children="update"
          className={cx(classes.button, 'AccountPage')}
          disabled={!this.props.valid || this.props.pristine}
        />
      </form>
    );
  }
}

export default compose(
  injectSheet(sheet),
  connect({
    changePassword: changePasswordAction,
    hasPassword,
  }),
  reduxForm({
    form: FORM_CHANGE_PASSWORD,
    validate: validatePassword,
  }),
)(ChangePasswordForm);
