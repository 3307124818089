import { select, fork } from 'redux-saga/effects';

import { matchParamsSel } from 'domain/router/RouterModel';
import { fetchArtworkList_apiGen } from 'domain/artwork/sagas';
import { watchCreateAlertByArtistId } from 'domain/alerts/sagas';
import { analyticsFormInit } from 'pages/common/buyAnalytics/saga';
import { DEFAULT_PAGE_SIZE } from 'domain/const';


export default function* () {
  yield fork(watchCreateAlertByArtistId);
  yield fork(analyticsFormInit);
  let { authorId } = yield select(matchParamsSel);
  authorId = parseInt(authorId, 10);
  yield fork(fetchArtworkList_apiGen.catchError, { artistId: authorId, page: 0, size: DEFAULT_PAGE_SIZE });
}
