import I from 'immutable';
import * as A from './UIActions';
import * as env from 'domain/env/EnvActions';
import { field, selector, at } from 'cpcs-reconnect';
import { galleryMapIndexedById_sel } from 'domain/galleries/GalleryModel';
import loaderActions from './loaderActions';
import { defaultParams, cleanEmpty } from 'pages/common/sidebar/filter/utils';

const emptyRouteParams = I.Map();

const State = I.fromJS({
  // AFDefault | AFOpened | AFClosed
  filter: 'AFDefault',
  // list of I.Map({ name: '', params: {} })
  filterParams: defaultParams,
  popup: I.List(),
  requestedLocation: null,
  requests: false,
  pageLoading: '',
  errors: {
    err404: false,
  },
  links: {
    gallery: {
      name: '',
      params: {},
    },
  },
  mobileMenuOpened: false,
  extraFooterGap: false,
});

const base = field('ui');
export const advancedFilterOpenState_sel = base.then(at('filter'));
export const mobileMenuOpenedSel = base.then(at('mobileMenuOpened'));
export const extraFooterGap_sel = base.then(at('extraFooterGap'));
export const filterParams = base.then(s => s.get('filterParams').toJS());
export const loading = base.then(at('requests'));
export const requestedLocation = base.then(at('requestedLocation'));
const popup = base.then(at('popup'));
export const popupStatusSelector = popup.then(list => list.last());
export const getPopupNameSelector = popupStatusSelector.then(s => s ? s.get('name') : void 0);
export const getPopupParamsSelector = popupStatusSelector.then(s => s ? s.get('params') : void 0);
export const isPopupVisibleSelector = getPopupNameSelector.then(n => (name) => n && name === n);
export const popupInListSelector = popupName => popup.then(list =>
  !!list.find(p => p.get('name') === popupName));
export const getRoute = (component) => base.then(s => s.getIn(['links', component]));
export const get404_sel = base.then(s => s.getIn(['errors', 'err404']));
export const getGalleryByPopupParamsSelector = selector(getPopupParamsSelector, galleryMapIndexedById_sel, (params, galleries) =>
  params ? galleries.get(parseInt(params.get('galleryId'), 10)) : void 0);
export const getMatch = field('router').then(r => r.location.match);

export const pageLoadingSel = base.then(at('pageLoading'));

export const reducer = {
  ui(state = State, action) { //NOSONAR
    if (loaderActions.requests.includes(action.type)){
      return state.set('requests', true);
    }
    if (loaderActions.responses.includes(action.type)){
      return state.set('requests', false);
    }
    switch (action.type) {


      case A.setPageLoadingAction.type:
        return state.set('pageLoading', action.payload);
      case A.resetFilterParamsAction.type:
        return state.update('filterParams', v => v.merge(I.fromJS(defaultParams)));

      case A.updateFilterParamsAction.type:
        return state.update('filterParams', v => v.merge(I.fromJS(cleanEmpty(action.payload))));

      case '@@router/LOCATION_CHANGE':
        return state
          .update('requests', v => action.payload.isFirstRendering ? v : false)
          .set('extraFooterGap', false)
          .update('requestedLocation', v => v || action.payload.pathname)
          .set('mobileMenuOpened', false);

      case A.resetRequestedLocation.type:
        return state.set('requestedLocation', null);

      case env.logOut.type:
        return State;

      case A.filterToggleAction.type:
        return state.update('filter', filter => (filter === 'AFDefault' || filter === 'AFOpened') ? 'AFClosed' : 'AFOpened');
      case A.filterOpenAction.type:
        return state.set('filter', 'AFOpened');
      case A.toggleExtraFooterGapAction.type:
        return state
          .set('extraFooterGap', action.payload);
      // case '@@router/PATH_NAME_CHANGED_ACTION':
      //   return state
      //     .set('filter', 'AFClosed');

      case A.hidePopupAction.type:
        return state.set('popup', state.get('popup').pop());
      case A.hidePopupByNamesAction.type:
        return state.set('popup', state.get('popup').filter(p => !action.payload.includes(p.get('name'))));
      case A.addPopupAction.type:
        return state.set('popup', state.get('popup').push(I.fromJS({
          name: action.payload.name,
          params: action.payload.params || {},
        })));

      case A.setLinkAction.type:
        return state
         .setIn(['links', action.payload.component, 'name'], action.payload.route)
         .setIn(['links', action.payload.component, 'params'], action.payload.params || emptyRouteParams);
      case A.err404Action.type:
        return state
          .setIn(['errors', 'err404'], action.payload);

      case A.toggleMobileMenuAction.type:
        return state.update('mobileMenuOpened', v => !v);

      default:
        return state;
    }
  },
};
