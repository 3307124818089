import carret from './images/caret.svg';
import cross from './images/close.svg';
import checked from './images/checked.svg';
import crop from './images/crop.svg';
import { DEFAULT_BUTTON_COLOR } from 'theme/theme';

export default {
  ImageSlider: {
    display: 'flex',
    flexDirection: 'column',
  },
  slider: {
    display: 'flex',
    maxWidth: '100%',
    overflow: 'hidden',
  },
  previewMain: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    overflow: 'hidden',
    '&:hover': {
      '& $btnContainer': {
        display: 'flex',
      },
    },
    '& $btnContainer': {
      display: 'none',
    },
    '&.withBorder': {
      border: 'dashed 2px #babcbf',
    },
  },
  images: {
    flex: '1 1 0',
    minWidth: 0,
    height: 64,
    display: 'flex',
    alignItems: 'center',
    // justifyContent: 'center',
    justifyContent: 'flex-start',
    overflow: 'hidden',
    marginRight: 10,
    scrollBehavior: 'smooth',
  },
  disabled: {
    opacity: 0.2,
  },
  controls: {
    flex: '0 0 28px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  button: {
    cursor: 'pointer',
    width: 28,
    height: 28,
    background: {
      repeat: 'no-repeat',
      position: 'center center',
      size: '28px, 28px',
    },
  },
  next: {
    extend: 'button',
    backgroundImage: `url(${carret})`,
    transform: 'rotate(-90deg)',
  },
  previous: {
    margin: [5, 0, 0],
    extend: 'next',
    transform: 'rotate(90deg)',
  },
  btnContainer: {
    position: 'absolute',
    right: 'calc(50% - 25px)',
    width: 60,
    bottom: 10,
    opacity: 0.7,
    display: 'flex',
    justifyContent: 'space-around',
  },
  remove: {
    width: 24,
    height: 24,
    cursor: 'pointer',
    background: `url("${cross}") scroll no-repeat center`,
    backgroundSize: [14, 14],
    borderRadius: '50%',
    backgroundColor: DEFAULT_BUTTON_COLOR,
    outline: 'none',
  },
  crop: {
    extend: 'remove',
    background: `url("${crop}") scroll no-repeat 1px 1px`,
    backgroundSize: [20, 20],
    backgroundPosition: 'center',
  },
  setMain: {
    extend: 'remove',
    background: `url("${checked}") scroll no-repeat 1px 1px`,
    backgroundSize: [24, 24],
  },
};
