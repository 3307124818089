// title artist createdDate auctionDate

/*
# sorting

Discover
 title. A-Z already by default,    title:asc*
Z-A       title:desc
=========================
Collect, Tracking page
by title - sort=title*
by artist last name - sort=artist
by date added - sort=createdDate
===========================
Artist search page,  Alert Page -> lots page
by auction date desc - sort=auctionDate:desc*
by auction date asc - sort=auctionDate:asc
by artist - sort=artist
*/

const artByTitle = {
  id: 'artByTitle',
  title: 'Title',
  params: { sort: 'title' },
};

const byTitle = {
  id: 'byTitle',
  title: 'A-Z',
  params: { sort: 'title:asc' },
};

const byTitleDesc = {
  id: 'byTitleDesc',
  title: 'Z-A',
  params: { sort: 'title:desc' },
};

const artistByName = {
  id: 'artistByName',
  title: 'Artist',
  params: { sort: 'artist' },
};

const auctionDate = {
  id: 'auctionDate',
  title: 'Sale Date (newest to oldest)',
  params: { sort: 'auctionDate:desc' },
};

const auctionDateAsc = {
  id: 'auctionDate-asc',
  title: 'Sale Date (oldest to newest)',
  params: { sort: 'auctionDate:asc' },
};



const size = {
  id: 'size',
  title: 'Size (largest to smallest)',
  params: { sort: 'size:desc' },
};

const sizeAsc = {
  id: 'size-asc',
  title: 'Size (smallest to largest)',
  params: { sort: 'size:asc' },
};

const soldPrice = {
  id: 'soldPrice',
  title: 'Price (highest to lowest)',
  params: { sort: 'soldPrice:desc' },
};

const soldPriceAsc = {
  id: 'soldPrice-asc',
  title: 'Price (lowest to highest)',
  params: { sort: 'soldPrice:asc' },
};

const createdDate = {
  id: 'createdDate',
  title: 'Recently Added',
  params: { sort: 'createdDate' },
};

const lotNumber = {
  id: 'lotNumber-asc',
  title: 'Lot # (asc)',
  params: { sort: 'lotNumber:asc' },
};

const lotNumberDesc = {
  id: 'lotNumber-desc',
  title: 'Lot # (desc)',
  params: { sort: 'lotNumber:desc' },
};

const art = [ createdDate, artByTitle, artistByName ];
const sharedByMe = [
  { id: 'sharedByMeNewest', title: 'Newest', params: { sort: 'created,desc' }, isDefault: true },
  { id: 'sharedByMeOldest', title: 'Oldest', params: { sort: 'created,asc' } },
];
const sharedWithMe = [
  { id: 'sharedWithMeNewest', title: 'Newest', params: { sort: 'created,desc' }, isDefault: true },
  { id: 'sharedWithMeOldest', title: 'Oldest', params: { sort: 'created,asc' } },
];
const shares = [
  { id: 'sharesNewest', title: 'Newest', params: { sort: 'created,desc' }, isDefault: true },
  { id: 'sharesOldest', title: 'Oldest', params: { sort: 'created,asc' } },
];
const rtvValues = [
  { id: 'artist', title: 'Artist', params: { sort: 'artist:desc' } },
  { id: 'artist-asc', title: 'Artist ASC', params: { sort: 'artist:asc' } },
  { id: 'timestamp', title: 'Timestamp', params: { sort: 'purchaseDate:desc' }, isDefault: true },
  { id: 'timestamp-asc', title: 'Timestamp ASC', params: { sort: 'purchaseDate:asc' } },
  { id: 'value', title: 'Value', params: { sort: 'value:desc' } },
  { id: 'value-asc', title: 'Value ASC', params: { sort: 'value:asc' } },
];

export const alertLots = [auctionDate, auctionDateAsc, soldPrice, soldPriceAsc, size, sizeAsc];
const homePage = [byTitle, byTitleDesc];
const artistPage = [auctionDate, auctionDateAsc, soldPrice, soldPriceAsc, size, sizeAsc];
const saleLotsListPage = [lotNumber, lotNumberDesc, soldPrice, soldPriceAsc, size, sizeAsc];

export const defaultOrderBy = 'createdDate';

export const findById = ({ list, id }) => {
  let item = null;
  list.forEach(v => {
    if (v.id === id) {
      item = v;
    }
  });
  return item;
};

export const getDefaultSortItem = list => {
  let isDefault;
  list.forEach(v => {
    if (v.isDefault) {
      isDefault = v;
    }
  });
  let globalDefault;
  list.forEach(v => v.id === defaultOrderBy ? globalDefault = v : null);
  return isDefault || globalDefault || list[0];
};

export function findFilterFromLocation(loc) {
  const { pathname, query } = loc;
  const pathPart = pathname.split('/')[1];
  const page = loc.match.name;
  const sortList = filters[page] || filters[pathPart] || filters['art'];
  if (query.sort) {
    return sortList.find(i => i.id === query.sort) || { params: {} };
  }
  return getDefaultSortItem(sortList);
}

const filters = {
  art,
  gallery: art,
  tracking: art,
  alerts: alertLots,
  homePage,
  artistPage,
  saleLotsListPage,
  shared: art,
  sharedByMe,
  myShares: sharedByMe,
  sharedWithMe,
  rtvValues,
  shares,
};

export const excluded = ['type', 'sort', 'currency'];

export default filters;
