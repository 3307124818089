import React from 'react';
import PropTypes from 'prop-types';
import translate from 'lib/translate';
import { connect } from 'cpcs-reconnect';
import { compose } from 'redux';
import cx from 'classnames';
import { updateQueryAction } from 'domain/router/RouterAction';
import { sortSel } from 'domain/router/RouterModel';
import SortBy from 'pages/common/filters/elements/sortBy';
import { getDefaultSortItem } from 'pages/common/filters/config';
// styles
import injectSheet from 'lib/sheet';
import styles from './sheet.js';

class SharedByHeader extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    amountLabelId: PropTypes.string.isRequired,
    amount: PropTypes.number,
    sortByList: PropTypes.array.isRequired,
    updateQuery: PropTypes.func.isRequired,
    sort: PropTypes.string,
    modifier: PropTypes.string,
  };

  render() {
    const { classes, title, amountLabelId, amount, sortByList, updateQuery, sort, modifier } = this.props;
    return (
      <header className={cx(classes.SharedByHeader, 'SharedByHeader')}>
        <h1 className={cx(classes.title, modifier)} children={title} />
        <div className={classes.results}>
          <span
            children="You have "
            className={classes.resultsLabel}
          />
          <span
            children={translate(amountLabelId, amount)}
            className={classes.resultsValue}
          />
        </div>
        <div
          className={classes.actions}
        >
          <SortBy
            list={sortByList}
            onChange={(e) => updateQuery({ sort: e.target.value })}
            value={sort || getDefaultSortItem(sortByList).id}
          />
        </div>
      </header>
    );
  }
}

export default compose(
  injectSheet(styles),
  connect({
    updateQuery: updateQueryAction,
    sort: sortSel,
  }),
)(SharedByHeader);
