import React from 'react';
import PropTypes from 'prop-types';
import I from 'immutable';
import { formatPrice } from 'lib/units';
import translate from 'lib/translate';

import {
  SINGLE_RTV,
  SUBSCRIPTION_TYPE_MEMBER,
  SUBSCRIPTION_TYPE_COLLECTOR,
  SUBSCRIPTION_TYPE_PROFESSIONAL,
  SUBSCRIPTION_TYPE_CUSTOM,
  DEFAULT_RTV_PRICE,
  SUBSCRIPTION_ORDER,
} from 'domain/const';
import MostPopular from 'pages/common/mostPopular';

import sheet from './sheet';
import injectSheet from 'lib/sheet';

export const SingleRtvProduct = new I.Map({
  shortName: SINGLE_RTV,
  // price for RTV purchase
  rtvDefaultValue: DEFAULT_RTV_PRICE,
  // price for year subscription
  subscriptionValue: 0,
  // amount of ree RTV's
  freeRtvCountIncluded: 1,
});

class ProductOption extends React.PureComponent {

  static propTypes = {
    classes: PropTypes.shape({
      period: PropTypes.string.isRequired,
      ProductOption: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      price: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      mostPopular: PropTypes.string.isRequired,
    }).isRequired,
    product: PropTypes.instanceOf(I.Collection),
    onSelectProduct: PropTypes.func.isRequired,
    price: PropTypes.number,
    currentPlanType: PropTypes.string,
  };

  get name() {
    return translate('rtvSidebar.product.' + this.props.product.get('shortName'));
  }

  get price() {
    const { product, classes, price } = this.props;
    const shortName = product.get('shortName');
    return <React.Fragment>
      {
        product.get('subscriptionValue') ?
          `$${formatPrice({ value: product.get('subscriptionValue') })}`:
          `$${formatPrice({ value: price || DEFAULT_RTV_PRICE })}`
      }
      {
        !!product.get('subscriptionValue') &&
          <span
            className={classes.period}
            children={shortName === SUBSCRIPTION_TYPE_MEMBER ? ' / month' : ' / year'}
          />
      }
    </React.Fragment>;
  }

  get description() {
    const { product } = this.props;
    const freeRtvCountIncluded = product.get('freeRtvCountIncluded');
    switch (product.get('shortName')) {
      case SINGLE_RTV:
        return <b>{translate('rtvSidebar.rtvValue.amount', freeRtvCountIncluded)}</b>;
      case SUBSCRIPTION_TYPE_MEMBER:
        return <React.Fragment>
          <b>{translate('rtvSidebar.rtvValue.amountFree', freeRtvCountIncluded)}</b>
          {' '}
          (one-time credit) and unlimited Auction Sales Database searches
        </React.Fragment>;
      case SUBSCRIPTION_TYPE_COLLECTOR:
        return <React.Fragment>
          <b>{translate('rtvSidebar.rtvValue.amount', freeRtvCountIncluded)}</b>
          {' '}
          and access to Market Insights to track artist financial performance
        </React.Fragment>;
      case SUBSCRIPTION_TYPE_PROFESSIONAL:
        return <React.Fragment>
          <b>{translate('rtvSidebar.rtvValue.amount', freeRtvCountIncluded)}</b>
          {' '}
          plus everything in Collector = big savings for the market insider
        </React.Fragment>;
      default:
        return 'If you see this, contact us. NO_SUCH_PRODUCT :(';
    }
  }

  onSelectProduct = () => {
    const { onSelectProduct, product } = this.props;
    onSelectProduct(product.get('shortName'));
  }

  render() {
    const { classes, product, currentPlanType } = this.props;
    if (!product) return null;
    if (SUBSCRIPTION_ORDER[product.get('shortName')] <= SUBSCRIPTION_ORDER[currentPlanType]) {
      return null;
    }
    if (currentPlanType === SUBSCRIPTION_TYPE_CUSTOM && product.get('shortName') !== SINGLE_RTV) {
      return null;
    }
    return (
      <button
        type="button"
        onClick={this.onSelectProduct}
        value={product.get('shortName')}
        className={classes.ProductOption}
      >
        <div
          className={classes.name}
          children={this.name}
        />
        <div
          className={classes.price}
          children={this.price}
        />
        <div
          className={classes.description}
          children={this.description}
        />
        {
          product.get('shortName') === SUBSCRIPTION_TYPE_PROFESSIONAL &&
            <div className={classes.mostPopular}>
              <MostPopular />
            </div>
        }
      </button>
    );
  }
}

export default injectSheet(sheet)(ProductOption);
