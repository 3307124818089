import { takeEvery, put, fork, call, select, take } from 'redux-saga/effects';
import { formValueSelector } from 'redux-form';

import {
  showAvailableComparablesAction,
  fetchAvailableComparablesAction,
  appendAvailableComparablesAction,
  saveSelectedComparablesAction,
  fetchComparableListAction,
  revertToRecommendedComparablesAction,
  onBaseListChangeAction,
  availableSelected_sel,
  availableListPagination_sel,
} from 'domain/comparables/ComparablesModel';
import { addPopupAction, hidePopupAction } from 'domain/ui/UIActions';
import { artwork_sel } from 'domain/artworkPage/ArtworkPageModel';
import { currentArtworkSelector as ownArtwork_sel } from 'domain/ownArtwork/OwnArtworkModel';
import { routeName_sel } from 'domain/router/RouterModel';
import { SELECT_COMPARABLES_POPUP, FORM_COMPARABLE_FILTER } from 'domain/const';
import Api, { makeApiGenerator } from 'domain/api';

const fieldValue_sel = ((fieldValueSelector) => fieldName =>
  state => fieldValueSelector(state, fieldName)
)(formValueSelector(FORM_COMPARABLE_FILTER));

// api
export const fetchComparable_apiGen = makeApiGenerator({
  api: Api.getComparableArtwork,
  actionCreator: fetchComparableListAction,
});
export const fetchComparableToOwnAO_apiGen = makeApiGenerator({
  api: Api.getComparableToOwnArtwork,
  actionCreator: fetchComparableListAction,
});
const fetchAvailableComparable_apiGen = makeApiGenerator({
  api: Api.fetchAvailableComparables,
  actionCreator: fetchAvailableComparablesAction,
});
const appendAvailableComparable_apiGen = makeApiGenerator({
  api: Api.fetchAvailableComparables,
  actionCreator: appendAvailableComparablesAction,
});
const fetchAvailableComparableToOwnAO_apiGen = makeApiGenerator({
  api: Api.fetchAvailableComparablesToOwnAO,
  actionCreator: fetchAvailableComparablesAction,
});
const appendAvailableComparableToOwnAO_apiGen = makeApiGenerator({
  api: Api.fetchAvailableComparablesToOwnAO,
  actionCreator: appendAvailableComparablesAction,
});
const updateAvailableComparables_apiGen = makeApiGenerator({
  api: Api.updateSelectedComparables,
  actionCreator: saveSelectedComparablesAction,
});
const updateAvailableComparablesToOwnAO_apiGen = makeApiGenerator({
  api: Api.updateSelectedComparablesToOwnAO,
  actionCreator: saveSelectedComparablesAction,
});
const revertToRecommendedComparables_apiGen = makeApiGenerator({
  api: Api.comparablesRevertToRecommended,
  actionCreator: revertToRecommendedComparablesAction,
});
const revertToRecommendedComparablesForOwnAO_apiGen = makeApiGenerator({
  api: Api.comparablesRevertToRecommendedForOwnAO,
  actionCreator: revertToRecommendedComparablesAction,
});

function* getPageMethods() {
  const routeName = yield select(routeName_sel);
  let apiSave;
  let apiFetch;
  let apiAppendAvailable;
  let apiFetchAvailable;
  let apiRevert;
  let artwork;
  let artworkId;
  const baseList = yield select(fieldValue_sel('baseList'));
  switch (routeName) {
    case 'artworkPage':
      apiSave = updateAvailableComparables_apiGen;
      apiFetch = fetchComparable_apiGen;
      apiAppendAvailable = appendAvailableComparable_apiGen;
      apiFetchAvailable = fetchAvailableComparable_apiGen;
      apiRevert = revertToRecommendedComparables_apiGen;
      artwork = yield select(artwork_sel);
      artworkId = artwork.get('id');
      break;
    case 'collectionItemPage':
      apiSave = updateAvailableComparablesToOwnAO_apiGen;
      apiFetch = fetchComparableToOwnAO_apiGen;
      apiAppendAvailable = appendAvailableComparableToOwnAO_apiGen;
      apiFetchAvailable = fetchAvailableComparableToOwnAO_apiGen;
      apiRevert = revertToRecommendedComparablesForOwnAO_apiGen;
      artwork = yield select(ownArtwork_sel);
      artworkId = artwork.get('id');
      break;
    default:
      console.error('unsupported routeName', routeName);
  }
  return { apiSave, apiFetch, artwork, artworkId, apiFetchAvailable, apiRevert, apiAppendAvailable, baseList };
}

function* onShowAvailableComparables() {
  yield put(addPopupAction({ name: SELECT_COMPARABLES_POPUP }));
  const { apiFetchAvailable, artworkId, baseList } = yield call(getPageMethods);
  yield fork(apiFetchAvailable.catchError, { artworkId, baseList });
}

function* onSaveSelectedComparables() {
  const ids = yield select(availableSelected_sel);
  const { apiSave, apiFetch, artworkId } = yield call(getPageMethods);
  yield call(apiSave.catchError, { artworkId, ids: ids.toJS() });
  yield put(hidePopupAction());
  yield fork(apiFetch.catchError, { artworkId, size: 3 });
}

function* onRevertToRecommended() {
  const { apiRevert, artworkId, apiFetch } = yield call(getPageMethods);
  yield call(apiRevert, { artworkId });
  yield put(hidePopupAction());
  yield fork(apiFetch, { artworkId, size: 3 });
}

function* onPagination() {
  const { page, loading } = yield select(availableListPagination_sel);
  if (loading) return;
  const { apiAppendAvailable, artworkId, baseList } = yield call(getPageMethods);
  yield call(apiAppendAvailable, { artworkId, page: page + 1, baseList });
};

function* onBaseListChange() {
  yield take('@@redux-form/CHANGE');
  const { apiAppendAvailable, artworkId, baseList } = yield call(getPageMethods);
  yield fork(apiAppendAvailable.catchError, { artworkId, baseList });
}

export function* watchComparables() {
  yield takeEvery(showAvailableComparablesAction.type, onShowAvailableComparables);
  yield takeEvery(saveSelectedComparablesAction.type, onSaveSelectedComparables);
  yield takeEvery(revertToRecommendedComparablesAction.type, onRevertToRecommended);
  yield takeEvery(appendAvailableComparablesAction.type, onPagination);
  yield takeEvery(onBaseListChangeAction.type, onBaseListChange);
}
