import React from 'react';
import { compose } from 'redux';
import { connect } from 'cpcs-reconnect';
import PropTypes from 'prop-types';
import injectSheet from 'lib/sheet';
import Popup from 'components/popup';
import sheet from './sheet';
import CreateForm from 'pages/common/popupForms/create';
import { isPopupVisibleSelector } from 'domain/ui/UIModel';
import { CREATE_GALLERY_POPUP } from 'domain/const';
import { hidePopupAction } from 'domain/ui/UIActions';
import Title from 'pages/common/popup/title';

class CreateGallery extends React.PureComponent {

  static propTypes = {
    classes: PropTypes.shape({
      container: PropTypes.string,
      titleWrapper: PropTypes.string,
      close: PropTypes.string,
      title: PropTypes.string,
    }).isRequired,
    isPopupVisible: PropTypes.func.isRequired,
    cancel: PropTypes.func.isRequired,
  }

  onCancel = () => {
    this.props.cancel();
  };

  render() {
    const { classes, isPopupVisible } = this.props;
    if (!isPopupVisible(CREATE_GALLERY_POPUP)) return null;
    return (
      <Popup>
        <div className={classes.container}>
          <Title
            children="Add new gallery"
            onClose={this.onCancel}
          />
          <CreateForm cancel={this.onCancel} />
        </div>
      </Popup>
    );
  }
}

export default compose(
  connect({
    isPopupVisible: isPopupVisibleSelector,
    cancel: hidePopupAction,
  }),
  injectSheet(sheet),
)(CreateGallery);
