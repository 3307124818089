import { DEFAULT_FONTFAMILY } from 'theme/theme';

export default {
  printBtn: {
    flexShrink: 0,
    cursor: 'pointer',
    border: '1px solid transparent',
    textAlign: 'center',
    fontFamily: DEFAULT_FONTFAMILY,
    textTransform: 'uppercase',
    textDecoration: 'none',
    padding: [6, 15],
    color: '#ffffff',
    width: 320,
    height: 56,
    fontSize: 18,
    background: '#304C95',
    marginTop: 40,
    fontWeight: 600,
    '&.pdf': {
      zIndex: 3,
      position: 'fixed',
      top: 20,
      right: 50,
    },
  },
  SvgPreview: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 700,
  },
  contentWrapper: {
    height: 'auto',
  },
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 1,
  },
  configurator: {
    position: 'relative',
    width: '1550px',
    left: 50,
    zIndex: 2,
    minHeight: '300px',
    backgroundColor: '#444',
    height: '100%',
    overflow: 'hidden',
    overflowY: 'auto',
  },
  svgDoc: {
    position: 'relative',
    left: 300,
    display: 'flex',
    flexDirection: 'column',
  },
  confSvg: {
    position: 'absolute',
    top: 0,
    left: 300,
  },
  configSidebar: {
    position: 'fixed',
    top: 100,
    left: 70,
  },
};
