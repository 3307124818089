import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'cpcs-reconnect';
import cx from 'classnames';
import I from 'immutable';
import { Link } from 'react-router-dom';
import { lnk } from 'lib/routes';
import Popup from 'components/popup';
import Title from 'pages/common/popup/title';
import { categoryById as categoryByIdSel } from 'domain/category/CategoryModel';
import { checkRtvStatus } from 'lib/helpers';

import injectSheet from 'lib/sheet';
import { sheet } from './sheet.js';

const doNothing = () => null;

class ProcessingPopup extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    artwork: PropTypes.instanceOf(I.Record),
    categoryById: PropTypes.instanceOf(I.Collection),
  };

  render() {
    const { classes, artwork, categoryById } = this.props;
    const rtvStatus = checkRtvStatus({ artwork, categoryById });
    if (!artwork || !artwork.id || rtvStatus !== 'PROCESSING') return null;
    return (
      <Popup cancel={doNothing} modifier="ProcessingPopup">
        <div
          className={`${classes.container} ${classes.ProcessingPopup}`}
        >
          <Title
            children="Processing..."
            modifier="title-spinner"
          />
          <div
            className={cx(classes.description)}
            children="ARTBnk is processing your request - this will only take a moment."
          />
          <div className={classes.btnGroup}>
            <Link
              className={cx(classes.button, 'ProcessingPopup-done')}
              to={lnk('addArtUnifiedPage')}
              children="Upload more art"
            />
          </div>
        </div>
      </Popup>
    );
  }
}

export default compose(
  injectSheet(sheet),
  connect({
    categoryById: categoryByIdSel,
  }),
)(ProcessingPopup);
