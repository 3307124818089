import React from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { options1, options2, options3, options4, options5 } from './mockData';
import PatternUsage from './PatternUsage';

export function RenderCharts({ classes, refs }) {
  return (
    <div className={classes.HighchartsPage}>
      <PatternUsage />
      <svg
        x="0px"
        y="0px"
        width="600"
        height="400"
        xmlSpace="preserve"
      >
        <g>
          <rect
            x="0"
            y="0"
            width="200"
            height="200"
            rx="0"
            ry="0"
            fill="url(#photoshopCanvasBg)"
          />
        </g>
      </svg>
      <div className={classes.content}>
        <HighchartsReact highcharts={Highcharts} options={options1} ref={refs[0]} />
        <HighchartsReact highcharts={Highcharts} options={options2} ref={refs[1]} />
        <HighchartsReact highcharts={Highcharts} options={options3} ref={refs[2]} />
        <HighchartsReact highcharts={Highcharts} options={options4} ref={refs[3]} />
        <HighchartsReact highcharts={Highcharts} options={options5} ref={refs[4]} />
        {/*<div className={classes.pageMock} />*/}
      </div>
    </div>
  );
}

RenderCharts.propTypes = {
  classes: PropTypes.shape({
    HighchartsPage: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    pageMock: PropTypes.string.isRequired,
  }).isRequired,
  refs: PropTypes.array,
};
