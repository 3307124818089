import React from 'react';
import I from 'immutable';
import { compose } from 'redux';
import { artistName } from 'domain/artist/helpers';
import PropTypes from 'prop-types';
import { connect } from 'cpcs-reconnect';
import { loading } from 'domain/ui';
import SmartImage from 'components/image/SmartImage';

import injectSheet from 'lib/sheet';
import styles from './sheet.js';

const imgParams = { width: 30, height: 30, cover: true };

function Item({ suggestion, classes, onClick }) {
  const year = suggestion.getIn(['createdAt', 'year']);
  return (
    <li className={classes.item} tabIndex="100">
      <button type="button" onClick={onClick} className={classes.btn}>
        <div className={classes.thumb}>
          <SmartImage
            src={suggestion.getIn(['pictures', '0', 'name'])}
            params={imgParams}
          />
        </div>
        <div className={classes.itemDescription}>
          <span className={classes.authorName} children={artistName(suggestion.get('artist'))} />
          {' '}
          <span className={classes.authorVsWorkDelimiter}>&bull;</span>
          {' '}
          <span className={classes.workName} children={suggestion.get('title')} />
          {' '}
          {
            year ? (
              <span className={classes.workCreatedYear}>({year})</span>
            ) : null
          }
        </div>
      </button>
    </li>
  );
}

Item.propTypes = {
  classes: PropTypes.shape({
    item: PropTypes.string,
    thumb: PropTypes.string,
    btn: PropTypes.string,
    itemDescription: PropTypes.string,
    authorName: PropTypes.string,
    authorYears: PropTypes.string,
    authorVsWorkDelimiter: PropTypes.string,
    workName: PropTypes.string,
    workCreatedYear: PropTypes.string,
  }).isRequired,
  suggestion: PropTypes.oneOfType([
    PropTypes.instanceOf(I.Record),
    PropTypes.instanceOf(I.Map),
  ]),
  onClick: PropTypes.func,
};

const ItemThemed = injectSheet(styles)(Item);

class Suggestions extends React.PureComponent {

  static defaultProps = {
    list: new I.List(),
  };

  render() {
    const { classes, list, fillArtworkForm, loading } = this.props;
    return (
      <div
        className={classes.Suggestions}
      >
        <h4 className={classes.componentTitle}>
          Suggestions
          { loading && <div><div className={classes.loader} /></div> }
        </h4>

        {
          (!!list) &&
            <ul className={classes.list}>
              {
                list.map(item =>
                  <ItemThemed
                    key={item.get('id')}
                    suggestion={item}
                    onClick={() => fillArtworkForm(item)}
                  />,
                )
              }
            </ul>
        }
      </div>
    );
  }
}

Suggestions.propTypes = {
  classes: PropTypes.shape({
    Suggestions: PropTypes.string,
    list: PropTypes.string,
    componentTitle: PropTypes.string,
    loader: PropTypes.string,
  }).isRequired,
  list: PropTypes.instanceOf(I.List),
  fillArtworkForm: PropTypes.func,
  loading: PropTypes.bool,
};

export default compose(
  injectSheet(styles),
  connect({ loading }),
)(Suggestions);
