import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'cpcs-reconnect';
import I from 'immutable';
import { galleryList_sel } from 'domain/galleries/GalleryModel';
import { addArtworksAction } from 'domain/galleries/GalleryActions';
import PopupChooseOrCreate from 'pages/common/popup/chooseOrCreate';
import CreateGallery from 'pages/common/popup/SelectGallery/create';
import { isPopupVisibleSelector } from 'domain/ui/UIModel';
import { ADD_TO_GALLERY_POPUP } from 'domain/const';

class AddArtworksToGallery extends React.PureComponent {
  static propTypes = {
    galleryList: PropTypes.instanceOf(I.List).isRequired,
    addArtworks: PropTypes.func.isRequired,
    isPopupVisible: PropTypes.func.isRequired,
  };

  addArtworksToGallery = galleryId => {
    this.props.addArtworks(galleryId);
  }

  render() {
    const { galleryList, isPopupVisible } = this.props;
    if (!isPopupVisible(ADD_TO_GALLERY_POPUP)) return null;
    return (
      <PopupChooseOrCreate
        galleryList={galleryList}
        onSelect={this.addArtworksToGallery}
        title="Add to gallery"
      >
        <CreateGallery
          onClick={() => 'create gallery'}
        />
      </PopupChooseOrCreate>
    );
  }
}

export default connect({
  galleryList: galleryList_sel,
  addArtworks: addArtworksAction,
  isPopupVisible: isPopupVisibleSelector,
})(AddArtworksToGallery);
