import { call, fork, select, takeEvery  } from 'redux-saga/effects';
import { fetchComparable_apiGen } from 'domain/comparables/saga';
import { ensureArtwork_apiGen, fetchAuthor_apiGen } from 'domain/artworkPage/sagas';
import { artwork_sel } from 'domain/artworkPage/ArtworkPageModel';
import { fetchOtherArtistWorksList_apiGen } from 'domain/otherArtistWorks/sagas';
import { watchItemActions } from 'pages/common/ItemActions/saga';
import { orderRtvAction } from 'pages/common/buyRtv/buyRtvActions';
import { watchCreateAlertByArtistId, watchArtistAddedToAlertNeedRecheck } from 'domain/alerts/sagas';
import { rtvFormInit } from 'pages/common/buyRtv/saga';
import { watchComparables } from 'domain/comparables/saga';


function* updateComparables({ artworkId }) {
  yield fork(fetchComparable_apiGen.catchError, { artworkId, size: 3 });
}

export default function* watchNavigation(_, { params: { artworkId } }) {
  yield fork(watchArtistAddedToAlertNeedRecheck);
  yield fork(rtvFormInit);
  yield fork(watchItemActions);
  yield call(ensureArtwork_apiGen.catchError, { id: artworkId });
  const artwork = yield select(artwork_sel);
  if (!artwork) {
    // 404
    return;
  }
  if (artwork && artwork.artist && artwork.artist.id) {
    yield fork(watchCreateAlertByArtistId, artwork.artist.id);
  }
  if (artwork && artwork.rtvPrice) {
    yield fork(fetchComparable_apiGen.catchError, { artworkId, size: 3 });
  }
  yield takeEvery(orderRtvAction.success, updateComparables, { artworkId });
  yield call(fetchAuthor_apiGen.catchError, { id: artwork.getIn(['artist', 'id']) });
  if (artwork && artwork.artist && artwork.artist.id) {
    yield fork(fetchOtherArtistWorksList_apiGen.catchError, { artistId: artwork.artist.id, excludeId: artwork.id });
  }
  yield fork(watchComparables);
}
