import { DARK_BLUE_COLOR, DEFAULT_FONTFAMILY } from 'theme/theme';
import { PageHeader } from 'theme/Dashboard';

export default {
  SharedByHeader: PageHeader,
  title: {
    flex: [1, 1, 0],
    color: DARK_BLUE_COLOR,
    font: `700 24px/1.25em ${DEFAULT_FONTFAMILY}`,
    minWidth: 0,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    '&.sharedWithMePage': {
      overflow: 'visible',
    },
    '@media screen and (max-width: 610px)': {
      order: 1,
      flex: [0, 0, '25px'],
    },
  },
  results: {
    flex: [1, 1, 0],
    textAlign: 'center',
    '@media screen and (max-width: 610px)': {
      marginTop: 5,
      flex: '1 1 0',
      order: 2,
    },
  },
  actions: {
    flex: [1, 1, 0],
    textAlign: 'right',
    '@media screen and (max-width: 610px)': {
      marginTop: 15,
      order: 3,
    },
  },
  resultsLabel: {
    color: DARK_BLUE_COLOR,
    font: `400 14px/1.25em ${DEFAULT_FONTFAMILY}`,
  },
  resultsValue: {
    color: DARK_BLUE_COLOR,
    font: `500 14px/1.25em ${DEFAULT_FONTFAMILY}`,
  },
};
