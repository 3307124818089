import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'cpcs-reconnect';
import { compose } from 'redux';
import I from 'immutable';
import InfiniteScroll from 'react-infinite-scroller';
import { sharedByMeSel, getSharedByMePagination_sel } from 'domain/shares/ShareModel';
import { getSharedByMeAppendPageAction } from 'domain/shares/ShareActions';

import defaultList from './__mocks__/sharedByMeList';
import SharesColumns from 'pages/common/sharesTable/columns';
import SharedByHeader from 'pages/common/shares/SharedByHeader';
import PageContainer from 'pages/common/pageContainer';
import DataTable from 'pages/common/sharesTable';
import NoSharesFound from 'pages/common/NoSharesFound';
import filterList from 'pages/common/filters/config';
import Loader from 'components/loader/Loader';

const sortByList = filterList.sharedByMe;

const columns = SharesColumns.select('thumb', 'title', 'members', 'created', 'revoke');

class SharedByMePage extends React.PureComponent {
  static propTypes = {
    list: PropTypes.instanceOf(I.List),
    pagination: PropTypes.instanceOf(I.Record),
    loadMore: PropTypes.func.isRequired,
  };

  static defaultProps = {
    list: defaultList,
  };

  get loader() {
    const { pagination } = this.props;
    if (!pagination) return null;
    const modifier = pagination.loading && pagination.total > 0 ? 'infiniteScroll_LoadMore' : 'infiniteScroll_FirstPage';
    return <Loader key="loader" loading modifier={modifier} />;
  }

  render() {
    const { list, pagination, loadMore } = this.props;
    if (!pagination) return null;
    return (
      <PageContainer modifier="SharedByMePage">
        <SharedByHeader
          title="Shared by Me"
          amountLabelId="sharedByMePage.header.amount"
          amount={pagination ? pagination.total : 0}
          sortByList={sortByList}
        />
        <InfiniteScroll
          className="InfiniteScroll"
          loadMore={loadMore}
          hasMore={pagination.page < (pagination.pages - 1) || (pagination.loading && pagination.page === 0)}
          loader={this.loader}
        >
          {
            !!list.size &&
              <DataTable
                columns={columns}
                items={list}
                modifier="IE"
              />
          }
          {
            !list.size && !pagination.loading &&
              <NoSharesFound />
          }
        </InfiniteScroll>
      </PageContainer>
    );
  }
}

export const SharedByMePagePure = SharedByMePage;

export default compose(
  connect({
    list: sharedByMeSel,
    pagination: getSharedByMePagination_sel,
    loadMore: getSharedByMeAppendPageAction,
  }),
)(SharedByMePage);
