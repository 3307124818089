import { popupComponents } from 'pages/common/popup/sheet';
import { button } from 'theme/button';

export const sheet = {
  ProcessingPopup: {},
  container: {
    ...popupComponents.container,
    width: 445,
  },
  btnGroup: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 15,
    marginRight: -15,
  },
  button,
  // accept: {
  //   ...Buttons.done,
  //   fontWeight: 500,
  //   margin: [0, 5],
  // },
  description: {
    ...popupComponents.description,
  },
};
