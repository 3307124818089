import record, { listOf, integer } from 'cpcs-recordjs';
import { field } from 'cpcs-reconnect';
import { Artwork, Pagination } from 'model';
import { asyncAction } from 'lib/actions';

// actions
export const showAvailableComparablesAction = asyncAction('comparables/SHOW_AVAILABLE_COMPARABLES');
export const fetchAvailableComparablesAction = asyncAction('comparables/FETCH_AVAILABLE_COMPARABLES');
export const appendAvailableComparablesAction = asyncAction('comparables/APPEND_AVAILABLE_COMPARABLES');
export const fetchComparableListAction = asyncAction('comparables/FETCH_COMPARABLE');
export const updateAvailableComparablesSelectionAction = asyncAction('comparables/TOGGLE_ITEM_SELECTION');
export const saveSelectedComparablesAction = asyncAction('comparables/SAVE_SELECTED_COMPARABLES');
export const revertToRecommendedComparablesAction = asyncAction('comparables/REVERT_TO_RECOMMENDED');
export const onBaseListChangeAction = asyncAction('comparables/ON_BASE_LIST_CHANGE');

// State
const State = record('Comparables', {
  comparableList: listOf(Artwork),
  availableList: listOf(Artwork),
  selectedAvailable: listOf(integer),
  availableListPagination: Pagination.parse({}),
});

// selectors
const base = field('comparables');

export const comparableListSelector = base.then(State.$comparableList);
export const availableList_sel = base.then(State.$availableList);
export const availableSelected_sel = base.then(State.$selectedAvailable);
export const availableListPagination_sel = base.then(State.$availableListPagination);

const toggleIdInList = ({ id, list }) => {
  const index = list.findIndex(v => v === id);
  if (index !== -1) {
    return list.filter(v => v !== id);
  }
  return list.push(id);
};

const excludeExisting = (payload, availableList) => payload.content
  .filter(v => !availableList.findEntry(e => e.get('id') === v.id));

// reducer
export const reducer = {
  comparables(state = new State(), action) { //NOSONAR
    switch (action.type) {
      case appendAvailableComparablesAction.failure:
        return state.apply(
          State.$availableListPagination.update(v => v ? v.set('loading', false) : v),
        );
      case appendAvailableComparablesAction.request:
        return state.apply(
          State.$availableListPagination.update(
            v => v ?
              v.set('loading', true)
                .set('page', action.payload.page || 0)
              : v,
          ),
        );

      case fetchAvailableComparablesAction.success:
      case appendAvailableComparablesAction.success:
        return state.apply(
          State.$availableList.parsedBy('concat', excludeExisting(action.payload, state.availableList)),
          State.$availableListPagination.parsed(action.payload),
        );

      case updateAvailableComparablesSelectionAction.type:
        return state.apply(
          State.$selectedAvailable.update(list => toggleIdInList({ id: action.payload, list })),
        );

      case onBaseListChangeAction.type:
        return state.apply(
          State.$availableList.parsed(state.selectedAvailable.map(
            id => state.availableList.find(entry => entry.id === id),
          ).toJS()),
          State.$availableListPagination.update(
            v => v ?
              v.set('loading', true)
                .set('page', action.payload.page || 0)
              : v,
          ),
        );
      case fetchAvailableComparablesAction.request:
        return state.apply(
          State.$availableList.parsed(state.comparableList.toJS()),
          State.$availableListPagination.update(
            v => v ?
              v.set('loading', true)
                .set('page', action.payload.page || 0)
              : v,
          ),
        );

      case fetchComparableListAction.request:
        return state.apply(
          State.$comparableList.parsed([]),
        );

      case fetchComparableListAction.success:
        return state.apply(
          State.$comparableList.parsed(action.payload),
          State.$selectedAvailable.parsed(action.payload.map(v => v.id)),
        );

      default:
        return state;
    }
  },
};
