import { call, fork, put, select, take, takeEvery } from 'redux-saga/effects';
import { getOwnArtworkAction } from 'domain/ownArtwork/OwnArtworkActions';
import { pageArtworkNoMock, currentArtworkSelector } from 'domain/ownArtwork/OwnArtworkModel';
import { fetchComparableToOwnAO_apiGen } from 'domain/comparables/saga';
import { fetchOwnArtwork_apiGen } from 'domain/ownArtwork/sagas';
import { addBreadCrumbs } from 'domain/env';
import { getGalleryById } from 'domain/galleries/GalleryModel';
import { orderRtvAction } from 'pages/common/buyRtv/buyRtvActions';
import { err404Action } from 'domain/ui/UIActions';
import { watchCreateAlertByArtistId, watchArtistAddedToAlertNeedRecheck } from 'domain/alerts/sagas';
import { createRTVWatcher } from 'components/ws/saga';
// import Api, { callApi } from 'domain/api';
import { lnk } from 'lib/routes';
import { ensureGallery_apiGen } from 'domain/galleries/sagas';
import { routerParams_sel } from 'domain/router/RouterModel';
import { fetchOtherArtistWorksList_apiGen } from 'domain/otherArtistWorks/sagas';
import { watchItemActions } from 'pages/common/ItemActions/saga';
import { rtvFormInit } from 'pages/common/buyRtv/saga';
import { watchComparables } from 'domain/comparables/saga';

function* updateComparables({ artworkId }) {
  yield fork(fetchComparableToOwnAO_apiGen.catchError, { artworkId, size: 3 });
}

function* watchViewedArtwork() {
  yield take(getOwnArtworkAction.success);
  let artwork = yield select(pageArtworkNoMock);
  if (!artwork) {
    return;
  }
}

function* breadcrumbsDataWatch() {
  try {
    const { gId } = yield select(routerParams_sel);
    let gallery = (yield select(getGalleryById(gId))) || (yield call(ensureGallery_apiGen, { galleryId: gId })) ;
    if (!gallery) return;
    yield put(addBreadCrumbs([
      { entity: gallery.title, link: lnk('collectionListPage', { gId }) },
    ]));
  } catch (e) {
    console.error(e);
  }
}

export default function* collectionItemPage(_, { params: { artworkId, authorId } }) {
  yield fork(watchArtistAddedToAlertNeedRecheck);
  yield fork(rtvFormInit);
  yield fork(watchItemActions);
  yield fork(breadcrumbsDataWatch);
  yield fork(watchViewedArtwork);
  const artwork = yield call(fetchOwnArtwork_apiGen.catchError, { artworkId });
  if (!artwork) {
    yield put(err404Action(true));
    return;
  }
  if (artwork.rtvPrice) {
    yield fork(fetchComparableToOwnAO_apiGen.catchError, { artworkId, size: 3 });
  }
  if (artwork.artist && artwork.artist.id) {
    yield fork(watchCreateAlertByArtistId, artwork.artist.id);
  }
  yield takeEvery(orderRtvAction.success, updateComparables, { artworkId });
  yield fork(fetchOtherArtistWorksList_apiGen.catchError, { artistId: authorId, excludeId: artworkId });
  yield fork(createRTVWatcher({
    fetchItem: artworkId => fetchOwnArtwork_apiGen.catchError({ artworkId }),
    artworkId,
    artwork_sel: currentArtworkSelector,
    artwork,
  }));
  yield fork(watchComparables);
}
