import { useEffect, useState } from 'react';

const objectHasEmpty = (o, props) => props.reduce((prev, name) => (prev || ![o[name]].join('')), false);

export function useFetch({ ref, token, chart, chartId, apiProps, filter, onResponseSuccess, artistReport }) {
  const [resp, setResponse] = useState({});
  const { current } = ref;
  useEffect(() => {
    if (objectHasEmpty(apiProps, chart.apiProps)) {
      const getEmptyApiPropsNames = (list, o) =>
        list.reduce((prev, name) => (![o[name]].join('') ? [ ...prev, name ] : prev), []);
      const emptyApiProps = getEmptyApiPropsNames(chart.apiProps, apiProps);
      const artistPageAllowsFetchWithoutArtworkId = artistReport &&
        emptyApiProps.length === 1 && emptyApiProps[0] === 'artworkId';
      if (emptyApiProps.length > 0 && !(artistPageAllowsFetchWithoutArtworkId)) {
        console.warn('Chart::useEffect empty API props', emptyApiProps);
        return;
      }
    }
    const fetchData = async () => {
      const resp = await chart.api({ token, ...apiProps, ...filter });
      if (!resp.data || current.unmounted) return;
      let { data: series } = resp;
      if (!series) {
        console.error(`wrong resp for chart ${chartId} (no data)`, resp);
        return;
      }
      series = series.data || series;
      let drilldown;
      if (chart.updateData) {
        try {
          ({ series, drilldown } = chart.updateData({ ...resp.data, series, data: undefined }));
        } catch (err) {
          const e = new Error(`${chartId} updateData`);
          e.origin = err;
          e.method = 'updateData';
          e.chart = chart;
          e.originSeries = series;
          e.response = resp;
          console.error({ err: e });
        }
      }
      if (chart.normalize) {
        let normalizers = chart.normalize;
        if (!Array.isArray(normalizers)) {
          normalizers = [normalizers];
        }
        const prevSeries = series;
        try {
          series = normalizers.reduce((prev, n) => prev.map(n({ series })), series);
        } catch (err) {
          console.error(err, prevSeries, chartId);
          return;
        }
      }
      try {
        if (onResponseSuccess) {
          onResponseSuccess({ series });
        }
      } catch (err) {
        console.error(err);
      }
      const newResp = { drilldown, ...resp.data, series };
      delete newResp.data;
      setResponse(newResp);
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current, token, chart, chartId, artistReport, ...Object.values(apiProps), ...Object.values(filter)]);
  return resp;
}
