import React from 'react';
import PropTypes from 'prop-types';
import I from 'immutable';
import cx from 'classnames';
import { Field } from 'redux-form';
import { getId } from '../utils';
import unitsList from 'lib/units';
import { limitFractionLength } from 'components/form/normalizers';
import { rules, validator } from 'components/form/validation';
import FormRowField from 'pages/common/newPage/form/elements/FormRowField';
import Select from 'components/form/select';

import injectSheet from 'lib/sheet';
import sheet from './sheet';

const validate = {
  width: validator(rules.float),
  height: validator(rules.float),
};

function SheetSize({ name = 'sheetSize', classes, modifier, list = unitsList, disabled, required = false }) {
  const id = getId(name);
  return (
    <li className={cx(classes.formItem, modifier)}>
      <label
        htmlFor={`${id}-height`}
        className={cx(classes.label, modifier, { required })}
        children="Sheet Size"
      />
      <div className={cx(classes.fieldWrapper, modifier, 'sizeContainer')}>
        <Field
          id={`${id}-height`}
          type="text"
          name="sheetHeight"
          component={FormRowField}
          modifier={cx('sizeField', modifier)}
          placeholder="H"
          disabled={disabled}
          rootTag="div"
          normalize={limitFractionLength(3)}
          validate={validate.height}
        />
        <Field
          id={`${id}-width`}
          type="text"
          name="sheetWidth"
          component={FormRowField}
          modifier={cx('sizeField', modifier)}
          placeholder="W"
          disabled={disabled}
          rootTag="div"
          normalize={limitFractionLength(3)}
          validate={validate.width}
        />
        <Field
          id={`${id}-depth`}
          type="text"
          name="sheetDepth"
          component={FormRowField}
          modifier={cx('sizeField', modifier)}
          placeholder="D"
          disabled
          rootTag="div"
          normalize={limitFractionLength(3)}
          validate={rules.float}
        />
        <div className={cx(classes.selectWrapper, modifier, 'unitField')}>
          <Field
            id={`${id}-unit`}
            component={Select}
            modifier={modifier}
            name="sheetUnit"
            placeholder="unit"
            disabled={disabled}
            list={list}
            defaultValue={list.get(0)}
            isClearable={false}
            isSearchable={false}
          />
        </div>
      </div>
    </li>
  );
}

SheetSize.propTypes = {
  name: PropTypes.string,
  list: PropTypes.instanceOf(I.List),
  disabled: PropTypes.bool,
  classes: PropTypes.shape({
    field: PropTypes.string,
    formItem: PropTypes.string,
    label: PropTypes.string,
    fieldWrapper: PropTypes.string,
    selectWrapper: PropTypes.string,
  }).isRequired,
  required: PropTypes.bool,
  modifier: PropTypes.string,
};

export default injectSheet(sheet)(SheetSize);
