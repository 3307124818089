import { put, select, takeEvery } from 'redux-saga/effects';
import { removeSelectedAction } from 'pages/common/selectedItemsControls/actions';
import { considerToRemoveAction, TYPE_ARTWORK } from 'domain/const';
import { selectedArtworkIds, selectedArtworksSelector } from 'domain/ownArtwork/OwnArtworkModel';

function* onRemoveSelected() {
  const artworkIds = yield select(selectedArtworkIds);
  if (!artworkIds.size) return;
  const artworks = yield select(selectedArtworksSelector);
  const payload = {
    type: TYPE_ARTWORK,
    params: { ids: artworkIds.toJS() },
    name: artworks.getIn([0, 'title'], ''),
  };
  yield put(considerToRemoveAction(payload));
}

export default function* watchRemoveSelected() {
  yield takeEvery(removeSelectedAction.type, onRemoveSelected);
}
