import ico from './mgf.svg';
import { HEADER_SEARCH_BACKGROUND } from 'theme/theme';

export default {
  Search: {
    margin: [6, 25, 0, 0],
    outline: 'none',
    '@media screen and (max-width: 1291px)': {
      width: 250,
    },
    '@media screen and (max-width: 1212px)': {
      marginRight: 18,
    },
    '@media screen and (max-width: 780px)': {
      width: 200,
    },
    '@media screen and (max-width: 767px)': {
      width: 250,
    },
    '@media screen and (max-width: 560px)': {
      flex: '1 1 0',
      width: 'auto',
    },
  },
  form: {
    display: 'flex',
    alignItems: 'center',
    background: HEADER_SEARCH_BACKGROUND,
  },
  submit: {
    margin: [0, 2, 0, 13],
    border: 'none',
    appearance: 'none',
    background: `url("${ico}") scroll no-repeat center / contain`,
    backgroundSize: 'cover',
    width: 20,
    height: 20,
    display: 'inline-block',
    flex: 'none',
  },
};

