import React from 'react';
import PropTypes from 'prop-types';
import I from 'immutable';
import ItemList from 'pages/common/ItemList';
import { ARTWORK, ArtworkSource, draggable } from 'components/dnd';
import Badge from 'pages/common/ItemList/Badges/Artwork';
import { lnk } from 'lib/routes';
import { connect } from 'cpcs-reconnect';
import { considerToRemoveAction, TYPE_ARTWORK } from 'domain/const';
import { getMatch } from 'domain/router/RouterModel';
import { allWorksGalleryId_sel } from 'domain/env/EnvModel';
import { updateSelectedAction, ownArtworkAppendPageAction } from 'domain/ownArtwork/OwnArtworkActions';
import {
  artworksPagination,
  artworkList,
  selectedArtworkIds,
} from 'domain/ownArtwork/OwnArtworkModel';

const DraggableArtwork = draggable(ARTWORK, ArtworkSource)(Badge);

class ArtworkList extends React.PureComponent {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        gId: PropTypes.string,
        authorId: PropTypes.string,
      }),
    }),
    selectedIds: PropTypes.instanceOf(I.Collection).isRequired,
    allWorksGalleryId: PropTypes.number,
    onRemove: PropTypes.func.isRequired,
    pagination: PropTypes.shape({
      loading: PropTypes.bool,
      page: PropTypes.number,
      pages: PropTypes.number,
      total: PropTypes.number,
    }).isRequired,
    loadMore: PropTypes.func.isRequired,
  };

  linkBuilder = (item) => {
    const { match: { params = {} } = {}, allWorksGalleryId } = this.props;
    const gId = params.gId || allWorksGalleryId;
    const authorId = item.getIn(['artist', 'id']) || params.authorId;
    if (item.get('isDraft')) {
      return lnk('artEdit', { artworkId: item.get('id') });
    }
    return lnk('collectionItemPage', { artworkId: item.get('id'), gId, authorId });
  }

  get noItemsProps() {
    let noItemsTitle;
    // const { allWorksGalleryId, match: { params: { gId } } } = this.props;
    // if (gId && parseInt(gId, 10) !== parseInt(allWorksGalleryId, 10)) {
    //   noItemsTitle = 'Great job! We’re almost done…';
    // } else {
    //   noItemsTitle = 'Adding artwork is easy,\n' +
    //     'click below to get started';
    // }
    noItemsTitle = 'Adding artwork is easy,\n' +
         'click below to get started';
    return { noItemsTitle };
  }

  render() {
    const { loadMore, pagination } = this.props;
    const gId = this.props.match.params.gId || this.props.allWorksGalleryId;
    return (
      <ItemList
        linkBuilder={this.linkBuilder}
        Badge={DraggableArtwork}
        createRoute="addArtUnifiedPage"
        // createRouteProps={{ query: { galleryId: gId } }}
        createText="ADD NEW ART"
        selectedIds={this.props.selectedIds}
        pagination={pagination}
        loadMore={loadMore}
        {...this.noItemsProps}
        {...this.props}
        onRemove={(ids) => this.props.onRemove({ type: TYPE_ARTWORK, params: { gId, ids } })}
      />
    );
  }
}

export default connect({
  pagination: artworksPagination,
  loadMore: ownArtworkAppendPageAction,
  list: artworkList,
  selectedIds: selectedArtworkIds,
  onSelect: updateSelectedAction,
  onRemove: considerToRemoveAction,
  match: getMatch,
  allWorksGalleryId: allWorksGalleryId_sel,
})(ArtworkList);
