import { useState, useCallback } from 'react';

export function useTooltip(ref) {
  const [tooltip, setTooltip] = useState();

  const onTooltipFreeze = useCallback((lotId) => {
    const { current } = ref;
    current.freezeTooltip = lotId;
    if (current.hideTooltipTimeout) {
      clearTimeout(current.hideTooltipTimeout);
      current.hideTooltipTimeout = null;
    }
  }, [ref]);

  const onMouseOut = useCallback(() => {
    const { current } = ref;
    if (current.freezeTooltip) return;
    current.hideTooltipTimeout && clearTimeout(current.hideTooltipTimeout);
    current.hideTooltipTimeout = setTimeout(() => {
      setTooltip();
    }, 3000);
  }, [ref]);

  const onTooltipShow = useCallback((newTooltip) => {
    const { current } = ref;
    current.hideTooltipTimeout && clearTimeout(current.hideTooltipTimeout);
    current.hideTooltipTimeout = null;
    if (current.freezeTooltip && current.freezeTooltip !== newTooltip.lotId) return;
    if (current.unmounted) {
      return;
    }
    setTooltip(newTooltip);
    // current.hideTooltipTimeout && clearTimeout(current.hideTooltipTimeout);
    // current.hideTooltipTimeout = setTimeout(() => {
    //   setTooltip();
    // }, 3000);
  }, [ref]);

  const onTootipClose = useCallback(() => {
    const { current } = ref;
    setTooltip();
    current.freezeTooltip = false;
  }, [ref]);
  return { tooltip, onTootipClose, onTooltipShow, onTooltipFreeze, onMouseOut };
}
