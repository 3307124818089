/**
 * polyfill for Array.prototype.includes
 * @todo import 'core-js';
 * @todo replace babel-polyfill to core-js
 * core-js has Array.prototype.includes polyfill
**/

import 'lib/clean-code-lib';
import { SHARE_TYPE_ALERT, SHARE_TYPE_ARTWORK } from 'domain/const';
const REQUIRED = 'Recuired';

const keys = [
  'artistName',
  'category',
  'condition',
  'date',
  'surfaces',
  'substrates',
  'mediums',
  'notes',
  'pricePaid',
  'provenance',
  'signature',
  'size',
  'title',
  'valuation',
];

export function shareValidate(values, props) {
  if ([SHARE_TYPE_ALERT, SHARE_TYPE_ARTWORK].includes(props.popupParams.get('shareType'))) return null;
  if (!keys.some(key => !!values[key])) {
    let errors = { shareParams: REQUIRED };
    errors._error = { ...errors };
    return errors;
  }
  return null;
}
