/* eslint-disable no-extend-native */

const $isArray = function(v) {
  return Object.prototype.toString.call(v) === '[object Array]';
};

if (!Array.$isArray) {
  Object.defineProperty(Array, '$isArray', {
    enumerable: false,
    writable: false,
    value: $isArray,
  });
}

if (!Object.$isArray) {
  Object.defineProperty(Object, '$isArray', {
    enumerable: false,
    writable: false,
    value: $isArray,
  });
}

const $isFunction = function(v) {
  return Object.prototype.toString.call(v) === '[object Function]';
};

if (!Object.$isFunction) {
  Object.defineProperty(Object, '$isFunction', {
    enumerable: false,
    writable: false,
    value: $isFunction,
  });
}

const arrayExcludeArr = function(arr) {
  return this.filter(item => {
    let pass = true;
    for (let forbidden of arr) {
      pass = pass && item !== forbidden;
    }
    return pass;
  });
};

if (!Array.prototype.$excludeArr) {
  Object.defineProperty(Array.prototype, '$excludeArr', {
    enumerable: false,
    writable: false,
    value: arrayExcludeArr,
  });
}

if (!Array.prototype.$excludeItems) {
  Object.defineProperty(Array.prototype, '$excludeItems', {
    enumerable: false,
    writable: false,
    value: arrayExcludeArr,
  });
}

if (!Array.prototype.$excludeArgs) {
  Object.defineProperty(Array.prototype, '$excludeArgs', {
    enumerable: false,
    writable: false,
    value: function(...v) {
      return this.$excludeArr(v);
    },
  });
}
/**
 * polyfill for Array.prototype.includes
 * @todo import 'core-js';
 * @todo replace babel-polyfill to core-js
 * core-js has Array.prototype.includes polyfill
**/
if (!Array.prototype.includes) {
  Object.defineProperty(Array.prototype, 'includes', {
    enumerable: false,
    writable: false,
    value: function(v) {
      for (let item of this) {
        if (item === v) return true;
      }
      return false;
    },
  });
}
