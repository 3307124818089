import { RADIO_CHECKED, RADIO_LABEL } from 'theme/theme';
import { CHECKBOX_FOCUSED_STYLE } from 'theme/focus';

export default {
  Radio: {
    display: 'inline-block',
    '&.AOForm-isPrivate': {
      marginRight: 10,
      '&:last-child': {
        marginRight: 0,
      },
    },
    '&.disabled': {
      opacity: 0.3,
    },
    '&:focus': {
      ...CHECKBOX_FOCUSED_STYLE,
    },
    '&.advancedFilter': {
      display: 'block',
    },
  },
  input: {
    width: 0,
    height: 0,
    visibility: 'hidden',
    position: 'absolute',
    zIndex: -1,
    // '&:checked + $label:after': {
    //   backgroundColor: RADIO_CHECKED,
    // },
  },
  label: {
    display: 'flex',
    fontSize: 14,
    fontWeight: 400,
    userSelect: 'none',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    alignItems: 'center',
    color: RADIO_LABEL,
    position: 'relative',
    '&:before, &:after': {
      content: '""',
      display: 'inline-block',
      borderRadius: '50%',
    },
    '&:before': {
      width: 20,
      height: 20,
      background: '#fff',
      border: '2px solid  #e6e6e6',
      marginRight: 10,
    },
    '&:after': {
      width: 10,
      height: 10,
      background: '#fff',
      position: 'absolute',
      left: 7,
      top: 7,
    },
    '&.checked:after': {
      backgroundColor: RADIO_CHECKED,
    },
    '& .AF-option-amount': {
      display: 'none',
      color: '#BABCBF',
    },
  },
};
