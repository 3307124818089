import record, { listOf, string } from 'cpcs-recordjs';
import { field } from 'cpcs-reconnect';
import * as A from './GlobalSearchAction';
import * as env from 'domain/env/EnvActions';
import { Artist } from 'model';

const State = record('GlobalSearch', {
  term: string(''),
  list: listOf(Artist),
});


const artists = field('globalSearch');
export const artistsListSelector = artists.then(State.$list);


export const reducer = {
  globalSearch(state = new State(), action) { //NOSONAR
    switch (action.type) {

      case env.logOut.type:
        return new State();

      case A.fetchArtistListAction.success:
        return state.apply(
          State.$list.set(State.$list.type.parse(action.payload.content)),
        );
      default:
        return state;
    }
  },
};
