import record from 'cpcs-recordjs';
import { field } from 'cpcs-reconnect';
import * as A from 'domain/artworkPage/ArtworkPageActions';
import * as env from 'domain/env/EnvActions';
import { orderRtvAction } from 'pages/common/buyRtv/buyRtvActions';
import { createConsiderationAction } from 'domain/considering/ConsideringActions';
import { Artist } from 'model';
import { Artwork } from 'model';

/**
 * models
**/

export const State = record('Artworks', {
  author: Artist,
  artwork: Artwork,
});

/**
 * selectors
**/
const artworks = field('artworkPage');
export const authorSelector = artworks.then(State.$author);
export const artwork_sel = artworks.then(State.$artwork);

/**
 * reducer
**/
export const reducer = {
  artworkPage(state = new State(), action) { // NOSONAR
    switch (action.type) {
      case orderRtvAction.success:
        return state.update(State.$artwork.update(w => {
          return w ? w.apply(
            Artwork.$rtvPrice.parsed(action.payload.rtvPrice),
            Artwork.$rtvCurrency.parsed(action.payload.rtvCurrency),
            Artwork.$rtvStatus.parsed(action.payload.artwork.rtvStatus),
            Artwork.$purchasedDate.parsed(action.payload.purchasedDate),
          ) : w;
        }));

      case createConsiderationAction.success:
        return state.update(State.$artwork.update(w =>
          // @note we use w.title here, because id of considered artwork differ then id of current AO
          (w && w.title === action.payload.title) ? w.update(Artwork.$isConsideration.set(true)) : w,
        ));

      case env.logOut.type:
        return new State();

      case A.fetchAuthorAction.success:
        return state.apply(
          State.$author.parsed(action.payload),
        );

      case A.fetchArtworkAction.success:
        return state.apply(
          State.$artwork.parsed(action.payload),
        );

      default:
        return state;
    }
  },
};
