import { action, asyncAction } from 'lib/actions';

export const uploadPictureAction = asyncAction('own_artwork/UPLOAD_PICTURE');

export const createArtworkAction = asyncAction('own_artwork/CREATE_ARTWORK');
export const createSuggestedArtworkAction = asyncAction('own_artwork/CREATE_FROM_SUGGESTION');
export const updateArtworkAction = asyncAction('own_artwork/UPDATE_ARTWORK');
export const saveDraftArtworkAction = asyncAction('own_artwork/SAVE_DRAFT');

export const getOwnArtworkAction = asyncAction('own_artwork/GET_ITEM');

/* Api events (request, success, failure) */

export const removeArtworkFromGalleryAction = asyncAction('own_artwork/API_REMOVE_ARTWORK_FROM_GALLERY');

/* -- Api events (request, success, failure) -- */
export const ownArtworkAppendPageAction = asyncAction('own_artwork/APPEND_PAGE');
export const fetchOwnArtworksAction = asyncAction('own_artwork/GET_LIST');

export const addPictureAction = action('own_artwork/ADD_PICTURE');

export const setArtworkDefaultImageAction = action('own_artwork/SET_DEFAULT_IMAGE');

export const removeArtworkImageAction = action('own_artwork/REMOVE_IMAGE');

export const updateSelectedAction = action('own_artwork/UPDATE_SELECTED');

export const setSelectedAction = action('own_artwork/SET_SELECTED');

export const submitArtworkFormAction = asyncAction('own_artwork/FORM_SUBMIT');
