import {
  LAYOUT_RANGE_425_MAX,
  LAYOUT_RANGE_1024_MIN,
  LAYOUT_RANGE_1024_MAX,
} from 'theme/layout/LAYOUT';

export const fieldWrapper = {
  width: 295,
  position: 'relative',
  '&.AOForm.artObjCreatedAt, &.AOForm.artObjConceptionDate': {
    width: '62px',
  },
  '&.report_calculate': {
    marginTop: 20,
    width: '100%',
  },
  '&.editionSize': {
    width: 110,
  },
  '&.provenance-date': {
    width: 70,
    '&.fromDate': {
      marginRight: 20,
    },
  },
  '&.acquisition': {
    width: 120,
  },
  '&.signature': {
    paddingRight: 30,
  },
  '&.rtv-form-coupon': {
    paddingTop: 5,
    width: '100%',
    '&.upgrade-subscription': {
      marginTop: 10,
    },
  },
  '&.advancedFilter-Range.sizeUnitField': {
    width: '100%',
  },
  '&.artistLifeDates': {
    display: 'flex',
    justifyContent: 'space-between',
  },
  '&.sizeContainer': {
    display: 'flex',
    justifyContent: 'space-between',
  },
  '&.NewAuthorForm': {
    width: 225,
  },
  '&.salesHistory-priceSold': {
    display: 'flex',
  },
  '&.LiquidityIndexes': {
    width: '100%',
  },
  '&.ResetPage, &.ForgotPageEmail, &.authPageField': {
    width: '100%',
  },
  [`@media screen and (max-width: ${LAYOUT_RANGE_1024_MAX}px) and (min-width: ${LAYOUT_RANGE_1024_MIN}px)`]: {
    '&.AOForm': {
      marginTop: 5,
      '&.AOFormStamps': {
        marginTop: 0,
      },
      '&.provenance-date': {
        marginTop: 0,
      },
    },
  },
  // NEW_ART_PAGE
  [`@media screen and (max-width: ${LAYOUT_RANGE_425_MAX}px)`]: {
    marginTop: 4,
  },
};
