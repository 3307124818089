import I from 'immutable';
import {
  formValueSelector as customFieldsSelCreator,
  getFormInitialValues as formInitialValuesSelCreator,
  getFormValues as formDataSelCreator,
} from 'redux-form';
import { objectIsNotEmpty } from 'lib/helpers';
import { categoryById as categoryById_sel } from 'domain/category/CategoryModel';
import { select, selector } from 'cpcs-reconnect';
import { artistById as artistById_sel } from 'domain/artist/ArtistModel';
import { dateStructToString } from 'lib/helpers';

import { FORM_ADD_ART_ITEM } from 'domain/const';

export const similarSearchFieldArr = [
  'artist',
  'category',
  'createdAt',
  'substrates',
  'mediums',
  'surfaces',
  'signature',
  'title',
  'pictures',
  'isCircular',
];

// import {  } from 'pages/common/artworkForm/ArtworkFormModel';
const formFields_sel = customFieldsSelCreator(FORM_ADD_ART_ITEM);
export const fieldsValuesForSimilarSearch_sel = select(state => formFields_sel(state, ...similarSearchFieldArr));
const formFieldValue_sel = fieldName => state => formFields_sel(state, fieldName);

export const artworkFormPictures_sel = select(formFieldValue_sel('pictures'));
export const notAccurate_sel = select(formFieldValue_sel('notAccurate'));
export const category_sel = select(formFieldValue_sel('category'));
export const edition_sel = select(formFieldValue_sel('edition'));
export const stamps_sel = select(formFieldValue_sel('stamps'));
export const docs_sel = select(formFieldValue_sel('docs'));
export const privateArtworkPictures_sel = select(formFieldValue_sel('privatePictures'));
// one of [PRIVATE, AUCTION, APPRAISAL]
export const appraisalPriceType_sel = select(formFieldValue_sel('userPrice.priceType'));

const finiteOrUndefined = v => isFinite(parseInt(v, 10)) ? parseInt(v, 10) : void 0;
export const diedIn_sel = selector(formFieldValue_sel('diedIn'), finiteOrUndefined);
export const bornIn_sel = selector(formFieldValue_sel('bornIn'), finiteOrUndefined);


const checkCategoryFor3D = (list, id) => !!(id && list.getIn([parseInt(id, 10), 'title'], '').includes('3D'));
export const is3D_sel = selector(categoryById_sel, formFieldValue_sel('category'), checkCategoryFor3D);


const formInitialValues_sel_noDefault = formInitialValuesSelCreator(FORM_ADD_ART_ITEM);
const formInitialValues_sel = select(state => formInitialValues_sel_noDefault(state) || {});

export const fromSuggestions_sel = formInitialValues_sel.then(data => !!data.artWork);
export const artworkTitleInitial_sel = formInitialValues_sel.then(data => data.title || '');

const formData_sel_noDefault = formDataSelCreator(FORM_ADD_ART_ITEM);
export const formData_sel = select(state => (formData_sel_noDefault(state) || {}));

export const salesHistoryEditable_sel = (state, { index, namePrefix = 'salesHistory' }) => {
  const data = formInitialValues_sel(state)[namePrefix];
  return data[index] ? data[index].editable : true;
};

export const provenanceEditable_sel = (state, { index }) => {
  const data = formInitialValues_sel(state).provenance;
  return data[index] ? data[index].editable : true;
};

export const salesHistoryIsPrivate_sel = (state, { index, namePrefix = 'salesHistory' }) => {
  const list = formFields_sel(state, namePrefix);
  return list && list[index] && list[index].isPrivate;
};

export const salesHistoryPrivateLabel_sel = (state, { index, namePrefix = 'salesHistory' }) => {
  const list = formFields_sel(state, namePrefix);
  return list && list[index] && list[index].privateLabel;
};

export const salesHistoryNotEmpty_sel = (state, { index }) => {
  return objectIsNotEmpty(formFields_sel(state, 'salesHistory')[index]);
};



/**
 * if in form - than plain JS Array/Object
 * if on detail page - records
 * early acquisition and upcomingSale was lists in form! but objects in AO model
**/
const fieldValueChecker = {
  provenance: (list = []) => {
    for (let item of list) {
      if ([item.city, item.country, item.from, item.to, item.owner].join('')) return true;
    }
    return false;
  },
  salesHistory: (list = [], data) => {
    for (let item of list) {
      const notEmpty = [item.auction, item.priceSold && item.priceSold.value, item.soldDate].join('');
      const notTheLot = !(data && data.lot && data.lot.saleAt === item.soldDate);
      if (notEmpty && notTheLot) return true;
    }
    return false;
  },
  upcomingSale: (item = {}, data) => {
    const notEmpty = [
      item.soldDate,
      item.seller,
      item.estimatedPriceStart && item.estimatedPriceStart.value,
      item.estimatedPriceEnd && item.estimatedPriceEnd.value,
    ].join('');
    const notTheLot = !(data && data.lot && data.lot.saleAt === item.soldDate);
    if (notEmpty && notTheLot) return true;
    return false;
  },
};
const fieldHasValue = (field, v, data) => {
  if (fieldValueChecker[field]) return fieldValueChecker[field](v, data);
  if (Object.$isArray(v)) return !!v.join('');
  if (I.List.isList(v)) return v.size;
  return !!v;
};
/**
 * fields: ['fName', ...] || [{ name: 'fName', ...}, ...]
**/
export const oneOfFieldHasValue = (data, fields) => {
  for (let field of fields) {
    const name = (field && field.name) || field;
    if (fieldHasValue(name, data[name], data)) return true;
  }
  return false;
};
export const oneOfFieldHasValue_sel = formData_sel.then(data => (...fields) => oneOfFieldHasValue(data, fields));

/**
 * editable artist form
**/
export const selectedArtistData_sel = selector(
  formFieldValue_sel('artist'),
  artistById_sel,
  (id, list) => list.get(parseInt(id, 10)),
);

const artistToJS = data => data
  .toMap()
  .set('bornIn', dateStructToString(data.get('bornIn')))
  .set('diedIn', dateStructToString(data.get('diedIn')))
  .toJS();

export const editArtistFormData_sel = selector(
  selectedArtistData_sel,
  data => data ? artistToJS(data) : { residence: [] },
);
export const artistEditable_sel = selector(selectedArtistData_sel, artist => !!(artist && artist.get('editable')));
