import { put, select, call, takeEvery, fork, take } from 'redux-saga/effects';

import { getShare_apiGen, getSharedArtwork_apiGen, getShareDirectionBreadcrumb } from 'domain/shares/sagas';
import { getSharedArtworkAction } from 'domain/shares';
import { err404Action } from 'domain/ui';
import * as M from 'domain/shares/ShareModel';
import * as A from 'domain/shares/ShareActions';
import { rtvFormInit } from 'pages/common/buyRtv/saga';
import { watchCreateAlertByArtistId, watchArtistAddedToAlertNeedRecheck } from 'domain/alerts/sagas';

import { addBreadCrumbs } from 'domain/env';

function* breadcrumbsDataWatch() {

  let share = yield select(M.getCurrentShareSelector);
  if (!share) {
    yield take(A.getShareAction.request);
    share = yield select(M.getCurrentShareSelector);
  }
  if (!share) return;
  const shareDirectionBC = yield call(getShareDirectionBreadcrumb, share);

  yield put(addBreadCrumbs([
    shareDirectionBC,
  ]));
}

function* show404ErrorPage() {
  yield put(err404Action(true));
}

export default function* watchNavigation(_, match) {
  yield fork(watchArtistAddedToAlertNeedRecheck);
  yield fork(rtvFormInit);
  const { shareId, artworkId } = match.params;
  const artworkIdInt = parseInt(artworkId, 10);
  const shareIdInt = parseInt(shareId, 10);
  if (isNaN(artworkIdInt) || isNaN(shareIdInt)) {
    yield call(show404ErrorPage);
    return;
  }

  yield takeEvery(getSharedArtworkAction.failure, show404ErrorPage);
  yield fork(getShare_apiGen.catchError, { id: shareIdInt });
  const artwork = yield call(getSharedArtwork_apiGen.catchError, { shareId: shareIdInt, artworkId: artworkIdInt });
  if (artwork && artwork.artist && artwork.artist.id) {
    yield fork(watchCreateAlertByArtistId, artwork.artist.id);
  }
  yield put(A.sharedArtworkViewedAction({ shareId: shareIdInt, artworkId: artworkIdInt }));
  yield call(breadcrumbsDataWatch);
}
