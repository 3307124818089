import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'cpcs-reconnect';
import { compose } from 'redux';
import { Link } from 'react-router-dom';
import { lnk } from 'lib/routes';
import { artistName, fullName } from 'domain/artist/helpers';
import { createByArtistIdAction } from 'domain/alerts/AlertsActions';
import { artistInAlertSel } from 'domain/alerts/AlertsModel';
import Hint from 'pages/common/selectedItemsControls/hint';
import { DEFAULT_FONTFAMILY, DARK_BLUE_COLOR } from 'theme/theme';
import injectSheet from 'lib/sheet';
// svg
import addAlertBg from 'pages/common/newPage/form/images/plus-in-circle.svg';
import addAlertBgChecked from 'pages/common/artworkView/solvers/images/circle-checked.svg';


function AuthorBlock({ to, children, className, classes, data, createAlert, artistInAlert }) {
  const hasConfirmedArtist = data && data.artist && data.artist.id && !data.artist.editable;
  return (
    <div
      className={classes.AuthorBlock}
    >
      <Link
        to={to}
        children={children}
        className={cx(className, classes.link)}
      />
      {
        hasConfirmedArtist && artistInAlert !== 'LOADING' &&
          <Hint text={artistInAlert === 'FALSE' ? 'add auction alert' : 'auction alert added'} modifier="addAlertByArtistId">
            <button
              type="button"
              className={cx(classes.addAlertBtn, { checked: artistInAlert === 'TRUE' })}
              onClick={() => (artistInAlert === 'FALSE' ? createAlert(data.artist) : null)}
              disabled={artistInAlert === 'SUBMITTING'}
            />
          </Hint>
      }
    </div>
  );
}

AuthorBlock.propTypes = {
  to: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  classes: PropTypes.shape({
    link: PropTypes.string,
    AuthorBlock: PropTypes.string,
    addAlertBtn: PropTypes.string,
  }).isRequired,
  data: PropTypes.object,
  createAlert: PropTypes.func.isRequired,
  artistInAlert: PropTypes.string,
};

const sheet = {
  AuthorBlock: {
    display: 'flex',
  },
  link: {
    font: `500 16px/22px ${DEFAULT_FONTFAMILY}`,
    textDecoration: 'underline',
    color: DARK_BLUE_COLOR,
  },
  addAlertBtn: {
    width: 22,
    height: 22,
    marginLeft: 8,
    cursor: 'pointer',
    background: {
      image: `url("${addAlertBg}")`,
      repeat: 'no-repeat',
      position: 'center',
      size: '18px',
    },
    '&.checked': {
      backgroundImage: `url("${addAlertBgChecked}")`,
    },
    '&:disabled': {
      opacity: 0.5,
    },
  },
};

const AuthorBlockTheme = compose(
  injectSheet(sheet),
  connect({
    createAlert: createByArtistIdAction,
    artistInAlert: artistInAlertSel,
  }),
)(AuthorBlock);

const artistSolver = (v, data, name, props) => {
  const { noArtistLink } = props;
  if (noArtistLink) {
    return fullName(v);
  }
  const text = artistName(v);
  if (!text) return '';
  if (!data || !data.artist || !data.artist.id) return text;
  return (
    <AuthorBlockTheme
      to={lnk('artistPage', { authorId: data.artist.id } )}
      children={text}
      className="textLink"
      data={data}
    />
  );
};

artistSolver.propTypes = {
  noArtistLink: PropTypes.bool,
};

export default artistSolver;
