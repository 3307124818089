import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { APP_FORMAT } from 'lib/helpers';
import { Field, formValueSelector } from 'redux-form';
import rules, { validator } from 'components/form/validation';
import { RadioBox } from 'pages/common/newPage/form/elements/RadioBox';
import FormRowField from 'pages/common/newPage/form/elements/FormRowField';
import Price from 'pages/common/newPage/form/about/price';
import {
  salesHistoryNotEmpty_sel,
  salesHistoryEditable_sel,
  salesHistoryIsPrivate_sel,
  salesHistoryPrivateLabel_sel,
} from 'pages/common/artworkForm/ArtworkFormModel';
import { connect } from 'react-redux';
import { FORM_ADD_ART_ITEM } from 'domain/const';
import Autocomplete from 'components/form/select';
import { connect as reconnect } from 'cpcs-reconnect';
import { compose } from 'redux';
import { groupedAuctions as auctionList } from 'domain/auction';
import DateField from 'components/form/DateField';
import { emptySalesHistory } from 'domain/ownArtwork/artworkSerializer';
import { today } from 'lib/envGlobals';
import {
  formatEditable,
  formatEditableDecimal,
  normalize,
  normalizeDecimal,
} from 'components/form/normalizers';

import injectSheet from 'lib/sheet';
import sheet from './sheet';


const IS_PRIVATE_OPTIONS = [{ value: 'yes', label: 'Private' }, { value: 'no', label: 'Auction' }];

class HistoryField extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    isEditable: PropTypes.bool,
    isNotEmpty: PropTypes.bool,
    index: PropTypes.number,
    isPrivate: PropTypes.string,
    fields: PropTypes.shape({
      push: PropTypes.func,
      remove: PropTypes.func,
      length: PropTypes.number,
    }),
    auctionList: PropTypes.array.isRequired,
    novalidation: PropTypes.bool,
    showEstimatedPrice: PropTypes.bool,
    modifier: PropTypes.string,
    fixDropdownPadding: PropTypes.bool,
    currency: PropTypes.string,
    privateLabel: PropTypes.bool,
  };

  validate = {
    soldDate: (...args) => {
      const { novalidation, isNotEmpty, showEstimatedPrice } = this.props;
      if (showEstimatedPrice) return rules.salesHistoryFutureDate(...args);
      if (novalidation || !isNotEmpty) return undefined;
      return validator(rules.required, rules.date)(...args);
    },
    seller: rules.strMaxLength(255),
    priceSold: rules.priceSold,
    estimatedPriceEnd: (val, data, props, fieldName) => {
      const minFieldName = fieldName.replace('estimatedPriceEnd', 'estimatedPriceStart');
      return rules.greaterThanFormValue(minFieldName)(val, data, props, fieldName);
    },
  };

  render() {
    const {
      index, isPrivate, privateLabel, isEditable,
      auctionList, classes, fields, modifier,
      showEstimatedPrice, fixDropdownPadding,
      currency,
    } = this.props;
    const disabled = !isEditable;
    const normalizer = currency === 'BTC' ? normalizeDecimal : normalize;
    const formatter = currency === 'BTC' ? formatEditableDecimal : formatEditable;
    return (
      <div className={cx(classes.HistoryField, { showEstimatedPrice })}>
        <div className={cx(classes.formItem, modifier, 'salesHistory FirstRow soldDateAndIsPrivate')}>
          <Field
            type="text"
            name={`salesHistory.${index}.soldDate`}
            component={FormRowField}
            placeholder={APP_FORMAT}
            validate={this.validate.soldDate}
            modifier={cx('salesHistory dateField', modifier)}
            disabled={disabled}
            Field={DateField}
            rootTag="div"
            maxDate={showEstimatedPrice ? undefined : today()}
          />
          <Field
            component={RadioBox}
            name={`salesHistory.${index}.isPrivate`}
            values={IS_PRIVATE_OPTIONS}
            modifier={cx('AOForm-isPrivate', modifier)}
            disabled={disabled}
          />
        </div>
        {
          (isPrivate === 'no' || (privateLabel && !isEditable)) ?
            <Field
              component={FormRowField}
              Field={Autocomplete}
              id={`salesHistory-${index}-auction`}
              name={`salesHistory.${index}.auction`}
              placeholder="Auction"
              list={auctionList}
              grouped
              disabled={disabled}
              modifier={cx('salesHistorySellerSelector', modifier)}
              rootTag="div"
              fixDropdownPadding={fixDropdownPadding}
            /> :
            <Field
              type="text"
              name={`salesHistory.${index}.seller`}
              component={FormRowField}
              placeholder="Name of the seller"
              validate={this.validate.seller}
              rootTag="div"
              modifier={cx('salesHistorySellerName', modifier)}
            />
        }
        {
          isEditable && fields.length !== -1 &&
            <div className={cx(classes.buttonContainer, 'HistoryField', 'remove', 'desktop')}>
              <button
                type="button"
                onClick={() => fields.remove(index)}
                className={cx(classes.button, classes.remove)}
                children=" "
                title="remove sale"
              />
            </div>
        }
        <div className={classes.priceBox}>
          <ul>
            {
              showEstimatedPrice &&
                <li className={cx(classes.formItem, modifier)}>
                  <div className={cx(classes.range)}>
                    <Field
                      type="text"
                      id={`salesHistory.${index}.estimatedPriceStart.value`}
                      name={`salesHistory.${index}.estimatedPriceStart.value`}
                      component={FormRowField}
                      placeholder="price from"
                      format={formatter}
                      normalize={normalizer}
                      rootTag="div"
                      modifier={cx('priceField salesHistoryEstimatedPrice', modifier)}
                      disabled={disabled}
                    />
                    <Field
                      type="text"
                      id={`salesHistory.${index}.estimatedPriceEnd.value`}
                      name={`salesHistory.${index}.estimatedPriceEnd.value`}
                      component={FormRowField}
                      placeholder="price to"
                      validate={this.validate.estimatedPriceEnd}
                      format={formatter}
                      normalize={normalizer}
                      rootTag="div"
                      modifier={cx('priceField salesHistoryEstimatedPrice', modifier)}
                      disabled={disabled}
                    />
                  </div>
                </li>
            }
            <Price
              isEditable={isEditable}
              disabled={disabled}
              name={`salesHistory.${index}.priceSold.value`}
              currencyName={`salesHistory.${index}.currency`}
              modifier={cx(modifier, 'salesHistory-priceSold')}
              validate={this.validate.priceSold}
            />
          </ul>
          <div className={cx(classes.buttonContainer, 'HistoryField', 'add')}>
            <button
              type="button"
              onClick={() => fields.remove(index)}
              className={cx(classes.button, classes.remove, 'mobile-XS')}
              children=" "
              title="remove sale"
            />
            {
              index === fields.length - 1 &&
                <button
                  type="button"
                  onClick={() => fields.length < 20 && fields.push(emptySalesHistory())}
                  className={cx(classes.button, classes.add)}
                  children=" "
                  title="add sale"
                />
            }
          </div>
        </div>
      </div>
    );
  }
}

const selector = formValueSelector(FORM_ADD_ART_ITEM);

export default compose(
  injectSheet(sheet),
  reconnect({
    auctionList,
  }),
  connect((state, props) => ({
    isPrivate: salesHistoryIsPrivate_sel(state, props),
    privateLabel: salesHistoryPrivateLabel_sel(state, props),
    isEditable: salesHistoryEditable_sel(state, props),
    isNotEmpty: salesHistoryNotEmpty_sel(state, props),
    // salesHistory.0.estimatedPriceEnd.value
    currency: selector(state, `salesHistory.${props.index}.currency`),
  })),
)(HistoryField);
