// export const FILTERS_Z_INDEX = 1;
export const BADGE_Z_INDEX = 2;
export const SELECTABLE_ITEM_CONTROLS_Z_INDEX = 2;
export const PROCESSING_POPUP_Z_INDEX = 2;
export const BADGE_FILLET_Z_INDEX = 3;
export const SIDEBAR_Z_INDEX = 4;
export const HEADER_Z_INDEX = 6;
export const SELECTED_ITEMS_CONTROLS_Z_INDEX = 7; /* Red actions header */
export const FOOTER_Z_INDEX = 8;
export const ABV_COUNTDOWN_BANNER_Z_INDEX = 9; /* AbvCountdownBanner */
export const PAGINATION_Z_INDEX = 9;
export const UNDO_BALLON_Z_INDEX = 10;
export const BUY_RTV_OVERLAY_Z_INDEX = 11;
export const BUY_RTV_SIDEBAR_Z_INDEX = 12;
export const AUTOCOMPLETE_WIDGET_Z_INDEX = 15;
export const CALENDAR_Z_INDEX = 15;
export const PDF_MODAL = 500;
export const POPUP_Z_INDEX = 501;
export const DOWNLOADING_PDF_MODAL = 502;
