const nodeMock = { offsetLeft: 0, offsetWidth: 0 };

const getScrollDistance = (children, visibleWidth) =>
  [].slice.call(children).reduce((prev, e) => {
        if (e.offsetLeft - 32 > visibleWidth) return prev;
        return e.offsetLeft;
      }, 0);

const sumChildrenWidth = (children) => {
  const lastSlide = (children.item(children.length - 1) || nodeMock);
  return lastSlide.offsetLeft + lastSlide.offsetWidth;
};

export const precountParams = node => {
  const children = node.querySelectorAll('li');
  const visibleWidth = node.offsetWidth;
  const ribbonWidth = sumChildrenWidth(children);
  return {
    children,
    ribbonWidth,
    visibleWidth,
    /**
     * count best distance to scroll items to not cut left slide
    **/
    scrollDistance: (
      ribbonWidth <= visibleWidth ? 0 : getScrollDistance(children, visibleWidth)
    ),
  };
};

export const getItemOffsetLeft = (children, index) =>
  [].slice.call(children).reduce((prev, e, key) => {
      if (key === index) return e.offsetLeft;
      return prev;
    }, 0);

/**
 * counts left and right points to scroll to in pixels
**/
export const countScrollPoints = (node, position) => {
  const ret = {
    left: 0,
    right: 0,
  };

  if (!node) return ret;

  const {
    ribbonWidth,
    /**
     * 0 or best distance to not cut items left side
     * 0 if all items are visible
    **/
    scrollDistance: delta,
  } = precountParams(node);

  if (delta === 0) return ret;

  /* left position */
  const left = Math.max(position - delta, 0);

  const preCalculatedRight = position + delta;

  /* right position */
  const right = (preCalculatedRight > ribbonWidth) ? position : preCalculatedRight;

  return {
    left,
    right,
  };
};
