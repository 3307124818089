import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import I from 'immutable';
import { connect } from 'cpcs-reconnect';
import { compose } from 'redux';
import Highcharts from 'highcharts/highstock';
import e from 'highcharts/modules/exporting';
import eo from 'highcharts/modules/offline-exporting';

import 'highcharts/modules/data';
import 'highcharts/modules/drilldown';
import 'highcharts/modules/accessibility';

import { auctionsById } from 'domain/auction/AuctionModel';

import { SET_ARTIST_LIQUIDITY } from 'pages/highcharts/helpers/PDFConstants';
import { usePDFEvents } from 'pages/highcharts/helpers/EventsContext';

import Tabs from 'components/Tabs/Tabs';
import { ArtistRankings } from 'components/reports/ArtistRankings';
import { CAGRProjectedValue } from 'components/reports/CAGRProjectedValue';
// import { TotalLotsSold } from 'components/reports/TotalLotsSold';
import { Chart } from 'components/reports/Chart/Chart';
import ArtistFinancialPerformanceMetrics from 'components/reports/ArtistFinancialPerformanceMetrics';
import PreloadingTabsDefault from 'components/reports/PreloadingTabs';
// import { LiquidityIndexes } from 'components/reports/LiquidityIndexes';
import { openedState } from 'components/reports/OpenedState';
import { ILLIQUID } from 'domain/const';

import { DEFAULT_FONTFAMILY_3 } from 'theme/theme';
import injectSheet from 'lib/sheet';


e(Highcharts);
eo(Highcharts);
Highcharts.setOptions({
  lang: {
      // Pre-v9 legacy settings
    rangeSelectorFrom: 'From',
    rangeSelectorTo: 'To',
    rangeSelectorZoom: '',
    thousandsSep: ',',
  },
});

const DEV = process.env.NODE_ENV === 'developement' || process.env.NODE_ENV === 'test';

// eslint-disable-next-line no-unused-vars
const PreloadingTabs = openedState(PreloadingTabsDefault);

const sheet = {
  ReportsArtist: {
    marginTop: 45,
    paddingBottom: 128,
  },
  title: {
    font: `700 24px/32px ${DEFAULT_FONTFAMILY_3}`,
    color: '#000',
    marginTop: 66,
  },
  description: {
    font: `400 16px/24px ${DEFAULT_FONTFAMILY_3}`,
    color: '#737992',
    '&.indexes': {
      width: 570,
      '@media screen and (max-width: 590px)': {
        width: 'auto',
      },
    },
  },
  tabContent: {
    display: 'none',
    '&.isActive': {
      display: 'block',
    },
  },
};

const TABS = [
  { title: 'Financial Performance' },
  { title: 'Market Movements' },
  { title: 'Artist Rankings' },
  { title: 'ARTBnk Indexes' },
];

const TABS_HASH = {
  FINANCIAL_PERFORMANCE_ANALYSIS: TABS[0],
  MARKET_MOVEMENTS: TABS[1],
  ARTIST_RANKING: TABS[2],
  ARTBNK_INDEXES: TABS[3],
};


function ReportsArtist({ classes, artistId, auctionList }) {
  const PDFEvents = usePDFEvents();
  const [liquidity, setLiquidity] = useState({});
  const [activeTab, setActiveTab] = useState(TABS_HASH.FINANCIAL_PERFORMANCE_ANALYSIS);
  const onSetLiquidity = useCallback((liquidityStr) => {
    PDFEvents.dispatch(SET_ARTIST_LIQUIDITY, liquidityStr);
    setLiquidity({ liquidity: liquidityStr, artistId });
  }, [setLiquidity, artistId, PDFEvents]);
  // eslint-disable-next-line no-unused-vars
  const previewProps = useCallback((chartId, title, props) => ({
    props: { artistId, chartId, auctionList, artistReport: true, ...props },
    Component: Chart,
    title,
  }), [artistId, auctionList]);

  if (!artistId) {
    return null;
  }

  const hideIlliquid = !liquidity || !liquidity.liquidity || liquidity.liquidity === ILLIQUID;

  return (
    <div className={cx('PAGE_SECTION ReportsArtist', classes.ReportsArtist)}>
      <ArtistFinancialPerformanceMetrics
        artistId={artistId}
        onSetLiquidity={onSetLiquidity}
        modifier="artistReport"
        artistReport
      />
      <Tabs
        items={TABS}
        onTabSelect={setActiveTab}
        defatultTab={TABS_HASH.FINANCIAL_PERFORMANCE_ANALYSIS}
        modifier="ReportsArtist__subTabs"
      />
      <div className={cx(classes.tabContent, { isActive: activeTab === TABS_HASH.FINANCIAL_PERFORMANCE_ANALYSIS })}>
        <h3 className={classes.title}>Financial Performance Analysis</h3>
        <p className={classes.description}>This section provides an in-depth look into an artist’s financial market <br />performance through advanced metrics using the repeat sales methodology.</p>
        {
          !hideIlliquid &&
            <CAGRProjectedValue chartId="p001" artistId={artistId} liquidity={liquidity} artistReport />
        }
        {
          (!hideIlliquid) &&
            <PreloadingTabs>
              {
                [
                  previewProps('p002', 'CAGR vs Holding Period', { liquidity, auctionList }),
                  previewProps('p015', 'vs Purchase Year'),
                  previewProps('p016', 'vs Sale Year'),
                ]
              }
            </PreloadingTabs>
        }
        <Chart chartId="p003" artistId={artistId} artistReport hideIlliquid liquidity={liquidity} />
        <Chart chartId="p005" artistId={artistId} artistReport />
        <PreloadingTabs>
          {
            [
              previewProps('p006', 'Average Price by Year Created'),
              previewProps('p007', 'by Auction Year'),
            ]
          }
        </PreloadingTabs>
        <Chart chartId="p010" artistId={artistId} artistReport />
        <Chart chartId="p012" artistId={artistId} artistReport auctionList={auctionList} />
      </div>
      <div className={cx(classes.tabContent, { isActive: activeTab === TABS_HASH.MARKET_MOVEMENTS })}>
        <h3 className={classes.title}>Market Movements</h3>
        <p className={classes.description}>This section provides an overview of the artist’s market activity over the past decade.</p>
        <PreloadingTabs>
          {
            [
              previewProps('m001', 'Total Volume by Year'),
              DEV && previewProps('m002', 'by Medium'),
              previewProps('m003', 'by  Price Segment'),
              DEV && previewProps('m004', 'by Auction House'),
              DEV && previewProps('m008', 'by Auction House (%)'),
            ]
          }
        </PreloadingTabs>
        <PreloadingTabs>
          {
            [
              previewProps('m010', 'Total Lots Sold'),
              DEV && previewProps('m011', 'by Medium'),
              previewProps('m012', 'by Price Segment'),
              DEV && previewProps('m013', 'by Auction House'),
              DEV && previewProps('m016', 'by Auction Year'),
            ]
          }
        </PreloadingTabs>
        <Chart chartId="m018" artistId={artistId} artistReport />
      </div>
      <div className={cx(classes.tabContent, { isActive: activeTab === TABS_HASH.ARTIST_RANKING })}>
        <ArtistRankings artistId={artistId} liquidity={liquidity} />
      </div>
      <div className={cx(classes.tabContent, { isActive: activeTab === TABS_HASH.ARTBNK_INDEXES })}>
        <h3 className={classes.title}>ARTBnk Indexes</h3>
        <p className={cx(classes.description, 'indexes')}>ARTBnk Indexes are based on the repeat sales methodology first used in the Case-Shiller Real Estate Index and then applied to the art market in 2002 by Jianping Mei, PhD and current ARTBnk Senior Advisor, Michael Moses, PhD. ARTBnk’s suite of indexes can be used to compare the financial performance of the art market as a whole and its sub-categories against other asset markets.</p>
        <PreloadingTabs>
          {
            [
              previewProps('x001', 'All'),
              previewProps('x001.10', '10y period'),
              previewProps('x001.5', '5y period'),
              previewProps('x001.1', '1y period'),
            ]
          }
        </PreloadingTabs>

        <PreloadingTabs>
          {
            [
              previewProps('x003', 'All'),
              previewProps('x003.10', '10y period'),
              previewProps('x003.5', '5y period'),
              previewProps('x003.1', '1y period'),
            ]
          }
        </PreloadingTabs>

        <Chart chartId="x002" artistId={artistId} artistReport />
      </div>
      {/** /}
      <Chart chartId="p002" artistId={artistId} auctionList={auctionList} liquidity={liquidity} hideIlliquid />
      <PreloadingTabs>
        {
          [
            // previewProps('p009', 'Average Price'),
            // previewProps('p004', 'Market Enthusiasm', { liquidity }),
            // previewProps('p011', 'Sell-Through'),
            // previewProps('p012', 'Prices Over Time'),
          ]
        }
      </PreloadingTabs>
      {/**/}
      {/** /}
      <PreloadingTabs>
        {
          [
            previewProps('m014', 'Total Lots Sold'),
            // {
            //   props: { artistId, chartId: 'm015', legend: ['allArtists', 'artworksOfComparableMedium'] },
            //   Component: TotalLotsSold,
            //   title: 'By Price Segment',
            // },
          ]
        }
      </PreloadingTabs>
      {/**/}

      {/** /}
      <LiquidityIndexes chartId="x001" artistId={artistId} />
      {/**/}
    </div>
  );
}
ReportsArtist.propTypes = {
  classes: PropTypes.shape({
    ReportsArtist: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    tabContent: PropTypes.string.isRequired,
  }),
  artistId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  auctionList: PropTypes.instanceOf(I.Collection),
};

export default compose(
  injectSheet(sheet),
  connect({
    auctionList: auctionsById,
  }),
)(ReportsArtist);



