import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { DEFAULT_FONTFAMILY_3 } from 'theme/theme';
import injectSheet from 'lib/sheet';

export const definitions = {
  P001_DEFINITION: {
    title: 'Definition',
    description: 'The CAGR Projected Value uses three portions of the artist\'s return distribution to project a range of today’s value. The return distribution is generated on the artist\'s repeat sales at auction.',
  },
  P001_INTERPRETATION: {
    title: 'Interpretation',
    description: 'The CAGR Projected Value chart should be used as a guidance tool when assessing today’s value and indicate when further due diligence may be required.',
  },
  P002_DEFINITION: {
    title: 'Definition',
    description: 'Each point on the graph represents the compound annual growth rate (CAGR) generated when a work sells for the second time at auction, plotted against the length of time between the two sale events.',
  },
  P002_INTERPRETATION: {
    title: 'Interpretation',
    description: 'Short resale periods can produce volatile returns. However, longer holding periods have proven to minimize the risk of a negative return.',
  },
  T001_LIQUIDITY_LEVEL: {
    title: 'Liquidity Level',
    description: 'The measure of an artist’s liquidity based on frequency of repeat sales at auction over the prior five year period. Artists are grouped into the following categories: Liquid: 50+ Repeat Sales | Active: 25-49 Repeat Sales | Frequent: 10-24 Repeat Sales | Infrequent: 5-9 Repeat Sales | Illiquid: 0-4 Repeat Sales.',
  },
  T001_REPEAT_SALES_RANKING: {
    title: 'Repeat Sales Ranking',
    description: 'The relative repeat sales rank of the artist based on frequency of repeat sales at auction over the prior five year period. Rank 1 = most liquid / frequently transacted.',
  },

  T001_ARTIST_MEDIAN_COMPOUND_GROWTH_RATE: {
    title: 'Artist Median Compound Growth Rate (CAGR)',
    description: 'The median of the middle 60% of realized returns as a percent change per year between purchase and sold price across all repeat sales for the artist. ARTBnk reports on the median CAGR rather than the mean to avoid the undue influence of extreme values/outliers.',
  },

  T001_ARTIST_SELL_THROUGH_RATE: {
    title: 'Artist Sell-Through rate',
    description: 'The rate (%) at which an artist’s work sells when offered at auction.',
  },

  T001_ARTIST_LIQUIDITY_RANKING: {
    title: 'Artist Liquidity Ranking',
    description: 'The relative liquidity rank of the artist based on frequency of repeat sales at auction over the prior five year period. Rank 1 = most liquid / frequently transacted.',
  },

  T001_ARTIST_RETURN_RANKING: {
    title: 'Artist Return Ranking',
    description: 'The relative rank of the artist based on the median annual compound growth rate (CAGR) of the middle 60% of returns. Rank 1 = Highest CAGR ranking.',
  },

  T001_ARTIST_VOLATILITY_RISK: {
    title: 'Artist Volatility (Risk)',
    description: 'A measure of the variability in the artist’s returns. The risk per unit, or coefficient of variation (COV), is the standard deviation of the individual returns divided by the mean of the individual returns. Assets with COV values closer to zero are considered to be less risky.',
  },
  T002_REPEAT_SALES_RANKING_DEFINITION: {
    title: 'Definition',
    description: 'The repeat sales rank of the artist is based on the frequency of repeat sales at auction over the prior five year period. Artists with 1 or more repeat sale pairs?are included in ranking totals. Rank 1 = highest count of Repeat Sales.',
  },
  T002_REPEAT_SALES_RANKING_INTERPRETATION: {
    title: 'Interpretation',
    description: 'This reveals how the artist’s volume of repeat sales compares with other artists.',
  },
  T003_CAGR_RANKING_DEFINITION: {
    title: 'Definition',
    description: 'The rank of the artist based on the median of the middle 60% of the artist’s compound anual growth rate  (CAGR). Artists with 5 or more repeat sales at Christie’s, Sotheby’s or Phillips since 2011 are included in ranking totals. Rank 1 = largest CAGR.',
  },
  T003_CAGR_RANKING_INTERPRETATION: {
    title: 'Interpretation',
    description: 'This reveals how the Artist Median Compound Annual Growth Rate (CAGR) compares with other artists.',
  },
  T004_RISK_RANKING_DEFINITION: {
    title: 'Definition',
    description: 'The rank of the artist’s Risk Rating. Artists with 5 or more repeat sales are included in ranking totals. Artists with the lowest volatility are ranked highest. A negative Risk Rating indicates a negative average return, thus negative Risk Ratings are ranked behind positive Risk Ratings, and then ordered by volatility. Rank 1 = Lowest positive Risk Rating',
  },
  T004_RISK_RANKING_INTERPRETATION: {
    title: 'Interpretation',
    description: 'This ranking indicates how artists compare when measured by volatility of returns.',
  },

  T005_SELL_THROUGH_RATE_RANKING_DEFINITION: {
    title: 'Definition',
    description: 'The rank of the artist’s Sell-Through Rate. Artists with 5 or more repeat sales and/or 50 sold auction lots in the last ten years are included in ranking totals. Rank 1 = highest Sell-Through Rate.',
  },
  T005_SELL_THROUGH_RATE_RANKING_INTERPRETATION: {
    title: 'Interpretation',
    description: 'This reveals how the artist’s Sell-Through Rate compares with other artists.',
  },

  P003_DEFINITION: {
    title: 'Definition',
    description: 'The Market Enthusiasm score represents the percent difference between the most recent sold price of an artwork at auction and the CAGR Adjusted Value of that artwork\'s prior sale price.',
  },
  P003_INTERPRETATION: {
    title: 'Interpretation',
    description: 'This score indicates if works have sold for more or less than the expected result based on past performance and can indicate a change in performance.',
  },

  P004_DEFINITION: {
    title: 'Definition',
    description: 'The Market Enthusiasm score represents the percent difference between the most recent sold price of an artwork at auction and the CAGR Adjusted Value of that artwork\'s prior sale price.',
  },
  P004_INTERPRETATION: {
    title: 'Interpretation',
    description: 'This score indicates if works have sold for more or less than the expected result based on past performance and can indicate a change in performance.',
  },

  P005_DEFINITION: {
    title: 'Definition',
    description: 'This graph represents the quarterly percentage difference between the artist’s realized auction prices and the pre-sale ARTBnk Value of the sold artworks. ARTBnk Value is ARTBnk’s data-driven, AI-generated objective measure of fair market value (FMV).',
  },
  P005_INTERPRETATION: {
    title: 'Interpretation',
    description: 'This graph illustrates the financial performance of artworks sold over the past year relative to the past performance of comparable artworks, the measure of which is made possible by ARTBnk Value.',
  },

  P006_DEFINITION: {
    title: 'Definition',
    description: 'This chart identifies the average price for an artwork by the year of its creation.',
  },
  P006_INTERPRETATION: {
    title: 'Interpretation',
    description: 'This can illustrate if the desirability of an artist’s work is correlated with a particular period of the artist’s life, and how the subject of the report compares with the average price for its year of creation.',
  },

  P007_DEFINITION: {
    title: 'Definition',
    description: 'This chart represents the average price of 2D Unique artworks created by the artist sold at auction over the past ten years.',
  },
  P007_INTERPRETATION: {
    title: 'Interpretation',
    description: 'This can illustrate a direction in overall performance of the artist’s market and/or reflect when the highest prices were achieved over the past decade.',
  },

  P008_DEFINITION: {
    title: 'Definition',
    description: 'This chart represents the average price of 2D Unique artworks created by the artist sold at auction over the past ten years, broken down by medium.',
  },
  P008_INTERPRETATION: {
    title: 'Interpretation',
    description: 'This can illustrate a direction in overall performance of the artist’s market and/or reflect when the highest prices were achieved over the past decade, and whether trends in the artist’s market movement are specific to a particular medium.',
  },

  P009_DEFINITION: {
    title: 'Definition',
    description: 'This chart identifies the average price for an artwork by the year of its creation.',
  },
  P009_INTERPRETATION: {
    title: 'Interpretation',
    description: 'This can illustrate if the desirability of an artist’s work is correlated with a particular period of the artist’s life, and how the subject of the report compares with the average price for its year of creation.',
  },

  P010_DEFINITION: {
    title: 'Definition',
    description: 'This chart shows the percentage of lots that successfully sold when offered at auction by year over the past decade.',
  },
  P010_INTERPRETATION: {
    title: 'Interpretation',
    description: 'The sell-through rate chart can help illustrate trends in market enthusiasm for an artist, the quality of work offered, and/or the willingness of the art market to buy or sell at auction in recent years.',
  },

  P011_DEFINITION: {
    title: 'Definition',
    description: 'This chart shows the percentage of lots that successfully sold when offered at auction by year over the past decade.\n\nArtworks of Comparable Medium is the data set comprised of artworks sold at auction which were created using materials comparable to the selected artwork.',
  },
  P011_INTERPRETATION: {
    title: 'Interpretation',
    description: 'The sell-through rate chart can help illustrate trends in market enthusiasm for an artist, the quality of work offered, and/or the willingness of the art market to buy or sell at auction in recent years.',
  },

  P012_DEFINITION: {
    title: 'Definition',
    description: 'Each point on the graph represents the date and the price at which an artwork from the artist was sold at auction.',
  },
  P012_INTERPRETATION: {
    title: 'Interpretation',
    description: 'This comprehensive view of the artist’s auction data depicts how prices of the artist’s artworks have fluctuated over time, illustrating trends in the artist’s prices as well as outlier sales.',
  },

  P015_DEFINITION: {
    title: 'Definition',
    description: 'Each point on the graph represents the compound annual growth rate (CAGR) generated when a work sells for a second time at auction, plotted against the date of the purchase (or first sale event in a repeat sale pair).',
  },
  P015_INTERPRETATION: {
    title: 'Interpretation',
    description: 'Purchase years can be used to track market trends as well as provide guidance to understand what years of purchase have delivered the optimal returns.',
  },

  P016_DEFINITION: {
    title: 'Definition',
    description: 'Each point on the graph represents the compound annual growth rate (CAGR) generated when a work sells for a second time at auction, plotted against the date of the sale (or second sale event in a repeat sale pair).',
  },
  P016_INTERPRETATION: {
    title: 'Interpretation',
    description: 'Sale years can be used to track market trends as well as provide guidance to understand what years of sale have delivered the optimal returns.',
  },
  P00_DEFINITION: {
    title: '',
    description: '',
  },
  P00_INTERPRETATION: {
    title: '',
    description: '',
  },

  M001_DEFINITION: {
    title: 'Definition',
    description: 'This chart represents the total volume of sales in $USD of artworks sold at auction over the past ten years.',
  },
  M001_INTERPRETATION: {
    title: 'Interpretation',
    description: 'This can illustrate a direction in overall performance of the artist’s market and will reflect when the highest dollar volume of sales occurred over the past decade.',
  },

  M002_DEFINITION: {
    title: 'Definition',
    description: 'This chart represents the dollar volume of 2D Unique artworks created by the artist sold at auction over the past ten years, broken down by surface and substrate.',
  },
  M002_INTERPRETATION: {
    title: 'Interpretation',
    description: 'This can illustrate a direction in overall performance of the artist’s market and/or reflect when the highest prices were achieved over the past decade, while illustrating whether trends in the artist’s market movement are specific to a particular surface and substrate.',
  },

  M003_DEFINITION: {
    title: 'Definition',
    description: 'This chart represents the total volume of sales in $USD of 2D Unique artworks created by the artist sold at Christie’s, Sotheby’s and Phillips over the past ten years, broken down into ranges segmented by sold price.',
  },
  M003_INTERPRETATION: {
    title: 'Interpretation',
    description: 'This can illustrate a direction in overall performance of the artist’s market and reflects what market sector accounted for the highest dollar volume of sales for a given year.',
  },

  M004_DEFINITION: {
    title: 'Definition',
    description: 'This chart represents the total volume of sales in $USD of 2D Unique artworks created by the artist sold at Christie’s, Sotheby’s and Phillips over the past ten years.',
  },
  M004_INTERPRETATION: {
    title: 'Interpretation',
    description: 'This can illustrate a direction in overall performance of the artist’s market and/or reflect when the highest prices were achieved over the past decade, while illustrating the distribution of sale volume in dollars at the three major auction houses.',
  },

  M006_DEFINITION: {
    title: 'Definition',
    description: 'This chart represents the total volume of sales in $USD of artworks sold at auction over the past ten years as well as the total volume of sales of artworks of comparable medium.',
  },
  M006_INTERPRETATION: {
    title: 'Interpretation',
    description: 'This can illustrate a direction in overall performance of the artist’s market and will reflect when the highest dollar volume of sales occurred over the past decade.',
  },

  M007_DEFINITION: {
    title: 'Definition',
    description: 'This chart represents the total dollars sold at auction by calendar year as well as the total dollars sold for artworks by the artist with comparable medium to the selected artwork.',
  },
  M007_INTERPRETATION: {
    title: 'Interpretation',
    description: 'This chart displays how the artist’s market has changed in volume over the past decade.',
  },

  M008_DEFINITION: {
    title: 'Definition',
    description: 'This chart represents the percentage of total dollar volume sold by auction house over the past ten years.',
  },
  M008_INTERPRETATION: {
    title: 'Interpretation',
    description: 'This illustrates the recent distribution of sales volume at auction houses worldwide.',
  },

  M010_DEFINITION: {
    title: 'Definition',
    description: 'This chart represents the total lots sold at auction by calendar year for all works by the artist.',
  },
  M010_INTERPRETATION: {
    title: 'Interpretation',
    description: 'This chart displays how the artist’s market has changed in volume over the past decade, and may reveal a trajectory in market demand for the artist’s work.',
  },

  M011_DEFINITION: {
    title: 'Definition',
    description: 'This chart represents the total lots sold of 2D Unique artworks created by the artist sold at auction over the past ten years, broken down by surface and substrate.',
  },
  M011_INTERPRETATION: {
    title: 'Interpretation',
    description: 'This chart displays how the artist’s market has changed in volume over the past decade, and may reveal a trajectory in market demand for the artist’s work, while illustrating whether trends in the artist’s market movement are specific to a particular surface and substrate.',
  },

  M012_DEFINITION: {
    title: 'Definition',
    description: 'This chart represents the count of 2D Unique artworks created by the artist sold at auction over the past ten years, broken down into ranges segmented by sold price.',
  },
  M012_INTERPRETATION: {
    title: 'Interpretation',
    description: 'This chart displays how the artist’s market has changed in volume over the past decade, and may reveal a trajectory in market demand for the artist’s work, as well as revealing which market sector accounted for the largest count of sales for a given year.',
  },

  M013_DEFINITION: {
    title: 'Definition',
    description: 'This chart represents the count of lots sold at the three major auction houses by calendar year for 2D Unique artworks by the artist.',
  },
  M013_INTERPRETATION: {
    title: 'Interpretation',
    description: 'This chart displays how the artist’s market has changed in volume over the past decade, and may reveal a trajectory in market demand for the artist’s work while illustrating the distribution of lots sold by auction house.',
  },

  M014_DEFINITION: {
    title: 'Definition',
    description: 'This chart represents the total lots sold at auction by calendar year as well as the total lots sold for artworks by the artist with comparable medium to the selected artwork.',
  },
  M014_INTERPRETATION: {
    title: 'Interpretation',
    description: 'This chart displays how the artist’s market has changed in volume over the past decade.',
  },
  M015_DEFINITION: {
    title: 'Definition',
    description: 'This graph represents the yearly count of artworks sold compared with the yearly count of sold artworks of comparable medium, broken down by price segment.',
  },
  M015_INTERPRETATION: {
    title: 'Interpretation',
    description: 'This chart displays how the artist’s market has changed in volume over the past decade.',
  },

  M016_DEFINITION: {
    title: 'Definition',
    description: 'This chart represents the percentage of total lots sold by auction house over the past ten years.',
  },
  M016_INTERPRETATION: {
    title: 'Interpretation',
    description: 'This illustrates the recent distribution of artworks sold at auction houses worldwide.',
  },

  M018_DEFINITION: {
    title: 'Definition',
    description: 'This chart represents the yearly count of repeat sale pairs. A repeat sale pair is the pair of consecutive auction sales of an individual artwork from which a Compound Annual Growth Rate is calculated. For example, if a painting is bought at auction in 2005 and sold at auction in 2020, then it can be considered a repeat sale since there are two auction sales.',
  },
  M018_INTERPRETATION: {
    title: 'Interpretation',
    description: 'Artists with higher liquidity ranking will have higher counts of repeat sales.',
  },
  X001_DEFINITION: {
    title: 'Definition',
    description: 'Quarterly index that begins in 1980, derived from artworks sold at the worldwide locations of Christie’s, Sotheby’s or Phillips auction houses, with each work having been sold previously at any public auction since 1970, segmented into five liquidity levels: Liquid, representing artists having at least 50 repeat sale pairs; Active, representing artists having at least 25 but less than 50 repeat sale pairs; Frequent, representing artists having at least 10 but less than 25 repeat sale pairs; Infrequent, representing artists having at least 5 but less than 10 repeat sale pairs; and Illiquid, representing artists having less than 5 repeat sale pairs.',
  },
  X001_INTERPRETATION: {
    title: 'Interpretation',
    description: 'This reveals how groups of artists segmented by Liquidity Levels have performed in comparison with one another.',
  },
  'X001.10_DEFINITION': {
    title: 'Definition',
    description: 'Quarterly index for the prior ten year period, representing artworks sold at the worldwide locations of Christie’s, Sotheby’s or Phillips auction houses, with each work having been sold previously at any public auction since 1970, segmented into five liquidity levels: Liquid, representing artists having at least 50 repeat sale pairs; Active, representing artists having at least 25 but less than 50 repeat sale pairs; Frequent, representing artists having at least 10 but less than 25 repeat sale pairs; Infrequent, representing artists having at least 5 but less than 10 repeat sale pairs; and Illiquid, representing artists having less than 5 repeat sale pairs.',
  },
  'X001.10_INTERPRETATION': {
    title: 'Interpretation',
    description: 'This reveals how groups of artists segmented by Liquidity Levels have performed in comparison with one another.',
  },
  'X001.5_DEFINITION': {
    title: 'Definition',
    description: 'Quarterly index for the prior five year period, representing artworks sold at the worldwide locations of Christie’s, Sotheby’s or Phillips auction houses, with each work having been sold previously at any public auction since 1970, segmented into five liquidity levels: Liquid, representing artists having at least 50 repeat sale pairs; Active, representing artists having at least 25 but less than 50 repeat sale pairs; Frequent, representing artists having at least 10 but less than 25 repeat sale pairs; Infrequent, representing artists having at least 5 but less than 10 repeat sale pairs; and Illiquid, representing artists having less than 5 repeat sale pairs.',
  },
  'X001.5_INTERPRETATION': {
    title: 'Interpretation',
    description: 'This reveals how groups of artists segmented by Liquidity Levels have performed in comparison with one another.',
  },
  'X001.1_DEFINITION': {
    title: 'Definition',
    description: 'Quarterly index for the prior one year period, representing artworks sold at the worldwide locations of Christie’s, Sotheby’s or Phillips auction houses, with each work having been sold previously at any public auction since 1970, segmented into five liquidity levels: Liquid, representing artists having at least 50 repeat sale pairs; Active, representing artists having at least 25 but less than 50 repeat sale pairs; Frequent, representing artists having at least 10 but less than 25 repeat sale pairs; Infrequent, representing artists having at least 5 but less than 10 repeat sale pairs; and Illiquid, representing artists having less than 5 repeat sale pairs.',
  },
  'X001.1_INTERPRETATION': {
    title: 'Interpretation',
    description: 'This reveals how groups of artists segmented by Liquidity Levels have performed in comparison with one another.',
  },
  X003_DEFINITION: {
    title: 'Definition',
    description: 'Semiannual index that begins in 1980, representing the performance of artworks sold at the worldwide locations of Christie’s, Sotheby’s or Phillips auction houses, segmented into the following collection categories: American Art, British Art, Impressionist/Modern Art, Latin American Art, Old Masters Art and Post-War/Contemporary Art. Each segment corresponds with sales regularly conducted by major auction houses, wherein the sale is typically dedicated entirely to the collecting category. While any artist may have artworks appear in sales of varying categories, for the calculation of this index, artists are placed by the sale category they most frequently appear in at auction.',
  },
  X003_INTERPRETATION: {
    title: 'Interpretation',
    description: 'This reveals how different categories of art sold at auction have performed in comparison with one another.',
  },
  'X003.10_DEFINITION': {
    title: 'Definition',
    description: 'Semiannual index for the prior ten year period, representing the performance of artworks sold at the worldwide locations of Christie’s, Sotheby’s or Phillips auction houses, segmented into the following collection categories: American Art, British Art, Impressionist/Modern Art, Latin American Art, Old Masters Art and Post-War/Contemporary Art. Each segment corresponds with sales regularly conducted by major auction houses, wherein the sale is typically dedicated entirely to the collecting category. While any artist may have artworks appear in sales of varying categories, for the calculation of this index, artists are placed by the sale category they most frequently appear in at auction.',
  },
  'X003.10_INTERPRETATION': {
    title: 'Interpretation',
    description: 'This reveals how different categories of art sold at auction have performed in comparison with one another.',
  },
  'X003.5_DEFINITION': {
    title: 'Definition',
    description: 'Semiannual index for the prior five year period, representing the performance of artworks sold at the worldwide locations of Christie’s, Sotheby’s or Phillips auction houses, segmented into the following collection categories: American Art, British Art, Impressionist/Modern Art, Latin American Art, Old Masters Art and Post-War/Contemporary Art. Each segment corresponds with sales regularly conducted by major auction houses, wherein the sale is typically dedicated entirely to the collecting category. While any artist may have artworks appear in sales of varying categories, for the calculation of this index, artists are placed by the sale category they most frequently appear in at auction.',
  },
  'X003.5_INTERPRETATION': {
    title: 'Interpretation',
    description: 'This reveals how different categories of art sold at auction have performed in comparison with one another.',
  },
  'X003.1_DEFINITION': {
    title: 'Definition',
    description: 'Semiannual index for the prior one year period, representing the performance of artworks sold at the worldwide locations of Christie’s, Sotheby’s or Phillips auction houses, segmented into the following collection categories: American Art, British Art, Impressionist/Modern Art, Latin American Art, Old Masters Art and Post-War/Contemporary Art. Each segment corresponds with sales regularly conducted by major auction houses, wherein the sale is typically dedicated entirely to the collecting category. While any artist may have artworks appear in sales of varying categories, for the calculation of this index, artists are placed by the sale category they most frequently appear in at auction.',
  },
  'X003.1_INTERPRETATION': {
    title: 'Interpretation',
    description: 'This reveals how different categories of art sold at auction have performed in comparison with one another.',
  },
  X002_DEFINITION: {
    title: 'Definition',
    description: 'The quarterly performance of artworks eligible for ARTBnk Value offered at the worldwide locations of Christie’s, Sotheby’s or Phillips auction houses. The graph represents the quarterly percentage difference between aggregated sold prices and aggregated pre-sale ARTBnk Values, ARTBnk’s AI generated comparable fair market automated valuation model. Lots that did not sell are included in sold price totals at a value equal to 80% of the Low Estimate plus Buyer\'s Premium. Prints, photographs and sculpture are excluded.',
  },
  X002_INTERPRETATION: {
    title: 'Interpretation',
    description: 'This reveals how the market has performed relative to past results.',
  },
};

const sheet = {
  Definition: {
    //
  },
  title: {
    font: `500 14px/16px ${DEFAULT_FONTFAMILY_3}`,
    backgroundColor: '#F0F2F6',
    padding: [12, 31, 13],
    color: '#5B6178',
    '&.artistFinancialPerformanceMetricsDefinition': {
      padding: [12, 16, 14],
      lineHeight: '14px',
    },
  },
  description: {
    padding: [12, 16, 13],
    font: `400 14px/24px ${DEFAULT_FONTFAMILY_3}`,
    color: '#737992',
    '&.artistFinancialPerformanceMetricsDefinition': {
      padding: [8, 18, 16, 16],
    },
    '@media screen and (max-width: 1360px)': {
      lineHeight: '18px',
      '&.artistFinancialPerformanceMetricsDefinition': {
        lineHeight: '24px',
      },
    },
    '@media screen and (max-width: 900px)': {
      '&.artistFinancialPerformanceMetricsDefinition': {
        fontSize: '12px',
      },
    },
  },
};

function Definition({ classes, id, modifier }) {
  const item = definitions[id];

  if (!item) {
    console.error('no definition for', id);
    return null;
  }
  return (
    <div className={classes.Definition} data-definition-id={id}>
      <h4 className={cx(classes.title, modifier)}>{item.title}</h4>
      <p className={cx(classes.description, modifier)}>{item.description}</p>
    </div>
  );
}

Definition.propTypes = {
  id: PropTypes.string.isRequired,
  classes: PropTypes.shape({
    Definition: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  }).isRequired,
  modifier: PropTypes.string,
};

Definition = injectSheet(sheet)(Definition);

export { Definition };
