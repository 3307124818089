import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'cpcs-reconnect';
// import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { Redirect, Route, Switch, withRouter } from 'react-router';
import cx from 'classnames';
import {
  isAuthorized,
  tempPassword,
  user_sel,
  navigationBlocked_sel,
  onboardingPassed_sel,
  isEnterpriseUser,
  token,
} from 'domain/env/EnvModel';
import { logOut as LogOutAction } from 'domain/env/EnvActions';
import { ENTERPRISE_URL } from 'domain/const';
import { routeName_sel, location_sel } from 'domain/router/RouterModel';
import { get404_sel, advancedFilterOpenState_sel } from 'domain/ui/UIModel';
import { compose } from 'redux';
import DragLayer from 'components/dnd/dragLayer/CustomDragLayer';
import BuyRtv from 'pages/common/buyRtv';
import BuyAnalytics from 'pages/common/buyAnalytics';

import { lnk, links as r } from 'lib/routes';
import asyncComponent from 'lib/asyncComponent';
import checkAuthenticated from 'lib/checkAuthenticated';

import Header from 'pages/common/header';
import Footer from 'pages/common/footer';
import ArtistPage from 'pages/artistPage';
import ArtistReportsPage from 'pages/artistReportsPage/ArtistReportsPage';
import SharesPage from 'pages/sharesPage';
import SharedWithMePage from 'pages/sharedWithMePage';
import SharedByMePage from 'pages/sharedByMePage';
import AccountPage from 'pages/accountPage';
import SharedItemsPage from 'pages/SharedItemsPage';
import SharedArtworkPage from 'pages/SharedArtworkPage';
import Error404Page from 'pages/Error404Page';
// import { DragDropContext } from 'react-dnd';
// import HTML5Backend from 'react-dnd-html5-backend';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import Notifications from 'components/notifications';
import ConfirmationPopup from 'pages/common/popup/confirmationPopup';
import SaleLotsListPage from 'pages/saleLotsListPage';
import UnsavedChangesPopup from 'components/unsavedChangesPopup';
import InsightPage from 'pages/insightPage';
import SetPassword from 'components/form/Password';
import ArtworkPage from 'pages/artworkPage';
// fixed pages
import AuthPage from 'pages/authPage';
import CollectionListPage from 'pages/collectionListPage';
import FavoriteArtistsPage from 'pages/favoriteArtistsPage';
import AddArtUnifiedPage from 'pages/addArtUnifiedPage';
import IntelligencePage from 'pages/intelligencePage';
import HighchartsPage from 'pages/highcharts';
import createPassword from 'pages/createPassword';

import sheet from 'pages/common/sheet';
import injectSheet from 'lib/sheet';

const redirect = (...lnkArgs) => <Redirect to={lnk(...lnkArgs)} />;

class App extends React.PureComponent {

  static propTypes = {
    isAuthorized: PropTypes.bool,
    isEnterpriseUser: PropTypes.bool,
    token: PropTypes.string,
    logOut: PropTypes.func,
    tempPassword: PropTypes.bool,
    classes: PropTypes.shape({
      authContainer: PropTypes.string,
    }).isRequired,
    err404: PropTypes.bool,
    profile: PropTypes.object,
    advancedFilterOpenState: PropTypes.string,
    navigationBlocked: PropTypes.bool,
    onboardingPassed: PropTypes.bool,
    routeName: PropTypes.string,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
    location2: PropTypes.shape({
      pathname: PropTypes.string,
    }),
  };

  reloadTimeout = null;

  componentDidUpdate() {
    const { location, location2 } = this.props;
    if (!location || !location2) return;
    if (location.pathname === location2.pathname && this.reloadTimeout) {
      clearTimeout(this.reloadTimeout);
      this.reloadTimeout = null;
    }
    if (location.pathname !== location2.pathname && !this.reloadTimeout) {
      this.reloadTimeout = setTimeout(() => {
        console.warn(`componentDidUpdate pathname not match orgin: ${location.pathname} new: ${location2.pathname}`);
        this.reloadTimeout = null;
        window.location.reload();
      }, 500);
    }
  }

  pages() {
    const {
      err404,
      navigationBlocked,
      onboardingPassed,
      profile: { favoriteArtists },
    } = this.props;
    switch (true) {
      case process.env.NODE_ENV === 'test':
        return <div children="test" />;
      case err404:
        return <Error404Page />;

      // disabled in prefere to src/sagas/navigator.js
      case false && navigationBlocked && process.env.NODE_ENV !== 'test':
        return (
          <Switch>
            <Route exact path={r.accountPage} component={AccountPage} />
            <Route exact path={r.homePage} render={(props) => <FavoriteArtistsPage {...props} />} />
            <Redirect to={r.accountPage} />
          </Switch>
        );

      case !onboardingPassed && process.env.NODE_ENV !== 'test':
        return (
          <Switch>
            {
              navigationBlocked &&
                <Route exact path={r.accountPage} component={AccountPage} />
            }
            {
              navigationBlocked &&
                <Route exact path={r.homePage} render={(props) => <FavoriteArtistsPage {...props} />} />
            }
            <Route exact path={r.preferencesPage} component={require('./Preferences').default} />
            {
              !navigationBlocked &&
                <Redirect to={r.preferencesPage} />
            }
            {
              navigationBlocked &&
                <Redirect to={lnk('accountPage', { hash: 'billing' })} />
            }
          </Switch>
        );

      default:
        return (
          <Switch>
            {
              favoriteArtists.size === 0 &&
                <Route path={r.preferencesPage} component={require('./Preferences').default} />
            }
            <Route exact path={r.artistPage} component={ArtistPage} />
            <Route exact path={r.artistReportsPage} component={ArtistReportsPage} />
            <Route exact path={r.artworkPage} component={ArtworkPage} />

            <Route exact path={r.newArtworkFromHP} component={asyncComponent(() => import('./newArtPage'))} />

            <Route exact path={r.homePage} render={(props) => <FavoriteArtistsPage {...props} />} />
            <Route exact path={r.alertList} component={() => redirect('homePage')} />
            <Route exact path={r.lotPage} component={props => redirect('artworkPage', { artworkId: props.match.params.artworkId })} />
            <Route path={r.alertAOsPage} render={props => redirect('artistPage', { authorId: props.match.params.alertId })} />

            <Route path={r.collectionItemPage} component={asyncComponent(() => import('./collectionItemPage'))} />
            <Route path={r.collectionItemPage_oldLink} component={asyncComponent(() => import('./collectionItemPage'))} />
            <Route exact path={r.artNew} component={asyncComponent(() => import('./newArtPage'))} />
            <Route exact path={r.artEdit} component={asyncComponent(() => import('./newArtPage'))} />
            <Route path={r.collectionListPage} render={(props) => <CollectionListPage {...props} />} />
            <Route path={r.collectionList_oldLink} render={(props) => <CollectionListPage {...props} />} />


            <Route exact path={r.myShares} component={SharedByMePage} />
            <Route exact path={r.sharedWithMe} component={SharedWithMePage} />
            <Route exact path={r.sharedItemsPage} render={(props) => <SharedItemsPage {...props} />} />
            <Route exact path={r.sharedGallery} render={(props) => <SharedItemsPage {...props} />} />
            <Route exact path={r.sharedArtwork} component={SharedArtworkPage} />
            <Route exact path={r.shares} component={SharesPage} />

            <Route exact path={r.accountPage} component={AccountPage} />
            <Route exact path={r.insightPage} component={InsightPage} />
            <Route exact path={r.intelligencePage} component={IntelligencePage} />
            <Route exact path={r.highchartsPage} component={HighchartsPage} />
            <Route path={r.confirmEmailOnEmailChange} component={() => null} />
            <Route exact path={r.rtvValues} component={asyncComponent(() => import('pages/rtvValueListPage'))} />
            <Route exact path={r.analyticsListPage} component={asyncComponent(() => import('pages/analyticsListPage'))} />
            <Route exact path={r.addArtUnifiedPage} component={AddArtUnifiedPage} />
            <Route exact path={r.saleLotsListPage} component={SaleLotsListPage} />
            {
              !navigationBlocked &&
                <Redirect to={r.intelligencePage} />
            }
            {
              navigationBlocked &&
                <Redirect to={r.homePage} />
            }
          </Switch>
        );
    }
  }

  render() {
    const {
      classes,
      isAuthorized,
      tempPassword,
      advancedFilterOpenState,
      routeName,
      isEnterpriseUser,
      token,
      logOut,
    } = this.props;
    const onboarding = routeName === 'preferencesPage';
    if (isEnterpriseUser) {
      window.location.href = `${ENTERPRISE_URL}/analytics-data?auth_token=${token}`;
      logOut();
      return;
    }
    return isAuthorized && !tempPassword ? (
      <DndProvider backend={HTML5Backend}>
        <React.Fragment>
          {
            !onboarding &&
              <Header />
          }
          <div className={cx('LAYOUT', routeName, advancedFilterOpenState)}>
            <DragLayer />
            <BuyRtv />
            <BuyAnalytics />
            {this.pages()}
            <Notifications />
            <ConfirmationPopup />
            <UnsavedChangesPopup />
          </div>
          <Footer />
        </React.Fragment>
      </DndProvider>
    ) : (
      <div className={classes.authContainer}>
        { tempPassword && <SetPassword /> }
        <Switch>
          <Route path="/auth" component={AuthPage} />
          <Route path={r.confirmEmailOnSNReg} component={() => null} />
          <Route path={r.confirmEmailOnStdReg} component={() => null} />
          <Route path={r.confirmEmailOnEmailChange} component={() => null} />
          <Route exact path={r.createPassword} component={createPassword} />
          <Redirect to="/auth/login" />
        </Switch>
        <Notifications />
      </div>
    );
  }
}

export default compose(
  checkAuthenticated,
  withRouter,
  connect({
    isAuthorized,
    isEnterpriseUser,
    token,
    tempPassword,
    err404: get404_sel,
    profile: user_sel,
    onboardingPassed: onboardingPassed_sel,
    advancedFilterOpenState: advancedFilterOpenState_sel,
    navigationBlocked: navigationBlocked_sel,
    routeName: routeName_sel,
    location2: location_sel,
    logOut: LogOutAction,
  }),
  injectSheet(sheet),
  // DragDropContext(HTML5Backend),
)(App);
