/**
 * adds to classes.td additional className if defined
 *
 * additional className will be extracted using columnDef.get('className') as key from
 * cellprops.classes or classes maps or returns key as is
 *
 * @return: String classes.td or classes.td + additional className
**/
export const getMoreClassNames = props => {
  const { classes, cellProps: { classes: cellClasses = {} } = {}, columnDef } = props;
  if (!columnDef.has('className')) return '';
  const key = columnDef.get('className');
  return cellClasses[key] || classes[key] || key;
};

export const getRenderProps = props => {
  const { classes, rowData, rowIndex, cellProps = {} } = props;
  return {
      rowData,
      rowIndex,
      cellProps,
      classes,
    };
};

export const NoDataExtractor = new Error('columns definition should specify "getValue" or "fieldPath" or "name"');

/**
 * extract value using
 *   columnDef.fieldPath or
 *   [columnDef.name] or
 *   columnDef.getValue: fn
 * @return [extractedValue, NoDataExtractor or null]
**/
export const getCellValue = props => {
  const { rowData, columnDef } = props;
  const renderProps = getRenderProps(props);
  const path = columnDef.get('fieldPath') ||
      (columnDef.get('name') && [columnDef.get('name')]) ||
      null;
  const value = path ? rowData.getIn(path) : undefined;
  if (typeof columnDef.get('getValue') === 'function') {
    return [columnDef.get('getValue')({ ...props, ...renderProps, value }), null];
  }
  if (path) {
    return [value, null];
  }
  return [undefined, NoDataExtractor];
};

export const getColumnDef = (columns, sel) => {
  const columnDef = columns[sel];
  if (columnDef) return columnDef;
  const err = new Error(`There is no column definition «${sel}» in columns map`);
  err.data = { columns, sel };
  throw err;
};
