import { LAYOUT_RANGE_1024_MIN, LAYOUT_RANGE_1024_MAX } from 'theme/layout/LAYOUT';

import {
  DARK_BLUE_COLOR,
  DEFAULT_FONTFAMILY,
  DEFAULT_FONTFAMILY_3,
  ADVANCED_FILTER_LABEL_COLOR,
} from 'theme/theme';

export const label = {
  fontSize: 14,
  color: DARK_BLUE_COLOR,
  flex: '0 0 135px',
  alignItems: 'center',
  margin: [7, 10, 0, 0],
  '&.rtv-form-coupon': {
    font: `500 12px/24px ${DEFAULT_FONTFAMILY}`,
    textTransform: 'uppercase',
    color: DARK_BLUE_COLOR,
  },
  '&.report_calculate': {
    marginRight: 0,
    font: `500 12px/15px ${DEFAULT_FONTFAMILY_3}`,
    whiteSpace: 'nowrap',
    '@media screen and (max-width: 549px)': {
      marginTop: 15,
      display: 'block',
    },
  },
  '&.required:after': {
    content: '"*"',
    color: 'red',
  },
  '&.editionSize': {
    flex: 'initial',
  },
  '&.provenance-date': {
    flex: 'initial',
  },
  '&.artObjConceptionDate': {
    // flex: [0, 0, '111px'],
    flex: '1 1 0',
    whiteSpace: 'nowrap',
  },
  '&.alertCountry': {
    marginTop: 0,
  },
  '&.advancedFilter-Range': {
    flex: '0 0 64px',
    margin: 0,
    color: ADVANCED_FILTER_LABEL_COLOR,
    fontFamily: DEFAULT_FONTFAMILY,
    fontWeight: 400,
  },
  '&.ResetPage, &.ForgotPageEmail, &.authPageField': {
    fontFamily: DEFAULT_FONTFAMILY_3,
    fontSize: 12,
    marginBottom: 6,
    display: 'inline-block',
  },
  [`@media screen and (max-width: ${LAYOUT_RANGE_1024_MAX}px) and (min-width: ${LAYOUT_RANGE_1024_MIN}px)`]: {
    '&.AOForm.isCircular': {
      display: 'none',
    },
  },
};
