import React from 'react';
import PropTypes from 'prop-types';
import I from 'immutable';
import { compose } from 'redux';
import { Field, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import cx from 'classnames';
import { getId } from 'pages/common/newPage/form/utils';
import { currencyList } from 'lib/units';
import FormRowField from 'pages/common/newPage/form/elements/FormRowField';
import Select from 'components/form/select';
import { FORM_ADD_ART_ITEM } from 'domain/const';
import {
  formatPrice,
  formatPriceDecimal,
  formatEditable,
  formatEditableDecimal,
  normalize,
  normalizeDecimal,
} from 'components/form/normalizers';

import injectSheet from 'lib/sheet';
import sheet from './sheet';

const selector = formValueSelector(FORM_ADD_ART_ITEM);

function Price({ classes, name, value, currencyName = 'currencyPaid', title, currency, list = currencyList, disabled = false, placeholder='0.00', validate = [], isEditable, modifier }) {
  const id = getId(name);
  let formatter = isEditable ? formatEditable : formatPrice;
  if (currency === 'BTC') {
    formatter = isEditable ? formatEditableDecimal : formatPriceDecimal;
  }
  return (
    <li className={classes.formItem}>
      {
        title &&
          <label
            htmlFor={id}
            className={classes.label}
            children={title}
          />
      }
      <div className={cx(classes.fieldWrapper, modifier)}>
        <Field
          type="text"
          id={id}
          name={name}
          component={FormRowField}
          placeholder={isEditable ? placeholder : ''}
          validate={validate}
          format={formatter}
          normalize={currency === 'BTC' ? normalizeDecimal : normalize}
          rootTag="div"
          disabled={disabled}
          modifier={cx(modifier, 'priceField', { PriceUnsold: (value === 0 && !isEditable) })}
        />
        {
          !(value === 0 && !isEditable) &&
            <div className={cx(classes.selectWrapper, 'currencyField', modifier)}>
              <Field
                id={`${id}.unit`}
                component={Select}
                name={currencyName}
                list={list}
                isClearable={false}
                isSearchable={false}
                disabled={disabled}
                fixDropdownPadding
              />
            </div>
        }

      </div>
    </li>
  );
}

Price.propTypes = {
  classes: PropTypes.shape({
    formItem: PropTypes.string,
    label: PropTypes.string,
    fieldWrapper: PropTypes.string,
    selectWrapper: PropTypes.string,
  }).isRequired,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  currencyName: PropTypes.string,
  validate: PropTypes.func,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  isEditable: PropTypes.bool,
  list: PropTypes.instanceOf(I.List),
  placeholder: PropTypes.string,
  modifier: PropTypes.string,
  currency: PropTypes.string,
};

export default compose(
  injectSheet(sheet),
  connect((state, props) => ({
    value: selector(state, props.name),
    currency: selector(state, props.currencyName),
  })),
)(Price);
