import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import injectSheet from 'lib/sheet';
import styles from './sheet.js';

class Fieldset extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    children: PropTypes.node,
    defaultOpened: PropTypes.bool,
    title: PropTypes.string.isRequired,
    opened: PropTypes.bool,
    onToggleCollapse: PropTypes.func,
    RootTag: PropTypes.string,
    modifier: PropTypes.string,
    followExternal: PropTypes.bool,
  };

  static defaultProps = {
    RootTag: 'fieldset',
  };

  static getDerivedStateFromProps(props, state) {
    if (props.followExternal && state.opened !== props.opened) {
      return { opened: props.opened };
    }
    if (props.opened && !state.lastPropsOpened && !state.opened) {
      return { lastPropsOpened: true, opened: true };
    }
    if (state.lastPropsOpened !== props.opened) {
      return { lastPropsOpened: props.opened };
    }
    return null;
  }

  state = {
    opened: this.props.defaultOpened,
    lastPropsOpened: false,
  };

  toggle = () => {
    const { onToggleCollapse } = this.props;
    if (onToggleCollapse) {
      onToggleCollapse();
      return;
    }
    this.setState({ opened: !this.state.opened });
  };

  render() {
    const { classes, children, title, RootTag, modifier } = this.props;
    const { opened } = this.state;
    return (
      <RootTag
        className={cx(classes.Fieldset, modifier, { collapsed: !opened })}
      >
        <div className={classes.header}>
          <button
            type="button"
            onClick={this.toggle}
            className={opened ? classes.btnOpened : classes.btnClosed}
            children={title}
          />
        </div>
        <div className={cx(classes.content, modifier)}>
          {children}
        </div>
      </RootTag>
    );
  }
}

export default injectSheet(styles)(Fieldset);
