import upload from './upload.svg';
import { DEFAULT_BUTTON_COLOR } from 'theme/theme';
import {
  LAYOUT_RANGE_425_MAX,
  LAYOUT_RANGE_768_MAX,
  LAYOUT_RANGE_1024_MAX,
} from 'theme/layout/LAYOUT';

export default {
  PictureForm: {
    // root
    width: 460,
    // height: 460,
    height: 'auto',
    [`@media screen and (max-width: ${LAYOUT_RANGE_1024_MAX}px)`]: {
      // NEW_ART_PAGE
      width: 402,
      // height: 526,
      paddingTop: 20,
    },
    [`@media screen and (max-width: ${LAYOUT_RANGE_768_MAX}px)`]: {
      // NEW_ART_PAGE
      width: 526,
      // height: 526,
      paddingTop: 20,
    },
    [`@media screen and (max-width: ${LAYOUT_RANGE_425_MAX}px)`]: {
      width: '100%',
      // height: 345,
    },
  },
  ImageUploadZone: {
    // root
  },
  group: {
    flex: [0, 1, '460px'],
    padding: [0, 10],
  },
  PictureFormError: {
    color: '#d0021b',
    fontSize: '12px',
    marginTop: 5,
    '&.first': {
      marginTop: 10,
    },
  },
  form: {
    cursor: 'pointer',
    width: '100%',
    height: 460,
    '&.hasImage': {
      height: 73,
      marginTop: 20,
    },
    [`@media screen and (max-width: ${LAYOUT_RANGE_1024_MAX}px)`]: {
      // NEW_ART_PAGE
      height: 526,
    },
    [`@media screen and (max-width: ${LAYOUT_RANGE_425_MAX}px)`]: {
      height: 345,
    },
    '@media screen and (max-width: 395px)': {
      '&.hasImage': {
        height: 100,
      },
    },
  },
  preview: {

  },
  zone: {
    width: '100%',
    border: 'dashed 2px #babcbf',
    height: '100%',
    position: 'relative',
    maxHeight: 205,
    //maxWidth: 205,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&.hasImage': {
      marginTop: 20,
    },
    '&.singleImageMode': {
      marginTop: 0,
      height: 460,
      maxHeight: 'none',
    },
  },
  previewItem: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: 218,
    maxHeight: 300,
    minWidth: 24,
    minHeight: 24,
    marginBottom: 10,
  },
  content: {
    textAlign: 'center',
    position: 'absolute',
    width: '100%',
    top: '50%',
    transform: 'translateY(-50%)',
    padding: 15,
    '&:before': {
      content: '""',
      display: 'inline-block',
      width: 90,
      height: 69,
      marginBottom: 50,
      background: {
        image: `url("${upload}")`,
        repeat: 'no-repeat',
      },
    },
    '&.filesIsLoad:before': {
      display: 'none',
    },
  },
  title: {
    fontSize: 16,
    fontWeight: 500,
    color: '#babcbf',
    '& mark': {
      background: 'none',
      color: DEFAULT_BUTTON_COLOR,
    },
    '&.filesIsLoad:before': {
      content: '""',
      display: 'inline-block',
      width: 30,
      height: 24,
      verticalAlign: 'middle',
      marginRight: 15,
      background: {
        image: `url("${upload}")`,
        repeat: 'no-repeat',
        size: 'contain',
      },
    },
  },
  subtitle: {
    margin: [7, 0, 0],
    fontSize: 12,
    '& mark': {
      opacity: 0.8,
    },
  },
  fileName: {
    fontWeight: 500,
  },
};
