import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'cpcs-reconnect';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';
import cx from 'classnames';
import I from 'immutable';
import { selector } from 'cpcs-reconnect';
import InfiniteScroll from 'react-infinite-scroller';

import filtersBg from './images/filter.svg';
import Popup from 'components/popup';
import Badge from 'pages/common/ItemList/Badges/Artwork';
import Loader from 'components/loader/Loader';
import FormRowField from 'pages/common/newPage/form/elements/FormRowField';
import Select from 'components/form/select';

import { hidePopupAction } from 'domain/ui/UIActions';
import { isPopupVisibleSelector } from 'domain/ui/UIModel';
import { artwork_sel } from 'domain/artworkPage/ArtworkPageModel';
import { currentArtworkSelector as ownArtwork_sel } from 'domain/ownArtwork/OwnArtworkModel';
import { routeName_sel } from 'domain/router/RouterModel';
import {
  availableList_sel,
  availableSelected_sel,
  availableListPagination_sel,
  updateAvailableComparablesSelectionAction,
  saveSelectedComparablesAction,
  revertToRecommendedComparablesAction,
  appendAvailableComparablesAction,
  onBaseListChangeAction,
} from 'domain/comparables/ComparablesModel';
import {
  SELECT_COMPARABLES_POPUP,
  FORM_COMPARABLE_FILTER,
} from 'domain/const';

import { button } from 'theme/button';
// import { responsiveListFullPage } from 'theme/layout/responsiveList';
import { DARK_BLUE_COLOR } from 'theme/theme';
import closeSVG from 'pages/common/popup/close.svg';
import injectSheet from 'lib/sheet';

// routeName: artworkPage || collectionItemPage
const artworkByPage_sel = selector(artwork_sel, ownArtwork_sel, routeName_sel, (ao, own, routeName) =>
  routeName === 'collectionItemPage' ? own : ao,
);

const description = 'You can only select up to 3 comparables. Remove one above to select another';

const emptyLinkBuilder = () => null;

const BASE_LIST_OPTIONS = I.fromJS([
  { id: 'all', title: 'View all' },
  { id: 'recommended', title: 'View recommended' },
]);

const sheet = {
  title: {
    fontSize: 24,
    fontWeight: 600,
    color: DARK_BLUE_COLOR,
    gridArea: 'title',
  },
  description: {
    fontSize: 14,
    gridArea: 'description',
    padding: 0,
    whiteSpace: 'nowrap',
    minWidth: 0,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '@media screen and (max-width: 925px)': {
      whiteSpace: 'normal',
    },
  },
  close: {
    width: 24,
    height: 24,
    background: `url("${closeSVG}") scroll no-repeat center`,
    marginLeft: 'auto',
    gridArea: 'close',
  },
  layout1: {
    display: 'grid',
    gridTemplateColumns: '295px auto',
    columnGap: '25px',
    gridTemplateAreas: '"sidebar header" "sidebar filters" "sidebar content"',
    '@media screen and (max-width: 860px)': {
      paddingLeft: 25,
      gridTemplateColumns: '270px auto',
      gridTemplateAreas: '"sidebar header" "sidebar filters" "sidebar content"',
    },
    '@media screen and (max-width: 660px)': {
      gridTemplateColumns: 'auto',
      gridTemplateAreas: '"header" "sidebar" "filters" "content"',
      height: '100%',
      overflow: 'hidden',
    },
  },
  header: {
    padding: [23, 20, 0, 0],
    gridArea: 'header',
    alignItems: 'center',
    marginBottom: 20,
    display: 'grid',
    width: '100%',
    gridTemplateColumns: 'max-content auto minmax(30px, 5%)',
    gridTemplateAreas: '"title actions close" "description actions close"',
    '@media screen and (max-width: 1460px)': {
      gridTemplateAreas: '"actions actions close" "title title title" "description description description"',
      gridTemplateColumns: 'min-content auto minmax(30px, 5%)',
      rowGap: '10px',
    },
    '@media screen and (max-width: 925px)': {
      gridTemplateAreas: '"actions close" "title title" "description description"',
      gridTemplateColumns: 'auto',
      alignItems: 'start',
    },
    '@media screen and (max-width: 830px)': {
      gridTemplateAreas: '"title close" "description description" "actions actions"',
      position: 'sticky',
    },
  },
  filters: {
    //
    paddingLeft: 30,
    background: `url("${filtersBg}") no-repeat scroll left center / 11px`,
  },
  filterItem: {
    maxWidth: 220,
  },
  sidebar: {
    gridArea: 'sidebar',
    borderRight: '2px solid #E1E1E1',
    '@media screen and (max-width: 660px)': {
      borderRight: 'none',
      display: 'none',
    },
  },
  content: {
    marginTop: 28,
    gridArea: 'content',
    padding: [0, 0, 23],
    height: 'calc(100vh - 64px - 125px)',
  },
  contentScroll: {
    height: 'calc(100vh - 64px - 165px)',
    overflowY: 'auto',
    overflowX: 'hidden',
    paddingRight: 10,
    paddingLeft: 20,
    transform: 'translate(-20px, 0)',
    '@media screen and (max-width: 1460px)': {
      height: 'calc(100vh - 64px - 220px)',
    },
    '@media screen and (max-width: 925px)': {
      height: 'calc(100vh - 64px - 225px)',
    },
    '@media screen and (max-width: 810px)': {
      height: 'calc(100vh - 64px - 275px)',
    },
    '@media screen and (max-width: 693px)': {
      height: 'calc(100vh - 64px - 305px)',
    },
    '@media screen and (max-width: 673px)': {
      height: 'calc(100vh - 64px - 325px)',
    },
    '@media screen and (max-width: 660px)': {
      height: 'calc(100vh - 64px - 215px)',
    },
    '@media screen and (max-width: 625px)': {
      height: 'calc(100vh - 64px - 235px)',
    },
    '@media screen and (max-width: 569px)': {
      height: 'calc(100vh - 64px - 145px)',
    },
    '@media screen and (max-width: 561px)': {
      height: 'calc(100vh - 64px - 165px)',
    },
    '@media screen and (max-width: 439px)': {
      height: 'calc(100vh - 64px - 235px)',
    },
  },
  scrollableMobile: {
    '@media screen and (max-width: 660px)': {
      height: '100%',
      overflowY: 'auto',
      overflowX: 'hidden',
    },
  },
  button,
  actions: {
    width: '100%',
    justifyContent: 'end',
    padding: [0, 10],
    display: 'grid',
    columnGap: 10,
    gridTemplateColumns: 'auto auto',
    gridArea: 'actions',
    '@media screen and (max-width: 810px)': {
      gridTemplateColumns: '100%',
      padding: 0,
      rowGap: '15px',
    },
    '@media screen and (max-width: 660px)': {
      gridTemplateColumns: 'auto auto',
      padding: 0,
    },
    '@media screen and (max-width: 439px)': {
      gridTemplateColumns: 'minmax(max-content, 100%)',
      rowGap: '15px',
      marginTop: 20,
    },
  },
  currentArwork: {
    width: 248,
    margin: [150, 'auto', 0],
    '@media screen and (max-width: 1460px)': {
      marginTop: 200,
    },
    '@media screen and (max-width: 920px)': {
      marginTop: 218,
    },
    '@media screen and (max-width: 810px)': {
      marginTop: 260,
    },
    '@media screen and (max-width: 693px)': {
      marginTop: 290,
    },
    '@media screen and (max-width: 660px)': {
      marginTop: 28,
    },
  },
  availableComparables: {
    width: '100%',
    display: 'grid',
    rowGap: '40px',
    columnGap: '30px',
    justifyContent: 'left',
    position: 'relative',
    // gridTemplateColumns: `repeat(auto-fit, minmax(248px, 1fr))`,
    gridTemplateColumns: 'repeat(auto-fit, 248px)',
    '@media screen and (max-width: 660px)': {
      justifyContent: 'center',
    },
  },
  item: {},
};

class SelectComparablesPopup extends React.Component {
  static propTypes = {
    onCancel: PropTypes.func.isRequired,
    availableList: PropTypes.instanceOf(I.Collection),
    classes: PropTypes.shape({
      title: PropTypes.string.isRequired,
      actions: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      close: PropTypes.string.isRequired,
      button: PropTypes.string.isRequired,
      layout1: PropTypes.string.isRequired,
      filters: PropTypes.string.isRequired,
      sidebar: PropTypes.string.isRequired,
      filterItem: PropTypes.string.isRequired,
      currentArwork: PropTypes.string.isRequired,
      header: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired,
      contentScroll: PropTypes.string.isRequired,
      availableComparables: PropTypes.string.isRequired,
      item: PropTypes.string.isRequired,
    }).isRequired,
    isPopupVisible: PropTypes.func.isRequired,
    artwork: PropTypes.instanceOf(I.Collection),
    updateAvailableComparablesSelection: PropTypes.func.isRequired,
    availableSelected: PropTypes.instanceOf(I.Collection).isRequired,
    saveSelectedComparables: PropTypes.func.isRequired,
    revertToRecommended: PropTypes.func.isRequired,
    pagination: PropTypes.shape({
      loading: PropTypes.bool,
      page: PropTypes.number,
      pages: PropTypes.number,
      total: PropTypes.number,
    }),
    onBaseListChange: PropTypes.func.isRequired,
    loadMore: PropTypes.func.isRequired,
  };

  onCancel = () => this.props.onCancel();
  onSave = () => {
    this.props.saveSelectedComparables();
  }
  onRevert = () => {
    this.props.revertToRecommended();
  }

  get limitSelection() {
    const { availableSelected } = this.props;
    return availableSelected.filter(v => v !== null).size >= 3;
  }

  renderActions = () => {
    const { classes, availableSelected } = this.props;
    return (
      <div className={classes.actions}>
        <button
          type="button"
          onClick={this.onSave}
          className={cx(classes.button, 'primary', 'select-comparables-popup')}
          disabled={!availableSelected.size > 0}
        >
          Save Selection
        </button>
        <button
          type="button"
          onClick={this.onRevert}
          className={cx(classes.button, 'secondary', 'select-comparables-popup', { disabled: true } )}
        >
          Revert to Recommended Comparables
        </button>
      </div>
    );
  }

  onSelect = (artwork) => () => {
    const { updateAvailableComparablesSelection } = this.props;
    updateAvailableComparablesSelection(artwork.get('id'));
  }

  get loader() {
    const { pagination } = this.props;
    if (!pagination) return null;
    if (!pagination.loading) return null;
    const modifier = pagination.loading && pagination.page > 0 ? 'infiniteScroll_LoadMore' : 'infiniteScroll_FirstPage';
    return <Loader key="loader" loading modifier={modifier} />;
  }

  render() {
    const { classes, isPopupVisible, artwork, availableList, availableSelected, pagination, onBaseListChange } = this.props;
    if (!isPopupVisible(SELECT_COMPARABLES_POPUP)) return null;
    const limitSelection = this.limitSelection;
    return (
      <Popup name={SELECT_COMPARABLES_POPUP} modifier="select-comparables-popup">
        <div className={classes.layout1}>
          <div className={classes.sidebar}>
            <div
              className={classes.currentArwork}
            >
              <Badge
                artwork={artwork}
                linkBuilder={emptyLinkBuilder}
              />
            </div>
          </div>
          <div className={classes.header}>
            <h2 className={classes.title}>Selected Comparables</h2>
            <p className={classes.description}>{description}</p>
            {this.renderActions()}
            <button
              type="button"
              onClick={this.onCancel}
              className={classes.close}
            />
          </div>
          <div className={classes.filters}>
            <div className={classes.filterItem}>
              <Field
                name="baseList"
                component={FormRowField}
                Field={Select}
                list={BASE_LIST_OPTIONS}
                onChange={onBaseListChange}
                isClearable={false}
                isSearchable={false}
                rootTag="div"
                modifier="comparablesBaseList"
              />
            </div>
          </div>
          <div className={classes.content} data-has-more={pagination.page < (pagination.pages - 1) || (pagination.loading && pagination.page === 0)}>
            <div className={classes.contentScroll}>
              <InfiniteScroll
                className={cx(classes.availableComparables, 'availableComparables')}
                loadMore={this.props.loadMore}
                hasMore={pagination.page < (pagination.pages - 1) || (pagination.loading)}
                loader={this.loader}
                useWindow={false}
              >
                {
                  availableList.map((AOItem, i) => (
                    <div
                      className={classes.item}
                      key={AOItem.get('id') + `${i}`}
                    >
                      <Badge
                        artwork={AOItem}
                        linkBuilder={emptyLinkBuilder}
                        onSelect={this.onSelect(AOItem)}
                        selected={availableSelected.includes(AOItem.get('id'))}
                        selectedIds={availableSelected}
                        selectedId={AOItem.get('id')}
                        enumerate
                        hideSelectOverlay
                        limitSelection={limitSelection}
                        modifier="available-comparable"
                      />
                    </div>
                  ))
                }
              </InfiniteScroll>
            </div>
          </div>
        </div>
      </Popup>
    );
  }
}

SelectComparablesPopup = compose(
  injectSheet(sheet),
  connect({
    availableList: availableList_sel,
    availableSelected: availableSelected_sel,
    artwork: artworkByPage_sel,
    onCancel: hidePopupAction,
    isPopupVisible: isPopupVisibleSelector,
    updateAvailableComparablesSelection: updateAvailableComparablesSelectionAction,
    saveSelectedComparables: saveSelectedComparablesAction,
    revertToRecommended: revertToRecommendedComparablesAction,
    loadMore: appendAvailableComparablesAction,
    onBaseListChange: onBaseListChangeAction,
    pagination: availableListPagination_sel,
  }),
  reduxForm({
    form: FORM_COMPARABLE_FILTER,
    initialValues: {
      baseList: 'recommended',
    },
  }),
)(SelectComparablesPopup);

export { SelectComparablesPopup };
