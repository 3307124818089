import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'cpcs-reconnect';
import ReactTooltip from 'react-tooltip';
import cx from 'classnames';
import I from 'immutable';

import NewAuthorPopup from 'pages/newArtPage/newAuthorPopup';
import { user_sel } from 'domain/env/EnvModel';
import { addPopupAction } from 'domain/ui/UIActions';
import { updateAccountAction } from 'domain/env/EnvActions';
import { query_sel } from 'domain/router/RouterModel';
import { occupationList_sel } from 'domain/occupations/OccupationModel';
import { searchArtistAction } from 'domain/artist/ArtistAction';
import { artistsList as artistsList_sel } from 'domain/artist/ArtistModel';
import { debounce } from 'lib/envGlobals';
import { fullName, life } from 'domain/artist/helpers';
import { CREATE_AUTHOR_POPUP } from 'domain/const';
import 'core-js/features/array/includes';

import sheet from './sheet';
import injectSheet from 'lib/sheet';

const newOccupationsList = (id, occupations) => occupations.includes(id) ?
  occupations.filter(v => v !== id) :
  occupations.concat(id);

const Occupations = ({ classes, occupationList, onChange, data: { occupations } }) => (
  <div className={classes.content}>
    <div className={classes.occupationList}>
      {
        occupationList.map(({ id, title }) =>
          <button
            type="button"
            className={cx(classes.button, 'occupation', { selected: occupations.find(i => i === id) })}
            key={id}
            onClick={() => onChange({ occupations: newOccupationsList(id, occupations) })}
            children={title}
          />,
        )
      }
    </div>
  </div>
);

Occupations.propTypes = {
  classes: PropTypes.object,
  occupationList: PropTypes.any,
  onChange: PropTypes.func,
  data: PropTypes.shape({
    occupations: PropTypes.array,
  }),
};

const newArtistsList = (id, favoriteArtists) => favoriteArtists.includes(id) ?
  favoriteArtists.filter(v => v !== id) :
  favoriteArtists.concat(id);

class Artists extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    artistsList: PropTypes.any,
    searchArtist: PropTypes.func,
    onChange: PropTypes.func,
    onTermChange: PropTypes.func,
    data: PropTypes.shape({
      favoriteArtists: PropTypes.array,
      tags: PropTypes.array,
    }),
    term: PropTypes.string,
    addPopup: PropTypes.func.isRequired,
  };

  state = {
    searchString: '',
    items: null,
  };

  static getDerivedStateFromProps(props, state) {
    if (props.artistsList !== state.items) {
      setTimeout(() => ReactTooltip.rebuild(), 100);
      return {
        items: props.artistsList,
      };
    } else {
      return null;
    }
  }

  searchArtistHandler = debounce(term => this.props.searchArtist({ term }), 500);

  onSearch = (e) => {
    const { onTermChange } = this.props;
    onTermChange(e.target.value);
    this.searchArtistHandler(e.target.value);
    this.setState({ searchString: e.target.value } );
  }; 

  onChange = artist => () => {
    const { onChange, data: { favoriteArtists, tags } } = this.props;
    onChange({
      favoriteArtists: newArtistsList(artist.id, favoriteArtists),
      tags: tags.find(v => v.id === artist.id) ? tags.filter(v => v.id !== artist.id) : tags.concat(artist),
    });
  };

  dataTip = artist =>
    ( (fullName(artist).length + life(artist).length) >=30 ) ?
      `${fullName(artist)} ${!!life(artist) && '('+life(artist)+')'}` :
      '';

  onAddArtist = () => this.props.addPopup({
    name: CREATE_AUTHOR_POPUP,
    params: { favoriteArtists: this.props.data.favoriteArtists },
  });

  render() {
    const { classes, artistsList, term, data: { favoriteArtists, tags } } = this.props;
    return (
      <div className="PAGE_CONTAINER PreferencesPage">
        <input
          value={term}
          onChange={this.onSearch}
          className={classes.search}
          placeholder="Search artist by name"
        />
        <div className={classes.tagsListWrapper}>
          <div className={classes.addArtistOffer}>
            {'Didn\'t find your artist? '}
            <button
              type="button"
              children="Click here"
              onClick={this.onAddArtist}
              className={classes.addArtistBtn}
            />
            {' '}
            {'to add your artist to our database.'}
          </div>
          <div className={classes.tagsList}>
            {
              tags.map((artist, tagIndex) => (
                <div className={classes.tag} key={`${tagIndex}-${artist.id}`}>
                  <span
                    className={classes.tagText}
                    children={fullName(artist)}
                  />
                  <button
                    type="button"
                    onClick={this.onChange(artist)}
                    className={classes.tagRmButton}
                  />
                </div>
              ))
            }
          </div>
        </div>
        <NewAuthorPopup />
        <div className={classes.artistsList}>
          {
            artistsList
              .slice(0, 21)
              .map(artist => (
                <button
                  type="button"
                  key={artist.id}
                  className={cx(classes.button, { selected: favoriteArtists.includes(artist.id) })}
                  onClick={this.onChange(artist)}
                  data-tip={this.dataTip(artist)}
                >
                  {fullName(artist)}
                  {!!life(artist) && <span className={classes.years}> ({ life(artist)})</span>}
                </button>
              ))
          }
        </div>
        <ReactTooltip key={this.state.searchString} place="top" type="dark" effect="solid"/>
      </div>
    );
  }
}

const steps = {
  occupations: {
    title: 'Tell us about yourself',
    subTitle: 'Select all that apply',
    Component: Occupations,
    allowNext: ({ occupations }) => occupations.length > 0,
  },
  artists: {
    title: 'Who are your favorite artists?',
    subTitle: 'Please choose one or more',
    Component: Artists,
    allowNext: ({ favoriteArtists }) => favoriteArtists.length > 0,
  },
};

class Preferences extends React.Component {

  static propTypes = {
    classes: PropTypes.object,
    occupationList: PropTypes.any,
    artistsList: PropTypes.any,
    searchArtist: PropTypes.func,
    updateAccount: PropTypes.func,
    userProfile: PropTypes.instanceOf(I.Record),
    query: PropTypes.shape({
      step: PropTypes.oneOf(['artists', 'occupations']),
    }).isRequired,
    addPopup: PropTypes.func.isRequired,
  }

  componentDidMount() {
    const { occupations, favoriteArtists } = this.props.userProfile.toJS();
    const step = occupations.length ? 1 : 0;
    this.setState({
      data: { occupations, favoriteArtists, tags: [] },
      step,
    });
  }

  state = {
    data: { occupations: [], favoriteArtists: [], tags: [] },
    term: '',
  }

  onFinish = () => {
    const { favoriteArtists } = this.state.data;
    this.props.updateAccount({ favoriteArtists });
  }

  onNextStep = () => {
    const { occupations } = this.state.data;
    this.props.updateAccount({ occupations });
  }

  onUpdateData = (data) => this.setState({ data: { ...this.state.data, ...data } });

  onTermChange = (term) => this.setState({ term });

  render() {
    const { classes, query: { step = 'occupations' } } = this.props;
    const { data } = this.state;
    const { Component, subTitle, title, allowNext } = steps[step];
    return (
      <div className={classes.Preferences}>
        <div className={classes.header}>
          {title}
        </div>
        <div className={classes.subHeader} children={subTitle} />
        <Component
          {...this.props}
          searchArtist={this.props.searchArtist}
          addPopup={this.props.addPopup}
          data={this.state.data}
          term={this.state.term}
          onChange={this.onUpdateData}
          onTermChange={this.onTermChange}
        />
        <div className={classes.controls}>
          {
            step === 'occupations' &&
              <button
                type="button"
                disabled={!allowNext(data)}
                className={classes.step}
                onClick={this.onNextStep}
                children="next step"
              />
          }
          {
            step === 'artists' &&
              <button
                type="button"
                disabled={!allowNext(data)}
                className={cx(classes.step, 'step2')}
                onClick={this.onFinish}
                children="finish"
              />
          }
        </div>
      </div>

    );
  }
};

export default compose(
  injectSheet(sheet),
  connect({
    occupationList: occupationList_sel,
    searchArtist: searchArtistAction,
    artistsList: artistsList_sel,
    updateAccount: updateAccountAction,
    userProfile: user_sel,
    query: query_sel,
    addPopup: addPopupAction,
  }),
)(Preferences);
