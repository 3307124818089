import React from 'react';
import cx from 'classnames';
import { connectEventsContainer } from 'pages/highcharts/helpers/EventsContext';
import { RefSize } from 'pages/highcharts/helpers/refSize';
import { DEFAULT_FONTFAMILY } from 'pages/highcharts/helpers/PDFConstants';
import { EVENT_GET_PAGE_COMPONENT } from 'pages/highcharts/helpers/PDFConstants';
import { rowIsValid, rowTitle, rowIlliquid } from 'components/reports/ArtistRankings';


class ArtistRankingsPDF extends RefSize {

  state = {
    rankings: null,
    liquidity: null,
  };

  componentDidMount() {
    const { PDFEvents, item } = this.props;
    const { item: rankings, liquidity } = PDFEvents.dispatch(EVENT_GET_PAGE_COMPONENT, { id: item.name }) ||
      { item: null };
    this.setState({ rankings, liquidity });
    super.componentDidMount();
  }
  render() {
    // const { item } = this.props;
    const { rankings, liquidity } = this.state;
    let validIndex = -1;
    return (
      <svg width="450" height="750" ref={this.ref} data-top="92" data-left="27">
        <style>
          {/** /}
          14 9 
          24 15.43
          {/**/}
          {`
          /**
           * Note that the color of the text is set with the
           * fill property, the color property is for HTML only
          **/
          .ArtistRankingsPDF_Title { font: 700 24px ${DEFAULT_FONTFAMILY}; fill: #000; }
          .ArtistRankingsPDF_Description { font: 400 12px ${DEFAULT_FONTFAMILY}; fill: #737992; }
          .ArtistRankingsPDF_place { font: 700 25px ${DEFAULT_FONTFAMILY}; fill: #304C95; }
          .ArtistRankingsPDF_total { font: 700 8px ${DEFAULT_FONTFAMILY}; fill: #1E202C; }
          .ArtistRankingsPDF_rankTitle { font: 700 12px ${DEFAULT_FONTFAMILY}; fill: #000; }
          .ArtistRankingsPDF_rankTitle_place { font: 500 9px ${DEFAULT_FONTFAMILY}; fill: #8C92AB; }
          .ArtistRankingsPDF_artist_rank { font: 500 8px ${DEFAULT_FONTFAMILY}; fill: #8C92AB; }
          .ArtistRankingsPDF_artist_rank_active { font: 700 8px ${DEFAULT_FONTFAMILY}; fill: #454A5F; }
          .ArtistRankingsPDF_artist_full_name { font: 500 8px ${DEFAULT_FONTFAMILY}; fill: #8C92AB; }
          .ArtistRankingsPDF_artist_full_name_active { font: 700 8px ${DEFAULT_FONTFAMILY}; fill: #454A5F; }
          `}
        </style>
        <text x="0" y="20" className="ArtistRankingsPDF_Title">
          Artist Rankings
        </text>
        <text x="0" y="36" className="ArtistRankingsPDF_Description">
          This section provides an overview of where artists rank in terms of key performance indicators.
        </text>
        <g transform="translate(0, 86)">
          {
            rankings && rankings.map(({ data: row = {} }) => {
              if (!rowIsValid(row) || rowIlliquid({ row, liquidity })) {
                return null;
              }
              validIndex++;
              const title = rowTitle(row);
              const { slug, rank, total_rank, artists } = row;
              return (
                <g key={slug} transform={`translate(0, ${validIndex * 139})`}>
                  <circle cx="42" cy="42" r="42" fill="#F8F6F5" />
                  {/*<circle cx="42" cy="42" r="42" fill="#AAA" />*/}
                  <text
                    x="42"
                    y="35"
                    className="ArtistRankingsPDF_place"
                    textAnchor="middle"
                    alignmentBaseline="middle"
                  >
                    {rank}
                  </text>
                  <text
                    x="42"
                    y="57"
                    className="ArtistRankingsPDF_total"
                    textAnchor="middle"
                    alignmentBaseline="middle"
                  >
                    of {total_rank}
                  </text>
                  <text
                    x="42"
                    y="96"
                    className="ArtistRankingsPDF_rankTitle_place"
                    textAnchor="middle"
                    alignmentBaseline="middle"
                  >
                    {/* Sell-through Rate Ranking -> Sell-through Rate \n Ranking*/}
                    {title === 'Sell-through Rate Ranking' ? 'Sell-through Rate' : title}
                  </text>
                  {
                    title === 'Sell-through Rate Ranking' &&
                      <text
                        x="42"
                        y="106"
                        className="ArtistRankingsPDF_rankTitle_place"
                        textAnchor="middle"
                        alignmentBaseline="middle"
                      >
                        Ranking
                      </text>
                  }
                  <text x="120" y="8" className="ArtistRankingsPDF_rankTitle">
                    {title}
                  </text>
                  {
                    artists.map((artist, artistIndex) => {
                      const id = artist.webapp_id || artist.id;
                      return (
                        <g key={id} transform={`translate(120, ${33 + artistIndex * 17})`}>
                          <line
                            x1="0"
                            y1="14"
                            x2="280"
                            y2="14"
                            stroke="#F0F2F6"
                            strokeWidth="1"
                          />
                          {
                            artist.active &&
                              <rect
                                x="-17"
                                y="-2"
                                width="280"
                                height="15"
                                rx="7"
                                ry="7"
                                fill="none"
                                stroke="#A6ACC4"
                                strokeWidth="1"
                              />
                          }
                          <text
                            x="0"
                            y="8"
                            className={cx('ArtistRankingsPDF_artist_rank',
                              { ArtistRankingsPDF_artist_rank_active: artist.active },
                            )}
                          >
                            {artist.rank}
                          </text>
                          <text
                            x="25"
                            y="8"
                            className={cx('ArtistRankingsPDF_artist_full_name',
                              { ArtistRankingsPDF_artist_full_name_active: artist.active },
                            )}
                          >
                            {artist.full_name}
                          </text>
                        </g>
                      );
                    })
                  }
                </g>
              );
            })
          }
        </g>
      </svg>
    );
  }
}

ArtistRankingsPDF = connectEventsContainer(ArtistRankingsPDF);

export { ArtistRankingsPDF };
