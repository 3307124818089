import { pageLayout } from 'theme/pageLayout';
import { COMPARABLES_DESCRIPTION, DEFAULT_FONTFAMILY_3 } from 'theme/theme';
import lockedBg from 'pages/common/pageSectionComparables/images/logo-locked.svg';
import { button } from 'theme/button';

import { responsiveListFullPage } from 'theme/layout/responsiveList';

export default {
  sectionHeader: pageLayout.sectionHeader,
  sectionHeaderTitle: pageLayout.sectionHeaderTitle,
  sectionDescription: {
    font: `400 14px/22px ${DEFAULT_FONTFAMILY_3}`,
    color: COMPARABLES_DESCRIPTION,
    gridArea: 'description',
  },
  button,
  sectionActions: {
    gridArea: 'actions',
  },
  comparableList: responsiveListFullPage,
  ArtworkMock: {
    flex: '0 0 230px',
    margin: 24,
  },
  imgMock: {
    width: 230,
    height: 200,
    background: {
      color: '#F4F4F4',
      image: `url("${lockedBg}")`,
      size: '160px 40px',
      position: 'center',
      repeat: 'no-repeat',
    },
  },
  txtMock: {
    height: 20,
    width: '100%',
    marginTop: 3,
    backgroundColor: '#F4F4F4',
    '&.index1': {
      marginTop: 20,
    },
    '&.last2': {
      width: '50%',
      '&.index3': {
        marginTop: 10,
      },
    },
  },
  item: {
  },
};
