import React from 'react';
import PropTypes from 'prop-types';

import {
  EVENT_ITEM_DID_MOUNT,
  EVENT_ITEM_DID_UPDATE,
  EVENT_LIST_RENDER_PROPS,
  EVENT_RENDER_TO_PDF,
  EVENT_APPEND_TO_PDF,
} from 'pages/highcharts/helpers/PDFConstants';

export class RefSize extends React.Component {
  static propTypes = {
    item: PropTypes.shape({
      contentSection: PropTypes.string,
      contentType: PropTypes.string,
      hidden: PropTypes.bool,
      name: PropTypes.string,
      order: PropTypes.number,
      parent: PropTypes.string,
      toJS: PropTypes.func.isRequired,
      useAsBg: PropTypes.bool,
    }).isRequired,
    PDFEvents: PropTypes.shape({
      removeEventListener: PropTypes.func.isRequired,
      addEventListener: PropTypes.func.isRequired,
      dispatch: PropTypes.func.isRequired,
    }).isRequired,
    part: PropTypes.number,
  };
  node = null;
  height = 0;
  get renderProps() {
    const { item, part } = this.props;
    let { width, height } = this.node.getBoundingClientRect();
    if (this.node.attributes && this.node.attributes.width) {
      width = parseInt(this.node.attributes.width.value, 10);
    }
    if (this.node.attributes && this.node.attributes.height) {
      height = parseInt(this.node.attributes.height.value, 10);
    }
    return {
      // item from PDFModel
      ...item.toJS(),
      width,
      height,
      top: this.node.dataset.top === undefined ? undefined : (parseInt(this.node.dataset.top, 10) || 0),
      left: parseInt(this.node.dataset.left, 10) || undefined,
      marginTop: parseInt(this.node.dataset.marginTop, 10) || undefined,
      marginTopFirst: parseInt(this.node.dataset.marginTopFirst, 10) || undefined,
      marginBottom: parseInt(this.node.dataset.marginBottom, 10) || undefined,
      marginLeft: parseInt(this.node.dataset.marginLeft, 10) || undefined,
      allowTopOverlap: this.node.dataset.allowTopOverlap || undefined,
      node: this.node,
      parts: parseInt(this.node.dataset.parts, 10) || undefined,
      part: parseInt(this.node.dataset.parts, 10) ? part || 0 : undefined,
    };
  }
  componentDidMount() {
    const { item } = this.props;
    this.hidden = item.hidden;
    const { PDFEvents } = this.props;
    if (!this.node) return;
    const event = new Event(EVENT_ITEM_DID_MOUNT);
    const renderProps = this.renderProps;
    event.data = renderProps;
    this.height = renderProps.height;
    PDFEvents.dispatch(EVENT_ITEM_DID_MOUNT, event);
    PDFEvents.addEventListener(EVENT_LIST_RENDER_PROPS, this.onListRenderProps);
    PDFEvents.addEventListener(EVENT_RENDER_TO_PDF, this.onRenderToPDF);
    PDFEvents.addEventListener(EVENT_APPEND_TO_PDF, this.onAppendToPDF);
  }
  componentDidUpdate() {
    const { PDFEvents, item } = this.props;
    const renderProps = this.renderProps;
    const heightChanged = this.height !== renderProps.height;
    const hiddenChanged = this.hidden !== item.hidden;
    if (hiddenChanged) {
      // console.log('hiddenChanged', item.name, [this.hidden, item.hidden]);
    }
    if (heightChanged) {
      // console.log('heightChanged', item.name, [this.height, renderProps.hidden]);
    }
    if (heightChanged || hiddenChanged) {
      this.hidden = item.hidden;
      // console.warn('height changed', [this.height, renderProps.height], this.props.item.name);
      this.height = renderProps.height;
      const event = new Event(EVENT_ITEM_DID_UPDATE);
      event.data = renderProps;
      PDFEvents.dispatch(EVENT_ITEM_DID_UPDATE, event);
    }
  }
  onListRenderProps = () => {
    const { item } = this.props;
    if (!this.node || !item) {
      console.warn('can\'t count renderProps', { item, node: this.node });
      return;
    }
    return this.renderProps;
  }

  componentWillUnmount() {
    this.unmounted = true;
    const { PDFEvents } = this.props;
    // console.log('componentWillUnmount', this.props.item.name);
    PDFEvents.removeEventListener(EVENT_LIST_RENDER_PROPS, this.onListRenderProps);
    PDFEvents.removeEventListener(EVENT_RENDER_TO_PDF, this.onRenderToPDF);
    PDFEvents.removeEventListener(EVENT_APPEND_TO_PDF, this.onAppendToPDF);
  }

  safeSetState = (...args) => {
    if (this.unmounted) return;
    this.setState(...args);
  }
  // renderToPDF_HTML({ pdf, top, left }) {
  //   return new Promise((resolve) => {
  //     pdf.html(this.node, {
  //       callback: function(/*doc*/) {
  //         // doc.save();
  //         resolve();
  //       },
  //       x: left,
  //       y: top,
  //       autoPaging: false,
  //       margin: [50, 50, 50, 50],
  //     });
  //   });
  // }
  // renderToPDF_SVG({ pdf, item, itemRenderProps }) {
  //   await pdf.svg(item.node, {
  //     width: item.width, height: item.height, x: itemRenderProps.itemLeft, y: itemRenderProps.itemTop });
  // }
  onRenderToPDF = ({ pdf, item, itemRenderProps, page }) => {
    if (item.name !== this.props.item.name || !this.customRenderToPDF) {
      return;
    }
    return new Promise((resolve) => {
      this.customRenderToPDF({ pdf, item, itemRenderProps, page, resolve });
    });
    // CONTENT_TYPE_CUSTOM
    // return this.renderToPDF_HTML({ pdf, top, left, page });
  }
  onAppendToPDF = ({ pdf, item, itemRenderProps, page }) => {
    if (item.name !== this.props.item.name || !this.customAppendToPDF) {
      return;
    }
    return new Promise((resolve) => {
      this.customAppendToPDF({ pdf, item, itemRenderProps, page, resolve });
    });
    // CONTENT_TYPE_CUSTOM
    // return this.renderToPDF_HTML({ pdf, top, left, page });
  }
  ref = (node) => {
    this.node = node;
  };
}
