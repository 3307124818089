import React from 'react';
import I from 'immutable';
import { connect } from 'cpcs-reconnect';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { unsavedFormsSelector } from 'domain/env/EnvModel';
import { passWindowDecorator } from 'lib/envGlobals';
import injectSheet from 'lib/sheet';

const sheet = {
  UnsavedChangesPopup: {
    display: 'none',
  },
};

class UnsavedChangesPopup extends React.PureComponent {
  static propTypes = {
    unsavedForms: PropTypes.instanceOf(I.Set).isRequired,
    window: PropTypes.object.isRequired,
    classes: PropTypes.shape({
      UnsavedChangesPopup: PropTypes.string,
    }).isRequired,
  };

  UNSAFE_componentWillMount() {
    const { window } = this.props;
    window.onbeforeunload = this.unloadListener;
  }

  unloadListener = (event) => {
    const { unsavedForms } = this.props;
    if (unsavedForms.size === 0) {
      return undefined;
    }
    const dialogText = 'You have delayed tasks, please wait until they will be finished';
    event.returnValue = dialogText;
    return dialogText;
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.UnsavedChangesPopup} />
    );
  }
}
UnsavedChangesPopup = compose(
  passWindowDecorator,
  injectSheet(sheet),
)(UnsavedChangesPopup);

export { UnsavedChangesPopup as UnsavedChangesPopupPure };

export default connect({
  unsavedForms: unsavedFormsSelector,
})(UnsavedChangesPopup);
