import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'cpcs-reconnect';
import cx from 'classnames';
import { Field, reduxForm } from 'redux-form';
import { FORM_BUY_ANALYTICS } from 'domain/const';
import { validator, rules } from 'components/form/validation';
import FormRowField from 'pages/common/newPage/form/elements/FormRowField';
import { cardNumberSelector, cardFormOpened_sel } from 'domain/env/EnvModel';
import { orderErrorSelector } from 'pages/common/buyAnalytics/buyAnalyticsModel';
import Checkbox from 'components/form/checkbox';
import Select from 'components/form/select';
import { countriesValidSel } from 'domain/country/CountryModel';


import injectSheet from 'lib/sheet';
import styles from './sheet.js';

class BillingForm extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    cardNumber: PropTypes.string,
    stripeCardToken: PropTypes.string,
    cardFormValid: PropTypes.bool,
    onFormSubmit: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    orderError: PropTypes.string,
    cardFormOpened: PropTypes.bool,
    submitInProgress: PropTypes.bool,
    countryList: PropTypes.any,
  };

  static defaultProps = {
    countryList: [],
  };

  get submitEnabled() {
    const { cardNumber, stripeCardToken, cardFormValid, cardFormOpened, submitInProgress } = this.props;
    // if card form opened & valid OR closed & has card token
    return !submitInProgress && ((!cardFormOpened && cardNumber) || (cardFormOpened && cardFormValid && stripeCardToken));
  }

  onSubmit = (data) => {
    const { stripeCardToken, onFormSubmit, cardFormValid, cardFormOpened } = this.props;
    if (!this.submitEnabled) return;
    const stripeToken = (cardFormOpened && cardFormValid && stripeCardToken) ? stripeCardToken : undefined;
    onFormSubmit({ ...data, stripeToken });
  }

  validation = {
    email: validator(
      rules.cmRequired('Email is required'),
      rules.email,
    ),
    code: rules.cmRequired('Zip code is required'),
    country: rules.cmRequired('Country is required'),
  }

  render() {
    const { classes, cardFormOpened, cardNumber, orderError, submitInProgress, countryList } = this.props;
    return (
      <form
        className={classes.BillingForm}
        onSubmit={this.props.handleSubmit(this.onSubmit)}
      >
        {
          (cardFormOpened || !cardNumber) &&
            <div className={classes.saveCardCheckboxWrp}>
              <Field
                component={Checkbox}
                name="saveCard"
                labelText="Save card for faster checkout"
                modifier="rtv-form--save-card analytics-form--save-card"
              />
            </div>
        }
        <Field
          component={FormRowField}
          modifier="BuyRtvBillingField"
          type="text"
          name="email"
          placeholder="Email"
          rootTag="div"
          validate={this.validation.email}
        />
        <div className={classes.zipCountryWrp}>
          <div className={classes.zipWrp}>
            <Field
              component={FormRowField}
              modifier="BuyRtvBillingField"
              type="text"
              name="code"
              placeholder="Zip code"
              rootTag="div"
              validate={this.validation.code}
            />
          </div>
          <div className={classes.countryWrp}>
            <Field
              name="country"
              placeholder="Country"
              validate={this.validation.country}
              component={FormRowField}
              Field={Select}
              list={countryList}
              isClearable={false}
              modifier="BuyRtvBillingField Country"
              SelectStyleKey="BuyRtvBillingField"
              fixDropdownPadding
            />
          </div>
        </div>
        {
          orderError &&
            <div
              className={classes.orderError}
              children={orderError}
            />
        }
        <button
          type="submit"
          children={submitInProgress ? null : 'Confirm Purchase'}
          className={cx(classes.submit, { loading: submitInProgress })}
          disabled={!this.submitEnabled}
        />
      </form>
    );
  }
}

export default compose(
  injectSheet(styles),
  connect({
    cardNumber: cardNumberSelector,
    orderError: orderErrorSelector,
    cardFormOpened: cardFormOpened_sel,
    countryList: countriesValidSel,
  }),
  reduxForm({
    form: FORM_BUY_ANALYTICS,
  }),
)(BillingForm);
