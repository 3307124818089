import React from 'react';
import { compose } from 'redux';
import { connect } from 'cpcs-reconnect';
import I from 'immutable';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Popup from 'components/popup';
import { isPopupVisibleSelector, getPopupParamsSelector } from 'domain/ui/UIModel';
import { artworkFormPictures_sel } from 'pages/common/artworkForm/ArtworkFormModel';
import { hidePopupAction } from 'domain/ui/UIActions';
import { replacePictureInFormAction } from 'pages/common/artworkForm/ArtworkFormActions';
import { RE_UPLOAD_IMAGE_POPUP } from 'domain/const';
import Title from 'pages/common/popup/title';
import SmartImage from 'components/image/SmartImage';
import ImageUploadZone from 'pages/newArtPage/pictureForm/ImageUploadZone';

import injectSheet from 'lib/sheet';
import sheet from './sheet';

class ReUploadForm extends React.PureComponent {

  static propTypes = {
    classes: PropTypes.shape({
      ReUploadForm: PropTypes.string.isRequired,
      previewImageBox: PropTypes.string.isRequired,
      arrow: PropTypes.string.isRequired,
      buttons: PropTypes.string.isRequired,
      button: PropTypes.string.isRequired,
    }).isRequired,
    cancel: PropTypes.func.isRequired,
    params: PropTypes.instanceOf(I.Collection),
    replacePictureInForm: PropTypes.func.isRequired,
    picturesList: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      preview: PropTypes.string,
      x1: PropTypes.number,
      x2: PropTypes.number,
      y1: PropTypes.number,
      y2: PropTypes.number,
      naturalWidth: PropTypes.number,
      naturalHeight: PropTypes.number,
    })),
  }

  static defaultProps = {
  };

  onCancel = () => {
    this.props.cancel();
  };

  unmounted: false;

  componentWillUnmount() {
    this.unmounted = true;
  };

  state = {
    image: null,
  };

  trySetState = (...args) => {
    if (this.unmounted) return;
    this.setState(...args);
  }

  setImageReplacement = (files) => {
    if (!files || !files.length) {
      return null;
    }
    this.trySetState({ image: files[0] });
  }

  onDone = () => {
    const { replacePictureInForm, params, cancel } = this.props;
    replacePictureInForm({ file: this.state.image, index: params.get('index') });
    cancel();
  }

  render() {
    const { classes, params, picturesList } = this.props;
    const oldFile = picturesList[params.get('index')];
    const oldImageFileName = (oldFile && (oldFile.preview || oldFile.name)) || null;
    const { image } = this.state;
    return (
      <React.Fragment>
        <div className={classes.ReUploadForm}>
          <div className={cx(classes.previewImageBox, 'old')}>
            <SmartImage
              modifier="ReUploadImagePopup"
              src={oldImageFileName}
              container="img"
            />
          </div>
          <div className={classes.arrow} />
          {
            !image &&
              <ImageUploadZone
                onFilesLoaded={this.setImageReplacement}
                multiple={false}
              />
          }
          {
            image &&
              <div className={classes.previewImageBox}>
                <SmartImage
                  modifier="ReUploadImagePopup"
                  src={image.preview}
                  container="img"
                  onClick={() => this.trySetState({ image: null })}
                />
              </div>
          }
        </div>
        <div className={classes.buttons}>
          <button
            type="button"
            onClick={this.onCancel}
            className={cx(classes.button, 'Popup-ReUploadImagePopup cancel')}
            children="cancel"
          />
          <button
            type="button"
            onClick={this.onDone}
            disabled={!image}
            className={cx(classes.button, 'add')}
            children="re-upload image"
          />
        </div>
      </React.Fragment>
    );
  }
}

function ReUploadImagePopup(props) {
  const { classes, isPopupVisible } = props;
  if (!isPopupVisible(RE_UPLOAD_IMAGE_POPUP)) return null;
  return (
    <Popup>
      <div className={classes.container}>
        <Title
          children="Re-upload Image"
          onClose={() => props.cancel()}
        />
        <ReUploadForm
          {...props}
        />
      </div>
    </Popup>
  );
}

ReUploadImagePopup.propTypes = {
  classes: PropTypes.shape({
    container: PropTypes.string,
  }),
  isPopupVisible: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
};

export default compose(
  connect({
    isPopupVisible: isPopupVisibleSelector,
    replacePictureInForm: replacePictureInFormAction,
    cancel: hidePopupAction,
    params: getPopupParamsSelector,
    picturesList: artworkFormPictures_sel,
  }),
  injectSheet(sheet),
)(ReUploadImagePopup);
