const consideringParams = [
  'id',
  'conditions',
  'catalogRaisonne',
  'exhibition',
  'artist',
  'category',
  'substrates',
  'mediums',
  'surfaces',
  'title',
  'unit',
  'width',
  'height',
  'depth',
  'signature',
  'pictures',
  'conceptionYear',
  'edition',
  'numberedNo',
  'numberedOf',
  'editionSize',
  'artistProof',
  'pressProof',
  'sheetHeight',
  'sheetWidth',
  'sheetUnit',
  'stamps',
  'plate',
  'printer',
  'publisher',
  'foundry',
  'inscription',
  'studio',
  'location',
  'docs',
  'galleryId',
  'isCircular',
];

export default [
  ...consideringParams,
  'artWork',
  'newArtist',
  'literature',
];
