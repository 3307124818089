import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { connect, connect as reconnect } from 'cpcs-reconnect';
import { compose } from 'redux';
import I from 'immutable';
import { onFormCancel } from 'pages/common/artworkForm/ArtworkFormOnCancel';
import Loader from 'components/loader/Loader';
import { PageContext } from 'lib/context';
import { FORM_ADD_ART_ITEM } from 'domain/const';

import {
  similarArtworksSel,
} from 'domain/ownArtwork/OwnArtworkModel';
import {
  fillArtworkFormAction,
  submitUnifiedDraftFormAction,
} from 'pages/common/artworkForm/ArtworkFormActions';
import {
  is3D_sel,
  fromSuggestions_sel,
} from 'pages/common/artworkForm/ArtworkFormModel';
import { pageLoadingSel, extraFooterGap_sel } from 'domain/ui/UIModel';
import { categoryList } from 'domain/category/CategoryModel';

import Header from 'pages/common/newPage/header';
import PictureForm from 'pages/newArtPage/pictureForm';
import Suggestions from 'pages/newArtPage/suggestions';
import files from 'components/Files';
import NewAuthorPopup from 'pages/newArtPage/newAuthorPopup';
import ArtItemForm from 'pages/addArtUnifiedPage/itemForm';
import PageContainer from 'pages/common/pageContainer';

// helpers
import { CREATE_INIT_DATA } from 'domain/ownArtwork/artworkSerializer';

import injectSheet from 'lib/sheet';
import styles from './sheet.js';

const SuggestionsContainer = reconnect({ list: similarArtworksSel, fillArtworkForm: fillArtworkFormAction })(Suggestions);

const Files = files('addArtItem');

class AddArtUnifiedPage extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    suggestionSelected: PropTypes.bool,
    categoryList: PropTypes.instanceOf(I.List),
    is3D: PropTypes.bool,
    loading: PropTypes.string,
    saveDraft: PropTypes.func.isRequired,
    onFormCancel: PropTypes.func.isRequired,
    extraFooterGap: PropTypes.bool,
  };

  render() {
    const { classes, suggestionSelected, categoryList, is3D, loading, saveDraft, extraFooterGap } = this.props;
    return (
      <PageContext.Provider value={'addArtUnifiedPage'}>
        <PageContainer modifier={cx('addArtUnifiedPage', { extraFooterGap })}>
          <Header
            title={null}
            submitName={FORM_ADD_ART_ITEM}
            onCancel={this.props.onFormCancel}
            saveDraft={!suggestionSelected ? () => saveDraft() : null}
            modifier="addArtUnifiedPage"
          />
          <div className={classes.form}>
            <div className="PAGE_INNER_COL1 newArtPageCol1">
              <PictureForm
                allowChangeMain={!suggestionSelected}
                allowRemoveMain={!suggestionSelected}
                readOnly={false}
              />
              <Files />
              <SuggestionsContainer />
            </div>
            <Loader loading={!(!loading && categoryList && !!categoryList.size)} modifier="AddArtUnifiedPage">
              <ArtItemForm
                initialValues={{ ...CREATE_INIT_DATA }}
                isEdit={false}
                suggestionSelected={suggestionSelected}
                is3D={is3D}
              />
            </Loader>
          </div>{/*<!-- /.form -->*/}
          <NewAuthorPopup />
        </PageContainer>
      </PageContext.Provider>
    );
  }
}

export default compose(
  injectSheet(styles),
  onFormCancel(),
  connect({
    suggestionSelected: fromSuggestions_sel,
    is3D: is3D_sel,
    categoryList,
    loading: pageLoadingSel,
    saveDraft: submitUnifiedDraftFormAction,
    extraFooterGap: extraFooterGap_sel,
  }),
)(AddArtUnifiedPage);
