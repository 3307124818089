import { put, call, select, takeEvery } from 'redux-saga/effects';
import I from 'immutable';

import { parseServerError } from 'lib/helpers';
import { reduceCallFrequency } from 'lib/saga-helpers';
import { pathnameChanged as pathnameChangedSelector } from 'domain/router/RouterModel';
import * as A from 'domain/alerts/AlertsActions';
import * as M from 'domain/alerts/AlertsModel';
import Api, { makeApiGenerator } from 'domain/api';
import { addNotification } from 'domain/env';
import { TYPE_ALERT_REMOVE } from 'domain/const';

// { page, ...query }
export const fetchAlerts_apiGen = makeApiGenerator({
  actionCreator: A.fetchAlertsAction,
  api: Api.getAlerts,
});

// { page, ...query }
export const alertsListAppendPage_apiGen = reduceCallFrequency(makeApiGenerator({
  actionCreator: A.alertListAppendPageAction,
  api: Api.getAlerts,
}), 2100);

function* getItem() {
  const item = yield select(M.alertsDetailSelector);
  return item || new I.Map();
}

// { alertId, ...queryParams, page }
export const ensureAlertItem_apiGen = makeApiGenerator({
  actionCreator: A.fetchAlertItem,
  api: Api.getAlertItem,
  returnValue: getItem,
});
// { alertId, ...queryParams, page }
export const alertItemsAppendPage_apiGen = reduceCallFrequency(makeApiGenerator({
  actionCreator: A.alertItemsAppendPageAction,
  api: Api.getAlertItem,
  returnValue: getItem,
}), 2100);

// { data }
export const createAlert_apiGen = makeApiGenerator({
  actionCreator: A.manageAlertItem,
  api: Api.createAlert,
});

// { alertId, data }
export const updateAlert_apiGen = makeApiGenerator({
  actionCreator: A.manageAlertItem,
  api: Api.updateAlert,
});

// { data }
const createAlertByArtist_apiGen = makeApiGenerator({
  actionCreator: A.createByArtistIdAction,
  api: Api.createAlert,
});

function* onCreateByArtistId({ payload: artist }) {
  try {
    const requestData = {
      artist: artist.id,
    };
    yield call(createAlertByArtist_apiGen, { data: requestData });
    yield put({
      type: A.manageAlertItem.success,
      payload: { artist },
    });
    yield put(addNotification({
      title: 'Alert was created.',
      type: 'success',
      restore: { type: TYPE_ALERT_REMOVE, params: { alertId: artist.id } },
    }));
  } catch (err) {
    const { message } = parseServerError(err);
    if (message === 'You already have alert with this artist!') {
      yield put(addNotification({ title: message, type: 'error' }));
    }
    yield put({
      type: A.manageAlertItem.failure,
    });
  }
}

const searchAlertByArtistId_apiGen = makeApiGenerator({
  api: Api.searchAlertByArtistId,
  actionCreator: A.searchAlertByArtistIdAction,
});

export function* watchArtistAddedToAlertNeedRecheck() {
  const pathnameChanged = yield select(pathnameChangedSelector);
  if (pathnameChanged) {
    yield put(A.artistAddedToAlertNeedRecheckAction());
  }
}

export function* watchCreateAlertByArtistId(artistId) {
  yield takeEvery(A.createByArtistIdAction.type, onCreateByArtistId);
  const pathnameChanged = yield select(pathnameChangedSelector);
  if (pathnameChanged && artistId) {
    yield call(searchAlertByArtistId_apiGen.catchError, { artistId });
  }
}
