import { fieldset, formItem, fieldWrapper, label, selectWrapper } from 'theme/form';
import {
  LAYOUT_RANGE_425_MAX,
  LAYOUT_RANGE_1024_MIN,
  LAYOUT_RANGE_1024_MAX,
} from 'theme/layout/LAYOUT';

export default {
  fieldset,
  formItem,
  label,
  fieldWrapper,
  selectWrapper,
  editionSizeWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    marginLeft: 145,
    width: 300,
    [`@media screen and (min-width: ${LAYOUT_RANGE_1024_MIN}px) and (max-width: ${LAYOUT_RANGE_1024_MAX}px)`]: {
      marginLeft: 0,
    },
    [`@media screen and (max-width: ${LAYOUT_RANGE_425_MAX}px)`]: {
      marginLeft: 0,
    },
  },
};
