import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { Field } from 'redux-form';
import { reduxForm, SubmissionError } from 'redux-form';
import cx from 'classnames';
import { createGalleryAction } from 'domain/galleries/GalleryActions';
import { Buttons } from 'pages/common/popupForms/element/Buttons';
import { createErrorMessage } from 'components/form/validation';

import rules, { validator } from 'components/form/validation';
import FormRowField from 'pages/common/newPage/form/elements/FormRowField';

import injectSheet from 'lib/sheet';
import sheet from './sheet';

const submit = (data, dispatch, { addSelected }) => {
  const submitPromise = new Promise((resolve, reject) => {
    dispatch({
      type: createGalleryAction.type,
      payload: data,
      addSelected,
      resolve,
      reject,
    });
  });
  return submitPromise.catch((err) => {
    if (err) {
      if (!err.response.data.errors) {
        throw new SubmissionError({
          title: err.response.data.message,
          _error: err.response.data.message,
        });
      }
      const { errors, message } = err.response.data;
      throw new SubmissionError({
        ...createErrorMessage(errors),
        _error: message,
      });
    }
  });
};

class CreateForm extends React.PureComponent {

  static propTypes = {
    classes: PropTypes.shape({
      createGalleryForm: PropTypes.string.isRequired,
    }).isRequired,
    cancel: PropTypes.func,
    handleSubmit: PropTypes.func.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    addSelected: PropTypes.bool,
    modifier: PropTypes.string,
  }

  validate = {
    title: validator(rules.required, rules.strMinLength(2), rules.strMaxLength(100)),
  };

  render() {
    const { classes, cancel, modifier } = this.props;
    return (
      <form onSubmit={this.props.handleSubmit(submit)} className={cx(classes.createGalleryForm, modifier)}>
        <Field
          type="text"
          name="title"
          component={FormRowField}
          rootTag="div"
          placeholder="Gallery title"
          modifier="Gallery-Title"
          validate={this.validate.title}
          setFocusOnMount
        />
        {
          cancel &&
            <Buttons
              cancel={cancel}
              modifier="Popup-CreateGallery"
              submit="Save"
            />
        }
      </form>
    );
  }
}

export default compose(
  reduxForm({
    form: 'create-gallery',
  }),
  injectSheet(sheet),
)(CreateForm);
