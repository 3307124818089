import { socialIcon } from '../theme';
import bg from './images/fb.svg';
import bgHover from './images/fbHover.svg';

export default {
  FbLoginButton: {
    extend: socialIcon,
    border: '1px solid #335190',
    background: {
      image: `url("${bg}")`,
      size: '13px 26px',
      repeat: 'no-repeat',
      position: 'center',
    },
    '&:hover': {
      background: `#335190 url("${bgHover}") center no-repeat`,
    },
  },
};
