import React from 'react';
import PropTypes from 'prop-types';
import I from 'immutable';
import { compose } from 'redux';
import { connect } from 'cpcs-reconnect';

import Details from 'pages/common/sharedItemsPage/Details';
import Header from 'pages/common/sharedItemsPage/Header';
import NotAccurateMessage from 'pages/common/NotAccurate';

import { Slider } from 'components/form/dropzone/slider';

import { getCurrentShareSelector, sharedArtworkSelector } from 'domain/shares';
import { categoryList_sel } from 'domain/category/CategoryModel';
import { oneOfFieldHasValue } from 'pages/common/artworkForm/ArtworkFormModel';
import PageContainer from 'pages/common/pageContainer';

import Property from 'pages/common/property';
import Fieldset from 'components/fieldset';
import Breadcrumbs from 'pages/common/breadcrumbs';

import { serializer, serialGroups, getDetails } from 'pages/common/artworkView';

const serializerGroup1 = serializer.select(...serialGroups.serializerGroup1Fields);

class SharedArtworkPage extends React.PureComponent {
  static propTypes = {
    item: PropTypes.instanceOf(I.Record),
    share: PropTypes.instanceOf(I.Record).isRequired,
    categoryList: PropTypes.instanceOf(I.Collection),
  };

  render() {
    const { share, item, categoryList } = this.props;
    if (!share || !share.get('id') || !item || !item.get('id')) return null;
    const pictures = item.get('pictures').toJS() || [];
    const itemCategory = item.get('category');
    const serializerDetails = getDetails({ categoryList, category: itemCategory });
    const serializerProps = { hideRtvButton: true };
    return (
      <PageContainer modifier="sharedArtWorkPage">
        <Breadcrumbs />
        <Details
          share={share}
        />
        <Header sharedArtWorkPage />
        <NotAccurateMessage artwork={item} />
        <div className="PAGE_SECTION sharedArtWorkPage">
          <div className="PAGE_INNER_COL1 sharedArtWorkPage">
            <Slider
              files={pictures}
              readOnly
            />
          </div>
          <div className="PAGE_INNER_COL2 sharedArtWorkPage">
            <Property data={item} serializer={serializerGroup1} props={serializerProps} />
            {
              (serializerDetails && oneOfFieldHasValue(item, serializerDetails)) && (
                <Fieldset title="Details" RootTag="section">
                  <Property data={item} serializer={serializerDetails} props={serializerProps} />
                </Fieldset>
              )
            }
            {
              oneOfFieldHasValue(item, serialGroups.serializerProvenance) &&
                <Fieldset title="Provenance & Sales" RootTag="section">
                  <Property data={item} serializer={serialGroups.serializerProvenance} props={serializerProps} />
                </Fieldset>
            }
            {
              oneOfFieldHasValue(item, serialGroups.serializerExhibition) &&
                <Fieldset title="Exhibitions & Literature" RootTag="section">
                  <Property data={item} serializer={serialGroups.serializerExhibition} props={serializerProps} />
                </Fieldset>
            }
          </div>
        </div>
      </PageContainer>
    );
  }
}

export default compose(
  connect({
    item: sharedArtworkSelector,
    share: getCurrentShareSelector,
    categoryList: categoryList_sel,
  }),
)(SharedArtworkPage);
