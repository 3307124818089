import React, { useCallback, useRef, useEffect, useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'cpcs-reconnect';
import { token_sel } from 'domain/env/EnvModel';
import { Link } from 'react-router-dom';

import { lnk } from 'lib/routes';
import Fieldset from 'components/fieldset';
import { Definition } from 'components/reports/Definition';
import API from 'domain/api';
import { ILLIQUID } from 'domain/const';
// PDF
import { useListen } from 'pages/highcharts/helpers/EventsContext';
import { EVENT_GET_PAGE_COMPONENT } from 'pages/highcharts/helpers/PDFConstants';

import { DEFAULT_FONTFAMILY_3 } from 'theme/theme';
import injectSheet from 'lib/sheet';

const sheet = {
  ArtistRankings: {
    // root
  },
  title: {
    marginTop: 65,
    font: `700 24px/32px ${DEFAULT_FONTFAMILY_3}`,
    color: '#000',
  },
  description: {
    font: `400 16px/24px ${DEFAULT_FONTFAMILY_3}`,
    color: '#737992',
    maxWidth: 470,
  },
  rows: {
    marginTop: 85,
  },
  ArtistRankingsRow: {
    display: 'grid',
    gridTemplateColumns: '810px 1px 1fr',
    columnGap: 80,
    paddingBottom: 64,
    '&:last-child': {
      paddingBottom: 0,
    },
    '@media screen and (max-width: 1360px)': {
      display: 'block',
    },
  },
  ranksWrapper: {
    display: 'grid',
    gridTemplateColumns: '180px 1fr',
    columnGap: 90,
    '@media screen and (max-width: 1360px)': {
      width: 775,
      columnGap: 60,
    },
    '@media screen and (max-width: 1200px)': {
      width: 'auto',
      columnGap: 100,
      gridTemplateColumns: '108px 1fr',
    },
    '@media screen and (max-width: 610px)': {
      columnGap: 68,
    },
    '@media screen and (max-width: 510px)': {
      display: 'block',
    },
  },
  border: {
    backgroundColor: '#C1C7DE',
  },
  definitions: {
    maxHeight: '310px',
    overflowY: 'auto',
    overflowX: 'hidden',
    // fix to avoid cutting focused state outline of definition collapse button
    '&.scrollableDefinitions': {
      transform: 'translate(-3px, 0)',
    },
    '@media screen and (max-width: 1360px)': {
      width: 804,
    },
    '@media screen and (max-width: 1200px)': {
      width: 'auto',
    },
  },
  rankTitle: {
    font: `700 22px/32px ${DEFAULT_FONTFAMILY_3}`,
    color: '#000',
    transform: 'translate(-10px, 0)',
    '&.place': {
      transform: 'unset',
      color: '#8C92AB',
      font: `500 18px/22px ${DEFAULT_FONTFAMILY_3}`,
      textAlign: 'center',
      marginTop: 15,
    },
    '@media screen and (max-width: 1200px)': {
      font: `700 16px/24px ${DEFAULT_FONTFAMILY_3}`,
      '&.place': {
        font: `500 14px/16px ${DEFAULT_FONTFAMILY_3}`,
        marginTop: 9,
      },
    },
    '@media screen and (max-width: 510px)': {
      marginTop: 32,
      '&.place': {
        marginTop: 9,
      },
    },
  },
  placeWrapper: {
    display: 'flex',
    flexDirection: 'column',
    '@media screen and (max-width: 510px)': {
      width: 108,
      margin: [0, 'auto'],
    },
  },
  place: {
    width: 164,
    height: 164,
    backgroundColor: '#F8F6F5',
    borderRadius: '50%',
    paddingTop: 42,
    '@media screen and (max-width: 1200px)': {
      width: 108,
      height: 108,
      paddingTop: 27,
    },
  },
  placeNumber: {
    color: '#304C95',
    font: `700 48px/48px ${DEFAULT_FONTFAMILY_3}`,
    textAlign: 'center',
    '&.table': {
      font: `500 16px/24px ${DEFAULT_FONTFAMILY_3}`,
      color: '#8C92AB',
      textAlign: 'left',
      '&.current': {
        fontWeight: 700,
        color: '#454A5F',
      },
    },
    '@media screen and (max-width: 1200px)': {
      fontSize: 31,
      lineHeight: '31px',
    },
  },
  total: {
    font: `700 16px/24px ${DEFAULT_FONTFAMILY_3}`,
    color: '#1E202C',
    marginTop: 8,
    textAlign: 'center',
    '@media screen and (max-width: 1200px)': {
      fontSize: 11,
      lineHeight: '14px',
    },
  },
  placeTableWrapper: {},
  placeTable: {
    display: 'grid',
    gridTemplateColumns: 'min-content 1fr max-content',
    columnGap: 10,
    marginTop: 30,
  },
  artistName: {
    font: `500 16px/24px ${DEFAULT_FONTFAMILY_3}`,
    color: '#8C92AB',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    minWidth: 0,
    overflow: 'hidden',
    '&.current': {
      fontWeight: 700,
      color: '#454A5F',
    },
  },
  artistLink: {
    backgroundColor: '#F0F2F6',
    font: `700 14px/14px ${DEFAULT_FONTFAMILY_3}`,
    color: '#8C92AB',
    padding: [4, 15, 5],
    borderRadius: 6,
    cursor: 'pointer',
    textDecoration: 'none',
    '&.current': {
      visibility: 'hidden',
      pointerEvents: 'none',
    },
  },
  artistDelimiter: {
    height: 1,
    backgroundColor: '#F0F2F6',
    margin: [5, 0],
    gridColumn: '1 / 4',
    '&.current:before': {
      display: 'block',
      content: '""',
      width: 'calc(100% + 5px)',
      height: '29px',
      transform: 'translate(-10px, -33px)',
      border: '2px solid #A6ACC4',
      borderRadius: 23,
      pointerEvents: 'none',
    },
  },
};
export const RANKS = {
  REPEAT_SALES_RANKING: {
    definitions: ['T002_REPEAT_SALES_RANKING_DEFINITION', 'T002_REPEAT_SALES_RANKING_INTERPRETATION'],
    id: 't002',
    title: 'Repeat Sales Ranking',
    hideIlliquid: false,
  },
  CAGR_RANKING: {
    definitions: ['T003_CAGR_RANKING_DEFINITION', 'T003_CAGR_RANKING_INTERPRETATION'],
    id: 't003',
    title: 'CAGR Ranking',
    hideIlliquid: true,
  },
  RISK_RATING: {
    definitions: ['T004_RISK_RANKING_DEFINITION', 'T004_RISK_RANKING_INTERPRETATION'],
    id: 't004',
    title: 'Risk Ranking',
    hideIlliquid: true,
  },
  ARTIST_SELL_THROUGH_RANKING: {
    definitions: ['T005_SELL_THROUGH_RATE_RANKING_DEFINITION', 'T005_SELL_THROUGH_RATE_RANKING_INTERPRETATION'],
    id: 't005',
    title: 'Sell-through Rate Ranking',
    hideIlliquid: false,
  },
};
export const rowIsValid = row => row && row.rank && row.artists && row.artists.length;
export const rowIlliquid = ({ row, liquidity }) => !row ||
  (
    (RANKS[row.slug] && RANKS[row.slug].hideIlliquid) &&
    (!liquidity || !liquidity.liquidity || liquidity.liquidity === ILLIQUID)
  );
export const rowTitle = row => (RANKS[row.slug] && RANKS[row.slug].title) || row.slug;

function ArtistRankingsRow({ classes, row, liquidity }) {
  if (!rowIsValid(row) || rowIlliquid({ row, liquidity })) {
    return null;
  }
  const title = rowTitle(row);
  return (
    <div className={classes.ArtistRankingsRow}>
      <div className={classes.ranksWrapper}>
        <div className={classes.placeWrapper}>
          <div className={classes.place}>
            <div className={classes.placeNumber}>{row.rank}</div>
            <div className={classes.total}>of {row.total_rank}</div>
          </div>
          <div className={cx(classes.rankTitle, 'place')}>{title}</div>
        </div>
        <div className={classes.placeTableWrapper}>
          <div className={cx(classes.rankTitle)}>{title}</div>
          <div className={classes.placeTable}>
            {
              row.artists.map((artist, index) => (
                <React.Fragment
                  key={`${index}-${artist.id}`}
                >
                  <div className={cx(classes.placeNumber, 'table', { current: artist.active })}>{artist.rank}</div>
                  <div className={cx(classes.artistName, { current: artist.active })}>{artist.full_name}</div>
                  <Link
                    to={lnk('artistPage', { authorId: artist.webapp_id || artist.id })}
                    className={cx(classes.artistLink, { current: artist.active })}
                    children="View Artist Page"
                    target="_blank"
                  />
                  <div className={cx(classes.artistDelimiter, { current: artist.active })} />
                </React.Fragment>
              ))
            }
          </div>
        </div>
      </div>
      <div className={classes.border} />
      <div className={cx(classes.definitions, 'scrollableDefinitions')}>
        <Fieldset
          title="About this ranking"
          RootTag="section"
          modifier="artist_ranking_definitions scrollableDefinitions"
        >
          {
            ((RANKS[row.slug] && RANKS[row.slug].definitions) || []).map(definition => (
              <Definition
                key={`${row.slug}-${definition}`}
                id={definition}
              />
            ))
          }
        </Fieldset>
      </div>
    </div>
  );
}
ArtistRankingsRow.propTypes = {
  row: PropTypes.shape({
    rank: PropTypes.number,
    total_rank: PropTypes.number,
    slug: PropTypes.string.isRequired,
    artists: PropTypes.array.isRequired,
    id: PropTypes.number,
    webapp_id: PropTypes.number,
  }).isRequired,
  classes: PropTypes.shape({
    ArtistRankingsRow: PropTypes.string.isRequired,
    ranksWrapper: PropTypes.string.isRequired,
    placeWrapper: PropTypes.string.isRequired,
    place: PropTypes.string.isRequired,
    placeNumber: PropTypes.string.isRequired,
    total: PropTypes.string.isRequired,
    rankTitle: PropTypes.string.isRequired,
    placeTableWrapper: PropTypes.string.isRequired,
    placeTable: PropTypes.string.isRequired,
    artistName: PropTypes.string.isRequired,
    artistLink: PropTypes.string.isRequired,
    artistDelimiter: PropTypes.string.isRequired,
    border: PropTypes.string.isRequired,
    definitions: PropTypes.string.isRequired,
  }).isRequired,
  liquidity: PropTypes.shape({
    liquidity: PropTypes.string,
  }),
};

function ArtistRankings_NoTheme({ classes, token, artistId, liquidity }) {
  // eslint-disable-next-line no-unused-vars
  const [rows, setRows] = useState([]);
  const ref = useRef({});
  useEffect(() => {
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      ref.current.unmounted = true;
    };
  }, [ref]);
  useEffect(() => {
    const fetchData = async () => {
      const resp = await API.getArtistRankingTable({ token, artistId });
      if (!resp.data || ref.current.unmounted) return;
      setRows(resp.data);
    };
    fetchData();
  }, [ref, token, artistId]);

  // pdf Events
  const onGetComponent = useCallback((event) => {
    if (event.id && event.id === 'ArtistRankings') {
      event.isPropagationStopped = true;
      return { item: rows, liquidity };
    }
  }, [rows, liquidity]);
  useListen(EVENT_GET_PAGE_COMPONENT, onGetComponent);

  return (
    <div className={classes.ArtistRankings}>
      <h3 className={classes.title}>Artist Rankings</h3>
      <div className={classes.description}>This section provides an overview of where artists rank in terms of key performance indicators.</div>
      <div className={classes.rows}>
        {
          rows.map(row => (
            <ArtistRankingsRow
              row={row.data}
              key={row.data.slug}
              classes={classes}
              liquidity={liquidity}
            />
          ))
        }
      </div>
    </div>
  );
}

ArtistRankings_NoTheme.propTypes = {
  classes: PropTypes.shape({
    ArtistRankings: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    rows: PropTypes.string.isRequired,
  }).isRequired,
  token: PropTypes.string.isRequired,
  artistId: PropTypes.string.isRequired,
  liquidity: PropTypes.shape({
    liquidity: PropTypes.string,
  }),
};

export const ArtistRankings = compose(
  injectSheet(sheet),
  connect({
    token: token_sel,
  }),
)(ArtistRankings_NoTheme);
