import I from 'immutable';
import { asyncAction, action } from 'lib/actions';
import { createAlertFields as fields } from 'domain/alerts/createAlertFields';
import {
  createSerializer,
  simpleSerializer,
  createFieldMappersHashtable,
} from 'lib/serialize';
import {
  tagToArray,
} from 'lib/helpers';

export const createAlertData = data => ({
  title: data[fields.alertTitle.name],
  filter: {
    ...createSerializer({
      ...createFieldMappersHashtable(
        Object.keys(fields)
          .filter(k =>
            // exclude:
            // - unsupported fields by API
            // - fields with incompatible name (e.g. signature -> hasSignature)
            ![
              fields.alertTitle.name,
            ].includes(fields[k].name),
          )
          .map(k => fields[k].name),
      ),
      [fields.provenance.name]: v => [fields.provenance.name, tagToArray(v)],
      [fields.artTitle.name]: v => [fields.artTitle.name, tagToArray(v)],
      [fields.catalogRaisonne.name]: v => [fields.catalogRaisonne.name, tagToArray(v)],
      [fields.exhibition.name]: v => [fields.exhibition.name, tagToArray(v)],
    })(data),
  },
});

const dict = name => ({
  [name]: v => [name, new I.List(v.map(e => e.id))],
});

const list = name => ({
  [name]: v => [name, new I.List([].concat(v))],
});

const tag = name => ({
  [name]: v => [name, v.join('#')],
});

const filterSerializer = {
  ...createFieldMappersHashtable([
    fields.bornIn.name,
    fields.diedIn.name,
    fields.yearCreatedFrom.name,
    fields.yearCreatedTo.name,
    fields.widthTo.name,
    fields.heightTo.name,
    fields.depthTo.name,
    fields.height.name,
    fields.width.name,
    fields.depth.name,
    fields.unit.name,
    fields.signature.name,
  ]),
  ...list(fields.artists.name),
  ...tag(fields.catalogRaisonne.name),
  ...tag(fields.exhibition.name),
  ...tag(fields.artTitle.name),
  ...tag(fields.provenance.name),
  ...dict(fields.residence.name),
  ...dict(fields.categories.name),
  ...dict(fields.substrates.name),
  ...dict(fields.surfaces.name),
};

export const serialFilter = simpleSerializer(filterSerializer);

export const alertItemsAppendPageAction = asyncAction('alerts/ITEMS_APPEND_PAGE');
export const fetchAlertsAction = asyncAction('alerts/GET_LIST');
export const alertListAppendPageAction = asyncAction('alerts/ALERT_LIST_APPEND_PAGE');
export const fetchAlertItem = asyncAction('alerts/GET_ITEM');
export const manageAlertItem = asyncAction('alerts/MANAGE_ITEM');
export const createByArtistIdAction = asyncAction('alerts/CREATE_BY_ARTIST_ID');
export const searchAlertByArtistIdAction = asyncAction('alerts/SEARCH_BY_ARTIST_ID');
export const artistAddedToAlertNeedRecheckAction = action('alerts/ARTIST_IN_ALERT_RECHECK');
export const updateSelectedAction = action('alerts/UPDATE_SELECTED');
export const setSelectedAction = action('alerts/SET_SELECTED');
export const clearSelectedAction = action('alerts/CLEAR_SELECTED');
