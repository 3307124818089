import close from './close.svg';
import { DARK_BLUE_COLOR, NEW_STYLE } from 'theme/theme';

export const popupComponents = {
  container: {
    padding: [50, 40, 63],
    position: 'relative',
    backgroundColor: '#FFF',
    '&.getRTV': {
      width: 430,
    },
    '&.deleteArtistConfirm': {
      width: 430,
    },
    '&.addToGalleryPopup': {
      width: 440,
    },
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 20,
    '&.noText': {
      marginBottom: 0,
    },
    '&.select-comparables-popup': {
      display: 'grid',
      width: '100%',
      gridTemplateColumns: 'max-content auto minmax(30px, 5%)',
      gridTemplateAreas: '"title actions close" "description actions close"',
      '@media screen and (max-width: 1460px)': {
        gridTemplateAreas: '"actions actions close" "title title title" "description description description"',
        gridTemplateColumns: 'min-content auto minmax(30px, 5%)',
      },
      '@media screen and (max-width: 925px)': {
        gridTemplateAreas: '"close" "actions" "title" "description"',
        gridTemplateColumns: 'auto',
      },
    },
  },
  title: {
    fontSize: 24,
    fontWeight: 600,
    color: DARK_BLUE_COLOR,
    gridArea: 'title',
    '&.title-spinner': {
      display: 'flex',
      '&:after': {
        marginLeft: 20,
        display: 'block',
        content: '""',
        width: 22,
        height: 22,
        border: `4px solid ${NEW_STYLE}`,
        borderRadius: '50%',
        borderTop: '4px solid transparent',
        transform: 'rotate(45deg)',
        animation: '$processing-popup-title-spinner 1s linear infinite',
      },
    },
    '&.filesForm': {
      fontWeight: 700,
      lineHeight: '30px',
    },
  },
  '@keyframes processing-popup-title-spinner': {
    'from': { transform: 'rotate(0deg)' },
    'to': { transform: 'rotate(360deg)' },
  },
  description: {
    fontSize: 14,
    padding: [15, 0],
    '&.select-comparables-popup': {
      gridArea: 'description',
      padding: 0,
      whiteSpace: 'nowrap',
      minWidth: 0,
    },
  },
  close: {
    width: 24,
    height: 24,
    flex: '0 0 24px',
    background: `url("${close}") scroll no-repeat center`,
    marginLeft: 'auto',
    gridArea: 'close',
  },
};

export const SharePopup = {
  SharePopup: {
    // root
  },
  ...popupComponents,
  container: {
    ...popupComponents.container,
    maxWidth: 420,
    width: '90vw',
  },
};

export default {
  ...popupComponents,
  container: {
    ...popupComponents.container,
    width: 390,
  },
};
