import { select, takeEvery, call, fork } from 'redux-saga/effects';

import { reduceCallFrequency } from 'lib/saga-helpers';
import * as Routing from 'domain/router/RouterModel';
import {
  fetchLotsAppendPageAction,
  fetchLotsAction,
} from 'pages/saleLotsListPage/SaleLotsListPageActions';
import {
  advancedFilterPageInit,
} from 'domain/advancedFilter/sagas';
import {
  pagination_sel,
} from 'pages/saleLotsListPage/SaleLotsListPageModel';
import Api, { makeApiGenerator } from 'domain/api';
import { DEFAULT_PAGE_SIZE } from 'domain/const';

// getAuctionSaleLots
const fetchLotsListAppendPage_apiGen = reduceCallFrequency(makeApiGenerator({
  actionCreator: fetchLotsAppendPageAction,
  api: Api.getAuctionSaleLots,
}), 2100);
const fetchLotsList_apiGen = makeApiGenerator({
  actionCreator: fetchLotsAction,
  api: Api.getAuctionSaleLots,
});

function* fetchArtworks(page = 0) {
  const filter = yield select(Routing.filterParamsSelector);
  let { auctionId, saleId } = yield select(Routing.routerParams_sel);
  saleId = decodeURIComponent(saleId);
  if (!auctionId || !saleId) return;
  try {
    if (page > 0) {
      yield call(fetchLotsListAppendPage_apiGen, { ...filter, saleId, auctions: [auctionId], page, size: DEFAULT_PAGE_SIZE });
    } else {
      yield call(fetchLotsList_apiGen, { ...filter, saleId, auctions: [auctionId], page, size: DEFAULT_PAGE_SIZE });
    }
  } catch (err) {
    console.error(err);
  }
}

export function* onPagination() {
  const { page, loading } = yield select(pagination_sel);
  if (loading) return;
  yield call(fetchArtworks, page + 1);
}

export default function* () {
  yield takeEvery(fetchLotsAppendPageAction.type, onPagination);
  yield call(fetchArtworks);
  yield fork(advancedFilterPageInit);
}
