import {
  SHARE_TYPE_GALLERY,
  SHARE_TYPE_OWN_ARTWORK,
  SHARE_TYPE_ARTWORK,
  SHARE_TYPE_CONSIDERATION,
  SHARE_TYPE_ALERT,
  LINK_NO_AUTHOR_ID_PARAM,
} from 'domain/const';

export const shareLink = (self, paramVals) => {
  const { type, sharedWithMe } = paramVals;
  const prevPageQuery = sharedWithMe ? '&prevPage=sharedWithMePage' : '';
  switch (type) {
    case SHARE_TYPE_GALLERY:
      return self._doBuild(paramVals) + '?type=galleries' + prevPageQuery;
    case SHARE_TYPE_OWN_ARTWORK:
      return self._doBuild(paramVals) + '?type=artworks' + prevPageQuery;
    case SHARE_TYPE_ARTWORK:
    case 'stack':
      return self._doBuild(paramVals) + '?type=stack' + prevPageQuery;
    case SHARE_TYPE_CONSIDERATION:
      return self._doBuild(paramVals) + '?type=considerations' + prevPageQuery;
    case SHARE_TYPE_ALERT:
      return self._doBuild(paramVals) + '?type=alert-artworks' + prevPageQuery;
    default:
      return '';
  }
};

export const collectionItemPage = (self, paramVals) => {
  const { gId, authorId } = paramVals;
  if (gId) {
    return self._doBuild({ ...paramVals, authorId: authorId || LINK_NO_AUTHOR_ID_PARAM });
  }
  /**
   * this should never heppen
  **/
  console.warn(`no gId for "${self.name}" (${self.link}) link builder.`);
  return '/';
};

export const trackingLink = (self, paramVals) => {
  const { gId, authorId } = paramVals;
  if (gId) {
    return self._doBuild({ ...paramVals, authorId: authorId || LINK_NO_AUTHOR_ID_PARAM });
  }
  /**
   * this should never heppen
  **/
  console.warn(`no gId for "${self.name}" (${self.link}) link builder. `);
  return '/';
};
