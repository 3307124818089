import rules, { addError } from 'components/form/validation';
import { compose } from 'redux';

const fields = ['newPassword'];

const validator = values => (errors, fieldName) => {
  const val = values[fieldName];
  switch (fieldName) {
    case 'newPassword':
      return compose(
        err => addError(err, fieldName, rules.minLength(val)),
        err => addError(err, fieldName, rules.required(val)),
      )(errors);
    default:
      return errors;
  }
};

export default values => fields.reduce(validator(values), {});
