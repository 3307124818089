/* eslint no-unused-vars: warn */
import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'cpcs-reconnect';

import { provenanceItemToStr } from 'pages/common/artworkView/solvers/provenanceSolver';
import { countriesById_sel } from 'domain/country/CountryModel';

import { splitTextToLines } from 'pages/highcharts/helpers/Text';
import { connectEventsContainer } from 'pages/highcharts/helpers/EventsContext';
import { RefSize } from 'pages/highcharts/helpers/refSize';
import { DEFAULT_FONTFAMILY } from 'pages/highcharts/helpers/PDFConstants';

const BASE_HEIGHT = 40;
const LINE_HEIGTH = 18;
const TEXT_TOP = BASE_HEIGHT + 15;

class ProvenancePDF extends RefSize {
  static propTypes = {
    PDFState: PropTypes.shape({
    }).isRequired,
  };

  render() {
    const { artwork, countries } = this.props;
    const lines = (artwork.provenance || [])
      .map(v => provenanceItemToStr(v, countries))
      .reduce((arr, str) => [...arr, ...splitTextToLines(str, 60)], []);
      // .map(str => splitTextToLines(str, 5));
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="350"
        height={TEXT_TOP + lines.length * LINE_HEIGTH}
        ref={this.ref}
        data-margin-top="32"
        data-left="240"
      >
        <style>
          {`
          .ProvenancePDF_title { font: 700 14px ${DEFAULT_FONTFAMILY}; fill: #454A5F; }
          .ProvenancePDF_text { font: 400 9px ${DEFAULT_FONTFAMILY}; fill: #313545; }
          `}
        </style>
        <g>
          <text y="15" x="0" className="ProvenancePDF_title">
            Provenance
          </text>
          {
            lines.map((line, i) => (
              <text y={TEXT_TOP + (LINE_HEIGTH * i)} x="0" className="ProvenancePDF_text" key={i}>
                {line}
              </text>
            ))
          }
        </g>
      </svg>
    );
  }
}

ProvenancePDF = compose(
  connectEventsContainer,
  connect({
    countries: countriesById_sel,
  }),
)(ProvenancePDF);

export { ProvenancePDF };
