import { call, select, fork, takeEvery, put } from 'redux-saga/effects';

import Api, { makeApiGenerator } from 'domain/api';
import { fetchArtworkList_apiGen, artworkListAppendPage_apiGen } from 'domain/artwork/sagas';
import { fetchArtistLiquidityAction } from 'domain/artist/ArtistAction';
import { artworkAppendPageAction, fetchArtworkListAction } from 'domain/artwork/ArtworkActions';
import { artworkPaginationSelector } from 'domain/artwork/ArtworkModel';
import { matchParamsSel, filterParamsSelector } from 'domain/router/RouterModel';
import { watchCreateAlertByArtistId } from 'domain/alerts/sagas';
import { pathNameChanged as pathnameChangedSelector } from 'domain/ui/sagas';
import { err404Action } from 'domain/ui/UIActions';
import { DEFAULT_PAGE_SIZE } from 'domain/const';

import {
  advancedFilterPageInit,
} from 'domain/advancedFilter/sagas';

function* fetchListPage(page = 0) {
  const filter = yield select(filterParamsSelector);
  let { authorId } = yield select(matchParamsSel);
  authorId = parseInt(authorId, 10);
  if (page > 0) {
    yield call(artworkListAppendPage_apiGen.catchError, { ...filter, artistId: authorId, page, size: DEFAULT_PAGE_SIZE });
  } else {
    yield call(fetchArtworkList_apiGen.catchError, { ...filter, artistId: authorId, page, size: DEFAULT_PAGE_SIZE });
  }
}

function* onPagination() {
  const { page, loading } = yield select(artworkPaginationSelector);
  if (loading) return;
  yield call(fetchListPage, page + 1);
};

const fetchArtistLiquidity_apiGen = makeApiGenerator({
  actionCreator: fetchArtistLiquidityAction,
  api: Api.getArtistLiquidity,
});

export default function* watchNavigation() {
  yield fork(watchCreateAlertByArtistId);
  let { authorId } = yield select(matchParamsSel);
  authorId = parseInt(authorId, 10);
  if (!authorId) {
    yield put(err404Action(true));
    return;
  }
  if (yield select(pathnameChangedSelector)) {
    yield fork(fetchArtistLiquidity_apiGen.catchError, { artistId: authorId });
    // clear previous artist AO's
    yield put({
      type: fetchArtworkListAction.success,
      debug: 'CLEAR',
      payload: {
        lots: {
          content: [],
          artist: {},
          page: 0,
          pages: 0,
          size: 0,
          total: 0,
        },
      },
    });
  }
  yield fork(advancedFilterPageInit);
  yield fork(fetchListPage);
  yield takeEvery(artworkAppendPageAction.type, onPagination);
}
