import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'cpcs-reconnect';
import {
  routeName_sel,
  query_sel,
  routerParams_sel,
} from 'domain/router/RouterModel';
import { EVENT_PREVIEW_CLOSED } from 'pages/highcharts/helpers/PDFConstants';
import { initPDFGenerator } from 'pages/highcharts/helpers/initPDFGenerator';
import { initEventsContainer } from 'pages/highcharts/helpers/EventsContext';

/**
 * ?downloadReport=true opens generator
**/

export const PDFModalStatesWrapper = (reportType) => (Component) => {
  const WrappedComponent = initPDFGenerator(reportType)(Component);
  class PDFModalStatesComponent extends React.Component {
    static propTypes = {
      query: PropTypes.shape({ downloadReport: PropTypes.string }),
      PDFEvents: PropTypes.shape({
        dispatch: PropTypes.func.isRequired,
      }).isRequired,
      routeName: PropTypes.string,
      routerParams: PropTypes.shape({
        artworkId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        authorId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
    };

    state = {
      downloadReportInProgress: false,
      PDFReportShown: false,
      readyToGeneratePDF: false,
      downloadReportCanceled: false,
      routeName: '',
      authorId: '',
      artworkId: '',
      destroyState: false,
    };

    static getDerivedStateFromProps(props, state) {
      let ret = null;
      const reset = {
        destroyState: true,
        PDFReportShown: false,
        readyToGeneratePDF: false,
        downloadReportCanceled: false,
        downloadReportInProgress: false,
      };
      if (state.routeName !== props.routeName) {
        ret = { ...ret, routeName: props.routeName, ...reset };
      }
      const { routerParams: { artworkId, authorId } = {} } = props;
      if (state.artworkId !== artworkId) {
        ret = { ...ret, artworkId: artworkId, ...reset };
      }
      if (state.authorId !== authorId) {
        ret = { ...ret, authorId: authorId, ...reset };
      }
      if (!ret && state.destroyState) {
        ret = { destroyState: false };
      }
      return ret;
    }

    componentDidMount() {
      const { query: { downloadReport } } = this.props;
      if (downloadReport) {
        if (global.ARTBnkLogger) {
          global.ARTBnkLogger.dispatch('ArtistReportsPage show DownloadingModal');
        }
        this.safeSetState({
          downloadReportInProgress: true,
        });
      }
    }

    componentWillUnmount() {
      this.unmounted = true;
    }

    safeSetState = (...args) => {
      const { PDFEvents } = this.props;
      if (this.unmounted) return;
      const [state] = args;
      if (state && state.PDFReportShown === false && this.state.PDFReportShown === true) {
        PDFEvents.dispatch(EVENT_PREVIEW_CLOSED);
      }
      this.setState(...args);
    }

    onReadyToGeneratePDF = () => {
      const { query: { downloadReport } } = this.props;
      const { downloadReportCanceled, readyToGeneratePDF, PDFReportShown } = this.state;
      let newState;
      if (!readyToGeneratePDF) {
        newState = { ...newState, readyToGeneratePDF: true };
      }
      if (!downloadReportCanceled && !!downloadReport && !PDFReportShown) {
        newState = {
          ...newState,
          PDFReportShown: true,
          downloadReportInProgress: true,
        };
      }
      if (global.ARTBnkLogger) {
        global.ARTBnkLogger.dispatch('PDFModalStatesWrapper::onReadyToGeneratePDF', {
          downloadReportCanceled,
          readyToGeneratePDF,
          newState,
        });
      }
      if (newState) {
        this.safeSetState(newState);
      }
    };

    togglePDFReport = () => {
      const { PDFReportShown } = this.state;
      if (global.ARTBnkLogger) {
        global.ARTBnkLogger.dispatch('togglePDFReport', {
          PDFReportShown: !PDFReportShown,
          downloadReportInProgress: !PDFReportShown,
          downloadReportCanceled: false,
        });
      }
      this.safeSetState({
        PDFReportShown: !PDFReportShown,
        downloadReportInProgress: !PDFReportShown,
        downloadReportCanceled: false,
      });
    };

    onPDFSaved = () => {
      if (global.ARTBnkLogger) {
        global.ARTBnkLogger.dispatch('onPDFSaved closeDownloadPDFModal');
      }
      this.safeSetState({ downloadReportInProgress: false });
    };

    cancelPDFReportDownload = () => {
      if (global.ARTBnkLogger) {
        global.ARTBnkLogger.dispatch('cancelPDFReportDownload');
      }
      this.safeSetState({
        downloadReportCanceled: true,
        PDFReportShown: false,
        downloadReportInProgress: false,
      });
    }

    render() {
      const { downloadReportInProgress, PDFReportShown, readyToGeneratePDF, destroyState } = this.state;
      const TheComponent = destroyState ? Component : WrappedComponent;
      return (
        <TheComponent
          {...this.props}
          PDFReportShown={!destroyState && PDFReportShown}
          readyToGeneratePDF={readyToGeneratePDF}
          onPDFSaved={this.onPDFSaved}
          togglePDFReport={this.togglePDFReport}
          onReadyToGeneratePDF={this.onReadyToGeneratePDF}
          downloadReportInProgress={downloadReportInProgress}
          cancelPDFReportDownload={this.cancelPDFReportDownload}
        />
      );
    }
  }
  return compose(
    connect({
      query: query_sel,
      routeName: routeName_sel,
      routerParams: routerParams_sel,
    }),
    initEventsContainer,
  )(PDFModalStatesComponent);
};

