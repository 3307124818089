import { button } from 'theme/button';

export const sheet = {
  PrivacyAndTermsLabel: {
    // root
    fontWeight: 400,
    width: 'calc(100% - 40px)',
    '@media screen and (max-width: 320px)': {
      width: 'calc(100% - 27px)',
    },
  },
  button,
  // privacyTermsBtn: {
  //   ...Buttons.linkButton,
  //   padding: 0,
  //   fontSize: 13,
  //   fontWeight: 500,
  //   textTransform: 'capitalize',
  //   textDecoration: 'underline',
  // },
};
