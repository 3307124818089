import { button } from 'theme/button';
import { NO_ARTS_TITLE, NO_ARTS_DESCRIPTION, DEFAULT_FONTFAMILY } from 'theme/theme';

export default {
  NoArts: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 90,
  },
  title: {
    // fontSize: 24,
    width: 350,
    fontWeight: 500,
    font: `500 29px/35px ${DEFAULT_FONTFAMILY}`,
    color: NO_ARTS_TITLE,
    textAlign: 'center',
  },
  description: {
    letterSpacing: '-0.24px',
    font: `400 18px/24px ${DEFAULT_FONTFAMILY}`,
    color: NO_ARTS_DESCRIPTION,
    marginTop: 10,
  },
  button,
  // button: {
  //   marginTop: 40,
  //   ...Buttons.big,
  // },
};
