import React from 'react';
import ReactDOM from 'react-dom';
import MaskedInput from 'react-text-mask';
import PropTypes from 'prop-types';
import cx from 'classnames';
import moment from 'moment';
import { compose } from 'redux';
import { passDocumentDecorator } from 'lib/envGlobals';
import { protectClassesReplacing } from 'lib/helpers';
import Calendar from './Calendar';

import injectSheet from 'lib/sheet';
import sheet from './sheet';

const HIDE_TIMEOUT = 130;
const modalRoot = () => document.getElementById('calendar-root');

function renderInput(ref, props) {
  const { defaultValue, ...otherProps } = props;
  return <input ref={ref} value={defaultValue} {...otherProps} autoComplete="off" />;
}

renderInput.propTypes = {
  defaultValue: PropTypes.string,
  ref: PropTypes.func.isRequired,
};

/**
 * # --- Year picker ---
 * import FormRowField from 'pages/common/newPage/form/elements/FormRowField';
 * import DateField from 'components/form/DateField';
 * import { rules } from 'components/form/validation';
 * <Field
 *   name="createdYear"
 *   component={FormRowField}
 *   Field={DateField}
 *   modifier="..."
 *   type="text"
 *   placeholder={...}
 *   onChange={value => ...}
 *   validate={rules.year}
 *   rootTag="div"
 *   normalize={v => v ? moment(v, 'YYYY').format('YYYY'): ''}
 *   format={v => v ? moment(v, 'YYYY-MM-DD').format('MM/DD/YYYY'): ''}
 *   momentMask="YYYY"
 *   momentParseMasks={['YYYY']}
 *   maxDetail="decade"
 *   onBlur={e => e.preventDefault()}
 *   mask={[/\d/, /\d/, /\d/, /\d/]}
 * />
 *
 * # --- Custom format date picker ---
 * import FormRowField from 'pages/common/newPage/form/elements/FormRowField';
 * import DateField from 'components/form/DateField';
 * import { rules } from 'components/form/validation';
 * <Field
 *   name="createdYear"
 *   component={FormRowField}
 *   Field={DateField}
 *   modifier="..."
 *   type="text"
 *   placeholder={...}
 *   onChange={value => ...}
 *   validate={rules.year}
 *   rootTag="div"
 *   normalize={v => v ? moment(v, 'MM/DD/YYYY').format('YYYY-MM-DD'): ''}
 *   format={v => v ? moment(v, 'YYYY-MM-DD').format('MM/DD/YYYY'): ''}
 *   onBlur={e => e.preventDefault()}
 *   // default params
 *   // mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
 *   // momentMask="MM/DD/YYYY"
 *   // momentParseMasks={['MM/DD/Y', 'MM/DD/YY', 'MM/DD/YYY', 'MM/DD/YYYY']}
 *   // maxDetail="month"
 *   // minDetail="century"
 * />
 * 
 * 
 * 
**/

class DateField extends React.Component {
  static propTypes = {
    placeholder: PropTypes.string,
    id: PropTypes.string,
    disabled: PropTypes.bool,
    classes: PropTypes.shape({
      DateField: PropTypes.string.isRequired,
      catchFocusWrapper: PropTypes.string.isRequired,
      field: PropTypes.string.isRequired,
    }).isRequired,
    // input START
    input: PropTypes.shape({
      onChange: PropTypes.func.isRequired,
      onFocus: PropTypes.func,
      onBlur: PropTypes.func,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      name: PropTypes.string,
    }).isRequired,
    // input END
    mask: PropTypes.array,
    momentMask: PropTypes.string,
    minDetail: PropTypes.string,
    maxDetail: PropTypes.string,
    maxDate: PropTypes.instanceOf(Date),
    minDate: PropTypes.instanceOf(Date),
    document: PropTypes.object,
    momentParseMasks: PropTypes.arrayOf(PropTypes.string),
    modifier: PropTypes.string,
    portal: PropTypes.bool,
  };

  static defaultProps = {
    classes: { DateField: 'DateField' },
    mask: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/],
    momentMask: 'MM/DD/YYYY',
    momentParseMasks: ['MM/DD/Y', 'MM/DD/YY', 'MM/DD/YYY', 'MM/DD/YYYY'],
    minDetail: 'century',
    maxDetail: 'month',
  };

  static getDerivedStateFromProps(props, state) {
    const { input: { value }, momentParseMasks } = props;
    const { raw, inputFocused } = state;
    let ret = {};
    if (state.prevPropsVal !== value) {
      ret = { ...ret, prevPropsVal: value };
    }
    // ret.prevInputFocused
    if (state.prevInputFocused !== state.inputFocused) {
      ret = { ...ret, prevInputFocused: state.inputFocused };
    }
    // ret.raw
    const changeRaw = !inputFocused && (
      (state.prevInputFocused && raw !== value) ||
      value !== state.prevPropsVal
    );
    if (changeRaw) {
      ret = { ...ret, raw: value || '' };
    }
    // NULL
    if (value === state.prevPropsVal) {
      return Object.keys(ret).length ? ret : null;
    }
    // ret.date: null
    if (!value) {
      return { ...ret, date: null };
    }
    try {
      const d = moment(value, ...momentParseMasks, true);
      if (!d || !d.isValid()) throw new Error('invalid date error');
      const date = d._d;
      ret = { ...ret, date, visible: true, prevPropsVal: value };
      if (state.dateSelectedByCalendar) {
        ret = { ...ret, visible: false };
      }
      return ret;
    } catch (err) {}
    return Object.keys(ret).length ? ret : null;
  }

  constructor(props) {
    super(props);
    this.el = document.createElement('div');
    this.el.setAttribute('data-name', this.props.input.name);
  }

  componentDidMount() {
    const { document, portal } = this.props;
    document.addEventListener('mousedown', this.onOutsideAction);
    document.addEventListener('focusin', this.onOutsideAction);
    if (portal) {
      this.inPortal = true;
      /**
       * use true to catch all scroll events of all child elements
      **/
      window.addEventListener('scroll', this.onScroll, true);
      modalRoot().appendChild(this.el);
    }
  }

  componentWillUnmount() {
    const { document } = this.props;
    this.unmounted = true;
    document.removeEventListener('mousedown', this.onOutsideAction);
    document.removeEventListener('focusin', this.onOutsideAction);
    if (this.inPortal) {
      window.removeEventListener('scroll', this.onScroll, true);
      modalRoot().removeChild(this.el);
    }
  }

  onScroll = () => {
    if (!this.visible || !this.wrapperNode) return;
    const style = this.calendarWrapperStyle();
    if (!style) return;
    this.wrapperNode.style.top = `${style.top}px`;
    this.wrapperNode.style.left = `${style.left}px`;
  }

  onOutsideAction = (event) => {
    const { focused } = this.state;
    if (!this.rootNode || !this.wrapperNode) return;
    const outside = !this.rootNode.contains(event.target) && !this.wrapperNode.contains(event.target);
    if (focused && outside) {
      this.onBlur();
    }
  }

  state = {
    date: null,
    visible: true,
    focused: false,
    inputFocused: false,
    prevPropsVal: undefined, // string
    prevInputFocused: undefined, // boolean
    raw: '',
  };

  refRoot = node => this.rootNode = node;
  refWrapper = node => this.wrapperNode = node;

  get inputValue() {
    const { raw } = this.state;
    return raw || '';
  }

  onChangeByMaskedInput = (ev) => {
    const { momentParseMasks, input: { onChange } } = this.props;
    const raw = ev.target.value;
    this.setState({ dateSelectedByCalendar: false, raw });
    if (!raw) {
      onChange(null);
      return;
    }
    try {
      const d = moment(raw, ...momentParseMasks, true);
      if (d && d.isValid()) {
        onChange(raw || '');
      }
    } catch (e) {/* ignore any errors while parsing date */}
  }

  onChangeByCalendar = (date) => {
    const { momentMask } = this.props;
    this.setState({ date, visible: false, dateSelectedByCalendar: true });
    this.props.input.onChange(moment(date).format(momentMask));
    /**
     * without this event form doesn't validate fields
    **/
    this.props.input.onBlur && this.props.input.onBlur();
  }

  get visible() {
    if (!this.state.focused) return false;
    if (!this.state.visible) return false;
    return true;
  }

  hideTimout = null;

  onFocus = ({ inputFocused }) => {
    this.props.input.onFocus && this.props.input.onFocus();
    let more = {};
    if (inputFocused) more = { inputFocused: true };
    if (!this.state.focused || !this.state.visible || !this.state.inputFocused) {
      this.setState({ focused: true, visible: true, ...more });
    }
    if (this.hideTimout) {
      clearTimeout(this.hideTimout);
      this.hideTimout = null;
    }
  }

  onInputFocus = () => {
    this.onFocus({ inputFocused: true });
  }

  onBlur = () => {
    if (this.hideTimout) {
      clearTimeout(this.hideTimout);
      this.hideTimout = null;
    }
    this.hideTimout = setTimeout(() => {
      this.hideTimout = null;
      if (this.unmounted) return;
      this.safeSetState({ focused: false, visible: false });
      this.props.input.onBlur && this.props.input.onBlur();
    }, HIDE_TIMEOUT);
  }

  onInputBlur = () => {
    this.setState({ inputFocused: false });
    this.onBlur();
  }

  onKeyUp = (ev) => {
    if (ev.key === 'Escape') {
      this.setState({ visible: !this.state.visible });
    }
  }

  onClick = () => {
    if (this.state.visible) return;
    this.setState({ visible: true });
  }

  safeSetState = (st, f) => {
    if (this.unmounted) return;
    this.setState(st, f);
  }

  calendarWrapperStyle = () => {
    if (!this.props.portal || !this.rootNode) return undefined;
    const rect = this.rootNode.getBoundingClientRect();
    return {
      top: rect.y + rect.height + 2,
      left: rect.x,
    };
  }

  renderCalendar() {
    const { classes, portal } = this.props;
    const component = (
      <div
        onFocus={this.onFocus}
        onBlur={this.onBlur}
        tabIndex="-1"
        className={cx(classes.catchFocusWrapper, { portal })}
        style={this.calendarWrapperStyle()}
        ref={this.refWrapper}
      >
        <Calendar
          onChange={this.onChangeByCalendar}
          value={this.state.date}
          minDetail={this.props.minDetail}
          maxDetail={this.props.maxDetail}
          maxDate={this.props.maxDate}
          minDate={this.props.minDate}
        />
      </div>
    );
    if (portal) {
      return ReactDOM.createPortal(
        component,
        this.el,
      );
    }
    return component;
  }

  render() {
    const { classes, placeholder, disabled, id, modifier } = this.props;
    return (
      <div
        onKeyUp={this.onKeyUp}
        className={cx(classes.DateField, modifier)}
        ref={this.refRoot}
        onClick={this.onClick}
      >
        <MaskedInput
          className={cx(classes.field, 'DateField', modifier)}
          mask={this.props.mask}
          value={this.inputValue}
          onChange={this.onChangeByMaskedInput}
          keepCharPositions
          render={renderInput}
          onFocus={this.onInputFocus}
          onBlur={this.onInputBlur}
          guide
          placeholder={placeholder}
          disabled={disabled}
          id={id}
          name={this.props.input.name}
          autoComplete="off"
        />
        {this.visible && this.renderCalendar()}
      </div>
    );
  }
}

export default compose(
  protectClassesReplacing,
  injectSheet(sheet),
  passDocumentDecorator,
)(DateField);
