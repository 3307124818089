import { race, takeLatest, put, take, call } from 'redux-saga/effects';
import { CONFIRM_POPUP } from 'domain/const';
import {
  confirmAction,
  confirmAcceptAction,
  confirmDenyAction,
  confirmCloseAction,
  hidePopupAction,
  addPopupAction,
} from 'domain/ui/UIActions';

function* onNeedConfirmAction(action) {
  const { actionCreator, args = [] } = action.payload;
  let { params } = action.payload;
  if (typeof params === 'string') params = { title: params };
  yield put(addPopupAction({ name: CONFIRM_POPUP, params }));
  const result = yield race({
    success: take(confirmAcceptAction.type),
    deny: take(confirmDenyAction.type),
    close: take(confirmCloseAction.type),
    leavePage: take('@@router/LOCATION_CHANGE'),
  });
  if (result.success) {
    yield put(actionCreator(...args));
  }
  if (result.deny && params.onDeny) {
    yield put(params.onDeny(...args));
  }
  if (params && params.doNotHide) {
    return;
  }
  yield put(hidePopupAction());
}

export default function* watchConfirmAction() {
  yield takeLatest(confirmAction.type, onNeedConfirmAction);
}

export function* confirmGenCall({ title, thumb, description, modifier }, genF, ...args) {
  try {
    yield put(addPopupAction({ name: CONFIRM_POPUP, params: { title, thumb, description, modifier } }));
    const result = yield race({
      success: take(confirmAcceptAction.type),
      deny: take(confirmDenyAction.type),
      close: take(confirmCloseAction.type),
      /**
       * currently we cant catch '@@router/LOCATION_CHANGE'
       * so we hide popup in finally section
       *
       * but I leave this here to warn future developers
       * that your saga generators can depend on location params or
       * state, that can be changed on location change
       *
       * so they can work incorrect
       *
       * please do not leave this poup between pages for generators!
      **/
      leavePage: take('@@router/LOCATION_CHANGE'),
    });
    if (result.success) {
      yield call(genF, ...args);
    }
    return result;
  } finally {
    yield put(hidePopupAction());
  }
}
