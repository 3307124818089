import React from 'react';

import { connectEventsContainer } from 'pages/highcharts/helpers/EventsContext';
import { RefSize } from 'pages/highcharts/helpers/refSize';
import { DEFAULT_FONTFAMILY, themes } from 'pages/highcharts/helpers/PDFConstants';
import { Text } from 'pages/highcharts/helpers/Text';

const TOP = 96;
const TEXT_LINE_HEIGHT = 12;
const MARGIN = 40;

// eslint-disable-next-line no-unused-vars
const linesStart = [
  'ARTBnk reports are subject to the conditions stated below and to those found in the ARTBnk',
  'Inc. Subscription Terms : https://www.artbnk.com/subscription-terms. These terms are an',
  'integral part of this report. By accepting the subscription terms you expressly agree that use',
  'of this report is at your sole risk and is provided on an \'as is\' basis, without warranties of any',
  'kind, either express or implied.',
  '',
  'ARTBnk, nor its affiliates or subsidiaries, or any of their directors, employees, consultants,',
  'attorneys, third party providers (collectively "ARTBnk"), make any representations to you or',
  'any other persons regarding title, provenance, authenticity, condition or accuracy of',
  'information with respect to any artwork. A report produced by our services is not a certificate',
  'of title, warranty of ownership or a promise of accuracy.',
  '',
  'While ARTBnk tries to ensure that information contained in this report is correctly calculated,',
  'its Data is based on information reported from users, collectors, art galleries, auction sites, and',
  'other websites, which ARTBnk does not independently test or verify. Therefore, ARTBnk is',
  'not responsible for misstated or withheld information or for any possible errors, omissions or',
  'inaccuracies, nor should it be liable for any loss or damage arising directly or indirectly from',
  'the use of this report.',
  '',
  'ARTBnk valuations are intended to reflect the Fair Market Value at the time of the appraisal',
  'and are not predictive of past or future value or appraisals that might be generated by other',
  'means. These valuations are data driven and do not necessarily reflect values determined by',
  'sale transaction, tax appraisal or insurance recovery.',
  '',
];

const linesMiddle = {
  artist: [
    'This report is not intended as tax, legal, accounting, investment or other expert advice and nor',
    'does ARTBnk warrant that the information contained in the report can be used for tax, legal or',
    'investment purposes. Data and projections presented in graphs, charts, diagrams, or as',
    'stand-alone statistics in this report are based on past auction sales data for similar artworks',
    'used for comparative modeling purposes only. Past performance is no guarantee of future',
    'results and past historical appreciation rates of artwork may not be indicative of future',
    'appreciation rates. Historical price trends of a group of selected artworks cannot predict',
    'future performances and sale prices.',
  ],
  artwork: [
    'This valuation is not a recommendation to purchase or sell artwork, nor is it intended as tax,',
    'legal, accounting, investment, or other expert advice, nor does ARTBnk warrant that the',
    'information contained in the valuation can be used for tax, legal, or investment purposes. Data',
    'is based on past auction sales data for similar artworks used for comparative modeling',
    'purposes only. Past performance is no guarantee of future results.',
    '',
    'Except where otherwise indicated, this valuation speaks as of the date hereof. The Company',
    'does not undertake any obligation to correct or update the information contained herein or',
    'provide the recipient with access to any additional evaluation material.',
  ],
};

const linesEnd = [
  '',
  'ARTBnk do not own the right to any artwork images contained in this report which are used for',
  'identification and comparison purposes only.',
];

const lines = {
  REPORT_TYPE_ARTIST: [...linesStart, ...linesMiddle.artist, ...linesEnd],
  REPORT_TYPE_ARTWORK: [...linesStart, ...linesMiddle.artwork, ...linesEnd],
};

const lines2 = [
  'This report and all intellectual property rights in or arising from it belong to ARTBnk, Inc, and all',
  'rights in it are reserved. No copying or distribution of this report in whole or in part is permitted',
  'without prior permission in writing from ARTBnk.',
];

const TEXT_LEFT = 167;

class DisclaimerPDF extends RefSize {
  render() {
    const { PDFState } = this.props;
    const { mainColor } = (themes[PDFState.reportType] || {});
    const lines1 = lines[PDFState.reportType];
    if (!lines1) {
      throw new Error(`DisclaimerPDF no content for ${PDFState.reportType}`);
    }
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="612"
        height="750"
        ref={this.ref}
        data-top={TOP}
        data-margin-left="0"
      >
        <style>
          {`
          .DisclaimerPDF_title { font: 700 16px ${DEFAULT_FONTFAMILY}; fill: #1E202C; }
          .DisclaimerPDF_text { font: 400 9px ${DEFAULT_FONTFAMILY}; fill: #304C95; }
          `}
        </style>
        <g transform="translate(0, 0)">
          <rect
            x="0"
            y="8"
            height="16"
            width="88"
            fill={mainColor}
          />
          <text x={TEXT_LEFT} y="16" className="DisclaimerPDF_title">
            Disclaimer
          </text>
          <Text
            left={TEXT_LEFT}
            top="40"
            lines={lines1}
            className="DisclaimerPDF_text"
            lineHeight={TEXT_LINE_HEIGHT}
          />
        </g>
        <g transform={`translate(0, ${MARGIN + lines1.length * TEXT_LINE_HEIGHT})`}>
          <text x={TEXT_LEFT} y="16" className="DisclaimerPDF_title">
            Copyright
          </text>
          <Text
            left={TEXT_LEFT}
            top="40"
            lines={lines2}
            className="DisclaimerPDF_text"
            lineHeight={TEXT_LINE_HEIGHT}
          />
        </g>
      </svg>
    );
  }
}

DisclaimerPDF = connectEventsContainer(DisclaimerPDF);

export { DisclaimerPDF };
