import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'cpcs-reconnect';
import { compose } from 'redux';
import cx from 'classnames';
import injectSheet from 'lib/sheet';
import Popup from 'components/popup';
import styles from './sheet.js';
import { isPopupVisibleSelector } from 'domain/ui/UIModel';
import { hidePopupAction } from 'domain/ui/UIActions';
import { CREATE_AUTHOR_POPUP, EDIT_AUTHOR_POPUP } from 'domain/const';
import NewAuthorForm from 'pages/common/newPage/newAuthorForm';
import { editArtistFormData_sel } from 'pages/common/artworkForm/ArtworkFormModel';

class NewAuthorPopup extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    isPopupVisible: PropTypes.func.isRequired,
    hidePopup: PropTypes.func.isRequired,
    artist: PropTypes.object,
  };

  onCancel = () => this.props.hidePopup();

  render() {
    const { classes, isPopupVisible, artist } = this.props;
    const isEdit = isPopupVisible(EDIT_AUTHOR_POPUP);
    if (!isPopupVisible(CREATE_AUTHOR_POPUP) && !isEdit ) return null;
    return (
      <Popup>
        <div
          className={cx(classes.NewAuthorPopup, classes.container)}
          data-name="add-new-artist-popup"
        >
          <div className={classes.titleWrapper}>
            <h2 className={classes.title}>{ `${ isEdit ? 'Edit' : 'Add New' } Artist` }</h2>
            <button
              type="button"
              onClick={this.onCancel}
              className={classes.close}
            />
          </div>
          <NewAuthorForm
            initialValues={isEdit ? artist : { residence: [] }}
            onCancel={this.onCancel}
          />
        </div>
      </Popup>
    );
  }
}

export default compose(
  injectSheet(styles),
  connect({
    isPopupVisible: isPopupVisibleSelector,
    hidePopup: hidePopupAction,
    artist: editArtistFormData_sel,
  }),
)(NewAuthorPopup);
