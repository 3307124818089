import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import I from 'immutable';
import { connect } from 'cpcs-reconnect';
import { artwork_sel } from 'domain/artworkPage/ArtworkPageModel';

import Dashboard from 'pages/common/dashboard';
import ItemActions from 'pages/common/ItemActions';
import Property from 'pages/common/property';
import SharePopup from 'pages/common/ShareSingleArtworkPopup';
import NotAccurateMessage from 'pages/common/NotAccurate';
import translate from 'lib/translate';
import { SHARE_TYPE_ARTWORK, SUBSCRIPTION_TYPE_ENTERPRISE } from 'domain/const';
import { getDetails, serialGroups } from 'pages/common/artworkView';
import { Slider } from 'components/form/dropzone/slider';
import { Files } from 'components/Files';
import { oneOfFieldHasValue } from 'pages/common/artworkForm/ArtworkFormModel';
import { categoryList_sel } from 'domain/category/CategoryModel';
import Fieldset from 'components/fieldset';
import PageSectionComparables, { PageSectionSalesComparablesDoll } from 'pages/common/pageSectionComparables';
// import PageSectionAnalyticsBanner from 'pages/common/pageSectionAnalyticsBanner';
import ProcessingPopup from 'pages/common/popup/processingPopup';
import PageContainer from 'pages/common/pageContainer';
import artistSolver from 'pages/common/artworkView/solvers/artistSolver';
import titleSolver from 'pages/common/artworkView/solvers/titleSolver';
import PageSectionOtherArtistWorks from 'pages/common/pageSectionOtherArtistWorks';
import PageSectionReports from 'components/reports/Reports';
import { subscriptionTypeSelector } from 'domain/env/EnvModel';

import { PDFModalStatesWrapper } from 'pages/highcharts/helpers/PDFModalStatesWrapper';
import { REPORT_TYPE_ARTWORK } from 'pages/highcharts/helpers/PDFConstants';
import { PDFPreview } from 'pages/highcharts/PDFPreview';
import DownloadAOPDF from 'pages/common/DownloadAOPDF/DownloadAOPDF';


class ArtworkPage extends React.PureComponent {

  static propTypes = {
    artwork: PropTypes.instanceOf(I.Record),
    categoryList: PropTypes.instanceOf(I.Collection),
    subscriptionType: PropTypes.string,
    PDFReportShown: PropTypes.bool,
    readyToGeneratePDF: PropTypes.bool,
    togglePDFReport: PropTypes.func.isRequired,
    onPDFSaved: PropTypes.func.isRequired,
    // force rerender PDFPreview
    PDFState: PropTypes.shape({
      reportType: PropTypes.string,
    }),
  };

  state = {
    imgStyle: { width: '100%' },
  }

  componentWillUnmount() {
    this.unmounted = true;
  }

  get allowGenerateReport() {
    const { subscriptionType, artwork } = this.props;
    return (subscriptionType === SUBSCRIPTION_TYPE_ENTERPRISE && !!artwork.rtvPrice);
  }

  render() {
    const {
      artwork, categoryList, subscriptionType,
      PDFReportShown, readyToGeneratePDF, togglePDFReport, onPDFSaved, PDFState,
    } = this.props;
    if (!artwork || !artwork.id) return null;
    const pictures = artwork.get('pictures').toJS() || [];
    const artWorkCategory = artwork.get('category');
    const serializerDetails = getDetails({ categoryList, category: artWorkCategory });
    const artworksById = new I.Map().set(artwork.get('id'), artwork);
    return (
      <PageContainer modifier="artworkPage">
        <Dashboard artwork={artwork} />
        <NotAccurateMessage artwork={artwork} />
        <div className="PAGE_SECTION artworkPage">
          <div className="PAGE_INNER_COL1 artworkPage">
            <Slider
              files={pictures}
              readOnly
            />
            <ItemActions
              tracked={artwork.get('isConsideration')}
              collected={artwork.get('isArtWork')}
              hideTrack={artwork.get('isArtWork')}
              artwork={artwork}
            />
            <Files docs={artwork.get('docs').toJS()} readOnly />
          </div>
          <div className="PAGE_INNER_COL2 artworkPage">
            <DownloadAOPDF
              togglePDFReport={togglePDFReport}
              readyToGeneratePDF={readyToGeneratePDF}
              PDFReportShown={PDFReportShown}
              allowGenerateReport={this.allowGenerateReport}
            >
              {artistSolver(artwork.artist, artwork, 'artist', this.props)}
            </DownloadAOPDF>
            {titleSolver(artwork.title, artwork, 'title', this.props)}
            <Property data={artwork} serializer={serialGroups.serializerGroup1} />
            {
              serializerDetails && oneOfFieldHasValue(artwork, serializerDetails) && (
                <Fieldset title="Details" RootTag="section">
                  <Property data={artwork} serializer={serializerDetails} />
                </Fieldset>
              )
            }
            {
              oneOfFieldHasValue(artwork, serialGroups.serializerProvenance) &&
                <Fieldset title="Provenance & Sales" RootTag="section">
                  <Property data={artwork} serializer={serialGroups.serializerProvenance} />
                </Fieldset>
            }
            {
              oneOfFieldHasValue(artwork, serialGroups.serializerExhibition) &&
              <Fieldset title="Exhibitions & Literature" RootTag="section">
                <Property data={artwork} serializer={serialGroups.serializerExhibition} />
              </Fieldset>
            }
          </div>
          <SharePopup
            id={artwork.get('id')}
            artworksById={artworksById}
            header={`Share ${translate('shared_entity_' + SHARE_TYPE_ARTWORK, 1)}`}
          />
        </div>
        {
          artwork.rtvPrice ?
            <PageSectionComparables /> :
            <PageSectionSalesComparablesDoll />
        }
        {/* <PageSectionAnalyticsBanner /> */}
        {
          !!(artwork && !(artwork.rtvPrice && subscriptionType === SUBSCRIPTION_TYPE_ENTERPRISE)) &&
            <PageSectionOtherArtistWorks artwork={artwork} />
        }
        {
          !!artwork.rtvPrice &&
            <PageSectionReports
              artworkId={artwork.get('id')}
              artistId={artwork.getIn(['artist', 'id'])}
            />
        }
        {
          !!artwork.rtvPrice && PDFReportShown &&
            <PDFPreview togglePDFReport={togglePDFReport} onPDFSaved={onPDFSaved} artwork={artwork} PDFState={PDFState} />
        }
        <ProcessingPopup artwork={artwork} />
      </PageContainer>
    );
  }
}

export default compose(
  connect({
    artwork: artwork_sel,
    categoryList: categoryList_sel,
    subscriptionType: subscriptionTypeSelector,
  }),
  PDFModalStatesWrapper(REPORT_TYPE_ARTWORK),
)(ArtworkPage);
