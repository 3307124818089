import { DARK_BLUE_COLOR, DEFAULT_FONTFAMILY, SHARES_SEE_ALL, SHARES_TABLE_TITLE } from 'theme/theme';

export default {
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: [0, 0, 7],
    position: 'relative',
  },
  title: {
    display: 'block',
    color: DARK_BLUE_COLOR,
    font: `700 24px/1.25em ${DEFAULT_FONTFAMILY}`,
    minWidth: 0,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  tableHeader: {
    padding: [34, 0, 7],
    border: 'none',
    display: 'flex',
    justifyContent: 'space-between',
  },
  tableHeaderColumn1: {
    flex: '4 4 0',
  },
  tableHeaderColumn2: {
    flex: '1 1 0',
    textAlign: 'right',
  },
  tableTitle: {
    display: 'inline-block',
    verticalAlign: 'middle',
    color: SHARES_TABLE_TITLE,
    font: `500 20px/1.25em ${DEFAULT_FONTFAMILY}`,
    minWidth: 0,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    textDecoration: 'underline',
  },
  seeAllBtn: {
    font: `500 12px/25px ${DEFAULT_FONTFAMILY}`,
    marginLeft: 50,
    color: SHARES_SEE_ALL,
    textDecoration: 'underline',
    whiteSpace: 'nowrap',
    '@media screen and (max-width: 1000px)': {
      marginLeft: 0,
    },
  },
  actionText: {
    font: `500 12px ${DEFAULT_FONTFAMILY}`,
    verticalAlign: 'middle',
  },
  amountText: {
    font: `400 12px ${DEFAULT_FONTFAMILY}`,
  },
};
