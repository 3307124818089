import { DARK_BLUE_COLOR } from 'theme/theme';
export default {
  noAccess: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  insightFrame: {
    height: 800,
  },
  notSubsc: {
    margin: [15, 0, 0, 0],
  },
  textLink: {
    color: DARK_BLUE_COLOR,
  },
};
