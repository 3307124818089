import React from 'react';
import PropTypes from 'prop-types';
import { DEFAULT_FONTFAMILY, DARK_BLUE_COLOR } from 'theme/theme';
import injectSheet from 'lib/sheet';


function TitleNoTheme({ children, classes }) {
  return <div
    children={children}
    className={classes.Title}
  />;
}

TitleNoTheme.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.shape({
    Title: PropTypes.string,
  }).isRequired,
};

const sheet = {
  Title: {
    font: `400 20px/24px ${DEFAULT_FONTFAMILY}`,
    color: DARK_BLUE_COLOR,
    textTransform: 'uppercase',
    margin: [16, 0, 50],
  },
};

const TitleTheme = injectSheet(sheet)(TitleNoTheme);

// eslint-disable-next-line no-unused-vars
const titleSolver = (v, data, name, props) => {
  return <TitleTheme
    children={data.get('title')}
  />;
};

export default titleSolver;
