import React from 'react';
import PropTypes from 'prop-types';

import injectSheet from 'lib/sheet';
import sheet from './sheet';
import cx from 'classnames';

const ProgressBar = ({ classes, value = 60, postfix = ' %' }) => {
  const turn = { transform: `rotate(${Math.floor(1.8 * value)}deg)` };
  const doubleTurn = { transform: `rotate(${Math.floor(1.8 * value * 2)}deg)` };
  if (value === null) return null;
  return (
    <div className={classes.root}>
      <div className={classes.circle}>
        <div className={cx(classes.mask)} style={turn}>
          <div className={classes.fill} style={turn} />
        </div>
        <div className={cx(classes.mask)}>
          <div className={classes.fill} style={turn} />
          <div className={classes.fill} style={doubleTurn} />
        </div>
        <div className={classes.shadow}/>
      </div>
      <div className={classes.inset}>{value}{postfix}</div>
    </div>
  );
};

ProgressBar.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string.isRequired,
    circle: PropTypes.string.isRequired,
    mask: PropTypes.string.isRequired,
    shadow: PropTypes.string.isRequired,
    inset: PropTypes.string.isRequired,
    fill: PropTypes.string.isRequired,
  }).isRequired,
  value: PropTypes.number,
  postfix: PropTypes.string,
};

export default injectSheet(sheet)(ProgressBar);
