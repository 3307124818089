let counter = 0;
export const contentSections = [
  {
    title: 'Cover Page',
    name: 'coverPage',
    description: 'Description Cover Page',
    index: 0,
    // bg: '#CBC6C2',
    // bg: '#304C95',
    hideFromContentTable: true,
  },
  {
    title: 'Introduction',
    name: 'intro',
    description: 'Overview of the contents of the report',
    index: counter++,
    // bg: '#CBC6C2',
    // bg: '#304C95',
  },
  {
    title: 'Artwork Details',
    name: 'AODetails',
    description: 'Artwork Description, Estimates and Comparables Works',
    index: counter++,
    // bg: '#777',
    // bg: '#304C95',
  },
  {
    title: 'Financial Performance Analytics',
    name: 'financialPerformance',
    description: 'In-depth analysis of the artist’s financial performance',
    index: counter++,
    // bg: '#304C95',
  },
  {
    title: 'Market Movements',
    name: 'marketMovement',
    description: 'Comprehensive overview of sale totals and averages',
    index: counter++,
    // bg: '#304C95',
  },
  {
    title: 'Artist Rankings',
    name: 'artistRankings',
    description: 'Contextualizing performance against other artist’s markets',
    index: counter++,
    // bg: '#304C95',
  },
  {
    title: 'Other Section Name',
    name: 'otherSection',
    description: 'Step Description Financial',
    index: counter++,
    // bg: '#304C95',
  },
  {
    title: 'ARTBnk Indexes',
    name: 'ARTBnkIndexes',
    description: 'Overall performance of the art market and its subcategories',
    index: counter++,
    // bg: '#999',
    // bg: '#304C95',
  },
  {
    title: 'About ARTBnk',
    name: 'contacts',
    // About ARTBnk and how to contact us
    description: '',
    index: counter++,
    // bg: '#833',
    // bg: '#304C95',
  },
];
