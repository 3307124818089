import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'cpcs-reconnect';
import { compose } from 'redux';
import cx from 'classnames';
import { Field, reduxForm } from 'redux-form';
import I from 'immutable';
import FormRowField from 'pages/common/newPage/form/elements/FormRowField';
import Checkbox from 'components/form/checkbox';
import Popup from 'components/popup';
import PrivacyAndTermsLabel from 'pages/authPage/elements/PrivacyAndTermsLabel';
import { AUTH_ACCEPT_PRIVACY_AND_USAGE_TERMS_POPUP, FORM_AUTH_PRIVACY_AND_TERMS } from 'domain/const';
import { hidePopupAction } from 'domain/ui/UIActions';
import { snAuthPrivacyAndTermsAcceptedAction } from 'domain/env/EnvActions';
import { isPopupVisibleSelector, getPopupParamsSelector } from 'domain/ui/UIModel';
import validate from 'pages/authPage/form/login/popup/askEmail/validate';
import errorMessages from 'pages/authPage/form/login/popup/askEmail/errorMessages';

import injectSheet from 'lib/sheet';
import { sheet } from 'pages/authPage/form/login/popup/askEmail/sheet';

class AcceptPrivacyAndTerms extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    onCancel: PropTypes.func.isRequired,
    popupParams: PropTypes.instanceOf(I.Map),
    isPopupVisible: PropTypes.func.isRequired,
    finishRegistration: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
  };

  onSubmit = ({ privacyTermsAccept, marketingEmailConsent }) => {
    const { popupParams, finishRegistration } = this.props;
    const payload = popupParams
      .setIn(['data', 'privacyTermsAccept'], !!privacyTermsAccept)
      .setIn(['data', 'marketingEmailConsent'], !!marketingEmailConsent);
    finishRegistration(payload);
  }

  get privacyAndTermsLabel() {
    return <PrivacyAndTermsLabel />;
  }

  render() {
    const { classes, onCancel, isPopupVisible } = this.props;
    if (!isPopupVisible(AUTH_ACCEPT_PRIVACY_AND_USAGE_TERMS_POPUP)) return null;
    return (
      <Popup cancel={() => onCancel()}>
        <div
          className={`${classes.container} ${classes.AcceptPrivacyAndTerms}`}
        >
          <div className={classes.titleWrapper}>
            <h2
              className={classes.title}
              children="You need to accept Privacy Policy and Terms of Use to continue registration"
            />
          </div>
          <form
            onSubmit={this.props.handleSubmit(this.onSubmit)}
            className={classes.form}
          >
            <Field
              name="marketingEmailConsent"
              component={FormRowField}
              rootTag="div"
              labelText="I consent to marketing communications from ARTBank"
              Field={Checkbox}
              modifier="privacyPopupCheckbox"
              id="marketingEmailConsentCheckboxInPopup"
              errorMessages={errorMessages}
            />
            <Field
              name="privacyTermsAccept"
              component={FormRowField}
              rootTag="div"
              labelText={this.privacyAndTermsLabel}
              Field={Checkbox}
              modifier="privacyPopupCheckbox"
              id="privacyAndTermsCheckboxInPopup"
              errorMessages={errorMessages}
            />
            <div className={classes.btnGroup}>
              <button
                className={cx(classes.button, 'submit', 'Popup-AcceptPrivacy')}
                type="submit"
                children="finish registration"
                data-name="submit-button"
              />
            </div>
          </form>
        </div>
      </Popup>
    );
  }
}

export default compose(
  injectSheet(sheet),
  connect({
    onCancel: hidePopupAction,
    popupParams: getPopupParamsSelector,
    isPopupVisible: isPopupVisibleSelector,
    finishRegistration: snAuthPrivacyAndTermsAcceptedAction,
  }),
  reduxForm({
    form: FORM_AUTH_PRIVACY_AND_TERMS,
    validate,
  }),
)(AcceptPrivacyAndTerms);
