export const FOCUSED_STYLE = {
  boxShadow: '0 0 0 1px rgba(73, 144, 226, 0.6), 0 0 3px 1px #4990e2',
  outline: 'none',
};
/**
 * has 3px #FFF offset between outline and component
**/
export const FOCUSED_STYLE_OFFSET = {
  // boxShadow: '-8px 0 0 3px #FFF, 8px 0 0 3px #FFF, -8px 0 0 4px rgba(73, 144, 226, 0.6), 8px 0 0 4px rgba(73, 144, 226, 0.6), -8px 0 3px 4px #4990e2, 8px 0 3px 4px #4990e2',
  boxShadow: '-8px 0 0 3px #FFF, 8px 0 0 3px #FFF, -8px 0 0 4px #7E9FE1, 8px 0 0 4px #7E9FE1, -8px 0 0 5px #C7D5F2, 8px 0 0 5px #C7D5F2, -8px 0 0 6px #E7EDF9, 8px 0 0 6px #E7EDF9, -8px 0 0 7px #F8F9FD, 8px 0 0 7px #F8F9FD',
  outline: 'none',
};
export const FOCUS_OUTLINE = '#4990e2';
const FOCUS_BORDER = 'rgba(73, 144, 226, 0.6)';

export const FOCUSED_FIELD_STYLE = {
  boxShadow: '0 0 3px 0px #4990e2',
  border: `1px solid ${FOCUS_BORDER}`,
  outline: 'none',
};
export const FOCUS_BG = '#EDEFEF';

export const ACTION_BUTTONS_FOCUSED_STYLE = {
  boxShadow: '0 0 0 4px #EDEFEF, inset 0 0 0 15px #EDEFEF',
  outline: 'none',
};

export const ADVANCED_FILTER_CHECKBOX_FOCUSED_STYLE = {
  boxShadow: '-10px 0 0 4px #EDEFEF, 12px 0 0 4px #EDEFEF, inset 0 0 0 15px #EDEFEF',
  outline: 'none',
};

export const CHECKBOX_FOCUSED_STYLE = {
  boxShadow: '0 0 0 4px #EDEFEF, inset 0 0 0 15px #EDEFEF',
  outline: 'none',
};


export const FOCUS_BG_STYLE = {
  outline: 'none',
  backgroundColor: FOCUS_BG,
  boxShadow: 'none',
};
