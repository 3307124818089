import right from './images/ic_chevron_right_black_24px.svg';
import left from './images/ic_chevron_left_black_24px.svg';
import { DARK_BLUE_COLOR, DIVIDER_COLOR } from 'theme/theme';

export const SLIDER_ITEM_WIDTH = 216;
export const SLIDER_ITEM_HEIGHT = 160;
export const SLIDER_ITEM_BG_HEIGHT = 130;
export const SLIDER_ITEM_MARGIN_LEFT = 32;
export const ITEM_HIROZONTAL_SIZE = SLIDER_ITEM_WIDTH + SLIDER_ITEM_MARGIN_LEFT;
export const MENU_LEFT = 90;

export default {
  ArtGallerySlider: {
    margin: [0, 'auto'],
    padding: [0, 0, 30],
    borderBottom: `1px solid ${DIVIDER_COLOR}`,
  },
  empty: {
    // make it testable
  },
  wrapper1: {
    paddingRight: 62,
    position: 'relative',
  },
  wrapper2: {
    padding: [10, 0, 0, 2],
    position: 'relative',
    overflow: 'hidden',
  },
  list: {
    display: 'flex',
    transform: 'translate3d(0, 0, 0)',
    transition: 'transform 0.5s ease',
    '&.noAnimation': {
      transition: 'unset',
    },
  },
  item: {
    flex: `0 0 ${SLIDER_ITEM_WIDTH}px`,
    marginLeft: SLIDER_ITEM_MARGIN_LEFT,
    '&:first-child': {
      marginLeft: 0,
    },
  },
  nav: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: 60,
    height: SLIDER_ITEM_HEIGHT,
  },
  navRight: {
    width: 26,
    height: 26,
    position: 'absolute',
    right: 0,
    top: 20,
    background: {
      image: `url("${right}")`,
      repeat: 'no-repeat',
      size: '24px 24px',
      position: 'center center',
    },
    padding: 0,
    border: `1px solid ${DARK_BLUE_COLOR}`,
    '&[disabled]': {
      opacity: 0.25,
    },
  },
  navLeft: {
    extend: 'navRight',
    top: 60,
    backgroundImage: `url("${left}")`,
  },
  menuPosition: {
    position: 'absolute',
    top: 40,
  },
  shadowLeft: {
    position: 'absolute',
    width: 50,
    height: SLIDER_ITEM_BG_HEIGHT + 5,
    pointerEvents: 'none',
    left: 0,
    top: 8,
    background: 'linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%)',
  },
  shadowRight: {
    extend: 'shadowLeft',
    left: 'initial',
    right: 62,
    background: 'linear-gradient(to left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%)',
  },
};
