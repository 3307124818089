import { DEFAULT_BUTTON_COLOR } from 'theme/theme';
import {
  fieldset,
  formItem,
  fieldWrapper,
  label,
  field,
  buttonContainer,
} from 'theme/form';
import { button } from 'theme/button';
import { AOFormSheet } from 'pages/common/newPage/form/sheet';

export default {
  fieldset,
  sLabel: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  rtv: {
    color: DEFAULT_BUTTON_COLOR,
  },
  formItem,
  fieldWrapper,
  label,
  field,
  buttonContainer,
  button,
  add: AOFormSheet.add,
};
