import { button } from 'theme/button';
import star from './images/star.svg';

export default {
  Upgrade: {
  },
  star: {
    width: 14,
    height: 14,
    marginRight: 5,
    background: `url("${star}")`,
    '@media screen and (max-width: 445px)': {
      marginRight: 0,
    },
  },
  title: {
    '@media screen and (max-width: 445px)': {
      display: 'none',
    },
  },
  button,
};
