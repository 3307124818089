import React from 'react';
import PropTypes from 'prop-types';
import I from 'immutable';
import translate from 'lib/translate.js';
import injectSheet from 'lib/sheet';
import styles from './sheet.js';

class NotAccurate extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    artwork: PropTypes.instanceOf(I.Record),
  };

  render() {
    const { classes, artwork } = this.props;
    if (!artwork || !artwork.notAccurate) return null;
    return (
      <div
        className={classes.NotAccurate}
        children={translate('artwork.notAccurate.Message')}
      />
    );
  }
}

export default injectSheet(styles)(NotAccurate);
