import closeBg from './images/close.svg';
import check from './images/check.png';
import back from './images/back.svg';

import { BUY_RTV_SIDEBAR_Z_INDEX, BUY_RTV_OVERLAY_Z_INDEX } from 'theme/zIndex';
import {
  BUY_RTV_FORM_SUBMIT_BUTTON,
  DEFAULT_FONTFAMILY,
  DEFAULT_FONTFAMILY_3,
  NEW_STYLE,
  RTV_COST,
} from 'theme/theme';

export default {
  BuyAnalytics: {
    // root,
  },
  overlay: {
    width: '100%',
    height: '100%',
    position: 'fixed',
    top: 0,
    left: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    zIndex: BUY_RTV_OVERLAY_Z_INDEX,
  },
  sidebar: {
    position: 'fixed',
    right: 0,
    top: 0,
    width: 430,
    height: '100%',
    backgroundColor: '#FFF',
    zIndex: BUY_RTV_SIDEBAR_Z_INDEX,
    overflow: 'auto',
    '&.extraFooterGap:after': {
      width: '100%',
      height: 110,
      display: 'block',
      content: '""',
    },
    '@media screen and (max-width: 429px)': {
      width: '100%',
    },
    '@media screen and (max-height: 860px)': {
      overflowY: 'scroll',
    },
  },
  buyAnalyticsSidebar: {
    width: 540,
  },
  header: {
    padding: [20, 30],
    display: 'grid',
    gridTemplateColumns: '1fr 25px',
    gridTemplateAreas: '"title close"',
    '@media screen and (max-width: 429px)': {
      paddingLeft: 22,
      paddingRight: 22,
    },
    '@media screen and (max-width: 369px)': {
      paddingLeft: 15,
      paddingRight: 15,
    },
  },
  backToPlans: {
    gridArea: 'title',
    textAlign: 'left',
    font: `600 20px/24px ${DEFAULT_FONTFAMILY}`,
  },
  backBtn: {
    width: 22,
    height: 22,
    background: {
      image: `url("${back}")`,
      size: '22px 22px',
      position: 'center center',
      repeat: 'no-repeat',
    },
  },
  closeBtn: {
    width: 25,
    height: 25,
    position: 'relative',
    right: -10,
    // left: 'calc(100% - 20px)',
    gridArea: 'close',
    background: {
      image: `url("${closeBg}")`,
      size: '22px 22px',
      position: 'center center',
      repeat: 'no-repeat',
    },
    '@media screen and (max-width: 429px)': {
      right: 0,
    },
  },
  content: {
    padding: [10, 40],
    '@media screen and (max-width: 429px)': {
      paddingLeft: 22,
      paddingRight: 22,
    },
    '@media screen and (max-width: 369px)': {
      paddingLeft: 15,
      paddingRight: 15,
    },
  },
  titleWrap: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    font: `800 24px/32px ${DEFAULT_FONTFAMILY}`,
  },
  titleVal: {
    color: RTV_COST,
    font: `500 24px/32px ${DEFAULT_FONTFAMILY}`,
  },
  subtext: {
    marginTop: 37,
    font: `400 18px/24px ${DEFAULT_FONTFAMILY}`,
  },
  ArtworkTitle: {
    marginTop: 30,
    font: `400 15px/20px ${DEFAULT_FONTFAMILY}`,
  },
  artistName: {
    fontWeight: 500,
  },
  artworkTitle: {
  },

  ArtistInfo: {
    background: '#F8F6F5',
    marginTop: 37,
    padding: [27, 25, 100, 25],
  },
  hubspotWrp: {
    marginTop: 60,
  },
  analyticsArtistInfo: {
    margin: [0, 0, 75, 0],
  },
  analyticsArtistName: {
    font: `400 48px/56px ${DEFAULT_FONTFAMILY_3}`,
  },
  analyticsArtistLifeDates: {
    font: `700 16px/24px ${DEFAULT_FONTFAMILY_3}`,
  },

  analyticsItem: {
    margin: [0, 0, 25, 0],
  },
  analyticsListTitle: {
    font: `700 16px/24px ${DEFAULT_FONTFAMILY_3}`,
  },
  analyticsList: {
    margin: [7, 0, 0, 17],
    listStyleType: 'disc',
  },
  analyticsListItem: {
    font: `400 16px/24px ${DEFAULT_FONTFAMILY_3}`,
  },

  SuccessBlock: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  successTitle: {
    margin: [150, 0, 70, 0],
    font: `700 34px/40px ${DEFAULT_FONTFAMILY_3}`,
    textAlign: 'center',
  },
  successImage: {
    width: 252,
    height: 252,
    margin: [0, 0, 70, 0],
    background: `url("${check}") left top no-repeat`,
    backgroundSize: 'cover',
  },
  successLink: {
    cursor: 'pointer',
    color: '#304C95',
    font: `700 16px/24px ${DEFAULT_FONTFAMILY_3}`,
    textDecoration: 'none',
  },

  loadingOverlay: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.85)',
    zIndex: BUY_RTV_OVERLAY_Z_INDEX,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loadingTxt: {
    font: `500 24px/32px ${DEFAULT_FONTFAMILY}`,
    color: NEW_STYLE,
  },
  spinner: {
    width: 60,
    height: 60,
    border: `6px solid ${NEW_STYLE}`,
    borderRadius: '50%',
    borderTop: '5px solid transparent',
    transform: 'rotate(45deg)',
    animation: '$rtv-form-spinner 1s linear infinite',
    marginTop: 30,
  },
  '@keyframes rtv-form-spinner': {
    'from': { transform: 'rotate(0deg)' },
    'to': { transform: 'rotate(360deg)' },
  },
  btnConfirmAnalytics: {
    backgroundColor: BUY_RTV_FORM_SUBMIT_BUTTON,
    width: '100%',
    padding: [16],
    marginTop: 125,
    color: '#FFF',
    fontSize: '16px',
    fontWeight: 700,
    fontFamily: DEFAULT_FONTFAMILY,
    lineHeight: '24px',
    '&:hover': {
      opacity: 0.9,
      cursor: 'pointer',
    },
  },
  rtvLeft: {
    font: `400 14px/20px ${DEFAULT_FONTFAMILY}`,
    marginTop: 50,
    textAlign: 'center',
  },
  productSelectionWrapper: {
    '& > p': {
      marginTop: 20,
      font: `400 14px/20px ${DEFAULT_FONTFAMILY}`,
    },
  },
  description: {
    marginTop: 20,
    font: `600 19px/24px ${DEFAULT_FONTFAMILY}`,
  },
};
