import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'cpcs-reconnect';
import moment from 'moment';

import { artistSelector } from 'domain/artwork/ArtworkModel';

import { connectEventsContainer } from 'pages/highcharts/helpers/EventsContext';
import { RefSize } from 'pages/highcharts/helpers/refSize';
import { DEFAULT_FONTFAMILY, PAGE_HEIGHT, PAGE_WIDTH } from 'pages/highcharts/helpers/PDFConstants';
import { Text } from 'pages/highcharts/helpers/Text';

// ABCDEFGHIJKLMNOPQRSTUVWXYZ
// w 1673 h 108
// fs 96
// wpc 1673/26 = 64.35
// wpfs = 64.35/96 = 0.671
//   example 96*0.671 = 64.42
const FONT_SIZE_TO_WIDTH = 0.671;


const ARTIST_NAME_FONT_SIZE = 96;
const ARTIST_NAME_LINE_HEIGHT = 85;
const ARTIST_NAME_BOTTOM = 86;
const TEXT_HEIGHT = 120;
const TEXT_LINE_HEIGHT = 24;
const TEXT_MARGIN_TOP = 53;
const LEFT = 24;
const MAX_WIDTH = PAGE_WIDTH - LEFT * 2;

const defaultArtist = {
  fullName: '',
};

class CoverPageContent extends RefSize {
  static propTypes = {
    //
    artist: PropTypes.shape({
      fullName: PropTypes.string.isRequired,
    }),
    previewMode: PropTypes.bool,
  };

  static defaultProps = {
    artist: defaultArtist,
  };

  render() {
    let { artist, artwork, previewMode } = this.props;
    if (artwork) {
      artist = artwork.artist;
    }
    if (!artist || !artist.fullName) {
      artist = defaultArtist;
    }
    const artistLines = artist.fullName.split(' ');
    const maxLength = artistLines.reduce((prev, line) => Math.max(prev, line.length), 0);
    const countedWidth = ARTIST_NAME_FONT_SIZE *  maxLength * FONT_SIZE_TO_WIDTH;
    let fontSizeMult = 1;
    if (countedWidth > MAX_WIDTH) {
      fontSizeMult = MAX_WIDTH / countedWidth;
    }
    const artistNameFontSize = Math.round(ARTIST_NAME_FONT_SIZE * fontSizeMult);
    const artistNameLineHeight = Math.round(ARTIST_NAME_LINE_HEIGHT * fontSizeMult);
    let dateX = previewMode ? 165 : 149;
    if (artwork) {
      dateX = previewMode ? 195 : 177;
    }
    const artistContent = (
      <Text
        length="600"
        className="coverPagePDF_artistName"
        lineHeight={artistNameLineHeight}
        top={artistNameLineHeight}
        lines={artistLines}
      />
    );
    const artistNameHeight = artistNameLineHeight * artistLines.length;
    const dateText = moment().format('MMMM YYYY');
    return (
      <svg
        width={MAX_WIDTH}
        height={artistNameHeight + TEXT_MARGIN_TOP + TEXT_HEIGHT}
        ref={this.ref}
        data-top={PAGE_HEIGHT - ARTIST_NAME_BOTTOM - artistNameHeight}
        data-left={LEFT}
        data-margin-top="0"
        data-margin-left="0"
      >
        <style>
          {`
          /**
           * Note that the color of the text is set with the
           * fill property, the color property is for HTML only
          **/
          .coverPagePDF_artistName { font: bold ${artistNameFontSize}px ${DEFAULT_FONTFAMILY}; fill: #FFF; }
          .coverPagePDF_reportName { font: 700 ${TEXT_LINE_HEIGHT}px ${DEFAULT_FONTFAMILY}; letter-spacing: 1px; fill: #FFF; }
          .coverPagePDF_date { font: 400 ${TEXT_LINE_HEIGHT}px ${DEFAULT_FONTFAMILY}; letter-spacing: 1px; fill: #FFF; }
          `}
        </style>
        {artistContent}
        <text x="0" y={artistNameHeight + TEXT_MARGIN_TOP} className="coverPagePDF_reportName">
          {artwork ? 'Artwork': 'Artist'} Report |
        </text>
        <text x={dateX} y={artistNameHeight + TEXT_MARGIN_TOP} className="coverPagePDF_date">
          {dateText}
        </text>
      </svg>
    );
  }
}

CoverPageContent = compose(
  connectEventsContainer,
  connect({
    artist: artistSelector,
  }),
)(CoverPageContent);

export { CoverPageContent };
