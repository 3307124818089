import { takeLatest, select, call, put } from 'redux-saga/effects';
import I from 'immutable';
import { asyncAction } from 'lib/actions';
import { parseServerError } from 'lib/helpers';

import {
  currentRouteNameSelector,
  matchParamsSel,
} from 'domain/router/RouterModel';
import { showSharePopupAction } from 'domain/shares/ShareActions';
import {
  SHARE_TYPE_OWN_ARTWORK,
  SHARE_TYPE_ARTWORK,
  SHARE_TYPE_ALERT,
} from 'domain/const';
import { downloadRtvPdfAction } from 'domain/rtvValueListPage/RtvValueListPageActions';
import { onDownloadRtvPdf } from 'pages/rtvValueListPage/sagas/sagas';
// (artwork page)
import { artwork_sel as artworkPageItem } from 'domain/artworkPage/ArtworkPageModel';
// (lotPage, collectionItemPage)
import { currentArtworkSelector as lotPageItem } from 'domain/ownArtwork/OwnArtworkModel';

import Api, { callApi } from 'domain/api';
import { user_sel } from 'domain/env/EnvModel';
import { addNotification } from 'domain/env/EnvActions';
import { redirectToPage } from 'lib/redirectToPage';

import { routerParams_sel } from 'domain/router/RouterModel';
import { createConsiderationOriginal_apiGen } from 'domain/considering/sagas';
export const onItemCollectAction = asyncAction('itemActions/collect');
export const onItemTrackAction = asyncAction('itemActions/track');
export const onItemShareAction = asyncAction('itemActions/share');
export const acquireItemAction = asyncAction('itemActions/acquire');


function* onItemTrack() {
  const { artworkId } = yield select(matchParamsSel);
  yield call(createConsiderationOriginal_apiGen, { id: artworkId });
}

function* onItemShare() {
  const routeName = yield select(currentRouteNameSelector);
  const routeParams = yield select(routerParams_sel);
  let shareType, artwork, moreData = {};
  switch (routeName) {
    case 'lotPage':
      shareType = SHARE_TYPE_ALERT;
      artwork = yield select(lotPageItem);
      moreData = { alertId: routeParams.alertId };
      break;
    case 'collectionItemPage_oldLink':
    case 'collectionItemPage':
      shareType = SHARE_TYPE_OWN_ARTWORK;
      artwork = yield select(lotPageItem);
      break;
    case 'artworkPage':
      shareType = SHARE_TYPE_ARTWORK;
      artwork = yield select(artworkPageItem);
      break;
    default:
      console.error('there is no share type for route', routeName, 'in pages/common/ItemActions/saga');
      return;
  }
  /**
   * sometimes selectors returns new Artwork({ id })
  **/
  if (!artwork || !artwork.getIn(['artist', 'id'])) return;
  yield put(showSharePopupAction({ ids: I.Set([artwork.get('id')]), shareType, ...moreData }));
}

function* onItemCollect(action) {
  if (action.payload.collected) {
    yield put(addNotification({ title: 'Artwork has already been added to My Collection', type: 'success' }));
    return;
  }
  yield call(onItemAcquired);
}

function* acquireLot(apiFn, id) {
  try {
    const { data: { id: artworkId, artist } } = yield callApi(apiFn, { id, data: {} });
    const { defaultNewGallery: gId } = yield select(user_sel);
    yield put(addNotification({ title: 'Artwork added to My Collection', type: 'success' }));
    const pageParams = { gId, authorId: artist.id, artworkId };
    yield redirectToPage('collectionItemPage', pageParams);
  } catch (err) {
    const message = parseServerError(err);
    if (message === 'You already own this artwork') {
      yield put(addNotification({ title: 'Artwork has already been added to My Collection', type: 'success' }));
      return true;
    }
    yield put(addNotification({ title: 'Artwork not added to My Collection', type: 'error' }));
    return false;
  }
}

function* onItemAcquired() {
  const routeName = yield select(currentRouteNameSelector);
  const routeParams = yield select(routerParams_sel);
  switch (routeName) {
    case 'artworkPage':
      yield call(acquireLot, Api.acquireLot, routeParams.artworkId);
      break;
    case 'lotPage':
      yield call(acquireLot, Api.acquireLot, routeParams.artworkId);
      break;
    case 'collectionItemPage_oldLink':
    case 'collectionItemPage':
      yield call(acquireLot, Api.acquireConsideration, routeParams.artworkId);
      break;
    default:
      break;
  }
}

export function* watchItemActions() {
  yield takeLatest(onItemShareAction.type, onItemShare);
  yield takeLatest(onItemTrackAction.type, onItemTrack);
  yield takeLatest(onItemCollectAction.type, onItemCollect);
  yield takeLatest(acquireItemAction.type, onItemAcquired);
  yield takeLatest(downloadRtvPdfAction.type, onDownloadRtvPdf);
}
