import removeCircle from '../images/remove-circle-black-24px.svg';

export default {
  Revoke: {
    cursor: 'pointer',
    '&:before': {
      content: '""',
      flex: '0 0 24px',
      margin: [0, 10],
      background: {
        color: 'transparent',
        size: '24px 24px',
        repeat: 'no-repeat',
        position: 'left center',
        image: `url("${removeCircle}")`,
      },
    },
  },
  revokeTxt: {
    whiteSpace: 'nowrap',
    flex: '1 1 0',
    alignSelf: 'center',
  },
};
