import { action, asyncAction } from 'lib/actions';
import { push as pushAction } from 'connected-react-router';
import { setStorageItem, clearStorage } from 'lib/storage';
import { currentTime } from 'lib/helpers';

export const updateAccountAction = asyncAction('ACCOUNT/update');
export const updatePreferredCurrencyAction = action('ACCOUNT/update/currency');
export const changePasswordAction = asyncAction('ACCOUNT/password');
export const forgotPasswordAction = asyncAction('ACCOUNT/forgot-password');
export const createPasswordAction = asyncAction('ACCOUNT/create-password');
export const resetPasswordAction = asyncAction('ACCOUNT/reset-password');
export const checkResetPasswordToken = asyncAction('ACCOUNT/check-reset-password-token');
export const saveBillingAction = asyncAction('ACCOUNT/SAVE_BILLING');
export const currentPlanAction = asyncAction('ENV/GET_CURRENT_PLAN');
export const subscribeAction = asyncAction('ACCOUNT/SUBSCRIBE');

export const addBreadCrumbs = action('BC/UPDATE');
const notifyAC = ({ timestamp = parseInt(currentTime.format('x'), 10), ...data }) => ({ payload: { ...data, timestamp } });
/**
 * yield put(addNotification({ title, type: 'warning' }))
 * type: 'success', 'warning', 'error'
*/
export const addNotification = action('NOTIFICATION/ADD', notifyAC);
export const removeNotification = action('NOTIFICATION/REMOVE');
export const clearOutdatedNotifications = action('NOTIFICATION/CLEAR');

export const logOut = Object.assign(
  () => (dispatch) => {
    clearStorage();
    dispatch({ type: 'auth/logout' });
    dispatch(pushAction('/auth/login'));
  },
  { type: 'auth/logout' },
);

export const loginAction = asyncAction('auth/login');

export const checkAuthAction = asyncAction('auth/checkAuth');

export const getUserStatsAction = asyncAction('USER/GET_STATS');

export const registerAction = asyncAction('auth/register');
export const termsAcceptAction = action('auth/TERMS_ACCEPT');
export const privacyAcceptAction = action('auth/PRIVACY_ACCEPT');
export const secondAcceptAction = action('auth/ACCEPT_TERMS_2');

export const hideNewArtTip = action('hideNewArtTip', () => {
  setStorageItem('newArtTipVisible', 'hidden');
  return {};
});

export const fetchTrialPlanPurchasesCountAction = asyncAction('trial/FETCH_PURCHASES_COUNT');

export const uploadPictureAction = asyncAction('ACCOUNT/UPLOAD_PICTURE');
export const removePictureAction = asyncAction('ACCOUNT/REMOVE_PICTURE');
export const uploadErrorAction = action('ACCOUNT/UPLOAD_PICTURE_ERROR');
export const saveStripeCardAction = asyncAction('ACCOUNT/SAVE_STRIPE_CARD_TOKEN');
export const fetchStripePropertiesAction = asyncAction('ACCOUNT/FETCH_STRIPE_PROPERTIES');
export const setCardFormOpenedAction = action('ACCOUNT/SET_CARD_FORM_OPENED');
/**
 * used when social network provides accessToken / authToken to send
 * it to api. Api checks user data and returns our app auth token
 * or user data to fill registration form and ask user for required data.
 * usage:
 *   authSocialNetworkAction({ providerId, data: { (accessToken | code + redirectUrl) } })
**/
export const authSocialNetworkAction = action('AUTH/SOCIAL_NETWORK');
export const authSocialNetworkEmailProvidedAction = action('AUTH/SOCIAL_NETWORK_EMAIL_PROVIDED');
/**
 * used when social network provides auth response (with/without accessToken, with/without error)
 * used to check data and build authSocialNetworkAction
**/
export const onGoogleSignInAction = action('AUTH/ON_GOOGLE_SIGN_IN');
export const onFacebookSignInAction = action('AUTH/ON_FACEBOOK_SIGN_IN');
export const getTwitterOauthTokenAction = asyncAction('AUTH/GET_TWITTER_OAUTH_TOKEN');

export const snAuthPrivacyAndTermsAcceptedAction = action('AUTH/PRIVACY_AND_TERMS_ACCEPPTED');

export const resendInvoiceAction = action('BILLING/RESEND_INVOICE');
