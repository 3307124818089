import React from 'react';
import { connect } from 'cpcs-reconnect';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import injectSheet from 'lib/sheet';
import { query_sel, sort_sel, advancedFilterActive_sel } from 'domain/router/RouterModel';
import { updateQueryAction } from 'domain/router/RouterAction';
import { filterToggleAction, filterOpenAction } from 'domain/ui/UIActions';
import { advancedFilterOpenState_sel } from 'domain/ui/UIModel';
import sheet from './sheet';
import cx from 'classnames';
import SortBy from 'pages/common/filters/elements/sortBy';
import filters, { getDefaultSortItem } from 'pages/common/filters/config';
import { withRouter } from 'react-router-dom';
import debounce from 'debounce';
import { cleanParams } from 'pages/common/sidebar/filter/utils';
import { currentRouteNameSelector } from 'domain/router/RouterModel';

const buttonText = {
  AFOpenedDesktop: 'Hide',
  AFDefaultDesktop: 'Hide',
  AFClosedDesktop: 'Show',
  AFOpenedMobile: 'Hide',
  AFDefaultMobile: 'Show',
  AFClosedMobile: 'Show',
};
const pages = [
  'collectionListPage',
  'collectionList_oldLink',
  'artistPage',
  'saleLotsListPage',
];

class TitleSearch extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.shape({
      selectField: PropTypes.string,
      clear: PropTypes.string,
    }).isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string,
    placeholder: PropTypes.string.isRequired,
  };

  state = {
    value: this.props.value || '',
  }

  onChange = (value = '') => {
    const { onChange } = this.props;
    this.setState({ value }, () => onChange(value.trim()));
  }

  render() {
    const { classes, placeholder } = this.props;
    const { value } = this.state;
    return (
      <div>
        <input
          onChange={(e) => this.onChange(e.target.value)}
          placeholder={placeholder}
          className={classes.selectField}
          value={value}
        />
        { value && <button className={classes.clear} onClick={() => this.onChange('')}>×</button>}
      </div>
    );
  }
}

TitleSearch = injectSheet(sheet)(TitleSearch);

class Filters extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.shape({
      advanced: PropTypes.string,
      selectField: PropTypes.string.isRequired,
      clear: PropTypes.string.isRequired,
      Filters: PropTypes.string.isRequired,
      wrapper: PropTypes.string.isRequired,
      advancedFilterButtonBox: PropTypes.string.isRequired,
      advancedWord: PropTypes.string.isRequired,
      sortDropdownBox: PropTypes.string.isRequired,
    }).isRequired,
    filterToggle: PropTypes.func.isRequired,
    filterOpen: PropTypes.func.isRequired,
    updateQuery: PropTypes.func.isRequired,
    sort: PropTypes.string,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
    }).isRequired,
    sortByList: PropTypes.array,
    placeholder: PropTypes.string.isRequired,
    routeName: PropTypes.string,
    advancedFilterOpenState: PropTypes.string,
    query: PropTypes.shape({
      artTitle: PropTypes.string,
      page: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }).isRequired,
    modifier: PropTypes.string,
    advancedFilterActive: PropTypes.bool,
  };

  static defaultProps = {
    sortByList: filters.gallery,
  };

  changeFilter = (filter) => {
    const { history, query, location } = this.props;
    delete query.page;
    history.push(`${location.pathname}?${cleanParams({ ...query, ...filter })}`);
  };

  changeFilterHandler = debounce(this.changeFilter, 1000);
  changeArtworkHandler = artTitle => this.changeFilterHandler({ artTitle });

  renderArtworksFilter = () => {
    const { query: { artTitle }, placeholder } = this.props;
    return (
      <TitleSearch onChange={this.changeArtworkHandler} value={artTitle} placeholder={placeholder} />
    );
  }

  render() {
    const { classes, sort, updateQuery, sortByList, routeName, modifier, advancedFilterOpenState,
      advancedFilterActive: active } = this.props;
    const { filterOpen, filterToggle } = this.props;
    const advancedFiltersAllowed = pages.includes(routeName);
    return (
      <div className={cx(classes.Filters, modifier, routeName)}>
        <div className={classes.wrapper}>
          <div className={classes.advancedFilterButtonBox}>
            {
              advancedFiltersAllowed && ['Desktop', 'Mobile'].map((device, mobile) => (
                <button
                  key={device}
                  type="button"
                  className={cx(classes.advanced, mobile ? 'open' : 'toggle', advancedFilterOpenState, { active })}
                  onClick={() => mobile ? filterOpen() : filterToggle()}
                ><span className={classes.advancedWord} children={buttonText[advancedFilterOpenState + device]} /> Filter
                </button>
              ))
            }
            {/** /}
            <div className={classes.searchBox}>
              { showExternal && this.renderArtworksFilter() }
            </div>
            {/**/}
          </div>
          <div className={classes.sortDropdownBox}>
            <SortBy
              list={sortByList}
              onChange={(e) => updateQuery({ sort: e.target.value })}
              value={sort || getDefaultSortItem(sortByList).id}
              alignedLeft
            />
          </div>
        </div>
      </div>
    );
  }
}

export default compose(
  withRouter,
  connect({
    routeName: currentRouteNameSelector,
    filterToggle: filterToggleAction,
    filterOpen: filterOpenAction,
    advancedFilterOpenState: advancedFilterOpenState_sel,
    sort: sort_sel,
    query: query_sel,
    updateQuery: updateQueryAction,
    advancedFilterActive: advancedFilterActive_sel,
  }),
  injectSheet(sheet),
)(Filters);
