import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import cx from 'classnames';
import { connect } from 'cpcs-reconnect';
import { submit as submitAction } from 'redux-form';
import injectSheet from 'lib/sheet';

import { sheet } from './sheet';

function Header({ classes, title, noTitleWrap, submitName, saveDraft, submit, modifier, onCancel }) {
  const onSubmit = () => submit(submitName);
  return (
    <header className={cx(classes.PageHeader, 'AO-Form-PageHeader', modifier)}>
      {noTitleWrap && (title || null)}
      {
        !noTitleWrap &&
          <h2 className={cx(classes.title, modifier)}>
            {title}
          </h2>
      }
      <div className={cx(classes.actions, modifier, { hasDraft: !!saveDraft })}>
        {
          onCancel &&
            <button
              type="button"
              onClick={() => onCancel()}
              children="CANCEL"
              className={cx(classes.button, 'AO-Form-PageHeader cancel')}
            />
        }
        {
          saveDraft &&
            <button
              type="button"
              onClick={saveDraft}
              className={cx(classes.button, 'AO-Form-PageHeader draft')}
              children="SAVE AS DRAFT"
            />
        }
        <button
          type="button"
          onClick={onSubmit}
          className={cx(classes.button, 'AO-Form-PageHeader save')}
        >
          SUBMIT
        </button>
      </div>
    </header>
  );
}

Header.propTypes = {
  classes: PropTypes.shape({
    PageHeader: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    actions: PropTypes.string.isRequired,
    button: PropTypes.string.isRequired,
  }).isRequired,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  noTitleWrap: PropTypes.bool,
  modifier: PropTypes.string,
  submitName: PropTypes.string,
  submit: PropTypes.func,
  saveDraft: PropTypes.func,
  onCancel: PropTypes.func,
};

export default compose(
  connect({
    submit: submitAction,
  }),
  injectSheet(sheet),
)(Header);


export { Header };
