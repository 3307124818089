import { field, selector } from 'cpcs-reconnect';
import I from 'immutable';
import queryString from 'query-string';
import * as Artworks from 'domain/ownArtwork/OwnArtworkModel';
import * as Considerations from 'domain/considering';
import * as Author from 'domain/author';
import { excluded, findFilterFromLocation } from 'pages/common/filters/config';
import { matchRoute } from 'lib/routes';
import { isTrackingLink } from 'lib/helpers';

const deepCopy = o => o ? I.fromJS(o).toJS() : o;

const base = field('router').then(r => r && r.location ? deepCopy(r) : null);
/**
 * router.location value example
 * {
 *   pathname: '/artist/185449/analytics',
 *   search: '?foo=bar',
 *   hash: '#hash',
 *   query: {
 *     foo: 'bar',
 *   },
 *   match: {
 *     path: '/artist/:authorId/analytics',
 *     url: '/artist/185449/analytics',
 *     isExact: true,
 *     params: {
 *       authorId: '185449',
 *     },
 *     name: 'artistReportsPage',
 *   },
 * },
**/
export const location_sel = selector(base, r => r ? r.location : { match: { name: 'home' } });
export const currentRouteNameSelector = location_sel.then(l => (l && l.match && l.match.name));
const ensureLocation = location => {
  if (location) return location;
  /**
   * this error is better for debug then '.match of undefined'
  **/
  throw new Error('location undefined');
};
/**
 * router.location.match value exapmle:
 * {
 *   path: '/tracking/edit/:artworkId',
 *   url: '/tracking/edit/793711',
 *   isExact: true,
 *   params: {
 *     artworkId: '793711',
 *   },
 *   name: 'trackingItemEditLink',
 * }
**/
export const getMatch = selector(base, r => ensureLocation(r.location).match);
export const getMatchSelector = getMatch;
/**
 * url placeholder values, like /alerts/:alertId/:artworkId
**/
export const routerParams_sel = selector(getMatch, m => m.params || {});
export const matchParamsSel = routerParams_sel;
export const routeName_sel = getMatchSelector.then(match => ((match && match.name) || ''));

export const authorFromRoute = selector(routerParams_sel, Author.authorsById,
  ({ authorId }, list) => list.get(parseInt(authorId, 10)),
);
export const authorValuation = selector(authorFromRoute,
  v => v ? v.get('totalRTV') : new I.Record({ value: 0, unit: 'USD' }),
);

export const search = selector(location_sel, l => l.search);
/**
 * query params, like parsed { foo: 'bar' } from ?foo=bar
**/
export const query_sel = selector(location_sel, l => l.query);
export const locationHash_sel = location_sel.then(l => l.hash);
export const currentPage = selector(query_sel, ({ page }) => parseInt(page, 10) || 1 );
export const prevLocationSelector = selector(base, r => r.prevLocation);
export const pathnameChanged = selector(location_sel, prevLocationSelector, (l, p) => !p || (l.pathname !== p.pathname));

const advancedFilterActiveExcludeQueryParams = ['type', 'sort', 'currency', 'page', 'sizeUnit'];
export const advancedFilterActive_sel = query_sel.then(query =>
  !!Object.keys(query)
    .filter(key => !advancedFilterActiveExcludeQueryParams.includes(key))
    .length,
);
export const queryCategoriesNotEmpty_sel = query_sel.then(query =>
  query && query.categories && query.categories.join && !!query.categories.join(''),
);
export const searchWithoutPage_sel = selector(search, (s = '') => {
  const clean = s.replace(/page=\d+/, '').replace('?&', '?').replace('&&', '&');
  return clean === '?' ? '' : clean;
});
export const state = selector(location_sel, l => l.state);
export const sort = selector(location_sel, (loc) => findFilterFromLocation(loc).id);
export const sortSel = sort;
export const sort_sel = sort;
export const sortValueSelector = selector(location_sel, (loc) => findFilterFromLocation(loc).params.sort);
export const selectedList = selector(
  location_sel,
  Artworks.selected,
  Considerations.selected,
  ({ match: { name = '' } }, artworks, considerations) =>
    (isTrackingLink(name) ? considerations : artworks) || I.Set(),
);

export const queryParams = selector(location_sel, (loc) => {
  const { query } = loc;
  let additionalParams = {};
  const { params } = findFilterFromLocation(loc);
  Object.keys(query).filter(key => !excluded.includes(key)).map(key => additionalParams[key] = query[key]);
  return { ...params, ...additionalParams };
});

export const filterParamsSelector = queryParams;

const extractMaching = (locationPathname) => {
  const m = matchRoute(locationPathname);
  if (m) {
    return {
      match: {
        ...m.match,
        name: m.route.name,
      },
    };
  }
  return { match: { params: {} } };
};

export const prevLocationReducer = (state, action) => {
  if (!state) {
    return state;
  }
  if (action.type === '@@router/LOCATION_CHANGE' && action.payload && !action.payload.isFirstRendering) {
    return {
      ...state,
      prevLocation: state.location,
    };
  }
  return state;
};

export const reducer = (state, action) => {
  if (!state) {
    return state;
  }
  if (action.type === '@@router/LOCATION_CHANGE') {
    const location = {
      ...state.location,
      ...extractMaching(state.location.pathname),
      query: queryString.parse(state.location.search, { arrayFormat: 'bracket' }),
    };
    return { ...state, location };
  }
  return state;
};
