import { DARK_BLUE_COLOR, DEFAULT_FONTFAMILY } from 'theme/theme';
import emailsIcon from 'pages/common/sharedItemsPage/Details/images/Users.svg';

export default {
  Details: {
    // root
    display: 'flex',
    justifyContent: 'space-between',
    '@media screen and (max-width: 470px)': {
      display: 'block',
    },
  },
  commentBox: {
    flex: '2 2 0',
    marginRight: 30,
  },
  comment: {
    paddingTop: 20,
    color: DARK_BLUE_COLOR,
    fontFamily: DEFAULT_FONTFAMILY,
    fontSize: 14,
    lineHeight: '18px',
  },
  recipientWrapper: {
    flex: '1 1 0',
    display: 'inline-flex',
    justifyContent: 'flex-end',
    '@media screen and (max-width: 470px)': {
      marginTop: 20,
      display: 'flex',
    },
  },
  created: {
    flex: '0 0 80px',
    alignSelf: 'flex-end',
    whiteSpace: 'nowrap',
    lineHeight: '16px',
    color: DARK_BLUE_COLOR,
    fontFamily: DEFAULT_FONTFAMILY,
    fontSize: 14,
    fontWeight: 400,
  },
  sharedByEmail: {
    flex: '0 0 22px',
    marginLeft: 20,
    alignSelf: 'flex-end',
    position: 'relative',
    fontSize: 14,
    textDecoration: 'underline',
    fontWeight: 500,
  },
  emails: {
    flex: '0 0 22px',
    marginLeft: 11,
    alignSelf: 'flex-end',
    position: 'relative',
    '&:hover $emailsPopover': {
      display: 'block',
    },
  },
  emailsIcon: {
    width: 22,
    height: 15,
    display: 'inline-block',
    background: {
      image: `url(${emailsIcon})`,
      size: 'cover',
      position: 'center center',
      repeat: 'no-repeat',
      color: 'transparent',
    },
  },
  emailsPopover: {
    position: 'absolute',
    backgroundColor: '#000',
    opacity: 0.7,
    color: '#FFF',
    right: 0,
    top: -52,
    minWidth: 130,
    maxWidth: 350,
    padding: 6,
    font: `300 11px/14px ${DEFAULT_FONTFAMILY}`,
    display: 'none',
  },
  emailsList: {
    listStyle: 'none',
    margin: 0,
    padding: 0,
    marginLeft: -10,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  emailListItem: {
    display: 'inline-block',
    padding: [5, 0, 0, 10],
    whiteSpace: 'nowrap',
    '&:firstChild': {
      paddingLeft: 0,
    },
  },
};
