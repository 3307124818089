import React from 'react';
import PropTypes from 'prop-types';
import I from 'immutable';
import cx from 'classnames';
import Checkbox from 'components/form/checkbox';
import injectSheet from 'lib/sheet';
import sheet from './sheet';

/**
 * import { Field } from 'redux-form';
 * import CheckboxList from 'components/form/checkboxList';
 * <Field
 *   name="colors"
 *   component={CheckboxList}
 *   list={['red', 'green', 'blue', 'purple']}
 *   getNewListValue={({ listValue, item, value }) => ({ ...listValue, [item]: value })}
 *   getCurrentValue={({ listValue, item }) => listValue[item]}
 *   getId={({ listValue, item }) => item}
 * />
 * // redux-form value example: { red: true, green: true }
 *
 * import { Field } from 'redux-form';
 * import CheckboxList from 'components/form/checkboxList';
 * import { strToMap, mapToStr } from 'lib/helpers';
 * import I from 'immutable';
 * const artistList = I.fromJS([{ id: 1, firstName, lastName }, ...]);
 * <Field
 *   name="artists"
 *   component={CheckboxList}
 *   list={artistList}
 *   parse={mapToStr}
 *   format={strToMap}
 *   getLabel={(v) => `${v.get('firstName')} ${v.get('lastName')}`}
 *   getNewListValue={({ listValue, item, value }) => ({ ...listValue, [item.get('id')]: value })}
 *   getCurrentValue={({ listValue, item }) => listValue[item.get('id')]}
 *   getId={({ listValue, item }) => item.get('id')}
 * />
 * // redux-form value example: '1,2'
 *
 * const mapToArr = (hashTable) => Object.keys(hashTable || {}).reduce((collect, key) => hashTable[key] ? [...collect, key] : collect, []);
 * const arrToMap = (arr) => (arr || []).reduce((collect, v) => ({ ...collect, [v]: true }), {});
 * <Field
 *   name="artists"
 *   component={CheckboxList}
 *   list={artistList}
 *   parse={mapToArr}
 *   format={arrToMap}
 *   getLabel={(v) => `${v.get('firstName')} ${v.get('lastName')}`}
 *   getNewListValue={({ listValue, item, value }) => ({ ...listValue, [item.get('id')]: value })}
 *   getCurrentValue={({ listValue, item }) => listValue[item.get('id')]}
 *   getId={({ listValue, item }) => item.get('id')}
 * />
 * // redux-form value example: [1, 2]
 *
 * import Radio from 'components/form/radio';
 * const onCategoryCheck = ({ item }) => ({ [item.getIn(['entity', 'id'])]: true });
 * const categoryGetCurrentValue = ({ item }) => item.getIn(['entity', 'id']);
 * const addCategoryInputChecked = ({ listValue, item }) => listValue[item.getIn(['entity', 'id'])];
 * <Field
 *   name="categories"
 *   modifier="advancedFilter categories"
 *   component={CheckboxList}
 *   ItemComponent={Radio}
 *   list={categoryList}
 *   parse={mapToArr}
 *   format={arrToMap}
 *   getLabel={item => `${item.getIn(['entity', 'title'])} (${item.get('count')})`}
 *   getNewListValue={onCategoryCheck}
 *   getCurrentValue={categoryGetCurrentValue}
 *   getId={categoryGetCurrentValue}
 *   addInputChecked={addCategoryInputChecked}
 *   onChange={this.onCategoryChange}
 * />
 * // redux-form value example: [1]
**/
class CheckboxList extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.shape({
      CheckboxList: PropTypes.string,
      listItem: PropTypes.string,
    }).isRequired,
    list: PropTypes.oneOfType([
      PropTypes.instanceOf(I.Collection),
      PropTypes.array,
    ]).isRequired,
    getLabel: PropTypes.func,
    disabled: PropTypes.bool,
    getCurrentValue: PropTypes.func.isRequired,
    getNewListValue: PropTypes.func.isRequired,
    getId: PropTypes.func.isRequired,
    defaultListValue: PropTypes.any,
    input: PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.any,
      onChange: PropTypes.func.isRequired,
    }).isRequired,
    modifier: PropTypes.string,
    addInputChecked: PropTypes.func,
    // valid react component
    ItemComponent: PropTypes.any,
    unselectedOption: PropTypes.any,
  };

  static defaultProps = {
    ItemComponent: Checkbox,
    getLabel: s => s,
    defaultListValue: {},
  };

  onChange = item => value => {
    const { getNewListValue, defaultListValue } = this.props;
    const { input: { onChange, value: listValue = defaultListValue } } = this.props;
    const newValue = getNewListValue({ listValue, item, value });
    onChange(newValue);
  }

  renderCheckbox(item, index) {
    const { getLabel, getCurrentValue, disabled, getId, defaultListValue, modifier } = this.props;
    const { input: { name, value: listValue = defaultListValue } } = this.props;
    const { ItemComponent, addInputChecked } = this.props;
    let inputProps = {
      value: getCurrentValue({ listValue, item }),
      onChange: this.onChange(item),
      name: `${name}.${getId({ listValue, item })}`,
    };
    if (addInputChecked) {
      inputProps = { ...inputProps, checked: addInputChecked({ listValue, item }) };
    }
    return <ItemComponent
      input={inputProps}
      labelText={getLabel(item)}
      disabled={disabled}
      modifier={modifier}
      index={index}
    />;
  }

  render() {
    const { classes, list, unselectedOption, modifier } = this.props;
    return (
      <ul
        className={cx(classes.CheckboxList, modifier)}
      >
        {
          (!!unselectedOption && list.size > 1) &&
            <li key="advanced-filter-artist--1" className={cx(classes.listItem, modifier)}>
              {this.renderCheckbox(unselectedOption, -1)}
            </li>
        }
        {
          list.map((item, index) => (
            <li key={`advanced-filter-artist-${index}`} className={cx(classes.listItem, modifier)}>
              {this.renderCheckbox(item, index)}
            </li>
          ))
        }
      </ul>
    );
  }
}

export default injectSheet(sheet)(CheckboxList);
