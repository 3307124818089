import { popupComponents } from 'pages/common/popup/sheet';

export default {
  NewAuthorPopup: {
    // root
  },
  ...popupComponents,
  container: {
    ...popupComponents.container,
  },
};
