/* eslint no-unused-vars: warn */
import React from 'react';
import PropTypes from 'prop-types';
import I from 'immutable';
import moment from 'moment';

import { formatSold, extractCurrency, formatEstimate } from 'pages/common/artworkView/solvers/salesHistorySolver';
import { cutStr } from 'pages/highcharts/helpers/helpers';

import { connectEventsContainer } from 'pages/highcharts/helpers/EventsContext';
import { RefSize } from 'pages/highcharts/helpers/refSize';
import { DEFAULT_FONTFAMILY } from 'pages/highcharts/helpers/PDFConstants';

const BASE_HEIGHT = 55;
const ROW_HEIGHT = 15;

class SalesHistoryPDF extends RefSize {
  static propTypes = {
    PDFState: PropTypes.shape({
      // contentSections: PropTypes.instanceOf(I.Collection),
    }).isRequired,
  };

  render() {
    const { artwork } = this.props;
    const salesHistory = (!!artwork.salesHistory && !!artwork.salesHistory.size && artwork.salesHistory) || I.List();
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="350"
        height={BASE_HEIGHT + salesHistory.size * ROW_HEIGHT}
        ref={this.ref}
        data-top="91"
        data-left="240"
      >
        <style>
          {`
          .SalesHistoryPDF_title { font: 700 14px ${DEFAULT_FONTFAMILY}; fill: #454A5F; }
          .SalesHistoryPDF_cellTitle { font: 500 7.74px ${DEFAULT_FONTFAMILY}; fill: #313545; }
          .SalesHistoryPDF_cellValue { font: 400 9px ${DEFAULT_FONTFAMILY}; fill: #707070; }
          `}
        </style>
        <g>
          <text y="15" className="SalesHistoryPDF_title">
            Sales History
          </text>
          <text y="50" x="0" className="SalesHistoryPDF_cellTitle">
            Sale Date
          </text>
          <text y="50" x="63" className="SalesHistoryPDF_cellTitle">
            Auction House
          </text>
          <text y="50" x="134" className="SalesHistoryPDF_cellTitle">
            Estimate
          </text>
          <text y="50" x="255" className="SalesHistoryPDF_cellTitle">
            Price Sold
          </text>
          <text y="50" x="317" className="SalesHistoryPDF_cellTitle">
            Currency
          </text>
          {
            salesHistory.map((lot, i) => (
              // (!lot.editable && lot.privateLabel) && 'Private sale'
              // lot.soldDate
              // formatSold(lot), formatEstimate(lot), extractCurrency(lot)
              <g transform={`translate(0, ${65 + i * ROW_HEIGHT})`} key={lot.id}>
                <text x="0" className="SalesHistoryPDF_cellValue">
                  {!!lot.soldDate && moment(lot.soldDate).format('MM/DD/YYYY')}
                </text>
                <text x="63" className="SalesHistoryPDF_cellValue">
                  {cutStr(lot.seller /* 'abcdefgh1abcdefgh2abcd3fgh3abcd3fgh4abcd3fgh5abcd3fgh6' */, 14)}
                </text>
                <text x="134" className="SalesHistoryPDF_cellValue">
                  {formatEstimate(lot, 'SalesHistoryPDF')}
                </text>
                <text x="255" className="SalesHistoryPDF_cellValue">
                  {formatSold(lot)}
                </text>
                <text x="317" className="SalesHistoryPDF_cellValue">
                  {extractCurrency(lot)}
                </text>
              </g>
            ))
          }
        </g>
      </svg>
    );
  }
}

SalesHistoryPDF = connectEventsContainer(SalesHistoryPDF);

export { SalesHistoryPDF };
