import React from 'react';
import I from 'immutable';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'cpcs-reconnect';
import Badge from 'pages/common/ItemList/Badges/Artwork';

import injectSheet from 'lib/sheet';
import sheet from './sheet';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { fullName } from 'domain/artist/helpers';
import {
  otherArtistWorksListSelector,
  otherArtistWorksArtistSelector,
  otherArtistWorksPaginationSelector,
} from 'domain/otherArtistWorks/otherArtistWorksModel';
import { Artist } from 'model';
import { lnk } from 'lib/routes';

function artistWorksLink(artist) {
  if (!artist || !artist.id) return '';
  return lnk('artistPage', { authorId: artist.id });
}
const artworkLinkBuilder = artwork => lnk('artworkPage', {
  artworkId: artwork.get('id'),
});

function PageSectionOtherArtistWorks(props) {
  const { classes, list, pagination, artist } = props;
  if (!list.size) return null;
  return (
    <div className="PAGE_SECTION divider PageSectionOtherArtistWorks">
      <div className={cx(classes.sectionHeader, 'lotPageArtistWorks')}>
        <Link
          to={artistWorksLink(artist)}
          className={classes.sectionHeaderTitleLink}
        >
          Other Works by <span className="sectionHeaderTitleLink__artist" children={fullName(artist)} />
        </Link>
        <Link
          to={artistWorksLink(artist)}
          className={classes.sectionHeader_authorWorksAllLink}
        >
          <span className="see-all">See all{' '}</span>
          <span className="see-all__total">({pagination.total})</span>
        </Link>
      </div>
      <div className={cx(classes.otherArtistWorks, 'otherArtistWorks')}>
        {
          list.map((lot, i) => (
            <div
              className={classes.item}
              key={lot.get('id') + `${i}`}
            >
              <Badge
                artwork={lot}
                linkBuilder={artworkLinkBuilder}
              />
            </div>
          ))
        }
      </div>
    </div>
  );
}

PageSectionOtherArtistWorks.propTypes = {
  classes: PropTypes.shape({
    otherArtistWorks: PropTypes.string.isRequired,
    sectionHeader: PropTypes.string.isRequired,
    sectionHeaderTitleLink: PropTypes.string.isRequired,
    sectionHeader_authorWorksAllLink: PropTypes.string.isRequired,
    item: PropTypes.string.isRequired,
  }).isRequired,
  list: PropTypes.instanceOf(I.Collection).isRequired,
  pagination: PropTypes.instanceOf(I.Record),
  artist: PropTypes.instanceOf(Artist),
};

export default compose(
  injectSheet(sheet),
  connect({
    list: otherArtistWorksListSelector,
    pagination: otherArtistWorksPaginationSelector,
    artist: otherArtistWorksArtistSelector,
  }),
)(PageSectionOtherArtistWorks);
