import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'cpcs-reconnect';
import cx from 'classnames';
import AccountForm from './AccountForm';
import CurrencyForm from './CurrencyForm';
import ChangePasswordForm from './ChangePasswordForm';
import Avatar from './Avatar';
import PageContainer from 'pages/common/pageContainer';
import { extraFooterGap_sel } from 'domain/ui/UIModel';
import { UpgradeSubscriptionSection } from 'pages/upgradeSubscriptionPage';
import { query_sel } from 'domain/router/RouterModel';

import injectSheet from 'lib/sheet';
import styles from './sheet.js';

class AccountPage extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    extraFooterGap: PropTypes.bool,
    query: PropTypes.shape({
      congratulation: PropTypes.string,
    }),
  };

  render() {
    const { classes, extraFooterGap, query: { congratulation } } = this.props;
    return (
      <PageContainer modifier={cx('AccountPage', { extraFooterGap })}>
        <div className={classes.header}>
          <h1 children="My Account" className={classes.title} />
        </div>
        <div className={cx(classes.content, 'AccountPage')}>
          <div className={cx(classes.column2, 'AccountPage Avatar')}>
            <Avatar />
          </div>
          <div className={cx(classes.column1, 'AccountPage')}>
            <AccountForm />
            <ChangePasswordForm />
            <CurrencyForm />
          </div>
        </div>

        <UpgradeSubscriptionSection modifier="AccountPage" allowSaveBillingInfo showCongratulation={!!congratulation} />
      </PageContainer>
    );
  }
}

export default compose(
  injectSheet(styles),
  connect({
    extraFooterGap: extraFooterGap_sel,
    query: query_sel,
  }),
)(AccountPage);
