import React from 'react';
import PropTypes from 'prop-types';
import DropZone from 'react-dropzone';
import { compose } from 'redux';
import cx from 'classnames';
import I from 'immutable';
import accepts from 'attr-accept';
import { change as changeAction, arrayPush as arrayPushAction } from 'redux-form';
import { connect } from 'cpcs-reconnect';
import FileDownload from 'js-file-download';

import { docs_sel } from 'pages/common/artworkForm/ArtworkFormModel';
import Progress from 'components/progressbar';
import { addNotification as addNotificationAction, token } from 'domain/env';
import injectSheet from 'lib/sheet';
import sheet from './style';
import Api from 'domain/api';
import Popup from 'components/popup';
import PopupTitle from 'pages/common/popup/title';
import Select from 'components/form/select';
import Hint from 'pages/common/selectedItemsControls/hint';

const MB = 1024 * 1024;
const doNothing = () => null;
const FILES_LIMIT = 10;

const rmExt = s => [(s || '')].join('').replace(/\.\w+$/i, '');
const onlyExt = s => [(s || '')].join('').replace(/.+(\.\w+)$/i, '$1');

const types = [
  'Invoice',
  'Appraisal',
  'Condition Report',
  'Letter of Authenticity',
  'Other',
];

const typesList = I.fromJS(types.map(id => ({ id, title: id })));

class FilesPure extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    addNotification: PropTypes.func,
    change: PropTypes.func,
    pushValue: PropTypes.func,
    docs: PropTypes.array,
    token: PropTypes.string,
    readOnly: PropTypes.bool,
    accept: PropTypes.string,
  }

  static defaultProps = {
    accept: '.doc,.docx,.pdf',
  };

  onDrop = (acceptedFiles, rejectedFiles) => {
    const { addNotification, accept, docs } = this.props;
    let errors = {}, type = 'warning';
    this.setState({ isDragActive: false });
    let messages = [];
    if (rejectedFiles.length > 0) {
      rejectedFiles.forEach((f, fKey) => {
        if (!accepts(f, accept)) {
          const err = errors.type || { amount: 0, title: 'type' };
          errors = { ...errors, type: { ...err, amount: err.amount + 1, fKey: 'fKey' in err ? err.fKey : fKey } };
        } else {
          const err = errors.size || { amount: 0, title: 'size' };
          errors = { ...errors, size: { ...err, amount: err.amount + 1, fKey: 'fKey' in err ? err.fKey : fKey } };
        }
      });
    }
    if (acceptedFiles.length > 0) {
      let files = acceptedFiles;
      if (acceptedFiles.length + docs.length > FILES_LIMIT) {
        messages.push(`You’ve reached files uploading limit (${FILES_LIMIT} items)`);
        files = acceptedFiles.slice(0, FILES_LIMIT - docs.length);
      }
      const total = files.reduce((p, f) => p + f.size, 0);
      this.setState({ files, total });
    } else {
      type = 'error';
    }
    Object.keys(errors).forEach(key => {
      const err = errors[key];
      if (err.amount === 1) {
        messages.push(`${rejectedFiles[err.fKey].name} has wrong ${err.title}`);
      } else {
        messages.push(`${rejectedFiles.length} files have wrong ${err.title}`);
      }
    });
    if (messages.length > 0) {
      addNotification({ title: messages.join('. '), type });
    }
  }

  cancelSource = null;

  load = () => {
    const { pushValue, token, addNotification } = this.props;
    const { files, types } = this.state;

    const onUploadProgress = file => ({ loaded }) => {
      const { total } = this.state;
      let { loadedBefore } = this.state;
      loadedBefore = { ...loadedBefore, [file.name]: loaded };
      const loadedBites = Object.keys(loadedBefore).reduce((prev, key) => prev + loadedBefore[key], 0);
      this.setState({
        loadedBefore,
        loading: Math.floor(loadedBites * 100 / total),
      });
    };
    const cancel = (CancelToken) => this.cancelSource = this.cancelSource || CancelToken.source();
    const promises = [];

    files.forEach((file, index) => {
      const formData = new FormData();
      formData.append('file', file);
      let promise = new Promise(resolve => {
        try {
          Api.uploadFiles({ data: formData, token, onUploadProgress: onUploadProgress(file), cancel })
            .then(({ data }) => {
              pushValue('docs', { path: data[0], title: file.name, type: types[index] });
              resolve();
            })
            .catch((err) => {
              let message = `Uploading error, please try again or choose another file (${file.name})`;
              let messageType = 'error';
              if (err.message === 'UPLOADING_CANCELED') {
                message = 'Uploading canceled.';
                messageType = 'success';
              }
              addNotification({ title: message, type: messageType });
              resolve();
            });
        } catch (err) {
          resolve();
          console.error(err);
        }
      });
      promises.push(promise);
      Promise.all(promises).finally(() => {
        this.setState({ files: [], types: {}, loading: null, total: null, loadedBefore: {} });
        this.cancelSource = null;
      });
    }); // files.forEach
  }

  onCancel = () => {
    if (this.cancelSource) {
      this.cancelSource.cancel('UPLOADING_CANCELED');
      this.cancelSource = null;
    }
    this.setState({ files: [],types: {}, loading: null, total: null, loadedBefore: {} });
  }

  state = {
    files: [],
    types: {},
    loading: null,
    loadedBefore: {},
    total: null,
    isDragActive: false,
  }

  download = (fileName, title, event) => {
    event.stopPropagation();
    event.preventDefault();
    const { token } = this.props;
    try {
      Api.downloadFile({ fileName, token })
        .then(resp => {
          FileDownload(resp.data, title);
        });
    } catch (err) {
      console.error(err);
    }
  }

  onRemove = (path) => (event) => {
    const { change, docs } = this.props;
    event.stopPropagation();
    event.preventDefault();
    change('docs', docs.filter(v => v.path !== path));
  }

  renderTypeCheck = () => {
    const { files, types, loading } = this.state;
    if (files.length === 0) return null;
    const { classes } = this.props;

    if (loading) {
      return (
        <Popup cancel={this.onCancel}>
          <div className={classes.popup}>
            <PopupTitle
              children="File Type"
              onClose={this.onCancel}
              modifier="filesForm"
            />
            <div className={classes.popupSubHeader}>
              Uploading files:
            </div>
            <div className={classes.popupContent}>
              <div className={classes.loader}>
                <Progress value={loading} />
              </div>
            </div>
          </div>
        </Popup>
      );
    }

    return (
      <Popup cancel={this.onCancel}>
        <div className={classes.popup}>
          <PopupTitle
            children="File Type"
            onClose={this.onCancel}
            modifier="filesForm"
          />
          <div className={classes.popupSubHeader}>
            Please tag file with the following options:
          </div>
          <div className={classes.popupContent}>
            {
              files.map(({ name, path }, index) => (
                <div className={classes.popupFile} key={`${index}-${path}`}>
                  <div className={classes.popupFileName} title={name}>
                    <Hint
                      text={name}
                      modifier={cx('AOForm-file', { first: index === 0 })}
                    >
                      <div className={classes.popupFileNameText}>{rmExt(name)}</div>
                      <div className={classes.popupFileExt}>{onlyExt(name)}</div>
                    </Hint>
                  </div>
                  <div className={classes.popupFileType}>
                    <Select
                      isClearable={false}
                      placeholder="Invoice, appraisal…"
                      list={typesList}
                      input={{
                        value: types[index],
                        onChange: v => this.setState({ types: { ...types, [index]: v } }),
                      }}
                      meta={{}}
                    />
                  </div>
                </div>
              ))
            }
          </div>
          <div className={classes.popupControls}>
            <button
              type="button"
              className={classes.popupBtn}
              onClick={() => this.setState({ files: [], types: {} })}
            >CANCEL</button>
            <button
              disabled={Object.keys(types).length !== files.length}
              type="button"
              className={cx(classes.popupBtn, classes.popupUpload)}
              onClick={this.load}
            >UPLOAD</button>
          </div>
        </div>
      </Popup>
    );
  }

  dropzoneChildren = ({ getRootProps, getInputProps }) => {
    const { classes, docs = [], readOnly = false } = this.props;
    const { isDragActive } = this.state;
    const types = {};
    return (
      <div
        className={cx(classes.DropZone, { isDragActive })}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        {
          !!docs.length &&
            <div className={classes.fileList}>
              {
                docs.map(({ title, path, type }, index) => (
                  <div className={cx(classes.file, { formView: !readOnly })} key={`${path}-${index}`}>
                    <button
                      onClick={(event) => this.download(path, title, event)}
                      title={title}
                      className={classes.fileName}
                    >
                      <Hint
                        text={title}
                        modifier={cx('AOForm-file', { first: index === 0 })}
                      >
                        <div
                          className={classes.fileNameText}
                          children={rmExt(title)}
                        />
                        <div
                          className={classes.fileExt}
                          children={onlyExt(title)}
                        />
                      </Hint>
                    </button>
                    <div className={classes.fileType}>
                      {type}
                      {(types[type] = (types[type] || 0) + 1, types[type] > 1 ? ` ${types[type]}` : null)}
                    </div>
                    {
                      !readOnly &&
                        <button
                          type="button"
                          className={classes.remove}
                          onClick={this.onRemove(path)}
                        />
                    }
                  </div>
                ))
              }
            </div>
        }
        {
          !readOnly && docs.length < FILES_LIMIT &&
            <div className={classes.areaText}>
              <div className="p">
                Drag <span className={classes.bold}>PDF or DOC files</span> or <span className={classes.browse}>browse</span> to upload
              </div>
            </div>
        }
        {
          isDragActive &&
            <div className={classes.dragOverlay}>
              <div className={classes.dragOverlayText}>Drop files here</div>
            </div>
        }
        {
          !readOnly && docs.length >= FILES_LIMIT &&
            <div className={classes.amountLimitMessage}>
              You’ve reached files uploading limit ({FILES_LIMIT} items)
            </div>
        }
      </div>
    );
  }

  render() {
    const { classes, docs = [], accept, readOnly = false } = this.props;
    return (
      <div className={classes.Files}>
        {
          (!readOnly || docs.length > 0) &&
            <div className={classes.header}>FILES</div>
        }
        {
          !readOnly &&
            this.renderTypeCheck()
        }
        <DropZone
          onDrop={readOnly ? doNothing : this.onDrop}
          accept={accept}
          maxSize={10 * MB + 1}
          onDragEnter={() => readOnly ? null : this.setState({ isDragActive: true })}
          onDragLeave={() => this.setState({ isDragActive: false })}
          disabled={docs.length >= FILES_LIMIT || readOnly}
          children={this.dropzoneChildren}
        />
      </div>
    );
  }
}

export const Files = compose(
  injectSheet(sheet),
  connect({
    token,
  }),
)(FilesPure);

export default (formName) => compose(
  injectSheet(sheet),
  connect({
    addNotification: addNotificationAction,
    token,
    docs: docs_sel,
    change: (...args) => changeAction(formName, ...args),
    pushValue: (...args) => arrayPushAction(formName, ...args),
  }),
)(FilesPure);
