import {
  DARK_BLUE_COLOR,
} from 'theme/theme';

export { formItem } from 'theme/form/formItem';
export { innerFormItem } from 'theme/form/innerFormItem';
export { label } from 'theme/form/label';
export { fieldWrapper } from 'theme/form/fieldWrapper';
export { field } from 'theme/form/field';
export { selectWrapper } from 'theme/form/selectWrapper';

export const error = {
  borderColor: '#d0021b',
};

export const rowWrapper = {
  maxWidth: 295,
  width: '100%',
};

export const fieldset = {
  border: '0 none',
  '&.alert-lot-fieldset': {
    marginTop: 31,
  },
};

export const comment = {
  fontSize: 12,
  lineHeight: '32px',
  color: DARK_BLUE_COLOR,
  whiteSpace: 'nowrap',
};

export const buttonContainer = {
  whiteSpace: 'nowrap',
  position: 'absolute',
  left: 'calc(100% + 6px)',
  top: 0,
  '&.HistoryField': {
    '&.add': {
      top: 44,
    },
  },
  '@media screen and (max-width: 1190px)': {
    '&.custom-artist-actions.editable': {
      position: 'static',
      marginTop: 12,
    },
  },
  '@media screen and (max-width: 350px)': {
    position: 'static',
    marginTop: 12,
    textAlign: 'center',
    '&.provenance, &.HistoryField': {
      '&.desktop': {
        display: 'none',
      },
    },
  },
};
