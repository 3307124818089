import { takeEvery, put, fork, select } from 'redux-saga/effects';
import { watchShowSharePopup, sharedArtworkViewed_apiGen } from 'domain/shares/sagas';
import {
  shareEntitiesAction,
  saveShareAction,
  sharedArtworkViewedAction,
  shareViewedAction,
} from 'domain/shares/ShareActions';
import { getCurrentShareSelector } from 'domain/shares/ShareModel';
import { stopSubmit } from 'redux-form';
import { addNotification } from 'domain/env/EnvActions';
import { hidePopupAction } from 'domain/ui';
import Api, { callApi } from 'domain/api';
import translate from 'lib/translate';
import { FORM_SHARING } from 'domain/const';


function* closePopup() {
  yield put(hidePopupAction());
}

export function* sharePopupSubmitted(creator, api, action) {
  const { shareType } = action.payload;
  const payload = action.payload.data;
  const { id } = payload;
  const entityTitle = id ? 'Share' : translate(`shared_entity_${shareType}`, payload.ids.length, (n, t) => `${t}`);
  const verb = id ? 'saved' : 'shared';
  try {
    const { data } = yield callApi(api, {
      data: {
        ...payload,
        type: shareType.toUpperCase(),
      },
    });
    yield put(addNotification({ title: `${entityTitle} ${verb}`, type: 'success' }));
    yield put({
      type: creator.success,
      payload: data,
      shareType,
    });
  } catch (err) {
    yield put(stopSubmit(FORM_SHARING));
    yield put({
      type: creator.failure,
      err,
      shareType,
      origin: action,
    });
    yield put(addNotification({ title: `${entityTitle} not ${verb}`, type: 'error' }));
  }
}

function* onSharedArtworkViewed(action) {
  const share = yield select(getCurrentShareSelector);
  if (share.get('updatedArts').includes(action.payload.artworkId)) {
    const { shareId, artworkId } = action.payload;
    yield fork(sharedArtworkViewed_apiGen.catchError, { shareId, ids: [artworkId] });
  }
}

function* onShareViewed() {
  const share = yield select(getCurrentShareSelector);
  if (share && share.get('deletedArts').size) {
    const payload = {
      shareId: share.get('id'),
      ids: share.get('deletedArts').toJS(),
    };
    yield fork(sharedArtworkViewed_apiGen.catchError, payload);
  }
}

export default function* shareWatcher() {
  yield takeEvery(shareEntitiesAction.type, sharePopupSubmitted, shareEntitiesAction, Api.shareEntities);
  yield takeEvery(shareEntitiesAction.success, closePopup);
  yield takeEvery(saveShareAction.type, sharePopupSubmitted, saveShareAction, Api.updateShare);
  yield takeEvery(saveShareAction.success, closePopup);
  yield fork(watchShowSharePopup);
  yield takeEvery(sharedArtworkViewedAction.type, onSharedArtworkViewed);
  yield takeEvery(shareViewedAction.type, onShareViewed);
}
