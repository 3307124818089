import { TEXT_LINK_COLOR, DEFAULT_FONTFAMILY } from 'theme/theme';

export default {
  '@keyframes fly': {
    from: {
      transform: 'scale(2)',
    },
    to: {
      transform: 'scale(1)',
    },
  },
  Notifications: {
    width: 344,
    bottom: 60,
    left: '50%',
    marginLeft: '-172px',
    padding: [11, 20],
    color: '#fff',
    position: 'fixed',
    borderRadius: 2,
    animation: '$fly 500ms linear',
    zIndex: 1000,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    minWidth: 0,
  },
  message: {
    font: `400 13px/18px ${DEFAULT_FONTFAMILY}`,
    minWidth: 0,
  },
  default: {
    backgroundColor: 'rgba(0, 0, 0, 0.9)',
  },
  success: {
    extend: 'default',
  },
  error: {
    backgroundColor: 'rgba(255, 60, 60, 0.9)',
  },
  warning: {
    extend: 'default',
    // backgroundColor: 'rgba(180, 180, 0, 0.9)',
  },
  controls: {
    cursor: 'pointer',
    fontWeight: 500,
  },
  control: {
    textDecoration: 'none',
    color: TEXT_LINK_COLOR,
    font: `500 13px/18px ${DEFAULT_FONTFAMILY}`,
    cursor: 'pointer',
    marginLeft: 20,
    outline: 'none',
  },
};
