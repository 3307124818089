import addNewGallery from './images/new-gallery.svg';
import { DARK_BLUE_COLOR, DEFAULT_FONTFAMILY, SELECT_GALLERY_POPUP_ITEM_HOVER } from 'theme/theme';

export default {
  SelectGallery: {
    // root
    height: 280,
    width: 440,
    overflowX: 'hidden',
    overflowY: 'auto',
    marginLeft: -40,
    marginRight: -40,
  },
  item: {
    position: 'relative',
    height: 68,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    paddingLeft: 40,
    paddingRight: 40,
    cursor: 'pointer',
    '&:after': {
      display: 'block',
      content: '""',
    },
    '&:hover': {
      backgroundColor: SELECT_GALLERY_POPUP_ITEM_HOVER,
    },
    '&.CreateGallery': {
      alignItems: 'flex-start',
      /* allow to show errors */
      height: 'auto',
      minHeight: 68,
    },
  },
  thumb: {
    height: 40,
    flex: '0 0 66px',
    marginRight: 20,
  },
  thumbNewGallery: {
    extend: 'thumb',
    marginTop: 14,
    backgroundImage: `url("${addNewGallery}")`,
    backgroundColor: 'transparent',
  },
  title: {
    fontFamily: DEFAULT_FONTFAMILY,
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '16px',
    color: DARK_BLUE_COLOR,
    maxHeight: 32,
    overflow: 'hidden',
    whiteSpace: 'normal',
  },
  addNewTitle: {
    extend: 'title',
    textAlign: 'left',
  },
  info: {
    textAlign: 'left',
    whiteSpace: 'nowrap',
  },
  artworksCount: {
    display: 'inline-block',
    font: `400 14px/18px ${DEFAULT_FONTFAMILY}`,
    color: DARK_BLUE_COLOR,
  },
  bull: {
    display: 'inline-block',
    color: DARK_BLUE_COLOR,
    fontSize: '8px',
    lineHeight: '16px',
    margin: [0, 10, 0],
  },
  loader: {
    textAlign: 'center',
    font: `400 24px/32px ${DEFAULT_FONTFAMILY}`,
    letterSpacing: '20px',
    padding: [15, 15, 30],
  },
};
