import record, { integer, string, listOf, bool } from 'cpcs-recordjs';
import { field, selector } from 'cpcs-reconnect';
import I from 'immutable';
import * as A from './GalleryActions';
import { Artwork } from 'model';
import * as env from 'domain/env/EnvActions';
import { currentGalleryId_sel } from 'domain/env/EnvModel';
import { TYPE_GALLERY, removeAction } from 'domain/const';
import RecordListHelpers from 'lib/record-helpers/list';

const Pagination = record('Pagination', {
  pages: integer,
  pageLeft: integer,
  pageRight: integer,
  perPage: integer(7),
  total: integer(0),
});

/**
 * models
**/
export const Gallery = record('Gallery', {
  id: integer(),
  title: string(),
  picture: string(),
  price: integer(),
  artworksCount: integer(),
  artWorks: listOf(Artwork),
  isDefault: bool(false),
  createdAt: string(),
  index: integer,
});

// global.$galleries = listOf(Gallery).parse([{ id: 1, title: 'gal 1' }, { id: 2, title: 'gal 2' }]);

/**
 * State
**/
export const State = record('Galleries', {
  pagination: Pagination(),
  list: listOf(Gallery),
  loading: bool(false),
});

/**
 * selectors
**/
const galleries = field('galleries');
export const galleryList_sel = galleries.then(State.$list);
export const galleryMapIndexedById_sel = galleries.then(State.$list).then(list => list.reduce((prev, gal) =>
  prev.set(gal.get('id'), gal),
  I.Map(),
));
export const galleryPagination_sel = galleries.then(State.$pagination);
export const totalGalleries = galleryPagination_sel.then(Pagination.$total);
export const loading_sel = galleries.then(State.$loading);
// sort indexedSetOf using seq
// export const galleryList = galleryMapIndexedById_sel.then(list =>
//   list
//     .valueSeq()
//     .sort((a, b) => a.id - b.id)
//     .sortBy(g => !g.isDefault)
//     .toList(),
// );
export const getGalleryById = id => galleryList_sel.then(list => list.find(g =>g.id === parseInt(id, 10)));

const addIndexToGalleries = ({ page = 0, size, content = [] }) => content.map((v, index) => ({ ...v, index: index + page * size }));
export const currentGallerySelector = selector(galleryMapIndexedById_sel, currentGalleryId_sel, (map, id) => map.get(id));

/**
 * reducer
**/
export const reducer = {
  galleries(state = new State(), action) { //NOSONAR
    if (action.type === removeAction.success && action.payload.type === TYPE_GALLERY) {
      return state.apply(
        State.$list.updateBy('filter', item => item.id !== action.payload.params.galleryId),
      );
    }

    switch (action.type) {

      case A.clearGaleries.type:
      case env.logOut.type:
        return new State();

      case A.fetchGalleriesAction.request:
      case A.fetchGalleriesPrependAction.request:
        return state.apply(State.$loading.set(true));

      case A.fetchGalleriesAction.failure:
      case A.fetchGalleriesPrependAction.failure:
        return state.apply(State.$loading.set(false));

      case A.fetchGalleriesAction.success:
        return state.apply(
          State.$loading.set(false),
          // State.$list.updateBy('merge', State.$list.type.parse(addIndexToGalleries(action.payload))),
          // State.$list.parsed(addIndexToGalleries(action.payload)),
          // State.$list.update(
          //   RecordListHelpers.$mergeRecordsListById(State.$list.type.parse(addIndexToGalleries(action.payload))),
          // ),
          RecordListHelpers.$mergeRecordsListById(
            State.$list.type.parse(addIndexToGalleries(action.payload)),
          )(State.$list),
          State.$list.update(list => list.sort((a, b) => (a.index < b.index) ? -1 : (a.index - b.index) & 1)),
          State.$pagination.updateBy('apply',
            Pagination.$pageLeft.update(page => page === undefined ? action.payload.page : Math.min(page, action.payload.page)),
            Pagination.$pageRight.update(page => page === undefined ? action.payload.page : Math.max(page, action.payload.page)),
            Pagination.$total.set(action.payload.total),
            Pagination.$pages.set(action.payload.pages),
            Pagination.$perPage.set(action.payload.size),
          ),
        );

      case A.fetchGalleriesPrependAction.success:
        return state.apply(
          State.$loading.set(false),
          RecordListHelpers.$mergeUnshiftingRecordsListById(
            State.$list.type.parse(addIndexToGalleries(action.payload)),
          )(State.$list),
          State.$pagination.updateBy('apply',
            Pagination.$pageLeft.update(page => page === undefined ? action.payload.page : Math.min(page, action.payload.page)),
            Pagination.$pageRight.update(page => page === undefined ? action.payload.page : Math.max(page, action.payload.page)),
            Pagination.$total.set(action.payload.total),
            Pagination.$pages.set(action.payload.pages),
            Pagination.$perPage.set(action.payload.size),
          ),
        );

      case A.setCoverAction.success:
        return state.apply(
          // State.$list.updateById(
          //   +action.payload.id,
          //   Gallery.$picture.set(action.payload.picture),
          // ),
          // State.$list.updateBy('map', gal =>
          //   (gal.get('id') === +action.payload.id) ? Gallery.$picture.set(action.payload.picture)(gal) : gal,
          // ),
          RecordListHelpers.$updateById(+action.payload.id, Gallery.$picture.set(action.payload.picture))(State.$list),
        );

      case A.createGalleryAction.success:
        return state.apply(
          State.$list.update(
            list => list.push(Gallery.parse({ ...action.payload, index: list.size })),
          ),
          State.$pagination.update(
            Pagination.$total.update(v => v + 1),
          ),
        );
      case A.fetchGalleryAction.success:
      case A.addArtworksAction.success:
        return state.apply(
          // State.$list.put(
          //   Gallery.parse(action.payload),
          // ),
          // State.$list.push(Gallery.parse(action.payload)),
          // State.$list.update(
          //   list =>
          //     list.find(gal => gal.get('id') === action.payload.id) ?
          //       list.map(gal => gal.get('id') === action.payload.id ? Gallery.parse(action.payload) : gal) :
          //       list.push(Gallery.parse(action.payload)),
          // ),
          RecordListHelpers.$replaceById(Gallery.parse(action.payload))(State.$list),
          State.$list.update(list => list.map((gal, index) => gal.set('index', index))),
        );

      case A.editGalleryAction.success:
        return state.apply(
          // State.$list.updateById(
          //   action.payload.id,
          //   Gallery.$title.set(action.payload.title),
          // ),
          // State.$list.updateBy('map', gal =>
          //   gal.get('id') === +action.payload.id ? Gallery.$title.set(action.payload.title)(gal) : gal,
          // ),
          RecordListHelpers.$updateById(+action.payload.id, Gallery.$title.set(action.payload.title))(State.$list),
        );

      default:
        return state;
    }
  },
};
