import removeEmail from '../images/remove-email.svg';
import { TEXT_LINK_COLOR, DEFAULT_FONTFAMILY } from 'theme/theme';

export default {
  SharedWith: {
    // root
    display: 'flex',
    flexFlow: 'column nowrap',
    height: '100%',
    justifyContent: 'space-around',
  },
  item: {
    whiteSpace: 'nowrap',
    display: 'flex',
  },
  itemWrapper: {
    display: 'flex',
    flexFlow: 'row nowrap',
    width: '100%',
  },
  buttons: {

  },
  email: {
    lineHeight: '20px',
    display: 'inline-block',
    verticalAlign: 'top',
    width: 250,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '@media screen and (max-width: 1000px)': {
      width: '100%',
    },
  },
  removeBtn: {
    flex: '0 0 24px',
    margin: [0, 10],
    cursor: 'pointer',
    background: {
      color: 'transparent',
      size: '8px 8px',
      repeat: 'no-repeat',
      position: 'center center',
      image: `url("${removeEmail}")`,
    },
    verticalAlign: 'top',
    '@media screen and (max-width: 1000px)': {
      display: 'none',
    },
  },
  toggleMoreButton: {
    cursor: 'pointer',
    color: TEXT_LINK_COLOR,
    fontFamily: DEFAULT_FONTFAMILY,
    textDecoration: 'underline',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '16px',
    marginTop: 5,
  },
};
