import { takeEvery, put, select } from 'redux-saga/effects';
import { change, formValueSelector } from 'redux-form';
import { termsAcceptedSelector, privacyAcceptedSelector } from 'domain/env/EnvModel';
import {
  termsAcceptAction,
  privacyAcceptAction,
} from 'domain/env/EnvActions';
import {
  AUTH_ASK_EMAIL_POPUP,
  AUTH_ACCEPT_PRIVACY_AND_USAGE_TERMS_POPUP,
  FORM_SIGN_UP,
  FORM_AUTH_EMAIL,
  FORM_AUTH_PRIVACY_AND_TERMS,
} from 'domain/const';
import { popupInListSelector } from 'domain/ui/UIModel';

const PRIVACY_CHECKBOX_FIELD = 'privacyTermsAccept';

const fieldValueSelector = (form, field) => state => formValueSelector(form)(state, field);

let checkboxWatcherOFF = false;

function* onPopupStateChange({ payload }) {
  let form = FORM_SIGN_UP;
  if (yield select(popupInListSelector(AUTH_ASK_EMAIL_POPUP))) {
    form = FORM_AUTH_EMAIL;
  } else if (yield select(popupInListSelector(AUTH_ACCEPT_PRIVACY_AND_USAGE_TERMS_POPUP))) {
    form = FORM_AUTH_PRIVACY_AND_TERMS;
  }

  const checked = yield select(fieldValueSelector(form, PRIVACY_CHECKBOX_FIELD));
  if (payload === false && checked) {
    checkboxWatcherOFF = true;
    yield put(change(form, PRIVACY_CHECKBOX_FIELD, false));
    checkboxWatcherOFF = false;
    return;
  }
  const terms = yield select(termsAcceptedSelector);
  const privacy = yield select(privacyAcceptedSelector);
  if (terms && privacy && !checked) {
    checkboxWatcherOFF = true;
    yield put(change(form, PRIVACY_CHECKBOX_FIELD, true));
    checkboxWatcherOFF = false;
  }
}

function* onSwitchCheckbox({ meta: { field, form }, payload }) {
  if (checkboxWatcherOFF) return;
  if (field === PRIVACY_CHECKBOX_FIELD && form === FORM_SIGN_UP) {
    yield put(termsAcceptAction(payload));
    yield put(privacyAcceptAction(payload));
  }
}

export default function* watchAcceptInPopups() {
  yield takeEvery(termsAcceptAction.type, onPopupStateChange);
  yield takeEvery(privacyAcceptAction.type, onPopupStateChange);
  yield takeEvery('@@redux-form/CHANGE', onSwitchCheckbox);
}
