import React from 'react';
import PropTypes from 'prop-types';
import I from 'immutable';
import { connect } from 'cpcs-reconnect';
import { compose } from 'redux';
import { removeEmailsFromShareAction } from 'domain/shares';
import injectSheet from 'lib/sheet';
import styles from './sheet.js';

const SIZE_TO_CUT = 2;

class SharedWith extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    list: PropTypes.instanceOf(I.List).isRequired,
    removeEmails: PropTypes.func.isRequired,
    shareId: PropTypes.number.isRequired,
  };
  state = {
    expanded: false,
  };

  showMore = () => {
    this.setState({ expanded: true });
  };

  showLess = () => {
    this.setState({ expanded: false });
  };

  get list() {
    const { list } = this.props;
    if (this.state.expanded) {
      return list;
    }
    return list.slice(0, SIZE_TO_CUT);
  }

  render() {
    const { classes, list, shareId } = this.props;
    const { expanded } = this.state;
    return (
      <ul
        className={classes.SharedWith}
      >
        {
          this.list.map((item, key) => (
            <li
              key={key}
              className={classes.item}
            >
              <div className={classes.itemWrapper}>
                <span
                  className={classes.email}
                  children={item}
                  title={item}
                />
                <button
                  className={classes.removeBtn}
                  type="button"
                  onClick={() => this.props.removeEmails({ emails: [item], shareId })}
                />
              </div>
            </li>
          ))
        }
        {
          (list.size > SIZE_TO_CUT) &&
            <li className={classes.item}>
              {
                expanded &&
                  <button
                    type="button"
                    children="Show less"
                    onClick={this.showLess}
                    className={classes.toggleMoreButton}
                  />
              }
              {
                (!expanded) &&
                  <button
                    type="button"
                    children={`Show ${list.size - SIZE_TO_CUT} more`}
                    onClick={this.showMore}
                    className={classes.toggleMoreButton}
                  />
              }
            </li>
        }
      </ul>
    );
  }
}

export default compose(
  connect({
    removeEmails: removeEmailsFromShareAction,
  }),
  injectSheet(styles),
)(SharedWith);
