// @flow

import React from 'react';
import PropTypes from 'prop-types';

export type FocusedProps = {
  containerProps: {},
}
/**
 * Support of focused component.
 * Add `props.containerProps` props to wrapped component,
 */
export default function focused(WrappedComponent: ReactClass<*>) {
  return class Focused extends React.PureComponent {

    static WrappedComponent = WrappedComponent

    static propTypes = {
      initialState: PropTypes.bool,
      onHide: PropTypes.func,
      onShow: PropTypes.func,
      tabIndex: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
    }

    static defaultProps = {
      initialState: false,
      tabIndex: 100,
      onHide: () => null,
      onShow: () => null,
    }

    state = {
      show: false,
      index: 0,
    }

    onHide = (...arg: Array<any>) => {
      !this.unmounted && this.setState({ show: false }, () => this.props.onHide(...arg));
    }

    onShow = () => !this.unmounted && this.setState({ show: true }, () => this.props.onShow());

    container: ?HTMLElement

    timeout = null
    focused = false

    componentDidMount() {
      if (!this.focused && this.container && this.props.initialState) {
        this.container.focus();
      }
    }

    componentWillUnmount() {
      this.unmounted = true;
    }

    handleBlur = (...arg: Array<any>) => {
      if (typeof this.timeout !== 'undefined') {
        clearTimeout(this.timeout);
        this.timeout = undefined;
      }
      this.timeout = setTimeout(() => this.onHide(...arg), 200);
      this.focused = false;
    }

    handleFocus = () => {
      if (!this.focused) {
        this.onShow();
      }
      this.focused = true;
      if (typeof this.timeout !== 'undefined') {
        clearTimeout(this.timeout);
        this.timeout = undefined;
      }
      this.timeout = null;
    }

    setContainer = (ref: HTMLElement) => {
      this.container = ref;
    }

    render() {
      const props = {
        key: 'body',
        ...this.props,
        containerProps: {
          ref: this.setContainer,
          tabIndex: this.props.tabIndex,
          onBlur: this.handleBlur,
          onFocus: this.handleFocus,
        },
        container: {
          show: this.state.show,
          onHide: this.onHide,
          onShow: this.onShow,
        },
      };
      return <WrappedComponent {...props} />;
    }
  };
}
