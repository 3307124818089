import React from 'react';
import PropTypes from 'prop-types';
import I from 'immutable';
import { connect } from 'cpcs-reconnect';
import { compose } from 'redux';
import cx from 'classnames';
import { user_sel } from 'domain/env/EnvModel';
import { shareShowEditPopupAction, removeShareAction } from 'domain/shares/ShareActions';
import { confirmAction } from 'domain/ui/UIActions';
import { getCurrentShareSelector, sharedArtworkSelector, artworksPagination_sel } from 'domain/shares/ShareModel';
import { routeName_sel } from 'domain/router/RouterModel';
import translate from 'lib/translate.js';
import { SHARE_TYPE_GALLERY } from 'domain/const';

import injectSheet from 'lib/sheet';
import { sheet } from './sheet.js';

const addQuotes = s => ([s].join('') ? `“${s}”` : '');
const addParentheses = s => ([s].join('') ? `(${s})` : '');

class Header extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.shape({
      Header: PropTypes.string.isRequired,
      titleWrapper: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      sharedBy: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      typeText: PropTypes.string.isRequired,
      entityTitle: PropTypes.string.isRequired,
      titleAmountText: PropTypes.string.isRequired,
      private: PropTypes.string.isRequired,
      buttonsWrapper: PropTypes.string.isRequired,
      button: PropTypes.string.isRequired,
    }).isRequired,
    user: PropTypes.instanceOf(I.Record).isRequired,
    isPrivate: PropTypes.bool,
    showEditPopup: PropTypes.func.isRequired,
    showEditButton: PropTypes.bool,
    item: PropTypes.instanceOf(I.Record),
    share: PropTypes.instanceOf(I.Record).isRequired,
    routeName: PropTypes.string,
    sharedArtWorkPage: PropTypes.bool,
    askBefore: PropTypes.func.isRequired,
    pagination: PropTypes.shape({
      total: PropTypes.number,
    }),
  };


  /**
   * examples:
   *   { typeText: "1 artwork", entityTitle: "“Abstraktes Foto”", amountText: "", noDoom: undefined }
   *   { typeText: "9 artworks", entityTitle: "", amountText: "", noDoom: undefined }
  **/
  renderTitle(typeText, entityTitle, amountText, noDoom) {
    const { classes } = this.props;
    if (noDoom) {
      return `${typeText} ${entityTitle} ${amountText}`;
    }
    return <React.Fragment>
      <span
        children={typeText}
        className={cx(classes.typeText, { noEntityTitle: !entityTitle })}
      />
      {
        !!entityTitle &&
          <span
            className={classes.entityTitle}
            children={` ${entityTitle}`}
          />
      }
      {
        amountText &&
          <span
            className={classes.titleAmountText}
            children={` ${amountText}`}
          />
      }
    </React.Fragment>;
  }
  generateTitle(noDoom) {
    const { share, item, sharedArtWorkPage, routeName, pagination } = this.props;
    if (!share || (sharedArtWorkPage && !item)) return 'loading...';
    if (routeName === 'sharedGallery' || (routeName === 'sharedItemsPage' && share.type === SHARE_TYPE_GALLERY)) {
      const typeText = translate('sharedGalleryPage.title.gallery');
      const entityTitle = addQuotes(share.title);
      let amountText = null;
      if (pagination && pagination.total) {
        amountText = addParentheses(translate('sharedGalleryPage.title.amount', pagination.total));
      }
      return this.renderTitle(typeText, entityTitle, amountText, noDoom);
    }
    const amount = sharedArtWorkPage ? 1 : share.amount; // || share.amount
    const domain = sharedArtWorkPage ? 'sharedArtworkPage.title.' : 'sharedItemsPage.title.';
    const typeText = translate(domain + share.type, amount);
    const entityTitle = addQuotes(sharedArtWorkPage ? item.get('title') : share.title);
    return this.renderTitle(typeText, entityTitle, '', noDoom);
  }

  onEdit = () => {
    const { share, showEditPopup } = this.props;
    showEditPopup({ id: share.id });
  }

  get sharedByMe() {
    const { user, share } = this.props;
    if (!user || !share) return false;
    return user.email === share.get('email') || share.get('email') === 'You';
  }

  onRemove = () => {
    const { askBefore, share } = this.props;
    askBefore('Are you sure?', removeShareAction, { id: share.get('id') });
  }

  render() {
    const { classes, isPrivate, showEditButton, routeName } = this.props;
    return (
      <div
        className={cx(classes.Header, 'sharePages')}
      >
        <div className={classes.titleWrapper}>
          <h1 className={classes.title}>
            {this.generateTitle()}
            {isPrivate && <sup className={classes.private}>private</sup>}
          </h1>
        </div>
        <div className={classes.buttonsWrapper}>
          {
            (showEditButton && routeName === 'sharedItemsPage' && this.sharedByMe) &&
              <button
                className={cx(classes.button, 'SharedItemsPage revokeButton')}
                type="button"
                children="Revoke"
                onClick={this.onRemove}
              />
          }
          {
            (showEditButton && this.sharedByMe) &&
              <button
                className={cx(classes.button, 'SharedItemsPage editButton')}
                type="button"
                children="edit"
                onClick={this.onEdit}
              />
          }
        </div>
        {/*
          (!this.sharedByMe) &&
            <div className={classes.sharedBy}>
              {'by '}
              <span
                className={classes.email}
                children={share.get('email')}
              />
            </div>
        */}
      </div>
    );
  }
}

export { Header as HeaderPure };

export default compose(
  connect({
    user: user_sel,
    showEditPopup: shareShowEditPopupAction,
    item: sharedArtworkSelector,
    share: getCurrentShareSelector,
    routeName: routeName_sel,
    askBefore: confirmAction,
    pagination: artworksPagination_sel,
  }),
  injectSheet(sheet),
)(Header);
