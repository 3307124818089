import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { PureFlatSelect } from 'components/form/select';

import injectSheet from 'lib/sheet';
import sheet from '../sheet';

function SortBy({ list, onChange, alignedLeft, classes, value, modifier }) {
  if (!list) {
    console.error('no sorting options provided', list);
    return null;
  }
  const options = list.map(({ id, title }) => ({ value: id, label: title }));
  const onChangeHandler = ({ value }) => onChange({ target: { value } });
  return (
    <span className={classes.SortBy}>
      <label className={classes.label} htmlFor="sort-by">Sort:</label>
      <PureFlatSelect
        defaultValue={options[0]}
        className={cx(classes.select, modifier)}
        name="sort-by"
        id="sort-by"
        onChange={onChangeHandler}
        value={options.find(v => v.value === value)}
        options={options}
        alignedLeft={alignedLeft}
      />
    </span>
  );
}

SortBy.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
    }),
  ),
  onChange: PropTypes.func,
  classes: PropTypes.shape({
    select: PropTypes.string,
    SortBy: PropTypes.string,
    label: PropTypes.string,
  }).isRequired,
  value: PropTypes.string,
  modifier: PropTypes.string,
  alignedLeft: PropTypes.bool,
};

export default injectSheet(sheet)(SortBy);
