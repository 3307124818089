import { pageLayout } from 'theme/pageLayout';
import { responsiveListFullPage } from 'theme/layout/responsiveList';

export default {
  sectionHeader: pageLayout.sectionHeader,
  sectionHeaderTitle: pageLayout.sectionHeaderTitle,
  sectionHeaderTitleLink: pageLayout.sectionHeaderTitle,
  sectionHeader_authorWorksAllLink: pageLayout.sectionHeaderTitle,
  otherArtistWorks: responsiveListFullPage,
  item: {
    // margin: '0 30px 40px 0',
  },
};
