import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import FormRowField from 'pages/common/newPage/form/elements/FormRowField';
import cx from 'classnames';
import { rules, validator } from 'components/form/validation';
import {
  normalize,
  normalizeDecimal,
  formatEditable,
  formatEditableDecimal,
} from 'components/form/normalizers';

import injectSheet from 'lib/sheet';
import sheet from './sheet';

/**
 * const RANGE_HEIGHT = { from: 'heightFrom', to: 'heightTo' };
 * <Range
 *   range={RANGE_HEIGHT}
 *   label="Height"
 *   modifier="first"
 *   onChange={this.onSubmit}
 * />
**/
class Range extends React.PureComponent {
  static propTypes = {
    range: PropTypes.shape({
      from: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired,
    }).isRequired,
    classes: PropTypes.shape({
      Range: PropTypes.string.isRequired,
      separator: PropTypes.string.isRequired,
      errorMessage: PropTypes.string.isRequired,
      rangeWrapper: PropTypes.string.isRequired,
    }).isRequired,
    onChange: PropTypes.func,
    label: PropTypes.string,
    modifier: PropTypes.string,
    placeholderMin: PropTypes.string,
    placeholderMax: PropTypes.string,
    allowDecimal: PropTypes.bool,
  };

  static defaultProps = {
    placeholderMin: 'min',
    placeholderMax: 'max',
  };

  constructor(props) {
    super(props);
    const { classes } = props;
    this.errorMessagePortal1 = document.createElement('div');
    this.errorMessagePortal1.classList.add(classes.errorMessage);
    this.errorMessagePortal2 = document.createElement('div');
    this.errorMessagePortal2.classList.add(classes.errorMessage);
  }

  onRootRef = (node) => {
    if (!node) return;
    node.appendChild(this.errorMessagePortal1);
    node.appendChild(this.errorMessagePortal2);
  }

  validate = {
    from: rules.int,
    fromDecimal: rules.floatAny,
    to: validator(
      rules.int,
      rules.greaterThanFormValue(this.props.range.from),
    ),
    toDecimal: validator(
      rules.floatAny,
      rules.greaterThanFormValue(this.props.range.from),
    ),
  };

  render() {
    const { classes, range: { from, to }, onChange, label, modifier, placeholderMin, placeholderMax, allowDecimal } = this.props;
    const formatter = allowDecimal ? formatEditableDecimal : formatEditable;
    const normalizer = allowDecimal ? normalizeDecimal : normalize;
    return (
      <div className={cx(classes.Range, modifier)} ref={this.onRootRef}>
        <div className={cx(classes.rangeWrapper, modifier)}>
          <Field
            type="text"
            name={from}
            component={FormRowField}
            noFieldWrapper
            labelLayout={!!label}
            label={label}
            rootTag="div"
            placeholder={placeholderMin}
            modifier={cx(modifier, 'advancedFilter-Range Range-min')}
            normalize={normalizer}
            format={formatter}
            onChange={onChange}
            validate={allowDecimal ? this.validate.fromDecimal : this.validate.from}
            errorMessagePortal={this.errorMessagePortal1}
          />
          <div className={cx(classes.separator, modifier, { fixLabelNeighbor: !!label })}>-</div>
          <Field
            type="text"
            name={to}
            component={FormRowField}
            rootTag="div"
            placeholder={placeholderMax}
            modifier={cx(modifier, 'advancedFilter-Range Range-max', { fixLabelNeighbor: !!label })}
            normalize={normalizer}
            format={formatter}
            onChange={onChange}
            validate={allowDecimal ? this.validate.toDecimal : this.validate.to}
            errorMessagePortal={this.errorMessagePortal2}
          />
        </div>
        {/* div.errorMessage x2 see onRootRef */}
      </div>
    );
  }
}

export default injectSheet(sheet)(Range);
