import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'cpcs-reconnect';
import I from 'immutable';
import InfiniteScroll from 'react-infinite-scroller';

import NoArts from 'pages/common/sharedItemsPage/noArts';
import Loader from 'components/loader/Loader';
import Badge from 'pages/common/ItemList/Badges/Artwork';

import { currentPage } from 'domain/router/RouterModel';
import { updateQueryAction } from 'domain/router/RouterAction';

import injectSheet from 'lib/sheet';
import { sheet } from './sheet';

const emptyList = I.List();


class ItemList extends React.Component {
  static propTypes = {
    list: PropTypes.instanceOf(I.Collection).isRequired,
    selectedIds: PropTypes.shape({
      includes: PropTypes.func.isRequired,
    }),
    onSelect: PropTypes.func,
    onRemove: PropTypes.func,
    /**
     * Badge allows to use draggable and usual artworks
    **/
    Badge: PropTypes.func,
    linkBuilder: PropTypes.func.isRequired,
    createRoute: PropTypes.string,
    createRouteProps: PropTypes.object,
    createText: PropTypes.string,
    noItemsTitle: PropTypes.string,
    noItemsDescription: PropTypes.string,
    classes: PropTypes.object.isRequired,
    currentPage: PropTypes.number.isRequired,
    updateQuery: PropTypes.func.isRequired,
    pagination: PropTypes.shape({
      loading: PropTypes.bool,
      page: PropTypes.number,
      pages: PropTypes.number,
      total: PropTypes.number,
    }),
    loadMore: PropTypes.func.isRequired,
    badgeProps: PropTypes.object,
    modifier: PropTypes.string,
  };

  static defaultProps = {
    Badge,
  };

  componentDidUpdate = () => {
    const { list, currentPage, updateQuery } = this.props;
    if (list.size === 0 && currentPage > 1) {
      updateQuery({ page: currentPage - 1 });
    }
  }

  renderBadge = (data, key) => {
    const { Badge, onSelect, selectedIds = emptyList, linkBuilder, onRemove, badgeProps = {}, modifier } = this.props;
    let props = { data, artwork: data, linkBuilder, canDrag: true, ...badgeProps, modifier };
    const id = parseInt(data.get('id'), 10);
    if (onSelect !== undefined) {
      props = { ...props, selectedIds, selected: selectedIds.includes(id), onSelect: () => onSelect(id) };
    }
    if (onRemove !== undefined) {
      props = { ...props, onRemove: () => onRemove([id]) };
    }
    return (
      <Badge key={`${key}-${id}`} {...props} />
    );
  }

  get loader() {
    const { pagination } = this.props;
    if (!pagination) return null;
    const modifier = pagination.loading && pagination.total > 0 ? 'infiniteScroll_LoadMore' : 'infiniteScroll_FirstPage';
    return <Loader key="loader" loading modifier={modifier} />;
  }

  render() {
    const { list, classes, createRoute, createRouteProps, createText, pagination, loadMore, noItemsTitle, noItemsDescription } = this.props;
    if (!pagination) return null;
    if (!pagination.loading && (!list || !list.size)) {
      return <NoArts
        createRoute={createRoute}
        createRouteProps={createRouteProps}
        createText={createText}
        title={noItemsTitle}
        description={noItemsDescription}
      />;
    }
    return (
      <InfiniteScroll
        className={classes.ItemList}
        loadMore={loadMore}
        hasMore={pagination.page < (pagination.pages - 1) || (pagination.loading && pagination.page === 0)}
        loader={this.loader}
      >
        {list.toList().map(this.renderBadge)}
      </InfiniteScroll>
    );
  }
}

export default compose(
  injectSheet(sheet),
  connect({
    currentPage,
    updateQuery: updateQueryAction,
  }),
)(ItemList);
