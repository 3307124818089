const { images, smallImage, imageBox } = {
  images: {
    display: 'none',
    position: 'absolute',
    right: 30,
    height: '100%',
    width: 140,
    alignItems: 'center',
    transform: 'scale(-1, 1)',
  },
  imageBox: {
    display: 'flex',
    height: 140,
    width: 140,
    flexDirection: 'column',
    flexWrap: 'wrap',
  },
  smallImage: {
    width: 50,
    height: 50,
    margin: [10, 10],
    transform: 'scale(-1, 1)',
  },
};

export { images, smallImage, imageBox };
