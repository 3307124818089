import React from 'react';
import PropTypes from 'prop-types';
import I from 'immutable';
import { compose } from 'redux';
import { connect } from 'cpcs-reconnect';
import cx from 'classnames';
import { rtv } from 'lib/units';
import { sqlDateToRedesign, checkRtvStatus } from 'lib/helpers';
import injectSheet from 'lib/sheet';
import naBtn from 'pages/common/icons/naBtn.svg';
import { RTV_AVAILABLE } from 'domain/const';
import { GET_RTV_BUTTON, RTV_DATE, DEFAULT_FONTFAMILY, DEFAULT_FONTFAMILY_3 } from 'theme/theme';
import { getRtvAction } from 'pages/common/buyRtv/buyRtvActions';
import { categoryById as categoryByIdSel } from 'domain/category/CategoryModel';
import Hint from 'pages/common/selectedItemsControls/hint';
import translate from 'lib/translate.js';

const sheet = {
  GetRtv: {
    backgroundColor: GET_RTV_BUTTON,
    color: '#FFF',
    padding: [7, 38],
    fontWeight: 400,
    fontSize: 12,
    '&:hover': {
      cursor: 'pointer',
      opacity: 0.9,
    },
    '@media screen and (max-width: 380px)': {
      paddingLeft: 10,
      paddingRight: 10,
      width: '100%',
      whiteSpace: 'nowrap',
    },
  },
};

function GetRtvPure({ classes, artwork, getRtv }) {
  const onClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    getRtv({ artwork });
  };
  return (
    <button
      type="button"
      className={classes.GetRtv}
      children="GET TODAY'S VALUE"
      onClick={onClick}
    />
  );
}

GetRtvPure.propTypes = {
  getRtv: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    GetRtv: PropTypes.string.isRequired,
  }).isRequired,
  artwork: PropTypes.shape({
    getRtv: PropTypes.func,
  }).isRequired,
};

const GetRtv = compose(
  injectSheet(sheet),
  connect({
    getRtv: getRtvAction,
  }),
)(GetRtvPure);

const RtvNotAvailableHint = <div>
  <p>There are a number of reasons that we are not able to produce an ARTBnk Value. The most common reasons are:</p>
  <br/>
  <ul>
    <li>1. The artist has not sold enough works at auction</li>
    <li>2. The past auction sales data for this artist have not yet been verified</li>
    <li>3. Only unique 2D works are supported at this time</li>
  </ul>
</div>;

export function rtvText({ artwork, categoryById }) {
  const rtvStatus = checkRtvStatus({ categoryById, artwork });
  if (!artwork.rtvPrice && rtvStatus) {
    return translate(`rtv.${rtvStatus}`);
  }
  const rtvValue = rtv(artwork);
  return rtvValue;
}

function RtvNoTheme({ classes, rtv, purchasedDate, rtvStatus, onListBadge, noPurchase, categoryById, artwork, modifier }) {
  const formattedDate = sqlDateToRedesign(purchasedDate);
  rtvStatus = checkRtvStatus({ categoryById, artwork });
  const rtvValue = rtv || (rtvStatus && translate(`rtv.${rtvStatus}`)) || '';
  return (
    <span
      className={cx(classes.rtvDataWrapper, modifier, !onListBadge && rtvStatus === 'NOT_AVAILABLE' && classes.rtvNaDataWrapper)}
    >
      <span
        className={cx(classes.rtvValue, modifier, { rtvStatus: !rtv, aOView: !onListBadge, available: !rtv && rtvStatus === 'AVAILABLE' })}
        children={rtvValue}
      />
      {
        !onListBadge && rtvStatus === 'NOT_AVAILABLE' &&
          <Hint
            text={RtvNotAvailableHint}
            modifier="notAvailableHint">
            <button
              type="button"
              className={classes.notAvailableHint}
            />
          </Hint>
      }

      {
        !noPurchase && rtv &&
          <span
            className={cx(classes.purchasedDate, modifier)}
            children={`Valued on ${formattedDate}`}
          />
      }
    </span>
  );
}
RtvNoTheme.propTypes = {
  classes: PropTypes.shape({
    rtvDataWrapper: PropTypes.string,
    rtvValue: PropTypes.string,
    purchasedDate: PropTypes.string,
    notAvailableHint: PropTypes.string,
    rtvNaDataWrapper: PropTypes.string,
  }).isRequired,
  rtv: PropTypes.node,
  purchasedDate: PropTypes.string,
  rtvStatus: PropTypes.string,
  onListBadge: PropTypes.bool,
  noPurchase: PropTypes.bool,
  categoryById: PropTypes.instanceOf(I.Collection),
  artwork: PropTypes.instanceOf(I.Record),
  modifier: PropTypes.string,
};

const rtvSheet = {
  rtvDataWrapper: {
    paddingTop: 8,
    display: 'inline-flex',
    flexDirection: 'column',
    '&.ItemList': {
      paddingTop: 0,
    },
  },
  rtvNaDataWrapper: {
    flexDirection: 'unset',
  },
  rtvValue: {
    '&.rtvStatus': {
      textTransform: 'capitalize',
    },
    '&.aOView': {
      color: '#304C95',
      font: `500 30px/34px ${DEFAULT_FONTFAMILY_3}`,
    },
    '&.available': {
      textDecoration: 'underline',
    },
    '&.ItemList': {
      fontWeight: 500,
    },
  },
  notAvailableHint: {
    width: 24,
    height: 24,
    margin: [1, 0, 0, 5],
    cursor: 'pointer',
    background: `url("${naBtn}") left top no-repeat`,
  },
  purchasedDate: {
    color: RTV_DATE,
    font: `400 13px/20px ${DEFAULT_FONTFAMILY}`,
    fontStyle: 'italic',
    textTransform: 'none',
    '&.ItemList': {
      display: 'none',
    },
  },
};
const RtvComponent = compose(
  injectSheet(rtvSheet),
  connect({
    categoryById: categoryByIdSel,
  }),
)(RtvNoTheme);

export default function rtvSolver(fieldValue, data, fieldName, { modifier, hideRtvButton, onListBadge, noPurchase }) {
  if (!data.rtvPrice && data.rtvStatus === RTV_AVAILABLE && !hideRtvButton) {
    return {
      val: <GetRtv artwork={data} />,
      modifier: 'getRtvBtn',
      custom: true,
    };
  }
  return <RtvComponent
    rtv={rtv(data)}
    purchasedDate={data.purchasedDate}
    rtvStatus={data.rtvStatus}
    onListBadge={onListBadge}
    noPurchase={noPurchase}
    categoryId={data.category}
    artwork={data}
    modifier={modifier}
  />;
}
