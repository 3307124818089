import { button } from 'theme/button';
import Range from 'pages/common/advancedFilter/form/range/sheet';
import { LAYOUT_768_MAX_WIDTH } from 'theme/layout/responsiveList';
import * as form from 'theme/form';

export default {
  AdvancedFilterForm: {
    // paddingBottom: 60,
    width: 248,
    [`@media screen and (min-width: ${LAYOUT_768_MAX_WIDTH + 1}px)`]: { // 833 + 1
      height: '100%',
      overflowY: 'auto',
    },
  },
  showMore: {
    marginTop: 15,
    textDecoration: 'underline',
  },
  btnGroup: {
    padding: [8, 2, 2],
    textAlign: 'right',
  },
  button,
  showResultsBox: {
    position: 'sticky',
    bottom: 20,
    textAlign: 'center',
  },
  ...Range,
  formItem: form.formItem,
  fieldWrapper: form.fieldWrapper,
  rowWrapper: form.rowWrapper,
  field: form.field,
  error: form.error,
};
