import I from 'immutable';
import { parseId } from 'lib/helpers';

export const residenceToStr = (artwork, countries) => artwork
    .getIn(['artist', 'residence'], new I.List())
    .map(id => parseId(id))
    .reduce((A, id) => {
      const V = countries.getIn([id, 'title'], '');
      return V.length ? A.concat(V) : A;
    }, [])
    .join(', ');

const countriesListSolver = (_, data, name, props) => residenceToStr(data, props.countries);

export default countriesListSolver;
