import { ARTWORK } from 'components/dnd/types';

export const ArtworkSource = {
  canDrag: (props) => props.canDrag,
  isDragging: (props, monitor) => {
    const { selected, data } = props;
    const dragId = monitor.getItem().id;
    return (dragId && selected) || data.get('id') === dragId;
  },
  beginDrag: (props) => {
    const item = { id: props.data.get('id'), type: ARTWORK };
    if (!props.selected) props.onSelect();
    return item;
  },
};
