import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import I from 'immutable';
import { connect } from 'cpcs-reconnect';
import { compose } from 'redux';
import { debounce } from 'lib/envGlobals';
import cx from 'classnames';
import injectSheet from 'lib/sheet';
import {
  galleryList_sel,
  loading_sel,
  galleryPagination_sel,
} from 'domain/galleries/GalleryModel';
import { loadGalleriesBidirectionalAction } from 'domain/galleries/GalleryActions';
import focused from 'components/generic/focused';
import { passWindowDecorator } from 'lib/envGlobals';
import sheet, { ITEM_HIROZONTAL_SIZE, SLIDER_ITEM_MARGIN_LEFT, MENU_LEFT } from './sheet';
import ART_GROUP from './__mocks__/gallery-empty';
import ArtGallery from '../artGallery';
import { SLIDER_ITEM_WIDTH } from 'pages/collectionListPage/artGallerySlider/sheet';
import {
  getScollParams,
  getPagePositionWithSlide,
  itemIsVisible,
} from './slider-utils';
import { searchWithoutPage_sel } from 'domain/router/RouterModel';
import { galleryIndexById } from './helpers';
import ArtGalleryContextMenu from '../artGalleryContextMenu';
import { ARTIST, ARTWORK, GalleryTarget, droppable } from 'components/dnd';
import { currentGalleryId_sel } from 'domain/env/EnvModel';

const Menu = focused(ArtGalleryContextMenu);
const DELAY = 200;

const galleryIdChanged = (idA, idB) => (isNaN(idA) && isNaN(idB)) ? false : idA !== idB;

const matchShape = PropTypes.shape({
  params: PropTypes.shape({
    gId: PropTypes.string,
  }).isRequired,
  url: PropTypes.string.isRequired,
}).isRequired;

const DroppableGallery = droppable([ARTIST, ARTWORK], GalleryTarget)(ArtGallery);

class ArtGallerySlider extends PureComponent {

  static propTypes = {
    match: (props, propName, componentName) => {
      if (props.useMenu && (!props.match || !props.match.params || !props.match.url)) {
        // show error message
        PropTypes.checkPropTypes({ match: matchShape }, props, 'prop', componentName);
        // stop component mounting
        return new Error('Invalid propType of `match`');
      }
      return null;
    },
    classes: PropTypes.shape({
      ArtGallerySlider: PropTypes.string.isRequired,
      wrapper1: PropTypes.string.isRequired,
      wrapper2: PropTypes.string.isRequired,
      list: PropTypes.string.isRequired,
      item: PropTypes.string.isRequired,
      empty: PropTypes.string.isRequired,
      shadowLeft: PropTypes.string.isRequired,
      shadowRight: PropTypes.string.isRequired,
      nav: PropTypes.string.isRequired,
      navRight: PropTypes.string.isRequired,
      navLeft: PropTypes.string.isRequired,
      menuPosition: PropTypes.string.isRequired,
    }).isRequired,
    addArtworks: PropTypes.func,
    list: PropTypes.instanceOf(I.List).isRequired,
    loadGalleries: PropTypes.func,
    loading: PropTypes.bool,
    currentGalleryId: PropTypes.number,
    searchWithoutPage: PropTypes.string,
    useMenu: PropTypes.bool,
    window: PropTypes.shape({
      addEventListener: PropTypes.func.isRequired,
      removeEventListener: PropTypes.func.isRequired,
    }).isRequired,
    buildGalleryLink: PropTypes.func,
    pagination: PropTypes.shape({
      pageLeft: PropTypes.number,
      pageRight: PropTypes.number,
      pages: PropTypes.number,
      perPage: PropTypes.number,
      total: PropTypes.number,
    }),
  };

  static defaultProps = {
    list: ART_GROUP,
    addArtworks: () => null,
    loadGalleries: () => null,
    loading: false,
  };

  slider = null;

  state = {
    position: 0,
    allowedToLeft: false,
    allowedToRight: true,
    menuShown: false,
    menuShownForIndex: null,
    loading: false,
  };

  safeSetState(state, cb) {
    if (this.unmounted) return;
    this.setState(state, cb);
  }

  componentDidUpdate(prevProps, prevState) {
    const { list, currentGalleryId, pagination: { pageLeft, perPage } } = this.props;
    const { position } = this.state;
    const { found: wasLoaded } = galleryIndexById(prevProps.list, currentGalleryId);
    const leftPageDiff = prevProps.pagination.pageLeft - pageLeft;
    if (list.size !== prevProps.list.size) this.debouncedOnResize();
    if (this.state.menuShownForIndex !== prevState.menuShownForIndex) return;
    // item added left
    // if (leftPageDiff) {
    //   console.log(`leftPageDiff ${leftPageDiff} x ${perPage} items`);
    // }
    if (leftPageDiff > 0) {
      // console.log(`left added ${perPage} items`);
      if (wasLoaded) {
        // console.log(`update position by (${perPage}) items: ${position} -> ${position + (SLIDER_ITEM_WIDTH + SLIDER_ITEM_MARGIN_LEFT) * perPage} (+${(SLIDER_ITEM_WIDTH + SLIDER_ITEM_MARGIN_LEFT) * perPage})`);
        if (this.enableAnimationTimeout) {
          // console.log('clear enableAnimationTimeout');
          clearTimeout(this.enableAnimationTimeout);
        }
        this.scroll({
            noAnimation: true,
            position: ((SLIDER_ITEM_WIDTH + SLIDER_ITEM_MARGIN_LEFT) * perPage) + position,
          },
          () => {
            // console.log('set enableAnimationTimeout');
            this.enableAnimationTimeout = this.enableAnimation();
          },
        );
      } else {
        // this.scrollToGalleryId(list, currentGalleryId, 'componentDidUpdate; leftPageDiff > 0 && !wasLoaded');
      }
    } else
    // selected gallery
    if (galleryIdChanged(currentGalleryId, prevProps.currentGalleryId)) {
      if (currentGalleryId) {
        this.scrollToGalleryId(list, currentGalleryId, 'componentDidUpdate; galleryIdChanged && currentGalleryId');
      } else {
        const { allowedToLeft, allowedToRight } = this.getScollParams({ position: 0 });
        this.scroll({ allowedToLeft, allowedToRight, position: 0 });
      }
    } else
    if (this.needScrollingAbilityCheck) {
      this.needScrollingAbilityCheck = false;
      const { allowedToLeft, allowedToRight } = this.getScollParams();
      this.safeSetState({ allowedToLeft, allowedToRight });
    } else {
      // current item invisible
      const { index: itemIndex, found } = galleryIndexById(list, currentGalleryId);
      if (
        // it was not a position change
        position === prevState.position &&
        // it was not menu state change
        !this.menuChanged(prevState) &&
        // !pagination
        (!wasLoaded && list.size !== prevProps.list.size) &&
        // item found but invisible
        found && !itemIsVisible({ node: this.slider, itemIndex, list, position })
      ) {
        this.needScrollingAbilityCheck = true;
        this.scrollToGalleryId(list, currentGalleryId, 'componentDidUpdate; !galleryIdChanged && !positionChanged && !menuChanged && !pagination && found && !itemIsVisible');
      }
    }
  }

  componentDidMount() {
    const { list, currentGalleryId, window } = this.props;
    if (!currentGalleryId) {
      this.scroll({ position: 0 });
    } else {
      this.scrollToGalleryId(list, currentGalleryId, 'componentDidMount; currentGalleryId');
    }
    this.debouncedOnResize();
    window.addEventListener('resize', this.debouncedOnResize);
  }

  componentWillUnmount() {
    this.props.window.removeEventListener('resize', this.debouncedOnResize);
    this.unmounted = true;
  }

  onResize = () => {
    const { allowedToRight, allowedToLeft } = this.getScollParams();
    this.safeSetState({ allowedToRight, allowedToLeft });
  };

  debouncedOnResize = debounce(this.onResize, DELAY);
  enableAnimation = debounce(() => {
    // console.log('animation enabled');
    this.enableAnimationTimeout = null;
    this.safeSetState({ noAnimation: false });
  }, 100);

  getScollParams({ direction = 'current', position: askedPosition } = {}) {
    const { pagination: { pageLeft, pageRight, pages } } = this.props;
    const { position } = this.state;
    const countForPosition = askedPosition === undefined ? position : askedPosition;
    const params = getScollParams(this.slider, countForPosition, direction);
    return {
      ...params,
      allowedToLeft: params.allowedToLeft || pageLeft > 0,
      allowedToRight: params.allowedToRight || (pageRight + 1) < pages,
    };
  }

  loadGalleries = debounce((direction) => this.props.loadGalleries(direction), 500);

  disableLoading = debounce(() => this.safeSetState({ loading: false }), 600);

  setLoading = () => {
    const { pagination: { pageLeft, pageRight, pages } } = this.props;
    if (pageLeft === 0 && (pageRight + 1) === pages) return;
    const { loading } = this.state;
    if (!loading && !this.props.loading) {
      this.safeSetState({ loading: true });
    }
    this.disableLoading();
  }

  next = () => {
    this.setLoading();
    this.loadGalleries(1);
    const { position } = this.state;
    const { right, allowedToLeft, allowedToRight } = this.getScollParams({ direction: 'right' });
    this.scroll({ position: position === 0 ? right + 100 : right, allowedToLeft, allowedToRight, menuShown: false });
  };

  prev = () => {
    this.setLoading();
    this.loadGalleries(-1);
    let { left, allowedToLeft, allowedToRight } = this.getScollParams({ direction: 'left' });
    if (left < 300) {
      left = 0;
      allowedToLeft = false;
    }
    this.scroll({ position: left > 300 ? left : 0, allowedToLeft, allowedToRight, menuShown: false });
  };

  toggleMenu = (itemIndex) => {
    if (!this.props.useMenu) return;
    const { menuShown, menuShownForIndex } = this.state;
    const hideMenu = menuShown && menuShownForIndex === itemIndex;
    if (hideMenu) {
      this.safeSetState({ menuShown: false });
      return;
    }
    this.safeSetState({
      menuShown: true,
      menuShownForIndex: itemIndex,
    });
  };

  hideMenu = () => {
    this.safeSetState({ menuShown: false });
  };

  menuChanged(prevState) {
    const { state } = this;
    return (prevState.menuShown !== state.menuShown) ||
      (prevState.menuShownForIndex !== state.menuShownForIndex);
  }

  scrollToGalleryId = (list, currentGalleryId/*, caller*/) => {
    // console.log('scrollToGalleryId', caller);
    const { index } = galleryIndexById(list, currentGalleryId);
    const position = getPagePositionWithSlide(this.slider, index);
    const { allowedToLeft, allowedToRight } = this.getScollParams({ position });
    this.scroll({ position, allowedToLeft, allowedToRight });
  };

  scroll = (state, cb) => {
    // console.log(`scroll from ${this.state.position} to ${state.position}`);
    this.safeSetState(state, cb);
  }

  render() {
    const { classes, list, useMenu, searchWithoutPage, addArtworks, currentGalleryId } = this.props;
    const { position, allowedToLeft, allowedToRight, menuShown, menuShownForIndex, noAnimation } = this.state;
    const loading = this.props.loading || this.state.loading;
    // const loading = false;
    const listStyle = {
      // transform: `translate3d(${ position === 0 ? 0 : (- position + 100)}px, 0, 0)`,
      transform: `translate3d(${ position === 0 ? 0 : (- position)}px, 0, 0)`,
    };
    const menuPositionStyle = {
      left: `${menuShownForIndex * ITEM_HIROZONTAL_SIZE + MENU_LEFT - position}px`,
    };
    return (
      <div className={classes.ArtGallerySlider}>
        <div className={classes.wrapper1}>
          <div className={classes.wrapper2}>
            <ul
              className={cx(classes.list, { noAnimation })}
              ref={el => this.slider = el}
              style={listStyle}
            >
              {
                (list && list.size > 0) ?
                  list.map((gal, k) =>
                    <li
                      key={k}
                      className={classes.item}
                      data-title={gal.get('title')}
                    >
                      <DroppableGallery
                        item={gal}
                        id={gal.get('id')}
                        active={gal.get('id') === currentGalleryId}
                        toggleMenu={() => this.toggleMenu(k)}
                        postfix={searchWithoutPage}
                        useMenu={useMenu}
                        onDrop={() => addArtworks(gal.get('id'))}
                        buildGalleryLink={this.props.buildGalleryLink}
                      />
                    </li>,
                  )
                  :
                  <li
                    className={cx(classes.item, classes.empty)}
                  >
                    <ArtGallery active />
                  </li>
              }
            </ul>
          </div>
          {
            allowedToLeft && position > 0 &&
              <div className={classes.shadowLeft} />
          }
          {
            allowedToRight &&
              <div className={classes.shadowRight} />
          }
          { /* nav buttons */
            (allowedToLeft || allowedToRight || position > 0) &&
              <nav className={classes.nav}>
                <button
                  type="button"
                  className={classes.navRight}
                  onClick={this.next}
                  disabled={loading || !allowedToRight}
                />
                <button
                  type="button"
                  className={classes.navLeft}
                  onClick={this.prev}
                  disabled={loading || (!allowedToLeft && position === 0)}
                />
              </nav>
          }
          { /* menu */
            (useMenu && menuShown) ? (
              <div
                className={classes.menuPosition}
                style={menuPositionStyle}
              >
                <Menu
                  initialState
                  gallery={list.get(menuShownForIndex)}
                  onHide={this.hideMenu}
                  pathname={this.props.match.url}
                />
              </div>
            ) : null
          }
        </div>
      </div>
    );
  }
}

ArtGallerySlider = compose(
  injectSheet(sheet),
  passWindowDecorator,
)(ArtGallerySlider);

export { ArtGallerySlider as PureArtGallerySlider };

export default compose(
  connect({
    list: galleryList_sel,
    loadGalleries: loadGalleriesBidirectionalAction,
    loading: loading_sel,
    searchWithoutPage: searchWithoutPage_sel,
    currentGalleryId: currentGalleryId_sel,
    pagination: galleryPagination_sel,
  }),
)(ArtGallerySlider);
