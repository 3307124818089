// import { CONTENT_TYPE_CUSTOM } from 'pages/highcharts/helpers/PDFConstants';

let counter = 0;

const addChart = (id, contentSection) => [
  {
    title: `Chart ${id}`,
    component: 'ChartPDF',
    name: id,
    order: counter++,
    removable: true,
    contentSection,
    useAsBg: true,
    customMarginBottom: 20,
  },
  {
    title: `${id.toUpperCase()}_DEFINITION`,
    component: 'Definition',
    name: `${id.toUpperCase()}_DEFINITION`,
    parent: id,
    order: counter++,
    removable: false,
    contentSection,
    customMarginTop: 30,
  },
  {
    title: `${id.toUpperCase()}_INTERPRETATION`,
    component: 'Definition',
    name: `${id.toUpperCase()}_INTERPRETATION`,
    parent: id,
    order: counter++,
    removable: false,
    contentSection,
  },
];

const items = [
  {
    title: 'Cover Page',
    name: 'CoverPageContent',
    order: counter++,
    removable: false,
    contentSection: 'coverPage',
  },
  // {
  //   title: 'Introduction Page',
  //   name: 'introductionPage',
  //   contentSection: 'intro',
  //   order: counter++,
  //   removable: true,
  // },
  {
    title: 'Introduction',
    name: 'intro',
    component: 'SectionHeader',
    order: counter++,
    removable: false,
    contentSection: 'intro',
  },
  {
    title: 'Table of Contents',
    name: 'tableOfContents',
    order: counter++,
    removable: false,
    contentSection: 'intro',
  },
  // {
  //   title: 'AOImage',
  //   name: 'AOImage',
  //   contentType: CONTENT_TYPE_CUSTOM,
  //   order: counter++,
  //   removable: true,
  //   contentSection: 'AODetails',
  // },
  // AFPM
  {
    title: 'Financial Performance',
    name: 'financialPerformance',
    component: 'SectionHeader',
    order: counter++,
    removable: true,
    contentSection: 'financialPerformance',
  },
  {
    title: 'Artist Financial Performance Metrics',
    component: 'AFPM',
    name: 'AFPM',
    order: counter++,
    removable: true,
    contentSection: 'financialPerformance',
    useAsBg: true,
  },
  {
    title: 'T001_LIQUIDITY_LEVEL',
    component: 'Definition',
    name: 'T001_LIQUIDITY_LEVEL',
    parent: 'AFPM',
    order: counter++,
    removable: false,
    contentSection: 'financialPerformance',
    customMarginTop: 56 - 35,
    customMarginLeft: 429 - 29,
    customWidth: 134 + 29 + 30,
  },
  {
    title: 'T001_ARTIST_MEDIAN_COMPOUND_GROWTH_RATE',
    component: 'Definition',
    name: 'T001_ARTIST_MEDIAN_COMPOUND_GROWTH_RATE',
    parent: 'AFPM',
    order: counter++,
    removable: false,
    contentSection: 'financialPerformance',
    customMarginLeft: 429 - 29,
    customWidth: 134 + 29 + 30,
  },
  {
    title: 'T001_REPEAT_SALES_RANKING',
    component: 'Definition',
    name: 'T001_REPEAT_SALES_RANKING',
    parent: 'AFPM',
    order: counter++,
    removable: false,
    contentSection: 'financialPerformance',
    customMarginLeft: 429 - 29,
    customWidth: 134 + 29 + 30,
  },
  {
    title: 'T001_ARTIST_SELL_THROUGH_RATE',
    component: 'Definition',
    name: 'T001_ARTIST_SELL_THROUGH_RATE',
    parent: 'AFPM',
    order: counter++,
    removable: false,
    contentSection: 'financialPerformance',
    customMarginLeft: 429 - 29,
    customWidth: 134 + 29 + 30,
  },
  {
    title: 'T001_ARTIST_VOLATILITY_RISK',
    component: 'Definition',
    name: 'T001_ARTIST_VOLATILITY_RISK',
    parent: 'AFPM',
    order: counter++,
    removable: false,
    contentSection: 'financialPerformance',
    customMarginLeft: 429 - 29,
    customWidth: 134 + 29 + 30,
  },
  {
    title: 'T001_ARTIST_RETURN_RANKING',
    component: 'Definition',
    name: 'T001_ARTIST_RETURN_RANKING',
    parent: 'AFPM',
    order: counter++,
    removable: false,
    contentSection: 'financialPerformance',
    customMarginLeft: 429 - 29,
    customWidth: 134 + 29 + 30,
  },
  // charts
  ...addChart('p001', 'financialPerformance'),
  ...addChart('p002', 'financialPerformance'),
  ...addChart('p015', 'financialPerformance'),
  ...addChart('p016', 'financialPerformance'),
  ...addChart('p003', 'financialPerformance'),
  ...addChart('p005', 'financialPerformance'),
  ...addChart('p006', 'financialPerformance'),
  ...addChart('p007', 'financialPerformance'),
  ...addChart('p010', 'financialPerformance'),
  ...addChart('p012', 'financialPerformance'),

  // Market Movements
  {
    title: 'Market Movements',
    name: 'marketMovement',
    component: 'SectionHeader',
    order: counter++,
    removable: true,
    contentSection: 'marketMovement',
  },
  // charts
  ...addChart('m001', 'marketMovement'),
  ...addChart('m003', 'marketMovement'),
  ...addChart('m010', 'marketMovement'),
  ...addChart('m012', 'marketMovement'),
  ...addChart('m018', 'marketMovement'),
  // Rankings
  {
    title: 'Artist Rankings',
    component: 'ArtistRankings',
    name: 'ArtistRankings',
    order: counter++,
    removable: true,
    contentSection: 'artistRankings',
  },
  // ARTBnk Indexes
  {
    title: 'ARTBnk Indexes',
    name: 'ARTBnkIndexes',
    component: 'SectionHeader',
    order: counter++,
    removable: true,
    contentSection: 'ARTBnkIndexes',
  },
  // charts
  ...addChart('x001', 'ARTBnkIndexes'),
  ...addChart('x003', 'ARTBnkIndexes'),
  ...addChart('x002', 'ARTBnkIndexes'),
  // Contacts
  {
    title: 'Contacts',
    component: 'ContactsPDF',
    name: 'ContactsPDF',
    order: counter++,
    removable: false,
    contentSection: 'contacts',
  },
  {
    title: 'Disclaimer',
    component: 'DisclaimerPDF',
    name: 'DisclaimerPDF',
    order: counter++,
    removable: false,
    contentSection: 'contacts',
  },
  {
    title: 'LastCoverPDF',
    component: 'LastCoverPDF',
    name: 'LastCoverPDF',
    order: counter++,
    removable: false,
    contentSection: 'contacts',
  },
];
export { items };
