import axiosOrigin from 'axios';
import { select as createSelector } from 'cpcs-reconnect';
import { call, put, select } from 'redux-saga/effects';
import { paramsSerial } from 'lib/serialize';
import { parseServerError } from 'lib/helpers';
import { logoutGen } from 'lib/helpers/logoutGen';
import { push as pushAction } from 'connected-react-router';

let axios = axiosOrigin;

if (process.env.NODE_ENV === 'test') {
  axios = require('lib/testUtils').axiosMock;
} else {
    axios.interceptors.request.use(request => {
    if (request.params && request.params.page === -1) request.params.page = 0;
    return request;
  });
}

/**
 * this selector was created without importing 'domain/env' module
 * to avoid cyclic importing
**/
const tokenSelector = createSelector(state => state.env.getIn(['user', 'token']));

/**
 * adds token to requestData
 * calls api fn with requestData arg, but adds token to it
 * if server returns 404 error status, dispatched action to show 404 error page splash screen
 *
 * 403 messages:
 *   The token was expected to have 3 parts, but got 0.
 *   Access is denied
 *   The string '{"sub":"...+exp001@gmail.com","…at":1612357636' doesn't have a valid JSON format.
 * 401 messages:
 *   token expired
 *     No message available
 * switch (true) {
 *   case status === 401 && message && message.search(/doesn't have a valid JSON format.$/) !== -1:
 *   case status === 401 && message && message.search(/^The string.*JWT/) !== -1:
 *   case status === 401 && message && message.search('The Token\'s Signature resulted invalid') !== -1:
 *   case status === 401 && message && message.search('The token was expected to have') !== -1:
 *   'Contact Support'
 * }
**/
export function* callApi(api, requestData = {}) {
  const token = yield select(tokenSelector);
  try {
    return yield call(api, { ...requestData, token });
  } catch (e) {
    // const { response: { data: { status } = {} } = {} } = e;
    const { status, message } = parseServerError(e);
    if (process.env.NODE_ENV !== 'test') {
      console.warn({ status, message });
    }
    if (status === 403 && message === 'Access is denied') {
      // yield put(addNotification({ title: message, type: 'error' }));
      yield put({ type: 'USER/SUBSCRIPTION/EXPIRED' });
      yield put(pushAction('/account#billing'));
    } else if ((status === 401 || status === 403)) {
      yield call(logoutGen, { status, message });
    } else if (status === 404) {
      /**
       * this action type was used as string literal without importing model actions
       * to avoid cyclic importing
      **/
      yield put({ type: 'ui/ERROR/404', payload: true });
    }
    throw e;
  }
}

// eslint-disable-next-line no-unused-vars
const getSuccessDataDefault = ({ data, headers, requestData, more }) => ({ payload: data });
// eslint-disable-next-line no-unused-vars
const defaultReturnValue = ({ data, headers, requestData, more, successData }) => (successData || {}).payload;

/**
 * most common usage:
 * 
 * 1. create api generator (apiGen)
 * 
 * export const getNews_apiGen = makeApiGenerator({
 *   actionCreator: fetchNewsAction,
 *   api:Api.apiEndpoint,
 * });
 * 
 * will do same as:
 * 
 * export function getNews_apiGen({ term, size, page } }) {
 *   try {
 *     const { data } = yield callApi(Api.apiEndpoint, { term, size, page });
 *     yield put({
 *       type: fetchNewsAction.success,
 *       payload: data,
 *     });
 *   } catch (err) {
 *     yield put({
 *       type: fetchNewsAction.failure,
 *       err,
 *     });
 *   }
 * }
 * 
 * then call it with .catchError:
 * 
 * const data = yield call(getNews_apiGen.catchError, params);
 * 
 * or
 * 
 * try {
 *   const data = yield call(getNews_apiGen, params);
 * } catch (err) {
 *   // fetchNewsAction.failure already dispatched, so
 *   yield put(addNotification({ title: 'Something went wrong', type: 'error' }));
 * }
 * 
 * by default returns data from
 * const { data } = yield callApi(apiFn, requestData);
 * return data;
**/
export const makeApiGenerator = ({
  actionCreator,
  api: apiFn,
  getSuccessData = getSuccessDataDefault,
  onSuccess,
  returnValue = defaultReturnValue,
  ensure = false,
  muteSuccess = false,
  ensureSelector,
  patchRequestData,
}) => {
  const generatorFactory = ({ catchError } = {}) => function* ({ silent = false, ...requestDataIn } = {}, more) {
    let requestData = requestDataIn;
    if (patchRequestData) {
      requestData = yield call(patchRequestData, { requestData });
    }
    try {
      if (ensure || ensureSelector) {
        const data = yield call(ensureSelector || returnValue, { requestData });
        if (data) {
          return data;
        }
      }
      if (!silent) {
        yield put({
          type: actionCreator.request,
          payload: requestData,
        });
      }
      const { data, headers } = yield callApi(apiFn, requestData);
      const successData = yield call(getSuccessData, {
        data,
        headers,
        requestData,
        more,
      });
      if (onSuccess) {
        yield call(onSuccess, { data, successData, headers, requestData, more });
      }
      if (!silent && !muteSuccess) {
        yield put({
          type: actionCreator.success,
          ...successData,
        });
      }
      return yield call(returnValue, { data, headers, requestData, more, successData });
    } catch (err) {
      if (!silent) {
        yield put({
          type: actionCreator.failure,
          err,
        });
      }
      if (catchError) {
        if (process.env.NODE_ENV === 'test') return;
        console.error(err);
      } else {
        throw err;
      }
    }
  };
  const apiGenerator = generatorFactory();
  apiGenerator.catchError = generatorFactory({ catchError: true });
  return apiGenerator;
};

export default {
  downloadFile({ fileName, token }) {
    return axios({
      method: 'get',
      url: '/uploads/get-doc',
      params: { fileName },
      headers: { 'X-Token': token },
      responseType: 'blob',
    });
  },
  uploadFiles({ data, token, onUploadProgress, cancel }) {
    return axios({
      onUploadProgress,
      method: 'post',
      url: '/uploads',
      headers: { 'X-Token': token },
      data,
      cancelToken: cancel ? (() => {
        // const source = axios.CancelToken.source();
        // @usage source.cancel();
        // return source.token;
        return cancel(axios.CancelToken).token;
      })() : undefined,
    });
  },
  registration({ data }) {
    return axios({
      method: 'post',
      url: '/api/v1/auth/register',
      data,
    });
  },
  checkAuth({ token }) {
    return axios({
      method: 'get',
      url: '/api/v1/auth',
      headers: { 'X-Token': token },
    });
  },
  login({ data }) {
    return axios({
      method: 'post',
      url: '/api/v1/auth',
      data,
    });
  },
  authSocialNetwork({ providerId, data }) {
    return axios({
      method: 'post',
      url: `/api/v1/auth/${providerId}`,
      data,
    });
  },
  twitterOauthToken({ data }) {
    return axios({
      method: 'post',
      url: '/api/v1/auth/signature/twitter',
      data,
    });
  },
  // used after registration through SN
  confirmEmailOnSNReg({ data }) {
    return axios({
      method: 'post',
      url: '/api/v1/auth/verify-email',
      data,
    });
  },
  // used after standard registration
  confirmEmailOnStdReg({ data: { token } }) {
    return axios({
      method: 'post',
      url: '/api/v1/auth/register/activate',
      data: { token },
    });
  },
  // used after chaning email
  confirmEmailOnEmailChange({ data: { token } }) {
    return axios({
      method: 'post',
      url: '/api/v1/profile/account/verify-email',
      data: { token },
    });
  },
  sendInvoiceEmail({ token, invoiceId }) {
    return axios({
      method: 'post',
      url: `/api/v1/stripe/invoice/${invoiceId}`,
      headers: { 'X-Token': token },
    });
  },
  getArtistById({ id, token }) {
    return axios({
      method: 'get',
      url: `/api/v1/artists/${id}`,
      headers: { 'X-Token': token },
    });
  },
  // getSharedArtistById({ authorId, shareId, token }) {
  //   return axios({
  //     method: 'get',
  //     url: `/api/v1/sharing/${shareId}/artists/${authorId}`,
  //     headers: { 'X-Token': token },
  //   });
  // },
  searchArtists({ token, ...params }) {
    return axios({
      method: 'get',
      url: '/api/v1/artists',
      params,
      paramsSerializer: paramsSerial,
      headers: { 'X-Token': token },
    });
  },
  createArtist({ token, data }) {
    return axios({
      method: 'post',
      url: '/api/v1/artists',
      data,
      headers: { 'X-Token': token },
    });
  },
  updateArtist({ id, token, data }) {
    return axios({
      method: 'post',
      url: `/api/v1/artists/${id}`,
      data,
      headers: { 'X-Token': token },
    });
  },
  removeArtist({ id, token }) {
    return axios({
      method: 'delete',
      url: `/api/v1/artists/${id}`,
      headers: { 'X-Token': token },
    });
  },
  getArtwork({ token, id }) {
    return axios({
      method: 'get',
      url: `/api/v1/artworks/original/${id}`,
      headers: { 'X-Token': token },
    });
  },
  /*
  /api/v1/alerts/{id}/filter-apply
  /api/v1/galleries/{id}/filter-apply
  /api/v1/alerts/sale/filter-apply?saleNumber={saleNumber}
  
  /api/v1/sharing/{id}/filter-apply
  */
  // AF total
  getArtistAdvancedFilterTotal({ token, artistId, ...params }) {
    return axios({
      method: 'get',
      url: `/api/v1/alerts/${artistId}/filter-apply`,
      params,
      paramsSerializer: paramsSerial,
      headers: { 'X-Token': token },
    });
  },
  getGalleryAdvancedFilterTotal({ token, galleryId, ...params }) {
    return axios({
      method: 'get',
      url: `/api/v1/galleries/${galleryId}/filter-apply`,
      params,
      paramsSerializer: paramsSerial,
      headers: { 'X-Token': token },
    });
  },
  getSaleLotsAdvancedFilterTotal({ token, saleId, auctions, ...params }) {
    return axios({
      method: 'get',
      url: '/api/v1/alerts/sale/filter-apply',
      params: { auctions, saleNumber: saleId, ...params },
      paramsSerializer: paramsSerial,
      headers: { 'X-Token': token },
    });
  },
  getShareLotsAdvancedFilterTotal({ token, shareId, ...params }) {
    return axios({
      method: 'get',
      url: `/api/v1/sharing/${shareId}/filter-apply`,
      params: { ...params },
      paramsSerializer: paramsSerial,
      headers: { 'X-Token': token },
    });
  },
  // AF options
  getArtistAdvancedFilterOptions({ token, artistId, ...params }) {
    return axios({
      method: 'get',
      url: `/api/v1/alerts/artist/${artistId}/filter`,
      params,
      paramsSerializer: paramsSerial,
      headers: { 'X-Token': token },
    });
  },
  getAlertAdvancedFilterOptions({ token, alertId, ...params }) {
    return axios({
      method: 'get',
      url: `/api/v1/alerts/${alertId}/filter`,
      params,
      paramsSerializer: paramsSerial,
      headers: { 'X-Token': token },
    });
  },
  getGalleryAdvancedFilterOptions({ token, galleryId, ...params }) {
    return axios({
      method: 'get',
      url: `/api/v1/galleries/${galleryId}/filter`,
      params,
      paramsSerializer: paramsSerial,
      headers: { 'X-Token': token },
    });
  },
  getSaleLotsAdvancedFilterOptions({ token, saleId, auctions, ...params }) {
    return axios({
      method: 'get',
      url: '/api/v1/alerts/sale/filter',
      params: { auctions, saleNumber: saleId, ...params },
      paramsSerializer: paramsSerial,
      headers: { 'X-Token': token },
    });
  },
  getShareAdvancedFilterOptions({ token, shareId, ...params }) {
    return axios({
      method: 'get',
      url: `/api/v1/sharing/${shareId}/filter`,
      params: { ...params },
      paramsSerializer: paramsSerial,
      headers: { 'X-Token': token },
    });
  },
  getComparableArtwork({ token, artworkId, size }) {
    return axios({
      method: 'get',
      // for artwork, that not belongs to user
      url: `/api/v1/alerts/comparable/${artworkId}`,
      params: { size },
      paramsSerializer: paramsSerial,
      headers: { 'X-Token': token },
    });
  },
  getComparableToOwnArtwork({ token, artworkId, size }) {
    return axios({
      method: 'get',
      url: `/api/v1/artworks/comparable/${artworkId}`,
      params: { size },
      paramsSerializer: paramsSerial,
      headers: { 'X-Token': token },
    });
  },
  /**
   * POST /api/v1/artworks/comparable/{id} own artworks
   * POST ???
   * GET /api/v1/artworks/comparable/{id}/all own artworks
   * GET /api/v1/alerts/comparable/{id}/all
   * GET /api/v1/artworks/comparable/{id}/recommended own artworks
   * GET /api/v1/alerts/comparable/{id}/recommended
  **/
  fetchAvailableComparables({ token, artworkId, page = 0, size = 15, baseList = 'recommended' }) {
    return axios({
      method: 'get',
      // for artwork, that not belongs to user
      // AOId: 955343
      url: `/api/v1/alerts/comparable/${artworkId}/${baseList}`,
      params: { page, size },
      paramsSerializer: paramsSerial,
      headers: { 'X-Token': token },
    });
  },
  fetchAvailableComparablesToOwnAO({ token, artworkId, page = 0, size = 15, baseList = 'recommended' }) {
    return axios({
      method: 'get',
      // for artwork, that not belongs to user
      // AOId: 955343
      url: `/api/v1/artworks/comparable/${artworkId}/${baseList}`,
      params: { page, size },
      paramsSerializer: paramsSerial,
      headers: { 'X-Token': token },
    });
  },
  updateSelectedComparables({ token, artworkId, ids }) {
    return axios({
      method: 'post',
      url: `/api/v1/alerts/comparable/${artworkId}`,
      data: { ids },
      headers: { 'X-Token': token },
    });
  },
  updateSelectedComparablesToOwnAO({ token, artworkId, ids }) {
    return axios({
      method: 'post',
      url: `/api/v1/artworks/comparable/${artworkId}`,
      data: { ids },
      headers: { 'X-Token': token },
    });
  },
  comparablesRevertToRecommended({ token, artworkId }) {
    return axios({
      method: 'post',
      url: `/api/v1/alerts/comparable/revert-to-recommended/${artworkId}`,
      headers: { 'X-Token': token },
    });
  },
  comparablesRevertToRecommendedForOwnAO({ token, artworkId }) {
    return axios({
      method: 'post',
      url: `/api/v1/artworks/comparable/revert-to-recommended/${artworkId}`,
      headers: { 'X-Token': token },
    });
  },
  /**
   * POST /api/v1/artworks/comparable/{id}
   * Body { ids: [ 12312, 32131, 321132 ] }
  **/
  // getAuthorExtended({ token, id }) {
  //   return axios({
  //     method: 'get',
  //     url: `/api/v1/alerts/artist-extended/${id}`,
  //     params: { size: 500 },
  //     paramsSerializer: paramsSerial,
  //     headers: { 'X-Token': token },
  //   });
  // },
  getArtworkList({ token, artistId, ...params }) {
    return axios({
      method: 'get',
      url: `/api/v1/alerts/artist/${artistId}`,
      params,
      paramsSerializer: paramsSerial,
      headers: { 'X-Token': token },
    });
  },
  getOwnArtwork({ token, artworkId }) {
    return axios({
      method: 'get',
      url: `/api/v1/artworks/${artworkId}`,
      headers: { 'X-Token': token },
    });
  },
  getShareProperties({ token, data }) {
    return axios({
      method: 'POST',
      data,
      url: '/api/v1/sharing/properties',
      headers: { 'X-Token': token },
    });
  },
  getSharedWithMeList({ token, ...params }) {
    return axios({
      method: 'get',
      // List of things shared with user
      url: '/api/v1/sharing/shared',
      headers: { 'X-Token': token },
      params,
      paramsSerializer: paramsSerial,
    });
  },
  getSharedByMeList({ token, ...params }) {
    return axios({
      method: 'get',
      // List of things shared by user
      url: '/api/v1/sharing/shares',
      headers: { 'X-Token': token },
      params,
      paramsSerializer: paramsSerial,
    });
  },
  removeEmailsFromShare({ token, shareId, emails }) {
    return axios({
      method: 'put',
      url: `/api/v1/sharing/shares/${shareId}`,
      headers: { 'X-Token': token },
      data: {
        action: 'REMOVE',
        emails: emails,
      },
    });
  },
  shareEntities({ token, data }) {
    return axios({
      method: 'post',
      data,
      url: '/api/v1/sharing',
      headers: { 'X-Token': token },
    });
  },
  updateShare({ token, data }) {
    return axios({
      method: 'post',
      data,
      url: `/api/v1/sharing/${data.id}`,
      headers: { 'X-Token': token },
    });
  },
  sharedArtworkViewed({ token, ids, shareId }) {
    return axios({
      method: 'post',
      data: { ids },
      url: `/api/v1/sharing/${shareId}/viewed/`,
      headers: { 'X-Token': token },
    });
  },
  getSharedArtworkList({ shareId, token, ...params }) {
    return axios({
      method: 'get',
      url: `/api/v1/sharing/${shareId}/artworks`,
      headers: { 'X-Token': token },
      params,
      paramsSerializer: paramsSerial,
    });
  },
  getSharedArtwork({ shareId, artworkId, token }) {
    return axios({
      method: 'get',
      url: `/api/v1/sharing/${shareId}/artworks/${artworkId}`,
      headers: { 'X-Token': token },
    });
  },
  getSharedGalleries({ shareId, token, ...params }) {
    return axios({
      method: 'get',
      url: `/api/v1/sharing/${shareId}/galleries`,
      headers: { 'X-Token': token },
      params,
      paramsSerializer: paramsSerial,
    });
  },
  getShare({ id, token }) {
    return axios({
      method: 'get',
      url: `/api/v1/sharing/${id}`,
      headers: { 'X-Token': token },
    });
  },
  removeShare({ shareId, token }) {
    return axios({
      method: 'delete',
      url: `/api/v1/sharing/${shareId}`,
      headers: { 'X-Token': token },
    });
  },
  removeMeFromShare({ shareId, token }) {
    return axios({
      method: 'delete',
      url: `/api/v1/sharing/refuse/${shareId}`,
      headers: { 'X-Token': token },
    });
  },
  createGallery({ token, data }) {
    return axios({
      method: 'put',
      data,
      url: '/api/v1/galleries',
      headers: { 'X-Token': token },
    });
  },
  editGallery({ token, galleryId, data }) {
    return axios({
      method: 'put',
      data,
      url: `/api/v1/galleries/${galleryId}`,
      headers: { 'X-Token': token },
    });
  },
  getGalleries({ token, ...params }) {
    return axios({
      method: 'get',
      url: '/api/v1/galleries',
      params,
      headers: { 'X-Token': token },
    });
  },
  getGalleryPage({ token, galleryId, ...params }) {
    return axios({
      method: 'get',
      url: `/api/v1/galleries/${galleryId}/page`,
      params,
      paramsSerializer: paramsSerial,
      headers: { 'X-Token': token },
    });
  },
  removeGallery({ token, galleryId }) {
    return axios({
      method: 'delete',
      url: `/api/v1/galleries/${galleryId}`,
      headers: { 'X-Token': token },
    });
  },
  restoreGallery({ token, galleryId }) {
    return axios({
      method: 'post',
      url: `/api/v1/galleries/restore/${galleryId}`,
      headers: { 'X-Token': token },
    });
  },
  getGallery({ token, galleryId }) {
    return axios({
      method: 'get',
      url: `/api/v1/galleries/${galleryId}`,
      headers: { 'X-Token': token },
    });
  },
  galleryAddArtworks({ token, data, gId }) {
    return axios({
      method: 'put',
      data,
      url: `/api/v1/galleries/${gId}/artworks`,
      headers: { 'X-Token': token },
    });
  },
  // getAuthors({ token, gId = 'all', ...params }) {
  //   return axios({
  //     method: 'get',
  //     url: `/api/v1/galleries/${gId}/authors`,
  //     params,
  //     paramsSerializer: paramsSerial,
  //     headers: { 'X-Token': token },
  //   });
  // },
  getGalleryArtworks({ token, gId = 'all', ...params }) {
    return axios({
      method: 'get',
      url: `/api/v1/galleries/${gId}/artworks`,
      params,
      paramsSerializer: paramsSerial,
      headers: { 'X-Token': token },
    });
  },
  getAlerts({ token, ...params }) {
    return axios({
      method: 'get',
      url: '/api/v1/alerts',
      params: { size: 4, page: 0, ...params },
      paramsSerializer: paramsSerial,
      headers: { 'X-Token': token },
    });
  },
  getAlertItem({ token, alertId, ...query }) {
    return axios({
      method: 'get',
      url: `/api/v1/alerts/${alertId}`,
      params: query,
      paramsSerializer: paramsSerial,
      headers: { 'X-Token': token },
    });
  },
  createAlert({ token, data }) {
    return axios({
      method: 'post',
      url: '/api/v1/alerts',
      data,
      headers: { 'X-Token': token },
    });
  },
  updateAlert({ token, data, alertId }) {
    return axios({
      method: 'post',
      url: `/api/v1/alerts/${alertId}`,
      data,
      headers: { 'X-Token': token },
    });
  },
  removeAlert({ token, alertId }) {
    return axios({
      method: 'delete',
      url: `/api/v1/alerts/${alertId}`,
      headers: { 'X-Token': token },
    });
  },
  restoreAlert({ token, alertId }) {
    return axios({
      method: 'post',
      url: `/api/v1/alerts/restore/${alertId}`,
      headers: { 'X-Token': token },
    });
  },
  removeAlertArtworks({ token, alertId, ids }) {
    return axios({
      method: 'put',
      data: { ids, action: 'REMOVE' },
      url: `/api/v1/alerts/${alertId}/artworks`,
      headers: { 'X-Token': token },
    });
  },
  restoreAlertArtworks({ token, alertId, ids }) {
    return axios({
      method: 'put',
      data: { ids, action: 'RESTORE' },
      url: `/api/v1/alerts/${alertId}/artworks`,
      headers: { 'X-Token': token },
    });
  },
  searchAlertByArtistId({ token, artistId }) {
    return axios({
      method: 'get',
      url: `/api/v1/alerts/artist-available/${artistId}`,
      headers: { 'X-Token': token },
    });
  },
  getCountries({ params = {}, id }) {
    return axios({
      method: 'get',
      url: `/api/v1/countries${id ? ('/' + id) : '' }`,
      params,
    });
  },
  getCategories({ token }) {
    return axios({
      url: '/api/v1/categories',
      method: 'get',
      headers: { 'X-Token': token },
    });
  },
  getSignatures({ token }) {
    return axios({
      url: '/api/v1/signatures',
      method: 'get',
      headers: { 'X-Token': token },
    });
  },
  getSurfaces({ token }) {
    return axios({
      url: '/api/v1/surfaces',
      method: 'get',
      headers: { 'X-Token': token },
    });
  },
  getSubstrates({ token }) {
    return axios({
      url: '/api/v1/substrates',
      method: 'get',
      headers: { 'X-Token': token },
    });
  },
  getConditions({ token }) {
    return axios({
      url: '/api/v1/conditions',
      method: 'get',
      headers: { 'X-Token': token },
    });
  },
  getOccupations() {
    return axios({
      url: '/api/v1/occupations',
      method: 'get',
    });
  },
  getAuctions({ token, id }) {
    return axios({
      url: `/api/v1/auctions${id ? ('/' + id) : '' }`,
      method: 'get',
      headers: { 'X-Token': token },
    });
  },
  newAccPassword({ token, data }) {
    return axios({
      method: 'post',
      data,
      url: '/api/v1/profile/account/gdpr-password',
      headers: { 'X-Token': token },
    });
  },
  changePassword({ token, data }) {
    return axios({
      method: 'post',
      data,
      url: '/api/v1/profile/account/password',
      headers: { 'X-Token': token },
    });
  },
  forgotPassword({ data }) {
    return axios({
      method: 'post',
      data,
      url: '/api/v1/auth/reset-password',
    });
  },
  /**
   * reset password by URL with token (usually sends to email)
  **/
  checkToken({ data }) {
    return axios({
      method: 'post',
      data,
      url: '/api/v1/auth/verify-token',
    });
  },
  resetPassword({ data }) {
    return axios({
      method: 'post',
      data,
      url: '/api/v1/auth/change-password',
    });
  },
  sendCreatePasswordEmail({ data }) {
    return axios({
      method: 'post',
      data,
      url: '/api/v1/auth/screener-reset-token',
    });
  },
  updateAccount({ token, data }) {
    return axios({
      method: 'post',
      data,
      url: '/api/v1/profile/account',
      headers: { 'X-Token': token },
    });
  },
  updateBilling({ token, data }) {
    return axios({
      method: 'post',
      data,
      url: '/api/v1/stripe/billing',
      headers: { 'X-Token': token },
    });
  },
  getStripeProperties({ token }) {
    return axios({
      method: 'get',
      url: '/api/v1/stripe/properties',
      headers: { 'X-Token': token },
    });
  },
  // checkDiscount({ token, data }) {
  //   return axios({
  //     method: 'post',
  //     data,
  //     url: '/api/v1/stripe/discount',
  //     headers: { 'X-Token': token },
  //   });
  // },

  buyArtistAnalyticsReport({ token, data: { artistId, ...data } }) {
    return axios({
      method: 'post',
      data,
      url: `/api/v1/stripe/card/artist-report/${artistId}`,
      headers: { 'X-Token': token },
    });
  },
  checkRtvCoupon({ token, data }) {
    return axios({
      method: 'get',
      params: data,
      paramsSerializer: paramsSerial,
      url: '/api/v1/stripe/check-coupon',
      headers: { 'X-Token': token },
    });
  },
  checkSubscriptionCoupon({ token, data }) {
    return axios({
      method: 'post',
      data: { subscriptionPlan: 'SUBSCRIPTION_TYPE_PROFESSIONAL', ...data },
      url: '/api/v1/stripe/subscriptions/discount',
      headers: { 'X-Token': token },
    });
  },
  getCurrentPlan({ token }) {
    return axios({
      method: 'get',
      url: '/api/v1/stripe/subscriptions/current',
      headers: { 'X-Token': token },
    });
  },
  fetchPlanTypes({ token, coupon }) {
    return axios({
      method: 'get',
      url: '/api/v1/stripe/subscriptions',
      params: { coupon },
      paramsSerializer: paramsSerial,
      headers: { 'X-Token': token },
    });
  },
  // subscribeFreePlan({ token, data }) {
  //   return axios({
  //     method: 'post',
  //     data,
  //     url: '/api/v1/stripe/card/subscribe',
  //     headers: { 'X-Token': token },
  //   });
  // },
  upgradePlan({ token, data }) {
    return axios({
      method: 'post',
      data,
      url: '/api/v1/stripe/subscriptions/upgrade',
      headers: { 'X-Token': token },
    });
  },
  // subscribe({ token, data }) {
  //   return axios({
  //     method: 'post',
  //     data,
  //     url: '/api/v1/stripe/subscribe',
  //     headers: { 'X-Token': token },
  //   });
  // },
  analyticsList({ token, params }) {
    return axios({
      method: 'get',
      url: '/api/v1/artist-report',
      params,
      paramsSerializer: paramsSerial,
      headers: { 'X-Token': token },
    });
  },
  rtvValueList({ token, params }) {
    return axios({
      method: 'get',
      url: '/api/v1/rtv-purchase',
      params,
      paramsSerializer: paramsSerial,
      headers: { 'X-Token': token },
    });
  },
  orderRtv({ token, data: { artworkId, ...data } }) {
    return axios({
      method: 'post',
      data,
      url: `/api/v1/stripe/card/buy-rtv/${artworkId}`,
      headers: { 'X-Token': token },
    });
  },
  downloadRtvPdf({ token, data: { artworkId }, params }) {
    return axios({
      method: 'get',
      url: `/api/v1/pdf/${artworkId}`,
      params,
      paramsSerializer: paramsSerial,
      headers: { 'X-Token': token },
      responseType: 'blob',
    });
  },
  saveStripeCard({ token, data }) {
    return axios({
      method: 'post',
      data,
      url: '/api/v1/stripe/card',
      headers: { 'X-Token': token },
    });
  },
  // use auth: { username: 'artbnk', password: 'RK3EqVtrv77S' }, for local instance
  uploadPicture({ token, data, onUploadProgress }) {
    return axios({
      method: 'post',
      data,
      onUploadProgress,
      url: '/uploads',
      headers: { 'X-Token': token },
    });
  },
  createConsideration({ token, data }) {
    return axios({
      method: 'post',
      data,
      url: '/api/v1/considerations',
      headers: { 'X-Token': token },
    });
  },
  createConsiderationOriginal({ token, id }) {
    return axios({
      method: 'post',
      url: `/api/v1/considerations/original/${id}`,
      headers: { 'X-Token': token },
    });
  },
  createSuggestedConsideration({ token, data }) {
    return axios({
      method: 'post',
      url: '/api/v1/considerations/add',
      data,
      headers: { 'X-Token': token },
    });
  },
  updateConsiderations({ token, data, id }) {
    return axios({
      method: 'post',
      url: `/api/v1/considerations/${id}`,
      data,
      headers: { 'X-Token': token },
    });
  },
  acquireLot({ token, data, id }) {
    return axios({
      method: 'post',
      url: `/api/v1/artworks/collect/${id}`,
      data,
      headers: { 'X-Token': token },
    });
  },
  acquireConsideration({ token, data, id }) {
    return axios({
      method: 'put',
      url: `/api/v1/considerations/acquired/${id}`,
      data,
      headers: { 'X-Token': token },
    });
  },
  updateDraftConsideration({ token, data, id }) {
    return axios({
      method: 'post',
      url: `/api/v1/considerations/draft/${id}`,
      data,
      headers: { 'X-Token': token },
    });
  },
  createDraftConsideration({ token, data }) {
    return axios({
      method: 'post',
      url: '/api/v1/considerations/draft',
      data,
      headers: { 'X-Token': token },
    });
  },
  addDraftConsideration({ token, data }) {
    return axios({
      method: 'post',
      url: '/api/v1/considerations/draft/add',
      data,
      headers: { 'X-Token': token },
    });
  },
  // fetchConsiderations({ token, params }) {
  //   return axios({
  //     method: 'get',
  //     url: '/api/v1/considerations',
  //     params,
  //     paramsSerializer: paramsSerial,
  //     headers: { 'X-Token': token },
  //   });
  // },
  // fetchConsiderationsByAuthors({ token, ...params }) {
  //   return axios({
  //     method: 'get',
  //     url: '/api/v1/considerations/authors',
  //     params,
  //     paramsSerializer: paramsSerial,
  //     headers: { 'X-Token': token },
  //   });
  // },
  fetchConsiderationItem({ token, artworkId }) {
    return axios({
      method: 'get',
      url: `/api/v1/considerations/${artworkId}`,
      headers: { 'X-Token': token },
    });
  },
  // similarSearch({ token, data }) {
  //   return axios({
  //     method: 'post',
  //     url: '/api/v1/artworks/search/similar',
  //     data,
  //     headers: { 'X-Token': token },
  //   });
  // },

  // removeArtworkImage({ token, data: { id } }) {
  //   return axios({
  //     method: 'post',
  //     url: `/api/v1/artworks/removeArtworkImage/${id}`,
  //     headers: { 'X-Token': token },
  //   });
  // },

  similarSearchConsideration({ token, data }) {
    return axios({
      method: 'post',
      url: '/api/v1/considerations/search/similar',
      data,
      headers: { 'X-Token': token },
    });
  },
  getStats({ token, ...params }) {
    return axios({
      method: 'get',
      url: '/api/v1/profile/statistics',
      params,
      paramsSerializer: paramsSerial,
      headers: { 'X-Token': token },
    });
  },
  // removeArtwork({ token, artworkId }) {
  //   return axios({
  //     method: 'delete',
  //     url: `/api/v1/artworks/${artworkId}`,
  //     headers: { 'X-Token': token },
  //   });
  // },
  restoreArtworks({ token, gId, ids }) {
    return axios({
      method: 'put',
      url: `/api/v1/galleries/${gId}/artworks`,
      headers: { 'X-Token': token },
      data: { action: 'RESTORE', ids },
    });
  },
  removeArtworks({ token, gId, ids }) {
    return axios({
      method: 'put',
      url: `/api/v1/galleries/${gId}/artworks`,
      headers: { 'X-Token': token },
      data: { action: 'REMOVE', ids },
    });
  },
  createArtwork({ token, data }) {
    return axios({
      method: 'post',
      url: '/api/v1/artworks/create',
      data,
      headers: { 'X-Token': token },
    });
  },
  createSuggestedArtwork({ token, data }) {
    return axios({
      method: 'post',
      url: '/api/v1/artworks/add',
      data,
      headers: { 'X-Token': token },
    });
  },
  updateDraftArtwork({ token, data, id }) {
    return axios({
      method: 'post',
      url: `/api/v1/artworks/draft/${id}`,
      data,
      headers: { 'X-Token': token },
    });
  },
  createDraftArtwork({ token, data }) {
    return axios({
      method: 'post',
      url: '/api/v1/artworks/draft/create',
      data,
      headers: { 'X-Token': token },
    });
  },
  addDraftArtwork({ token, data }) {
    return axios({
      method: 'post',
      url: '/api/v1/artworks/draft/add',
      data,
      headers: { 'X-Token': token },
    });
  },
  updateArtwork({ token, data, id }) {
    return axios({
      method: 'post',
      url: `/api/v1/artworks/${id}`,
      data,
      headers: { 'X-Token': token },
    });
  },
  setGalleryCover({ token, galleryId, artworkId }) {
    return axios({
      method: 'put',
      url: `/api/v1/galleries/${galleryId}/artworks/${artworkId}/cover`,
      headers: { 'X-Token': token },
    });
  },
  getMediums({ token }) {
    return axios({
      method: 'get',
      url: '/api/v1/mediums',
      headers: { 'X-Token': token },
    });
  },

  getAuctionSaleLots({ token, auctions, saleId, ...query }) {
    return axios({
      method: 'get',
      url: '/api/v1/alerts/sale',
      params: { ...query, saleNumber: saleId, auctions },
      paramsSerializer: paramsSerial,
      headers: { 'X-Token': token },
    });
  },

  getCAGRProjectedValue({ token, artworkId, artistId, sold_price, auction_date }) {
    return axios({
      method: 'get',
      url: `/api/v1/graphs/projected-value/${artworkId || ''}`,
      params: {
        price: sold_price || undefined,
        sold_price: sold_price || undefined,
        auction_date: auction_date || undefined,
        artistId,
        chart_id: 'p001',
      },
      paramsSerializer: paramsSerial,
      headers: { 'X-Token': token },
    });
  },
  getMarketPerformanceSeries({ token, artistId, artworkId, chartId }) {
    return axios({
      method: 'get',
      url: `/api/v1/graphs/market-performance/${artistId}`,
      params: { art_id: artworkId, chart_id: chartId },
      paramsSerializer: paramsSerial,
      headers: { 'X-Token': token },
    });
  },
  getMarketPerformanceArtworkSeries({ token, chartId, artworkId }) {
    return axios({
      method: 'get',
      url: `/api/v1/graphs/market-performance/artwork-line/${artworkId}/`,
      params: { chart_id: chartId },
      paramsSerializer: paramsSerial,
      headers: { 'X-Token': token },
    });
  },
  getMarketMovementSeries({ token, artistId, artworkId, chartId }) {
    return axios({
      method: 'get',
      url: `/api/v1/graphs/market-movement/${artistId}`,
      params: { art_id: artworkId, chart_id: chartId },
      paramsSerializer: paramsSerial,
      headers: { 'X-Token': token },
    });
  },
  getLiquidityIndexSeries({ token, artistId, chartId }) {
    return axios({
      method: 'get',
      url: `/api/v1/graphs/liquidity-indexes/${artistId}`,
      params: { chart_id: chartId },
      paramsSerializer: paramsSerial,
      headers: { 'X-Token': token },
    });
  },
  getFairMarketValues({ token, artistId, chartId }) {
    return axios({
      method: 'get',
      url: `/api/v1/graphs/fair-market-values/${artistId}`,
      params: { chart_id: chartId },
      paramsSerializer: paramsSerial,
      headers: { 'X-Token': token },
    });
  },
  getCollectingCategoryIndexes({ token, artistId, chartId }) {
    return axios({
      method: 'get',
      url: `/api/v1/graphs/collecting-category-indexes/${artistId}`,
      params: { chart_id: chartId },
      paramsSerializer: paramsSerial,
      headers: { 'X-Token': token },
    });
  },
  getLotInfo({ token, lotId }) {
    return axios({
      method: 'get',
      url: `/api/v1/graphs/market-performance/lotDetails/${lotId}`,
      params: { lot_id: lotId },
      paramsSerializer: paramsSerial,
      headers: { 'X-Token': token },
    });
  },
  getArtistRankingTable({ token, artistId }) {
    return axios({
      method: 'get',
      url: `/api/v1/graphs/artist-ranking-table/${artistId}`,
      params: { chart_id: 't002,t003,t004,t005' },
      paramsSerializer: paramsSerial,
      headers: { 'X-Token': token },
    });
  },
  getArtistLiquidity({ token, artistId }) {
    return axios({
      method: 'get',
      url: `/api/v1/graphs/artists/${artistId}/is-illiquid`,
      headers: { 'X-Token': token },
    });
  },
  loadConfig() {
    return axios({
      method: 'get',
      url: '/api/v1/common/fe-init',
    });
  },
};
