import record, { indexedSetOf } from 'cpcs-recordjs';
import { field } from 'cpcs-reconnect';
import { compose } from 'redux';
import {
  onFieldChangeAction,
  onFieldBlurAction,
  onFieldErrorAction,
  resetCardFormAction,
  setStripeCardTokenAction,
  setStripeCardTokenInvalidAction,
} from 'pages/billingPage/form/cardFormActions';
import { saveStripeCardAction } from 'domain/env/EnvActions';

const FieldError = record('FieldError', {
  message: '',
});

const Field = record('reduxFormField', {
  label: '',
  complete: false,
  error: FieldError,
  blur: false,
  empty: true,
  name: '',
});

const State = record('cardFormModel', {
  isFormValid: false,
  fields: indexedSetOf(Field, Field.$name),
  stripeCardToken: '',
  cardTokenInvalid: false,
});

const initialState = new State().update(State.$fields.parsed([
  { complete: false, error: void 0, blur: false, empty: true, name: 'cardNumber', label: 'card number' },
  { complete: false, error: void 0, blur: false, empty: true, name: 'cardExpiry', label: 'expiration' },
  { complete: false, error: void 0, blur: false, empty: true, name: 'cardCvc', label: 'number' },
]));
export const fieldNames = ['cardNumber', 'cardExpiry', 'cardCvc'];

// selectors
const base = field('cardFormModel');
export const isFormValidSel = base.then(State.$isFormValid);
export const fieldsSel = base.then(State.$fields);
export const stripeCardToken_sel = base.then(State.$stripeCardToken);
export const cardTokenInvalid_sel = base.then(State.$cardTokenInvalid);

// reducers
const $onFieldChange = (state, payload) => {
  let { fields } = state;
  let field = fields.get(payload.name);
  if (!field) {
    throw new Error(`no such card Field "${payload.name}"`);
  }
  field = field.apply(
    Field.$error.parsed(payload.error),
    Field.$complete.parsed(payload.complete),
    Field.$empty.parsed(payload.empty),
  );
  
  state = state.update(
    State.$fields.put(field),
  );
  // validation
  fields = state.fields;
  const isFormValid = fieldNames
    .reduce((prev, n) => (prev && !!fields.get(n).complete), true);
  return state.apply(
    State.$isFormValid.parsed(isFormValid),
    State.$stripeCardToken.parsed(''),
    State.$cardTokenInvalid.parsed(false),
  );
};

export const reducer = {
  cardFormModel(state = initialState, action) {
    switch (action.type) {
      case onFieldBlurAction.type:
        return state.update(
          State.$fields.updateById(
            action.payload,
            Field.$blur.set(true),
          ),
        );
      case onFieldErrorAction.type:
        return state.apply(
          State.$isFormValid.set(false),
          State.$fields.updateById(action.payload.name, compose(
            Field.$error.parsed(action.payload.error),
            Field.$blur.parsed(true),
          )),
        );
      case setStripeCardTokenAction.type:
        return state.apply(
          State.$stripeCardToken.parsed(action.payload.token),
          State.$cardTokenInvalid.parsed(false),
        );
      case setStripeCardTokenInvalidAction.type:
        return state.apply(
          State.$cardTokenInvalid.parsed(true),
        );
      case onFieldChangeAction.type:
        return $onFieldChange(state, action.payload);
      case saveStripeCardAction.success:
        return state.apply(
          State.$stripeCardToken.set(''),
        );
      case '@@router/LOCATION_CHANGE':
      case resetCardFormAction.type:
        return initialState;
      default:
        return state;
    }
  },
};
