import record, { integer, string, listOf, enhancedType } from 'cpcs-recordjs';
import I from 'immutable';
import { field, at } from 'cpcs-reconnect';
import { compose } from 'redux';
import { Artwork, Pagination } from 'model';
import { Gallery } from 'domain/galleries/GalleryModel';
import { orderRtvAction } from 'pages/common/buyRtv/buyRtvActions';
import * as A from './ShareActions';
import * as env from 'domain/env/EnvActions';

/**
 * map of bool values
**/
const CheckBoxes = enhancedType({
  typeName: 'CheckBoxes',
  parse(value) {
    return value ? Object.keys(value).reduce((map, k) => map.set(k, !!value[k]), I.Map()) : {};
  },
});

export const Share = record('Share', {
  id: integer(),
  picture: string(),
  amount: integer(),
  type: string(),
  title: string(),
  email: string(),
  emails: listOf(string().type),
  created: string(''),
  message: string(''),
  ids: listOf(integer),
  alertId: integer,
  galleryId: integer,
  prop: CheckBoxes(),
  updatedArts: listOf(integer),
  deletedArts: listOf(integer),
});

export const SharedWithMe = record('SharedWithMe', {
  list: listOf(Share),
  page: integer(),
});

export const SharedByMe = record('SharedByMe', {
  list: listOf(Share),
  page: integer(),
});


const State = record('Shares', {
  withMe: SharedWithMe(),
  withMePagination: Pagination.parse({}),
  // used in sharesPage (byMe + withMe). here should be stored the id of filter
  withMeSorting: string,
  byMe: SharedByMe(),
  byMePagination: Pagination.parse({}),
  // used in sharesPage (byMe + withMe). here should be stored the id of filter
  byMeSorting: string,
  artworks: listOf(Artwork),
  artworksPagination: Pagination.parse({}),
  artwork: Artwork,
  galleries: listOf(Gallery),
  share: Share(),
  page: integer(),
});

const shares = field('shares');
export const getSharedWithMe = shares.then(State.$withMe).then(SharedWithMe.$list);
export const sharedByMeSel = shares.then(State.$byMe).then(SharedByMe.$list);
export const getSharedArtworkList_sel = shares.then(State.$artworks);
export const getCurrentShareSelector = shares.then(State.$share);

export const sharedArtworkSelector = shares.then(State.$artwork);

const getSharedGalleries_sel = shares.then(State.$galleries);
export const getSharedGalleryById = id => getSharedGalleries_sel.then(list => list.find(e => e.id === id));
export const getSharedWithMePagination_sel = shares.then(State.$withMePagination);
export const getSharedByMePagination_sel = shares.then(State.$byMePagination);
export const artworksPagination_sel = shares.then(State.$artworksPagination);
export const byMeLoadingSelector = shares.then(State.$byMePagination).then(at('loading'));
export const withMeLoadingSelector = shares.then(State.$withMePagination).then(at('loading'));

export const byMeSortingSelector = shares.then(State.$byMeSorting);
export const withMeSortingSelector = shares.then(State.$withMeSorting);

/**
 * if sharedByMe item found by id, updates it
 * else returns state as is
**/
const $updateSharedByMeItemIfExists = newItem => state => {
  const KEY = 0;
  /**
   * [i, <Record> foundItem] or undefined;
  **/
  const entry = compose(
    SharedByMe.$list,
    State.$byMe,
  )(state)
    .findEntry(item => item.id === newItem.id);
  if (!entry) return state;

  return State.$byMe.update(
    SharedByMe.$list.updateBy('set', entry[KEY], newItem),
  )(state);
};

export const reducer = {
  shares(state = new State(), action) { //NOSONAR
    switch (action.type) {

      case env.logOut.type:
        return new State();

      case A.getSharedWithMeAction.failure:
        return state.apply(
          State.$withMePagination.update(v => v ? v.set('loading', false) : v),
        );
      case A.getSharedWithMeAction.request:
        return state.apply(
          State.$withMePagination.parsed({ loading: true }),
        );
      case A.getSharedWithMeAction.success:
        return state.apply(
          State.$withMe.update(
            SharedWithMe.$list.parsed(action.payload.content),
          ),
          State.$withMePagination.parsed(action.payload),
        );

      case A.getSharedWithMeAppendPageAction.failure:
        return state.apply(
          State.$withMePagination.update(v => v ? v.set('loading', false) : v),
        );

      case A.getSharedWithMeAppendPageAction.request:
        return state.apply(
          State.$withMePagination.update(v => v.set('loading', true)),
        );
      case A.getSharedWithMeAppendPageAction.success:
        return state.apply(
          State.$withMe.update(
            SharedWithMe.$list.parsedBy('concat', action.payload.content),
          ),
          State.$withMePagination.parsed(action.payload),
        );
      case A.getSharedByMeAppendPageAction.failure:
        return state.apply(
          State.$byMePagination.update(v => v ? v.set('loading', false) : v),
        );
      case A.getSharedByMeAppendPageAction.request:
        return state.apply(
          State.$byMePagination.update(v => v.set('loading', true)),
        );
      case A.getSharedByMeAction.failure:
        return state.apply(
          State.$byMePagination.update(v => v ? v.set('loading', false) : v),
        );
      case A.getSharedByMeAction.request:
        return state.apply(
          State.$byMePagination.parsed({ loading: true }),
        );
      case A.getSharedByMeAppendPageAction.success:
        return state.apply(
          State.$byMe.update(
            SharedByMe.$list.parsedBy('concat', action.payload.content),
          ),
          State.$byMePagination.parsed(action.payload),
        );
      case A.getSharedByMeAction.success:
        return state.apply(
          State.$byMe.update(
            SharedByMe.$list.parsed(action.payload.content),
          ),
          State.$byMePagination.parsed(action.payload),
        );
      case A.removeShareAction.type:
        return state.apply(
          State.$byMe.update(
            SharedByMe.$list.filter(
              item => item.get('id') !== action.payload.id,
            ),
          ),
          State.$withMe.update(
            SharedWithMe.$list.filter(
              item => item.get('id') !== action.payload.id,
            ),
          ),
        );
      case A.removeMeFromShareAction.type:
        return state.apply(
          State.$withMe.update(
            SharedWithMe.$list.filter(
              item => item.get('id') !== action.payload.id,
            ),
          ),
        );

      case A.getSharedArtworkAction.request:
        return State.$artwork.clear()(state);

      case A.getSharedArtworkAction.success:
        return State.$artwork.parsed(action.payload)(state);

      case orderRtvAction.success:
        return state.update(State.$artwork.update(w => {
          return w && w.id === action.requestData.artworkId ? w.apply(
            Artwork.$rtvPrice.parsed(action.payload.rtvPrice),
            Artwork.$rtvCurrency.parsed(action.payload.rtvCurrency),
            Artwork.$rtvStatus.parsed(action.payload.artwork.rtvStatus),
            Artwork.$purchasedDate.parsed(action.payload.purchasedDate),
          ) : w;
        }));

      case A.sharedArtworkAppendPageAction.failure:
        return state.update(
          State.$artworksPagination.update(v => v ? v.set('loading', false) : v),
        );
      case A.sharedArtworkAppendPageAction.request:
        return state.update(
          State.$artworksPagination.update(v => v.set('loading', true)),
        );
      case A.getSharedArtworkListAction.failure:
        return state.apply(
          State.$artworksPagination.update(v => v ? v.set('loading', false) : v),
        );
      case A.getSharedArtworkListAction.request:
        return state.apply(
          State.$artworks.clear(),
          State.$artworksPagination.parsed({ loading: true }),
        );
      case A.sharedArtworkAppendPageAction.success:
        return state.apply(
          State.$artworks.parsedBy('concat', action.payload.content),
          State.$artworksPagination.parsed(action.payload),
        );
      case A.getSharedArtworkListAction.success:
        return state.apply(
          State.$artworks.parsed(action.payload.content),
          State.$artworksPagination.parsed(action.payload),
        );
      case A.getSharedGalleriesAction.success:
        return State.$galleries.parsed(action.payload.content)(state);
      case A.getShareAction.request:
        return State.$share.clear()(state);
      case A.getShareAction.success:
        return State.$share.parsed(action.payload)(state);
      case A.removeEmailsFromShareAction.success:
        return state.apply(
          $updateSharedByMeItemIfExists(Share.parse(action.payload)),
        );
      case A.sortSharedByMeAction.type:
        return state.apply(
          State.$byMeSorting.set(action.payload.sort),
        );
      case A.sortSharedWithMeAction.type:
        return state.apply(
          State.$withMeSorting.set(action.payload.sort),
        );
      case A.saveShareAction.success:
        return State.$share.parsed(action.payload)(state);

      default:
        return state;
    }
  },
};
