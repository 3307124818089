// /* global hbspt */
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'cpcs-reconnect';
import { compose } from 'redux';
import I from 'immutable';
import cx from 'classnames';
import CardForm from 'pages/billingPage/form/cardForm';
import {
  buyAnalyticsPopupOpenedSelector,
  buyAnalyticsPopupArtworkSelector,
  submittingSelector,
  gettingFreeSelector,
  selectedProduct_sel,
  lastPaidArtistReport_sel,
} from 'pages/common/buyAnalytics/buyAnalyticsModel';
import { isFormValidSel, stripeCardToken_sel } from 'pages/billingPage/form/cardFormModel';
import { resetCardFormAction } from 'pages/billingPage/form/cardFormActions';
import {
  hideAnalyticsFormAction,
  buyAnalyticsFormSubmitAction,
  showAnalyticsSubscriptionTypeAction,
} from 'pages/common/buyAnalytics/buyAnalyticsActions';
import { fullName, life } from 'domain/artist/helpers';
import {
  user_sel,
} from 'domain/env/EnvModel';
import { USAIdSelector } from 'domain/country/CountryModel';
import BillingForm from 'pages/common/buyAnalytics/billingForm';
import { setPropTypes } from 'lib/helpers';
import { extraFooterGap_sel } from 'domain/ui/UIModel';
import { planTypesLoading_sel } from 'pages/upgradeSubscriptionPage/upgradeSubscriptionModel';
import ReportOption from 'pages/common/buyAnalytics/reportOption';

import injectSheet from 'lib/sheet';
import sheet from './sheet';

const ArtistInfo = compose(
  injectSheet(sheet),
  setPropTypes({
    classes: PropTypes.shape({
      ArtistInfo: PropTypes.string.isRequired,
      artistName: PropTypes.string.isRequired,
      artworkTitle: PropTypes.string.isRequired,
    }).isRequired,
    title: PropTypes.node,
    fullName: PropTypes.node,
  }),
)(({ classes, artwork }) => {
  // if (!fullName) return null;
  return (
    <div className={classes.ArtistInfo}>
      <div className={classes.analyticsArtistInfo}>
        <p
          className={classes.analyticsArtistName}
          children={fullName(artwork)}
        />
        <p
          className={classes.analyticsArtistLifeDates}
          children={`(${life(artwork)})`}
        />
      </div>

      <div className={classes.analyticsItem}>
        <span className={classes.analyticsListTitle} children="Artist Analytics"/>
        <ul className={classes.analyticsList}>
          <li className={classes.analyticsListItem}>Financial Performance & Metrics</li>
          <li className={classes.analyticsListItem}>Market Movements</li>
          <li className={classes.analyticsListItem}>Artist Rankings</li>
          <li className={classes.analyticsListItem}>ARTBnk Indexes</li>
        </ul>
      </div>
      <div className={classes.analyticsItem}>
        <span className={classes.analyticsListTitle} children="Artist Report"/>
        <ul className={classes.analyticsList}>
          <li className={classes.analyticsListItem}>Download an in-depth analytical report on the artist market performance.</li>
        </ul>
      </div>
    </div>
  );
});

const SuccessBlock = compose(
  injectSheet(sheet),
  setPropTypes({
    classes: PropTypes.shape({
      ArtistInfo: PropTypes.string.isRequired,
      artistName: PropTypes.string.isRequired,
      artworkTitle: PropTypes.string.isRequired,
    }).isRequired,
    title: PropTypes.node,
    fullName: PropTypes.node,
  }),
)(({ classes, onClose }) => {
  return (
    <div className={classes.SuccessBlock}>
      <p className={classes.successTitle} children="Artist Analytics Purchased"/>
      <div className={classes.successImage}/>
      <a onClick={onClose} className={classes.successLink} children="Continue to artist page" />
    </div>
  );
});

function HubspotForm() {
  const temp = useRef();
  const ref = (node) => temp.current = node;
  const node = temp.current;
  useEffect(() => {
    if (!temp.current) return;
    const script =
      `hbspt.forms.create({
        region: 'na1',
        portalId: '3795930',
        formId: '815f3bfb-1b74-44a3-acc0-c9dd44d756fd',
        version: 'V2_PRERELEASE',
      });
    `;
    const scriptNode = document.createElement('script');
    scriptNode.innerHTML = script;
    temp.current.appendChild(scriptNode);
  }, [node]);
  return <div ref={ref}/>;
}

class BuyAnalytics extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.shape({
      BuyAnalytics: PropTypes.string.isRequired,
      overlay: PropTypes.string.isRequired,
      sidebar: PropTypes.string.isRequired,
      buyAnalyticsSidebar: PropTypes.string.isRequired,
      header: PropTypes.string.isRequired,
      backToPlans: PropTypes.string.isRequired,
      closeBtn: PropTypes.string.isRequired,
      backBtn: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired,
      titleWrap: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      titleVal: PropTypes.string.isRequired,
      productSelectionWrapper: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      btnConfirmAnalytics: PropTypes.string.isRequired,
      loadingOverlay: PropTypes.string.isRequired,
      loadingTxt: PropTypes.string.isRequired,
      spinner: PropTypes.string.isRequired,
      subtext: PropTypes.string.isRequired,
      hubspotWrp: PropTypes.string.isRequired,
    }).isRequired,
    stripeCardToken: PropTypes.string,
    isVisible: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    user: PropTypes.instanceOf(I.Record).isRequired,
    USA_ID: PropTypes.number,
    artwork: PropTypes.instanceOf(I.Record),
    submitInProgress: PropTypes.bool,
    getFreeSubmitInProgress: PropTypes.bool,
    onBuyAnalyticsFormSubmit: PropTypes.func.isRequired,
    cardFormValid: PropTypes.bool,
    resetCardForm: PropTypes.func.isRequired,
    extraFooterGap: PropTypes.bool,
    // React.Component or function
    // React.Component or function
    CardForm: PropTypes.any,
    // React.Component or function
    BillingForm: PropTypes.any,
    lastPaidArtistReport: PropTypes.number,
  };

  static defaultProps = {
    CardForm,
    BillingForm,
  };

  state = { showAnalyticsSubscriptionType: false, reportType: '' };

  onShowSubscriptionTypes = () => {
    this.setState({ showAnalyticsSubscriptionType: true });
  };

  onBackClick = () => {
    if (this.state.reportType) {
      this.setState({ reportType: '' });
    } else if (this.state.showAnalyticsSubscriptionType) {
      this.setState({ showAnalyticsSubscriptionType: false });
    }
  }

  onClose = () => {
    const { onClose, resetCardForm } = this.props;
    resetCardForm();
    this.setState({ showAnalyticsSubscriptionType: false, reportType: '' });
    onClose();
  }

  selectReportType = (title) => {
    this.setState({ reportType: title });
  };

  onBuyAnalyticsFormSubmit = (payload) => {
    const { onBuyAnalyticsFormSubmit, artwork } = this.props;
    let artistId = artwork.get('id');
    onBuyAnalyticsFormSubmit({ ...payload, artistId });
  };

  render() {
    const {
      classes, isVisible, artwork, USA_ID, user,
      submitInProgress, stripeCardToken,
      getFreeSubmitInProgress,
      extraFooterGap,
      CardForm, BillingForm, lastPaidArtistReport,
    } = this.props;
    // if (!isVisible || !artwork || !user || !subscription) return null;
    if (!isVisible) return null;
    const billingFormInitialValues = {
      email: user.email,
      code: user.code,
      country: user.country || USA_ID,
      saveCard: true,
    };
    return (
      <div
        className={classes.BuyAnalytics}
      >
        <div className={classes.overlay} onClick={this.onClose} />
        <div className={cx(classes.sidebar, classes.buyAnalyticsSidebar, { extraFooterGap })}>
          <div className={classes.header}>
            {
              this.state.showAnalyticsSubscriptionType && lastPaidArtistReport !== artwork.get('id') &&
                <button
                  type="button"
                  className={classes.backBtn}
                  onClick={this.onBackClick}
                />
            }
            <button
              type="button"
              className={classes.closeBtn}
              onClick={this.onClose}
            />
          </div>
          <div className={classes.content}>
            <div className={classes.titleWrap}>
              <span
                className={classes.title}
                children="Artist Analytics"
              />
            </div>
            {
              !this.state.showAnalyticsSubscriptionType && lastPaidArtistReport !== artwork.get('id') &&
              <ArtistInfo
                artwork={artwork}
              />
            }
            {
              !this.state.showAnalyticsSubscriptionType && lastPaidArtistReport !== artwork.get('id') &&
                <p className={classes.subtext} children="Access to the artist analytics and pdf report expires 3 months from the purchase date."/>
            }
            { this.state.showAnalyticsSubscriptionType && lastPaidArtistReport !== artwork.get('id') && this.state.reportType !== 'Enterprise' &&
              <div className={classes.productSelectionWrapper}>
                <p>Make a one time purchase, or contact us to discuss a custom solution that meets your Enterprise needs.</p>
                <ReportOption
                  onSelectReportType={this.selectReportType}
                  title="Single Report"
                  description="Artist Analytics &  Report"
                  price="500$"
                  active={this.state.reportType === 'Single Report'}
                />
                {
                  this.state.reportType !== 'Single Report' &&
                    <ReportOption
                      onSelectReportType={this.selectReportType}
                      title="Enterprise"
                      description="Custom solution that meets your Enterprise needs"
                      price="Contact us"
                      active={this.state.reportType === 'Enterprise'}
                    />
                }
              </div>
            }
            { this.state.showAnalyticsSubscriptionType && lastPaidArtistReport !== artwork.get('id') && this.state.reportType === 'Single Report' &&
              <CardForm
                title="Card info"
                titleModifier="analyticsForm"
                changeCardBtnModifier="buyForm"
                autoSubmit
                hideActionButtons
              />
            }
            { this.state.showAnalyticsSubscriptionType && lastPaidArtistReport !== artwork.get('id') && this.state.reportType === 'Single Report' &&
              <BillingForm
                initialValues={billingFormInitialValues}
                stripeCardToken={stripeCardToken}
                cardFormValid={this.props.cardFormValid}
                submitInProgress={submitInProgress}
                onFormSubmit={this.onBuyAnalyticsFormSubmit}
                titleModifier="analyticsForm"
              />
            }
            {
              !this.state.showAnalyticsSubscriptionType && lastPaidArtistReport !== artwork.get('id') &&
              <button
                type="button"
                children="Purchase Analytics"
                className={classes.btnConfirmAnalytics}
                onClick={this.onShowSubscriptionTypes}
              />
            }
            {
              this.state.reportType === 'Enterprise' &&
                <div className={classes.hubspotWrp}>
                  <HubspotForm/>
                </div>
            }
            {/*
              !this.hasFreeRtvs &&
                <StripeLogo />
            */}
            { lastPaidArtistReport === artwork.get('id') &&
              <SuccessBlock onClose={this.onClose}/>
            }
          </div>
          {/*
            (couponInValidation || planTypesLoading) &&
              <div className={classes.loadingOverlay}>
                <div children={couponInValidation ? 'Checking...' : 'Loading...'} className={classes.loadingTxt} />
                <div className={classes.spinner} />
              </div>
          */}
          {
            getFreeSubmitInProgress &&
              <div className={classes.loadingOverlay}>
                <div className={classes.spinner} />
              </div>
          }
        </div>
      </div>
    );
  }
}

export const PureBuyAnalytics = BuyAnalytics;

export default compose(
  injectSheet(sheet),
  connect({
    isVisible: buyAnalyticsPopupOpenedSelector,
    artwork: buyAnalyticsPopupArtworkSelector,
    onClose: hideAnalyticsFormAction,
    user: user_sel,
    USA_ID: USAIdSelector,
    submitInProgress: submittingSelector,
    planTypesLoading: planTypesLoading_sel,
    showAnalyticsSubscriptionTypeAction: showAnalyticsSubscriptionTypeAction,
    getFreeSubmitInProgress: gettingFreeSelector,
    onBuyAnalyticsFormSubmit: buyAnalyticsFormSubmitAction,
    cardFormValid: isFormValidSel,
    resetCardForm: resetCardFormAction,
    extraFooterGap: extraFooterGap_sel,
    selectedProductName: selectedProduct_sel,
    stripeCardToken: stripeCardToken_sel,
    lastPaidArtistReport: lastPaidArtistReport_sel,
  }),
)(BuyAnalytics);
