import 'svg2pdf.js';
import 'html2canvas';
import { jsPDF } from 'jspdf';
import moment from 'moment';
import {
  EVENT_APPEND_TO_PDF,
  EVENT_LIST_RENDER_PROPS,
  EVENT_RENDER_TO_PDF,
  PAGE_WIDTH,
  PAGE_HEIGHT,
  CONTENT_TYPE_CUSTOM,
  PDF_FONT_NAME,
  PDF_FONT_FILE_NORMAL,
  PDF_FONT_FILE_MEDIUM,
  PDF_FONT_FILE_BOLD,
  EVENT_PDF_GENERATED,
  EVENT_PREVIEW_CLOSED,
} from 'pages/highcharts/helpers/PDFConstants';
import { placeItemsToPages } from 'pages/highcharts/helpers/placeItemsToPages';
import { getKey, sortPDFComponents } from 'pages/highcharts/helpers/helpers';

const log = (str) => global.ARTBnkLogger.dispatch(str);

export const getCreatePDF = PDFEvents => async ({ artist, artwork } = {}) => {
  const renderedItems = PDFEvents.dispatch(EVENT_LIST_RENDER_PROPS, { collect: true })
    .sort(sortPDFComponents);
  let closed = false;
  log('PDFGeneration');
  const onClose = () => {
    log('PDFGeneration onClose listener');
    closed = true;
  };
  PDFEvents.addEventListener(EVENT_PREVIEW_CLOSED, onClose, { once: true });
  // eslint-disable-next-line no-unused-vars
  const { pages, items, contentSections } = placeItemsToPages(renderedItems);
  const pdf = new jsPDF({ format: [PAGE_WIDTH, PAGE_HEIGHT], unit: 'px', orientation: 'p' });
  // fontStyle italic | normal; fontWeight: bold | bolder | lighter | normal
  // pdf.addFont(postScriptName, id, fontStyle, fontWeight, encoding)
  // doc.addFont("test/reference/MouhitsuBold.ttf", "Mouhitsu", "bold");
  pdf.addFont(PDF_FONT_FILE_NORMAL, PDF_FONT_NAME, 'normal', 'normal');
  pdf.addFont(PDF_FONT_FILE_MEDIUM, PDF_FONT_NAME, 'normal', '500');
  pdf.addFont(PDF_FONT_FILE_BOLD, PDF_FONT_NAME, 'normal', 'bold');
  pdf.setFont('Haas Grot Disp Web');
  const pageSVGs = document.getElementsByClassName('PagePDF');
  let index = -1;
  for (let page of pages) {
    log('PDFGeneration page', page);
    if (closed) return;
    index++;
    const svgElement = pageSVGs[index];
    if (index !== 0) {
      // add page
      pdf.addPage([PAGE_WIDTH, PAGE_HEIGHT], 'p');
    }
    // add page bg
    await pdf.svg(svgElement, { width: PAGE_WIDTH, height: PAGE_HEIGHT, x: 0, y: 0 });
    // add components
    const renderedPageItems = renderedItems.filter(item => {
      const itemRenderProps = items[getKey(item)];
      if (!itemRenderProps) {
        console.error('no item props', item);
        return false;
      }
      if (itemRenderProps.page !== page.page) {
        return false;
      }
      return true;
    });
    for (let item of renderedPageItems) {
      if (closed) return;
      const itemRenderProps = items[getKey(item)];
      log(`PDFGeneration item ${getKey(item)}`);
      // add component
      // check type to add to PDF
      // await PDFEvents.dispatch(EVENT_RENDER_TO_PDF, { pdf, item, itemRenderProps, page });
      switch (item.contentType) {
        case CONTENT_TYPE_CUSTOM:
          await PDFEvents.dispatch(EVENT_RENDER_TO_PDF, { pdf, item, itemRenderProps, page });
          break;
        // case CONTENT_TYPE_HTML:
        //   await PDFEvents.dispatch(EVENT_RENDER_TO_PDF, {
        //     pdf, name: item.name, left: itemRenderProps.itemLeft, top: itemRenderProps.itemTop, page });
        //   break;
        default:
          await pdf.svg(item.node, {
            width: item.width, height: item.height, x: itemRenderProps.itemLeft, y: itemRenderProps.itemTop });
        break;
      }
      await PDFEvents.dispatch(EVENT_APPEND_TO_PDF, { pdf, item, itemRenderProps, page });
    }
  }
  // pdf.output('pdfobjectnewwindow');
  let fileName = [];
  if (artwork) {
    fileName = [
      'ARTBnk Artwork Report',
      artwork.title || '',
    ];
  } else if (artist) {
    fileName = [
      'ARTBnk Artist Report',
      (artist && artist.fullName) || '',
    ];
  } else {
    
  }
  fileName = [
    ...fileName,
    moment().format('MMMM YYYY'),
  ].filter(Boolean).join(' - ');
  if (closed) return;
  pdf.save(fileName.includes('.') ? `${fileName}.pdf` : fileName);
  PDFEvents.dispatch(EVENT_PDF_GENERATED);
};
