import { ROBOTO_FONTFAMILY } from 'theme/theme';
import bg from './images/bg.jpg';
import mobile_bg from './images/mobile_bg.jpg';
import logo from './images/logo.png';
export default {
  CreatePassword: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    '@media screen and (max-width: 900px)': {
      display: 'block',
    },
  },
  createPasswordContent: {
    display: 'flex',
    width: 1440,
    margin: '0px auto',
    '@media screen and (max-width: 900px)': {
      width: '100%',
      flexDirection: 'column',
    },
  },
  logo: {
    width: 263,
    height: 141,
    background: `url(${logo}) left top no-repeat`,
  },
  createPasswordText: {
    margin: [50, 0, 45, 0],
    fontFamily: ROBOTO_FONTFAMILY,
    fontSize: 18,
    fontWeight: 900,
    textTransform: 'uppercase',
    '@media screen and (max-width: 900px)': {
      margin: [30, 0, 25, 0],
      width: 207,
      textAlign: 'center',
    },
  },
  expired: {
    margin: [85, 0, 20, 0],
  },
  separator: {
    height: 5,
    width: 121,
    background: '#E5E5E5',
  },
  regularText: {
    width: 365,
    margin: [35, 0, 0, 0],
    textAlign: 'center',
    '@media screen and (max-width: 900px)': {
      fontSize: 12,
      fontWeight: 400,
      width: 181,
    },
  },
  link: {
    color: '#000000',
  },
  createPasswordForm: {
    display: 'flex',
    paddingTop: 166,
    alignItems: 'center',
    width: '50%',
    flexDirection: 'column',
    '@media screen and (max-width: 900px)': {
      width: '100%',
      paddingTop: 70,
    },
  },
  createPasswordImage: {
    width: '50%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    background: `url(${bg}) center top no-repeat`,
    '@media screen and (max-width: 900px)': {
      width: '100%',
      height: 454,
      marginTop: 80,
      margin: '0px auto',
      backgroundPosition: 'center',
      background: `url(${mobile_bg}) center top no-repeat`,
    },
  },
  pageTitle: {
    marginTop: 196,
    width: 475,
    color: '#fff',
    fontSize: 34,
    fontWeight: 700,
    textAlign: 'center',
    '@media screen and (max-width: 900px)': {
      marginTop: 135,
      width: 289,
      fontSize: 20,
    },
  },
  pageText: {
    marginTop: 150,
    width: 526,
    color: '#fff',
    fontSize: 22,
    fontWeight: 400,
    textAlign: 'center',
    '@media screen and (max-width: 1024px)': {
      width: 460,
    },
    '@media screen and (max-width: 900px)': {
      marginTop: 50,
      width: 234,
      fontSize: 13,
    },
  },
  wrapper: {
  },
  inputDescription: {
    marginTop: 10,
    fontSize: 12,
    fontFamily: 'Roboto',
    fontWeight: 400,
    textTransform: 'uppercase',
  },
  newPassword: {
    border: '2px solid #000000',
  },
  inputWrp: {
    display: 'flex',
    alignItems: 'center',
  },
  btnWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    width: 253,
    height: 44,
    marginTop: 45,
    color: '#070707',
    fontSize: 13,
    fontFamily: 'Roboto',
    fontWeight: 900,
    textTransform: 'uppercase',
    background: '#E5E5E5',
    border: '2px solid rgba(52, 152, 153, 0.6)',
    borderRadius: 6,
    '@media screen and (max-width: 900px)': {
      fontSize: 10,
      width: 142,
    },
  },
  ConfirmationBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  TokenExpiredBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
};
