import React from 'react';
import PropTypes from 'prop-types';
import I from 'immutable';
import cx from 'classnames';
import Header from './header';
import Badge from 'pages/common/ItemList/Badges/Artwork';
import { lnk } from 'lib/routes';

import injectSheet from 'lib/sheet';
import { sheet } from './sheet';

class Item extends React.PureComponent {
  static propTypes = {
    alert: PropTypes.instanceOf(I.Record),
    classes: PropTypes.shape({
      Item: PropTypes.string,
      artWorkList: PropTypes.string,
      artwork: PropTypes.string,
      noArtworks: PropTypes.string,
    }).isRequired,
    modifier: PropTypes.string,

  };

  buildLink = (artwork) =>
    lnk('artworkPage', {
      // alertId: this.props.alert.id,
      artworkId: artwork.get('id'),
    });

  render() {
    const { classes, alert, modifier } = this.props;
    const lots =  alert.getIn(['lots', 'content'], new I.List());
    return (
      <li className={classes.Item}>
        <Header
          title={alert.get('title')}
          id={alert.getIn(['artist', 'id'])}
          seeAll={lots.size}
          total={alert.getIn(['lots', 'total'])}
          modifier={modifier}
        />
        <ul className={cx(classes.artWorkList, 'favoriteArtistsPage')}>
          {
            lots.map((lot, i) =>
              <li
                key={`${lot.get('id')}-${i}`}
                className={classes.artwork}
              >
                <Badge
                  artwork={lot}
                  linkBuilder={this.buildLink}
                  modifier="DiscoverPage"
                />
              </li>,
            )
          }
        </ul>
        {
          lots.size === 0 ? (
            <div className={classes.noArtworks}>No lots found</div>
          ) : null
        }
      </li>
    );
  }
}

export default injectSheet(sheet)(Item);
