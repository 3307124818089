import checked from './images/checked.svg';
import checkHovered from './images/check-hovered.svg';
import unselectIco from './images/x-mark.svg';
import plusMark from './images/plus-mark.svg';
import { DEFAULT_BUTTON_COLOR } from 'theme/theme';
import { SELECTABLE_ITEM_CONTROLS_Z_INDEX } from 'theme/zIndex';

export default {
  SelectableItemControls: {
    // root
    height: '100%',
    width: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    top: 0,
    left: 0,
    zIndex: SELECTABLE_ITEM_CONTROLS_Z_INDEX,
    '& .selectable-item__select-button': {
      display: 'block',
      width: 46,
      height: 46,
      position: 'absolute',
      right: 0,
      top: 0,
      pointerEvents: 'all',
      cursor: 'pointer',
    },
    '& .selectable-item__select-icon': {
      display: 'block',
      opacity: 0,
      width: 26,
      height: 26,
      margin: 10,
      borderRadius: '50%',
      boxShadow: '0 1px 2px rgba(0, 0, 0, 0.3)',
      background: {
        color: 'transparent',
        repeat: 'no-repeat',
        position: 'center center',
        image: `url("${checkHovered}")`,
      },
      '&.selectIconSelected': {
        opacity: 0.9,
        backgroundImage: `url("${checked}")`,
      },
      '&.text': {
        backgroundImage: 'none',
        backgroundColor: '#454A5F',
        color: '#FFF',
        fontSize: '18px',
        lineHeight: '26px',
        textAlign: 'center',
      },
    },
    '@media screen and (max-width: 1439px)': {
      '& .selectable-item__select-icon': {
        opacity: 0.5,
      },
    },
  },
  SelectableItemControlsActive: {
    pointerEvents: 'auto',
    cursor: 'pointer',
    '&.hideSelectOverlay': {
      cursor: 'default',
    },
  },
  '@global .selectable-item:hover .selectable-item__select-icon': {
    opacity: 1,
  },
  '@global .selectable-item.available-comparable .selectable-item__select-icon': {
    opacity: 1,
    backgroundImage: `url("${plusMark}")`,
    fontSize: '0.5px',
    textIndent: '30px',
    backgroundColor: 'transparent',
  },
  '@global .selectable-item.available-comparable .selectable-item__select-icon.disabled': {
    opacity: 0,
  },
  '@global .selectable-item.available-comparable .selectable-item__select-icon.selectIconSelected.text': {
    textIndent: 0,
    backgroundImage: 'none',
    backgroundColor: '#454A5F',
    color: '#FFF',
    fontSize: '18px',
    lineHeight: '26px',
    textAlign: 'center',
    userSelect: 'none',
  },
  '@global .selectable-item.available-comparable:hover .selectable-item__select-icon.selectIconSelected:not(.justSelected)': {
    backgroundImage: `url("${unselectIco}")`,
    fontSize: '0.5px',
    textIndent: '30px',
    backgroundColor: 'transparent',
  },
  '@global .badge-menu': {
    display: 'none',
    position: 'absolute',
    right: 0,
    backgroundColor: DEFAULT_BUTTON_COLOR,
    opacity: 0.7,
    pointerEvents: 'all',
  },
  '@global .selectable-item:hover .badge-menu': {
    display: 'flex',
  },
  selectedOverlay: {
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%',
    pointerEvents: 'none',
    border: 'solid 2px #2e343e',
  },
  selectionOverlay: {
    height: '100%',
  },
};
