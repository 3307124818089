import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect as cpConnect } from 'cpcs-reconnect';
import { connect } from 'react-redux';
import I from 'immutable';
import { reduxForm, Field, getFormValues, touch as touchAction } from 'redux-form';

import { token_sel } from 'domain/env/EnvModel';

import FormRowField from 'pages/common/newPage/form/elements/FormRowField';
import { Chart } from 'components/reports/Chart/Chart';
import Select from 'components/form/select';

import injectSheet from 'lib/sheet';

const sheet = {
  LiquidityIndexes: {
    //
  },
  selectWrapper: {
    display: 'none',
    width: 'calc(100% - 30px)',
    marginBottom: 16,
    marginLeft: 20,
  },
};

// const defaultSeries = ['ACTIVE ARTISTS', 'LIQUID ARTISTS'];
const defaultSeries = ['All Artists', 'Liquid Artists', 'Active Artists', 'Frequent Artists', 'Infrequent Artists', 'Illiquid Artists'];

function LiquidityIndexes({ classes, artworkId, artistId, token, form, data, onTouch, ...props }) {
  const [series, setSeries] = useState(defaultSeries);
  const [allSeries, setAllSeries] = useState([]);
  const onResponseSuccess = useCallback(({ series: respSeries }) => {
    const newAllSeries = respSeries.map(v => (I.fromJS({ id: v.name, title: v.name })));
    setAllSeries(newAllSeries);
  }, [setAllSeries]);
  const onSelect = useCallback((list) => {
    if (list.length === 0) {
      setSeries(defaultSeries);
    } else {
      setSeries(list);
    }
  }, [setSeries]);
  const updateOptions = useCallback(({ options }) => {
    const rows = options.series
      .filter(v => series.includes(v.name))
      /* fix highchart bug (it modifies passed series) */
      .map(row => ({ ...row, data: [ ...row.data ] }));
    return { ...options, series: rows };
  }, [series]);
  const chartUpper = (
    <div className={classes.selectWrapper}>
      <Field
        name="series"
        component={FormRowField}
        Field={Select}
        list={allSeries}
        onChange={onSelect}
        isClearable
        isSearchable={false}
        isMulti
        placeholder="Select Indexes to compare"
        modifier="LiquidityIndexes"
        rootTag="div"
      />
    </div>
  );
  return (
    <div className={classes.LiquidityIndexes}>
      <Chart
        chartId="x001"
        artworkId={artworkId}
        artistId={artistId}
        updateOptionsTrigger={series}
        updateOptions={updateOptions}
        onResponseSuccess={onResponseSuccess}
        chartUpper={chartUpper}
        {...props}
      />
    </div>
  );
}

LiquidityIndexes.propTypes = {
  classes: PropTypes.shape({
    LiquidityIndexes: PropTypes.string.isRequired,
    selectWrapper: PropTypes.string.isRequired,
  }).isRequired,
  artworkId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  artistId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  token: PropTypes.string,
  form: PropTypes.string.isRequired,
  onTouch: PropTypes.func.isRequired,
  data: PropTypes.object,
  liquidity: PropTypes.shape({
    liquidity: PropTypes.string,
  }),
};


LiquidityIndexes = compose(
  cpConnect({
    token: token_sel,
    onTouch: touchAction,
  }),
  injectSheet(sheet),
  reduxForm({
    form: 'LiquidityIndexes',
    initialValues: {
      series: defaultSeries,
    },
  }),
  connect(state => ({
    data: getFormValues('LiquidityIndexes')(state),
  })),
)(LiquidityIndexes);

export { LiquidityIndexes };
