import {
  DEFAULT_BUTTON_COLOR,
  DEFAULT_FONTFAMILY,
  DEFAULT_TEXT_COLOR,
  ALERT_LIST_SEE_ALL,
  DARK_BLUE_COLOR,
  DIVIDER_COLOR,
  SEE_ALL_HOVER_COLOR,
} from 'theme/theme';
import { FOCUSED_STYLE_OFFSET } from 'theme/focus';
import { loader } from 'theme/loader';
import { sheet as alerPageStyles } from 'pages/favoriteArtistsPage/sheet';
import { responsiveListFullPage } from 'theme/layout/responsiveList';

const { total, totalStart, totalEnd } = alerPageStyles;

const AlertList = {
  Item: {
    margin: '0px auto 30px',
    padding: [0, 0, 30, 0],
    borderBottom: `1px solid ${DIVIDER_COLOR}`,
  },
  link: {
    color: DEFAULT_BUTTON_COLOR,
    fontSize: 14,
    fontWeight: 400,
    textDecoration: 'none',
    marginLeft: 20,
    paddingBottom: 2,
  },
  seeAll: {
    color: ALERT_LIST_SEE_ALL,
    fontSize: 12,
    fontWeight: 600,
    letterSpacing: 1,
    textTransform: 'uppercase',
    textDecoration: 'none',
    whiteSpace: 'nowrap',
    '&.AlertsList': {
      marginLeft: 10,
      textDecoration: 'underline',
      fontWeight: 400,
      '&:hover': {
        color: SEE_ALL_HOVER_COLOR,
      },
    },
  },
  noArtworks: {
    opacity: 0.3,
    color: DARK_BLUE_COLOR,
    fontSize: 20,
    fontWeight: 600,
    textAlign: 'center',
  },
};

const ArtWorkList = {
  artWorkList: responsiveListFullPage,
  artwork: {
    minWidth: 0,
  },
};

const PageItems = {
  AlertsList: {},
  header: {
    display: 'flex',
    paddingBottom: 8,
    marginBottom: 20,
    alignItems: 'center',
    position: 'relative',
    '@media screen and (max-width: 575px)': {
      flexWrap: 'wrap',
    },
  },
  title: {
    width: '60%',
  },
  titleLink: {
    color: DEFAULT_TEXT_COLOR,
    fontSize: 20,
    fontWeight: 500,
    // fix text overlay
    display: 'block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    '&:focus': {
      ...FOCUSED_STYLE_OFFSET,
    },
  },
  remove: {
    extend: 'seeAll',
    color: DEFAULT_BUTTON_COLOR,
    cursor: 'pointer',
    outline: 'none',
    fontFamily: DEFAULT_FONTFAMILY,
  },
  buttonsBox: {
    marginLeft: 'auto',
  },
  total: {
    ...total,
    textDecoration: 'none',
  },
  totalStart,
  totalEnd,
  loaderContainer: {
    width: '100%',
    marginTop: 100,
    position: 'relative',
  },
};

export const sheet = {
  ...PageItems,
  ...AlertList,
  ...ArtWorkList,
  ...loader,
};
