import React from 'react';
import PropTypes from 'prop-types';
import I from 'immutable';
import { Field, reduxForm, SubmissionError } from 'redux-form';
import FormRowField from 'pages/common/newPage/form/elements/FormRowField';
import { editGalleryAction } from 'domain/galleries/GalleryActions';
import rules, { createErrorMessage } from 'components/form/validation';
import { Buttons } from 'pages/common/popupForms/element/Buttons';

import injectSheet from 'lib/sheet';
import sheet from './sheet';


function submit(data, dispatch, props) {
  const submitPromise = new Promise((resolve, reject) => {
    dispatch({
      type: editGalleryAction.type,
      payload: { galleryId: props.item.get('id'), data },
      resolve,
      reject,
    });
  });

  return submitPromise.catch((err) => {
    if (!err.response.data.errors) {
      throw new SubmissionError({
        title: err.response.data.message,
        _error: err.response.data.message,
      });
    }
    const { errors, message } = err.response.data;
    throw new SubmissionError({
      ...createErrorMessage(errors),
      _error: message,
    });
  });
}

const errorMessages = I.fromJS({
  title: {
    Size: 'Title should be not less than 2 chars',
  },
});

class RenameForm extends React.PureComponent {
  render() {
    const { classes, cancel } = this.props;
    return (
      <form
        onSubmit={this.props.handleSubmit(submit)}
        className={classes.RenameForm}
      >
        <Field
          type="text"
          name="title"
          component={FormRowField}
          modifier="Gallery-Title"
          rootTag="div"
          validate={rules.required}
          errorMessages={errorMessages}
        />
        <Buttons modifier="Popup-RenameGallery" cancel={cancel} submit="DONE" />
      </form>
    );
  }
}

RenameForm.propTypes = {
  classes: PropTypes.shape({
    RenameForm: PropTypes.string.isRequired,
  }).isRequired,
  cancel: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'rename',
})(injectSheet(sheet)(RenameForm));
