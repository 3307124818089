import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import I from 'immutable';
import injectSheet from 'lib/sheet';
import styles from './sheet';
import { imgUrl } from 'lib/helpers';

const width = 230;
const height = 200;
const padding = 5;
const maxSelected = 10;

class BaseLayer extends PureComponent {
  static propTypes = {
    classes: PropTypes.shape({
      thumb: PropTypes.string,
      container: PropTypes.string,
      badge: PropTypes.string,
    }).isRequired,
    item: PropTypes.object,
    imagePath: PropTypes.array,
    list: PropTypes.shape({
      get: PropTypes.func.isRequired,
      has: PropTypes.func.isRequired,
    }),
    selected: PropTypes.instanceOf(I.Set),
  };

  static defaultProps = {
    imagePath: ['picture'],
  }

  getThumbStyle = (data) => {
    const { imagePath } = this.props;
    if (!data.getIn(imagePath, '')) return {};
    const picture = imgUrl(data.getIn(imagePath, ''), { width, height, cover: true });
    return picture.length ? { backgroundImage: `url("${picture}")` } : { };
  }

  render() {
    const { classes, list, selected, item } = this.props;
    const items = selected.size > 0 ? selected.toList().filter(i => list.has(i)).slice(0, maxSelected): new I.List([item.id]);
    return (
      <div className={classes.container}>
        { items.map((item, index) => <div key={item}
          className={classes.thumb}
          style={{ ...this.getThumbStyle(list.get(item)), left: index * padding, top: index * padding, zIndex: maxSelected - index }}
        /> ) }
        { selected.size > 1 && <div className={classes.badge}>{selected.size}</div> }
      </div>
    );
  }
}

export default injectSheet(styles)(BaseLayer);
