import { action, asyncAction } from 'lib/actions';

export const fetchGalleriesAction = asyncAction('gallery/GET_LIST');
export const fetchGalleriesPrependAction = asyncAction('gallery/GET_LIST_PREPEND');

export const editGalleryAction = asyncAction('gallery/EDIT');

export const createGalleryAction = asyncAction('gallery/CREATE');

export const addArtworksAction = asyncAction('gallery/ADD_ARTWORKS');

export const setCoverAction = asyncAction('gallery/SET_COVER');

export const fetchGalleryAction = asyncAction('gallery/GET_SINGLE');

export const loadGalleriesBidirectionalAction = action('gallery/LOAD_BI_DIRECTIONAL');

export const clearGaleries = action('gallery/CLEAR');
