import React from 'react';
import PropTypes from 'prop-types';
import I from 'immutable';
import { connect } from 'cpcs-reconnect';
import { categoryList } from 'domain/category';
import { oneOfFieldHasValue_sel, category_sel } from 'pages/common/artworkForm/ArtworkFormModel';
import { Field } from 'redux-form';
import Fieldset from 'components/fieldset';
import { is2DEdition, is3DEdition, isEdition } from 'pages/common/newPage/form/artObject/helpers';
import CheckboxList from 'components/form/checkboxList';
import FormRowField from 'pages/common/newPage/form/elements/FormRowField';
import { strToMap, mapToStr } from 'lib/helpers';

const STAMP_VALUES = [
  { value: 'signature', label: 'Signature' },
  { value: 'studio', label: 'Studio' },
  { value: 'estate', label: 'Estate' },
];

const STAMP_VALUES_3D_EDITION = [
  { value: 'foundry', label: 'Foundry' },
];

const stampsNewValue = ({ listValue, item, value }) => ({ ...listValue, [item.value]: value });
const stampItemLabel = v => v.label;
const stampItemValue = ({ listValue, item }) => listValue[item.value];
const stampItemId = ({ item }) => item.value;

class Details extends React.PureComponent {
  static propTypes = {
    suggestionSelected: PropTypes.bool,
    categoryList: PropTypes.instanceOf(I.Collection),
    category: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    oneOfFieldHasValue: PropTypes.func.isRequired,
  };

  render() {
    const { categoryList, category, suggestionSelected, oneOfFieldHasValue } = this.props;
    const stampValues = [].concat(
      isEdition({ categoryList, category }) ? STAMP_VALUES : [],
      is3DEdition({ categoryList, category }) ? STAMP_VALUES_3D_EDITION : [],
    );
    return isEdition({ categoryList, category }) ?
      <Fieldset
        title="Details"
        opened={oneOfFieldHasValue('plate', 'stamps', 'foundry', 'studio', 'printer', 'publisher', 'inscription')}
      >
        {
          is2DEdition({ categoryList, category }) &&
            <Field
              name="plate"
              placeholder="Plate number"
              component={FormRowField}
              modifier="AOForm"
              title="Plate"
              disabled={suggestionSelected}
            />
        }
        {
          (stampValues.length > 0) &&
            <Field
              name="stamps"
              component={FormRowField}
              Field={CheckboxList}
              title="Stamp"
              list={stampValues}
              parse={mapToStr}
              format={strToMap}
              disabled={suggestionSelected}
              modifier="AOForm AOFormStamps"
              getLabel={stampItemLabel}
              getNewListValue={stampsNewValue}
              getCurrentValue={stampItemValue}
              getId={stampItemId}
            />
        }
        {
          is3DEdition({ categoryList, category }) &&
            <Field
              name="foundry"
              placeholder="Foundry"
              component={FormRowField}
              modifier="AOForm"
              title="Foundry"
              disabled={suggestionSelected}
            />
        }
        {
          is3DEdition({ categoryList, category }) &&
            <Field
              name="studio"
              placeholder="Studio"
              component={FormRowField}
              modifier="AOForm"
              title="Studio"
              disabled={suggestionSelected}
            />
        }
        {
          is2DEdition({ categoryList, category }) &&
            <Field
              name="printer"
              placeholder="Printer name"
              component={FormRowField}
              modifier="AOForm"
              title="Printer"
              disabled={suggestionSelected}
            />
        }
        {
          is2DEdition({ categoryList, category }) &&
            <Field
              name="publisher"
              placeholder="Publisher name"
              component={FormRowField}
              modifier="AOForm"
              title="Publisher"
              disabled={suggestionSelected}
            />
        }
        <Field
          name="inscription"
          placeholder="Inscription text"
          component={FormRowField}
          modifier="AOForm"
          title="Inscription"
          disabled={suggestionSelected}
        />
      </Fieldset> : null;
  }
}

export default connect({
  category: category_sel,
  categoryList,
  oneOfFieldHasValue: oneOfFieldHasValue_sel,
})(Details);
