import { DEFAULT_BUTTON_COLOR, DARK_BLUE_COLOR, DEFAULT_FONTFAMILY } from 'theme/theme';
import { LAYOUT_RANGE_1024_MAX } from 'theme/layout/LAYOUT';

export default {
  Suggestions: {
    marginTop: 20,
    // root,
    [`@media screen and (max-width: ${LAYOUT_RANGE_1024_MAX}px)`]: {
      // NEW_ART_PAGE
      display: 'none',
    },
  },
  componentTitle: {
    width: 101,
    fontFamily: DEFAULT_FONTFAMILY,
    fontSize: '12px',
    fontWeight: 500,
    letterSpacing: '2px',
    color: DARK_BLUE_COLOR,
    textTransform: 'uppercase',
    display: 'flex',
    alignItems: 'center',
    height: 26,
  },
  list: {
    paddingTop: 10,
  },
  item: {
    minHeight: 38,
    backgroundColor: '#FFF',
    boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.2)',
    marginTop: 6,
    '&:first-child': {
      marginTop: 0,
    },
    '&:hover, &:focus': {
      backgroundColor: DEFAULT_BUTTON_COLOR,
      outline: 'none',
    },
    '&:hover $authorName, &:focus $authorName': {
      color: '#FFF',
    },
    '&:hover $authorYears, &:focus $authorYears': {
      color: '#FFF',
    },
    '&:hover $workName, &:focus $workName': {
      color: '#FFF',
    },
    '&:hover $workCreatedYear, &:focus $workCreatedYear': {
      color: '#FFF',
    },
    '&:hover $authorVsWorkDelimiter, &:focus $authorVsWorkDelimiter': {
      color: '#FFF',
    },
    '&:active': {
      opacity: 0.95,
    },
  },
  thumb: {
    margin: 4,
    float: 'left',
  },
  itemDescription: {
    padding: [9, 10, 9, 0],
    cursor: 'default',
  },
  authorName: {
    fontFamily: DEFAULT_FONTFAMILY,
    fontSize: '13px',
    fontWeight: 500,
    textAlign: 'left',
    lineHeight: '15px',
    color: DARK_BLUE_COLOR,
  },
  authorYears: {
    extend: 'authorName',
    fontWeight: 400,
  },
  workName: {
    extend: 'authorName',
  },
  workCreatedYear: {
    extend: 'authorYears',
  },
  authorVsWorkDelimiter: {
    display: 'inline-block',
    fontSize: '8px',
    lineHeight: '15px',
    padding: [0, 4],
    verticalAlign: 'middle',
  },
  btn: {
    textAlign: 'left',
    width: '100%',
  },
  '@keyframes ring': {
    from: { transform: 'rotate(0deg)' },
    to: { transform: 'rotate(360deg)' },
  },
  loader: {
    display: 'inline-block',
    width: 26,
    height: 26,
    animation: '$ring 0.5s linear infinite',
    marginLeft: 10,
    '&:after': {
      content: '" "',
      display: 'block',
      width: '20px',
      height: '20px',
      margin: '1px',
      borderRadius: '50%',
      border: `2px solid ${DEFAULT_BUTTON_COLOR}`,
      borderColor: `${DEFAULT_BUTTON_COLOR} transparent ${DEFAULT_BUTTON_COLOR} transparent`,
    },
  },
};
