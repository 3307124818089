import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'cpcs-reconnect';
import I from 'immutable';
import { Field, FieldArray, reduxForm, startSubmit as startSubmitAction } from 'redux-form';
import FormRowField from 'pages/common/newPage/form/elements/FormRowField';
import Textarea from 'components/form/field/nodes/Textarea';
import CheckboxList from 'pages/common/popupForms/element/CheckBoxGroup';
import { Buttons } from 'pages/common/popupForms/element/Buttons';
import Emails from 'pages/common/popupForms/element/EmailsTag';
import rules, { validator } from 'components/form/validation';
import { shareValidate as validate } from './validate';
import { shareEntitiesAction, saveShareAction } from 'domain/shares/ShareActions';
import { getPopupParamsSelector } from 'domain/ui';
import injectSheet from 'lib/sheet';
import sheet from './sheet';
import { FORM_SHARING } from 'domain/const';

const getSubmit = (popupParams) => function submit(data, dispatch) {
  const moreData = (popupParams.get('moreData') || I.Map()).toJS();
  const checkboxMap = (popupParams.get('checkboxMap') || I.Map()).toJS();
  /**
   * replace undefined checkbox values to false
  **/
  Object.keys(checkboxMap).forEach(field => {
    data[field] = data[field] || false;
  });
  const payload = { ...data, ...moreData };
  if (data.id) {
    return dispatch(saveShareAction({ data: payload, shareType: popupParams.get('shareType') }));
  } else {
    return dispatch(shareEntitiesAction({ data: payload, shareType: popupParams.get('shareType') }));
  }
};

function Ids({ fields }) {
  return (
    <div>
      {
        fields.map((item, index) => (
          <Field
            type="hidden"
            key={`${fields.name}-${index}`}
            name={`${fields.name}.${index}`}
            component="input"
          />
        ))
      }
    </div>
  );
}

Ids.propTypes = {
  fields: PropTypes.shape({
    map: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};

class SharingForm extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.shape({
      field: PropTypes.string,
      textarea: PropTypes.string,
      SharingForm: PropTypes.string.isRequired,
      message: PropTypes.string.isRequired,
    }).isRequired,
    cancel: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    error: PropTypes.shape({
      shareParams: PropTypes.string,
    }),
    anyTouched: PropTypes.bool,
    popupParams: PropTypes.instanceOf(I.Map).isRequired,
    submitting: PropTypes.bool,
    submittingStarted: PropTypes.func.isRequired,
    submitButtonTitle: PropTypes.string,
  }

  validate = {
    email: validator(rules.arrayNotEmpty, rules.arrayEmail),
  };

  componentWillUnmount() {
    this.unmounted = true;
  }

  onSubmit = (e) => {
    e.preventDefault();
    const { handleSubmit, popupParams, submittingStarted } = this.props;
    submittingStarted(FORM_SHARING);
    /**
     * wait for autocomplete value to be submitted on lost focus
     * used if user press submit button just from autocomplete field
    **/
    setTimeout(handleSubmit(getSubmit(popupParams)), 500);
  }

  unmounted = false;

  safeSetState = (state) => {
    if (this.unmounted) return;
    this.setState(state);
  }

  render() {
    const { classes, cancel, error, anyTouched, popupParams, submitting } = this.props;
    return (
      <form onSubmit={this.onSubmit} data-name="SharingForm" className={classes.SharingForm}>
        <Field
          type="hidden"
          name="id"
          component="input"
        />
        <FieldArray
          type="hidden"
          name="ids"
          component={Ids}
          props={{ classes }}
        />
        <Emails validate={this.validate.email} placeholder="Type email" modifier="shareForm emails" />
        <CheckboxList
          meta={{
            touched: anyTouched,
            error: error && error.shareParams ? 'shareParams' : null,
          }}
          checkboxMap={popupParams.get('checkboxMap')}
        />
        <Field
          name="message"
          placeholder="Add a message"
          component={FormRowField}
          modifier="ShareNote"
          Field={Textarea}
          rootTag="div"
        />
        {
          popupParams.get('hasDrafts') && popupParams.get('hasNoDrafts') &&
            <div
              className={classes.message}
              data-name="drafts-message"
              children="Please note: your Drafts Art Objects will NOT be shared"
            />
        }
        {
          popupParams.get('hasDrafts') && !popupParams.get('hasNoDrafts') &&
            <div
              className={classes.message}
              data-name="drafts-message"
              children="As all your art objects are in draft status for this artist there is nothing to share"
            />
        }
        {
          !popupParams.get('hasDrafts') && !popupParams.get('hasNoDrafts') &&
            <div
              className={classes.message}
              data-name="drafts-message"
              children="Sorry, but your gallery is empty. There is nothing to share. Please add art objects first."
            />
        }
        <Buttons
          cancel={cancel}
          modifier="Popup-share"
          disabled={!popupParams.get('hasNoDrafts') || submitting}
          submit={this.props.submitButtonTitle}
        />
      </form>
    );
  }
}

export default compose(
  connect({
    popupParams: getPopupParamsSelector,
    submittingStarted: startSubmitAction,
  }),
  reduxForm({
    form: FORM_SHARING,
    validate,
  }),
  injectSheet(sheet),
)(SharingForm);
