import remove from '../images/remove.svg';

export default {
  Remove: {
    cursor: 'pointer',
    '&:before': {
      content: '""',
      flex: '0 0 24px',
      margin: [0, 10],
      background: {
        color: 'transparent',
        size: 'contain',
        repeat: 'no-repeat',
        position: 'right center',
        image: `url("${remove}")`,
      },
    },
  },
  text: {
    whiteSpace: 'nowrap',
    flex: '1 1 0',
    alignSelf: 'center',
  },
};
