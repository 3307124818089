import { put, call } from 'redux-saga/effects';
import { clearStorage } from 'lib/storage';
import { addNotification } from 'domain/env/EnvActions';

/**
 * { message, status }
**/
export function* logoutGen({ message }) {
  yield call(clearStorage);
  yield put({ type: 'auth/logout' });
  let title = 'Token expired';
  switch (true) {
    case message === 'Authorization failed':
    case message === 'Contact Support':
      title = message;
      break;
    default:
      break;
  }
  yield put(addNotification({ title, type: 'error' }));
}
