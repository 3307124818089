import record, { listOf } from 'cpcs-recordjs';
import { field } from 'cpcs-reconnect';
import * as A from 'domain/analyticsListPage/AnalyticsListPageActions';
import { AnalyticsValue, Pagination } from 'model';

/**
 * State
**/
const State = record('AnalyticsList', {
  list: listOf(AnalyticsValue),
  listPagination: Pagination.parse({}),
});

/**
 * selectors
**/
const base = field('analyticsList');

export const analyticsListSelector = base.then(State.$list);
export const analyticsPaginationSelector = base.then(State.$listPagination);

/**
 * recuder
**/
export const reducer = {
  analyticsList(state = new State(), action) {
    switch (action.type) {
      case A.analyticsListFetchAction.failure:
      case A.analyticsListAppendPageAction.failure:
        return state.update(
          State.$listPagination.update(v => v ? v.set('loading', false) : v),
        );
      case A.analyticsListFetchAction.request:
      case A.analyticsListAppendPageAction.request:
        return state.update(
          State.$listPagination.update(v => v.set('loading', true)),
        );
      case A.analyticsListAppendPageAction.success:
        return state.apply(
          State.$list.parsedBy('concat', action.payload.content),
          State.$listPagination.parsed(action.payload),
        );
      case A.analyticsListFetchAction.success:
        return state.apply(
          State.$list.parsed(action.payload.content),
          State.$listPagination.parsed(action.payload),
        );
      default:
        return state;
    }
  },
};
