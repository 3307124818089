import React from 'react';
import PropTypes from 'prop-types';

import { PAGE_WIDTH, PAGE_HEIGHT, themes } from 'pages/highcharts/helpers/PDFConstants';
import { LogoHeader } from 'pages/highcharts/PDFComponents/Logo';

export class CoverPage extends React.Component {
  render() {
    const { PDFState } = this.props;
    const { mainColor } = (themes[PDFState.reportType] || {});
    return (
      <svg width={PAGE_WIDTH} height={PAGE_HEIGHT} className="PagePDF">
        <g>
          <rect
            width={PAGE_WIDTH}
            height={PAGE_HEIGHT}
            fill={mainColor}
          />
          <LogoHeader fill="#FFF" />
        </g>
      </svg>
    );
  }
}

CoverPage.propTypes = {
  PDFState: PropTypes.shape({
    reportType: PropTypes.string.isRequired,
  }).isRequired,
};
