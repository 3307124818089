import { action, asyncAction } from 'lib/actions';
import axios from 'axios';

export const searchArtistAction = asyncAction('artist/SEARCH');

export const fetchArtistAction = asyncAction('artist/GET_BY_ID');

export const addArtistToDictionary = asyncAction('artist/DICTIONARY_ADD');

export const getArtistGroup = (...arr) => ( dispatch, getState) => {
  dispatch({
    type: addArtistToDictionary.request,
    payload: '',
  });
  if (!arr.length) return [];
  const store = getState();
  const xToken = store.env.getIn(['user', 'token']);
  return Promise.all(
    arr.map(id => axios({
      method: 'get',
      url: `/api/v1/artists/${id}`,
      headers: { 'X-Token': xToken },
    })),
  )
    .then(resp => {
      dispatch({
        type: addArtistToDictionary.success,
        payload: resp.map(r => r.data),
      });
      return Promise.resolve(resp);
    })
    .catch(err => {
      dispatch({
        type: addArtistToDictionary.failure,
        payload: err,
      });
      return Promise.reject(err);
    });
};

export const submitArtistForm = action('artist/FORM_SUBMIT');
export const createArtistAction = asyncAction('artist/CREATE');
export const updateArtistAction = asyncAction('artist/UPDATE');
export const removeArtistAction = asyncAction('artist/REMOVE');
export const removeArtistConfirmedAction = action('artist/REMOVE/confirmed');

export const fetchArtistLiquidityAction = asyncAction('artist/FETCH_LIQUIDITY');
