import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { compose } from 'redux';
import { connect } from 'cpcs-reconnect';
import I from 'immutable';
import { Link } from 'react-router-dom';

import {
  artistSelector,
  totalSelector,
} from 'domain/artwork/ArtworkModel';
import { createByArtistIdAction } from 'domain/alerts/AlertsActions';
import { routerParams_sel, routeName_sel } from 'domain/router/RouterModel';
import ArtistBanner from 'pages/common/artistBanner';
import Filters from 'pages/common/filters';
import filterList from 'pages/common/filters/config';
import translate from 'lib/translate';
import { ENTITY_NAME_ARTWORK, SUBSCRIPTION_TYPE_ENTERPRISE, TYPE_ALERT } from 'domain/const';
import { lnk } from 'lib/routes';
import Tabs from 'components/Tabs/Tabs';
import { allowToRemoveAlert_sel, subscriptionTypeSelector } from 'domain/env/EnvModel';
import RemoveButton from 'pages/common/RemoveButton';

import { button } from 'theme/button';
import { PageHeader, actions } from 'theme/Dashboard';
import { DEFAULT_FONTFAMILY_3 } from 'theme/theme';
import injectSheet from 'lib/sheet';


const sheet = {
  Header: {},
  PageHeader,
  actions,
  button,
  pageTitle: {
  },
  pageTitle__ArtistName: {
    font: `400 34px/40px ${DEFAULT_FONTFAMILY_3}`,
    display: 'block',
  },
  pageTitle__LifeYears: {
    paddingTop: 10,
    font: `400 18px/30px ${DEFAULT_FONTFAMILY_3}`,
    fontWeight: 700,
    display: 'block',
  },
  totalArtworks: {
    color: '#5B6178',
    font: `400 18px/30px ${DEFAULT_FONTFAMILY_3}`,
    fontWeight: 500,
    marginTop: 28,
    marginBottom: 38,
  },
  totalArtworks_amount: {
    color: '#1E202C',
    font: `400 24px/30px ${DEFAULT_FONTFAMILY_3}`,
    fontWeight: 500,
  },
};

const notEmpty = v => !![v].join('');

class Header extends React.PureComponent {
  static propTypes = {
    artist: PropTypes.instanceOf(I.Collection),
    artistTotal: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    classes: PropTypes.shape({
      Header: PropTypes.string.isRequired,
      PageHeader: PropTypes.string.isRequired,
      pageTitle: PropTypes.string.isRequired,
      pageTitle__ArtistName: PropTypes.string.isRequired,
      pageTitle__LifeYears: PropTypes.string.isRequired,
      totalArtworks: PropTypes.string.isRequired,
      totalArtworks_amount: PropTypes.string.isRequired,
      actions: PropTypes.string.isRequired,
      button: PropTypes.string.isRequired,
    }),
    createAlert: PropTypes.func.isRequired,
    routeParams: PropTypes.shape({
      authorId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    hideFilters: PropTypes.bool,
    hideTotal: PropTypes.bool,
    allowToRemoveAlert: PropTypes.bool,
    subscriptionType: PropTypes.string.isRequired,
    routeName: PropTypes.string,
    togglePDFReport: PropTypes.func,
    readyToGeneratePDF: PropTypes.bool,
    PDFReportShown: PropTypes.bool,
    artistLiquidity: PropTypes.string,
  };

  get tabs() {
    const { artistLiquidity, routeParams: { authorId } } = this.props;
    if (!authorId) return [];
    const tabs = [
      { link: lnk('artistPage', { authorId }), title: 'Auction Sales' },
    ];
    if (artistLiquidity && artistLiquidity !== 'LIQUID') {
      return [tabs[0]];
    }
    return tabs;
  }

  get allowGenerateReport() {
    const { subscriptionType, artist, artistLiquidity } = this.props;
    if (artistLiquidity && artistLiquidity !== 'LIQUID') return false;
    return (subscriptionType === SUBSCRIPTION_TYPE_ENTERPRISE || (artist && artist.analyticAvailable));
  }
  onCreateAlert = () => {
    const { artist, createAlert } = this.props;
    createAlert(artist);
  }

  get artistLifeYears() {
    const { artist } = this.props;
    if (!artist) return null;
    return [artist.getIn(['bornIn', 'year']), artist.getIn(['diedIn', 'year'])]
      .filter(notEmpty)
      .join(' - ');
  }

  render() {
    const { classes, artist, artistTotal, hideFilters, hideTotal, routeParams: { authorId } } = this.props;
    const { allowToRemoveAlert, togglePDFReport, readyToGeneratePDF, PDFReportShown, routeName } = this.props;
    const lifeYears = this.artistLifeYears;
    return (
      <div className={cx('PAGE_HEADER', classes.Header, 'ArtistPage')}>
        {
          artist && artist.get('bannerUrl') &&
            <ArtistBanner artist={artist} />
        }
        <header className={cx(classes.PageHeader, 'artistPage')}>
          {
            artist &&
            <span data-author-id={authorId} className={classes.pageTitle}>
              <span className={classes.pageTitle__ArtistName}>
                {artist.get('fullName')}
                {/* (artist.get('fullName') && lifeYears) ? ', ' : '' */}
              </span>
              <span className={classes.pageTitle__LifeYears}>
                {lifeYears ? `(${lifeYears})` : null}
              </span>
            </span>
          }
          {/** /}
            <div className={cx(classes.actions, 'ArtistPage')}>
            </div>
          {/**/}
          <div>
            {
              (artist && !artist.alertExist && !artist.editable) &&
                <button
                  type="button"
                  onClick={this.onCreateAlert}
                  className={cx(classes.button, 'ArtistPage', 'addAlert')}
                  children="Add Auction Alert"
                />

            }
            {
              (artist && allowToRemoveAlert && !(!artist.alertExist && !artist.editable)) &&
                <RemoveButton
                  type={TYPE_ALERT}
                  title="Delete Auction Alert"
                  modifier="artistPage"
                  entityTitle={artist ? artist.get('fullName') : ''}
                  params={{ alertId: parseInt(authorId, 10) }}
                />
            }
            {
              routeName === 'artistPage' &&
                <Link
                  className={cx(classes.button, 'ArtistPage', 'generagePDFReport')}
                  to={lnk('artistReportsPage', { authorId })}
                  children="Access Analytics"
                />
            }
            {
              (this.allowGenerateReport && routeName === 'artistReportsPage') &&
                <button
                  type="button"
                  onClick={() => togglePDFReport()}
                  className={cx(classes.button, 'ArtistPage', 'generagePDFReport')}
                  children="Download Report"
                  disabled={!readyToGeneratePDF || PDFReportShown}
                />
            }
            {
              (false && this.allowGenerateReport && routeName === 'artistPage') &&
                <Link
                  to={lnk('artistReportsPage', { authorId, query: { downloadReport: true } })}
                  className={cx(classes.button, 'ArtistPage', 'generagePDFReport')}
                  children="Download Report"
                />
            }
          </div>
        </header>
        <Tabs items={this.tabs} />
        {
          !hideTotal &&
            <div className={classes.totalArtworks}>
              Total artworks available:{' '}
              <span className={classes.totalArtworks_amount}>
                {/* translate('artistPage.header.artworks.amount', artistTotal) */}
                {artistTotal}
              </span>
            </div>
        }
        {
          !hideFilters &&
            <Filters
              sortByList={filterList.artistPage}
              placeholder={translate(`filterLabel.${ENTITY_NAME_ARTWORK}`)}
            />
        }
      </div>
    );
  }
}

export default compose(
  injectSheet(sheet),
  connect({
    routeParams: routerParams_sel,
    routeName: routeName_sel,
    artistTotal: totalSelector,
    artist: artistSelector,
    createAlert: createByArtistIdAction,
    allowToRemoveAlert: allowToRemoveAlert_sel,
    subscriptionType: subscriptionTypeSelector,
  }),
)(Header);
