import rules, { addError } from 'components/form/validation';
import { compose } from 'redux';
const fields = ['firstname', 'lastname', 'email', 'phone'];

const validator = values => (errors, fieldName) => {
  const val = values[fieldName];
  switch (fieldName) {
    case 'country':
      return compose(
        e => addError(e, fieldName, rules.cmRequired('Country is required')(val)),
      )(errors);
    case 'phone':
      return compose(
        e => addError(e, fieldName, rules.phone(val)),
      )(errors);
    case 'email':
      return compose(
        e => addError(e, fieldName, rules.email(val)),
        e => addError(e, fieldName, rules.required(val)),
      )(errors);
    default:
      return errors;
  }
};

export default values => fields.reduce(validator(values), {});
