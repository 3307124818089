import { select, call } from 'redux-saga/effects';
import {
  artworksById,
} from 'domain/ownArtwork/OwnArtworkModel';
import { edition_sel, stamps_sel } from 'pages/common/artworkForm/ArtworkFormModel';
import { mapToServer } from 'domain/ownArtwork/artworkSerializer';
import { routeName_sel } from 'domain/router/RouterModel';
import {
  artworksById as tracksById,
} from 'domain/considering/ConsideringModel';
import { momentFormatNow } from 'lib/envGlobals';
import { categoryById } from 'domain/category/CategoryModel';
import { UNITS } from 'lib/units';
import { isTrackingLink } from 'lib/helpers';
import 'lib/clean-code-lib';

const notesMapper = (artwork) => (text, index) => {
  if (!artwork) return { text: text || '', createdAt: momentFormatNow() };
  const sourceText = artwork.getIn(['notes', index, 'text'], '');
  const sourceDate = artwork.getIn(['notes', index, 'createdAt'], '');
  const createdAt = sourceText === text ? sourceDate : momentFormatNow();
  return { text, createdAt };
};

/**
 * ['...', ...] => [{ text, createdAt }, ...]
**/
function* mapToServerNotes(data) {
  const routeName = yield select(routeName_sel);
  const listSel = isTrackingLink(routeName) ? tracksById : artworksById;
  let notes = data.notes || [];
  if (data.id) {
    const artwork = (yield select(listSel)).get(parseInt(data.id, 10));
    notes = (notes || [])
      .filter(v => !!v)
      .map(notesMapper(artwork));
  } else {
    const createdAt = momentFormatNow();
    notes = notes
      .filter(v => !!v)
      .map(text => ({ text, createdAt }));
  }
  return { ...data, notes };
}

function* mapToServerCreatedAt(payload) {
  // here was assumption of createdAt date doe to https://youtrack.artbnk.com/issue/MVP-5791 requirements
  yield null;
  return payload;
}

function mapToServerEdition(data) {
  const { edition } = data;
  let ret = data;
  // maps fields to edition value
  const editionFields = {
    numbered: ['numberedNo', 'numberedOf'],
    editionSize: ['editionSize'],
    artistProof: ['artistProof'],
    pressProof: ['pressProof'],
    unknown: [],
  };
  // edition additional fields, that are not accessible for current edition value should be cleared
  const toClear = ['numberedNo', 'numberedOf', 'editionSize', 'artistProof', 'pressProof']
    .$excludeArr(editionFields[edition] || []);
  for (let field of toClear) {
    ret = { ...ret, [field]: '' };
  }
  return ret;
}

/**
 *      plate, stamps, foundry, studio, printer, publisher, inscription, mediums
 * 2DE    *      *                         *         *           *
 * 3D                     *        *                             *          *
 * 3DE           *        *        *                             *          *
**/

function* mapToServerCategory(data) {// NOSONAR
  const { category: categoryId } = data;
  let ret = data;
  if (!categoryId) return ret;
  const category = (yield select(categoryById)).get(parseInt(categoryId, 10));
  if (!category) return ret;
  const { title = '' } = category.toJS();
  const any3D = title.includes('3D');
  const isEdition = title.includes('Edition');
  const is2DEdition = !any3D && isEdition;
  const is3DEdition = any3D && isEdition;
  if (!isEdition) {
    ret = {
      ...ret,
      stamps: '',
      conceptionYear: '',
      edition: '',
      numberedNo: '',
      numberedOf: '',
      editionSize: '',
      artistProof: '',
    };
  }
  if (!is3DEdition && !is2DEdition) {
    ret = {
      ...ret,
      authenticityLetter: '',
    };
  }
  if (!is2DEdition) {
    ret = {
      ...ret,
      plate: '',
      printer: '',
      publisher: '',
      sheetUnit: null,
      pressProof: '',
      sheetHeight: '',
      sheetWidth: '',
    };
  }
  if (is3DEdition) {
    ret = { ...ret, literature: '' };
  }
  if (is2DEdition && !ret.sheetUnit) {
    ret = { ...ret, sheetUnit: UNITS.INCH.id };
  }
  if (any3D || !isEdition) {
    ret = {
      ...ret,
      imageHeight: '',
      imageWidth: '',
    };
  }
  if (!any3D) {
    ret = {
      ...ret,
      foundry: '',
      studio: '',
      depth: '',
      mediums: [],
    };
  }
  if (!any3D && !isEdition && data.isCircular) {
    ret = {
      ...ret,
      width: '',
      depth: '',
    };
  }
  if (any3D) {
    ret = {
      ...ret,
      substrates: [],
      surfaces: [],
    };
  }
  if (any3D || isEdition) {
    ret = {
      ...ret,
      isCircular: false,
    };
  }
  if (!isEdition && !any3D) {
    ret = {
      ...ret,
      inscription: '',
    };
  }
  let stamps = yield select(stamps_sel);
  if (stamps && !any3D) {
    stamps = (stamps || '').split(/[,;]+/).filter(v => v && v !== 'foundry').join(',');
    ret = { ...ret, stamps };
  }
  const edition = yield select(edition_sel);
  if (!is2DEdition && edition === 'pressProof') {
    ret = { ...ret, edition: null };
  }
  return ret;
}

function mapToServerCrop(data) {
  const pictures = data.pictures.map(v => ({
    x1: v.x1,
    x2: Math.max(0, v.naturalWidth - v.x2),
    y1: v.y1,
    y2: Math.max(0, v.naturalHeight - v.y2),
    rotateClockwiseTimes: v.rotationTimes,
    name: v.name,
  }));
  return { ...data, pictures };
}

export function* mapToServerSaga(payload) {
  let data = { ...payload };
  data = yield call(mapToServerCreatedAt, data);
  data = yield call(mapToServerNotes, data);
  data = yield call(mapToServerEdition, data);
  data = yield call(mapToServerCategory, data);
  data = yield call(mapToServerCrop, data);
  return mapToServer(data);
}
