export const sheet = {
  ArtistBanner: {
    display: 'flex',
    justifyContent: 'center',
    padding: [20, 0, 0],
  },
  banner970x120: {
    '@media screen and (max-width: 969px)': {
      display: 'none',
    },
  },
  banner720x120: {
    display: 'none',
    '@media screen and (max-width: 969px)': {
      display: 'block',
    },
    '@media screen and (max-width: 719px)': {
      display: 'none',
    },
  },
  banner343x120: {
    display: 'none',
    '@media screen and (max-width: 719px)': {
      display: 'block',
    },
  },
};
