export default {
  DayLabel: {
    position: 'absolute',
    top: 10,
    minWidth: 64,
    left: -10,
    height: 24,
    backgroundColor: '#000000',
    opacity: 0.7,
    zIndex: 3,
    whiteSpace: 'nowrap',
    color: '#ffffff',
    fontSize: 12,
    lineHeight: '24px',
    padding: [0, 8],
  },
  day: {
    fontWeight: 700,
    marginRight: 5,
  },
};
