import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import I from 'immutable';

import RemoveButton from '../RemoveButton';
import { TYPE_ARTWORK_SINGLE } from 'domain/const';
import { lnk } from 'lib/routes';

import injectSheet from 'lib/sheet';
import { sheet } from './sheet';

class Dashboard extends React.PureComponent {

  static propTypes = {
    artwork: PropTypes.instanceOf(I.Collection),
    editBtnLink: PropTypes.string,
    deleteRedirectLink: PropTypes.string,
    title: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.node,
    ]),
    noTitleWrap: PropTypes.bool,
    classes: PropTypes.shape({
      btn: PropTypes.string,
      // artBtn: PropTypes.string,
      title: PropTypes.string,
      PageHeader: PropTypes.string,
      actions: PropTypes.string,
      button: PropTypes.string,
    }).isRequired,
    modifier: PropTypes.string,
  };

  render() {
    const {
      artwork, classes, editBtnLink, deleteRedirectLink, title = '',
      modifier, noTitleWrap,
    } = this.props;
    const showDeleteBtn = deleteRedirectLink && artwork && !!artwork.get('id');
    return (
      <header className={cx(classes.PageHeader, modifier)}>
        {noTitleWrap && (title || null)}
        {
          !noTitleWrap &&
            <h2 className={cx(classes.title, modifier)}>
              {title}
            </h2>
        }
        <div className={cx(classes.actions, modifier)}>
          {
            showDeleteBtn &&
              <RemoveButton
                title="Delete"
                modifier="onDashboard"
                type={TYPE_ARTWORK_SINGLE}
                link={deleteRedirectLink}
                params={{ ids: [ artwork.get('id') ] }}
                entityTitle={artwork.get('title')}
              />
          }
          {
            editBtnLink &&
              <Link
                to={lnk(editBtnLink,{ artworkId: artwork.get('id') })}
                className={cx(classes.button, 'editBtn')}
              >Edit</Link>
          }
        </div>
      </header>
    );
  }
}

export default injectSheet(sheet)(Dashboard);
