import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

export default function FormattedMessageTag(props) {
  const tagName = props.tagName || 'span';
  const params = {};
  switch (typeof props.className) {
    case 'string':
      params.className = props.className;
      break;
    case 'object':
      params.className = cx(props.className);
      break;
    default:
      // do nothing
      break;
  }
  if ([props.tabIndex].join('')) {
    params.tabIndex = props.tabIndex;
  }
  if (typeof props.onClick === 'function') {
    params.onClick = props.onClick;
  }
  return React.createElement(tagName, params, props.defaultMessage || props.id);
}

FormattedMessageTag.propTypes = {
  id: PropTypes.string,
  defaultMessage: PropTypes.string,
  tagName: PropTypes.string,
  values: PropTypes.object, // eslint-disable-line react/no-unused-prop-types
  className: PropTypes.any,
  tabIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onClick: PropTypes.func,
};
