import I from 'immutable';
import rules from 'components/form/validation';
import { today } from 'lib/envGlobals';
import { strIs2D } from 'lib/helpers';

const MIN_YEARS_AFTER_BIRTH_TO_AO_CREATED = 10;

const editions = I.List(['2D Edition', '3D Edition']);
export const isEdition = ({ categoryList, category: id }) => {
  if (!id) return false;
  const entity = categoryList.find(v => v.get('id') === id);
  if (!entity) return false;
  if (editions.includes(entity.get('title'))) return true;
  return false;
};

export const is3DEdition = ({ categoryList, category: id }) => {
  if (!id) return false;
  const entity = categoryList.find(v => v.get('id') === id);
  if (!entity) return false;
  return entity.get('title') === '3D Edition';
};

export const is2DEdition = ({ categoryList, category: id }) => {
  if (!id) return false;
  const entity = categoryList.find(v => v.get('id') === id);
  if (!entity) return false;
  return entity.get('title') === '2D Edition';
};

export const get2DId = (categoryList) => {
  const entity = categoryList.find(item => strIs2D(item.get('title')));
  return entity ? entity.id : null;
};

export const maxCreatedAt = ({ categoryList, category, diedIn }) => {
  const diedIn_Int = (isFinite(diedIn) && diedIn !== '') ? parseInt(diedIn, 10) : null;
  if (!isEdition({ categoryList, category }) && diedIn_Int !== null && !isNaN(diedIn_Int)) {
    const d = today();
    d.setFullYear(diedIn_Int);
    return d;
  }
  return today();
};

export const minCreatedAt = ({ bornIn }) => {
  const bornIn_Int = isFinite(bornIn) ? parseInt(bornIn, 10) : null;
  if (bornIn_Int !== null && !isNaN(bornIn_Int)) {
    const d = today();
    const minYear = bornIn_Int + MIN_YEARS_AFTER_BIRTH_TO_AO_CREATED;
    d.setFullYear(minYear);
    return d;
  }
  return null;
};

/**
 * please don't rewrite to `(categoryList) => (value, allValues, ...args) => ...` factory
 * because if Field.validate prop changed redux form will re-register the field
**/
export const validateCreatedAt = (categoryList, value, allValues, ...args) => {
  const { category, diedIn } = allValues;
  const midD = minCreatedAt(allValues);
  const minYear = midD ? midD.getFullYear() : null;
  const maxYear = maxCreatedAt({ categoryList, category, diedIn }).getFullYear();
  return rules.range(minYear, maxYear)(value, allValues, ...args);
};
