import { fork, takeEvery, put, call, select, take } from 'redux-saga/effects';
import { change, initialize } from 'redux-form';
import { fetchCountriesAction } from 'domain/country/CountryAction';
import { defaultCountry } from 'domain/country/CountryModel';
import { registerAction, loginAction } from 'domain/env/EnvActions';
import Api, { makeApiGenerator } from 'domain/api';
import { FORM_SIGN_UP } from 'domain/const';
import loginPageGenerator from './login';
import { setStorageItem } from 'lib/storage';

const registration_apiGen = makeApiGenerator({
  actionCreator: registerAction,
  api: Api.registration,
  returnValue: function returnValue({ data, headers }) {
    return { data, headers };
  },
});

function* registration({ payload: { data: userData, resolve, reject } }) {
  try {
    const { data, headers } = yield call(registration_apiGen, { data: userData });
    resolve();
    yield call(setStorageItem, 'token', headers['x-token']);
    yield put({
      type: loginAction.success,
      payload: data,
      headers,
    });
  } catch (err) {
    reject(err);
  }
}

let usaChecked = true;
function* checkChanges({ meta: { field }, payload }) {
  if (field === 'country') {
    const USA = yield select(defaultCountry);
    if (parseInt(USA, 10) === parseInt(payload, 10)){
      usaChecked = true;
      yield put(change(FORM_SIGN_UP, 'marketingEmailConsent', true));
    } else {
      if (usaChecked){
        usaChecked = false;
        yield put(change(FORM_SIGN_UP, 'marketingEmailConsent', false));
      }
    }
  }
}

export default function* (payload, match) {
  let country = yield select(defaultCountry);
  if (!country){
    ({ payload: { defaultCountry: country } } = yield take(fetchCountriesAction.success));
  }
  yield put(initialize(FORM_SIGN_UP, { country, marketingEmailConsent: true, privacyTermsAccept: false }));
  yield takeEvery(registerAction.type, registration);
  yield fork(loginPageGenerator, payload, match);
  yield takeEvery('@@redux-form/CHANGE', checkChanges);
}
