import React, { useCallback, useEffect, useReducer, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import { debounce } from 'lib/envGlobals';
import { connectEventsContainer } from 'pages/highcharts/helpers/EventsContext';
import { RefSize } from 'pages/highcharts/helpers/refSize';
import {
  DEFAULT_FONTFAMILY,
  EVENT_COMPONENT_UPDATED,
  EVENT_GET_CHART_REF,
  PAGE_WIDTH,
} from 'pages/highcharts/helpers/PDFConstants';
import { getSVG } from 'pages/highcharts/helpers/getSVG';

const WIDTH = 1148;
const CHART_SCALE = 0.34;
const PAGE_PADDING_LEFT = 24;
const CHART_PADDING_LEFT = 8;
const CHART_PADDING_TOP = 20;

/**
 * if chart will be rerendered during PDF preview or PDF generation
 * we will update it's SVG inside PDFPreview limited amount of times to avoid cyclic rerenders
 * @todo add ability to reload PDF manually from PDFPreview
**/
const MAX_AUTO_UPDATES = 3;

function ChartFn({ item, PDFEvents, rootRef }) {
  const [height, setHeight] = useState(450);
  const [svgVersion, bumpSvgVersion] = useReducer(state => Math.min((state + 1), MAX_AUTO_UPDATES), 0);
  const [showChartId, setShowChartId] = useState(false);
  const gRef = useRef();
  const self = useRef({ unmounted: false });
  const id = item.name;
  /* track unmounted state */
  useEffect(() => {
    return () => {
      const { current } = self;
      current.unmounted = true;
    };
  }, []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const delayCallCb = useCallback(debounce((cb, payload) => {
    if (self.current.unmounted || id !== payload.id) return;
    cb();
  }, 1500), [id]);
  useEffect(() => {
    const delayBumpSvg = (payload) => {
      delayCallCb(bumpSvgVersion, payload);
    };
    PDFEvents && PDFEvents.addEventListener(EVENT_COMPONENT_UPDATED, delayBumpSvg);
    return () => PDFEvents && PDFEvents.removeEventListener(EVENT_COMPONENT_UPDATED, delayBumpSvg);
  }, [PDFEvents, delayCallCb, bumpSvgVersion]);
  useEffect(() => {
    const renderChartSVG = async () => {
      const chartId = item.name;
      const svgRef = PDFEvents.dispatch(EVENT_GET_CHART_REF, { chartId, svgVersion });
      const options = Highcharts.merge(Highcharts.getOptions().exporting, {
        type: 'application/pdf',
        // width: 800,
        sourceWidth: WIDTH,
        // sourceWidth: 842,
        sourceHeight: 600,
      });
      // more: { height, width, index }
      const { svg, ...more } = await getSVG([svgRef], options, { index: 0 });
      if (self.current.unmounted) return;
      if (!svg) {
        setShowChartId(true);
      }
      setHeight(Math.round(more.height));
      gRef.current.innerHTML = svg;
    };
    renderChartSVG();
  }, [item, PDFEvents, svgVersion]);
  const svgHeight = Math.round(height * CHART_SCALE + CHART_PADDING_TOP * 2);
  return (
    <svg
      width={PAGE_WIDTH - PAGE_PADDING_LEFT * 2}
      height={svgHeight}
      ref={rootRef}
      data-margin-top="15"
      data-margin-top-first="64"
      data-margin-bottom="8"
      data-left={PAGE_PADDING_LEFT}
      viewBox={`0 0 ${PAGE_WIDTH - PAGE_PADDING_LEFT * 2} ${svgHeight}`}
      data-svg-version={svgVersion}
    >
      <style>
        {`
        /**
         * Note that the color of the text is set with the
         * fill property, the color property is for HTML only
        **/
        .mockComponentTitle { font: bold 18px ${DEFAULT_FONTFAMILY}; fill: #000; }
        `}
      </style>
      <rect
        width={PAGE_WIDTH - PAGE_PADDING_LEFT * 2}
        height={svgHeight}
        stroke="#E5E5E5"
        strokeWidth="1"
        fill="#FFF"
        rx="2"
        ry="2"
      />
      {
        showChartId &&
          <text x="15" y="30" className="mockComponentTitle">
            {item.name}
          </text>
      }
      <g
        ref={gRef}
        transform={`translate(${CHART_PADDING_LEFT}, ${CHART_PADDING_TOP}) scale(${CHART_SCALE})`}
      ></g>
    </svg>
  );
}

ChartFn.propTypes = {
  PDFEvents: PropTypes.shape({
    addEventListener: PropTypes.func.isRequired,
    removeEventListener: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
  }).isRequired,
  item: PropTypes.shape({
    title: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  rootRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ currrent: PropTypes.any }),
  ]).isRequired,
};

class ChartPDF extends RefSize {
  static propTypes = {
    //
  };
  componentDidMount() {
    super.componentDidMount();
  }
  render() {
    const { item, PDFEvents } = this.props;
    return (
      <ChartFn
        item={item}
        rootRef={this.ref}
        PDFEvents={PDFEvents}
      />
    );
  }
}

ChartPDF = connectEventsContainer(ChartPDF);

export { ChartPDF };
