import {
  TEXT_LINK_COLOR,
  PREFERENCES_ARTIST_TAG_BG,
  PREFERENCES_ARTIST_TAG_COLOR,
  DEFAULT_FONTFAMILY,
  DEFAULT_TEXT_COLOR,
} from 'theme/theme';
import closeBg from 'pages/Preferences/images/close.svg';

export default {
  Preferences: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: [0 , 15],
    position: 'relative',
    backgroundColor: '#F7F7F7',
    '@media screen and (max-height: 760px)': {
      display: 'block',
      textAlign: 'center',
      overflowY: 'scroll',
      '&:after': {
        display: 'block',
        content: '""',
        height: 50,
      },
    },
    '&:before': {
      display: 'block',
      content: '""',
      width: '100%',
      height: 62,
      position: 'absolute',
      top: 0,
      left: 0,
      background: {
        image: `url(${require('./images/logo-137-34.svg')})`,
        repeat: 'no-repeat',
        position: '33px 20px',
      },
    },
  },
  header: {
    fontSize: 24,
    marginTop: 50,
    fontWeight: 500,
    textAlign: 'center',
    '@media screen and (max-height: 760px)': {
      marginTop: 70,
    },
  },
  subHeader: {
    fontSize: 16,
    color: '#2e343e',
    marginTop: 20,
    textAlign: 'center',
  },
  occupationList: {
    width: 300,
    margin: '0 auto',
    display: 'grid',
    gridTemplateColumns: '300px',
    rowGap: '14px',
    marginTop: 25,
    '@media screen and (max-width: 330px)': {
      width: '100%',
      gridTemplateColumns: '100%',
    },
  },
  tagsListWrapper: {
    // extend: 'artistsList',
    width: 1000,
    '@media screen and (max-width: 1024px)': {
      width: 650,
    },
    '@media screen and (max-width: 690px)': {
      width: 300,
    },
    '@media screen and (max-width: 330px)': {
      width: '100%',
    },
  },
  addArtistOffer: {
    marginTop: 12,
    minHeight: 34,
    textAlign: 'center',
    font: `400 16px/20px ${DEFAULT_FONTFAMILY}`,
  },
  addArtistBtn: {
    font: `400 16px/20px ${DEFAULT_FONTFAMILY}`,
    color: DEFAULT_TEXT_COLOR,
    // textDecoration: 'underline',
    borderBottom: `1px solid ${DEFAULT_TEXT_COLOR}`,
    cursor: 'pointer',
  },
  tagsList: {
    display: 'flex',
    marginTop: 12,
    flexWrap: 'wrap',
    minHeight: 34,
  },
  tag: {
    backgroundColor: PREFERENCES_ARTIST_TAG_BG,
    padding: [5, 0, 4, 6],
    height: 24,
    marginTop: 10,
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    marginRight: 20,
    font: `400 13px/15px ${DEFAULT_FONTFAMILY}`,
    color: PREFERENCES_ARTIST_TAG_COLOR,
  },
  tagText: {
    flex: '1 1 0',
  },
  tagRmButton: {
    flex: '0 0 20px',
    minHeight: '100%',
    width: 20,
    opacity: 0.4,
    background: {
      image: `url("${closeBg}")`,
      size: [8, 8],
      position: 'center',
      repeat: 'no-repeat',
    },
  },
  artistsList: {
    // relates tagsListWrapper
    width: 1050,
    marginTop: 20,
    display: 'grid',
    rowGap: '14px',
    columnGap: '50px',
    gridAutoRows: '50px',
    gridTemplateColumns: 'repeat(auto-fill, 300px)',
    justifyContent: 'center',
    overflow: 'hidden',
    '@media screen and (max-width: 1024px)': {
      width: 650,
    //   padding: [0, 15],
    },
    '@media screen and (max-width: 690px)': {
      width: 300,
    },
    '@media screen and (max-width: 330px)': {
      width: '100%',
      gridTemplateColumns: '100%',
    },
  },
  button: {
    width: '100%',
    height: 50,
    lineHeight: '50px',
    boxShadow: '0 1px 4px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#ffffff',
    border: 0,
    outline: 'none',
    padding: [0, 20],
    color: '#000000',
    cursor: 'pointer',
    // margin: [7, 25],
    fontSize: 14,
    textAlign: 'left',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    fontFamily: 'Facto',
    fontWeight: 600,
    '&.selected': {
      color: '#FFFFFF',
      backgroundColor: '#000000',
    },
    '&.occupation': {
      marginLeft: 0,
      marginRight: 0,
      fontWeight: 400,
      fontSize: 16,
    },
    // only devices where any available input mechanism can hover over elements
    '@media (any-hover)': {
      '&:hover': {
        color: '#FFFFFF',
        backgroundColor: '#000000',
      },
    },
  },
  search: {
    width: 500,
    height: 50,
    fontSize: 18,
    lineHeight: '50px',
    paddingLeft: 54,
    border: '1px solid #000000',
    background: {
      image: `url(${require('./images/search.svg')})`,
      repeat: 'no-repeat',
      position: '20px center',
      color: '#FFF',
    },
    '@media screen and (max-width: 690px)': {
      width: 300,
    },
    '@media screen and (max-width: 330px)': {
      width: '100%',
    },
  },
  years: {
    fontWeight: 400,
  },
  controls: {
    //
  },
  step: {
    marginTop: 20,
    width: 200,
    height: 50,
    backgroundColor: TEXT_LINK_COLOR,
    lineHeight: '50px',
    fontSize: 16,
    textTransform: 'uppercase',
    color: '#FFF',
    fontWeight: 500,
    cursor: 'pointer',
    fontFamily: 'Facto',
    '&:disabled': {
      opacity: 0.7,
      cursor: 'not-allowed',
    },
  },
};
