import { call, fork, select, takeEvery } from 'redux-saga/effects';
import {
  fetchAlerts_apiGen,
  alertsListAppendPage_apiGen,
} from 'domain/alerts/sagas';
import { alertListAppendPageAction } from 'domain/alerts/AlertsActions';
import { listPaginationSelector } from 'domain/alerts/AlertsModel';
import {
  filterParamsSelector,
} from 'domain/router/RouterModel';

function* fetchAlertsPage(page) {
  try {
    const queryParams = yield select(filterParamsSelector);
    if (page > 0) {
      yield call(alertsListAppendPage_apiGen.catchError, { ...queryParams, page });
    } else {
      yield fork(fetchAlerts_apiGen.catchError, { ...queryParams, page });
    }
  } catch (err) {
    console.error(err);
  }
}

function* onPaginationAlertList() {
  const { page, loading } = yield select(listPaginationSelector);
  if (loading) return;
  yield call(fetchAlertsPage, page + 1);
}

export default function* favoriteArtistsPage() {
  yield takeEvery(alertListAppendPageAction.type, onPaginationAlertList);
  yield fork(fetchAlertsPage);
}
