function peek(getter) {
  /**
   * @arg value
   * @arg data
   * @arg name
   * @arg props
  **/
  return (value, data) => (data.getIn(['createdAt', getter]) || '');
}

const createdAtYearSolver = peek('year');

export default createdAtYearSolver;
