import React from 'react';

export default function SvgViewDefs() {
  return (
    <svg
      x="0px"
      y="0px"
      width="600"
      height="400"
      xmlSpace="preserve"
    >
      <defs>
        <pattern
          id="imageExample"
          x="0"
          y="0"
          width="1"
          height="1"
          patternUnits="objectBoundingBox"
          viewBox="0 0 1922 1540"
        >
          <path fill="#A0A0A0" transform="scale(1, -1) translate(1 -1410)" d="M640 960q0 -80 -56 -136t-136 -56t-136 56t-56 136t56 136t136 56t136 -56t56 -136zM1664 576v-448h-1408v192l320 320l160 -160l512 512zM1760 1280h-1600q-13 0 -22.5 -9.5t-9.5 -22.5v-1216q0 -13 9.5 -22.5t22.5 -9.5h1600q13 0 22.5 9.5t9.5 22.5v1216 q0 13 -9.5 22.5t-22.5 9.5zM1920 1248v-1216q0 -66 -47 -113t-113 -47h-1600q-66 0 -113 47t-47 113v1216q0 66 47 113t113 47h1600q66 0 113 -47t47 -113z" />
        </pattern>
        <pattern
          id="yellow-note"
          width="100%"
          height="18"
          x="0"
          y="3"
          patternUnits="userSpaceOnUse"
        >
          <rect
            width="100%"
            height="30"
            fill="#FFFFCC"
          />
          <rect
            width="100%"
            height="0.1px"
            fill="#000000"
            shapeRendering="geometricPrecision"
          />
          <rect
            width="0.4px"
            x="10"
            height="100%"
            shapeRendering="geometricPrecision"
            fill="#FFCC99"
          />
        </pattern>
        <pattern
          id="photoshopCanvasBg"
          x="0"
          y="0"
          width="20"
          height="20"
          patternUnits="userSpaceOnUse"
        >
          <rect fill="rgba(0,0,0,0.05)" height="10" width="10" x="0" y="0" />
          <rect fill="rgba(255,255,255,0.05)" height="10" width="10" x="10" y="0" />
          <rect fill="rgba(0,0,0,0.05)" height="10" width="10" x="10" y="10" />
          <rect fill="rgba(255,255,255,0.05)" height="10" width="10" x="0" y="10" />
        </pattern>
        <filter id="filterInvert100">
          <feComponentTransfer>
            <feFuncR type="table" tableValues="1 0"/>
            <feFuncG type="table" tableValues="1 0"/>
            <feFuncB type="table" tableValues="1 0"/>
          </feComponentTransfer>
        </filter>
      </defs>
      <g>
        <rect
          x="0"
          y="0"
          width="200"
          height="200"
          rx="0"
          ry="0"
          fill="url(#photoshopCanvasBg)"
        />
      </g>
    </svg>
  );
}
