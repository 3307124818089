import { takeEvery, call, select, put } from 'redux-saga/effects';
import Api, { makeApiGenerator } from 'domain/api';
import { checkResetPasswordToken, createPasswordAction } from 'domain/env/EnvActions';
import { queryParams } from 'domain/router/RouterModel';
import { redirectToPageAction } from 'lib/redirectToPage';
import { sendCreatePasswordEmailAction } from 'pages/createPassword/createPasswordModel';

const sendRecoveryEmail_apiGen = makeApiGenerator({
  actionCreator: createPasswordAction,
  api: Api.resetPassword,
});

const sendCreatePasswordEmail_apiGen = makeApiGenerator({
  actionCreator: sendCreatePasswordEmailAction,
  api: Api.sendCreatePasswordEmail,
});


function* onCreatePassword({ payload: { data, resolve, reject } }) {
  try {
    yield call(sendRecoveryEmail_apiGen, { data });
    resolve();
  } catch (err) {
    reject(err);
  }
}

function* onSendEmail() {
  const { token } = yield select(queryParams);
  yield call(sendCreatePasswordEmail_apiGen.catchError, { data: { token } });
}

const checkToken_apiGen = makeApiGenerator({
  actionCreator: checkResetPasswordToken,
  api: Api.checkToken,
});

export default function* createPassword() {
  const { token, expired } = yield select(queryParams);
  try {
    if (!expired) {
      yield call(checkToken_apiGen, { data: { token } });
    }
  } catch (err) {
    yield put({
      type: redirectToPageAction.type,
      payload: { page: 'createPassword', query: { token, expired: 'expired' } },
    });
  };
  yield takeEvery(createPasswordAction.type, onCreatePassword);
  yield takeEvery(sendCreatePasswordEmailAction.type, onSendEmail);
}
