import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect, select } from 'cpcs-reconnect';

import { advancedFilterOpenState_sel } from 'domain/ui/UIModel';
import AdvancedFilterForm from 'pages/common/advancedFilter/form';
import { FORM_ADVANCED_FILTER } from 'domain/const';

import injectSheet from 'lib/sheet';
import sheet from './sheet';

class AdvancedFilter extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    toggle: PropTypes.func.isRequired,
    advancedFilterOpenState: PropTypes.string,
    form: PropTypes.shape({
      initial: PropTypes.shape({
        sizeUnit: PropTypes.string,
      }),
      registeredFields: PropTypes.shape({
        artTitle: PropTypes.shape({
          count: PropTypes.number,
          name: PropTypes.string,
          type: PropTypes.string,
        }),
      }),
      values: PropTypes.shape({}),
    }),
  };

  componentDidMount() {
    const { advancedFilterOpenState } = this.props;
    document.body.classList.add('modal-open');
    document.body.classList.add(advancedFilterOpenState);
  }

  componentDidUpdate(prevProps) {
    const { advancedFilterOpenState } = this.props;
    if (prevProps.advancedFilterOpenState === advancedFilterOpenState) return;
    document.body.classList.remove('AFOpened', 'AFClosed', 'AFDefault');
    document.body.classList.add(advancedFilterOpenState);
  }

  componentWillUnmount() {
    document.body.classList.remove('modal-open', 'AFOpened', 'AFClosed', 'AFDefault');
  }

  static getDerivedStateFromProps(props, state) {
    if (!state.mountForm && !props.form) {
      return { mountForm: true };
    }
    return null;
  }

  state = {
    /**
     * mountForm
     *   when switching between pages with sidebar, the new init event dispatched before destroy
     *   so to avoid it, mount component only when form is empty
    **/
    mountForm: false,
  }

  render() {
    const { classes, toggle } = this.props;
    const { mountForm } = this.state;
    return (
      <div className={classes.AdvancedFilter}>
        <div className={classes.header}>
          <div className={classes.headerContent}>
            <div className={classes.title}>Filter</div>
            <button
              type="button"
              className={classes.close}
              onClick={() => toggle()}
            />
          </div>
        </div>
        {
          mountForm &&
            <AdvancedFilterForm />
        }
      </div>
    );
  }
}

export default compose(
  injectSheet(sheet),
  connect({
    advancedFilterOpenState: advancedFilterOpenState_sel,
    form: select(state => state.form && state.form[FORM_ADVANCED_FILTER]),
  }),
)(AdvancedFilter);
