import { takeLatest, put } from 'redux-saga/effects';
import { sagaDelay } from 'lib/envGlobals';
import { addNotification } from 'domain/env';

export function* showSubmitFailNotify() {
  yield sagaDelay(100);
  yield put(addNotification({ title: 'Not all required fields are filled correctly', type: 'error' }));
}

export function* watchFailSubmit() {
  yield takeLatest('@@redux-form/SET_SUBMIT_FAILED', showSubmitFailNotify);
}
