import { call, put, takeEvery } from 'redux-saga/effects';
import { stopSubmit, change } from 'redux-form';
import {
  checkCouponAction,
  closeCouponFormAction,
} from 'pages/common/couponForm/couponFormActions';
import Api, { makeApiGenerator } from 'domain/api';
import { SINGLE_RTV, FORM_COUPON } from 'domain/const';
import translate from 'lib/translate';

const checkRtvCoupon_apiGen = makeApiGenerator({
  actionCreator: checkCouponAction,
  api: Api.checkRtvCoupon,
  getSuccessData: ({ data, more }) => ({ payload: { ...data, more } }),
});

const checkPlanCoupon_apiGen = makeApiGenerator({
  actionCreator: checkCouponAction,
  api: Api.checkSubscriptionCoupon,
  getSuccessData: ({ data, more }) => ({ payload: { ...data, more } }),
});

function* onClearCouponForm() {
  yield put(change(FORM_COUPON, 'coupon', ''));
}

function* onCheckCoupon(action) {
  try {
    const { coupon, product } = action.payload;
    const isSubscription = !product || product.get('shortName') !== SINGLE_RTV;
    if (isSubscription) {
      // eslint-disable-next-line no-unused-vars
      yield call(checkPlanCoupon_apiGen, { data: { discount: coupon } }, { coupon, isSubscription });
    } else {
      // eslint-disable-next-line no-unused-vars
      yield call(checkRtvCoupon_apiGen, { data: { discount: coupon } }, { coupon, isSubscription });
    }
    yield put(stopSubmit(FORM_COUPON));
  } catch (err) {
    const message = translate('couponForm.error.invalid');
    yield put(stopSubmit(FORM_COUPON, { coupon: message }));
  }
}

export function* watchCouponForm() {
  yield takeEvery(closeCouponFormAction.type, onClearCouponForm);
  yield takeEvery(checkCouponAction.type, onCheckCoupon);
}
