export default {
  DraggableComponent: {
    position: 'relative',
  },
  DroppableComponent: {
    position: 'relative',
  },
  drag: {
    opacity: 0.5,
  },
  dragOver: {
    boxShadow: '0 2px 8px 5px rgba(203, 105, 84, 0.9)',
  },
  canDrop: {
    boxShadow: '0 2px 8px 2px rgba(203, 105, 84, 0.5);',
  },
};
