import { DEFAULT_BUTTON_COLOR } from 'theme/theme';

export const loader = {
  '@keyframes ring': {
    from: { transform: 'rotate(0deg)' },
    to: { transform: 'rotate(360deg)' },
  },
  loader: {
    position: 'absolute',
    left: 'calc(50% - 25px)',
    top: 200,
    display: 'inline-block',
    width: 50,
    height: 50,
    animation: '$ring 1s linear infinite',
    '&:after': {
      content: '" "',
      display: 'block',
      width: '38px',
      height: '38px',
      margin: '1px',
      borderRadius: '50%',
      border: `5px solid ${DEFAULT_BUTTON_COLOR}`,
      borderColor: `${DEFAULT_BUTTON_COLOR} transparent ${DEFAULT_BUTTON_COLOR} transparent`,
    },
  },
};
