import { takeEvery, call, put, select, fork } from 'redux-saga/effects';
import Api, { callApi } from 'domain/api';
import { addNotification } from 'domain/env/EnvActions';
import { allWorksGalleryId_sel, currentGalleryId_sel } from 'domain/env/EnvModel';
import { lnk } from 'lib/routes';
import { push } from 'connected-react-router';
import { galleryMapIndexedById_sel } from 'domain/galleries/GalleryModel';
// import { currentRouteNameSelector } from 'domain/router/RouterModel';
import { reloadPage } from 'domain/router/saga';
import { confirmGenCallIfDefaultGallery } from 'sagas/confirm/helpers';
import { confirmGenCall } from 'sagas/confirm';
import { parseServerError } from 'lib/helpers';
import { deSelectArtwork } from 'domain/ownArtwork/sagas';
import { deSelectTracking } from 'domain/considering/sagas';
import { removeArtistApiGen } from 'domain/artist/sagas';
import {
  TYPE_ARTWORK,
  TYPE_ARTWORK_SINGLE,
  TYPE_GALLERY,
  TYPE_CONSIDERATION_ARTWORK,
  TYPE_ALERT,
  TYPE_ALERT_REMOVE,
  TYPE_ALERT_ARTWORK,
  TYPE_CUSTOMER_ARTIST,
  removeAction,
  considerToRemoveAction,
  restoreAction,
  RESTORE_DELETION_PERIOD,
} from 'domain/const';

const showTime = RESTORE_DELETION_PERIOD;

function* remove({ payload: { type, params, link, builtLink } }) { // NOSONAR
  const gId = yield select(currentGalleryId_sel);
  const allWorksGalleryId = yield select(allWorksGalleryId_sel);
  let title;
  try {
    switch (type) {
      case TYPE_ARTWORK_SINGLE:
        yield callApi(Api.removeArtworks, { ...params, gId: allWorksGalleryId });
        title = 'Artwork was removed';
        yield put(addNotification({ title, showTime, restore: { type, params: { ...params, gId: allWorksGalleryId } } }));
        yield fork(deSelectArtwork);
        break;
      case TYPE_ARTWORK:
        yield callApi(Api.removeArtworks, { ...params, gId });
        title = params.ids.length === 1 ? 'Artwork was removed' : 'Artworks were removed';
        yield put(addNotification({ title, showTime, restore: { type, params: { ...params, gId } } }));
        yield fork(deSelectArtwork);
        break;
      case TYPE_GALLERY:
        params = {
          ...params,
          index: (yield select(galleryMapIndexedById_sel)).getIn([params.galleryId, 'index']),
        };
        yield callApi(Api.removeGallery, params);
        yield put(addNotification({ title: 'Gallery was removed', showTime, restore: { type, params } }));
        if (gId === params.galleryId) builtLink = lnk('collectionListPage', { gId: allWorksGalleryId } ); // NOSONAR
        break;
      case TYPE_CONSIDERATION_ARTWORK:
        yield callApi(Api.removeArtworks, { ...params, gId });
        title = params.ids.length === 1 ? 'Artwork was removed' : 'Artworks were removed';
        yield put(addNotification({ title, showTime, restore: { type, params: { ...params, gId } } }));
        yield fork(deSelectTracking);
        break;
      case TYPE_ALERT:
        yield callApi(Api.removeAlert, params);
        yield put(addNotification({ title: 'Alert was removed', showTime, restore: { type, params } }));
        break;
      case TYPE_ALERT_ARTWORK:
        yield callApi(Api.removeAlertArtworks, params);
        title = params.ids.length === 1 ? 'Artwork was removed' : 'Artworks were removed';
        yield put(addNotification({ title, showTime, restore: { type, params } }));
        break;
      case TYPE_CUSTOMER_ARTIST:
        yield call(removeArtistApiGen, params);
        break;
      default:
        throw new Error(`Unknown entity type for remove "${type}"`);
    }
    yield put({ type: removeAction.success, payload: { type, params } });
    if (builtLink){
      yield put(push(builtLink));
    } else {
      yield link ? put(push(lnk(link, params))) : call(reloadPage);
    }
  } catch (err) {
    if (process.env.NODE_ENV !== 'test') {
      console.error(err);
    }
    yield put(addNotification({ title: 'Something went wrong, remove failed', type: 'error' }));
  }
}

function* dispatchGen(action) {
  yield put(action);
}

function* considerToRemove({ payload }) {
  let confirmParams;
  let confirmFn = confirmGenCall;
  switch (payload.type) {
    case TYPE_ARTWORK:
      confirmFn = confirmGenCallIfDefaultGallery;
      // falls through
    case TYPE_ARTWORK_SINGLE:
      confirmParams = {
        title: payload.params.ids.length === 1 ? 'Delete artwork' : `Delete ${payload.params.ids.length} artworks`,
        description: 'Are you sure you want to delete %s from your collection?'.replace('%s', payload.params.ids.length === 1 ? `<strong>“${payload.name}”</strong>` : 'selected artworks'),
      };
      break;
    case TYPE_CONSIDERATION_ARTWORK:
      confirmParams = {
        title: payload.params.ids.length === 1 ? 'Delete artwork' : `Delete ${payload.params.ids.length} artworks`,
        description: 'Are you sure you want to delete %s from your track?'.replace('%s', payload.params.ids.length === 1 ? `<strong>“${payload.name}”</strong>` : 'selected artworks'),
      };
      confirmFn = confirmGenCallIfDefaultGallery;
      break;
    case TYPE_ALERT_ARTWORK:
      confirmParams = {
        title: payload.params.ids.length === 1 ? 'Delete artwork' : `Delete ${payload.params.ids.length} artworks`,
        description: 'Are you sure you want to delete %s from your alert?'.replace('%s', payload.params.ids.length === 1 ? `<strong>“${payload.name}”</strong>` : 'selected artworks'),
      };
      confirmFn = confirmGenCallIfDefaultGallery;
      break;
    case TYPE_GALLERY:
      confirmParams = {
        title: 'Delete gallery',
        description: `Are you sure you want to delete <strong>“${payload.name}”</strong> from your collection?`,
        thumb: payload.thumb,
      };
      break;
    case TYPE_ALERT:
      confirmParams = {
        title: 'Delete alert',
        description: `Are you sure you want to delete alert <strong>“${payload.name}”</strong>?`,
      };
      break;
    case TYPE_CUSTOMER_ARTIST:
      confirmParams = {
        title: 'Delete artist',
        description: `All artworks by <strong>${payload.name}</strong> will be set to Draft in your collection.\n\n Are you sure you want to delete the artist?`,
        modifier: 'deleteArtistConfirm',
      };
      break;
    default:
      confirmParams = { title: 'Are you sure?' };
  }
  yield call(confirmFn, confirmParams, dispatchGen, removeAction(payload));
}

function* restore({ payload }) {
  const { type, params } = payload;
  let needReload = true;
  let title = 'Something went wrong, restore failed';
  try {
    switch (type) {
      case TYPE_CONSIDERATION_ARTWORK:
      case TYPE_ARTWORK:
      case TYPE_ARTWORK_SINGLE:
        yield callApi(Api.restoreArtworks, params);
        break;
      case TYPE_GALLERY:
        yield callApi(Api.restoreGallery, params);
        needReload = false;
        break;
      case TYPE_ALERT:
        yield callApi(Api.restoreAlert, params);
        break;
      case TYPE_ALERT_REMOVE:
        yield callApi(Api.removeAlert, params);
        yield put({ type: restoreAction.success, payload });
        break;
      case TYPE_ALERT_ARTWORK:
        yield callApi(Api.restoreAlertArtworks, params);
        break;
      default:
        throw new Error(`Unknown entity type "${type}" for restore`);
    }
    if (needReload) yield call(reloadPage);
  } catch (err) {
    const { message } = parseServerError(err);
    if (message === 'Gallery with the same name already exists') {
      title = message;
    }
    yield put(addNotification({ title, type: 'error' }));
  }
}

export function* watchRemoveRestore() {
  yield takeEvery(considerToRemoveAction.type, considerToRemove);
  yield takeEvery(removeAction.type, remove);
  yield takeEvery(restoreAction.type, restore);
}
