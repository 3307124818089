import React from 'react';
import PropTypes from 'prop-types';
import I, { List } from 'immutable';
import { reduxForm, Field, FieldArray } from 'redux-form';
import { connect } from 'cpcs-reconnect';
import { compose } from 'redux';
import cx from 'classnames';
import Textarea from 'components/form/field/nodes/Textarea';
import { conditionList as conditionListSelector } from 'domain/conditions';
import { oneOfFieldHasValue_sel, category_sel } from 'pages/common/artworkForm/ArtworkFormModel';
import { categoryList } from 'domain/category';
import { is3DEdition, isEdition } from 'pages/common/newPage/form/artObject/helpers';
import Fieldset from 'components/fieldset';
import {
  submitUnifiedFormAction,
} from 'pages/common/artworkForm/ArtworkFormActions';
import { FORM_ADD_ART_ITEM } from 'domain/const';
import Notes from 'pages/common/newPage/form/about/notes';
import { Art } from 'pages/common/newPage/form/artObject';
import { ArtistFieldset } from 'pages/common/newPage/form/artist';
import Provenance from 'pages/common/newPage/form/elements/Provenance';
import SalesHistory from 'pages/common/newPage/form/elements/SalesHistory';
import Pictures from 'pages/common/newPage/form/elements/pictures';
import Select from 'components/form/select';
import FormRowField from 'pages/common/newPage/form/elements/FormRowField';
import Details from 'pages/common/newPage/form/details';

const focusError = () => {
  setTimeout(() => {
    const elem = document.getElementsByClassName('error');
    if (elem && elem[0]){
      elem[0].focus();
    }
  }, 500);
};

const submit = (data, dispatch) => {
  return dispatch(submitUnifiedFormAction(data));
};

class ArtItemForm extends React.PureComponent {

  static propTypes = {
    suggestionSelected: PropTypes.bool,
    handleSubmit: PropTypes.func.isRequired,
    isEdit: PropTypes.bool,
    is3D: PropTypes.bool,
    conditionList: PropTypes.instanceOf(List),
    categoryList: PropTypes.instanceOf(I.Collection),
    category: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    oneOfFieldHasValue: PropTypes.func.isRequired,
  };

  state = {
    disabled: {},
  };

  render() {
    const { handleSubmit, suggestionSelected, isEdit, conditionList,
      categoryList, category, is3D = false, oneOfFieldHasValue } = this.props;
    return (
      <form
        className={cx('PAGE_INNER_COL1', 'ArtItemForm')}
        onSubmit={handleSubmit(submit)}
      >
        <Field component="input" type="hidden" name="artWork" />
        <FieldArray name="pictures" fields={List([1])} component={Pictures} />
        <ArtistFieldset isEdit={isEdit} suggestionSelected={suggestionSelected} modifier="AOForm" />
        <Art isEdit={isEdit} is3D={is3D} suggestionSelected={suggestionSelected} />
        <Details suggestionSelected={suggestionSelected} />
        <Fieldset
          title="Provenance & Sales"
          opened={oneOfFieldHasValue('conditions', 'provenance', 'salesHistory', 'location')}
        >
          <ul>
            <Field
              title="Condition"
              id="id-condition"
              component={FormRowField}
              modifier="AOForm"
              Field={Select}
              name="conditions"
              placeholder="bloom, bowing..."
              isMulti
              list={conditionList}
            />
            <Provenance modifier="AOForm" />
            <Field
              name="location"
              placeholder="location"
              component={FormRowField}
              title="Location"
              modifier="AOForm location"
            />
            <SalesHistory label="Sales information" showEstimatedPrice modifier="AOForm" />
          </ul>
        </Fieldset>
        <Fieldset
          title="Exhibitions & Literature"
          opened={oneOfFieldHasValue('catalogRaisonne', 'exhibition', 'literature', 'notes', 'authenticityLetter')}
        >
          <Field
            name="catalogRaisonne"
            component={FormRowField}
            Field={Textarea}
            modifier="AOForm"
            type="text"
            id="id-catalogRaisonne"
            title="Catalogue Raisonné"
            placeholder="Catalogue entry information"
            disabled={suggestionSelected}
          />
          <Field
            name="exhibition"
            component={FormRowField}
            Field={Textarea}
            modifier="AOForm"
            type="text"
            id="id-exhibition"
            title="Exhibitions"
            placeholder="Specify exhibition history"
            disabled={suggestionSelected}
          />
          {
            (!is3DEdition({ categoryList, category })) &&
              <Field
                name="literature"
                placeholder="Publication history"
                component={FormRowField}
                modifier="AOForm"
                title="Literature"
                disabled={suggestionSelected}
              />
          }
          {
            isEdition({ categoryList, category }) &&
              <Field
                name="authenticityLetter"
                placeholder="Yes, no, unknown..."
                component={FormRowField}
                modifier="AOForm"
                disabled={suggestionSelected}
                title="Letter of Authenticity"
              />
          }
          <Notes modifier="AOForm" />
        </Fieldset>
      </form>
    );
  }
}


export default compose(
  connect({
    conditionList: conditionListSelector,
    category: category_sel,
    categoryList,
    oneOfFieldHasValue: oneOfFieldHasValue_sel,
  }),
  reduxForm({
    form: FORM_ADD_ART_ITEM,
    onSubmit: submit,
    onSubmitFail: focusError,
  }),
)(ArtItemForm);
