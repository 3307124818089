import React, { Component } from 'react';
import makeCancelable from './makeCancelable';

export default function asyncComponent(importComponent) {
  class AsyncComponent extends Component {

    constructor(props) {
      super(props);
      this.state = {
        component: null,
      };
    }

    cancelable = null;

    UNSAFE_componentWillMount(){
      this.cancelable = makeCancelable(importComponent());
      this.cancelable.promise
        .then(({ default: component }) => this.setState({ component }) )
        .catch(() => null);
    }

    componentWillUnmount() {
      this.cancelable.cancel();
    }

    render() {
      const C = this.state.component;
      return (C ? <C {...this.props} /> : null);
    }
  }
  return AsyncComponent;
}
