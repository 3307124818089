import { popupComponents } from 'pages/common/popup/sheet';
import { button } from 'theme/button';
import rotateLeft from './images/rotate_left-24px.svg';
import rotateRight from './images/rotate_right-24px.svg';

const TITLE_HEIGHT = 49;
const CONTAINER_PADDING = 50 + 23;

export default {
  ...popupComponents,
  container: {
    width: '90vw',
    height: '80vh',
    ...popupComponents.container,
  },
  cropSubTitle: {
    marginBottom: 35,
  },
  cropBoxWrapper: {
    height: `calc(100% - ${TITLE_HEIGHT}px - ${CONTAINER_PADDING}px)`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    lineHeight: 0,
    '@media screen and (max-width: 474px)': {
      height: `calc(100% - ${TITLE_HEIGHT+19}px - ${CONTAINER_PADDING}px)`,
    },
    '@media screen and (max-width: 302px)': {
      height: `calc(100% - ${TITLE_HEIGHT+19+29}px - ${CONTAINER_PADDING}px)`,
    },
    '@media screen and (max-width: 300px)': {
      height: `calc(100% - ${TITLE_HEIGHT+19+29+19}px - ${CONTAINER_PADDING}px)`,
    },
  },
  cropBox: {
    // show black bg for transparent image areas
    backgroundColor: '#000',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 25,
    height: 30,
    '@media screen and (max-width: 660px)': {
      flexDirection: 'column',
      marginTop: 5,
    },
  },
  buttonsCol: {
    flex: '1 1 0',
    display: 'flex',
    '&.col2': {
      justifyContent: 'center',
    },
    '&.col3': {
      justifyContent: 'flex-end',
    },
    '@media screen and (max-width: 660px)': {
      '&.col1': {
        display: 'none',
      },
    },
  },
  button,
  rotateButton: {
    width: 30,
    height: 30,
    background: `transparent url("${rotateLeft}") no-repeat scroll center center / 24px 24px`,
    '&.right': {
      backgroundImage: `url("${rotateRight}")`,
    },
  },
};
