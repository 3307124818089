import {
  BUY_RTV_FORM_SUBMIT_BUTTON,
  DEFAULT_FONTFAMILY,
  DARK_BLUE_COLOR,
} from 'theme/theme';
import errorMessageSheet from 'components/form/message/sheet';


export default {
  BillingForm: {
    // root,
    padding: [30, 0, 0, 0],
  },
  formTitle: {
    color: DARK_BLUE_COLOR,
    font: `500 14px/24px ${DEFAULT_FONTFAMILY}`,
    textTransform: 'uppercase',
    marginTop: 30,
    '&.rtvForm': {
      fontSize: '12px',
    },
  },
  error: {},
  saveCardCheckboxWrp: {
    padding: [0, 0, 15, 0],
  },
  zipCountryWrp: {
    display: 'flex',
  },
  zipWrp: {
    width: 130,
    margin: [0, 25, 0, 0],
  },
  countryWrp: {
    width: 250,
  },
  field: {
    backgroundColor: '#fff',
    border: 'solid 1px #e6e6e6',
    width: '100%',
    height: 46,
    padding: 15,
    marginTop: 12,
    lineHeight: '16px',
    color: DARK_BLUE_COLOR,
    fontFamily: DEFAULT_FONTFAMILY,
    fontSize: 14,
    fontWeight: 400,
  },
  submit: {
    backgroundColor: BUY_RTV_FORM_SUBMIT_BUTTON,
    width: '100%',
    padding: [0, 20],
    height: 64,
    color: '#FFF',
    marginTop: 40,
    fontSize: '16px',
    fontWeight: 700,
    fontFamily: DEFAULT_FONTFAMILY,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    lineHeight: '24px',
    '&:disabled': {
      opacity: 0.5,
    },
    '&.loading:after': {
      display: 'block',
      content: '""',
      width: 22,
      height: 22,
      border: '4px solid #FFF',
      borderRadius: '50%',
      borderTop: '4px solid transparent',
      transform: 'rotate(45deg)',
      animation: '$buy-button-spinner 1s linear infinite',
    },
    '&:hover': {
      cursor: 'pointer',
      opacity: 0.9,
    },
  },
  '@keyframes buy-button-spinner': {
    'from': { transform: 'rotate(0deg)' },
    'to': { transform: 'rotate(360deg)' },
  },
  orderError: {
    ...errorMessageSheet.message,
    ...errorMessageSheet.error,
    marginTop: 20,
  },
};
