import { action, asyncAction } from 'lib/actions';

export const showAnalyticsFormAction = action('buyAnalytics/SHOW_FORM');
export const hideAnalyticsFormAction = action('buyAnalytics/HIDE_FORM');
export const buyAnalyticsFormSubmitAction = action('buyAnalytics/FORM_SUBMIT');
export const orderAnalyticsAction = asyncAction('buyAnalytics/ORDER_REQUEST');
export const setOrderErrorAction = action('buyAnalytics/SET_ORDER_ERROR');
export const updateCardAction = action('creditCard/UPDATE');
export const orderFreeAnalyticsAction = asyncAction('buyAnalytics/GET_FREE');
export const showAnalyticsSubscriptionTypeAction = asyncAction('buyAnalytics/SHOW_SUBSCRIPTION_TYPE');
// export const noFreeAnalyticsLeftAction = action('buyAnalytics/NO_FREE_RTV_LEFT');
export const setSelectedProductNameAction = action('buyAnalytics/SET_SELECTED_PRODUCT_NAME');
export const buyAnalyticsReportAction = asyncAction('buyAnalytics/BUY_ANALYTICS_REPORT');
