import { call, select, put, fork, takeEvery } from 'redux-saga/effects';
import { reset, initialize, getFormInitialValues } from 'redux-form';
import { FORM_ACCOUNT } from 'domain/const';
import { setStorageItem } from 'lib/storage';
import { parseServerError } from 'lib/helpers';
import translate from 'lib/translate.js';
import {
  updateAccountAction,
  changePasswordAction,
  uploadPictureAction,
  addNotification,
  saveStripeCardAction,
  fetchStripePropertiesAction,
  checkAuthAction,
  loginAction,
  currentPlanAction,
} from 'domain/env/EnvActions';
import { requestedLocation, resetRequestedLocation } from 'domain/ui';
import {
  accountFormInitialData_sel,
  homePageLink_sel,
  stripeApiTokenSelector,
  isAuthorized,
  currentPlanSel,
} from 'domain/env/EnvModel';
import { replace as routerReplace, push as pushAction } from 'connected-react-router';
import Api, { callApi, makeApiGenerator } from 'domain/api';

export const getCurrentPlan_apiGen = makeApiGenerator({
  actionCreator: currentPlanAction,
  api: Api.getCurrentPlan,
});

export function* ensureCurrentPlan() {
  const subscription = yield select(currentPlanSel);
  if (!subscription || !subscription.shortName) {
    yield fork(getCurrentPlan_apiGen.catchError);
  }
}

// { data }
const updateAccount_apiGen = makeApiGenerator({
  actionCreator: updateAccountAction,
  api: Api.updateAccount,
  getSuccessData: ({ data, headers }) => ({ payload: { ...data, token: headers['x-token'] } }),
});

export function* onUpdateAccount({ payload }, reinit = true) {
  try {
    const { token } = yield call(updateAccount_apiGen, { data: payload });
    yield call(setStorageItem, 'token', token);
    if (reinit) {
      const formData = yield select(accountFormInitialData_sel);
      yield put(initialize(FORM_ACCOUNT, formData));
    }
    const title = translate('notification.accountData.saved.success');
    yield put(addNotification({ title, type: 'success', showTime: 10000 }));
  } catch (err) {
    yield put(addNotification({ title: 'Changes were not saved.', type: 'error' }));
  }
}

export function* updatePreferredCurrency({ payload }) {
  const data = yield select(getFormInitialValues(FORM_ACCOUNT));
  yield call(onUpdateAccount, { payload: { ...data, ...payload } }, false);
}

// { data }
const acceptCreateNewPassword_apiGen = makeApiGenerator({
  actionCreator: changePasswordAction,
  api: Api.newAccPassword,
});

export function* onChangePasswordAction({ payload }) {
  try {
    yield call(acceptCreateNewPassword_apiGen, { data: payload });
  } catch (err) {
    yield put(addNotification({ title: 'Changes were not saved.', type: 'error' }));
  }
}

// { data }
const changePassword_apiGen = makeApiGenerator({
  actionCreator: changePasswordAction,
  api: Api.changePassword,
});

export function* onChangePassword({ payload }) {
  const action = payload.currentPassword ? 'changed' : 'created';
  try {
    yield call(changePassword_apiGen, { data: payload });
    yield put(addNotification({ title: `Password was ${action}.`, type: 'success' }));
    yield put(reset('password'));
  } catch (err) {
    yield put(addNotification({ title: `Password was not ${action}.`, type: 'error' }));
  }
}

export function* onUploadPicture(action) {
  try {
    const formData = yield new FormData();
    formData.append('file', action.payload);
    yield put({ type: uploadPictureAction.request });
    const { data } = yield callApi(Api.uploadPicture, { data: formData });
    const payload = yield select(getFormInitialValues(FORM_ACCOUNT));
    yield call(onUpdateAccount, { payload: { ...payload, picture: data[0] } }, false);
  } catch (err) {
    yield put({
      type: uploadPictureAction.failure,
      err: 'Can\'t upload photo',
    });
  }
}

export function* removePicture() {
  const payload = yield select(getFormInitialValues(FORM_ACCOUNT));
  yield call(onUpdateAccount, { payload: { ...payload, picture: '' } }, false);
}

// no params
const ensureStripeProperties_apiGen = makeApiGenerator({
  actionCreator: fetchStripePropertiesAction,
  api: Api.getStripeProperties,
  ensure: true,
  ensureSelector: function* ensureStripeToken() {
    return yield select(stripeApiTokenSelector);
  },
});

export function* stripeInit() {
  const authorized = yield select(isAuthorized);
  if (authorized) {
    yield fork(ensureStripeProperties_apiGen.catchError);
  }
  yield takeEvery([checkAuthAction.success, loginAction.success], ensureStripeProperties_apiGen.catchError);
}

// { data }
const saveStripeCard_apiGen = makeApiGenerator({
  actionCreator: saveStripeCardAction,
  api: Api.saveStripeCard,
});

export function* saveCardToken(requestData, callBack) {
  try {
    yield call(saveStripeCard_apiGen, { data: requestData });
    yield put(addNotification({ title: 'Card information was saved.', type: 'success' }));
    if (callBack) yield call(callBack);
  } catch (err) {
    const title = err.response ? err.response.data.message : 'Card information was not saved.';
    yield put(addNotification({ title, type: 'error' }));
  }
}

export function* onAuthSuccess(resp) {
  const token = resp.headers['x-token'];
  if (token) {
    setStorageItem('token', token);
    yield put({
      type: loginAction.success,
      payload: resp.data,
      headers: resp.headers,
    });
    const redirectUrl = (yield select(requestedLocation)) || (yield select(homePageLink_sel));
    yield put(routerReplace(redirectUrl));
    yield put(resetRequestedLocation());
  }
  return token;
}

export const checkAuth_apiGen = makeApiGenerator({
  actionCreator: checkAuthAction,
  api: Api.checkAuth,
});

export function* onCheckAuth({ payload: { resolve, reject } }) {
  try {
    const data = yield call(checkAuth_apiGen);
    resolve(data);
  } catch (err) {
    try {
      const { status } = parseServerError(err);
      // disable loading for authPage/registerPage
      // see callAPi
      if (status === 403 || status === 401) {
        reject({ err, unblock: true });
      } else {
        yield put(pushAction(`/${status}`));
        if (!status) {
          console.log({ err });
          console.error(err);
        }
      }
    } catch (e) {}
  }
}
