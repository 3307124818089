import { ERROR_MESSAGE_COLOR, ERROR_COLOR } from 'theme/theme';

export default {
  message: {
    fontSize: 12,
    fontWeight: 400,
    marginTop: 3,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: ERROR_MESSAGE_COLOR,
    '&.artObjCreatedAt': {
      overflow: 'unset',
    },
    '&.artObjConceptionDate': {
      overflow: 'unset',
    },
    '&.provenance': {
      overflow: 'unset',
      whiteSpace: 'normal',
      textAlign: 'left',
    },
    '&.sizeField': {
      overflow: 'unset',
      whiteSpace: 'normal',
      textAlign: 'left',
    },
    '&.rtv-form-coupon.upgrade-subscription': {
      textTransform: 'none',
      whiteSpace: 'normal',
    },
    '&.salesHistory-priceSold': {
      overflow: 'unset',
    },
    '&.privacyPopupCheckbox, &.AskEmailPopup': {
      whiteSpace: 'normal',
    },
    '&.SignUpPageField.privacyTermsAccept': {
      whiteSpace: 'normal',
    },
  },
  warning: {
    color: ERROR_COLOR,
  },
  error: {
    color: ERROR_COLOR,
  },
};
