import React from 'react';
import I from 'immutable';
import PropTypes from 'prop-types';
import { connect } from 'cpcs-reconnect';
import injectSheet from 'lib/sheet';
import Popup from 'components/popup';
import { isPopupVisibleSelector, getGalleryByPopupParamsSelector } from 'domain/ui/UIModel';
import { hidePopupAction } from 'domain/ui/UIActions';
import sheet from './sheet';
import RenameForm from 'pages/common/popupForms/rename';
import Title from 'pages/common/popup/title';
import { RENAME_GALLERY_POPUP } from 'domain/const';

class Rename extends React.PureComponent {

  static propTypes = {
    gallery: PropTypes.instanceOf(I.Record),
    classes: PropTypes.shape({
      container: PropTypes.string,
    }).isRequired,
    isPopupVisible: PropTypes.func.isRequired,
    cancel: PropTypes.func.isRequired,
  }

  onClose = () => {
    this.props.cancel();
  }

  render() {
    const { classes, isPopupVisible, gallery } = this.props;
    if (!isPopupVisible(RENAME_GALLERY_POPUP)) return null;
    return (
      <Popup>
        <div
          className={classes.container}
        >
          <Title
            ico={gallery.get('picture')}
            children="Rename gallery"
            onClose={this.onClose}
          />
          <RenameForm
            cancel={this.onClose}
            item={gallery}
            initialValues={{ title: gallery.get('title') || '' }}
          />
        </div>
      </Popup>
    );
  }
}

Rename = injectSheet(sheet)(Rename);
export default connect({
  isPopupVisible: isPopupVisibleSelector,
  gallery: getGalleryByPopupParamsSelector,
  cancel: hidePopupAction,
})(Rename);
