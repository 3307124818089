import { field } from 'theme/form';
import { CALENDAR_Z_INDEX } from 'theme/zIndex';

export default {
  DateField: {
    // root
  },
  field,
  catchFocusWrapper: {
    // html tag for js onFocus, onBlur
    '&.portal': {
      position: 'fixed',
      zIndex: CALENDAR_Z_INDEX,
    },
  },
};
