import { socialIcon } from '../theme';
import bg from './images/google.svg';
import bgHover from './images/googleHover.svg';

export default {
  GoogleLoginButton: {
    extend: socialIcon,
    border: '1px solid #DC4E41',
    background: {
      image: `url("${bg}")`,
      size: '24px 24px',
      repeat: 'no-repeat',
      position: 'center',
    },
    '&:hover': {
      background: `#DC4E41 url("${bgHover}") center no-repeat`,
    },
  },
};
