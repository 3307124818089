import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import I from 'immutable';
import { lnk } from 'lib/routes';
// import { hasSubscription } from 'lib/helpers';
// import { SUBSCRIPTION_TYPE_ENTERPRISE } from 'domain/const';
import focused from 'components/generic/focused';
import cx from 'classnames';

import injectSheet from 'lib/sheet';
import sheet from './sheet';

const muteEvent = e => {e.preventDefault(); e.stopPropagation();};

function UserMenu({ classes, logOut, containerProps, onHide, profile }) {
  if (!profile) return null;
  const nameSurnameLetters = profile.get('firstname').charAt(0) + profile.get('lastname').charAt(0);
  return (
    <div className={classes.UserMenu} {...containerProps} onClick={muteEvent}>
      <div className={classes.userMenuHeader}>
        {
          profile.picture ?
            <img className={classes.ava} src={profile.picture} alt={nameSurnameLetters} />
            : <div className={classes.nameSurnameLetters} children={nameSurnameLetters} />
        }
        <div className={classes.nameSurname}>{profile.get('firstname')} <br/> {profile.get('lastname')}</div>
      </div>
      <ul className={classes.userMenuList} >
        {
          /** /
          !hasSubscription(profile.subscriptionType) &&
            <li className={classes.menuItem}>
              <Link
                to={lnk('upgradeSubscriptionLink')}
                className={cx(classes.btn, classes.upgrade)}
                onClick={onHide}
                children="Upgrade Plan"
              />
            </li>
          /**/
        }
        {
          /** /
          hasSubscription(profile.subscriptionType) && profile.subscriptionType !== SUBSCRIPTION_TYPE_ENTERPRISE &&
            <li className={classes.menuItem}>
              <Link
                to={lnk('billing')}
                className={classes.btn}
                onClick={onHide}
                children="Billing"
              />
            </li>
          /**/
        }
        <li className={classes.menuItem}>
          <Link
            to={lnk('accountPage')}
            className={classes.btn}
            onClick={onHide}
            children="My Account"
          />
        </li>
        <li className={classes.menuItem}>
          <button
            type="button"
            onClick={logOut}
            className={cx(classes.btn, classes.signOut)}
            children="Sign Out"
          />
        </li>
      </ul>
    </div>
  );
}

UserMenu.propTypes = {
  classes: PropTypes.shape({
    UserMenu: PropTypes.string.isRequired,
    btn: PropTypes.string.isRequired,
    menuItem: PropTypes.string.isRequired,
    userMenuHeader: PropTypes.string.isRequired,
    ava: PropTypes.string.isRequired,
    nameSurnameLetters: PropTypes.string.isRequired,
    nameSurname: PropTypes.string.isRequired,
    userMenuList: PropTypes.string.isRequired,
    signOut: PropTypes.string.isRequired,
  }).isRequired,
  logOut: PropTypes.func.isRequired,
  containerProps: PropTypes.object.isRequired,
  onHide: PropTypes.func.isRequired,
  profile: PropTypes.instanceOf(I.Collection),
};

const UserMenuThemed = compose(
  injectSheet(sheet),
  focused,
)(UserMenu);

export { UserMenuThemed as UserMenu };
