import { DIVIDER_COLOR } from 'theme/theme';

export default {
  CheckboxList: {
    '&.AOFormStamps': {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      width: 'calc(100% + 20px)',
    },
    '&.advancedFilter': {
        marginTop: 17,
      '&.categories': {
      },
      '&.mediums': {
        marginTop: 15,
        borderTop: `1px solid ${DIVIDER_COLOR}`,
        paddingTop: 15,
      },
    },
  },
  listItem: {
    '&.AOFormStamps': {
      marginRight: 20,
    },
    '&.advancedFilter': {
      marginTop: 15,
      '&:first-child': {
        marginTop: 0,
      },
      '&.categories': {
        marginTop: 6,
      },
    },
  },
};
