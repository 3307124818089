import rules, { addError } from 'components/form/validation';
import I from 'immutable';
import { compose } from 'redux';

const PASSWORD_MIN_LENGTH = 6;

const fields = ['currentPassword', 'newPassword'];

const validator = values => (errors, fieldName) => {
  const val = values[fieldName];
  switch (fieldName) {
    case 'currentPassword':
      return compose(
        err => addError(err, fieldName, rules.required(val)),
      )(errors);
    case 'newPassword':
      return compose(
        err => addError(err, fieldName, rules.strMinLength(PASSWORD_MIN_LENGTH, 'Size')(val)),
        err => addError(err, fieldName, rules.required(val)),
      )(errors);
    default:
      return errors;
  }
};

export default values => fields.reduce(validator(values), {});

export const errorMessages = I.fromJS({
  currentPassword: {
    NotNull: 'May not be null',
    NotMuch: 'Incorrect current password',
    Size: `Password must be ${PASSWORD_MIN_LENGTH} characters or more`,
  },
  newPassword: {
    NotNull: 'May not be null',
    NotMuch: 'Incorrect current password',
    Size: `Password must be ${PASSWORD_MIN_LENGTH} characters or more`,
  },
});
