/* eslint no-unused-vars: warn */
import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'cpcs-reconnect';
import moment from 'moment';

import { sizeSolver } from 'pages/common/artworkView/solvers/sizeSolver';
import { rtv, currencySymbolMap } from 'lib/units';
import { residenceToStr } from 'pages/common/artworkView/solvers/countriesListSolver';
import { countriesById_sel } from 'domain/country/CountryModel';
import { categoryById_sel } from 'domain/category/CategoryModel';
import { surfacesById_sel } from 'domain/surface/SurfaceModel';
import { substratesById_sel } from 'domain/substrate/SubstrateModel';
import { signatureById_sel } from 'domain/signatures/SignaturesModel';

import { splitTextToLines, Text } from 'pages/highcharts/helpers/Text';
import { connectEventsContainer } from 'pages/highcharts/helpers/EventsContext';
import { RefSize } from 'pages/highcharts/helpers/refSize';
import { DEFAULT_FONTFAMILY } from 'pages/highcharts/helpers/PDFConstants';


class AOMainPDF extends RefSize {
  static propTypes = {
    PDFState: PropTypes.shape({
      // contentSections: PropTypes.instanceOf(I.Collection),
    }).isRequired,
  };

  dict = (v, list) => list.getIn([v, 'title']) || list.getIn([`${v}`, 'title']) || 'undefined';

  dicts = (vs = [], list) => vs.map(v => this.dict(v, list)).filter(Boolean).join(', ');

  render() {
    const { artwork, countries, category, surface, substrate, signature, PDFState } = this.props;
    const titleLines = splitTextToLines(artwork.title, 20, true);
    const associatedCountryLines = splitTextToLines(`Associated Country: ${residenceToStr(artwork, countries)}`, 36);
    const categoryById = category;
    let top = 0;
    const topAdd = (v) => ((top += Math.max(0, v)) && null);
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="270"
        height="245"
        ref={this.ref}
        data-top="94"
        data-left="310"
      >
        <style>
          {`
          .AOMainPDF_artistName { font: 500 12px ${DEFAULT_FONTFAMILY}; fill: #1E202C; }
          .AOMainPDF_artworkName { font: 700 16px ${DEFAULT_FONTFAMILY}; fill: #1E202C; }
          .AOMainPDF_ABVLabel { font: 400 12px ${DEFAULT_FONTFAMILY}; fill: #1E202C; }
          .AOMainPDF_ABV { font: 700 18px ${DEFAULT_FONTFAMILY}; fill: #304C95; }
          .AOMainPDF_description { font: 500 12px ${DEFAULT_FONTFAMILY}; fill: #1E202C; }
          .AOMainPDF_vals { font: 400 10px ${DEFAULT_FONTFAMILY}; fill: #1E202C; }
          `}
        </style>
        <g>
          <text y={top += 10} className="AOMainPDF_artistName">
            {artwork.artist.fullName}
          </text>
          <Text top={top += 21} className="AOMainPDF_artworkName" lines={titleLines} lineHeight="20" />
          {topAdd(20 * (titleLines.length - 1))}
          {
            !!PDFState.rtvShown &&
              <g>
                <text y={top += 31} className="AOMainPDF_ABVLabel">
                  ARTBnk Value ({moment(artwork.purchasedDate, 'YYYY-MM-DD').format('MMM DD, YYYY')})
                </text>
                <text y={top += 21} className="AOMainPDF_ABV">
                  {currencySymbolMap[artwork.rtvCurrency] || null}{rtv(artwork)}
                </text>
              </g>
          }
          <text y={top += 30} className="AOMainPDF_description">
            Description
          </text>
          <Text top={top += 22} className="AOMainPDF_vals" lines={associatedCountryLines} lineHeight="10" />
          {topAdd(10 * (associatedCountryLines.length - 1))}
          <text y={top += 15} className="AOMainPDF_vals">
            Category: {this.dict(artwork.category, categoryById)}
          </text>
          <text y={top += 15} className="AOMainPDF_vals">
            Year Created: {moment(artwork.createdDate).format('YYYY')}
          </text>
          <text y={top += 15} className="AOMainPDF_vals">
            Surface: {this.dicts(artwork.surfaces, surface)}
          </text>
          <text y={top += 15} className="AOMainPDF_vals">
            Substrate: {this.dicts(artwork.substrates, substrate)}
          </text>
          <text y={top += 15} className="AOMainPDF_vals">
            Signature: {this.dict(artwork.signature, signature)}
          </text>
          <text y={top += 15} className="AOMainPDF_vals">
            Size: {sizeSolver(null, artwork, '', { category })}
          </text>
        </g>
      </svg>
    );
  }
}

AOMainPDF = compose(
  connectEventsContainer,
  connect({
    countries: countriesById_sel,
    category: categoryById_sel,
    surface: surfacesById_sel,
    substrate: substratesById_sel,
    signature: signatureById_sel,
  }),
)(AOMainPDF);

export { AOMainPDF };
