import { call, put, select, takeEvery } from 'redux-saga/effects';
import { reduceCallFrequency } from 'lib/saga-helpers';
import * as A from 'domain/considering/ConsideringActions';
import * as M from 'domain/considering/ConsideringModel';
import I from 'immutable';
import { parseId, toggleByIdInSet } from 'lib/helpers';
import Api, { callApi, makeApiGenerator } from 'domain/api';
import { lnk } from 'lib/routes';
import { artworkSaver } from 'domain/ownArtwork/sagas';
import { ensureGallery_apiGen } from 'domain/galleries/sagas';
import { addNotification, addBreadCrumbs } from 'domain/env/EnvActions';
import {
  // routerParams_sel,
  queryParams,
  getMatch,
  query_sel,
} from 'domain/router/RouterModel';
import { user_sel, currentGalleryId_sel } from 'domain/env/EnvModel';

/**
 * yield trackingSaver(...)(data, id)
 * factory returns generator, that takes params: data and id
**/
function trackingSaver({ apiGenerator, actionCreator, successTitle, failureTitle, catchErr, fullPayload = false, link = 'collectionItemPage', noDefaultGalleryId }) {
  const saver = artworkSaver({
    apiGenerator,
    actionCreator,
    successTitle,
    failureTitle,
    fullPayload,
    link,
    catchErr,
    noDefaultGalleryId,
  });
  return function* (apiProps) {
    const query = yield select(query_sel);
    let galleryId = query && query.galleryId;
    if (!galleryId) {
      const { defaultNewConsiderationGallery } = yield select(user_sel);
      galleryId = defaultNewConsiderationGallery;
    }
    const data = apiProps.data || {};
    return yield call(saver, { ...apiProps, data: { ...data, galleryId: data.galleryId || galleryId } });
  };
}

export const createConsideration_apiGen = trackingSaver({
  apiGenerator: Api.createConsideration,
  actionCreator: A.createConsiderationAction,
  successTitle: 'Artwork added to Watchlist.',
  failureTitle: 'Artwork not created',
});

export const createSuggestedConsideration_apiGen = trackingSaver({
  apiGenerator: Api.createSuggestedConsideration,
  actionCreator: A.createSuggestedConsiderationAction,
  successTitle: 'Artwork added to Watchlist.',
  failureTitle: 'Artwork not created',
});

export const createConsiderationOriginal_apiGen = trackingSaver({
  apiGenerator: Api.createConsiderationOriginal,
  actionCreator: A.createConsiderationAction,
  successTitle: 'Artwork added to Watchlist.',
  failureTitle: 'Artwork not created',
  link: null,
  catchErr: function* (err) {
    const { response: { data: { message } = {} } = {} } = err || {};
    if (!message) {
      return false;
    }
    if (message === 'You already add this artwork to the considerations') {
      yield put(addNotification({ title: 'Artwork has already been tracked', type: 'success' }));
      return true;
    }
    if (message === 'You already own this artwork') {
      yield put(addNotification({ title: 'You already own this artwork', type: 'error' }));
      return true;
    }
    return false;
  },
});

export const updateConsiderations_apiGen = trackingSaver({
  apiGenerator: Api.updateConsiderations,
  actionCreator: A.updateConsiderationAction,
  successTitle: 'Tracking updated.',
  failureTitle: 'Tracking not updated',
});

export const createDraft_apiGen = trackingSaver({
  apiGenerator: Api.createDraftConsideration,
  actionCreator: A.saveDraftConsiderationAction,
  successTitle: 'Artwork added to Watchlist.',
  failureTitle: 'Artwork not created',
  fullPayload: true,
  noDefaultGalleryId: true,
  link: 'collectionListPage',
});

export const addDraft_apiGen = trackingSaver({
  apiGenerator: Api.addDraftConsideration,
  actionCreator: A.saveDraftConsiderationAction,
  successTitle: 'Artwork added to Watchlist.',
  failureTitle: 'Artwork not created',
  fullPayload: true,
  noDefaultGalleryId: true,
  link: 'collectionListPage',
});

export const updateDraft_apiGen = trackingSaver({
  apiGenerator: Api.updateDraftConsideration,
  actionCreator: A.saveDraftConsiderationAction,
  successTitle: 'Tracking updated.',
  failureTitle: 'Tracking not updated',
  fullPayload: true,
  noDefaultGalleryId: true,
  link: 'collectionListPage',
});

// export function* authorHasArtworks() {
//   try {
//     const { authorId } = yield select(routerParams_sel);
//     const { data } = yield callApi(Api.fetchConsiderations, { params: { artists: [authorId] } });
//     return data.content.length > 0;
//   } catch(err) {
//     yield put(addNotification({ title: 'Something went wrong', type: 'error' }));
//   }
// }

function fetchActionCreator(actionCreator, apiFn) {
  return function* fetchConsiderations_apiGen(additionalParams = {}) {
    try {
      yield put({ type: actionCreator.request });
      const params = yield select(queryParams);
      const gId = yield select(currentGalleryId_sel);
      const { data } = yield callApi(apiFn, { gId, isConsideration: true, ...params, ...additionalParams });
      yield put({
        type: actionCreator.success,
        payload: data,
      });
    } catch (err) {
      yield put({
        type: actionCreator.failure,
        err,
      });
    }
  };
}
// { gId, page, size }
export const fetchConsiderations_apiGen = fetchActionCreator(A.fetchConsiderationsAction, Api.getGalleryArtworks);
// { gId, page, size }
export const considerationsAppendPage_apiGen = reduceCallFrequency(fetchActionCreator(
  A.considerationAppendPageAction,
  Api.getGalleryArtworks,
), 2100);

export function* deSelectTracking() {
  yield put(A.setSelectedAction(new I.Set()));
}

/**
 * @param { artworkId }
**/
const fetchConsiderationItem_apiGen = makeApiGenerator({
  api: Api.fetchConsiderationItem,
  actionCreator: A.fetchConsiderationItemAction,
});

export function* enrichArtwork(artworkId) {
  yield call(fetchConsiderationItem_apiGen.catchError, { artworkId });
  const artworks = yield select(M.artworksById);
  return artworks.get(parseId(artworkId));
}

export function* updateListItem({ payload: { artworkId } = {} } = {}) {
  const list = yield select(M.consideringList);
  if (!list.find(v => v.id === parseInt(artworkId, 10))) {
    return;
  }
  yield call(fetchConsiderationItem_apiGen.catchError, { artworkId });
}

/**
 * view, edit, new trackgin AO
**/
export function* singleItemPageBreadcrumbs() {
  let { params: { gId } = {} } = yield select(getMatch);
  const defaultGalleryId = (yield select(user_sel)).defaultNewConsiderationGallery;
  let breadcrumbs;
  if (gId && parseInt(gId, 10) !== defaultGalleryId) {
    const gal = yield call(ensureGallery_apiGen.catchError, { galleryId: gId });
    if (!gal) return;
    breadcrumbs = [
      { entity: gal.get('title'), link: lnk('collectionListPage', { gId }) },
    ];
  } else {
    breadcrumbs = [
      { entity: 'All art', link: lnk('collectionListPage') },
    ];
  }
  yield put(addBreadCrumbs(breadcrumbs));
}

function* selectItem(action) {
  const selectedIds = yield select(M.selected);
  yield put(
    A.setSelectedAction(
      toggleByIdInSet({ id: action.payload, idsSet: selectedIds }),
    ),
  );
}

export function* watchSelectItem() {
  yield takeEvery(A.updateSelectedAction.type, selectItem);
}
