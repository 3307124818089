import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { Field, reduxForm, SubmissionError } from 'redux-form';
import CheckBox from 'components/form/checkbox';
import Message from 'components/form/message';
import Password from 'components/form/Password';
import { setStorageType, SESSION_STORAGE, LOCAL_STORAGE } from 'lib/storage';
import { dispatchPromise, parseServerError } from 'lib/helpers';
import { loginAction } from 'domain/env/EnvActions';
import { FORM_LOGIN } from 'domain/const';
import { loginValidate as validate } from '../validation.js';
import SocialButtons from 'pages/authPage/elements/SocialButtons';
import FormRowField from 'pages/common/newPage/form/elements/FormRowField';
import { ContactSupportMessage } from 'components/errors/ContactSupport';
import { links as r } from 'lib/routes';

import injectSheet from 'lib/sheet';
import { AuthPageSheet as sheet } from '../sheet';

function submit(values, dispatch) {
  return dispatchPromise(
      (v) => dispatch(loginAction(v)),
      values,
    )
    .catch((err) => {
      const { message } = parseServerError(err);
      if ('Contact Support' === message) {
        throw new SubmissionError({
          _error: <ContactSupportMessage />,
        });
      }
      throw new SubmissionError({ _error: 'Invalid email or password' });
    });
}

class LoginForm extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.shape({
      button: PropTypes.string,
      item: PropTypes.string.isRequired,
      rememberMeLabel: PropTypes.string.isRequired,
      noAcc: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
    }).isRequired,
    error: PropTypes.node,
    handleSubmit: PropTypes.func.isRequired,
  }

  state = {
    rememberMe: true,
  }

  componentDidMount = () => {
    setStorageType(LOCAL_STORAGE);
    const firstField = document.querySelector('input[name=email]');
    if (firstField) firstField.focus();
  }

  handleChanger = () => {
    this.setState({ rememberMe: !this.state.rememberMe });
    setStorageType(this.state.rememberMe ? SESSION_STORAGE : LOCAL_STORAGE);
  }

  render() {
    const { classes, error, handleSubmit } = this.props;
    return (
      <form
        id="logIn"
        name="logIn"
        onSubmit={handleSubmit(submit)}
      >
        { process.env.NODE_ENV === 'test' && <SocialButtons /> }
        <Field
          name="email"
          modifier="authPageField"
          component={FormRowField}
          type="text"
          placeholder="Enter your email"
          autoComplete="email"
          rootTag="div"
          title="Email"
        />
        <Field
          name="password"
          modifier="authPageField"
          component={FormRowField}
          placeholder="Enter your password"
          autoComplete="new-password"
          Field={Password}
          rootTag="div"
          title="Password"
        />
        <Message meta={{ touched: !!error, error }} />
        <div className={classes.item}>
          <CheckBox
            input={{ name: 'checkbox', value: this.state.rememberMe, onChange: this.handleChanger }}
            modifier="AuthPage-rememberMe"
            data-value={this.state.rememberMe ? 'true' : 'false'}
          >
            <span className={classes.rememberMeLabel}>Remember me</span>
          </CheckBox>
          <Link to={r.forgot} className={cx(classes.link, 'forgotPass')}>Forgot Password?</Link>
        </div>
        <div>
          <button type="submit" className={cx(classes.button, 'AuthPage submit')} children="LOG IN" />
        </div>
        {/* <div className={classes.noAcc}>
          <span>Having issues login in?</span>
          <a href={CONTACT_US_URL} target="_blank" rel="noreferrer" className={classes.link}>Contact Us</a>
        </div> */}
      </form>
    );
  }
}

export default compose(
  reduxForm({
    form: FORM_LOGIN,
    validate,
  }),
  injectSheet(sheet),
)(LoginForm);
