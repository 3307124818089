export default {
  socialButtons: {
    margin: [35, -5, 0],
    display: 'flex',
  },
  socialButton: {
    flex: '1 1 0px',
    margin: [5, 5, 0],
  },
  socialFormSeparator: {
    position: 'relative',
    margin: [30, 0],
    width: '100%',
    textAlign: 'center',
    textTransform: 'uppercase',
    '&:before': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: 8,
      width: 145,
      height: 1,
      background: '#d8d8d8',
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      right: 0,
      top: 8,
      width: 145,
      height: 1,
      background: '#d8d8d8',
    },
  },
};
