import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'cpcs-reconnect';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import { Field, reduxForm, SubmissionError } from 'redux-form';

import Checkbox from 'components/form/checkbox';

import { createErrorMessage } from 'components/form/validation';
import PrivacyAndTermsLabel from 'pages/authPage/elements/PrivacyAndTermsLabel';
import { registerAction } from 'domain/env/EnvActions';
import { FORM_SIGN_UP } from 'domain/const';
import { countriesValidSel } from 'domain/country/CountryModel';
import { signupValidate as validate } from '../validation.js';
import FormRowField from 'pages/common/newPage/form/elements/FormRowField';
import Select from 'components/form/select';
import Password from 'components/form/Password';
import { formatOptionLabel } from 'pages/authPage/form/fields';
import { EmailAlreadyInUseMessage } from 'components/errors/EmailAlreadyInUse';

import injectSheet from 'lib/sheet';
import { AuthPageSheet as sheet } from '../sheet';

/**
 * function exported for tests purpose only
**/
export function submit(data, dispatch) {
  const submitPromise = new Promise((resolve, reject) => {
    dispatch(registerAction({ data, resolve, reject }));
  });
  return submitPromise.catch((err) => {
    if (err === 'decline_terms') {
      throw new SubmissionError({ _error: 'Privacy policy and Terms of use not accepted!' });
    }
    if (err) {
      const { errors, message } = ((err || {}).response || {}).data || {};
      if (/^The email .+ is already in use$/.test(message)) {
        throw new SubmissionError({
          email: <EmailAlreadyInUseMessage />,
          _error: message,
        });
      }
      const parsedErrors = createErrorMessage(errors);
      if (parsedErrors.privacyTermsAccept === 'must be true') {
        throw new SubmissionError({ privacyTermsAccept: 'Accept ARTBnk\'s Privacy Policy and Subscription Terms to use our service' });
      }
      throw new SubmissionError({
        ...parsedErrors,
        _error: message,
      });
    }
  });
}

class SignUp extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.shape({
      buttonGroup: PropTypes.string.isRequired,
      button: PropTypes.string.isRequired,
      noAcc: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
      titleTrial: PropTypes.string.isRequired,
      names: PropTypes.string.isRequired,
      signUpLoginBtn: PropTypes.string.isRequired,
    }).isRequired,
    handleSubmit: PropTypes.func.isRequired,
    countryList: PropTypes.any,
  };

  get checkboxLabel() {
    return <PrivacyAndTermsLabel />;
  }

  componentDidMount = () => {
    const firstField = document.querySelector('input[name=firstname]');
    if (firstField) firstField.focus();
  }

  render() {
    const { classes, countryList = [] }  = this.props;
    return (
      <form
        id="signUp"
        name="signUp"
        onSubmit={this.props.handleSubmit(submit)}
      >
        <h2
          className={classes.titleTrial}
          children="Start Your FREE Account Now"
        />
        <ul>
          <li className={classes.names}>
            <Field
              name="firstname"
              placeholder="First Name"
              autoComplete="given-name"
              component={FormRowField}
              modifier="SignUpPageField SignUpPageField-names"
              rootTag="div"
            />
            <Field
              name="lastname"
              placeholder="Last Name"
              autoComplete="family-name"
              component={FormRowField}
              modifier="SignUpPageField SignUpPageField-names"
              rootTag="div"
            />
          </li>
          <Field
            name="email"
            placeholder="Email"
            autoComplete="email"
            component={FormRowField}
            modifier="SignUpPageField"
          />
          <Field
            name="password"
            modifier="SignUpPageField"
            component={FormRowField}
            placeholder="Password"
            autoComplete="new-password"
            Field={Password}
            helper="Type 6 characters or more"
          />
          <Field
            name="country"
            placeholder="Country"
            component={FormRowField}
            Field={Select}
            list={countryList}
            modifier="SignUpPageField--Select"
            formatOptionLabel={formatOptionLabel}
            isClearable={false}
            SelectStyleKey="AuthPageCountry"
            fixDropdownPadding
          />
          <Field
            name="marketingEmailConsent"
            component={FormRowField}
            Field={Checkbox}
            labelText="I consent to marketing communications from ARTBnk"
            modifier="SignUpPageField"
          />
          <Field
            name="privacyTermsAccept"
            component={FormRowField}
            Field={Checkbox}
            labelText={this.checkboxLabel}
            modifier="SignUpPageField privacyTermsAccept"
          />
        </ul>
        <div className={classes.buttonGroup}>
          <button
            type="submit"
            className={cx(classes.button, 'AuthPage submit')}
            children="Get Started - It's Free"
          />
        </div>
        <div className={classes.noAcc}>
          <span>Already registered? </span>
          <Link to="/auth/login" className={classes.signUpLoginBtn}>Log In</Link>
        </div>
      </form>
    );
  }
}

export default compose(
  reduxForm({
    form: FORM_SIGN_UP,
    validate,
    initialValues: {
      marketingEmailConsent: true,
      privacyTermsAccept: false,
    },
  }),
  connect({
    countryList: countriesValidSel,
  }),
  injectSheet(sheet),
)(SignUp);
