import { POPUP_Z_INDEX, PROCESSING_POPUP_Z_INDEX } from 'theme/zIndex';

const Popup = {
  position: 'fixed',
  left: 0,
  right: 0,
  bottom: 0,
  top: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: POPUP_Z_INDEX,
  '&.ProcessingPopup': {
    zIndex: PROCESSING_POPUP_Z_INDEX,
  },
  '&.mobileMenu': {
    alignItems: 'stretch',
    justifyContent: 'flex-end',
  },
  '@media screen and (max-width: 569px)': {
    '&.mobileMenu': {
      alignItems: 'flex-start',
    },
  },
};

const overlay = {
  position: 'absolute',
  display: 'block',
  width: '100%',
  left: 0,
  right: 0,
  bottom: 0,
  top: 0,
  background: 'rgba(0,0,0,0.4)',
  cursor: 'default',
  textDecoraton: 'none',
};

const popup = {
  //margin: 'auto',
  position: 'relative',
  backgroundColor: '#f7f7f7',
  zIndex: 2,
  height: 'auto',
  boxShadow: '0 2px 8px rgba(0, 0, 0, 0.2)',
  '&.mobileMenu': {
    width: '375px',
  },
  '@media screen and (max-width: 569px)': {
    '&.mobileMenu': {
      width: '100%',
    },
  },
  '&.select-comparables-popup': {
    width: 'calc(100% - 64px)',
    height: 'calc(100% - 64px)',
    overflow: 'hidden',
    '@media screen and (max-width: 569px)': {
      width: '100%',
      height: '100%',
    },
  },
};

export const sheet = {
  Popup,
  overlay,
  popup,
};
