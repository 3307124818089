import React from 'react';
import { SalesHistoryItem } from 'pages/common/artworkView/solvers/salesHistorySolver';
// import PropTypes from 'prop-types';
// import { soldPriceVal } from 'pages/common/artworkView/solvers/soldPriceSolver';

// import injectSheet from 'lib/sheet';

// const sheet = {};

// function AppraisalVal({ appraisal, classes }) {
//   const soldPrice = soldPriceVal(appraisal, { unsold: null });
//   return (
//     <ul>
//       {
//         soldPrice !== null &&
//           <li children={soldPrice} />
//       }
//     </ul>
//   );
// }

// const AppraisalValThemed = injectSheet(sheet)(AppraisalVal);

export default function appraisalSolver(appraisal, data, fieldName, { auctions } = {}) {
  if (!appraisal) {
    return null;
    //   return {
    //   val: null,
    //   title: null,
    //   custom: true,
    // };
  }
  if (appraisal) {
    return {
      // val: <GetRtv artwork={data} />,
      // val: <AppraisalValThemed appraisal={appraisal} />,
      val: <SalesHistoryItem lot={appraisal} auctions={auctions} isAppraisal RootTag="div" />,
      // modifier: 'getRtvBtn',
      // title: appraisal.priceType === 'APPRAISAL' ? 'Appraised value' : 'Purchase price',
      custom: true,
    };
  }
}
