import record, { integer, string, listOf } from 'cpcs-recordjs';
import { field, selector } from 'cpcs-reconnect';
import { listToMap } from 'lib/serialize';
import * as A from './ConditionsActions';

const Condition = record('Condition', {
  id: integer(0),
  title: string(),
});

const State = record('Conditions', {
  content: listOf(Condition),
});

export const conditions = field('conditions');
export const conditionList = conditions.then(State.$content);
export const conditionById = selector(conditionList, listToMap);

export const reducer = {
  conditions(state = new State(), action) { //NOSONAR
    switch (action.type) { //NOSONAR

      case A.fetchConditionAction.success:
        return state.apply(
          State.$content.set(
            State.$content.type.parse(action.payload),
          ),
        );

      default:
        return state;
    }
  },
};
