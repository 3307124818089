import { DARK_BLUE_COLOR, DEFAULT_FONTFAMILY } from 'theme/theme';

export default {
  Hint: {
    position: 'relative',
    display: 'inline-block',
    maxWidth: '100%',
    '&:hover $popover': {
      display: 'block',
    },
    '&.AOForm-file': {
      display: 'flex',
    },
  },
  popover: {
    position: 'absolute',
    display: 'none',
    whiteSpace: 'nowrap',
    bottom: -30,
    left: -30,
    height: 22,
    opacity: 0.7,
    padding: [5, 10, 4],
    backgroundColor: DARK_BLUE_COLOR,
    lineHeight: '13px',
    fontFamily: DEFAULT_FONTFAMILY,
    fontSize: '11px',
    letterSpacing: '0.9px',
    textAlign: 'center',
    color: '#ffffff',
    '&.addAlertByArtistId': {
      fontWeight: 400,
      backgroundColor: '#000',
      left: '100%',
      bottom: 30,
    },
    '&.notAvailableHint': {
      top: -10,
      left: '100%',
      width: 460,
      height: 88,
      whiteSpace: 'normal',
      textAlign: 'left',
      backgroundColor: '#000',
    },
    '&.AOForm-file': {
      bottom: 'unset',
      top: -25,
      left: 20,
      '&.first': {
        top: -15,
      },
    },
  },
};
