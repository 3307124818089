import React from 'react';
import PropTypes from 'prop-types';
import { Chart } from 'components/reports/Chart/Chart';
import injectSheet from 'lib/sheet';
import cx from 'classnames';
import { DEFAULT_FONTFAMILY_3 } from 'theme/theme';

const sheet = {
  TotalLotsSold: {
    //
  },
  additionalLegend: {
  },
  additionalLegendList: {
    display: 'flex',
    margin: [15, 0, 0, 30],
  },
  additionalLegendItem: {
    position: 'relative',
    margin: [0, 35, 0, 0],
    padding: [0, 0, 0, 18],
    fontFamily: DEFAULT_FONTFAMILY_3,
    fontSize: 16,
    fontWeight: 500,
    '&:before': {
      content: '""',
      position: 'absolute',
      top: 3,
      left: 0,
      height: 12,
      width: 12,
      padding: [10, 0, 0, 0],
      display: 'inline-block',
      borderRadius: '50%',
      background: 'red',
      marginRight: 10,
      boxSizing: 'border-box',
    },
    '&.allArtists': {
      '&:before': {
        background: '#FF9F40',
      },
    },
    '&.artworksOfComparableMedium': {
      '&:before': {
        background: '#015DD3',
      },
    },
  },
};

function TotalLotsSold({ classes, artworkId, artistId, chartId, legend }) {
  const chartUnder = (
    <div className={classes.additionalLegend}>
      <ul className={classes.additionalLegendList}>
        {
          legend.map((title, key) => (
            <li
              className={cx(classes.additionalLegendItem, title)}
              key={key}
              children={title === 'allArtists' ? 'All Artist Artworks' : 'Artworks of Comparable Medium'}
            />
          ))
        }
      </ul>
    </div>
  );
  return (
    <div className={classes.TotalLotsSold}>
      <Chart
        chartId={chartId}
        artworkId={artworkId}
        artistId={artistId}
        chartUnder={chartUnder}
      />
    </div>
  );
}

TotalLotsSold.propTypes = {
  classes: PropTypes.shape({
    TotalLotsSold: PropTypes.string.isRequired,
    additionalLegend: PropTypes.string.isRequired,
    additionalLegendList: PropTypes.string.isRequired,
    additionalLegendItem: PropTypes.string.isRequired,
  }).isRequired,
  artworkId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  legend: PropTypes.arrayOf(PropTypes.string).isRequired,
  chartId: PropTypes.string.isRequired,
  artistId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

TotalLotsSold = injectSheet(sheet)(TotalLotsSold);

export { TotalLotsSold };
