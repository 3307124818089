import React from 'react';
import { compose } from 'redux';
import { connect } from 'cpcs-reconnect';

import { artistSelector } from 'domain/artwork/ArtworkModel';

import { connectEventsContainer } from 'pages/highcharts/helpers/EventsContext';
import { RefSize } from 'pages/highcharts/helpers/refSize';
import { DEFAULT_FONTFAMILY } from 'pages/highcharts/helpers/PDFConstants';
import { renderText, Text } from 'pages/highcharts/helpers/Text';

const SECTIONS = {
  intro: {
    lines: [
      'The following is a comprehensive analysis of recent financial',
      'performance of {ARTIST_NAME} based on transactions from the world’s',
      'leading auction houses. Powered by artificial intelligence and machine',
      'learning, ARTBnk’s data-driven approach to financial performance',
      'analysis illuminates the state of the artist’s market and equips collectors',
      'with the necessary knowledge to make informed financial decisions.',
    ],
    descriptionFontSize: 12,
    descriptionLineHeight: 16,
  },
  financialPerformance: {
    lines: [
      'This section provides an in-depth look into an artist’s financial market',
      'performance through advanced metrics using the repeat sales methodology.',
    ],
    descriptionFontSize: 10,
    descriptionLineHeight: 12,
  },
  marketMovement: {
    lines: [
      'This section provides an overview of the artist’s market activity over the past decade.',
    ],
    descriptionFontSize: 10,
    descriptionLineHeight: 12,
  },
  ARTBnkIndexes: {
    lines: [
      'ARTBnk Indexes are based on the repeat sales methodology first used in the Case-Shiller Real Estate Index and',
      'then applied to the art market in 2002 by Jianping Mei, PhD and current ARTBnk Senior Advisor, Michael Moses,',
      'PhD. ARTBnk’s suite of indexes can be used to compare the financial performance of the art market as a whole',
      'and its sub-categories against other asset markets',
    ],
    descriptionFontSize: 10,
    descriptionLineHeight: 12,
  },
};

const DESCRIPTION_MARGIN_TOP = 10;
const TITLE_LINE_HEIGHT = 24;

class SectionHeader extends RefSize {
  static propTypes = {
    //
  };

  render() {
    const { item, artist } = this.props;
    const { title, name } = item;
    let { lines, descriptionFontSize: dfs, descriptionLineHeight: TEXT_LINE_HEIGHT } =
      SECTIONS[item.contentSection] || { lines: [] };
    const fullName = (artist && artist.fullName) || '';
    lines = lines.map(l => l.replace(/\{ARTIST_NAME\}/gi, fullName));
    // text, length, className, lineHeight, left = 0, top = 0, lines, props = {}
    const { content: titleText, lines: titleLines } = renderText({
      text: title,
      length: 20,
      lineHeight: TITLE_LINE_HEIGHT,
      className: `SectionHeader_${name}_title`,
      left: 0,
      top: TITLE_LINE_HEIGHT / 2,
      props: { alignmentBaseline: 'middle' },
    });
    return (
      <svg
        ref={this.ref}
        width="570"
        heigth={150}
        data-top="94"
        data-margin-left="24"
        className="SectionHeader"
      >
        <style>
          {`
          .SectionHeader_${name}_title { font: bold 24px ${DEFAULT_FONTFAMILY}; fill: #333; }
          .SectionHeader_${name}_description { font: 400 ${dfs}px ${DEFAULT_FONTFAMILY}; fill: #333; }
          `}
        </style>
        {titleText}
        <Text
          lines={lines}
          lineHeight={TEXT_LINE_HEIGHT}
          className={`SectionHeader_${name}_description`}
          left="0"
          top={titleLines.length * TITLE_LINE_HEIGHT + DESCRIPTION_MARGIN_TOP + TEXT_LINE_HEIGHT / 2}
          props={{ alignmentBaseline: 'middle' }}
        />
      </svg>
    );
  }
}

SectionHeader = compose(
  connectEventsContainer,
  connect({
    artist: artistSelector,
  }),
)(SectionHeader);

export { SectionHeader };
