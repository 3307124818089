import {
  LAYOUT_RANGE_425_MAX,
  LAYOUT_RANGE_1024_MIN,
  LAYOUT_RANGE_1024_MAX,
} from 'theme/layout/LAYOUT';

export const innerFormItem = {
  // outline: '2px dotted #ffeb3b',
  display: 'flex',
  alignItems: 'start',
  position: 'relative',
  // NEW_ART_PAGE
  '&.AOForm.artObjCreatedAt': {
    width: '100%',
  },
  [`@media screen and (max-width: ${LAYOUT_RANGE_1024_MAX}px) and (min-width: ${LAYOUT_RANGE_1024_MIN}px)`]: {
    '&.AOForm.artObjCreatedAt, &.AOForm.artObjConceptionDate': {
      display: 'block',
      minWidth: 0,
    },
  },
  // '&.artObjConceptionDate': {
  //   flex: '0 0 184px',
  // },
  [`@media screen and (max-width: ${LAYOUT_RANGE_425_MAX}px)`]: {
    display: 'block',
  },
};
