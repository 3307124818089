import { takeEvery, call } from 'redux-saga/effects';
import Api, { makeApiGenerator } from 'domain/api';
import { forgotPasswordAction } from 'domain/env/EnvActions';

const sendRecoveryEmail_apiGen = makeApiGenerator({
  actionCreator: forgotPasswordAction,
  api: Api.forgotPassword,
});


function* onForgotPassword({ payload: { data, resolve, reject } }) {
  try {
    yield call(sendRecoveryEmail_apiGen, { data });
    resolve();
  } catch (err) {
    reject(err);
  }
}

export default function* forgotPage() {
  yield takeEvery(forgotPasswordAction.type, onForgotPassword);
}
