import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import translate from 'lib/translate';
import { daysFromNowToDate } from 'lib/helpers';

import injectSheet from 'lib/sheet';
import sheet from './sheet';

const renderLabel = (classes) => (amount, message) => {
  return (
    <React.Fragment>
      <span className={classes.day}>{amount || message }</span>
      { amount > 0 && <span>{ message }</span> }
    </React.Fragment>
  );
};

class DayLabel extends React.PureComponent {
  static propTypes = {
    date: PropTypes.string,
    maxDaysToShow: PropTypes.number,
    classes: PropTypes.shape({
      DayLabel: PropTypes.string,
      day: PropTypes.string,
    }).isRequired,
    modifier: PropTypes.string,
  };

  interval = null;

  componentDidMount() {
    this.interval = setInterval(this.forceUpdate.bind(this), 60 * 60 * 1000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    const { classes, date, modifier, maxDaysToShow = 30 } = this.props;
    const day = daysFromNowToDate(date);
    if (day === null || day > maxDaysToShow || day < 0) {
      return null;
    }
    return (
      <div className={cx(classes.DayLabel, modifier)}>
        {translate('dayLeft', day, renderLabel(classes))}
      </div>
    );
  }
}

export default injectSheet(sheet)(DayLabel);
