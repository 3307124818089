export default {
  breadcrumbs: {
    display: 'none',
    marginLeft: -10,
  },
  filters: {
    display: 'flex',
  },
  content: {

  },
};
