import { similarSearchAction } from 'pages/common/artworkForm/ArtworkFormActions';
import { getAuthorsAction } from 'domain/author';
import {
  getSharedArtworkListAction,
} from 'domain/shares';

const actions = [
  getAuthorsAction,
  similarSearchAction,
  getSharedArtworkListAction,
];

export default {
  requests: actions.map(fn => fn.request),
  responses: actions.reduce((p, fn) => [ ...p, fn.success, fn.failure ] ,[]),
};
