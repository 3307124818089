import messageSheet from 'components/form/message/sheet';
import { DEFAULT_FONTFAMILY } from 'theme/theme';

export const btnGroup = {
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: 25,
  marginRight: -15,
  '&.Popup-RenameGallery': {
    marginTop: 40,
  },
};

export default {
  SharingForm: {
    // root
    margin: [0, -5],
  },
  RenameForm: {
    // RenameForm root
  },
  createGalleryForm: {
    '&.addToGalleryPopup': {
      flex: '1 1 0px',
    },
  },
  hidden: {
    display: 'none',
  },
  fieldset: {
    border: '0 none',
    margin: [30, 0],
  },
  legend: {
    font: `400 14px/18px ${DEFAULT_FONTFAMILY}`,
  },
  list: {
    position: 'relative',
    marginTop: 10,
  },
  item: {
    display: 'inline-block',
    width: 'calc(50% - 5px)',
    '& input': {
      position: 'absolute',
    },
  },
  itemOdd: {
    marginRight: 10,
  },
  btnGroup,
  // send: {
  //   ...Buttons.done,
  //   margin: [0, 5],
  // },
  // cancel: {
  //   ...Buttons.cancel,
  //   margin: [0, 5],
  // },
  message: {
    ...messageSheet.message,
    ...messageSheet.warning,
    whiteSpace: 'normal',
  },
};
