import addIco from 'pages/common/newPage/form/images/plus-in-circle.svg';
import removeIco from 'pages/common/newPage/form/images/minus-in-circle.svg';
import { AO_FORM_BUTTON, DEFAULT_FONTFAMILY } from 'theme/theme';
import { FOCUSED_STYLE } from 'theme/focus';

const BTN_HOVER_BG = 'rgba(0, 0, 0, 0.07)';

const button = {
  cursor: 'pointer',
  lineHeight: '16px',
  color: AO_FORM_BUTTON,
  fontFamily: DEFAULT_FONTFAMILY,
  fontSize: 14,
  fontWeight: 400,
  letterSpacing: 1,
  textAlign: 'center',
  padding: [7, 27, 7, 37],
  minWidth: 'auto',
  display: 'inline-block',
  height: 30,
  whiteSpace: 'nowrap',
  background: {
    repeat: 'no-repeat',
    position: '13px center',
    color: 'transparent',
  },
  '&:hover': {
    backgroundColor: BTN_HOVER_BG,
  },
  '&:focus': {
    ...FOCUSED_STYLE,
  },
};

export const AOFormSheet = {
  button,
  add: {
    width: 30,
    height: 30,
    padding: 0,
    textIndent: '37px',
    position: 'relative',
    '&:before': {
      content: '""',
      display: 'block',
      width: 30,
      height: 30,
      position: 'absolute',
      left: 0,
      top: 0,
      borderRadius: '50%',
      background: {
        ...button.background,
        position: 'center',
        image: `url("${addIco}")`,
      },
    },
    '&:hover': {
      backgroundColor: 'transparent',
      '&:before': {
        backgroundColor: BTN_HOVER_BG,
      },
    },
    '&:focus': {
      outline: 'none',
      boxShadow: 'none',
      '&:before': {
        ...FOCUSED_STYLE,
      },
    },
  },
  remove: {
    width: 30,
    height: 30,
    padding: 0,
    position: 'relative',
    borderRadius: '50%',
    background: {
      ...button.background,
      position: 'center',
      image: `url("${removeIco}")`,
    },
    '&.mobile-XS': {
      display: 'none',
    },
    '@media screen and (max-width: 350px)': {
      '&.mobile-XS': {
        display: 'inline-block',
        verticalAlign: 'bottom',
      },
    },
  },
};
