import { field } from 'theme/form';
import { DEFAULT_FONTFAMILY } from 'theme/theme';
import { AUTOCOMPLETE_WIDGET_Z_INDEX } from 'theme/zIndex';
const boxShadow = '0 0 0 2px #a2c3fa';

export const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};

const SelectStyleMap = {
  control: {
    padding: 0,
    AOForm: {
    },
    AuthPageCountry: {
      height: 44,
    },
    BillingCountryField: {
      height: 44,
    },
    AccountCountryField: {
      height: 44,
    },
    BuyRtvBillingField: {
      height: 44,
    },
  },
  valueContainer: {
    AuthPageCountry: {
      padding: '0 16px',
    },
    BillingCountryField: {
      padding: '0 16px',
    },
    AccountCountryField: {
      padding: '0 16px',
    },
    BuyRtvBillingField: {
      padding: '0 16px',
    },
  },
};

const getStyleByKey = (node = 'control', key) => ({
  ...((node && SelectStyleMap[node]) || {}),
  ...((key && SelectStyleMap[node] && SelectStyleMap[node][key]) || {}),
});

/**
 * container.field > control > valueContainer
**/
export const styles = ({ meta: { touched, error }, isClearable = true, grouped = false, SelectStyleKey }) => ({
  container: style => ({
    ...style,
    borderRadius: 2,
    fontFamily: DEFAULT_FONTFAMILY,
    boxSizing: 'border-box',
    border: touched && error ? 'solid 1px red' : 'solid 1px #e6e6e6',
  }),
  placeholder: style => ({ ...style, color: '#000000', opacity: 0.5 }),
  dropdownIndicator: style => ({ ...style, cursor: 'pointer', transform: 'scale(0.8)', padding: '' }),
  clearIndicator: style => ({ ...style, cursor: 'pointer', transform: 'scale(0.8)', padding: '' }),
  multiValue: style => ({ ...style, backgroundColor: 'transparent' }),
  multiValueLabel: style => ({ ...style, color: '#000000', paddingLeft: 0, padding: '', fontSize: 'inherit' }),
  indicatorSeparator: style => (isClearable ? style : { display: 'none' }),
  indicatorsContainer: style => ({ ...style }),
  multiValueRemove: () => ({
    backgroundColor: 'transparent',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'flex-end',
    opacity: 0.5,
    paddingBottom: 1,
  }),
  option: style => ({
    ...style,
    cursor: 'pointer',
    fontSize: '14px',
    textOverflow: 'ellipsis',
    textAlign: 'left',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    padding: grouped ? '6px 12px 6px 24px' : '6px 12px',
    lineHeight: '18px',
  }),
  menu: style => ({ ...style, zIndex: AUTOCOMPLETE_WIDGET_Z_INDEX, marginTop: 0, marginBottom: 0, borderRadius: 2, boxShadow }),
  menuList: style => ({ ...style, paddingTop: 10, paddingBottom: 10 }),
  //groupHeading: () => ({}),
  //group: () => ({}),
  control: (style, { isDisabled, disabled, isFocused }) => ({
    ...style,
    ...field,
    opacity: (isDisabled || disabled) ? 0.5 : 1,
    // minHeight: ((SelectStyleKey && field[`&.${SelectStyleKey}`]) || {}).height,
    minHeight: 30,
    height: '100%',
    border: 'none',
    boxShadow: isFocused ? boxShadow : 'none',
    boxSizing: 'border-box',
    flexWrap: 'nowrap',
    ...getStyleByKey('control', SelectStyleKey),
  }),
  valueContainer: style => ({
    ...style,
    // minHeight: ((SelectStyleKey && field[`&.${SelectStyleKey}`]) || { height: 30 }).height,
    minHeight: 30,
    width: '100%',
    minWidth: 0,
    // outline: '2px solid red',
    ...getStyleByKey('valueContainer', SelectStyleKey),
  }),
  singleValue: style => ({ ...style, width: '100%' }),
  input: style => ({ ...style, boxSizing: 'border-box', minWidth: '40px', overflow: 'hidden' }),
});

export const flatStyles = ({ alignedLeft, ...props } = {}) => ({
  ...styles({ ...props, meta: {}, isClearable: false }),
  container: style => ({
    ...style,
    fontFamily: DEFAULT_FONTFAMILY,
    border: 'none',
  }),
  valueContainer: style => ({
    ...style,
    width: '100%',
    padding: '2px',
    paddingRight: 0,
    display: 'flex',
    flexWrap: 'nowrap',
  }),
  // input: style => ({ ...style, display: 'none', position: 'absolute', }),
  singleValue: style => ({
    ...style,
    width: '100%',
    paddingRight: 0,
    position: 'static',
    transform: 'initial',
    top: 'initial',
    maxWidth: 'initial',
  }),
  menu: style => ({
    ...style,
    width: 'auto',
    right: alignedLeft ? undefined : 0,
    left: alignedLeft ? 0 : undefined,
    zIndex: AUTOCOMPLETE_WIDGET_Z_INDEX,
  }),
  control: style => ({
    ...style,
    backgroundColor: 'transparent',
    minHeight: 'auto',
    height: 'auto',
    border: 'none',
    boxShadow: 'none',
    flexWrap: 'nowrap',
  }),
});
