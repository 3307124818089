import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'cpcs-reconnect';
import { compose } from 'redux';
import I from 'immutable';
import cx from 'classnames';
import { hidePopupAction } from 'domain/ui/UIActions';
import Popup from 'components/popup';
import Title from 'pages/common/popup/title';
import SelectGallery from './SelectGallery';
import injectSheet from 'lib/sheet';
import styles from './sheet.js';

class ChooseOrCreate extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    galleryList: PropTypes.instanceOf(I.List).isRequired,
    onSelect: PropTypes.func.isRequired,
    title: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.string,
    ]).isRequired,
    children: PropTypes.node,
    onCancel: PropTypes.func.isRequired,
  };

  onCancel = () => this.props.onCancel();

  render() {
    const { classes, galleryList, title, onSelect, children } = this.props;
    return (
      <Popup>
        <div className={cx(classes.container, 'addToGalleryPopup')}>
          <Title
            children={title}
            onClose={this.onCancel}
          />
          <SelectGallery
            list={galleryList}
            onSelect={onSelect}
          >{children}</SelectGallery>
        </div>
      </Popup>
    );
  }
}
export default compose(
  injectSheet(styles),
  connect({
    onCancel: hidePopupAction,
  }),
)(ChooseOrCreate);
