import { cancel, fork, put, select, race, take, takeEvery } from 'redux-saga/effects';
import { query_sel, pathnameChanged } from 'domain/router/RouterModel';
import { get404_sel } from 'domain/ui/UIModel';
import { err404Action } from 'domain/ui/UIActions';
import { isAuthorized, navigationBlocked_sel, homePageLink_sel } from 'domain/env/EnvModel';
import { addBreadCrumbs, checkAuthAction } from 'domain/env/EnvActions';
import { matchRoute, lnk } from 'lib/routes';
import { push } from 'connected-react-router';

import collectionListPage from 'pages/collectionListPage/sagas';
import favoriteArtistsPage from 'pages/favoriteArtistsPage/sagas';
import accountPage from 'pages/accountPage/sagas';
import billingPage from 'pages/billingPage/sagas';
import insightPage from 'pages/insightPage/sagas';
import saleLotsListPage from 'pages/saleLotsListPage/sagas/saga';
import sharesPage from 'pages/sharesPage/sagas';
import sharedItemsPage from 'pages/SharedItemsPage/sagas';
import newArtPage, { editArtPage } from 'pages/newArtPage/sagas';
import sharedArtworkPage from 'pages/SharedArtworkPage/sagas';
import sharedWithMePage from 'pages/sharedWithMePage/sagas';
import collectionItemPage from 'pages/collectionItemPage/sagas';
import sharedByMePage from 'pages/sharedByMePage/sagas';
import signUpPage from 'pages/authPage/sagas/signup';
import loginPage from 'pages/authPage/sagas/login';
import confirmEmailOnSNRegPage from 'pages/authPage/sagas/confirmEmailOnSNReg';
import confirmEmailOnEmailChangePage from 'pages/authPage/sagas/confirmEmailOnEmailChange';
import confirmEmailOnStdRegPage from 'pages/authPage/sagas/confirmEmailOnStdReg';
import forgotPage from 'pages/forgotPage/saga';
import resetPage from 'pages/resetPage/saga';
import { navigator as preferencesPage } from 'pages/Preferences/saga';
import artistPage from 'pages/artistPage/sagas';
import artistReportsPage from 'pages/artistReportsPage/saga/saga';
import artworkPage from 'pages/artworkPage/sagas';
import rtvValueListPage from 'pages/rtvValueListPage/sagas/sagas';
import analyticsListPage from 'pages/analyticsListPage/sagas/sagas';
import addArtUnifiedPage from 'pages/addArtUnifiedPage/saga';
import { pathNameChangedAction } from 'domain/router/RouterAction';
import intelligencePage from 'pages/intelligencePage/saga';
import highchartsPage from 'pages/highcharts/saga';
import createPassword from 'pages/createPassword/saga';

const TEST = process.env.NODE_ENV === 'test';

const routeSaga = {
  homePage: favoriteArtistsPage,
  artistPage: artistPage,
  artistReportsPage: artistReportsPage,
  artworkPage: artworkPage,
  // aliases
  newArtworkFromHP: newArtPage,
  // alert (keep to allow <Redirect ... /> work and avoid 404 error page)
  alertList: () => null,
  lotPage: () => null,
  alertAOsPage: () => null,
  // art, author
  artNew: newArtPage,
  artEdit: editArtPage,
  collectionItemPage: collectionItemPage,
  collectionItemPage_oldLink: collectionItemPage,
  collectionListPage: collectionListPage,
  collectionList_oldLink: collectionListPage,
  // shares
  shares: sharesPage,
  myShares: sharedByMePage,
  sharedItemsPage: sharedItemsPage,
  sharedGallery: sharedItemsPage,
  sharedArtwork: sharedArtworkPage,
  sharedWithMe: sharedWithMePage,
  // account, auth
  accountPage: accountPage,
  billing: billingPage,
  insightPage: insightPage,
  intelligencePage,
  highchartsPage,
  createPassword,
  signUp: signUpPage,
  forgot: forgotPage,
  reset: resetPage,
  authSocialNetwork: loginPage,
  auth: loginPage,
  preferencesPage: preferencesPage,
  // used to finish SN registration
  confirmEmailOnSNReg: confirmEmailOnSNRegPage,
  // used to finish standard registration
  confirmEmailOnStdReg: confirmEmailOnStdRegPage,
  // used to finish email changing
  confirmEmailOnEmailChange: confirmEmailOnEmailChangePage,
  rtvValues: rtvValueListPage,
  analyticsListPage: analyticsListPage,
  addArtUnifiedPage: addArtUnifiedPage,
  saleLotsListPage: saleLotsListPage,
};

let task;

const unauthorisedRoutes = [
  'signUp',
  'auth',
  'forgot',
  'confirmEmailOnSNReg',
  'confirmEmailOnStdReg',
  'confirmEmailOnEmailChange',
  'authSocialNetwork',
  'createPassword',
];

function* routeMatcher({ payload }) {
  if (yield select(get404_sel)) yield put(err404Action(false));
  if (yield select(pathnameChanged)) {
    yield put(addBreadCrumbs([]));
    yield put(pathNameChangedAction());
  }
  if (task) yield cancel(task);
  try {
    const matchRes = matchRoute(payload.location.pathname);
    const { route: { name }, match } = matchRes;
    const query = yield select(query_sel);
    const authorized = yield select(isAuthorized);
    const allowedNonAuthUrl = unauthorisedRoutes.includes(name) || matchRes.match.url.includes('/auth/');
    if (!authorized && !allowedNonAuthUrl && !TEST) {
      const result = yield race({
        auth: take(checkAuthAction.success),
        location: take('@@router/LOCATION_CHANGE'),
      });
      if (!result.auth) return;
    }
    const navigationBlocked = yield select(navigationBlocked_sel);
    if (navigationBlocked && name === 'homePage' && (!query || query.acct !== 'upgrade')) {
      const homePageLink = yield select(homePageLink_sel);
      yield put(push(homePageLink));
      return;
    }
    if (navigationBlocked && name !== 'accountPage' && name !== 'homePage' && name !== 'auth') {
      yield put(
        push(lnk('accountPage', { hash: 'billing' })),
      );
      return;
    }
    task = yield fork(routeSaga[name], { ...payload, query }, match);
    if (yield select(pathnameChanged) && !TEST) {
      window.scrollTo(0, 0);
    }
  } catch (e) {
    yield put(err404Action(true));
  }
}

export default function* navigator() {
  yield takeEvery('@@router/LOCATION_CHANGE', routeMatcher);
}
