import io from 'socket.io-client/dist/socket.io.js';
import { waitEnvConfig } from 'config';

let store = null;
// f.e. const printStatus = (str, ...args) => console.log(`%c [${str}]:`, 'color: #1c8c21; font-size: 10px', ...args);
const printStatus = () => null;

const SocketState = {
  connected: false,
  connection: null,
};

const Socket = {
  get connected() { return SocketState.connected; },
  connect: () => null,
  disconnect: () => {
    const connection = SocketState.connection;
    if (!connection) return;
    SocketState.connected = false;
    SocketState.connection = null;
    // connection.emit('error', { message: 'socket connection lost' });
    connection.disconnect();
    store.dispatch({ type: 'RTV_WS_NOTIFIER/SOCKET_DISCONNECT' });
  },
  setStore: (o) => store = o,
};

Socket.connect = async function socketConnect(token) {
  const { REACT_APP_NOTIFIER_HOST: WS_URL } = await waitEnvConfig();
  if ((SocketState.connection && SocketState.connected) || !WS_URL) return;
  printStatus('connect', token);
  // socket
  SocketState.connection = io.connect(WS_URL, {
    path: '',
    reconnectionDelay: 5000,
    reconnection: true,
    // reconnectionAttempts default Infinity
    // reconnectionAttempts: 3,
    randomizationFactor: 0,
    reconnectionDelayMax: 10000,
    query: `token=${token}`,
  });

  SocketState.connection
      .on('rtv_available', data => {
        try {
          // [rtv_available]: {userId: 1, artWorkId: 444444, event: "rtv_available"}
          printStatus('rtv_available', data);
          store.dispatch({
            type: 'RTV_WS_NOTIFIER/SOCKET_DATA/rtv_available',
            payload: { artworkId: data.artWorkId },
          });
        } catch (e) {
          console.error(e, 'on dispatch RTV_WS_NOTIFIER/SOCKET_DATA', { data });
        }
      })
      .on('connect', function() {
        // socket.emit(GET_CONFIGS_COMMAND);
        printStatus('socket соединение установлено');
        store.dispatch({ type: 'RTV_WS_NOTIFIER/SOCKET_CONNECTED' });
        SocketState.connected = true;
      })
      .on('disconnect', function() {
        if (!SocketState.connection) return;
        printStatus('socket соединение потеряно');
        store.dispatch({ type: 'RTV_WS_NOTIFIER/SOCKET_CONNECTION_LOST' });
        SocketState.connection.emit('error', { message: 'socket connection lost' });
        SocketState.connected = false;
        SocketState.connection = null;
      })
      .on('logout', function() {
        store.dispatch({
          type: 'RTV_WS_NOTIFIER/SOCKET_LOGOUT',
          payload: { err: { message: 'logout', code: 401, status: 401 } },
        });
      })
      .on('error', function(err) {
        if (typeof err === 'string') {
          try {
            err = JSON.parse(err);
          } catch (e) {
            err = { message: err };
          }
        }
        store.dispatch({
          type: 'RTV_WS_NOTIFIER/SOCKET_ERROR',
          payload: { err },
        });
      });
};

export default Socket;
