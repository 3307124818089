import queryString from 'query-string';
import { compose } from 'redux';

export const defaultParams = {
  priceFrom: 1,
  priceTo: 100000000,
};

export const init = {
  artists: [],
  categories: [],
  surfaces: [],
  substrates: [],
  mediums: [],
  currency: 'USD',
  ...defaultParams,
};

export function initForm(search, values) {
  const query = queryString.parse(search, { arrayFormat: 'bracket' });
  return { ...init, ...values, ...query };
}

const isEmpty = (value) => ![value].join('');

export function cleanEmpty(hashTable) {
  return Object
    .keys(hashTable)
    .reduce((collect, key) => (!isEmpty(hashTable[key]) ? { ...collect, [key]: hashTable[key] } : collect), {});
}


const keys = ['priceFrom','priceTo'];

export function cleanInit(init) {
  return (hashTable) => {
    return Object
      .keys(hashTable)
      .reduce((collect, key) => {
        if (keys.includes(key) && hashTable[key] === init[key]) return collect;
        return { ...collect, [key]: hashTable[key] };
      }, {});
  };
}

export function paramsStringify(p) {
  return queryString.stringify(p, { arrayFormat: 'bracket' });
}

export function cleanParams(params, filterParams = {}) {
  return compose(
    paramsStringify,
    cleanInit({ ...init, ...cleanEmpty(filterParams) }),
    cleanEmpty,
  )(params);
}
