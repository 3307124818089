import React from 'react';
import PropTypes from 'prop-types';
import Radio from 'components/form/radio';
import { LAYOUT_RANGE_375_MAX } from 'theme/layout/LAYOUT';
import cx from 'classnames';
import injectSheet from 'lib/sheet';

const sheet = {
  RadioBox: {
    display: 'flex',
    justifyContent: 'space-between',
    [`@media screen and (max-width: ${LAYOUT_RANGE_375_MAX}px)`]: {
      '&.AOForm-isPrivate.AOForm.appraisal': {
        marginTop: 15,
      },
    },
  },
};

function renderRadio({ meta, input, disabled, val, modifier }) {
  const { value: inputValue, onChange } = input;
  const { value = val, label = val } = typeof val === 'object' ? val : { };
  const props = {
    meta,
    input: {
      ...input,
      onChange: () => onChange(value),
      value: value,
      checked: `${inputValue}` === `${value}`,
    },
  };
  return (
    <Radio
      key={`radio-${value}`}
      {...props}
      type="radio"
      value={value}
      disabled={disabled}
      modifier={modifier}
    >{label}</Radio>
  );
}

renderRadio.propTypes = {
  modifier: PropTypes.string,
  disabled: PropTypes.bool,
  val: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.node,
    }),
  ]),
  input: PropTypes.shape({
    value: PropTypes.string,
    onChange: PropTypes.func,
  }).isRequired,
  meta: PropTypes.shape({}),
};
/**
 * @param {Object} fieldProps
 * @param {Array} fieldProps.values - [{ value, label }, ...]
**/
function RadioBoxNoTheme({ input, meta, classes, values, modifier, disabled = false }) {
  return (
    <div className={cx(classes.RadioBox, modifier)}>
      { values.map(val => renderRadio({ input, meta, disabled, val, modifier })) }
    </div>
  );
}

RadioBoxNoTheme.propTypes = {
  disabled: PropTypes.bool,
  values: PropTypes.array.isRequired,
  classes: PropTypes.shape({
    RadioBox: PropTypes.string,
  }).isRequired,
  input: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    onChange: PropTypes.func,
  }).isRequired,
  meta: PropTypes.shape({}),
  modifier: PropTypes.string,
};

export const RadioBox = injectSheet(sheet)(RadioBoxNoTheme);
