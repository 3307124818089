import { DARK_BLUE_COLOR, DEFAULT_FONTFAMILY } from 'theme/theme';

export default {
  NothingFound: {
    // root
    opacity: 0.3,
    color: DARK_BLUE_COLOR,
    fontFamily: DEFAULT_FONTFAMILY,
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '29px',
    marginTop: 110,
    textAlign: 'center',
  },
};
