import { ORANGE_COLOR } from 'theme/theme';

export default {
  container: {
    width: 330,
    marginTop: 40,
    fontSize: '14px',
    '@media screen and (max-width: 360px)': {
      width: '100%',
    },
  },
  bold: {
    textTransform: 'uppercase',
    fontWeight: 500,
  },
  link: {
    textDecoration: 'underline',
    color: ORANGE_COLOR,
  },
  middle: {
    marginTop: 20,
  },
  bottom: {
    marginTop: 50,
  },
};
