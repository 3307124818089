import React from 'react';
import PropTypes from 'prop-types';
import I from 'immutable';
import { connect } from 'cpcs-reconnect';
import { compose } from 'redux';
import cx from 'classnames';
import { isPopupVisibleSelector, getPopupParamsSelector, hidePopupAction } from 'domain/ui';
import Popup from 'components/popup';
import ShareForm from 'pages/common/popupForms/share';
import { SHARE_POPUP } from 'domain/const';
import Title from 'pages/common/popup/title';

import injectSheet from 'lib/sheet';
import { SharePopup as sheet } from 'pages/common/popup/sheet';

class ShareSingleArtworkPopup extends React.PureComponent {

  static propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    classes: PropTypes.shape({
      container: PropTypes.string,
      titleWrapper: PropTypes.string,
      ico: PropTypes.string,
      title: PropTypes.string,
    }).isRequired,
    artworksById: PropTypes.instanceOf(I.Map).isRequired,
    hidePopupAction: PropTypes.func.isRequired,
    popupParams: PropTypes.instanceOf(I.Map),
    isPopupVisible: PropTypes.func.isRequired,
    header: PropTypes.string,
  };

  static defaultProps = {
    header: 'Share work of art',
  };

  get id() {
    return parseInt(this.props.id, 10);
  }

  get artwork() {
    const { artworksById }  = this.props;
    const id = this.id;
    if (isNaN(id)) throw new Error('Incorrect artwork id');
    return artworksById.get(id, new I.Map());
  }

  onCancel = () => this.props.hidePopupAction();

  render() {
    const { classes, popupParams, isPopupVisible, header } = this.props;
    if (!isPopupVisible(SHARE_POPUP)) return null;
    const checkboxes = popupParams.get('checkboxMap').toJS();
    const checkboxInitVals = Object.keys(checkboxes)
      .reduce((s, n) => ({ ...s, [n]: !checkboxes[n] }), {});
    const init = { emails: [], ids: [ this.id ], ...checkboxInitVals };
    return (
      <Popup>
        <div className={cx(classes.container, 'ShareSingleArtworkPopup')}>
          <Title
            ico={this.artwork.getIn(['pictures', 0, 'name'])}
            children={header}
            onClose={this.onCancel}
          />
          <ShareForm
            initialValues={init}
            cancel={this.onCancel}
          />
        </div>
      </Popup>
    );
  }
}

export default compose(
  connect({
    hidePopupAction,
    popupParams: getPopupParamsSelector,
    isPopupVisible: isPopupVisibleSelector,
  }),
  injectSheet(sheet),
)(ShareSingleArtworkPopup);
