import { DEFAULT_BUTTON_COLOR, DEFAULT_FONTFAMILY } from 'theme/theme';
import { formTitle } from 'theme/form/formTitle';
import { button } from 'theme/button';

import upload from './images/upload_new.svg';
import remove from './images/remove.svg';


export default {
  Avatar: {
    '&:after': {
      content: '""',
      display: 'block',
      clear: 'both',
    },
  },
  title: formTitle,
  button,
  dropZone: {
    cursor: 'pointer',
    width: 200,
    height: 200,
    marginTop: 10,
    position: 'relative',
    background: {
      image: `url("${upload}")`,
      size: 'cover',
      repeat: 'no-repeat',
      position: 'center center',
    },
    '& $subTitle': {
      display: 'none',
    },
    '@media screen and (max-width: 375px)': {
      margin: [10, 'auto', 0],
    },
  },
  dropZoneEmpty: {
    border: '2px dashed #babcbf',
    backgroundSize: '90px 60px',
    background: {
      image: `url("${upload}")`,
      size: 'cover',
      repeat: 'no-repeat',
      position: 'center',
    },
    '& $removeIco': {
      display: 'none',
    },
    '& $subTitle': {
      display: 'block',
    },
  },
  changeAvatarBtn: {
    display: 'block',
    margin: [14, 'auto', 0],
    color: '#304C95',
    font: `400 14px/18px ${DEFAULT_FONTFAMILY}`,
    textDecoration: 'underline',
  },
  uploadError: {
    fontFamily: DEFAULT_FONTFAMILY,
    fontSize: 14,
    lineHight: '18px',
    color: 'red',
    marginTop: 10,
  },
  removeIco: {
    width: 20,
    height: 20,
    display: 'block',
    position: 'absolute',
    top: 15,
    right: 15,
    cursor: 'pointer',
    background: {
      image: `url("${remove}")`,
      repeat: 'no-repeat',
      position: 'center center',
      size: [14, 14],
    },
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  subTitle: {
    fontSize: 14,
    fontWeight: 500,
    textAlign: 'center',
    padding: 10,
    width: '100%',
    color: '#babcbf',
    '& mark': {
      background: 'none',
      color: DEFAULT_BUTTON_COLOR,
    },
  },
  subTitleSmall: {
    fontSize: 11,
  },
};
