/* eslint-disable no-template-curly-in-string */
// eslint-disable-next-line no-unused-vars
import moment from 'moment';

import { debounce } from 'lib/envGlobals';
import Api from 'domain/api';
import { TooltipLot } from 'components/reports/TooltipLot';
const x001colours = {
  'All Artists': '#007AFF',
  'Liquid Artists': '#FFA600',
  'Active Artists': '#984A9E',
  'Frequent Artists': '#33B8FF',
  'Infrequent Artists': '#F26EA0',
  'Illiquid Artists': '#00D7BC',
};
const x002colours = {
  'All Art Auction FMV': '#007AFF',
  'High Market Art Auction FMV': '#FFA600',
  'Middle Market Art Auction': '#33B8FF',
  'Low Market Art Auction': '#304C95',
};
const x003colours = {
  'PWC - Post-War/Contemporary Art': '#007AFF',
  'IMP - Impressionist/Modern Art': '#FFA600',
  'AMP - American Art': '#33B8FF',
  'BRP - British Art': '#FF4CE2',
  'LAP - Latin American Art': '#304C95',
  'OMP - Old Masters Art ': '#00D7BC',
};
const getLiquidityIndexesConfig = ({ title, subtitle, definition, interpretation, api, colours, chartId }) => {
  const valueSuffix = ['x001', 'x001.10', 'x001.5', 'x001.1', 'x003', 'x003.10', 'x003.5', 'x003.1']
    .includes(chartId) ? '' : '%';
  return {
    ticket: 'VIZ-31',
    type: 'spline',
    title,
    subtitle,
    definitions: [definition, interpretation],
    api,
    // apiProps: ['artworkId', 'artistId', 'chartId', 'triggerClick'],
    apiProps: ['artworkId', 'artistId', 'chartId'],
    normalize: [
      // () => row => ({
      //   ...row,
      //   data: row.data.map(([year, v]) => [Date.UTC(year, year % 1 ? 5 : 0), v]),
      // }),
      normalizers.setColors(colours),
      () => (row) => {
        return { ...row, marker: { enabled: false } };
      },
    ],
    stockChart: true,
    chartProps: {
      // colors: ['#007AFF', '#FFA600'],
      navigator: {
        enabled: true,
      },
      plotOptions: {
        spline: {
          marker: {
            enabled: true,
            symbol: 'circle',
          },
        },
      },
      tooltip: {
        split: false,
        // pointFormat: `<span style="color: {point.color}">&#x25CF;</span> {series.name}: <b>{point.y:,.2f}${valueSuffix}</b>`,
        valueSuffix,
        valueDecimals: 2,
      },
      scrollbar: defaultProps.scrollbar,
      rangeSelector: {
        buttons: [],
        ...defaultProps.rangeSelectorInput,
      },
      yAxis: {
        title: {
          text: undefined,
        },
        opposite: false,
        // linkedTo: 0,
        labels: {
          format: `{value:.0f}${valueSuffix}`,
          style: axiosLabelStyle,
        },
        // tickAmount: 6,
        showLastLabel: true,
      },
      xAxis: {
        // tickInterval: 10,
      },
    },
  };
};

const preview = (chartId, type = 'scatter', definitions) => ({
  [chartId]: {
    // ticket: 'ART-14',
    type,
    title: `preview ${chartId}`,
    // stockChart: true,
    definitions,
    api: chartId.startsWith('m') ? Api.getMarketMovementSeries : Api.getMarketPerformanceSeries,
    apiProps: ['artistId', 'chartId'],
    chartProps: {
      chart: {
        zoomType: 'x',
      },
      // colors: ['#304C95', '#FFA600'],
      xAxis: {
        type: 'category',
        // tickInterval: 5,
        // tickInterval: 1000 /* ms */ * 60 /* sec */ * 60 /* min */ * 24 /* hours */ * 365 /* days */ * 5 /* years */,
        labels: {
          // format: '{value:%Y-%m-%d}',
          // formatter: function (...args) {
          //   return (new Date(this.value)).getFullYear();
          // },
          style: axiosLabelStyle,
        },
      },
      yAxis: {
        opposite: false,
        zoomEnabled: false,
        title: {
          text: undefined,
        },
        labels: {
          // format: '{value:.0f}%',
          style: axiosLabelStyle,
        },
      },
    },
  },
});

const defaultProps = {
  scrollbar: {
    // enabled: true,
    barBackgroundColor: '#C1C7DE',
    barBorderColor: '#C1C7DE',
    buttonBackgroundColor: '#FFFFFF',
    buttonBorderColor: '#737992',
    rifleColor: '#FFFFFF',
    buttonArrowColor: '#A6ACC4',
  },
  rangeSelectorInput: {
    inputEnabled: true,
    labelStyle: {
      color: '#1E202C',
      fontWeight: 'bold',
    },
    inputBoxBorderColor: '#A6ACC4',
    inputBoxHeight: 24,
    inputBoxWidth: 104,
    inputStyle: {
      color: '#1E202C',
      fontWeight: '400',
      fontSize: '12px',
    },
  },
};

// eslint-disable-next-line
const oneSplineToLine = (dotType = 'scatter') => () => {
  let lineCounter = 0;
  let splineCounter = 0;
  return (row) => {
    let type = row.type === 'scatter' ? dotType : 'line';
    switch (row.type) {
      case 'line':
        type = lineCounter > 0 ? 'spline' : 'line';
        lineCounter++;
        break;
      case 'spline':
        type = splineCounter > 0 ? 'line' : 'spline';
        splineCounter++;
        break;
      default:
        break;
    }
    return {
      name: row.name,
      data: row.data,
      type,
    };
  };
};

const normalizers = {
  xYearToMilliseconds: () => row => ({
    ...row,
    data: row.data.map(([year, v]) => [Date.UTC(year), v]),
  }),
  setColors: colors => () => row => ({ ...row, color: colors[row.name] }),
  rename: (table) => () => row => ({ ...row, name: table[row.name] || row.name }),
  showMarkers: () => (row) => ({ ...row, marker: { enabled: true } }),
};


// eslint-disable-next-line no-unused-vars
const updateOptionsLoadLot = ({ options, series, props, onTooltipShow, onMouseOut, onTooltipFreeze, refCurrent }) => {
  const { token, auctionList } = props;
  const loadLot = debounce(async function(lotId) {
    try {
      const lotResp = await Api.getLotInfo({ token, lotId });
      const { data: { auction: auctionId } = {} } = lotResp || {};
      return { ...lotResp.data, auction: { id: auctionId, title: auctionList.getIn([auctionId, 'title']) } };
    } catch (err) {
      console.error('updateOptionsLoadLot', { err });
    }
  }, 600, { usePromise: true });
  const mapSeries = ({ chart, lotId, lot }) => {
    let updatedPoint = false;
    let point = null;
    let foundAtRowIndex = null;
    let foundAtPointIndex = null;
    let updatedRow = null;
    chart.series.forEach((row, rowIndex) => {
      if (updatedPoint) return;
      const mappedRow = row.data.map((pointItem, pointIndex) => {
        if (pointItem.lotId === lotId) {
          foundAtRowIndex = rowIndex;
          updatedPoint = { ...pointItem.options, lot };
          foundAtPointIndex = pointIndex;
          // point = row.points[pointIndex];
          point = pointItem;
          return updatedPoint;
        }
        return pointItem.options;
      });
      if (!updatedPoint) return;
      updatedRow = mappedRow;
    });
    return { updatedPoint, rowIndex: foundAtRowIndex, updatedRow, point, pointIndex: foundAtPointIndex };
  };
  let hoveredLotId;
  let loading = false;
  const loadTooltip = debounce(async function({ coordinates }) {
    if (!this || !this.series || !this.options) return;
    // this.plotX, this.plotY, this.options.lotId
    // chart.series[0].setData([89,71,16,12,14]);
    // this.plotX this.series.chart.plotSizeX
    const { chart } = this.series;
    const { lotId } = this.options;
    try {
      loading = true;
      const lot = await loadLot(lotId);
      loading = false;
      if (hoveredLotId === lotId || refCurrent.freezeTooltip === lotId) {
        onTooltipShow({ lot, x: this.x, y: this.y, coordinates, lotId });
      }
      // eslint-disable-next-line no-unused-vars
      let { updatedRow, rowIndex, point, pointIndex } = mapSeries({ chart, lotId, lot });
      chart.series[rowIndex].setData(updatedRow);
    } catch (err) {
      loading = false;
      console.error('updateOptionsLoadLot::mouseOver', { err });
    }
  }, 300);
  const showTooltip = ({ isClick = false } = {}) => function() {
    if (!this || !this.options || !this.series) {
      console.warn('plotOptions.point.events - this.options unavailable', this);
      return;
    }
    const { lotId } = this.options;
    isClick && onTooltipFreeze(lotId);
    const coordinates = {
      x: this.plotX,
      y: this.plotY,
      // width: this.series.chart.plotSizeX,
      // height: this.series.chart.plotSizeY,
      width: this.series.chart.chartWidth,
      height: this.series.chart.chartHeight,
      plotLeft: this.series.chart.plotLeft,
      plotTop: this.series.chart.plotTop,
    };
    hoveredLotId = lotId;
    if (this.options.lot) {
      onTooltipShow({ lot: this.options.lot, x: this.x, y: this.y, coordinates, lotId });
      return;
    }
    onTooltipShow({ coordinates, lotId });
    // don't stop loading for freezed point on mouse hover events on other points until loaded
    if (!isClick && refCurrent.freezeTooltip && refCurrent.freezeTooltip !== lotId && loading) {
      return;
    }
    loadTooltip.call(this, { coordinates });
  };
  return {
    ...options,
    tooltip: {
      enabled: false,
    },
    plotOptions: {
      series: {
        point: {
          events: {
            mouseOut: function() {
              onMouseOut();
            },
            mouseOver: showTooltip(),
            click: showTooltip({ isClick: true }),
          },
        },
      },
    },
  };
};

const renderLabel = (confId) => function() {
  // const text = this.category + ': ' + this.y + ' was last selected';
  // const chart = this.series.chart;
  const labelText = 'Select a portion of the chart to zoom';
  const chart = this;
  const options = {
    p002: {
      y: 30,
    },
    p012: {
      y: 70,
    },
  };
  const { y } = options[confId];
  if (!chart.lbl) {
    chart.lbl = chart.renderer.label(labelText, 20, y)
      .attr({
        padding: 10,
        r: 3,
        fill: '#F0F2F6',
        // stroke: 'red',
        // 'stroke-width': 2,
        zIndex: 5,
      })
      .css({
        color: '#454A5F',
        // border: '2px solid red',
      })
      .add();
  } else {
    chart.lbl.attr({
        text: labelText,
    });
  }
};

const updatePieChartResponse = (resp) => {
  let { series } = resp;
  let other = [];
  let otherTotal = 0;
  let data = series.map(p => {
      if (p.data < 30 ) {
        other.push([p.name, p.data]);
        otherTotal += p.data;
        // const name = 'More';
        // return { name, drilldown: name, y: p.data };
      }
      // const name = p.name;
      // return { name, y: p.data };
      const name = p.data >= 30 ? p.name : 'More';
      // return { name, drilldown: name, y: p.data };
      return { name, drilldown: name === 'More' ? name : 'Else', y: p.data };
    })
    .filter(p => p.name !== 'More');
  data = [...data, { name: 'More', drilldown: 'More', y: otherTotal }];
  series = [{
    // name: 'Market Movement Reports',
    name: 'Auction Houses',
    data,
  }];
  const drilldown = { series: [
    { id: 'More', name: 'More', data: other },
    { id: 'Else', name: 'Else', data: [] },
  ] };
  return { ...resp, series, drilldown };
};

const axiosLabelStyle = {
  color: '#000',
  fontWeight: 500,
  fontSize: '12px',
};

export const chartsHash = {
  p001: {
    ticket: 'ART-13',
    type: 'area',
    title: 'CAGR Projected Value',
    subtitle: 'Today’s value based on past financial performance at auction.',
    definitions: ['P001_DEFINITION', 'P001_INTERPRETATION'],
    api: Api.getCAGRProjectedValue,
    apiProps: ['artworkId', 'artistId'],
    updateData: (resp) => {
      const rowOrder = {
        'Bottom 80% CAR': { order: 2, title: 'Bottom 80% CAGR' },
        'Middle 60% CAR': { order: 1, title: 'Middle 60% CAGR' },
        'Top 80% CAR': { order: 0, title: 'Top 80% CAGR' },
      };
      let series = [...resp.series];
      series = series
        .sort((a, b) => rowOrder[a.name].order - rowOrder[b.name].order)
        .map(row => ({ ...row, name: rowOrder[row.name].title }));
      return { ...resp, series };
    },
    // normalize: () => (row) => ({ ...row, data: [] }),
    // eslint-disable-next-line no-unused-vars
    updateOptions: ({ options, series, props }) => {
      if (!series || !series[0] || !series[0].data || !series[0].data[0] || !series[0].data[0][0]) {
        return {
          ...options,
          yAxis: options.yAxis.map(o => ({
            ...o,
            max: 250000,
            tickAmount: 6,
          })),
        };
      } else {
        return {
          ...options,
          yAxis: options.yAxis.map(o => ({
            ...o,
            max: undefined,
            tickAmount: 6,
          })),
        };
      }
    },
    // normalize: () => (row) => {
    //   return {
    //     name: row.name,
    //     data: row.data
    //       .map((v) => ({ x: v.date, y: v.price }))
    //       // .map((v) => ([ moment(v.date).utc().format('YYYY-MM-DD'), v.price ]))
    //       // .filter((v, index) => index !== (row.data.length - 1)),
    //   };
    // },
    chartProps: {
      // Top 80% CAGR → Hex code:  A6ACC4
      // Middle 60% CAGR → Hex code: 006FFF
      // Bottom 80% CAGR → Hex code: 33B8FF
      colors: ['#A6ACC4', '#006FFF', '#33B8FF'],
      chart: {
        // spacingRight: 100,
        marginRight: 100,
        // borderWidth: 1,
        events: {
          // render: (...args) => console.log('p001::render', args),
        },
      },
      tooltip: {
        headerFormat: '{point.x:%b %e, %Y}<br/>',
        pointFormat: '<span style="color: {point.color}">&#x25CF;</span> {series.name}: <b>${point.y:,.0f}</b>',
      },
      xAxis: {
        // type: 'category',
        type: 'datetime',
        tickInterval: 1000 /* ms */ * 60 /* sec */ * 60 /* min */ * 24 /* hours */ * 365 /* days */,
        labels: {
          // format: '{value:%Y-%m-%d}',
          format: '{value:%Y}',
          style: axiosLabelStyle,
        },
      },
      yAxis: [
        {
          title: {
            text: undefined,
          },
          labels: {
            // format: '${value:,.0f}',
            format: '${text}',
            formatter: function() {
              let text = this.axis.defaultLabelFormatter.call(this);
              if (text === '1,000k') {
                text = '1M';
              }
              return `$${text}`.toUpperCase();
            },
            style: axiosLabelStyle,
          },
          // min: 395000,
        },
        {
          title: {
            text: undefined,
          },
          // min: 395000,
          opposite: true,
          linkedTo: 0,
          labels: {
            format: '${text}',
            formatter: function() {
              let text = this.axis.defaultLabelFormatter.call(this);
              if (text === '1,000k') {
                text = '1M';
              }
              return `$${text}`.toUpperCase();
            },
            align: 'left',
            x: 15,
            // y: 5,
            style: axiosLabelStyle,
          },
        },
      ],
    },
  },
  p002: {
    ticket: 'ART-14',
    type: 'scatter',
    title: 'CAGR vs Holding Period',
    definitions: ['P002_DEFINITION', 'P002_INTERPRETATION'],
    api: Api.getMarketPerformanceSeries,
    apiProps: ['artworkId', 'artistId', 'chartId'],
    TooltipComponent: TooltipLot,
    normalize: () => (row) => {
      return {
        name: row.name,
        data: row.data
          .filter(point => point.car <= 100)
          .map(point => ({ x: point.holding_period, y: point.car, lotId: point.lot_id })),
      };
    },
    chartProps: {
      chart: {
        zoomType: 'x',
        events: {
          load: renderLabel('p002'),
        },
      },
      legend: {
        enabled: false,
      },
      colors: ['#304C95', '#FFA600'],
      xAxis: {
        type: 'category',
        tickInterval: 5,
        // tickInterval: 1000 /* ms */ * 60 /* sec */ * 60 /* min */ * 24 /* hours */ * 365 /* days */ * 5 /* years */,
        labels: {
          // format: '{value:%Y-%m-%d}',
          // formatter: function (...args) {
          //   return (new Date(this.value)).getFullYear();
          // },
          style: axiosLabelStyle,
        },
      },
      yAxis: {
        opposite: false,
        zoomEnabled: false,
        title: {
          text: undefined,
        },
        labels: {
          format: '{value:.0f}%',
          style: axiosLabelStyle,
        },
      },
    },
    updateOptions: updateOptionsLoadLot,
  },
  p003: {
    ticket: 'VIZ-179',
    type: 'spline',
    title: 'Market Enthusiasm',
    definitions: ['P003_DEFINITION', 'P003_INTERPRETATION'],
    api: Api.getMarketPerformanceSeries,
    apiProps: ['artworkId', 'artistId', 'chartId'],
    normalize: [
      // () => (row) => {
      //   const conf = {
      //     'Artwork that is the subject of the report': { color: '#007AFF' },
      //     'All Artist Artworks': { color: '#007AFF', type: 'spline' },
      //     'Artist Artworks of Comparable Medium': { color: '#FFA600', type: 'line' },
      //   };
      //   return { ...row, ...(conf[row.name] || {} ) };
      // },
      normalizers.rename({
        'All Artist Repeat Sales': 'Market Enthusiam Score',
      }),
      normalizers.xYearToMilliseconds,
      // () => (row) => {
      //   return { ...row, marker: { enabled: true } };
      // },
    ],
    stockChart: true,
    chartProps: {
      colors: ['#304C95'],
      navigator: {
        enabled: false,
      },
      scrollbar: {
        enabled: false,
      },
      rangeSelector: {
        buttons: [
          {
            type: 'year',
            count: 5,
            text: '5y',
            title: 'View 5 year',
          },
          {
            type: 'year',
            count: 10,
            text: '10y',
            title: 'View 10 year',
          },
        ],
        ...defaultProps.rangeSelectorInput,
      },
      tooltip: {
        pointFormat: 'Year: <b>{point.x:%Y}</b><br/>' +
          'Market Enthusiam Score: <b>{point.y:,.0f}%</b>',
      },
      xAxis: {
        tickInterval: 1000 /* ms */ * 60 /* sec */ * 60 /* min */ * 24 /* hours */ * 365 /* days */ * 1 /* years */,
        labels: {
          style: axiosLabelStyle,
        },
      },
      yAxis: {
        opposite: false,
        tickAmount: 6,
        title: {
          text: undefined,
        },
        labels: {
          format: '{value}%',
          style: axiosLabelStyle,
        },
      },
    },
  },
  p004: {
    ticket: 'ART-16',
    type: 'spline',
    title: 'Market Enthusiasm',
    // subtitle: 'Market Enthusiasm + Comparable line graph',
    definitions: ['P004_DEFINITION', 'P004_INTERPRETATION'],
    api: Api.getMarketPerformanceSeries,
    apiProps: ['artworkId', 'artistId', 'chartId'],
    updateData: (resp) => {
      const series = resp.series
        .filter(row => row.name !== 'Artist Repeat Sales of Comparable Medium');
      return { ...resp, series };
    },
    normalize: normalizers.xYearToMilliseconds,
    stockChart: true,
    chartProps: {
      navigator: {
        enabled: false,
      },
      scrollbar: {
        enabled: false,
      },
      rangeSelector: {
        inputEnabled: false,
        buttons: [
          {
            type: 'year',
            count: 5,
            text: '5y',
            title: 'View 5 year',
          },
          {
            type: 'year',
            count: 10,
            text: '10y',
            title: 'View 10 year',
          },
          {
            type: 'all',
            text: 'All',
            title: 'View All',
          },
        ],
      },
      colors: ['#007AFF', '#FFA600'],
      tooltip: {
        pointFormat: '<span style="color: {point.color}">&#x25CF;</span> {series.name}: <b>{point.y:,.0f}%</b>',
      },
      xAxis: {
        title: {
          text: undefined,
        },
        tickInterval: 1000 /* ms */ * 60 /* sec */ * 60 /* min */ * 24 /* hours */ * 365 /* days */ * 1 /* years */,
        labels: {
          format: '{value:%Y}',
          style: axiosLabelStyle,
        },
      },
      yAxis: {
        opposite: false,
        title: {
          text: undefined,
        },
        labels: {
          format: '{value:.0f}%',
          style: axiosLabelStyle,
        },
      },
    },
  },
  p005: {
    ticket: 'VIZ-180',
    type: 'spline',
    title: 'Artist FMV vs Auction Results',
    definitions: ['P005_DEFINITION', 'P005_INTERPRETATION'],
    api: Api.getMarketPerformanceSeries,
    apiProps: ['artworkId', 'artistId', 'chartId'],
    normalize: [
      // () => (row) => {
      //   const conf = {
      //     'Artwork that is the subject of the report': { color: '#007AFF' },
      //     'All Artist Artworks': { color: '#007AFF', type: 'spline' },
      //     'Artist Artworks of Comparable Medium': { color: '#FFA600', type: 'line' },
      //   };
      //   return { ...row, ...(conf[row.name] || {} ) };
      // },
      normalizers.rename({ 'SOLD PRICE vs. ARTBnk VALUE': 'Artist FMV vs Auction Results' }),
    ],
    chartProps: {
      colors: ['#33B8FF'],
      tooltip: {
        // pointFormat: 'Year Created: <b>{point.x:%Y}</b><br/>' +
        //   'Average Value: <b>{point.y:,.2f}</b>',
        valueSuffix: '%',
        valueDecimals: 2,
      },
      xAxis: {
        type: 'category',
        labels: {
          formatter: function() {
            return [
              'Q' + (parseInt(this.value.substr(5, 2) / 3, 10)),
              this.value.substr(0, 4),
            ].join(' ');
          },
          style: axiosLabelStyle,
        },
      },
      yAxis: {
        opposite: false,
        tickAmount: 6,
        title: {
          text: undefined,
        },
        labels: {
          format: '{value}%',
          style: axiosLabelStyle,
        },
      },
    },
  },
  p006: {
    ticket: 'VIZ-181',
    type: 'spline',
    title: 'Average Price',
    definitions: ['P006_DEFINITION', 'P006_INTERPRETATION'],
    subtitle: 'by Year Created',
    api: Api.getMarketPerformanceSeries,
    apiProps: ['artworkId', 'artistId', 'chartId'],
    normalize: [
      // () => (row) => {
      //   const conf = {
      //     'Artwork that is the subject of the report': { color: '#007AFF' },
      //     'All Artist Artworks': { color: '#007AFF', type: 'spline' },
      //     'Artist Artworks of Comparable Medium': { color: '#FFA600', type: 'line' },
      //   };
      //   return { ...row, ...(conf[row.name] || {} ) };
      // },
      // () => row => ({ ...row, data: row.data.map(([x, y]) => [parseInt(x.replace(/Q\d\s/g, ''), 10), y]) }),
      normalizers.xYearToMilliseconds,
      // () => (row) => {
      //   return { ...row, marker: { enabled: true } };
      // },
    ],
    stockChart: true,
    chartProps: {
      colors: ['#007AFF'],
      navigator: {
        enabled: false,
      },
      scrollbar: {
        enabled: false,
      },
      rangeSelector: {
        buttons: [
          {
            type: 'year',
            count: 5,
            text: '5y',
            title: 'View 5 year',
          },
          {
            type: 'year',
            count: 10,
            text: '10y',
            title: 'View 10 year',
          },
          {
            type: 'all',
            text: 'All',
            title: 'View All',
          },
        ],
        ...defaultProps.rangeSelectorInput,
      },
      tooltip: {
        pointFormat: 'Year Created: <b>{point.x:%Y}</b><br/>' +
          'Average Value: <b>${point.y:,.0f}</b>',
      },
      xAxis: {
        tickInterval: 1000 /* ms */ * 60 /* sec */ * 60 /* min */ * 24 /* hours */ * 365 /* days */ * 1 /* years */,
        labels: {
          style: axiosLabelStyle,
        },
      },
      yAxis: {
        opposite: false,
        showLastLabel: true,
        // tickAmount: 6,
        title: {
          text: undefined,
        },
        labels: {
          format: '${text}',
          style: axiosLabelStyle,
        },
      },
    },
  },
  // auction
  p007: {
    ticket: 'ART-39',
    type: 'column',
    title: 'Average Price',
    definitions: ['P007_DEFINITION', 'P007_INTERPRETATION'],
    subtitle: 'by Auction Year',
    api: Api.getMarketPerformanceSeries,
    apiProps: ['artworkId', 'artistId', 'chartId'],
    stockChart: true,
    normalize: [normalizers.xYearToMilliseconds],
    chartProps: {
      colors: ['#007AFF'],
      navigator: {
        enabled: false,
      },
      scrollbar: {
        enabled: false,
      },
      rangeSelector: {
        buttons: [
          {
            type: 'year',
            count: 5,
            text: '5y',
            title: 'View 5 year',
          },
          {
            type: 'year',
            count: 10,
            text: '10y',
            title: 'View 10 year',
          },
          {
            type: 'all',
            text: 'All',
            title: 'View All',
          },
        ],
        ...defaultProps.rangeSelectorInput,
      },
      tooltip: {
        pointFormat: 'Year Created: <b>{point.x:%Y}</b><br/>' +
          'Average Value: <b>${point.y:,.0f}</b>',
      },
      xAxis: {
        tickInterval: 1000 /* ms */ * 60 /* sec */ * 60 /* min */ * 24 /* hours */ * 365 /* days */ * 1 /* years */,
        labels: {
          style: axiosLabelStyle,
        },
      },
      yAxis: {
        opposite: false,
        showLastLabel: true,
        // tickAmount: 6,
        title: {
          text: undefined,
        },
        labels: {
          format: '${text}',
          style: axiosLabelStyle,
        },
      },
    },
  },
  // medium
  p008: {
    ticket: 'ART-40',
    type: 'column',
    title: 'Average Price',
    definitions: ['P008_DEFINITION', 'P008_INTERPRETATION'],
    subtitle: 'Average Price by <b>Medium</b>',
    api: Api.getMarketPerformanceSeries,
    apiProps: ['artworkId', 'artistId', 'chartId'],
    chartProps: {
      xAxis: {
        labels: {
          style: axiosLabelStyle,
        },
      },
      yAxis: {
        labels: {
          // eslint-disable-next-line no-template-curly-in-string
          format: '${text}',
          style: axiosLabelStyle,
        },
      },
    },
  },
  // medium
  p009: {
    ticket: 'ART-19',
    type: 'line',
    title: 'Average price',
    subtitle: 'by Year Created',
    definitions: ['P009_DEFINITION', 'P009_INTERPRETATION'],
    api: Api.getMarketPerformanceArtworkSeries,
    apiProps: ['artworkId', 'artistId', 'chartId'],
    normalize: [
      () => (row) => {
        const conf = {
          'Artwork that is the subject of the report': { color: '#007AFF' },
          'All Artist Artworks': { color: '#007AFF', type: 'spline' },
          'Artist Artworks of Comparable Medium': { color: '#FFA600', type: 'line' },
        };
        return { ...row, ...(conf[row.name] || {} ) };
      },
      normalizers.xYearToMilliseconds,
      () => (row) => {
        return { ...row, marker: { enabled: true } };
      },
    ],
    stockChart: true,
    chartProps: {
      navigator: {
        enabled: false,
      },
      scrollbar: defaultProps.scrollbar,
      rangeSelector: {
        buttons: [
          {
            type: 'year',
            count: 5,
            text: '5y',
            title: 'View 5 year',
          },
          {
            type: 'year',
            count: 10,
            text: '10y',
            title: 'View 10 year',
          },
          {
            type: 'all',
            text: 'All',
            title: 'View All',
          },
        ],
        ...defaultProps.rangeSelectorInput,
      },
      tooltip: {
        pointFormat: 'Year Created: <b>{point.x:%Y}</b><br/>' +
          'Average Value: <b>${point.y:,.0f}</b>',
      },
      xAxis: {
        tickInterval: 1000 /* ms */ * 60 /* sec */ * 60 /* min */ * 24 /* hours */ * 365 /* days */ * 1 /* years */,
        labels: {
          style: axiosLabelStyle,
        },
      },
      yAxis: {
        opposite: false,
        // tickAmount: 6,
        showLastLabel: true,
        title: {
          text: undefined,
        },
        labels: {
          style: axiosLabelStyle,
        },
      },
    },
  },
  p010: {
    ticket: 'VIZ-183',
    type: 'column',
    title: 'Sell-Through Rate',
    definitions: ['P010_DEFINITION', 'P010_INTERPRETATION'],
    subtitle: 'by Auction Year',
    api: Api.getMarketPerformanceSeries,
    apiProps: ['artworkId', 'artistId', 'chartId'],
    normalize: [
      () => row => {
        return {
          name: row.name,
          data: row.data.map(([year, v]) => [Date.UTC(year), v * 100]),
        };
      },
    ],
    stockChart: true,
    chartProps: {
      navigator: {
        enabled: false,
      },
      scrollbar: {
        enabled: false,
      },
      rangeSelector: {
        inputEnabled: false,
        buttons: [
          {
            type: 'year',
            count: 5,
            text: '5y',
            title: 'View 5 year',
          },
          {
            type: 'year',
            count: 10,
            text: '10y',
            title: 'View 10 year',
          },
        ],
      },
      colors: ['#304C95'],
      tooltip: {
        // headerFormat: '',
        pointFormat: '{point.x:%Y}<br/><b>{point.y:,.0f}%</b>',
      },
      xAxis: {
        labels: {
          format: '{value:%Y}',
          style: axiosLabelStyle,
        },
      },
      yAxis: {
        opposite: false,
        title: {
          text: undefined,
        },
        // max: 100,
        labels: {
          format: '{value:.0f}%',
          style: axiosLabelStyle,
        },
      },
    },
  },
  p011: {
    ticket: 'ART-20',
    type: 'column',
    title: 'Sell-Through Rate',
    subtitle: 'by Auction Year',
    definitions: ['P011_DEFINITION', 'P011_INTERPRETATION'],
    api: Api.getMarketPerformanceSeries,
    apiProps: ['artworkId', 'artistId', 'chartId'],
    normalize: () => row => {
      return {
        name: row.name,
        data: row.data.map(([year, v]) => [Date.UTC(year), v * 100]),
      };
    },
    stockChart: true,
    chartProps: {
      navigator: {
        enabled: false,
      },
      scrollbar: {
        enabled: false,
      },
      rangeSelector: {
        inputEnabled: false,
        buttons: [
          {
            type: 'year',
            count: 5,
            text: '5y',
            title: 'View 5 year',
          },
          {
            type: 'year',
            count: 10,
            text: '10y',
            title: 'View 10 year',
          },
        ],
      },
      colors: ['#304C95', '#006FFF'],
      tooltip: {
        pointFormat: '<span style="color: {point.color}">&#x25CF;</span> {series.name}: <b>{point.y:,.0f}%</b>',
      },
      xAxis: {
        labels: {
          format: '{value:%Y}',
          style: axiosLabelStyle,
        },
      },
      yAxis: {
        opposite: false,
        title: {
          text: undefined,
        },
        // max: 100,
        labels: {
          format: '{value:.0f}%',
          style: axiosLabelStyle,
        },
      },
    },
  },
  p012: {
    ticket: 'ART-36',
    type: 'scatter',
    title: 'Auction Prices over Time',
    subtitle: '',
    definitions: ['P012_DEFINITION', 'P012_INTERPRETATION'],
    api: Api.getMarketPerformanceSeries,
    apiProps: ['artworkId', 'artistId', 'chartId'],
    TooltipComponent: TooltipLot,
    normalize: () => row => ({
      name: row.name,
      data: row.data
        .slice(-1000)
        .map((v) => ({ x: v.auction_date, y: v.sold_price, lotId: v.lot_id })),
    }),
    stockChart: true,
    chartProps: {
      navigator: {
        enabled: false,
      },
      chart: {
        zoomType: 'x',
        events: {
          load: renderLabel('p012'),
        },
      },
      // boost: {
      //   enabled: false,
      // },
      scrollbar: {
        enabled: false,
      },
      rangeSelector: {
        ...defaultProps.rangeSelectorInput,
      },
      colors: ['#304C95', '#FFA600'],
      xAxis: {
        labels: {
          format: '{value:%Y}',
          style: axiosLabelStyle,
        },
      },
      yAxis: {
        opposite: false,
        // tickAmount: 6,
        showLastLabel: true,
        title: {
          text: undefined,
        },
        labels: {
          // eslint-disable-next-line no-template-curly-in-string
          format: '${text}',
          style: axiosLabelStyle,
        },
      },
    },
    updateOptions: updateOptionsLoadLot,
  },
  p015: {
    ticket: 'VIZ-177',
    type: 'scatter',
    title: 'CAGR vs Purchase Year',
    definitions: ['P015_DEFINITION', 'P015_INTERPRETATION'],
    api: Api.getMarketPerformanceSeries,
    apiProps: ['artworkId', 'artistId', 'chartId'],
    TooltipComponent: TooltipLot,
    normalize: () => (row) => ({
      name: row.name,
      data: row.data
        .filter(point => point.car <= 100)
        .map(point => ({ x: point.year, y: point.car, lotId: point.lot_id })),
    }),
    chartProps: {
      chart: {
        zoomType: 'x',
        events: {
          load: renderLabel('p002'),
        },
      },
      legend: {
        enabled: false,
      },
      colors: ['#304C95', '#FFA600'],
      xAxis: {
        type: 'category',
        // tickInterval: 5,
        // tickInterval: 1000 /* ms */ * 60 /* sec */ * 60 /* min */ * 24 /* hours */ * 365 /* days */ * 5 /* years */,
        labels: {
          // format: '{value:%Y-%m-%d}',
          // formatter: function (...args) {
          //   return (new Date(this.value)).getFullYear();
          // },
          style: axiosLabelStyle,
        },
      },
      yAxis: {
        opposite: false,
        zoomEnabled: false,
        title: {
          text: undefined,
        },
        labels: {
          format: '{value:.0f}%',
          style: axiosLabelStyle,
        },
      },
    },
    updateOptions: updateOptionsLoadLot,
  },
  p016: {
    ticket: 'VIZ-178',
    type: 'scatter',
    title: 'CAGR vs Sale Year',
    definitions: ['P016_DEFINITION', 'P016_INTERPRETATION'],
    api: Api.getMarketPerformanceSeries,
    apiProps: ['artworkId', 'artistId', 'chartId'],
    TooltipComponent: TooltipLot,
    normalize: () => (row) => {
      return {
        name: row.name,
        data: row.data
          .filter(point => point.car <= 100)
          .map(point => ({ x: parseInt(point.year, 10), y: point.car, lotId: point.lot_id })),
      };
    },
    chartProps: {
      chart: {
        zoomType: 'x',
        events: {
          load: renderLabel('p002'),
        },
      },
      legend: {
        enabled: false,
      },
      colors: ['#304C95', '#FFA600'],
      xAxis: {
        type: 'category',
        tickInterval: 1,
        labels: {
          style: axiosLabelStyle,
        },
      },
      yAxis: {
        opposite: false,
        zoomEnabled: false,
        title: {
          text: undefined,
        },
        labels: {
          // format: '{value:.0f}%',
          format: '{text}%',
          style: axiosLabelStyle,
        },
      },
    },
    updateOptions: updateOptionsLoadLot,
  },
  m001: {
    ticket: 'VIZ-32',
    type: 'column',
    title: 'Total Volume',
    subtitle: 'by Auction Year',
    definitions: ['M001_DEFINITION', 'M001_INTERPRETATION'],
    api: Api.getMarketMovementSeries,
    apiProps: ['artworkId', 'artistId', 'chartId'],
    stockChart: true,
    normalize: [
      normalizers.xYearToMilliseconds,
    ],
    chartProps: {
      navigator: {
        enabled: false,
      },
      scrollbar: {
        enabled: false,
      },
      rangeSelector: {
        buttons: [
          {
            type: 'year',
            count: 5,
            text: '5y',
            title: 'View 5 year',
          },
          {
            type: 'year',
            count: 10,
            text: '10y',
            title: 'View 10 year',
          },
        ],
        ...defaultProps.rangeSelectorInput,
      },
      colors: ['#33B8FF', '#006FFF'],
      tooltip: {
        // pointFormat: '<span style="color: {point.color}">&#x25CF;</span> {series.name}: <b>{point.y:,.0f}%</b>',
        valueDecimals: 0,
        valuePrefix: '$',
      },
      xAxis: {
        labels: {
          format: '{value:%Y}',
          style: axiosLabelStyle,
        },
      },
      yAxis: {
        opposite: false,
        title: {
          text: undefined,
        },
        // max: 100,
        labels: {
          format: '${text}',
          style: axiosLabelStyle,
        },
      },
    },
  },
  ...preview('m002', 'column', ['M002_DEFINITION', 'M002_INTERPRETATION']),
  m003: {
    ticket: 'VIZ-187',
    type: 'column',
    title: 'Total Volume',
    definitions: ['M003_DEFINITION', 'M003_INTERPRETATION'],
    subtitle: 'by Price Segment',
    stockChart: true,
    normalize: [
      normalizers.xYearToMilliseconds,
    ],
    api: Api.getMarketMovementSeries,
    apiProps: ['artworkId', 'artistId', 'chartId'],
    demo: [['stacked columns', 'https://jsfiddle.net/gh/get/library/pure/highcharts/highcharts/tree/master/samples/highcharts/demo/column-stacked-and-grouped']],
    chartProps: {
      navigator: {
        enabled: false,
      },
      scrollbar: {
        enabled: false,
      },
      rangeSelector: {
        buttons: [
          {
            type: 'year',
            count: 5,
            text: '5y',
            title: 'View 5 year',
          },
          {
            type: 'year',
            count: 10,
            text: '10y',
            title: 'View 10 year',
          },
        ],
        ...defaultProps.rangeSelectorInput,
      },
      colors: [
        '#9AC6FF',
        '#60A5FF',
        '#006FFF',
        '#00459F',
        '#313545',
      ],
      chart: {
        zoomType: 'x',
      },
      plotOptions: {
        column: {
          stacking: 'normal',
        },
      },
      tooltip: {
        split: false,
        shared: false,
        pointFormat: '<span style="color: {point.color}">&#x25CF;</span> {series.name}: <b>${point.y:,.0f}</b>',
        // headerFormat: 'Year: <b>{point.x:%Y}</b><br/>',
        // footerFormat: '<br/> Total: <b>{point.stackTotal}</b>',
      },
      xAxis: {
        type: 'datetime',
        tickInterval: 1000 /* ms */ * 60 /* sec */ * 60 /* min */ * 24 /* hours */ * 365 /* days */ * 1 /* years */,
        labels: {
          format: '{value:%Y}',
          style: axiosLabelStyle,
        },
      },
      yAxis: {
        title: {
          text: undefined,
        },
        opposite: false,
        labels: {
          format: '${text}',
          style: axiosLabelStyle,
        },
      },
    },
  },
  m004: {
    ticket: 'VIZ-188',
    type: 'bar',
    title: 'Total Volume',
    definitions: ['M004_DEFINITION', 'M004_INTERPRETATION'],
    subtitle: 'by Auction House',
    stockChart: true,
    normalize: [
      normalizers.xYearToMilliseconds,
    ],
    api: Api.getMarketMovementSeries,
    apiProps: ['artworkId', 'artistId', 'chartId'],
    demo: [['stacked bars', 'https://jsfiddle.net/gh/get/library/pure/highcharts/highcharts/tree/master/samples/highcharts/plotoptions/series-stacking-bar/']],
    chartProps: {
      navigator: {
        enabled: false,
      },
      scrollbar: {
        enabled: false,
      },
      colors: [
        '#F4BE37',
        '#7EB6FF',
        '#006FFF',
        '#00459F',
        '#313545',
      ],
      rangeSelector: {
        buttons: [
          {
            type: 'year',
            count: 5,
            text: '5y',
            title: 'View 5 years',
          },
          {
            type: 'year',
            count: 10,
            text: '10y',
            title: 'View 10 years',
          },
        ],
        selected: 2,
        ...defaultProps.rangeSelectorInput,
      },
      chart: {
        height: 800,
      },
      plotOptions: {
        series: {
          stacking: 'normal',
        },
      },
      tooltip: {
        split: false,
        shared: false,
        // headerFormat: 'Year: <b>{point.x:%Y}</b><br/>',
        // footerFormat: '<br/> Total: <b>{point.stackTotal}</b>',
        pointFormat: '<span style="color: {point.color}">&#x25CF;</span> {series.name}: <b>${point.y:,.0f}</b>',
      },
      xAxis: {
        // type: 'category',
        type: 'datetime',
        reversed: false,
        // tickAmount: 5,
        tickInterval: 1000 /* ms */ * 60 /* sec */ * 60 /* min */ * 24 /* hours */ * 365 /* days */ * 1 /* years */,
        tickPixelInterval: 10,
        labels: {
          format: '{value:%Y}',
          style: axiosLabelStyle,
        },
      },
      yAxis: {
        title: {
          text: undefined,
        },
        opposite: false,
        labels: {
          style: axiosLabelStyle,
          y: 20,
        },
      },
    },
  },
  m006: {
    // ticket: 'ART-53',
    ticket: 'VIZ-34',
    type: 'column',
    title: 'Total Volume',
    subtitle: 'by Auction Year',
    definitions: ['M006_DEFINITION', 'M006_INTERPRETATION'],
    api: Api.getMarketMovementSeries,
    apiProps: ['artworkId', 'artistId', 'chartId'],
    normalize: normalizers.xYearToMilliseconds,
    stockChart: true,
    chartProps: {
      navigator: {
        enabled: false,
      },
      scrollbar: {
        enabled: false,
      },
      rangeSelector: {
        buttons: [
          {
            type: 'year',
            count: 5,
            text: '5y',
            title: 'View 5 year',
          },
          {
            type: 'year',
            count: 10,
            text: '10y',
            title: 'View 10 year',
          },
        ],
        ...defaultProps.rangeSelectorInput,
      },
      colors: ['#33B8FF', '#006FFF'],
      tooltip: {
        pointFormat: '<span style="color: {point.color}">&#x25CF;</span> {series.name}: <b>${point.y:,.0f}</b>',
      },
      xAxis: {
        labels: {
          format: '{value:%Y}',
          style: axiosLabelStyle,
        },
      },
      yAxis: {
        opposite: false,
        title: {
          text: undefined,
        },
        // max: 100,
        labels: {
          style: axiosLabelStyle,
        },
      },
    },
  },
  m007: {
    ticket: 'VIZ-21',
    type: 'column',
    title: 'Total Volume',
    subtitle: 'by Price Segment',
    definitions: ['M007_DEFINITION', 'M007_INTERPRETATION'],
    stockChart: true,
    normalize: normalizers.xYearToMilliseconds,
    api: Api.getMarketMovementSeries,
    apiProps: ['artworkId', 'artistId', 'chartId'],
    demo: [['stacked columns', 'https://jsfiddle.net/gh/get/library/pure/highcharts/highcharts/tree/master/samples/highcharts/demo/column-stacked-and-grouped']],
    chartProps: {
      navigator: {
        enabled: false,
      },
      scrollbar: defaultProps.scrollbar,
      rangeSelector: defaultProps.rangeSelectorInput,
      colors: ['#CCCCCC', '#9E9E9E', '#656565', '#2F2F2F', '#000000', '#DFEDFF', '#60A5FF', '#006FFF', '#00459F', '#313545'],
      chart: {
        zoomType: 'x',
      },
      plotOptions: {
        column: {
          stacking: 'normal',
        },
      },
      tooltip: {
        split: false,
        shared: false,
        headerFormat: 'Year: <b>{point.x:%Y}</b><br/>',
      },
      xAxis: {
        // type: 'category',
        type: 'datetime',
        tickInterval: 1000 /* ms */ * 60 /* sec */ * 60 /* min */ * 24 /* hours */ * 365 /* days */ * 1 /* years */,
        labels: {
          // format: '{value:%Y-%m-%d}',
          format: '{value:%Y}',
          style: axiosLabelStyle,
        },
      },
      yAxis: {
        title: {
          text: undefined,
        },
        // min: 395000,
        opposite: false,
        // linkedTo: 0,
        labels: {
          // format: '${value:,.0f}',
          // align: 'left',
          // x: 15,
          // y: 5,
          style: axiosLabelStyle,
        },
      },
    },
  },
  m008: {
    ticket: 'VIZ-189',
    type: 'pie',
    title: 'Total Volume',
    definitions: ['M008_DEFINITION', 'M008_INTERPRETATION'],
    subtitle: 'by Auction House',
    // stockChart: true,
    // normalize: normalizers.xYearToMilliseconds,
    updateData: updatePieChartResponse,
    normalize: [
      // () => (row) => {
      //   console.log('m008 origin', row);
      //   return { name: 'Auction Houses', data: { name: v.name, y: v.data, drilldown: v.name })) };
      // },
      // () => row => console.log('m008 row', row) || row,
    ],
    api: Api.getMarketMovementSeries,
    apiProps: ['artworkId', 'artistId', 'chartId'],
    demo: [['Pie with drilldown', 'https://jsfiddle.net/gh/get/library/pure/highcharts/highcharts/tree/master/samples/highcharts/demo/pie-drilldown']],
    chartProps: {
      // navigator: {
      //   enabled: false,
      // },
      // scrollbar: defaultProps.scrollbar,
      // rangeSelector: defaultProps.rangeSelectorInput,
      colors: ['#CCCCCC', '#9E9E9E', '#656565', '#2F2F2F', '#000000', '#DFEDFF', '#60A5FF', '#006FFF', '#00459F', '#313545'],
      chart: {
        // zoomType: 'x',
      },
      plotOptions: {
        // column: {
        //   stacking: 'normal',
        // },
      },
      tooltip: {
        // split: false,
        // shared: false,
        // headerFormat: 'Year: <b>{point.x:%Y}</b><br/>',
      },
      xAxis: {
        type: 'category',
        // type: 'datetime',
        // tickInterval: 1000 /* ms */ * 60 /* sec */ * 60 /* min */ * 24 /* hours */ * 365 /* days */ * 1 /* years */,
        labels: {
          // format: '{value:%Y-%m-%d}',
          // format: '{value:%Y}',
          style: axiosLabelStyle,
        },
      },
      yAxis: {
        title: {
          text: undefined,
        },
        // min: 395000,
        opposite: false,
        // linkedTo: 0,
        labels: {
          // format: '${value:,.0f}',
          // align: 'left',
          // x: 15,
          // y: 5,
          style: axiosLabelStyle,
        },
      },
    },
  },
  m010: {
    ticket: 'VIZ-190',
    type: 'column',
    title: 'Total Lots Sold',
    definitions: ['M010_DEFINITION', 'M010_INTERPRETATION'],
    subtitle: 'by Auction Year',
    api: Api.getMarketMovementSeries,
    apiProps: ['artworkId', 'artistId', 'chartId'],
    stockChart: true,
    normalize: normalizers.xYearToMilliseconds,
    chartProps: {
      navigator: {
        enabled: false,
      },
      scrollbar: {
        enabled: false,
      },
      colors: ['#FFA600', '#006FFF'],
      tooltip: {
        valueDecimals: 0,
      },
      rangeSelector: {
        buttons: [
          {
            type: 'year',
            count: 5,
            text: '5y',
            title: 'View 5 year',
          },
          {
            type: 'year',
            count: 10,
            text: '10y',
            title: 'View 10 year',
          },
        ],
        selected: 1,
        ...defaultProps.rangeSelectorInput,
      },
      xAxis: {
        labels: {
          format: '{value:%Y}',
          style: axiosLabelStyle,
        },
      },
      yAxis: {
        opposite: false,
        title: {
          text: undefined,
        },
        // max: 100,
        labels: {
          style: axiosLabelStyle,
        },
      },
    },
  },
  m011: {
    ticket: 'ART-53',
    type: 'column',
    title: 'Lots Sold',
    definitions: ['M011_DEFINITION', 'M011_INTERPRETATION'],
    subtitle: 'by Medium',
    api: Api.getMarketMovementSeries,
    apiProps: ['artworkId', 'artistId', 'chartId'],
  },
  m012: {
    ticket: 'VIZ-192',
    type: 'column',
    title: 'Total Lots Sold',
    definitions: ['M012_DEFINITION', 'M012_INTERPRETATION'],
    subtitle: 'by Price Segment',
    stockChart: true,
    normalize: [
      normalizers.xYearToMilliseconds,
    ],
    api: Api.getMarketMovementSeries,
    apiProps: ['artworkId', 'artistId', 'chartId'],
    demo: [['stacked columns', 'https://jsfiddle.net/gh/get/library/pure/highcharts/highcharts/tree/master/samples/highcharts/demo/column-stacked-and-grouped']],
    chartProps: {
      navigator: {
        enabled: false,
      },
      scrollbar: {
        enabled: false,
      },
      rangeSelector: {
        buttons: [
          {
            type: 'year',
            count: 5,
            text: '5y',
            title: 'View 5 year',
          },
          {
            type: 'year',
            count: 10,
            text: '10y',
            title: 'View 10 year',
          },
        ],
        ...defaultProps.rangeSelectorInput,
      },
      colors: [
        '#9AC6FF',
        '#60A5FF',
        '#006FFF',
        '#00459F',
        '#313545',
      ],
      chart: {
        zoomType: 'x',
      },
      plotOptions: {
        column: {
          stacking: 'normal',
        },
      },
      tooltip: {
        split: false,
        shared: false,
        // headerFormat: 'Year: <b>{point.x:%Y}</b><br/>',
        // footerFormat: '<br/> Total: <b>{point.stackTotal}</b>',
      },
      xAxis: {
        type: 'datetime',
        tickInterval: 1000 /* ms */ * 60 /* sec */ * 60 /* min */ * 24 /* hours */ * 365 /* days */ * 1 /* years */,
        labels: {
          format: '{value:%Y}',
          style: axiosLabelStyle,
        },
      },
      yAxis: {
        title: {
          text: undefined,
        },
        opposite: false,
        labels: {
          style: axiosLabelStyle,
        },
      },
    },
  },
  m013: {
    ticket: ['VIZ-193', 'VIZ-46'],
    type: 'bar',
    title: 'Total Lots Sold',
    definitions: ['M013_DEFINITION', 'M013_INTERPRETATION'],
    subtitle: 'by Auction House',
    stockChart: true,
    normalize: [
      normalizers.xYearToMilliseconds,
    ],
    api: Api.getMarketMovementSeries,
    apiProps: ['artworkId', 'artistId', 'chartId'],
    demo: [['stacked bars', 'https://jsfiddle.net/gh/get/library/pure/highcharts/highcharts/tree/master/samples/highcharts/plotoptions/series-stacking-bar/']],
    chartProps: {
      navigator: {
        enabled: false,
      },
      scrollbar: {
        enabled: false,
      },
      colors: [
        '#33B8FF',
        '#FFA600',
        '#006FFF',
        '#304C95',
      ],
      rangeSelector: {
        buttons: [
          {
            type: 'year',
            count: 5,
            text: '5y',
            title: 'View 5 years',
          },
          {
            type: 'year',
            count: 10,
            text: '10y',
            title: 'View 10 years',
          },
        ],
        selected: 1,
        ...defaultProps.rangeSelectorInput,
      },
      chart: {
        height: 800,
      },
      plotOptions: {
        series: {
          stacking: 'normal',
        },
      },
      tooltip: {
        split: false,
        shared: false,
        // headerFormat: 'Year: <b>{point.x:%Y}</b><br/>',
        // footerFormat: '<br/> Total: <b>{point.stackTotal}</b>',
      },
      xAxis: {
        // type: 'category',
        type: 'datetime',
        reversed: false,
        tickInterval: 1000 /* ms */ * 60 /* sec */ * 60 /* min */ * 24 /* hours */ * 365 /* days */ * 1 /* years */,
        tickPixelInterval: 10,
        labels: {
          format: '{value:%Y}',
          style: axiosLabelStyle,
        },
      },
      yAxis: {
        title: {
          text: undefined,
        },
        opposite: false,
        labels: {
          y: 20,
          style: axiosLabelStyle,
        },
      },
    },
  },
  m014: {
    ticket: 'VIZ-26',
    type: 'column',
    title: 'Total Lots Sold',
    subtitle: 'by Auction Year',
    definitions: ['M014_DEFINITION', 'M014_INTERPRETATION'],
    api: Api.getMarketMovementSeries,
    apiProps: ['artworkId', 'artistId', 'chartId'],
    stockChart: true,
    normalize: normalizers.xYearToMilliseconds,
    chartProps: {
      navigator: {
        enabled: false,
      },
      scrollbar: {
        enabled: false,
      },
      rangeSelector: {
        buttons: [
          {
            type: 'year',
            count: 5,
            text: '5y',
            title: 'View 5 year',
          },
          {
            type: 'year',
            count: 10,
            text: '10y',
            title: 'View 10 year',
          },
        ],
        ...defaultProps.rangeSelectorInput,
      },
      colors: ['#E3A52C', '#304C95'],
      chart: {
        zoomType: 'x',
      },
      xAxis: {
        // type: 'category',
        type: 'datetime',
        tickInterval: 1000 /* ms */ * 60 /* sec */ * 60 /* min */ * 24 /* hours */ * 365 /* days */ * 1 /* years */,
        labels: {
          // format: '{value:%Y-%m-%d}',
          format: '{value:%Y}',
          style: axiosLabelStyle,
        },
      },
      yAxis: {
        title: {
          text: undefined,
        },
        // min: 395000,
        opposite: false,
        // linkedTo: 0,
        labels: {
          // format: '${value:,.0f}',
          // align: 'left',
          // x: 15,
          // y: 5,
          style: axiosLabelStyle,
        },
      },
    },
  },
  m015: {
    ticket: 'VIZ-52',
    type: 'column',
    title: 'Total Lots Sold',
    subtitle: 'by Price Segment',
    definitions: ['M015_DEFINITION', 'M015_INTERPRETATION'],
    stockChart: true,
    normalize: normalizers.xYearToMilliseconds,
    // updateData: ({ series: rows }) => {
    //   if (!rows || !rows.length || !rows.[0].data || !rows.[0].data.length) {
    //     return rows;
    //   }
    //   const newRows = rows
    //     .reduce((prevRows, row) => [ ...prevRows, { ...row, data: [] } ], []);
    //   for (let pointIndex = 0; pointIndex < rows[0].data.length; pointIndex++) {
    //     const totalStack = rows.reduce((prev, row) => {
    //       prev[row.stack] = prev[row.stack] || 0;
    //       prev[row.stack] += (row.data[pointIndex][1] || 0);
    //       return prev;
    //     }, {});
    //     newRows.forEach((newRow, rowIndex) => {
    //       const point = rows[rowIndex].data[pointIndex];
    //       const total = totalStack[newRow.stack];
    //       newRow.data.push({ x: Date.UTC(point[0]), y: point[1], total });
    //     });
    //   };
    //   return newRows;
    // },
    api: Api.getMarketMovementSeries,
    apiProps: ['artworkId', 'artistId', 'chartId'],
    demo: [['stacked columns', 'https://jsfiddle.net/gh/get/library/pure/highcharts/highcharts/tree/master/samples/highcharts/demo/column-stacked-and-grouped']],
    chartProps: {
      navigator: {
        enabled: false,
      },
      legend: {
        // useHTML: true,
        // className: 'legend123',
        // y: 150,
      },
      scrollbar: {
        enabled: false,
      },
      rangeSelector: defaultProps.rangeSelectorInput,
      colors: ['#FFD280', '#FFC14C', '#FF9F40', '#E38C36', '#AA5C10', '#60A5FF', '#007AFF', '#015DD3', '#00459F', '#002960'],
      chart: {
        zoomType: 'x',
      },
      plotOptions: {
        column: {
          stacking: 'normal',
        },
      },
      tooltip: {
        split: false,
        shared: false,
        headerFormat: 'Year: <b>{point.x:%Y}</b><br/>',
        footerFormat: '<br/> Total: <b>{point.stackTotal}</b>',
      },
      xAxis: {
        // type: 'category',
        type: 'datetime',
        tickInterval: 1000 /* ms */ * 60 /* sec */ * 60 /* min */ * 24 /* hours */ * 365 /* days */ * 1 /* years */,
        labels: {
          // format: '{value:%Y-%m-%d}',
          format: '{value:%Y}',
          style: axiosLabelStyle,
        },
      },
      yAxis: {
        title: {
          text: undefined,
        },
        // min: 395000,
        opposite: false,
        // linkedTo: 0,
        labels: {
          // format: '${value:,.0f}',
          // align: 'left',
          // x: 15,
          // y: 5,
          style: axiosLabelStyle,
        },
      },
    },
  },
  m016: {
    ticket: 'VIZ-194',
    type: 'pie',
    title: 'Total Lots Sold',
    definitions: ['M016_DEFINITION', 'M016_INTERPRETATION'],
    subtitle: 'by Auction House',
    // stockChart: true,
    // normalize: normalizers.xYearToMilliseconds,
    updateData: updatePieChartResponse,
    normalize: [
      // () => (row) => {
      //   console.log('m008 origin', row);
      //   return { name: 'Auction Houses', data: { name: v.name, y: v.data, drilldown: v.name })) };
      // },
      // () => row => console.log('m008 row', row) || row,
    ],
    api: Api.getMarketMovementSeries,
    apiProps: ['artworkId', 'artistId', 'chartId'],
    demo: [['Pie with drilldown', 'https://jsfiddle.net/gh/get/library/pure/highcharts/highcharts/tree/master/samples/highcharts/demo/pie-drilldown']],
    chartProps: {
      // navigator: {
      //   enabled: false,
      // },
      // scrollbar: defaultProps.scrollbar,
      // rangeSelector: defaultProps.rangeSelectorInput,
      colors: ['#CCCCCC', '#9E9E9E', '#656565', '#2F2F2F', '#000000', '#DFEDFF', '#60A5FF', '#006FFF', '#00459F', '#313545'],
      chart: {
        // zoomType: 'x',
      },
      plotOptions: {
        // column: {
        //   stacking: 'normal',
        // },
      },
      tooltip: {
        // split: false,
        // shared: false,
        // headerFormat: 'Year: <b>{point.x:%Y}</b><br/>',
      },
      xAxis: {
        type: 'category',
        // type: 'datetime',
        // tickInterval: 1000 /* ms */ * 60 /* sec */ * 60 /* min */ * 24 /* hours */ * 365 /* days */ * 1 /* years */,
        labels: {
          // format: '{value:%Y-%m-%d}',
          // format: '{value:%Y}',
          style: axiosLabelStyle,
        },
      },
      yAxis: {
        title: {
          text: undefined,
        },
        // min: 395000,
        opposite: false,
        // linkedTo: 0,
        labels: {
          // format: '${value:,.0f}',
          // align: 'left',
          // x: 15,
          // y: 5,
          style: axiosLabelStyle,
        },
      },
    },
  },
  m018: {
    ticket: 'VIZ-41',
    type: 'column',
    title: 'Repeat Sales',
    subtitle: 'by Auction Year',
    definitions: ['M018_DEFINITION', 'M018_INTERPRETATION'],
    // stockChart: true,
    normalize: normalizers.xYearToMilliseconds,
    api: Api.getMarketMovementSeries,
    apiProps: ['artworkId', 'artistId', 'chartId'],
    demo: [['stacked columns', 'https://jsfiddle.net/gh/get/library/pure/highcharts/highcharts/tree/master/samples/highcharts/demo/column-stacked-and-grouped']],
    stockChart: true,
    chartProps: {
      navigator: {
        enabled: false,
      },
      scrollbar: {
        enabled: false,
      },
      colors: ['#002068'],
      rangeSelector: {
        buttons: [
          {
            type: 'year',
            count: 5,
            text: '5y',
            title: 'View 5 year',
          },
          {
            type: 'year',
            count: 10,
            text: '10y',
            title: 'View 10 year',
          },
        ],
        selected: 1,
        ...defaultProps.rangeSelectorInput,
      },
      chart: {
        zoomType: 'x',
      },
      // plotOptions: {
      //   column: {
      //     stacking: 'normal',
      //   },
      // },
      xAxis: {
        // type: 'category',
        type: 'datetime',
        tickInterval: 1000 /* ms */ * 60 /* sec */ * 60 /* min */ * 24 /* hours */ * 365 /* days */ * 1 /* years */,
        labels: {
          // format: '{value:%Y-%m-%d}',
          format: '{value:%Y}',
          style: axiosLabelStyle,
        },
      },
      yAxis: {
        title: {
          text: undefined,
        },
        // min: 395000,
        opposite: false,
        // linkedTo: 0,
        labels: {
          // format: '${value:,.0f}',
          // align: 'left',
          // x: 15,
          // y: 5,
          style: axiosLabelStyle,
        },
      },
    },
  },
  x001: getLiquidityIndexesConfig({
    chartId: 'x001',
    title: 'ARTBnk Liquidity Indexes',
    api: Api.getLiquidityIndexSeries,
    definition: 'X001_DEFINITION',
    interpretation: 'X001_INTERPRETATION',
    colours: x001colours,
  }),
  'x001.10': getLiquidityIndexesConfig({
    chartId: 'x001.10',
    title: 'ARTBnk Liquidity Indexes',
    subtitle: '10y period',
    api: Api.getLiquidityIndexSeries,
    definition: 'X001.10_DEFINITION',
    interpretation: 'X001.10_INTERPRETATION',
    colours: x001colours,
  }),
  'x001.5': getLiquidityIndexesConfig({
    chartId: 'x001.5',
    title: 'ARTBnk Liquidity Indexes',
    subtitle: '5y period',
    api: Api.getLiquidityIndexSeries,
    definition: 'X001.5_DEFINITION',
    interpretation: 'X001.5_INTERPRETATION',
    colours: x001colours,
  }),
  'x001.1': getLiquidityIndexesConfig({
    chartId: 'x001.1',
    title: 'ARTBnk Liquidity Indexes',
    subtitle: '1y period',
    api: Api.getLiquidityIndexSeries,
    definition: 'X001.1_DEFINITION',
    interpretation: 'X001.1_INTERPRETATION',
    colours: x001colours,
  }),
  x003: getLiquidityIndexesConfig({
    chartId: 'x003',
    title: 'Collecting Category Indexes',
    api: Api.getCollectingCategoryIndexes,
    definition: 'X003_DEFINITION',
    interpretation: 'X003_INTERPRETATION',
    colours: x003colours,
  }),
  'x003.10': getLiquidityIndexesConfig({
    chartId: 'x003.10',
    title: 'Collecting Category Indexes',
    subtitle: '10y period',
    api: Api.getCollectingCategoryIndexes,
    definition: 'X003.10_DEFINITION',
    interpretation: 'X003.10_INTERPRETATION',
    colours: x003colours,
  }),
  'x003.5': getLiquidityIndexesConfig({
    chartId: 'x003.5',
    title: 'Collecting Category Indexes',
    subtitle: '5y period',
    api: Api.getCollectingCategoryIndexes,
    definition: 'X003.5_DEFINITION',
    interpretation: 'X003.5_INTERPRETATION',
    colours: x003colours,
  }),
  'x003.1': getLiquidityIndexesConfig({
    chartId: 'x003.1',
    title: 'Collecting Category Indexes',
    subtitle: '1y period',
    api: Api.getCollectingCategoryIndexes,
    definition: 'X003.1_DEFINITION',
    interpretation: 'X003.1_INTERPRETATION',
    colours: x003colours,
  }),
  x002: getLiquidityIndexesConfig({
    chartId: 'x002',
    title: 'Fair Market Value vs Auction Results',
    api: Api.getFairMarketValues,
    definition: 'X002_DEFINITION',
    interpretation: 'X002_INTERPRETATION',
    colours: x002colours,
  }),
};


