import I from 'immutable';
import { select } from 'cpcs-reconnect';

import { categoryById, categoryList } from 'domain/category';
import { substratesById, groupedSubstrates } from 'domain/substrate';
import { mediumsById_sel, groupedMediums_sel } from 'domain/medium/MediumModel';
import { surfacesById, groupedSurfaces } from 'domain/surface';
import { conditionById, conditionList } from 'domain/conditions';
import { countriesById, countryList } from 'domain/country';
import { auctionsById, auctionList } from 'domain/auction';
import { signatureById, signatureList } from 'domain/signatures';

export * from './sagas';

export const EDITIONS_LIST_3D = I.fromJS([
  { id: 'numbered', title: 'Numbered' },
  { id: 'editionSize', title: 'Edition Size' },
  { id: 'artistProof', title: 'Artist Proof' },
  { id: 'unknown', title: 'Unknown' },
]);
export const EDITIONS_LIST_2D = EDITIONS_LIST_3D.insert(
  EDITIONS_LIST_3D.size - 1,
  I.Map({ id: 'pressProof', title: 'Press Proof' }),
);

const editionsById = EDITIONS_LIST_2D.reduce((m, v) => m.set(v.get('id'), v), I.Map());

export const dictionariesById = {
  category: categoryById,
  substrates: substratesById,
  surfaces: surfacesById,
  mediums: mediumsById_sel,
  signature: signatureById,
  conditions: conditionById,
  countries: countriesById,
  auctions: auctionsById,
  edition: select(() => editionsById),
};

export const dictionaryList = {
  categoryList,
  substrateList: groupedSubstrates,
  surfaceList: groupedSurfaces,
  mediumList: groupedMediums_sel,
  conditionList,
  countryList,
  auctionList,
  signatureList,
};

export const AOStamps = ['Signature', 'Studio', 'Estate'];
