export default {
  CustomDragLayer: {
    position: 'fixed',
    pointerEvents: 'none',
    zIndex: 100,
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
  },
  container: {
    width: 235,
    height: 120,
    position: 'absolute',
  },
};
