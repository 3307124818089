import { formTitle } from 'theme/form/formTitle';
import { button } from 'theme/button';

export default {
  ChangePasswordForm: {
    '&:after': {
      content: '""',
      display: 'block',
      clear: 'both',
    },
  },
  title: formTitle,
  button,
};
