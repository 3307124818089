import React from 'react';


import { RefSize } from 'pages/highcharts/helpers/refSize';
import { connectEventsContainer } from 'pages/highcharts/helpers/EventsContext';

import { PAGE_WIDTH, PAGE_HEIGHT, themes } from 'pages/highcharts/helpers/PDFConstants';
import { Logo } from 'pages/highcharts/PDFComponents/Logo';

class LastCoverPDF extends RefSize {
  render() {
    const { PDFState } = this.props;
    const { mainColor } = (themes[PDFState.reportType] || {});
    return (
      <svg width={PAGE_WIDTH} height={PAGE_HEIGHT} ref={this.ref} data-top="0">
        <g>
          <rect
            width={PAGE_WIDTH}
            height={PAGE_HEIGHT}
            fill={mainColor}
          />
          <g transform="translate(262, 690)">
            <Logo />
          </g>
        </g>
      </svg>
    );
  }
}


LastCoverPDF = connectEventsContainer(LastCoverPDF);

export { LastCoverPDF };
