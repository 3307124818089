import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Field, reduxForm } from 'redux-form';
import { compose } from 'redux';
import { connect } from 'cpcs-reconnect';
import FormRowField from 'pages/common/newPage/form/elements/FormRowField';
import Select from 'components/form/select';
import validateAccount from './validate';
import { updateAccountAction } from 'domain/env/EnvActions';
import { countriesValidSel, USAIdSelector } from 'domain/country/CountryModel';
import { FORM_ACCOUNT } from 'domain/const';
import { accountFormInitialData_sel } from 'domain/env/EnvModel';

import injectSheet from 'lib/sheet';
import sheet from './sheet';

class AccountForm extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    updateAccount: PropTypes.func.isRequired,
    valid: PropTypes.bool,
    pristine: PropTypes.bool,
    countryList: PropTypes.any,
    USA_ID: PropTypes.number,
  };

  onSubmit = (data) => {
    this.props.updateAccount(data);
  };

  render() {
    const { classes, countryList, USA_ID } = this.props;
    return (
      <form
        onSubmit={this.props.handleSubmit(this.onSubmit)}
        className={classes.AccountForm}
      >
        <h3
          className={cx(classes.title, 'AccountForm')}
          children="Account information"
        />
        <Field
          type="text"
          name="firstname"
          component={FormRowField}
          modifier="AccountFormField"
          placeholder="First name"
          rootTag="div"
        />
        <Field
          type="text"
          name="lastname"
          component={FormRowField}
          modifier="AccountFormField"
          placeholder="Last name"
          rootTag="div"
        />
        <Field
          type="text"
          name="email"
          component={FormRowField}
          modifier="AccountFormField"
          placeholder="email"
          rootTag="div"
        />
        {
          (!USA_ID) ? null :
          <Field
            name="country"
            placeholder="Country"
            component={FormRowField}
            Field={Select}
            list={countryList}
            isClearable={false}
            modifier={'accountPageField--AccountForm--Country'}
            SelectStyleKey="AccountCountryField"
            />
        }
        <button
          type="submit"
          children="update"
          className={cx(classes.button, 'AccountPage')}
          disabled={!this.props.valid || this.props.pristine}
        />
      </form>
    );
  }
}


export default compose(
  injectSheet(sheet),
  connect({
    updateAccount: updateAccountAction,
    initialValues: accountFormInitialData_sel,
    countryList: countriesValidSel,
    USA_ID: USAIdSelector,
  }),
  reduxForm({
    form: FORM_ACCOUNT,
    validate: validateAccount,
  }),
)(AccountForm);
