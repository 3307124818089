/* eslint-disable no-func-assign */
import React, { useRef, useCallback, useState } from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import 'svg2pdf.js';
import { jsPDF } from 'jspdf';
import Highcharts from 'highcharts';
import e from 'highcharts/modules/exporting';
import eo from 'highcharts/modules/offline-exporting';
import { SvgPreview } from 'pages/highcharts/mock/SvgPreview';
import { RenderCharts } from 'pages/highcharts/mock/RenderCharts';
import { getSVG } from 'pages/highcharts/helpers/getSVG';
import { PDFPreview } from 'pages/highcharts/PDFPreview';
import { initPDFGenerator } from 'pages/highcharts/helpers/initPDFGenerator';
import { REPORT_TYPE_ARTWORK } from 'pages/highcharts/helpers/PDFConstants';

import sheet from './sheet';
import injectSheet from 'lib/sheet';

e(Highcharts);
eo(Highcharts);

function HighchartsPage({ classes }) {
  const [svgs, setSvgs] = useState([]);
  const [isPreviewShown, setIsPreviewShown] = useState(false);
  const previewRef = useRef();
  const refs = [
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
  ];
  const previewPDF = useCallback(async (e) => {
    document.body.style.overflow = 'hidden';
    e.preventDefault();
    const options = Highcharts.merge(Highcharts.getOptions().exporting, {
      type: 'application/pdf',
      // width: 800,
      sourceWidth: 1200,
      // sourceWidth: 842,
    });
    let newSvgs = [];
    let index = -1;
    for (let svgRef of refs) {
      index++;
      let ret = await getSVG([svgRef], options, { index });
      newSvgs.push(ret);
    }
    setSvgs(newSvgs);
    setIsPreviewShown(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const hidePreview = useCallback((e) => {
    document.body.style.overflow = '';
    e.preventDefault();
    setSvgs([]);
    setIsPreviewShown(false);
  }, [setSvgs, setIsPreviewShown]);
  const makePDF = useCallback(async () => {
    console.log({ previewRef });
    // previewRef.current.innerHTML = ret.svg;
    // let svgElement = previewRef.current.firstElementChild;
    // const pdf = new jsPDF(width > height ? 'l' : 'p', 'pt', [width, height]);
    let pdf;
    // let top = 0;
    let count = 0;
    // let svgs = Array.from(previewRef.current.childNodes)
    let svgs = previewRef.current.svgList
      .filter(node => node.nodeName === 'svg');
    for (let svgElement of svgs) {
      count++;
      svgElement.getBoundingClientRect(); // force layout calculation
      console.log('svgElement.getBoundingClientRect()', svgElement.getBoundingClientRect());
      let width = svgElement.width.baseVal.value;
      let height = svgElement.height.baseVal.value;
      const orientation = width > height ? 'l' : 'p';
      pdf = pdf || new jsPDF({ format: [width + 60, height + 60], unit: 'pt', orientation });
      // const pageHeight = pdf.internal.pageSize.height;
      if (count > 1) {
        // pdf.addPage('a4', 'l');
        pdf.addPage([width + 60, height + 60], orientation);
      }
      console.log({ svgElement, width, height });
      await pdf.svg(svgElement, { width, height, x: 30, y: 30 });
      console.log('after add');
    }
      // .then(() => {
      //   pdf.addPage('a4', 'l');
      //   // pageHeight = 595.28px, pageWidth = 841.89px
      //   const pageHeight= pdf.internal.pageSize.height;
      //   console.log({ pageHeight });
      //   // console.log(pdf.output('datauristring'));
      //   // console.log(pdf.output('bloburi'));
      //   // console.log(pdf.output('datauri'));
      //   console.log(pdf.output('pdfobjectnewwindow'));
      // });
    // await Promise.all(promises)
    pdf.addPage('a4', 'l');
    pdf.setFillColor(200, 200, 200);
    pdf.rect(5, 5, 831, 585, 'F');
    // const tab = pdf.output('pdfobjectnewwindow');
    pdf.output('pdfobjectnewwindow');
    // pdf.output('save');
    // pdf.addPage('a4', 'l');
    // // pageHeight = 595.28px, pageWidth = 841.89px
    // // [595.28, 841.89]
    // pdf.setFillColor(200,200,200);
    // // 1110 x 780
    // pdf.rect(5, 5, 831, 585, 'F');
    // const pageHeight = pdf.internal.pageSize.height;
    // const pageWidth = pdf.internal.pageSize.width;
    // console.log({ pageHeight, pageWidth });
    // // console.log(pdf.output('datauristring'));
    // // console.log(pdf.output('bloburi'));
    // // console.log(pdf.output('datauri'));
    // svgElement = refs[0].current.container.current.firstElementChild.firstChild;
    // svgElement.getBoundingClientRect(); // force layout calculation
    // width = svgElement.width.baseVal.value + 60;
    // height = svgElement.height.baseVal.value + 60;
    // await pdf.svg(svgElement, { width, height, x: 30, y: 30 });
    // console.log(pdf.output('pdfobjectnewwindow'));
  }, [previewRef]);
  return (
    <div className={classes.HighchartsPageWrp}>
      <RenderCharts classes={classes} refs={refs} />
      <button className={classes.printBtn} onClick={previewPDF} type="button">configure PDF report ...</button>
      <SvgPreview
        impRef={previewRef}
        parts={svgs}
        hidden={!isPreviewShown}
        onExport={makePDF}
        onHide={hidePreview}
      />
      <hr />
      <h1>PDFPreview</h1>
      <PDFPreview />
    </div>
  );
}
HighchartsPage.propTypes = {
  classes: PropTypes.shape({
    HighchartsPageWrp: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    printBtn: PropTypes.string.isRequired,
  }).isRequired,
};

export default compose(
  injectSheet(sheet),
  initPDFGenerator(REPORT_TYPE_ARTWORK),
)(HighchartsPage);
