import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { compose } from 'redux';
import { field } from 'theme/form';
import inputToPropsDecorator from 'components/form/field/inputToPropsDecorator';
import injectSheet from 'lib/sheet';

export class Input extends React.Component {
  static propTypes = {
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onDragStart: PropTypes.func,
    onDrop: PropTypes.func,
    onFocus: PropTypes.func,
    value: PropTypes.any,
    disabled: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
    ]),
    id: PropTypes.string,
    placeholder: PropTypes.string,
    type: PropTypes.string,
    autoComplete: PropTypes.string,
    setFocusOnMount: PropTypes.bool,
    modifier: PropTypes.string,
    classes: PropTypes.shape({
      field: PropTypes.string.isRequired,
    }).isRequired,
    maxLength: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    InputComponent: PropTypes.any,
  };

  static defaultProps = {
    InputComponent: 'input',
  };

  componentDidMount() {
    if (this.props.setFocusOnMount && this.ref) {
      this.ref.focus();
    }
  }

  render() {
    const { name, onBlur, onChange, onDragStart, onDrop, onFocus, value, disabled, id, placeholder,
      type = 'text', autoComplete, classes, modifier, maxLength, InputComponent,
    } = this.props;
    return (
      <InputComponent
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        onDragStart={onDragStart}
        ref={node => this.ref = node}
        onDrop={onDrop}
        onFocus={onFocus}
        value={value}
        className={cx(classes.field, 'Input', modifier)}
        disabled={disabled}
        maxLength={maxLength}
        id={id}
        placeholder={placeholder}
        type={type}
        autoComplete={autoComplete}
      />
    );
  }
}

export default compose(
  inputToPropsDecorator,
  injectSheet({ field }),
)(Input);
