import arrow from './arrow.svg';
import { button } from 'theme/button';
import { PageHeader, title, actions } from 'theme/Dashboard';


export const sheet = {
  PageHeader,
  title,
  back: {
    width: 24,
    height: 24,
    display: 'inline-block',
    background: `url("${arrow}") scroll no-repeat center`,
    backgroundSize: [16, 16],
    marginRight: 10,
  },
  button,
  actions,
};
