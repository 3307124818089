// Holding Period | CAGR
export default [
  [17, 9.1],
  { name: 'foo name', x: 10, y: 40, color: '#FF0000', artist: 'foo bar' },
  { x: 20, y: 40, marker: { fillColor: '#BF0B23', radius: 10 } },
  [35, 13.91],
  [4.5, -1.5],
  [25.5, 11.27],
  [5, 4.73],
  [18.5, 7.49],
  [25.5, 10.18],
  [26, 10.83],
  [13, 11.06],
  [10, -0.95],
  [6.5, 18.74],
  [41.5, 7.32],
  [4, 5.84],
  [6.5, 17.89],
  [21.5, 6.68],
  [25.5, 7.97],
  [3.5, 7.2],
  [31.5, 8.73],
  [28.5, 6.24],
  [3, -7.17],
  [1.5, -2],
  [28, 5.21],
  [10, 2.14],
  [3.5, -32.67],
  [20, 8.69],
  [22, 7.97],
  [27, 5.59],
  [2.5, -14.88],
  [29, 5.49],
  [19, 2.63],
  [15, 10.14],
  [5, 4.56],
  [24, 12.12],
  [26.5, 7.05],
  [4.5, -0.16],
  [7, 10.71],
  [15, 0.64],
  [17.5, 14.89],
  [20, 6.7],
  [6, -4.39],
  [16, 16.08],
  [14, 19.22],
  [24, 5.9],
  [3.5, 10.89],
  [13, 18.14],
  [4, 11.86],
  [14, 21.57],
  [23.5, 8.98],
  [12.5, 7.64],
  [17.5, 17.1],
  [28.5, 9.8],
  [31, 8.43],
  [22, 7.85],
  [8, -10.76],
  [24, 7.26],
  [15.5, 12.68],
  [2, 6.9],
  [10, 11.8],
  [17, 4.24],
  [10, 8.79],
  [18.5, 17.17],
  [22, 9.71],
  [10.5, 17.42],
  [11.5, 8.17],
  [24.5, 10.14],
  [2.5, 55.17],
  [13.5, 24.35],
  [10.5, 15.55],
  [14.5, 18.26],
  [3, 21.11],
  [4, 27.33],
  [12, 11.26],
  [11.5, 17.43],
  [8.5, 17.81],
];
