import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import I from 'immutable';
import cx from 'classnames';
import { setPropTypes } from 'lib/helpers';

import sheet from './sheet';
import injectSheet from 'lib/sheet';

function Item({ classes, valueNormalize, preview, cancelPreview, doSelect, index, onMouseMove, item }) {
  return <li>
    <button
      type="button"
      title={valueNormalize(item)}
      onMouseEnter={() => preview(item.get('id'))}
      onMouseLeave={cancelPreview}
      onMouseMove={onMouseMove}
      onClick={() => doSelect(item.get('id'))}
      className={cx(classes.option, { active: index === item.get('id') })}
      children={valueNormalize(item)}
    />
  </li>;
};

Item.propTypes = {
  classes: PropTypes.shape({
    option: PropTypes.string,
  }),
  valueNormalize: PropTypes.func,
  preview: PropTypes.func,
  cancelPreview: PropTypes.func,
  doSelect: PropTypes.func,
  index: PropTypes.number,
  onMouseMove: PropTypes.func,
  item: PropTypes.instanceOf(I.Collection),
};

const OptionSeparator = compose(
  injectSheet(sheet),
  setPropTypes({
    classes: PropTypes.shape({
      OptionSeparator: PropTypes.string,
    }),
    modifier: PropTypes.string,
  }),
)(
  ({ classes, modifier }) => <li className={cx(classes.OptionSeparator, modifier)} />,
);

function ListNoTheme({ modifier, valueNormalize, classes, preview, cancelPreview, doSelect, index, list, onMouseMove, common = new I.List() }) {
  const params = { classes, valueNormalize, preview, cancelPreview, doSelect, index, onMouseMove, common };
  const commonCount = list.filter(v => common.includes(v.get('id'))).size;
  return (
    <ul className={classes.dropMenu}>
      {
        common
          .map(v => list.find(i => v === i.get('id')))
          .filter(v => v)
          .map(item => <Item key={item.get('id')} {...params} item={item} />)
      }
      {
        !!commonCount &&
          <OptionSeparator modifier={modifier} />
      }
      {
        list
          .filter(v => !common.includes(v.get('id')))
          .map(item => <Item key={item.get('id')} {...params} item={item} />)
      }
    </ul>
  );
}

ListNoTheme.propTypes = {
  modifier: PropTypes.string,
  valueNormalize: PropTypes.func,
  classes: PropTypes.shape({
    dropMenu: PropTypes.string,
  }),
  preview: PropTypes.func,
  cancelPreview: PropTypes.func,
  doSelect: PropTypes.func,
  index: PropTypes.number,
  list: PropTypes.oneOfType([
    PropTypes.instanceOf(I.Collection),
    PropTypes.array,
  ]),
  onMouseMove: PropTypes.func,
  common: PropTypes.oneOfType([
    PropTypes.instanceOf(I.Collection),
    PropTypes.array,
  ]),
};

const List = injectSheet(sheet)(ListNoTheme);


function autocompleteOption({ modifier, valueNormalize = e => e.get('title') } = {}) {
  return setPropTypes(
    {
      show: PropTypes.bool,
      list: PropTypes.instanceOf(I.Collection),
    },
  )(
    (props) => {
      if (props.show && props.list.size) {
        return <List {...props} modifier={modifier} valueNormalize={valueNormalize} />;
      }
      return null;
    },
  );
}

autocompleteOption.propTypes = {
  valueNormalize: PropTypes.func,
  modifier: PropTypes.string,
};

export default autocompleteOption;
