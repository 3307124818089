import React from 'react';
import PropTypes from 'prop-types';
import { createdAtToString } from 'lib/helpers';
import I from 'immutable';
import { connect } from 'cpcs-reconnect';
import { compose } from 'redux';
import { user_sel } from 'domain/env/EnvModel';
import injectSheet from 'lib/sheet';
import styles from './sheet.js';

// eslint-disable-next-line react/jsx-key
const br = [<br key="br-0" />, <br key="br-1" />];

class Details extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.shape({
      Details: PropTypes.string.isRequired,
      commentBox: PropTypes.string.isRequired,
      comment: PropTypes.string.isRequired,
      created: PropTypes.string.isRequired,
      recipientWrapper: PropTypes.string.isRequired,
      sharedByEmail: PropTypes.string.isRequired,
      emails: PropTypes.string.isRequired,
      emailsIcon: PropTypes.string.isRequired,
      emailsPopover: PropTypes.string.isRequired,
      emailsList: PropTypes.string.isRequired,
      emailListItem: PropTypes.string.isRequired,
    }).isRequired,
    share: PropTypes.instanceOf(I.Record).isRequired,
    user: PropTypes.instanceOf(I.Record).isRequired,
  };

  get comment() {
    const { share } = this.props;
    if (share.message) {
      const parts = share.message.split(/\n\n+/g);
      return (
        <React.Fragment>
          “
          {
            parts.map((v, k) => (
              <span
                key={k}
                children={[v, k + 1 === parts.length ? null : br]}
              />
            ))
          }
          ”
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>&nbsp;</React.Fragment>
    );
  }

  get sharedByMe() {
    const { user, share } = this.props;
    if (!user || !share) return false;
    return user.email === share.get('email') || share.get('email') === 'You';
  }

  render() {
    const { classes, share: { created, email, emails }, user } = this.props;
    return (
      <div
        className={classes.Details}
      >
        <div
          className={classes.commentBox}
        >
          <div
            className={classes.comment}
            children={this.comment}
          />
        </div>
        <div className={classes.recipientWrapper}>
          <div
            className={classes.created}
            children={createdAtToString(created)}
          />
          {
            (!this.sharedByMe) &&
              <div
                className={classes.sharedByEmail}
                children={email}
              />
          }
          {
            (user.email === email) &&
              <div className={classes.emails}>
                <i
                  className={classes.emailsIcon}
                />
                <div className={classes.emailsPopover}>
                  The art is shared with<br />
                  <ul className={classes.emailsList}>
                    {
                      emails.map(v => (
                        <li
                          key={v}
                          className={classes.emailListItem}
                          children={v}
                        />
                      ))
                    }
                  </ul>
                </div>
              </div>
          }
        </div>
      </div>
    );
  }
}

const DetailsPure = injectSheet(styles)(Details);

export {
  DetailsPure,
};

export default compose(
  connect({
    user: user_sel,
  }),
  injectSheet(styles),
)(Details);
