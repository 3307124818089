import I from 'immutable';

export default I.fromJS([
  {
    slug: '/art/rename',
    title: 'Rename gallery',
    allowForDefault: false,
  },
  {
    slug: '/art/share/gallery',
    title: 'Share gallery',
    allowForDefault: true,
  },
  {
    slug: '/art/delete',
    title: 'Delete gallery',
    allowForDefault: false,
  },
]);
