/**
 * usage
 *   import { Field } from 'redux-form';
 *   import Select from 'components/form/select';
 *   <Field
 *     name="sizeUnit"
 *     component={FormRowField}
 *     Field={Select}
 *     list={SIZE_LIST}
 *     onChange={this.onFilterChange}
 *     isClearable={false}
 *     isSearchable={false}
 *     labelLayout
 *     label="Unit"
 *     modifier="advancedFilter advancedFilter-Range sizeUnitField"
 *     rootTag="div"
 *   />
 *   const SIZE_LIST = I.fromJS([
 *     { id: 'MM', title: 'mm' },
 *     { id: 'CM', title: 'cm' },
 *     { id: 'INCH', title: 'inch' },
 *   ]);
**/
import React from 'react';
import ReactSelect, { components } from 'react-select';
import { connect } from 'cpcs-reconnect';
import { compose } from 'redux';
import cx from 'classnames';
import PropTypes from 'prop-types';
import I from 'immutable';

import { field } from 'theme/form';
import { DEFAULT_FONTFAMILY, DARK_BLUE_COLOR } from 'theme/theme';
import { toggleExtraFooterGapAction } from 'domain/ui/UIActions';
import { isEmpty } from 'lib/helpers';

import { styles, flatStyles, groupStyles } from './sheet';
import injectSheet from 'lib/sheet';

const formatGroupLabel = data => {
  return <div style={groupStyles}>
    <span>{data.label}</span>
  </div>;
};

const SingleValue = ({ children, innerProps, ...props }) => {
  const title = typeof children === 'string' ? children : undefined;
  return (
    <components.SingleValue {...{ ...props, children, innerProps: { ...innerProps, title } }}/>
  );
};

SingleValue.propTypes = {
  children: PropTypes.any,
  innerProps: PropTypes.any,
};

const MultiValueLabel = ({ children, innerProps, ...props }) => {
  const title = typeof children === 'string' ? children : undefined;
  return (
    <components.MultiValueLabel {...{ ...props, children, innerProps: { ...innerProps, title } }} />
  );
};

MultiValueLabel.propTypes = {
  children: PropTypes.any,
  innerProps: PropTypes.any,
};

const inputStyle = isHidden => ({
  background: 0,
  border: 0,
  opacity: isHidden ? 0 : 1,
  outline: 0,
  padding: 0,
  boxShadow: 'none',
  fontFamily: DEFAULT_FONTFAMILY,
  color: DARK_BLUE_COLOR,
  fontSize: '14px',
});

const Input = (props) => {
  return (
    <components.Input
      {...props}
      id={`${props.id}-search`}
      inputStyle={inputStyle(props.isHidden)}
    />
  );
};

Input.propTypes = {
  isHidden: PropTypes.bool,
  id: PropTypes.string,
};

const groupedListToFlat = list => I.List.isList(list) ? list : list.reduce((p, n) => p.concat(n.options), []);

const groupedParseValue = v => {
  if (isEmpty(v)) return '';
  if (v && v.get) {
    return v.get('value') || v.get('id');
  }
  return v.value || v.id || '';
};

class PureSelect extends React.PureComponent {
  static propTypes = {
    id: PropTypes.string,
    parseValue: PropTypes.func,
    parseLabel: PropTypes.func,
    filterOption: PropTypes.func,
    noOptionsMessage: PropTypes.func,
    placeholder: PropTypes.string,
    isMulti: PropTypes.bool,
    grouped: PropTypes.bool,
    isClearable: PropTypes.bool,
    styles: PropTypes.func.isRequired,
    list: PropTypes.oneOfType([ PropTypes.array, PropTypes.instanceOf(I.List)]).isRequired,
    input: PropTypes.shape({
      onChange: PropTypes.func,
      value: PropTypes.oneOfType([ PropTypes.string, PropTypes.number, PropTypes.array ]),
      name: PropTypes.string,
      onFocus: PropTypes.func,
      onBlur: PropTypes.func,
    }).isRequired,
    meta: PropTypes.shape({}).isRequired,
    modifier: PropTypes.string,
    classes: PropTypes.shape({
      field: PropTypes.string.isRequired,
    }).isRequired,
    fixDropdownPadding: PropTypes.bool,
    toggleFooterGap: PropTypes.func,
    dataComponent: PropTypes.string,
    onInputChange: PropTypes.func,
  }

  static defaultProps = {
    saveValues: false,
    parseValue: v => v && v.get ? v.get('id') : '',
    parseLabel: v => v && v.get ? v.get('title') : '',
    placeholder: 'Select ...',
    isMulti: false,
    isClearable: true,
    grouped: false,
    styles,
    list: new I.List(),
    noOptionsMessage: () => null,
  }

  state = {
    term: '',
    options: null,
  }

  getValue() {
    const { input: { value }, parseValue, list, isMulti, grouped } = this.props;
    const parser = grouped ? groupedParseValue : parseValue;
    if (!value) return isMulti ? [] : '';
    const options = grouped ? groupedListToFlat(list) : list;
    if (typeof value !== 'string' && !!value.reduce) {
      return value.map(v => options.find(item => parser(item).toString() === v.toString()));
    }
    return options.find(item => parser(item).toString() === value.toString());
  }

  onChange = (value) => {
    const { input: { onChange }, parseValue, isMulti, grouped } = this.props;
    const parser = grouped ? groupedParseValue : parseValue;
    let parsed;
    if (value) {
      parsed = isMulti ? value.map(parser) : parser(value);
    } else {
      parsed = isMulti ? [] : null;
    }
    onChange(parsed);
  }

  fixDropdownPadding = v => () => {
    const { toggleFooterGap, fixDropdownPadding } = this.props;
    if (!fixDropdownPadding) return;
    toggleFooterGap && toggleFooterGap(v);
  }

  filterOption = (option, term = '') => {
    if (!term) return true;
    const { label = '' } = option;
    if (term.includes(' ')) {
      return term.toLowerCase().split(' ')
        .reduce((p, n) => p && label.toString().toLowerCase().includes(n), true);
    }
    return label.toString().toLowerCase().includes(term.toLowerCase());
  }

  matchOption = (option, term) => {
    const { parseLabel, grouped } = this.props;
    let { label } = option;
    let match = false;
    if (!grouped) {
      label = parseLabel(option);
    }
    const exactFound = (`${label}`).toLowerCase() === term.toLowerCase();
    if (exactFound) {
      return { exactFound, match: true };
    }
    if (term.includes(' ')) {
      match = term.toLowerCase().split(' ')
        .reduce((p, n) => p && label.toString().toLowerCase().includes(n), true);
      return { exactFound, match };
    }
    match = label.toString().toLowerCase().includes(term.toLowerCase());
    return { exactFound, match };
  }

  get options() {
    return this.state.options || this.props.list;
  }

  asyncFilterOptions = (term) => {
    const { grouped, list } = this.props;
    if (!term) {
      this.setState({ options: list });
      return;
    }
    let bestMatch = [];
    if (grouped) {
      let newList = list.reduce((prev, group) => {
        const options = group.options.reduce((prev, option) => {
          const { exactFound, match } = this.matchOption(option, term);
          if (exactFound) {
            bestMatch = [...bestMatch, option];
            return prev;
          }
          if (match) {
            return [ ...prev, option ];
          }
          return prev;
        }, []);
        if (options.length) {
          return [ ...prev, { ...group, options }];
        }
        return prev;
      }, []);
      if (bestMatch.length) {
        newList = [
          { label: 'Closest match', options: bestMatch },
          ...newList,
        ];
      }
      
      this.setState({ options: newList });
    } else {
      const newList = list.reduce((prev, option) => {
        const { exactFound, match } = this.matchOption(option, term);
        if (exactFound) {
          bestMatch = [...bestMatch, option];
          return prev;
        }
        if (match) {
          return [ ...prev, option ];
        }
        return prev;
      }, []);
      this.setState({ options: bestMatch.concat(newList) });
    }
  }

  alwaysTrue = () => true

  render() {
    const {
      input: { onFocus, onBlur }, grouped, id, dataComponent,
      parseValue, parseLabel, styles, classes, modifier, ...props
    } = this.props;
    const filterOptions = (!this.props.onInputChange && !this.props.filterOption) ?
      {
        onInputChange: this.asyncFilterOptions,
        filterOption: this.alwaysTrue,
      } : {};
    if (grouped) {
      return <div
        data-component={cx(dataComponent, 'PureSelect grouped')}
        data-name={`autocomplete--${this.props.input.name || 'unnamed'}`}
        style={{ width: '100%' }}
      >
        <ReactSelect
          filterOption={this.props.filterOption || this.filterOption}
          {...props}
          {...filterOptions}
          inputId={id}
          id={`${id}-container`}
          onChange={this.onChange}
          options={this.options}
          className={cx(classes.field, 'SelectField', modifier)}
          classNamePrefix="react-select"
          styles={styles({ ...this.props, grouped: true })}
          onBlur={() => onBlur && onBlur()}
          onFocus={() => onFocus && onFocus()}
          formatGroupLabel={formatGroupLabel}
          components={{ Input, MultiValueLabel, SingleValue }}
          value={this.getValue()}
          isDisabled={props.disabled || props.isDisabled}
          onMenuOpen={this.fixDropdownPadding(true)}
          onMenuClose={this.fixDropdownPadding(false)}
        />
      </div>;
    }
    return <div
      data-component={cx(dataComponent, 'PureSelect')}
      data-name={`autocomplete--${this.props.input.name || 'unnamed'}`}
      style={{ width: '100%' }}
    >
      <ReactSelect
        filterOption={this.props.filterOption || this.filterOption}
        {...props}
        inputId={id}
        id={`${id}-container`}
        value={this.getValue()}
        options={this.options}
        className={cx(classes.field, 'SelectField', modifier)}
        classNamePrefix="react-select"
        styles={styles(this.props)}
        onBlur={() => onBlur && onBlur()}
        onFocus={() => onFocus && onFocus()}
        getOptionValue={parseValue}
        getOptionLabel={parseLabel}
        onChange={this.onChange}
        components={{ Input, MultiValueLabel, SingleValue }}
        isDisabled={props.disabled || props.isDisabled}
        onMenuOpen={this.fixDropdownPadding(true)}
        onMenuClose={this.fixDropdownPadding(false)}
      />
    </div>;
  }
}

PureSelect = compose(
  injectSheet({ field }),
  connect({
    toggleFooterGap: toggleExtraFooterGapAction,
  }),
)(PureSelect);

/**
 * props:
 *   alignedLeft
**/
export const PureFlatSelect = ({ className, modifier, ...props }) => <ReactSelect
  styles={flatStyles(props)}
  isSearchable={false}
  classNamePrefix="react-select"
  // defaultMenuIsOpen
  {...props}
  className={cx('PureFlatSelect', className, modifier, 'SelectField')}
/>;

PureFlatSelect.propTypes = {
  className: PropTypes.string,
  modifier: PropTypes.string,
};

export class AsyncMultiAutocomplete extends React.PureComponent {
  static propTypes = {
    parseValue: PropTypes.func,
    getValues: PropTypes.func,
    isMulti: PropTypes.bool,
    storage: PropTypes.oneOfType([ PropTypes.array, PropTypes.instanceOf(I.List)]).isRequired,
    list: PropTypes.oneOfType([ PropTypes.array, PropTypes.instanceOf(I.List)]).isRequired,
    input: PropTypes.shape({
      onChange: PropTypes.func,
      value: PropTypes.oneOfType([ PropTypes.string, PropTypes.number, PropTypes.array ]),
      name: PropTypes.string,
    }).isRequired,
  }

  static defaultProps = {
    parseValue: v => v && v.get ? (v.get('id') || '').toString() : '',
    isMulti: false,
  }

  getArrayValue = () => {
    const { input: { value }, isMulti } = this.props;
    return isMulti ? value : [ value ];
  }

  checkValues = () => {
    const { parseValue, getValues, storage } = this.props;
    const idList = this.getArrayValue().filter(v => !storage.find(i => parseValue(i).toString() === v.toString()));
    if (idList && getValues) getValues(idList);
  }

  componentDidUpdate = ({ input: { value } }) => {
    if (this.props.isMulti) {
      if (!!value && !!this.props.input.value && value.length !== this.props.input.value.length) this.checkValues();
    } else {
      if (value !== this.props.input.value) this.checkValues();
    }
  }

  componentDidMount = () => this.checkValues();

  render() {
    const { list, storage, ...props } = this.props;
    const convertedInput = this.getArrayValue().map(v => v.toString());
    const selectedOptions = storage.filter(v => convertedInput.includes(props.parseValue(v)));
    let options = [];
    if (props.grouped) {
      options = I.fromJS(list).update(0, v => v.update('options', v => v.push(...selectedOptions))).toJS();
    } else {
      options = selectedOptions.concat(list);
    }
    return <PureSelect
      dataComponent="AsyncMultiAutocomplete"
      hideSelectedOptions
      filterOption={() => true}
      list={options}
      {...props}
    />;
  }
}

export default PureSelect;
