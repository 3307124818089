import { matchPath } from 'react-router-dom';
import Route from './Route';
import { getLinks } from './helpers';
import * as builders from './builders';
import { push as pushAction } from 'connected-react-router';

/**
 * ..._oldLink used for backward compatibility
**/
const routes = [
  // home
  new Route('homePage', '/'),
  new Route('artistPage', '/artist/:authorId'),
  new Route('artistReportsPage', '/artist/:authorId/analytics'),
  new Route('preferencesPage', '/preferences'),
  new Route('rtvValues', '/value'),
  new Route('analyticsListPage', '/analytics'),
  // aliases
  new Route('newArtworkFromHP', '/new-art'),
  new Route('newAlertFromHP', '/new-alert'),
  // alert
  new Route('alertList', '/alerts'),
  new Route('lotPage', '/alerts/:alertId/:artworkId'),
  new Route('alertAOsPage', '/alerts/:alertId'),
  // artwork
  new Route('artworkPage', '/ao/:artworkId'),
  // collection
  new Route('collectionItemPage', '/art/gallery/:gId/artist/:authorId/artwork/:artworkId', { builder: builders.collectionItemPage }),
  new Route('artNew', '/art/newart'),
  new Route('artEdit', '/art/artwork/edit/:artworkId'),
  new Route('collectionListPage', '/art/:gId?'),
  new Route('collectionItemPage_oldLink', '/my-collection/gallery/:gId/artist/:authorId/artwork/:artworkId', { builder: builders.collectionItemPage }),
  new Route('collectionList_oldLink', '/my-collection/:gId?'),
  // shares
  new Route('myShares', '/shared/by-me'),
  new Route('sharedWithMe', '/shared/with-me'),
  new Route('sharedItemsPage', '/shared/:shareId', { builder: builders.shareLink }),
  new Route('sharedGallery', '/shared/:shareId/gallery/:gId'),
  new Route('sharedArtwork', '/shared/:shareId/artwork/:artworkId'),
  new Route('shares', '/shared'),
  // account, auth
  new Route('accountPage', '/account'),
  new Route('accountPageBilling', '/account#billing'),
  new Route('auth', '/auth/login'),
  new Route('signUp', '/auth/create-new'),
  new Route('signUpSuccess', '/auth/create-new/success'),
  new Route('forgot', '/auth/login/forgot'),
  new Route('reset', '/auth/reset-password'),
  new Route('authSocialNetwork', '/auth/:sn'),

  new Route('billing', '/account/billing'),
  new Route('insightPage', '/insight'),
  new Route('intelligencePage', '/intelligence'),
  new Route('highchartsPage', '/highcharts'),
  new Route('createPassword', '/create-password'),
  // used to confirm email taken by SN registration
  new Route('confirmEmailOnSNReg', '/verify-email'),
  // used to confirm email taken by standard registration
  new Route('confirmEmailOnStdReg', '/confirm-email'),
  // used to confirm email taken by change email in profile page
  new Route('confirmEmailOnEmailChange', '/update-email'),
  new Route('saleLotsListPage', '/auction/:auctionId/sale/:saleId'),

  new Route('addArtUnifiedPage', '/add-art'),
];


const hashTable = routes.reduce((collected, r) => ({ ...collected, [r.name]: r }), {});

export const links = getLinks(hashTable);

/**
 * @param routeName
 * @param params
 * lnk('collectionListPage', { gId: 1, query: { sort: 'artistByName' } })
 *  => '/my-collection/1?sort=artistByName'
 * lnk('collectionListPage', { gId: 1, query: { sort: 'artistByName' }, hash: 'pageSection' })
 *  => '/my-collection/1?sort=artistByName#pageSection'
**/
export const lnk = (routeName, params) => hashTable[routeName].build(params);

export const routerPush = (...args) => pushAction(lnk(...args));

export const matchRoute = (locationPathname) => {
  for (let route of routes) {
    const match = matchPath(locationPathname, {
      exact: ('exact' in route) ? route.exact : true,
      strict: false,
      path: route.link,
    });
    if (match !== null) {
      return { match, route };
    }
  }
  return null;
};
