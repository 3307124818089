import record, { integer, listOf } from 'cpcs-recordjs';
import { field, selector } from 'cpcs-reconnect';
import { listToMap, toGroupedList } from 'lib/serialize';
import { Auction } from 'model';
import * as A from './AuctionAction';

const State = record('Auctions', {
  list: listOf(Auction),
  common: listOf(integer),
});

export const auctions = field('auctions');
export const auctionList = auctions.then(State.$list);
export const auctionCommonList = auctions.then(State.$common);
export const groupedAuctions  = selector(auctionList, auctionCommonList, toGroupedList);
export const auctionsById = selector(auctionList, listToMap);
export const auctionsById_sel = selector(auctionList, listToMap);

export const reducer = {
  auctions(state = new State(), action) { //NOSONAR
    switch (action.type) { //NOSONAR

      case A.fetchAuctionsAction.success:
        return state.apply(
          State.$list.parsed(action.payload.content),
          State.$common.parsed(action.payload.common),
        );

      default:
        return state;
    }
  },
};
