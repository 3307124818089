import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { routerMiddleware, connectRouter } from 'connected-react-router';
import I from 'immutable';
import installDevTools from 'immutable-devtools';
import thunk from 'redux-thunk';
import createSagaMiddleware, { END } from 'redux-saga';
import { initialState as envState } from 'domain/env';
import { chainReducers } from 'domain/lib';
import { ROUTER } from 'domain/const';
import Socket from 'components/ws';

const {
  PREVENT_LOCATION_CHANGE,
  ALLOW_LOCATION_CHANGE,
  LOCATION_CHANGE,
} = ROUTER;

installDevTools(I);

const preventLocationChangeMiddleware = history => () => {
  let formList = I.Set();
  let unblock;
  return next => action => {
    const f = formList.get(action.payload);
    if (action && action.type === PREVENT_LOCATION_CHANGE && !f) {
      formList = formList.add(action.payload);
      // store unblock fn
      if (!unblock) {
        unblock = history.block('Are you sure? You have unsaved changes!');
      }
    } else if (action.type === ALLOW_LOCATION_CHANGE) {
      formList = formList.delete(action.payload);
      if (formList.size === 0 && unblock) {
        unblock();
        unblock = null;
      }
    } else if (action.type === LOCATION_CHANGE) {
      if (unblock) {
        unblock();
        unblock = null;
      }
      formList = formList.clear();
    }
    return next(action);
  };
};

const BroadcastChannelMiddleware = (store) => {
  global.broadcastToken = Math.random().toString(16).substr(2);
  const storageListener = (ev) => {
    if (ev.key !== 'BroadcastChannel') return;
    try {
      const { action, broadcastToken } = JSON.parse(ev.newValue);
      if (!action || !action.type || (broadcastToken && (broadcastToken !== global.broadcastToken))) return;
      store.dispatch(action);
      if (broadcastToken) {
        // allow to dispatch same event
        localStorage.setItem('BroadcastChannel', '');
      }
    } catch (err) {
      console.error(err);
    }
  };
  window.addEventListener('storage', storageListener);
  return next => action => next(action);
};

function configureStore(history, ...additionalMiddleware) {
  let composeEnhancers = compose;
  if (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      maxAge: 100,
      actionsBlacklist: [
        '@@redux-form/REGISTER_FIELD',
        '@@redux-form/UNREGISTER_FIELD',
        '@@redux-form/FOCUS',
        '@@redux-form/BLUR',
        // '@@redux-form/CHANGE',
        // '@@redux-form/DESTROY',
      ],
    });
  }
  const sagaMiddleware = createSagaMiddleware();
  const initialState = { env: envState() };

  const store = createStore(
    combineReducers({
      ...require('domain/env').reducer,
      ...require('domain/ui').reducer,
      ...require('domain/country').reducer,
      ...require('domain/occupations').reducer,
      ...require('domain/auction').reducer,
      ...require('domain/artist').reducer,
      ...require('domain/category').reducer,
      ...require('domain/substrate').reducer,
      ...require('domain/medium/MediumModel').reducer,
      ...require('domain/surface').reducer,
      ...require('domain/conditions').reducer,
      ...require('domain/ownArtwork/OwnArtworkModel').reducer,
      ...require('domain/galleries/GalleryModel').reducer,
      ...require('domain/shares').reducer,
      ...require('domain/considering').reducer,
      ...require('domain/alerts').reducer,
      ...require('domain/author').reducer,
      ...require('domain/signatures').reducer,
      ...require('domain/artwork/ArtworkModel').reducer,
      ...require('domain/artworkPage/ArtworkPageModel').reducer,
      ...require('domain/globalSearch/GlobalSearchModel').reducer,
      ...require('domain/comparables/ComparablesModel').reducer,
      ...require('domain/otherArtistWorks/otherArtistWorksModel').reducer,
      ...require('domain/advancedFilter/AdvancedFilterModel').reducer,
      ...require('pages/common/buyRtv/buyRtvModel').reducer,
      ...require('pages/common/buyAnalytics/buyAnalyticsModel').reducer,
      ...require('pages/common/couponForm/couponFormModel').reducer,
      ...require('domain/rtvValueListPage/RtvValueListPageModel').reducer,
      ...require('domain/analyticsListPage/AnalyticsListPageModel').reducer,
      ...require('pages/upgradeSubscriptionPage/upgradeSubscriptionModel').reducer,
      ...require('pages/billingPage/form/cardFormModel').reducer,
      ...require('pages/saleLotsListPage/SaleLotsListPageModel').reducer,
      ...require('pages/createPassword/createPasswordModel').reducer,
      router: chainReducers(
        require('./router/RouterModel').prevLocationReducer,
        connectRouter(history),
        require('./router/RouterModel').reducer,
      ),
      form: require('redux-form').reducer,
      // add other modules here
    }),
    initialState,
    composeEnhancers(
      applyMiddleware(
        sagaMiddleware,
        thunk,
        preventLocationChangeMiddleware(history),
        BroadcastChannelMiddleware,
        routerMiddleware(history),
        ...additionalMiddleware,
      ),
    ),
  );

  store.runSaga = sagaMiddleware.run;
  store.close = () => {
    store.dispatch(END);
    Socket.disconnect();
  };
  Socket.setStore(store);
  return store;
}

export default configureStore;
