import { DEFAULT_FONTFAMILY } from 'theme/theme';

export default {
  HighchartsPageWrp: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 15,
    margin: '0 auto',
    width: '100%',
  },
  HighchartsPage: {
  },
  content: {
    width: 1280,
    paddingTop: 158,
    paddingBottom: 70,
    '@media screen and (max-width: 1460px)': {
      width: '100%',
      padding: [158, 30, 70],
    },
    '@media screen and (max-width: 550px)': {
      paddingTop: 98,
    },
    '@media print': {
      display: 'block',
      textAlign: 'center',
      width: '90%',
    },
  },
  printBtn: {
    flexShrink: 0,
    cursor: 'pointer',
    border: '1px solid transparent',
    textAlign: 'center',
    fontFamily: DEFAULT_FONTFAMILY,
    textTransform: 'uppercase',
    textDecoration: 'none',
    padding: [6, 15],
    color: '#ffffff',
    width: 320,
    height: 56,
    fontSize: 18,
    background: '#304C95',
    marginTop: 40,
    fontWeight: 600,
    '&.pdf': {
      zIndex: 3,
      position: 'fixed',
      top: 20,
      right: 50,
    },
  },
  pageMock: {
    height: 1200,
    width: 900,
    background: '#955',
  },
};
