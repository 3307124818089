import React from 'react';
import PropTypes from 'prop-types';
import I from 'immutable';
import cx from 'classnames';
import { nothing } from 'lib/helpers';
import { fetchValue, getNewHoveredId, getValue, resortListWithCommon, sortResults } from './utils';

import injectSheet from 'lib/sheet';
import sheet from './sheet.js';

const PREVIEW_LAST_ACTION_KEYDOWN = 'PREVIEW_LAST_ACTION_KEYDOWN';
const PREVIEW_LAST_ACTION_MOUSEMOVE = 'PREVIEW_LAST_ACTION_MOUSEMOVE';

class Autocomplete extends React.Component {
  static propTypes = {
    id: PropTypes.string,
    placeholder: PropTypes.string,
    classes: PropTypes.object.isRequired,
    optionList: PropTypes.instanceOf(I.List),
    onSearch: PropTypes.func.isRequired,
    name: PropTypes.string,
    input: PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      name: PropTypes.string,
      onChange: PropTypes.func,
    }).isRequired,
    valueNormalize: PropTypes.func,
    // fetchData: PropTypes.func,
    disabled: PropTypes.bool,
    children: PropTypes.func.isRequired,
    meta: PropTypes.shape({
      touched: PropTypes.bool,
      error: PropTypes.string,
      warning: PropTypes.string,
    }).isRequired,
    comboBox: PropTypes.bool,
    showClear: PropTypes.bool,
    filterValues: PropTypes.bool,
    ownSortingAndFiltration: PropTypes.bool,
    callOnChangeWhileTyping: PropTypes.bool,
    modifier: PropTypes.string,
  }

  static defaultProps = {
    ownSortingAndFiltration: false,
    placeholder: '',
    optionList: new I.List(),
    valueNormalize: x => x.get('title'),
    fetchData: nothing,
    disabled: false,
    comboBox: false,
    showClear: false,
    filterValues: false,
    callOnChangeWhileTyping: true,
  }

  constructor(props) {
    super(props);
    this.state = {
      term: getValue(props) || '',
      show: false,
      hoveredId: 0,
      preview: '',
      prevVal: null,
    };
    this.timeout = null;
    this.focused = false;
    this.focusedElement = null;
  }

  previewLastAction = PREVIEW_LAST_ACTION_KEYDOWN;

  static getDerivedStateFromProps(props, state) {
    let ret = null;
    const { input: { value } } = props;
    if (value !== state.prevVal) {
      ret = {
        prevVal: value,
        term: getValue(props) || '',
        preview: '',
      };
    }
    return ret;
  }

  async componentDidUpdate(prevProps) {
    if (this.props.input.value && this.props.input.value !== prevProps.input.value) {
      const term = getValue(this.props) || await fetchValue(this.props);
      this.setState({ term: term || '' });
    } else if (!this.state.term && !this.props.optionList.equals(prevProps.optionList)) {
      const term = getValue(this.props);
      this.setState({ term: term || '' });
    } else if (!this.focused && this.props.input.value && this.state.term && this.props.optionList.size > 0) {
      // redraw if label in option list changed
      const term = getValue(this.props);
      if (term && this.state.term !== term) this.setState({ term });
    }
    if (!this.props.input.value && prevProps.input.value && !this.focused){
      this.setState({ term: '' });
    }
  }

  async componentDidMount() {
    if (this.props.input.value && this.state.term === '') {
      const term = getValue(this.props) || await fetchValue(this.props);
      this.setState({ term: term || '' });
    }
  }

  onHide = () => this.setState({ show: false });

  onShow = () => this.setState({ show: true });

  handleBlur = (e) => {
    // do nothing if option clicked
    if (e.relatedTarget && e.relatedTarget.className.startsWith('option')) return;

    const selected = this.props.optionList.get(this.state.hoveredId);
    if (typeof this.timeout !== 'undefined') {
      clearTimeout(this.timeout);
      this.timeout = undefined;
    }
    if (!selected) {
      this.setState({ term: '' });
    }
    this.timeout = setTimeout(this.onHide, 200);
    this.focused = false;
  }

  handleFocus = () => {
    if (!this.focused) {
      this.onShow();
    }
    this.focused = true;
    if (typeof this.timeout !== 'undefined') {
      clearTimeout(this.timeout);
      this.timeout = undefined;
    }
    this.timeout = null;
  }

  keyDown = (e) => {
    const { hoveredId, show } = this.state;
    this.previewLastAction = PREVIEW_LAST_ACTION_KEYDOWN;
    if (!show) return;
    switch (e.key) {
      case 'ArrowDown':
        this.setState({ hoveredId: getNewHoveredId(resortListWithCommon(this.sortedList), hoveredId, 1) });
        break;
      case 'ArrowUp':
        this.setState({ hoveredId: getNewHoveredId(resortListWithCommon(this.sortedList), hoveredId, -1) });
        break;
      case 'Enter':
        this.focusedElement.blur();
        if (hoveredId) {
          this.doSelect(hoveredId);
        }
        this.setState({ show: false });
        break;
      case 'Escape':
        this.cancelPreview();
        this.focusedElement.blur();
        this.setState({ show: false });
        break;
      default:
        break;
    }
  }

  searchHandler = term => this.setState({ term, show: true }, () => {
    const { onSearch, input: { onChange }, comboBox, callOnChangeWhileTyping } = this.props;
    onSearch(term);
    if (callOnChangeWhileTyping) {
      onChange(comboBox ? term : '');
    }
  });

  onOptionsMouseMove = () => {
    this.previewLastAction = PREVIEW_LAST_ACTION_MOUSEMOVE;
  }

  onItemHover = hoveredId => {
    const { valueNormalize, optionList } = this.props;
    this.setState({
      preview: valueNormalize(optionList.find(i => i.get('id') === hoveredId)),
      hoveredId,
    });
  }

  cancelPreview = () => {
    this.setState({ preview: '' });
  }

  get val() {
    const { preview, term } = this.state;
    if (preview && this.previewLastAction === PREVIEW_LAST_ACTION_MOUSEMOVE) {
      return preview;
    }
    return term;
  }

  doSelect = (id) => {
    const { input: { onChange }, comboBox } = this.props;
    if (!comboBox) {
      this.focused = false;
    }
    this.setState({
      hoveredId: 0,
      preview: '',
      show: false,
    }, () => onChange(id));
  }

  onClear = () => {
    this.setState({ term: '', preview: '' });
  }

  get sortedList() {
    const { optionList, valueNormalize, filterValues, ownSortingAndFiltration } = this.props;
    const { term } = this.state;
    return ownSortingAndFiltration ? sortResults(optionList, term, valueNormalize, filterValues) : optionList;
  }

  render() {
    const { classes, id, meta, showClear, modifier } = this.props;
    const { touched, error } = meta;
    const { term, show } = this.state;
    return (
      <div
        className={classes.Autocomplete}
        data-name={`autocomplete--${this.props.input.name || this.props.name}`}
      >
        <div className={classes.container}>
          <div
            className={cx(
              classes.selectWrapper,
              classes.autocompleteWrapper,
              { disabled: this.props.disabled },
            )}
          >
            <input
              id={id}
              role="combobox"
              aria-autocomplete="list"
              autoComplete="off"
              aria-expanded={show}
              aria-controls={id}
              className={cx(
                classes.field,
                classes.selectField,
                modifier,
                { error: touched && error },
              )}
              placeholder={this.props.placeholder}
              value={this.val}
              onFocus={this.handleFocus}
              onBlur={this.handleBlur}
              onKeyDown={this.keyDown}
              disabled={this.props.disabled}
              ref={(element) => { this.focusedElement = element; }}
              onChange={({ target }) => this.searchHandler(target.value)}
            />
            { showClear && term && <button className={classes.clear} onClick={this.onClear}>×</button> }
          </div>
          {
            this.props.children({
              list: this.sortedList,
              show: this.state.show,
              preview: this.onItemHover,
              cancelPreview: this.cancelPreview,
              doSelect: this.doSelect,
              index: this.state.hoveredId,
              onMouseMove: this.onOptionsMouseMove,
            })
          }
        </div>
      </div>
    );
  }
}

const AutocompleteThemed = injectSheet(sheet)(Autocomplete);

export {
  AutocompleteThemed as Autocomplete,
  Autocomplete as TestAutocomplete,
};
