import { put, call } from 'redux-saga/effects';
import { replace as routerReplace } from 'connected-react-router';
import { callApi } from 'domain/api';
import { onAuthSuccess } from 'domain/env/sagas';
import { addNotification } from 'domain/env/EnvActions';

export function emailVerificationHandler(apiGenerator, redirectUrl) {
  function* onEmailVerificationToken(token) {
    try {
      const resp = yield callApi(apiGenerator, { data: { token } });
      const authToken = yield call(onAuthSuccess, resp);
      if (!authToken) {
        throw new Error('onEmailVerificationToken no token headers');
      }
    } catch (err) {
      yield put(addNotification({ type: 'error', title: 'Token expired' }));
      yield put(routerReplace(redirectUrl));
      console.error(err);
    }
  }

  return function* (payload) {
    const { token } = payload.query;
    if (!token) {
      yield put(routerReplace(redirectUrl));
    }
    yield call(onEmailVerificationToken, token);
  };
}
