import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import CalendarSchema from 'react-calendar';
import { JssProvider } from 'react-jss';
import injectSheet from 'lib/sheet';
import sheet from './Calendar.jss';
import { getDropdownPosition } from 'lib/helpers';

/**
 * @param: rule
 * @param: sheet
**/
const jssGenerateId = (rule) => {
  return rule.key === 'react-calendar' ? rule.key : `react-calendar__${rule.key}`;
};
const COMPONENT_HEIGH = 300 /*widget height */ + 50 /* footer height (fixed) */;
const COMPONENT_WIDTH = 300 + 15 /* right padding */;

class CalendarWrapper extends React.Component {

  static propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.instanceOf(Date),
    onClick: PropTypes.func,
    classes: PropTypes.shape({
      CalendarWrapper: PropTypes.string,
    }).isRequired,
  };

  state = {
    positionClassName: null,
  };

  rootNode = null;

  setRootNode = (node) => {
    this.rootNode = node;
  }

  componentDidMount() {
    const { horizontal, vertical } = getDropdownPosition(this.rootNode, COMPONENT_HEIGH, COMPONENT_WIDTH);
    let newState = { horizontal };
    if (vertical === 'top') {
      newState = { ...newState, positionClassName: 'position-top' };
    }
    this.setState(newState);
  }

  render() {
    const { onChange, value, classes, ...props } = this.props;
    const { horizontal, positionClassName } = this.state;
    const style = { marginLeft: `${horizontal}px` };
    return (
      <div
        className={cx(classes.CalendarWrapper, positionClassName)}
        ref={this.setRootNode}
        style={style}
        onClick={this.props.onClick}
      >
        <CalendarSchema
          onChange={onChange}
          value={value}
          {...props}
        />
      </div>
    );
  }

}


const StyledComponent = injectSheet(sheet)(CalendarWrapper);


export default function CustomJSSPovider(props) {
  return (
    <JssProvider generateId={jssGenerateId}>
      <StyledComponent {...props} />
    </JssProvider>
  );
}
