import chevron from './icons/chevron.svg';
import { DARK_BLUE_COLOR, DEFAULT_FONTFAMILY } from 'theme/theme';
import { FOCUSED_STYLE, FOCUSED_FIELD_STYLE } from 'theme/focus';
import { LAYOUT } from 'theme/layout/LAYOUT';
import { PAGE_CONTAINER } from 'theme/layout/PAGE_CONTAINER';
import { PAGE_SECTION } from 'theme/layout/PAGE_SECTION';
import { PAGE_INNER_COL1 } from 'theme/layout/PAGE_INNER_COL1';
import { PAGE_INNER_COL2 } from 'theme/layout/PAGE_INNER_COL2';
import { LAYOUT_768_MAX_WIDTH } from 'theme/layout/responsiveList';

export default {
  '@global': {
    'html, body': {
      width: '100%',
      height: '100%',
      color: DARK_BLUE_COLOR,
      display: 'flex',
      margin: 0,
      padding: 0,
      fontFamily: DEFAULT_FONTFAMILY,
      // fontSize: 'inherit',
    },
    'body.modal-open.AFOpened': {
      [`@media screen and (max-width: ${LAYOUT_768_MAX_WIDTH}px)`]: { // 833
        overflow: 'hidden',
      },
    },
    'h2': {
      fontWeight: 600,
    },
    '#root': {
      width: '100%',
      minHeight: '100%',
      height: 'auto !important',
    },
    '.LAYOUT': LAYOUT,
    '.PAGE_CONTAINER': PAGE_CONTAINER,
    '.PAGE_SECTION': PAGE_SECTION,
    '.PAGE_INNER_COL1': PAGE_INNER_COL1,
    '.PAGE_INNER_COL2': PAGE_INNER_COL2,

    'select::-ms-expand': {
      display: 'none',
    },
    'input::-webkit-contacts-auto-fill-button, input::-webkit-credentials-auto-fill-button': {
      visibility: 'hidden',
      display: 'none !important',
      pointerEvents: 'none',
      position: 'absolute',
      right: 0,
    },
    'input:-webkit-autofill': {
      backgroundColor: 'transparent! important',
      transition: 'background-color 5000s ease-in-out 0s',
    },
    'input::-webkit-input-placeholder': {
      color: '#000000',
      opacity: 0.5,
    },
    'input::-ms-clear, input::-ms-reveal': {
      display: 'none !important',
    },
    'select': {
      '-webkit-appearance': 'none',
      '-moz-appearance': 'none',
      paddingLeft: 5,
      paddingRight: 20,
      background: {
        color: 'transparent',
        image: `url(${chevron})`,
        position: 'calc(100% - 2px) center',
        size: '18px 18px',
        repeat: 'no-repeat',
      },
      color: DARK_BLUE_COLOR,
    },
    '*': {
      padding: '0',
      margin: '0',
      boxSizing: 'border-box',
      fontWeight: 'inherit',
    },
    'ul, ol': {
      listStyle: 'none',
    },
    button: {
      border: '0 none',
      background: 'none',
      textAlign: 'center',
      padding: 0,
      color: DARK_BLUE_COLOR,
    },
    'a, button': {
      '&:focus': {
        // outline: `2px solid ${FOCUS_OUTLINE}`,
        // outline: 'none',
        // boxShadow: '0 0 3px 2px #4990e2',
        ...FOCUSED_STYLE,
      },
    },
    'input': {
      '&:focus': {
        ...FOCUSED_FIELD_STYLE,
      },
    },
  },
  wrapper: {
    display: 'flex',
    // fix block with for personalization page 320px responsive design
    position: 'relative',
    minHeight: '100%',
    overflow: 'hidden',
  },
  authContainer: {
    width: '100%',
    height: '100%',
    fontFamily: DEFAULT_FONTFAMILY,
    display: 'flex',
    alignItems: 'stretch',
  },
};
