import React from 'react';
import injectSheet from 'lib/sheet';
import styles from './dueSheet';
import PropTypes from 'prop-types';

class DueDiligenceRisk extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.shape({
      box: PropTypes.string.isRequired,
      message: PropTypes.string.isRequired,
    }).isRequired,
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.box}>
        <span>!</span>
        <div className={classes.message}>We recommend exercising additional due diligence if you consider purchasing this art</div>
      </div>
    );
  }
}

export default injectSheet(styles)(DueDiligenceRisk);
