import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'cpcs-reconnect';
import I from 'immutable';
import InfiniteScroll from 'react-infinite-scroller';

import { loadGalleriesBidirectionalAction } from 'domain/galleries/GalleryActions';
import { galleryPagination_sel, loading_sel } from 'domain/galleries/GalleryModel';
import SmartImage from 'components/image/SmartImage';

import injectSheet from 'lib/sheet';
import styles from './sheet.js';



const imgParams = { width: 66, height: 40, cover: true };


class SelectGallery extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    list: PropTypes.instanceOf(I.List).isRequired,
    onSelect: PropTypes.func,
    children: PropTypes.node,
    loadMore: PropTypes.func.isRequired,
    pagination: PropTypes.shape({
      pageRight: PropTypes.number,
      pages: PropTypes.number,
    }).isRequired,
    loading: PropTypes.bool,
  };

  get loader() {
    const { loading, classes } = this.props;
    if (!loading) return null;
    return <div key="loader" className={classes.loader} children="..." />;
  }

  render() {
    const { classes, list, onSelect, children, loadMore, pagination, loading } = this.props;
    return (
      <div className={classes.SelectGallery}>
        <InfiniteScroll
          className="InfiniteScroll"
          loadMore={() => loadMore()}
          hasMore={pagination.pageRight < (pagination.pages - 1) || (loading && pagination.pageRight === 0)}
          loader={this.loader}
          useWindow={false}
        >
          <React.Fragment>
            {children}
            {
              list.map(e => (
                <button
                  key={e.id}
                  type="button"
                  className={classes.item}
                  onClick={() => onSelect(e.id)}
                >
                  <div className={classes.thumb}>
                    <SmartImage
                      src={e.picture}
                      params={imgParams}
                      modifier="SelectGallery"
                    />
                  </div>
                  <div className={classes.info}>
                    <span
                      className={classes.title}
                    >
                      {e.title}
                      &nbsp;
                      <span
                        className={classes.artworksCount}
                        children={`(${e.artworksCount || 0} art objects)`}
                      />
                    </span>
                    
                  </div>
                </button>
              ))
            }
          </React.Fragment>
        </InfiniteScroll>
      </div>
    );
  }
}

export default compose(
  injectSheet(styles),
  connect({
    loadMore: loadGalleriesBidirectionalAction,
    pagination: galleryPagination_sel,
    loading: loading_sel,
  }),
)(SelectGallery);
