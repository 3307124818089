import React from 'react';
import PropTypes from 'prop-types';
import I from 'immutable';
import cx from 'classnames';
import injectSheet from 'lib/sheet';
import sheet from './sheet';

const defaultErrorMessages = I.Map();

function Message({ meta: { touched, error, warning }, errorMessages = defaultErrorMessages, classes, modifier }) {
  const messageCode = touched ? (error || warning) : undefined;
  const message = errorMessages.get(messageCode, messageCode);

  const className = {
    [classes.warning]: warning,
    [classes.error]: error,
  };
  return (message) ? (
    <div className={cx(classes.message, className, modifier)}>{message}</div>
  ) : null;
}

Message.propTypes = {
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.any,
    warning: PropTypes.string,
  }).isRequired,
  classes: PropTypes.shape({
    warning: PropTypes.string,
    error: PropTypes.string,
    message: PropTypes.string,
  }).isRequired,
  modifier: PropTypes.string,
  errorMessages: PropTypes.instanceOf(I.Map),
};

export default injectSheet(sheet)(Message);
