import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'cpcs-reconnect';
import { compose } from 'redux';
import I from 'immutable';
import { removeMeFromShareAction } from 'domain/shares/ShareActions';
import { confirmAction } from 'domain/ui/UIActions';
import injectSheet from 'lib/sheet';
import styles from './sheet.js';

const confirmQuestion = 'Are you sure?';

class Remove extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.shape({
      Remove: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    }).isRequired,
    className: PropTypes.string.isRequired,
    item: PropTypes.instanceOf(I.Record).isRequired,
    askBefore: PropTypes.func.isRequired,
  };

  onRemove = () => {
    const { askBefore, item } = this.props;
    askBefore(confirmQuestion, removeMeFromShareAction, { id: item.get('id') });
  }

  render() {
    const { classes, className } = this.props;
    return (
      <div
        className={`${classes.Remove} ${className}`}
        onClick={this.onRemove}
      >
        <div
          className={classes.text}
          children={'Remove'}
        />
      </div>
    );
  }
}

export default compose(
  connect({
    askBefore: confirmAction,
  }),
  injectSheet(styles),
)(Remove);
