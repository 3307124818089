// import imgLogin from './img-login.jpg';
import logo from './logo.svg';
import { DARK_BLUE_COLOR, DEFAULT_FONTFAMILY_3 } from 'theme/theme';

export default {
  '@global body, html': {
    overflow: 'hidden',
  },
  LoginPage: {
    width: '100%',
    height: '100%',
    background: '#f7f7f7',
    overflow: 'auto',
    display: 'flex',
    justifyContent: 'space-between',
  },
  content: {
    flex: '0 0 50%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '@media screen and (max-width: 1023px)': {
      flex: '1 1 0px',
    },
    '@media screen and (max-height: 760px)': {
      '&:after': {
        display: 'block',
        content: '""',
        flex: '0 0 50px',
        width: '100%',
      },
    },
    '&.extraFooterGap:after': {
      display: 'block',
      content: '""',
      flex: '0 0 110px',
      width: '100%',
    },
  },
  bg: {
    flex: '0 0 50%',
    // background: `url(${imgLogin}) right center no-repeat`,
    pointerEvents: 'none',
    '@media screen and (max-width: 1023px)': {
      display: 'none',
    },
    overflow: 'hidden',
  },
  pic: {
    height: '100vh',
  },
  logo: {
    background: `url(${logo}) no-repeat`,
    marginTop: 38,
    marginLeft: 34,
    flex: '0 0 50px',
    width: '100%',
    '@media screen and (max-width: 610px)': {
      marginLeft: 19,
      marginTop: 22,
    },
  },
  form: {
    width: 308,
    margin: 'auto',
    '@media screen and (max-width: 362px)': {
      width: 'calc(100% - 32px)',
    },
    '@media screen and (max-height: 760px)': {
      marginTop: 40,
    },
  },
  link: {
    fontSize: 24,
    color: DARK_BLUE_COLOR,
    opacity: 0.5,
    textDecoration: 'none',
    fontWeight: 500,
    margin: [0, 10],
    fontFamily: DEFAULT_FONTFAMILY_3,
    '&:first-child': {
      marginLeft: 0,
    },
  },
  active: {
    opacity: 1,
  },
  title: {
    marginBottom: 30,
  },
};
