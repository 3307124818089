import { DEFAULT_BUTTON_COLOR } from 'theme/theme';

const circleSize = 120;
const circleBackGround = '#d6dadc';
const boxShadow = '6px 6px 10px rgba(0,0,0,0.2)';
const insetSize = 90;
const insetColor = 'white';

export default {
  root: {
    width: circleSize,
    height: circleSize,
    backgroundColor: circleBackGround,
    borderRadius: '50%',
    position: 'relative',
    margin: [10, 10],
  },
  circle: {
    '& $mask, $fill, $shadow': {
      width: circleSize,
      height: circleSize,
      position: 'absolute',
      borderRadius: '50%',
    },
  },
  shadow: {
    boxShadow: `${boxShadow} inset`,
  },
  fill: {
    backgroundColor: DEFAULT_BUTTON_COLOR,
    clip: `rect(0px, ${circleSize / 2}px, ${circleSize}px, 0px)`,
  },
  mask: {
    clip: `rect(0px, ${circleSize}px, ${circleSize}px, ${circleSize / 2}px)`,
  },
  inset: {
    width: insetSize,
    height: insetSize,
    backgroundColor: insetColor,
    position: 'absolute',
    marginLeft: (circleSize - insetSize) / 2,
    marginTop: (circleSize - insetSize) / 2,
    borderRadius: '50%',
    boxShadow,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: DEFAULT_BUTTON_COLOR,
    fontWeight: 600,
  },
};
