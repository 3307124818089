import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'cpcs-reconnect';
import { compose } from 'redux';
import I from 'immutable';
import InfiniteScroll from 'react-infinite-scroller';
import {
  getSharedWithMe as getSharedWithMe_sel,
  getSharedWithMePagination_sel,
} from 'domain/shares/ShareModel';
import {
  getSharedWithMeAppendPageAction,
} from 'domain/shares/ShareActions';
import sharedItems from './__mocks__/sharedWithMeList';
import SharesColumns from 'pages/common/sharesTable/columns';
import DataTable from 'pages/common/sharesTable';
import NoSharesFound from 'pages/common/NoSharesFound';
import SharedByHeader from 'pages/common/shares/SharedByHeader';
import PageContainer from 'pages/common/pageContainer';
import filterList from 'pages/common/filters/config';
import Loader from 'components/loader/Loader';

const sortByList = filterList.sharedWithMe;

const columns = SharesColumns.select('thumb', 'title', 'email', 'comment', 'created', 'remove');

class SharedWithMePage extends React.PureComponent {
  static propTypes = {
    list: PropTypes.instanceOf(I.List),
    pagination: PropTypes.instanceOf(I.Record),
    loadMore: PropTypes.func.isRequired,
  };

  static defaultProps = {
    list: sharedItems,
  };

  get loader() {
    const { pagination } = this.props;
    if (!pagination) return null;
    const modifier = pagination.loading && pagination.total > 0 ? 'infiniteScroll_LoadMore' : 'infiniteScroll_FirstPage';
    return <Loader key="loader" loading modifier={modifier} />;
  }

  render() {
    const { list, pagination, loadMore } = this.props;
    if (!pagination) return null;
    return (
      <PageContainer modifier="SharedWithMePage">
        <SharedByHeader
          title="Shared with Me"
          amountLabelId="sharedWithMePage.header.amount"
          amount={pagination ? pagination.total : 0}
          sortByList={sortByList}
          modifier="sharedWithMePage"
        />
        <InfiniteScroll
          className="InfiniteScroll"
          loadMore={loadMore}
          hasMore={pagination.page < (pagination.pages - 1) || (pagination.loading && pagination.page === 0)}
          loader={this.loader}
        >
          {
            !!list.size &&
              <DataTable
                columns={columns}
                items={list}
                sharedWithMe
                modifier="IE"
              />
          }
          {
            !list.size && !pagination.loading &&
              <NoSharesFound />
          }
        </InfiniteScroll>
      </PageContainer>
    );
  }
}

export const SharedWithMePagePure = SharedWithMePage;

export default compose(
  connect({
    list: getSharedWithMe_sel,
    pagination: getSharedWithMePagination_sel,
    loadMore: getSharedWithMeAppendPageAction,
  }),
)(SharedWithMePage);
