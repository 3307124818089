import record, { integer, listOf, setOf, string } from 'cpcs-recordjs';
import { field/*, select, selector*/ } from 'cpcs-reconnect';
import { Artwork, Pagination } from 'model';
import * as A from 'pages/saleLotsListPage/SaleLotsListPageActions';

const AuctionInfo = record('AuctionInfo', {
  // 2480
  auctionId: integer,
  // "Sotheby's"
  seller: string,
  // "2020-06-16"
  saleAt: string,
  // "MIOL18"
  saleNumber: string,
});

/**
 * State
**/
export const State = record('SaleLots', {
  auction: AuctionInfo,
  list: listOf(Artwork),
  listPagination: Pagination.parse({}),
  total: integer(),
  selected: setOf(integer),
});

/**
 * selectors
**/
const base = field('saleLots');
export const lotsList_sel = base.then(State.$list);
export const auction_sel = base.then(State.$auction);
export const pagination_sel = base.then(State.$listPagination);

/**
 * reducer
**/
export const reducer = {
  saleLots(state = new State(), action) { //NOSONAR
    switch (action.type) {
      case A.fetchLotsAppendPageAction.success:
        return state.apply(
          State.$list.parsedBy('concat', action.payload.lots.content),
          State.$listPagination.parsed(action.payload.lots),
          State.$total.parsed(action.payload.lots.total),
        );
      case A.fetchLotsAction.success:
        return state.apply(
          State.$list.parsed(action.payload.lots.content),
          State.$listPagination.parsed(action.payload.lots),
          State.$total.parsed(action.payload.lots.total),
          State.$auction.parsed(action.payload.auction),
        );
      case A.fetchLotsAppendPageAction.request:
      case A.fetchLotsAction.request:
        return state.apply(
          State.$listPagination.update(v => v.set('loading', true)),
        );
      case A.fetchLotsAppendPageAction.failure:
      case A.fetchLotsAction.failure:
        return state.apply(
          State.$listPagination.update(v => v ? v.set('loading', false) : v),
        );
      default:
        return state;
    }
  },
};
