import React from 'react';
import PropTypes from 'prop-types';
import I from 'immutable';
import { connect } from 'cpcs-reconnect';
import { user_sel, logOut } from 'domain/env';
import { UserMenu } from './menu';
import USER from './__mocks__/userMock';
import { toggleMobileMenuAction } from 'domain/ui/UIActions';

import injectSheet from 'lib/sheet';
import sheet from './sheet';


class Profile extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.shape({
      profile: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      button: PropTypes.string.isRequired,
      ava: PropTypes.string.isRequired,
      mobileMenu: PropTypes.string.isRequired,
    }).isRequired,
    profile: PropTypes.instanceOf(I.Record).isRequired,
    logOut: PropTypes.func.isRequired,
    toggleMobileMenu: PropTypes.func.isRequired,
  };

  state = {
    menuStatus: false,
  }

  menuHandler = menuStatus => this.setState({ menuStatus });

  hideMenu = () => this.menuHandler(false);

  render() {
    const { classes, toggleMobileMenu, profile = USER } = this.props;
    const nameSurnameLetters = profile.get('firstname').charAt(0) + profile.get('lastname').charAt(0);
    return (
      <React.Fragment>
        <div className={classes.profile}>
          <button
            className={classes.button}
            onClick={() => this.menuHandler(!this.state.menuStatus)}
          >
            {
            profile.picture ?
              <img className={classes.ava} src={profile.picture} alt={nameSurnameLetters} />
              :
              <div className={classes.name} children={nameSurnameLetters}/>
          }
          </button>
          {
            this.state.menuStatus ? (
              <UserMenu
                initialState
                profile={profile}
                onHide={this.hideMenu}
                logOut={this.props.logOut}
              />
            ) : null
          }
        </div>
        <button className={classes.mobileMenu} onClick={() => toggleMobileMenu()} />
      </React.Fragment>
    );
  }
}

export default connect({
  profile: user_sel,
  logOut,
  toggleMobileMenu: toggleMobileMenuAction,
})(
  injectSheet(sheet)(Profile),
);
