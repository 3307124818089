import { socialIcon } from '../theme';
import bg from './images/instagram.svg';
import bgHover from './images/instagramHover.svg';

export default {
  InstagramLoginButton: {
    extend: socialIcon,
    // background: 'linear-gradient(to bottom, #517fa4, #306088)',
    border: '1px solid #000',
    position: 'relative',
    background: {
      image: `url("${bg}")`,
      size: '24px 24px',
      repeat: 'no-repeat',
      position: 'center',
    },
    '&:hover': {
      background: `#000 url("${bgHover}") center no-repeat`,
      backgroundSize: '24px 24px',
    },
  },
};
