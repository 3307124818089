/* eslint no-unused-vars: warn */
import React from 'react';
import PropTypes from 'prop-types';
import I from 'immutable';
import { compose } from 'redux';
import cx from 'classnames';
import ToggleItemHidden from 'pages/highcharts/components/ToggleItemHidden';

import {
  DEFAULT_FONTFAMILY,
  PAGE_HEIGHT,
  PAGE_WIDTH,
  REMOVED_SIDEBAR_WIDTH,
} from 'pages/highcharts/helpers/PDFConstants';
import { getKey, itemSVGProps, itemAvailableByLiquidity } from 'pages/highcharts/helpers/helpers';

import injectSheet from 'lib/sheet';

const sheet = {
  PDFRemovedItemsSidebar: {},
  title: {
    font: `700 18px/22px ${DEFAULT_FONTFAMILY}`,
  },
  description: {
    paddingTop: 24,
    font: `400 16px/20px ${DEFAULT_FONTFAMILY}`,
  },
  btnGeneratePDF: {
    font: `700 16px/28px ${DEFAULT_FONTFAMILY}`,
    backgroundColor: '#304C95',
    padding: [26, 55],
    borderRadius: 40,
    color: '#FFF',
    marginTop: 46,
    cursor: 'pointer',
    '&.generateDisabled': {
      backgroundColor: '#4f5a78',
      color: '#999',
    },
  },
  removedItemsSVG: {
    marginTop: 45,
  },
};

class PDFRemovedItemsSidebar extends React.Component {
  static propTypes = {
    classes: PropTypes.shape({
      PDFRemovedItemsSidebar: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.string,
      btnGeneratePDF: PropTypes.string,
      removedItemsSVG: PropTypes.string,
    }).isRequired,
    onGeneratePDF: PropTypes.func.isRequired,
    generateDisabled: PropTypes.bool,
    items: PropTypes.oneOfType([
      PropTypes.instanceOf(I.Collection),
      PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          parent: PropTypes.string,
          hidden: PropTypes.bool,
          removable: PropTypes.bool,
        }),
      ),
    ]),
    // map: { [componentKey]: amountOfParts }
    parts: PropTypes.shape({}),
    PDFState: PropTypes.shape({}),
    // map: { [componentKey]: { width, height, top, left } }
    positions: PropTypes.shape({}),
  };
  onGeneratePDF = () => {
    this.props.onGeneratePDF();
  }
  renderItemsPreviewParts = () => {
    const { classes, items } = this.props;
    const ret = items.map(this.renderItemPreview).filter(Boolean);
    const height = ret.reduce((h, v) => h + v.viewBox.height + 15, 0);
    const nodes = ret.map(v => v.node);
    return (
      <svg width={REMOVED_SIDEBAR_WIDTH} height={height} className={classes.removedItemsSVG}>
        {nodes}
      </svg>
    );
  }
  renderItemPreview = (item) => {
    const { parts, positions, PDFState } = this.props;
    const part = parts[item.name] ? 0 : undefined;
    if (!itemAvailableByLiquidity({ PDFState, item })) return null;
    if (!item.hidden || part > 0 || (item.parent && !item.removable)) return null;
    const itemKey = getKey({ name: item.name, part });
    let position = positions[itemKey];
    if (!position) {
      position = {
        width: 0,
        height: 0,
        top: 0,
        left: 0,
      };
    }
    const { removedTransform, viewBox } = itemSVGProps({ position, item });
    const node = (
      <g key={itemKey} data-item-name={itemKey} transform={`translate(0, ${position.top})`}>
        <rect
          x="0"
          y="0"
          width={viewBox.width}
          height={viewBox.height}
          rx="0"
          ry="0"
          fill="#FFF"
        />
        <g {...removedTransform}>
          <rect
            x="0"
            y="0"
            width={position.width || PAGE_WIDTH}
            height={position.height || PAGE_HEIGHT}
            rx="0"
            ry="0"
            fill={`url(#${itemKey})`}
          />
        </g>
        <ToggleItemHidden
          item={item}
          viewBox={viewBox}
        />
      </g>
    );
    return { node, viewBox };
  }
  render() {
    const { classes, generateDisabled } = this.props;
    return (
      <div className={classes.PDFRemovedItemsSidebar}>
        <h3 className={classes.title}>
          Add and remove information from your custom report
        </h3>
        <p className={classes.description}>
          Add pages using selection buttons on the left side of the screen. Add/remove graphs to your report using the selection butons on the right side of the page. Generate the report to preview before downloading to your device.
        </p>
        <button
          type="button"
          className={cx(classes.btnGeneratePDF, { generateDisabled })}
          children="Generate PDF"
          onClick={this.onGeneratePDF}
        />
        {this.renderItemsPreviewParts()}
      </div>
    );
  }
}

PDFRemovedItemsSidebar = compose(
  injectSheet(sheet),
)(PDFRemovedItemsSidebar);

export { PDFRemovedItemsSidebar };
