import React from 'react';
import PropTypes from 'prop-types';
import I from 'immutable';
import { setPropTypes, createdAtToString, imgUrl } from 'lib/helpers';
import { getColumnDef } from 'lib/dataTable/helpers';
import SharedWith from './SharedWith';
import SharedTitle from './SharedTitle';
import ShareComment from './ShareComment';
import Revoke from './Revoke';
import Remove from './Remove';

export const columns = {

  thumb: {
    fieldPath: ['picture'],
    className: 'tdThumb IE',
    getValue: setPropTypes({
      value: PropTypes.string.isRequired,
      classes: PropTypes.object.isRequired,
    })(
      ({ value, classes }) => {
        if (process.env.NODE_ENV !== 'test') {
          throw new Error('img should be shown with <SmartImage src="..." />. See sharesTable/columns.jsx');
        }
        return (
          <div
            className={classes.thumb}
            style={value ? { backgroundImage: `url("${imgUrl(value, { width: 40, cover: true })}")` } : {}}
          />
        );
      },
    ),
  },

  title: {
    className: 'tdTxt title IE',
    getValue: setPropTypes({
      rowData: PropTypes.instanceOf(I.Record).isRequired,
      sharedWithMe: PropTypes.bool,
    })(
      ({ rowData, sharedWithMe }) =>
        <SharedTitle item={rowData} sharedWithMe={sharedWithMe} />,
    ),
  },

  created: {
    className: 'tdDate IE',
    fieldPath: ['created'],
    getValue: ({ value }) => createdAtToString(value),
  },

  email: {
    className: 'tdTxt emails IE',
    fieldPath: ['email'],
    getValue: setPropTypes({
      value: PropTypes.string,
    })(
      ({ value }) => <span title={value}>{ value }</span>,
    ),
  },

  members: {
    className: 'tdEmails emails IE',
    fieldPath: ['emails'],
    getValue: setPropTypes({
      value: PropTypes.instanceOf(I.List).isRequired,
    })(
      ({ value, rowData }) => <SharedWith list={value} shareId={rowData.id} />,
    ),
  },

  revoke: {
    render: setPropTypes({
      className: PropTypes.string.isRequired,
      rowData: PropTypes.instanceOf(I.Record).isRequired,
    })(
      ({ className, rowData }) =>
        <Revoke
          className={`${className} tdRevoke IE`}
          item={rowData}
        />,
    ),
  },

  remove: {
    render: setPropTypes({
      className: PropTypes.string.isRequired,
      rowData: PropTypes.instanceOf(I.Record).isRequired,
    })(
      ({ className, rowData }) =>
        <Remove
          className={`${className} tdRevoke IE`}
          item={rowData}
        />,
    ),
  },

  comment: {
    fieldPath: ['message'],
    className: 'tdTxt comment IE',
    getValue: setPropTypes({
      value: PropTypes.string,
    })(
      ({ value }) => <ShareComment text={value} />,
    ),
  },

  select: (...cols) => I.fromJS(cols.map(col => getColumnDef(columns, col))),
};

export default columns;
