import loader from './images/loader.gif';
import emptyGalleryBg from 'pages/collectionListPage/artGallery/images/empty-gallery-bg.svg';
import emptyImage from './images/no-image.png';
import { responsiveListTheme_Thumb } from 'theme/layout/responsiveList';
import { SELECTED_GALLERY_BORDER } from 'theme/theme';

export default {
  SmartImage: {
    position: 'relative',
  },

  bg: {
    width: 200,
    height: 200,
    position: 'relative',
    background: {
      position: 'center',
      size: 'cover',
      repeat: 'no-repeat',
    },
    '&.inactive:before': {
      content: '""',
      display: 'block',
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(255, 255, 255, 0.5)',
      position: 'relative',
      zIndex: 1,
    },
    '&.sliderThumb': {
      margin: [0, 8],
      cursor: 'pointer',
      '&.selected': {
        border: '2px solid #000000',
      },
    },
    '&.AOBadge': {
      ...responsiveListTheme_Thumb,
      backgroundColor: '#C4C4C4',
      '&:hover': {
        '&:after': {
          backgroundColor: 'rgba(255, 255, 255, 0.4)',
        },
      },
    },
    '&.ArtGallery': {
      '&.active': {
        boxShadow: `0 0 0px 2px ${SELECTED_GALLERY_BORDER}`,
      },
    },
    '&.SelectGallery': {
      backgroundColor: '#c4c4c4',
    },
    '&.popupTitleIco': {
      marginRight: 20,
      flex: [0, 0, '65px'],
    },
  },
  loader: {
    position: 'absolute',
    left: 'calc(50% - 20px)',
    top: 'calc(50% - 20px)',
    width: 40,
    height: 40,
    background: {
      position: 'center',
      size: 'cover',
      repeat: 'no-repeat',
      image: `url(${loader})`,
    },
  },
  noImage: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    background: {
      position: 'center',
      size: 'contain',
      repeat: 'no-repeat',
      image: `url(${emptyImage})`,
    },
    '&.SelectGallery': {
        background: {
          repeat: 'no-repeat',
          size: '50px auto',
          color: '#FFF',
          position: 'center',
          image: `url("${emptyGalleryBg}")`,
        },
        boxShadow: '0 0 0 1px #B6B7BA',
      },
    '&.ArtGallery': {
      background: {
        image: `url("${emptyGalleryBg}")`,
        size: '112px 31px',
        color: 'transparent',
        repeat: 'no-repeat',
        position: 'center',
      },
      boxShadow: '0 0 0px 1px #B6B7BA',
    },
    '&.popupTitleIco': {
      backgroundPosition: '-6px, 4px',
      backgroundSize: '160px auto',
    },
  },
  image: {
    width: 'auto',
    maxWidth: '100%',
    '&.withForm': {
      maxHeight: 540,
      // show black bg for transparent image areas
      backgroundColor: '#000',
    },
  },
  imageBox: {
    textAlign: 'center',
    '&.ReUploadImagePopup': {
      // show black bg for transparent image areas
      backgroundColor: '#000',
      lineHeight: '0',
    },
  },
};
