import { fork, put, takeEvery, select, call } from 'redux-saga/effects';
import { addNotification } from 'domain/env';
import { getSharedByMeList_apiGen, getSharedByMeListAppendPage_apiGen, watchRemoveShare } from 'domain/shares/sagas';
import { sharedByMeSel, getSharedByMePagination_sel } from 'domain/shares/ShareModel';
import {
  removeEmailsFromShareAction,
  removeShareAction,
  getSharedByMeAppendPageAction,
} from 'domain/shares/ShareActions';
import { queryParams as queryParams_sel, currentPage as currentPageSelector } from 'domain/router/RouterModel';
import { updateQueryAction } from 'domain/router/RouterAction';
import { onRemoveEmailsFromShare } from 'pages/sharesPage/sagas';
import { DEFAULT_PAGE_SIZE } from 'domain/const';

// function* updateList(request) {
//   /* sort: asc|desc */
//   const queryParams = yield select(queryParams_sel);
//   const { page: pageStr } = request.query;
//   const page = (pageStr - 1) || 0;
//   yield fork(getSharedByMeList_apiGen.catchError, { ...queryParams, page });
// }

function* onSuccessRemove() {
  yield put(addNotification({ title: 'Share was removed' }));
  const list = yield select(sharedByMeSel);
  const page = yield select(currentPageSelector);
  yield put(addNotification({ title: 'Share was removed' }));
  if (list.size === 0 && page > 1) {
    yield put(updateQueryAction({ page: page - 1 }));
  }
}

function* fetchSharedByMePage(page = 0) {
  // const filter = yield select(Routing.filterParamsSelector);
  const queryParams = yield select(queryParams_sel);
  try {
    if (page > 0) {
      yield call(getSharedByMeListAppendPage_apiGen, { ...queryParams, page, size: DEFAULT_PAGE_SIZE });
    } else {
      yield call(getSharedByMeList_apiGen, { ...queryParams, page, size: DEFAULT_PAGE_SIZE });
    }
  } catch (err) {
    console.error(err);
  }
}

export function* onPagination() {
  const { page, loading } = yield select(getSharedByMePagination_sel);
  if (loading) return;
  yield call(fetchSharedByMePage, page + 1);
}

export default function* watchNavigation() {
  yield fork(fetchSharedByMePage);
  yield takeEvery(removeShareAction.success, onSuccessRemove);
  yield takeEvery(removeEmailsFromShareAction.type, onRemoveEmailsFromShare);
  yield takeEvery(getSharedByMeAppendPageAction.type, onPagination);
  yield fork(watchRemoveShare);
}
