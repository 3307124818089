import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'cpcs-reconnect';
import { compose } from 'redux';
import { Slider } from 'components/form/dropzone/slider';
import { artworkFormPictures_sel, privateArtworkPictures_sel } from 'pages/common/artworkForm/ArtworkFormModel';
import {
  addPictureAction,
  removeArtworkImageAction,
  setArtworkDefaultImageAction,
} from 'domain/ownArtwork/OwnArtworkActions';
import { addPopupAction } from 'domain/ui/UIActions';
import { CROP_IMAGE_POPUP } from 'domain/const';
import ImageUploadZone from 'pages/newArtPage/pictureForm/ImageUploadZone';
import CropImagePopup from 'pages/common/popup/CropImagePopup';
import ReUploadImagePopup from 'pages/common/popup/ReUploadImagePopup';

import injectSheet from 'lib/sheet';
import sheet from './sheet';

class Picture extends React.PureComponent {
  static propTypes = {
    privatePictures: PropTypes.arrayOf(PropTypes.string),
    picturesList: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      preview: PropTypes.string,
      x1: PropTypes.number,
      x2: PropTypes.number,
      y1: PropTypes.number,
      y2: PropTypes.number,
      croppedUrl: PropTypes.string,
      naturalWidth: PropTypes.number,
      naturalHeight: PropTypes.number,
      rotationTimes: PropTypes.number,
    })),
    removeImage: PropTypes.func.isRequired,
    showPopup: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    setDefaultImage: PropTypes.func,
    readOnly: PropTypes.bool,
    allowAdditionalImages: PropTypes.bool,
    allowChangeMain: PropTypes.bool,
    allowRemoveMain: PropTypes.bool,
    addPictureToForm: PropTypes.func.isRequired,
  };

  static defaultProps = {
    picturesList: [],
    privatePictures: [],
    allowAdditionalImages: true,
  };

  onShowCrop = (index, file) => {
    const { picturesList, showPopup } = this.props;
    file = picturesList[index] || file;
    if (!file) return;
    showPopup({
      name: CROP_IMAGE_POPUP,
      params: {
        index: index,
      },
    });
  }

  render() {
    const { classes, picturesList, privatePictures, addPictureToForm } = this.props;
    const { readOnly, allowAdditionalImages, allowRemoveMain, allowChangeMain } = this.props;

    return (
      <div className={classes.PictureForm}>
        {
          !!picturesList.length &&
            <Slider
              border
              withForm
              files={picturesList}
              onRemove={this.props.removeImage}
              onCrop={this.onShowCrop}
              onSetMain={this.props.setDefaultImage}
              allowChangeMain={allowChangeMain}
              readOnly={readOnly}
              privatePictures={privatePictures}
              allowRemoveMain={allowRemoveMain}
            />
        }
        {
          allowAdditionalImages &&
            <ImageUploadZone
              onFilesLoaded={addPictureToForm}
            />
        }
        <CropImagePopup />
        <ReUploadImagePopup />
      </div>
    );
  }
}

export default compose(
  connect({
    privatePictures: privateArtworkPictures_sel,
    picturesList: artworkFormPictures_sel,
    removeImage: removeArtworkImageAction,
    addPictureToForm: addPictureAction,
    setDefaultImage: setArtworkDefaultImageAction,
    showPopup: addPopupAction,
  }),
  injectSheet(sheet),
)(Picture);

