import qs from 'query-string';
const reg = /\/:[\d\w_]+\??/g;

const removeDots = s => s.substr(1, s.length);
const removeForvardSlash = s => s.substr(1, s.length);
const removeQuestionMark = s => s.search(/\?/) === -1 ? s : s.substr(0, s.length - 1);
const applyModifiers = (prev, f) => f(prev);

const getVal = (param, values) => {
  const paramName = [removeDots, removeQuestionMark]
    .reduce(applyModifiers, param);
  const val = values[paramName];
  return val ? encodeURIComponent(val) : val;
};

const isOptional = param => (param.substr(param.length - 1, 1) === '?');

export function RequiredParamMissingForRoute(message) {
  if (Error.captureStackTrace) {
    Error.captureStackTrace(this, this.constructor);
  } else {
    this.stack = (new Error()).stack;
  }
  this.message = message;
}
RequiredParamMissingForRoute.prototype = new Error();
RequiredParamMissingForRoute.prototype.name = RequiredParamMissingForRoute.name;

class Route {
  name = '';
  link = '';
  builder = null;
  exact = true;
  params = [];
  parts = [];

  constructor(name, link, { builder, exact = true } = {}) {
    this.name = name;
    this.builder = builder;
    this.link = link;
    this.exact = exact;
    const m = link.match(reg);
    if (m) {
      this.params = m.map(removeForvardSlash);
      this.parts = link.split(reg);
    } else {
      this.parts = [link];
    }
  }

  toString() {
    return this.link;
  }

  _doBuild(paramVals) {
    const { params, parts } = this;
    let search = paramVals.query ? '?' + qs.stringify(paramVals.query, { arrayFormat: 'bracket' }) : '';
    if (paramVals.hash) search = `${search}#${paramVals.hash}`;
    if (!params.length) return this.link + search;
    return (parts.reduce(
      ((prev, part, k) => {
        prev.push(part);
        if (params[k]) {
          const val = getVal(params[k], paramVals);
          if (typeof val === 'undefined' && !isOptional(params[k])) {
            throw new RequiredParamMissingForRoute(`param ${params[k]} is required for route "${this.name}" ${this.link}`);
          }
          if (typeof val !== 'undefined' || !isOptional(params[k])) {
            prev.push(`/${val}`);
          }
        }
        return prev;
      }),
      [],
    )
      .join('') + search);
  }

  build(paramVals = {}) {
    if (this.builder) {
      return this.builder(this, paramVals);
    } else {
      return this._doBuild(paramVals);
    }
  }
}

export default Route;
