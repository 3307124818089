import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { lnk } from 'lib/routes';
import translate from 'lib/translate';

import injectSheet from 'lib/sheet';
import { sheet } from './sheet';

function Dashboard({ classes, title/*, galleryId*/, size = 0, unit='gallery', onCreateGallery }) {
  // const query = galleryId && isFinite(galleryId) ? { galleryId } : {};
  return (
    <header className={classes.PageHeader}>
      <h2 className={classes.title}>
        {title}
        {' '}
        <span
          className={classes.totalQuantity}
          children={`(${translate('collectionListPage.header.entityAmount.' + unit, size)})`}
        />
      </h2>
      <div className={classes.actions}>
        <button
          className={cx(classes.button, 'ListPage--Dashboard galleryBtn')}
          children="add gallery"
          onClick={() => onCreateGallery()}
        />
        <Link
          to={lnk('addArtUnifiedPage'/*, { query }*/)}
          className={cx(classes.button, 'ListPage--Dashboard artBtn')}
        >Add Art</Link>
      </div>
    </header>
  );
}

Dashboard.propTypes = {
  size: PropTypes.number,
  unit: PropTypes.string,
  classes: PropTypes.shape({
    PageHeader: PropTypes.string.isRequired,
    button: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    totalQuantity: PropTypes.string.isRequired,
    actions: PropTypes.string.isRequired,
  }).isRequired,
  title: PropTypes.string,
  onCreateGallery: PropTypes.func.isRequired,
  // galleryId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default injectSheet(sheet)(Dashboard);
