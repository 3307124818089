import { HEADER_Z_INDEX } from 'theme/zIndex';

export default {
  Header: {
    width: '100%',
    height: 60,
    background: '#FFFFFF',
    boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.2)',
    padding: [0, 25, 0, 25],
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: HEADER_Z_INDEX,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '@media screen and (max-width: 375px)': {
      justifyContent: 'initial',
    },
  },
  headerRight: {
    display: 'flex',
    marginLeft: 15,
    '@media screen and (max-width: 370px)': {
      width: '100%',
    },
  },
  mobileDropdown: {
    width: '100%',
  },
};
