import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import injectSheet from 'lib/sheet';
import styles from './sheet.js';

class Hint extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.shape({
      Hint: PropTypes.string,
      popover: PropTypes.string,
    }).isRequired,
    children: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.node,
    ]).isRequired,
    text: PropTypes.node.isRequired,
    modifier: PropTypes.string,
  };

  render() {
    const { classes, children, text, modifier } = this.props;
    return (
      <div className={cx(classes.Hint, modifier)}>
        {children}
        <div
          className={cx(classes.popover, modifier)}
          children={text}
        />
      </div>
    );
  }
}
export default injectSheet(styles)(Hint);
