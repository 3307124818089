import { call, fork, put, select } from 'redux-saga/effects';
import * as Artwork from 'domain/ownArtwork/OwnArtworkModel';
import { lnk } from 'lib/routes';
import {
  setMaterials,
  fillArtworkForm,
  artworkFormInit,
} from 'pages/common/artworkForm/sagas/artworkForm';
import { addBreadCrumbs } from 'domain/env';
import {
  fetchOwnArtwork_apiGen,
} from 'domain/ownArtwork/sagas';
import { getGalleryById } from 'domain/galleries/GalleryModel';
import { ensureGallery_apiGen } from 'domain/galleries/sagas';
import * as RouterModel from 'domain/router/RouterModel';


function* breadcrumbsDataWatch() {
  const { galleryId } = yield select(RouterModel.query_sel);
  let gallery;
  if (galleryId) {
    gallery = (yield select(getGalleryById(galleryId))) || (yield call(ensureGallery_apiGen.catchError, { galleryId }));
    yield put(addBreadCrumbs([
      { entity: gallery.title, link: lnk('collectionListPage', { gId: galleryId } ) },
    ]));
  } else {
    yield put(addBreadCrumbs([
      { entity: 'All art', link: lnk('collectionListPage' ) },
    ]));
  }
}

export function* editArtPage(_, { params: { artworkId } }) {
  // own
  yield call(fetchOwnArtwork_apiGen.catchError, { artworkId });
  const list = yield select(Artwork.artworksById);
  const artwork = list.get(parseInt(artworkId, 10));
  yield fork(breadcrumbsDataWatch);
  // AO form
  yield fork(artworkFormInit);
  yield fork(setMaterials, artwork);
  yield fork(fillArtworkForm, { payload: artwork }, artworkId);
}

export default function* newArtPage() {
  // own
  yield fork(breadcrumbsDataWatch);
  // AO form
  yield fork(artworkFormInit);
  yield fork(setMaterials);
  yield fork(fillArtworkForm);
  // more
}
