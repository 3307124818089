import { TEXT_LINK_COLOR, DEFAULT_FONTFAMILY, BUY_RTV_FORM_SUBMIT_BUTTON } from 'theme/theme';
import { LAYOUT_RANGE_425_MAX } from 'theme/layout/LAYOUT';
import * as form from 'theme/form';

export default {
  CouponForm: {
    paddingTop: 30,
  },
  openBtn: {
    color: TEXT_LINK_COLOR,
    textDecoration: 'underline',
    font: `400 14px/18px ${DEFAULT_FONTFAMILY}`,
    cursor: 'pointer',
  },
  submitBtn: {
    display: 'inline-block',
    marginTop: 50,
    padding: [7, 15],
    height: 28,
    width: 110,
    letterSpacing: 1,
    color: '#2e343e',
    fontFamily: DEFAULT_FONTFAMILY,
    fontSize: '12px',
    fontWeight: 400,
    textTransform: 'uppercase',
    border: '1px solid black',
    marginLeft: 10,
    cursor: 'pointer',
    '&:disabled': {
      opacity: 0.5,
      cursor: 'default',
    },
    [`@media screen and (max-width: ${LAYOUT_RANGE_425_MAX}px)`]: {
      marginTop: '52px',
    },
    '&.upgrade-subscription': {
      marginTop: 12,
      float: 'right',
    },
  },
  closeBtn: {
    extend: 'submitBtn',
  },
  buyButton: {
    backgroundColor: BUY_RTV_FORM_SUBMIT_BUTTON,
    width: '100%',
    padding: [20],
    color: '#FFF',
    textTransform: 'uppercase',
    marginTop: 40,
    fontSize: '18px',
    fontFamily: DEFAULT_FONTFAMILY,
    lineHeight: '24px',
    '&:disabled': {
      opacity: 0.5,
    },
    '&:hover': {
      cursor: 'pointer',
      opacity: 0.9,
    },
  },
  fieldWrapper: form.fieldWrapper,
  field: form.field,
  error: form.error,
  formItem: form.formItem,
  label: form.label,
  couponRow: {
    display: 'flex',
    alignItems: 'flex-start',
    '&.upgrade-subscription': {
      display: 'block',
    },
    '&:after': {
      display: 'block',
      clear: 'both',
      content: '""',
    },
  },
};
