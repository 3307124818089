import { DEFAULT_FONTFAMILY_3 } from 'theme/theme';

export default {
  Chart: {
    border: '1px solid #E5E5E5',
    borderRadius: 4,
    padding: [23, 0, 23, 18],
    marginTop: 64,
    '&.insidePreloadingTabs': {
      marginTop: 20,
    },
    '@media screen and (max-width: 1760px)': {
      padding: [20, 0, 23, 13],
    },
  },
  content: {
    display: 'grid',
    gridTemplateColumns: 'calc(100% - 470px) 400px',
    gridTemplateAreas: '"chart definitions"',
    columnGap: '70px',
    alignItems: 'start',
    '@media screen and (max-width: 1760px)': {
      gridTemplateColumns: 'calc(100% - 324px - 50px) 324px',
      columnGap: '50px',
    },
    '@media screen and (max-width: 1360px)': {
      gridTemplateColumns: 'calc(100% - 315px - 70px) 315px',
      columnGap: '70px',
    },
    '@media screen and (max-width: 1200px)': {
      gridTemplateColumns: '100%',
      gridTemplateAreas: '"chart" "definitions"',
    },
  },
  // title: {},
  // subtitle: {},
  chart: {
    gridArea: 'chart',
    position: 'relative',
  },
  unavailable: {
    backgroundColor: '#F0F2F6',
    padding: [115, 0],
  },
  definitions: {
    gridArea: 'definitions',
  },
  unavailableTitle: {
    font: `400 24px/30px ${DEFAULT_FONTFAMILY_3}`,
    textAlign: 'center',
  },
  unavailableDescription: {
    textAlign: 'center',
    margin: [15, 'auto', 0],
    maxWidth: 335,
    font: `400 14px/22px ${DEFAULT_FONTFAMILY_3}`,
  },
};
