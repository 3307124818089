import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import cx from 'classnames';

import { DEFAULT_FONTFAMILY_3 } from 'theme/theme';
import { formatPrice } from 'lib/units';
import { imgUrl } from 'lib/helpers';

import closeSVG from 'components/reports/images/close.svg';
import injectSheet from 'lib/sheet';

const WIDTH = 251;
const HEIGHT = 220;
const MARGIN = 10;

const sheet = {
  TooltipLot: {
    position: 'absolute',
    // opacity: 0.9,
    height: HEIGHT,
    width: WIDTH,
    border: '1px solid rgb(148, 162, 200)',
    padding: [16, 55, 16, 16],
    borderRadius: '3px',
    top: 120,
    // background: 'rgba(247, 247, 247, 0.85)',
    backgroundColor: '#FFF',
    boxShadow: '0px 10px 30px 3px #304C9578',
    display: 'flex',
    pointerEvents: 'none',
    '&.p012': {
      height: HEIGHT - 30,
    },
    '& .img-box': {
      height: 180,
      flex: '0 0 56px',
      overflow: 'hidden',
      margin: [7, 15, 0, 7],
    },
    '& .img-box .img': {
      maxWidth: '100%',
    },
    '&.loading .img-box .img': {
      height: 80,
      backgroundColor: '#CCC',
    },
    '& .props-box': {
      marginTop: 7,
      font: `400 12px/16px ${DEFAULT_FONTFAMILY_3}`,
      flex: '0 0 111px',
      minWidth: 0,
    },
    '& .props-box .link': {
      font: `500 12px/16px ${DEFAULT_FONTFAMILY_3}`,
      color: '#1E202C',
      display: 'block',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      minWidth: 0,
      whiteSpace: 'nowrap',
      cursor: 'pointer',
      pointerEvents: 'all',
    },
    '& .param': {
      marginTop: 8,
      height: 14,
      '& b': {
        font: `700 12px/16px ${DEFAULT_FONTFAMILY_3}`,
      },
    },
    '&.loading .param': {
      backgroundColor: '#CCC',
      '&.error': {
        color: 'red',
      },
    },
    '& .param.link': {
      marginTop: 0,
      backgroundColor: '#AAA',
    },
    '& .param.medium': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      minWidth: 0,
    },
    '& .param.size': {
      marginTop: 0,
    },
    '& .param.holdingPeriod': {
      whiteSpace: 'nowrap',
    },
    '& .param.price': {
      font: `700 12px/16px ${DEFAULT_FONTFAMILY_3}`,
      whiteSpace: 'nowrap',
      '&.p012': {
        font: `700 18px/24px ${DEFAULT_FONTFAMILY_3}`,
      },
    },
    '& .param.soldDate.p012': {
      font: `700 12px/16px ${DEFAULT_FONTFAMILY_3}`,
    },
    '&.loading .param.price': {
      backgroundColor: '#AAA',
    },
  },
  close: {
    width: 24,
    height: 24,
    background: `url("${closeSVG}") scroll no-repeat center`,
    cursor: 'pointer',
    position: 'absolute',
    right: 10,
    top: 15,
    pointerEvents: 'all',
    '&:hover': {
      opacity: 0.9,
    },
  },
};

// eslint-disable-next-line no-unused-vars
function TooltipLot({ classes, tooltip: { lotId, lot, y: value, x: holdingPeriod, coordinates }, onClose, chartId }) {
  const { x, y, width, height, plotLeft, plotTop } = coordinates;
  let left = Math.max(0, (x + WIDTH + MARGIN) < width ? x + MARGIN : x - WIDTH - MARGIN) + plotLeft;
  const top = Math.max(0, (y + HEIGHT / 2 + plotTop) < height ? y - HEIGHT / 2 : y - HEIGHT) + plotTop;
  let right = 'auto';
  if (left + WIDTH > width) {
    left = 'auto';
    right = 0;
  }
  const style = {
    left,
    right,
    top,
  };
  const loading = !lot || !lot.id;
  if (loading) {
    return (
      <div className={cx(classes.TooltipLot, 'loading', chartId)} style={style}>
        <button type="button" onClick={() => onClose()} className={classes.close} />
        <div className="img-box"><div className="img"/></div>
        <div className="props-box">
          <div className={cx('param link', { error: !lotId })}>{!lotId && 'No lotId'}</div>
          <div className="param"></div>
          <div className="param medium"></div>
          <div className="param size"></div>
          <div className="param"></div>
          <div className="param"></div>
          <div className="param price"></div>
          {
            chartId === 'p002' &&
              <React.Fragment>
                <div className="param"></div>
                <div className="param"></div>
              </React.Fragment>
          }
        </div>
      </div>
    );
  }
  const medium = (lot.surfaces || []).concat(lot.substrates || [])
    .map(({ title }) => title)
    .join(', ');
  let price = null;
  if (lot.priceSold) {
    price = formatPrice(lot.priceSold, lot.priceSold.unit);
  }
  return (
    <div className={cx(classes.TooltipLot, chartId)} style={style}>
      <button type="button" onClick={() => onClose()} className={classes.close} />
      <div className="img-box">
        <img className="img" src={imgUrl(lot.picture, true)} alt={lot.title} crossOrigin="anonymous" />
      </div>
      <div className="props-box">
        <a className="link" href={`/ao/${lot.id}`} target="_blank" rel="noreferrer">{lot.title}</a>
        <div className="param">{(lot.createdAt || {}).year || 'year: N/A'}</div>
        <div className="param medium">{(lot.category || {}).title}, {medium}</div>
        <div className="param size">{lot.width} x {lot.height} {lot.unit}</div>
        <div className="param">{lot.auction.title}</div>
        <div className={cx('param soldDate', chartId)}>{moment(lot.soldDate).format('LL')}</div>
        <div className={cx('param price', chartId)}>$ {price}</div>
        {
          chartId === 'p002' &&
            <React.Fragment>
              <div className="param holdingPeriod">
                Holding Period (Years):{' '}
                <b>{(holdingPeriod || 0).toFixed(1).replace(/\.0$/, '')}</b>
              </div>
              <div className="param">CAGR: <b>{(value || 0).toFixed(2)}%</b></div>
            </React.Fragment>
        }
      </div>
    </div>
  );
}

TooltipLot.propTypes = {
  classes: PropTypes.object,
  tooltip: PropTypes.shape({
    lotId: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    coordinates: PropTypes.shape({
      x: PropTypes.number,
      y: PropTypes.number,
      width: PropTypes.number,
      height: PropTypes.number,
      plotLeft: PropTypes.number,
      plotTop: PropTypes.number,
    }),
    lot: PropTypes.shape({
      surfaces: PropTypes.arrayOf(PropTypes.shape({ title: PropTypes.string })),
      substrates: PropTypes.arrayOf(PropTypes.shape({ title: PropTypes.string })),
      priceSold: PropTypes.shape({ value: PropTypes.number, unit: PropTypes.string }),
      picture: PropTypes.string,
      id: PropTypes.number,
      title: PropTypes.string,
      createdAt: PropTypes.shape({ year: PropTypes.number }),
      category: PropTypes.shape({ title: PropTypes.string }),
      width: PropTypes.number,
      height: PropTypes.number,
      unit: PropTypes.string,
      auction: PropTypes.shape({ title: PropTypes.string }),
      soldDate: PropTypes.string,
    }),
    align: PropTypes.oneOf(['left', 'right']),
    x: PropTypes.number,
    y: PropTypes.number,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  chartId: PropTypes.string.isRequired,
};

TooltipLot = injectSheet(sheet)(TooltipLot);

export { TooltipLot };
