export const billingPageLayout = {
  header: {
  },
  content: {
    display: 'flex',
    '@media screen and (min-width: 1023px and max-width: 1205px)': {
      width: 804,
      margin: 'auto',
    },
    '@media screen and (max-width: 1023px)': {
      '&.AccountPage': {
        display: 'block',
      },
    },
  },
  column1: {
    order: 1,
    flex: '0 0 330px',
    '@media screen and (max-width: 1023px)': {
      '&.AccountPage': {
        width: 330,
      },
    },
    '@media screen and (max-width: 375px)': {
      '&.AccountPage': {
        width: '100%',
      },
    },
  },
  column2: {
    order: 2,
    marginLeft: 225,
    '&.AccountPage': {
      width: 200,
    },
    '@media screen and (min-width: 1206px)': {
      '&.AccountPage': {
        '&.ChoosePlanForm': {
          width: 610,
        },
      },
    },
    '@media screen and (max-width: 1205px)': {
      '&.AccountPage': {
        marginLeft: 102,
        '&.ChoosePlanForm': {
          width: 610,
        },
      },
    },
    '@media screen and (max-width: 1023px)': {
      '&.AccountPage': {
        marginLeft: 0,
      },
    },
    '@media screen and (max-width: 768px)': {
      '&.AccountPage.ChoosePlanForm': {
        width: 330,
      },
    },
    '@media screen and (max-width: 375px)': {
      '&.AccountPage.Avatar': {
        width: '100%',
      },
    },
    '@media screen and (max-width: 361px)': {
      '&.AccountPage.ChoosePlanForm': {
        width: '100%',
      },
    },
  },
};
