import React from 'react';
import PropTypes from 'prop-types';
import { Field, hasSubmitFailed, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'redux';
import cx from 'classnames';
import DropZone, { cutName } from 'components/form/dropzone';
import { artworkFormPictures_sel } from 'pages/common/artworkForm/ArtworkFormModel';
import {
  uploadPictureAction,
} from 'domain/ownArtwork/OwnArtworkActions';
import { dispatchPromise } from 'lib/helpers';
import Progress from 'components/progressbar';
import { FORM_ADD_ART_ITEM, FORM_ADD_ART_ITEM_PICTURES } from 'domain/const';

import injectSheet from 'lib/sheet';
import sheet from './sheet';

class ImageUploadZone extends React.PureComponent {
  static propTypes = {
    picturesList: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
    })),
    onFilesLoaded: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    uploadPicture: PropTypes.func.isRequired, // eslint-disable-line react/no-unused-prop-types
    showError: PropTypes.bool,
    multiple: PropTypes.bool,
  };

  static defaultProps = {
    picturesList: [],
    multiple: true,
  };

  filesLoaded = [];

  state = {
    rejected: [],
    loadedPercent: null,
    loadedBefore: {},
  };

  unmounted = false;

  componentWillUnmount() {
    this.unmounted = true;
  }

  trySetState = (...args) => {
    if (this.unmounted) return;
    this.setState(...args);
  }

  async loadFiles(acceptedFiles) {
    const { uploadPicture, onFilesLoaded } = this.props;
    for (let file of acceptedFiles) {
      try {
        const formdata = new FormData();
        formdata.append('file', file);
        const data = await dispatchPromise(
          uploadPicture,
          { data: formdata, onUploadProgress: this.getUploadProgressHandler(acceptedFiles, file) },
        );
        if (!data || !data.length) throw new Error('file rejected');
        // crop popup            | fileName (removed) || name
        // Slider                | fileName (removed) || name | preview (selected)
        // POST                  |                    |  name
        // dropzone              |                    |       | preview
        // AO form               |                    |  name | preview
        const loadedFile = { name: data[0], preview: file.preview, justLoaded: true };
        this.filesLoaded = [...this.filesLoaded, loadedFile];
      } catch (e) {
        this.trySetState({
          rejected: [ ...this.state.rejected, { name: cutName(file.name), err: 'uploading error' } ],
        });
      }
    }

    onFilesLoaded(this.filesLoaded);
    this.filesLoaded = [];
    this.trySetState({ loadedPercent: null, loadedBefore: {} });
  }

  getUploadProgressHandler = (acceptedFiles, file) => ({ loaded }) => {
    const total = acceptedFiles.reduce((p, f) => p + f.size, 0);
    let { loadedBefore } = this.state;
    loadedBefore = { ...loadedBefore, [file.name]: loaded };
    const loadedBites = Object.keys(loadedBefore).reduce((prev, key) => prev + loadedBefore[key], 0);
    this.trySetState({
      loadedBefore,
      loadedPercent: Math.floor(loadedBites * 100 / total),
    });
  }

  uploadHandler = (_, newValue) => {
    const { acceptedFiles = [], rejectedFiles = [] } = newValue;
    this.trySetState({ rejected: rejectedFiles });
    if (acceptedFiles.length){
      this.loadFiles(acceptedFiles);
    }
  }

  render() {
    const { classes, showError, picturesList, multiple } = this.props;
    const { loadedPercent, rejected } = this.state;
    const zoneProps = {
      disabled: loadedPercent !== null,
      multiple,
      accept: 'image/jpeg, image/png',
    };
    const filesIsLoad = { filesIsLoad: picturesList.length };
    const hasImage = {
      hasImage: multiple && picturesList.length > 0,
      singleImageMode: !multiple,
    };
    return (
      <div className={classes.ImageUploadZone}>
        {
          loadedPercent === null &&
            <form className={cx(classes.form, hasImage)}>
              <Field
                name="file"
                component={DropZone}
                onChange={this.uploadHandler}
                props={zoneProps}
                >
                <div className={cx(classes.content, filesIsLoad)}>
                  <h2 className={cx(classes.title, filesIsLoad)}>Drag Image files or <mark>browse</mark> to upload</h2>
                  <h3 className={cx(classes.title, classes.subtitle)}>
                    Please, upload images not more then <mark>15 Mb</mark>
                  </h3>
                </div>
              </Field>
            </form>
        }
        {
          loadedPercent !== null &&
            <div className={cx(classes.zone, hasImage)}>
              <Progress value={loadedPercent} />
            </div>
        }
        {
          showError && picturesList.length === 0 &&
            <div
              className={cx(classes.PictureFormError)}
              children="Required"
            />
        }
        {
          !!rejected.length && rejected.map(({ name, err }, index) => (
            <div className={cx(classes.PictureFormError, { first: index === 0 })} key={`${name}-${index}`}>
              <span className={classes.fileName}>{name}</span> - {err}
            </div>
          ))
        }
      </div>
    );
  }
}

export const ImageUploadZonePure = ImageUploadZone;

export default compose(
  connect(
    state => ({
      showError: hasSubmitFailed(FORM_ADD_ART_ITEM)(state),
      picturesList: artworkFormPictures_sel(state),
    }),
    {
      uploadPicture: uploadPictureAction,
    },
  ),
  reduxForm({ form: FORM_ADD_ART_ITEM_PICTURES }),
  injectSheet(sheet),
)(ImageUploadZone);
