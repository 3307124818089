import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import I from 'immutable';
import translate from 'lib/translate.js';
import { lnk } from 'lib/routes';
import injectSheet from 'lib/sheet';
import styles from './sheet.js';

class SharedTitle extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    item: PropTypes.instanceOf(I.Record).isRequired,
    sharedWithMe: PropTypes.bool,
  };

  get link() {
    const { item, sharedWithMe } = this.props;
    const { id: shareId } = item;
    return lnk('sharedItemsPage', { shareId, type: item.type, sharedWithMe });
  }

  render() {
    const { classes, item } = this.props;
    const type = item.get('type');
    const amount = item.get('amount');
    const title = item.get('title');
    const sharedTitle = translate('sharingTable.' + type, amount);
    return (
      <Link
        to={this.link}
        className={classes.link}
      >
        <span className={classes.SharedTitle}>
          {sharedTitle} {' '}
          {
            title &&
              <span className={classes.artName}>
                “{title}”
              </span>
          }
        </span>
        {
          (item.get('updatedArts').size > 0) &&
            <i
              className={classes.exclamationMarkIco}
            />
        }
      </Link>
    );
  }
}

SharedTitle = injectSheet(styles)(SharedTitle);
export default SharedTitle;
