import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { debounce } from 'lib/envGlobals';

import Lightbox from 'lightbox-react';
import 'lightbox-react/style.css';

import { imgUrl } from 'lib/helpers';

import SmartImage from 'components/image/SmartImage';
import ThumbMenu from 'components/form/dropzone/slider/thumbMenu';

import injectSheet from 'lib/sheet';
import sheet from './sheet';

const SLIDER_IMAGE_WIDTH = 80;

const thumbParams = { width: 64, height: 64, cover: true };

class ImageSlider extends React.Component {
  static propTypes = {
    classes: PropTypes.shape({
      ImageSlider: PropTypes.string.isRequired,
      next: PropTypes.string.isRequired,
      previous: PropTypes.string.isRequired,
      previewMain: PropTypes.string.isRequired,
      btnContainer: PropTypes.string.isRequired,
      setMain: PropTypes.string.isRequired,
      remove: PropTypes.string.isRequired,
      crop: PropTypes.string.isRequired,
      slider: PropTypes.string.isRequired,
      images: PropTypes.string.isRequired,
      controls: PropTypes.string.isRequired,
      disabled: PropTypes.string.isRequired,
    }).isRequired,
    privatePictures: PropTypes.arrayOf(PropTypes.string),
    files: PropTypes.array.isRequired,
    onRemove: PropTypes.func,
    onCrop: PropTypes.func,
    onSetMain: PropTypes.func,
    readOnly: PropTypes.bool,
    border: PropTypes.bool,
    withForm: PropTypes.bool,
    allowRemoveMain: PropTypes.bool,
    allowChangeMain: PropTypes.bool,
  }

  scrollableNode = null;
  rootNode = null;
  unmounted = false;

  state = {
    selected: 0,
    photoIndex: -1,
    leftScrollEnabled: false,
    rightScrollEnabled: true,
  }

  static getDerivedStateFromProps(props, state) {
    if (!props.files[state.selected]) {
      return { selected: 0 };
    }
    return null;
  }

  componentWillUnmount() {
    this.unmounted = true;
    const { files } = this.props;
    (files || []).forEach((file) => {
      if (file && file.croppedUrl) {
        try {
          window.URL.revokeObjectURL(file.croppedUrl);
        } catch (err) {
          console.error(err);
        }
      }
    });
  }

  componentDidMount() {
    this.updateScrollButtons();
  }

  componentDidUpdate() {
    this.updateScrollButtons();
  }

  safeSetState = (...args) => {
    if (this.unmounted) return;
    this.setState(...args);
  }

  updateScrollButtons = debounce(() => {
    if (!this.scrollableNode) return;
    const { scrollableNode } = this;
    const { scrollWidth, scrollLeft } = scrollableNode;
    const scrollRight = Math.max(0, scrollWidth - scrollLeft - scrollableNode.getBoundingClientRect().width);
    const leftScrollEnabled = scrollLeft > 0;
    const rightScrollEnabled = scrollRight > 0;
    if (this.state.leftScrollEnabled === leftScrollEnabled && this.state.rightScrollEnabled === rightScrollEnabled) {
      return;
    }
    this.safeSetState({ leftScrollEnabled, rightScrollEnabled });
  }, 300);

  onSetMain = (index) => {
    const { onSetMain } = this.props;
    this.setState({ selected: 0 });
    onSetMain(index);
  }

  isPrivate = index => {
    const { files, privatePictures = [] } = this.props;
    const { name } = files[index] || {};
    return privatePictures.includes(name);
  }

  removeAllowed(index) {
    const { allowRemoveMain } = this.props;
    return ((index > 0 && !this.isPrivate(index)) || allowRemoveMain);
  }

  setScrollableRef = node => {
    this.scrollableNode = node;
  }

  setRootNodeRef = node => {
    const forceUpdate = !this.rootNode || this.rootNode !== node;
    this.rootNode = node;
    if (forceUpdate) this.forceUpdate();
  }

  scroll = (direction = 1) => {
    const { scrollableNode } = this;
    if (!scrollableNode) {
      return;
    }
    const rootWidth = scrollableNode.getBoundingClientRect().width;
    const imagesPerRootWidth = Math.floor(rootWidth / SLIDER_IMAGE_WIDTH);
    const distance = imagesPerRootWidth * SLIDER_IMAGE_WIDTH * direction;
    /**
     * Math.ceil
     * we allow to scroll to end of slider (scrollLeft >= scrollWidth)
     * in this case last image aligned to right side and left image can be cutted
     * Math.ceil helps to show cutted image in last position on left direction scrolling
    **/
    const prevScroll = Math.ceil(scrollableNode.scrollLeft / SLIDER_IMAGE_WIDTH) * SLIDER_IMAGE_WIDTH;
    /**
     * Math.floor aligns left image to left side of slider
     *
     * except scrolling to end of slider
     * in this case left image can be cutted and right will be aligned to right side
     *
     * we don't check if (scrollLeft >= scrollWidth) this allows to scroll to end of slider, and align last image
    **/
    const scrollX = Math.floor((prevScroll + distance) / SLIDER_IMAGE_WIDTH) * SLIDER_IMAGE_WIDTH;
    scrollableNode.scrollTo(scrollX, 0);
    this.updateScrollButtons();
  }

  scrollToRight = () => {
    this.scroll();
  }

  scrollToLeft = () => {
    this.scroll(-1);
  }

  render() {
    const { classes, files, onRemove, onCrop, border = false, withForm = false } = this.props;
    const { readOnly = false, allowChangeMain } = this.props;
    const { selected, leftScrollEnabled, rightScrollEnabled } = this.state;
    const { croppedUrl, name, preview, originalLink, justLoaded } = files[selected] || {};

    const { photoIndex } = this.state;

    const allowChangeDefault = selected > 0 && allowChangeMain;
    const allowRemoveImage = this.removeAllowed(selected);

    return (
      <div className={classes.ImageSlider} ref={this.setRootNodeRef}>
        <div className={cx(classes.previewMain, { withBorder: border })}>
          <SmartImage
            withForm={withForm}
            key={`img-${name}-${selected}`}
            src={croppedUrl || preview || name}
            originalLink={!!originalLink}
            thumbSize="L"
            container="img"
            onClick={() => files.length !== 0 && this.setState({ photoIndex: selected })}
            PDFComponentName="AOImage"
          />
          {
            !readOnly &&
              <div className={classes.btnContainer}>
                {
                  allowChangeDefault &&
                    <button
                      type="button"
                      onClick={() => this.onSetMain(selected)}
                      className={classes.setMain}
                      title="Use as main image"
                    />
                }
                {
                  allowRemoveImage &&
                    <button
                      type="button"
                      onClick={() => onRemove(selected)}
                      className={classes.remove}
                      title="Remove"
                    />
                }
                {
                  (justLoaded && ((selected > 0 && !this.isPrivate(selected)) || allowChangeMain)) &&
                    <button
                      type="button"
                      onClick={() => onCrop(selected)}
                      className={classes.crop}
                      title="crop"
                    />
                }
              </div>
          }
        </div>
        {
          /*
            (photoIndex + files.length - 1) % files.length].name)
            returns prev image index if exists or last image index
            for example:
            +--------+-------+---------------------------------+------------------------------------------------+
            | length | index | (photoIndex + files.length - 1) | (photoIndex + files.length - 1) % files.length |
            +--------+-------+---------------------------------+------------------------------------------------+
            |  1     |  0    |   0                             | 0                                              |
            |  2     |  0    |   1                             | 1                                              |
            |  2     |  1    |   2                             | 0                                              |
            |  3     |  0    |   2                             | 2                                              |
            |  3     |  1    |   3                             | 0                                              |
            |  3     |  2    |   4                             | 1                                              |
            |  4     |  0    |   3                             | 3                                              |
            |  4     |  1    |   4                             | 0                                              |
            |  4     |  2    |   5                             | 1                                              |
            |  4     |  3    |   6                             | 2                                              |
            +--------+-------+---------------------------------+------------------------------------------------+
            '?Lightbox' used to avoid caching images without CORS headers
          */
          files.length > 0 && (photoIndex > -1) &&
            <Lightbox
              mainSrc={imgUrl(files[photoIndex].croppedUrl || files[photoIndex].name) + '?Lightbox'}
              nextSrc={imgUrl(files[(photoIndex + 1) % files.length].croppedUrl || files[(photoIndex + 1) % files.length].name) + '?Lightbox'}
              prevSrc={imgUrl(files[(photoIndex + files.length - 1) % files.length].croppedUrl || files[(photoIndex + files.length - 1) % files.length].name) + '?Lightbox'}
              onCloseRequest={() => this.setState({ photoIndex: -1 })}
              onMovePrevRequest={() =>
                this.setState({
                  photoIndex: (photoIndex + files.length - 1) % files.length,
                })
              }
              onMoveNextRequest={() =>
                this.setState({
                  photoIndex: (photoIndex + 1) % files.length,
                })
              }
            />
        }
        {
          files.length > 1 &&
            <div className={classes.slider}>
              <div className={classes.images} ref={this.setScrollableRef}>
                {
                  files.map((file, index) => (
                    <SmartImage
                      key={file.name + index}
                      index={index}
                      src={file.croppedUrl || file.preview || file.name}
                      modifier={cx('sliderThumb', {
                        selected: selected === index,
                        inactive: index !== selected,
                      })}
                      originalLink={!!file.originalLink}
                      params={thumbParams}
                      onClick={() => this.setState({ selected: index })}
                    >
                      {
                        !readOnly &&
                          <ThumbMenu
                            index={index}
                            removeImage={onRemove}
                            setAsDefault={this.onSetMain}
                            allowChangeDefault={allowChangeMain && (index) > 0}
                            allowRemoveImage={this.removeAllowed(index)}
                            portal={this.rootNode}
                          />
                      }
                    </SmartImage>
                  ))
                }
              </div>
              {
                (rightScrollEnabled || leftScrollEnabled) &&
                  <div className={classes.controls}>
                    <button
                      className={cx(classes.next, { [classes.disabled]: !rightScrollEnabled })}
                      onClick={this.scrollToRight}
                    />
                    <button
                      className={cx(classes.previous, { [classes.disabled]: !leftScrollEnabled })}
                      onClick={this.scrollToLeft}
                    />
                  </div>
              }
            </div>
        }
      </div>
    );
  }
}

const Slider = injectSheet(sheet)(ImageSlider);

export { Slider };
