import React from 'react';
import PropTypes from 'prop-types';
import I from 'immutable';
import { connect } from 'cpcs-reconnect';
import { selectedList } from 'domain/router/RouterModel';
import SelectedItemsControls from 'pages/common/selectedItemsControls';
import { setCoverAction } from 'domain/galleries/GalleryActions';
import { addPopupAction } from 'domain/ui/UIActions';
import { removeSelectedAction, shareSelectedAction } from 'pages/common/selectedItemsControls/actions';
import {
  ADD_TO_GALLERY_POPUP,
} from 'domain/const';

class SelectedBar extends React.PureComponent {
  static propTypes = {
    selected: PropTypes.instanceOf(I.Set),
    setCover: PropTypes.func.isRequired,
    setArt: PropTypes.func,
    removeSelected: PropTypes.func.isRequired,
    shareSelected: PropTypes.func.isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        gId: PropTypes.string,
      }),
    }).isRequired,
    hasSelectedDrafts: PropTypes.bool.isRequired,
    addToGallery: PropTypes.func.isRequired,
  }

  get galleryId() {
    const { match: { params } } = this.props;
    const gId = parseInt(params.gId, 10);
    return isNaN(gId) ? 'all' : gId;
  };

  get coverButtonShown() {
    const { selected, hasSelectedDrafts } = this.props;
    return selected.size === 1 && hasSelectedDrafts === false;
  }

  onSetCover = () => {
    const { setCover, selected } = this.props;
    setCover({
      galleryId: this.galleryId,
      artworkId: selected.first(),
    });
  };

  onDelete = () => this.props.removeSelected();

  render() {
    const { selected, setArt, hasSelectedDrafts } = this.props;
    const onReset = () => setArt(new I.Set());
    return (
      <SelectedItemsControls
        items={selected}
        setCoverShown={this.coverButtonShown}
        onSetCover={this.onSetCover}
        onAddToGallery={this.props.addToGallery}
        onDelete={this.onDelete}
        onShare={hasSelectedDrafts ? null : this.props.shareSelected}
        onReset={onReset}
      />
    );
  }
}

export const SelectedBarPure = SelectedBar;

export default connect({
  shareSelected: shareSelectedAction,
  setCover: setCoverAction,
  removeSelected: removeSelectedAction,
  selected: selectedList,
  addToGallery: () => addPopupAction({ name: ADD_TO_GALLERY_POPUP }),
})(SelectedBar);
