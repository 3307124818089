import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import cx from 'classnames';
import inputToPropsDecorator from 'components/form/field/inputToPropsDecorator';
import injectSheet from 'lib/sheet';
import sheet from './sheet';

export function Textarea({ classes, name, onBlur, onChange, onDragStart, onDrop, onFocus, value, disabled, id, placeholder, modifier, maxLength }) {
  return (
    <textarea
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      onDragStart={onDragStart}
      onDrop={onDrop}
      onFocus={onFocus}
      className={cx(classes.field, 'Textarea', modifier)}
      value={value}
      maxLength={maxLength}
      disabled={disabled}
      id={id}
      placeholder={placeholder}
    />
  );
}

Textarea.propTypes = {
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onDragStart: PropTypes.func,
  onDrop: PropTypes.func,
  onFocus: PropTypes.func,
  value: PropTypes.any,
  disabled: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  id: PropTypes.string,
  placeholder: PropTypes.string,
  modifier: PropTypes.string,
  classes: PropTypes.shape({
    field: PropTypes.string.isRequired,
  }).isRequired,
  maxLength: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
};

export default compose(
  inputToPropsDecorator,
  injectSheet(sheet),
)(Textarea);
