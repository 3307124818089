import React from 'react';
import PropTypes from 'prop-types';
import I from 'immutable';
import cx from 'classnames';
import { Field } from 'redux-form';
import Checkbox from 'components/form/checkbox';
import Message from 'components/form/message';
import translate from 'lib/translate.js';

import injectSheet from 'lib/sheet';
import sheet from 'pages/common/popupForms/sheet';

const errorMessages = I.Map({
  shareParams: 'At once one checkbox should be checked',
});

const hasVisibleCheckBoxes = ( checkboxMap ) => checkboxMap
  .keySeq().toArray().filter(v => checkboxMap.get(v)).length > 0;

class CheckBoxGroup extends React.PureComponent {
  static propTypes = {
    checkboxMap: PropTypes.instanceOf(I.Map).isRequired,
    classes: PropTypes.shape({
      item: PropTypes.string,
      fieldset: PropTypes.string,
      legend: PropTypes.string,
      list: PropTypes.string,
      itemOdd: PropTypes.string,
      hidden: PropTypes.string,
    }).isRequired,
    meta: PropTypes.shape({
      touched: PropTypes.bool,
      error: PropTypes.string,
    }),
  };

  visibleCount = 0;

  getItemClass(name) {
    const { checkboxMap, classes } = this.props;
    return checkboxMap.get(name) ?
      cx(classes.item, { [classes.itemOdd]: !(this.visibleCount++ % 2) }) :
      classes.hidden;
  }

  render() {
    const { checkboxMap, classes, meta } = this.props;
    if (checkboxMap.size === 0) return <fieldset className={classes.fieldset} />;
    this.visibleCount = 0;
    return (
      <fieldset className={classes.fieldset}>
        { hasVisibleCheckBoxes(checkboxMap) &&
        <legend className={classes.legend}>Select information you want to share:</legend> }
        <ul className={classes.list}>
          {
            checkboxMap.keySeq().toArray().map((name) => (
              <li
                key={name}
                className={this.getItemClass(name)}
              >
                <Field
                  type="checkbox"
                  tabIndex="0"
                  name={name}
                  component={Checkbox}
                >{translate(`sharePropsCheckbox.${name}`)}</Field>
              </li>
            ))
          }
        </ul>
        <Message meta={meta} errorMessages={errorMessages} />
      </fieldset>
    );
  }
}

export default injectSheet(sheet)(CheckBoxGroup);
