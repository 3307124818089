import { takeEvery, put, call, select } from 'redux-saga/effects';
import Api, { makeApiGenerator } from 'domain/api';
import { addNotification, resetPasswordAction, checkResetPasswordToken } from 'domain/env/EnvActions';
import { queryParams } from 'domain/router/RouterModel';
import { redirectToPageAction } from 'lib/redirectToPage';

// { data: { ...payload, token } }
const resetPasswordRequest_apiGen = makeApiGenerator({
  actionCreator: resetPasswordAction,
  api: Api.resetPassword,
});
// { data: { token } })
const checkToken_apiGen = makeApiGenerator({
  actionCreator: checkResetPasswordToken,
  api: Api.checkToken,
});

function* onResetPassword({ payload }) {
  try {
    const { token } = yield select(queryParams);
    yield call(resetPasswordRequest_apiGen, { data: { ...payload, token } });
  } catch (err) {
    yield put(addNotification({ title: 'Password was not changed.', type: 'error' }));
  }
}

export default function* resetPage() {
  const { token } = yield select(queryParams);
  try {
    yield call(checkToken_apiGen, { data: { token } });
  } catch (err) {
    yield put({
      type: redirectToPageAction.type,
      payload: { page: 'auth' },
    });
    // yield put(addNotification({ title: 'Invalid token.', type: 'error' }));
  };
  yield takeEvery(resetPasswordAction.type, onResetPassword);
}
