import { call, put, fork, cancel } from 'redux-saga/effects';
import { sagaDelay } from 'lib/envGlobals';

export const createGenCallback = (fn, ...fnArgs) => function* (...more) {
  yield call(fn, ...fnArgs, ...more);
};

export const createGenDispatch = (action) => function* () {
  yield put(typeof action === 'string' ? { type: action } : action);
};

/**
 * import { reduceCallFrequency } from 'lib/saga-helpers';
 *
 * const myFn = reduceCallFrequency(mySagaFn, 4000);
 * yield fork(myFn);
 *
 * if fn will be called every 100ms and delay 3000ms, fn will be runned at (ms) 0, 3000, 6000, ...
 * if fn will be called every 4000ms and delay 3000ms, fn will be runned at (ms) 0, 4000, 8000, ...
 *
 * unlike the debounce where
 * if fn will be called every 100ms and delay 3000ms fn will not be runned at all
 * until calls will be stopped for 3000ms
 *
 * @param intermCb will be called every time the wrapper will be called but only if there is already pending task
 * (f.e. to show loading bar for delayed request);
**/
export const reduceCallFrequency = (fn, DELAY, intermCb) => {
  let lastCallTS = 0;
  let task = null;
  const taskRunnerFactory = catchError => function* taskRunner(delay, ts, args) {
    if (delay > 0) {
      yield sagaDelay(delay);
    }
    task = null;
    lastCallTS = ts + delay;
    if (catchError) {
      yield fork(fn.catchError, ...args);
    } else {
      yield fork(fn, ...args);
    }
  };
  const frequentCallFactory = (taskRunner) => function* frequentCall(...args) {
    const ts = new Date().getTime();
    const delay = Math.max(0, DELAY - (ts - lastCallTS));
    if (task && intermCb) {
      yield fork(intermCb);
    }
    if (task) {
      yield cancel(task);
      task = null;
    }
    task = yield fork(taskRunner, delay, ts, args);
  };
  const frequentCall = frequentCallFactory(taskRunnerFactory());
  if (fn.catchError) {
    frequentCall.catchError = frequentCallFactory(taskRunnerFactory(true));
  }
  return frequentCall;
};
