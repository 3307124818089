import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'cpcs-reconnect';
import I from 'immutable';
import moment from 'moment';

import { artistSelector } from 'domain/artwork/ArtworkModel';

import { PAGE_WIDTH, PAGE_HEIGHT, DEFAULT_FONTFAMILY, themes } from 'pages/highcharts/helpers/PDFConstants';
import { LogoHeader } from 'pages/highcharts/PDFComponents/Logo';

const LEFT = 239;

class PagePDF extends React.Component {
  static propTypes = {
    page: PropTypes.shape({
      contentSection: PropTypes.string.isRequired,
      page: PropTypes.number.isRequired,
    }).isRequired,
    PDFState: PropTypes.shape({
      contentSections: PropTypes.instanceOf(I.Collection).isRequired,
      reportType: PropTypes.string,
    }).isRequired,
    total: PropTypes.number,
    artist: PropTypes.shape({
      fullName: PropTypes.string,
    }),
    artwork: PropTypes.shape({
      title: PropTypes.string,
      artist: PropTypes.shape({
        fullName: PropTypes.string,
      }).isRequired,
    }),
  };

  sectionProp(prop) {
    const { PDFState, page } = this.props;
    const section = PDFState.contentSections.find(v => v.name === page.contentSection);
    if (!section) return null;
    return section.get(prop);
  }

  render() {
    const { page, total, artist, artwork, PDFState } = this.props;
    const { bg } = themes.pages[page.contentSection] || themes.pages.default;
    const year = moment().format('YYYY');
    const dateText = moment().format('MMMM YYYY');
    const { mainColor, headerTextColor } = (themes[PDFState.reportType] || {});
    const reportName = (artwork ? `${artwork.title} - ${artwork.artist.fullName}` : '') ||
      (artist ? artist.fullName : '') ||
      'Artist Name';
    return (
      <svg width={PAGE_WIDTH} height={PAGE_HEIGHT} className="PagePDF">
        <style>
          {`
            .PagePDF_pageNumber { font: 400 10px/16px ${DEFAULT_FONTFAMILY}; fill: ${headerTextColor}; }
            .PagePDF_sectionTitle { font: 500 10px/16px ${DEFAULT_FONTFAMILY}; fill: ${headerTextColor}; }
            .PagePDF_sectionDate { font: 500 10px/16px ${DEFAULT_FONTFAMILY}; fill: ${headerTextColor}; }
            .PagePDF_copyrights { font: 400 8px/16px ${DEFAULT_FONTFAMILY}; fill: ${headerTextColor}; }
            .PagePDF_artistName { font: 400 10px/16px ${DEFAULT_FONTFAMILY}; fill: ${headerTextColor}; }
          `}
        </style>
        <g>
          <rect
            width={PAGE_WIDTH}
            height={PAGE_HEIGHT}
            fill={bg}
          />
          <LogoHeader fill={headerTextColor} />
          <text x={LEFT} textAnchor="start" y="35" className="PagePDF_sectionTitle">
            Financial Performance Report
            {/*this.sectionProp('title')*/}
          </text>
          <text x="511" textAnchor="start" y="35" className="PagePDF_sectionDate">
            {dateText}
          </text>
          <path d={`M${LEFT} 40 h${PAGE_WIDTH - LEFT}`} stroke="#777" />
          <text x={LEFT} textAnchor="start" y="53" className="PagePDF_artistName">
            {reportName}
          </text>
          <text x="588" textAnchor="end" y="776" className="PagePDF_pageNumber">
            {page.page - 1} of {total - 1}
          </text>
          <text x="24" textAnchor="start" y="776" className="PagePDF_copyrights">
            Copyright © {year} ARTBnk Inc.
          </text>
          <rect
            width={PAGE_WIDTH}
            height="8"
            x="0"
            y={PAGE_HEIGHT - 8}
            fill={this.sectionProp('bg') || mainColor}
          />
        </g>
      </svg>
    );
  }
}

PagePDF = compose(
  connect({
    artist: artistSelector,
  }),
)(PagePDF);

export { PagePDF };
