import React from 'react';
import I from 'immutable';
import eq from 'deep-equal';
import { asyncNothing, isArray } from 'lib/helpers';
import PropTypes from 'prop-types';
import cx from 'classnames';
import TagList from './TagList';
import { focused } from 'components/generic';
import { keyDown, searchHandler, filterList, sortResults } from '../autocomplete/utils.js';

import injectSheet from 'lib/sheet';
import sheet from './sheet';

const sortByCommon = (common = new I.List()) => (a, b) => {
  if (common.includes(a.get('id')) && !common.includes(b.get('id'))) return -1;
  if (!common.includes(a.get('id')) && common.includes(b.get('id'))) return 1;
  return 0;
};

class Tags extends React.PureComponent {
  static propTypes = {
    containerProps: PropTypes.shape({
      ref: PropTypes.func,
      tabIndex: PropTypes.number,
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
    }),
    container: PropTypes.shape({
      show: PropTypes.bool,
      onShow: PropTypes.func,
      onHide: PropTypes.func,
    }).isRequired,
    classes: PropTypes.shape({
      Tags: PropTypes.string.isRequired,
      field: PropTypes.string.isRequired,
      tagsWrapper: PropTypes.string.isRequired,
    }).isRequired,
    meta: PropTypes.shape({
      touched: PropTypes.bool,
      error: PropTypes.string,
      warning: PropTypes.string,
    }).isRequired,
    input: PropTypes.shape({
      value: PropTypes.arrayOf(
        PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
        ]).isRequired,
      ).isRequired,
      onChange: PropTypes.func.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    disabled: PropTypes.bool,
    id: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    getValues: PropTypes.func.isRequired,
    onSearch: PropTypes.func, // eslint-disable-line
    optionList: PropTypes.instanceOf(I.List).isRequired,
    common: PropTypes.shape({
      includes: PropTypes.func,
    }),
    children: PropTypes.func,
    filterList: PropTypes.func,
    name: PropTypes.string,
    valueNormalize: PropTypes.func,
    noDropdown: PropTypes.bool,
    ownSortingAndFiltration: PropTypes.bool,
    modifier: PropTypes.string,
  };

  static defaultProps = {
    ownSortingAndFiltration: true,
    placeholder: '',
    filterList,
    optionList: new I.List(),
    valueNormalize: x => x.get('title'),
    getValues: asyncNothing,
    disabled: false,
    common: new I.List(),
    input: {
      value: [],
    },
  };

  state = {
    term: '',
    focused: false,
    hoveredId: 0,
    tags: [],
  };

  unmounted = false;

  setStateSafe(state, cb) {
    if (this.unmounted) {
      return;
    }
    this.setState(state, cb);
  }

  handleFocus = () => {
    this.setStateSafe({ focused: true }, this.props.containerProps.onFocus);
  };

  handleBlur = (event) => {
    const value = event.target;
    this.setStateSafe({
      focused: false,
    }, () => this.props.containerProps.onBlur(value, this));
  };

  containerFocus = () => this.input.focus();

  onHover = hoveredId => {
    this.setStateSafe({ hoveredId });
  };

  onSelect = id => {
    const { input: { onChange, value } } = this.props;
    const ids = id.toString();
    if (!value.includes(ids)) {
      this.setStateSafe({
        term: '',
        hoveredId: 0,
      }, () => onChange(value.concat(ids)));
    }
  };

  removeTag = (event, index) => {
    event.stopPropagation();
    const { disabled, input: { onChange, value } } = this.props;
    if (disabled) return;
    onChange(value.filter((e, i) => i !== index));
  };

  clearTerm = () => {
    this.setStateSafe({
      term: '',
      hoveredId: 0,
      focused: false,
    });
  };

  async componentDidMount() {
    const { value } = this.props.input;
    if (isArray(value) && value.length) {
      const tags = await this.props.getValues(...this.props.input.value);
      this.setStateSafe({
        tags: (tags || []),
      });
    }
  }

  async componentDidUpdate(prevProps) {
    const { input, optionList } = this.props;
    if (input.value.length && (!eq(prevProps.input.value, input.value) || prevProps.optionList.size !== optionList.size)) {
      const tags = await this.props.getValues(...input.value);
      this.setStateSafe({
        tags: (tags || []),
      });
    } else if (!input.value.length && this.state.tags.length) {
      this.setStateSafe({ tags: [] });
    }
  }

  componentWillUnmount() {
    this.unmounted = true;
  }

  get sortedList() {
    const {
      optionList, valueNormalize, onSearch, filterList, ownSortingAndFiltration,
      input: { value },
      common,
    } = this.props;
    const { term } = this.state;
    return [
      l => ownSortingAndFiltration ? sortResults(l, term, valueNormalize, onSearch === undefined) : l,
      l => filterList(l, this.state.term, value, !ownSortingAndFiltration),
    ].reduce((l, f) => f(l), optionList)
     .sort(sortByCommon(common));
  }

  render() {
    const { classes, id, disabled, meta, noDropdown, common, modifier } = this.props;
    const { touched, error } = meta;
    return (
      <div
        className={cx(classes.Tags, { disabled })}
        disabled={disabled}
        data-name={`tags-autocomplete--${this.props.input.name || this.props.name}`}
      >
        <div style={{ position: 'relative' }}>
          <div
            tabIndex="0"
            onClick={this.containerFocus}
            onFocus={this.containerFocus}
            className={cx(
              classes.tagsWrapper,
              {
                error: touched && error,
                hideDropdownArrow: noDropdown,
                focused: this.state.focused && !disabled,
              },
            )}
            ref={this.props.containerProps.ref}
          >
            <TagList
              disabled={disabled}
              list={this.state.tags}
              onRemove={this.removeTag}
            />
            <input
              id={id}
              role="combobox"
              aria-autocomplete="list"
              autoComplete="off"
              aria-expanded={this.state.show}
              aria-controls={id}
              className={cx(classes.field, 'Tags', modifier)}
              placeholder={this.props.placeholder}
              value={this.state.term}
              onFocus={this.handleFocus}
              onBlur={this.handleBlur}
              onKeyDown={keyDown.bind(this)}
              disabled={this.props.disabled}
              ref={(element) => { this.input = element; }}
              onChange={searchHandler.bind(this)}
              data-component="components/form/tags"
            />
          </div>
          {
            this.props.children({
              list: this.sortedList,
              common,
              show: this.props.container.show,
              preview: this.onHover,
              doSelect: this.onSelect,
              index: this.state.hoveredId,
            })
          }
        </div>
      </div>
    );
  }
}

const TagsThemed = focused(injectSheet(sheet)(Tags));
export {
  Tags as PureTags,
  TagsThemed as Tags,
};
