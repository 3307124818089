import React from 'react';
import PropTypes from 'prop-types';
import I from 'immutable';
import { dateStructToString } from 'lib/helpers';
import injectSheet from 'lib/sheet';

export const provenanceItemToStr = (e, countries) => [
  e.owner,
  e.from ? `from (${dateStructToString(e.from)})` : null,
  e.to ? `to (${dateStructToString(e.to)})` : null,
  e.city ? e.city : null,
  (e.country && countries) ? countries.getIn([e.country, 'title'], null) : null,
].filter(v => [v].join(''))
  .join(', \n')
  .replace(/&amp;/g, '&');

function ProvenancesNoTheme({ classes, provenances, countries = {} }) {
  if (!provenances || !provenances.size) return null;
  return (
    <ul className={classes.Provenances}>
      {
        provenances && provenances.map((e, key) => (
          <li
            key={key}
            className={classes.item}
            children={provenanceItemToStr(e, countries)}
          />
        ))
      }
    </ul>
  );
}
ProvenancesNoTheme.propTypes = {
  classes: PropTypes.shape({
    Provenances: PropTypes.string.isRequired,
    item: PropTypes.string.isRequired,
  }).isRequired,
  provenances: PropTypes.instanceOf(I.Collection),
  countries: PropTypes.instanceOf(I.Collection),
};

const Provenances = injectSheet({
  Provenances: {},
  item: {
    marginTop: 10,
    wordSpacing: '2px',
    whiteSpace: 'pre-line',
    overflowWrap: 'break-word',
    '&:first-child': {
      marginTop: 0,
    },
  },
})(ProvenancesNoTheme);

export default function provenanceSolver(value, data, name, props = {}) {
  return <Provenances
    provenances={value}
    countries={props.countries}
  />;
};

provenanceSolver.propTypes = {
  countries: PropTypes.instanceOf(I.Collection),
};
