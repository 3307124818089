import React from 'react';
import PropTypes from 'prop-types';

function auctionSolver(val, data, name, props) {
  if (props.expiredTrialUser) return <span/>; // eslint-disable-line
  const { noLotLink } = props;
  if (!data) return null;
  const lot = data.get('lot');
  if (!lot) return null;
  if (noLotLink) return lot.get('seller', null);
  if (!lot.get('url') && !lot.get('seller')) return null;
  if (
    lot.get('url') && (
      lot.get('url').match('\\b(\\w*fineart.ha\\w*)\\b') ||
      lot.get('url').match('\\b(\\w*liveauctioneers\\w*)\\b') ||
      lot.get('url').match('\\b(\\w*invaluable\\w*)\\b')
    )
  ) {
    return <span>{lot.get('seller') || ''}</span>;
  }
  return (
    <a
      style={{ textDecoration: 'underline' }}
      target="_blank"
      href={lot.get('url')}
      rel="noopener noreferrer"
      children={lot.get('seller') || lot.get('url') || ''}
    />
  );
}

auctionSolver.propTypes = {
  noLotLink: PropTypes.bool,
};
export default auctionSolver;
