import React from 'react';
import PropTypes from 'prop-types';
import I from 'immutable';
import cx from 'classnames';
import { Field } from 'redux-form';
import { getId } from '../utils';
import unitsList from 'lib/units';
import { limitFractionLength } from 'components/form/normalizers';
import { rules, validator } from 'components/form/validation';
import FormRowField from 'pages/common/newPage/form/elements/FormRowField';
import Select from 'components/form/select';

import injectSheet from 'lib/sheet';
import sheet from './sheet';


// https://youtrack.artbnk.com/issue/MVP-6592
const allowEmtpyValidator = {
  height: validator(rules.float3),
  width2D: validator(rules.float3),
  width3D: validator(rules.float3),
  depth: validator(rules.float3),
};

const validateOptional = {
  height: validator(rules.float3, rules.greaterThan(0)),
  width2D: validator(rules.float3, rules.greaterThan(0)),
  width3D: validator(rules.float3),
  depth: validator(rules.float3),
};

const validateRequired = {
  height: validator(rules.required, rules.float3, rules.greaterThan(0)),
  width2D: validator(rules.required, rules.float3, rules.greaterThan(0)),
  width3D: validator(rules.float3),
  depth: validator(rules.float3),
};

const validateRequiredCircular = {
  height: validator(rules.required, rules.float3, rules.greaterThan(0)),
  width2D: validator(rules.float3),
  width3D: validator(rules.float3),
  depth: validator(rules.float3),
};

const validateOptionalCircular = {
  height: validator(rules.float3, rules.greaterThan(0)),
  width2D: validator(rules.float3),
  width3D: validator(rules.float3),
  depth: validator(rules.float3),
};

const OPTIONAL = 0;
const REQUIRED = 1;
const CIRCULAR = 2;
const validators = {
  [OPTIONAL]: validateOptional,
  [REQUIRED]: validateRequired,
  [CIRCULAR]: validateOptionalCircular,
  [REQUIRED + CIRCULAR]: validateRequiredCircular,
};

function Size({ name = 'size', classes, list = unitsList, disabled, modifier, allowEmpty = false, isCircular = false, is3D = false, required = false, label='Size' }) {
  let validate = validators[required * REQUIRED + isCircular * CIRCULAR];
  if (allowEmpty) {
    validate = allowEmtpyValidator;
  }
  const id = getId(name);
  return (
    <li className={cx(classes.formItem, modifier)}>
      <label
        htmlFor={`${id}-height`}
        className={cx(classes.label, modifier, { required })}
        children={label}
      />
      <div className={cx(classes.fieldWrapper, modifier, 'sizeContainer')}>
        <Field
          id={`${id}-height`}
          type="text"
          name="height"
          component={FormRowField}
          modifier={cx('sizeField', modifier)}
          placeholder={isCircular ? 'D' : 'H'}
          disabled={disabled}
          rootTag="div"
          normalize={limitFractionLength(3)}
          validate={validate.height}
        />
        <Field
          id={`${id}-width`}
          type="text"
          name="width"
          component={FormRowField}
          modifier={cx('sizeField', modifier)}
          placeholder="W"
          disabled={disabled || isCircular}
          rootTag="div"
          normalize={limitFractionLength(3)}
          format={v => isCircular ? '' : ([v].join(''))}
          validate={is3D ? validate.width3D : validate.width2D}
        />
        <Field
          id={`${id}-depth`}
          type="text"
          name="depth"
          component={FormRowField}
          modifier={cx('sizeField', modifier)}
          placeholder="D"
          disabled={disabled || !is3D}
          rootTag="div"
          normalize={limitFractionLength(3)}
          validate={is3D ? validate.depth : undefined}
        />
        <div className={cx(classes.selectWrapper, modifier, 'unitField')}>
          <Field
            id={`${id}-unit`}
            component={Select}
            name="unit"
            list={list}
            defaultValue={list.get(0)}
            isClearable={false}
            isSearchable={false}
            disabled={disabled}
          />
        </div>
      </div>
    </li>
  );
}

Size.propTypes = {
  name: PropTypes.string,
  list: PropTypes.instanceOf(I.List),
  disabled: PropTypes.bool,
  classes: PropTypes.shape({
    field: PropTypes.string,
    formItem: PropTypes.string,
    label: PropTypes.string,
    fieldWrapper: PropTypes.string,
    selectWrapper: PropTypes.string,
  }).isRequired,
  is3D: PropTypes.bool,
  required: PropTypes.bool,
  label: PropTypes.string,
  allowEmpty: PropTypes.bool,
  isCircular: PropTypes.bool,
  modifier: PropTypes.string,
};

export default injectSheet(sheet)(Size);
