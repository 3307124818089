import { NEW_STYLE } from 'theme/theme';
import { ABV_COUNTDOWN_BANNER_Z_INDEX } from 'theme/zIndex';
import { LAYOUT_768_MAX_WIDTH } from 'theme/layout/responsiveList';
import close from './images/close.svg';

const abvButtons = {
  display: 'flex',
  height: 33,
  color: '#fff',
  fontSize: 12,
  fontWeight: 500,
  justifyContent: 'center',
  alignItems: 'center',
  textDecoration: 'none',
  textTransform: 'uppercase',
  '&:hover': {
    textDecoration: 'underline',
  },
};

export default {
  AbvCountdownBanner: {
    position: 'fixed',
    display: 'flex',
    bottom: 40,
    width: 268,
    height: 65,
    opacity: 0.9,
    color: '#FFF',
    borderRadius: 4,
    backgroundColor: NEW_STYLE,
    boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.2)',
    zIndex: ABV_COUNTDOWN_BANNER_Z_INDEX,
    [`@media screen and (max-width: ${LAYOUT_768_MAX_WIDTH}px)`]: {
      '&.AFOpened': {
        display: 'none',
      },
    },
    '@media screen and (max-width: 470px)': {
      width: 258,
      height: 50,
    },
  },
  abvValues: {
    display: 'flex',
    width: 171,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRight: '1px solid #ebebeb',
    '@media screen and (max-width: 470px)': {
      width: 135,
      '&.SUBSCRIPTION_TYPE_PROFESSIONAL, &.SUBSCRIPTION_TYPE_ENTERPRISE': {
        width: 155,
      },
    },
  },
  abvValuesTitle: {
    margin: [0, 0, 5],
    fontSize: 14,
    fontWeight: 500,
  },
  abvValuesRemained: {
    fontSize: 12,
  },
  abvActions: {
    display: 'flex',
    width: 97,
    flexDirection: 'column',
    justifyContent: 'center',
    '&.SUBSCRIPTION_TYPE_PROFESSIONAL': {
      justifyContent: 'center',
    },
  },
  abvUpgrade: {
    ...abvButtons,
    borderBottom: '1px solid #ebebeb',
    '&.SUBSCRIPTION_TYPE_PROFESSIONAL, &.SUBSCRIPTION_TYPE_ENTERPRISE': {
      display: 'none',
    },
    '@media screen and (max-width: 470px)': {
      height: '100%',
      borderBottom: 'none',
    },
  },
  abvDismissSmall: {
    display: 'none',
    justifyContent: 'center',
    width: 10,
    height: 10,
    margin: [21, 0, 0, 15],
    background: `url("${close}") scroll no-repeat center / 10px`,
    '@media screen and (max-width: 470px)': {
      display: 'flex',
    },
    '&.SUBSCRIPTION_TYPE_PROFESSIONAL, &.SUBSCRIPTION_TYPE_ENTERPRISE': {
      display: 'none',
    },
  },
  abvDismiss: {
    ...abvButtons,
    '@media screen and (max-width: 470px)': {
        display: 'none',
        '&.SUBSCRIPTION_TYPE_PROFESSIONAL, &.SUBSCRIPTION_TYPE_ENTERPRISE': {
          display: 'block',
        },
    },
    opacity: 0.7,
    cursor: 'pointer',
  },
};
