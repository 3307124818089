// import popupForms from 'pages/common/popupForms/sheet';

export const sheet = {
  NewAuthorForm: {
    // root
  },
  // btnGroup: popupForms.btnGroup,
  // send: {
  //   ...Buttons.done,
  //   margin: [0, 5],
  // },
  // cancel: {
  //   ...Buttons.cancel,
  //   margin: [0, 5],
  // },
};
