import {
  formItem,
  label,
  fieldWrapper,
  buttonContainer,
} from 'theme/form';
import { AOFormSheet } from 'pages/common/newPage/form/sheet';

export default {
  formItem,
  label,
  fieldWrapper,
  buttonContainer,
  Provenance: {
    // root
  },
  ProvenanceFields: {
    // root of fields component
    position: 'relative',
  },
  ProvenanceItem: {
    marginTop: 12,
    // root of each provenance field group
    '&:before': {
      display: 'block',
      content: '""',
      height: 0,
      borderTop: '1px dashed gray',
      position: 'relative',
      top: -6,
    },
    '&:first-child': {
      marginTop: 0,
    },
    '&:first-child:before': {
      opacity: 0,
    },
  },
  fieldsGroup: {
    flex: [1, 1, 'auto'],
    minWidth: 0,
  },
  periodField: {
    width: 150,
  },
  periodFieldTo: {
    extend: 'periodField',
    textAlign: 'right',
  },
  // fromDate: {
  //   flex: [0, 1, '120px'],
  //   marginRight: 20,
  // },
  // toDate: {
  //   flex: [0, 1, '120px'],
  // },
  button: AOFormSheet.button,
  add: AOFormSheet.add,
  remove: AOFormSheet.remove,
  expandButton: {
    position: 'relative',
    left: 0,
    top: 0,
    width: '100%',
    textAlign: 'left',
  },
};
