import collect from './images/collectButton.svg';
import track from './images/trackButton.svg';
import share from './images/shareButton.svg';
import collectDone from './images/collectButton-done.svg';
import trackDone from './images/trackButton-done.svg';
import pdf from 'pages/rtvValueListPage/images/PDF.svg';
import { DARK_BLUE_COLOR } from 'theme/theme';
import { ACTION_BUTTONS_FOCUSED_STYLE } from 'theme/focus';

const VISIBLE_BEFORE = {
  display: 'inline-block',
  content: '""',
  height: 24,
  width: 24,
};

export default {
  ItemActions: {
    display: 'flex',
    width: '100%',
    marginTop: 35,
    justifyContent: 'center',
    '@media screen and (max-width: 430px)': {
      marginTop: 20,
      flexWrap: 'wrap',
      '&.items-4': {
        justifyContent: 'space-between',
      },
    },
    '@media screen and (max-width: 310px)': {
      '&.items-3': {
        justifyContent: 'space-between',
      },
    },
    '@media screen and (max-width: 240px)': {
      display: 'block',
    },
  },
  button: {
    // padding: [0, 0, 0, 24],
    padding: 0,
    height: 24,
    lineHeight: '23px',
    fontSize: 14,
    marginRight: 30,
    cursor: 'pointer',
    textDecoration: 'none',
    whiteSpace: 'nowrap',
    textIndent: '5px',
    display: 'flex',
    verticalAlign: 'middle',
    '&:focus': {
      ...ACTION_BUTTONS_FOCUSED_STYLE,
    },
    '&:last-child': {
      marginRight: 0,
    },
    '&.btnCollect': {
      '&:before': {
        fontWeight: 500,
        ...VISIBLE_BEFORE,
        background: `url(${collect}) no-repeat 3px 3px`,
      },
      '&.collected:before': {
        backgroundImage: `url(${collectDone})`,
      },
    },
    '&.btnTrack': {
      '&:before': {
        fontWeight: 500,
        ...VISIBLE_BEFORE,
        background: `url(${track}) no-repeat 2px 1px / 20px`,
      },
      '&.tracked::before': {
        backgroundImage: `url(${trackDone})`,
      },
    },
    '&.ShareButton:before': {
      color: DARK_BLUE_COLOR,
      ...VISIBLE_BEFORE,
      background: `url("${share}") no-repeat 2px 1px`,
    },
    '&.downloadPdf:before': {
      color: DARK_BLUE_COLOR,
      ...VISIBLE_BEFORE,
      background: {
        image: `url("${pdf}")`,
        repeat: 'no-repeat',
        position: '1px center',
        color: 'transparent',
      },
    },
    '@media screen and (max-width: 900px)': {
      marginRight: 23,
    },
    '@media screen and (max-width: 392px)': {
      marginRight: 16,
    },
    '@media screen and (max-width: 373px)': {
      marginRight: 10,
      marginTop: 10,
    },
  },
};
