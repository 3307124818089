import 'core-js';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import history from 'lib/history';
import rootSaga from 'sagas/rootSaga';
import ReactGA from 'react-ga';

import { loadConfig, onEnvConfigLoaded } from 'config';
import configureStore from './domain';
import App from './pages';

loadConfig();

global.I = require('immutable');
global.moment = require('moment');
global.NODE_ENV = process.env.NODE_ENV;

const store = configureStore(history);
store.runSaga(rootSaga);

history.listen(location => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
});

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root'),
);
/* istanbul ignore next */
onEnvConfigLoaded(({ GOOGLETAGMANAGER_ENABLED }) => {
  if (!GOOGLETAGMANAGER_ENABLED) return;
  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({ 'gtm.start':
    new Date().getTime(),event: 'gtm.js' });var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!=='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','GTM-TST2ZNP');
});

/* istanbul ignore next */
onEnvConfigLoaded(({ HOTJAR_ENABLED }) => {
  if (!HOTJAR_ENABLED) return;
  (function(h, o, t, j, a, r) {
    h.hj = h.hj || function() { (h.hj.q = h.hj.q || []).push(arguments); }; //NOSONAR
    h._hjSettings = { hjid: 599447, hjsv: 5 };
    a = o.getElementsByTagName('head')[0];
    r = o.createElement('script');
    r.async = 1;
    r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
    a.appendChild(r);
  })(window, document, '//static.hotjar.com/c/hotjar-', '.js?sv=');
});

/* istanbul ignore next */
onEnvConfigLoaded(({ ZDASSETS_ENABLED }) => {
  if (!ZDASSETS_ENABLED) return;
  (function(w, d, l, v) {
    const head = d.getElementsByTagName('head')[0];
    const script = d.createElement('script');
    script.id = 'ze-snippet';
    script.async = 1;
    script.src = l + v;
    head.appendChild(script);
  })(window, document, 'https://static.zdassets.com/ekr/snippet.js?key=', '906851fe-afad-4e00-be55-e47eb2c43eee');
});

/* istanbul ignore next */
onEnvConfigLoaded(({ USERGUIDING_ENABLED }) => {
  if (!USERGUIDING_ENABLED) return;
  (function(u, s, e, r, g) {
    u[r] = u[r] || [];
    u[r].push({
      'ug.start': new Date().getTime(), event: 'embed.js',
    });
    var f = s.getElementsByTagName(e)[0],
      j = s.createElement(e);
    j.async = true;
    j.src = 'https://static.userguiding.com/media/user-guiding-'
      + g + '-embedded.js';
    f.parentNode.insertBefore(j, f);
  })(window, document, 'script', 'userGuidingLayer', '40266282ID');
});

global.intercomSettings = {
  app_id: 'd1z9zvu6',
};
// We pre-filled your app ID in the widget URL: 'https://widget.intercom.io/widget/d1z9zvu6'
(function() {
  var w = global;
  var ic = w.Intercom;
  if (typeof ic === 'function') {
    ic('reattach_activator');
    ic('update', w.intercomSettings);
  } else {
    var d = document;
    var i = function() {
      i.c(arguments);
    };
    i.q = [];
    i.c = function(args) {
      i.q.push(args);
    };
    w.Intercom = i;
    var l = function() {
      var s = d.createElement('script');
      s.type = 'text/javascript';
      s.async = true;
      s.src = 'https://widget.intercom.io/widget/d1z9zvu6';
      var x = d.getElementsByTagName('script')[0];
      x.parentNode.insertBefore(s, x);
    };
    if (w.attachEvent) {
      w.attachEvent('onload', l);
    } else {
      w.addEventListener('load', l, false);
    }
  }
})();
