import React from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';
import sheet from './sheet';
import injectSheet from 'lib/sheet';

class ReportOption extends React.PureComponent {

  static propTypes = {
    classes: PropTypes.shape({
      ReportOption: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      price: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      ReportOptionActive: PropTypes.string.isRequired,
    }).isRequired,
    onSelectReportType: PropTypes.func.isRequired,
    title: PropTypes.string,
    description: PropTypes.string,
    price: PropTypes.string,
    active: PropTypes.string,
  };

  onSelectReportType = () => {
    const { onSelectReportType, title } = this.props;
    onSelectReportType(title);
  }

  render() {
    const { classes, title, description, price, active } = this.props;
    const activeClass = active ? classes.ReportOptionActive : '';
    return (
      <button
        type="button"
        onClick={this.onSelectReportType}
        className={cx(classes.ReportOption, activeClass)}
      >
        <div
          className={classes.name}
          children={title}
        />
        <div
          className={classes.price}
          children={price}
        />
        <div
          className={classes.description}
          children={description}
        />
      </button>
    );
  }
}

export default injectSheet(sheet)(ReportOption);
