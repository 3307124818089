import logo from './artbnk.svg';
// import small_logo from './artbnk_small.svg';
import closeBg from './close.svg';
// import star from './star.svg';
import { DEFAULT_TEXT_COLOR, DEFAULT_FONTFAMILY } from 'theme/theme';
import { FOCUS_BG_STYLE } from 'theme/focus';

const defaultLogo = {
  width: 137,
  height: '100%',
  background: `url("${logo}") center no-repeat`,
  backgroundSize: 'contain',
};

// const mobileLogo = {
//   width: 34,
//   height: 34,
//   background: `url("${small_logo}") left top no-repeat`,
//   backgroundSize: 'cover',
// };

export default {
  Menu: {
    // root
    display: 'flex',
    height: '100%',
    '&.mobileMenu': {
      position: 'relative',
      flexDirection: 'column',
    },
    '@media screen and (max-width: 569px)': {
      '&.mobileMenu': {
        textAlign: 'center',
      },
    },
  },
  logo: {
    ...defaultLogo,
    '&.mobileMenu': {
      width: 137,
      height: 34,
      marginLeft: 24,
      backgroundSize: 'cover',
      visibility: 'hidden',
    },
    // '@media screen and (max-width: 1440px)': {
    //   '&.mobileMenu': {
    //     ...defaultLogo,
    //   },
    // },
    // '@media screen and (max-width: 1278px)': {
    //   ...mobileLogo,
    // },
    // '@media screen and (max-width: 1164px)': {
    //   ...defaultLogo,
    // },
    // '@media screen and (max-width: 767px)': {
    //   ...mobileLogo,
    //   '&.mobileMenu': {
    //     ...mobileLogo,
    //   },
    // },
    // '@media screen and (max-width: 569px)': {
    //   '&.mobileMenu': {
    //     visibility: 'visible',
    //   },
    // },
    // '@media screen and (max-width: 375px)': {
    //   '&.mobileMenu': {
    //     ...mobileLogo,
    //   },
    // },
  },

  nav: {
    display: 'flex',
    '@media screen and (max-width: 569px)': {
      justifyContent: 'center',
    },
  },
  menuWrp: {
    display: 'flex',
    height: '100%',
    margin: [0, 0, 0, 25],
    fontSize: 13,
    fontWeight: 600,
    textTransform: 'uppercase',
    '&.mobileMenu': {
      flexDirection: 'column',
      margin: [20, 0, 0, 82],
    },
    // '@media screen and (max-width: 1423px)': {
    //   display: 'none',
    //   '&.mobileMenu': {
    //     display: 'block',
    //     width: '100%',
    //   },
    // },
    '@media screen and (max-width: 1084px)': {
      display: 'none',
      '&.mobileMenu': {
        display: 'block',
      },
    },
    '@media screen and (max-height: 620px)': {
      '&.mobileMenu': {
        marginTop: 5,
      },
    },
    '@media screen and (max-width: 569px)': {
      '&.mobileMenu': {
        marginLeft: 0,
      },
    },
  },
  menuWrpSimple: {
    height: '100%',
    margin: [0, 0, 0, 25],
    fontSize: 13,
    fontWeight: 600,
    textTransform: 'uppercase',
    display: 'none',
    '&.mobileMenu': {
      display: 'none',
    },
    '@media screen and (max-width: 1084px)': {
      display: 'flex',
    },
    '@media screen and (max-width: 950px)': {
      display: 'none',
    },
  },
  menuItem: {
    margin: [0, 4, 0, 0],
    '&.mobileMenu': {
      display: 'flex',
      margin: [20, 0, 0],
      fontSize: 16,
      '&:first-child': {
        marginTop: [10, 0, 0],
      },
    },
    '@media screen and (max-height: 620px)': {
      '&.mobileMenu': {
        margin: 0,
      },
    },
    '@media screen and (max-width: 375px)': {
      margin: [0, 4, 0, 0],
      '&.mobileMenu': {
        margin: [7, 0],
      },
    },
    '@media screen and (max-width: 569px)': {
      '&.mobileMenu': {
        justifyContent: 'center',
      },
    },
  },
  menuItemLink: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    padding: [11, 12, 0],
    color: '#000000',
    borderBottom: '4px solid transparent',
    textDecoration: 'none',
    letterSpacing: '2px',
    fontWeight: 500,
    textAlign: 'center',
    '&.active, &:hover': {
      cursor: 'pointer',
      borderBottom: '4px solid #304C95',
      fontWeight: 600,
    },
    '&:focus': {
      ...FOCUS_BG_STYLE,
    },
    '&.insightPage': {
      position: 'relative',
      // paddingLeft: 20,
      // '&:before': {
      //   content: '""',
      //   position: 'absolute',
      //   left: 0,
      //   width: 15,
      //   height: 15,
      //   background: `url("${star}") left top no-repeat`,
      // },
      // '&.mobileMenu': {
      //   paddingLeft: 20,
      // },
      '@media screen and (max-width: 1084px)': {
        '&.mobileMenu': {
          display: 'none',
        },
      },
      '@media screen and (max-width: 950px)': {
        '&.mobileMenu': {
          display: 'flex',
        },
      },
    },
    '&.auctionAlerts': {
      '@media screen and (max-width: 1084px)': {
        '&.mobileMenu': {
          display: 'none',
        },
      },
      '@media screen and (max-width: 950px)': {
        '&.mobileMenu': {
          display: 'flex',
        },
      },
    },
    '&.mobileMenu': {
      display: 'flex',
      width: 'max-content',
      paddingLeft: 0,
      paddingRight: 0,
    },
  },

  mobileValueYourArt: {
    display: 'none',
    '@media screen and (max-width: 780px)': {
      display: 'block',
    },
  },

  mobileAccountMenu: {
    margin: [45, 0, 54, 82],
    '@media screen and (max-width: 375px)': {
      margin: [35, 0, 14, 54],
    },
    '@media screen and (max-height: 620px)': {
      margin: [20, 0, 5, 82],
    },
    '@media screen and (max-width: 569px)': {
      marginLeft: 0,
    },
  },
  mobileAccountMenuItemWrp: {
    margin: [0, 0, 36, 0],
    '@media screen and (max-height: 620px)': {
      marginBottom: 10,
    },
  },
  mobileAccountMenuItem: {
    color: DEFAULT_TEXT_COLOR,
    font: `400 16px ${DEFAULT_FONTFAMILY}`,
    textDecoration: 'none',
    textTransform: 'uppercase',
    borderBottom: '4px solid transparent',
    outline: 'none',
    '&:hover': {
      cursor: 'pointer',
      borderBottom: '4px solid #304C95',
      fontWeight: 600,
    },
    '@media screen and (max-width: 375px)': {
      marginBottom: 25,
    },
  },
  closeMobileMenuBtn: {
    position: 'absolute',
    top: 20,
    right: 24,
    width: 24,
    height: 24,
    outline: 'none',
    background: `url("${closeBg}") left top no-repeat`,
    backgroundSize: 'cover',
  },
  ico: {},
};
