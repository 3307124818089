import { takeLatest, put, select, call, fork } from 'redux-saga/effects';
import { getFormValues } from 'redux-form';

import {
  fetchAdvancedFilterOptionsAction,
  fetchAdvancedFilterPaginationAction,
  onArtistSearchAdvancedFilterAction,
  advancedFilterFetchTotalAction,
} from 'domain/advancedFilter/AdvancedFilterActions';
import {
  artistListSelector,
} from 'domain/advancedFilter/AdvancedFilterModel';
import Api from 'domain/api';
import {
  currentRouteNameSelector,
  routerParams_sel,
  location_sel,
  pathnameChanged as pathnameChanged_sel,
} from 'domain/router/RouterModel';
import { callApi } from 'domain/api';
import { currentGalleryId_sel } from 'domain/env/EnvModel';
import { FORM_ADVANCED_FILTER } from 'domain/const';
import { sagaDelay } from 'lib/envGlobals';
import { mapCategoryTitle } from 'lib/helpers';
// clean-code-lib: Array.prototype.includes
import 'lib/clean-code-lib';

function* advancedFilterSuccessData({ data }) {
  const location = yield select(location_sel);
  return { payload: { ...data, pathname: location.pathname } };
}

// export const fetchAlertAdvancedFilterOptions_apiGen = makeApiGenerator({
//   actionCreator: fetchAdvancedFilterOptionsAction,
//   api: Api.getAlertAdvancedFilterOptions,
//   getSuccessData: advancedFilterSuccessData,
// });
// export const fetchGalleryAdvancedFilterOptions_apiGen = makeApiGenerator({
//   actionCreator: fetchAdvancedFilterOptionsAction,
//   api: Api.getGalleryAdvancedFilterOptions,
//   getSuccessData: advancedFilterSuccessData,
// });
// export const fetchArtistAdvancedFilterOptions_apiGen = makeApiGenerator({
//   actionCreator: fetchAdvancedFilterOptionsAction,
//   api: Api.getArtistAdvancedFilterOptions,
//   getSuccessData: advancedFilterSuccessData,
// });
// export const fetchSaleLotsAdvancedFilterOptions_apiGen = makeApiGenerator({
//   actionCreator: fetchAdvancedFilterOptionsAction,
//   api: Api.getSaleLotsAdvancedFilterOptions,
//   getSuccessData: advancedFilterSuccessData,
// });

function* fetchAdvancedFilterPagination({ requestData, apiFn }) {
  try {
    yield put({
      type: fetchAdvancedFilterPaginationAction.request,
      payload: requestData,
    });
    const { data } = yield callApi(apiFn, requestData);
    yield put({
      type: fetchAdvancedFilterPaginationAction.success,
      payload: {
        data,
        filterName: requestData.filterName,
      },
    });
    return data;
  } catch (err) {
    console.error(err);
    yield put({
      type: fetchAdvancedFilterPaginationAction.failure,
      err,
    });
    // throw err;
  }
}

const MAP_ROUTENAME_TO_APIFN = {
  collectionListPage: { apiFn: Api.getGalleryAdvancedFilterOptions, isGallery: true },
  collectionList_oldLink: { apiFn: Api.getGalleryAdvancedFilterOptions, isGallery: true },
  artistPage: { apiFn: Api.getArtistAdvancedFilterOptions, isArtist: true },
  saleLotsListPage: { apiFn: Api.getSaleLotsAdvancedFilterOptions, isSaleLots: true },
  sharedItemsPage: { apiFn: Api.getShareAdvancedFilterOptions, isShare: true },

  artistPageTotal: { apiFn: Api.getArtistAdvancedFilterTotal, isArtist: true },
  collectionListPageTotal: { apiFn: Api.getGalleryAdvancedFilterTotal, isGallery: true },
  saleLotsListPageTotal: { apiFn: Api.getSaleLotsAdvancedFilterTotal, isSaleLots: true },
  sharedItemsPageTotal: { apiFn: Api.getShareLotsAdvancedFilterTotal, isShare: true },
};

function* prepareOptionsRequest(key, requestData = {}) {
  const { apiFn, isGallery, isAlert, isArtist, isSaleLots, isShare } = MAP_ROUTENAME_TO_APIFN[key] || {};
  if (!apiFn) throw new Error(`no apiFn for "${key}"`);
  if (isGallery) {
    const galleryId = yield select(currentGalleryId_sel);
    if (!galleryId) throw new Error('no galleryId');
    requestData = { ...requestData, galleryId };
  } else if (isAlert) {
    const { alertId } = yield select(routerParams_sel);
    if (!alertId) throw new Error('no alertId');
    requestData = { ...requestData, alertId };
  } else if (isArtist) {
    const { authorId } = yield select(routerParams_sel);
    if (!authorId) throw new Error('no authorId');
    requestData = { ...requestData, artistId: authorId };
  } else if (isSaleLots) {
    let { auctionId, saleId } = yield select(routerParams_sel);
    if (!saleId) throw new Error('no saleId');
    if (!auctionId) throw new Error('no !auctionId');
    saleId = decodeURIComponent(saleId);
    requestData = { ...requestData, saleId, auctions: [auctionId] };
  } else if (isShare) {
    const { shareId } = yield select(routerParams_sel);
    if (!shareId) throw new Error('no shareId');
    requestData = { ...requestData, shareId };
  }
  return { apiFn, requestData };
}

function* onAdvancedFilterPagination(action) {
  const routeName = yield select(currentRouteNameSelector);
  try {
    const { apiFn, requestData } = yield call(prepareOptionsRequest, routeName, { ...action.payload, size: 5 });
    return yield call(fetchAdvancedFilterPagination, { apiFn, requestData });
  } catch (err) {
    console.error(err);
  }
}

function renameCategories(payload) {
  if (!payload || !payload.categories) {
    return payload;
  }
  try {
    let { categories } = payload;
    categories = {
      ...categories,
      content: (categories.content || []).map(
        ({ count, entity: { id, title } }) =>
          ({ entity: { id, title: mapCategoryTitle(title) }, count }),
      ),
    };
    payload = { ...payload, categories };
  } catch (err) {
    console.error(err);
  }
  return payload;
}

function* fetchAdvancedFilterOptions() {
  try {
    yield put({ type: fetchAdvancedFilterOptionsAction.request });
    const routeName = yield select(currentRouteNameSelector);
    const { apiFn, requestData } = yield call(prepareOptionsRequest, routeName);
    const { data } = yield callApi(apiFn, requestData);
    let { payload } = yield call(advancedFilterSuccessData, { data });
    payload = renameCategories(payload);
    yield put({
      type: fetchAdvancedFilterOptionsAction.success,
      payload,
    });
  } catch (err) {
    console.error(err);
  }
}

function* onArtistSearchAdvancedFilter(action) {
  const { payload: artistName } = action;
  let { artists: selectedIds = [] } = yield select(getFormValues(FORM_ADVANCED_FILTER));
  selectedIds = selectedIds.map(id => parseInt(id, 10));
  const list = yield select(artistListSelector);
  const selectedArtists = list.filter(v => selectedIds.includes(v.entity.id));
  const foundSelectedIds = selectedArtists.map(v => v.entity.id);
  const filterName = 'artists';
  const requestParams = { filterName, artistName, page: 0, size: 20 };
  try {
    const routeName = yield select(currentRouteNameSelector);
    const { apiFn, requestData } = yield call(prepareOptionsRequest, routeName, requestParams);
    const { data: { artists: { content, ...pagination } } } = yield callApi(apiFn, requestData);
    const newArtists = content.filter(v => !foundSelectedIds.includes(v.entity.id));
    yield put({
      type: fetchAdvancedFilterPaginationAction.success,
      payload: {
        data: { artists: {
          ...pagination,
          content: [
            ...selectedArtists.toJS(),
            ...newArtists,
          ],
        } },
        filterName: requestData.filterName,
      },
    });
  } catch (e) {
    console.error(e);
  }
}
/*
/api/v1/galleries/{id}/filter-apply
/api/v1/alerts/{id}/filter-apply
/api/v1/alerts/sale/filter-apply?saleNumber={saleNumber}
/api/v1/sharing/{id}/filter-apply
*/
function* fetchAdvancedFilterTotal() {
  yield sagaDelay(300);
  const rawData = yield select(getFormValues(FORM_ADVANCED_FILTER));
  let requestParams = {};
  if (rawData) {
    Object.keys(rawData).forEach(key => {
      if ([rawData[key]].join('')) {
        requestParams = { ...requestParams, [key]: rawData[key] };
      }
    });
  }
  try {
    yield put({ type: advancedFilterFetchTotalAction.request });
    const routeName = yield select(currentRouteNameSelector);
    const { apiFn, requestData } = yield call(prepareOptionsRequest, routeName + 'Total', requestParams);
    const { data } = yield callApi(apiFn, requestData);
    yield put({
      type: advancedFilterFetchTotalAction.success,
      payload: data,
    });
  } catch (err) {
    console.error(err);
  }
}

function* mayBeAdvancedFrom(action) {
  if (!action.meta || action.meta.form !== FORM_ADVANCED_FILTER) {
    return;
  }
  yield call(fetchAdvancedFilterTotal);
}

export function* advancedFilterWatcher() {
  yield takeLatest(onArtistSearchAdvancedFilterAction.type, onArtistSearchAdvancedFilter);
  yield takeLatest(fetchAdvancedFilterPaginationAction.type, onAdvancedFilterPagination);
  yield takeLatest('@@redux-form/CHANGE', mayBeAdvancedFrom);
  yield takeLatest('@@redux-form/INITIALIZE', mayBeAdvancedFrom);
  yield takeLatest(advancedFilterFetchTotalAction.type, fetchAdvancedFilterTotal);
}

export function* advancedFilterPageInit() {
  if (yield select(pathnameChanged_sel)) {
    yield fork(fetchAdvancedFilterOptions);
  }
}
