// @flow
import I from 'immutable';
import queryString from 'query-string';
import { isArray } from 'lib/helpers';

type Serializator = {
  [key: string]: (value: any) => [string, any]
}

const toOption = ({ id, title }) => ({ label: title.toString(), value: id.toString() });
export function toGroupedList(list, common) {
  return [
    { label: 'Common', options: common.map(id => list.find(v => v.id === id)).filter(v => !!v).map(toOption).toJS() },
    { label: 'General', options: list.filter(v => !common.includes(v.id)).map(toOption).toJS() },
  ];
}

export function listToMap(list, fieldPath = ['id']) {
  return list.reduce((AC, V) => AC.set(V.getIn(fieldPath, 0), V), new I.Map());
}

export function listToLabelValue(list) {
  return list.sortBy(v => v.get('id')).map(item => new I.Map({ value: item.get('id').toString(), label: item.get('title') }));
}

export function arrayToMap(array, fieldName = 'id') {
  if (!isArray(array)) throw new Error('Not array');
  return array.reduce((AC, V) => AC.set(V[fieldName], new I.Map(V)), new I.Map());
}

export function simpleSerializer(serializator: Serializator) {
  return (data: {[key: string]: any} = {}) => {
    return Object
      .keys(data)
      .reduce((acc, key) =>
          typeof serializator[key] === 'function'
            ? acc.set(...serializator[key](data[key]))
            : acc,
        new I.Map(),
      );
  };
}

export function createSerializer(fieldMappers) {
  const fieldNames = Object.keys(fieldMappers);
  const arToObj = arr => ({ [arr[0]]: arr[1] });
  return obj => fieldNames.reduce((prev, fieldName) => {
    if (obj.hasOwnProperty(fieldName)) {
      return { ...prev, ...arToObj(fieldMappers[fieldName](obj[fieldName], obj)) };
    }
    return prev;
  }, {});
}
/**
 * @param ['fieldName1', ...]
 * @return { fieldName1: v => ['fieldName1', v] }
**/
export function createFieldMappersHashtable(list: Array<string>): { [string]: Function } {
  const fn = fieldName => ({ [fieldName]: value => [fieldName, value] });
  return list.reduce((prev, fieldName) => ({ ...prev, ...fn(fieldName) }), {});
}

export function paramsSerial(params) {
  let q = params;
  if (typeof params === 'string') {
    q = queryString.parse(params, { arrayFormat: 'bracket' });
  }
  return queryString.stringify(q);
}
