import { put, take, select, takeEvery, call } from 'redux-saga/effects';
import { push, goBack } from 'connected-react-router';
import { lnk } from 'lib/routes';
import { prevLocationSelector } from 'domain/router/RouterModel';
import { action } from 'lib/actions';

/**
 * put(redirectToPageAction({ page: lnk('homePage'), id: 'foo' }));
**/
export const redirectToPageAction = action('REDIRECT_TO_PAGE');

function* onRedirectToPage({ payload: { page, ...props } }) {
  yield call(redirectToPage, page, props);
}

export function* redirectToPageWatcher() {
  yield takeEvery(redirectToPageAction.type, onRedirectToPage);
}

export function* redirectToURL(url) {
  if (process.env.NODE_ENV === 'test') {
    yield take(`GO/TO/${url}`);
  }
  yield put(push(url));
}

export function* redirectToPage(page, props) {
  if (process.env.NODE_ENV === 'test') {
    yield take(`GO/TO/${page}`);
  }
  yield put(push(lnk(page, props)));
}

export function* onGoBack({ payload: path = '/' } = {}) {
  if (process.env.NODE_ENV === 'test') {
    yield take('GO/BACK');
  }
  const prevLocation = yield select(prevLocationSelector);
  if (prevLocation) {
    yield put(goBack());
  } else {
    yield put(push(path));
  }
}
