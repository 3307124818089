import React, { useState, useCallback } from 'react';

export const openedState = (Component) => function OpenedState(props) {
  const [externalOpened, setExternalOpened] = useState(false);
  const onChange = useCallback(() => {
    setExternalOpened(!externalOpened);
  }, [externalOpened, setExternalOpened]);
  return (
    <Component {...props} externalOpened={externalOpened} setExternalOpened={onChange} />
  );
};
