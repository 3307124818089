import { sqlDateToRedesign } from 'lib/helpers';

const saleAtSolver = (val, data, name, props) => {
  if (props.expiredTrialUser) return ' ';
  const d = data.getIn(['lot', 'saleAt']);
  if (!d) return null;
  // Mar 27, 2019
  return sqlDateToRedesign(d);
};

export default saleAtSolver;
