import React from 'react';
import PropTypes from 'prop-types';
import I, { Record } from 'immutable';
import cx from 'classnames';
import DataTableCell from './DataTableCell';

function DataTableRow({ cellProps, rowClassName, columns, rowIndex, rowData, classes, modifier, ...props }) {
  return (
    <div
      className={cx(rowClassName, modifier)}
      role="row"
    >
      {
        columns.map((columnDef, key) => (
          <DataTableCell
            key={key}
            {...{ rowData, rowIndex, columnDef, cellProps, classes, ...props }}
          />
        ))
      }
    </div>
  );
}

DataTableRow.propTypes = {
  cellProps: PropTypes.object,
  rowClassName: PropTypes.string.isRequired,
  columns: PropTypes.instanceOf(I.List).isRequired,
  rowIndex: PropTypes.number.isRequired,
  rowData: PropTypes.oneOfType([
    PropTypes.instanceOf(I.Map), PropTypes.instanceOf(Record)]).isRequired,
  classes: PropTypes.object.isRequired,
  modifier: PropTypes.string,
};

export default DataTableRow;
