import { call, put, select, takeEvery } from 'redux-saga/effects';
import * as A from './ArtistAction';
import * as M from './ArtistModel';
import Api, { makeApiGenerator, callApi } from 'domain/api';
import { addNotification } from 'domain/env';
import { FORM_ADD_ART_ITEM, TYPE_CUSTOMER_ARTIST, considerToRemoveAction } from 'domain/const';
import { hidePopupAction } from 'domain/ui/UIActions';
import { dateStructToString } from 'lib/helpers';
import { change } from 'redux-form';
import { selectedArtistData_sel } from 'pages/common/artworkForm/ArtworkFormModel';

// @param { id }
export const ensureArtist_apiGen = makeApiGenerator({
  actionCreator: A.fetchArtistAction,
  api: Api.getArtistById,
  ensure: true,
});

// { term, findOwned: false, ...params }
const searchArtists_apiGen = makeApiGenerator({
  actionCreator: A.searchArtistAction,
  api: Api.searchArtists,
});

export function* onSearchArtist(params = { findOwned: true }, action) {
  const { payload: { term } } = action;
  yield call(searchArtists_apiGen.catchError, { term, ...params });
}

export function* watchSearchArtist(params) {
  yield takeEvery(A.searchArtistAction.type, onSearchArtist, params);
}

function* askRemoveArtist() {
  const artist = yield select(selectedArtistData_sel);
  if (!artist) return;
  yield put(considerToRemoveAction({
    type: TYPE_CUSTOMER_ARTIST,
    name: artist.fullName,
    params: { id: artist.id },
  }));
}

export function* watchOwnArtistActions() {
  yield takeEvery(A.submitArtistForm.type, createOrUpdateArtist);
  yield takeEvery(A.removeArtistAction.type, askRemoveArtist);
  yield takeEvery(A.removeArtistConfirmedAction.type, removeArtistApiGen);
}

// { data }
export const createArtist_apiGen = makeApiGenerator({
  actionCreator: A.createArtistAction,
  api: Api.createArtist,
  returnValue: function* returnValue({ data }) {
    return (yield select(M.artistById)).get(data.id);
  },
});

// { id, data }
const updateArtist_apiGen = makeApiGenerator({
  actionCreator: A.updateArtistAction,
  api: Api.updateArtist,
  onSuccess: function* () {
    yield put(addNotification({ title: 'Artist updated' }));
  },
  returnValue: function* returnValue({ data }) {
    return (yield select(M.artistById)).get(data.id);
  },
});

function* createOrUpdateArtist({ payload: { id, ...data } }) {
  const artist = id ?
    yield call(updateArtist_apiGen.catchError, { id, data }) :
    yield call(createArtist_apiGen.catchError, { data });
  if (artist) {
    yield put(hidePopupAction());
    yield put(
      change(FORM_ADD_ART_ITEM, 'artist', artist.id),
    );
    yield put(
      change(FORM_ADD_ART_ITEM, 'bornIn', dateStructToString(artist.bornIn)),
    );
    yield put(
      change(FORM_ADD_ART_ITEM, 'diedIn', dateStructToString(artist.diedIn)),
    );
    yield put(
      change(FORM_ADD_ART_ITEM, 'residence', artist.residence ? artist.residence.toJS() : []),
    );
  }
}

export function* removeArtistApiGen({ id }) {
  try {
    yield callApi(Api.removeArtist, { id });
    yield put(change(FORM_ADD_ART_ITEM, 'artist', ''));
    yield put({ type: A.removeArtistAction.success, payload: id });
    yield put(addNotification({ title: 'Artist removed' }));
  } catch (err) {
    yield put({
      type: A.removeArtistAction.failure,
      err,
    });
    yield put(addNotification({ title: 'Artist not removed', type: 'error' }));
  }
}
