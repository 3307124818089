export function WaitForFB(cb) {
  let timeout = null;
  const checkAgain = () => {
    timeout = setTimeout(() => {
      if (!global.FB) {
        checkAgain();
      } else {
        timeout = null;
        cb();
      }
    }, 100);
  };
  checkAgain();
  this.destroy = () => {
    if (timeout) {
      clearTimeout(timeout);
    }
  };
  return this;
}

export const mountFBLib = (APP_ID) => {
  (function(d, s, id) {
    var js, fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) return;
    js = d.createElement(s);
    js.id = id;
    js.src = `https://connect.facebook.net/en_GB/sdk.js#xfbml=1&version=v2.12&appId=${APP_ID}`;
    fjs.parentNode.insertBefore(js, fjs);
  }(document, 'script', 'facebook-jssdk'));
};
