import {
  PAGE_HEIGHT,
  PAGE_WIDTH,
  REMOVED_CONTENT_WIDTH,
  REMOVED_SIDEBAR_PADDING,
  REMOVED_SIDEBAR_WIDTH,
  HIDDEN_REPORT_ITEMS,
} from 'pages/highcharts/helpers/PDFConstants';
import { ILLIQUID } from 'domain/const';

export const cutStr = (v, len) => {
  const cutted = (v || '').substr(0, len);
  return cutted === v ? v : `${cutted} [...]`;
};

export const sortPDFComponents = (a, b) => {
  if (a.order === b.order && a.part !== undefined && b.part !== undefined) {
    return a.part - b.part;
  }
  return a.order - b.order;
};

const isEmpty = v => !![v].join('');
export const getKey = item => [item.name, item.part].filter(isEmpty).join('-');

export const itemAvailableByLiquidity = ({ item, PDFState }) => {
  if (PDFState.liquidity !== ILLIQUID) return true;
  if (HIDDEN_REPORT_ITEMS[PDFState.reportType].includes(item.name)) {
    return false;
  }
  if (item.parent && HIDDEN_REPORT_ITEMS[PDFState.reportType].includes(item.parent)) {
    return false;
  }
  return true;
};

// item from PDFStore, position from placeItemsToPages
export const itemSVGProps = ({ position, item }) => {
  const scaleK = Math.min(1, REMOVED_CONTENT_WIDTH / (position.width || PAGE_WIDTH));
  const removedTransform = (item.removable && item.hidden) ? {
    transform: [
    `translate(${REMOVED_SIDEBAR_PADDING} ${REMOVED_SIDEBAR_PADDING})`,
    `scale(${Math.min(1, scaleK)})`,
    ].join(' '),
  } : undefined;
  const viewBox = {
    width: item.hidden ? REMOVED_SIDEBAR_WIDTH : (position.width || PAGE_WIDTH),
    height: item.hidden ? (position.height || PAGE_HEIGHT) * scaleK + REMOVED_SIDEBAR_PADDING * 2 : (position.height || PAGE_HEIGHT),
  };
  return { scaleK, removedTransform, viewBox };
};
