import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'components/form/button/Button';
import { PDF_MODAL } from 'theme/zIndex';
import {
  PAGE_WIDTH,
  REMOVED_SIDEBAR_WIDTH,
  LEFT_SIDEBAR_WIDTH,
  PDFPREVIEW_PADDING,
} from 'pages/highcharts/helpers/PDFConstants';

import injectSheet from 'lib/sheet';

const sheet = {
  PDFModal: {
    width: '100vw',
    minWidth: PAGE_WIDTH + REMOVED_SIDEBAR_WIDTH + LEFT_SIDEBAR_WIDTH + PDFPREVIEW_PADDING * 2,
    position: 'fixed',
    top: 60,
    left: 0,
    height: 'calc(100vh - 60px)',
    overflow: 'hidden',
    zIndex: PDF_MODAL,
  },
  overlay: {},
  contentWrapper: {
    backgroundColor: '#3D3D3D',
    height: '100%',
    overflow: 'hidden',
    overflowY: 'scroll',
  },
  header: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: 28,
  },
};

class PDFModal extends React.Component {

  static propTypes = {
    //
    onClose: PropTypes.func.isRequired,
    classes: PropTypes.shape({
      contentWrapper: PropTypes.string.isRequired,
      header: PropTypes.string.isRequired,
      PDFModal: PropTypes.string.isRequired,
      overlay: PropTypes.string.isRequired,
    }).isRequired,
    children: PropTypes.node,
    scrollRef: PropTypes.func,
  };

  body = null;

  componentDidMount() {
    if (process.env.NODE_ENV === 'test') return;
    this.body = document.getElementById('root');
    this.body.style.overflow = 'hidden';
  }

  componentWillUnmount() {
    if (process.env.NODE_ENV === 'test') return;
    this.body.style.overflow = '';
  }

  onClose = () => {
    this.props.onClose();
  }

  render() {
    const { classes, children, scrollRef } = this.props;
    return (
      <div className={classes.PDFModal}>
        <div className={classes.overlay} onClick={this.onClose} />
        <div className={classes.contentWrapper} ref={scrollRef}>
          <div className={classes.header}>
            <Button modifier="cancel PDFModal" onClick={this.onClose}>Cancel</Button>
          </div>
          {children}
        </div>
      </div>
    );
  }

}

PDFModal = injectSheet(sheet)(PDFModal);

export { PDFModal };
