import close from './close.svg';
import { button } from 'theme/button';
import { ORANGE_COLOR, DEFAULT_BUTTON_COLOR, DEFAULT_FONTFAMILY } from 'theme/theme';

export const sheet = {
  container: {
    padding: [30, 20, 60, 40],
    position: 'relative',
    backgroundColor: '#f7f7f7',
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '20px',
  },
  textContainer: {
    maxWidth: 980,
    height: 720,
    margin: [0, 0, 20, 0],
    padding: [0, 10, 0],
    overflowY: 'scroll',
  },
  title: {
    color: '#000000',
    font: `500 28px/1.45em ${DEFAULT_FONTFAMILY}`,
    letterSpacing: '.18em',
    textTransform: 'uppercase',
  },
  textTitle: {
    font: `500 14px/1.5em ${DEFAULT_FONTFAMILY}`,
    letterSpacing: '.1em',
    textTransform: 'uppercase',
    color: '#000000',
    margin: [20, 0, 0, 0],
  },
  subTitle: {
    font: `500 14px/1.5em ${DEFAULT_FONTFAMILY}`,
    letterSpacing: '.1em',
    textTransform: 'uppercase',
    color: '#000000',
    fontStyle: 'italic',
    margin: [20, 0, 0, 0],
  },
  text: {
    margin: [10, 0, 0, 0],
  },
  list: {
  },
  innerList: {
    margin: [0, 0, 0, 20],
  },
  listItem: {
    margin: [10, 0],
    fontSize: 14,
    fontWeight: 400,
  },
  bold: {
    fontWeight: 600,
  },
  link: {
    color: ORANGE_COLOR,
    '&.Popup-SecondPrivacy': {
      fontWeight: 500,
    },
  },
  footer: {
    position: 'absolute',
    width: '100%',
    height: 70,
    bottom: 0,
    left: 0,
    borderTop: `2px solid ${DEFAULT_BUTTON_COLOR}`,
    background: '#FFF',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingRight: 40,
  },
  button,
  // submit: {
  //   ...Buttons.add,
  //   marginLeft: 10,
  // },
  // cancel: {
  //   ...Buttons.cancel,
  //   marginLeft: 10,
  // },
  close: {
    position: 'absolute',
    top: 25,
    right: 55,
    width: 24,
    height: 24,
    background: `url("${close}") scroll no-repeat center`,
    zIndex: 3,
    marginLeft: 'auto',
    outline: 'none',
  },
  textUnderlined: {
    textDecoration: 'underline',
  },
};
