import { put, takeEvery, select, call } from 'redux-saga/effects';
import I from 'immutable';
import moment from 'moment';
import { lnk } from 'lib/routes';
import translate from 'lib/translate';
import { reduceCallFrequency } from 'lib/saga-helpers';
import {
  getShareAction,
  getSharedArtworkAction,
  getSharedArtworkListAction,
  getSharedByMeAction,
  getSharedByMeAppendPageAction,
  getSharedGalleriesAction,
  getSharedWithMeAction,
  getSharedWithMeAppendPageAction,
  removeEmailsFromShareAction,
  removeMeFromShareAction,
  removeShareAction,
  sharedArtworkAppendPageAction,
  sharedArtworkViewedAction,
  shareShowEditPopupAction,
  showSharePopupAction,
} from 'domain/shares/ShareActions';
import { getCurrentShareSelector } from 'domain/shares/ShareModel';
import { setLinkAction, addPopupAction, err404Action } from 'domain/ui/UIActions';
import {
  SHARE_TYPE_CONSIDERATION,
  SHARE_TYPE_ARTWORK,
  SHARE_TYPE_OWN_ARTWORK,
  SHARE_TYPE_GALLERY,
  SHARE_TYPE_ALERT,
  SHARE_DIRECTION_TO_ME,
  SHARE_DIRECTION_BY_ME,
  SHARE_POPUP,
} from 'domain/const';
import Api, { callApi, makeApiGenerator } from 'domain/api';
import { addNotification, userProfileEmail } from 'domain/env';
import { reloadPage } from 'domain/router/saga';
import { query_sel } from 'domain/router/RouterModel';

// { ...params, shareId, page }
export const getSharedArtworkList_apiGen = makeApiGenerator({
  actionCreator: getSharedArtworkListAction,
  api: Api.getSharedArtworkList,
});

// { ...params, shareId, page }
export const sharedArtworkListAppendPage_apiGen = reduceCallFrequency(makeApiGenerator({
  actionCreator: sharedArtworkAppendPageAction,
  api: Api.getSharedArtworkList,
}), 2100);

function setLotFromSalesHistory(data) {
  if (!data || !data.salesHistory) return data;
  let lot = data.lot || undefined;
  if (data.upcomingSale && data.upcomingSale.auction) {
    lot = { ...data.upcomingSale, saleAt: data.upcomingSale.soldDate };
  }
  for (let iLot of data.salesHistory) {
    if (iLot.auction && (!lot || moment(iLot.soldDate, 'YYYY-MM-DD').isAfter(lot.soldDate))) {
      lot = { ...iLot, saleAt: iLot.soldDate };
    }
  }
  if (lot) {
    return { ...data, lot };
  }
  return data;
}
/**
 * @param { shareId, artworkId }
**/
export const getSharedArtwork_apiGen = makeApiGenerator({
  actionCreator: getSharedArtworkAction,
  api: Api.getSharedArtwork,
  getSuccessData: ({ data }) => ({ payload: setLotFromSalesHistory(data) }),
});

// { shareId, page }
export const getSharedGalleries_apiGen = makeApiGenerator({
  actionCreator: getSharedGalleriesAction,
  api: Api.getSharedGalleries,
});

// { id }
export const getShare_apiGen = makeApiGenerator({
  actionCreator: getShareAction,
  api: Api.getShare,
});

export const getSharedWithMeList_apiGen = makeApiGenerator({
  actionCreator: getSharedWithMeAction,
  api: Api.getSharedWithMeList,
});
export const getSharedWithMeListAppendPage_apiGen = reduceCallFrequency(makeApiGenerator({
  actionCreator: getSharedWithMeAppendPageAction,
  api: Api.getSharedWithMeList,
}), 2100);

// { page: 0, size: 5, ...queryParams }
export const getSharedByMeList_apiGen = makeApiGenerator({
  actionCreator: getSharedByMeAction,
  api: Api.getSharedByMeList,
});
export const getSharedByMeListAppendPage_apiGen = reduceCallFrequency(makeApiGenerator({
  actionCreator: getSharedByMeAppendPageAction,
  api: Api.getSharedByMeList,
}), 2100);

export function* removeEmailsFromShare({ shareId, emails }) {
  try {
    const { data } = yield callApi(Api.removeEmailsFromShare, { shareId, emails });
    if (data) {
      yield put({
        type: removeEmailsFromShareAction.success,
        payload: data,
      });
    } else {
      yield put({
        type: removeShareAction.success,
        payload: { id: parseInt(shareId, 10) },
      });
    }
    yield put(addNotification({ title: 'Email was removed' }));
  } catch (err) {
    console.error(err);
    yield put({
      type: removeEmailsFromShareAction.failure,
      err,
    });
  }
}

// { shareId }
export const removeShare_apiGen = makeApiGenerator({
  actionCreator: removeShareAction,
  api: Api.removeShare,
  muteSuccess: true,
  onSuccess: function* onSuccess({ more: { noReload } = {} }) {
    if (!noReload) {
      yield call(reloadPage);
    }
    yield put(addNotification({ title: 'Share was removed' }));
  },
});

// { shareId }
const removeMeFromShare_apiGen = makeApiGenerator({
  actionCreator: removeMeFromShareAction,
  api: Api.removeMeFromShare,
  muteSuccess: true,
  onSuccess: function* onSuccess() {
    yield call(reloadPage);
    yield put(addNotification({ title: 'Your email was removed from share list' }));
  },
});

export function* onRemoveShare(action) {
  yield call(removeShare_apiGen.catchError, { shareId: action.payload.id });
}
export function* onRemoveMeFromShare(action) {
  yield call(removeMeFromShare_apiGen.catchError, { shareId: action.payload.id });
}
export function* watchRemoveShare() {
  yield takeEvery(removeShareAction.type, onRemoveShare);
  yield takeEvery(removeMeFromShareAction.type, onRemoveMeFromShare);
}

function* getShareProperties_apiGen(params, moreData) {
  try {
    const { type } = params;
    const payload = { ...params, type: type.toUpperCase() };
    const { data } =  yield callApi(Api.getShareProperties, { data: payload });
    if (data.prop.valuation) delete data.prop.valuation;
    const checkboxMap = I.Map(data.prop);
    const hasDrafts = data.draftExist;
    const hasPrivate = data.privateExist;
    const hasNoDrafts = !data.allDrafts;
    return { hasDrafts, hasPrivate, hasNoDrafts, checkboxMap, moreData };
  } catch (err) {
    return null;
  }
}
/**
 * alertId - for alert works
 * share - (optional) editing share
**/
function* onShowSharePopup({ payload: { shareType, ids, share, alertId } }) {
  let shareProperties;
  switch (shareType) {
    case SHARE_TYPE_CONSIDERATION:
    case SHARE_TYPE_OWN_ARTWORK:
    case SHARE_TYPE_GALLERY:
      shareProperties = yield call(getShareProperties_apiGen, { ids: ids.toJS(), type: shareType });
      break;
    case SHARE_TYPE_ALERT:
    case SHARE_TYPE_ARTWORK:
      // we can't create alert from private artworks, so no private alerts.
      // here'is also no alerts for draft AW.
      shareProperties = {
        hasPrivate: false,
        hasDrafts: false,
        hasNoDrafts: ids.size > 0,
        checkboxMap: I.Map(),
        shareType,
        moreData: shareType === SHARE_TYPE_ARTWORK ? undefined : { alertId },
      };
      break;
    default:
      break;
  }
  if (!shareProperties) {
    yield put(addNotification({ title: 'Can`t get sharing properties' }));
    return;
  }
  yield put(addPopupAction({
    name: SHARE_POPUP,
    params: { shareType, ids, ...shareProperties, share },
  }));
}

export function* watchShowSharePopup() {
  yield takeEvery(showSharePopupAction.type, onShowSharePopup);
}

export function* setGalleryLink(params) {
  yield put(setLinkAction({ component: 'collectionListPage', route: 'sharedGallery', params }));
}

export function* getShareDirectionBreadcrumb(share) {
  const sessionEmail = yield select(userProfileEmail);
  const { prevPage } = yield select(query_sel);
  const shareDirection = (sessionEmail === share.email && prevPage !== 'sharedWithMePage') ?
    SHARE_DIRECTION_BY_ME :
    SHARE_DIRECTION_TO_ME;
  const shareRouteName = shareDirection === SHARE_DIRECTION_BY_ME ? 'myShares' : 'sharedWithMe';
  return {
    entity: translate(`sharePagesBreadcrumbs.sharingDirection.${shareDirection}`),
    link: lnk(shareRouteName),
  };
}

function* onShowShareEditPopup(action) {
  const { id } = action.payload;
  yield call(getShare_apiGen.catchError, { id });
  const share = yield select(getCurrentShareSelector);
  if (!share) {
    yield put(err404Action(true));
    return;
  }
  let payload = {
    shareType: share.type,
    ids: share.ids,
    share,
  };
  if (share.galleryId) {
    payload = { ...payload, galleryId: share.galleryId };
  }
  if (share.alertId) {
    payload = { ...payload, alertId: share.alertId };
  }
  yield call(onShowSharePopup, { payload });
}

export function* watchEditShare() {
  yield takeEvery(shareShowEditPopupAction.type, onShowShareEditPopup);
}

// { shareId, ids }
export const sharedArtworkViewed_apiGen = makeApiGenerator({
  actionCreator: sharedArtworkViewedAction,
  api: Api.sharedArtworkViewed,
});
