import React from 'react';

const solver = v => v === undefined ? null : v;

const dict = (id, data, name, props) => props[name].getIn([id, 'title'], null);
const dictList = (ids, data, name, props) => ids.map(id => props[name].getIn([id, 'title'])).join(', ');
const separator = () => <span></span>;

const solvers = {
  solver,
  dict,
  dictList,
  separator,
};

export default solvers;

export {
  solvers,
  solver,
  dict,
  dictList,
  separator,
};
