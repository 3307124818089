import React from 'react';
import PropTypes from 'prop-types';
import I from 'immutable';
import cx from 'classnames';
// components
import DataTableTh from './DataTableTh';
import DataTableRow from './DataTableRow';

class DataTable extends React.PureComponent {
  static propTypes = {
    columns: PropTypes.instanceOf(I.List).isRequired,
    items: PropTypes.instanceOf(I.List).isRequired,
    classes: PropTypes.shape({
      trEven: PropTypes.string.isRequired,
      trOdd: PropTypes.string.isRequired,
      th: PropTypes.string,
      tr: PropTypes.string.isRequired,
      td: PropTypes.string.isRequired,
      DataTable: PropTypes.string.isRequired,
      thAsc: PropTypes.string,
      thDesc: PropTypes.string,
      trh: PropTypes.string,
    }).isRequired,
    getRowRenderer: PropTypes.func,
    cellProps: PropTypes.object,
    orderTableByColumn: PropTypes.func,
    sortableTableParams: PropTypes.instanceOf(I.Map),
    showColumnsTitles: PropTypes.bool,
  };

  static defaultProps = {
    showColumnsTitles: false,
  };

  renderRow = (rowData, rowIndex) => {
    const { getRowRenderer, classes, columns, cellProps, items, ...props } = this.props;
    const rowClassName = cx((rowIndex % 2) ? classes.trEven : classes.trOdd, classes.tr);
    const RowComponent = getRowRenderer ? getRowRenderer({
        rowData,
        rowIndex: rowIndex,
      }) : DataTableRow;
    return (<RowComponent
      key={rowIndex}
      {...{
        ...props,
        classes,
        cellProps,
        rowClassName,
        columns,
        rowIndex,
        rowData,
        rowsCount: items.size,
      }}
    />);
  }

  isOrdered(direction, columnDef) {
    const { sortableTableParams } = this.props;
    const orderedBy = sortableTableParams ? sortableTableParams.get('columnName') : null;
    return orderedBy !== null &&
      columnDef.get('name') === orderedBy &&
      (
        (direction === 'ASC') ?
          sortableTableParams.get('orderAsc') :
          !sortableTableParams.get('orderAsc')
      );
  }

  render() {
    const { columns, items, classes, cellProps, orderTableByColumn, showColumnsTitles } = this.props;
    return (
      <div className={classes.DataTable}>
        {
          showColumnsTitles &&
            <div className={cx(classes.tr, classes.trh)}>
              {
                columns.map((columnDef, key) => (
                  <DataTableTh
                    key={key}
                    {...{ columnDef, cellProps, orderTableByColumn, classes }}
                    orderedAsc={this.isOrdered('ASC', columnDef)}
                    orderedDesc={this.isOrdered('DESC', columnDef)}
                  />
                ))
              }
            </div>
        }
        {
          items.isEmpty() ?
            <div className={classes.tr}>
              <div
                className={`${classes.td} tdEmpty`}
                children="No records found"
              />
            </div>
          :
            items.map(this.renderRow)
        }
      </div>
    );
  }
}

export default DataTable;
