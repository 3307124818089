import { DIVIDER_COLOR } from 'theme/theme';

export const PAGE_SECTION = {
  '&.divider': {
    borderTop: `1px solid ${DIVIDER_COLOR}`,
    marginTop: 30,
    paddingTop: 30,
  },
  '&.UpgradeSubscriptionSection.AccountPage': {
    marginTop: 50,
    paddingTop: 30,
    borderTop: `1px solid ${DIVIDER_COLOR}`,
  },
  '&.error404Page': {
  },
  '&.collectionItemPage,&.artworkPage,&.sharedArtWorkPage': {
    display: 'flex',
    position: 'relative',
    '@media screen and (max-width: 900px)': {
      display: 'block',
    },
  },
  '&.sharedArtWorkPage': {
    paddingTop: 30,
    '@media screen and (max-width: 900px)': {
      paddingTop: 20,
    },
  },
};
