import { asyncAction } from 'lib/actions';

export const DEFAULT_PAGE_SIZE = 16;

export const removeAction = asyncAction('REMOVE');
export const considerToRemoveAction = asyncAction('CONSIDER_TO_REMOVE');
export const restoreAction = asyncAction('RESTORE');

export const SHARE_TYPE_ALERT = 'alert';
export const SHARE_TYPE_OWN_ARTWORK = 'art_work';
export const SHARE_TYPE_ARTWORK = 'art_work_stack';
export const SHARE_TYPE_GALLERY = 'gallery';
export const SHARE_TYPE_CONSIDERATION = 'consideration';

export const SHARE_PROPERTIES_TYPE_GALLERY = 'GALLERY';
export const SHARE_PROPERTIES_TYPE_ARTWORK = 'ART_WORK';
export const SHARE_PROPERTIES_TYPE_ARTIST = 'ARTIST';
export const SHARE_PROPERTIES_TYPE_ALERT = 'ALERT';
export const SHARE_PROPERTIES_TYPE_CONSIDERATION = 'CONSIDERATION';
export const SHARE_PROPERTIES_TYPE_CONSIDERATION_ARTIST = 'CONSIDERATION_ARTIST';
export const SHARE_PROPERTIES_TYPE = {
  GALLERY: SHARE_PROPERTIES_TYPE_GALLERY,
  ARTWORK: SHARE_PROPERTIES_TYPE_ARTWORK,
  ARTIST: SHARE_PROPERTIES_TYPE_ARTIST,
  ALERT: SHARE_PROPERTIES_TYPE_ALERT,
  CONSIDERATION: SHARE_PROPERTIES_TYPE_CONSIDERATION,
  CONSIDERATION_ARTIST: SHARE_PROPERTIES_TYPE_CONSIDERATION_ARTIST,
};

export const SHARE_DIRECTION_TO_ME = 'shared-to-me';
export const SHARE_DIRECTION_BY_ME = 'shared-by-me';

export const ENTITY_NAME_ARTWORK = 'art_work';
export const ENTITY_NAME_GALLERY = 'gallery';
export const ENTITY_NAME_CONSIDERATION = 'consideration';
export const ENTITY_NAME_ALERT = 'alert';
export const ENTITY_NAME_SHARE = 'share';

export const TYPE_ARTWORK = 'art_work';
export const TYPE_ARTWORK_SINGLE = 'art_work_single';
export const TYPE_GALLERY = 'gallery';
export const TYPE_CONSIDERATION_ARTWORK = 'consideration_artwork';
export const TYPE_ALERT = 'alert';
export const TYPE_ALERT_REMOVE = 'alert-remove-created';
export const TYPE_ALERT_ARTWORK = 'alert_artwork';
export const TYPE_CUSTOMER_ARTIST = 'TYPE_CUSTOMER_ARTIST';

// subscription types sorted by price ASC
export const SUBSCRIPTION_TYPE_TRIAL = 'SUBSCRIPTION_TYPE_TRIAL';
export const SUBSCRIPTION_TYPE_MEMBER = 'SUBSCRIPTION_TYPE_MEMBER';
export const SUBSCRIPTION_TYPE_COLLECTOR = 'SUBSCRIPTION_TYPE_COLLECTOR';
export const SUBSCRIPTION_TYPE_PROFESSIONAL = 'SUBSCRIPTION_TYPE_PROFESSIONAL';
export const SUBSCRIPTION_TYPE_CUSTOM = 'SUBSCRIPTION_TYPE_CUSTOM';
export const SUBSCRIPTION_TYPE_PREMIUM = 'SUBSCRIPTION_TYPE_PREMIUM';
export const SUBSCRIPTION_TYPE_ENTERPRISE = 'SUBSCRIPTION_TYPE_ENTERPRISE';
// option on buy rtv sidebar
export const SINGLE_RTV = 'SINGLE_RTV';
export const DEFAULT_RTV_PRICE = 50;
export const SUBSCRIPTION_ORDER = {
  SUBSCRIPTION_TYPE_TRIAL: 0,
  SUBSCRIPTION_TYPE_MEMBER: 1,
  SUBSCRIPTION_TYPE_COLLECTOR: 2,
  SUBSCRIPTION_TYPE_PROFESSIONAL: 3,
  SUBSCRIPTION_TYPE_CUSTOM: 4,
  SUBSCRIPTION_TYPE_PREMIUM: 5,
  SUBSCRIPTION_TYPE_ENTERPRISE: 6,
  SINGLE_RTV: 7,
};

export const RENAME_GALLERY_POPUP = 'renameGalery';
export const REMOVE_GALLERY_POPUP = 'removeGalery';
export const CREATE_GALLERY_POPUP = 'createGalery';
export const ADD_TO_GALLERY_POPUP = 'addToGallery';
export const CROP_IMAGE_POPUP = 'CROP_IMAGE_POPUP';
export const RE_UPLOAD_IMAGE_POPUP = 'RE_UPLOAD_IMAGE_POPUP';

export const EDIT_AUTHOR_POPUP = 'edit-author';
export const CREATE_AUTHOR_POPUP = 'create-author';
export const CONFIRM_POPUP = 'confirm-popup';
export const SHARE_POPUP = 'share';
export const AUTH_ASK_EMAIL_POPUP = 'auth-ask-email';
export const AUTH_ACCEPT_PRIVACY_AND_USAGE_TERMS_POPUP = 'auth-accept-privacy-and-usage-terms';
export const SELECT_COMPARABLES_POPUP = 'SELECT_COMPARABLES_POPUP';

export const MAX_SHARE_COMMENT_LENGTH = 67;

export const FORM_ADD_ART_ITEM = 'addArtItem';
export const FORM_ADD_ART_ITEM_PICTURES = 'picture';
export const FORM_ADD_ALERT_ITEM = 'addAlertItem';
export const FORM_ADD_AUTHOR = 'add-author-form';
export const FORM_ACCOUNT = 'account';
export const FORM_CURRENCY = 'currency';
export const FORM_BILLING = 'billing';
export const FORM_AUTH_EMAIL = 'auth-email';
export const FORM_AUTH_PRIVACY_AND_TERMS = 'auth-privacy-and-terms';
export const FORM_SHARING = 'sharing';
export const FORM_SIGN_UP = 'signup';
export const FORM_LOGIN = 'login';
export const FORM_CHANGE_PASSWORD = 'password';
export const FORM_CREATE_PASSWORD = 'create-password-form';
export const FORM_BUY_RTV = 'buy_rtv';
export const FORM_BUY_ANALYTICS = 'buy_analytics';
export const FORM_COUPON = 'coupon';
export const FORM_CARD = 'stripe_card';
export const FORM_ADVANCED_FILTER = 'advanced-filter';
export const FORM_COMPARABLE_FILTER = 'FORM_COMPARABLE_FILTER';

export const SIGNUP_TERMS_POPUP = 'terms';
export const SIGNUP_PRIVACY_POPUP = 'privacy';

export const LINK_NO_AUTHOR_ID_PARAM = 'untitled';


export const RESTORE_DELETION_PERIOD = 30000;

export const CREATE_GALLERY_ENTITY_ART_WORK = 'ART_WORK';
export const SN_AUTH_WINDOW = 'SN_AUTH_WINDOW';

export const RTV_AVAILABLE = 'AVAILABLE';

// actions
export const ROUTER_PREVENT_LOCATION_CHANGE = 'router/PREVENT_LOCATION_CHANGE';
export const ROUTER_ALLOW_LOCATION_CHANGE = 'router/ALLOW_LOCATION_CHANGE';
export const ROUTER_LOCATION_CHANGE = '@@router/LOCATION_CHANGE';

export const ROUTER = {
  PREVENT_LOCATION_CHANGE: ROUTER_PREVENT_LOCATION_CHANGE,
  ALLOW_LOCATION_CHANGE: ROUTER_ALLOW_LOCATION_CHANGE,
  LOCATION_CHANGE: ROUTER_LOCATION_CHANGE,
};

// amazon urls
export const IMAGES_STORAGE_URL = 'https://artbnk-art-images.s3.amazonaws.com';
export const RESIZED_IMAGES_STORAGE_URL = 'https://artbnk-art-resized-images.s3.amazonaws.com';
export const ARTIST_BANNERS_URL = 'https://artbnk-artist-ad-banners.s3.amazonaws.com';

// reports
export const ILLIQUID = 'illiquid';
export const LIQUID = 'liquid';
export const LIQUIDITY_TYPES = [ILLIQUID, LIQUID, 'active'];

// enterprise url
export const ENTERPRISE_URL = 'https://enterprise.artbnk.com';

// contact us url
export const CONTACT_US_URL = 'https://www.artbnk.com/contact-us';
