import Api, { makeApiGenerator } from 'domain/api';
import { reduceCallFrequency } from 'lib/saga-helpers';
import { fetchArtworkListAction, artworkAppendPageAction } from 'domain/artwork/ArtworkActions';

export const fetchArtworkList_apiGen = makeApiGenerator({
  api: Api.getArtworkList,
  actionCreator: fetchArtworkListAction,
});

export const artworkListAppendPage_apiGen = reduceCallFrequency(makeApiGenerator({
  api: Api.getArtworkList,
  actionCreator: artworkAppendPageAction,
}), 2100);


