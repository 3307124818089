/* eslint no-unused-vars: warn */
import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'cpcs-reconnect';
import moment from 'moment';

import { soldPriceStr } from 'pages/common/artworkView/solvers/soldPriceSolver';
import { sizeSolver } from 'pages/common/artworkView/solvers/sizeSolver';
import { categoryById_sel } from 'domain/category/CategoryModel';
import { surfacesById_sel } from 'domain/surface/SurfaceModel';
import { substratesById_sel } from 'domain/substrate/SubstrateModel';
import { formatEstimationValue } from 'pages/common/artworkView/solvers/estimateSolver';
import { LogoHeader } from 'pages/highcharts/PDFComponents/Logo';

import { splitTextToLines, Text } from 'pages/highcharts/helpers/Text';
import { cutStr } from 'pages/highcharts/helpers/helpers';
import { connectEventsContainer } from 'pages/highcharts/helpers/EventsContext';
import { RefSize } from 'pages/highcharts/helpers/refSize';
import { DEFAULT_FONTFAMILY, EVENT_GET_PAGE_COMPONENT, PAGE_HEIGHT } from 'pages/highcharts/helpers/PDFConstants';
import { cutToFitScalable } from 'pages/highcharts/helpers/imgToDataURL';

const maxWidth = 143;
const maxHeight = 179;
const INFO_TOP_MARGIN = 43;
const FOOTER_HEIGHT = 40;

class AOInfoSidebar extends RefSize {
  static propTypes = {
    PDFState: PropTypes.shape({
      // contentSections: PropTypes.instanceOf(I.Collection),
    }).isRequired,
  };

  state = {
    img: null,
  };

  componentDidMount() {
    const { PDFEvents } = this.props;
    const ret = PDFEvents.dispatch(EVENT_GET_PAGE_COMPONENT, { id: 'AOImage' });
    const { item: { img } } = ret || { item: {} };
    if (img) {
      this.setImgBlob(img);
    }
    super.componentDidMount();
  }

  setImgBlob = async (img) => {
    const state = {
      img,
      // src, width, height
      ...(await cutToFitScalable({ img, maxWidth, maxHeight, cut: false, vAlign: 'top' })),
    };
    this.safeSetState(state);
  }

  dict = (v, list) => list.getIn([v, 'title']) || list.getIn([`${v}`, 'title']) || 'undefined';

  dicts = (vs = [], list) => vs.map(v => this.dict(v, list)).filter(Boolean).join(', ');

  render() {
    const { artwork, category, surface, substrate } = this.props;
    const categoryById = category;
    let width = maxWidth;
    let height = maxHeight;
    if (this.state.img) {
      width = this.state.width;
      height = this.state.height;
    }
    let top = 0;
    const topAdd = (v) => ((top += Math.max(0, v)) && null);
    const description = [
      this.dict(artwork.category, categoryById),
      this.dicts(artwork.surfaces, surface),
      this.dicts(artwork.substrates, substrate),
      sizeSolver(null, artwork, '', { category }),
    ].filter(Boolean).join(', ');
    const titleLines = splitTextToLines(artwork.title, 20, true);
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="270"
        height={PAGE_HEIGHT - FOOTER_HEIGHT}
        ref={this.ref}
        data-top="0"
        data-left="0"
      >
        <style>
          {`
          .AOInfoSidebar_artistName { font: 500 12px ${DEFAULT_FONTFAMILY}; fill: #333; }
          .AOInfoSidebar_artworkName { font: 700 12px ${DEFAULT_FONTFAMILY}; fill: #333; }
          .AOInfoSidebar_auctionLabel { font: 400 14px ${DEFAULT_FONTFAMILY}; fill: #333; }
          .AOInfoSidebar_auctionValue { font: 500 12px ${DEFAULT_FONTFAMILY}; fill: #333; }
          .AOInfoSidebar_label { font: 400 12px ${DEFAULT_FONTFAMILY}; fill: #333; }
          .AOInfoSidebar_value { font: 500 12px ${DEFAULT_FONTFAMILY}; fill: #333; }
          `}
        </style>
        <rect
          x="0"
          y="0"
          width="199"
          height={PAGE_HEIGHT - FOOTER_HEIGHT}
          rx="0"
          ry="0"
          fill="#F7F7F8"
        />
        <LogoHeader fill="#304C95" />
        <g transform="translate(24, 94)">
          {
            this.state.img &&
              <image href={this.state.src} height={height} width={width} />
          }
          <g transform={`translate(0, ${height + INFO_TOP_MARGIN})`}>
            {/** /}
            <text y="25" className="AOInfoSidebar_">
            <circle cx="120" cy="20" r="16" fill={this.contentSectionGet('bg', v.name) || theme.mainColor} />
            <path d="M120 35 v30" stroke={this.contentSectionGet('bg', v.name) || theme.mainColor} strokeWidth="2" />
            {/**/}
            <text y={top += 10} className="AOInfoSidebar_artistName">
              {artwork.artist.fullName}
            </text>
            <Text
              top={top += 25}
              className="AOInfoSidebar_artworkName"
              lines={titleLines}
              lineHeight="20"
            />
            {topAdd(25 * (titleLines.length - 1))}
            <text y={top += 25} className="AOInfoSidebar_auctionLabel">
              Auction House:
            </text>
            <text y={top += 15} className="AOInfoSidebar_auctionValue">
              {cutStr(artwork.getIn(['lot', 'seller'], 'Undefined'), 20)}
            </text>
            <text y={top += 25} className="AOInfoSidebar_label">
              Sale Date
            </text>
            <text y={top += 15} className="AOInfoSidebar_value">
              {moment(artwork.getIn(['lot', 'saleAt'])).format('MMM DD, YYYY')}
            </text>
            <text y={top += 25} className="AOInfoSidebar_label">
              Sold Price:
            </text>
            <text y={top += 15} className="AOInfoSidebar_value">
              {soldPriceStr(artwork)}
            </text>
            {
              artwork.getIn(['lot', 'lotNumber']) &&
                <React.Fragment>
                  <text y={top += 25} className="AOInfoSidebar_label">
                    Lot:
                  </text>
                  <text y={top += 15} className="AOInfoSidebar_value">
                    {artwork.getIn(['lot', 'lotNumber'])}
                  </text>
                </React.Fragment>
            }
            <text y={top += 25} className="AOInfoSidebar_label">
              Estimated Price:
            </text>
            <text y={top += 15} className="AOInfoSidebar_value">
              {formatEstimationValue(artwork)}
            </text>
            <text y={top += 25} className="AOInfoSidebar_label">
              Description:
            </text>
            <Text
              top={top += 15}
              className="AOInfoSidebar_value"
              text={description}
              length="30"
              lineHeight="15"
            />
          </g>
        </g>
      </svg>
    );
  }
}

AOInfoSidebar = compose(
  connectEventsContainer,
  connect({
    category: categoryById_sel,
    surface: surfacesById_sel,
    substrate: substratesById_sel,
  }),
)(AOInfoSidebar);

export { AOInfoSidebar };
