import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'cpcs-reconnect';
import { connect as reduxConnect } from 'react-redux';
import cx from 'classnames';
import I from 'immutable';
import { Field, reduxForm, getFormValues } from 'redux-form';
import { debounce } from 'lib/envGlobals';
// components
import {
  StripeProvider,
  Elements,
  injectStripe,
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
} from 'react-stripe-elements';
import FormRowField from 'pages/common/newPage/form/elements/FormRowField';
import {
  onFieldChangeAction,
  onFieldBlurAction,
  resetCardFormAction,
  setStripeCardTokenAction,
} from 'pages/billingPage/form/cardFormActions';
import {
  isFormValidSel,
  fieldsSel,
  cardTokenInvalid_sel,
} from 'pages/billingPage/form/cardFormModel';

import {
  stripeApiTokenSelector,
  cardNumberSelector,
  cardFormOpened_sel,
  user_sel,
} from 'domain/env/EnvModel';
import { setCardFormOpenedAction } from 'domain/env/EnvActions';
// utils
import { rules } from 'components/form/validation';
import { FORM_CARD } from 'domain/const';
// styles
import injectSheet from 'lib/sheet';
import styles, { StripeStyle } from './sheet.js';

function ErrorMessage({ className, field }) {
  if (!field) return 'NO_FIELD';
  const { complete, error, blur, label, empty } = field;
  if (!blur) return null;
  let message;
  if (error) {
    message = error.message;
  } else if (!complete && empty) {
    message = `Please enter a valid ${label}`;
  }
  if (!message) return null;
  return (
    <div
      className={className}
      children={message}
    />
  );
}
ErrorMessage.propTypes = {
  className: PropTypes.string.isRequired,
  field: PropTypes.shape({
    error: PropTypes.shape({
      message: PropTypes.string.isRequired,
    }),
    blur: PropTypes.bool,
    empty: PropTypes.bool,
    label: PropTypes.string.isRequired,
    complete: PropTypes.bool,
  }),
};

let avoidTokenReInitDuplication = false;

class CardForm extends React.Component {
  static propTypes = {
    setStripeCardToken: PropTypes.func.isRequired,
    cardNumber: PropTypes.string,
    onSubmit: PropTypes.func,
    classes: PropTypes.shape({
      formTitle: PropTypes.string,
      cardField: PropTypes.string,
      actions: PropTypes.string,
      cancel: PropTypes.string,
      submit: PropTypes.string,
      ccv: PropTypes.string,
      cardExpiringFieldList: PropTypes.string,
      expired: PropTypes.string,
      field: PropTypes.string.isRequired,
      ErrorMessage: PropTypes.string.isRequired,
      formItem: PropTypes.string.isRequired,
      expHolder: PropTypes.string.isRequired,
      cvHolder: PropTypes.string.isRequired,
    }).isRequired,
    stripe: PropTypes.shape({
      createToken: PropTypes.func.isRequired,
    }).isRequired,
    title: PropTypes.string,
    titleModifier: PropTypes.string,
    setFormOpened: PropTypes.func.isRequired,
    formOpened: PropTypes.bool,
    autoSubmit: PropTypes.bool,
    hideActionButtons: PropTypes.bool,
    hideSaveButton: PropTypes.bool,
    formValues: PropTypes.shape({
      cardHolderName: PropTypes.string,
    }),
    onFieldChange: PropTypes.func.isRequired,
    onFieldBlur: PropTypes.func.isRequired,
    onCardFormReset: PropTypes.func.isRequired,
    isFormValid: PropTypes.bool,
    fields: PropTypes.instanceOf(I.Collection).isRequired,
    cardTokenInvalid: PropTypes.bool,
  };
  static defaultProps = {
    title: 'Card Information',
    titleModifier: '',
  };
  state = {};
  static getDerivedStateFromProps(props) {
    const { isFormValid, setStripeCardToken, stripe, formValues: { cardHolderName } = {} } = props;
    if (avoidTokenReInitDuplication) return null;
    if (!isFormValid) return null;
    if (!props.cardTokenInvalid) return null;
    const tokenData = {
      name: cardHolderName,
    };
    avoidTokenReInitDuplication = true;
    stripe.createToken(tokenData).then((payload) => {
      setStripeCardToken({ token: payload.token.id });
      avoidTokenReInitDuplication = false;
    });
    return null;
  }
  componentWillUnmount() {
    this.props.onCardFormReset();
  }
  submitCounter = 0;
  handleSubmit = (ev) => {
    ev && ev.preventDefault();
    if (!this.props.isFormValid) return;
    const { setStripeCardToken, stripe, onSubmit, formValues: { cardHolderName } } = this.props;
    const tokenData = {
      name: cardHolderName,
    };
    // use only latest request
    const submitCounter = ++this.submitCounter;
    stripe.createToken(tokenData).then((payload) => {
      /**
       * payload:
       *   token:
       *     card:
       *       address_city: null
       *       address_country: null
       *       address_line1: null
       *       address_line1_check: null
       *       address_line2: null
       *       address_state: null
       *       address_zip: null
       *       address_zip_check: null
       *       brand: "Visa"
       *       country: "US"
       *       cvc_check: "unchecked"
       *       dynamic_last4: null
       *       exp_month: 4
       *       exp_year: 2024
       *       funding: "credit"
       *       id: "card_1Hop................qx7c"
       *       last4: "0341"
       *       name: "3 L"
       *       object: "card"
       *       tokenization_method: null
       *     client_ip: "255.0.0.1"
       *     created: 1605699543
       *     id: "tok_1Hop..................YZ"
       *     livemode: false
       *     object: "token"
       *     type: "card"
       *     used: false
      **/
      if (!payload.token || submitCounter !== this.submitCounter) {
        return;
      }
      // onStripeCardToken({ ...payload, cardHolderName, autoSubmit: !ev });
      setStripeCardToken({ token: payload.token.id });
      if (onSubmit) {
        onSubmit({ token: payload.token.id, cardHolderName, autoSubmit: !ev });
      }
    });
  }
  handleSubmitDebounced = debounce(() => {
    this.handleSubmit();
  }, 2000);
  openForm = () => {
    this.props.setFormOpened(true);
  }
  onCloseForm = () => {
    this.props.setFormOpened(false);
    this.props.onCardFormReset();
  }
  onChange = (ev) => {
    const { onFieldChange } = this.props;
    onFieldChange({
      name: ev.elementType,
      error: ev.error,
      complete: ev.complete,
      empty: ev.empty,
    });
    if (this.props.autoSubmit) {
      this.handleSubmitDebounced();
    }
  }
  onBlur = (ev) => {
    const { onFieldBlur } = this.props;
    onFieldBlur(ev.elementType);
  }
  fieldState = (fieldName) => (this.props.fields.get(fieldName));
  fieldHasVisibleError = ({ complete, error, blur, name } = {}) =>
    name && blur && (!complete || error);
  validation = {
    cardHolderName: rules.cmRequired('Please enter your full name'),
  };
  render() {
    const { classes, cardNumber, formOpened, title, titleModifier, isFormValid } = this.props;
    if (cardNumber && !formOpened) {
      return (
        <div>
          <h4
            className={cx(classes.formTitle, titleModifier)}
            children={title}
          />
          <div className={classes.cardField}>
            <span
              style={StripeStyle}
              children={`**** **** **** ${cardNumber}`}
            />
          </div>
          <div className={classes.actions}>
            <button
              type="buton"
              children="CHANGE"
              className={cx(classes.submit, 'change')}
              onClick={this.openForm}
              data-name="change-card"
            />
          </div>
        </div>
      );
    }
    return (
      <form onSubmit={this.handleSubmit}>
        <h4
          className={cx(classes.formTitle, titleModifier)}
          children={title}
        />
        <div
          className={cx(
            classes.field,
            'billingPageField cardField cardIconedField',
            {
              error: this.fieldHasVisibleError(this.fieldState('cardNumber')),
            },
          )}
        >
          <CardNumberElement
            style={StripeStyle}
            onChange={this.onChange}
            onBlur={this.onBlur}
          />
        </div>
        <ErrorMessage className={classes.ErrorMessage} field={this.fieldState('cardNumber')} />
        <div className={cx(classes.formItem, 'cardExpiringFieldList')}>
          <div className={classes.expHolder}>
            <CardExpiryElement
              style={StripeStyle}
              className={cx(
                classes.field,
                'billingPageField expired',
                {
                  error: this.fieldHasVisibleError(this.fieldState('cardExpiry')),
                },
              )}
              onChange={this.onChange}
              onBlur={this.onBlur}
            />
            <ErrorMessage className={classes.ErrorMessage} field={this.fieldState('cardExpiry')} />
          </div>
          <div className={classes.cvHolder}>
            <CardCVCElement
              style={StripeStyle}
              className={cx(
                classes.field,
                'billingPageField ccv cardIconedField',
                {
                  error: this.fieldHasVisibleError(this.fieldState('cardCvc')),
                },
              )}
              onChange={this.onChange}
              onBlur={this.onBlur}
            />
            <ErrorMessage className={classes.ErrorMessage} field={this.fieldState('cardCvc')} />
          </div>
        </div>
        <Field
          name="cardHolderName"
          placeholder="Full name"
          component={FormRowField}
          rootTag="div"
          validate={this.validation.cardHolderName}
          modifier="billingPageField"
        />
        <div className={cx(classes.actions, { hidden: this.props.hideActionButtons })} >
          {
            cardNumber &&
              <button type="button" children="Cancel" className={classes.cancel} onClick={this.onCloseForm} />
          }
          <button
            id="cardFormSave"
            type="submit"
            children="SAVE"
            className={cx(classes.submit, { hidden: this.props.hideSaveButton })}
            disabled={!isFormValid}
          />
        </div>
      </form>
    );
  }
}


CardForm = compose(
  connect({
    cardNumber: cardNumberSelector,
    formOpened: cardFormOpened_sel,
    setFormOpened: setCardFormOpenedAction,
    onFieldChange: onFieldChangeAction,
    onFieldBlur: onFieldBlurAction,
    onCardFormReset: resetCardFormAction,
    setStripeCardToken: setStripeCardTokenAction,
    isFormValid: isFormValidSel,
    fields: fieldsSel,
    cardTokenInvalid: cardTokenInvalid_sel,
  }),
  injectStripe,
  injectSheet(styles),
  reduxForm({
    form: FORM_CARD,
  }),
)(CardForm);

class WrappedByProviderCardForm extends React.Component {
  static propTypes = {
    stripeApiToken: PropTypes.string,
  };
  render() {
    const { stripeApiToken, ...props } = this.props;
    if (!stripeApiToken) return null;
    return (
      <StripeProvider apiKey={stripeApiToken}>
        <Elements>
          <CardForm {...props} />
        </Elements>
      </StripeProvider>
    );
  }
}

export default compose(
  reduxConnect(state => ({
    initialValues: {
      cardHolderName: user_sel(state).cardHolderName || [user_sel(state).firstname, user_sel(state).lastname].join(' '),
    },
    formValues: getFormValues(FORM_CARD)(state),
  })),
  connect({
    stripeApiToken: stripeApiTokenSelector,
  }),
)(WrappedByProviderCardForm);
