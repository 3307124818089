import { put, call, select, takeLatest, takeEvery, fork } from 'redux-saga/effects';
import Api, { callApi, makeApiGenerator } from 'domain/api';
import { deSelectArtwork } from 'domain/ownArtwork/sagas';
import { deSelectTracking } from 'domain/considering/sagas';
import * as A from '../GalleryActions';
import * as M from '../GalleryModel';
import { selectedList, location_sel, routerParams_sel } from 'domain/router/RouterModel';
import { isTrackingLink } from 'lib/helpers';
import { addNotification } from 'domain/env/EnvActions';
import { currentGalleryId_sel } from 'domain/env/EnvModel';
import { hidePopupAction, hidePopupByNamesAction } from 'domain/ui/UIActions';
import {
  CREATE_GALLERY_ENTITY_ART_WORK,
  CREATE_GALLERY_POPUP,
  ADD_TO_GALLERY_POPUP,
} from 'domain/const';

const DEFAULT_PAGE_SIZE = 12;

// { page, size, isArtWorkGallery }
const loadGalleries_apiGen = makeApiGenerator({
  actionCreator: A.fetchGalleriesAction,
  api: Api.getGalleries,
});

const loadGalleriesPrepend_apiGen = makeApiGenerator({
  actionCreator: A.fetchGalleriesPrependAction,
  api: Api.getGalleries,
});

export function* getGalleryPage(galleryId) {
  let page;
  try {
    const size = DEFAULT_PAGE_SIZE;
    const { data } = yield callApi(Api.getGalleryPage, { galleryId, size });
    page = Math.max(0, data - 1);
  } catch (err) {
    console.error(err);
  }
  return page;
}

export function* ensureGalleries_apiGen(page) {
  try {
    const size = DEFAULT_PAGE_SIZE;
    const { gId } = yield select(routerParams_sel);
    if (gId && Number(gId) && page === undefined) {
      page = yield call(getGalleryPage, gId);
    }
    page = page || 0;
    /**
     * fetch galleries page with current gallery || { page: 0 }
     * @todo loadGallerisAppendPage_apiGen.catchError
    **/
    yield fork(loadGalleries_apiGen.catchError, { page, size });
  } catch (err) {
    console.error(err);
  }
}

export function* onLoadGalleriesBidirectional({ payload = 1 }) {
  const { pageLeft, pageRight, pages, total } = yield select(M.galleryPagination_sel);
  if (total === (yield select(M.galleryList_sel).size)) return;
  if (payload > 0) {
    const page = pageRight + payload;
    if (page < pages) {
      yield fork(loadGalleries_apiGen.catchError, { page, size: DEFAULT_PAGE_SIZE });
    }
  } else {
    const page = pageLeft + payload;
    if (page > -1) {
      yield fork(loadGalleriesPrepend_apiGen.catchError, { page, size: DEFAULT_PAGE_SIZE });
    }
  }
  // const page = payload > 0 ? pageRight + payload : pageLeft + payload;
  // if (page > -1 && page < pages) {
  //   yield fork(loadGalleries_apiGen.catchError, { page, size: DEFAULT_PAGE_SIZE });
  // }
}

// { galleryId }
export const ensureGallery_apiGen = makeApiGenerator({
  actionCreator: A.fetchGalleryAction,
  api: Api.getGallery,
  returnValue: function* returnValue({ data }) {
    return yield select(M.getGalleryById(data.id));
  },
});

function* createGallery_apiGen({ payload: { title }, resolve, reject, addSelected = false, entity = CREATE_GALLERY_ENTITY_ART_WORK }) {
  try {
    const { match: { name } } = yield select(location_sel);
    const ids = addSelected ? (yield select(selectedList)).toArray() : [];
    const isArtWorkGallery = !isTrackingLink(name);
    const { data } = yield callApi(Api.createGallery, { data: { title, ids, entity, isArtWorkGallery } });
    yield put({
      type: A.createGalleryAction.success,
      payload: data,
    });
    if (resolve) resolve();
    const notify = ids.length ? `Artworks added to new "${title}" gallery` : 'Gallery created';
    const route = 'collectionListPage';
    yield put(addNotification({ title: notify, type: 'success', link: { route, params: { gId: data.id } } }));
    yield put(hidePopupByNamesAction(CREATE_GALLERY_POPUP, ADD_TO_GALLERY_POPUP));
    if (ids.length) yield fork(onAddToGallerySuccess);
  } catch (err) {
    if (reject) reject(err);
  }
}

// { payload: { galleryId, data }, resolve }
export const editGallery_apiGen = makeApiGenerator({
  actionCreator: A.editGalleryAction,
  api: Api.editGallery,
});

export function* onEditGallery({ payload, resolve, reject }) {
  try {
    yield call(editGallery_apiGen, payload);
    if (resolve) resolve();
    yield put(addNotification({ title: 'Gallery was renamed.', type: 'success' }));
    yield put(hidePopupAction());
  } catch (err) {
    if (reject) reject(err);
  }
}

function* addEntityToGal_apiGen({ gId, ids, fromGalleryId }) {
  const message = `Artwork${ ids.length > 1 ? 's were' : ' was'}`;
  try {
    const { match: { name } } = yield select(location_sel);
    const isArtWorkGallery = !isTrackingLink(name);
    const params = { gId, data: { action: 'ADD', ids, fromGalleryId, isArtWorkGallery } };
    const route = 'collectionListPage';
    const { data } = yield callApi(Api.galleryAddArtworks, params);
    yield put({
      type: A.addArtworksAction.success,
      payload: data,
    });
    yield put(addNotification({ title: `${message} added to gallery.`, link: { route, params: { gId } } }));
  } catch (err) {
    yield put({
      type: A.addArtworksAction.failure,
      err,
    });
    yield put(addNotification({ title: `${message} not added to gallery.`, type: 'error' }));
  }
}

export function* galleryAddSelected({ gId, ids }) {
  const fromGalleryId = yield select(currentGalleryId_sel);
  yield call(addEntityToGal_apiGen, { gId, ids, fromGalleryId });
}

// { galleryId, artworkId }
export const setGalleryCover_apiGen = makeApiGenerator({
  actionCreator: A.setCoverAction,
  api: Api.setGalleryCover,
});

export function* watchCreateGalleryBySelection() {
  yield takeLatest(A.createGalleryAction.type, createGallery_apiGen);
}

function* onAddToGalleryPopupGallerySelected({ payload: gId }) {
  const ids = (yield select(selectedList)).toArray();
  yield call(galleryAddSelected, { gId, ids });
}

function* onAddToGallerySuccess() {
  yield fork(deSelectArtwork);
  yield fork(deSelectTracking);
  yield put(hidePopupAction());
}

export function* watchAddToGalleryPopup() {
  yield takeEvery(A.addArtworksAction.type, onAddToGalleryPopupGallerySelected);
  yield takeLatest(A.addArtworksAction.success, onAddToGallerySuccess);
}
