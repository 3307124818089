/**
 * // usage
 *
 * // just decorate your item component
 * const MySelectableItem = selectableItem(MyItem)
 *
 * // and paste children
 * function MyItem({ item, children }) { return (
 *   <div style={{position: 'relative'}}>
 *     {item.get('id')} {children}
 *   </div>
 * );}
 *
 * // required props: selected: bool and onSelect: fn
 * in MyList:
 *   <MySelectableItem
 *     item={item}
 *     selected={true || false}
 *     onSelect={(e) => this.toggleItemSelection(e, item.get('id'))}
 *     selectedIds={setOfIds}
 *     limitSelection={setOfIds.size >= 2}
 *   />
**/

import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import I from 'immutable';
import cx from 'classnames';
import injectSheet from 'lib/sheet';
import sheet from './sheet.js';

function SelectableItemControls({ onSelect, anySelected, selected, classes, disabled = false, num, hideSelectOverlay }) {
    const [justSelected, setJustSelected] = useState(false);
    const onMouseLeave = useCallback(() => {
      if (justSelected) {
        setJustSelected(false);
      }
    }, [justSelected, setJustSelected]);
    const handleSelect = useCallback((e) => {
      const cb = disabled ? undefined : onSelect;
      if (!selected) {
        setJustSelected(true);
      }
      if (cb) {
        cb(e);
      }
    }, [onSelect, disabled, selected]);
    return (
      <div
        className={cx(classes.SelectableItemControls, {
          [classes.SelectableItemControlsActive]: (!!anySelected && !selected),
          hideSelectOverlay,
        })}
      >
        <button
          type="button"
          className="selectable-item__select-button"
          onClick={handleSelect}
          onMouseLeave={onMouseLeave}
        >
          <div
            className={cx(
              'selectable-item__select-icon',
              {
                selectIconSelected: !!selected,
                text: num !== undefined,
                disabled,
                justSelected,
              },
            )}
            children={num === undefined ? null : (num + 1)}
          />
        </button>
        {
          (!!anySelected && !selected && !disabled && !hideSelectOverlay) &&
            <div onClick={onSelect} className={classes.selectionOverlay} />
        }
      </div>
    );
}
SelectableItemControls.propTypes = {
  classes: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
  selected: PropTypes.bool,
  anySelected: PropTypes.bool,
  disabled: PropTypes.bool,
  hideSelectOverlay: PropTypes.bool,
  num: PropTypes.number,
};

const SelectableItemControlsStyled = injectSheet(sheet)(SelectableItemControls);

function SelectedOverlay({ classes }) {
  return <div className={classes.selectedOverlay} />;
}
SelectedOverlay.propTypes = {
  classes: PropTypes.shape({
    selectedOverlay: PropTypes.string.isRequired,
  }).isRequired,
};

const SelectedOverlayThemed = injectSheet(sheet)(SelectedOverlay);

export default function selectableItem(WrappedComponent) {

  class SelectableItem extends React.PureComponent {

    static propTypes = {
      onSelect: PropTypes.func,
      selected: PropTypes.bool,
      selectedIds: PropTypes.instanceOf(I.Collection),
      limitSelection: PropTypes.bool,
      enumerate: PropTypes.bool,
      hideSelectOverlay: PropTypes.bool,
      selectedId: PropTypes.number,
    };

    get num() {
      const { enumerate, selectedIds, selectedId } = this.props;
      if (!enumerate) return undefined;
      const found = selectedIds.findIndex(v => v === selectedId);
      // id or -1
      return found > -1 ? found : undefined;
    }

    renderControls() {
      const { onSelect, selected, selectedIds, limitSelection, hideSelectOverlay } = this.props;
      return (
        <SelectableItemControlsStyled
          onSelect={onSelect}
          disabled={limitSelection && !selected}
          selected={selected}
          anySelected={!!(selectedIds && selectedIds.size)}
          num={this.num}
          hideSelectOverlay={hideSelectOverlay}
        />
      );
    }

    renderSelected() {
      const { selected } = this.props;
      return (!!selected) ? <SelectedOverlayThemed /> : null;
    }

    selectionChildren() {
      return { controls: this.renderControls(), selected: this.renderSelected() };
    }

    render() {
      const { onSelect } = this.props;
      if (onSelect === undefined) {
        return (
          <WrappedComponent {...this.props} />
        );
      } else {
        return (
          <WrappedComponent
            {...this.props}
            selectionChildren={this.selectionChildren()}
          />
        );
      }
    }
  }

  return SelectableItem;
}
