import React from 'react';
import { compose } from 'redux';
import { connect } from 'cpcs-reconnect';
import I from 'immutable';
import cx from 'classnames';
import PropTypes from 'prop-types';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import Popup from 'components/popup';
import { isPopupVisibleSelector, getPopupParamsSelector } from 'domain/ui/UIModel';
import { artworkFormPictures_sel } from 'pages/common/artworkForm/ArtworkFormModel';
import { hidePopupAction, addPopupAction } from 'domain/ui/UIActions';
import { CROP_IMAGE_POPUP, RE_UPLOAD_IMAGE_POPUP } from 'domain/const';
import { setImageCropAction } from 'pages/common/artworkForm/ArtworkFormActions';
import Title from 'pages/common/popup/title';
import { imgUrl, isEmpty } from 'lib/helpers';
import { addNotification as addNotificationAction } from 'domain/env/EnvActions';
import { getCroppedImgUrlBySrc } from 'components/image/getCroppedImageUrl';
import { getRotatedImgUrlBySrc } from 'components/image/getRotatedImageUrl';

import injectSheet from 'lib/sheet';
import sheet from './sheet';

const START_PERCENT_WIDTH = 95;
const START_PERCENT_HEIGHT = 95;

class CropForm extends React.PureComponent {

  static propTypes = {
    classes: PropTypes.shape({
      container: PropTypes.string,
      titleWrapper: PropTypes.string,
      close: PropTypes.string,
      title: PropTypes.string,
      cropBoxWrapper: PropTypes.string.isRequired,
      cropBox: PropTypes.string.isRequired,
      buttons: PropTypes.string.isRequired,
      buttonsCol: PropTypes.string.isRequired,
      rotateButton: PropTypes.string.isRequired,
      button: PropTypes.string.isRequired,
    }).isRequired,
    cancel: PropTypes.func.isRequired,
    params: PropTypes.instanceOf(I.Collection),
    minWidth: PropTypes.number,
    minHeight: PropTypes.number,
    addNotification: PropTypes.func.isRequired,
    onDone: PropTypes.func.isRequired,
    addPopup: PropTypes.func.isRequired,
    picturesList: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      preview: PropTypes.string,
      x1: PropTypes.number,
      x2: PropTypes.number,
      y1: PropTypes.number,
      y2: PropTypes.number,
      naturalWidth: PropTypes.number,
      naturalHeight: PropTypes.number,
    })),
  }

  static defaultProps = {
    minWidth: 0,
    minHeight: 0,
  };

  static getDerivedStateFromProps(props, state) {
    const { params, picturesList } = props;
    const file = params && picturesList && picturesList[params.get('index')];
    const retOrigin = {};
    let ret = retOrigin;
    let { rotationTimes } = state;
    if (file && file.rotationTimes && typeof state.rotationTimes === 'undefined') {
      rotationTimes = file.rotationTimes;
      ret = { ...ret, rotationTimes: file.rotationTimes };
    }
    if (state.self && file && rotationTimes && typeof state.rotatedUrls[rotationTimes] === 'undefined') {
      const { self } = state;
      // do not load same rotatedUrl again while it's async loading
      ret = { ...ret, rotatedUrls: { ...state.rotatedUrls, [rotationTimes]: null } };
      const src = imgUrl(file.preview || file.name);
      const deg = rotationTimes * 90;
      getRotatedImgUrlBySrc(src, rotationTimes, `rotated-${deg}-grad.jpg`).then(rotatedUrl => {
        const { rotatedUrls } = self.state;
        self.setState({
          rotatedUrls: { ...rotatedUrls, [rotationTimes]: rotatedUrl },
        });
      }).catch(err => console.error('rotating image error', err));
    }
    return ret === retOrigin ? null : ret;
  }

  componentDidMount() {
    global.addEventListener('resize', this.onResize);
  }

  componentWillUnmount() {
    global.removeEventListener('resize', this.onResize);
  }

  state = {
    // { x, y, width, height }
    percentCrop: null,
    crop: {
      // unit: '%',
      // width: 33.33,
      // height: 33.33,
      // x: 33.33,
      // y: 33.33,
    },
    initialized: false,
    cropBoxStyle: undefined,
    // one of 0, 1, 2, 3, -1, -2, -3
    rotationTimes: undefined,
    rotatedUrls: {},
    self: this,
  };

  cropBox = null;
  cropBoxWrapper = null;
  image = null;

  onResize = () => {
    this.resizeWrapper();
  };

  onCancel = () => {
    this.props.cancel();
  };

  onRotate = timesDirection => () => {
    this.image = null;
    /**
     * keep rotation times in set 0, 1, 2, 3
     * convert counterclock-wise rotation direction to clockwise
     * and avoid rotating over 360 degrees
     * (((v || 0) + timesDirection) % 4 + 4) % 4
     * example
     *     [-7, -6, -5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5, 6, 7].map(v => (v % 4 + 4) % 4)
     *     returns [1, 2, 3, 0, 1, 2, 3, 0, 1, 2, 3, 0, 1, 2, 3]
    **/
    const rotationTimes = (((this.state.rotationTimes || 0) + timesDirection) % 4 + 4) % 4;
    /**
     * on rigth side of = should be used prev state
     * rotate + crop:
     *   rotate +1
     *       x = (100 - y - h)
     *       y = x
     *       w = h
     *       h = w
     *   rotate -1
     *       x = y
     *       y = (100 - x - w)
     *       w = h
     *       h = w
    **/
    const { percentCrop: p } = this.state;
    let percentCrop = p;
    if (p) {
      percentCrop = {
        x: timesDirection > 0 ? 100 - p.y - p.height : p.y,
        y: timesDirection > 0 ? p.x : 100 - p.x - p.width,
        width: p.height,
        height: p.width,
      };
    }
    this.setState({ rotationTimes, percentCrop });
  }

  onCropChange = (crop, percentCrop) => {
    const { initialized } = this.state;
    if (!initialized) return;
    crop = { ...crop, rotationTimes: this.state.rotationTimes };
    this.setState({ crop, percentCrop });
  };

  // { x, y, width, height, unit }
  // onCropSelected = () => null;

  setCropBox = node => this.cropBox = node;
  setCropBoxWrapper = node => this.cropBoxWrapper = node;

  get imageSize() {
    const { image } = this;
    const { naturalWidth, naturalHeight } = image;
    return { naturalWidth, naturalHeight };
  }

  resizeWrapper() {
    try {
      const { image, cropBoxWrapper } = this;
      if (!image || !cropBoxWrapper) return;
      if (!this.cropBoxWrapper) return;
      let wrapper = this.cropBoxWrapper.getBoundingClientRect();
      if (process.env.NODE_ENV === 'test') {
        wrapper = { width: 2048, height: 1200 };
      }
      const { naturalWidth, naturalHeight } = this.imageSize;
      const scaleY = Math.min(wrapper.height, naturalHeight) / naturalHeight;
      const scaleX = Math.min(wrapper.width, naturalWidth) / naturalWidth;
      const scale = Math.min(scaleX, scaleY);
      const { percentCrop: p } = this.state;
      const crop = {
        rotationTimes: this.state.rotationTimes,
        x: naturalWidth * scale * p.x / 100,
        y: naturalHeight * scale * p.y / 100,
        width: naturalWidth * scale * p.width / 100,
        height: naturalHeight * scale * p.height / 100,
        unit: 'px',
      };
      this.setState({
        cropBoxStyle: { width: naturalWidth * scale + 'px' },
        crop,
      });
    } catch (err) {
      console.error(err);
    }
  }

  onImageLoaded = image => {
    this.image = image;
    const file = this.file;
    if (!file) return;
    let x, y, width, height;
    const { naturalWidth, naturalHeight } = this.imageSize;
    if (!isEmpty(file.x1, file.x2, file.y1, file.y2)) {
      x = file.x1 / (naturalWidth / 100);
      y = file.y1 / (naturalHeight / 100);
      width = Math.max(0, file.x2 - file.x1) / (naturalWidth / 100);
      height = Math.max(0, file.y2 - file.y1) / (naturalHeight / 100);
    } else {
      const { minWidth, minHeight } = this.props;
      if (!minWidth || !minHeight) {
        const marginSizePx = Math.round(
          Math.min(
            naturalWidth / 100 * (100 - START_PERCENT_WIDTH),
            naturalHeight / 100 * (100 - START_PERCENT_HEIGHT),
          ),
        );
        /**
         * w 200, h 400, 95%, 5% margin
         * marginPx = min(10, 20)
         * marginWidthPercent = 10 / (200 / 100)
         * marginHeightPercent = 10 / (400 / 100)
        **/
        const marginWidthPercent = marginSizePx / (naturalWidth / 100);
        const marginHeightPercent = marginSizePx / (naturalHeight / 100);
        width = 100 - marginWidthPercent;
        height = 100 - marginHeightPercent;
      } else {
        width = Math.min(100, minWidth / (naturalWidth / 100));
        height = Math.min(100, minHeight / (naturalHeight / 100));
      }
      x = (100 - width) / 2;
      y = (100 - height) / 2;
    }
    if (this.state.percentCrop) {
      this.resizeWrapper();
      return false;
    }
    this.setState({
      percentCrop: { x, y, width, height, unit: '%' },
      initialized: true,
    }, () => {
      this.resizeWrapper();
    });
    return false;
  }

  onReUpload = () => {
    const { addPopup, params } = this.props;
    addPopup({
      name: RE_UPLOAD_IMAGE_POPUP,
      params: {
        index: params.get('index'),
      },
    });
  }

  onDone = () => {
    const { x, y, width, height } = this.state.percentCrop;
    const { addNotification, params } = this.props;
    const { image } = this;
    if (!image) {
      addNotification({ type: 'error', title: 'Image was not loaded. Please refresh the page and try again.' });
      return null;
    }
    const x1 = Math.floor(image.naturalWidth * x / 100);
    const x2 = Math.floor(image.naturalWidth * x / 100 + image.naturalWidth * width / 100);
    const y1 = Math.ceil(image.naturalHeight * y / 100);
    const y2 = Math.ceil(image.naturalHeight * y / 100 + image.naturalHeight * height / 100);
    const w = x2 - x1;
    const h = y2 - y1;
    const index = params.get('index');
    const file = this.file;
    if (file && file.croppedUrl && !this.rotationTimes) {
      try {
        window.URL.revokeObjectURL(file.croppedUrl);
      } catch (err) {
        console.error(err);
      }
    }
    if (width === 100 && height === 100 && file) {
      let res = {
        croppedUrl: this.rotationTimes ? this.rotatedUrl: undefined,
        naturalHeight: undefined,
        naturalWidth: undefined,
        index,
        x1: undefined,
        x2: undefined,
        y1: undefined,
        y2: undefined,
        w: undefined,
        h: undefined,
        rotationTimes: this.rotationTimes,
      };
      this.props.onDone(res);
      return;
    }
    const crop = { x1, x2, y1, y2 };
    getCroppedImgUrlBySrc(this.rotatedUrl, crop).then((croppedUrl) => {
      this.props.onDone({
        w, h, x1, x2, y1, y2, index, croppedUrl,
        naturalWidth: image.naturalWidth,
        naturalHeight: image.naturalHeight,
        rotationTimes: this.rotationTimes,
      });
    }).catch(err => console.error('cropping image error', err));
  }

  get rotationTimes() {
    // return (times || 0) * 90 % 360;
    return (this.state.rotationTimes || 0) % 4;
  }

  get file() {
    const { params, picturesList } = this.props;
    return (params && picturesList && picturesList[params.get('index')]) || null;
  }

  get rotatedUrl() {
    const { rotatedUrls } = this.state;
    const times = this.rotationTimes;
    const { file } = this;
    if (!file) return undefined;
    if (times) {
      return rotatedUrls[times];
    }
    return imgUrl(file.preview || file.name);
  }

  render() {
    const { classes } = this.props;
    const { crop, cropBoxStyle } = this.state;
    const { rotatedUrl } = this;
    return (
      <React.Fragment>
        <div className={classes.cropBoxWrapper} ref={this.setCropBoxWrapper}>
          {
            rotatedUrl &&
              <div className={classes.cropBox} style={cropBoxStyle} ref={this.setCropBox}>
                <ReactCrop
                  src={rotatedUrl}
                  crop={crop}
                  onChange={this.onCropChange}
                  // onComplete={params => this.onCropSelected(params)}
                  // keepSelection
                  onImageLoaded={this.onImageLoaded}
                  // minWidth={600}
                  // minHeight={600}
                  ruleOfThirds
                />
              </div>
          }
        </div>
        <div className={classes.buttons}>
          <div className={cx(classes.buttonsCol, 'col1')}></div>
          <div className={cx(classes.buttonsCol, 'col2')}>
            <button
              type="button"
              onClick={this.onRotate(-1)}
              className={cx(classes.rotateButton, 'left')}
            />
            <button
              type="button"
              onClick={this.onRotate(1)}
              className={cx(classes.rotateButton, 'right')}
            />
          </div>
          <div className={cx(classes.buttonsCol, 'col3')}>
            <button
              type="button"
              onClick={this.onReUpload}
              className={cx(classes.button, 'Popup-CropImagePopup cancel')}
              children="re-upload image"
            />
            <button
              type="button"
              onClick={this.onDone}
              className={cx(classes.button, 'add')}
              children="accept"
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function CropImagePopup(props) {
  const { classes, isPopupVisible } = props;
  if (!isPopupVisible(CROP_IMAGE_POPUP)) return null;
  return (
    <Popup name="CROP_IMAGE_POPUP">
      <div className={classes.container}>
        <Title
          children="Image Preview"
          onClose={() => props.cancel()}
        />
        <p className={classes.cropSubTitle} children="Crop to the art work excluding wall and frame"/>
        <CropForm
          {...props}
        />
      </div>
    </Popup>
  );
}

CropImagePopup.propTypes = {
  classes: PropTypes.shape({
    container: PropTypes.string,
    cropSubTitle: PropTypes.string,
  }),
  isPopupVisible: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
};

export default compose(
  connect({
    isPopupVisible: isPopupVisibleSelector,
    cancel: hidePopupAction,
    params: getPopupParamsSelector,
    onDone: setImageCropAction,
    addNotification: addNotificationAction,
    addPopup: addPopupAction,
    picturesList: artworkFormPictures_sel,
  }),
  injectSheet(sheet),
)(CropImagePopup);
