import I from 'immutable';

export default I.fromJS({
  privacyTermsAccept: {
    Required: 'You need to accept Privacy Policy and Terms of Use to continue registration',
  },
  marketingEmailConsent: {
    Required: 'You need to accept GDPR politics',
  },
});
