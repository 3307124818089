import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'cpcs-reconnect';
import { compose } from 'redux';
import cx from 'classnames';
import Popup from 'components/popup';
import { isPopupVisibleSelector, hidePopupAction } from 'domain/ui';
import { privacyAcceptAction } from 'domain/env/EnvActions';
import { Terms as T, Bold as B, P, A } from './utils';

import { SIGNUP_PRIVACY_POPUP } from 'domain/const';

import injectSheet from 'lib/sheet';
import { sheet } from './sheet';

class PrivacyPopup extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.shape({
      container: PropTypes.string.isRequired,
      close: PropTypes.string.isRequired,
      textContainer: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      textTitle: PropTypes.string.isRequired,
      subTitle: PropTypes.string.isRequired,
      innerList: PropTypes.string.isRequired,
      listItem: PropTypes.string.isRequired,
      bold: PropTypes.string.isRequired,
      footer: PropTypes.string.isRequired,
      button: PropTypes.string.isRequired,
    }).isRequired,
    hidePopup: PropTypes.func.isRequired,
    isPopupVisible: PropTypes.func.isRequired,
    onAccept: PropTypes.func.isRequired,
  }

  onCancel = () => {
    this.props.onAccept(false);
    this.props.hidePopup();
  }

  onAccept = () => {
    this.props.onAccept(true);
    this.props.hidePopup();
  }

  onHide = () => {
    this.props.hidePopup();
  }

  render() { //NOSONAR
    const { classes, isPopupVisible } = this.props;
    if (!isPopupVisible(SIGNUP_PRIVACY_POPUP)) return null;
    return (
      <Popup>
        <button
          type="button"
          onClick={this.onHide}
          className={classes.close}
        />
        <div className={classes.container}>
          <div className={classes.textContainer}>
            <h2 className={classes.title}>ARTBNK PRIVACY POLICY</h2>
            <P>Effective as of: July 20, 2018</P>
            <P>This Privacy Policy relates to information collected by ARTBNK LLC (<T>ARTBNK</T>, <T>we</T>, <T>us</T>
              or <T>our</T>) through your use of the ARTBNK website at http://artbnk.com/ and any subdomains
              (the <T>Site</T>) and the services, features, and information available on the Site and/or any mobile
              applications we may offer (together with the Site, along with associated and successor websites,
              applications, features, information, and services, or any part thereof (the <T>Service</T>). This Privacy
              Policy is incorporated into, a part of, and governed by
              the ArtBnk LLC Subscription Terms (the <T>Subscription Terms</T>). Any capitalized
              terms not defined herein will have the meanings ascribed to them in the Subscription Terms. You should
              carefully read this Privacy Policy. By using and/or interacting with the Service, you are signifying your
              acceptance of this Privacy Policy. If you do not agree to this Privacy Policy, you are not permitted to
              use or interact with the Service.</P>
            <P>For the purposes of this Privacy Policy, the terms “using” and “processing” information
              include using cookies on a computer, subjecting the information to statistical or other analysis and using
              or handling information in any way, including, but not limited to collecting, storing, evaluating,
              modifying, deleting, using, combining, disclosing and transferring information within our organization or
              among our affiliates within the United States or internationally.</P>
            <P>As described in further detail below, ARTBNK complies with the EU-U.S. Privacy
              Shield Framework and the Swiss-U.S. Privacy Shield Framework as set forth by the U.S. Department of
              Commerce regarding the collection, use, and retention of personal information (as defined below)
              transferred from the European Union and Switzerland, respectively, to the United States.<br /><br />

              As used herein,{' '}
              “personal information” means information relating to an identified or identifiable natural person.
              As described below, types of personal information we collect could include personally identifiable
              as well as non-personally identifiable information. With respect to such personal information
              that is transferred from the European Union (including Switzerland) or the European Economic Area to the
              United States (“transfer data”),
              ARTBNK has certified to the Department of Commerce that it adheres to the Privacy Shield
              Principles. If there is any conflict between the terms in this Privacy Policy and the Privacy Shield
              Principles with respect to such transfer data, the Privacy Shield Principles shall govern. To learn more
              about the Privacy Shield program,
              and to view our certification, please visit <A>https://www.privacyshield.gov/</A>.</P>
            <P>
              To the extent that the Service is available to individuals located in the European Economic Area and
              the United Kingdom we are bound by and act in compliance with the
              General Data Protection Regulation 2016/679 (“the GDPR”). This Privacy Policy sets out our practices and
              obligations under the GDPR.
            </P>
            <h3 className={classes.textTitle}>WHAT INFORMATION ABOUT ME IS COLLECTED?</h3>
            <P>Depending on your use of the Service, we may collect two types of information:
              personally identifiable information and non-personally identifiable information.</P>
            <h4 className={classes.subTitle}>Personally Identifiable Information</h4>
            <P>Personally identifiable information is information that identifies you or can be
              used to identify or contact you. Such information may include your name, password, and email address.</P>
            <P>
              Personally identifiable information amounts to ‘personal data for the purposes of the GDPR.
              All references to personally identifiable information in this Privacy Policy shall be deemed
              to include personal data as defined and used in the GDPR.
            </P>
            <h4 className={classes.subTitle}>Non-Personally Identifiable Information</h4>
            <P>Non-personally identifiable information is information, any single item of
              which, by itself, cannot be used to identify or contact you, IP addresses, browser types, unique device
              identifiers, device types, requested URL, referring URL, browser language, the pages you view, the date
              and time of your visit, domain names, and other statistical data involving the use of the Service. Certain
              non-personally identifiable information may be considered a part of your personally identifiable
              information if it were combined with other non-personally identifiable information (for example, combining
              name and your IP address) in a way that enables you to be identified. However, the same pieces of
              information are considered non-personally identifiable information when they are taken alone or combined
              only with other non-personally identifiable information (for example, your viewing preferences).</P>

            <h3 className={classes.textTitle}>WHERE AND WHEN IS INFORMATION COLLECTED (INCLUDING THROUGH THE USE OF
              COOKIES AND ACTION TAGS)?</h3>
            <h4 className={classes.subTitle}>Information Provided by You</h4>
            <P>We will collect personally identifiable and non-personally identifiable
              information that you submit to us.</P>
            <P>You may be required to establish an account in order to take advantage of
              certain features of the Service. If you establish an account, you may be required to provide us with
              information (including personally identifiable information and non-personally identifiable information)
              such as your name, username, password, and email address. We may also receive personally identifiable
              information about you from third parties providing credit and debit card authorization and fraud screening
              services as part of the registration process. In addition, we may obtain your personally identifiable
              information from you if you identify yourself to us by sending us an e-mail with questions or comments.
              Also, we will have access to any personally identifiable information that you choose to share through the
              Service.</P>
            <h4 className={classes.subTitle}>Cookies and Action Tags</h4>
            <P>We may collect non-personally identifiable information passively using “cookies”
              and “action tags.”</P>
            <P><T>Cookies</T> are small text files that can be placed on your computer or mobile
              device in order to identify your Web browser and the activities of your computer on the Service and other
              websites. Cookies can be used to personalize your experience on the Service (such as dynamically
              generating content on webpages specifically designed for you), to assist you in using the Service (such as
              saving time by not having to reenter your name each time you use the Service), to allow us to
              statistically monitor how you are using the Service to help us improve our offerings, and to target
              certain advertisements to your browser which may be of interest to you or to determine the popularity of
              certain content.</P>
            <P>You do not have to accept cookies to use the Service. Although most browsers are
              initially set to accept cookies, you may reset your browser to notify you when you receive a cookie or to
              reject cookies generally. Most browsers offer instructions on how to do so in the "Help" section of the
              toolbar. However, if you reject cookies, certain features or resources of the Service may not work
              properly or at all and you may experience some loss of convenience.</P>
            <P><T>Action tags</T>, also known as web beacons or gif tags, are a web technology used
              to help track website usage information, such as how many times a specific page has been viewed. Action
              tags are invisible to you, and any portion of the Service, including advertisements, or e-mail sent on our
              behalf, may contain action tags.</P>
            <P>By using cookies and action tags together, we are able to gain valuable
              information to improve the Service and measure the effectiveness of our advertising and marketing
              efforts.</P>
            <P>Finally, you should be aware that advertisers and other third parties may use
              their own cookies or action tags when you click on their advertisement or a link to their websites or
              services on or from the Service. This Privacy Policy does not govern the use of cookies or action tags or
              the use of your information by such third-party websites or services or providers of third-party
              advertising.</P>
            <P>The third party services we use are
              {' '}
              <A href="https://support.google.com/analytics/answer/6004245" children="Google Analytics" />
              {' '}
              and
              {' '}
              <A
                href="https://www.google.com/policies/technologies/ads/?hl=en&ref_topic=6089781&visit_id=0-636433332310881937-1175607659&rd=1"
                children="Google Ad Words."
              />
            </P>
            <h4 className={classes.subTitle}>Log Files</h4>
            <P>We also collect non-personally identifiable information through our Internet log
              files, which record data such as user IP addresses, browser types, domain names, and other anonymous
              statistical data involving the use of the Service. This information may be used to analyze trends, to
              administer the Service, to monitor the use of the Service, and to gather general demographic information.
              We may link this information to personally identifiable information for these and other purposes such as
              personalizing your experience on the Service and evaluating the Service in general.</P>
            <h4 className={classes.subTitle}>Public Areas</h4>
            <P>The Service may feature various community areas and other public forums,
              discussion boards, question and answer areas and comment areas (the "Public Areas") where Service users
              with similar interests can share information and support one another or where members can post questions
              for others to answer. These Public Areas are open to the public and should not be considered private. We
              cannot prevent such information from being used in a manner that may violate this Privacy Policy, the law,
              or your personal privacy. We are not responsible for the results of such postings or for the accuracy of
              any information contained in those postings.</P>

            <h3 className={classes.textTitle}>IS INFORMATION SUBMITTED TO THE PUBLIC AREAS OF THE SERVICE PRIVATE?</h3>
            <P>No. Any information shared in the Public Areas (as defined above) of the Service
              is available to the public, including to all users. Such information is not protected or treated as
              confidential, can be used in any manner, and is not subject to this Privacy Policy. You should think
              carefully before posting any information in any Public Area. What you post can be seen, disclosed to or
              collected by others and may be used by others in ways we cannot control or predict. As with any public
              forum on any website, the information you post may also show up in third-party search engines like Google,
              Yahoo, MSN, and Bing. If you mistakenly post personally identifiable information in a Public Area you can
              send us an e-mail to request that we remove it by contacting us
              at <A mailto>privacy@artbnk.com</A>. You should
              understand that in some cases, we may not be able to remove your information. If you wish to keep any
              information private or proprietary, do not submit it to the Public Areas.</P>
            <P>NOTWITHSTANDING THE FOREGOING, WE HAVE NO RESPONSIBILITY OR LIABILITY IF A
              USER’S INFORMATION OR IDENTITY IS MISUSED OR STOLEN, OR IF A USER SUFFERS HARM AS A RESULT OF VOLUNTARY
              DISCLOSURES.</P>

            <h3 className={classes.textTitle}>IS A USERNAME PRIVATE?</h3>
            <P>No. A user’s username is available to the public, including to all users, when a
              user shares or posts information in the Public Areas of the Service. When prompted to create a username,
              we advise users to use non-personally identifiable information – i.e., select a screen name that is not
              similar to a user’s actual name – because such information can be publicly available to all users.</P>

            <h3 className={classes.textTitle}>DOES ARTBNK COLLECT INFORMATION FROM CHILDREN UNDER 13 YEARS OF AGE?</h3>
            <P>We are committed to protecting the privacy of children. The Service is not
              designed for or directed to children under the age of 13. We do not collect personally identifiable
              information from any person we actually know is under the age of 13. As currently operated persons under
              the age of 21 may not use the Service. By using the Service, you represent that you are 21 years of age or
              older.</P>

            <h3 className={classes.textTitle}>WHAT DOES ARTBNK DO WITH THE INFORMATION IT COLLECTS?</h3>
            <P>
              We will only use your personally identifiable information to the extent that the law allows us to do so.
              Under the GDPR, we rely on the following legal bases for processing your personally identifiable information:
            </P>

            <ul className={classes.innerList}>
              <li className={classes.listItem}>
                1. where you have given consent to the processing;
              </li>
              <li className={classes.listItem}>
                2. where it is necessary to perform the contract we have entered into or are about to enter into with you
                (whether in relation to the provision of the Service or otherwise); and/or
              </li>
              <li className={classes.listItem}>
                3. where it is necessary for the purposes of our legitimate interests (or those of a third party) and your
                interests or fundamental rights and freedoms do not override those legitimate interests.
              </li>
            </ul>

            <P>
              We use the information collected to provide the Service to you and process your transactions, to help us
              understand who uses the Service, for internal operations such as operating and improving the Service,
              to contact you for customer service and billing purposes, to facilitate the delivery of advertising, and,
              unless you “opt out”, so that we can contact you about products and services that may be of interest
              to you and deliver relevant content to you.
            </P>

            <P>
              We may use your information to send you an e-mail that may confirm your user name and password.
              Unless you opt out, we may send you electronic newsletters, contact you about the Service, products,
              services, information and news that may be of interest to you, and provide you with targeted feedback.
              If you no longer desire to receive these communications, we will provide you with the option to change
              your preferences in each communication we send you. Also see “WHAT CHOICES DO I HAVE REGARDING THE
              COLLECTION, DISCLOSURE AND DISTRIBUTION OF PERSONALLY IDENTIFIABLE INFORMATION?” below for instructions
              on how to opt out.
            </P>

            <P>
              If you identify yourself to us by sending us an e-mail with questions or comments, we may use your
              information (including personally identifiable information) to respond to your questions or comments,
              and we may file your questions or comments (with your information) for future reference.
            </P>

            <P>
              We may also use the information collected to send announcements and updates regarding the Service or,
              if applicable, about your billing account status. You will not be able to unsubscribe from these Service
              announcements and updates as they contain important information relevant to your use of the Service,
              and are necessary for the performance of our contract with you.
            </P>
            <P>
              We may also use the information gathered to perform statistical analysis of user
              behavior or to evaluate and improve the Service. We may link some of this information to personally
              identifiable information for internal purposes or to improve your experience with the Service.
            </P>

            <h3 className={classes.textTitle}>WHEN DOES ARTBNK DISCLOSE INFORMATION TO THIRD PARTIES?</h3>
            <P>Unless specifically authorized by you, we will only disclose any information we
              gather from you through the Service as set forth in this Privacy Policy or
              the Subscription Terms or as otherwise disclosed at the time we request such information from you.
            </P>
            <h4 className={classes.subTitle}>Laws and Legal Rights</h4>
            <P>We may disclose your information (including personally identifiable information)
              if we believe in good faith that we are required to do so in order to comply with an applicable statute,
              regulation, rule or law, a subpoena, a search warrant, a court or regulatory order, lawful requests by
              public authorities, including to meet national security or law enforcement requirements, or other valid
              legal process. We may disclose personally identifiable information in special circumstances when we have
              reason to believe that disclosing this information is necessary to identify, contact or bring legal action
              against someone who may be violating
              the Subscription Terms to detect fraud, for assistance
              with a delinquent account, or to protect our rights or property or the safety and/or security of our
              users, the Service or the general public.</P>
            <h4 className={classes.subTitle}>Third Parties Generally</h4>
            <P>ArtBnk may decide to disclose your personally-identifiable information to third
              parties for the purpose of sending you select marketing offers and communications that we believe may be
              of interest to you. You will have the option to out-out of such disclosure by following the opt-out
              procedure set forth below. Your non-personally identifiable information may be provided to third parties,
              including where such information is combined with similar information of other users of the Site. In
              addition to the above, when individuals use the Site, third parties (including without limitation
              third-party analytics and other service providers, and advertisers) may directly collect information about
              our Site visitors, including about our visitors’ online activities over time and across different
              websites.</P>
            <h4 className={classes.subTitle}>Outside Contractors</h4>
            <P>We may employ independent contractors, vendors and suppliers (collectively,
              <T>Outside Contractors</T>) to provide specific services and products related to the Service, such as hosting
              and maintaining the Service, providing credit card processing and fraud screening, and developing
              applications for the Service. In the course of providing products or services to us, these Outside
              Contractors may have access to information collected through the Service, including your personally
              identifiable information. We will ensure that these Outside Contractors protect the privacy of your
              personally identifiable information and act only on our documented instructions.</P>
            <h4 className={classes.subTitle}>Sale of Business/Bankruptcy</h4>
            <P>We reserve the right to transfer information, including personally identifiable
              information, to a third party in the event of a sale, merger or other transfer of all or substantially all
              of the assets of ARTBNK or any of its Corporate Affiliates (as defined below), or that portion of ARTBNK
              or any of its Corporate Affiliates to which the Service relates, or in the event that we discontinue our
              business or file a petition or have filed against us a petition in bankruptcy, reorganization or similar
              proceeding, or become insolvent or are unable to pay our debts when due provided that the third party
              agrees to adhere to the terms of this Privacy Policy.</P>
            <h4 className={classes.subTitle}>Affiliates</h4>
            <P>We may disclose information (including personally identifiable information)
              about you to our Corporate Affiliates. For purposes of this Privacy Policy: "Corporate Affiliate" means
              any person or entity which directly or indirectly controls, is controlled by or is under common control
              with ARTBNK, whether by ownership or otherwise; and “control” means possessing, directly or indirectly,
              the power to direct or cause the direction of the management, policies or operations of an entity, whether
              through ownership of fifty percent (50%) or more of the voting securities, by contract or otherwise. Any
              information relating to you that we provide to our Corporate Affiliates will be treated by those Corporate
              Affiliates in accordance with the terms of this Privacy Policy.</P>
            <h4 className={classes.subTitle}>Transborder Hosting and Transfer of Information</h4>
            <P>
              Personally identifiable information collected on the Site or through the provision of the Service may be
              transferred from time to time to ARTBNK offices or personnel, or to third parties, located throughout the
              world, including offices located outside the European Economic Area and/or the United Kingdom, and
              the Site may be viewed and hosted anywhere in the world, including countries (such as the United States)
              that may not have laws of general applicability regulating the use and transfer of such data. By using
              the Site and submitting such information on it, you voluntarily consent to such transborder transfer and
              hosting of such information.
            </P>
            <P>
              To the extent that we process personally identifiable information of individuals located in the
              European Economic Area and/or the United Kingdom, we are bound by and act in compliance with the GDPR.
            </P>
            <P>
              For specific information regarding how we handle personal information transferred to the United States
              from the European Union and the European Economic Area, please see the section below titled
              “ADDITIONAL PROVISIONS FOR PERSONAL INFORMATION RECEIVED FROM THE EUROPEAN UNION (INCLUDING SWITZERLAND)”.
            </P>
            <h3 className={classes.textTitle}>
              FOR HOW LONG WILL MY PERSONALLY IDENTIFIABLE INFORMATION BE KEPT?
            </h3>
            <P>
              We will only store your personally identifiable information for as long as is necessary to fulfill
              the purposes for which we collected it.
            </P>
            <P>
              To determine the appropriate retention period for personally identifiable information, we consider the amount,
              nature and sensitivity of that information, the potential risk of harm from unauthorized use or disclosure,
              the purposes for which we process your personally identifiable information and whether we can achieve
              those purposes through other means, and any applicable legal requirements.
            </P>

            <h3 className={classes.textTitle}>
              DOES THIS PRIVACY POLICY APPLY WHEN I LINK TO OTHER WEBSITES OR SERVICES?
            </h3>
            <P>The Service may provide you with access to other websites and services,
              including a third party payment system. This may include providing you with the ability to automatically
              post updates on social media. Please be aware that we are not responsible for the privacy practices of any
              websites or services other than the Service. We encourage you to read the privacy policies or statements
              of each and every such website and service. This Privacy Policy applies solely to information collected by
              us through the Service.</P>

            <h3 className={classes.textTitle}>IS THE INFORMATION COLLECTED THROUGH THE SERVICE SECURE?</h3>
            <P>We want your information (including personally identifiable information) to
              remain secure. We strive to provide transmission of your information from your computer or mobile device
              to our servers through techniques that are consistent with industry standards and to employ
              administrative, physical, and electronic measures designed to protect your information from loss, misuse,
              unauthorized access, disclosures, alteration, and destruction, taking into account the risks involved and
              the nature of the information.</P>
            <P>The information we receive through your use of the Service is stored in the
              cloud through Amazon Web Services (AWS). You can learn more about the security and privacy practices of
              AWS here: <A>https://aws.amazon.com/security/</A></P>
            <P>Notwithstanding the above, you should be aware that there is always some risk
              involved in transmitting information over the Internet. There is also some risk that others could find a
              way to thwart the security systems. As a result, while we strive to protect your information, we cannot
              ensure or warrant the security or privacy of any information you transmit to us, and, by using the
              Service, you do so at your own risk.</P>

            <h3 className={classes.textTitle}>
              WHAT CHOICES DO I HAVE REGARDING THE COLLECTION, DISCLOSURE AND DISTRIBUTION OF PERSONALLY IDENTIFIABLE INFORMATION?
            </h3>
            <P>
              Unless specifically authorized by you, we will only use and disclose any information we gather from you
              through the Service as set forth in this Privacy Policy or the Subscription Terms or as otherwise
              disclosed at the time we request such information from you. You may change your preference and opt out of
              receiving certain marketing communications from us by changing that option in "My Account" or by
              contacting <A mailto>privacy@artbnk.com</A>.
            </P>
            <P>
              Under certain circumstances and in compliance with the GDPR, you have the right to:
            </P>
            <P>
              <B>Request access</B> to your personally identifiable information (commonly known as a ‘subject access request’).
              This enables you to receive a copy of the personally identifiable information we hold about you and to
              check that we are lawfully processing it;
            </P>
            <P>
              <B>Request correction</B> of the personally identifiable information that we hold about you. This enables you to
              have any incomplete or inaccurate information we hold about you corrected;
            </P>
            <P>
              <B>Request erasure</B> of your personally identifiable information. This enables you to ask us to delete or
              remove personally identifiable information where there is no good reason for us continuing to process it.
              You also have the right to ask us to delete or remove all of your personally identifiable information in
              certain circumstances;
            </P>
            <P>
              <B>Object to processing</B> of your personally identifiable information where we are relying on a legitimate
              interest (or those of a third party) and there is something about your particular situation which makes
              you want to object to processing on this ground;
            </P>
            <P>
              <B>Request the restriction of processing</B> of your personally identifiable information. This enables you to
              ask us to suspend the processing of personally identifiable information, for example if you want us to
              establish its accuracy or the reason for processing it;
            </P>
            <P>
              <B>Request the transfer</B> of your personally identifiable information to another party; and
            </P>
            <P>
              <B>Lodge a complaint</B> with the relevant supervisory authority (as defined in the GDPR). If you have any
              complaints about the way we process your personally identifiable information please do contact us.
              Alternatively you may lodge a complaint with the supervisory authority which is established in your country.
            </P>
            <P>
              You can request any of the above by accessing "My Account" or
              contacting <A mailto>privacy@artbnk.com</A>. Such updates,
              corrections, changes and deletions will have no effect on other information that we maintain,
              or information that we have provided to third parties in accordance with this Privacy Policy prior to
              such update, correction, change or deletion. To protect your privacy and security, we may take reasonable
              steps (such as requesting a unique password) to verify your identity before granting you profile access or
              making corrections. You are responsible for maintaining the secrecy of your unique password and account
              information at all times.
            </P>
            <P>
              You should be aware that it is not technologically possible to remove each and every record of the
              information you have provided to us from our system. The need to back up our systems to protect
              information from inadvertent loss means that a copy of your personally identifiable information may exist
              in a non-erasable form that will be difficult or impossible for us to locate. After receiving your
              request, we will use commercially reasonable efforts to update, correct, change, or delete, as
              appropriate, all personally identifiable information stored in databases we actively use and other readily
              searchable media as appropriate, as soon as and to the extent reasonably practicable.
            </P>

            <h3 className={classes.textTitle}>DO NOT TRACK</h3>
            <P>The term “Do Not Track” refers to a HTTP header offered by certain web browsers
              to request that websites refrain from tracking the user. If you have enabled Do Not Track on your browser,
              we will not record any data from you. If you have not enabled Do Not Track on your browser, then we may
              collect non-personally identifiable visitation data in order to customize and optimize your visit to the
              Site.</P>

            <h3 className={classes.textTitle}>HOW WILL I KNOW IF THERE ARE ANY CHANGES TO THIS PRIVACY POLICY?</h3>
            <P>
              We may revise this Privacy Policy from time to time.  We will not make changes
              that result in significant additional uses or disclosures of your personally identifiable information
              without allowing you to opt out of such changes. We may also make non-significant changes to this Privacy
              Policy that generally will not significantly affect our use of your personally identifiable information,
              for which your opt-out is not required. We encourage you to check this page periodically for any changes.
              If any non-significant changes to this Privacy Policy are unacceptable to you, you must immediately
              contact us and, until the issue is resolved, stop using the Service. Your continued use of the Service
              following the posting of non-significant changes to this Privacy Policy constitutes your acceptance of those changes.
            </P>

            <h3 className={classes.textTitle}>ADDITIONAL PROVISIONS FOR PERSONAL INFORMATION RECEIVED FROM THE EUROPEAN
              UNION (INCLUDING SWITZERLAND).</h3>
            <P>In addition to the other provisions of this Privacy Policy, with respect to
              personal information received from the European Union (including Switzerland), the following provisions apply:</P>
            <h4 className={classes.subTitle}>Types of Personal Information Collected</h4>
            <P>As described above, personal information is information relating to an
              identified or identifiable natural person in the European Union.
              The types of personal information we collect could include personally identifiable
              as well as non-personally identifiable information, such as name, password and email address, IP
              addresses, browser types, unique device identifiers, device types, requested URL, referring URL, browser
              language, the pages you view, the date and time of your visit, and domain names.</P>
            <h4 className={classes.subTitle}>Opt-out Choices</h4>
            <P>If you no longer wish to have your personal information disclosed to third
              parties, you may choose to opt-out by notifying us that you want your personal information removed. To do
              so, send an email
              to <A mailto>privacy@artbnk.com</A>. Please be
              aware that your personal information may have been
              disclosed to third parties prior to removal. Also, ARTBNK will provide you with notice before using your
              personal information for a purpose other than that for which it was originally collected or subsequently
              authorized by you, and you may choose to opt-out of such use by following the directions provided in the
              notice. However, even after any opt-out or removal of your personal information, we reserve the right to
              disclose your personal information to a third party when we believe in good faith that we are required to
              do so in order to comply with an applicable statute, regulation, rule or law, a subpoena, a search
              warrant, a court or regulatory order, lawful requests by public authorities, including to meet national
              security or law enforcement requirements, or other valid legal process.</P>

            <h4 className={classes.subTitle}>Onward Transfer</h4>
            <P>Before we disclose any of your transfer data to a third party we will
              require that such third party provide the same level of privacy protection as is required by the Privacy
              Shield Principles. ARTBNK’s accountability for transfer data that it receives under the Privacy
              Shield and transfers to a third party is outlined in the Privacy Shield Principles. In particular, ARTBNK
              remains liable under the Privacy Shield Principles if third-party agents that it retains to process
              transfer data on ARTBNK’s behalf process such transfer data in a manner inconsistent with
              the Privacy Shield Principles, unless ARTBNK can prove that it is not responsible for the event giving
              rise to the damage.</P>
            <h4 className={classes.subTitle}>Data Integrity and Purpose Limitation</h4>
            <P>Except as may be authorized by you, we use your personal information in a way
              that is compatible with and relevant for the purpose for which it was collected. To the extent necessary
              for these purposes, we take reasonable steps to ensure that personal information is accurate, complete,
              current, and reliable for its intended use.</P>
            <h4 className={classes.subTitle}>Access</h4>
            <P>You have the right to access your personal information held by us and you may
              correct, amend, or delete such personal information, to the extent that it is inaccurate or, with
              respect to transfer data, has been
              processed in violation of the Principles, except where the burden or expense of providing access would be
              disproportionate to the risks to your privacy in the case in question or where the rights of persons other
              than the individual would be violated or as otherwise described in the
              GDPR or, with respect to transfer data, as otherwise described in the Privacy Shield
              Principles. If you wish to access
              your personal information, please contact us in either of the ways described below (under Recourse,
              Enforcement, and Liability).</P>
            <h4 className={classes.subTitle}>Recourse, Enforcement, and Liability</h4>
            <P>ARTBNK uses the self-assessment method to verify the attestations and assertions
              made herein and to ensure that its privacy practices have been implemented as presented herein. Any
              questions, complaints, or concerns regarding this Privacy Policy should be directed to ARTBNK at the
              e-mail or postal/courier address referenced in the “Who Do I Contact If I Have Any Privacy Questions?”
              section below. ARTBNK will investigate and attempt to resolve any disputes/complaints, including
              correcting any personal information, using transfer data consistent with the Privacy Shield
              Principles and U.S.-Swiss Safe Harbor Privacy Principles, reversing or correcting the effects of
              noncompliance, and assuring that future processing of personal information will be in conformity with this
              Policy, including, with respect to transfer data, the Privacy Shield Principles and
              U.S.-Swiss Safe Harbor Privacy Principles.</P>
            <P>For any complaints that cannot be resolved by ARTBNK directly, ARTBNK agrees to
              cooperate and comply with the EU Data Protection Authorities (DPAs) and the Swiss Federal Data Protection
              and Information Commissioner (FDPIC) to investigate unresolved disputes/complaints. With respect to the application of
              Privacy Shield Principles to transfer data, the panel established by the DPAs is the independent dispute resolution body
              designed to address complaints and provide appropriate recourse to you free of charge.</P>
            <P>As further described in the Privacy Shield Principles, a binding arbitration
              option will be made available to you in order to address residual complaints regarding transfer data
              that have not been resolved
              by other means. ARTBNK is subject to the investigatory and enforcement powers of the Federal Trade
              Commission.</P>
            <P>Adherence by ARTBNK to the Principles and the above-set forth provisions
              regarding transfer data may be
              limited (a) to the extent necessary to meet national security, public interest or law enforcement
              requirements; (b) by statute, government regulation, or case law that creates conflicting obligations or
              explicit authorizations; or (c) if the effect of the GDPR or Member State
              law is to allow exceptions or derogations, provided that such exceptions or derogations are applied in
              comparable contexts.</P>

            <h3 className={classes.textTitle}>WHO DO I CONTACT IF I HAVE ANY PRIVACY QUESTIONS?</h3>
            <P>If you have any questions or comments about this Privacy Policy, please contact
              our Privacy Agent in any of the following ways:</P>
            <P>By e-mail:</P>
            <P>
              <A mailto className={classes.bold}>privacy@artbnk.com</A>
            </P>
            <P>By postal mail or courier:</P>
            <P><B>Rob Steinberg, CEO</B></P>
            <P><B>ARTBnk LLC</B></P>
            <P><B>98 Penhallow Street</B></P>
            <P><B>Portsmouth, NH 03801</B></P>
            <h3 className={classes.textTitle}>BY USING THE SERVICE, YOU SIGNIFY YOUR ACCEPTANCE OF THIS PRIVACY POLICY.
              IF YOU DO NOT AGREE TO THIS PRIVACY POLICY, YOU SHOULD NOT USE THE SERVICE. CONTINUED USE OF THE SERVICE,
              FOLLOWING THE POSTING OF CHANGES TO THIS PRIVACY POLICY THAT DO NOT SIGNIFICANTLY AFFECT THE USE OR
              DISCLOSURE OF YOUR PERSONALLY IDENTIFIABLE INFORMATION, MEANS THAT YOU ACCEPT THOSE CHANGES.</h3>
          </div>

          <div className={classes.footer}>
            <button className={cx(classes.button, 'Popup-Privacy cancel')} onClick={this.onCancel} children="Cancel" />
            <button className={cx(classes.button, 'Popup-Privacy submit')} onClick={this.onAccept} children="I agree" />
          </div>
        </div>
      </Popup>
    );
  }
}

export default compose(
  connect({
    isPopupVisible: isPopupVisibleSelector,
    hidePopup: hidePopupAction,
    onAccept: privacyAcceptAction,
  }),
  injectSheet(sheet),
)(PrivacyPopup);
