import React from 'react';
import PropTypes from 'prop-types';
import I from 'immutable';
import InfiniteScroll from 'react-infinite-scroller';
import Loader from 'components/loader/Loader';
import Item from './Item';

import injectSheet from 'lib/sheet';
import { sheet } from './sheet';

class AlertsList extends React.PureComponent {
  static propTypes = {
    list: PropTypes.instanceOf(I.List),
    classes: PropTypes.object.isRequired,
    children: PropTypes.node,
    pagination: PropTypes.shape({
      total: PropTypes.number,
      pages: PropTypes.number,
      page: PropTypes.number,
      loading: PropTypes.bool,
    }).isRequired,
    loadMore: PropTypes.func.isRequired,
  };

  get loader() {
    const { pagination } = this.props;
    const modifier = pagination.loading && pagination.total > 0 ? 'infiniteScroll_LoadMore' : 'infiniteScroll_FirstPage';
    return <Loader key="loader" loading modifier={modifier} />;
  }

  render() {
    const { classes, list, children, pagination, loadMore } = this.props;
    if (list.size === 0 && !pagination.loading) return children;
    return (
      <InfiniteScroll
        className={classes.AlertsList}
        loadMore={loadMore}
        hasMore={pagination.page < (pagination.pages - 1) || (pagination.loading && pagination.page === 0)}
        loader={this.loader}
        element="ul"
      >
      
        {
          this.props.list.map(alert => (
            <Item
              key={alert.getIn(['artist', 'id'])}
              alert={alert}
              modifier="AlertsList"
            />
          ))
        }
      </InfiniteScroll>
    );
  }
}

export default injectSheet(sheet)(AlertsList);
