import { select, put } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
  location_sel,
  routeName_sel,
} from 'domain/router/RouterModel';
import { isTrackingLink } from 'lib/helpers';

export function* reloadPage() {
  const { pathname, search } = yield select(location_sel);
  yield put(push(pathname+search));
}

export function* isTrackingPage() {
  const name = yield select(routeName_sel);
  return isTrackingLink(name);
}
