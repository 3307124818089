import React from 'react';
import PropTypes from 'prop-types';
import GlobalSearch from 'pages/common/GlobalSearch';
import { connect } from 'cpcs-reconnect';
import { compose } from 'redux';
import Profile from 'pages/common/header/profile';
import Upgrade from 'pages/common/header/upgrade';
import { Menu } from 'pages/common/header/menu';
import Popup from 'components/popup';
import { mobileMenuOpenedSel } from 'domain/ui/UIModel';
import { toggleMobileMenuAction } from 'domain/ui/UIActions';

import injectSheet from 'lib/sheet';
import sheet from './sheet';

function Header({ classes, mobileMenuOpened, toggleMobileMenu }) {
  return (
    <header className={classes.Header}>
      <Menu/>
      <div className={classes.headerRight}>
        <GlobalSearch />
        <Upgrade />
        <Profile />
      </div>
      {
        mobileMenuOpened &&
          <Popup modifier="mobileMenu" cancel={() => toggleMobileMenu()}>
            <div className={classes.mobileDropdown}>
              <Menu modifier="mobileMenu" mobileMenu />
            </div>
          </Popup>
      }
    </header>
  );
}

Header.propTypes = {
  classes: PropTypes.shape({
    Header: PropTypes.string.isRequired,
    headerRight: PropTypes.string.isRequired,
    mobileDropdown: PropTypes.string.isRequired,
  }).isRequired,
  mobileMenuOpened: PropTypes.bool,
  toggleMobileMenu: PropTypes.func.isRequired,
};

export { Header as HeaderTest };

export default compose(
  injectSheet(sheet),
  connect({
    mobileMenuOpened: mobileMenuOpenedSel,
    toggleMobileMenu: toggleMobileMenuAction,
  }),
)(Header);
