import React, { useCallback, useEffect, useState, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'cpcs-reconnect';
import cx from 'classnames';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';

import { PreloadingTabsContext } from 'components/reports/PreloadingTabs';
import Fieldset from 'components/fieldset';
import { Definition } from 'components/reports/Definition';
import { chartsHash } from 'components/reports/Chart/config';
import { ILLIQUID } from 'domain/const';
import { usePDFEvents, useListen } from 'pages/highcharts/helpers/EventsContext';
import { EVENT_GET_CHART_REF } from 'pages/highcharts/helpers/PDFConstants';
import { pureComponent } from 'pages/highcharts/helpers/pureComponent';

import { token_sel } from 'domain/env/EnvModel';

// <T ticket={chart.ticket} />
// eslint-disable-next-line no-unused-vars
import { T } from 'components/reports/Ticket';
import { useOptions } from 'components/reports/Chart/useOptions';
import { useFetch } from 'components/reports/Chart/useFetch';

import sheet from 'components/reports/Chart/sheet.js';
import injectSheet from 'lib/sheet';

const getProps = (o, props) => props.reduce((prev, name) => ({ ...prev, [name]: o[name] }), {});

function Chart(props) {
  const { token, filter = {}, classes, externalOpened, setExternalOpened, onResponseSuccess, updateOptions,
    updateOptionsTrigger, artistReport } = props;
  const insidePreloadingTabs = useContext(PreloadingTabsContext);
  const [updatedOptions, setUpdatedOptions] = useState();
  const { chartId } = props;
  const chart = chartsHash[chartId];
  const ref = useRef({ unmounted: false, freezeTooltip: null });
  const apiProps = getProps(props, chart.apiProps);
  // pdf Events
  const chartRef = useRef();
  const PDFEvents = usePDFEvents();
  if (PDFEvents) PDFEvents.dispatch('Chart::render', { chartId });
  const onGetRef = useCallback((event) => {
    if (!event.chartId || event.chartId === chartId) {
      return chartRef;
    }
  }, [chartId, chartRef]);
  useListen(EVENT_GET_CHART_REF, onGetRef);

  /* track unmounted state */
  useEffect(() => {
    return () => {
      const { current } = ref;
      current.unmounted = true;
    };
  }, []);

  /* fetch resp */
  const resp = useFetch({ ref, token, chart, chartId, apiProps, filter, onResponseSuccess, artistReport });
  // ========================

  /* set options on resp */
  const { options, onTootipClose, tooltip } = useOptions(ref, resp, chart, chartId, props);
  // ========================

  /* set updatedOptions on options || updateOptionsTrigger */
  useEffect(() => {
    if (ref.current.unmounted) return;
    if (updateOptions && options) {
      setUpdatedOptions(updateOptions({ options }));
    } else {
      setUpdatedOptions(options);
    }
  }, [updateOptionsTrigger, options, updateOptions]);
  const { TooltipComponent } = chart;
  const noData = props.liquidity && props.liquidity.liquidity === ILLIQUID;
  if (noData) {
    if (props.hideIlliquid) return null;
  }
  return (
    <div className={cx(classes.Chart, { insidePreloadingTabs })} data-chart-id={chartId} data-ticket={chart.ticket}>
      {/* * /}
      <div>{chartId.toUpperCase()}</div>
      #{chartId.toUpperCase()} <span title={chart.subtitle}>"{chart.title}"</span> <T ticket={chart.ticket} />
      {/* */}
      {/** /
        chart.demo && chart.demo.map(([title, link]) => (
          <a href={link} key={link}>
            {title}
          </a>
        ))
      }
      {/* */}
      <div className={classes.content}>
        <div className={classes.chart}>
          {!noData && (props.chartUpper || null)}
          {
            !!noData &&
              <div className={classes.unavailable}>
                <h3 className={classes.unavailableTitle}>Required data is not available</h3>
                <p className={classes.unavailableDescription}>This Artist doesn’t have the minimum amount of repeat sales required to generate this graph.</p>
              </div>
          }
          {
            !!updatedOptions && !noData &&
              <HighchartsReact
                highcharts={Highcharts}
                options={updatedOptions}
                constructorType={chart.stockChart ? 'stockChart' : 'chart'}
                ref={chartRef}
              />
          }
          {!noData && (props.chartUnder || null)}
          {
            !!tooltip && !noData &&
              <TooltipComponent tooltip={tooltip} classes={classes} onClose={onTootipClose} chartId={chartId} />
          }
        </div>
        <div className={classes.definitions}>
          <Fieldset
            title="About this chart"
            RootTag="section"
            opened={externalOpened}
            onToggleCollapse={setExternalOpened}
            followExternal={!!setExternalOpened}
            modifier="report_definitions"
          >
            {
              !!chart.definitions && chart.definitions.map(definition => (
                <Definition
                  key={`${chartId}-${definition}`}
                  id={definition}
                />
              ))
            }
          </Fieldset>
        </div>
      </div>
    </div>
  );
}

Chart.propTypes = {
  token: PropTypes.string.isRequired,
  filter: PropTypes.object,
  chartId: PropTypes.string.isRequired,
  externalOpened: PropTypes.bool,
  setExternalOpened: PropTypes.func,
  classes: PropTypes.shape({
    Chart: PropTypes.string.isRequired,
    chart: PropTypes.string.isRequired,
    definitions: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    unavailable: PropTypes.string.isRequired,
    unavailableTitle: PropTypes.string.isRequired,
    unavailableDescription: PropTypes.string.isRequired,
  }).isRequired,
  updateOptionsTrigger: PropTypes.any,
  updateOptions: PropTypes.func,
  chartUpper: PropTypes.node,
  chartUnder: PropTypes.node,
  onResponseSuccess: PropTypes.func,
  liquidity: PropTypes.shape({
    liquidity: PropTypes.string,
  }),
  hideIlliquid: PropTypes.bool,
  artistReport: PropTypes.bool,
};

Chart = compose(
  pureComponent,
  injectSheet(sheet),
  connect({
    token: token_sel,
  }),
)(Chart);

function EnsureProps(props) {
  const { chartId } = props;
  const chart = chartsHash[chartId];
  if (!chart) return <div>{chartId} has no config</div>;
  return <Chart {...props} />;
}
EnsureProps.propTypes = {
  chartId: PropTypes.string.isRequired,
};

export { EnsureProps as Chart };
