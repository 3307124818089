import { loader } from 'theme/loader';

export default {
  Loader: {
    position: 'relative',
    height: 250,
    '&.infiniteScroll_FirstPage': {
      width: '100%',
    },
    '&.infiniteScroll_LoadMore': {
      width: '100%',
      height: 100,
      '&.comparables': {
        height: 225,
      },
    },
    '&.AddArtUnifiedPage': {
      width: '100%',
      height: 50,
      alignSelf: 'center',
    },
    '&.DownloadingModal': {
      height: 50,
      width: 50,
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -25,
      marginLeft: -25,
      '&.noTable': {
        position: 'relative',
        top: 'unset',
        left: 'unset',
        margin: 0,
        height: 100,
        width: 'auto',
      },
    },
  },
  ...loader,
  loader: {
    ...loader.loader,
    '&.infiniteScroll_LoadMore': {
      top: 85,
      '&.comparables': {
        top: 85,
      },
    },
    '&.SharesPage': {
      top: 125,
    },
    '&.AddArtUnifiedPage': {
      top: 0,
    },
    '&.DownloadingModal': {
      top: 40,
    },
  },
};
