import React from 'react';
import PropTypes from 'prop-types';

import starBg from 'pages/common/header/menu/star.svg';
import {
  DEFAULT_FONTFAMILY,
  SUBSCRIPTION_PLAN_MOST_POPULAR_COLOR,
} from 'theme/theme';

import injectSheet from 'lib/sheet';

const sheet = {
  MostPopular: {
    fontFamily: DEFAULT_FONTFAMILY,
    fontWeight: 400,
    fontSize: '14px',
    color: SUBSCRIPTION_PLAN_MOST_POPULAR_COLOR,
    paddingLeft: 22,
    background: {
      position: 'left center',
      image: `url("${starBg}")`,
      repeat: 'no-repeat',
    },
    whiteSpace: 'nowrap',
  },
};

function MostPopular({ classes }) {
  return <div className={classes.MostPopular} children="Most Popular" />;
}

MostPopular.propTypes = {
  classes: PropTypes.shape({
    MostPopular: PropTypes.string.isRequired,
  }).isRequired,
};

export default injectSheet(sheet)(MostPopular);
