import React, { useReducer, useRef, useCallback, useContext } from 'react';

// enhancedType, decimal, integer
import record, { listOf, integer } from 'cpcs-recordjs';
import { items as artistReportItems } from 'pages/highcharts/mock/artistReportItems';
import { items as artworkReportItems } from 'pages/highcharts/mock/artworkReportItems';
import { contentSections } from 'pages/highcharts/mock/contentSections';
import {
  REPORT_TYPE_ARTIST,
  REPORT_TYPE_ARTWORK,
  SET_ARTIST_LIQUIDITY,
  // EVENT_PREVIEW_CLOSED,
} from 'pages/highcharts/helpers/PDFConstants';

// layer Positions
// store size & positions of all items
// will be updated after each item will be rendered / hidden/shown, Page hidden/shown

// pages: coverPage, introductionPage, 

// component:
// onRemove, onRecover, renderToPDF, getSize, setPosition, setPage, getPage, getByPage, def

// const ImmutableMap = enhancedType({
//   typeName: 'Map',
//   defaultValue: I.fromJS({}),
//   parse(value) {
//     // return I.fromJS(value || {});
//     return value;
//   },
//   // operations: {
//   //   // pass
//   // },
// });
const Metadata = record('', {
  width: integer,
  height: integer,
  top: integer,
  left: integer,
});

const Item = record('SectionItem', {
  title: '',
  // p001, intro, tableOfContents, ArtistRankings, ARTBnkIndexes, ContactsPDF, DisclaimerPDF
  name: '',
  // p001, p002, ... (other component with any type)
  parent: '',
  component: '',
  hidden: false,
  // coverPage, intro, AODetails, financialPerformance
  contentSection: '',
  // chartSvg: '',
  order: 0,
  // assume: chart, note, artworkTitle, group-<groupName>, provenance
  // real: CONTENT_TYPE_CUSTOM || ''
  contentType: '',
  removable: true,
  useAsBg: false,
  metadata: Metadata.parse({}),
  customMarginTop: 0,
  customMarginBottom: 0,
  customMarginLeft: 0,
  customWidth: 0,
  // x: 0, y: 0 store in different layer
  // width, height store in different layer
});

const ContentSection = record('ContentSection', {
  title: '',
  name: '',
  description: '',
  index: 0,
  bg: '',
  hideFromContentTable: false,
});

const PDFConfigurator = record('PDFConfigurator', {
  items: listOf(Item),
  contentSections: listOf(ContentSection),
  reportType: '',
  liquidity: '',
  rtvShown: true,
});

const defaultState = PDFConfigurator.parse({
  items: [],
  contentSections,
  reportType: '',
  liquidity: '',
});

const artistReportState = PDFConfigurator.parse({
  items: artistReportItems,
  contentSections,
  reportType: REPORT_TYPE_ARTIST,
  liquidity: '',
});

const artworkReportState = PDFConfigurator.parse({
  items: artworkReportItems,
  contentSections,
  reportType: REPORT_TYPE_ARTWORK,
  liquidity: '',
});

const $updateHidden = (state, action) => state.update(
  PDFConfigurator.$items.update(
    items => {
      let contentSection = '';
      return items.map(item => {
        if (item.name === action.payload && item.component === 'SectionHeader') {
          contentSection = item.contentSection;
        }
        const matchedSection = contentSection && item.contentSection === contentSection;
        const matchedName = item.name === action.payload;
        const matchedParent = item.parent === action.payload;
        if (matchedName || matchedParent || matchedSection) {
          return item.set('hidden', action.type === 'HIDE_ITEM');
        }
        return item;
      });
    },
  ),
);

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    /* clear state * /
    case EVENT_PREVIEW_CLOSED:
      /* solution 1 * /
      return state.apply(
        PDFConfigurator.$rtvShown.set(true),
        PDFConfigurator.$items.map(Item.$hidden.set(false)),
      );
      /**/
      /* solution 2* /
      if (state.reportType === REPORT_TYPE_ARTWORK) ret = artworkReportState;
      if (state.reportType === REPORT_TYPE_ARTIST) ret = artistReportState;
      return ret.apply(
        PDFConfigurator.$liquidity.parsed(state.liquidity),
      );
      /**/
    /**/
    case 'TOGGLE_RTV':
      return state.update(
        PDFConfigurator.$rtvShown.toggle(),
      );
    case SET_ARTIST_LIQUIDITY:
      return state.update(
        PDFConfigurator.$liquidity.parsed(action.payload.liquidity),
      );
    case 'HIDE_ITEM':
    case 'SHOW_ITEM':
      return $updateHidden(state, action);

    default:
      return state;
  }
};

export const PDFStoreContext = React.createContext(defaultState);

const reportStates = {
  REPORT_TYPE_ARTWORK: artworkReportState,
  REPORT_TYPE_ARTIST: artistReportState,
};

export const initPDFStore = reportType => (Component) => {
  function PDFStoreProvider(props) {
    const [state, dispatch] = useReducer(reducer, reportStates[reportType]);
    const wrappedDispatch = useCallback((...args) => {
      if (global.ARTBnkLogger) {
        const [{ type, payload }] = args;
        global.ARTBnkLogger.dispatch(`dispatch ${type}`, payload);
      }
      dispatch(...args);
    }, []);
    const PDFStore = useRef({ state, dispatch: wrappedDispatch });
    if (PDFStore.current.state !== state) {
      PDFStore.current.state = state;
    }
    if (PDFStore.current.dispatch !== wrappedDispatch) {
      PDFStore.current.dispatch = wrappedDispatch;
    }
    return (
      <PDFStoreContext.Provider value={PDFStore.current}>
        <Component {...props} PDFState={state} PDFStore={PDFStore.current} />
      </PDFStoreContext.Provider>
    );
  }
  return PDFStoreProvider;
};

export const connectPDFStore = (Component) => {
  function PDFStoreProvider(props) {
    return (
      <PDFStoreContext.Consumer>
        {
          (value) => (
            <Component {...props} PDFState={value.state} PDFStore={value} />
          )
        }
      </PDFStoreContext.Consumer>
    );
  }
  return PDFStoreProvider;
};

export function usePDFStore() {
  const PDFStore = useContext(PDFStoreContext);
  return { PDFStore, state: PDFStore.state, dispatch: PDFStore.dispatch };
}
