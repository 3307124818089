import { select } from 'redux-saga/effects';
import { location_sel, prevLocationSelector } from 'domain/router/RouterModel';
import { isArray, isObject } from 'lib/helpers';

const arrayDiff = (a, b) => {
  const aSort = [...a].sort();
  const bSort = [...b].sort();
  const [iter, search] = aSort.length > bSort.length ? [aSort, bSort] : [bSort, aSort];
  return iter
    .reduce((r, val) => (search.find((item) => item === val) ? r : [...r, val]), []);
};

const objecKeysDiff = (a, b) => Object.keys({ ...a, ...b }).reduce((r, key) => {
  if (isArray(a[key]) && isArray(b[key])) {
    return arrayDiff(a[key], b[key]).length > 0 ? [...r, key] : r;
  }
  return a[key] !== b[key] ? [...r, key] : r;
}, []);

const keysDiff = (a, b) => {
  if (isArray(a) && isArray(b)) {
    return arrayDiff(a, b);
  }
  if (isObject(a) && isObject(b)) {
    return objecKeysDiff(a, b);
  }
  return a === b ? [a, b] : [];
};

export function* needResetSelected() {
  const p = yield select(prevLocationSelector);
  const c = yield select(location_sel);
  if (!p) return false;
  if (p.pathname !== c.pathname) return true;
  return keysDiff(p.query, c.query).filter(key => !['page'].includes(key)).length > 0;
}

export function* pathNameChanged() {
  const p = yield select(prevLocationSelector);
  const c = yield select(location_sel);
  if (!p) return true;
  if (!p.match) return false;
  return p.match.name !== c.match.name;
}
