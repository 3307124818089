import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'cpcs-reconnect';
import { compose } from 'redux';
import I from 'immutable';
import { removeShareAction } from 'domain/shares/ShareActions';
import { confirmAction } from 'domain/ui/UIActions';
import injectSheet from 'lib/sheet';
import styles from './sheet.js';

const confirmQuestion = 'Are you sure?';

class Revoke extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.shape({
      Revoke: PropTypes.string.isRequired,
      revokeTxt: PropTypes.string.isRequired,
    }).isRequired,
    className: PropTypes.string.isRequired,
    item: PropTypes.instanceOf(I.Record).isRequired,
    askBefore: PropTypes.func.isRequired,
  };

  onRemove = () => {
    const { askBefore, item } = this.props;
    askBefore(confirmQuestion, removeShareAction, { id: item.get('id') });
  }

  render() {
    const { classes, className } = this.props;
    return (
      <div
        className={`${classes.Revoke} ${className}`}
        onClick={this.onRemove}
      >
        <div
          className={classes.revokeTxt}
          children={'Revoke'}
        />
      </div>
    );
  }
}

export default compose(
  connect({
    askBefore: confirmAction,
  }),
  injectSheet(styles),
)(Revoke);
