import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import accepts from 'attr-accept';
import injectSheet from 'lib/sheet';
import sheet from './sheet';

const MB = 1024 * 1024;

let images = 0;
let acceptedFiles = [];
let rejectedFiles = [];

const sizeError = 'image size should be less then 15 Mb';
const mimeError = 'Sorry, we do not support this type of format';
const dimensionError = 'image resolution should be 300x300px or more';

export function cutName(name = ''){
  if (name.length < 24) return name;
  return name.substr(0, 10) + '...' + name.substr(-10);
}

class FieldDropZone extends React.PureComponent {

  static propTypes = {
    input: PropTypes.shape({
      onChange: PropTypes.func,
      name: PropTypes.string,
    }).isRequired,
    classes: PropTypes.shape({
      FieldDropZone: PropTypes.string,
      zone: PropTypes.string,
    }).isRequired,
    meta: PropTypes.shape({
      touched: PropTypes.bool,
      error: PropTypes.string,
    }).isRequired,
    children: PropTypes.node,
    multiple: PropTypes.bool,
    accept: PropTypes.string,
  }

  static defaultProps = {
    multiple: false,
  }

  preLoadImage = (file) => {
    images++;
    const img = new Image();
    img.onload = ({ target: e }) => {
      if (e.width < 300 || e.height < 300){
        rejectedFiles.push({ name: cutName(file.name), err: dimensionError });
      } else {
        acceptedFiles.push(file);
      }
      images > 1 ? images-- : this.change(); //NOSONAR
    };
    img.src = file.preview;
  }

  change = () => {
    images = 0;
    this.props.input.onChange({ acceptedFiles, rejectedFiles });
    acceptedFiles = [];
    rejectedFiles = [];
  }

  onChange = (af, rf) => {
    const { accept } = this.props;
    if (rf.length) rejectedFiles = rf.map(f => {
      let err;
      if (accept && !accepts(f, accept)) {
        err = mimeError;
      } else {
        err = sizeError;
      }
      return { name: cutName(f.name), err };
    });
    af.length === 0 ? this.change() : af.forEach(file => {
      Object.assign(file, {
        preview: window.URL.createObjectURL(file),
      });
      this.preLoadImage(file);
    });
  }

  render() {
    const { classes, input, meta, multiple, accept } = this.props;
    const style = {};
    return (
      <div className={classes.FieldDropZone}>
        <Dropzone
          multiple={multiple}
          accept={accept}
          onDrop={this.onChange}
          maxSize={15 * MB + 1}
        >
          {
            ({ getRootProps, getInputProps }) => (
              <div
                className={classes.zone}
                style={style}
                {...getRootProps()}
              >
                <input {...getInputProps()} name={input.name} />
                {
                  this.props.children
                }
              </div>
            )
          }
        </Dropzone>
        {
          meta.touched && meta.error ? (
            <span className="error">{meta.error}</span>
          ) : null
      }
      </div>
    );
  }
}

export default injectSheet(sheet)(FieldDropZone);
