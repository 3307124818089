import React from 'react';
import PropTypes from 'prop-types';
import I from 'immutable';
import { compose } from 'redux';
import { connect } from 'cpcs-reconnect';
import { stateSelector } from 'lib/helpers';
import { Field } from 'redux-form';
import { debounce } from 'lib/envGlobals';
import {
  searchArtistAction,
  artistById,
  groupedArtists,
  toOption,
} from 'domain/artist';
import { countryList } from 'domain/country';
import { artistEditable_sel } from 'pages/common/artworkForm/ArtworkFormModel';
import FormRowField from 'pages/common/newPage/form/elements/FormRowField';
import { FormName, getFormValues } from 'redux-form';
import ArtistSelector from 'pages/common/newPage/form/artist/acselector';
import Life from 'pages/common/newPage/form/artist/Life';
import Select from 'components/form/select';

import injectSheet from 'lib/sheet';
import sheet from './sheet';

class ArtistFieldset extends React.PureComponent {
  static propTypes = {
    groupedArtists: PropTypes.any,
    countryList: PropTypes.instanceOf(I.List).isRequired,
    artistById: PropTypes.instanceOf(I.Map).isRequired,
    suggestionSelected: PropTypes.bool,
    searchArtist: PropTypes.func.isRequired,
    classes: PropTypes.shape({
      fieldset: PropTypes.string.isRequired,
    }).isRequired,
    isEdit: PropTypes.bool,
    artistEditable: PropTypes.bool,
    state: PropTypes.object.isRequired,
    modifier: PropTypes.string,
  }

  searchArtistHandler = debounce(term => this.props.searchArtist({ term }), 500);

  componentDidMount = () => {
    const { searchArtist } = this.props;
    searchArtist({ term: '' });
  }

  renderContext = ({ form }) => {
    const { classes, suggestionSelected, isEdit, countryList, artistById, groupedArtists, artistEditable, state, modifier } = this.props;
    const { bornIn, diedIn, residence } = getFormValues(form)(state) || {};

    return (
      <fieldset className={classes.fieldset}>
        <ul>
          <ArtistSelector
            onInputChange={this.searchArtistHandler}
            list={groupedArtists}
            storage={artistById.toList().map(toOption).toJS()}
            disabled={suggestionSelected && isEdit}
            modifier={modifier}
          />
          {
            (artistEditable && (bornIn || diedIn)) &&
              <Life disabled modifier={modifier} />
          }
          {
            artistEditable && (residence && residence.join('')) &&
              <Field
                id="id-residence"
                name="residence"
                component={FormRowField}
                modifier={modifier}
                Field={Select}
                placeholder=""
                title="Associated Country"
                list={countryList}
                disabled
                isMulti
                isSearchable
              />
          }
        </ul>
      </fieldset>
    );
  }

  render() {
    return <FormName
      children={this.renderContext}
    />;
  }
}


ArtistFieldset = compose(
  injectSheet(sheet),
  connect({
    searchArtist: searchArtistAction,
    groupedArtists,
    artistById,
    countryList,
    artistEditable: artistEditable_sel,
    state: stateSelector,
  }),
)(ArtistFieldset);

export { ArtistFieldset };
