import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'cpcs-reconnect';
import { compose } from 'redux';
import cx from 'classnames';
import I from 'immutable';
import filtersConfig from 'pages/common/filters/config';
import { sqlDateToRedesign } from 'lib/helpers';

// import { setSelectedAction } from 'domain/ownArtwork/OwnArtworkActions';
// import { hasSelectedDrafts } from 'domain/ownArtwork/OwnArtworkModel';

import Filters from 'pages/common/filters';
import PageContainer from 'pages/common/pageContainer';
import {
  auction_sel,
  lotsList_sel,
  pagination_sel,
} from 'pages/saleLotsListPage/SaleLotsListPageModel';
import {
  fetchLotsAppendPageAction,
} from 'pages/saleLotsListPage/SaleLotsListPageActions';

// import SelectedBar from 'pages/collectionListPage/SelectedBar';
import ItemList from 'pages/common/ItemList';
import SharePopup from 'pages/common/popup/share';
import { lnk } from 'lib/routes';
import { sheet } from './sheet';
import injectSheet from 'lib/sheet';

class SaleLotsListPage extends React.Component {

  static propTypes = {
    // match: PropTypes.shape({
    //   params: PropTypes.shape({
    //     gId: PropTypes.string,
    //   }),
    //   url: PropTypes.string,
    // }).isRequired,
    // setSelected: PropTypes.func.isRequired,
    // hasSelectedDrafts: PropTypes.bool,
    lotsList: PropTypes.instanceOf(I.Collection),
    loadMore: PropTypes.func.isRequired,
    pagination: PropTypes.instanceOf(I.Collection),
    auction: PropTypes.instanceOf(I.Collection),
    classes: PropTypes.shape({
      PageHeader: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }).isRequired,
  };

  get title() {
    const { auction } = this.props;
    if (!auction) return null;
    // @todo {seller name} - {sale date}
    return [
      auction.get('seller'),
      sqlDateToRedesign(auction.get('saleAt')),
    ].filter(v => v).join(' - ');
  }


  buildLink = (item) => lnk('artworkPage', {
    artworkId: item.get('id'),
  });

  render() {
    const { classes, lotsList, loadMore, pagination } = this.props;
    return (
      <React.Fragment>
        <div className="PAGE_HEADER">
          {/** /}
          <SelectedBar
            match={this.props.match}
            setArt={this.props.setSelected}
            hasSelectedDrafts={this.props.hasSelectedDrafts}
          />
          {/**/}
          <header className={cx(classes.PageHeader, 'saleLotsListPage')}>
            <h1
              className={classes.title}
              children={this.title}
            />
          </header>
          <Filters
            sortByList={filtersConfig.saleLotsListPage}
            placeholder=""
            modifier="saleLotsListPage"
          />
        </div>
        <PageContainer modifier="saleLotsListPage">
          <ItemList
            // onSelect={this.props.updateSelected}
            // selectedIds={this.props.selectedIds}
            pagination={pagination}
            loadMore={loadMore}
            // onRemove={(ids) => this.props.onRemove({ type: TYPE_ALERT_ARTWORK, params: { alertId, ids } })}
            linkBuilder={this.buildLink}
            list={lotsList}
          />
          <SharePopup />
        </PageContainer>
      </React.Fragment>
    );
  }
}

export default compose(
  connect({
    // hasSelectedDrafts: hasSelectedDrafts,
    // setSelected: setSelectedAction,
    pagination: pagination_sel,
    lotsList: lotsList_sel,
    loadMore: fetchLotsAppendPageAction,
    auction: auction_sel,
  }),
  injectSheet(sheet),
)(SaleLotsListPage);
