import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import injectSheet from 'lib/sheet';
import styles from './sheet.js';
import googleApiDecorator from 'pages/authPage/form/login/googleLoginButton/googleApiDecorator';
import { onGoogleSignInAction } from 'domain/env/EnvActions';

class GoogleLoginButton extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    onLogin: PropTypes.func.isRequired,
    onLogout: PropTypes.func.isRequired,
    showLogoutButton: PropTypes.bool,
    showLoginButton: PropTypes.bool,
  };


  onClick = () => {
    const { showLogoutButton } = this.props;
    if (showLogoutButton) {
      this.props.onLogout();
    } else {
      this.props.onLogin();
    }
  }

  render() {
    const { classes, showLoginButton } = this.props;
    if (!showLoginButton) return null;
    return (
      <button
        className={classes.GoogleLoginButton}
        type="button"
        onClick={this.onClick}
      />
    );
  }
}

export {
  GoogleLoginButton,
};

export default compose(
  connect(null, {
    onGoogleSignIn: onGoogleSignInAction,
  }),
  googleApiDecorator,
  injectSheet(styles),
)(GoogleLoginButton);
