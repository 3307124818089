import React from 'react';
import PropTypes from 'prop-types';

import injectSheet from 'lib/sheet';
import { sheet } from './sheet';

function Terms({ children, classes }) {
  return (
    <span className={classes.bold}>“{children}”</span>
  );
};
Terms.propTypes = {
  children: PropTypes.any,
  classes: PropTypes.shape({
    bold: PropTypes.string.isRequired,
  }).isRequired,
};

const TermsStyled = injectSheet(sheet)(Terms);

function Bold({ children, classes }) {
  return (
    <b className={classes.bold}>{children}</b>
  );
};
Bold.propTypes = {
  children: PropTypes.any,
  classes: PropTypes.shape({
    bold: PropTypes.string.isRequired,
  }).isRequired,
};

const BoldStyled = injectSheet(sheet)(Bold);

function P({ children, classes }) {
  return (
    <p className={classes.text}>{children}</p>
  );
};
P.propTypes = {
  children: PropTypes.any,
  classes: PropTypes.shape({
    text: PropTypes.string.isRequired,
  }).isRequired,
};

const PStyled = injectSheet(sheet)(P);

function A({ children, classes, href, mailto, className }) {
  return (
    <a
      href={mailto ? `mailto:${href || children}` : (href || children)}
      target="_blank"
      rel="noopener noreferrer"
      className={`${classes.link}${className ? ' ' + className : ''}`}
      children={children || href}
    />
  );
};
A.propTypes = {
  children: PropTypes.string,
  href: PropTypes.string,
  className: PropTypes.string,
  mailto: PropTypes.bool,
  classes: PropTypes.shape({
    link: PropTypes.string.isRequired,
  }).isRequired,
};

const AStyled = injectSheet(sheet)(A);

export {
  TermsStyled as Terms,
  BoldStyled as Bold,
  PStyled as P,
  AStyled as A,
};
