export const SESSION_STORAGE = 'sessionStorage';
export const LOCAL_STORAGE = 'localStorage';

function getStorageType() {
  const storage = window.localStorage && window.localStorage.getItem('storage');
  if (storage === LOCAL_STORAGE) return storage;
  return SESSION_STORAGE;
}

export function setStorageType(type) {
  if (type !== LOCAL_STORAGE && type !== SESSION_STORAGE) return false;
  return window.localStorage.setItem('storage', type);
}

function getStorage() {
  return window[getStorageType()];
}

export function setStorageItem(key, value) {
  if (arguments.length !== 2 || typeof key !== 'string') return false;
  return getStorage().setItem(key, value);
}

export function getStorageItem(key, def = '') {
  const storage = getStorage();
  return (storage && storage.getItem(key)) || def;
}

export function clearStorage() {
  window.localStorage.clear();
  window.sessionStorage.clear();
}
