import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { lnk } from 'lib/routes';

function saleNumberSolver(val, data, name, props) {
  const { noLotLink } = props;
  if (noLotLink) return null;
  if (!data) return null;
  const lot = data.get('lot');
  if (!lot || !lot.get('saleNumber') || !lot.get('auctionId')) return null;
  const query = {
    auctionDateFrom: lot.get('saleAt'),
    auctionDateTo: lot.get('saleAt'),
  };
  return (
    <Link
      style={{ textDecoration: 'underline' }}
      to={lnk('saleLotsListPage', { auctionId: lot.get('auctionId'), saleId: lot.get('saleNumber'), query })}
      children="View all lots in this Auction"
      className="saleNumberSolver"
    />
  );
}

saleNumberSolver.propTypes = {
  noLotLink: PropTypes.bool,
};
export default saleNumberSolver;
