import React from 'react';
import PropTypes from 'prop-types';

import injectSheet from 'lib/sheet';
import sheet from './sheet';

function TagList({ list = [], onRemove, classes, disabled }) {
  return (
    <ul className={classes.list}>
      {
        list.map((e, index) => (
          <li key={`TL-item-${index}-${e}`} className={classes.item}>
            <span className={classes.itemContainer}>
              <span
                title={e}
                className={classes.element}
              >{e}</span>
              <button
                type="button"
                title="remove"
                className={classes.remove}
                disabled={disabled}
                onClick={e => onRemove(e, index)}
              />
            </span>
          </li>
        ))
      }
    </ul>
  );
}

TagList.propTypes = {
  list: PropTypes.arrayOf(PropTypes.string).isRequired,
  onRemove: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    item: PropTypes.string,
    itemContainer: PropTypes.string,
    element: PropTypes.string,
    remove: PropTypes.string,
    list: PropTypes.string,
  }).isRequired,
  disabled: PropTypes.bool,
};

export default injectSheet(sheet)(TagList);
