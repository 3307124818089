import record, { integer, string, listOf, enhancedType } from 'cpcs-recordjs';
import { field, selector } from 'cpcs-reconnect';
import { listToMap } from 'lib/serialize';
import { Artist, Pagination } from 'model';
import * as A from './AuthorActions';
import * as env from 'domain/env/EnvActions';

const integerOrSlug = enhancedType({
  typeName: 'IntegerOrSlug',
  parse(value) {
    return parseInt(value, 10) || ('' + value);
  },
});

const Valuation = record('Valuation', {
  value: integer(),
  unit: string(),
});
export const Author = record('Content', {
  author: Artist(),
  artWorksCount: integer(),
  totalRTV: Valuation(),
  picture: string(),
  pictures: listOf(string),
  id: integer(),
});

export const State = record('Authors', {
  content: listOf(Author),
  listPagination: Pagination,
  galleryId: integerOrSlug,
});

const base = field('authors');
export const authorsList = base.then(State.$content);
export const authorsById = selector(authorsList, l => listToMap(l, ['author', 'id']));

export const reducer = {
  authors(state = new State(), action) { //NOSONAR
    switch (action.type) {

      case env.logOut.type:
        return new State();

      case A.getAuthorsAction:
        return state.apply(
          State.$content.parsed(state.content.clear()),
          State.$galleryId.parsed(action.payload.galleryId),
        );

      case A.getAuthorsAction.success:
        return state.apply(
          State.$content.parsed(action.payload.content),
          State.$galleryId.parsed(action.galleryId),
          State.$listPagination.parsed(action.payload),
        );

      default:
        return state;
    }
  },
};
