import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { DEFAULT_FONTFAMILY_3 } from 'theme/theme';
import injectSheet from 'lib/sheet';


export const PreloadingTabsContext = React.createContext();

const sheet = {
  PreloadingTabs: {
    //
    borderBottom: '8px solid #EEE',
  },
  tabs: {
    display: 'flex',
    padding: [4, 8, 8],
    backgroundColor: '#F0F2F6',
    marginTop: 32,
    borderRadius: 4,
    width: 'max-content',
    '@media screen and (max-width: 610px)': {
      width: 210,
      flexDirection: 'column',
      alignItems: 'center',
      margin: [32, 'auto', 0],
    },
  },
  tab: {
    marginLeft: 10,
    color: '#454A5F',
    '&.active': {
      backgroundColor: '#FFF',
    },
    '&:first-child': {
      marginLeft: 0,
    },
    '@media screen and (max-width: 610px)': {
      marginLeft: 0,
      width: '100%',
      marginBottom: 6,
    },
  },
  tabButton: {
    padding: [4, 8],
    cursor: 'pointer',
    color: '#454A5F',
    textAlign: 'center',
    font: `500 14px/20px ${DEFAULT_FONTFAMILY_3}`,
    '&.active': {
      backgroundColor: '#FFF',
      color: '#1E202C',
    },
    '@media screen and (max-width: 610px)': {
      width: '100%',
    },
  },
  tabContent: {
    display: 'none',
    '&.active': {
      display: 'block',
    },
  },
  content: {
    //
  },
};

function PreloadingTabs({ children: tabs, classes, ...props }) {
  const [tab, setTab] = useState(0);
  return (
    <PreloadingTabsContext.Provider value>
      <div className={classes.PreloadingTabs}>
        <ul className={classes.tabs}>
          {
            tabs.map((Tab, index) => Tab && (
              <li
                key={`title-${Tab.props.chartId}`}
                className={cx(classes.tab, { active: tab === index })}
              >
                <button
                  type="button"
                  className={cx(classes.tabButton, { active: tab === index })}
                  onClick={() => setTab(index)}
                >
                  {Tab.title}
                </button>
              </li>
            ))
          }
        </ul>
        <div className={classes.content}>
          {
            tabs.map((Tab, index) => Tab && (
              <div
                className={cx(classes.tabContent, { active: tab === index })}
                key={`content-${Tab.props.chartId}`}
                data-content-id={`content-${Tab.props.chartId}`}
              >
                <Tab.Component {...props} {...Tab.props} />
              </div>
            ))
          }
        </div>
      </div>
    </PreloadingTabsContext.Provider>
  );
}

PreloadingTabs.propTypes = {
  children: PropTypes.array.isRequired,
  classes: PropTypes.shape({
    PreloadingTabs: PropTypes.string.isRequired,
    tabs: PropTypes.string.isRequired,
    tab: PropTypes.string.isRequired,
    tabButton: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    tabContent: PropTypes.string.isRequired,
  }).isRequired,
};

export default injectSheet(sheet)(PreloadingTabs);
