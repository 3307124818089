import { isEmpty } from 'lib/helpers';

function lotNumberSolver(val, data, name, props) {
  if (!data) return null;
  const lot = data.get('lot');
  if (!lot || isEmpty(lot.get('lotNumber'))) return null;
  if (props.onListBadge) return lot.get('lotNumber');
  return lot.get('lotNumber');
}
export default lotNumberSolver;
