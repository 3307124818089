import React from 'react';
import { connect } from 'cpcs-reconnect';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import rules from 'components/form/validation';
import { notAccurate_sel, fromSuggestions_sel } from 'pages/common/artworkForm/ArtworkFormModel';

function Pictures({ fields, notAccurate, fromSuggestions }) {
  return (
    <ul>
      {
        fields.map((e, index) => (
          <Field
            key={`pictures-${index}`}
            name={`pictures.${index}.name`}
            type="hidden"
            component="input"
            validate={index === 0 && !(notAccurate && fromSuggestions) ? rules.required : void 0}
          />
        ))
      }
    </ul>
  );
}

Pictures.propTypes = {
  fields: PropTypes.shape({
    map: PropTypes.func.isRequired,
  }).isRequired,
  notAccurate: PropTypes.bool,
  fromSuggestions: PropTypes.bool,
};

export default connect({
  notAccurate: notAccurate_sel,
  fromSuggestions: fromSuggestions_sel,
})(Pictures);
