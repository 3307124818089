import { popupComponents } from 'pages/common/popup/sheet';
import { button } from 'theme/button';

export const confirmationComponents = {
  ConfirmationPopup: {},
  container: {
    ...popupComponents.container,
    '&.unifiedAddArtPage': {
      width: 400,
    },
    '&.unsavedForm': {
      width: 410,
    },
  },
  btnGroup: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 15,
    marginRight: -15,
  },
  button,
  // accept: {
  //   ...Buttons.done,
  //   fontWeight: 500,
  //   margin: [0, 5],
  // },
  // cancel: {
  //   ...Buttons.cancel,
  //   fontWeight: 500,
  //   margin: [0, 5],
  // },
  description: {
    ...popupComponents.description,
    '& strong': {
      fontWeight: 500,
    },
  },
  noTitleTextLayout: {
    '&.noText': {
      display: 'flex',
      flexDirection: 'row-reverse',
      justifyContent: 'space-between',
      '& .description-layout': {
        marginRight: 15,
      },
    },
  },
};

export default {
  ...confirmationComponents,
  container: {
    ...confirmationComponents.container,
    width: 390,
  },
};
