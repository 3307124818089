export function getCroppedImgUrlBySrc(src, crop, fileName) {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.crossOrigin = 'anonymous';
    image.onload = () => {
      getCroppedImgUrl(image, crop, fileName).then(resolve).catch(reject);
    };
    image.onerror = () => reject(new Error('Can\'t load image "' + src + '"'));
    image.src = src;
  });
};

function getCroppedImgUrl(image, crop, fileName = 'newFile.jpeg') {
  return new Promise((resolve, reject) => {
    const canvas = document.createElement('canvas');
    // void ctx.drawImage(image, dx, dy);
    // void ctx.drawImage(image, dx, dy, dWidth, dHeight);
    // void ctx.drawImage(image, sx, sy, sWidth, sHeight, dx, dy, dWidth, dHeight);
    const width = Math.max(crop.x2 - crop.x1, 0);
    const height = Math.max(crop.y2 - crop.y1, 0);
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(
      image,
      crop.x1,
      crop.y1,
      width,
      height,
      0,
      0,
      width,
      height,
    );
    canvas.toBlob(blob => {
      if (!blob) {
        reject(new Error('Canvas is empty'));
        return;
      }
      blob.name = fileName;
      const fileUrl = window.URL.createObjectURL(blob);
      resolve(fileUrl);
    }, 'image/jpeg');
  });
}
