import record, { integer, listOf } from 'cpcs-recordjs';
import { field, selector } from 'cpcs-reconnect';
import { listToMap, toGroupedList } from 'lib/serialize';
import * as A from './MediumActions';
import { Medium } from 'model';

const State = record('Medium', {
  content: listOf(Medium),
  common: listOf(integer),
});

const mediums = field('mediums');
export const mediumList_sel = mediums.then(State.$content);
const mediumCommonList_sel = mediums.then(State.$common);
export const groupedMediums_sel = selector(mediumList_sel, mediumCommonList_sel, toGroupedList);
export const mediumsById_sel = selector(mediumList_sel, listToMap);

export const reducer = {
  mediums(state = new State(), action) { //NOSONAR
    switch (action.type) { //NOSONAR

      case A.fetchMediumsAction.success:
        return state.apply(
          State.$content.parsed(action.payload),
        );

      case A.setCommonMediumsAction.type:
        return state.apply(
          State.$common.parsed(action.payload),
        );

      default:
        return state;
    }
  },
};
