import { take, select, call, fork, takeEvery, takeLatest, cancel } from 'redux-saga/effects';
import { sagaDelay } from 'lib/envGlobals';
import Socket from 'components/ws';
import { checkRtvStatus } from 'lib/helpers';
import { categoryById as categoryById_sel, loading_sel } from 'domain/category/CategoryModel';
import { fetchCategoryAction } from 'domain/category/CategoryAction';


function* fetchItemByInterval({ fetchItem, artworkId, artwork_sel }) {
  try {
    while (true) {
      yield sagaDelay(30 * 1000);
      const artwork = yield select(artwork_sel);
      const categoryById = yield select(categoryById_sel);
      const rtvStatus = checkRtvStatus({ artwork, categoryById });
      if (!artwork || !rtvStatus) {
        continue;
      }
      if (artwork && rtvStatus && rtvStatus !== 'PROCESSING') {
        yield fork(stopEmergencyWatcher);
        return;
      }
      yield call(fetchItem, artworkId);
    }
  } catch (err) {
  } finally {
  }
}

let task = null;
function* startEmergencyWatcher({ fetchItem, artworkId, artwork_sel }) {
  if (task) {
    yield cancel(task);
    task = null;
  }
  task = yield fork(fetchItemByInterval, { fetchItem, artworkId, artwork_sel });
}

function* stopEmergencyWatcher() {
  if (task) {
    yield cancel(task);
    task = null;
  }
}

function* checkApiRtvStatus({ fetchItem, artworkId, artwork_sel }) {
  const artwork = yield select(artwork_sel);
  const loading = yield select(loading_sel);
  if (loading) {
    yield take(fetchCategoryAction.success);
  }
  const categoryById = yield select(categoryById_sel);
  const rtvStatus = checkRtvStatus({ artwork, categoryById });
  if (artwork && rtvStatus === 'PROCESSING') {
    yield call(fetchItem, artworkId);
  }
}

function* onConnectionResume({ fetchItem, artworkId, artwork_sel }) {
  yield call(stopEmergencyWatcher);
  yield call(checkApiRtvStatus, { fetchItem, artworkId, artwork_sel });
}

/**
 * details page (with protection of ws disconnection)
 * yield fork(createRTVWatcher({
 *   fetchItem: artworkId => fetchOwnArtwork_apiGen.catchError({ artworkId }),
 *   artworkId,
 *   artwork_sel: currentArtwork_sel,
 *   artwork,
 * }));
 * 
 * list page (without protection of ws disconnection)
 * yield fork(createRTVWatcher({
 *   fetchItem: artworkId => call(updateListItem, { payload: { artworkId } }),
 * }));
 * 
 * redirect after creating AO | open AO page by URL
 *   ws disconnected
 *     startCycle30s
 *       ws connected between 0-30s
 *         fetch AO, stop startCycle30s
 *       delay 30s, no ws connection
 *         fetch AO, startCycle30s continues
 *       leave page | logout | ...
 *         task closed by parent saga
 *   ws connected
 *     ws lost connection
 *       startCycle30s
 * @todo change fetchItem to endpoint to get rtv status only (not hole AO)
**/
export function createRTVWatcher({
  fetchItem,
  artworkId,
  artwork_sel,
  artwork,
}) {
  return function* RTVWatcher() {
    if (artworkId && !artwork) {
      throw new Error('call RTVWatcher after artwork fetched');
    }
    yield takeEvery(
      'RTV_WS_NOTIFIER/SOCKET_DATA/rtv_available',
      ({ payload: { artworkId } = {} } = {}) => fetchItem(artworkId),
    );
    if (artworkId && artwork_sel) {
      if (!Socket.connected) {
        yield fork(startEmergencyWatcher, { fetchItem, artworkId, artwork_sel });
      }
      yield takeEvery(
        ['RTV_WS_NOTIFIER/SOCKET_CONNECTION_LOST', 'RTV_WS_NOTIFIER/SOCKET_ERROR', 'RTV_WS_NOTIFIER/SOCKET_DISCONNECT'],
        startEmergencyWatcher,
        { fetchItem, artworkId, artwork_sel },
      );
      yield takeEvery('RTV_WS_NOTIFIER/SOCKET_DATA/rtv_available', stopEmergencyWatcher);
      // stopEmergencyWatcher && checkApiRtvStatus,
      yield takeLatest('RTV_WS_NOTIFIER/SOCKET_CONNECTED', onConnectionResume, { fetchItem, artworkId, artwork_sel });
    }
  };
}
