import React from 'react';
import { compose } from 'redux';
import { isValidElementType } from 'react-is';
import queryString from 'query-string';
import moment from 'moment';
import PropTypes from 'prop-types';
import { fullName, life } from 'domain/artist/helpers';
import I from 'immutable';
import { IMAGES_STORAGE_URL, RESIZED_IMAGES_STORAGE_URL, ARTIST_BANNERS_URL, SN_AUTH_WINDOW } from 'domain/const';
import { select } from 'cpcs-reconnect';

// Array.prototype.includes polyfill
import 'lib/clean-code-lib';

export const stateSelector = select(state => state);

export const SQL_FORMAT = 'YYYY-MM-DD';
export const APP_FORMAT = 'MM/DD/YYYY';
const REDESIGN_APP_FORMAT = 'MMM D, YYYY';
const DEFAULT_TIME_FOR_TEST_ENV = '2017-11-13T14:00:00Z';
export const currentTime = process.env.NODE_ENV === 'test' ? moment.utc(DEFAULT_TIME_FOR_TEST_ENV) : moment.utc();
export const getTimestamp = () => process.env.NODE_ENV === 'test' ? moment.utc(DEFAULT_TIME_FOR_TEST_ENV).unix() : moment().unix();

export function formatAuctionDate(date) {
  return date ? moment(date).format(REDESIGN_APP_FORMAT) : '';
}

const compare = (a, b) => {
  if (!a || !b) return false;
  if (!!a.join && !!b.join && !!a.sort && !!b.sort) return a.sort().join('#') === b.sort().join('#');
  return a === b;
};

export function addDots(text, maxLength){
  if (text.length <= maxLength) return text;
  return text.substr(0, text.substr(0, maxLength).lastIndexOf(' ')) + '...';
}

export function isObjectsEqual(a, b, excludedKeys = []) {
  const filterKeys = v => !excludedKeys.includes(v);
  const aKeys = Object.keys(a).filter(filterKeys);
  const bKeys = Object.keys(b).filter(filterKeys);
  if (aKeys.length !== bKeys.length) return false;
  return aKeys.filter(v => !compare(a[v], b[v])).length === 0;
}

const setZeroTime = (date = currentTime) => {
  return date
    .set('hour', 0)
    .set('minute', 0)
    .set('second', 0)
    .utc();
};

export function daysFromNowToDate(date) {
  return date ? Math.round(setZeroTime(moment.utc(date)).diff(setZeroTime(currentTime), 'hours') / 24) : null;
}

export const isEmpty = (...args) => !args.join('');

export function isObjectEmpty(data, ignoreKeys = [], minNotEmtyKeys = 1) {
  const keys = Object.keys(data);
  let notEmptyKeys = 0;
  keys
    .filter(key => ignoreKeys.indexOf(key) === -1)
    .forEach(key => {
      if (!isEmpty(data[key])){
        notEmptyKeys++;
      }
  });
  return notEmptyKeys < minNotEmtyKeys;
}

export function createdAtToString(date) {
  return date ? moment(date).format('MMM DD, YYYY') : '';
}

export const nothing = () => null;
export const asyncNothing = () => Promise.resolve(null);

export function getFullYear(s) {
  return moment(s).format('YYYY');
}

const nanToEmptyString = (value) => isNaN(value) ? '' : value;

export function normalizeNumberOrEmpty(value, previousValue) {
  return /(^$|^[0-9]+[0-9]*$)/.test(value) ? nanToEmptyString(parseInt(value, 10)) : previousValue;
}

export function leftPadZero(pad = '00', number = 0) {
  const str = number.toString();
  return pad.substring(0, pad.length - str.length) + str;
}

export const tagToArray = (value) => {
  if (value) {
    return value.indexOf('#') === -1 ? [value] : value.split('#');
  } else {
    return [];
  }
};

export function ucFirst(value = '') {
  return value.toLowerCase().replace(/\b[a-z]/g, function(letter) {
    return letter.toUpperCase();
  });
}

const formatYear = (year, cutYear) => {
  if (!year) return [year].join('');
  if (cutYear && year > 1950) return year.toString().substr(2, 2);
  return year.toString();
};

const dateStructToStringDefaultParams = {
  cutYear: false,
};

export function dateStructToString(date, params = dateStructToStringDefaultParams) {
  if (!date || typeof date !== 'object'){
    return '';
  }
  const { day, month, year } = date;

  if (!day || !month) return [year].join('');
  return `${leftPadZero('00', month)}/${leftPadZero('00', day)}/${formatYear(year, params.cutYear)}`;
}

export function stringToDateStruct(value, separator = '/') {
  value = [value].join('');
  const dataArray = value.toString().split(separator);
  const dataStructure = {};
  if (dataArray.length === 3) {
    if (parseId(dataArray[0])) dataStructure.month = parseId(dataArray[0]);
    if (parseId(dataArray[1])) dataStructure.day = parseId(dataArray[1]);
    if (parseId(dataArray[2])) dataStructure.year = parseId(dataArray[2]);
  }
  if (dataArray.length === 1) {
    if (parseId(dataArray[0])) dataStructure.year = parseId(dataArray[0]);
  }
  return dataStructure;
}

export function matchDate(date) {
  return date ? moment(date, APP_FORMAT).format(SQL_FORMAT) : '';
}

export function sqlDateToApp(date) {
  return date ? moment(date, SQL_FORMAT).format(APP_FORMAT) : '';
}


export function sqlDateToRedesign(date) {
  return date ? moment(date, SQL_FORMAT).format(REDESIGN_APP_FORMAT) : '';
}

export function appDateToSql(date) {
  return date ? moment(date, APP_FORMAT).format(SQL_FORMAT) : '';
}

export function dateToAppFormat(date) {
  return date ? moment(date).format(APP_FORMAT) : '';
}

export function toggleByIdInSet({ id, idsSet }) {
  if (idsSet.has(id)) return idsSet.remove(id);
  return idsSet.add(id);
}

export const setPropTypes = (propTypes) => f => Object.assign(f, { propTypes });

export function parseId(id, def = undefined) {
  if (typeof id === 'number') return id;
  if (typeof id === 'string' && id.length) {
    const iId = parseInt(id, 10);
    if (isNaN(iId)) return false;
    return iId;
  }
  return def;
}

export function parseIds(ids: ?string) {
  return ids ? ids.split(/,/g)
  .reduce((A, V) => {
    const e = parseInt(V, 10);
    return isNaN(e) ? A : A.concat(e);
  }, []) : [];
}

export const excerpt = ({ str: strIn, maxLen }) => {
  // skip undefined and null
  if ([strIn].join('').length === 0) return '';
  const str = strIn + '';

  if (str.length <= maxLen) return str;

  const words = str.split(' ');
  let ret = words.shift() || '';
  if (ret.length > maxLen) return ret + ' …';
  let concated = '';
  for (let w of words) {
    concated = ret + ' ' + w;
    if (concated.length > maxLen) return ret + ' …';
    ret = concated;
  }
  return ret;
};

/**
 * url examples:
 *   (preview)
 *   blob:http://localhost:3001/7fcf61d5-770e-406c-8504-3d820ea1d1fe
 *   d85f38b4a545ce97c91cfdc680d34400.jpg
 *   http://...
 *   https://...
**/
export const isUrl = s => /^(https?|blob):|^\/\//i.test(s);

export const noForwardSlash = s => {
  if (!s || typeof s !== 'string') return '';
  if (s.substr(0, 1) === '/') return s.substr(1, s.length - 1);
  return s;
};

const prepareResizedUrl = compose(
  noForwardSlash,
  s => (s || '').replace(/png$/, 'jpg'),
);

export function imgUrl(url, params, width = 526) {
  if (!url) return null;
  if (isUrl(url)) return url;
  if (!params) return `${IMAGES_STORAGE_URL}/${noForwardSlash(url)}`;
  return `${RESIZED_IMAGES_STORAGE_URL}/w${width}/${prepareResizedUrl(url)}`;
};

export function artistBannerUrl(filename) {
  return `${ARTIST_BANNERS_URL}/${filename}`;
};

export const uploadedURL = s => {
  if (isUrl(s)) return s;
  return s ? `/uploads/tmp/${s}` : '';
};

export const urlSetParams = (location, params) => {
  const { hash, pathname, search } = location;
  const query = queryString.parse(search || '', { arrayFormat: 'bracket' });
  return pathname + '?' +
        queryString.stringify({ ...query, ...params }, { arrayFormat: 'bracket' }) +
        (hash || '');
};

export const uriByPath = (path = '') => {
  let origin;
  if (process.env.NODE_ENV === 'test') {
    origin = 'http://artbnk.com';
  } else {
    origin = global.document.location.origin;
  }
  return origin + path;
};
/**
 * buildUrl(base, { query1, query2, query3 })
**/
const encodeParamReducer = o => (k) => (`${k}=` + encodeURIComponent(o[k]));
export const buildUrl = (base, params) => base + '?' + Object.keys(params)
  .map(encodeParamReducer(params)).join('&');

export const authorWithLife = ({ classes, artist }) => {
  const l = life(artist);
  const a = fullName(artist);
  return (
    <span>
      {a}
      <span className={classes.life}>{(a.length && l.length) ? `, ${l}`: ''}</span>
    </span>
  );
};

authorWithLife.propTypes = {
  classes: PropTypes.shape({
    life: PropTypes.string.isRequired,
  }).isRequired,
  artist: PropTypes.oneOfType([
    PropTypes.instanceOf(I.Record),
    PropTypes.instanceOf(I.Map),
  ]),
};

export const openAuthWindow = (href) => {
  const width = 650;
  const height = 600;
  const top = (global.innerHeight - height) / 2;
  const left = (global.innerWidth - width) / 2;
  global.open(href, SN_AUTH_WINDOW, `width=${width},height=${height},top=${top},left=${left}`);
};

export const objectIsNotEmpty = (obj = {}) => Object.keys(obj)
  // 'currency' was removed after refactoring, change this method if error accurs
  .filter(key => !['currency', 'isPrivate', 'editable', 'url', 'priceSold', 'estimatedPriceStart', 'estimatedPriceEnd'].includes(key))
  .filter(key => !!obj[key]).length > 0 ||
  (!!obj.priceSold && !!obj.priceSold.value) ||
  (!!obj.estimatedPriceStart && !!obj.estimatedPriceStart.value) ||
  (!!obj.estimatedPriceEnd && !!obj.estimatedPriceEnd.value);


const logCSS = 'color: #1c8c21; font-size: 10px';
export const getLogger = fname => {
  let logger = console; // eslint-disable-line no-console
  if (process.env.NODE_ENV === 'test') {
    const doNothing = () => null;
    logger = { log: doNothing, warn: doNothing, error: doNothing };
  }
  return Object.assign(
    (...args) => logger.log(`%c [${fname}]:`, logCSS, ...args), // eslint-disable-line no-console
    {
      log: (...args) => logger.log(`%c [${fname}]:`, logCSS, ...args), // eslint-disable-line no-console
      info: (...args) => logger.log(`%c [${fname}]:`, logCSS, ...args), // eslint-disable-line no-console
      warn: (...args) => logger.warn(`%c [${fname}]:`, logCSS, ...args),
      err: (...args) => logger.error(`%c [${fname}]:`, logCSS, ...args),
      error: (...args) => logger.error(`%c [${fname}]:`, logCSS, ...args),
    },
  );
};

export function parseServerError(err) {
  const defaultMessage = 'something went wrong';
  const defaultStatus = undefined;
  let message, status;
  try {
    message = err.response.data.message;
    status = err.response.data.status;
  } catch (e) {/* we can't extract message from some errors*/}
  if (!message) try {
    message = err.message;
  } catch (err) {/* we can't extract message from some errors*/}
  if (!status) try {
    status = ((err && err.response) || err || {}).status;
  } catch (err) {/* we can't extract status from some errors*/}
  return { message: message || defaultMessage, status: status || defaultStatus };
}

export const isValidReactComponent = (props, propName, componentName) => {
  if (props[propName] && !isValidElementType(props[propName])) {
    return new Error(`Invalid prop "${propName}" supplied to "${componentName}."`);
  }
  return null;
};


export const isTrackingLink = name => ((name || '').includes('consideration') || (name || '').includes('tracking'));

export const isArray = v => Object.prototype.toString.call(v) === '[object Array]';
export const isObject = v => Object.prototype.toString.call(v) === '[object Object]';

const getVpHeight = () => Math.max(document.documentElement.clientHeight, global.innerHeight || 0);
const getVpWidth = () => Math.max(document.documentElement.clientWidth, global.innerWidth || 0);

export const getDropdownPosition = (node, componentHeight, componentWidth) => {
  let vertical = 'bottom';
  let horizontal = 0; /*-30 | 15 (px) */
  if (!node) return { vertical, horizontal };
    // if bottom of component will not visible in view port
  const noSpaceBellow = (node.getBoundingClientRect().top + componentHeight) > getVpHeight();
  // if there is space above the component
  const isSpaceAbove = node.getBoundingClientRect().top > componentHeight;
  if (noSpaceBellow && isSpaceAbove) {
    vertical = 'top';
  }
  const noSpaceRight = node.getBoundingClientRect().left + componentWidth > getVpWidth();
  if (noSpaceRight) {
    // hidden right width
    horizontal = (node.getBoundingClientRect().left + componentWidth) - getVpWidth();
    // if component, moved on hidden width will be cutted on left side
    if ((node.getBoundingClientRect().left - horizontal) < 0) {
      // place component at center
      horizontal = horizontal + (node.getBoundingClientRect().left - horizontal) / 2;
    };
  }
  return { vertical, horizontal: - horizontal };
};

export function protectClassesReplacing(WrappedComponent) {
  function ProtectClassesReplacing({ classes, ...props }) {
    return <WrappedComponent {...props} externalClasses={classes} />;
  }
  ProtectClassesReplacing.propTypes = {
    classes: PropTypes.object,
  };
  return ProtectClassesReplacing;
}
export const serializerAddSelect = arr => {
  Object.defineProperty(arr, 'select', {
    enumerable: false,
    writable: false,
    value: function(...names) {
      return this.filter(serializer => serializer && serializer.name && names.includes(serializer.name));
    },
  });
  Object.defineProperty(arr, 'selectOrdered', {
    enumerable: false,
    writable: false,
    value: function(...names) {
      // return this.filter(serializer => serializer && serializer.name && names.includes(serializer.name));
      return names.map(name => {
        let found;
        for (let item of this) {
          if (item.name === name) {
            found = item;
            break;
          }
        }
        if (!found) {
          console.error(`there is no solver with name "${name}"`);
          return { name, solver: () => `no "${name}" solver` };
        }
        return found;
      });
    },
  });
};

export const strToMap = s => [s].join('').split(/[,;]+/g)
  .filter(v => v.length > 0)
  .reduce((o, v) => ({ ...o, [v]: true }), {});
export const mapToStr = o => Object.keys(o).filter(k => o[k]).join(',');

export const hasSubscription = (subscriptionType) => I.List([
  'SUBSCRIPTION_TYPE_COLLECTOR',
  'SUBSCRIPTION_TYPE_PROFESSIONAL',
  'SUBSCRIPTION_TYPE_PREMIUM',
  'SUBSCRIPTION_TYPE_ENTERPRISE',
]).includes(subscriptionType);

export const insightAvailable = (subscriptionType) => I.List([
  'SUBSCRIPTION_TYPE_COLLECTOR',
  'SUBSCRIPTION_TYPE_PROFESSIONAL',
  'SUBSCRIPTION_TYPE_PREMIUM',
  'SUBSCRIPTION_TYPE_CUSTOM',
  'SUBSCRIPTION_TYPE_ENTERPRISE',
]).includes(subscriptionType);

const isAny2D = ({ categoryList, category: id }) => {
  if (!id || !categoryList) return false;
  const entity = categoryList.find(v => v.get('id') === id);
  if (!entity) return false;
  return entity.get('title', '').includes('2D');
};

export const checkRtvStatus = ({ categoryById, artwork }) => {
  if (!artwork) return '';
  const { rtvStatus, category, isDraft, notAccurate } = artwork;
  if (rtvStatus === 'PROCESSING' && !isAny2D({ categoryList: categoryById, category })) {
    return 'NOT_AVAILABLE';
  }
  if (rtvStatus === 'PROCESSING' && isDraft) {
    return 'NOT_AVAILABLE';
  }
  if (rtvStatus === 'PROCESSING' && notAccurate) {
    return 'NOT_AVAILABLE';
  }
  return rtvStatus;
};

export const dispatchPromise = (fn, payload, ...args) => {
  return new Promise((resolve, reject) => {
    fn({ ...payload, resolve, reject }, ...args);
  });
};

const strLike2D = (title = '') => title.includes('2D');
const strIsEdition = (title = '') => title.includes('Edition');
export const strIsEdition2D = (title = '') => title === '2D Edition';
export const strIs2D = title => strLike2D(title) && !strIsEdition(title);

export const mapCategoryTitle = title => ({
  '2D': '2D Unique',
  '3D': '3D Unique',
}[title] || title);
