import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import I from 'immutable';
import { artistBannerUrl } from 'lib/helpers';
import injectSheet from 'lib/sheet';
import { sheet } from './sheet.js';

class ArtistBanner extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    artist: PropTypes.instanceOf(I.Collection),
  };

  render() {
    const { classes, artist } = this.props;
    return (
      <div className={classes.ArtistBanner}>
        <div className={classes.banner970x120}>
          <a href={artist.get('bannerUrl')} target="_blank" rel="noopener noreferrer">
            <img src={artistBannerUrl(artist.get('banner970x120'))} alt={artist.get('fullName')} />
          </a>
        </div>
        <div className={classes.banner720x120}>
          <a href={artist.get('bannerUrl')} target="_blank" rel="noopener noreferrer">
            <img src={artistBannerUrl(artist.get('banner720x120'))} alt={artist.get('fullName')} />
          </a>
        </div>
        <div className={classes.banner343x120}>
          <a href={artist.get('bannerUrl')} target="_blank" rel="noopener noreferrer">
            <img src={artistBannerUrl(artist.get('banner343x120'))} alt={artist.get('fullName')} />
          </a>
        </div>
      </div>
    );
  }
}

export default compose(
  injectSheet(sheet),
)(ArtistBanner);
