import React from 'react';
import PropTypes from 'prop-types';
import { connect, select } from 'cpcs-reconnect';
import { isDirty } from 'redux-form';
import { redirectToPageAction } from 'lib/redirectToPage';
import { goBackAction } from 'domain/router/RouterAction';
import { FORM_ADD_ART_ITEM } from 'domain/const';
import {
  // (params, actionCreator, ...args)
  confirmAction,
} from 'domain/ui/UIActions';


export function onFormCancel({ pageName, formName = FORM_ADD_ART_ITEM } = {}) {
  return function decorator(WrappedComponent) {
    class ArtworkFormOnCancel extends React.Component {
      static propTypes = {
        isMainFormDirty: PropTypes.bool,
        redirectToPage: PropTypes.func.isRequired,
        confirmPopup: PropTypes.func.isRequired,
        goBack: PropTypes.func.isRequired,
      };

      onCancel = () => {
        const { confirmPopup, redirectToPage, isMainFormDirty, goBack } = this.props;
        const payload = { page: pageName };
        if (!isMainFormDirty) {
          if (pageName) {
            redirectToPage(payload);
          } else {
            goBack();
          }
          return;
        }
        const args = pageName ? [redirectToPageAction, payload] : [goBackAction];
        confirmPopup({
          title: '',
          description: 'Are you sure, you want to leave this page?\nAll unsaved data will be lost.',
          modifier: 'unsavedForm',
        }, ...args);
      };

      render() {
        return (
          <WrappedComponent
            onFormCancel={this.onCancel}
            {...this.props}
          />
        );
      }
    }

    return connect({
      isMainFormDirty: select(isDirty(formName)),
      redirectToPage: redirectToPageAction,
      confirmPopup: confirmAction,
      goBack: goBackAction,
    })(ArtworkFormOnCancel);
  };
}

