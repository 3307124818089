import { SIDEBAR_Z_INDEX } from 'theme/zIndex';
import { LAYOUT_768_MAX_WIDTH } from 'theme/layout/responsiveList';

export const sheet = {
  SideBar: {
    // width: 248,
    // minWidth: 300,
    // minHeight: '100%',
    // boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.2)',
    // position: 'relative',
    zIndex: SIDEBAR_Z_INDEX,
    // paddingBottom: 70,
    // '@media screen and (min-width: 1440px)': {
      // width: 320,
      position: 'sticky',
      top: 60,
      height: '100vh',
      paddingBottom: 70,
      minHeight: 0,
      // minWidth: 278,
      // overflowY: 'hidden',
    // },
    // '@media screen and (max-width: 768px)': {
    //   minWidth: '100%',
    // },
    [`@media screen and (max-width: ${LAYOUT_768_MAX_WIDTH}px)`]: { // 833
      paddingLeft: 20,
      paddingRight: 20,
      width: 288,
      display: 'none',
      background: '#FFF',
      position: 'fixed',
      left: 0,
      top: 60,
      height: '100%',
      overflowY: 'scroll',
      '&.AFOpened': {
        display: 'block',
      },
    },
    '&.preferencesPage': {
      display: 'none',
    },
  },
  overlay: {
    display: 'none',
    content: '""',
    position: 'fixed',
    left: 288,
    top: 60,
    // left: 320,
    width: '100vw',
    height: '100vh',
    background: 'rgba(0, 0, 0, 0.4)',
    zIndex: 0,
    [`@media screen and (max-width: ${LAYOUT_768_MAX_WIDTH}px)`]: { // 833
      display: 'block',
    },
  },
  sidebarFixedWrap: {
    width: 300,
    position: 'sticky',
    left: 0,
    top: 0,
  },
  logo: {
    width: '100%',
    minHeight: 60,
    position: 'relative',
    background: '#fff',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 35,
  },
  logoImage: {
    height: 40,
  },
  logoNavWrp: {
    width: '100%',
  },
  nav: {
    display: 'block',
    marginTop: 19,
  },
  helpGuide: {
    display: 'flex',
    width: 150,
    margin: [0, 'auto'],
    flexDirection: 'column',
  },
};
