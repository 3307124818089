import {
  LAYOUT_RANGE_425_MAX,
  LAYOUT_RANGE_768_MAX,
  LAYOUT_RANGE_1024_MAX,
} from 'theme/layout/LAYOUT';

export const PAGE_CONTAINER = {
  padding: [30, 40, 50],
  width: '100%',
  maxWidth: '100%',
  minWidth: 0,
  '&.SharesPage, &.SharedWithMePage, &.SharedByMePage': {
    '@media screen and (max-width: 1000px)': {
      margin: '0 auto',
      width: '606px',
    },
    '@media screen and (max-width: 630px)': {
      margin: 0,
      width: '100%',
    },
  },
  '&.FavoriteArtistsPage': {
    '@media screen and (max-width: 1760px)': {
      paddingRight: 0,
      paddingLeft: 0,
    },
  },
  '&.PreferencesPage': {
    marginTop: 40,
    paddingBottom: 0,
    display: 'flex',
    // display: 'block',
    // height: '100%',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
  },
  '&.error404Page': {
    padding: [30, 40],
    minHeight: 500,
    height: '100%',
    boxSizing: 'border-box',
    width: '100%',
  },
  '&.FavoriteArtist sPage, &.ArtistPage, &.saleLotsListPage, &.collectionListPage': {
    margin: 0,
    paddingLeft: 0,
    paddingRight: 0,
  },
  '&.ArtistPage, &.saleLotsListPage, &.collectionListPage': {
    paddingTop: 12,
    justifySelf: 'end',
  },
  '&.sharedItemsPage': {
    margin: 0,
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
  },
  '&.artworkPage': {
    margin: 0,
    padding: [40, 0, 101],
  },
  '&.collectionItemPage': {
    margin: 0,
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 20,
  },
  '&.sharedArtWorkPage': {
    margin: 0,
    padding: [30, 0],
  },
  '&.newArtPage': {
    // NEW_ART_PAGE
    paddingTop: 20,
  },
  '&.extraFooterGap': {
    paddingBottom: 150,
  },
  [`@media screen and (max-width: ${LAYOUT_RANGE_1024_MAX}px)`]: {
    // NEW_ART_PAGE
    '&.newArtPage, &.addArtUnifiedPage': {
      width: 804,
      margin: '0 auto',
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  [`@media screen and (max-width: ${LAYOUT_RANGE_768_MAX}px)`]: {
    // NEW_ART_PAGE
    '&.newArtPage, &.addArtUnifiedPage': {
      width: 526,
      margin: '0 auto',
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  // AccountPage
  '@media screen and (max-width: 1023px)': {
    '&.AccountPage': {
      width: 606,
      margin: '0 auto',
    },
  },
  // RtvValueListPage
  '@media screen and (max-width: 768px)': {
    '&.RtvValueListPage': {
      paddingLeft: 16,
      paddingRight: 16,
    },
  },
  [`@media screen and (max-width: ${LAYOUT_RANGE_425_MAX}px)`]: {
    // NEW_ART_PAGE
    '&.newArtPage, &.addArtUnifiedPage': {
      width: '100%',
      paddingLeft: 15,
      paddingRight: 15,
      margin: 0,
    },
  },
  '@media screen and (max-width: 450px)': {
    '&.AccountPage': {
      paddingBottom: 80,
      '&.extraFooterGap': {
        paddingBottom: 150,
      },
    },
  },
  '@media screen and (max-width: 410px)': {
    '&.AccountPage': {
      width: 'auto',
      paddingLeft: 15,
      paddingRight: 15,
    },
  },
  '@media screen and (max-width: 375px)': {
    '&.AccountPage': {
      width: '100%',
    },
  },
};
