/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useMemo, useState, useEffect } from 'react';
import { Dispatcher } from 'pages/highcharts/helpers/Dispatcher';

export const EventsContext = React.createContext();

export const initEventsContainer = (Component) => {
  function EventsProvider(props) {
    let dispatcher;
    dispatcher = useMemo(() => {
      const dispatcher = new Dispatcher();
      if (global.ARTBnkLogger) {
        const dispatchToLogger = (event, eventName) => global.ARTBnkLogger.dispatch(eventName, event);
        dispatchToLogger.__skipOnCollect = true;
        dispatcher.addEventListener('*', dispatchToLogger);
      }
      return dispatcher;
    }, []);
    useEffect(() => {
      return () => {
        dispatcher.destroy();
      };
    }, [dispatcher]);
    const [PDFEvents] = useState(dispatcher);
    return (
      <EventsContext.Provider value={PDFEvents}>
        <Component {...props} PDFEvents={PDFEvents} />
      </EventsContext.Provider>
    );
  }
  return EventsProvider;
};

export const connectEventsContainer = (Component) => {
  function EventsCounsumer(props) {
    return (
      <EventsContext.Consumer>
        {PDFEvents => <Component {...props} PDFEvents={PDFEvents} />}
      </EventsContext.Consumer>
    );
  }
  return EventsCounsumer;
};

// usage const PDFEvents = usePDFEvents();
export function usePDFEvents() {
  return useContext(EventsContext);
}

// usage useListen('EVENT_NAME', callback);
export function useListen(eventName, callback) {
  const PDFEvents = usePDFEvents();
  useEffect(() => {
    PDFEvents && PDFEvents.addEventListener(eventName, callback);
    return () => PDFEvents && PDFEvents.removeEventListener(eventName, callback);
  }, [PDFEvents, callback, eventName]);
}
