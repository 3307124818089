import { valuation } from 'lib/units';

export const soldPriceStr = (artwork) => {
  const value = artwork.getIn(['lot', 'priceSold', 'value']);
  const currency = artwork.getIn(['lot', 'currency']);
  if (value === 0) {
    return 'Unsold';
  }
  if (!value) return null;
  return valuation({ value, currency });
};

export function soldPriceVal(lot, { expiredTrialUser, unsold = 'Unsold' } = {}) {
  const value = lot.getIn(['priceSold', 'value']);
  const currency = lot.get('currency');
  // hotfix, temporarily hidden
  // const auctionStatus = lot.getIn(['lot', 'auctionStatus']);
  // if (auctionStatus === 'Unsold') {
  //   return 'Unsold';
  // }
  // if (auctionStatus === 'Awaiting Result') {
  //   return 'Awaiting Result';
  // }
  if (value) {
    if (expiredTrialUser) return ' ';
  }
  if (value !== 0) {
    return valuation({ value, currency });
  } else if (value === 0) {
    if (expiredTrialUser) return ' ';
    return unsold;
  }
  return null;
}

const soldPriceSolver = (val, data, name, props) => {
  return soldPriceVal(data.get('lot'), props);
};

export default soldPriceSolver;
