import record, { integer, string, listOf } from 'cpcs-recordjs';
import { field } from 'cpcs-reconnect';
import * as A from './OccupationAction';

const Occupation = record('Occupation', {
  id: integer(0),
  title: string(''),
});

const State = record('Occupations', {
  content: listOf(Occupation),
});

export const occupations = field('occupations');
export const occupationList_sel = occupations.then(State.$content);

export const reducer = {
  occupations(state = new State(), action) { //NOSONAR
    switch (action.type) { //NOSONAR

      case A.fetchOccupationAction.success:
        return state.apply(
          State.$content.set(
            State.$content.type.parse(action.payload),
          ),
        );

      default:
        return state;
    }
  },
};
