import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import { connect } from 'cpcs-reconnect';
import { compose } from 'redux';
import I from 'immutable';
import cx from 'classnames';
import {
  couponFormOpenedSelector,
  couponInValidationSelector,
  validatedCouponSelector,
  priceSelector,
} from 'pages/common/couponForm/couponFormModel';
import {
  submittingSelector,
} from 'pages/common/buyRtv/buyRtvModel';
import {
  openCouponFormAction,
  closeCouponFormAction,
  checkCouponAction,
  buyRtvForCouponAction,
  removeCheckedCouponAction,
} from 'pages/common/couponForm/couponFormActions';
import { FORM_COUPON } from 'domain/const';
import FormRowField from 'pages/common/newPage/form/elements/FormRowField';
import rules, { validator } from 'components/form/validation';
import injectSheet from 'lib/sheet';
import styles from './sheet.js';

class CouponForm extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    onOpen: PropTypes.func,
    onClose: PropTypes.func,
    onCheck: PropTypes.func.isRequired,
    opened: PropTypes.bool,
    couponInValidation: PropTypes.bool,
    buyingInProgress: PropTypes.bool,
    validatedCoupon: PropTypes.string,
    buyRtvForCoupon: PropTypes.func,
    handleSubmit: PropTypes.func.isRequired,
    removeCheckedCoupon: PropTypes.func.isRequired,
    price: PropTypes.number,
    modifier: PropTypes.string,
    cancelText: PropTypes.string,
    useGetFreeButton: PropTypes.bool,
    required: PropTypes.bool,
    product: PropTypes.instanceOf(I.Collection),
  };

  static defaultProps = {
    cancelText: 'Cancel',
    useGetFreeButton: false,
    required: true,
  };

  onSubmit = (data) => {
    const { validatedCoupon, onCheck, useGetFreeButton, buyRtvForCoupon, product } = this.props;
    if (!validatedCoupon) {
      onCheck({ ...data, product });
    } else if (buyRtvForCoupon && useGetFreeButton) {
      buyRtvForCoupon(data);
    }
  }

  validate = {
    coupon: validator(rules.required),
  };

  onChange = () => {
    this.props.removeCheckedCoupon();
  }

  render() {
    const {
      classes, opened, onOpen, onClose, couponInValidation, validatedCoupon, buyingInProgress, price,
      modifier, cancelText, useGetFreeButton, required, product,
    } = this.props;
    return (
      <form
        className={classes.CouponForm}
        onSubmit={this.props.handleSubmit(this.onSubmit)}
      >
        {
          !opened && !!onOpen &&
            <button
              type="button"
              className={classes.openBtn}
              children="Apply coupon"
              onClick={() => onOpen()}
            />
        }
        {
          opened &&
            <div className={cx(classes.couponRow, modifier)}>
              <Field
                type="text"
                name="coupon"
                component={FormRowField}
                title="Coupon"
                placeholder="Enter coupon code"
                validate={required ? this.validate.coupon : undefined}
                rootTag="div"
                modifier={cx('rtv-form-coupon', modifier)}
                onChange={this.onChange}
              />
              {
                !validatedCoupon &&
                  <button
                    type="submit"
                    className={cx(classes.submitBtn, modifier)}
                    children="Submit"
                    disabled={couponInValidation}
                  />
              }
              {
                validatedCoupon && !!onClose &&
                  <button
                    type="button"
                    className={cx(classes.closeBtn, modifier)}
                    children={cancelText}
                    onClick={() => onClose({ product })}
                  />
              }
            </div>
        }
        {
          opened && validatedCoupon && !price && useGetFreeButton &&
            <button
              children="Get free value"
              className={classes.buyButton}
              type="submit"
              disabled={buyingInProgress}
            />
        }
      </form>
    );
  }
}

CouponForm = compose(
  injectSheet(styles),
  reduxForm({
    form: FORM_COUPON,
  }),
)(CouponForm);

export const CouponFormBuyRtv = connect({
  opened: couponFormOpenedSelector,
  onOpen: openCouponFormAction,
  onClose: closeCouponFormAction,
  removeCheckedCoupon: removeCheckedCouponAction,
  onCheck: checkCouponAction,
  couponInValidation: couponInValidationSelector,
  validatedCoupon: validatedCouponSelector,
  buyRtvForCoupon: buyRtvForCouponAction,
  buyingInProgress: submittingSelector,
  price: priceSelector,
})(CouponForm);

export const CouponFormUpgradePlan = connect({
  onClose: closeCouponFormAction,
  removeCheckedCoupon: removeCheckedCouponAction,
  onCheck: checkCouponAction,
  couponInValidation: couponInValidationSelector,
  validatedCoupon: validatedCouponSelector,
})(CouponForm);
