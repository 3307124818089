import { DEFAULT_FONTFAMILY, GALLERY_MENU, GALLERY_MENU_BG, GALLERY_MENU_ITEM_HOVER_BG } from 'theme/theme';

export default {
  ArtGalleryContextMenu: {
    backgroundColor: GALLERY_MENU_BG,
    boxShadow: '0 8px 8px 0 rgba(0, 0, 0, 0.24), 0 0 8px 0 rgba(0, 0, 0, 0.12)',
    zIndex: 2,
    outline: 'none',
  },
  list: {
    position: 'relative',
    zIndex: 3,
  },
  item: {
    // just list item <li />
  },
  link: {
    textAlign: 'left',
    fontFamily: DEFAULT_FONTFAMILY,
    fontSize: '14px',
    lineHeight: 1.43,
    padding: [7, 24, 5],
    whiteSpace: 'nowrap',
    textDecoration: 'none',
    display: 'block',
    width: '100%',
    color: GALLERY_MENU,
    '&:hover': {
      backgroundColor: GALLERY_MENU_ITEM_HOVER_BG,
    },
  },
};
