import rules, { addError } from 'components/form/validation';
import { compose } from 'redux';
const fields = ['email', 'privacyTermsAccept', 'marketingEmailConsent'];

const validator = values => (errors, fieldName) => {
  const val = values[fieldName];
  switch (fieldName) {
    case 'email':
      return compose(
        e => addError(e, fieldName, rules.email(val)),
        e => addError(e, fieldName, rules.required(val)),
      )(errors);
    case 'privacyTermsAccept':
      return addError(errors, fieldName, rules.required(val));
    default:
      return errors;
  }
};

export default values => fields.reduce(validator(values), {});
