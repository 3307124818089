import React from 'react';
import PropTypes from 'prop-types';
import I from 'immutable';
import { sqlDateToApp } from 'lib/helpers';

import injectSheet from 'lib/sheet';

function priceFormat(value, maximumFractionDigits) {
  return new Intl.NumberFormat('en-US', { minimumFractionDigits: 0, maximumFractionDigits }).format(value);
}

const priceFormatPDFEstimation = (v) => priceFormat(v, 0);

const PRICE_TYPE_LABEL = {
  PRIVATE: 'Private Sale',
  AUCTION: 'Auction Sale',
  APPRAISAL: 'Appraised Value',
};
function AuctionNoTheme({ auctions, classes, lot, isAppraisal }) {
  return <span
    className={classes.auctionSolver}
  >
    {
      lot.editable && !isAppraisal && lot.privateLabel && !lot.seller && 'Private sale '
    }
    {auctions.getIn([lot.auction, 'title'], lot.seller)}
    {
      (isAppraisal && !lot.seller && !lot.auction) &&
        PRICE_TYPE_LABEL[lot.priceType]
    }
    {' '}
  </span>;
}

AuctionNoTheme.propTypes = {
  auctions: PropTypes.instanceOf(I.Map),
  isAppraisal: PropTypes.bool,
  classes: PropTypes.shape({
    auctionSolver: PropTypes.string,
  }),
  lot: PropTypes.shape({
    auction: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    seller: PropTypes.string,
    editable: PropTypes.bool,
    privateLabel: PropTypes.bool,
    priceType: PropTypes.string,
  }),
};

const Auction = injectSheet({
  auctionSolver: {
    textTransform: 'capitalize',
  },
})(AuctionNoTheme);

export const formatSold = ({ priceSold, editable, awaitingPrice }) => {
  if (!priceSold) return '';
  if (priceSold.value === 0) return 'Unsold';
  if (priceSold.value === undefined) return '';
  const unsoldStatus = awaitingPrice ? 'Awaiting Result' : '';
  return parseInt(priceSold.value, 10) <= 0 && !editable ? ` ${unsoldStatus}` : ` ${priceFormat(priceSold.value)}`;
};

export const formatEstimate = (lot, place = 'AOBadge') => {
  const start = lot.estimatedPriceStart && lot.estimatedPriceStart.value;
  const end = lot.estimatedPriceEnd && lot.estimatedPriceEnd.value;
  let val;
  const pf = place === 'SalesHistoryPDF' ? priceFormatPDFEstimation : priceFormat;
  if (start && end) {
    val = `${pf(start)} - ${pf(end)}`;
  } else if (start) {
    val = pf(start);
  } else if (end) {
    val = pf(end);
  } else {
    return null;
  }
  switch (place) {
    case 'SalesHistoryPDF':
      return `${val}`;
    default:
      return ` (Est. ${val})`;
  }
};

const prices = (soldPrice, estPrice, currency) => {
  return <React.Fragment>{estPrice} {soldPrice} {currency}</React.Fragment>;
};

export const extractCurrency = e => {
  if (
    (!e.priceSold || !e.priceSold.value || e.priceSold.value === 0) && !(
      (e.estimatedPriceStart && e.estimatedPriceStart.value) &&
      (e.estimatedPriceEnd && e.estimatedPriceEnd.value)
    )
  ) return null;
  const currency = e.currency;
  if (!currency) return null;
  return <React.Fragment>&nbsp;{currency}</React.Fragment>;
};

function SalesHistoryItemNoTheme({ classes, lot, auctions, isAppraisal, RootTag = 'li' }) {
  return (
    <RootTag className={classes.salesHistoryItem}>
      {(!lot.editable && lot.privateLabel && !isAppraisal) ? 'Private sale - ' : null}
      {!!lot.soldDate && `${sqlDateToApp(lot.soldDate)} `}
      <Auction {...{ auctions, lot, isAppraisal }} />
      {prices(formatSold(lot), formatEstimate(lot), extractCurrency(lot))}
    </RootTag>
  );
}
SalesHistoryItemNoTheme.propTypes = {
  classes: PropTypes.shape({
    salesHistoryItem: PropTypes.string,
  }).isRequired,
  isAppraisal: PropTypes.bool,
  lot: PropTypes.instanceOf(I.Collection).isRequired,
  auctions: PropTypes.instanceOf(I.Collection).isRequired,
  RootTag: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.any,
  ]),
};
export const SalesHistoryItem = injectSheet({
  salesHistoryItem: {
    marginTop: 10,
    wordSpacing: '2px',
    whiteSpace: 'pre-line',
    overflowWrap: 'break-word',
    '&:first-child': {
      marginTop: 0,
    },
  },
})(SalesHistoryItemNoTheme);

const salesHistorySolver = (items, data, name, props = {}) => {
  const { auctions } = props;
  const { lot: { saleAt } = {} } = data;
  if (!items || !I.List.isList(items) || !items.size) return null;
  items = items.filter(v => !v.soldDate || v.soldDate !== saleAt);
  if (!items || !I.List.isList(items) || !items.size) return null;
  return <ul>
    {
      items && items.map((lot, key) => (
        <SalesHistoryItem
          key={key}
          lot={lot}
          auctions={auctions}
        />
      ))
    }
  </ul>;
};

salesHistorySolver.propTypes = {
  auctions: PropTypes.instanceOf(I.Map),
};

export default salesHistorySolver;

export const upcomingSaleSolver = (value, data, name, props) => {
  if (!value || !value.id) return null;
  return salesHistorySolver(I.List([value]), data, name, props);
};
