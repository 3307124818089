import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import AbvCountdownBanner from 'pages/common/abvCountdownBanner';
import SideBar from 'pages/common/sidebar';

export default function PageContainer({ children, modifier }) {
  return (
    <div className={cx('PAGE_CONTAINER', modifier)}>
      <div className="SIDEBAR_LAYOUT">
        <SideBar />
        <div className="PAGE_SIDE">
          {children}
          <AbvCountdownBanner />
        </div>
      </div>
    </div>
  );
}
PageContainer.propTypes = {
  children: PropTypes.node,
  modifier: PropTypes.string,
};
