import I from 'immutable';

function  exist(d) {
  return (typeof d !== 'undefined' && d.size);
}

export function life(d) {
  if (!exist(d)) return '';
  const diedIn = d.getIn(['diedIn', 'year']) || '';
  const bornIn = d.getIn(['bornIn', 'year']) || '';
  return !!bornIn && !!diedIn ? `${bornIn} - ${diedIn}` : `${bornIn || diedIn}`;
}

export function fullName(artist = new I.Map()) {
  return artist.get('fullName') || '';
}

export function artistName(d) {
  if (!exist(d)) return '';
  const l = life(d);
  const n = fullName(d);
  return !!l && !!n ? `${n} (${l})` : n;
}
