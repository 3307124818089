export default {
  container: {
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  thumb: {
    boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.2)',
    width: 115,
    height: 100,
    position: 'absolute',
    opacity: 0.8,
    background: {
      position: 'center center',
      size: 'cover',
      repeat: 'no-repeat',
      color: '#C4C4C4',
    },
  },
  badge: {
    position: 'absolute',
    borderRadius: '3px',
    backgroundColor: 'lightblue',
    left: 45,
    top: 35,
    display: 'flex',
    width: 30,
    height: 30,
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    border: '2px solid white',
    zIndex: 11,
  },
};
