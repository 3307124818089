import React from 'react';
import PropTypes from 'prop-types';

export default function inputToPropsDecorator(WrappedComponent) {
  /**
   * const DEFAULT_INPUT = {
   *   name = '',
   *   onBlur = doNothing,
   *   onChange = doNothing,
   *   onDragStart = doNothing,
   *   onDrop = doNothing,
   *   onFocus = doNothing
   *   value = void 0,
   * };
  */
  function InputToPropsDecorator({ input, meta, ...props }) {
    return <WrappedComponent
      {...input}
      {...props}
    />;
  }
  InputToPropsDecorator.propTypes = {
    meta: PropTypes.object,
    input: PropTypes.shape({
      name: PropTypes.string,
      onBlur: PropTypes.func,
      onChange: PropTypes.func,
      onDragStart: PropTypes.func,
      onDrop: PropTypes.func,
      onFocus: PropTypes.func,
      value: PropTypes.any,
    }).isRequired,
  };
  return InputToPropsDecorator;
}
