import record, { listOf, integer } from 'cpcs-recordjs';
import { field, selector } from 'cpcs-reconnect';
import { listToMap, toGroupedList } from 'lib/serialize';
import * as A from './CountryAction';
import { Country } from 'model';

const State = record('Countries', {
  list: listOf(Country),
  common: listOf(integer),
  defaultCountry: integer,
});

const resort = (list, id) => list.sort((a, b) => {
  if (a.get('id') === id) return -1;
  if (b.get('id') === id) return 1;
  return 0;
});

export const countries = field('countries');
export const countryList = countries.then(State.$list);
export const defaultCountry = countries.then(State.$defaultCountry);
export const countrySortedList = selector(countryList, defaultCountry, resort);
export const countriesValidSel = countrySortedList.then(l => l.filter(v => !v.historical && !!v.code));
export const countryCommonList = countries.then(State.$common);
export const groupedCountries = selector(countryList, countryCommonList, toGroupedList);
export const countriesById = selector(countryList, listToMap);
export const countriesById_sel = countriesById;

export const findDefaultCountryReducer = (prev, item) => {
  if (prev) return prev;
  return item.get('code') === 'US' ? item.get('id') : null;
};
export const USAIdSelector = countryList.then(l => l.reduce(findDefaultCountryReducer, null));

export const reducer = {
  countries(state = new State(), action) { //NOSONAR
    switch (action.type) { //NOSONAR

      case A.fetchCountriesAction.success:
        return state.apply(
          State.$list.parsed(action.payload.content),
          State.$common.parsed(action.payload.common),
          State.$defaultCountry.parsed(action.payload.defaultCountry),
        );

      default:
        return state;
    }
  },
};
