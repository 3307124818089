import record from 'cpcs-recordjs';
import { asyncAction } from '../../lib/actions';
import { field } from 'cpcs-reconnect';

export const sendCreatePasswordEmailAction = asyncAction('ACCOUNT/SEND_CREATE_PASSWORD_EMAIL');

const State = record('createPassword', {
  newEmailSent: false,
});

const base = field('createPassword');
export const sendCreatePasswordEmail_sel = base.then(State.$newEmailSent);

const defaultState = (new State());

export const reducer = {
  createPassword: (state = defaultState, action) => {
    switch (action.type) {
      case sendCreatePasswordEmailAction.success:
        return state.apply(
          State.$newEmailSent.set(true),
        );
      default:
        return state;
    }
  },
};

