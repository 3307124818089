import React from 'react';
import injectSheet from 'lib/sheet';
import sheet from './sheet';
import cx from 'classnames';
import { connect } from 'cpcs-reconnect';
import { firstNotification as firstNotification_sel } from 'domain/env/EnvModel';
import {
  removeNotification as removeNotificationAction,
  clearOutdatedNotifications as clearOutdatedNotificationsAction,
} from 'domain/env/EnvActions';
import { restoreAction } from 'domain/const';
import { push as pushAction } from 'connected-react-router';
import { currentTime } from 'lib/helpers';
import { lnk } from 'lib/routes';
import PropTypes from 'prop-types';
import I from 'immutable';

class Notifications extends React.Component {
  static propTypes = {
    classes: PropTypes.shape({
      Notifications: PropTypes.string.isRequired,
      controls: PropTypes.string.isRequired,
      control: PropTypes.string.isRequired,
      message: PropTypes.string.isRequired,
    }).isRequired,
    notification: PropTypes.instanceOf(I.Record),
    restore: PropTypes.func.isRequired,
    removeNotification: PropTypes.func.isRequired,
    clearOutdatedNotifications: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
  };

  static timeout = 0;

  UNSAFE_componentWillMount = () => {
    const { notification, clearOutdatedNotifications } = this.props;
    if (!notification) return;
    const currentTimestamp = parseInt(currentTime.format('x'), 10);
    const remainingTime = notification.get('showTime') + notification.get('timestamp') - currentTimestamp;

    if (remainingTime > 0){
      this.timeout = setTimeout(() => clearOutdatedNotifications(currentTimestamp + remainingTime), remainingTime);
    } else {
      clearOutdatedNotifications(currentTimestamp);
    }
  }

  UNSAFE_componentWillReceiveProps = ({ notification, clearOutdatedNotifications }) => {
    if (notification && notification !== this.props.notification){
      clearTimeout(this.timeout);
      const currentTimestamp = parseInt(currentTime.format('x'), 10);
      const remainingTime = notification.get('showTime') + notification.get('timestamp') - currentTimestamp;

      if (remainingTime > 0){
        this.timeout = setTimeout(() => clearOutdatedNotifications(currentTimestamp + remainingTime), remainingTime);
      } else {
        clearOutdatedNotifications(currentTimestamp);
      }
    }
  }

  onRestore = () => {
    const { notification, restore, removeNotification } = this.props;
    restore(notification.get('restore') || {});
    removeNotification(notification.get('timestamp'));
  }

  onLink = () => {
    const { notification, push, removeNotification } = this.props;
    push(lnk(notification.getIn(['link', 'route']), notification.getIn(['link', 'params'])));
    removeNotification(notification.get('timestamp'));
  }

  render(){
    const { classes, notification } = this.props;
    if (notification) {
      return (
        <div className={cx(classes.Notifications, classes[notification.get('type')])}>
          <span
            className={classes.message}
            children={notification.get('title')}
          />
          <div className={classes.controls}>
            {
              notification.get('restore') &&
                <button
                  className={classes.control}
                  onClick={this.onRestore}
                  children="UNDO"
                />
            }
            {
              notification.getIn(['link', 'route']) &&
                <button
                  className={classes.control}
                  onClick={this.onLink}
                  children={notification.getIn(['link', 'title'])}
                />
            }
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}

export const NotificationsPure = injectSheet(sheet)(Notifications);
export default connect({
  notification: firstNotification_sel,
  removeNotification: removeNotificationAction,
  restore: restoreAction,
  clearOutdatedNotifications: clearOutdatedNotificationsAction,
  push: pushAction,
})(NotificationsPure);
