const hideAmount = true;
export default {
  // share type from API
  'sharesPage.title': { title: 'Share' },
  'sharedWithMePage.header.amount': { single: 'share', many: 'shares', zero: 'share' },
  'sharedByMePage.header.amount': { single: 'share', many: 'shares', zero: 'share' },
  // 'sharedArtworkPage.title.art_work': { single: 'artwork', many: 'artworks', zero: 'artworks' },
  // 'sharedArtworkPage.title.gallery': { single: 'gallery', many: 'galleries', zero: 'galleries' },
  // 'sharedArtworkPage.title.alert': { single: 'lot', many: 'lots', zero: 'lots' },

  // 'sharedArtworkPage.title.alert': { single: 'lot', many: 'lots', zero: 'lots' },
  'sharedArtworkPage.title.alert': { single: 'Artwork', many: 'Artworks', zero: 'Artworks', hideAmount },
  'sharedArtworkPage.title.artist': { single: 'Artwork', many: 'Artworks', zero: 'Artworks', hideAmount },
  'sharedArtworkPage.title.art_work': { single: 'Artwork', many: 'Artworks', zero: 'Artworks', hideAmount },
  'sharedArtworkPage.title.art_work_stack': { single: 'Artwork', many: 'Artworks', zero: 'Artworks', hideAmount },
  'sharedArtworkPage.title.gallery': { single: 'Artwork', many: 'Artworks', zero: 'Artworks', hideAmount },
  'sharedArtworkPage.title.undefined': { single: 'Artwork', many: 'Artworks', zero: 'Artworks', hideAmount },
  'sharedArtworkPage.title.consideration': { single: 'Artwork', many: 'Artworks', zero: 'Artworks', hideAmount },
  'sharedArtworkPage.title.consideration_artist': { single: 'Artwork', many: 'Artworks', zero: 'Artworks', hideAmount },

  'sharedItemsPage.title.alert': { single: 'lot', many: 'lots', zero: 'lots' },
  'sharedItemsPage.title.artist': { single: 'artist', many: 'artists', zero: 'artists' },
  'sharedItemsPage.title.art_work': { single: 'artwork', many: 'artworks', zero: 'artworks' },
  'sharedItemsPage.title.art_work_stack': { single: 'artwork', many: 'artworks', zero: 'artworks' },
  'sharedItemsPage.title.gallery': { single: 'gallery', many: 'galleries', zero: 'galleries' },
  'sharedItemsPage.title.consideration': { single: 'artwork', many: 'artworks', zero: 'artworks' },
  'sharedItemsPage.title.consideration_artist': { single: 'artist', many: 'artists', zero: 'artists' },

  'sharedGalleryPage.title.gallery': { single: 'Gallery',  many: 'Galleries', zero: 'Galleries' },
  'sharedGalleryPage.title.amount': { single: 'artwork', many: 'artworks', zero: 'artworks' },
  // 'sharedItemsPage.breadcrumbs.alert': { single: 'lot', many: 'lots', zero: 'lots' },
  // 'sharedItemsPage.breadcrumbs.artist': { single: 'artist', many: 'artists', zero: 'artists' },
  // 'sharedItemsPage.breadcrumbs.art_work': { single: 'artwork', many: 'artworks', zero: 'artworks' },
  // 'sharedItemsPage.breadcrumbs.art_work_stack': { single: 'artwork', many: 'artworks', zero: 'artworks' },
  // 'sharedItemsPage.breadcrumbs.gallery': { single: 'gallery', many: 'galleries', zero: 'galleries' },
  // 'sharedItemsPage.breadcrumbs.consideration': { single: 'artwork', many: 'artworks', zero: 'artwork' },
  // 'sharedItemsPage.breadcrumbs.consideration_artist': { single: 'artist', many: 'artists', zero: 'artists' },

  'sharingTable.alert': { single: 'lot', many: 'lots', zero: 'lots' },
  'sharingTable.artist': { single: 'artist', many: 'artists', zero: 'artists' },
  'sharingTable.art_work': { single: 'artwork', many: 'artworks', zero: 'artworks' },
  'sharingTable.art_work_stack': { single: 'artwork', many: 'artworks', zero: 'artworks' },
  'sharingTable.gallery': { single: 'gallery', many: 'galleries', zero: 'galleries' },
  'sharingTable.consideration': { single: 'artwork', many: 'artworks', zero: 'artworks' },
  'sharingTable.consideration_artist': { single: 'artist', many: 'artists', zero: 'artists' },

  'sharePagesBreadcrumbs.sharingDirection.shared-to-me': { title: 'Shared with Me' },
  'sharePagesBreadcrumbs.sharingDirection.shared-by-me': { title: 'Shared by Me' },

  // groupBy
  'artworks': { single: 'artwork', many: 'artworks', zero: 'artworks' },
  'authors': { single: 'artist', many: 'artists', zero: 'artists' },
  'lots': { single: 'lot', many: 'lots', zero: 'lots' },
  'galleries': { single: 'gallery', many: 'galleries', zero: 'galleries' },
  // other
  'artwork': { single: 'artwork', many: 'artworks', zero: 'artworks' },
  'alert': { single: 'alert', many: 'alerts', zero: 'alerts' },
  'auction_alert': { single: 'auction alert', many: 'auction alerts', zero: 'auction alerts' },
  'dayLeft': { single: 'day left', many: 'days left', zero: 'Today' },

  'filterLabel.art_work': { title: 'Type art title' },
  'filterLabel.gallery': { title: 'Type art title' },
  'filterLabel.artist': { title: 'Type artist name' },
  'filterLabel.alert': { title: 'Type lot title' },
  'filterLabel.consideration': { title: 'Type art title' },
  'filterLabel.consideration_artist': { title: 'Type artist name' },

  'accountForm.email.error.alreadyInUse': { title: 'This email is already in use' },

  'abvCountdownBanner.worksValued': { single: 'work valued', many: 'works valued', zero: 'works valued' },

  'sharePropsCheckbox.category': { title: 'Category' },
  'sharePropsCheckbox.condition': { title: 'Condition' },
  'sharePropsCheckbox.date': { title: 'Year Created' },
  'sharePropsCheckbox.substrate': { title: 'Substrate' },
  'sharePropsCheckbox.subject': { title: 'Subject' },
  'sharePropsCheckbox.surface': { title: 'Surface' },
  'sharePropsCheckbox.notes': { title: 'Notes' },
  'sharePropsCheckbox.pricePaid': { title: 'Price Paid' },
  'sharePropsCheckbox.provenance': { title: 'Provenance' },
  'sharePropsCheckbox.signature': { title: 'Signature' },
  'sharePropsCheckbox.size': { title: 'Size' },
  'sharePropsCheckbox.valuation': { title: 'Valuation' },
  'sharePropsCheckbox.dateOfAcquisition': { title: 'Date of Acquisition' },
  'sharePropsCheckbox.literature': { title: 'Literature' },
  'sharePropsCheckbox.exhibition': { title: 'Exhibitions' },
  'sharePropsCheckbox.catalogRaisonne': { title: 'Catalogue Raisonné' },
  'sharePropsCheckbox.salesHistory': { title: 'Sales History' },
  'sharePropsCheckbox.upcomingSale': { title: 'Upcoming sale' },
  'sharePropsCheckbox.files': { title: 'Files' },
  'sharePropsCheckbox.medium': { title: 'Medium' },


  'artistPage.header.artworks.amount': { single: 'artwork', many: 'artworks', zero: 'artworks' },

  'collectionListPage.header.entityAmount.gallery': { single: 'gallery', many: 'galleries', zero: 'galleries' },
  'collectionListPage.header.entityAmount.artwork': { single: 'artwork', many: 'artworks', zero: 'artworks' },
  'collectionListPage.header.entityAmount.track': { single: 'track', many: 'tracks', zero: 'tracks' },
  'notification.snAuth.tryAgainLater': { title: 'Sorry, the service is temporarily unavailable, try after 15 minutes or use another social network account' },
  'shared_entity_gallery': { single: 'gallery', many: 'galleries', zero: 'galleries' },
  'shared_entity_art_work': { single: 'artwork', many: 'artworks', zero: 'artworks' },
  'shared_entity_art_work_stack': { single: 'artwork', many: 'artworks', zero: 'artworks' },
  'shared_entity_artist': { single: 'artist', many: 'artists', zero: 'artists' },
  'shared_entity_alert': { single: 'lot', many: 'lots', zero: 'lots' },
  'shared_entity_consideration': { single: 'artwork', many: 'artworks', zero: 'artworks' },
  'shared_entity_consideration_artist': { single: 'artist', many: 'artists', zero: 'artists' },
  'notification.accountData.saved.success': { title: 'Changes were successfully saved.' },
  'notification.confirmEmail.success': { title: 'Yout email confirmed successfully.' },
  // 'rtv.UPDATE_AVAILABLE': { title: 'Available' },
  'rtv.AVAILABLE': { title: 'Available' },
  'rtv.NOT_AVAILABLE': { title: 'Not Yet Available' },
  'rtv.PROCESSING': { title: 'Processing' },
  'couponForm.error.invalid': { title: 'The coupon code is invalid' },
  'couponForm.error.freeSubscription': { title: 'Invalid coupon' },

  'subscriptionType.SUBSCRIPTION_TYPE_TRIAL.title': { title: 'TRIAL' },
  'subscriptionType.SUBSCRIPTION_TYPE_MEMBER.title': { title: 'MEMBER' },
  'subscriptionType.SUBSCRIPTION_TYPE_COLLECTOR.title': { title: 'COLLECTOR' },
  'subscriptionType.SUBSCRIPTION_TYPE_PROFESSIONAL.title': { title: 'PATRON' },
  'subscriptionType.SUBSCRIPTION_TYPE_CUSTOM.title': { title: 'CUSTOM' },
  'subscriptionType.SUBSCRIPTION_TYPE_PREMIUM.title': { title: 'PREMIUM' },
  'subscriptionType.SUBSCRIPTION_TYPE_ENTERPRISE.title': { title: 'ENTERPRISE' },

  'rtvSidebar.rtvValue.amount': { single: 'ARTBnk Value', many: 'ARTBnk Values', zero: 'ARTBnk Values' },
  'rtvSidebar.rtvValue.amountFree': { single: 'FREE ARTBnk Value', many: 'FREE ARTBnk Values', zero: 'FREE ARTBnk Values' },
  'rtvSidebar.title.SINGLE_RTV': { title: 'ARTBnk Value' },
  'rtvSidebar.title.SUBSCRIPTION_TYPE_MEMBER': { title: 'Member' },
  'rtvSidebar.title.SUBSCRIPTION_TYPE_COLLECTOR': { title: 'Collector' },
  'rtvSidebar.title.SUBSCRIPTION_TYPE_PROFESSIONAL': { title: 'Patron' },
  'rtvSidebar.product.SINGLE_RTV': { title: 'Single Valuation' },
  'rtvSidebar.product.SUBSCRIPTION_TYPE_MEMBER': { title: 'Member' },
  'rtvSidebar.product.SUBSCRIPTION_TYPE_COLLECTOR': { title: 'Collector' },
  'rtvSidebar.product.SUBSCRIPTION_TYPE_PROFESSIONAL': { title: 'Patron' },

  'artwork.notAccurate.Message': { title: 'Subject to ARTBnk Verification' },

};
