import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'cpcs-reconnect';
import cx from 'classnames';
import Dropzone from 'react-dropzone';
import {
  uploadPictureAction,
  removePictureAction,
  uploadErrorAction,
  getPicture,
  avatarUploadError,
} from 'domain/env';
import { uploadedURL } from 'lib/helpers';
import injectSheet from 'lib/sheet';
import styles from './sheet.js';

const MB = 1024 * 1024;
const MAX_SIZE_MB = 2 * MB;
const SIZE_ERROR = 'Your profile picture should not be bigger than 2MB';

class Avatar extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    uploadPicture: PropTypes.func.isRequired,
    picture: PropTypes.string,
    removePicture: PropTypes.func.isRequired,
    uploadError: PropTypes.string,
    uploadErrorAction: PropTypes.func.isRequired,
  };

  uploadHandler = (files, rejected) => {
    if (!files.length && rejected.length) {
      this.props.uploadErrorAction(SIZE_ERROR);
      return;
    }
    this.props.uploadPicture(files[0]);
  }

  onRemove = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.removePicture();
  }

  get src() {
    return uploadedURL(this.props.picture);
  }

  render() {
    const { classes, uploadError } = this.props;
    const src = this.src;
    const style = src ? { backgroundImage: `url("${src}")` } : {};
    let dropzoneRef: any;
    return (
      <div className={cx(classes.Avatar, 'AvatarComponentRoot')} >
        <h3
          children="Profile Picture"
          className={cx(classes.title, 'Avatar')}
        />
        <Dropzone
          accept="image/jpeg, image/png"
          multiple={false}
          disablePreview
          ref={(node) => { dropzoneRef = node; }}
          onDrop={this.uploadHandler.bind(this)}
          maxSize={MAX_SIZE_MB}
        >
          {
            ({ getRootProps, getInputProps }) => (
              <div
                className={cx(
                  classes.dropZone,
                  {
                    [classes.dropZoneEmpty]: !src,
                  },
                )}
                style={style}
                {...getRootProps()}
              >
                <input {...getInputProps()} />
                <button
                  type="button"
                  title="remove image"
                  onClick={this.onRemove}
                  className={classes.removeIco}
                />
              </div>
            )
          }
        </Dropzone>
        <button
          type="button"
          className={classes.changeAvatarBtn}
          onClick={(e) => {
            e.preventDefault();
            dropzoneRef.open();
          }}
          children="Change"
        />

        {
          (uploadError) &&
            <div
              className={classes.uploadError}
              children={uploadError}
            />
        }
      </div>
    );
  }
}

export default compose(
  injectSheet(styles),
  connect({
    uploadPicture: uploadPictureAction,
    removePicture: removePictureAction,
    picture: getPicture,
    uploadError: avatarUploadError,
    uploadErrorAction,
  }),
)(Avatar);
