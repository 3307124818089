import React from 'react';
import PropTypes from 'prop-types';
import { ORANGE_COLOR } from 'theme/theme';
import injectSheet from 'lib/sheet';
// import { CONTACT_US_URL } from 'domain/const';

export const emailSheet = {
  WrongEmail: {
    whiteSpace: 'normal',
  },
  link: {
    color: ORANGE_COLOR,
  },
};

function WrongEmail({ classes }) {
  return (
    <div className={classes.WrongEmail}>
      {'This email is not in our system. Re-enter'}
      {/* <a href={CONTACT_US_URL} target="_blank" rel="noreferrer" className={classes.link}>Contact Us</a> */}
    </div>
  );
}

WrongEmail.propTypes = {
  classes: PropTypes.shape({
    WrongEmail: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
  }),
};
export const WrongEmailMessage = injectSheet(emailSheet)(WrongEmail);
