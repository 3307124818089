import record, { string } from 'cpcs-recordjs';
import { field } from 'cpcs-reconnect';
import { Artwork } from 'model';
import {
  showRtvFormAction,
  hideRtvFormAction,
  orderRtvAction,
  setOrderErrorAction,
  buyRtvFormSubmitAction,
  orderFreeRtvAction,
  setSelectedProductNameAction,
} from 'pages/common/buyRtv/buyRtvActions';
import {
  buyRtvForCouponAction,
} from 'pages/common/couponForm/couponFormActions';
import {
  upgradePlanAction,
} from 'pages/upgradeSubscriptionPage/upgradeSubscriptionActions';


const State = record('buyRtv', {
  artwork: Artwork,
  overlayFormVisible: false,
  orderError: '',
  submitting: false,
  gettingFree: false,
  product: string,
});

const base = field('buyRtv');
export const buyRtvPopupOpenedSelector = base.then(State.$overlayFormVisible);
export const buyRtvPopupArtworkSelector = base.then(State.$artwork);
export const orderErrorSelector = base.then(State.$orderError);
export const submittingSelector = base.then(State.$submitting);
export const gettingFreeSelector = base.then(State.$gettingFree);
export const selectedProduct_sel = base.then(State.$product);

export const reducer = {
  buyRtv(state = new State(), action) {
    switch (action.type) {
      case buyRtvForCouponAction.type:
      case buyRtvFormSubmitAction.type:
        return state.update(
          State.$submitting.set(true),
        );
      case setOrderErrorAction.type:
        return state.apply(
          State.$orderError.set(action.payload.message),
        );
      case orderRtvAction.failure:
      case upgradePlanAction.failure:
        return state.apply(
          State.$submitting.set(false),
        );
      case setSelectedProductNameAction.type:
        return state.apply(
          State.$product.set(action.payload),
        );
      case orderFreeRtvAction.type:
        return state.apply(
          State.$artwork.set(action.payload.artwork),
          State.$gettingFree.set(true),
        );
      case orderFreeRtvAction.success:
      case orderFreeRtvAction.failure:
        return state.apply(
          State.$gettingFree.set(false),
        );
      case showRtvFormAction.type:
        return state.apply(
          State.$overlayFormVisible.set(true),
          State.$artwork.set(action.payload.artwork),
        );
      case '@@router/LOCATION_CHANGE':
      case orderRtvAction.success:
      case upgradePlanAction.success:
      case hideRtvFormAction.type:
        return state.apply(
          State.$submitting.set(false),
          State.$orderError.set(''),
          State.$overlayFormVisible.set(false),
          State.$artwork.set(null),
          State.$gettingFree.set(false),
          State.$product.set(''),
        );
      default:
        return state;
    }
  },
};
