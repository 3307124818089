import record, { integer, listOf, setOf } from 'cpcs-recordjs';
import I from 'immutable';
import { field, selector } from 'cpcs-reconnect';
import * as CA from 'domain/considering/ConsideringActions';
import * as env from 'domain/env/EnvActions';
import { listToMap } from 'lib/serialize';
import { $replaceById } from 'lib/record-helpers/list';
import { removeAction, TYPE_CONSIDERATION_ARTWORK } from 'domain/const';
import { orderRtvAction } from 'pages/common/buyRtv/buyRtvActions';
import { Artwork, Pagination } from 'model';

function onRemove(state, { type, params }) {
  if (![TYPE_CONSIDERATION_ARTWORK].includes(type)) return state;
  return state.update('selected', v => v.filter(i => !params.ids.includes(i)));
}

/**
 * State
**/
const State = record('Considerations', {
  list: listOf(Artwork),
  listPagination: Pagination.parse({}),
  // considering-artworks or artists
  selected: setOf(integer),
});

/**
 * selectors
**/
const base = field('considerations');

export const consideringList = base.then(State.$list);
export const considerationsById = consideringList.then(
  list =>
    list.reduce((res, value) => res.set(value.get('id'), value), new I.Map()),
);

export const hasSelectedDrafts = base.then(
  state => {
    const list = State.$list(state).reduce((res, value) => res.set(value.get('id'), value), new I.Map());
    const selected = State.$selected(state);
    return selected.reduce((previous, id) => previous || list.getIn([id, 'isDraft'], false), false);
  },
);

export const selectedAOSelector = base.then(
  state => {
    const list = State.$list(state);
    const selectedIds = State.$selected(state);
    if (!selectedIds.size || !list.size) return list;
    return list.filter(v => selectedIds.includes(v.get('id')));
  },
);

export const artworksById = selector(consideringList, listToMap);
// considering-artworks or artists
export const selected = base.then(State.$selected);
export const listPagination = base.then(State.$listPagination);
export const listPaginationSelector = listPagination;

const getArtworkId = (state) => parseInt(state.router.location.match.params.artworkId, 10);

export const currentArtworkSelector = selector(artworksById, getArtworkId, (map, id) => map.get(id));

/**
 * reducer
**/
export const reducer = {
  considerations(state = new State(), action) { //NOSONAR
    switch (action.type) {

      case orderRtvAction.success:
        return state.update(State.$list.update(list => list.map(w => (
          w.id !== action.payload.artworkId ? w : w.apply(
            Artwork.$rtvPrice.parsed(action.payload.rtvPrice),
            Artwork.$rtvCurrency.parsed(action.payload.rtvCurrency),
            Artwork.$rtvStatus.parsed(action.payload.artwork.rtvStatus),
            Artwork.$purchasedDate.parsed(action.payload.purchasedDate),
          )
        ))));

      case env.logOut.type:
        return new State();

      case CA.updateConsiderationAction.success:
      case CA.fetchConsiderationItemAction.success:
        return state.apply(
          State.$list.update(
            $replaceById(Artwork.parse(action.payload)),
          ),
        );
      case CA.considerationAppendPageAction.failure:
      case CA.fetchConsiderationsAction.failure:
        return State.$listPagination.update(v => v ? v.set('loading', false) : v)(state);
      case CA.considerationAppendPageAction.request:
      case CA.fetchConsiderationsAction.request:
        return State.$listPagination.update(v => v.set('loading', true))(state);
      case CA.fetchConsiderationsAction.success:
        return state.apply(
          State.$list.parsed(action.payload.content.map(v => ({
            ...v,
            // isDraft: true,
            // upcomingSale: { soldDate: '2019-10-13' },
            // isDueDiligenceRisk: true,
          }))),
          State.$listPagination.parsed(action.payload),
        );
      case CA.considerationAppendPageAction.success:
        return state.apply(
          State.$list.parsedBy('concat', action.payload.content.map(v => ({
            ...v,
            // isDraft: true,
            // upcomingSale: { soldDate: '2019-10-13' },
            // isDueDiligenceRisk: true,
          }))),
          State.$listPagination.parsed(action.payload),
        );
      case CA.setSelectedAction.type:
        return state.apply(
          State.$selected.set(action.payload),
        );
      case CA.clearSelectedAction.type:
        return state.apply(
          State.$selected.set(I.Set()),
        );

      case removeAction.success:
        return onRemove(state, action.payload);

      default:
        return state;
    }
  },
};
