import { DEFAULT_FONTFAMILY, FIELDSET_COLOR, FIELDSET_HEADER_LINE } from 'theme/theme';
import { FOCUSED_STYLE } from 'theme/focus';
import openedBg from './images/minus.svg';
import closedBg from './images/plus.svg';

export default {
  Fieldset: {
    paddingTop: 30,
    border: '0 none',
    '&.collapsed': {
      overflow: 'hidden',
      height: 44 + 30,
    },
    '&.scrollableDefinitions': {
      width: 'calc(100% + 3px)',
      paddingLeft: 3,
    },
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: 20,
    '&:after': {
      backgroundColor: FIELDSET_HEADER_LINE,
      height: '1px',
      content: '""',
      display: 'block',
      flex: '1 1 0',
    },
  },
  content: {
    '@media screen and (min-width: 1761px)': {
      '&.report_definitions': {
        maxHeight: 560,
        overflowX: 'hidden',
        overflowY: 'auto',
      },
    },
  },
  btn: {
    flex: '0 0 24px',
    height: 24,
    border: '',
    marginRight: 10,
    padding: [0, 10, 0, 34],
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    background: {
      image: `url("${openedBg}")`,
      size: '24px',
      position: 'left center',
      repeat: 'no-repeat',
      color: 'transparent',
    },
    color: FIELDSET_COLOR,
    fontFamily: DEFAULT_FONTFAMILY,
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '24px',
    '&:focus': {
      ...FOCUSED_STYLE,
    },
  },
  btnOpened: {
    extend: 'btn',
  },
  btnClosed: {
    extend: 'btn',
    backgroundImage: `url("${closedBg}")`,
  },
  title: {
    color: FIELDSET_COLOR,
    fontFamily: DEFAULT_FONTFAMILY,
    fontSize: 12,
    fontWeight: 500,
    lineHeight: '24px',
    textTransform: 'uppercase',
  },
};
