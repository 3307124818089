// import filter from './images/filter.svg';
// import filter_ac from './images/filter-ac.svg';
import filter_opened from './images/ic-sort-black-24-px-opened.svg';
import filter_closed from './images/ic-sort-black-24-px-closed.svg';
import filter_opened_active from './images/ic-sort-black-24-px-opened-active.svg';
import filter_closed_active from './images/ic-sort-black-24-px-closed-active.svg';
import remove from './images/remove.svg';
import {
  LAYOUT_768_MAX_WIDTH,
} from 'theme/layout/responsiveList';

import {
  DARK_BLUE_COLOR,
  DEFAULT_BUTTON_COLOR,
  DEFAULT_FONTFAMILY,
  PLACEHOLER_COLOR,
} from 'theme/theme';
import { FOCUSED_STYLE } from 'theme/focus';

const OPENED_BG = '#FFF';
const OPENED_BG_HOVER = '#EFEFEF';
const OPENED_BORDER = '#000';
const OPENED_FORG = '#000';

const CLOSED_BG = '#000';
const CLOSED_BG_HOVER = '#333';
const CLOSED_BORDER = '#000';
const CLOSED_FORG = '#FFF';

export default {
  Filters: {
    padding: [0, 0, 20],
    boxSizing: 'border-box',
    '&.collectionListPage': {
      paddingTop: 20,
    },
    '&.rtvValueListPage': {
      padding: 0,
      margin: 0,
      display: 'none',
    },
    '&.discoverPage, &.rtvValueListPage': {
      padding: 0,
      margin: 'unset',
      order: 3,
    },
    '@media screen and (max-width: 900px)': {
      '&.rtvValueListPage': {
        display: 'block',
      },
    },
    '@media screen and (max-width: 610px)': {
      '&.discoverPage': {
        marginTop: 30,
      },
    },
    '@media screen and (max-width: 550px)': {
      width: '100%',
    },
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row-reverse',
    '@media screen and (max-width: 319px)': {
      flexDirection: 'column',
      height: 70,
    },
  },
  sortDropdownBox: {
    // flex: '1 0 220px',
    display: 'flex', // align filter verically
  },
  advancedFilterButtonBox: {
    display: 'flex',
  },
  searchBox: {
    marginLeft: 30,
    height: 25,
    position: 'relative',
    width: 300,
  },
  advanced: {
    font: `500 12px/18px ${DEFAULT_FONTFAMILY}`,
    textDecoration: 'none',
    position: 'relative',
    width: 110,
    whiteSpace: 'nowrap',
    padding: [3, 8, 4, 27],
    cursor: 'pointer',
    height: 28,
    backgroundColor: OPENED_BG,
    border: `1px solid ${OPENED_BORDER}`,
    color: OPENED_FORG,
    textTransform: 'uppercase',
    textAlign: 'left',
    '&:hover': {
      backgroundColor: OPENED_BG_HOVER,
    },
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      display: 'inline-block',
      width: 25,
      height: 25,
      verticalAlign: 'middle',
      left: 0,
      margin: 'auto',
      top: 0,
      bottom: 0,
    },
    '&:before': {
      background: `url("${filter_opened}") no-repeat scroll 6px center`,
    },
    '&:after': {
      background: `url("${filter_closed}") no-repeat scroll 6px center`,
      opacity: 1,
    },
    '&.active': {
      '&:before': {
        backgroundImage: `url("${filter_opened_active}")`,
      },
      '&:after': {
        backgroundImage: `url("${filter_closed_active}")`,
      },
    },
    '&.AFClosed': {
      backgroundColor: CLOSED_BG,
      color: CLOSED_FORG,
      border: `1px solid ${CLOSED_BORDER}`,
      '&:hover': {
        backgroundColor: CLOSED_BG_HOVER,
      },
      '&:after': {
        opacity: 0,
      },
    },
    // '&.active:after': {
    //   opacity: 1,
    // },
    '&.open': {
      display: 'none',
    },
    [`@media screen and (max-width: ${LAYOUT_768_MAX_WIDTH}px)`]: { // 833 (804 + 29)
      backgroundColor: CLOSED_BG,
      color: CLOSED_FORG,
      border: `1px solid ${CLOSED_BORDER}`,
      '&.toggle': {
        display: 'none',
      },
      '&.open': {
        display: 'block',
        '&:hover': {
          backgroundColor: CLOSED_BG_HOVER,
        },
        '&.AFOpened': {
          backgroundColor: OPENED_BG_HOVER,
        },
        '&.AFClosed': {
          backgroundColor: CLOSED_BG_HOVER,
        },
      },
      '&:after': {
        opacity: 0,
      },
      '&.AFOpened': {
        backgroundColor: OPENED_BG,
        color: OPENED_FORG,
        border: `1px solid ${OPENED_BORDER}`,
        '&:after': {
          opacity: 1,
        },
      },
      '&.AFClosed': {
      },
      // '&.active:after': {
      //   opacity: 1,
      // },
    },
    '@media screen and (max-width: 347px)': {
      width: 'auto',
    },
    '@media screen and (max-width: 319px)': {
      textAlign: 'center',
      width: '100%',
    },
  },
  advancedWord: {
    '@media screen and (max-width: 347px)': {
      display: 'none',
    },
    '@media screen and (max-width: 319px)': {
      display: 'unset',
    },
  },
  // TAGS==========
  tags: {
    margin: [18, 0],
  },
  title: {
    fontSize: 12,
    letterSpacing: '2px',
    fontWeight: 500,
    textTransform: 'uppercase',
  },
  list: {
    margin: [10, 0, 0],
  },
  item: {
    display: 'inline-block',
    backgroundColor: 'rgba(73, 144, 226, .2)',
    padding: [3, 6],
    margin: [0, 12, 6, 0],
    fontSize: 14,
    fontWeight: 500,
  },
  itemContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  remove: {
    background: `url("${remove}") scroll no-repeat center`,
    backgroundSize: [7, 7],
    display: 'inline-block',
    width: 12,
    height: 12,
    marginLeft: 3,
  },
  //==============
  //====SortBy===
  SortBy: {
    whiteSpace: 'nowrap',
    display: 'inline-flex',
    alignItems: 'center',
  },
  label: {
    color: DARK_BLUE_COLOR,
    fontSize: 14,
    '@media screen and (max-width: 555px)': {
      display: 'none',
    },
  },
  select: {
    display: 'inline-block',
    minWidth: 72,
    fontSize: '16px',
    fontWeight: 500,
    border: '0 none',
    fontFamily: DEFAULT_FONTFAMILY,
    marginLeft: 5,
  },
  option: {
    fontWeight: 400,
  },
  //==============
  switch: {
    position: 'absolute',
    zIndex: -1,
    '&:checked + $switchLabel': {
      opacity: 1,
    },
    '&:checked + $switchLabel:hover': {
      opacity: 1,
    },
  },
  switchLabel: {
    display: 'inline-block',
    cursor: 'pointer',
    width: 24,
    height: 24,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    verticalAlign: 'middle',
    opacity: 0.3,
    marginLeft: 9,
    '&:hover': {
      backgroundColor: '#efefef',
      opacity: 0.5,
    },
  },
  selectField: {
    border: 'none',
    borderBottom: `1px solid ${DARK_BLUE_COLOR}`,
    color: DARK_BLUE_COLOR,
    width: 300,
    backgroundColor: 'transparent',
    lineHeight: '25px',
    height: '25px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    paddingLeft: 5,
    paddingRight: 20,
    fontFamily: DEFAULT_FONTFAMILY,
    '&:focus': {
      ...FOCUSED_STYLE,
    },
    '&::placeholder': {
      color: PLACEHOLER_COLOR,
    },
  },
  clear: {
    position: 'absolute',
    right: 1,
    lineHeight: '24px',
    fontSize: 14,
    fontWeight: 600,
    fontFamily: 'inherit',
    paddingLeft: 5,
    paddingRight: 5,
    cursor: 'pointer',
    color: DEFAULT_BUTTON_COLOR,
    ...FOCUSED_STYLE,
  },
  //==============
};
