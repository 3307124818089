//font
export const DEFAULT_FONTFAMILY = '"Facto", Arial, Helvetica, sans-serif';
export const DEFAULT_FONTFAMILY_3 = 'Haas Grot Disp Web, Arial, Helvetica, sans-serif';
export const SANSPRO_FONTFAMILY = '"Source Sans Pro", Arial, Helvetica, sans-serif';
export const ROBOTO_FONTFAMILY = '"Roboto", Arial, Helvetica, sans-serif';

// colors
export const DEFAULT_TEXT_COLOR = '#000';
export const DARK_BLUE_COLOR = '#000000';
export const ORANGE_COLOR = '#a9431e';
export const ERROR_COLOR = '#d0021b';
export const ERROR_MESSAGE_COLOR = '#2e343e';
export const WHITE = '#FFF';
export const FIELDSET_COLOR = '#000';
export const FIELDSET_HEADER_LINE = '#d8d8d8';
export const FOCUS_OUTLINE = '#9ECAED';
const PRIMARY_BLUE = '#304C95';
export const SMOKY_WHITE = '#F0F0F0';

// redesign new colors
export const NEW_STYLE = PRIMARY_BLUE;
export const PLACEHOLER_COLOR = DARK_BLUE_COLOR;
export const DIVIDER_COLOR = '#d8d8d8';
export const DATA_TABLE_BORDER_COLOR = '#d8d8d8';
export const RTV_VALUE_TABLE_BORDER_COLOR = '#D8D8D8';
export const ACTION_CURRENT_COLOR = PRIMARY_BLUE;
export const DEFAULT_BUTTON_COLOR = PRIMARY_BLUE;
export const SELECTED_ITEMS_CONTROLS_BG = DARK_BLUE_COLOR;
export const PAGINATION_BG_COLOR = DARK_BLUE_COLOR;
export const PAGINATION_CLICKABLE_BORDER = DARK_BLUE_COLOR;
export const SELECTED_GALLERY_BORDER = '#000';
export const TEXT_LINK_COLOR = PRIMARY_BLUE;
export const HEADER_SEARCH_BACKGROUND = '#F7F7F7';
export const SEE_ALL_HOVER_COLOR = '#304C95';
export const GET_RTV_BUTTON = '#304C95';
export const BUY_RTV_FORM_SUBMIT_BUTTON = '#304C95';
export const RTV_COST = '#304C95';
export const UPGRADE_PLAN_BTN_BG = PRIMARY_BLUE;
export const UPGRADE_PLAN_BTN_COLOR = WHITE;
export const UPGRADE_PLAN_HELP_COLOR = '#000';
export const RTV_DATE = '#304C95';
export const ITEM_LIST_AUCTION = '#8E8F94';
export const NO_ARTS_TITLE = '#3b3b3b';
export const NO_ARTS_DESCRIPTION = '#8e8f94';
export const SELECT_GALLERY_POPUP_ITEM_HOVER = '#efefef';
export const GALLERY_MENU = '#000';
export const GALLERY_MENU_ITEM_HOVER_BG = '#F7F7F7';
export const GALLERY_MENU_BG = WHITE;
export const GALLERY_ITEM_TITLE = '#000';
export const RADIO_LABEL = '#000';
export const TABLET_TABLE_DEFAULT = '#000';
export const TABLET_TABLE_DATE = '#818181';
export const FILE_ODD_BG = '#F7F7F7';
export const AUTOCOMPBLETE_PLACEHOLDER = '#2e343e';
export const CHECKBOX_CHECKED = '#2e343e';
export const CHECKBOX_CHECKED_BLUE = '#304c95';
export const RADIO_CHECKED = '#2e343e';
export const ALERT_LIST_SEE_ALL = '#2e343e';
export const BILLING_PAGE_SUBMIT_CHANGE = '#2e343e';
export const BILLING_PAGE_SUBMIT_ADDRESS_FORM = '#2e343e';
export const SUBSCRIPTION_PLAN_MOST_POPULAR_COLOR = PRIMARY_BLUE;
export const AO_FORM_BUTTON = '#2e343e';
export const COMPARABLES_DESCRIPTION = '#2e343e';
export const SHARES_SEE_ALL = PRIMARY_BLUE;
export const SHARES_TABLE_TITLE = '#2e343e';
export const BUTTON_ARTIST_EDIT = '#2e343e';
export const PREFERENCES_ARTIST_TAG_BG = WHITE;
export const PREFERENCES_ARTIST_TAG_COLOR = '#000';
export const DISCOVER_PAGE_REMOVE_ALERT_BG = WHITE;
export const DISCOVER_PAGE_REMOVE_ALERT_BORDER = DARK_BLUE_COLOR;
export const DISCOVER_PAGE_REMOVE_ALERT_BG_HOVER = SMOKY_WHITE;
export const DISCOVER_PAGE_REMOVE_ALERT = DARK_BLUE_COLOR;
export const LOGIN_PLACEHOLDER_COLOR = '#5a5a5a';

export const HEADER_AVA_LETTERS = '#2e343e';
export const HEADER_DROPDOWN_DEFAULT_FONT = '#000';
export const HEADER_DROPDOWN_HOVER = '#d8d8d8';
export const HEADER_DROPDOWN_SIGNOUT = '#7f7f7f';

// sidebar

export const SIDEBAR_DIVIDER_COLOR = '#d8d8d8';
export const SIDEBAR_COUNTER_COLOR = '#babcbf';
export const SIDEBAR_SHOWMOREBTN_COLOR = '#2e343e';

export const PAGE_SECTION_HEADER = '#000';
export const PAGE_SECTION_HEADER_LINK = PRIMARY_BLUE;
export const ALERT_LOT_PAGE_AUTHOR_WORKS_ALL_LINK = '#2e343e';

// advanced filter
export const ADVANCED_FILTER_LABEL_COLOR = '#2e343e';
