import messages from './messages';

function getTranslationKey(amount) {
  if (amount <= 0) return 'zero';
  if (amount === 1) return 'single';
  if (amount > 1) return 'many';
  return null;
}

const defaultRender = (amount, message) => (`${amount} ${message}`);

export const noAmountRender = (amount, message) => (message || '');

const translate = (name, amount, render = defaultRender) => {
  if (!messages[name]) {
    console.warn(new Error(`translation for ${name} amount: (${amount}) not found`));
    return `translation for ${name} not found`;
  }
  if (amount === undefined) {
    if (!messages[name].title && !messages[name].single) {
      console.warn(new Error(`translation for ${name} amount: (${amount}) not found`));
      return `translation for ${name} not found`;
    }
    return messages[name].title || messages[name].single;
  }
  const key = getTranslationKey(parseInt(amount, 10));
  if (key) {
    if (messages[name].hideAmount) {
      render = noAmountRender;
    }
    return render(amount, messages[name][key]);
  }
  console.warn(new Error(`translation for ${name} amount: (${amount}) not found`));
  return `translation for ${name} not found`;
};

export default translate;
