import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import { debounce } from 'lib/envGlobals';

import injectSheet from 'lib/sheet';
import sheet from './sheet';

class ThumbMenu extends React.Component {
  static propTypes = {
    setAsDefault: PropTypes.func.isRequired,
    removeImage: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    index: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    allowChangeDefault: PropTypes.bool,
    allowRemoveImage: PropTypes.bool,
    // node ref for portal
    portal: PropTypes.any,
  };

  unmounted = false;

  state = {
    menuOpened: false,
    pageX: 0,
    pageY: 0,
  }

  componentWillUnmount() {
    this.unmounted = true;
  }

  safeSetState = (...args) => {
    if (this.unmounted) return;
    this.setState(...args);
  }

  toglleMenu = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ menuOpened: !this.state.menuOpened, pageX: e.pageX, pageY: e.pageY });
  };

  closeMenu = debounce(() => {
    this.safeSetState({ menuOpened: false });
  }, 200);

  get menuStyle() {
    const { portal } = this.props;
    if (!portal) return {};
    const { pageX } = this.state;
    return {
      top: portal.getBoundingClientRect().height - 60 + 'px',
      left: pageX - portal.getBoundingClientRect().left + 'px',
    };
  }

  onMakeDefault = (e) => {
    const { setAsDefault, index } = this.props;
    e.stopPropagation();
    setAsDefault(index);
  }

  onRemoveImage = (e) => {
    const { removeImage, index } = this.props;
    e.stopPropagation();
    removeImage(index);
  }

  render() {
    const { classes, allowChangeDefault, allowRemoveImage, portal } = this.props;
    const { menuOpened } = this.state;
    /* eslint-disable jsx-a11y/anchor-is-valid */
    if (!allowChangeDefault && !allowRemoveImage) return null;
    return (
      <button
        onBlur={this.closeMenu}
        className={classes.ThumbMenu}
        onClick={this.toglleMenu}
      >
        {
          !menuOpened &&
            <div className={classes.hint} children="more options" />
        }
        {
          menuOpened && portal && ReactDOM.createPortal(
            <div className={classes.optionsPopupMenu} style={this.menuStyle}>
              <ul className={classes.optionsPopupMenuList}>
                {
                  allowChangeDefault &&
                    <li className={classes.optionsPopupMenuListItem}>
                      <a
                        className={classes.optionsPopupMenuListItemButton}
                        onClick={this.onMakeDefault}
                        role="button"
                        children="Make default"
                      />
                    </li>
                }
                {
                  allowRemoveImage &&
                    <li className={classes.optionsPopupMenuListItem}>
                      <a
                        className={classes.optionsPopupMenuListItemButton}
                        onClick={this.onRemoveImage}
                        role="button"
                        children="Delete"
                      />
                    </li>
                }
              </ul>
            </div>,
            portal,
          )
        }
      </button>
    );
    /* eslint-enable jsx-a11y/anchor-is-valid */
  }
}

export default injectSheet(sheet)(ThumbMenu);
