import { AOFormSheet } from 'pages/common/newPage/form/sheet';
import {
  label,
  fieldWrapper,
  formItem,
  selectWrapper,
  buttonContainer,
} from 'theme/form';

export default {
  HistoryField: {
    position: 'relative',
    marginTop: 12,
    '&:before': {
      display: 'block',
      content: '""',
      height: 0,
      borderTop: '1px dashed gray',
      position: 'relative',
      top: -6,
    },
    '&:first-child': {
      marginTop: 0,
    },
    '&:first-child:before': {
      opacity: 0,
    },
  },
  AppraisalField: {
    extend: 'HistoryField',
  },
  priceBox: {
    position: 'relative',
  },
  range: {
    display: 'flex',
    justifyContent: 'space-between',
    flex: '1 1 0',
  },
  button: AOFormSheet.button,
  add: AOFormSheet.add,
  remove: AOFormSheet.remove,
  buttonContainer,
  label,
  fieldWrapper,
  formItem,
  selectWrapper,
  expandButton: {
    position: 'relative',
    left: 0,
    top: 0,
    width: '100%',
    textAlign: 'left',
  },
  unsold: {
    marginBottom: 32,
    display: 'flex',
    opacity: 0.5,
    justifyContent: 'center',
    alignItems: 'center',
  },
};
