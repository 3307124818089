import icoDelete from './images/ic-delete.svg';
import icoShare from './images/ic-share.svg';
import icoAddToGallery from './images/ic-galleries.svg';
import icoCover from './images/ic-cover.svg';
import { DEFAULT_FONTFAMILY, SELECTED_ITEMS_CONTROLS_BG } from 'theme/theme';
import { SELECTED_ITEMS_CONTROLS_Z_INDEX } from 'theme/zIndex';

export default {
  SelectedItemsControls: {
    // root
    margin: 0,
  },
  clear: {
    color: 'white',
    marginRight: 5,
    cursor: 'pointer',
    fontSize: 32,
    lineHeight: '18px',
  },
  showIfNotEmpty: {
    position: 'fixed',
    left: 0,
    top: 0,
    width: '100%',
    zIndex: SELECTED_ITEMS_CONTROLS_Z_INDEX,
    height: 60,
    backgroundColor: SELECTED_ITEMS_CONTROLS_BG,
    boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.2)',
  },
  showIfNotEmptyInner: {
    display: 'flex',
    width: 1638,
    height: '100%',
    margin: [0, 'auto'],
    alignItems: 'center',
    justifyContent: 'space-between',
    '@media screen and (max-width: 1760px)': {
      width: 1360,
    },
    '@media screen and (max-width: 1460px)': {
      width: 1082,
    },
    '@media screen and (max-width: 1200px)': {
      width: 804,
    },
    '@media screen and (max-width: 900px)': {
      width: 526,
    },
    '@media screen and (max-width: 610px)': {
      width: '100%',
      padding: [30, 15],
    },
  },
  amount: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: DEFAULT_FONTFAMILY,
    fontSize: '18px',
    fontWeight: '500',
    textAlign: 'left',
    color: '#FFF',
    '@media screen and (max-width: 610px)': {
      fontSize: '16px',
    },
  },
  btns: {},
  btn: {
    width: 24,
    height: 24,
    marginRight: 24,
    cursor: 'pointer',
    background: {
      color: 'transparent',
      size: '24px 24px',
      repeat: 'no-repeat',
      position: 'center center',
    },
    border: 'none',
    '&:last-child': {
      marginRight: 0,
    },
    '@media screen and (max-width: 610px)': {
      marginRight: 14,
    },
  },
  btnDelete: {
    extend: 'btn',
    marginRight: 0,
    backgroundImage: `url("${icoDelete}")`,
    '@media screen and (max-width: 610px)': {
      marginRight: 0,
    },
  },
  btnShare: {
    extend: 'btn',
    backgroundImage: `url("${icoShare}")`,
  },
  btnAddToGallery: {
    extend: 'btn',
    backgroundImage: `url("${icoAddToGallery}")`,
  },
  btnCover: {
    extend: 'btn',
    backgroundImage: `url("${icoCover}")`,
  },
};
