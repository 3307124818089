import { DARK_BLUE_COLOR, DEFAULT_FONTFAMILY } from 'theme/theme';
import { button } from 'theme/button';
import { PageHeader } from 'theme/Dashboard';

export const sheet = {
  PageHeader,
  title: {
    color: DARK_BLUE_COLOR,
    fontSize: 24,
    fontWeight: 700,
    lineHeight: 1.25,
    fontFamily: DEFAULT_FONTFAMILY,
    minWidth: 0,
    flex: [0, 0, '50%'],
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    '@media screen and (max-width: 610px)': {
      width: '100%',
      marginTop: 26,
      order: 2,
    },
  },
  alertsQuantity: {
    fontSize: 18,
    fontWeight: 400,
    '@media screen and (max-width: 610px)': {
      display: 'block',
    },
  },
  total: {
    fontSize: 14,
    lineHeight: 1.25,
    fontFamily: DEFAULT_FONTFAMILY,
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
  totalStart: {
    color: DARK_BLUE_COLOR,
  },
  totalEnd: {
    color: DARK_BLUE_COLOR,
    fontWeight: 600,
  },
  button,
  noAlerts: {
    paddingTop: 100,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  noAlertsText: {
    paddingBottom: 30,
    color: 'rgba(0,0,0,0.3)',
    fontSize: 24,
    lineHeight: 1.25,
    fontFamily: DEFAULT_FONTFAMILY,
    fontWeight: 700,
  },
  noAlertsTextDefault: {
    paddingBottom: 30,
    color: '#000',
    fontSize: 24,
    lineHeight: 1.25,
    fontFamily: DEFAULT_FONTFAMILY,
    fontWeight: 400,
    textAlign: 'center',
  },
  filters: {
    padding: [20, 0],
  },
};
