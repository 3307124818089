import React from 'react';
import PropTypes from 'prop-types';
import { momentYear } from 'lib/envGlobals';

import injectSheer from 'lib/sheet';
import sheet from './sheet';

function Footer({ classes }) {
  return (
    <footer className={classes.Footer}>
      <div className={classes.termsAndConditionsLinks}>
        <a href="https://www.artbnk.com/subscription-terms" className={classes.termsAndConditionsLink} children="Subscription Terms" target="_blank" rel="noopener noreferrer"/>
        <a href="https://www.artbnk.com/privacy-policy" className={classes.termsAndConditionsLink} children="Privacy Policy" target="_blank" rel="noopener noreferrer"/>
        <a href="https://support.artbnk.com/hc/en-us" className={classes.termsAndConditionsLink} children="Help Center" target="_blank" rel="noopener noreferrer"/>
      </div>
      <div className={classes.copy}>&copy; {momentYear()} ART<span className={classes.textBold}>Bnk</span>. All rights reserved.</div>
    </footer>
  );
}

Footer.propTypes = {
  classes: PropTypes.shape({
    Footer: PropTypes.string.isRequired,
    termsAndConditionsLinks: PropTypes.string.isRequired,
    termsAndConditionsLink: PropTypes.string.isRequired,
    copy: PropTypes.string.isRequired,
    textBold: PropTypes.string.isRequired,
  }).isRequired,
};

export { Footer as FooterTest };

export default injectSheer(sheet)(Footer);
