import React from 'react';
import { compose } from 'redux';
import { connect } from 'cpcs-reconnect';
import PropTypes from 'prop-types';
import I from 'immutable';
import Popup from 'components/popup';
import injectSheet from 'lib/sheet';
import Title from 'pages/common/popup/title';
import SharingForm from 'pages/common/popupForms/share';
import { isPopupVisibleSelector, getPopupParamsSelector, hidePopupAction } from 'domain/ui';
import { galleryMapIndexedById_sel } from 'domain/galleries/GalleryModel';
import { artworksById } from 'domain/ownArtwork/OwnArtworkModel';
import { artworksById as considerationsById } from 'domain/considering/ConsideringModel';
import { currentPageArtworksSelector } from 'domain/alerts/AlertsModel';
import { getSharedArtworkList_sel } from 'domain/shares/ShareModel';
import translate from 'lib/translate';
import * as SHARE from 'domain/const';
import { SharePopup as sheet } from 'pages/common/popup/sheet';

/**
 *  SHARE_TYPE_ALERT          alert           currentPageArtworksSelector  domain/alerts/AlertsModel
 *  SHARE_TYPE_OWN_ARTWORK    art_work        artworksById                 domain/ownArtwork/OwnArtworkModel
 *  SHARE_TYPE_ARTWORK        art_work_stack
 *  SHARE_TYPE_GALLERY        gallery         galleryMapIndexedById_sel    domain/galleries/GalleryModel
 *  SHARE_TYPE_CONSIDERATION  consideration   considerationsById           domain/considering/ConsideringModel
**/

const defaultIcoPath = ['pictures', 0, 'name'];

const IconPath = {
  [SHARE.SHARE_TYPE_GALLERY]: ['picture'],
  [SHARE.SHARE_TYPE_OWN_ARTWORK]: defaultIcoPath,
  [SHARE.SHARE_TYPE_CONSIDERATION]: defaultIcoPath,
  [SHARE.SHARE_TYPE_ALERT]: defaultIcoPath,
  [SHARE.SHARE_TYPE_ARTWORK]: defaultIcoPath,
};

class SharePopup extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.shape({
      container: PropTypes.string,
      SharePopup: PropTypes.string,
    }).isRequired,
    popupParams: PropTypes.instanceOf(I.Map),
    [SHARE.SHARE_TYPE_GALLERY]: PropTypes.instanceOf(I.Map).isRequired, // eslint-disable-line react/no-unused-prop-types
    [SHARE.SHARE_TYPE_OWN_ARTWORK]: PropTypes.instanceOf(I.Map).isRequired, // eslint-disable-line react/no-unused-prop-types
    [SHARE.SHARE_TYPE_CONSIDERATION]: PropTypes.instanceOf(I.Map).isRequired, // eslint-disable-line react/no-unused-prop-types
    [SHARE.SHARE_TYPE_ALERT]: PropTypes.instanceOf(I.Map).isRequired, // eslint-disable-line react/no-unused-prop-types
    isPopupVisible: PropTypes.func.isRequired,
    hidePopup: PropTypes.func.isRequired,
    submitButtonTitle: PropTypes.string,
    artworks: PropTypes.instanceOf(I.Collection).isRequired,
  }

  get ico() {
    const { popupParams, artworks } = this.props;
    const share = popupParams.get('share');
    // edit share
    if (share && share.id) {
      if (!artworks) {
        console.error('there is no artworks in share');
        return '';
      }
      return artworks.getIn([0, ...defaultIcoPath]);
    }
    const shareType = popupParams.get('shareType');
    // used while creating share
    const items = this.props[shareType];
    if (!items) {
      console.error('unknown share type', popupParams.toJS());
    }
    const icoPath = IconPath[shareType] || defaultIcoPath;
    return popupParams.get('ids').reduce(((prev, id) =>
        prev || items.getIn([id, ...icoPath]) || ''
    ), '');
  }

  get title() {
    const { popupParams } = this.props;
    const shareType = popupParams.get('shareType');
    const amount = popupParams.get('ids').size;
    const entityTitle = translate(`shared_entity_${shareType}`, amount);
    return `Share ${entityTitle}`;
  }

  onCancel = () => this.props.hidePopup();

  get init() {
    const { popupParams } = this.props;
    const checkboxes = popupParams.get('checkboxMap').toJS();
    const ids = popupParams.get('ids').toJS();
    let id;
    let emails = [];
    let message;
    let shareValues = I.Map();
    const share = popupParams.get('share');
    if (share) {
      id = share.id;
      emails = share.get('emails') ? share.get('emails').toJS() : [];
      message = share.get('message', '') || '';
      shareValues = share.get('prop');
    }
    const checkboxValues = Object.keys(checkboxes).reduce((s, n) => ({ ...s, [n]: !checkboxes[n] || shareValues.get(n) }), {});
    return { emails, message, ids, id, ...checkboxValues };
  }

  render() {
    const { classes } = this.props;
    if (!this.props.isPopupVisible(SHARE.SHARE_POPUP)) return null;
    return (
      <Popup>
        <div className={`${classes.container} ${classes.SharePopup} SharePopup`}>
          <Title
            ico={this.ico}
            children={this.title}
            onClose={this.onCancel}
          />
          <SharingForm
            initialValues={this.init}
            cancel={this.onCancel}
            submitButtonTitle={this.props.submitButtonTitle}
          />
        </div>
      </Popup>
    );
  }
}

export default compose(
  connect({
    popupParams: getPopupParamsSelector,
    isPopupVisible: isPopupVisibleSelector,
    hidePopup: hidePopupAction,
    [SHARE.SHARE_TYPE_GALLERY]: galleryMapIndexedById_sel,
    [SHARE.SHARE_TYPE_OWN_ARTWORK]: artworksById,
    [SHARE.SHARE_TYPE_CONSIDERATION]: considerationsById,
    [SHARE.SHARE_TYPE_ALERT]: currentPageArtworksSelector,
    artworks: getSharedArtworkList_sel,
  }),
  injectSheet(sheet),
)(SharePopup);
