export default {
  OptionSeparator: {
    height: 1,
    borderBottom: 'solid 1px rgba(73, 144, 226, 0.6)',
    marginLeft: 10,
  },
  option: {
    border: '0 none',
    background: 'none',
    height: 32,
    lineHeight: '32px',
    padding: [0, 10],
    width: '100%',
    textAlign: 'left',
    fontSize: 14,
    overflow: 'hidden',
    textOwerflow: '',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    '&.active': {
      backgroundColor: '#f5f5f5',
    },
  },
  dropMenu: {
    maxHeight: 352,
    position: 'absolute',
    background: '#fff',
    top: '100%',
    zIndex: 10,
    width: '100%',
    boxShadow: '0 0 3px 0 #4990e2',
    border: 'solid 1px rgba(73, 144, 226, 0.6)',
    overflowY: 'auto',
  },
};
