export const socialIcon = {
  height: 40,
  width: '100%',
  whiteSpace: 'nowrap',
  display: 'inline-block',
  font: '400 13px/16px Roboto, Helvetica, Arial, sans-serif',
  cursor: 'pointer',
  textDecoration: 'none',
  padding: [6, 9, 6, 33],
  color: '#FFF',
};
