import {
  precountParams,
  getItemOffsetLeft,
} from './lib';

export const itemIsVisible = ({ node, list, itemIndex, position }) => {
  if (!node || !list || !itemIndex) return true;

  const {
    children,
    scrollDistance,
  } = precountParams(node);

  const itemPosition = getItemOffsetLeft(children, itemIndex);

  if (itemPosition < position || itemPosition > (position + scrollDistance)) {
    return false;
  }

  return true;
};
