import { fork, take, select, put, call, takeEvery } from 'redux-saga/effects';
import I from 'immutable';
import { err404Action } from 'domain/ui';
import { matchParamsSel, filterParamsSelector, location_sel } from 'domain/router/RouterModel';
import { redirectToPage } from 'lib/redirectToPage';
import {
  getShare_apiGen,
  setGalleryLink,
  getSharedArtworkList_apiGen,
  sharedArtworkListAppendPage_apiGen,
  getSharedGalleries_apiGen,
  watchEditShare,
  removeShare_apiGen,
} from 'domain/shares/sagas';
import {
  getShareAction,
  shareViewedAction,
  sharedArtworkAppendPageAction,
  removeShareAction,
} from 'domain/shares/ShareActions';
import * as M from 'domain/shares/ShareModel';

function* show404ErrorPage() {
  yield put(err404Action(true));
}

function* check404() {
  try {
    yield take(getShareAction.success);
  } catch (e) {
    console.error(e);
    yield call(show404ErrorPage);
  }
}

function* fetchItems(page = 0) {
  const request =  yield select(location_sel);
  const params = yield select(filterParamsSelector);
  const { shareId, gId } = yield select(matchParamsSel);
  const { type } = request.query;
  if (gId || (type === 'galleries')) {
    yield fork(getSharedGalleries_apiGen.catchError, { shareId });
  }
  const moreParams = gId ? { galleries: gId } : {};
  const apiGen = page > 0 ?
    sharedArtworkListAppendPage_apiGen.catchError :
    getSharedArtworkList_apiGen.catchError;
  yield fork(
    apiGen,
    { ...moreParams, ...params, page, shareId },
  );
}

function* onPagination() {
  const { page, loading } = yield select(M.artworksPagination_sel);
  if (loading) return;
  yield call(fetchItems, page + 1);
}

function* onShareFetched(action) {
  yield put(shareViewedAction(action.payload));
}

function* onRemoveShare({ payload: { id } }) {
  yield call(removeShare_apiGen.catchError, { shareId: id }, { noReload: true });
  yield redirectToPage('shares');
}

export default function* watchNavigation(request, match) {
  yield takeEvery(sharedArtworkAppendPageAction.type, onPagination);
  const { shareId } = match.params;
  yield takeEvery(getShareAction.failure, show404ErrorPage);
  yield fork(check404);
  yield fork(setGalleryLink, I.Map({ shareId }));
  yield fork(getShare_apiGen.catchError, { id: shareId });
  yield fork(fetchItems);
  yield fork(watchEditShare);
  yield takeEvery(getShareAction.success, onShareFetched);
  yield takeEvery(removeShareAction.type, onRemoveShare);
}
