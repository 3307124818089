import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'cpcs-reconnect';
import { compose } from 'redux';
import { subscriptionTypeSelector } from 'domain/env/EnvModel';
import { SUBSCRIPTION_ORDER, SUBSCRIPTION_TYPE_COLLECTOR } from 'domain/const';
import injectSheet from 'lib/sheet';
import sheet from './sheet';

class Upgrade extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.shape({
      Upgrade: PropTypes.string.isRequired,
      button: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      star: PropTypes.string.isRequired,
    }).isRequired,
    subscriptionType: PropTypes.string,
  };

  render() {
    const { subscriptionType } = this.props;
    if (SUBSCRIPTION_ORDER[subscriptionType] > SUBSCRIPTION_ORDER[SUBSCRIPTION_TYPE_COLLECTOR]) {
      return null;
    }
    return (<div></div>);
  }
}

export const DumbUpgrade = Upgrade;

export default compose(
  injectSheet(sheet),
  connect({
    subscriptionType: subscriptionTypeSelector,
  }),
)(Upgrade);
