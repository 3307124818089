import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { FieldArray } from 'redux-form';
import injectSheet from 'lib/sheet';
import styles from './sheet.js';

import HistoryField from './HistoryField';
import { emptySalesHistory } from 'domain/ownArtwork/artworkSerializer';

class SalesHistory extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    label: PropTypes.string,
    showEstimatedPrice: PropTypes.bool,
    modifier: PropTypes.string,
  };

  renderFields = ({ fields }) => {
    const { classes, modifier, showEstimatedPrice } = this.props;
    return (
      <div id="salesHistory" className={cx(classes.fieldWrapper, modifier)}>
        {
          fields.length > 0 && fields.map((item, index, fields) => (
            <HistoryField
              fields={fields}
              key={`${index}/${fields.length}`}
              index={index}
              fixDropdownPadding={(fields.length - 1) === index}
              showEstimatedPrice={showEstimatedPrice}
              modifier={modifier}
            />
          ))
        }
        {
          fields.length === 0 &&
            <button
              type="button"
              onClick={() => fields.length < 20 && fields.push(emptySalesHistory())}
              className={cx(classes.button, classes.add, classes.expandButton)}
              children="add sale"
            />
        }
      </div>
    );
  }

  render() {
    const { label, classes, modifier } = this.props;
    return (
      <li className={cx(classes.formItem, modifier, 'salesHistory SalesHistory')}>
        <label
          htmlFor="salesHistory"
          className={cx(classes.label, modifier, 'salesHistory')}
          children={label || 'Sales history'}
        />
        <FieldArray
          name="salesHistory"
          component={this.renderFields}
        />
      </li>
    );
  }
}

export default injectSheet(styles)(SalesHistory);
