import React from 'react';

export function pureComponent(WrappedComponent) {
  class PureComponentWrapper extends React.PureComponent {
    render() {
      return <WrappedComponent {...this.props} />;
    }
  }
  return PureComponentWrapper;
}
