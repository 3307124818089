import { delay as sagaDelayOrigin } from 'redux-saga/effects';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

let w = global;

const getDocument = () => {
  if (process.env.NODE_ENV === 'test') {
    return {
      addEventListener: () => null,
      removeEventListener: () => null,
    };
  }
  return global.document;
};

export function passWindowDecorator(WrappedComponent) {
  class PassWindow extends React.Component {
    static propTypes = {
      window: PropTypes.object,
    };

    static childContextTypes = {
      window: PropTypes.object,
    };

    static contextTypes = {
      window: PropTypes.object,
    };

    getChildContext() {
      if (this.props.window) {
        return {
          window: this.props.window,
        };
      }
      if (this.context.window) {
        return {
          window: this.context.window,
        };
      }
      return {};
    };

    render() {
      const window = this.props.window || this.context.window || w;
      return (
        <WrappedComponent
          {...this.props}
          window={window}
        />
      );
    }
  }

  return PassWindow;
}

export function passDocumentDecorator(WrappedComponent) {
  class PassDocument extends React.Component {
    static propTypes = {
      document: PropTypes.object,
    };

    static childContextTypes = {
      document: PropTypes.object,
    };

    static contextTypes = {
      document: PropTypes.object,
    };

    getChildContext() {
      if (this.props.document) {
        return {
          document: this.props.document,
        };
      }
      if (this.context.document) {
        return {
          document: this.context.document,
        };
      }
      return {};
    };

    render() {
      const document = this.props.document || this.context.document || getDocument();
      return (
        <WrappedComponent
          {...this.props}
          document={document}
        />
      );
    }
  }

  return PassDocument;
}

export function passGetTimestamp(WrappedComponent) {
  class PassWindow extends React.Component {
    static propTypes = {
      currentTimestamp: PropTypes.number,
    };

    static childContextTypes = {
      currentTimestamp: PropTypes.number,
    };

    static contextTypes = {
      currentTimestamp: PropTypes.number,
    };

    getChildContext() {
      if (this.props.currentTimestamp) {
        return {
          currentTimestamp: this.props.currentTimestamp,
        };
      }
      if (this.context.currentTimestamp) {
        return {
          currentTimestamp: this.context.currentTimestamp,
        };
      }
      return {};
    };

    getTimestamp = () => {
      return this.props.currentTimestamp || this.context.currentTimestamp ||
        new Date().getTime();
    }
    render() {
      return (
        <WrappedComponent
          {...this.props}
          getTimestamp={this.getTimestamp}
        />
      );
    }
  }

  return PassWindow;
}

const sagaDelayMock = () => null;

export const sagaDelay = process.env.NODE_ENV === 'test' ? sagaDelayMock : sagaDelayOrigin;

/**
 * testable version of debounce
 * you can force run it with
 *   jest.useFakeTimers() before call
 *   jest.runAllTimers() after calling debounced method
**/
export const debounce = (f, milliseconds, { usePromise = false } = {}) => {
  let timeout = null;
  function debounced() {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
    const promise = new Promise((resolve) => {
      timeout = setTimeout(() => {
        timeout = null;
        resolve(f.apply(this, arguments));
      }, milliseconds);
    });
    if (usePromise) return promise;
  }
  Object.defineProperty(debounced, 'timeout', {
    get: () => timeout,
  });
  Object.defineProperty(debounced, 'cancel', {
    get: () => () => {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
    },
  });
  return debounced;
};

export const runOncePerPeriod = (f, milliseconds, asyncOnly = false) => {
  let timeout;
  let last = performance.now();
  // performance.now();
  function debounced() {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
    const current = performance.now();
    const past = current - last;
    let delay = milliseconds;
    if (past > milliseconds) {
      if (asyncOnly) {
        delay = 0;
      } else {
        last = current;
        f.apply(this, arguments);
        return;
      }
    }
    timeout = setTimeout(() => {
      timeout = null;
      last = performance.now();
      f.apply(this, arguments);
    }, delay);
  }
  Object.defineProperty(debounced, 'timeout', {
    get: () => timeout,
  });
  Object.defineProperty(debounced, 'cancel', {
    get: () => () => {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
    },
  });
  return debounced;
};

export const today = () => (process.env.NODE_ENV === 'test') ? new Date(2018, 6, 9) : new Date();

export const momentFormatNow = () => moment(today()).format();

export const momentNow = () => moment(today());

export const unixSeconds = () => moment().unix();

export const momentYear = (format = 'YYYY') => moment(today()).format(format);

export const responseTimestamp = resp => (process.env.NODE_ENV === 'test') ? moment(new Date(2018, 6, 9)).unix() : moment(resp.headers.date).unix();

export const Fragment = (process.env.NODE_ENV === 'test') ? 'div' : React.Fragment;

export const getExternalToken = () => {
  if (process.env.NODE_ENV === 'test') return null;
  return new URLSearchParams(window.location.search).get('auth_token');
};

export const delayRender = timeout => Component => function(props) {
  const [ready, setReady] = useState(false);
  useEffect(() => {
    setTimeout(setReady, timeout, true);
  }, []);
  if (!ready) return null;
  return <Component {...props} />;
};
