import I from 'immutable';
import { lnk } from 'lib/routes';

export default I.fromJS([
  {
    path: lnk('intelligencePage'),
    name: 'intelligencePage',
    title: 'Intelligence',
    customClass: 'insightPage',
  },
  {
    path: lnk('homePage'),
    name: 'homePage',
    title: 'Auction Alerts',
    children: ['newAlertFromHP', 'lotPage', 'artistPage'],
  },
  // {
  //   path: lnk('shares'),
  //   name: 'shares',
  //   title: 'Share',
  //   children: ['myShares', 'sharedWithMe', 'sharedItemsPage', 'sharedGallery', 'sharedArtwork'],
  // },
]);
