import { DARK_BLUE_COLOR, DEFAULT_FONTFAMILY } from 'theme/theme';

export default {
  TopNav: {
    // root
    '& $navLink+$navLink': {
      marginLeft: 25,
      '&:before': {
        content: '"/"',
        display: 'inline-block',
        marginRight: 25,
      },
    },
    '& $navLink+$navLinkActive:before': {
      opacity: 0.5,
    },
  },
  navLink: {
    opacity: 0.5,
    textAlign: 'left',
    fontFamily: DEFAULT_FONTFAMILY,
    fontSize: '24px',
    fontWeight: 'bold',
    lineHeight: '29px',
    textDecoration: 'none',
    color: DARK_BLUE_COLOR,
  },
  navLinkActive: {
    opacity: 1,
  },
};
