import React from 'react';
// import PropTypes from 'prop-types';

import { connectEventsContainer } from 'pages/highcharts/helpers/EventsContext';
import { RefSize } from 'pages/highcharts/helpers/refSize';
import { DEFAULT_FONTFAMILY, themes } from 'pages/highcharts/helpers/PDFConstants';
import { Text } from 'pages/highcharts/helpers/Text';


const ADDRESS_TOP = 52;
const ADDRESS_LINE_HEIGHT = 12;
const TEXT_LINE_HEIGHT = 12;

// eslint-disable-next-line no-unused-vars
const lines = [
  'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
  'Aenean id massa a tortor elementum lacinia id eget nisi.',
  'Duis varius sem vel sodales ornare. Proin volutpat vel',
  'nibh nec viverra. Vivamus id tristique libero. Sed laoreet',
  'placerat felis ut consequat. Donec egestas, quam quis',
  'luctus posuere, sapien magna interdum odio, vitae',
  'vehicula nunc eros vitae risus. Suspendisse pharetra',
  'lacus eu maximus porttitor. Pellentesque ut odio mattis,',
  'sollicitudin nibh at, porttitor nibh. Donec non pulvinar',
  'dolor. Suspendisse molestie sapien dolor, quis dictum',
  'ipsum luctus congue. Vestibulum ante ipsum primis in',
  'faucibus orci luctus et ultrices posuere cubilia curae;',
  'Nam non lectus mi.',
];

const lines2 = [
  'ARTBnk’s mission is to provide transparency to those',
  'who collect, transact, finance and insure art, by offering',
  'a number of investment and wealth management',
  'solutions centered around fine art. ARTBnk’s data-',
  'driven approach to art market analysis sets the',
  'company apart as the best equipped for identification of',
  'sound investment opportunities in the realm of art',
  'collecting. To learn more about ARTBnk’s exclusive offerings',
  'visit artbnk.com.',
];

class ContactsPDF extends RefSize {
  static propTypes = {
    // foo //
  }

  render() {
    let adressLine = 0;
    const { PDFState } = this.props;
    const { mainColor } = (themes[PDFState.reportType] || {});
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="612"
        height="750"
        ref={this.ref}
        data-top="104"
        data-margin-left="0"
      >
        <style>
          {`
          .ContactsPDF_title { font: 700 24px ${DEFAULT_FONTFAMILY}; fill: #1E202C; }
          .ContactsPDF_address { font: 400 12px ${DEFAULT_FONTFAMILY}; fill: #304C95; }
          .ContactsPDF_textTitle { font: 700 16px ${DEFAULT_FONTFAMILY}; fill: #1E202C; }
          .ContactsPDF_text { font: 400 12px ${DEFAULT_FONTFAMILY}; fill: #304C95; }
          `}
        </style>
        <g transform="translate(25, 0)">
          <text y="25" className="ContactsPDF_title">
            Contacts
          </text>
          <text y={ADDRESS_TOP + ADDRESS_LINE_HEIGHT * adressLine++} className="ContactsPDF_address">
            9 E 37th St.
          </text>
          <text y={ADDRESS_TOP + ADDRESS_LINE_HEIGHT * adressLine++} className="ContactsPDF_address">
            Suite 11
          </text>
          <text y={ADDRESS_TOP + ADDRESS_LINE_HEIGHT * adressLine++} className="ContactsPDF_address">
            New York City, NY 10016
          </text>
          {adressLine++}
          <text y={ADDRESS_TOP + ADDRESS_LINE_HEIGHT * adressLine++} className="ContactsPDF_address">
            info@artbnk.com
          </text>
          <text y={ADDRESS_TOP + ADDRESS_LINE_HEIGHT * adressLine++} className="ContactsPDF_address">
            +1 888 966-6096
          </text>
        </g>
        <g transform="translate(310, 160)">
          <rect
            x="-310"
            height="16"
            width="88"
            fill={mainColor}
          />
          {/** /}
          <text y="16px" className="ContactsPDF_textTitle">
            Disclaimer
          </text>
          <Text
            top="40"
            lines={lines}
            length="59"
            className="ContactsPDF_text"
            lineHeight={TEXT_LINE_HEIGHT}
          />
          {/**/}
        </g>
        <g transform={`translate(25, ${300 || 411})`}>
          <rect
            x="524"
            height="16"
            width="88"
            fill={mainColor}
          />
          <text y="16px" className="ContactsPDF_textTitle">
            About ARTBnk
          </text>
          <Text
            top="40"
            lines={lines2}
            length="68"
            className="ContactsPDF_text"
            lineHeight={TEXT_LINE_HEIGHT}
          />
        </g>
        {/*<path d="M120 35 v30" stroke={this.contentSectionGet('bg', ) || '#304C95'} strokeWidth="2" />*/}
      </svg>
    );
  }
}

ContactsPDF = connectEventsContainer(ContactsPDF);

export { ContactsPDF };
