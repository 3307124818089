import { call, takeLatest } from 'redux-saga/effects';
import * as A from './GlobalSearchAction';
import Api, { makeApiGenerator } from 'domain/api';

// { term, findOwned: false, ...params }
const searchArtistList_apiGen = makeApiGenerator({
  actionCreator: A.fetchArtistListAction,
  api: Api.searchArtists,
});

function* onSearchArtistList(action) {
  yield call(searchArtistList_apiGen.catchError, { term: action.payload.term });

}

export function* watchSearchArtistList() {
  yield takeLatest(A.fetchArtistListAction.type, onSearchArtistList);
}
