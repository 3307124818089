import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'cpcs-reconnect';
import { compose } from 'redux';
import I from 'immutable';
import cx from 'classnames';
import Popup from 'components/popup';
import { CONFIRM_POPUP } from 'domain/const';
import { confirmAcceptAction, confirmDenyAction, confirmCloseAction } from 'domain/ui/UIActions';
import { isPopupVisibleSelector, getPopupParamsSelector } from 'domain/ui/UIModel';
import Title from 'pages/common/popup/title';

import injectSheet from 'lib/sheet';
import sheet from './sheet.js';

const maybeBold = v => v.split(/<\/?strong>/g).map((s, k) => <React.Fragment key={k}>{(k % 2) ? <strong children={s} /> : s}</React.Fragment>);

const markUp = text => {
  if (typeof text === 'string' && text.length) {
    return text.split('\n').map((v, index) => <React.Fragment key={index}>{maybeBold(v)}<br /></React.Fragment>);
  }
  return text;
};

class ConfirmationPopup extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    cancelText: PropTypes.string,
    acceptText: PropTypes.string,
    onDeny: PropTypes.func.isRequired,
    onAccept: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    popupParams: PropTypes.instanceOf(I.Map),
    isPopupVisible: PropTypes.func.isRequired,
  };

  static defaultProps = {
    cancelText: 'Cancel',
    acceptText: 'Confirm',
  };

  onDeny = () => this.props.onDeny();
  onAccept = () => this.props.onAccept();
  onClose = () => this.props.onClose();

  render() {
    const { classes, isPopupVisible, popupParams } = this.props;
    if (!isPopupVisible(CONFIRM_POPUP)) return null;
    return (
      <Popup cancel={this.onClose}>
        <div
          className={`${classes.container} ${classes.ConfirmationPopup} Popup ${popupParams.get('modifier') || ''}`}
        >
          <div className={cx(classes.noTitleTextLayout, { noText: !popupParams.get('title') })}>
            <Title
              ico={popupParams.get('thumb')}
              children={markUp(popupParams.get('title'))}
              onClose={this.onClose}
            />
            {
              popupParams.get('description') &&
                <div
                  className={cx(classes.description, 'description-layout')}
                  children={markUp(popupParams.get('description'))}
                />
            }
          </div>
          <div className={classes.btnGroup}>
            <button
              className={cx(classes.button, 'ConfirmationPopup cancel')}
              type="button"
              onClick={this.onDeny}
              children={popupParams.get('cancelText') || this.props.cancelText}
              data-name="cancel-button"
            />
            <button
              className={cx(classes.button, 'ConfirmationPopup accept send')}
              type="button"
              onClick={this.onAccept}
              children={popupParams.get('acceptText') || this.props.acceptText}
              data-name="accept-button"
            />
          </div>
        </div>
      </Popup>
    );
  }
}

export { ConfirmationPopup as ConfirmationPopupTest };

export default compose(
  injectSheet(sheet),
  connect({
    onAccept: confirmAcceptAction,
    onDeny: confirmDenyAction,
    onClose: confirmCloseAction,
    popupParams: getPopupParamsSelector,
    isPopupVisible: isPopupVisibleSelector,
  }),
)(ConfirmationPopup);
