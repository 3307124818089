import record, { integer, string, listOf, bool, enhancedType, typeWithDefault, decimal } from 'cpcs-recordjs';
// import { UNITS } from 'lib/units';

const isNothing = x => typeof x === 'undefined' || x === null;

const ParsedPagination = record('ParsedPagination', {
  page: 0,
  pages: 0,
  perPage: 0,
  total: 0,
  loading: false,
});

/**
 * page: 0    // currentPageNumber
 * pages: 2   // totalPagesAmount
 * size: 10   // itemsPerPage
 * perPage: size // renamed size param for Immutable compatibility
 * total: 11  // totalItems
**/
export const Pagination = enhancedType({
  typeName: 'Pagination',
  defaultValue: ParsedPagination.parse({ page: 0, pages: 1 }),
  parse: (value) => {
    /**
     * at once 1 page should exists
     * even if it's empty
    **/
    value = { ...value, pages: value.pages || 1 };
    let pagination = ParsedPagination.parse(value);
    if ('size' in value) {
      pagination = pagination.update(ParsedPagination.$perPage.set(value.size));
    }
    return pagination;
  },
});

const NoUnitPrice = record('NoUnitPrice', {
  value: decimal,
});

export const Country = record('Country', {
  id: integer,
  title: string,
  code: string,
  historical: bool(true),
});

export const Lot = record('Lot', {
  seller: string,
  saleAt: string,
  city: string,
  country: Country(),
  // from Auction model. Basicaly use these fields
  // auction is old version of seller
  // auction: string,
  // date is old version of saleAt
  // date: string,
  // it looks like auctionUrl is not used in project
  // auctionUrl: string,
  auctionStatus: string,
  priceSold: NoUnitPrice(),
  estimatedPriceStart: NoUnitPrice(),
  estimatedPriceEnd: NoUnitPrice(),
  currency: string('USD'),
  url: string,
  // recent alerts has alert id in alert field
  alert: integer,
  lotNumber: string,
  saleNumber: string,
  auctionId: integer,
  privateLabel: bool(false),
});

export const Category = record('Category', {
  id: integer(0),
  title: string(''),
  commonSubstrates: listOf(integer),
  commonSurfaces: listOf(integer),
  commonMediums: listOf(integer),
});

export const SalesHistory = record('SalesHistory', {
  id: integer,
  editable: bool(true),
  isPrivate: bool(false),
  priceSold: NoUnitPrice(),
  estimatedPriceStart: NoUnitPrice(),
  estimatedPriceEnd: NoUnitPrice(),
  currency: string('USD'),
  auction: integer,
  soldDate: string,
  seller: string,
  url: string,
  acquisition: bool(false),
  awaitingPrice: bool(false),
  lotNumber: string,
  saleNumber: string,
  auctionId: integer,
  // [PRIVATE, AUCTION, APPRAISAL]
  priceType: string,
  privateLabel: bool(false),
  // public: bool,
});

export const UpcomingSaleBase = record('UpcomingSale', {
  id: integer,
  editable: bool(true),
  isPrivate: bool(false),
  estimatedPriceStart: NoUnitPrice(),
  estimatedPriceEnd: NoUnitPrice(),
  currency: string('USD'),
  auction: integer,
  soldDate: string,
  seller: string,
  url: string,
  privateLabel: bool(false),
});

export const UpcomingSale = enhancedType({
  typeName: 'UpcomingSale',
  parse(value) {
    return value ? UpcomingSaleBase(value) : null;
  },
});

export const uppercaseString = enhancedType({
  typeName: 'uppercaseString',
  parse(value = '') {
    return value.toUpperCase();
  },
});

export const DateStructure = record('Date', {
  day: integer,
  month: integer,
  year: integer,
});

export const Artist = record('Artist', {
  id: integer(),
  fullName: uppercaseString(),
  namePrefix: string(),
  born: integer(),
  bornIn: DateStructure(),
  diedIn: DateStructure(),
  residence: listOf(string().type),
  editable: bool(false),
  showRtv: bool(false),
  // analytics was bought and isn't expired
  analyticAvailable: bool(false),
  banner343x120: string(),
  banner720x120: string(),
  banner970x120: string(),
  bannerUrl: string(),
  alertExist: bool(false),
});

export const Auction = record('Auction', {
  id: integer(0),
  title: string(),
});

export const ToggleString = typeWithDefault('', {
  typeName: '[ToggleString]',
  parse(value) {
    if (isNothing(value)) {
      return undefined;
    }
    if (typeof value === 'string') {
      return value;
    }
    if (typeof value === 'number') {
      return `${value}`;
    }
    throw new Error(`parse failed, ToggleString expected but found ${typeof value}: ${value}`);
  },
  operations: {
    toggle: attr => value => attr.update(currentValue => currentValue === value ? '' : value),
  },
});


const CardExpDate = record('CardExpDate', {
  month: integer,
  year: integer,
});

export const Card = record('Card', {
  cardNumber: string(''),
  cardExpDate: CardExpDate(),
});

export const Substrate = record('Substrate', {
  id: integer,
  title: string,
});

export const Surface = record('Substrate', {
  id: integer,
  title: string,
});

export const Medium = record('Medium', {
  id: integer(),
  title: string(),
});

/**
 * models
**/
const Note = record('Note', {
  text: string(''),
  createdAt: string,
});

const Picture = record('Picture', {
  name: string(''),
});

export const Provenance = record('Provenance', {
  city: string,
  country: integer,
  editable: bool(true),
  from: DateStructure,
  to: DateStructure,
  owner: string,
});

export const Doc = record('Doc', {
  id: integer(),
  path: string(),
  title: string(),
  type: string(),
});

const ownArtworkFields = {
  notes: listOf(Note),
  ownedAt: string,
  price: decimal,
  pricePaid: decimal,
  addedAt: string,
};

const artworkFields = {
  id: integer,
  artist: Artist,
  isConsideration: bool,
  isArtWork: bool,
  editable: bool,
  catalogRaisonne: string,
  category: integer,
  conditions: listOf(integer),
  createdAt: DateStructure(),
  exhibition: string,
  literature: string,
  substrates: listOf(integer),
  mediums: listOf(integer),
  surfaces: listOf(integer),
  pictures: listOf(Picture),
  privatePictures: listOf(string),
  provenance: listOf(Provenance),
  salesHistory: listOf(SalesHistory),
  signature: integer,
  title: string,
  height: decimal,
  depth: decimal,
  width: decimal,
  isCircular: bool(false),
  // AOs from AE without units hould not display units
  // unit: string(UNITS.INCH.id),
  unit: string(),
  isDueDiligenceRisk: bool(false),
  isDraft: bool(false),
  lot: Lot(),
  rtvCurrency: string('USD'),
  rtvPrice: decimal(0),
  rtvStatus: string(''),
  purchasedDate: string(''),
  stamps: string(''),
  plate: string(''),
  printer: string(''),
  publisher: string(''),
  foundry: string(''),
  inscription: string(''),
  conceptionYear: integer,
  edition: string(''),
  numberedNo: integer,
  numberedOf: integer,
  editionSize: integer,
  artistProof: integer,
  pressProof: integer,
  sheetHeight: integer,
  sheetWidth: integer,
  // AOs from AE without units hould not display units
  // sheetUnit: string(UNITS.INCH.id),
  sheetUnit: string(),
  authenticityLetter: string(''),
  studio: string(''),
  location: string(''),
  docs: listOf(Doc),
  loading: bool(false),
  // display status
  notAccurate: bool(false),
  userPrice: SalesHistory,
};

const considerationFields = {
  createdDate: string,
  upcomingSale: UpcomingSale(),
  saleAt: string,
  seller: string,
};

export const Artwork = record('Artwork', { ...artworkFields, ...ownArtworkFields, ...considerationFields });

export const RtvValue = record('RtvValue', {
  artwork: Artwork,
  current: true,
  purchasedDate: string,
  rtvCurrency: string,
  rtvPrice: decimal,
  userId: decimal,
});

export const AnalyticsValue = record('AnalyticsValue', {
  artist: Artist,
  expirationDate: string,
  expired: bool(false),
  id: decimal,
  purchasedDate: string,
  userId: decimal,
});

/*
shortName: "SUBSCRIPTION_TYPE_COLLECTOR",
freeRtvCountIncluded: 10,
subscriptionValue: 300,
rtvDefaultValue: 40,
currency: "usd",
interval: "year",
intervalCount: 1,
description: "a 20% savings",
nickName: "Collector_recurring"
*/
export const Plan = record('Plan', {
  // 2020-10-16 (looks like not used)
  amount: decimal,
  currency: string(''),
  shortName: string(''),
  // price for RTV purchase
  rtvDefaultValue: decimal,
  // price for year subscription
  subscriptionValue: decimal,
  // price for upgrading to higher plan
  subscriptionProratedValue: decimal,
  // amount of ree RTV's
  freeRtvCountIncluded: decimal,
  // will not be used, we decided to hardcode this text in SubscriptionType component
  description: string(''),
});

// export const EstimatedPrice = record('EstimatedPrice', {
//   value: decimal,
//   unit: string('USD'),
// });
