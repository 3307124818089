import { call, put, select, takeEvery, fork } from 'redux-saga/effects';
import { stopSubmit, getFormValues } from 'redux-form';
import {
  buyRtvFormSubmitAction,
  orderRtvAction,
  setOrderErrorAction,
  hideRtvFormAction,
  updateCardAction,
  getRtvAction,
  showRtvFormAction,
  noFreeRtvLeftAction,
  orderFreeRtvAction,
  setSelectedProductNameAction,
} from 'pages/common/buyRtv/buyRtvActions';
import {
  buyRtvForCouponAction,
} from 'pages/common/couponForm/couponFormActions';
import { setStorageItem } from 'lib/storage';
import { addNotification } from 'domain/env/EnvActions';
import { ensureCurrentPlan, getCurrentPlan_apiGen, checkAuth_apiGen } from 'domain/env/sagas';
import { hidePopupAction } from 'domain/ui/UIActions';
import { parseServerError } from 'lib/helpers';
import { FORM_BUY_RTV, FORM_CARD, SINGLE_RTV } from 'domain/const';
import Api, { makeApiGenerator } from 'domain/api';
import {
  buyRtvPopupArtworkSelector,
} from 'pages/common/buyRtv/buyRtvModel';
import { watchCouponForm } from 'pages/common/couponForm/saga';
import {
  priceSelector,
  validatedCouponSelector,
} from 'pages/common/couponForm/couponFormModel';
import { fetchPlanTypesAction } from 'pages/upgradeSubscriptionPage/upgradeSubscriptionActions';
import { setStripeCardTokenInvalidAction } from 'pages/billingPage/form/cardFormActions';
import { upgradePlan, backgroundFetchPlanTypes } from 'pages/upgradeSubscriptionPage/sagas';

// { data: { artworkId, discount, cardHolderName, ... } }
const orderRtv_apiGen = makeApiGenerator({
  actionCreator: orderRtvAction,
  api: Api.orderRtv,
  getSuccessData: ({ data, headers, requestData }) => ({
    payload: { ...data, artworkId: requestData.data.artworkId },
    requestData: requestData.data,
    headers,
  }),
  returnValue: function returnValue({ data, headers }) {
    return { data, headers };
  },
});

function* getStoreDiscount() {
  return (yield select(priceSelector) && (yield select(validatedCouponSelector))) || undefined;
}

function* onBuyRtv(action) {
  try {
    const artwork = yield select(buyRtvPopupArtworkSelector);
    if (!artwork || !artwork.id) {
      yield put({ type: orderRtvAction.failure, payload: { message: 'No artwork' } });
      return;
    }
    let { discount } = action.payload;
    discount = discount || (yield call(getStoreDiscount));
    const { cardHolderName } = ( (yield select(getFormValues(FORM_CARD))) || {} );
    const artworkId = artwork.id;
    const requestData = { data: { ...action.payload, artworkId, discount, cardHolderName } };
    const { data, headers } = yield call(orderRtv_apiGen, requestData);
    const { id } = data;
    if (headers['x-token']) {
      setStorageItem('token', headers['x-token']);
    }
    if (id && artworkId) {
      yield put(hideRtvFormAction);
    }
    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (err) {
    console.error(err);
    if (action.payload.stripeToken) {
      yield put(setStripeCardTokenInvalidAction());
    }
    const { message } = parseServerError(err);
    if (action.onFailure) {
      yield call(action.onFailure, { message });
      return;
    }
    if (!message) {
      return;
    }
    if (message.match(/The email.*is already in use/)) {
      yield put(stopSubmit(FORM_BUY_RTV, { email: 'This email is already in use' }));
    } else if (message.match(/email/)) {
      yield put(stopSubmit(FORM_BUY_RTV, { email: 'Fill in valid email' }));
    } else {
      yield put(setOrderErrorAction({ message: 'The purchase did not take place. Please try again later.' }));
    }
  }
};

function* onBuyRtvSuccess(action) {
  if (action.payload.card) {
    yield put(updateCardAction(action.payload.card));
  }
  yield call(checkAuth_apiGen.catchError);
}

function* onBuyRtvForCoupon(action) {
  yield call(onBuyRtv, { ...action, payload: { discount: action.payload.coupon } });
}

function* onOrderFreeRtvSuccess() {
  yield put({ type: orderFreeRtvAction.success });
  yield put(hidePopupAction());
}

function* onOrderFreeRtvFailure({ message }) {
  yield put({ type: orderFreeRtvAction.failure });
  yield put(hidePopupAction());
  if (message === 'NO_FREE_RTV_ENTERPRISE') {
    yield put(addNotification({ title: 'You have no ARTBnk Values remaining - please contact your plan administrator', type: 'error' }));
  } else if (message === 'NO FREE RTV') {
    yield put(addNotification({ title: 'You have no more free ABV\'s.', type: 'error' }));
    yield put(noFreeRtvLeftAction());
    yield fork(getCurrentPlan_apiGen.catchError);
  } else if (message === 'RTV did not change price since last buy') {
    yield put(addNotification({ title: message, type: 'error' }));
  } else {
    yield put(addNotification({ title: 'Something went wrong.', type: 'error' }));
  }
}

function* onOrderFreeRtv(action) {
  yield call(onBuyRtv, { ...action, payload: { }, onSuccess: onOrderFreeRtvSuccess, onFailure: onOrderFreeRtvFailure });
}

function* onGetRtv({ payload: { artwork } }) {
  yield call(checkAuth_apiGen.catchError);
  yield put(showRtvFormAction({ artwork }));
}

function* onBuyRtvFormSubmit({ payload: { product, ...payload }, ...action }) {
  if (!product || product.get('shortName') === SINGLE_RTV) {
    yield fork(onBuyRtv, { ...action, payload });
  } else {
    const discount = yield call(getStoreDiscount);
    yield fork(upgradePlan, {
      stripeCardToken: payload.stripeToken,
      billingData: {
        country: payload.country,
        code: payload.code,
        email: payload.email,
      },
      planType: product.get('shortName'),
      discount,
    });
  }
}

function checkSubscriptionsWatcher({ payload: productName }) {
  if (!productName || productName === SINGLE_RTV) {
    backgroundFetchPlanTypes.disable();
  } else {
    backgroundFetchPlanTypes.enable();
  }
}

export function* rtvFormInit() {
  yield put(fetchPlanTypesAction());
  yield takeEvery(setSelectedProductNameAction.type, checkSubscriptionsWatcher);
  yield fork(watchCouponForm);
  yield takeEvery(getRtvAction.type, onGetRtv);
  yield takeEvery(buyRtvFormSubmitAction.type, onBuyRtvFormSubmit);
  yield takeEvery(orderRtvAction.success, onBuyRtvSuccess);
  yield takeEvery(buyRtvForCouponAction.type, onBuyRtvForCoupon);
  yield takeEvery(orderFreeRtvAction.type, onOrderFreeRtv);
  yield fork(ensureCurrentPlan);
}
