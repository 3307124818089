import { SANSPRO_FONTFAMILY } from 'theme/theme';

export default {
  IntelligencePage: {
    display: 'flex',
    justifyContent: 'center',
  },
  content: {
    width: 1280,
    paddingTop: 158,
    paddingBottom: 70,
    '@media screen and (max-width: 1460px)': {
      width: '100%',
      padding: [158, 30, 70],
    },
    '@media screen and (max-width: 550px)': {
      paddingTop: 98,
    },
  },
  title: {
    font: `700 35px/40px ${SANSPRO_FONTFAMILY}`,
    letterSpacing: '-0.8px',
    lineHeight: '72px',
    '@media screen and (max-width: 550px)': {
      fontSize: 30,
      lineHeight: '65px',
    },
  },
  description: {
    font: `400 22px/30px ${SANSPRO_FONTFAMILY}`,
    marginTop: 26,
    maxWidth: 750,
    '@media screen and (max-width: 550px)': {
      fontSize: 22,
    },
    '& p': {
      fontSize: 20,
    },
    '&.advantages': {
      width: 'auto',
      marginTop: 22,
    },
  },
  descriptionText: {
    fontSize: 18,
  },
  investment: {
    fontWeight: 700,
  },
  buttonBox: {
    marginTop: 38,
    display: 'block',
  },
  demoLink: {
    display: 'inline-block',
    font: '900 10px/15px Roboto',
    width: 134,
    height: 33,
    textAlign: 'center',
    padding: [7, 19],
    border: '2px solid rgba(0, 0, 0, 0.8)',
    borderRadius: '6px',
    color: 'rgba(0, 0, 0, 0.8)',
    textDecoration: 'none',
    textTransform: 'uppercase',
    '&.advantages': {
      marginTop: 40,
    },
    '&:hover': {
      background: '#000',
      color: '#fff',
    },
  },
  ipadImage: {
    maxWidth: '80%',
  },
  row: {
    display: 'flex',
    '&.advantages': {
      marginTop: 170,
      alignItems: 'center',
    },
    '@media screen and (max-width: 1100px)': {
      '&.header': {
        flexDirection: 'column',
      },
      '&.advantages': {
        marginTop: 40,
        paddingLeft: 0,
        flexDirection: 'column-reverse',
      },
    },
  },
  columnHeader: {
    flex: '60 75 0px',
    paddingLeft: 9,
  },
  columnThumb: {
    flex: '69 67 0px',
    display: 'flex',
    justifyContent: 'right',
    alignItems: 'center',
    '@media screen and (max-width: 1100px)': {
      marginTop: 50,
      justifyContent: 'center',
    },
  },
  columnGraph: {
    width: 703,
    '@media screen and (max-width: 1100px)': {
      width: '100%',
      margin: [40, 0, 0],
      textAlign: 'center',
    },
  },
  columnAdvantages: {
    display: 'block',
    paddingTop: 20,
    marginLeft: 20,
  },
  graphImage: {
    maxWidth: '100%',
  },
  advantagesTitle: {
    font: `700 32px/36px ${SANSPRO_FONTFAMILY}`,
    letterSpacing: '-0.8px',
    '@media screen and (max-width: 550px)': {
      fontSize: 24,
    },
  },
  list: {
    marginTop: 20,
    marginLeft: 10,
    listStyle: 'disc',
    '& li': {
      fontSize: 20,
      listStylePosition: 'outside',
      '@media screen and (max-width: 550px)': {
        fontSize: 22,
      },
    },
  },
  artworkListHeader: {
    font: `700 36px/36px ${SANSPRO_FONTFAMILY}`,
    letterSpacing: '-0.8px',
    marginTop: 205,
    '@media screen and (max-width: 1100px)': {
      marginTop: 40,
      '@media screen and (max-width: 550px)': {
        fontSize: 24,
      },
    },
  },
  artworkListDownload: {
    font: `400 18px ${SANSPRO_FONTFAMILY}`,
    marginTop: 18,
    letterSpacing: '-0.1px',
  },
  aoList: {
    marginTop: 115,
    display: 'grid',
    rowGap: '60px',
    columnGap: 'min(45px)',
    gridTemplateColumns: 'repeat(auto-fit, 287px)',
    justifyContent: 'space-between',
    '@media screen and (max-width: 970px)': {
      columnGap: 'min(15px)',
      gridTemplateColumns: 'auto',
      textAlign: 'center',
    },
  },
  aoItem: {
    font: `400 24px/30px ${SANSPRO_FONTFAMILY}`,
    width: 305,
    '@media screen and (max-width: 970px)': {
      width: '100%',
    },
    '& p': {
      marginTop: 10,
    },
  },
  download: {
    textDecoration: 'none',
    color: '#000',
  },
  aoImage: {
    width: 250,
    '@media screen and (max-width: 1100px)': {
      width: 319,
    },
  },
  aoTitle: {
    marginTop: 25,
    font: `700 20px/36px ${SANSPRO_FONTFAMILY}`,
    '@media screen and (max-width: 550px)': {
      fontSize: 22,
    },
  },
};
