import { put, call, select, all, fork, race, take } from 'redux-saga/effects';
import { fetchCountriesAction, countryList } from 'domain/country';
import { fetchCategoryAction, categoryList } from 'domain/category';
import { fetchSurfacesAction, surfaceList } from 'domain/surface';
import { fetchSubstratesAction, substrateList } from 'domain/substrate';
import { fetchConditionAction, conditionList } from 'domain/conditions';
import { fetchAuctionsAction, auctionList } from 'domain/auction';
import { fetchSignaturesAction, signatureList } from 'domain/signatures';
import { fetchOccupationAction, occupationList_sel } from 'domain/occupations';
import { fetchMediumsAction } from 'domain/medium/MediumActions';
import { mediumList_sel } from 'domain/medium/MediumModel';
import Api, { callApi } from 'domain/api';
import { checkAuthAction, loginAction } from 'domain/env/EnvActions';
import { isAuthorized } from 'domain/env/EnvModel';
import { mapCategoryTitle } from 'lib/helpers';

function* ensureDictionary(selector, action, apiFunction, mapFn, retry = 3) {
  if ((yield select(selector)).size) return;
  try {
    const { data } = yield callApi(apiFunction);
    yield put({ type: action.success, payload: mapFn ? mapFn(data) : data });
  } catch (err) {
    yield put({ type: action.failure, payload: err });
    if (retry > 0) {
      yield call(ensureDictionary, selector, action, apiFunction, mapFn, retry - 1);
    }
  }
}

function* ensureCountries() {
  yield call(ensureDictionary, countryList, fetchCountriesAction, Api.getCountries);
}

function* ensureSignatures() {
  yield call(ensureDictionary, signatureList, fetchSignaturesAction, Api.getSignatures);
}

const mapCategories = data => {
  try {
    data = data.map(
      ({ category: { id, title, ...category }, ...v }) =>
        ({ category: { id, title: mapCategoryTitle(title), ...category }, ...v }),
    );
  } catch (err) {
    console.error(err);
  }
  return data;
};

function* ensureCategories() {
  yield call(ensureDictionary, categoryList, fetchCategoryAction, Api.getCategories, mapCategories);
}

function* ensureSurfaces() {
  yield call(ensureDictionary, surfaceList, fetchSurfacesAction, Api.getSurfaces);
}

function* ensureSubstrates() {
  yield call(ensureDictionary, substrateList, fetchSubstratesAction, Api.getSubstrates);
}

function* ensureConditions() {
  yield call(ensureDictionary, conditionList, fetchConditionAction, Api.getConditions);
}

function* ensureOccupations() {
  yield call(ensureDictionary, occupationList_sel, fetchOccupationAction, Api.getOccupations);
}

function* ensureAuctions() {
  yield call(ensureDictionary, auctionList, fetchAuctionsAction, Api.getAuctions);
}

function* ensureMediums() {
  yield call(ensureDictionary, mediumList_sel, fetchMediumsAction, Api.getMediums);
}

function* loadAllDictionaries() {
  yield all({
    occupations: fork(ensureOccupations),
    countries: fork(ensureCountries),
    categories: fork(ensureCategories),
    substrates: fork(ensureSubstrates),
    surfaces: fork(ensureSurfaces),
    conditions: fork(ensureConditions),
    signatures: fork(ensureSignatures),
    auctions: fork(ensureAuctions),
    mediums: fork(ensureMediums),
  });
}

const matchAuth = {
  login: take(loginAction.success),
  checked: take(checkAuthAction.success),
};

export function* ensureDictionaries() {
  const hasAuth = yield select(isAuthorized);
  if (hasAuth || process.env.NODE_ENV === 'test') {
    yield fork(loadAllDictionaries);
  } else {
    yield fork(ensureCountries);
    yield fork(ensureOccupations);
    yield race(matchAuth);
    yield call(loadAllDictionaries);
  }
}
