import { DARK_BLUE_COLOR, CHECKBOX_CHECKED, ERROR_COLOR, CHECKBOX_CHECKED_BLUE } from 'theme/theme';
import { ADVANCED_FILTER_CHECKBOX_FOCUSED_STYLE, CHECKBOX_FOCUSED_STYLE } from 'theme/focus';
import checked from 'components/form/checkbox/checked.svg';

export default {
  CheckBox: {
    display: 'block',
    overflow: 'hidden',
    '&.AuthPage-rememberMe': {
      height: 17,
      overflow: 'unset',
    },
    '&.rtv-form--save-card': {
      marginTop: 20,
    },
    '&.AOFormStamps': {
      marginTop: 7,
    },
    '&.disabled': {
      opacity: 0.3,
    },
    '&:focus': {
      ...CHECKBOX_FOCUSED_STYLE,
    },
    '&.advancedFilter': {
      '&:focus': {
        ...ADVANCED_FILTER_CHECKBOX_FOCUSED_STYLE,
      },
    },
  },
  input: {
    // visibility: 'hidden',
    width: 0,
    height: 0,
    position: 'absolute',
    zIndex: -1,
    opacity: 0,
    // '&:checked + $label:before': {
    //   background: {
    //     color: CHECKBOX_CHECKED,
    //     image: `url("${checked}")`,
    //     repeat: 'no-repeat',
    //     size: [14, 10],
    //     position: 'center',
    //   },
    // },
  },
  label: {
    display: 'inline-flex',
    fontSize: 14,
    fontWeight: 400,
    userSelect: 'none',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    color: DARK_BLUE_COLOR,
    // alignItems: 'center',
    '&:before': {
      content: '""',
      flex: '0 0 15px',
      height: 15,
      width: 15,
      display: 'inline-block',
      background: '#fff',
      border: `1px solid  ${CHECKBOX_CHECKED}`,
      marginRight: 10,
      boxSizing: 'border-box',
    },
    '&.analytics-form--save-card': {
      '&.checked:before': {
        background: {
          color: '#304C95',
          image: `url("${checked}")`,
          repeat: 'no-repeat',
          size: [11, 'auto'],
          position: 'center',
        },
      },
    },
    '&.error:before': {
      border: `1px solid ${ERROR_COLOR}`,
    },
    '&.checked:before': {
       background: {
         color: CHECKBOX_CHECKED_BLUE,
         image: `url("${checked}")`,
         repeat: 'no-repeat',
         size: [11, 'auto'],
         position: 'center',
       },
       border: 0,
       borderRadius: 2,
    },
    '&.SignUpPageField': {
      alignItems: 'flex-start',
      color: DARK_BLUE_COLOR,
      width: '100%',
      whiteSpace: 'normal',
    },
    '&.privacyPopupCheckbox': {
      whiteSpace: 'normal',
    },
    '&.AskEmailPopup': {
      whiteSpace: 'normal',
    },
    '& .AF-option-amount': {
      display: 'none',
      color: '#BABCBF',
    },
  },
};
