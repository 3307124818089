import {
  formItem,
  innerFormItem,
  label,
  fieldWrapper,
} from 'theme/form';

export default {
  FormRowField: {
    // outline: '2px dotted #00ec00',
  },
  innerFormItem,
  formItem,
  label,
  fieldWrapper,
  badFieldClassName: {
    outline: '2px solid #ff5400',
    background: '#ff5400',
  },
  fieldClassName: {
    border: '4px solid red',
    background: 'red',
  },
  rootClassName: {
    border: '2px solid black',
    background: 'black',
  },
  externalClasses: {
    border: '2px dotted orange',
    background: 'blue',
  },
};
