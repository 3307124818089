import {
  DEFAULT_FONTFAMILY,
} from 'theme/theme';

export const selectWrapper = {
  position: 'relative',
  display: 'inline-block',
  width: '100%',
  '&.disabled:after': {
    opacity: 0.5,
  },
  '&.currencyField': {
    width: 90,
    height: 32,
    lineHeight: '32px',
    display: 'inline-block',
    position: 'relative',
  },
  '&.unitField': {
    width: 70,
    height: 32,
    lineHeight: '32px',
    fontFamily: DEFAULT_FONTFAMILY,
  },
};
