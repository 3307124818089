export const PAGE_INNER_COL2 = {
  flex: '1 1 50%',
  '&.collectionItemPage,&.artworkPage,&.sharedArtWorkPage': {
    flex: '1 1 0',
    '@media screen and (max-width: 1200px)': {
      flex: '0 0 372px',
      width: 'unset',
    },
    '@media screen and (max-width: 900px)': {
      flex: 'unset',
      width: '100%',
      marginTop: 50,
    },
    '@media screen and (max-width: 610px)': {
      width: 363,
      margin: '50px auto 0',
    },
    '@media screen and (max-width: 393px)': {
      width: '100%',
    },
  },
};
