import remove from './remove.svg';
import chevrone from './chevron.svg';
import { DARK_BLUE_COLOR } from 'theme/theme';
import { FOCUSED_FIELD_STYLE } from 'theme/focus';
import { field } from 'theme/form';

const fieldHeight = 32;

export default {
  Tags: {
    position: 'relative',
    '&.disabled': {
      outline: 'none',
      opacity: 0.5,
    },
  },
  list: {
    display: 'inline-block',
    padding: [0, 4],
    width: '100%',
  },
  item: {
    display: 'inline-block',
    lineHeight: '16px',
    borderRadius: 4,
    whiteSpace: 'normal',
    maxWidth: '100%',
  },
  itemContainer: {
    display: 'inline-block',
    whiteSpace: 'nowrap',
    maxWidth: '100%',
    position: 'relative',
    paddingRight: 20,
  },
  remove: {
    display: 'inline-block',
    background: `url("${remove}") scroll no-repeat center`,
    backgroundSize: [8, 8],
    width: 19,
    height: 12,
    verticalAlign: 'middle',
    cursor: 'pointer',
    position: 'absolute',
    right: 3,
    top: 4,
    '&:disabled': {
      cursor: 'default',
    },
  },
  element: {
    maxWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    position: 'relative',
    display: 'inline-block',
    whiteSpace: 'nowrap',
    fontSize: 14,
    verticalAlign: 'middle',
    color: DARK_BLUE_COLOR,
  },
  tagsWrapper: {
    position: 'relative',
    backgroundColor: '#ffffff',
    border: 'solid 1px #e6e6e6',
    display: 'flex',
    flexWrap: 'wrap',
    '&:after': {
      content: '""',
      display: 'inline-block',
      position: 'absolute',
      background: `url("${chevrone}") scroll no-repeat center`,
      backgroundSize: 'contain',
      width: 18,
      height: 18,
      right: 2,
      bottom: (fieldHeight/2 - 18/2),
      margin: 'auto',
      pointerEvents: 'none',
    },
    '&.focused': {
      // outlineOffset: -2,
      // outline: `5px auto ${FOCUS_OUTLINE}`,
      ...FOCUSED_FIELD_STYLE,
    },
    '&.hideDropdownArrow:after': {
      display: 'none',
    },
    '&.error': {
      borderColor: '#d0021b',
    },
  },
  field,
};
