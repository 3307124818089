import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import cx from 'classnames';

import { DEFAULT_FONTFAMILY_3 } from 'theme/theme';
import injectSheet from 'lib/sheet';
import closeSVG from 'components/reports/images/financialPerformancebg.svg';
import { button } from 'theme/button';
import { connect } from 'cpcs-reconnect';
import { showAnalyticsFormAction } from 'pages/common/buyAnalytics/buyAnalyticsActions';
import { artistSelector } from 'domain/artwork/ArtworkModel';

const sheet = {
  button,
  FinancialPerformance: {
  },
  content: {
    padding: [94, 0, 400, 0],
    display: 'grid',
    gridTemplateColumns: '540px 825px',
    gridTemplateAreas: '"description preview"',
    // columnGap: '70px',
    alignItems: 'start',
    justifyContent: 'space-between',
    // '@media screen and (max-width: 1760px)': {
    //   gridTemplateColumns: '780px 340px',
    // },
    '@media screen and (max-width: 1360px)': {
      gridTemplateColumns: '538px 640px',
    },
    '@media screen and (max-width: 1200px)': {
      gridTemplateColumns: 'auto',
    },
    '@media screen and (max-width: 900px)': {
      padding: [100, 0],
      gridTemplateAreas: '"preview" "description"',
    },
    '@media screen and (max-width: 475px)': {
      padding: [80, 0, 200, 0],
    },
  },
  text: {
    // width: 540,
    gridArea: 'description',
  },
  preview: {
    gridArea: 'preview',
    // width: 757,
    height: 550,
    background: `url("${closeSVG}") scroll no-repeat center`,
    '@media screen and (max-width: 1360px)': {
      margin: [330, 0, 0, -240],
    },
    '@media screen and (max-width: 1300px)': {
      margin: [330, 0, 0, -350],
    },
    '@media screen and (max-width: 1200px)': {
      width: 440,
      height: 295,
      backgroundSize: 'cover',
    },
    '@media screen and (max-width: 900px)': {
      margin: 'auto',
    },
    '@media screen and (max-width: 475px)': {
      width: 266,
      height: 180,
    },
  },
  title: {
    font: `700 48px/48px ${DEFAULT_FONTFAMILY_3}`,
    '@media screen and (max-width: 900px)': {
      font: `700 32px/40px ${DEFAULT_FONTFAMILY_3}`,
    },
    '@media screen and (max-width: 475px)': {
      font: `700 24px/28px ${DEFAULT_FONTFAMILY_3}`,
    },
  },
  description: {
    marginTop: 16,
    font: `400 18px/28px ${DEFAULT_FONTFAMILY_3}`,
  },
  list: {
    margin: [32, 0, 32, 23],
    listStyleType: 'disc',
  },
  listItem: {
    font: `700 24px/24px ${DEFAULT_FONTFAMILY_3}`,
    '@media screen and (max-width: 900px)': {
      font: `500 18px/18px ${DEFAULT_FONTFAMILY_3}`,
    },
  },
  buttons: {
    '@media screen and (max-width: 1200px)': {
      flexDirection: 'column',
    },
    '@media screen and (max-width: 900px)': {
      display: 'flex',
      justifyContent: 'center',
    },
    '@media screen and (max-width: 475px)': {
      alignItems: 'center',
    },
  },
  requestDemo: {
    color: '#1E202C',
    margin: [0, 0, 0, 15],
    font: `500 16px/16px ${DEFAULT_FONTFAMILY_3}`,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
    '@media screen and (max-width: 900px)': {
     margin: [15, 0, 0, 0],
    },
  },
};


function FinancialPerformance({ classes, modifier, artist, showAnalyticsPopup }) {
  return (
    <div
      className={cx(classes.FinancialPerformance, modifier)}
    >
      <div className={cx(classes.content, modifier)}>
        <div className={classes.text}>
          <h3 className={cx(classes.title, modifier)}>Explore the financial performance of Artists through in-depth <br/> market analytics.</h3>
          <div className={cx(classes.description, modifier)}>
            ARTBnk’s unique suite of analytics and indexes allow for a compehensive analysis of an artist’s financial performance, allowing market participants to unlock unprecedented opportunities. The artist suite of analytics includes more than 30+ charts and key performance metrics,  grouped into  4 sections:
          </div>
          <ul className={classes.list}>
            <li className={classes.listItem}>Financial Performance</li>
            <li className={classes.listItem}>Market Movements</li>
            <li className={classes.listItem}>Artist Ranking</li>
            <li className={classes.listItem}>ARTBnk Indexes</li>
          </ul>
          <div className={classes.buttons}>
            <button
              type="button"
              onClick={() => showAnalyticsPopup({ artist })}
              className={cx(classes.button, 'ArtistPage', 'addAlert', 'purchaseAnalyticsBtn')}
              children="Purchase Analytics"
            />
            {/* <a
              href="https://www.artbnk.com/contact-us "
              className={classes.requestDemo}
              children="Request a Demo"
              rel="noreferrer"
              target="_blank"
            /> */}
          </div>
        </div>
        <div className={classes.preview} />
      </div>
    </div>
  );
}
FinancialPerformance.propTypes = {
  classes: PropTypes.object.isRequired,
  modifier: PropTypes.string,
  artist: PropTypes.shape({ fullName: PropTypes.string }),
  showAnalyticsPopup: PropTypes.func.isRequired,
};

export default compose(
  connect({
    showAnalyticsPopup: showAnalyticsFormAction,
    artist: artistSelector,
  }),
  injectSheet(sheet),
)(FinancialPerformance);
