import {
  LAYOUT_RANGE_375_MAX,
  LAYOUT_RANGE_425_MAX,
  LAYOUT_RANGE_1024_MIN,
  LAYOUT_RANGE_1024_MAX,
} from 'theme/layout/LAYOUT';
import {
  DARK_BLUE_COLOR,
  DEFAULT_FONTFAMILY,
} from 'theme/theme';

export const formItem = {
  display: 'flex',
  alignItems: 'start',
  marginTop: 12,
  width: '100%',
  position: 'relative',
  '&.report_calculate': {
    marginTop: 0,
    position: 'relative',
    top: -40,
    // transform: 'translate(0, -40px)',
    display: 'block',
    '@media screen and (max-width: 1200px)': {
      top: 0,
    },
  },
  '&.comparablesBaseList': {
    marginTop: 0,
  },
  '&.createdAt-and-conceptionYear': {
    display: 'grid',
    columnGap: '50px',
    gridTemplateColumns: '207px 183px',
  },
  '&.CreatePassword': {
    flexDirection: 'column',
  },
  // outline: '2px solid blue',
  '&.salesHistory': {
    // marginTop: 0,
    '&.dateField': {
      marginTop: 0,
      display: 'block',
      '&.appraisal': {
        marginRight: 10,
      },
    },
    '&.FirstRow': {
      marginTop: 0,
    },
  },
  '&.Provenance': {
    // marginTop: 0,
  },
  '&.editionSize': {
    width: 140,
    justifyContent: 'flex-end',
    display: 'block',
    marginTop: 0,
    '&:first-child': {
      marginLeft: 0,
      justifyContent: 'flex-start',
    },
  },
  '&.sizeField': {
    width: 70,
    display: 'block',
    marginTop: 0,
    marginBottom: 8,
    fontFamily: DEFAULT_FONTFAMILY,
  },
  [`@media screen and (max-width: ${LAYOUT_RANGE_1024_MAX}px) and (min-width: ${LAYOUT_RANGE_1024_MIN}px)`]: {
    '&.AOForm': {
      display: 'block',
      '&.sizeField': {
        width: 60,
      },
      '&.ProvenanceItem.period': {
        display: 'flex',
      },
      '&.provenance-date': {
        display: 'flex',
      },
      '&.salesHistory.soldDateAndIsPrivate': {
        display: 'flex',
      },
      '&.location, &.SalesHistory, &.Provenance': {
        marginTop: 40,
      },
      '&.createdAt-and-conceptionYear': {
        display: 'grid',
        columnGap: '25px',
        gridTemplateColumns: '135px 135px',
      },
    },
  },
  '&.provenance-title': {
    display: 'block',
    marginTop: 0,
  },
  '&.provenance-date': {
    width: 120,
    marginTop: 0,
  },
  '&.priceField': {
    width: 140,
    flex: '0 0 140px',
    marginRight: 20,
    marginTop: 0,
    display: 'inline-block',
    fontFamily: DEFAULT_FONTFAMILY,
    '&.salesHistoryEstimatedPrice': {
      marginRight: 0,
    },
    '&.PriceUnsold': {
      width: '100%',
    },
  },
  '&.rtv-form-coupon': {
    display: 'block',
    '&.upgrade-subscription': {
      color: DARK_BLUE_COLOR,
      fontFamily: DEFAULT_FONTFAMILY,
      fontWeight: 500,
      marginTop: 30,
      textTransform: 'uppercase',
      fontSize: '14px',
    },
  },
  '&.provenanceCity': {
    display: 'block',
  },
  '&.acquisitionSellerName': {
    display: 'block',
  },
  '&.salesHistorySellerName': {
    display: 'block',
  },
  '&.cardExpiringFieldList': {
    justifyContent: 'space-between',
  },
  '&.authPageField': {
    display: 'block',
    marginTop: '20px',
  },
  '&.ResetPage': {
    display: 'block',
  },
  '&.SignUpPageField': {
    display: 'block',
  },
  '&.SignUpPageField-names': {
    flex: '0 1 160px',
  },
  '&.ForgotPageEmail': {
    display: 'block',
  },
  '&.AccountFormField': {
    display: 'block',
  },
  '&.ChangePasswordForm': {
    display: 'block',
  },
  '&.billingPageField': {
    display: 'block',
  },
  '&.CurrencyFormField': {
    display: 'block',
  },
  '&.advancedFilter-artTitle': {
    marginTop: 7,
  },
  '&.advancedFilter-Range': {
    // marginTop: 0,
    alignItems: 'baseline',
    flex: '1 1 0px',
    // display: 'block',
    '&.labelLayout': {
      '&.Range-min, &.sizeUnitField': {
        flex: '1 1 64px',
      },
    },
    '&.Range-max': {
      display: 'block',
    },
  },
  '&.privacyPopupCheckbox': {
    display: 'block',
  },
  '&.AskEmailPopup': {
    display: 'block',
  },
  '&.BuyRtvBillingField': {
    display: 'block',
  },
  '&.billingPageField--Country': {
    display: 'block',
  },
  '&.Gallery-Title': {
    display: 'block',
  },
  '&.advancedFilter-PeriodDate': {
    // marginTop: 0,
    display: 'block',
  },
  // NEW_ART_PAGE
  [`@media screen and (max-width: ${LAYOUT_RANGE_425_MAX}px)`]: {
    display: 'block',
    '&.advancedFilter-Range': {
      display: 'flex',
    },
    '&.createdAt-and-conceptionYear': {
      display: 'grid',
      rowGap: '12px',
      gridTemplateColumns: '1fr',
    },
    '&.salesHistory.soldDateAndIsPrivate': {
      display: 'flex',
    },
    '&.cardExpiringFieldList': {
      display: 'flex',
    },
    '&.AOForm.ProvenanceItem.period': {
      display: 'flex',
    },
    '&.AOForm.provenance-date': {
      display: 'flex',
    },
  },
  [`@media screen and (max-width: ${LAYOUT_RANGE_375_MAX}px)`]: {
    '&.salesHistory.soldDateAndIsPrivate.appraisal': {
      display: 'block',
    },
  },
  '@media screen and (max-width: 360px)': {
    '&.cardExpiringFieldList': {
      display: 'block',
    },
  },
};
