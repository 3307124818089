import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { button } from 'theme/button';
import injectSheet from 'lib/sheet';


const sheet = {
  button,
};

function Button({ children, classes, modifier, type = 'button', ...props }) {
  return (
    <button
      type={type}
      className={cx(classes.button, modifier)}
      {...props}
    >
      {children}
    </button>
  );
}
Button.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.shape({
    button: PropTypes.string.isRequired,
  }).isRequired,
  modifier: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({}),
  ]),
  type: PropTypes.oneOf(['button', 'submit']),
};

Button = injectSheet(sheet)(Button);

export { Button };
