import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'cpcs-reconnect';
import { compose } from 'redux';
import I from 'immutable';

// components
import { Link } from 'react-router-dom';
import PageContainer from 'pages/common/pageContainer';
import SharesColumns from 'pages/common/sharesTable/columns';
import DataTable from 'pages/common/sharesTable';
import NoSharesFound from 'pages/common/NoSharesFound';
import SortBy from 'pages/common/filters/elements/sortBy';
import Loader from 'components/loader/Loader';
import { sortSel } from 'domain/router/RouterModel';
import { updateQueryAction } from 'domain/router/RouterAction';

// tools
import { lnk } from 'lib/routes';
import filters, { getDefaultSortItem } from 'pages/common/filters/config';

// selectors
import {
  byMeLoadingSelector,
  withMeLoadingSelector,
  getSharedWithMe,
  getSharedWithMePagination_sel,
  sharedByMeSel,
  getSharedByMePagination_sel,
} from 'domain/shares/ShareModel';

// styles
import injectSheet from 'lib/sheet';
import styles from './sheet.js';

const columnsWithMe = SharesColumns.select('thumb', 'title', 'email', 'comment', 'created', 'remove');
const columnsByMe = SharesColumns.select('thumb', 'title', 'members', 'created', 'revoke');

class SharesPage extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    withMe: PropTypes.instanceOf(I.List),
    withMePagination: PropTypes.instanceOf(I.Record),
    byMe: PropTypes.instanceOf(I.List),
    byMePagination: PropTypes.instanceOf(I.Record),
    byMeLoading: PropTypes.bool,
    withMeLoading: PropTypes.bool,
    updateQuery: PropTypes.func.isRequired,
    sort: PropTypes.string,
  };

  render() {
    const {
      classes, withMe, byMe, withMePagination, byMePagination,
      byMeLoading, withMeLoading, updateQuery, sort,
    } = this.props;
    return (
      <PageContainer modifier="SharesPage">
        <header className={classes.header}>
          <h1 className={classes.title}>Share</h1>
          <SortBy
            list={filters.shares}
            onChange={(e) => updateQuery({ sort: e.target.value })}
            value={sort || getDefaultSortItem(filters.shares).id}
            modifier="sharesPage"
          />
        </header>
        <div className={classes.tableHeader}>
          <div className={classes.tableHeaderColumn1}>
            <Link
              className={classes.tableTitle}
              to={lnk('myShares')}
              children="Shared by Me"
            />
          </div>
          <div className={classes.tableHeaderColumn2}>
            {
              (byMePagination && byMePagination.pages > 1 ) &&
                <Link
                  className={classes.seeAllBtn}
                  to={lnk('myShares')}
                >
                  <span
                    className={classes.actionText}
                    children="SEE ALL"
                  />
                  {' '}
                  <span
                    className={classes.amountText}
                    children={`(${byMePagination.total})`}
                  />
                </Link>
            }
          </div>
        </div>
        <Loader modifier="SharesPage" loading={byMeLoading}>
          {
            byMe.size ?
              <DataTable
                columns={columnsByMe}
                items={byMe}
                modifier="IE"
              />
              :
              <NoSharesFound />
          }
        </Loader>
        <div className={classes.tableHeader}>
          <div className={classes.tableHeaderColumn1}>
            <Link
              className={classes.tableTitle}
              to={lnk('sharedWithMe')}
              children="Shared with Me"
            />
          </div>
          <div className={classes.tableHeaderColumn2}>
            {
              (withMePagination && withMePagination.pages > 1 ) &&
                <Link
                  className={classes.seeAllBtn}
                  to={lnk('sharedWithMe')}
                >
                  <span
                    className={classes.actionText}
                    children="SEE ALL"
                  />
                  {' '}
                  <span
                    className={classes.amountText}
                    children={`(${withMePagination.total})`}
                  />
                </Link>
            }
          </div>
        </div>
        <Loader modifier="SharesPage" loading={withMeLoading}>
          {
            withMe.size ?
              <DataTable
                columns={columnsWithMe}
                items={withMe}
                sharedWithMe
                modifier="IE"
              />
              :
              <NoSharesFound />
          }
        </Loader>
      </PageContainer>
    );
  }
}

export default compose(
  injectSheet(styles),
  connect({
    withMe: getSharedWithMe,
    withMePagination: getSharedWithMePagination_sel,
    byMe: sharedByMeSel,
    byMePagination: getSharedByMePagination_sel,
    byMeLoading: byMeLoadingSelector,
    withMeLoading: withMeLoadingSelector,
    updateQuery: updateQueryAction,
    sort: sortSel,
  }),
)(SharesPage);
