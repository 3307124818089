import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'cpcs-reconnect';
// import cx from 'classnames';

import Header from 'pages/artistPage/Header';
import ReportsArtist from 'components/reports/ReportsArtist';
import { routerParams_sel } from 'domain/router/RouterModel';
import FinancialPerformance from 'components/reports/FinancialPerformance';
import { subscriptionTypeSelector } from 'domain/env/EnvModel';
import { SUBSCRIPTION_TYPE_ENTERPRISE } from 'domain/const';

import injectSheet from 'lib/sheet';
import sheet from './sheet.js';
import { artistSelector } from 'domain/artwork/ArtworkModel';
import { PDFModalStatesWrapper } from 'pages/highcharts/helpers/PDFModalStatesWrapper';
import { REPORT_TYPE_ARTIST } from 'pages/highcharts/helpers/PDFConstants';
import { PDFPreview } from 'pages/highcharts/PDFPreview';


class ArtistReportsPage extends React.PureComponent {
  static propTypes = {
    routeParams: PropTypes.shape({
      authorId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    subscriptionType: PropTypes.string,
    artist: PropTypes.shape({
      fullName: PropTypes.string,
      analyticAvailable: PropTypes.bool,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    PDFReportShown: PropTypes.bool,
    readyToGeneratePDF: PropTypes.bool,
    togglePDFReport: PropTypes.func.isRequired,
    onPDFSaved: PropTypes.func.isRequired,
    // force rerender PDFPreview
    PDFState: PropTypes.shape({
      reportType: PropTypes.string,
    }),
  }

  get analyticsAccessible() {
    const { subscriptionType, artist } = this.props;
    return subscriptionType === SUBSCRIPTION_TYPE_ENTERPRISE || (artist && artist.analyticAvailable);
  }

  render() {
    const {
      routeParams: { authorId }, artist,
      PDFReportShown, readyToGeneratePDF, togglePDFReport, onPDFSaved, PDFState,
    } = this.props;

    return (
      <React.Fragment>
        <Header
          hideFilters
          hideTotal
          togglePDFReport={togglePDFReport}
          readyToGeneratePDF={readyToGeneratePDF}
          PDFReportShown={PDFReportShown}
        />
        {
          this.analyticsAccessible &&
            <ReportsArtist
              artistId={authorId}
            />
        }
        {
          this.analyticsAccessible && PDFReportShown &&
            <PDFPreview togglePDFReport={togglePDFReport} onPDFSaved={onPDFSaved} artist={artist} PDFState={PDFState} />
        }
        {
          artist && artist.id && !this.analyticsAccessible &&
            <FinancialPerformance />
        }
      </React.Fragment>
    );
  }
}

export default compose(
  injectSheet(sheet),
  connect({
    routeParams: routerParams_sel,
    subscriptionType: subscriptionTypeSelector,
    artist: artistSelector,
  }),
  PDFModalStatesWrapper(REPORT_TYPE_ARTIST),
)(ArtistReportsPage);
