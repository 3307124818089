import React from 'react';
import cx from 'classnames';

import { definitions } from 'components/reports/Definition';

import { connectEventsContainer } from 'pages/highcharts/helpers/EventsContext';
import { RefSize } from 'pages/highcharts/helpers/refSize';
import { DEFAULT_FONTFAMILY } from 'pages/highcharts/helpers/PDFConstants';
import { renderText } from 'pages/highcharts/helpers/Text';


const themes = {
  AFPM: {
    TITLE_FONT_SIZE: 9,
    TEXT_FONT_SIZE: 8,
    TEXT_LINE_HEIGHT: 14,
    MARGIN_LEFT: 429 - 29,
    WIDTH: 134 + 29 + 30,
    LENGTH: 48,
    MODIFIER: 'AFPM',
    TEXT_TOP: 39,
    HEADER_HEIGHT: 24,
    PADDING_BOTTOM: 0,
  },
  default: {
    TITLE_FONT_SIZE: 8,
    TEXT_FONT_SIZE: 6,
    TEXT_LINE_HEIGHT: 8,
    MARGIN_LEFT: 429,
    WIDTH: 158,
    // item.customWidth ? 48 : 42,
    LENGTH: 48,
    MODIFIER: '',
    TEXT_TOP: 26,
    HEADER_HEIGHT: 16,
    PADDING_BOTTOM: 4,
  },
};

class DefinitionPDF extends RefSize {
  static propTypes = {
    //
  };

  render() {
    const { item } = this.props;
    const THEME = themes[item.parent] || themes.default;
    const defItem = definitions[item.name];
    const { lines, content } = renderText({
      text: defItem ? defItem.description : '',
      length: THEME.LENGTH,
      className: cx('DefinitionPDF_text', THEME.MODIFIER),
      lineHeight: THEME.TEXT_LINE_HEIGHT,
      left: 8,
      top: THEME.TEXT_TOP,
      props: {
        alignmentBaseline: 'middle',
      },
    });
    const height = THEME.TEXT_TOP + THEME.TEXT_LINE_HEIGHT * lines.length + THEME.PADDING_BOTTOM;
    const m = THEME.MODIFIER ? `.${THEME.MODIFIER}` : '';
    return (
      <svg
        width={THEME.WIDTH}
        height={height}
        ref={this.ref}
        data-margin-left={item.customMarginLeft || THEME.MARGIN_LEFT}
        data-margin-top={item.customMarginTop}
        data-margin-bottom={item.customMarginBottom}
      >
        <style>
          {`
          .DefinitionPDF_title${m} { font: 500 ${THEME.TITLE_FONT_SIZE}px ${DEFAULT_FONTFAMILY}; fill: #5B6178; }
          .DefinitionPDF_text${m} { font: 400 ${THEME.TEXT_FONT_SIZE}px ${DEFAULT_FONTFAMILY}; fill: #737992; }
          `}
        </style>
        <rect
          x="0"
          y="0"
          width={THEME.WIDTH}
          height={height}
          fill="#FFFFFF01"
        />
        <rect
          x="0"
          y="0"
          width={THEME.WIDTH}
          height={THEME.HEADER_HEIGHT}
          fill="#F0F2F6"
        />
        <text
          x="8"
          y={Math.round(THEME.HEADER_HEIGHT / 2)}
          className={cx('DefinitionPDF_title', THEME.MODIFIER)}
          alignmentBaseline="middle"
        >
          {defItem && defItem.title}
        </text>
        {content}
      </svg>
    );
  }
}

DefinitionPDF = connectEventsContainer(DefinitionPDF);

export { DefinitionPDF };
