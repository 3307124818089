import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import CreateForm from 'pages/common/popupForms/create';
import injectSheet from 'lib/sheet';
import sheet from './sheet';

function CreateGallery({ classes }) {
  return (
    <div className={cx(classes.item, 'CreateGallery')}>
      <div className={classes.thumbNewGallery} />
      <CreateForm addSelected modifier="addToGalleryPopup" />
    </div>
  );
}

CreateGallery.propTypes = {
  classes: PropTypes.shape({
    item: PropTypes.string,
    thumbNewGallery: PropTypes.string,
    addNewTitle: PropTypes.string,
  }).isRequired,
};

export default injectSheet(sheet)(CreateGallery);
