import React from 'react';
import { connect } from 'cpcs-reconnect';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { RemoveButton as theme } from 'theme/button';
import cx from 'classnames';
import { considerToRemoveAction, TYPE_ALERT, TYPE_ARTWORK, TYPE_CONSIDERATION_ARTWORK } from 'domain/const';

import injectSheet from 'lib/sheet';
const sheet = { RemoveButton: theme };

function RemoveButton({ classes, type, params, link, builtLink, onRemove, title = 'Delete art', modifier, entityTitle }) {
  return (
    <button
      className={cx(classes.RemoveButton, 'button', modifier)}
      type="button"
      onClick={() => onRemove({ type, params, link, builtLink, name: entityTitle })}
      id="idRemoveButton"
      children={title}
    />
  );
}

RemoveButton.propTypes = {
  type: PropTypes.string.isRequired,
  title: PropTypes.string,
  params: PropTypes.object.isRequired,
  link: PropTypes.string,
  builtLink: PropTypes.string,
  modifier: PropTypes.string,
  onRemove: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    RemoveButton: PropTypes.string.isRequired,
  }).isRequired,
  entityTitle: PropTypes.string,
};

export { RemoveButton as RemoveButtonPure };
export { TYPE_ARTWORK, TYPE_CONSIDERATION_ARTWORK, TYPE_ALERT };

export default compose(
  injectSheet(sheet),
  connect({
    onRemove: considerToRemoveAction,
  }),
)(RemoveButton);
