import Api from 'domain/api';

// FACEBOOK, LINKEDIN, GOOGLE, INSTAGRAM, TWITTER, PINTEREST
const config = {
  auth: {
    providers: {
      facebook: {
        provider: 'FACEBOOK',
        appId: '160106391489910',
        redirectPath: '/',
      },
      google: {
        provider: 'GOOGLE',
        appId: '279478146266-cr5k5c0c5vsfegclehg30f2r1it9m2ua.apps.googleusercontent.com',
        redirectPath: '/auth/google',
      },
      instagram: {
        provider: 'INSTAGRAM',
        appId: '61c158bc17d847e19b765b10e77dfbdb',
        redirectPath: '/auth/instagram',
      },
      linkedin: {
        provider: 'LINKEDIN',
        appId: '783t3xcvohwe3x',
        redirectPath: '/auth/linkedin',
      },
      pinterest: {
        provider: 'PINTEREST',
        appId: '4964586184863920009',
        redirectPath: '/auth/pinterest',
      },
      twitter: {
        provider: 'TWITTER',
        appId: 'aEMMpESNZZxbldoKLAwHP1BuT',
        redirectPath: '/auth/twitter',
      },
    },
  },
  env: undefined,
};

export default config;

let envConfigListeners = [];
const handleOnEnvConfig = () => envConfigListeners.forEach(f => f(config.env));
export const onEnvConfigLoaded = (f) => {
  envConfigListeners = [...envConfigListeners, f];
};
export const waitEnvConfig = async () => {
  if (config.env) return config.env;
  await new Promise(onEnvConfigLoaded);
  return config.env;
};

export const loadConfig = async () => {
  try {
    const resp = await Api.loadConfig();
    const { data = {} } = resp;
    let {
      REACT_APP_NOTIFIER_HOST = '//ws-notifier.dev03.artbnk.com',
      GOOGLETAGMANAGER_ENABLED = false,
      HOTJAR_ENABLED = false,
      ZDASSETS_ENABLED = false,
      USERGUIDING_ENABLED = false,
    } = data;
    if (process.env.NODE_ENV === 'development' || process.env.REACT_APP_DISABLE_WS_NOTIFIER) {
      REACT_APP_NOTIFIER_HOST = '';
    }
    config.env = {
      REACT_APP_NOTIFIER_HOST,
      GOOGLETAGMANAGER_ENABLED,
      HOTJAR_ENABLED,
      ZDASSETS_ENABLED,
      USERGUIDING_ENABLED,
    };
    handleOnEnvConfig();
  } catch (err) {
    console.error(err);
  }
};
