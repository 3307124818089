import React from 'react';
import PropTypes from 'prop-types';
import I from 'immutable';

import { compose } from 'redux';
import { connect } from 'cpcs-reconnect';

import Details from 'pages/common/sharedItemsPage/Details';
import Header from 'pages/common/sharedItemsPage/Header';
import PageContainer from 'pages/common/pageContainer';
import filters from 'pages/common/filters/config';
import { ENTITY_NAME_ARTWORK } from 'domain/const';
import SharePopup from 'pages/common/popup/share';

import {
  getCurrentShareSelector,
} from 'domain/shares/ShareModel';
import Filters from 'pages/common/filters';

import injectSheet from 'lib/sheet';
import styles from './sheet.js';
import ArtworkList from './ArtworkList';
import translate from 'lib/translate.js';

class SharedItemsPage extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    share: PropTypes.instanceOf(I.Record).isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        gId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }).isRequired,
    }).isRequired,
  };

  get entityName() {
    return ENTITY_NAME_ARTWORK;
  }

  render() {
    const { classes, share, match } = this.props;

    if (!share || !share.id) {
      return <PageContainer modifier="sharedItemsPage" />;
    }

    return (
      <PageContainer modifier="sharedItemsPage">
        <Details
          share={share}
        />
        <Header
          showEditButton={!match.params.gId}
        />
        <div key="filter" className={classes.filters}>
          <Filters sortByList={filters.shared} placeholder={translate(`filterLabel.${this.entityName}`)} />
        </div>
        <ArtworkList match={match} />
        <SharePopup submitButtonTitle="SAVE CHANGES" />
      </PageContainer>
    );
  }
}

export default compose(
  injectSheet(styles),
  connect({
    share: getCurrentShareSelector,
  }),
)(SharedItemsPage);
