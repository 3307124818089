import record, { integer, string, listOf } from 'cpcs-recordjs';
import { field, selector } from 'cpcs-reconnect';
import { listToMap, toGroupedList } from 'lib/serialize';
import * as A from './SubstrateActions';

const Substrate = record('Substrate', {
  id: integer(),
  title: string(),
});

const State = record('Substrates', {
  content: listOf(Substrate),
  common: listOf(integer),
});

export const substrates = field('substrates');
export const substrateList = substrates.then(State.$content);
const substrateCommonList_sel = substrates.then(State.$common);
export const groupedSubstrates = selector(substrateList, substrateCommonList_sel, toGroupedList);
export const substratesById = selector(substrateList, listToMap);
export const substratesById_sel = selector(substrateList, listToMap);

export const reducer = {
  substrates(state = new State(), action) { //NOSONAR
    switch (action.type) { //NOSONAR

      case A.fetchSubstratesAction.success:
        return state.apply(
          State.$content.parsed(action.payload),
        );

      case A.setCommonSubstratesAction.type:
        return state.apply(
          State.$common.parsed(action.payload),
        );

      default:
        return state;
    }
  },
};
