import RemoveButtonBg from 'pages/common/RemoveButton/images/remove.svg';
import {
  DEFAULT_BUTTON_COLOR,
  DARK_BLUE_COLOR,
  DEFAULT_FONTFAMILY,
  DEFAULT_FONTFAMILY_3,
  TEXT_LINK_COLOR,
  NEW_STYLE,
  BUTTON_ARTIST_EDIT,
  DISCOVER_PAGE_REMOVE_ALERT,
  DISCOVER_PAGE_REMOVE_ALERT_BG,
  DISCOVER_PAGE_REMOVE_ALERT_BG_HOVER,
  DISCOVER_PAGE_REMOVE_ALERT_BORDER,
  SMOKY_WHITE,
} from 'theme/theme';

const color = {
  textWithBg: '#ffffff',
  textActive: TEXT_LINK_COLOR,
};


const done = {
  color: color.textActive,
  '&:not([disabled]):hover': {
    backgroundColor: SMOKY_WHITE,
  },
};

const primary = {
  color: '#FFF',
  backgroundColor: '#304C95',
  border: '2px solid #304C95',
  borderRadius: 6,
  fontFamily: DEFAULT_FONTFAMILY_3,
  fontWeight: 500,
  fontSize: '16px',
  lineHeight: '16px',
  textTransform: 'none',
  padding: [5, 15, 6],
  '&:not([disabled]):focus': {
    backgroundColor: '#304C95',
    border: '2px solid #1E202C',
    outline: 'none',
    boxShadow: 'none',
  },
  '&:not([disabled]):hover': {
    backgroundColor: '#313545',
    border: '2px solid #313545',
  },
  '&:not([disabled]):hover:focus': {
    backgroundColor: '#313545',
    border: '2px solid #1E202C',
  },
};

const secondary = {
  color: '#1E202C',
  backgroundColor: '#F0F2F6',
  border: '2px solid #F0F2F6',
  borderRadius: 6,
  fontFamily: DEFAULT_FONTFAMILY_3,
  fontWeight: 500,
  fontSize: '16px',
  lineHeight: '16px',
  textTransform: 'none',
  padding: [5, 15, 6],
  '&:not([disabled]):focus': {
    backgroundColor: '#D9DDEA',
    border: '2px solid #A6ACC4',
    outline: 'none',
    boxShadow: 'none',
  },
  '&:not([disabled]):hover': {
    backgroundColor: '#D9DDEA',
    border: '2px solid #D9DDEA',
  },
  '&:not([disabled]):hover:focus': {
    backgroundColor: '#D9DDEA',
    border: '2px solid #A6ACC4',
  },
};

const secondary2 = {
  background: '#F0F2F6',
  borderRadius: 6,
  font: `500 16px/19px ${DEFAULT_FONTFAMILY_3}`,
  padding: [5, 15, 6],
  height: 'auto',
  textTransform: 'none',
  color: '#304C95',
  backgroundColor: '#EFF2F6',
  border: '2px solid #EFF2F6',
  '&:not([disabled]):focus': {
    backgroundColor: '#D9DDEA',
    border: '2px solid #D9DDEA',
    outline: 'none',
    boxShadow: 'none',
  },
  '&:not([disabled]):hover': {
    backgroundColor: '#D9DDEA',
    border: '2px solid #D9DDEA',
  },
  '&:not([disabled]):hover:focus': {
    backgroundColor: '#D9DDEA',
    border: '2px solid #A6ACC4',
  },
};

const cancel = {
  color: DARK_BLUE_COLOR,
  '&:not([disabled]):hover': {
    backgroundColor: SMOKY_WHITE,
  },
  '&.PDFModal': {
    color: '#FFF',
    font: `700 18px/22px ${DEFAULT_FONTFAMILY}`,
    textTransform: 'none',
    position: 'relative',
    paddingRight: 30,
    '&:before,&:after': {
      display: 'block',
      content: '""',
      position: 'absolute',
      right: 0,
      top: 15,
      borderBottom: '2px solid #FFF',
      width: 20,
      rotate: '45deg',
    },
    '&:after': {
      rotate: '-45deg',
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
};

const defaultTheme = {
  color: DARK_BLUE_COLOR,
  fontWeight: 500,
  border: `1px solid ${DARK_BLUE_COLOR}`,
  '&:not([disabled]):hover': {
    backgroundColor: SMOKY_WHITE,
  },
};

const darkTheme = {
  color: '#FFF',
  backgroundColor: '#000',
  border: '1px solid #000',
  fontWeight: 500,
  fontSize: '13px',
  '&:disabled': {
    opacity: 0.8,
  },
  '&:not([disabled]):hover': {
    // backgroundColor: color.activeDark,
    opacity: 0.8,
  },
};

const add = {
  background: DEFAULT_BUTTON_COLOR,
  color: color.textWithBg,
  '&:not([disabled]):hover': {
    backgroundColor: color.activeDark,
  },
};

const draft = {
  backgroundColor: '#b9b4b4',
  color: 'black',
};

// const edit = {
//   background: DEFAULT_BUTTON_COLOR,
//   color: color.textWithBg,
//   '&:hover': {
//     backgroundColor: color.activeDark,
//   },
// };

const link = {
  color: TEXT_LINK_COLOR,
};

const big = {
  fontSize: 18,
  letterSpacing: '1.5px',
  width: 320,
  height: 56,
  lineHeight: '42px',
  fontWeight: 600,
};

const button = {
  flexShrink: 0,
  cursor: 'pointer',
  display: 'inline-block',
  border: '1px solid transparent',
  textAlign: 'center',
  fontFamily: DEFAULT_FONTFAMILY,
  textTransform: 'uppercase',
  textDecoration: 'none',
  letterSpacing: 0.4,
  fontSize: 12,
  fontWeight: 400,
  height: 30,
  padding: [6, 15],
  lineHeight: '16px',
  '&.done': done,
  '&.cancel': cancel,
  '&.defaultTheme': defaultTheme,
  '&.add': add,
  '&.draft': draft,
  '&.link': link,
  '&.big': big,
  '&.darkTheme': darkTheme,
  '&:disabled': {
    opacity: 0.5,
  },
  '&:not([disabled]):hover': {
    opacity: 0.9,
  },
  '&.secondary2': secondary2,
  '&.secondary': secondary,
  '&.primary': primary,

  // modifiers
  '&.purchaseAnalyticsBtn': {
    width: 180,
  },
  '&.report_calculate': {
    '@media screen and (max-width: 1200px)': {
      transform: 'translate(0, 39px)',
    },
    '@media screen and (max-width: 549px)': {
      transform: 'none',
      marginTop: 15,
    },
  },
  '&.select-comparables-popup': {
    whiteSpace: 'nowrap',
    textTransform: 'capitalize',
    '@media screen and (max-width: 660px)': {
      padding: [6, 14],
    },
  },
  '&.Header-Upgrade-Btn': {
    ...add,
    display: 'flex',
    height: 34,
    margin: [7, 15, 0, 0],
    alignItems: 'center',
    justifyContent: 'center',
  },
  '@media screen and (max-width: 375px)': {
    '&.Header-Upgrade-Btn': {
      width: 34,
      height: 32,
      padding: 0,
    },
  },

  '&.Popup-AskEmail-Submit': done,
  '&.Popup-AcceptPrivacy.submit': done,
  '&.Popup-CropImagePopup, &.Popup-ReUploadImagePopup': {
    marginRight: 10,
    border: `1px solid ${DARK_BLUE_COLOR}`,
  },

  '&.Popup-Privacy, &.Popup-SecondPrivacy, &.Popup-SignUp-Terms': {
    marginLeft: 10,
    '&.submit': add,
  },
  '&.NewAuthorForm, &.Popup-CreateGallery, &.Popup-RenameGallery, &.Popup-share, &.ConfirmationPopup': {
    margin: [0, 5],
    '&.send': done,
  },
  '&.advancedFilter.reset': {
    ...link,
    fontWeight: 500,
    paddingRight: 4,
    paddingLeft: 4,
  },
  '&.advancedFiltersShowResults': {
    width: '100%',
    textTransform: 'uppercase',
  },
  '&.ProcessingPopup-done': {
    ...done,
    fontWeight: 500,
    margin: [0, 5],
  },
  '&.AccountPage': {
    ...add,
    padding: [7, 27],
    marginTop: 12,
    float: 'right',
    fontWeight: 500,
    letterSpacing: 1,
  },
  '&.ListPage--Dashboard': {
    whiteSpace: 'nowrap',
    '&.galleryBtn': {
      ...defaultTheme,
      gridArea: 'cancel',
    },
    '&.artBtn': {
      ...add,
      gridArea: 'save',
    },
  },
  '&.SharedItemsPage--NoArt-Create': {
    ...add,
    ...big,
    marginTop: 40,
  },
  '&.AuthPage.submit, &.ResetPage.submit, &.ForgotPage.submit': {
    ...big,
    width: '100%',
    fontSize: 14,
    fontWeight: 500,
    height: 38,
    padding: 0,
    lineHeight: '38px',
    letterSpacing: '1px',
    backgroundColor: NEW_STYLE,
    borderRadius: '4px',
    color: '#FFF',
    '&:hover': {
      backgroundColor: NEW_STYLE,
    },
  },
  '&.AO-Form-PageHeader': {
    verticalAlign: 'middle',
    '&.cancel': {
      ...defaultTheme,
      gridArea: 'cancel',
    },
    '&.save': {
      ...add,
      gridArea: 'save',
    },
    '&.draft': {
      whiteSpace: 'nowrap',
      gridArea: 'draft',
    },
    '&:first-child': {
      marginLeft: 0,
    },
  },
  '&.editBtn': {
    fontWeight: 500,
    ...add,
    gridArea: 'save',
  },
  '&.ArtistPage': {
    '&.btnAdd': {
      ...add,
      gridArea: 'save',
      whiteSpace: 'nowrap',
    },
    '&.addAlert': {
      padding: [10, 14, 11],
      marginTop: 8,
      font: `500 16px/19px ${DEFAULT_FONTFAMILY_3}`,
      color: '#FFF',
      backgroundColor: '#304C95',
      textTransform: 'none',
      height: 'auto',
      borderRadius: 6,
      border: 'none',
    },
    '&.btnEdit': {
      gridArea: 'cancel',
      ...defaultTheme,
    },
    '&.btnAddBig': {
      ...add,
      ...big,
    },
    '&.generagePDFReport': {
      background: '#F0F2F6',
      borderRadius: 6,
      font: `500 16px/19px ${DEFAULT_FONTFAMILY_3}`,
      padding: [10, 14, 11],
      marginTop: 8,
      height: 'auto',
      border: 'none',
      marginLeft: 15,
      textTransform: 'none',
      color: '#000000',
    },
    '&.analyticsTableBtn': {
      fontSize: 14,
      '&.purchaseAnalytics': {
        margin: [0, 25, 0, 0],
        '&.mobile': {
          margin: [0, 10, 0, 0],
          '@media screen and (max-width: 654px)': {
            marginRight: 0,
          },

        },
      },
      '&.mobile': {
        width: 150,
        '@media screen and (max-width: 512px)': {
          width: 130,
          fontSize: 12,
        },
      },
    },
  },
  '&.SharedItemsPage': {
    '&.revokeButton': {
      border: '1px solid #000000',
      padding: [3, 25],
    },
    '&.editButton': {
      ...add,
      padding: [3, 25],
    },
  },
  '&.PrivacyAndTermsLabel.tems-link': {
    ...link,
    height: 'auto',
    padding: 0,
    fontSize: 13,
    fontWeight: 500,
    textTransform: 'capitalize',
    textDecoration: 'underline',
    display: 'inline',
    whiteSpace: 'normal',
  },
  '&.artistEdit': {
    color: BUTTON_ARTIST_EDIT,
    fontSize: 14,
    letterSpacing: 1,
    padding: [7, 27, 7, 27],
    minWidth: 'auto',
    whiteSpace: 'nowrap',
    verticalAlign: 'top',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.07)',
    },
    // '&:focus': {
    //   outline: 'none',
    //   boxShadow: '0 0 3px 2px #4990e2',
    // },
  },
};

const RemoveButton = {
  cursor: 'pointer',
  textDecoration: 'none',
  height: 24,
  color: DARK_BLUE_COLOR,
  fontFamily: DEFAULT_FONTFAMILY,
  fontSize: 16,
  fontWeight: 400,
  display: 'inline-block',
  whiteSpace: 'nowrap',
  '&:before': {
    content: '""',
    background: `url("${RemoveButtonBg}") scroll no-repeat left center`,
    display: 'inline-block',
    width: 20,
    height: 18,
    verticalAlign: 'top',
    marginLeft: 4,
  },
  '&.onDashboard': {
    height: 30,
    gridArea: 'cancel',
    border: '1px solid #000000',
    fontSize: 12,
    textTransform: 'uppercase',
    padding: [6, 15],
    ...defaultTheme,
    '&:before': {
      display: 'none',
    },
  },
  '&.discoverPage': {
    background: DISCOVER_PAGE_REMOVE_ALERT_BG,
    color: DISCOVER_PAGE_REMOVE_ALERT,
    border: `1px solid ${DISCOVER_PAGE_REMOVE_ALERT_BORDER}`,
    font: `400 12px/16px ${DEFAULT_FONTFAMILY}`,
    textTransform: 'uppercase',
    letterSpacing: 0.4,
    height: 30,
    padding: [6, 15],
    marginLeft: 20,
    '&:before': {
      display: 'none',
    },
    '&:hover': {
      backgroundColor: DISCOVER_PAGE_REMOVE_ALERT_BG_HOVER,
    },
  },
  '&.artistPage': {
    color: '#FFF',
    backgroundColor: '#313545',
    borderRadius: 6,
    padding: [11, 14, 12],
    height: 'auto',
    marginTop: 8,
    font: `500 16px/16px ${DEFAULT_FONTFAMILY_3}`,
    '&:before': {
      display: 'none',
    },
  },
  '@media screen and (max-width: 575px)': {
    '&.discoverPage': {
      margin: [20, 0, 0],
      flex: '0 0 100%',
    },
  },
};

export {
  button,
  RemoveButton,
};
