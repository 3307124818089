import React, { useState, useCallback, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect as cpConnect } from 'cpcs-reconnect';
import { connect } from 'react-redux';
import cx from 'classnames';
import { reduxForm, Field, getFormValues, touch as touchAction } from 'redux-form';

import { rules, validator } from 'components/form/validation';
import { ILLIQUID } from 'domain/const';

import FormRowField from 'pages/common/newPage/form/elements/FormRowField';
import DateField from 'components/form/DateField';
import { Chart } from 'components/reports/Chart/Chart';

import { button } from 'theme/button';
import injectSheet from 'lib/sheet';

const priceValidator = validator(rules.required, rules.int);
const yearValidator = validator(rules.required, rules.year);

const sheet = {
  CAGRProjectedValue: {
    //
  },
  filter: {
    display: 'grid',
    gridTemplateColumns: '115px 108px 98px',
    columnGap: '24px',
    justifyContent: 'end',
    paddingRight: 110,
    '@media screen and (max-width: 1360px)': {
      paddingTop: 40,
      paddingLeft: 30,
      paddingRight: 0,
      justifyContent: 'start',
    },
    '@media screen and (max-width: 1200px)': {
      paddingTop: 0,
    },
    '@media screen and (max-width: 549px)': {
      gridTemplateColumns: '100%',
    },
  },
  button,
};

const format = (v = '') => v ? new Intl.NumberFormat('en-EN').format(v) : v;
const normalize = (v, p = '') => {
  return /[^0-9,']+/.test(v) ? p : v.replace(/\D/g, '');
};
/* eslint-disable react/prop-types */
function InputWithCurrencyNoRef(props, ref) {
  return (
    <div className={cx(props.className, 'input_with_currency_root')}>
      <div className={cx(props.className, 'input_with_currency_sign')}>${' '}</div>
      <input {...props} ref={ref} className={cx(props.className, 'input_with_currency')} />
    </div>
  );
}
// InputWithCurrencyNoRef.propTypes = {
//   className: PropTypes.string.isRequired,
// };
/* eslint-enable react/prop-types */
const InputWithCurrency = forwardRef(InputWithCurrencyNoRef);

function CAGRProjectedValue({ classes, artworkId, artistId, form, data, onTouch, initialize, artistReport, ...props }) {
  const defaultFilters = artistReport ?
    { sold_price: 100000, auction_date: '2010-01-01' } :
    { sold_price: null, auction_date: null };
  const [filter, setFilter] = useState(defaultFilters);
  const onResponseSuccess = useCallback(({ series }) => {
    const { series: [ { data: [ [ ms, price = 0 ] = [] ] = [] } = { data: [] } ] = [] } = { series } || {};
    let auction_date = null;
    if (ms) {
      const date = new Date(ms);
      auction_date = date.getFullYear();
    }
    initialize({ auction_date, sold_price: price });
  }, [initialize]);
  const onFilter = useCallback(() => {
    if (!data) {
      onTouch(form, 'sold_price', 'auction_date');
      return;
    }
    const date = data.auction_date ? `${data.auction_date}-01-01` : null;
    setFilter({ sold_price: null, ...data, auction_date: date });
  }, [data, setFilter, form, onTouch]);
  if (!props.liquidity || !props.liquidity.liquidity || props.liquidity.liquidity === ILLIQUID) return null;
  const chartUnder = (
    <div className={classes.filter}>
      <Field
        type="text"
        name="sold_price"
        component={FormRowField}
        modifier="report_calculate"
        placeholder="000000000"
        rootTag="div"
        validate={priceValidator}
        label="Appraisal/Sale Price"
        format={format}
        normalize={normalize}
        InputComponent={InputWithCurrency}
      />
      <Field
        type="text"
        name="auction_date"
        component={FormRowField}
        modifier="report_calculate"
        placeholder="YYYY"
        validate={yearValidator}
        Field={DateField}
        props={{
          // title: 'Price / Year',
          disabled: false,
          rootTag: 'div',
        }}
        mask={[/\d/, /\d/, /\d/, /\d/]}
        momentMask="YYYY"
        momentParseMasks={['YYYY']}
        maxDetail="decade"
        label="Appraisal/Sale Date"
      />
      <button
        type="button"
        className={cx(classes.button, 'secondary', 'report_calculate')}
        onClick={onFilter}
        children="Calculate"
      />
    </div>
  );
  return (
    <div className={classes.CAGRProjectedValue}>
      <Chart
        chartId="p001"
        artworkId={artworkId}
        artistId={artistId}
        filter={filter}
        chartUnder={chartUnder}
        onResponseSuccess={onResponseSuccess}
        artistReport={artistReport}
      />
    </div>
  );
}

CAGRProjectedValue.propTypes = {
  classes: PropTypes.shape({
    CAGRProjectedValue: PropTypes.string.isRequired,
    filter: PropTypes.string.isRequired,
    button: PropTypes.string.isRequired,
  }).isRequired,
  artworkId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  artistId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  form: PropTypes.string.isRequired,
  onTouch: PropTypes.func.isRequired,
  data: PropTypes.object,
  initialize: PropTypes.func.isRequired,
  liquidity: PropTypes.shape({
    liquidity: PropTypes.string,
  }),
  artistReport: PropTypes.bool,
};


CAGRProjectedValue = compose(
  cpConnect({
    onTouch: touchAction,
  }),
  injectSheet(sheet),
  reduxForm({ form: 'CAGRProjectedValue' }),
  connect(state => ({
    data: getFormValues('CAGRProjectedValue')(state),
  })),
)(CAGRProjectedValue);

export { CAGRProjectedValue };
