/* eslint-disable no-func-assign */
import React, { useReducer, useRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import injectSheet from 'lib/sheet';
import sheet from './SvgPreview.sheet';

const initialState = {
  hidden: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'show':
      return { ...state, hidden: state.hidden.filter(v => v !== action.index) };
    case 'hide':
      return { ...state, hidden: [ ...state.hidden, action.index ] };
    default:
      return state;
  }
};

function SvgPreview({ hidden, parts: allParts, classes, onExport, onHide, impRef }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const svgDocRef = useRef();
  const refs = [];
  const previewRefs = [];
  const parts = allParts.filter(v => !state.hidden.includes(v.index));
  parts.forEach((part) => {
    refs.push(node => {
      if (!node) return;
      node.innerHTML = part.svg;
    });
  });
  state.hidden
    .map(index => allParts.find(v => v.index === index))
    .forEach((part) => {
      previewRefs.push(node => {
        if (!node) return;
        node.innerHTML = part.svg;
      });
    });
  useImperativeHandle(impRef, () => ({
    get svgList() {
      return Array.from(svgDocRef.current.childNodes);
    },
  }));
  let top = 0;
  let height = 0;
  if (hidden) return null;
  return (
    <div className={classes.SvgPreview}>
      <div className={classes.overlay} onClick={onHide} />
      <div className={classes.configurator}>
        <div className={classes.contentWrapper}>
          <div className={classes.svgDoc} ref={svgDocRef}>
            {
              parts.map((part, i) => (
                <svg ref={refs[i]} key={i} width={part.width} height={(height += part.height, part.height)} />
              ))
            }
          </div>
          <svg className={classes.confSvg} width="100%" height={height}>
            {
              parts.map(part => {
                const rect = (
                  <g
                    transform={`translate(30, ${30 + top})`}
                    key={part.index}
                    onClick={() => dispatch({ type: 'hide', index: part.index })}
                    style={{ pointerEvents: 'painted', cursor: 'pointer' }}
                  >
                    <circle
                      cx="600"
                      cy="180"
                      r="50"
                      fill="#0007"
                      strokeWidth="0"
                    />
                    <text x="600" y="202" fill="#0DD" fontFamily="Verdana, Geneva, sans-serif" fontSize="80" textAnchor="middle">-</text>
                  </g>
                );
                top += part.height;
                return rect;
              })
            }
          </svg>
        </div>
        <svg className={classes.configSidebar} width="250" height="800" fill="#222">
          {
            state.hidden.map((index, i) => (
              <g
                transform={`translate(0, ${i * 120})`}
                key={`${index}-${i}`}
                onClick={() => dispatch({ type: 'show', index })}
                style={{ pointerEvents: 'painted', cursor: 'pointer' }}
              >
                <g transform="scale(0.2, 0.2)" ref={previewRefs[i]}/>
                <circle
                  cx="120"
                  cy="43"
                  r="12"
                  fill="#0007"
                  strokeWidth="0"
                />
                <text x="120" y="50" fill="#0DD" fontFamily="Verdana, Geneva, sans-serif" fontSize="24" textAnchor="middle">+</text>
              </g>
            ))
          }
        </svg>
      </div>
      <button
        type="button"
        onClick={() => onExport()}
        className={cx(classes.printBtn, 'pdf')}
      >Download PDF</button>
    </div>
  );
}
SvgPreview.propTypes = {
  impRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
  ]).isRequired,
  hidden: PropTypes.bool,
  parts: PropTypes.array,
  onHide: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    SvgPreview: PropTypes.string.isRequired,
    overlay: PropTypes.string.isRequired,
    printBtn: PropTypes.string.isRequired,
    svgDoc: PropTypes.string.isRequired,
    configurator: PropTypes.string.isRequired,
    confSvg: PropTypes.string.isRequired,
    configSidebar: PropTypes.string.isRequired,
    contentWrapper: PropTypes.string.isRequired,
  }).isRequired,
  onExport: PropTypes.func.isRequired,
};
// SvgPreview = forwardRef(SvgPreview);
SvgPreview = injectSheet(sheet)(SvgPreview);

export { SvgPreview };
