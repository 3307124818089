import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import injectSheet from 'lib/sheet';
import sheet from './sheet';
import { links as r } from 'lib/routes';

class Page extends React.Component {
  static propTypes = {
    classes: PropTypes.shape({
      container: PropTypes.string,
      bold: PropTypes.string,
      middle: PropTypes.string,
      bottom: PropTypes.string,
      link: PropTypes.string,
    }).isRequired,
  };

  render() {
    const { classes: { bold, link, container, middle, bottom } } = this.props;
    return (
      <div className={container}>
        <div>
          We’ve sent you an account activation email. Go to
          <br/>
          your inbox and click the “<span className={bold}>Confirm My Email</span>”
          <br/>
          button to activate your account.
        </div>
        <div className={middle}>
          If you don’t see our email, please check your
          <br/>
          spam or promotions folder. Or, if you’ve already
          <br/>
          confirmed, just <Link to={r.auth} className={link}>Log In</Link>
        </div>
        <div className={bottom}>
          Any issues or questions? Feel free to contact us
          <br/>
          <a href="mailto:support@artbnk.com" className={link}>support@artbnk.com</a>
        </div>
      </div>
    );
  }
}

export default injectSheet(sheet)(Page);
