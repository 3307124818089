import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'lib/sheet';
import sheet from './sheet';
import FbLoginButton from 'pages/authPage/form/login/fbLoginButton';
import GoogleLoginButton from 'pages/authPage/form/login/googleLoginButton';
import InstagramLoginButton from 'pages/authPage/form/login/instagramLoginButton';

class LoginForm extends React.PureComponent {

  static propTypes = {
    classes: PropTypes.shape({
      socialButtons: PropTypes.string,
      socialButton: PropTypes.string,
      socialFormSeparator: PropTypes.string,
    }).isRequired,
  }

  render() {
    const { classes } = this.props;
    return [
      <li key="sn-buttons">
        <ul className={classes.socialButtons}>
          <li className={classes.socialButton}><FbLoginButton /></li>
          <li className={classes.socialButton}><GoogleLoginButton /></li>
          <li className={classes.socialButton}><InstagramLoginButton /></li>
        </ul>
      </li>,
      <li key="sn-buttons--form--separator" className={classes.socialFormSeparator} children="Or"/>,
    ];
  }
}

export default injectSheet(sheet)(LoginForm);
