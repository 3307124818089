export const EVENT_ITEM_DID_MOUNT = 'EVENT_ITEM_DID_MOUNT';
export const EVENT_ITEM_DID_UPDATE = 'EVENT_ITEM_DID_UPDATE';
export const EVENT_LIST_RENDER_PROPS = 'EVENT_LIST_RENDER_PROPS';
export const EVENT_PRINT_PDF = 'EVENT_PRINT_PDF';
export const EVENT_RENDER_TO_PDF = 'EVENT_RENDER_TO_PDF';
export const EVENT_APPEND_TO_PDF = 'EVENT_APPEND_TO_PDF';
export const EVENT_GET_CHART_REF = 'EVENT_GET_CHART_REF';
export const EVENT_GET_PAGE_COMPONENT = 'EVENT_GET_PAGE_COMPONENT';
export const EVENT_COMPONENT_READY = 'EVENT_COMPONENT_READY';
export const EVENT_COMPONENT_UPDATED = 'EVENT_COMPONENT_UPDATED';
export const EVENT_LOADING_STATE = 'EVENT_LOADING_STATE';
export const EVENT_PDF_GENERATED = 'EVENT_PDF_GENERATED';
export const EVENT_PREVIEW_CLOSED = 'EVENT_PREVIEW_CLOSED';

export const PAGE_WIDTH = 612;
export const PAGE_HEIGHT = 792;
export const PAGE_GAP = 30;
export const PAGE_PADDING_TOP = 50;
export const REMOVED_SIDEBAR_PADDING = 15;
export const REMOVED_ITEM_MARGIN_TOP = 45;
export const REMOVED_SIDEBAR_WIDTH = 400;
export const REMOVED_CONTENT_WIDTH = REMOVED_SIDEBAR_WIDTH - REMOVED_SIDEBAR_PADDING * 2;
export const LEFT_SIDEBAR_WIDTH = 185;
export const PDFPREVIEW_PADDING = 50;


export const PDF_FONT_NAME = 'Haas Grot Disp Web';
export const PDF_FONT_FILE_NORMAL = '/fonts/NeueHaasGrotDisp-55Roman-App.ttf';
export const PDF_FONT_FILE_MEDIUM = '/fonts/NeueHaasGrotDisp-65Medium-App.ttf';
export const PDF_FONT_FILE_BOLD = '/fonts/NeueHaasGrotDisp-75Bold-App.ttf';
export const DEFAULT_FONTFAMILY = `${PDF_FONT_NAME}, Arial, Helvetica, sans-serif`;

export const CONTENT_TYPE_HTML = 'CONTENT_TYPE_HTML';
export const CONTENT_TYPE_CUSTOM = 'CONTENT_TYPE_CUSTOM';

export const REPORT_TYPE_ARTIST = 'REPORT_TYPE_ARTIST';
export const REPORT_TYPE_ARTWORK = 'REPORT_TYPE_ARTWORK';

export const SET_ARTIST_LIQUIDITY = 'SET_ARTIST_LIQUIDITY';

export const themes = {
  pages: {
    contacts: {
      bg: '#F0F2F6',
    },
    default: {
      bg: '#FAFAFA',
    },
  },
  REPORT_TYPE_ARTIST: {
    mainColor: '#304C95',
    headerTextColor: '#304C95',
  },
  REPORT_TYPE_ARTWORK: {
    mainColor: '#E3A52C',
    headerTextColor: '#333333',
  },
};

const liquidArtworkReportItems = [
  'p001',
  'p002',
  'p009',
  'p004',
  'p011',
  'p012',
  'm006',
  'm014',
  'm015',
  'm018',
  'x001',
];
const hideIliquidArtworkReportItems = [
  'p001',
  'p002',
  'p004',
];

export const ARTWORK_REPORT_ITEMS = {
  liquid: liquidArtworkReportItems,
  illiquid: liquidArtworkReportItems.filter(chart => !hideIliquidArtworkReportItems.includes(chart)),
};

const liquidArtistReportItems = [
  'p001',
  'p002',
  'p015',
  'p016',
  'p003',
  'p005',
  'p006',
  'p007',
  'p010',
  'p012',
  'm001',
  'm003',
  'm010',
  'm012',
  'm018',
  'x001',
  'x003',
  'x002',
];

const hideIliquidArtistReportItems = [
  'p001',
  'p002',
  'p015',
  'p016',
  'p003',
];

export const ARTIST_REPORT_ITEMS = {
  liquid: liquidArtistReportItems,
  illiquid: liquidArtistReportItems.filter(chart => !hideIliquidArtistReportItems.includes(chart)),
};

export const REPORT_ITEMS = {
  REPORT_TYPE_ARTIST: ARTIST_REPORT_ITEMS,
  REPORT_TYPE_ARTWORK: ARTWORK_REPORT_ITEMS,
};

export const HIDDEN_REPORT_ITEMS = {
  REPORT_TYPE_ARTIST: hideIliquidArtistReportItems,
  REPORT_TYPE_ARTWORK: hideIliquidArtworkReportItems,
};
