import chevrone from './chevron.svg';
import { AUTOCOMPBLETE_PLACEHOLDER, DARK_BLUE_COLOR, DEFAULT_FONTFAMILY, HEADER_SEARCH_BACKGROUND } from 'theme/theme';

export default {
  Autocomplete: {
    // root
  },
  container: {
    position: 'relative',
  },
  clear: {
    position: 'absolute',
    top: 6,
    right: 10,
    lineHeight: '24px',
    fontSize: 14,
    fontWeight: 600,
    fontFamily: 'inherit',
    paddingLeft: 5,
    paddingRight: 5,
    cursor: 'pointer',
    outline: 'none',
  },
  selectWrapper: {
    position: 'relative',
    display: 'inline-block',
    width: '100%',
    '&:after': {
      content: '""',
      display: 'none',
      position: 'absolute',
      background: `url("${chevrone}") scroll no-repeat center`,
      backgroundSize: 'contain',
      width: 18,
      height: 18,
      right: 2,
      top: 0,
      bottom: 0,
      margin: 'auto',
      pointerEvents: 'none',
    },
    '&.disabled:after': {
      opacity: 0.5,
    },
  },
  autocompleteWrapper: {},
  field: {
    appearance: 'none',
    border: '0 none',
    fontSize: 14,
    fontFamily: DEFAULT_FONTFAMILY,
    color: DARK_BLUE_COLOR,
    padding: [4, 8],
    minWidth: 240,
  },

  selectField: {
    border: 'none',
    color: DARK_BLUE_COLOR,
    background: HEADER_SEARCH_BACKGROUND,
    width: 250,
    lineHeight: '34px',
    height: '36px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    paddingLeft: 10,
    paddingRight: 30,
    fontFamily: DEFAULT_FONTFAMILY,
    '&:focus': {
      outline: 'none',
    },
    '&::placeholder': {
      color: AUTOCOMPBLETE_PLACEHOLDER,
      opacity: 1,
    },
    '&.GlobalSearch': {
      minWidth: 'auto',
      width: '100%',
    },
    '@media screen and (max-width: 400px)': {
      '&.GlobalSearch': {
        fontSize: 14,
        paddingRight: 10,
      },
    },
  },
};
