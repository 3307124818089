import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import I from 'immutable';
import cx from 'classnames';
import { connect } from 'cpcs-reconnect';
import { Link, NavLink } from 'react-router-dom';
import { routeName_sel } from 'domain/router/RouterModel';
import { logOut as logOutAction } from 'domain/env/EnvActions';
import { subscriptionTypeSelector, homePageLink_sel } from 'domain/env/EnvModel';
import MENU_MOCK from 'pages/common/header/menu/__mocks__/menu';
import SIMPLE_MENU_MOCK from 'pages/common/header/menu/__mocks__/simpleMenu';
import { lnk } from 'lib/routes';
import { toggleMobileMenuAction } from 'domain/ui/UIActions';

import injectSheet from 'lib/sheet';
import sheet from './sheet';

function Item({ classes, data, routeName, modifier, subscriptionType, homePageLink }) {
  const classname = data.get('title').toLowerCase().replace(/\s+/ig, '');
  const isActive = () => {
    if (data.get('name') === routeName) return true;
    if (data.get('name') === 'rtvValues' && routeName === 'analyticsListPage') return true;
    if (data.get('children', I.List()).includes(routeName)) return true;
    return false;
  };
  return (
    <li className={cx(classes.menuItem, modifier)}>
      <NavLink
        to={data.get('name') === 'homePage' ? homePageLink : data.get('path')}
        activeClassName="active"
        className={cx(classes.menuItemLink, modifier, data.get('customClass'), subscriptionType)}
        exact={data.get('path') === '/'}
        isActive={isActive}
      >
        <span className={cx(classes.ico, classes[classname])} />
        {data.get('title')}
      </NavLink>
    </li>
  );
}

Item.propTypes = {
  classes: PropTypes.shape({
    menuItem: PropTypes.string,
    menuItemLink: PropTypes.string,
    ico: PropTypes.string,
  }).isRequired,
  data: PropTypes.instanceOf(I.Map).isRequired,
  routeName: PropTypes.string,
  modifier: PropTypes.string,
  subscriptionType: PropTypes.string,
  homePageLink: PropTypes.string,
};

const ItemThemed = compose(
  connect({
    routeName: routeName_sel,
    subscriptionType: subscriptionTypeSelector,
    homePageLink: homePageLink_sel,
  }),
  injectSheet(sheet),
)(Item);

function Menu({ classes, modifier, mobileMenu, toggleMobileMenu, logOut, homePageLink }) {
  return (
    <div
      className={cx(classes.Menu, modifier)}
    >
      {/* eslint-disable jsx-a11y/anchor-has-content */}
      <Link
        to={homePageLink}
        className={cx(classes.logo, modifier)}
      />
      {/* eslint-enable jsx-a11y/anchor-has-content */}
      <nav className={classes.nav}>
        <ul className={cx(classes.menuWrp, modifier)}>
          {
            MENU_MOCK.map(e => (
              <ItemThemed
                key={e.get('path')}
                data={e}
                modifier={modifier}
              />
            ))
          }
        </ul>
        <ul className={cx(classes.menuWrpSimple, modifier)}>
          {
            SIMPLE_MENU_MOCK.map(e => (
              <ItemThemed
                key={e.get('path')}
                data={e}
                modifier={modifier}
              />
            ))
          }
        </ul>
      </nav>
      {
        mobileMenu &&
          <ul className={classes.mobileAccountMenu}>
            <li className={classes.mobileAccountMenuItemWrp}>
              <Link
                to={lnk('accountPage')}
                className={classes.mobileAccountMenuItem}
                children="My Account"
              />
            </li>
            <li>
              <button
                type="button"
                onClick={() => logOut()}
                className={classes.mobileAccountMenuItem}
                children="Sign Out"
              />
            </li>
          </ul>
      }
      {/*
        !mobileMenu &&
          <AddArtBtn />
      */}
      {
        mobileMenu &&
          <button className={classes.closeMobileMenuBtn} onClick={() => toggleMobileMenu()} />
      }
    </div>
  );
}

Menu.propTypes = {
  classes: PropTypes.shape({
    Menu: PropTypes.string.isRequired,
    logo: PropTypes.string.isRequired,
    menuWrp: PropTypes.string.isRequired,
    menuWrpSimple: PropTypes.string.isRequired,
    nav: PropTypes.string.isRequired,
    mobileAccountMenu: PropTypes.string.isRequired,
    mobileAccountMenuItemWrp: PropTypes.string.isRequired,
    mobileAccountMenuItem: PropTypes.string.isRequired,
    closeMobileMenuBtn: PropTypes.string.isRequired,
  }).isRequired,
  modifier: PropTypes.string,
  toggleMobileMenu: PropTypes.func.isRequired,
  logOut: PropTypes.func.isRequired,
  mobileMenu: PropTypes.bool,
  homePageLink: PropTypes.string,
};

const MenuExport = compose(
  injectSheet(sheet),
  connect({
    toggleMobileMenu: toggleMobileMenuAction,
    logOut: logOutAction,
    homePageLink: homePageLink_sel,
  }),
)(Menu);

export {
  MenuExport as Menu,
  Menu as MenuTest,
  Item as ItemTest,
};
