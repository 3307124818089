import { DEFAULT_BUTTON_COLOR, DARK_BLUE_COLOR, DEFAULT_FONTFAMILY } from 'theme/theme';
import { PageHeader } from 'theme/Dashboard';
import { button } from 'theme/button';

export const sheet = {
  Header: PageHeader,
  private: {
    borderRadius: '4px',
    backgroundColor: DEFAULT_BUTTON_COLOR,
    color: '#fff',
    fontSize: '10px',
    marginLeft: '5px',
    padding: '1px 4px 3px 4px',
    fontWeight: '100',
  },
  titleWrapper: {
    overflow: 'hidden',
    '@media screen and (max-width: 610px)': {
      marginTop: 20,
      order: 2,
    },
  },
  title: {
    color: DARK_BLUE_COLOR,
    fontFamily: DEFAULT_FONTFAMILY,
    fontSize: 24,
    fontWeight: 400,
    lineHeight: '29px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  typeText: {
    '&.noEntityTitle': {
      fontWeight: 700,
    },
  },
  titleAmountText: {
    fontSize: 18,
  },
  entityTitle: {
    fontWeight: 700,
  },
  sharedBy: {
    flex: '0 0 0',
    whiteSpace: 'nowrap',
    color: DARK_BLUE_COLOR,
    fontFamily: DEFAULT_FONTFAMILY,
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '29px',
  },
  email: {
    display: 'inline-block',
    fontWeight: 600,
    lineHeight: '29px',
  },
  buttonsWrapper: {
    minWidth: 110 + 110 + 15,
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    columnGap: '15px',
  },
  button,
};
