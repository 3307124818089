import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'cpcs-reconnect';
import I from 'immutable';
import cx from 'classnames';
import { lnk } from 'lib/routes';
import { artworkListSelector, artworkPaginationSelector } from 'domain/artwork/ArtworkModel';
import { artworkAppendPageAction } from 'domain/artwork/ArtworkActions';
import { artistLiquidity_sel } from 'domain/artist/ArtistModel';

import InfiniteScroll from 'react-infinite-scroller';
import Loader from 'components/loader/Loader';
import Badge from 'pages/common/ItemList/Badges/Artwork';
import NoArts from 'pages/common/sharedItemsPage/noArts';
import PageContainer from 'pages/common/pageContainer';
import { advancedFilterOpenState_sel } from 'domain/ui/UIModel';
import Header from 'pages/artistPage/Header';

import injectSheet from 'lib/sheet';
import sheet from './sheet';

class ArtistPage extends React.Component {
  static propTypes = {
    classes: PropTypes.shape({
      artistWorks: PropTypes.string.isRequired,
    }),
    artistArtworks: PropTypes.instanceOf(I.Collection),
    fetchList: PropTypes.func.isRequired,
    pagination: PropTypes.shape({
      loading: PropTypes.bool,
      page: PropTypes.number,
      pages: PropTypes.number,
      total: PropTypes.number,
    }).isRequired,
    advancedFilterOpenState: PropTypes.string,
    artistLiquidity: PropTypes.string,
  };

  buildLink = (artwork) =>
    lnk('artworkPage', {
      artworkId: artwork.get('id'),
    });

  loadMore = (page) => {
    const { fetchList } = this.props;
    fetchList(page);
  }

  get loader() {
    const { pagination } = this.props;
    const modifier = pagination.loading && pagination.total > 0 ? 'infiniteScroll_LoadMore' : 'infiniteScroll_FirstPage';
    return <Loader key="loader" loading modifier={modifier} />;
  }

  render() {
    const { classes, artistArtworks, pagination, advancedFilterOpenState, artistLiquidity } = this.props;

    return (
      <React.Fragment>
        <Header artistLiquidity={artistLiquidity} />
        <PageContainer modifier={cx('ArtistPage', advancedFilterOpenState)}>
          <div className="PAGE_SECTION artistsWorksPage">
            <InfiniteScroll
              className={classes.artistWorks}
              loadMore={this.loadMore}
              hasMore={pagination.page < (pagination.pages - 1) || (pagination.loading && pagination.page === 0)}
              loader={this.loader}
            >
              {
                artistArtworks.map((artwork, i) => (
                  <Badge
                    key={artwork.get('id') + `${i}`}
                    linkBuilder={this.buildLink}
                    artwork={artwork}
                    // modifier="singleBadge"
                  />
                ))
              }
            </InfiniteScroll>
          </div>
          {
            !pagination.loading && artistArtworks && artistArtworks.size === 0 &&
            <NoArts />
          }
        </PageContainer>
      </React.Fragment>
    );
  }
}

export default compose(
  injectSheet(sheet),
  connect({
    artistArtworks: artworkListSelector,
    fetchList: artworkAppendPageAction,
    pagination: artworkPaginationSelector,
    advancedFilterOpenState: advancedFilterOpenState_sel,
    artistLiquidity: artistLiquidity_sel,
  }),
)(ArtistPage);
