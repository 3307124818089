import close from './images/close.svg';
import { SIDEBAR_DIVIDER_COLOR } from 'theme/theme';
import { LAYOUT_768_MAX_WIDTH } from 'theme/layout/responsiveList';

export default {
  AdvancedFilter: {
    width: 248,
    [`@media screen and (min-width: ${LAYOUT_768_MAX_WIDTH + 1}px)`]: { // 833 + 1
      height: 'calc(100% - 60px)',
      // overflowY: 'auto',
      zIndex: 'unset',
    },
    [`@media screen and (max-width: ${LAYOUT_768_MAX_WIDTH}px)`]: { // 833
      zIndex: 1,
      position: 'relative',
      backgroundColor: '#FFF',
    },
  },
  header: {
    fontSize: 18,
    fontWeight: 500,
    borderBottom: `1px solid ${SIDEBAR_DIVIDER_COLOR}`,
  },
  headerContent: {
    display: 'flex',
    justifyContent: 'space-between',
    '@media screen and (max-width: 768px)': {
      // width: 350,
      width: '100%',
      margin: '0px auto',
    },
    '@media screen and (max-width: 350px)': {
      width: '100%',
    },
  },
  close: {
    width: 34,
    height: 34,
    margin: [-6, 0, 0, 0],
    background: `url("${close}") scroll no-repeat center / 14px`,
  },
  title: {
    margin: [0, 16, 10, 6],
  },
};
