import { call, put, select, takeEvery } from 'redux-saga/effects';
import I from 'immutable';
import {
  createSuggestedArtworkAction,
  createArtworkAction,
  fetchOwnArtworksAction,
  ownArtworkAppendPageAction,
  saveDraftArtworkAction,
  updateArtworkAction,
  getOwnArtworkAction,
  setSelectedAction,
  uploadPictureAction,
} from 'domain/ownArtwork/OwnArtworkActions';
import {
  artworkList as artworkListSel,
} from 'domain/ownArtwork/OwnArtworkModel';

import { reduceCallFrequency } from 'lib/saga-helpers';
import { addNotification } from 'domain/env';
import { redirectToPage } from 'lib/redirectToPage';
import { allWorksGalleryId_sel } from 'domain/env/EnvModel';
import { ensureGalleries_apiGen } from 'domain/galleries/sagas';
import Api, { callApi, makeApiGenerator } from 'domain/api';

/**
 * @param { gId, silent = false, ...query }
 * query: artists, ...
**/
export const getGalleryArtworks_apiGen = makeApiGenerator({
  actionCreator: fetchOwnArtworksAction,
  api: Api.getGalleryArtworks,
  getSuccessData: ({ data }) => {
    return ({ payload: data });
  },
});

/**
 * @param { gId, silent = false, ...query }
 * query: artists, ...
**/
export const galleryArtworkListAppendPage_apiGen = reduceCallFrequency(makeApiGenerator({
  actionCreator: ownArtworkAppendPageAction,
  api: Api.getGalleryArtworks,
}), 2100);

/**
 * @param { artworkId }
**/
export const fetchOwnArtwork_apiGen = makeApiGenerator({
  actionCreator: getOwnArtworkAction,
  api: Api.getOwnArtwork,
});

export function* deSelectArtwork() {
  yield put(setSelectedAction(new I.Set()));
}

export function* updateListItem({ payload: { artworkId } = {} } = {}) {
  const list = yield select(artworkListSel);
  if (!list.find(v => v.id === parseInt(artworkId, 10))) {
    return;
  }
  yield call(fetchOwnArtwork_apiGen.catchError, { artworkId });
}

/**
 * export const createConsiderationOriginal_apiGen = trackingSaver({
 *   apiGenerator: Api.createConsiderationOriginal,
 *   actionCreator: A.createConsiderationAction,
 *   successTitle: 'Art Object added to tracking.',
 *   failureTitle: 'Tracking not created',
 *   link: null,
 *   catchErr: function* (err) {
 *     const { response: { data: { message } = {} } = {} } = err || {};
 *     if (!message) {
 *       return false;
 *     }
 *     if (message === 'You already add this artwork to the considerations') {
 *       yield put(addNotification({ title: 'Artwork has already been acquired', type: 'success' }));
 *       return true;
 *     }
 *     return false;
 *   },
 * });
**/
export function artworkSaver({ apiGenerator, actionCreator, successTitle, failureTitle, catchErr, fullPayload = true, link = 'collectionItemPage', noDefaultGalleryId = false }) {
  /**
   * apiProps generally { data, id } (see your api endpoint)
  **/
  return function* (apiProps) {
    try {
      const payload = yield callApi(apiGenerator, apiProps);
      yield put({
        type: actionCreator.success,
        payload: fullPayload ? payload : payload.data,
      });
      yield put(addNotification({ title: successTitle, type: 'success' }));
      if (link) {
        const authorId = (payload.data.artist || {}).id;
        let gId = (apiProps.data && apiProps.data.galleryId);
        if (!gId && !noDefaultGalleryId) {
          gId = yield select(allWorksGalleryId_sel);
        }
        const artworkId = payload.data.id;
        yield call(ensureGalleries_apiGen);
        yield redirectToPage(link, { gId, authorId, artworkId });
      }
      return payload;
    } catch (err) {
      if (catchErr) {
        try {
          if (yield call(catchErr, err)) {
            return;
          }
        } catch (err) {
          console.error(err);
        }
      }
      yield put({
        type: actionCreator.failure,
        err,
      });
      yield put(addNotification({ title: failureTitle, type: 'error' }));
    }
  };
}

export const updateArtwork_apiGen = artworkSaver({
  apiGenerator: Api.updateArtwork,
  actionCreator: updateArtworkAction,
  successTitle: 'Artwork saved.',
  failureTitle: 'changes not saved',
});

export const createArtwork_apiGen = artworkSaver({
  apiGenerator: Api.createArtwork,
  actionCreator: createArtworkAction,
  successTitle: 'Artwork added to My Collection.',
  failureTitle: 'Artwork not created',
});

export const createSuggestedArtwork_apiGen = artworkSaver({
  apiGenerator: Api.createSuggestedArtwork,
  actionCreator: createSuggestedArtworkAction,
  successTitle: 'Artwork added to My Collection.',
  failureTitle: 'Artwork not created',
});


export const createDraft_apiGen = artworkSaver({
  apiGenerator: Api.createDraftArtwork,
  actionCreator: saveDraftArtworkAction,
  successTitle: 'Artwork added to My Collection.',
  failureTitle: 'Artwork not created',
  link: 'collectionListPage',
});

export const addDraft_apiGen = artworkSaver({
  apiGenerator: Api.addDraftArtwork,
  actionCreator: saveDraftArtworkAction,
  successTitle: 'Artwork added to My Collection.',
  failureTitle: 'Artwork not created',
  link: 'collectionListPage',
});

export const updateDraft_apiGen = artworkSaver({
  apiGenerator: Api.updateDraftArtwork,
  actionCreator: saveDraftArtworkAction,
  successTitle: 'Artwork saved.',
  failureTitle: 'changes not saved',
  link: 'collectionListPage',
});

const uploadPicture_apiGen = makeApiGenerator({
  actionCreator: uploadPictureAction,
  api: Api.uploadPicture,
});

function* onUploadPicture({ payload: { resolve, reject, ...payload } }) {
  try {
    const data = yield call(uploadPicture_apiGen.catchError, payload);
    resolve(data);
  } catch (err) {
    reject(err);
  }
}

export function* watchUploadPicture() {
  yield takeEvery(uploadPictureAction.type, onUploadPicture);
}
