import {
  PAGE_HEIGHT, PAGE_GAP,
  PAGE_PADDING_TOP,
  REMOVED_ITEM_MARGIN_TOP,
  REMOVED_CONTENT_WIDTH,
} from 'pages/highcharts/helpers/PDFConstants';
import { getKey } from 'pages/highcharts/helpers/helpers';



export const placeItemsToPages = (renderedItems) => {
  // place on page
  let top = 0;
  let topFromParent = 0;
  let left = 0;
  let pageNumber = 0;
  let isFirstOnPage = true;
  // table of content
  let lastContentSection = null;
  let contentFirstPage = 0;
  let contentLastPage = 0;
  let parts = {};
  const pages = [];
  const items = {};
  const contentSections = [];
  const getItemMarginTop = item => ((item.marginTopFirst !== undefined && isFirstOnPage) ? item.marginTopFirst :
    item.marginTop) || 0;
  const countItemTop = item => (item.top === undefined ? (top + getItemMarginTop(item)) : item.top);
  const countItemLeft = item => (item.left || (left + item.marginLeft || 0));
  const addContentSection = (pageEnd) => {
    contentSections.push({
      pageStart: contentFirstPage,
      pageEnd,
      name: lastContentSection,
    });
  };
  const addNewPage = (item) => {
    // pages
    pageNumber++;
    isFirstOnPage = true;
    if (!item.contentSection) {
      console.warn('no item.contentSection', item, renderedItems);
    }
    pages.push({ contentSection: item.contentSection, page: pageNumber });
    top = PAGE_PADDING_TOP;
    topFromParent = 0;
    // contentSections
    contentLastPage = pageNumber;
    const contentSectionAlreadyAdded = contentSections.find(cs => cs.name === lastContentSection);
    if (lastContentSection !== item.contentSection && !contentSectionAlreadyAdded) {
      if (lastContentSection) {
        addContentSection(contentLastPage - 1);
      }
      lastContentSection = item.contentSection;
      contentFirstPage = pageNumber;
    }
  };
  // visible items
  renderedItems.forEach((item) => {
    if (item.hidden) return;
    if (item.parts) {
      parts[item.name] = parts[item.name] || item.parts;
    }
    let newPageRequired = item.contentSection !== lastContentSection;
    if (newPageRequired) {
      addNewPage(item);
    }
    let itemTop = (!item.parent && !item.allowTopOverlap) ? Math.max(countItemTop(item), topFromParent) : countItemTop(item);
    let itemLeft = countItemLeft(item);
    newPageRequired = !newPageRequired && !item.allowTopOverlap && (PAGE_HEIGHT < (itemTop + item.height));
    if (newPageRequired) {
      addNewPage(item);
      itemTop = countItemTop(item);
    }
    items[getKey(item)] = {
      top: (pageNumber - 1) * (PAGE_HEIGHT + PAGE_GAP) + itemTop,
      left: itemLeft,
      page: pageNumber,
      itemTop,
      itemLeft,
      width: item.width,
      height: item.height,
    };
    top = itemTop + item.height + (item.marginBottom || 0);
    if (!item.parent) {
      topFromParent = top;
    }
    if (item.useAsBg) {
      top = top - item.height;
    }
    isFirstOnPage = false;
  });
  const contentSectionAlreadyAdded = contentSections.find(cs => cs.name === lastContentSection);
  if (!contentSectionAlreadyAdded) {
    addContentSection(contentLastPage);
  }
  top = 0;
  // hidden items
  renderedItems.forEach((item) => {
    if (item.parts) {
      parts[item.name] = parts[item.name] || item.parts;
    }
    if (!item.hidden || (item.parent && !item.removable)) return;
    let itemTop = top;
    let itemLeft = 0;
    items[getKey(item)] = {
      top: itemTop,
      left: itemLeft,
      page: undefined,
      itemTop,
      itemLeft,
      width: item.width,
      height: item.height,
      hidden: true,
    };
    const scaleK = Math.min(1, REMOVED_CONTENT_WIDTH / item.width);
    top = top + (item.height * scaleK) + REMOVED_ITEM_MARGIN_TOP;
  });
  return { pages, items, contentSections, parts };
};
