import { field, formItem } from 'theme/form';
import { DARK_BLUE_COLOR, DEFAULT_FONTFAMILY, BILLING_PAGE_SUBMIT_CHANGE, BILLING_PAGE_SUBMIT_ADDRESS_FORM } from 'theme/theme';
import { formTitle } from 'theme/form/formTitle';


export const StripeStyle = {
  /**
   * more properties: color, letterSpacing, ::placeholder color
   * @see https://stripe.com/docs/stripe-js/elements/quickstart
  **/
  base: {
    fontSize: '14px',
    fontFamily: DEFAULT_FONTFAMILY,
    '::placeholder': {
    },
  },
  invalid: {
    color: '#d0021b',
  },
};

export default {
  AddressForm: {},
  formTitle,
  formItem,
  field,
  disabled: {},
  expHolder: {
    flex: '0 0 150px',
  },
  cvHolder: {
    flex: '0 0 160px',
    '@media screen and (max-width: 360px)': {
      marginTop: 12,
    },
  },
  actions: {
    textAlign: 'right',
    minHeight: 36,
    '&.hidden': {
      display: 'none',
    },
  },
  submit: {
    display: 'inline-block',
    marginTop: 12,
    padding: [7, 15],
    height: 28,
    width: 110,
    letterSpacing: 1,
    color: '#ffffff',
    fontFamily: DEFAULT_FONTFAMILY,
    fontSize: '12px',
    fontWeight: 400,
    textTransform: 'uppercase',
    backgroundColor: 'black',
    border: '1px solid black',
    marginLeft: 10,
    cursor: 'pointer',
    '&:disabled': {
      opacity: 0.5,
      cursor: 'default',
    },
    '&.change': {
      backgroundColor: '#FFF',
      color: BILLING_PAGE_SUBMIT_CHANGE,
      borderColor: BILLING_PAGE_SUBMIT_CHANGE,
    },
    '&.addressForm': {
      backgroundColor: '#FFF',
      color: BILLING_PAGE_SUBMIT_ADDRESS_FORM,
      borderColor: BILLING_PAGE_SUBMIT_ADDRESS_FORM,
    },
    '&.hidden': {
      display: 'none',
    },
  },
  cancel: {
    extend: 'submit',
    backgroundColor: 'transparent',
    border: '1px solid #FFF',
    color: DARK_BLUE_COLOR,
    fontWeight: 500,
  },
  ErrorMessage: {
    fontSize: 12,
    marginTop: 3,
    color: '#d0021b',
  },
};
