import React from 'react';
import PropTypes from 'prop-types';
import I from 'immutable';
import cx from 'classnames';
import { getMoreClassNames, NoDataExtractor, getCellValue, getRenderProps } from './helpers';

function DataTableCell(props) {
  const { columnDef, classes } = props;
  const className = cx(
    classes.td,
    getMoreClassNames(props),
  );

  const [value, err] = getCellValue(props);
  const renderProps = getRenderProps(props);

  if (typeof columnDef.get('render') === 'function') {
    return columnDef.get('render')({
      ...renderProps,
      value,
      className,
    });
  }

  if (err === NoDataExtractor) {
    throw err;
  }

  return <div
    className={className}
    children={value}
  />;
}

DataTableCell.propTypes = {
  classes: PropTypes.shape({
    td: PropTypes.string.isRequired,
  }).isRequired,
  // rowData: PropTypes.oneOfType([
  //   PropTypes.instanceOf(I.Map), PropTypes.instanceOf(I.Record)]).isRequired,
  // rowIndex: PropTypes.number.isRequired,
  // cellProps: PropTypes.object,
  columnDef: PropTypes.instanceOf(I.Map).isRequired,
};

export default DataTableCell;
