import { LAYOUT_RANGE_768_MAX } from 'theme/layout/LAYOUT';
export default {
  AddArtUnifiedPage: {
    
  },
  form: {
    display: 'flex',
    marginTop: 40,
    position: 'relative',
    [`@media screen and (max-width: ${LAYOUT_RANGE_768_MAX}px)`]: {
      // NEW_ART_PAGE
      display: 'block',
    },
  },
};
