import { action } from 'lib/actions';
import {
  ROUTER_PREVENT_LOCATION_CHANGE,
  ROUTER_ALLOW_LOCATION_CHANGE,
} from 'domain/const';

export const pathNameChangedAction = action('@@router/PATH_NAME_CHANGED_ACTION');
export const updateQueryAction = action('router/UPDATE_QUERY');
export const goBackAction = action('router/GO_BACK_OR_DEFAULT');
export const preventLocationChangeAction = action(ROUTER_PREVENT_LOCATION_CHANGE);
export const allowLocationChangeAction = action(ROUTER_ALLOW_LOCATION_CHANGE);
