import { DARK_BLUE_COLOR, DEFAULT_FONTFAMILY } from 'theme/theme';
import exclamationIco from 'pages/common/sharesTable/SharedTitle/images/exclamation-mark.svg';

export default {
  SharedTitle: {
    // root
    minWidth: 0,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
    whiteSpace: 'nowrap',
    display: 'flex',
    alignItems: 'center',
  },
  artName: {
    fontFamily: DEFAULT_FONTFAMILY,
    fontSize: '14px',
    lineHeight: '24px',
    color: DARK_BLUE_COLOR,
    fontWeight: 600,
  },
  exclamationMarkIco: {
    display: 'inline-block',
    height: 24,
    flex: '0 0 24px',
    marginLeft: 5,
    background: {
      image: `url("${exclamationIco}")`,
      repeat: 'no-repeat',
      size: [24, 24],
      position: 'left center',
      color: 'transparent',
    },
  },
};
