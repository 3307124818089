import React from 'react';

import { connectEventsContainer } from 'pages/highcharts/helpers/EventsContext';
import { RefSize } from 'pages/highcharts/helpers/refSize';
import { DEFAULT_FONTFAMILY } from 'pages/highcharts/helpers/PDFConstants';


class MockComponent extends RefSize {
  render() {
    const { item } = this.props;
    return (
      <svg width="250" height="175" ref={this.ref} data-margin-top="15" data-left="300">
        <style>
          {`
          /**
           * Note that the color of the text is set with the
           * fill property, the color property is for HTML only
          **/
          .mockComponentTitle { font: bold 18px ${DEFAULT_FONTFAMILY}; fill: #FFF; }
          .mockComponentDescr { font: 400 15px ${DEFAULT_FONTFAMILY}; fill: #FFF; }
          `}
        </style>
        <rect width="250" height="175" />
        <text x="15" y="40" className="mockComponentTitle">
          {item.title}
        </text>
        <text x="15" y="80" className="mockComponentDescr">
          {'{{'}{item.name}{'}}'}
        </text>
      </svg>
    );
  }
}
MockComponent = connectEventsContainer(MockComponent);

export const PDFComponents = {
  AFPM: require('pages/highcharts/PDFComponents/AFPM').AFPM,
  CoverPageContent: require('pages/highcharts/PDFComponents/CoverPageContent').CoverPageContent,
  SectionHeader: require('pages/highcharts/PDFComponents/SectionHeader').SectionHeader,
  tableOfContents: require('pages/highcharts/PDFComponents/TableOfContents').TableOfContents,
  AOImage: require('pages/highcharts/PDFComponents/AOImage').AOImage,
  Definition: require('pages/highcharts/PDFComponents/DefinitionPDF').DefinitionPDF,
  ArtistRankings: require('pages/highcharts/PDFComponents/ArtistRankingsPDF').ArtistRankingsPDF,
  ContactsPDF: require('pages/highcharts/PDFComponents/ContactsPDF').ContactsPDF,
  DisclaimerPDF: require('pages/highcharts/PDFComponents/DisclaimerPDF').DisclaimerPDF,
  LastCoverPDF: require('pages/highcharts/PDFComponents/LastCoverPDF').LastCoverPDF,
  AOMainPDF: require('pages/highcharts/PDFComponents/AOMainPDF').AOMainPDF,
  ComparablesPDF: require('pages/highcharts/PDFComponents/ComparablesPDF').ComparablesPDF,
  SalesHistoryPDF: require('pages/highcharts/PDFComponents/SalesHistoryPDF').SalesHistoryPDF,
  ProvenancePDF: require('pages/highcharts/PDFComponents/ProvenancePDF').ProvenancePDF,
  LiteraturePDF: require('pages/highcharts/PDFComponents/LiteraturePDF').LiteraturePDF,
  AOInfoSidebar: require('pages/highcharts/PDFComponents/AOInfoSidebar').AOInfoSidebar,
  // tableOfContents: MockComponent,
  default: MockComponent,
  ChartPDF: require('pages/highcharts/PDFComponents/ChartPDF').ChartPDF,
};
