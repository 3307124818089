import { DARK_BLUE_COLOR, TEXT_LINK_COLOR } from 'theme/theme';
import star from './star.svg';

export default {
  property: {
    position: 'relative',
  },
  item: {
    display: 'flex',
    minHeight: 23,
    marginTop: 10,
    fontSize: 14,
    color: DARK_BLUE_COLOR,
    '&:first-child': {
      marginTop: 0,
    },
    '&.ItemList': {
      marginTop: 0,
      minHeight: 21,
      '&.titleSolver': {
        margin: [8, 0, 16],
      },
      '&.sizeSolver': {
        marginBottom: 20,
      },
    },
  },
  title: {
    flex: '0 0 200px',
    '&.rtvSolver': {
      paddingTop: 13,
    },
    '&.getRtvBtn': {
      paddingTop: 3,
    },
    '@media screen and (max-width: 1200px)': {
      flex: '0 0 150px',
    },
    '@media screen and (max-width: 360px)': {
      flex: '0 0 128px',
    },
    '&.artistSolver, &.titleSolver, &.saleNumberSolver': {
      display: 'none',
    },
    '&.ItemList': {
      color: '#000000',
      flex: 'unset',
      whiteSpace: 'nowrap',
      fontSize: '13px',
      '&:after': {
        content: '": "',
        display: 'inline-block',
        marginRight: 6,
      },
      '&.rtvSolver': {
        paddingTop: 0,
      },
      '&.lotNumberSolver': {
        // display: 'none',
      },
    },
    '@media screen and (max-width: 300px)': {
      flex: 'unset',
      whiteSpace: 'nowrap',
      '&:after': {
        content: '": "',
        display: 'inline-block',
        marginRight: 6,
      },
      '&.separator:after': {
        display: 'none',
      },
    },
  },
  value: {
    flex: '1 1 320px',
    '& a': {
      color: 'inherit',
      textTransform: 'capitalize',
      '&.textLink': {
        color: TEXT_LINK_COLOR,
      },
      '&.saleNumberSolver': {
        textTransform: 'none',
      },
    },
    '&.ItemList': {
      color: '#000',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontWeight: 500,
      fontSize: '13px',
      '&.artistSolver': {
        fontWeight: 500,
        textTransform: 'uppercase',
      },
      '&.titleSolver': {
        fontWeight: 400,
        textTransform: 'capitalize',
        fontStyle: 'italic',
      },
      '&.soldPriceSolver,&.estimateSolver': {
        fontWeight: 500,
      },
      '&.dateOfSaleSolver': {
        marginBottom: 20,
      },
      '&.sellerSolver,&.dateOfSaleSolver,&.estimateSolver,&.soldPriceSolver': {
        '&.expired': {
          marginTop: 3,
          background: `url("${star}") left top no-repeat`,
        },
      },
    },
    '@media screen and (max-width: 300px)': {
      color: '#000',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  indent: {
    marginBottom: 50,
  },
  strong: {
    fontWeight: 600,
    '&.ItemList': {
      fontWeight: 400,
    },
  },
  veryStrong: {
    fontWeight: 600,
    fontSize: 16,
    '&.ItemList': {
      fontWeight: 400,
    },
  },
};
