import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'cpcs-reconnect';
import { compose } from 'redux';
import { Link } from 'react-router-dom';
import { homePageLink_sel } from 'domain/env/EnvModel';

import injectSheet from 'lib/sheet';
import styles from './sheet.js';


class Error404Page extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    homePageLink: PropTypes.string,
  };

  render() {
    const { classes, homePageLink } = this.props;
    return (
      <div
        className="PAGE_CONTAINER error404Page"
      >
        <div className="PAGE_SECTION error404Page">
          <div className={classes.content}>
            <h1
              className={classes.title}
              children="Sorry, the page you were looking for doesn’t exist"
            />
            <div className={classes.text}>
              Go back to
              {' '}
              <Link to={homePageLink} className={classes.textLink} children="Homepage" />
              {' '}
              or contact
              {' '}
              <a
                href="mailto:support@artbnk.com"
                className={classes.textLink}
                target="_blank"
                rel="noopener noreferrer"
                children="support@artbnk.com"
              />
              {' '}
              about a problem.
            </div>
          </div>{/* content */}
        </div>{/* PAGE_SECTION */}
      </div>/* PAGE_CONTAINER */
    );
  }
}

export { Error404Page };

export default compose(
  injectSheet(styles),
  connect({
    homePageLink: homePageLink_sel,
  }),
)(Error404Page);
