import { popupComponents } from 'pages/common/popup/sheet';
import { button } from 'theme/button';
import arrowBg from 'pages/common/popup/ReUploadImagePopup/images/arrow.svg';

const TITLE_HEIGHT = '49px';
const CONTAINER_PADDING = '55px';

export default {
  ...popupComponents,
  container: {
    width: '90vw',
    maxWidth: 1115,
    // height: '80vh',
    minHeight: '480px',
    ...popupComponents.container,
  },
  ReUploadForm: {
    height: `calc(100% - ${TITLE_HEIGHT} - ${CONTAINER_PADDING})`,
    // display: 'flex',
    // justifyContent: 'center',
    display: 'grid',
    // gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
    gridTemplateColumns: '1fr 103px 1fr',
    gridTemplateRows: 'minmax(250px, 460px)',
    columnGap: '10px',
    '@media screen and (max-width: 700px)': {
      display: 'block',
    },
  },
  previewImageBox: {
    border: 'dashed 2px #babcbf',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // minHeight: 460,
    height: '100%',
    '@media screen and (max-width: 700px)': {
      '&.old': {
        display: 'none',
      },
    },
  },
  arrow: {
    width: 93,
    height: 20,
    background: {
      image: `url("${arrowBg}")`,
    },
    placeSelf: 'center',
    '@media screen and (max-width: 700px)': {
      display: 'none',
    },
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 25,
    height: 30,
  },
  button,
};
