export default {
  FieldDropZone: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  zone: {
    width: '100%',
    height: '100%',
    border: 'dashed 2px #babcbf',
    position: 'relative',
  },
  preview: {
    width: '100%',
    fontSize: 0,
    '& img': {
      width: '100%',
    },
  },
};
