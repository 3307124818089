import I from 'immutable';
import { isArray } from 'lib/helpers';

export function getValue({ optionList, comboBox, input: { value } , valueNormalize = x => x }) {
  if (typeof value === 'string' && value.length === 0) return null;
  if (!optionList || optionList.size === 0) return comboBox ? value : null;
  const item = optionList.find(e => e.get('id') === value);
  if (typeof item === 'undefined') {
    return comboBox ? value : null;
  }
  return valueNormalize(item);
}

export async function fetchValue({ input: { value }, fetchData, valueNormalize }){
  if (typeof value === 'string' && value.length === 0) return null;
  try {
    const { data } = await fetchData(value);
    return valueNormalize(I.fromJS(data));
  } catch (e) {
    return null;
  }
}

export function resortListWithCommon(list, common = new I.List()) {
  return common.size === 0 ? list : common
    .map(id => list.find(i => i.get('id') === id))
    .filter(v => v)
    .concat(list.filter(v => !common.includes(v.get('id'))));
}

export function getNewHoveredId(list, id = 0, updater = 1){
  if (list.size === 0) return 0;
  if (id === 0 && updater > 0) return list.getIn([0, 'id']);
  const index = list.findIndex(v => v.get('id') === id);
  if (updater > 0) return list.size - 1 === index ? id : list.getIn([index + updater, 'id']);
  if (updater < 0) return index > 0 ? list.getIn([index + updater, 'id']) : id;
}

export function keyDown(e) {
  const { container: { show, onHide, onShow }, common = new I.List() } = this.props;
  const { hoveredId } = this.state;
  const selected = this.sortedList.find(v => v.get('id') === hoveredId);
  if (!show) {
    if (e.keyCode === 40 || e.keyCode === 38) onShow();
    return;
  }
  switch (e.keyCode) {
    case 40:
      this.setState({ hoveredId: getNewHoveredId(resortListWithCommon(this.sortedList, common), hoveredId, 1) });
      break;
    case 38:
      this.setState({ hoveredId: getNewHoveredId(resortListWithCommon(this.sortedList, common), hoveredId, -1) });
      break;
    case 13:
      e.preventDefault();
      e.stopPropagation();
      if (selected) {
        this.onSelect(selected.get('id'));
      }
      onHide(this.input, this);
      return false;
    case 27:
      onHide({ value: '' }, this);
      break;
    default:
      return;
  }
}

export function searchHandler({ target: { value } }) {
  const { container: { onShow }, onSearch } = this.props;
  this.setState({ term: value }, () => {
    onShow();
    onSearch && onSearch(value);
  });
}

export function filterList(list, term, ids = [], onlyRemovePresent = false) {
  const compare = f => onlyRemovePresent ? true : f.get('title').toLowerCase().indexOf(term.toLowerCase()) !== -1;
  if (!ids.length && !term.length) {
    return list;
  }
  return list
    .filter(f => compare(f) && !ids.map(id => id.toString()).includes(f.get('id').toString()));
}

export function idsSeparator(ids, dictionary = new I.Map()) {
  if (!isArray(ids)) throw new Error('ids must be Array');
  if (!I.Map.isMap(dictionary)) throw new Error(`dictionary mast be Immutable Map ${dictionary}`);
  return ids.reduce((A, V) => {
    const id = parseInt(V, 10);
    return dictionary.has(id) ? {
      present: A.present.concat(dictionary.get(id)),
      needs: A.needs,
    } : {
      present: A.present,
      needs: A.needs.concat(V),
    };
  }, { present: [], needs: [] });
}

const getMinPosition = (textValue, termArray) => termArray.filter(v => !!v).reduce((prev, value) => {
  const index = textValue.indexOf(value);
  return (index > -1 && index < prev) || prev === -1 ? index : prev;
}, -1);

const allPartsExist = (textValue, termArray) => termArray.filter(v => !!v).reduce((prev, value) => {
  return textValue.indexOf(value) > -1 && prev;
}, true);

const lengthSort = (prevItem, nextItem) => (prevItem.length - nextItem.length);

/**
 * ['a', 'b', 'c'].sort(fn);
 *
 * node 10.24.0
 *   args: ['a', 'b'] then ['b', 'c']
 *
 * node 12.19.1
 *   args: ['b', 'a'] then ['c', 'b']
**/
const sortFunction = (prevItem, nextItem, term) => {
  // exact match
  const exactPrevIndex = prevItem.indexOf(term);
  const exactNextIndex = nextItem.indexOf(term);
  if (exactNextIndex === -1 && exactPrevIndex === -1){
    // parts match
    const prevIndex = getMinPosition(prevItem, term.split(' '));
    const nextIndex = getMinPosition(nextItem, term.split(' '));
    if (prevIndex === nextIndex){
      // alphabetic sort
      const charSorted = [prevItem, nextItem].sort();
      // length sort if same
      return charSorted.indexOf(prevItem) - charSorted.indexOf(nextItem) || lengthSort(prevItem, nextItem);
    }
    if (prevIndex < 0) return 1;
    if (nextIndex < 0) return -1;
    return prevIndex - nextIndex;
  } else {
    if (exactNextIndex === -1) return -1;
    if (exactPrevIndex === -1) return 1;
    return exactPrevIndex - exactNextIndex || lengthSort(prevItem, nextItem);
  }
};

const filterValues = (term, valueNormalize, filter = false) => {
  if (filter){
    return (value) => {
      const textValue = valueNormalize(value).toLowerCase();
      return textValue.indexOf(term.toLowerCase()) > -1 || allPartsExist(textValue, term.toLowerCase().split(' '));
    };
  } else {
    return () => true;
  }
};

export const sortResults = (list, term = '', valueNormalize = x => x.get('title'), filter = false) => {
  if (term && list.size > 0) {
    return list
      .filter(filterValues(term, valueNormalize, filter))
      .sort((prevItem, nextItem) => {
        return sortFunction(valueNormalize(prevItem).toLowerCase(), valueNormalize(nextItem).toLowerCase(), term.toLowerCase());
      });
  } else {
    return list;
  }
};
