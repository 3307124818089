import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import injectSheet from 'lib/sheet';
import { nothing } from 'lib/helpers';
import sheet from './sheet';

const DEF_INPUT = {
  // value: undefined,
  name: 'checkbox',
  onChange: nothing,
  onBlur: nothing,
  value: false,
};

const onChangeHandler = (input, getNewValue) => () => {
  input.onChange(getNewValue(input.value));
  input.onBlur && input.onBlur();
};

const getNewValueDefault = value => !value;
export const getNewValueRadio = value => value;

const checkboxChecked = ({ input }) => !!input.value;
export const radioChecked = ({ input }) => !!input.checked;

const onKeyPress = (cb) => (event) => {
  switch (true) {
    case event.ctrlKey:
    case event.altKey:
    case event.shiftKey:
    case event.key !== 'Enter' && event.key !== ' ':
      return;
    default:
      break;
  }
  event.preventDefault();
  event.stopPropagation();
  cb();
};

function CheckBox({
  tabIndex = '0',
  type = 'checkbox',
  input = DEF_INPUT,
  classes,
  modifier,
  id,
  children,
  labelText,
  checkIsChecked = checkboxChecked,
  rootClassName = 'CheckBox',
  getNewValue = getNewValueDefault,
  disabled,
  index,
}) {
  const idName = typeof id === 'undefined' ? `id-${input.name}` : id;
  const handleOnChange = onChangeHandler(input, getNewValue);
  const checked = checkIsChecked({ input });
  const labelClassName = cx(
    classes[rootClassName],
    modifier,
    { disabled, checked, [`${rootClassName}-${index}`]: Number.isInteger(index) },
  );
  return (
    <label
      className={labelClassName}
      htmlFor={idName}
      tabIndex={tabIndex}
      onKeyPress={onKeyPress(handleOnChange)}
    >
      <input
        type={type}
        name={input.name}
        id={idName}
        value={input.value}
        onChange={handleOnChange}
        checked={checked}
        className={cx(classes.input, { checked })}
        {
          ...(typeof disabled === 'undefined' ? { } : { disabled })
        }
      />
      <div className={cx(classes.label, modifier, { checked })}>
        {children}{labelText}
      </div>
    </label>
  );
}

CheckBox.propTypes = {
  tabIndex: PropTypes.string,
  type: PropTypes.string,
  input: PropTypes.shape({
    name: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.any,
  }),
  classes: PropTypes.shape({
    input: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }),
  disabled: PropTypes.bool,
  modifier: PropTypes.string,
  rootClassName: PropTypes.string,
  id: PropTypes.string,
  children: PropTypes.node,
  labelText: PropTypes.node,
  checkIsChecked: PropTypes.func,
  getNewValue: PropTypes.func,
  index: PropTypes.number,
};

export default injectSheet(sheet)(CheckBox);
export { CheckBox as Markup };
