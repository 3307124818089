import thumbOptions from './images/thumbOptions.svg';
import HintSheet from 'pages/common/selectedItemsControls/hint/sheet';
import { DEFAULT_FONTFAMILY } from 'theme/theme';

export default {
  ThumbMenu: {
    position: 'absolute',
    width: 24,
    height: 24,
    top: 0,
    right: 0,
    outline: 'none',
    background: `url(${thumbOptions}) left top no-repeat`,
    cursor: 'pointer',
    zIndex: 1,
    '&:hover $hint': {
      display: 'block',
    },
  },

  optionsPopupMenu: {
    position: 'absolute',
    top: 25,
    left: 10,
    width: 140,
    padding: [0, 10],
    background: '#fff',
    boxShadow: '0 2px 5px 0 rgba(0, 0, 0, 0.26)',
    zIndex: 999,
  },

  optionsPopupMenuList: {
    textAlign: 'left',
  },

  optionsPopupMenuListItem: {
    margin: [10, 0],
  },

  optionsPopupMenuListItemButton: {
    fontSize: 14,
    fontFamily: DEFAULT_FONTFAMILY,
    cursor: 'pointer',
  },

  imageBox: {
    textAlign: 'center',
  },
  hint: {
    ...HintSheet.popover,
    zIndex: 1,
  },
};
