import React from 'react';
import PropTypes from 'prop-types';
import { DragLayer as DefaultDragLayer } from 'react-dnd';
import sheet from './sheet';
import injectSheet from 'lib/sheet';
import BaseLayer from './BaseLayer';
import { connect, selector } from 'cpcs-reconnect';
import * as types from 'components/dnd/types';
import { location_sel } from 'domain/router/RouterModel';
import { isTrackingLink } from 'lib/helpers';
import { artworksById, selected as selectedArtworks } from 'domain/ownArtwork/OwnArtworkModel';
import { considerationsById, selected as selectedConsiderations } from 'domain/considering';

import createMonitor from 'components/dnd/createMonitor';

const artworksList = selector(
  location_sel,
  considerationsById,
  artworksById,
  ({ match: { name = '' } = {} }, cList, aList) => isTrackingLink(name) ? cList : aList,
);

const artworksSelected = selector(
  location_sel,
  selectedConsiderations,
  selectedArtworks,
  ({ match: { name = '' } = {} }, cList, aList) => isTrackingLink(name) ? cList : aList,
);

const MockedDragLayerDecorator = (monitorToProps) => (Component) => {
  class DecoratedLayer extends React.PureComponent {
    static propTypes = {
      monitor: PropTypes.shape({
        getItemType: PropTypes.func.isRequired,
        getItem: PropTypes.func.isRequired,
        getClientOffset: PropTypes.func.isRequired,
        isDragging: PropTypes.func.isRequired,
      }),
    };

    render(){
      const { monitor = createMonitor(), ...props } = this.props;
      return <Component {...monitorToProps(monitor)} {...props} />;
    }
  }
  return DecoratedLayer;
};

const DragLayer = process.env.NODE_ENV === 'test' ? MockedDragLayerDecorator : DefaultDragLayer;

const Artwork = connect({
  list: artworksList,
  selected: artworksSelected,
})(BaseLayer);

class CustomDragLayer extends React.Component {
  static propTypes = {
    item: PropTypes.object,
    itemType: PropTypes.string,
    offset: PropTypes.shape({
      x: PropTypes.number.isRequired,
      y: PropTypes.number.isRequired,
    }),
    classes: PropTypes.shape({
      CustomDragLayer: PropTypes.string.isRequired,
      container: PropTypes.string.isRequired,
    }),
    isDragging: PropTypes.bool.isRequired,
  }

  renderItem = () => {
    const { item, itemType } = this.props;
    if (itemType === types.ARTWORK) {
      return <Artwork item={item} imagePath={['pictures', 0, 'name']} />;
    }
  }

  render() {
    const { isDragging, classes, item, offset } = this.props;
    if (isDragging && item.id && offset) {
      const style = {
        top: offset.y - 80,
        left: offset.x - 80,
      };
      return (
        <div className={classes.CustomDragLayer}>
          <div className={classes.container} style={style}>
            { this.renderItem() }
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}

export default DragLayer(monitor => ({
  item: monitor.getItem(),
  itemType: monitor.getItemType(),
  offset: monitor.getClientOffset(),
  isDragging: monitor.isDragging(),
}))(injectSheet(sheet)(CustomDragLayer));
