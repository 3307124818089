import { fork, put } from 'redux-saga/effects';
import { sagaDelay } from 'lib/envGlobals';
import { setPageLoadingAction } from 'domain/ui/UIActions';
import {
  setMaterials,
  artworkFormInit,
  fillArtworkForm,
} from 'pages/common/artworkForm/sagas/artworkForm';


export default function* addArtUnifiedPage() {
  // own
  yield put(setPageLoadingAction('addArtUnifiedPage'));
  // AO form
  yield fork(artworkFormInit);
  yield fork(setMaterials);
  // more
  /**
   * async @@redux-form/DESTROY
   * can destroy initial values of page form
   * so make delay before it mounts
  **/
  yield sagaDelay(500);
  yield put(setPageLoadingAction(''));
  yield fork(fillArtworkForm);
}
