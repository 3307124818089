import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import I from 'immutable';
import injectSheet from 'lib/sheet';
import styles from './sheet.js';
import { connect } from 'react-redux';
import { getBreadcrumbs } from 'domain/env';

class Breadcrumbs extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    items: PropTypes.instanceOf(I.List).isRequired,
    modifier: PropTypes.string,
  };

  renderTitle(item) {
    const { classes } = this.props;
    if (item.get('entity')) {
      return (
        <React.Fragment>
          {
            item.get('title') ||
              <React.Fragment>
                Back <span className={classes.to}>to </span>
              </React.Fragment>
          }
          <span
            className={classes.entity}
            children={item.get('entity')}
          />
        </React.Fragment>
      );
    }
    return item.get('title') || null;
  }

  render() {
    const { classes, items, modifier } = this.props;
    if (items.size === 0) {
      return null;
    }
    return (
      <div
        className={cx(classes.Breadcrumbs, modifier)}
      >
        {
          items.map((e, k) => (
            e.get('link') && (k < items.size - 1 || k === 0) ?
              <Link
                key={k}
                to={e.get('link')}
                className={classes.item}
                children={this.renderTitle(e)}
              />
              :
              <span
                key={k}
                className={classes.item}
                children={this.renderTitle(e)}
              />
          ))
        }
      </div>
    );
  }
}

export default connect(state => ({
  items: getBreadcrumbs(state),
}))(injectSheet(styles)(Breadcrumbs));
