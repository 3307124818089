import menuIcon from './images/ic_menu_white_24px.svg';
import {
  DARK_BLUE_COLOR,
  DEFAULT_FONTFAMILY,
  GALLERY_ITEM_TITLE,
} from 'theme/theme';
import {
  SLIDER_ITEM_WIDTH,
  SLIDER_ITEM_HEIGHT,
  SLIDER_ITEM_BG_HEIGHT,
} from 'pages/collectionListPage/artGallerySlider/sheet';

export default {
  ArtGallery: {
    cursor: 'pointer',
    width: SLIDER_ITEM_WIDTH,
    height: SLIDER_ITEM_HEIGHT,
    position: 'relative',
    display: 'block',
    '&:after': {
      content: '""',
      position: 'absolute',
      zIndex: 2,
      top: 0,
      left: 0,
      width: SLIDER_ITEM_WIDTH,
      height: SLIDER_ITEM_BG_HEIGHT,
      pointerEvents: 'none',
    },
    '&:hover': {
      '&:after': {
        opacity: 0.3,
        backgroundImage: `linear-gradient(to bottom, ${DARK_BLUE_COLOR} 0%, #FFF7F7 50%)`,
      },
    },
    '&:hover $showMenuBtn': {
      opacity: 1,
    },
  },
  linkWrap: {
    display: 'block',
    width: '100%',
    height: '100%',
    position: 'relative',
    zIndex: 2,
  },
  title: {
    color: GALLERY_ITEM_TITLE,
    opacity: 0.8,
    font: `400 16px/20px ${DEFAULT_FONTFAMILY}`,
    position: 'absolute',
    left: 10,
    top: SLIDER_ITEM_BG_HEIGHT + 10,
    width: 'calc(100% - 20px)',
    height: 20,
    overflow: 'hidden',
    '&.active': {
      fontWeight: 500,
      opacity: 1,
    },
  },
  count: {
  },
  showMenuBtn: {
    position: 'absolute',
    right: 0,
    top: 0,
    display: 'block',
    opacity: 0,
    border: 'none',
    padding: 0,
    width: 44,
    height: 44,
    zIndex: 2,
    background: {
      color: 'transparent',
      image: `url("${menuIcon}")`,
      repeat: 'no-repeat',
      size: 'auto 20px',
      position: 'center center',
    },
    '@media screen and (max-width: 1023px)': {
      opacity: 0.5,
    },
  },
  hintedBtn: {
    '&:hover $btnHint': {
      display: 'block',
    },
  },
  btnHint: {
    display: 'none',
    position: 'absolute',
    height: 22,
    left: 116,
    top: 41,
    padding: [5, 6, 4],
    fontFamily: DEFAULT_FONTFAMILY,
    fontSize: '11px',
    lineHeight: '13px',
    letterSpacing: '0.9px',
    textAlign: 'center',
    color: '#ffffff',
    opacity: 0.7,
    backgroundColor: DARK_BLUE_COLOR,
    whiteSpace: 'nowrap',
    zIndex: 2,
  },
};
