import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'cpcs-reconnect';
import I from 'immutable';
import cx from 'classnames';

import { galleryMapIndexedById_sel, totalGalleries as totalGalleries_sel } from 'domain/galleries/GalleryModel';
import { addArtworksAction } from 'domain/galleries/GalleryActions';
import { addPopupAction } from 'domain/ui/UIActions';
import { advancedFilterOpenState_sel } from 'domain/ui/UIModel';
import { setSelectedAction } from 'domain/ownArtwork/OwnArtworkActions';
import { hasSelectedDrafts } from 'domain/ownArtwork/OwnArtworkModel';
import { user_sel } from 'domain/env/EnvModel';
import {
  CREATE_GALLERY_POPUP,
  ENTITY_NAME_ARTWORK,
} from 'domain/const';

import Filters from 'pages/common/filters';

import translate from 'lib/translate';
import Dashboard from 'pages/collectionListPage/dashboard';
import PageContainer from 'pages/common/pageContainer';
import { Fragment } from 'lib/envGlobals';
import ArtGallerySlider from 'pages/collectionListPage/artGallerySlider';
import AddArtworksToGallery from 'pages/common/popup/AddArtworksToGallery';
import CreateGalleryPopup from 'pages/common/popup/createGallery';
import RenameGalleryPopup from 'pages/common/popup/renameGallery';
import SelectedBar from 'pages/collectionListPage/SelectedBar';
import SharePopup from 'pages/common/popup/share';
import ArtworkList from 'pages/collectionListPage/ArtworkList';

class CollectionListPage extends React.Component {

  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        gId: PropTypes.string,
      }),
      url: PropTypes.string,
    }).isRequired,
    totalGalleries: PropTypes.number,
    galleryById: PropTypes.instanceOf(I.Map).isRequired,
    addArtworks: PropTypes.func.isRequired,
    showCreateGalleryPopup: PropTypes.func.isRequired,
    setArt: PropTypes.func.isRequired,
    hasSelectedDrafts: PropTypes.bool,
    user: PropTypes.shape({
      defaultNewConsiderationGallery: PropTypes.number,
    }),
    advancedFilterOpenState: PropTypes.string,
  };

  get galleryId() {
    const { match: { params: { gId } = {} } = {} } = this.props;
    return parseInt(gId, 10);
  }

  get title() {
    const { galleryById } = this.props;
    const galleryId = this.galleryId;
    if (galleryId) {
      const galleryTitle = (galleryById.get(galleryId) || I.Map()).get('title');
      return galleryTitle ? `Gallery "${galleryTitle}"` : '';
    }
    return 'Art';
  }

  get sizeUnit() {
    const { totalGalleries, galleryById, user: { defaultNewConsiderationGallery } = {} } = this.props;
    const galleryId = this.galleryId;
    if (galleryId) {
      return {
        size: (galleryById.get(galleryId) || I.Map()).get('artworksCount') || 0,
        unit: +galleryId === defaultNewConsiderationGallery ? 'track' : 'artwork',
      };
    }
    return { size: totalGalleries, unit: 'gallery' };
  }

  render() {
    const {
      match,
      addArtworks,
      hasSelectedDrafts,
      advancedFilterOpenState,
    } = this.props;
    const { size, unit } = this.sizeUnit;
    return (
      <Fragment>
        <div className="PAGE_HEADER collectionListPage">
          <SelectedBar
            match={match}
            setArt={this.props.setArt}
            hasSelectedDrafts={hasSelectedDrafts}
          />
          <Dashboard
            size={size}
            title={this.title}
            unit={unit}
            onCreateGallery={this.props.showCreateGalleryPopup}
            galleryId={this.galleryId}
          />
          <ArtGallerySlider
            match={match}
            currentGalleryId={this.galleryId}
            addArtworks={addArtworks}
            useMenu
          />
          <Filters placeholder={translate(`filterLabel.${ENTITY_NAME_ARTWORK}`)} />
        </div>
        <PageContainer modifier={cx('collectionListPage', advancedFilterOpenState)}>
          <ArtworkList />
          <SharePopup />
          <RenameGalleryPopup />
          <CreateGalleryPopup />
          <AddArtworksToGallery />
        </PageContainer>
      </Fragment>
    );
  }
}

export default connect({
  totalGalleries: totalGalleries_sel,
  galleryById: galleryMapIndexedById_sel,
  hasSelectedDrafts: hasSelectedDrafts,
  addArtworks: addArtworksAction,
  showCreateGalleryPopup: () => addPopupAction({ name: CREATE_GALLERY_POPUP }),
  setArt: setSelectedAction,
  user: user_sel,
  advancedFilterOpenState: advancedFilterOpenState_sel,
})(CollectionListPage);
