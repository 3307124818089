import { BADGE_Z_INDEX } from 'theme/zIndex';

const COLUMN_WIDTH = 248;
// const COLUMN_WIDTH_MAX = 390;
const COLUMN_GAP = 30;
const COLUMN_PADDING_LEFT = 0;
// export const LAYOUT_1920_
export const LAYOUT_1920_WIDTH = 1638;
// export const LAYOUT_1920_MAX_WIDTH = 1638 + 29; // 1667
export const LAYOUT_1920_COLUMNS = 5;
// export const LAYOUT_1600_
export const LAYOUT_1600_WIDTH = 1360;
export const LAYOUT_1600_MAX_WIDTH = 1638 + 29; // 1667
export const LAYOUT_1600_COLUMNS = 4;
// export const LAYOUT_1440_
// export const LAYOUT_1440_WIDTH = 1360;
// export const LAYOUT_1440_MAX_WIDTH = 1638 + 29; // 1667
// export const LAYOUT_1440_COLUMNS = 4;
// export const LAYOUT_1024_
export const LAYOUT_1024_WIDTH = 804;
export const LAYOUT_1024_MAX_WIDTH = 1360 + 29; // 1389
export const LAYOUT_1024_COLUMNS = 2;
// export const LAYOUT_768_
export const LAYOUT_768_WIDTH = 526;
export const LAYOUT_768_MAX_WIDTH = 804 + 29; // 833
export const LAYOUT_768_COLUMNS = 2;
// export const LAYOUT_375_
export const LAYOUT_375_WIDTH = 343;
export const LAYOUT_375_MAX_WIDTH = 526 + 29; // 555
export const LAYOUT_375_COLUMNS = 1;

const responsiveList = {
  width: '100%',
  display: 'grid',
  gridTemplateColumns: `repeat(${LAYOUT_1920_COLUMNS}, minmax(${COLUMN_WIDTH}px, 1fr))`,
  columnGap: `${COLUMN_GAP}px`,
  rowGap: '40px',
  justifyContent: 'center',
  position: 'relative',
};


// '&.favoriteArtistsPage, &.otherArtistWorks, &.comparableList'
export const responsiveListFullPage = {
  ...responsiveList,
  rowGap: '0px',
  gridTemplateRows: 'min-content repeat(50, 0px)',
  gridTemplateColumns: `repeat(5, minmax(${COLUMN_WIDTH}px, 1fr))`,
  // gridTemplateColumns: `repeat(auto-fill, minmax(${COLUMN_WIDTH}px, ${COLUMN_WIDTH_MAX}px))`,
  overflowY: 'hidden',
  overflowX: 'visible',
  // fix lables cutting for favoriteArtistsPage
  // marginLeft: -20,
  width: 'calc(100% + 20px)',
  paddingLeft: 20,
  transform: 'translateX(-20px)',
  // '@media screen and (max-width: 1760px)': {},
  '@media screen and (max-width: 1360px)': {
    gridTemplateColumns: `repeat(4, minmax(${COLUMN_WIDTH}px, 1fr))`,
  },
  '@media screen and (max-width: 1200px)': {
    gridTemplateColumns: `repeat(3, minmax(${COLUMN_WIDTH}px, 1fr))`,
  },
  '@media screen and (max-width: 900px)': {
    gridTemplateColumns: `repeat(2, minmax(${COLUMN_WIDTH}px, 1fr))`,
  },
  '@media screen and (max-width: 535px)': {
    gridTemplateColumns: `repeat(1, minmax(${COLUMN_WIDTH}px, 1fr))`,
    gridTemplateRows: 'unset',
    overflow: 'unset',
    marginLeft: 'unset',
    // '&.doll': {},
  },
  '&.otherArtistWorks': {
    marginTop: 10,
  },
  '&.comparableList': {
    marginTop: 15,
  },
  '&.availableComparables': {
    gridTemplateColumns: `repeat(auto-fit, minmax(${COLUMN_WIDTH}px, 1fr))`,
    gridTemplateRows: 'unset',
  },
};

export const responsiveListTheme = {
  ...responsiveList,
  [`@media screen and (max-width: ${LAYOUT_1600_MAX_WIDTH}px)`]: {
    gridTemplateColumns: `repeat(${LAYOUT_1600_COLUMNS}, minmax(${COLUMN_WIDTH}px, 1fr))`,
  },
  [`@media screen and (max-width: ${LAYOUT_1024_MAX_WIDTH}px)`]: {
    gridTemplateColumns: `repeat(${LAYOUT_1024_COLUMNS}, minmax(${COLUMN_WIDTH}px, 1fr))`,
  },
  [`@media screen and (max-width: ${LAYOUT_768_MAX_WIDTH}px)`]: {
    gridTemplateColumns: `repeat(${LAYOUT_768_COLUMNS}, minmax(${COLUMN_WIDTH}px, 1fr))`,
  },
  [`@media screen and (max-width: ${LAYOUT_375_MAX_WIDTH}px)`]: {
    gridTemplateColumns: `repeat(${LAYOUT_375_COLUMNS}, minmax(${COLUMN_WIDTH}px, 1fr))`,
  },
};

export const responsiveListTheme_Item = {
  paddingLeft: COLUMN_PADDING_LEFT,
};

export const responsiveListTheme_Thumb = {
  width: '100%',
  height: 'auto',
  // css trick height (80%) of width (100%)
  padding: '80% 0 0',
  '&:after': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: BADGE_Z_INDEX,
  },
  '@media screen and (max-width: 374px)': {
    height: 200,
    '&:after': {
      height: 200,
    },
  },
  '@media screen and (max-width: 300px)': {
    height: 200,
    '&:after': {
      height: 200,
    },
  },
};
