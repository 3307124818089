import React from 'react';
import injectSheet from 'lib/sheet';
import { compose } from 'redux';
import { connect } from 'cpcs-reconnect';
import cx from 'classnames';
import { hidePopupAction } from 'domain/ui/UIActions';
import PropTypes from 'prop-types';
import { sheet } from './sheet';

function scrollBarWidth() {
  const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  return `${width - document.body.offsetWidth}px`;
}

class Popup extends React.PureComponent {

  static propTypes = {
    modifier: PropTypes.string,
    children: PropTypes.node,
    classes: PropTypes.shape({
      Popup: PropTypes.string,
      overlay: PropTypes.string,
      popup: PropTypes.string,
    }).isRequired,
    cancelDefault: PropTypes.func.isRequired,
    cancel: PropTypes.func,
    name: PropTypes.string,
  };

  body = null;

  componentDidMount() {
    if (process.env.NODE_ENV === 'test') return;
    this.body = document.getElementById('root');
    this.body.style.paddingRight = scrollBarWidth();
    this.body.style.overflow = 'hidden';
    global.document.addEventListener('keydown', this.handleKeyDown);
  }

  componentWillUnmount() {
    if (process.env.NODE_ENV === 'test') return;
    this.body.style.paddingRight = '';
    this.body.style.overflow = '';
    global.document.removeEventListener('keydown', this.handleKeyDown);
  }

  handleKeyDown = (event) => {
    const { ctrlKey, defaultPrevented, key, shiftKey, altKey } = event;
    if (defaultPrevented) return;
    const keyStamp = [
      ['', 'ctrl+'][ctrlKey * 1],
      ['', 'shift+'][shiftKey * 1],
      ['', 'alt+'][altKey * 1],
      key,
    ].join('');
    switch (keyStamp) {
      case 'Escape':
        this.onCancel();
        event.preventDefault();
        event.stopPropagation();
        break;
      default:
        break;
    }
  }

  onCancel = () => {
    const { cancel, cancelDefault } = this.props;
    cancel ? cancel() : cancelDefault();
  }

  render() {
    const { classes, modifier, name } = this.props;
    return (
      <div className={cx(classes.Popup, name, modifier)}>
        <button type="button" className={classes.overlay} onClick={this.onCancel} data-name="overlay-btn" />
        <div className={cx(classes.popup, modifier)}>
          {
            this.props.children
          }
        </div>
      </div>
    );
  }
}

export default compose(
  injectSheet(sheet),
  connect({
    cancelDefault: hidePopupAction,
  }),
)(Popup);
