import { ACTION_CURRENT_COLOR, FILE_ODD_BG, DEFAULT_FONTFAMILY } from 'theme/theme';
import removeButtonIco from './ic-remove.svg';

export default {
  Files: {
    width: '100%',
    margin: [40, 0, 0],
    position: 'relative',
  },
  header: {
    fontSize: '12px',
    fontWeight: 500,
    letterSpacing: '1px',
    color: '#000000',
    marginBottom: 20,
    lineHeight: '20px',
  },
  DropZone: {
    position: 'relative',
    '&.isDragActive': {
      minHeight: 200,
    },
  },
  dragOverlay: {
    backgroundColor: '#FFFFFF99',
    width: '100%',
    height: '100%',
    position: 'absolute',
    left: 0,
    top: 0,
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    // pointerEvents: 'none',
    border: '2px dashed #babcbf',
  },
  dragOverlayText: {
    font: `500 18px/24px ${DEFAULT_FONTFAMILY}`,
    color: ACTION_CURRENT_COLOR,
  },
  areaText: {
    cursor: 'pointer',
    minHeight: 60,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '2px dashed #babcbf',
    '& .p': {
      display: 'block',
      fontSize: '13px',
      color: '#babcbf',
      paddingLeft: 40,
      lineHeight: '30px',
      background: `10px center no-repeat url(${require('./ic-upload-file.svg')})`,
    },
  },
  dropAreaActive: {
    extend: 'dropArea',
    height: 'calc(100% - 40px)',
    position: 'absolute!important',
    top: 40,
    backgroundColor: '#fff',
    zIndex: 1,
    width: '100%',
  },
  bold: {
    fontWeight: 500,
  },
  browse: {
    color: ACTION_CURRENT_COLOR,
  },
  fileList: {
    borderTop: '1px solid #d8d8d8',
    marginBottom: 10,
    fontSize: 13,
    overflowY: 'auto',
    maxHeight: 241,
  },
  file: {
    lineHeight: '22px',
    padding: [13, 20],
    alignItems: 'center',
    display: 'grid',
    // 526 | 402 (1200) | 363 (900)
    // 362 = 212 + 150
    gridTemplateColumns: '1fr 155px',
    gridTemplateAreas: '"title type"',
    '&.formView': {
      gridTemplateColumns: '1fr 155px 20px',
      gridTemplateAreas: '"title type remove"',
    },
    '&:nth-child(odd)': {
      backgroundColor: FILE_ODD_BG,
    },
    '@media screen and (max-width: 446px)': {
      gridTemplateColumns: '1fr',
      gridTemplateAreas: '"title" "type"',
      '&.formView': {
        gridTemplateColumns: '1fr 20px',
        gridTemplateAreas: '"title remove" "type remove"',
      },
      lineHeight: '30px',
    },
    '@media screen and (max-width: 305px)': {
      gridTemplateColumns: '1fr',
      '&.formView': {
        gridTemplateColumns: '1fr 20px',
      },
    },
  },
  fileName: {
    color: '#0058bf',
    whiteSpace: 'nowrap',
    textAlign: 'left',
    outline: 'none',
    border: 0,
    cursor: 'pointer',
    gridArea: 'title',
    minWidth: 0,
    paddingRight: 10,
    '@media screen and (max-width: 446px)': {
      lineHeight: '30px',
    },
  },
  fileNameText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  fileExt: {
  },
  fileType: {
    gridArea: 'type',
  },
  remove: {
    gridArea: 'remove',
    minHeight: 20,
    cursor: 'pointer',
    outline: 'none',
    border: 0,
    background: `center center no-repeat url(${removeButtonIco})`,
    opacity: 0.3,
    '&:hover': {
      opacity: 0.5,
    },
  },
  popup: {
    backgroundColor: '#f7f7f7',
    padding: [30, 40],
    fontSize: 14,
    fontWeight: 400,
    width: 400,
  },
  popupSubHeader: {
    marginTop: 19,
  },
  popupFile: {
    display: 'flex',
    height: 32,
    marginBottom: 12,
    justifyContent: 'space-between',
  },
  popupFileName: {
    lineHeight: '32px',
    whiteSpace: 'nowrap',
    display: 'flex',
    minWidth: 0,
    zIndex: 1,
  },
  popupFileNameText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  popupFileExt: {
  },
  popupFileType: {
    flex: '0 0 214px',
    marginLeft: 5,
  },
  popupContent: {
    marginTop: 40,
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 20,
  },
  popupControls: {
    marginTop: 60,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  popupBtn: {
    outline: 'none',
    border: 0,
    letterSpacing: '1px',
    fontSize: 12,
    fontWeight: 600,
    padding: [0, 15],
    lineHeight: '30px',
    cursor: 'pointer',
    '&:disabled': {
      opacity: 0.5,
    },
  },
  popupUpload: {
    color: ACTION_CURRENT_COLOR,
  },
  amountLimitMessage: {
    font: `400 12px/14px ${DEFAULT_FONTFAMILY}`,
    color: '#2e343e',
    marginTop: 22,
  },
};
