import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'cpcs-reconnect';
import { compose } from 'redux';
import I from 'immutable';
import cx from 'classnames';
import { NavLink } from 'react-router-dom';
import { lnk } from 'lib/routes';
import injectSheet from 'lib/sheet';
import sheet from './sheet';
import { addDots } from 'lib/helpers';
import { RequiredParamMissingForRoute } from 'lib/routes/Route';
import SmartImage from 'components/image/SmartImage';

import { location_sel } from 'domain/router/RouterModel';
import { user_sel } from 'domain/env/EnvModel';
import { SLIDER_ITEM_WIDTH, SLIDER_ITEM_BG_HEIGHT } from 'pages/collectionListPage/artGallerySlider/sheet';

const emptyItem = I.Map({ artWorks: I.List(), title: 'All', id: '', artworksCount: 0 });

const imgParams = { width: SLIDER_ITEM_WIDTH, height: SLIDER_ITEM_BG_HEIGHT, cover: true };

class ArtGallery extends PureComponent {

  static propTypes = {
    active: PropTypes.bool.isRequired,
    classes: PropTypes.shape({
      ArtGallery: PropTypes.string.isRequired,
      linkWrap: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      count: PropTypes.string.isRequired,
      hintedBtn: PropTypes.string.isRequired,
      showMenuBtn: PropTypes.string.isRequired,
      btnHint: PropTypes.string.isRequired,
    }).isRequired,
    item: PropTypes.any.isRequired,
    toggleMenu: PropTypes.func.isRequired,
    location: PropTypes.object,
    useMenu: PropTypes.bool,
    buildGalleryLink: PropTypes.func,
    // eslint-disable-next-line react/no-unused-prop-types
    user: PropTypes.shape({
      defaultNewGallery: PropTypes.number,
      defaultNewConsiderationGallery: PropTypes.number,
      defaultNewArtWorkGallery: PropTypes.number,
    }),
    id: PropTypes.number,
  };

  static defaultProps = {
    active: false,
    toggleMenu: () => null,
    item: emptyItem,
  };

  get navLink() {
    const { buildGalleryLink, item, location: { query, match: { name } } } = this.props;
    /**
     * if user changes location using browser back button, current route name can have
     * unexpected required params, and it throws RequiredParamMissingForRoute exception
    **/
    try {
      if (buildGalleryLink) {
        return buildGalleryLink({ item, location: this.props.location });
      }
      return lnk(name, { gId: item.get('id'), query });
    } catch (e) {
      if (e instanceof RequiredParamMissingForRoute) {
        // do nothing
        // console.error(e);
      } else {
        throw e;
      }
    }
    return '/';
  }

  render() {
    const { classes, item, toggleMenu, active, useMenu, id } = this.props;
    const img = item.get('picture');
    const title = item.get('title', 'No Name');
    const titleDots = addDots(title, 21);
    return (
      <div
        className={classes.ArtGallery}
        data-id={id}
      >
        <NavLink
          to={this.navLink}
          className={classes.linkWrap}
          activeClassName="active"
        >
          <SmartImage
            src={img}
            params={imgParams}
            modifier={cx('ArtGallery', { active })}
          />
          <div
            className={cx(classes.title, { active })}
            title={title}
          >
            {titleDots}
            <span
              children={`(${item.get('artworksCount')})`}
              className={cx(classes.count, { active })}
            />
          </div>
        </NavLink>
        {
          useMenu &&
            <div className={classes.hintedBtn}>
              <button
                type="button"
                className={classes.showMenuBtn}
                onClick={toggleMenu}
              />
              <span
                className={classes.btnHint}
                children={'more options'}
              />
            </div>
        }
      </div>
    );
  }
}

export default compose(
  injectSheet(sheet),
  connect({
    location: location_sel,
    user: user_sel,
  }),
)(ArtGallery);
