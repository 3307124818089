import React from 'react';
import PropTypes from 'prop-types';
import I from 'immutable';
import cx from 'classnames';
import { connect as cpConnect } from 'cpcs-reconnect';
import { push as pushAction } from 'connected-react-router';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, reduxForm, getFormValues, change as changeFormFieldAction } from 'redux-form';
import moment from 'moment';
// import debounce from 'debounce';
import { debounce, Fragment } from 'lib/envGlobals';
import { preferredCurrency_sel } from 'domain/env/EnvModel';
import FormRowField from 'pages/common/newPage/form/elements/FormRowField';
import { location_sel, routeName_sel, advancedFilterActive_sel } from 'domain/router/RouterModel';
import {
  artistListSelector,
  auctionListSelector,
  categoryListSelector,
  substrateListSelector,
  surfaceListSelector,
  mediumsListSelector,
  // pagination
  artistListPaginationSelector,
  auctionListPaginationSelector,
  categoryListPaginationSelector,
  substrateListPaginationSelector,
  surfaceListPaginationSelector,
  mediumsListPaginationSelector,
  total_sel,
} from 'domain/advancedFilter/AdvancedFilterModel';
import Radio from 'components/form/radio';
import {
  fetchAdvancedFilterPaginationAction,
  onArtistSearchAdvancedFilterAction,
  advancedFilterFetchTotalAction,
} from 'domain/advancedFilter/AdvancedFilterActions';
import Accordion from 'pages/common/advancedFilter/form/advancedFilterAccordion';
import { fullName } from 'domain/artist/helpers';
import CheckboxList from 'components/form/checkboxList';
import Period from 'pages/common/advancedFilter/form/period';
import Range from 'pages/common/advancedFilter/form/range';
import { cleanParams, cleanEmpty, parseLocationSearch } from 'pages/common/advancedFilter/form/utils';
import { isObjectsEqual } from 'lib/helpers';
import Select from 'components/form/select';
import SIZE_LIST, { UNITS, currencySymbolMap } from 'lib/units';
import { FORM_ADVANCED_FILTER } from 'domain/const';
import 'lib/clean-code-lib';

import injectSheet from 'lib/sheet';
import sheet from './sheet';

/**
 * There is a propblem with two sources of truth: location and redux-form data in state
 * But to have ability to reuse components that used as redux-form Field and to not reinvent bicycle
 * I leaved it as is, and solve this problem using getDerivedStateFromProps
**/

const RANGE_HEIGHT = { from: 'heightFrom', to: 'heightTo' };
const RANGE_WIDTH = { from: 'widthFrom', to: 'widthTo' };
const RANGE_PRICE = { from: 'soldPriceFrom', to: 'soldPriceTo' };

const mapToArr = (hashTable) => Object.keys(hashTable || {}).reduce((collect, key) => hashTable[key] ? [...collect, key] : collect, []);
const arrToMap = (arr) => (arr || []).reduce((collect, v) => ({ ...collect, [v]: true }), {});

const artistGetLabel = item => <span>{fullName(item.get('entity'))} <span className="AF-option-amount">({item.get('count')})</span></span>;
const artistListGetNewList = ({ listValue, item, value }) => ({ ...listValue, [item.getIn(['entity', 'id'])]: value });
const artistListGetCurrent = ({ listValue, item }) => listValue[item.getIn(['entity', 'id'])];
const artistListGetId = ({ item }) => item.getIn(['entity', 'id']);

const categoryGetLabel = item => <span>{item.getIn(['entity', 'title'])} <span className="AF-option-amount">({item.get('count')})</span></span>;

const unselectedCategoryOption = I.fromJS({ entity: { title: 'All', id: '' } });

const auctionGetLabel = item => <span>{item.getIn(['entity', 'title'])} <span className="AF-option-amount">({item.get('count')})</span></span>;
const auctionListGetNewList = ({ listValue, item, value }) => ({ ...listValue, [item.getIn(['entity', 'id'])]: value });
const auctionListGetCurrent = ({ listValue, item }) => listValue[item.getIn(['entity', 'id'])];
const auctionListGetId = ({ item }) => item.getIn(['entity', 'id']);


const onCategoryCheck = ({ item }) => ({ [item.getIn(['entity', 'id'])]: true });
const categoryGetCurrentValue = ({ item }) => item.getIn(['entity', 'id']);
const addCategoryInputChecked = ({ listValue, item }) => listValue[item.getIn(['entity', 'id'])];


function isNotEqualLocationAndFormState({ props, data }) {
  const { location } = props;
  const locationProps = parseLocationSearch(location.search);
  const formProps = cleanEmpty(data);
  return !isObjectsEqual(locationProps, formProps, 'sizeUnit');
}

function checkCategoryByMediums(data, categoryList) {
  const { categories = [], surfaces = [], substrates = [], mediums = [] } = data;
  let categoryLike = null;
  if ((surfaces && surfaces.length) || (substrates && substrates.length)) {
    categoryLike = '2D';
  } else if (mediums && mediums.length) {
    categoryLike = '3D';
  } else {
    categoryLike = null;
  }
  if (categoryLike && (!categories || !categories.length)) {
    if (!categoryList || !categoryList.size) return data;
    const category = categoryList.find((o) => o.getIn(['entity', 'title']).search(categoryLike) > -1);
    if (category) {
      return { ...data, categories: [ category.getIn(['entity', 'id']) ] };
    }
  }
  return data;
}

function submit(data, dispatch, props) {
  const { push, location, categoryList } = props;
  data = checkCategoryByMediums(data, categoryList);
  if (isNotEqualLocationAndFormState({ props, data })) {
    push(`${location.pathname}?${cleanParams({ ...data })}`);
  }
}

class AdvancedFilterForm extends React.Component {
  static propTypes = {
    artistList: PropTypes.instanceOf(I.Collection).isRequired,
    auctionList: PropTypes.instanceOf(I.Collection).isRequired,
    categoryList: PropTypes.instanceOf(I.Collection).isRequired,
    substrateList: PropTypes.instanceOf(I.Collection).isRequired,
    surfaceList: PropTypes.instanceOf(I.Collection).isRequired,
    mediumsList: PropTypes.instanceOf(I.Collection).isRequired,
    artistListPagination: PropTypes.instanceOf(I.Record),
    auctionListPagination: PropTypes.instanceOf(I.Record),
    categoryListPagination: PropTypes.instanceOf(I.Record),
    substrateListPagination: PropTypes.instanceOf(I.Record),
    surfaceListPagination: PropTypes.instanceOf(I.Record),
    mediumsListPagination: PropTypes.instanceOf(I.Record),
    handleSubmit: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    initialize: PropTypes.func.isRequired,
    data: PropTypes.object,
    classes: PropTypes.shape({
      showMore: PropTypes.string,
      btnGroup: PropTypes.string,
      btnReset: PropTypes.string,
      AdvancedFilterForm: PropTypes.string.isRequired,
      Range: PropTypes.string.isRequired,
      rangeWrapper: PropTypes.string.isRequired,
      showResultsBox: PropTypes.string.isRequired,
      button: PropTypes.string.isRequired,
    }).isRequired,
    showMore: PropTypes.func.isRequired,
    onArtistSearch: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired,
    routeName: PropTypes.string,
    changeFormField: PropTypes.func.isRequired,
    currency: PropTypes.string,
    active: PropTypes.bool,
    total: PropTypes.number,
    fetchTotal: PropTypes.func.isRequired,
  };
  static defaultProps = {
    data: { sizeUnit: UNITS.INCH.id },
  };
  static getDerivedStateFromProps(props, state) {
    const { data } = props;
    let ret = null;
    let reInitForm = false;
    if (state.prevPathname !== props.location.pathname) {
      reInitForm = true;
      ret = { ...(ret || {}), prevPathname: props.location.pathname };
    }
    if (state.prevLocationSearch !== props.location.search) {
      reInitForm = true;
      ret = { ...(ret || {}), prevLocationSearch: props.location.search };
    }
    if (reInitForm && isNotEqualLocationAndFormState({ props, data })) {
      // location doesn't always have default form params, like sizeUnit, and doesn't have artistName
      const { sizeUnit, artistName } = data;
      const newData = { sizeUnit, artistName, ...parseLocationSearch(props.location.search) };
      if (!newData.categories || !newData.categories.length) {
        newData.categories = [''];
      }
      props.initialize(newData);
    } else if (reInitForm) {
      props.fetchTotal();
    }
    return ret;
  }
  state = {
    prevLocationSearch: null,
    prevPathname: null,
    formChanged: false,
  };
  categoryChanged = false;
  onSubmit = debounce(this.props.handleSubmit(submit), 500);
  onFilterChange = () => {
    if (!this.state.formChanged) {
      this.setState({ formChanged: true });
    }
  };
  onReset = () => {
    const { change, location, push } = this.props;
    change('artistName', '');
    push(location.pathname);
  }
  onArtistSearch = debounce(({ target: { value } }) => {
    this.props.onArtistSearch(value);
  }, 300);
  showMoreButton(pagination = {}) {
    const { classes, data: { artistName } } = this.props;
    let payload = null;
    switch (pagination) {
      case this.props.artistListPagination:
        payload = { filterName: 'artists', artistName };
        break;
      case this.props.auctionListPagination:
        payload = { filterName: 'auctions' };
        break;
      case this.props.categoryListPagination:
        payload = { filterName: 'categories' };
        break;
      case this.props.substrateListPagination:
        payload = { filterName: 'substrates' };
        break;
      case this.props.surfaceListPagination:
        payload = { filterName: 'surfaces' };
        break;
      case this.props.mediumsListPagination:
        payload = { filterName: 'mediums' };
        break;
      default:
        break;
    }
    return ((pagination.page + 1) < pagination.pages && payload) &&
      <button
        type="button"
        children="+ Show more"
        className={classes.showMore}
        onClick={() => this.props.showMore({ ...payload, page: pagination.page + 1 })}
      />;
  }

  get showArtist() {
    const { routeName } = this.props;
    switch (routeName) {
      case 'artistPage':
        return false;
      default:
        return true;
    }
  }

  get showActionHouse() {
    const { routeName } = this.props;
    return routeName !== 'saleLotsListPage';
  }

  categoryLike(nD) {
    const { data, categoryList } = this.props;
    if (!categoryList || categoryList.size === 0) return false;
    let categoryId = categoryList.first() ? categoryList.first().getIn(['entity', 'id']) : null;
    if (data && data.categories && data.categories.length) {
      categoryId = data.categories[0];
    }
    if (!categoryId) return false;
    const category = categoryList
      .map(v => v.get('entity'))
      .find(item => item.get('id') === parseInt(categoryId, 10));
    if (!category) return false;
    return (category.get('title') || '').includes(nD);
  }

  noCategorySelected() {
    const { data } = this.props;
    return (!data || !data.categories || !data.categories.join(''));
  }

  onCategoryChange = (val) => {
    this.categoryChanged = true;
    const { categoryList, changeFormField } = this.props;
    const category = categoryList.find(v => val[v.getIn(['entity', 'id'])]);
    if (category) {
      const is2d = (category.getIn(['entity', 'title']) || '').includes('2D');
      if (is2d) {
        changeFormField(FORM_ADVANCED_FILTER, 'mediums', null);
      } else {
        changeFormField(FORM_ADVANCED_FILTER, 'surfaces', null);
        changeFormField(FORM_ADVANCED_FILTER, 'substrates', null);
      }
    }
    this.onFilterChange();
  };

  render() {
    const {
      classes, artistList, auctionList, categoryList, substrateList, surfaceList, mediumsList,
      // pagination
      artistListPagination, auctionListPagination, categoryListPagination, substrateListPagination,
      surfaceListPagination, mediumsListPagination,
      currency, active, total,
    } = this.props;
    const currencyPlaceholder = currencySymbolMap[currency];
    const formHasUnsavedChanges = isNotEqualLocationAndFormState({ props: this.props, data: this.props.data });
    return (
      <Fragment>
        <form onSubmit={this.onFilterChange} className={classes.AdvancedFilterForm}>
          <div className={classes.btnGroup}>
            <button
              type="reset"
              onClick={this.onReset}
              className={cx(classes.button, 'advancedFilter', 'reset')}
              children="Clear All"
              disabled={!active}
            />
          </div>
          <Accordion title="Artwork Title" defaultOpened modifier="artTitle">
            <Field
              name="artTitle"
              component={FormRowField}
              onChange={this.onFilterChange}
              modifier="advancedFilter-artTitle"
              placeholder="Type title"
              type="text"
              rootTag="div"
            />
          </Accordion>
          {
            this.showArtist &&
              <Accordion title="Artist" amount={artistListPagination && artistListPagination.total} defaultOpened>
                <Field
                  name="artistName"
                  component={FormRowField}
                  onChange={this.onArtistSearch}
                  type="text"
                  rootTag="div"
                  placeholder="Type artist name"
                />
                <Field
                  name="artists"
                  component={CheckboxList}
                  modifier="advancedFilter advancedFilter-artists"
                  list={artistList}
                  parse={mapToArr}
                  format={arrToMap}
                  getLabel={artistGetLabel}
                  getNewListValue={artistListGetNewList}
                  getCurrentValue={artistListGetCurrent}
                  getId={artistListGetId}
                  onChange={this.onFilterChange}
                />
                {this.showMoreButton(artistListPagination)}
              </Accordion>
          }
          {
            this.showActionHouse &&
              <Accordion title="Auction House" amount={auctionListPagination && auctionListPagination.total} defaultOpened>
                <Field
                  name="auctions"
                  component={CheckboxList}
                  modifier="advancedFilter"
                  list={auctionList}
                  parse={mapToArr}
                  format={arrToMap}
                  getLabel={auctionGetLabel}
                  getNewListValue={auctionListGetNewList}
                  getCurrentValue={auctionListGetCurrent}
                  getId={auctionListGetId}
                  onChange={this.onFilterChange}
                />
                {this.showMoreButton(auctionListPagination)}
              </Accordion>
          }
          <Accordion title="Sale Date">
            <Period
              onChange={this.onFilterChange}
              period={{ from: 'auctionDateFrom', to: 'auctionDateTo' }}
              minLabel="left limit"
              normalize={v => v ? moment(v, 'MM/DD/YYYY').format('YYYY-MM-DD'): ''}
              format={v => v ? moment(v, 'YYYY-MM-DD').format('MM/DD/YYYY'): ''}
              validateMask="YYYY-MM-DD"
            />
          </Accordion>
          <Accordion title="Year Created">
            <Period
              onChange={this.onFilterChange}
              period={{ from: 'periodFrom', to: 'periodTo' }}
              minLabel="left limit"
              placeholder="YYYY"
              mask={[/\d/, /\d/, /\d/, /\d/]}
              validateMask="YYYY"
              momentMask="YYYY"
              momentParseMasks={['YYYY']}
              maxDetail="decade"
            />
          </Accordion>
          <Accordion title="Sold Price">
            <Range
              range={RANGE_PRICE}
              modifier="sold-price first"
              onChange={this.onFilterChange}
              allowDecimal={currency === 'BTC'}
              placeholderMin={currencyPlaceholder}
              placeholderMax={currencyPlaceholder}
            />
          </Accordion>
          <Accordion
            title="Medium"
            defaultOpened
            modifier={cx('category', { is2D: this.categoryLike('2D') })}
          >
            <Field
              name="categories"
              modifier="advancedFilter categories"
              component={CheckboxList}
              ItemComponent={Radio}
              list={categoryList}
              parse={mapToArr}
              format={arrToMap}
              getLabel={categoryGetLabel}
              getNewListValue={onCategoryCheck}
              getCurrentValue={categoryGetCurrentValue}
              getId={categoryGetCurrentValue}
              addInputChecked={addCategoryInputChecked}
              unselectedOption={unselectedCategoryOption}
              onChange={this.onCategoryChange}
            />
            {this.showMoreButton(categoryListPagination)}
            {
              (this.categoryLike('3D') || this.noCategorySelected()) &&
                <Fragment>
                  <Field
                    name="mediums"
                    modifier="advancedFilter mediums"
                    component={CheckboxList}
                    list={mediumsList}
                    parse={mapToArr}
                    format={arrToMap}
                    getLabel={auctionGetLabel}
                    getNewListValue={auctionListGetNewList}
                    getCurrentValue={auctionListGetCurrent}
                    getId={auctionListGetId}
                    onChange={this.onFilterChange}
                  />
                  {this.showMoreButton(mediumsListPagination)}
                </Fragment>
            }
            {/* Substrate */}
            {
              (this.categoryLike('2D') || this.noCategorySelected()) &&
                <Accordion
                  title="Substrate"
                  amount={substrateListPagination && substrateListPagination.total}
                  defaultOpened
                  hideToggleButton
                  modifier="substrate"
                >
                  <Field
                    name="substrates"
                    modifier="advancedFilter substrate"
                    component={CheckboxList}
                    list={substrateList}
                    parse={mapToArr}
                    format={arrToMap}
                    getLabel={auctionGetLabel}
                    getNewListValue={auctionListGetNewList}
                    getCurrentValue={auctionListGetCurrent}
                    getId={auctionListGetId}
                    onChange={this.onFilterChange}
                  />
                  {this.showMoreButton(substrateListPagination)}
                </Accordion>
            }
            {/* Surface */}
            {
              (this.categoryLike('2D') || this.noCategorySelected()) &&
                <Accordion
                  title="Surface"
                  amount={surfaceListPagination && surfaceListPagination.total}
                  defaultOpened
                  hideToggleButton
                  modifier="surface"
                >
                  <Field
                    name="surfaces"
                    modifier="advancedFilter surface"
                    component={CheckboxList}
                    list={surfaceList}
                    parse={mapToArr}
                    format={arrToMap}
                    getLabel={auctionGetLabel}
                    getNewListValue={auctionListGetNewList}
                    getCurrentValue={auctionListGetCurrent}
                    getId={auctionListGetId}
                    onChange={this.onFilterChange}
                  />
                  {this.showMoreButton(surfaceListPagination)}
                </Accordion>
            }
          </Accordion>
          {/* Medium * /}
          {
            this.categoryLike('3D') &&
              <Accordion
                title="Medium"
                amount={mediumsListPagination && mediumsListPagination.total}
                defaultOpened={this.categoryChanged}
              >
              </Accordion>
          }
          {/* / Medium */}
          <Accordion title="Size" modifier="last">
            <Range
              range={RANGE_HEIGHT}
              label="Height"
              modifier="first"
              onChange={this.onFilterChange}
            />
            <Range
              range={RANGE_WIDTH}
              label="Width"
              onChange={this.onFilterChange}
            />
            <div className={cx(classes.Range, 'sizeUnitField')}>
              <div className={cx(classes.rangeWrapper, 'sizeUnitField')}>
                <Field
                  name="sizeUnit"
                  component={FormRowField}
                  Field={Select}
                  list={SIZE_LIST}
                  onChange={this.onFilterChange}
                  isClearable={false}
                  isSearchable={false}
                  labelLayout
                  label="Unit"
                  modifier="advancedFilter advancedFilter-Range sizeUnitField"
                />
              </div>
            </div>
          </Accordion>
        </form>
        <div className={classes.showResultsBox}>
          <button
            type="button"
            onClick={this.onSubmit}
            className={cx(classes.button, 'advancedFiltersShowResults', 'darkTheme')}
            disabled={!formHasUnsavedChanges}
          >
            {total ? `Show ${total}` : 'No'} Results
          </button>
        </div>
      </Fragment>
    );
  }
}

export default compose(
  injectSheet(sheet),
  connect((state) => ({
    data: getFormValues(FORM_ADVANCED_FILTER)(state),
  })),
  cpConnect({
    artistList: artistListSelector,
    auctionList: auctionListSelector,
    categoryList: categoryListSelector,
    substrateList: substrateListSelector,
    surfaceList: surfaceListSelector,
    mediumsList: mediumsListSelector,
    // pagination
    artistListPagination: artistListPaginationSelector,
    auctionListPagination: auctionListPaginationSelector,
    categoryListPagination: categoryListPaginationSelector,
    substrateListPagination: substrateListPaginationSelector,
    surfaceListPagination: surfaceListPaginationSelector,
    mediumsListPagination: mediumsListPaginationSelector,
    showMore: fetchAdvancedFilterPaginationAction,
    onArtistSearch: onArtistSearchAdvancedFilterAction,
    routeName: routeName_sel,
    changeFormField: changeFormFieldAction,
    currency: preferredCurrency_sel,
    location: location_sel,
    push: pushAction,
    active: advancedFilterActive_sel,
    total: total_sel,
    fetchTotal: advancedFilterFetchTotalAction,
  }),
  reduxForm({
    form: FORM_ADVANCED_FILTER,
    initialValues: { sizeUnit: UNITS.INCH.id, categories: [''] },
  }),
)(AdvancedFilterForm);
