import I from 'immutable';
import { lnk } from 'lib/routes';

export default I.fromJS([
  {
    path: lnk('intelligencePage'),
    name: 'intelligencePage',
    title: 'Intelligence',
    customClass: 'insightPage',
  },
  {
    path: lnk('homePage'),
    name: 'homePage',
    title: 'Auction Alerts',
    children: ['newAlertFromHP', 'lotPage', 'artistPage'],
    customClass: 'auctionAlerts',
  },
  {
    path: lnk('addArtUnifiedPage'),
    name: 'addArtUnifiedPage',
    title: '+ Value your art',
  },
  {
    path: lnk('rtvValues'),
    name: 'rtvValues',
    title: 'ARTBnk Reports',
  },
  {
    path: lnk('collectionListPage'),
    name: 'collectionListPage',
    title: 'My Art',
    children: ['collectionItemPage', 'artEdit', 'artNew'],
  },
  // {
  //   path: lnk('shares'),
  //   name: 'shares',
  //   title: 'Share',
  //   children: ['myShares', 'sharedWithMe', 'sharedItemsPage', 'sharedGallery', 'sharedArtwork'],
  // },
]);
