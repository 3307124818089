import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';

import { splitTextToLines } from 'pages/highcharts/helpers/Text';
import { connectEventsContainer } from 'pages/highcharts/helpers/EventsContext';
import { RefSize } from 'pages/highcharts/helpers/refSize';
import { DEFAULT_FONTFAMILY } from 'pages/highcharts/helpers/PDFConstants';

const BASE_HEIGHT = 40;
const LINE_HEIGTH = 18;
const TEXT_TOP = BASE_HEIGHT + 15;
const LINE_LEGNTH = 97;

const LINES_PER_PAGE = 35;

class LiteraturePDF extends RefSize {
  static propTypes = {
    PDFState: PropTypes.shape({
    }).isRequired,
    part: PropTypes.number,
  };

  static defaultProps = {
    part: 0,
  };

  render() {
    const { artwork, part } = this.props;
    const linesAll = splitTextToLines(artwork.literature, LINE_LEGNTH);
    const lines = linesAll.slice(part * LINES_PER_PAGE, (part + 1) * LINES_PER_PAGE);
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="350"
        height={TEXT_TOP + lines.length * LINE_HEIGTH}
        ref={this.ref}
        data-margin-top="32"
        data-left="240"
        data-parts={Math.ceil(linesAll.length / LINES_PER_PAGE)}
        data-part={part}
      >
        <style>
          {`
          .LiteraturePDF_title { font: 700 14px ${DEFAULT_FONTFAMILY}; fill: #454A5F; }
          .LiteraturePDF_text { font: 400 9px ${DEFAULT_FONTFAMILY}; fill: #313545; }
          `}
        </style>
        <g>
          <text y="15" x="0" className="LiteraturePDF_title">
            Literature
          </text>
          {
            lines.map((line, i) => (
              <text y={TEXT_TOP + (LINE_HEIGTH * i)} x="0" className="LiteraturePDF_text" key={i}>
                {line}
              </text>
            ))
          }
        </g>
      </svg>
    );
  }
}

LiteraturePDF = compose(
  connectEventsContainer,
)(LiteraturePDF);

export { LiteraturePDF };
