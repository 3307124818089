export default {
  Period: {
  },
  rangeWrapper: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  separator: {
    margin: [16, 10, 0],
    flex: '0 0 7px',
  },
  errorMessage: {
    flex: '0 0 100%',
  },
};
