import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import I from 'immutable';
import { connect } from 'cpcs-reconnect';
import { compose } from 'redux';

import { dictionariesById } from 'domain/dictionary';
import { categoryList_sel } from 'domain/category/CategoryModel';
import { isEdition } from 'pages/common/newPage/form/artObject/helpers';

import injectSheet from 'lib/sheet';
import sheet from './sheet';

const isEmpty = v => v && v.title ? ![v.val].join('') : ![v].join('');

const getVal = ({ name, solver }, data, props) => {
  if (typeof solver !== 'function') {
    throw new Error(`solver "${name}" is not a function`);
  }
  return solver(data.get(name), data, name, props);
};

const getAdditionalClasses = ( classes, { classNames = [] }) => classNames
  .reduce((prev, key) => classes[key] ? { [classes[key]]: true, ...prev } : prev, {});

const valModifier = (val) => (val && val.modifier) || undefined;

function Property({ classes, data, serializer, categoryList, props, modifier, ...dictionaries }) {
  let val;
  const IS_EDITION = isEdition({ categoryList, category: data.get('category') });
  return (
    <div className={classes.property}>
      {
        serializer.map(e => (
          val = getVal(e, data, { ...props, ...dictionaries, isEdition: IS_EDITION, modifier }), // eslint-disable-line no-sequences
          isEmpty(val) ?
            null :
            <dl key={e.name} className={cx(classes.item, getAdditionalClasses(classes, e), modifier, valModifier(val), valModifier(e))} data-name={e.name}>
              <dt className={cx(classes.title, modifier, valModifier(val), valModifier(e))}>{(val && val.title) || e.title}</dt>
              <dd className={cx(classes.value, modifier, valModifier(val), valModifier(e))}>{(val && (val.title || val.custom)) ? val.val : val }</dd>
            </dl>
        ))
      }
    </div>
  );
}

Property.propTypes = {
  classes: PropTypes.shape({
    title: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    property: PropTypes.string.isRequired,
    item: PropTypes.string.isRequired,
  }).isRequired,
  data: PropTypes.any.isRequired,
  serializer: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      title: PropTypes.string,
      solver: PropTypes.func,
    }),
  ).isRequired,
  categoryList: PropTypes.instanceOf(I.Collection).isRequired,
  props: PropTypes.object,
  modifier: PropTypes.string,
};

export default compose(
  injectSheet(sheet),
  connect({
    categoryList: categoryList_sel,
    ...dictionariesById,
  }),
)(Property);
