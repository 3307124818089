import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Field, FieldArray } from 'redux-form';
import Textarea from 'components/form/field/nodes/Textarea';
import { getId } from 'pages/common/newPage/form/utils';
import sheet from './sheet';
import injectSheet from 'lib/sheet';

function NotesFields({ fields, classes, modifier }) {
  const notEmpty = (index) => fields.get(index).length > 0;
  const add = (index) => {
    if (fields.length < 10 && notEmpty(index)) fields.push('');
  };
  const remove = (index) => fields.remove(index);
  return (
    <div className={cx(classes.fieldWrapper, modifier)}>
      {
        fields.map((item, index) => (
          <div key={`notes-${index}`} className={classes.noteFieldWrapper}>
            <Field
              type="text"
              name={`notes.${index}`}
              component={Textarea}
              modifier={modifier}
              placeholder="Type notes here…"
              maxLength="1024"
            />
            <div className={classes.buttonContainer}>
              {
                fields.length > 1 &&
                  <button
                    type="button"
                    onClick={() => remove(index)}
                    className={cx(classes.button, classes.remove)}
                    title="remove note"
                    children=" "
                  />
              }
              {
                (index === fields.length - 1 && fields.length < 10) &&
                  <button
                    type="button"
                    onClick={() => add(index)}
                    className={cx(classes.button, classes.add)}
                    title="add note"
                    children=" "
                  />
              }
            </div>
          </div>
        ))
      }
    </div>
  );
}

NotesFields.propTypes = {
  classes: PropTypes.shape({
    fieldWrapper: PropTypes.string,
    noteFieldWrapper: PropTypes.string,
    buttonContainer: PropTypes.string,
    add: PropTypes.string,
    remove: PropTypes.string,
    button: PropTypes.string,
  }).isRequired,
  modifier: PropTypes.string,
  fields: PropTypes.shape({
    map: PropTypes.func,
    get: PropTypes.func,
    length: PropTypes.number,
    push: PropTypes.func,
    remove: PropTypes.func,
  }).isRequired,
};

class Notes extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.shape({
      formItem: PropTypes.string,
      label: PropTypes.string,
    }).isRequired,
    modifier: PropTypes.string,
  }
  render() {
    const name = 'notes';
    const { classes, modifier } = this.props;
    const id = getId(name);
    return (
      <li className={cx(classes.formItem, modifier)}>
        <label
          htmlFor={id}
          className={cx(classes.label, modifier)}
        >Notes</label>
        <FieldArray
          name={name}
          component={NotesFields}
          props={{ classes, modifier }}
        />
      </li>
    );
  }
}

export default injectSheet(sheet)(Notes);
