import React from 'react';
import PropTypes from 'prop-types';

export const splitTextToLines = (text, length) => {
  const words = (text || '').split(' ').filter(Boolean);
  let line = '';
  const lines = [];
  words.forEach((word) => {
    if (word.length + 1 > length) {
      // push to long word
      if (line) {
        lines.push(line);
        line = '';
      }
      lines.push(word);
    } else if (line.length + word.length + 1 < length) {
      // add word to line
      line += (line ? ' ' : '') + word;
    } else {
      // push current line, line = word
      lines.push(line);
      line = word;
    }
  });
  if (line) {
    lines.push(line);
  }
  return lines;
};

export function Text({ text, length, className, lineHeight, left = 0, top = 0, lines, props = {} }) {
  if (!lines) {
    lines = splitTextToLines(text, length);
  }
  return (
    <g transform={`translate(${left}, ${top})`}>
      {
        lines.map((str, index) => (
          <text y={lineHeight * index} className={className} key={index} {...props}>
            {str}
          </text>
        ))
      }
    </g>
  );
}

Text.propTypes = {
  text: PropTypes.string,
  length: PropTypes.oneOfType([PropTypes.number.isRequired, PropTypes.string.isRequired]),
  className: PropTypes.string.isRequired,
  lineHeight: PropTypes.oneOfType([PropTypes.number.isRequired, PropTypes.string.isRequired]).isRequired,
  left: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  top: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  lines: PropTypes.arrayOf(PropTypes.string),
  props: PropTypes.shape({
    alignmentBaseline: PropTypes.oneOf(['middle', 'baseline', 'top', 'center', 'bottom']),
    textAnchor: PropTypes.oneOf(['start', 'middle', 'end']),
  }),
};

export function renderText({ text, length, ...props }) {
  const lines = splitTextToLines(text, length);
  return {
    lines,
    content: <Text {...props} lines={lines} />,
  };
}
