import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import cx from 'classnames';

import { imgUrl } from 'lib/helpers';
import { connectEventsContainer } from 'pages/highcharts/helpers/EventsContext';
import { EVENT_GET_PAGE_COMPONENT } from 'pages/highcharts/helpers/PDFConstants';

import injectSheet from 'lib/sheet';
import styles from './sheet';

const deprecated = (props, propName, componentName) => {
  if (!props[propName]) return;
  throw new Error(`\`${propName}\` is deprecated in \`${componentName}\` but its value is \`${props[propName]}\``);
};

export const fitImage = (e, { maxWidth, maxHeight }) => {
  const { naturalHeight, naturalWidth } = e.target;
  const wGain = naturalWidth / maxWidth;
  const hGain = naturalHeight / maxHeight;
  let width = 'auto';
  let height = 'auto';
  if (hGain >= wGain && hGain > 1) {
    height = maxHeight;
  } else if (wGain > 1) {
    width = maxWidth;
  }
  return { width, height };
};

const imageLink = (props, state) => {
  const { params, thumbSize, src } = props;
  if (props.noSrcTransforms) {
    return src;
  }
  const { tryFullImg, error } = state;
  if (tryFullImg && !error) {
    return imgUrl(src, null);
  }
  return props.originalLink ? imgUrl(src, null) : imgUrl(src, params || thumbSize);
};

let preLoadImage = (srcIn, component) => {
  if (!srcIn) return {};
  const img = new Image();
  img.crossOrigin = 'anonymous';
  img.onload = () => {
    const { props, state } = component;
    if (img !== state.img) {
      return;
    }
    const src = imageLink(props, state);
    component.setStateSafe({
      imageStyles: { backgroundImage: `url("${src}")` },
      imageUrl: src,
      error: false,
    });
  };

  // tryFullImgOnError
  img.onerror = () => {
    const { props, state } = component;
    if (state.img !== img) return;
    const { params, thumbSize } = props;
    const { tryFullImg, error } = state;
    // we already tried load original size image
    if (!params && !thumbSize) {
      component.setStateSafe({ error: true });
      return;
    }
    if (tryFullImg && !error) {
      component.setStateSafe({ error: true });
      return;
    }
    if (!tryFullImg) {
      component.setStateSafe({ tryFullImg: true }, () => {
        const { props, state } = component;
        img.src = imageLink(props, state);
      });
    }
  };
  return { img };
};

if (process.env.NODE_ENV === 'test') {
  preLoadImage = (srcIn, component) => {
    if (!srcIn) return {};
    const { props, state } = component;
    const src = imageLink(props, state);
    const img = new Image();
    img.src = src;
    return {
      img,
      imageStyles: src ? { backgroundImage: `url("${src}")` } : undefined,
      imageUrl: imageLink(props, state),
      error: false,
    };
  };
}

/**
 * mount:
 *   constructor()
 *   static getDerivedStateFromProps()
 *   render()
 *   componentDidMount()
 * updating: (on new props or after setState)
 *   static getDerivedStateFromProps()
 *   shouldComponentUpdate()
 *   render()
 *   getSnapshotBeforeUpdate()
 *   componentDidUpdate()
**/
class SmartImage extends Component {

  static propTypes = {
    classes: PropTypes.shape({
      SmartImage: PropTypes.string.isRequired,
      bg: PropTypes.string.isRequired,
      loader: PropTypes.string.isRequired,
      noImage: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
      imageBox: PropTypes.string.isRequired,
    }).isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    className: deprecated,
    src: PropTypes.string,
    // eslint-disable-next-line react/no-unused-prop-types
    params: PropTypes.shape({
      width: PropTypes.number,
      height: PropTypes.number,
      cover: PropTypes.bool,
    }),
    // eslint-disable-next-line react/no-unused-prop-types
    thumbSize: PropTypes.oneOf(['S', 'M', 'L']),
    container: PropTypes.string,
    // eslint-disable-next-line react/no-unused-prop-types
    originalLink: PropTypes.bool,
    onClick: PropTypes.func,
    children: PropTypes.node,
    withForm: PropTypes.bool,
    modifier: PropTypes.string,
    refImg: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.object,
    ]),
    PDFComponentName: PropTypes.string,
    PDFEvents: PropTypes.shape({
      removeEventListener: PropTypes.func.isRequired,
      addEventListener: PropTypes.func.isRequired,
    }),
  };

  setStateSafe(state, cb) {
    if (!this.unmounted) {
      this.setState(state, cb);
    }
  }

  state = {
    // image src as background (resized or full if preload was success)
    imageStyles: null,
    // tested image src (resized or full if preload was success)
    imageUrl: null,
    // if all attempts to load image falls
    error: false,
    // if resized src fell
    tryFullImg: false,
    // used in getDerivedStateFromProps to subscribe image events
    component: this,
    // used to check if props.src changed (in getDerivedStateFromProps)
    prevSrc: null,
    // Image instance, used for preload and test imageUrls
    img: null,
  }

  unmounted = false;

  componentWillUnmount() {
    this.unmounted = true;
    const { PDFEvents, PDFComponentName } = this.props;
    if (PDFEvents && PDFComponentName) {
      PDFEvents.removeEventListener(EVENT_GET_PAGE_COMPONENT, this.onGetComponent);
    }
  }

  componentDidMount() {
    this.checkImgUrl();
    const { PDFEvents, PDFComponentName } = this.props;
    if (PDFEvents && PDFComponentName) {
      PDFEvents.addEventListener(EVENT_GET_PAGE_COMPONENT, this.onGetComponent);
    }
  }

  componentDidUpdate() {
    this.checkImgUrl();
  }

  static getDerivedStateFromProps(props, state) {
    let ret = null;
    if (state.prevSrc !== props.src) {
      ret = {
        imageStyles: null,
        imageUrl: null,
        prevSrc: props.src,
        error: false,
        // img, imageStyles, imageUrl, error
        ...preLoadImage(props.src, state.component),
      };
    }
    return ret;
  }

  onGetComponent = (event) => {
    const { PDFComponentName } = this.props;
    // event.id && event.id === 'id'
    if (event.id && event.id === PDFComponentName) {
      event.isPropagationStopped = true;
      return { item: { img: this.state.img } };
    }
  }

  checkImgUrl() {
    const { img } = this.state;
    if (img && !img.src) {
      img.src = imageLink(this.props, this.state);
    }
  }

  renderPreview = () => {
    const { classes, onClick, withForm, refImg } = this.props;
    const { imageUrl } = this.state;
    return <img
      onClick={onClick}
      alt="cover"
      src={imageUrl}
      className={cx(classes.image, { withForm })}
      ref={refImg}
      crossOrigin="anonymous"
    />;
  }

  get divStyle() {
    const { params } = this.props;
    const { imageStyles } = this.state;
    if (!params) return imageStyles;
    return {
      ...imageStyles,
      width: params.width,
      height: params.height,
      backgroundSize: params.cover === false ? 'contain' : 'cover',
    };
  }

  render() {
    const { classes, src, container, children, modifier, onClick } = this.props;
    const { imageStyles, imageUrl, error } = this.state;
    if (container === 'img' && imageStyles) {
      return (
        <div className={cx('SmartImage', classes.imageBox, modifier)}>
          {!!imageUrl && this.renderPreview()}
          {children}
        </div>
      );
    } else {
      return (
        <div className={cx(classes.SmartImage, modifier)}>
          <div onClick={onClick} className={cx(classes.bg, modifier)} style={this.divStyle}>
            {
              !imageStyles && src && !error &&
                <div className={cx(classes.loader, modifier)} />
            }
            {
              ((!src) || error) &&
                <div className={cx(classes.noImage, modifier)} />
            }
            {children}
          </div>
        </div>
      );
    }
  }
}

export default compose(
  injectSheet(styles),
  connectEventsContainer,
)(SmartImage);
