import { fork, put, takeEvery, select, call } from 'redux-saga/effects';
import { addNotification } from 'domain/env';
import {
  getSharedWithMeList_apiGen,
  getSharedWithMeListAppendPage_apiGen,
  watchRemoveShare,
} from 'domain/shares/sagas';
import {
  removeMeFromShareAction,
  getSharedWithMeAppendPageAction,
} from 'domain/shares/ShareActions';
import {
  getSharedWithMe as getSharedWithMe_sel,
  getSharedWithMePagination_sel,
} from 'domain/shares/ShareModel';
import { queryParams as queryParams_sel, currentPage as currentPage_sel } from 'domain/router/RouterModel';
import { updateQueryAction } from 'domain/router/RouterAction';
import { DEFAULT_PAGE_SIZE } from 'domain/const';

// function* updateList(request) {
//   /* sort: asc|desc */
//   const queryParams = yield select(queryParams_sel);
//   const { page: pageStr } = request.query;
//   const page = (pageStr - 1) || 0;
//   yield call(getSharedWithMeList_apiGen.catchError, { ...queryParams, page });
// }

function* onSuccessRemove() {
  const list = yield select(getSharedWithMe_sel);
  const page = yield select(currentPage_sel);
  yield put(addNotification({ title: 'Share was removed' }));
  if (list.size === 0 && page > 1) {
    yield put(updateQueryAction({ page: page - 1 }));
  }
}

function* fetchSharedWithMePage(page = 0) {
  const queryParams = yield select(queryParams_sel);
  try {
    if (page > 0) {
      yield call(getSharedWithMeListAppendPage_apiGen, { ...queryParams, page, size: DEFAULT_PAGE_SIZE });
    } else {
      yield call(getSharedWithMeList_apiGen, { ...queryParams, page, size: DEFAULT_PAGE_SIZE });
    }
  } catch (err) {
    console.error(err);
  }
}

export function* onPagination() {
  const { page, loading } = yield select(getSharedWithMePagination_sel);
  if (loading) return;
  yield call(fetchSharedWithMePage, page + 1);
}

export default function* watchNavigation() {
  yield fork(fetchSharedWithMePage);
  yield takeEvery(getSharedWithMeAppendPageAction.type, onPagination);
  yield takeEvery(removeMeFromShareAction.success, onSuccessRemove);
  yield fork(watchRemoveShare);
}
