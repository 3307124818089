import React from 'react';
import PropTypes from 'prop-types';
import { excerpt } from 'lib/helpers';
import injectSheet from 'lib/sheet';
import styles from './sheet.js';

import { MAX_SHARE_COMMENT_LENGTH } from 'domain/const';

const TEXT_CUTTED = 'TEXT_CUTTED';
const TEXT_EXPANDED = 'TEXT_EXPANDED';
const TEXT_SMALL = 'TEXT_SMALL';

class ShareComment extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.shape({
      ShareComment: PropTypes.string.isRequired,
      toggleCollapseBtn: PropTypes.string.isRequired,
      expandBtnColumn: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    }).isRequired,
    text: PropTypes.string,
  };

  state = {
    expanded: false,
  };

  get text() {
    const { text } = this.props;
    if (this.state.expanded) return text;
    return excerpt({ str: text, maxLen: MAX_SHARE_COMMENT_LENGTH });
  }

  get expandBtnVisibility() {
    const { text } = this.props;
    if ((text + '').length <= MAX_SHARE_COMMENT_LENGTH) return TEXT_SMALL;
    if (this.state.expanded) return TEXT_EXPANDED;
    return TEXT_CUTTED;
  }

  get expandBtn() {
    const { classes } = this.props;
    switch (this.expandBtnVisibility) {
      case TEXT_CUTTED:
        return (
          <button
            type="button"
            onClick={this.onExpand}
            children="Show more"
            className={classes.toggleCollapseBtn}
            data-name="show-more"
          />
        );
      case TEXT_EXPANDED:
        return (
          <button
            type="button"
            onClick={this.onCollapse}
            children="Show less"
            className={classes.toggleCollapseBtn}
            data-name="show-less"
          />
        );
      default:
        return null;
    }
  }

  onExpand = () => this.setState({ expanded: true });

  onCollapse = () => this.setState({ expanded: false });

  render() {
    const { classes } = this.props;
    return (
      <div
        className={classes.ShareComment}
      >
        <div
          className={classes.text}
          children={this.text}
        />
        <div
          className={classes.expandBtnColumn}
          children={this.expandBtn}
        />
      </div>
    );
  }
}

export default injectSheet(styles)(ShareComment);
