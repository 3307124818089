import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import cx from 'classnames';
import I from 'immutable';
import { isValidReactComponent } from 'lib/helpers';
import { getId } from 'pages/common/newPage/form/utils';
import Message from 'components/form/message';
import Input from 'components/form/field/nodes/Input';
import { protectClassesReplacing } from 'lib/helpers';
import injectSheet from 'lib/sheet';
import sheet from './sheet';

const Fragment = ({ children, key }) => <React.Fragment children={children} key={key} />;
Fragment.propTypes = { children: PropTypes.node, key: PropTypes.any };
/**
 * <Field
 *   type="text"
 *   name="firstname"
 *   component={FormRowField}
 *   placeholder="First name"
 *   modifier="MySuperFormField"
 *   rootTag="div"
 * />
 * <Field
 *   name="createdYear"
 *   component={FormRowField}
 *   Field={DateField}
 *   modifier="MySuperFormField"
 *   type="text"
 *   placeholder={...}
 *   onChange={value => ...}
 *   validate={rules.year}
 *   rootTag="div"
 *   normalize={v => v ? moment(v, 'YYYY').format('YYYY'): ''}
 *   format={v => v ? moment(v, 'YYYY-MM-DD').format('MM/DD/YYYY'): ''}
 *   momentMask="YYYY"
 *   momentParseMasks={['YYYY']}
 *   maxDetail="decade"
 *   onBlur={e => e.preventDefault()}
 *   mask={[/\d/, /\d/, /\d/, /\d/]}
 * />
 * if your form has complex layout you can provide `errorMessagePortal` to render it outside this component
**/
function FormRowField({
  input, meta, type, id, placeholder, title, label, children, classes, modifier, className, fieldClassName, errorMessages, rootClassName, rootTag = 'li',
  Field = Input,
  innerFormItem = false, labelLayout = false, disabled = false, required = false, noFieldWrapper = false,
  externalClasses, fieldProps = {}, errorMessagePortal, ...other
}) {
  id = id || getId(input.name);
  const { touched, error } = meta;
  className = cx(
    classes.badFieldClassName,
    fieldClassName ? classes.fieldClassName : '',
    fieldClassName ? `fieldClassName--${fieldClassName}` : '',
    className ? `className--${className}` : '',
  );
  modifier = cx(
    modifier,
    {
      labelLayout,
      error: error && touched,
      'field-with-error': error && touched,
    },
  );
  const Root = labelLayout ? 'label' : rootTag;
  const Label = labelLayout ? 'span' : 'label';
  const Wrapper = (( (!id || !title) && !label ) || noFieldWrapper) ? Fragment : 'div';
  
  let errorMessageNode = <Message
    meta={meta}
    errorMessages={errorMessages ? errorMessages.get(input.name) : void 0}
    modifier={modifier}
  />;
  if (errorMessagePortal) {
    // without div Wrapper can throw error of object child if it set to React.Fragment
    errorMessageNode = (
      <div className="portalWrapper">
        {ReactDOM.createPortal(errorMessageNode, errorMessagePortal)}
      </div>
    );
  }
  return (
    <Root
      data-name={input.name}
      className={cx(
        classes.FormRowField,
        (!innerFormItem ? classes.formItem : classes.innerFormItem),
        rootClassName ? classes.rootClassName : '',
        externalClasses ? classes.externalClasses : '',
        modifier,
      )}
    >
      {
        ((id || labelLayout) && (title || label)) &&
          <Label
            htmlFor={labelLayout ? undefined : id}
            className={cx(classes.label, modifier, { required })}
            children={title || label}
          />
      }
      <Wrapper className={cx(classes.fieldWrapper, modifier) || undefined}>
        <Field
          type={type}
          id={id}
          disabled={disabled}
          required={required}
          className={className}
          placeholder={placeholder}
          meta={meta}
          input={input}
          modifier={modifier}
          title={title}
          {...fieldProps}
          {...other}
        />
        {children}
        {errorMessageNode}
      </Wrapper>
    </Root>
  );
}

FormRowField.propTypes = {
  // html attr
  className: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  title: PropTypes.string,
  // redux form field attr
  input: PropTypes.shape({
    onChange: PropTypes.func,
    value: PropTypes.any,
    name: PropTypes.string,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.any,
  }).isRequired,
  // chema attr
  rootTag: isValidReactComponent,
  Li: isValidReactComponent,
  // rootClassName: PropTypes.string,
  fieldClassName: PropTypes.string,
  modifier: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.number,
  ]),
  Field: isValidReactComponent,
  classes: PropTypes.shape({
    FormRowField: PropTypes.string,
    label: PropTypes.string,
    fieldWrapper: PropTypes.string,
    formItem: PropTypes.string,
    innerFormItem: PropTypes.string,
    // field: PropTypes.string.isRequired,
    // inputField: PropTypes.string,
    badFieldClassName: PropTypes.string,
    fieldClassName: PropTypes.string,
    rootClassName: PropTypes.string,
    externalClasses: PropTypes.string,
  }).isRequired,
  // error message attr
  errorMessages: PropTypes.instanceOf(I.Map),
  innerFormItem: PropTypes.bool,
  labelLayout: PropTypes.bool,
  noFieldWrapper: PropTypes.bool,
  label: PropTypes.node,
  rootClassName: PropTypes.string,
  externalClasses: PropTypes.shape({}),
  fieldProps: PropTypes.shape({}),
  // HTML dom node (document.createElement('div'))
  errorMessagePortal: PropTypes.shape({
    tagName: PropTypes.string,
  }),
};

export default compose(
  protectClassesReplacing,
  injectSheet(sheet),
)(FormRowField);
