import {
  formItem,
  label,
  fieldWrapper,
  buttonContainer,
  selectWrapper,
} from 'theme/form';
import { AOFormSheet } from 'pages/common/newPage/form/sheet';

export default {
  formItem,
  label,
  fieldWrapper,
  buttonContainer,
  selectWrapper,
  noteFieldWrapper: {
    position: 'relative',
  },
  button: AOFormSheet.button,
  remove: AOFormSheet.remove,
  add: AOFormSheet.add,
};
