import React from 'react';
import PropTypes from 'prop-types';
import I from 'immutable';
import Hint from './hint';
import injectSheet from 'lib/sheet';
import styles from './sheet.js';

// function SelectedItemsControls({ classes, items, onSetCover, onAddToGallery, onDelete, onShare, setCoverShown, onReset }) {
function SelectedItemsControls({ classes, items, onSetCover, onAddToGallery, onDelete, setCoverShown, onReset }) {
    return (
      <div
        className={classes.SelectedItemsControls}
      >
        {
          (!!items.size) &&
            <div className={classes.showIfNotEmpty}>
              <div className={classes.showIfNotEmptyInner}>
                <div className={classes.amount}>
                  <button className={classes.clear} title="Clear" onClick={onReset}>×</button>
                  <span>{`${items.size} selected`}</span>
                </div>
                <div className={classes.btns}>
                  {
                    (!!setCoverShown) &&
                    <Hint text="set cover">
                      <button
                        type="button"
                        className={classes.btnCover}
                        onClick={() => onSetCover(items)}
                        data-name="btn-cover"
                      />
                    </Hint>
                  }
                  {
                    onAddToGallery &&
                    <Hint text="add to gallery">
                      <button
                        type="button"
                        className={classes.btnAddToGallery}
                        onClick={() => onAddToGallery(items)}
                        data-name="btn-add-to-gal"
                      />
                    </Hint>
                  }
                  {/*
                    onShare &&
                    <Hint text="share">
                      <button
                        type="button"
                        className={classes.btnShare}
                        onClick={() => onShare(items)}
                        data-name="btn-share"
                      />
                    </Hint>
                  */}
                  <Hint text="delete">
                    <button
                      type="button"
                      className={classes.btnDelete}
                      onClick={() => onDelete(items)}
                      data-name="btn-delete"
                    />
                  </Hint>
                </div>
              </div>
            </div>
        }
      </div>
    );
}
SelectedItemsControls.propTypes = {
  items: PropTypes.instanceOf(I.Set).isRequired,
  classes: PropTypes.object.isRequired,
  onSetCover: PropTypes.func,
  onAddToGallery: PropTypes.func,
  onDelete: PropTypes.func.isRequired,
  // onShare: PropTypes.func,
  setCoverShown: PropTypes.bool,
  onReset: PropTypes.func,
};

export default injectSheet(styles)(SelectedItemsControls);
