import opened from './images/advanced-filter-group-opened.svg';
import closed from './images/advanced-filter-group-closed.svg';
import { DEFAULT_FONTFAMILY } from 'theme/theme';

export default {
  AdvancedFilterAccordion: {
    borderBottom: '1px solid #d8d8d8',
    paddingBottom: 18,
    '&.substrate, &.surface': {
      // margin: [0, 16],
      borderBottom: 'none',
      paddingBottom: 0,
    },
    '&.surface': {
    },
    '&.last': {
      marginBottom: 30,
    },
  },
  head: {
    height: 36,
    padding: [15, 6, 0],
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
    // '&.opened': {
    //   // paddingBottom: 12,
    //   // height: 36 + 12,
    // },
    '&.artTitle': {
      paddingTop: 7,
    },
    '&.substrate, &.surface': {
      paddingLeft: 0,
      paddingRight: 0,
    },
    '@media screen and (max-width: 768px)': {
      // width: 350,
      width: '100%',
      margin: '0px auto',
    },
    '@media screen and (max-width: 350px)': {
      width: '100%',
    },
  },
  title: {
    font: `500 14px/18px ${DEFAULT_FONTFAMILY}`,
  },
  amount: {
    flex: '1 1 0',
    textAlign: 'left',
    paddingLeft: 4,
    font: `400 14px/18px ${DEFAULT_FONTFAMILY}`,
  },
  collapseCell: {
    flex: '0 0 20px',
  },
  collapseBtn: {
    color: 'rgba(0, 0, 0, 0)',
    width: 20,
    height: 20,
    background: {
      image: `url(${closed})`,
      size: '10px 6px',
      repeat: 'no-repeat',
      position: 'center',
      color: 'transparent',
    },
    '&.opened': {
      backgroundImage: `url(${opened})`,
    },
  },
  content: {
    padding: [0, 6],
    display: 'none',
    '&.opened': {
      display: 'block',
    },
    '&.substrate, &.surface': {
      padding: 0,
    },
    '@media screen and (max-width: 768px)': {
      // width: 350,
      width: '100%',
      margin: '0px auto',
    },
    '@media screen and (max-width: 350px)': {
      width: '100%',
    },
  },
};
