export default {
  Tooltip: {
    position: 'relative',
    display: 'inline-block',
    lineHeight: '1em',
    '&.opened': {
      '& $popoverWrapper': {
        overflow: 'visible',
      },
      '& $popover': {
        visibility: 'visible',
        pointerEvents: 'all',
      },
    },
    '&.AOForm-file': {
      display: 'flex',
    },
  },
  popoverWrapper: {
    float: 'left',
    width: 1,
    height: 1,
    overflow: 'hidden',
  },
  popover: {
    position: 'absolute',
    visibility: 'hidden',
    pointerEvents: 'none',
    zIndex: 1,
    top: -50,
    left: 43,
    padding: [24, 24, 22],
    backgroundColor: '#FFF',
    boxShadow: '0px 11px 20px rgba(5, 16, 55, 0.1)',
    borderRadius: 4,
    overflow: 'hidden',
    '@media screen and (max-width: 900px)': {
      padding: [4, 1, 4, 7],
    },
  },
};
