export function sizeSolver(height, data, name, params) {
  if (!data || !data.get('category') || !params || !params.category) return null;
  const IS_3D = (params.category.getIn([data.get('category'), 'title']) || '').search(/3d/i) > -1;
  if (!data.get('height')) {
    return (IS_3D && data.get('notAccurate')) ? 'Not Available' : null;
  }
  let ret = [
    data.get('height'),
    data.get('width'),
    data.get('depth'),
  ].filter(v => v).join(' x ') + ' ' + (data.get('unit') || '').toLowerCase();
  if (data.isCircular) {
    return ret + ' diameter';
  }
  return ret;
}
