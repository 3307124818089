import React from 'react';
import PropTypes from 'prop-types';
import Popup from 'components/popup';
import cx from 'classnames';
import { isPopupVisibleSelector, hidePopupAction } from 'domain/ui';
import { termsAcceptAction } from 'domain/env/EnvActions';
import { Terms as T, Bold as B, P, A } from './utils';

import { connect } from 'cpcs-reconnect';
import { compose } from 'redux';
import { SIGNUP_TERMS_POPUP } from 'domain/const';

import injectSheet from 'lib/sheet';
import { sheet } from './sheet';

class TermsPopup extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.shape({
      container: PropTypes.string.isRequired,
      close: PropTypes.string.isRequired,
      textContainer: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      list: PropTypes.string.isRequired,
      listItem: PropTypes.string.isRequired,
      textTitle: PropTypes.string.isRequired,
      innerList: PropTypes.string.isRequired,
      footer: PropTypes.string.isRequired,
      button: PropTypes.string.isRequired,
    }).isRequired,
    hidePopup: PropTypes.func.isRequired,
    isPopupVisible: PropTypes.func.isRequired,
    onAccept: PropTypes.func.isRequired,
  }

  onCancel = () => {
    this.props.onAccept(false);
    this.props.hidePopup();
  }

  onAccept = () => {
    this.props.onAccept(true);
    this.props.hidePopup();
  }

  onHide = () => {
    this.props.hidePopup();
  }

  render() { //NOSONAR
    const { classes, isPopupVisible } = this.props;
    if (!isPopupVisible(SIGNUP_TERMS_POPUP)) return null;
      return (
        <Popup>
          <button
            type="button"
            onClick={this.onHide}
            className={classes.close}
          />
          <div className={classes.container}>
            <div className={classes.textContainer}>
              <h2 className={classes.title}>ARTBNK LLC SUBSCRIPTION TERMS</h2>
              <P>Last modified May 25, 2018</P>
              <P>THESE SUBSCRIPTION TERMS (AND ARTBNK’S PRIVACY POLICY, AS NOTED BELOW) CREATE A
                LEGAL AGREEMENT (THIS <T>AGREEMENT</T>) BETWEEN ARTBNK LLC, A DELAWARE LIMITED LIABILITY COMPANY
                (<T>ARTBNK</T>, <T>WE</T>, <T>US</T> OR <T>OUR</T>) AND THE USER OF THE SERVICE ENTERING INTO THIS
                AGREEMENT (<T>YOU</T> OR <T>YOUR</T>). YOU SHOULD CAREFULLY READ THIS AGREEMENT AND
                THE <A href="https://www.artbnk.com/privacy-policy">PRIVACY POLICY</A> (the <T>Privacy Policy</T>), WHICH IS
                INCORPORATED INTO AND GOVERNED BY THIS AGREEMENT. THIS AGREEMENT GOVERNS YOUR USE OF THE WEBSITE
                WWW.ARTBNK.COM AND ANY SUBDOMAINS (the <T>Site</T>) AND THE SERVICES, FEATURES, AND INFORMATION AVAILABLE ON
                THE SITE AND/OR ANY MOBILE APPLICATIONS WE MAY OFFER (TOGETHER WITH THE SITE, ALONG WITH ASSOCIATED AND
                SUCCESSOR WEBSITES, APPLICATIONS, FEATURES, INFORMATION, AND SERVICES, OR ANY PART THEREOF, THE
                <T>SERVICE</T>). BY USING OR ACCESSING THE SERVICE, YOU AGREE TO BE BOUND BY THIS AGREEMENT. IF YOU DO NOT
                AGREE TO BE BOUND BY THIS AGREEMENT, OR YOU ARE BELOW THE AGE OF 21, YOU ARE NOT PERMITTED TO USE THE
                SERVICE. THE SERVICE MAY BE ACCESSED DIRECTLY THROUGH THE SITE OR THROUGH SOCIAL MEDIA ACCOUNTS, AND OTHER
                ONLINE CHANNELS. ARTBNK IS NOT LIABLE FOR THE USE OF THIS SERVICE BY, OR FOR ANY CONTENT OR ADVERTISEMENTS
                VIEWED BY MINORS IN VIOLATION OF THIS AGREEMENT.</P>
              <ul className={classes.list}>
                <li className={classes.listItem}>
                  <h3 className={classes.textTitle}>1. Changes to this Agreement.</h3>
                  <P> Except with respect to Section 16.4 (Mandatory Arbitration), ARTBNK
                    reserves the right, in its sole discretion, to change, modify, add to, supplement or delete any terms
                    and conditions of this Agreement (including the Privacy Policy, which is incorporated into and
                    governed by this Agreement) at any time; provided, however, that ARTBNK will use reasonable efforts to
                    provide you with notification of any material changes (as determined in ARTBNK’s sole discretion) by
                    email, postal mail, website posting, pop-up screen, or in-service notice. You should visit this page
                    whenever you use the Service to review this Agreement and learn if any terms have changed. If any
                    future changes to this Agreement are unacceptable to you or cause you to no longer be in compliance
                    with this Agreement, you must immediately stop using the Service. Your continued use of the Service
                    following any revision to this Agreement constitutes your acceptance of all such changes.</P>
                </li>
                <li className={classes.listItem}>
                  <h3 className={classes.textTitle}>2. Access to the Service.</h3>
                  <ul className={classes.innerList}>
                    <li className={classes.listItem}>2.1. Subject to your acceptance of and compliance with this
                      Agreement, ARTBNK grants to you a non-exclusive, non-transferable, revocable limited license to use
                      the Service and any related Content for purposes of tracking, organizing, recording, offering,
                      publicizing, acquiring, and valuing artwork that is owned, controlled, displayed or sold by you or,
                      if you are a business or professional, by your clients and customers. You agree not to use the
                      Service to download, save, copy, transmit, publish, license, create derivative works from, transfer,
                      sell or distribute any Content that is not owned and contributed by you, except as specifically
                      allowed in this Agreement. For these purposes the <T>Content</T> includes all text, images, articles,
                      photographs, illustrations, audio, graphics, graphs, video clips, and other materials available
                      through the Service, including without limitation material on the Service’s Public Areas (as defined
                      below).
                    </li>
                    <li className={classes.listItem}>2.2. ARTBNK may change, modify, suspend, or discontinue all or any
                      aspect of the Service at any time, and may also impose limits on certain features or restrict or
                      prohibit your access to parts or all of the Service at any time, all without notice or liability,
                      provided that (i) in most cases it would only do so for a good reason and with notice, and (ii) you
                      will be entitled to a pro rata refund of any amounts attributable to a period when the Service have
                      been discontinued or substantially diminished or limited.
                    </li>
                    <li className={classes.listItem}>2.3. Your use of the Service is conditional upon your compliance with
                      this Agreement, and any use of the Service in violation of this Agreement may constitute
                      infringement of ARTBNK’s copyrights in and to the Service and Content (as applicable). ARTBNK
                      reserves the right to terminate your access to the Service without notice if you violate this
                      Agreement or for other good cause.
                    </li>
                    <li className={classes.listItem}>2.4. Unless you have subscribed to a multi-user account,
                      your subscription -- and your password and account -- are personal to you
                      and may not be shared with others.
                    </li>
                    <li className={classes.listItem}>2.5. Use of the Service by Minors. Individuals under the age of 21
                      may not use the Service. In the event you allow a minor to use the Service, you hereby accept this
                      Agreement on the minor’s behalf and you agree that you will be responsible for all uses of the
                      Service by the minor, whether or not these uses were authorized by you.
                    </li>
                  </ul>
                </li>
                <li className={classes.listItem}>
                  <h3 className={classes.textTitle}>3. Subscription and Payment.</h3>
                  <P>Access to the Service is only available to persons who create valid accounts
                    and, where required by our subscription terms, pay the applicable fees.</P>
                </li>
              </ul>
              <P>Our pricing and payment terms can be found on the Site’s pricing page
                at <A>www.artbnk.com</A> or in your invoice (the <T>Pricing Terms</T>), which are hereby incorporated into this
                Agreement. If you subscribe for the Service the following applies:</P>
              <ul className={classes.innerList}>
                <li className={classes.listItem}>a) You will pay the full subscription price set out in the Pricing Terms
                  for the Services used by you or by anyone using your account. The full subscription price for the plan
                  period will be debited from your debit or credit card at the time of registration.
                </li>
                <li className={classes.listItem}>b) You will provide complete and accurate registration information and
                  inform us of any changes to that information.
                </li>
                <li className={classes.listItem}>c) You represent and warrant that you are over 21 years of age and able
                  to enter into a valid and binding agreement with us.
                </li>
                <li className={classes.listItem}>d) Your subscription will renew automatically, and the subscription price
                  applicable at the time of renewal as set out in the Pricing Terms will be charged to your card, unless
                  cancelled by you prior to the renewal date. If your card is declined, there is a grace period of 29 days
                  before your subscription is cancelled.
                </li>
                <li className={classes.listItem}>e) All prices are exclusive of sales, use, transfer, value-added or other
                  taxes and duties as may be applicable in any relevant jurisdiction (international, national, state or
                  local) (<T>Sales Tax</T>) (if any). If we make an error in the amount of Sales Tax collected we reserve the
                  right to process an additional charge in the amount of the properly chargeable Sales Tax.
                </li>
              </ul>
              <ul className={classes.list}>
                <li className={classes.listItem}>
                  <h3 className={classes.textTitle}>4. Ownership of Intellectual Property.</h3>
                  <ul className={classes.innerList}>
                    <li className={classes.listItem}>4.1. Unless otherwise specified in writing, all Content and other
                      materials that are part of the Service are owned, controlled, or licensed by ARTBNK and its
                      licensors and are protected by law from unauthorized use. The entire contents of the Service are
                      copyrighted under U.S. copyright laws and/or similar laws of other jurisdictions. ARTBNK, and the
                      ARTBNK logos, are trademarks of ARTBNK and may not be used without the express written permission of
                      ARTBNK.
                    </li>
                    <li className={classes.listItem}>4.2. You do not acquire any ownership rights by using the Service, or
                      by copying or downloading Content from the Service, though you retain ownership of any information
                      or Content initially owned by you that you upload or incorporate into the Service. You agree that
                      you may not and shall not use the Service or Content in any manner that competes with ARTBNK.
                      You represent and warrant that you have the right to upload or otherwise incorporate
                      Content into the Service, and that doing so will not violate any intellectual property or
                      proprietary rights of any third person or entity.
                    </li>
                    <li className={classes.listItem}>4.3. You hereby grant to ARTBNK an unlimited, non-exclusive,
                      worldwide, perpetual, irrevocable, royal-free sublicensable (through multiple tiers), and
                      transferable right and license to use all comments, feedback, blog or forum statements, suggestions,
                      ideas, emails and other submissions disclosed or submitted to ARTBNK in connection with your use of
                      the Service (collectively, <T>Submissions</T>) in any manner ARTBNK may desire, including, but not
                      limited to, to make, have made, use, sell, offer to sell, import, reproduce, modify, create
                      derivative versions of, distribute, publicly display and publicly perform such Submissions, in any
                      and all forms and media now known or hereafter devised, without compensation to you and without
                      identifying you as the creator. You agree that the provisions in this Section 4 will survive any
                      termination of your account(s), the Service, or this Agreement.
                    </li>
                    <li className={classes.listItem}>4.4. You hereby grant to ARTBNK a non-exclusive, worldwide,
                      perpetual, irrevocable, royalty-free, sublicensable (through multiple tiers), and transferable right
                      and license to exercise all rights you have in Your Information (including without limitation to
                      use, adapt, and reproduce Your Information for purposes of developing and enhancing ARTBNK’s
                      valuation engine and service, but not to publicly display or perform Your Information except in
                      anonymized form), in all forms and media, whether or not currently known, in connection with
                      providing the Service. For these purposes, <T>Your Information</T> means any information or content you
                      provide to ARTBNK or other users, or to which you provide access to ARTBNK or other users, in the
                      registration or transaction process (including without limitation your name and photograph); in any
                      Public Areas (as defined below), as well as your user profile and in-Service messages (including
                      those between users); or through any e-mail or other feature of the Service.
                      To the extent that Your Information includes personal data its use will be subject
                      to the terms of the Privacy Policy.
                    </li>
                    <li className={classes.listItem}>4.5. You agree to include, and to not remove or alter, ARTBNK’s
                      trademark, copyright or other proprietary rights notices, as provided by ARTBNK on or in connection
                      with the Service, when using or sharing content or otherwise using the Service, and you agree to
                      comply with usage guidelines that may be provided by ARTBNK from time to time. You agree that all
                      goodwill that arises in connection with your use of ARTBNK trademarks inures exclusively to ARTBNK,
                      and you agree not to challenge ARTBNK’s ownership or control of any ARTBNK trademarks, nor use or
                      adopt any trademarks that might be confusingly similar to such ARTBNK trademarks.
                    </li>
                  </ul>
                </li>
                <li className={classes.listItem}>
                  <h3 className={classes.textTitle}>5. Posting on Other Web Sites.</h3>
                  <ul className={classes.innerList}>
                    <li className={classes.listItem}>5.1. Subject to your acceptance of and compliance with this
                      Agreement, ARTBNK grants to you a non-exclusive, non-transferable, revocable limited license to post
                      a screen shot from your account, and any other Content that ARTBNK specifically notifies you may be
                      posted on other web sites, on your own personal web site or on a third party web site that permits
                      posting of content at the direction of users provided that such third party web site (a) is not
                      commercially competitive to ARTBNK, (b) does not criticize or injure ARTBNK, (c) does not obtain any
                      rights to such Content other than a non-exclusive license to post it at your direction, (d) does not
                      charge for access to such Content or associate products, services or advertising with such Content,
                      (e) complies with all applicable laws, and (f) does not in any way abuse, defame, stalk, threaten or
                      violate the legal rights of others or publish or distribute any inappropriate, infringing,
                      defamatory, profane, indecent, obscene, unlawful or otherwise objectionable information or material.
                      All of ARTBNK’s rights and remedies are expressly reserved, and ARTBNK may revoke this limited
                      license, in whole or in part, upon notice.
                    </li>
                  </ul>
                </li>
                <li className={classes.listItem}>
                  <h3 className={classes.textTitle}>6. Public Areas.</h3>
                  <P>The Service may feature various community areas and other public forums,
                    including but not limited to blogs or member communities, discussion boards, question and answer areas
                    and comment areas (the <T>Public Areas</T>). If you use a Public Area you are solely responsible for your
                    own Submissions, the consequences of posting your Submissions, and your reliance on any information in
                    the Public Areas or other areas of the Service. Any information you share, including without
                    limitation any discussions with others, in any online Public Area is by design open to the public and
                    is not private. The ARTBNK Parties (as defined herein) reserve the right to, but shall not be
                    obligated to, record any dialogue or exchanges in the Public Areas of the Service. The ARTBNK Parties
                    shall have no responsibility for any actions taken, or failures to take action, with respect to the
                    Public Areas of the Service or any submissions by you or other users. As with any public forum on any
                    website, the information you post may show up in third-party search engine results.</P>
                </li>
                <li className={classes.listItem}>
                  <h3 className={classes.textTitle}>7. Passwords.</h3>
                  <P> ARTBNK has several tools that allow you to record and store information in
                    your account. You are responsible for all actions on the Service by you or under your password or
                    account and for taking all reasonable steps to ensure that no unauthorized person shall have access to
                    your password or account. Without limiting the foregoing, it is your sole responsibility to (1)
                    control the dissemination and use of any login code and password; (2) authorize, monitor, and control
                    access to and use of your Service account and password; (3) promptly inform ARTBNK of any need to
                    deactivate a password. You grant ARTBNK and all other persons or entities involved in the operation of
                    the Service the right to transmit, monitor, retrieve, store, and use any information recorded and/or
                    stored in your account in connection with the operation of the Service.</P>
                </li>
                <li className={classes.listItem}>
                  <h3 className={classes.textTitle}>8. Usage Rules.</h3>
                  <P> As a condition of your use of and access to the Service, you agree to
                    comply with any application-, tool-, or content-specific rules published within the Service as well as
                    the following usage rules, which ARTBNK may modify or supplement in its discretion from time to time
                    (with notice to you of material changes, per Section 1). You agree that your activities on the Service
                    will not (as determined by ARTBNK in its discretion):</P>
                  <ul className={classes.innerList}>
                    <li className={classes.listItem}>a) copy, adapt, reverse engineer, decompile, reverse assemble,
                      modify or attempt to discover any software (source code or object code) that the Service creates to
                      generate web pages or any software or other products or processes accessible through the Service;
                    </li>
                    <li className={classes.listItem}>b) use or launch, develop or distribute any automated system,
                      including, without limitation, any spider, robot (or "bot"), cheat utility, scraper or offline
                      reader that accesses the Service, or use or launch any unauthorized script or other software;
                    </li>
                    <li className={classes.listItem}>c) distribute any virus, time bomb, trap door, or other harmful or
                      disruptive computer code, mechanism or program;
                    </li>
                    <li className={classes.listItem}>d) cover or obscure any notice, legend, warning, banner or
                      advertisement contained on the Service;
                    </li>
                    <li className={classes.listItem}>e) interfere with or circumvent any security feature of the Service
                      or any feature that restricts or enforces limitations on use of or access to the Service;
                    </li>
                    <li className={classes.listItem}>f) sell the Service or any part thereof including but not limited to
                      user accounts and access to them in exchange for money or items of value;
                    </li>
                    <li className={classes.listItem}>g) violate any applicable law, including without limitation any
                      applicable export laws;
                    </li>
                    <li className={classes.listItem}>h) harvest or otherwise collect information about others, including
                      email addresses, without their permission, or infringe or violate the rights of any other party,
                      including without limitation any intellectual property rights or rights of privacy or publicity;
                    </li>
                    <li className={classes.listItem}>i) engage in conduct that is obscene, offensive, pornographic,
                      fraudulent, deceptive, defamatory, threatening, harassing, abusive, slanderous, hateful, or causes
                      embarrassment to any other person;
                    </li>
                    <li className={classes.listItem}>j) further any chain letters or pyramid schemes, transmit
                      unsolicited messages, or engage in “spam”;
                    </li>
                    <li className={classes.listItem}>k) deliberately mislead anyone as to your identity, impersonate
                      another, falsely identify the source of any Submissions, or allow another person or entity to use
                      your identity in order to access the Service or post or view Submissions;
                    </li>
                    <li className={classes.listItem}>l) engage in conduct that conflicts with the spirit or intent of the
                      Service, including without limitation, by disrupting the flow of dialogue in a Public Area (as
                      defined above), restricting any other user from using or enjoying the Service, or exposing ARTBNK or
                      another to any liability or detriment of any kind;
                    </li>
                    <li className={classes.listItem}>m) use automated queries, including screen and database scraping,
                      spiders, robots, crawlers, information harvesting, and any other automated activity with the purpose
                      of obtaining Content, information or data from the Service, unless you receive the express written
                      permission of ARTBNK; or
                    </li>
                    <li className={classes.listItem}>n) violate the terms of service of Facebook, Google, or other
                      online channel by which you access the Service.
                    </li>
                  </ul>
                </li>
              </ul>
              <ul className={classes.list}>
                <li className={classes.listItem}>
                  <h3 className={classes.textTitle}>9. Terms for App Store Apps</h3>
                  <ul className={classes.innerList}>
                    <li className={classes.listItem}>
                      a) If you accessed or downloaded the App from the Apple Store, you agree to use the App only:
                      (1) on an Apple-branded product or device that runs iOS
                      (Apple’s proprietary operating system software);
                      and (2) as permitted by the “Usage Rules” set forth in the Apple Store Terms of Service
                      as of the effective date of this Agreement. If You accessed or downloaded the App from
                      any app store or distribution platform (like the Apple Store, Google Play or the Amazon Appstore)
                      (each, an “App Provider”), then you acknowledge and agree that:
                    </li>
                    <li className={classes.listItem}>
                      b) This Agreement is between you and ARTBNK, and not with App Provider, and that, as between ARTBNK
                      and the App Provider, ARTBNK is solely responsible for the App.
                    </li>
                    <li className={classes.listItem}>
                      c) The App Provider has no obligation whatsoever to furnish any maintenance and support services
                      with respect to the App.
                    </li>
                    <li className={classes.listItem}>
                      d) In the event of any failure of the App to conform to any applicable warranty, you may notify
                      App Provider and App Provider will refund to you any purchase price you paid for the App
                      (if applicable) and, to the maximum extent permitted by applicable law, App Provider will have
                      no other warranty obligation whatsoever with respect to the App. Any other claims, losses,
                      liabilities, damages, costs or expenses attributable to any failure of the App to conform to any
                      warranty will be the sole responsibility of ARTBNK.
                    </li>
                    <li className={classes.listItem}>
                      e) App Provider is not responsible for addressing any claims you or any third party may have
                      relating to the App or your possession and use of the App, including, but not limited to:
                      (1) product liability claims;
                      (2) any claim that the App fails to conform to any applicable legal or regulatory requirement;
                      and (3) claims arising under consumer protection or similar legislation.
                    </li>
                    <li className={classes.listItem}>
                      f) In the event of any third-party claim that the App or your possession and use of the
                      App infringes that third party’s intellectual property rights, ARTBNK will be solely responsible
                      for the investigation, defense, settlement and discharge of any such intellectual property
                      infringement claim solely to the extent required by this Agreement.
                    </li>
                    <li className={classes.listItem}>
                      g) App Provider and its subsidiaries are third-party beneficiaries of this Agreement
                      as related to your license of the App, and, upon your acceptance of this Agreement, App Provider
                      will have the right (and will be deemed to have accepted the right) to enforce this Agreement
                      as related to your license of the App against you as a third party beneficiary thereof.
                    </li>
                    <li className={classes.listItem}>
                      h) You agree to comply with all U.S. and foreign export laws and regulations to ensure that
                      neither the App nor any technical data related thereto nor any direct product thereof is exported
                      or re-exported directly or indirectly in violation of, or used for any purposes prohibited by,
                      such laws and regulations. By using the App you represent and warrant that: (1) you are not
                      located in a country that is subject to a U.S. Government embargo, or that has been designated by
                      the U.S. Government as a “terrorist supporting” country; and (2) you are not listed on any U.S.
                      Government list of prohibited or restricted parties.
                    </li>
                  </ul>{/* .innerList */}
                </li>{/* .listItem */}
              </ul>{/* .list */}
              <ul className={classes.list}>
                <li className={classes.listItem}>
                  <h3 className={classes.textTitle}>10. Privacy and Protection of Personal Information.</h3>
                  <P>
                    ARTBNK respects the privacy of visitors to and users of the Service.
                    Information collected from you by ARTBNK is subject to the Privacy Policy
                    at <A>https://www.artbnk.com/privacy-policy</A>.
                  </P>
                  <P>
                    To the extent that the Service is available to individuals located in the
                    European Economic Area and the United Kingdom we are bound by and act in compliance with the
                    General Data Protection Regulation 2016/679 (the <T>GDPR</T>).
                    Further detail can be found in the Privacy Policy.
                  </P>
                  <P>
                    If you access the Service through Facebook, Google, or other online channel, you may be
                    allowing Facebook, Google, or such channel to share your email address and any other personally
                    identifiable and non-personally identifiable information with ARTBNK.
                    You acknowledge and agree that the Privacy Policy, which describes the manner in which ARTBNK collects, uses and discloses
                    your personally identifiable information and non-personally identifiable information, is incorporated
                    into and governed by this Agreement. By accepting this Agreement, you agree to all of the terms of the Privacy Policy.
                    You further agree to comply with all applicable laws with respect to all
                    information you may receive from ARTBNK.</P>
                </li>
                <li className={classes.listItem}>
                  <h3 className={classes.textTitle}>11. Child Online Protection Act Notification.</h3>
                  <P>
                    Pursuant to 47 U.S.C. § 230(d) as amended, ARTBNK hereby notifies you that
                    parental control protections (such as computer hardware, software, or filtering services) are
                    commercially available that may assist you in limiting access to material that is harmful to minors.
                    Information identifying providers of such protection is available on the websites StaySafeOnline
                    (<A>http://staysafeonline.org/stay-safe-online/</A>),
                    GetNetWise (<A>http://kids.getnetwise.org/</A>),
                    and
                    OnGuardOnline (<A>http://www.onguardonline.com/</A>).
                    Please note that ARTBNK is not affiliated with the
                    above listed sites, nor is the above intended as an endorsement of any of the products or services
                    listed on such sites.</P>
                </li>
                <li className={classes.listItem}>
                  <h3 className={classes.textTitle}>12. Disclaimers; Limitations; Waivers of Liability.</h3>
                  <ul className={classes.innerList}>
                    <li className={classes.listItem}>12.1. YOU EXPRESSLY AGREE THAT USE OF THE SERVICE IS AT YOUR SOLE
                      RISK AND IS PROVIDED ON AN "AS IS" BASIS WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED,
                      INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF TITLE, NON-INFRINGEMENT, MERCHANTABILITY OR
                      FITNESS FOR A PARTICULAR PURPOSE (EXCEPT TO THE EXTENT THIS DISCLAIMER IS PROHIBITED UNDER
                      APPLICABLE LAWS). WITHOUT LIMITING THE FOREGOING, NEITHER ARTBNK NOR ITS AFFILIATES OR SUBSIDIARIES,
                      OR ANY OF THEIR DIRECTORS, EMPLOYEES, AGENTS, ATTORNEYS, THIRD-PARTY CONTENT PROVIDERS,
                      DISTRIBUTORS, LICENSEES OR LICENSORS (COLLECTIVELY, <T>ARTBNK PARTIES</T>) WARRANT THAT THE SERVICE WILL
                      BE UNINTERRUPTED, UNCORRUPTED, TIMELY, OR ERROR-FREE.
                    </li>
                    <li className={classes.listItem}>
                      12.2. THE ARTBNK PARTIES EXPRESSLY MAKE NO REPRESENTATION OR WARRANTY THAT THE SERVICE OR CONTENT
                      (INCLUDING WITHOUT LIMITATION ANY APPRAISAL OF ANY ARTWORK) WILL BE ACCURATE, RELIABLE, COMPLETE,
                      CURRENT, OR TIMELY. YOU ACKNOWLEDGE THAT WITH RESPECT TO ARTWORK RECORDED, DISPLAYED, APPRAISED OR
                      OTHERWISE ASSOCIATED WITH THE SERVICE:
                      <ul className={classes.innerList}>
                        <li className={classes.listItem}>a) THE ARTBNK PARTIES MAKE NO REPRESENTATIONS TO YOU OR TO ANY
                          OTHER PERSONS REGARDING TITLE, PROVENANCE, AUTHENTICITY, CONDITION, OR ACCURACY OF INFORMATION
                          WITH RESPECT TO ANY WORK. A VALUATION OR OTHER REPORT PRODUCED BY OUR SERVICES IS NOT A
                          CERTIFICATE OF TITLE, WARRANTY OF OWNERSHIP, OR PROMISE OF ACCURACY.
                        </li>
                        <li className={classes.listItem}>b) OUR REAL-TIME APPRAISALS ARE BASED ON REPORTS FROM USERS,
                          COLLECTORS, ART GALLERIES, AUCTION SITES AND OTHER WEBSITES, WHICH WE DO NOT INDEPENDENTLY TEST
                          OR VERIFY. WE HAVE NO WAY TO DETERMINE IF USERS OR OTHERS HAVE MISSTATED OR WITHHELD
                          INFORMATION. THEREFORE, WE CANNOT GURANTEE TITLE, PROVENANCE, AUTHENTICITY CONDITION OR ACCURACY
                          AND WE ARE NOT RESPONSIBLE FOR MISSTATED OR WITHHELD INFORMATION OR FOR POSSIBLE ERRORS OR
                          OMISSIONS.
                        </li>
                        <li className={classes.listItem}>c) OUR REAL-TIME APPRAISALS ARE INTENDED TO REFLECT FAIR MARKET
                          VALUE AT THE TIME OF THE APPRAISAL AND ARE NOT PREDICTIVE OF PAST OR FUTURE VALUES OR OF
                          APPRAISALS THAT MIGHT BE RECEIVED BY OTHER MEANS OR FROM OTHER SOURCES. THESE APPRAISALS ARE
                          ESTIMATES ONLY AND DO NOT NECESSARILY REFLECT THE ACTUAL VALUE THAT WOULD BE DETERMINED IN ANY
                          SALES TRANSACTION, TAX APPRAISAL OR INSURANCE RECOVERY. ARTBNK DOES NOT RECEIVE A COMMISSION AND
                          IS NOT COMPENSATED BASED ON VALUES, SALES NUMBERS, SALES PRICES OR INSURANCE PAYMENTS.
                        </li>
                      </ul>
                    </li>
                    <li className={classes.listItem}>12.3. THE ARTBNK PARTIES WILL NOT BE LIABLE FOR ANY DIRECT, INDIRECT,
                      INCIDENTAL, SPECIAL, EXEMPLARY OR CONSEQUENTIAL DAMAGES, PERSONAL INJURY/WRONGFUL DEATH, LOST
                      PROFITS, LOST DATA, OR BUSINESS INTERRUPTION, THE USE OR MISUSE OF SUBMISSIONS OR CONTENT IN ANY WAY
                      WHATSOEVER ARISING OUT OF THE USE OF, OR INABILITY TO USE, THE SERVICE, WHETHER OR NOT THE ARTBNK
                      PARTIES ARE ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IN THE EVENT THAT THE FOREGOING EXCLUSION OF
                      LIABILITY IS FOUND BY A COURT OF COMPETENT JURISDICTION TO BE UNENFORCEABLE, AND A DETERMINATION IS
                      MADE THAT ARTBNK IS LIABLE, UNDER NO CIRCUMSTANCES WILL THE ARTBNK PARTIES BE LIABLE TO YOU FOR MORE
                      THAN THE AMOUNT YOU HAVE PAID ARTBNK IN THE NINETY (90) DAYS IMMEDIATELY PRECEDING THE DATE ON WHICH
                      YOU FIRST ASSERT THE CLAIM.
                    </li>
                    <li className={classes.listItem}>12.4. TO THE FULLEST EXTENT PERMITTED BY LAW, THESE DISCLAIMERS OF
                      LIABILITY CONTAINED HEREIN APPLY TO ANY AND ALL DAMAGES OR INJURY WHATSOEVER CAUSED BY OR RELATED TO
                      USE OF, OR INABILITY TO USE, THE SERVICE UNDER ANY CAUSE OR ACTION WHATSOEVER OF ANY JURISDICTION,
                      INCLUDING, WITHOUT LIMITATION, ACTIONS FOR BREACH OF WARRANTY, BREACH OF CONTRACT OR TORT (INCLUDING
                      NEGLIGENCE).
                    </li>
                    <li className={classes.listItem}>12.5. <B>Release</B>. You forever release, discharge, and covenant not to
                      sue the ARTBNK Parties from any and all liability, claims, actions, and expenses that may arise,
                      whether caused by the negligence of the ARTBNK Parties or otherwise, in connection with your use of
                      the Service or your interaction with any party through or as a result of the Service. In other
                      words, you cannot sue the ARTBNK Parties if anything happens to you or your property from using the
                      Service or interacting with any party through the Service. You agree that the provisions in this
                      Section 12.5 will survive any termination of your account(s), the Service, or this Agreement.
                    </li>
                  </ul>
                </li>
                <li className={classes.listItem}>
                  <h3 className={classes.textTitle}>13. Indemnification.</h3>
                  <P>You agree to defend, indemnify and hold harmless the ARTBNK Parties from and
                    against all liability, claims, actions and expenses, including attorneys' fees and costs, arising out
                    of your use of the Service or your breach or alleged breach of any term, condition, obligation,
                    representation or warranty in this Agreement, including without limitation your submitting or posting
                    any information, content or images without the right to do so. You agree that the provisions in this
                    paragraph will survive any termination of your account(s) the Service, or this Agreement.</P>
                </li>
                <li className={classes.listItem}>
                  <h3 className={classes.textTitle}>14. Copyright Policy; Objectionable Content.</h3>
                  <P>If you believe your rights have been violated by, or you otherwise object
                    to, any posting, content or information on the Service, please contact us promptly so we can evaluate
                    the claim and take appropriate action. If your complaint includes a claim of copyright infringement,
                    the following policy will apply:</P>
                  <ul className={classes.innerList}>
                    <li className={classes.listItem}>
                      <P>It is ARTBNK’s policy to respond promptly to claims of copyright
                        infringement, and to remove, or disable access to, infringing material. If you believe that any of
                        the content or materials appearing on this Service contain infringements, please send a notice to
                        our designated agent at the address stated below. Your notice should contain the following: a
                        physical or electronic signature of a person (i.e., claimant) authorized to act on behalf of the
                        copyright owner; identification of the copyrighted work claimed to be infringed; identification of
                        the content or material claimed to be infringing; a reference or link to the infringing material
                        or activity, or the subject of the infringing activity, including information to enable us to
                        locate that material or reference; the address, telephone number or email address of the claimant;
                        a statement that the claimant has a good faith belief that use of the material in the manner
                        complained of is not authorized by the copyright owner, its agent, or the law; and a statement
                        that the information in the notice is accurate and, under penalty of perjury, that the claimant is
                        authorized to act on behalf of the owner of an exclusive right that is allegedly infringed. ARTBNK
                        will also terminate a user's account if a user is deemed to be a repeat infringer, namely, one who
                        has been notified of bona fide infringing activity more than twice. Our designated agent, to whom
                        you should direct your infringement claim (or other complaints), is:</P>
                      <P><B>Marianne Cali, COO</B></P>
                      <P><B>ArtBnk LLC</B></P>
                      <P><B>98 Penhallow Street</B></P>
                      <P><B>Portsmouth, NH 03801</B></P>
                      <P><B><A mailto>marianne.cali@artbnk.com</A></B>
                      </P>
                    </li>
                  </ul>
                </li>
                <li className={classes.listItem}>
                  <h3 className={classes.textTitle}>15. Third Party Sites and Products.</h3>
                  <P>We may include links to third-party sites or services, or information about
                    third-party products or services. You should review the terms of use and privacy policies of all sites
                    and services that you link to from, or are referred to by, our Service. We do not endorse or take
                    responsibility for these third party offerings, whose service terms and policies may differ from ours.
                    We do not vet or take responsibility for third-party sites, services or products or the postings or
                    communications of other users. Even if any of these third parties is introduced as, or refers to
                    itself as, a partner or sponsored service, ARTBNK has no responsibility or liability for their
                    conduct, products or services.</P>
                </li>
                <li className={classes.listItem}>
                  <h3 className={classes.textTitle}>16. Governing Law/Waiver of Injunctive Relief.</h3>
                  <ul className={classes.innerList}>
                    <li className={classes.listItem}>16.1. This Agreement and all aspects of the Service will be governed
                      by and construed in accordance with the internal laws of the United States and the
                      State of New Hampshire governing contracts entered into and to be fully performed in New Hampshire
                      (thus, without regard to conflict of laws provisions) regardless of your location. With respect to any
                      disputes or claims not subject to informal dispute resolution or arbitration (as set forth below),
                      you agree not to commence or prosecute any action in connection therewith other than in the state or
                      federal courts located in Concord, New Hampshire, and you hereby consent to, and waive all defenses
                      of lack of personal jurisdiction and forum non conveniens with respect to, venue and jurisdiction in
                      the state and federal courts located in Concord, New Hampshire.
                    </li>
                    <li className={classes.listItem}>16.2. You acknowledge that the rights granted and obligations made
                      hereunder to ARTBNK are of a unique and irreplaceable nature, the loss of which will irreparably
                      harm ARTBNK and which cannot be replaced by monetary damages alone, so that ARTBNK will be entitled
                      to injunctive or other equitable relief (without the obligations of posting any bond or surety) in
                      the event of any breach or anticipatory breach by you. You irrevocably waive all rights to seek
                      injunctive or other equitable relief and agree to limit your claims to claims for monetary damages
                      (if any).
                    </li>
                    <li className={classes.listItem}>16.3. To expedite resolution and control the cost of any dispute,
                      controversy or claim related to this Agreement (<T>Dispute</T>), you and ARTBNK agree to first attempt to
                      negotiate any Dispute (except those Disputes expressly provided below) informally for at least
                      thirty (30) days before initiating any arbitration or court proceeding. Such informal negotiations
                      commence upon written notice from one person to the other. You will send your notice to ArtBnk LLC
                      at the address listed in Section 14 above, attention: Rob Steinberg, CEO.
                    </li>
                    <li className={classes.listItem}>16.4. <B>Mandatory Arbitration</B>. If you and ARTBNK are unable to resolve
                      a Dispute through informal negotiations within 30 days, either you or ARTBNK may elect to have the
                      Dispute (except those Disputes expressly excluded below) finally and exclusively resolved by binding
                      arbitration. Any election to arbitrate by one party will be final and binding on the other. YOU
                      UNDERSTAND THAT ABSENT THIS PROVISION, YOU WOULD HAVE THE RIGHT TO SUE IN COURT AND HAVE A JURY
                      TRIAL. The arbitration will be commenced and conducted under the Streamlined Arbitration Rules and
                      Procedures (the <T>Rules</T>) of JAMS, which is available at the JAMS
                      website <A href="http://www.jamsadr.com/">www.jamsadr.com</A>. The
                      determination of whether a Dispute is subject to arbitration will be governed by the Federal
                      Arbitration Act and determined by a court rather than an arbitrator. Your arbitration fees and your
                      share of arbitrator compensation will be governed by the Rules. The arbitration may be conducted in
                      person, through the submission of documents, by phone or online. The arbitrator will make a decision
                      in writing, but need not provide a statement of reasons unless requested by a party. The arbitrator
                      must follow applicable law, and any award may be challenged if the arbitrator fails to do so.
                    </li>
                    <li className={classes.listItem}>16.5. Notwithstanding the above, you and ARTBNK agree that
                      arbitration will be limited to the Dispute between ARTBNK and you individually. To the full extent
                      permitted by law, (a) no arbitration will be joined with any other; (b) there is no right or
                      authority for any Dispute to be arbitrated on a class-action basis or to utilize class action
                      procedures; and (c) there is no right or authority for any Dispute to be brought in a purported
                      representative capacity on behalf of the general public or any other persons.
                    </li>
                    <li className={classes.listItem}>16.6. You and ARTBNK agree that the following Disputes are not
                      subject to the above provisions concerning informal negotiations and binding arbitration: (a) any
                      Disputes seeking to enforce or protect, or concerning the validity of, any of your or ARTBNK’s
                      intellectual property rights; (b) any Dispute related to, or arising from, allegations of theft,
                      piracy, invasion of privacy or unauthorized use; and (c) any claim for injunctive relief or to
                      compel arbitration, stay proceedings pending arbitration, or to confirm, modify, vacate or enter
                      judgment on the award entered by the arbitrator.
                    </li>
                    <li className={classes.listItem}>16.7. To the extent that the Service is available to individuals
                      located in the European Economic Area and the United Kingdom, we are bound by and act in
                      compliance with the GDPR and may be subject to investigative and corrective powers of the
                      relevant supervisory authority. The relevant supervisory authority is the public authority
                      established in the relevant territory within the European Economic Area and/or
                      the United Kingdom and which is responsible for monitoring the application of the GDPR
                      within that territory.
                    </li>
                  </ul>
                </li>
                <li className={classes.listItem}>
                  <h3 className={classes.textTitle}>17. Waiver/Severability.</h3>
                  <ul className={classes.innerList}>
                    <li className={classes.listItem}>17.1. The failure of ARTBNK to require or enforce strict performance
                      by you of any provision of this Agreement or to exercise any right under any provision of this
                      Agreement will not be construed as a waiver or relinquishment of ARTBNK’s right to assert or rely
                      upon any such provision or right in that or any other instance.
                    </li>
                    <li className={classes.listItem}>17.2. You and ARTBNK agree that if any portion of this Agreement,
                      except any portion of Section 16.6, is found illegal or unenforceable, in whole or in part by any
                      court of competent jurisdiction, such provision will, as to such jurisdiction, be ineffective to the
                      extent of such determination of invalidity or unenforceability without affecting the validity or
                      enforceability thereof in any other manner or jurisdiction and without affecting the remaining
                      provisions of this Agreement, which will continue to be in full force and effect. If Section 16.6 is
                      found to be illegal or unenforceable, then neither you nor ARTBNK will elect to arbitrate any
                      Dispute falling within that portion of Section 16.6 found to be illegal or unenforceable and such
                      Dispute will be decided by a court of competent jurisdiction within Concord, New Hampshire, and you
                      and ARTBNK agree to submit to the personal jurisdiction of that court.
                    </li>
                  </ul>
                </li>
                <li className={classes.listItem}>
                  <h3 className={classes.textTitle}>18. Term and Termination.</h3>
                  <P>This Agreement is effective until terminated by either party. You may
                    terminate this Agreement by destroying all Service-related materials obtained from the Service, ARTBNK
                    or other source. We reserve the right to limit, terminate or suspend your account and/or your access
                    to or use of any or all of our Services, at any time at our sole discretion, if we believe you are
                    violating the rights of any third party, or you have failed to comply with any term or provision of
                    this Agreement, or for any reason in ARTBNK’s sole discretion. Following the termination of this
                    Agreement, your account(s), or the Service, you will lose all access to your account information,
                    unless you have migrated and saved it to another system prior to termination. ARTBNK normally retains
                    user account information for 12 months after termination, during which time the user may renew
                    his/her account and resume access; but we reserve the right to delete all of your account information
                    immediately following termination and make no guaranty that the account information will be retained
                    for any period after termination. ARTBNK shall retain all rights to the Submissions pursuant to this
                    Agreement.</P>
                </li>
                <li className={classes.listItem}>
                  <h3 className={classes.textTitle}>19. Miscellaneous.</h3>
                  <P>ARTBNK operates and controls the Service from its offices in the United
                    States. ARTBNK makes no representation that the Service is appropriate or available in other
                    locations. The information provided on the Service is not intended for distribution to or use by any
                    person or entity in any jurisdiction or country where such distribution or use would be contrary to
                    law or regulation or which would subject ARTBNK to any registration requirement within such
                    jurisdiction or country. Accordingly, those persons who choose to access the Service from other
                    locations do so on their own initiative and are solely responsible for compliance with local laws, if
                    and to the extent local laws are applicable. The privileges granted to you under this Agreement,
                    including without limitation access to the Service directly or through Facebook, Google, or other
                    online channel, will terminate immediately and automatically without notice from ARTBNK if, in our sole
                    discretion, you fail to comply with any term or provision of this Agreement or for any reason in
                    ARTBNK’s sole discretion. Neither the course of conduct between the parties nor trade practice will
                    act to modify this Agreement. ARTBNK may assign this Agreement to any party at any time without any
                    notice to you. You may not assign this Agreement without ARTBNK’s prior written consent. This
                    Agreement (including the Privacy Policy) contains the entire understanding of you and ARTBNK’s,
                    and supersedes all prior understandings between the parties concerning its subject matter, and cannot
                    be changed or modified by you. The section headings used in this Agreement are for convenience only
                    and will not be given any legal import. Upon ARTBNK’s request, you will furnish ARTBNK with any
                    documentation, substantiation or releases necessary to verify your compliance with this Agreement. You
                    agree that this Agreement will not be construed against ARTBNK by virtue of having drafted it. You
                    hereby waive any and all defenses you may have based on the electronic form of this Agreement and the
                    lack of signing by the parties hereto to execute this Agreement.</P>
                </li>
                <li className={classes.listItem}>
                  <h3 className={classes.textTitle}>20. Statute of Limitations.</h3>
                  <P> You and ARTBNK both agree that regardless of any statute or law to the
                    contrary, any claim or cause of action arising out of or related to use of the Service or this
                    Agreement (including the Privacy Policy) must be filed within ONE (1) YEAR after such claim or cause
                    of action arose or will be forever barred.</P>
                </li>
              </ul>
            </div>
            <div className={classes.footer}>
              <button type="button" className={cx(classes.button, 'Popup-SignUp-Terms cancel')} onClick={this.onCancel} children="Cancel" />
              <button type="button" className={cx(classes.button, 'Popup-SignUp-Terms submit')} onClick={this.onAccept} children="I agree" />
            </div>
          </div>
        </Popup>
    );
  }
}

export default compose(
  connect({
    isPopupVisible: isPopupVisibleSelector,
    hidePopup: hidePopupAction,
    onAccept: termsAcceptAction,
  }),
  injectSheet(sheet),
)(TermsPopup);
