import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { compose } from 'redux';
import { button } from 'theme/button';
import injectSheet from 'lib/sheet';

const sheet = {
  button,
  DownloadAOPDF: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
  },
  buttonWrapper: {
    textAlign: 'right',
  },
};

function DownloadAOPDF({ children: artistInfo, classes, togglePDFReport, readyToGeneratePDF, PDFReportShown, allowGenerateReport }) {
  return (
    <div className={classes.DownloadAOPDF}>
      {artistInfo}
      <div className={classes.buttonWrapper}>
        {
          !!allowGenerateReport &&
            <button
              className={cx(classes.button, 'secondary2')}
              onClick={() => togglePDFReport()}
              type="button"
              disabled={!readyToGeneratePDF || PDFReportShown}
              children="Download Report"
            />
        }
      </div>
    </div>
  );
}

DownloadAOPDF.propTypes = {
  allowGenerateReport: PropTypes.bool,
  children: PropTypes.node,
  classes: PropTypes.shape({
    DownloadAOPDF: PropTypes.string.isRequired,
    buttonWrapper: PropTypes.string.isRequired,
    button: PropTypes.string.isRequired,
  }).isRequired,
  togglePDFReport: PropTypes.func.isRequired,
  readyToGeneratePDF: PropTypes.bool,
  PDFReportShown: PropTypes.bool,
};

export default compose(
  injectSheet(sheet),
)(DownloadAOPDF);
