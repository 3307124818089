import { fork, put, select, takeEvery, call } from 'redux-saga/effects';
import shareWatcher from './share';
import watchNavigate from './navigator.js';
import watchRouter from './router';
import watchConfirmActions from './confirm';
import { redirectToPageWatcher } from 'lib/redirectToPage';
import { ensureDictionaries } from 'domain/dictionary/sagas';
import { watchRemoveRestore } from './remove';
import { resetFilterParamsAction, updateFilterParamsAction } from 'domain/ui';
import { pathnameChanged } from 'domain/router/RouterModel';
import { setCommonSurfacesAction } from 'domain/surface';
import { setCommonSubstratesAction } from 'domain/substrate';
import { watchSearchArtistList } from 'domain/globalSearch/sagas';
import { ensureCurrentPlan, stripeInit, onCheckAuth } from 'domain/env/sagas';
import { checkAuthAction, loginAction, logOut } from 'domain/env/EnvActions';
import { user_sel } from 'domain/env/EnvModel';
import { initIntercom } from 'lib/intercomChat';
import { initGa } from 'lib/googleAnalytics';
import { upgradeSubscriptionWatcher } from 'pages/upgradeSubscriptionPage/sagas';
import { advancedFilterWatcher } from 'domain/advancedFilter/sagas';
import debugToolsWatcher from 'sagas/debug';
import Socket from 'components/ws';

function* updateFilterParams({ payload: { range } }) {
  const { valuationMin: priceFrom, valuationMax: priceTo } = range;
  yield put(updateFilterParamsAction({ priceFrom, priceTo }));
}

function* checkFilters() {
  if (yield select(pathnameChanged)) {
    yield put(resetFilterParamsAction());
  }
}

function* clearSupposedFields() {
  if (yield select(pathnameChanged)) {
    yield put(setCommonSurfacesAction([], 'rootSaga on pathnameChanged'));
    yield put(setCommonSubstratesAction([], 'rootSaga on pathnameChanged'));
  }
}

function* watchFilterParams(){
  yield takeEvery('@@router/LOCATION_CHANGE', checkFilters);
  yield takeEvery(({ payload }) => payload && payload.range, updateFilterParams);
}

function* watchAuth() {
  yield takeEvery(checkAuthAction.type, onCheckAuth);
  yield takeEvery([checkAuthAction.success, loginAction.success], function* () {
    const userData = yield select(user_sel);
    if (!userData || !userData.token) {
      console.warn('Bad user data on auth event in root saga', userData);
      return;
    }
    yield call(initIntercom, userData.toJS());
    yield call(initGa, userData.toJS());
    try {
      yield call(Socket.connect, userData.token);
    } catch (err) {
      console.error(err);
    }
    yield fork(ensureCurrentPlan);
  });
  yield takeEvery(logOut.type, function* () {
    yield call(Socket.disconnect);
  });
}

export default function* rootSaga() {
  yield fork(debugToolsWatcher);
  yield takeEvery('@@router/LOCATION_CHANGE', clearSupposedFields);
  yield fork(stripeInit);
  yield fork(upgradeSubscriptionWatcher);
  yield fork(watchNavigate);
  yield fork(watchRouter);
  yield fork(shareWatcher);
  yield fork(redirectToPageWatcher);
  yield fork(watchRemoveRestore);
  yield fork(watchConfirmActions);
  yield fork(ensureDictionaries);
  yield fork(watchFilterParams);
  yield fork(watchSearchArtistList);
  yield fork(watchAuth);
  yield fork(advancedFilterWatcher);
}
