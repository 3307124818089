import { action, asyncAction } from 'lib/actions';

// import {  } 'pages/common/artworkForm/ArtworkFormActions';
export const fillArtworkFormAction = action('artworkForm/FILL_DATA');
export const similarSearchAction = asyncAction('artworkForm/SIMILAR_SEARCH');
export const clearSuggestionsAction = action('artworkForm/CLEAR_SUGGESTIONS');
export const submitUnifiedFormAction = action('artworkForm/SUBMIT_UNIFIED');
export const submitUnifiedDraftFormAction = action('artworkForm/SUBMIT_UNIFIED_AS_DRAFT');
export const setImageCropAction = action('artworkForm/SET_IMAGE_CROP');
export const replacePictureInFormAction = action('artworkForm/REPLACE_IMAGE');
