import React from 'react';
import PropTypes from 'prop-types';
// import { connect } from 'cpcs-reconnect';
import { compose } from 'redux';
import cx from 'classnames';
// const
// import { SIGNUP_PRIVACY_POPUP, SIGNUP_TERMS_POPUP } from 'domain/const';
// actions
// import { addPopupAction } from 'domain/ui';
// styles
import injectSheet from 'lib/sheet';
import { sheet } from './PrivacyAndTermsLabel.jss.js';

class PrivacyAndTermsLabel extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    // showTermPopup: PropTypes.func.isRequired,
    // showPrivacyPopup: PropTypes.func.isRequired,
  };

  // showPrivacyPopup = (e) => {
  //   e.stopPropagation();
  //   e.preventDefault();
  //   this.props.showPrivacyPopup();
  // };

  // showTermPopup = (e) => {
  //   e.stopPropagation();
  //   e.preventDefault();
  //   this.props.showTermPopup();
  // };

  render() {
    const { classes } = this.props;
    return (
      <span className={classes.PrivacyAndTermsLabel}>
        I have read and agree to the
        {' '}
        {/* * /}
        import Button from 'components/form/button';
        <Button
          type="button"
          onClick={this.showPrivacyPopup}
          children="Privacy Policy"
          className={classes.btn}
        />
        {/* */}
        <a
          href="https://www.artbnk.com/privacy-policy"
          target="_blank"
          rel="noopener noreferrer"
          children="Privacy Policy"
          className={cx(classes.button, 'PrivacyAndTermsLabel tems-link', 'privacyTermsBtn')}
        />
        {' '}
        and
        {' '}
        {/* * /}
        <Button
          type="button"
          onClick={this.showTermPopup}
          children="Subscription Terms"
          className={classes.btn}
        />
        {/* */}
        <a
          href="https://www.artbnk.com/subscription-terms"
          target="_blank"
          rel="noopener noreferrer"
          children="Subscription Terms"
          className={cx(classes.button, 'PrivacyAndTermsLabel tems-link', 'privacyTermsBtn')}
        />
      </span>
    );
  }
}

export default compose(
  injectSheet(sheet),
  // connect({
  //   showTermPopup: () => addPopupAction({ name: SIGNUP_TERMS_POPUP }),
  //   showPrivacyPopup: () => addPopupAction({ name: SIGNUP_PRIVACY_POPUP }),
  // }),
)(PrivacyAndTermsLabel);
