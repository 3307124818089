import { fork, call, put, takeEvery, select } from 'redux-saga/effects';
import {
  getSharedWithMeList_apiGen,
  getSharedByMeList_apiGen,
  watchRemoveShare,
  removeEmailsFromShare,
} from 'domain/shares/sagas';
import {
  removeEmailsFromShareAction,
  removeShareAction,
  removeMeFromShareAction,
  sortSharedByMeAction,
  sortSharedWithMeAction,
} from 'domain/shares/ShareActions';
import {
  sharedByMeSel,
} from 'domain/shares/ShareModel';
import { filterParamsSelector } from 'domain/router/RouterModel';
import { confirmAction } from 'domain/ui/UIActions';

function* fetchSharedByMe() {
  const params = yield select(filterParamsSelector);
  yield call(getSharedByMeList_apiGen.catchError, { page: 0, size: 5, ...params });
}

function* fetchSharedWithMe() {
  const params = yield select(filterParamsSelector);
  yield call(getSharedWithMeList_apiGen.catchError, { page: 0, size: 5, ...params });
}

function* updateList() {
  yield fork(fetchSharedWithMe);
  yield fork(fetchSharedByMe);
}

export function* onRemoveEmailsFromShare(action) {
  const list = yield select(sharedByMeSel);
  const share = list.find(v => v.get('id') === action.payload.shareId);
  if (share && share.get('emails').size === 1) {
    const confirmQuestion = 'It is last email, share will be removed. Are you sure?';
    yield put(confirmAction(confirmQuestion, removeShareAction, { id: action.payload.shareId }));
  } else {
    yield fork(removeEmailsFromShare, action.payload);
  }
}

export default function* watchNavigate() {
  yield fork(fetchSharedWithMe);
  yield fork(fetchSharedByMe);
  yield fork(watchRemoveShare);
  yield takeEvery(removeShareAction.success, updateList);
  yield takeEvery(removeMeFromShareAction.success, updateList);
  yield takeEvery(removeEmailsFromShareAction.type, onRemoveEmailsFromShare);
  yield takeEvery(removeEmailsFromShareAction.success, fetchSharedWithMe);
  yield takeEvery(sortSharedByMeAction.type, fetchSharedByMe);
  yield takeEvery(sortSharedWithMeAction.type, fetchSharedWithMe);
}
