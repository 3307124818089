import React from 'react';
import PropTypes from 'prop-types';
import waterfall from 'async/waterfall';
import config from 'config';
const log = require('lib/helpers').getLogger('pages/authPage/form/login/googleLoginButton/googleApiDecorator.js');

const SDK_ID = 'google-jssdk';
const SDK_SRC = 'https://apis.google.com/js/platform.js?onload=renderGoogleLoginButton';
const CLIENT_ID = config.auth.providers.google.appId;
const GOOGLE_AUTH_SCOPE = 'profile email';
const GOOGLE_AUTH_UX_MODE = 'popup'; // redirect popup

let GoogleAuth;

const onComponentDidMount = (component) => {
  const render = () => {
    component.allowUsingGoogleAuth();
  };
  waterfall([
      // wait for gapi
      (cb) => {
        global.renderGoogleLoginButton = () => cb();
        if (global.gapi) {
          cb();
        }
      },
      // wait for gapi.auth2
      (cb) => {
        if (global.gapi.auth2) {
          cb();
        } else {
          global.gapi.load('auth2', () => cb());
        }
      },
      // init auht2
      (cb) => {
        if (GoogleAuth) {
          cb();
        } else {
          global.gapi.auth2
            .init({ client_id: CLIENT_ID })
            .then(
              (o) => {
                GoogleAuth = o;
                cb();
              },
              (err) => {
                cb(err);
              },
            );
        }
      },
    ],
    (err) => {
      if (err) {
        log.warn(err);
      } else {
        render();
      }
    },
  );
};

const addGoogleSDK = (id, src) =>
  (function(d, s) {
    const fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) return;
    let js = d.createElement('script');
    js.id = id;
    js.src = src;
    js.async = 'true';
    fjs.parentNode.insertBefore(js, fjs);
  }(global.document, 'meta'));

export default function googleApiDecorator(WrappedComponent) {
  class GoogleAuthApi extends React.Component {
    static propTypes = {
      onGoogleSignIn: PropTypes.func.isRequired,
    };

    state = {
      showLogoutButton: false,
      showLoginButton: false,
      /**
       * one of [popup_closed_by_user, access_denied, immediate_failed, popup_blocked_by_browser]
      **/
      lastError: null,
    };

    componentDidMount() {
      onComponentDidMount(this);
      addGoogleSDK(SDK_ID, SDK_SRC);
    }

    allowUsingGoogleAuth() {
      this.setState({
        showLoginButton: true,
        showLogoutButton: this.isSignedIn,
      });
    }

    get initialized() {
      return GoogleAuth &&
        GoogleAuth.currentUser &&
        GoogleAuth.isSignedIn &&
        GoogleAuth.signIn &&
        global.gapi &&
        global.gapi.auth2;
    }

    get googleUser() {
      return this.initialized && GoogleAuth.currentUser.get();
    }

    get profile() {
      if (!this.initialized) return null;
      const profile = this.googleUser.getBasicProfile();
      if (!profile) return null;
      const authResponse = this.googleUser.getAuthResponse();
      return {
        id: profile.getId(),
        fullName: profile.getName(),
        email: profile.getEmail(),
        name: profile.getGivenName(),
        lastName: profile.getFamilyName(),
        idToken: authResponse.id_token,
        accessToken: authResponse.access_token,
      };
    }

    get isSignedIn() {
      return this.initialized && GoogleAuth.isSignedIn.get();
    }

    onFailure = (error) => {
      const { onGoogleSignIn } = this.props;
      log.error(error);
      onGoogleSignIn(error);
      this.setState({ lastError: error });
    }

    onSuccess = () => {
      const { onGoogleSignIn } = this.props;
      this.setState({ showLogoutButton: true });
      // dispatch action
      onGoogleSignIn(this.profile);
    }

    signIn = () => {
      if (!this.initialized) return;
      if (this.isSignedIn) {
        this.onSuccess();
      } else {
        GoogleAuth
          .signIn({
            scope: GOOGLE_AUTH_SCOPE,
            ux_mode: GOOGLE_AUTH_UX_MODE, // redirect, popup
          })
          .then(this.onSuccess, this.onFailure);
      }
    }

    onLogout = () => {
      global.gapi.auth2.getAuthInstance().signOut().then(() => {
        this.setState({ showLogoutButton: false });
      });
    }

    render() {
      const { showLoginButton, showLogoutButton } = this.state;
      return (
        <WrappedComponent
          {...this.props}
          profile={this.profile}
          isSignedIn={this.isSignedIn}
          onLogin={this.signIn}
          onLogout={this.onLogout}
          showLoginButton={showLoginButton}
          showLogoutButton={showLogoutButton}
        />
      );
    }
  }
  return GoogleAuthApi;
}
