import { DARK_BLUE_COLOR, DEFAULT_FONTFAMILY } from 'theme/theme';
import { BADGE_FILLET_Z_INDEX } from 'theme/zIndex';
import { imageBox, images, smallImage } from 'pages/common/ItemList/Badges/sheet';
import { responsiveListTheme_Item } from 'theme/layout/responsiveList';

export default {
  badge: {
    position: 'relative',
    textDecoration: 'none',
    ...responsiveListTheme_Item,
  },
  content: {
    width: '100%',
    padding: [10, 0],
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    position: 'relative',
    fontFamily: DEFAULT_FONTFAMILY,
  },
  link: {
    textDecoration: 'none',
    display: 'block',
    color: DARK_BLUE_COLOR,
  },
  images,
  smallImage,
  imageBox,
  fillet: {
    position: 'absolute',
    top: 0,
    zIndex: BADGE_FILLET_Z_INDEX,
  },
};
