import { put, call, select, fork, takeEvery, take } from 'redux-saga/effects';
import * as M from 'domain/ownArtwork/OwnArtworkModel';
import { artworksPagination as artworksPaginationSelector } from 'domain/ownArtwork/OwnArtworkModel';
import { addNotification, checkAuthAction } from 'domain/env/EnvActions';
import { createRTVWatcher } from 'components/ws/saga';
import {
  setSelectedAction,
  updateSelectedAction,
  ownArtworkAppendPageAction,
} from 'domain/ownArtwork/OwnArtworkActions';
// end
import { setLinkAction, err404Action } from 'domain/ui/UIActions';
import { needResetSelected, pathNameChanged } from 'domain/ui/sagas';
import { toggleByIdInSet } from 'lib/helpers';
import {
  editGalleryAction,
  clearGaleries,
  loadGalleriesBidirectionalAction,
  setCoverAction,
} from 'domain/galleries/GalleryActions';
import { allWorksGalleryId_sel, currentGalleryId_sel } from 'domain/env/EnvModel';
import watchRemoveSelected from './sagas-common';
import {
  DEFAULT_PAGE_SIZE,
  SHARE_TYPE_OWN_ARTWORK,
  TYPE_GALLERY,
  restoreAction,
} from 'domain/const';
import {
  ensureGalleries_apiGen,
  onLoadGalleriesBidirectional,
  setGalleryCover_apiGen,
  watchCreateGalleryBySelection,
  watchAddToGalleryPopup,
  onEditGallery,
  getGalleryPage,
} from 'domain/galleries/sagas';
import {
  deSelectArtwork,
  getGalleryArtworks_apiGen,
  galleryArtworkListAppendPage_apiGen,
  updateListItem,
} from 'domain/ownArtwork/sagas';

import * as Routing from 'domain/router/RouterModel';
import { shareSelectedAction } from 'pages/common/selectedItemsControls/actions';
import { showSharePopupAction, shareEntitiesAction } from 'domain/shares/ShareActions';
import {
  advancedFilterPageInit,
} from 'domain/advancedFilter/sagas';

function getShareType() {
  return SHARE_TYPE_OWN_ARTWORK;
}

export function* showSharePopup() {
  const ids = yield select(Routing.selectedList);
  const shareType = yield call(getShareType);
  yield put(showSharePopupAction({
    shareType,
    ids,
  }));
}

function* onShareResult() {
  const shareType = yield call(getShareType);
  // shareType can be SHARE_TYPE_GALLERY
  if (shareType === SHARE_TYPE_OWN_ARTWORK) {
    yield call(deSelectArtwork);
  }
}

export function* selectArtwork({ payload }) {
  const idsSet = yield select(M.selected);
  yield put(
    setSelectedAction(
      toggleByIdInSet({ id: payload, idsSet }),
    ),
  );
}

function* watchSelect() {
  yield takeEvery(updateSelectedAction.type, selectArtwork);
}

export function* setGalleryLink() {
  yield put(setLinkAction({ component: 'gallery', route: 'collectionListPage' }));
}

function* setLinks() {
  yield fork(setGalleryLink);
}

function* setCover({ payload: { galleryId, artworkId } }) {

  let gId = parseInt(galleryId, 10);

  if (galleryId === 'all') {
    gId = yield select(allWorksGalleryId_sel);
  }
  yield call(setGalleryCover_apiGen.catchError, { galleryId: gId, artworkId });
  yield put(addNotification({ title: 'Gallery cover updated.', type: 'success' }));
}

function* onRestoreGallery({ payload: { type, params } }) {
  if (type === TYPE_GALLERY) {
    const page = yield call(getGalleryPage, params.galleryId);
    yield fork(ensureGalleries_apiGen, page);
  }
}

export function* watchGalleriesAction() {
  yield takeEvery(setCoverAction.type, setCover);
  yield takeEvery(editGalleryAction.type, onEditGallery);
  yield takeEvery(loadGalleriesBidirectionalAction.type, onLoadGalleriesBidirectional);
  yield takeEvery(restoreAction.type, onRestoreGallery);
  yield fork(watchCreateGalleryBySelection);
  yield fork(watchAddToGalleryPopup);
}

function* getGalleryItems(page = 0) {
  const filter = yield select(Routing.filterParamsSelector);
  const gId = yield select(currentGalleryId_sel);
  try {
    if (page > 0) {
      yield call(galleryArtworkListAppendPage_apiGen, { ...filter, gId, page, size: DEFAULT_PAGE_SIZE });
    } else {
      yield call(getGalleryArtworks_apiGen, { ...filter, gId, page, size: DEFAULT_PAGE_SIZE });
    }
  } catch (err) {
    console.error(err);
  }
}

export function* onPagination() {
  const { page, loading } = yield select(artworksPaginationSelector);
  if (loading) return;
  yield call(getGalleryItems, page + 1);
}

export default function* collectionListPage() {
  if (yield call(pathNameChanged)) yield put(clearGaleries());
  let galleryId;
  do {
    galleryId = yield select(currentGalleryId_sel);
    if (galleryId && !isFinite(galleryId)) {
      yield put(err404Action(true));
      return;
    }
    if (!galleryId) {
      yield take(checkAuthAction.success);
    }
  } while (!galleryId);
  if (yield call(needResetSelected)) {
    yield fork(deSelectArtwork);
  }
  yield fork(advancedFilterPageInit);
  yield fork(ensureGalleries_apiGen);
  yield takeEvery(ownArtworkAppendPageAction.type, onPagination);
  yield takeEvery(shareSelectedAction.type, showSharePopup);
  yield takeEvery(shareEntitiesAction.success, onShareResult);
  yield fork(setLinks);
  yield call(getGalleryItems);
  yield fork(watchRemoveSelected);
  yield fork(watchSelect);
  yield fork(watchGalleriesAction);
  yield fork(createRTVWatcher({
    fetchItem: artworkId => updateListItem({ payload: { artworkId } }),
  }));
}
