import { call, select, fork, takeEvery } from 'redux-saga/effects';

import { reduceCallFrequency } from 'lib/saga-helpers';
import Api, { makeApiGenerator, callApi } from 'domain/api';
import { DEFAULT_PAGE_SIZE } from 'domain/const';
import {
  getTimestamp,
} from 'lib/helpers';
import {
  rtvPaginationSelector,
} from 'domain/rtvValueListPage/RtvValueListPageModel';
import {
  rtvValueListAppendPageAction,
  rtvValueListFetchAction,
  downloadRtvPdfAction,
} from 'domain/rtvValueListPage/RtvValueListPageActions';
import { sortValueSelector } from 'domain/router/RouterModel';
import download from 'downloadjs';

const rtvListFetch_apiGen = makeApiGenerator({ actionCreator: rtvValueListFetchAction, api: Api.rtvValueList });
const rtvListAppendPage_apiGen = reduceCallFrequency(makeApiGenerator({
  actionCreator: rtvValueListAppendPageAction,
  api: Api.rtvValueList,
}), 2100);

function* fetchPageData(page = 0) {
  const sort = yield select(sortValueSelector);
  try {
    if (page > 0) {
      yield call(rtvListAppendPage_apiGen, { params: { sort, page, size: DEFAULT_PAGE_SIZE } });
    } else {
      yield call(rtvListFetch_apiGen, { params: { sort, page, size: DEFAULT_PAGE_SIZE } });
    }
  } catch (err) {
    console.error(err);
  }
}

function* onPagination() {
  const { page, loading } = yield select(rtvPaginationSelector);
  if (loading) return;
  yield call(fetchPageData, page + 1);
}

const matchFileName = (contentDisposition = '', defaultFileName = 'rtv.pdf') => {
  const m = contentDisposition.match(/filename=(.*)/);
  return (m && m[1]) || defaultFileName;
};

export function* onDownloadRtvPdf({ payload: { artwork } }) {
  try {
    const { data, headers } = yield callApi(Api.downloadRtvPdf, { data: { artworkId: artwork.id }, params: { timestamp: getTimestamp() } });
    const filename = matchFileName(headers['content-disposition']);
    download(data, filename, 'application/pdf');
  } catch (err) {
    console.error(err);
  }
}

export default function* watchNavigate() {
  yield takeEvery(rtvValueListAppendPageAction.type, onPagination);
  yield fork(fetchPageData);
  yield takeEvery(downloadRtvPdfAction.type, onDownloadRtvPdf);
}
