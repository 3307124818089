export async function getSVG(charts, options, more) {
  let svgArr = [];
  let width = 0;
  let height = 0;
  function addSVG(svgres) {
      // Grab width/height from exported chart
      const svgWidth = + svgres.match(/^<svg[^>]*width\s*=\s*"?(\d+)"?[^>]*>/)[1];
      const svgHeight = + svgres.match(/^<svg[^>]*height\s*=\s*"?(\d+)"?[^>]*>/)[1];
          // Offset the position of this chart in the final SVG
      let svg = svgres.replace('<svg', '<g transform="translate(0,' + height + ')" ');
      svg = svg.replace('</svg>', '</g>');
      // console.log({ width, svgWidth, svgres: { svgres } });
      width = Math.max(width, svgWidth);
      height += svgHeight;
      svgArr.push(svg);
  };
  let resolve;
  let promise = new Promise(r => resolve = r);
  function exportChart(i) {
      if (i === charts.length) {
        // const svg = [
        //   `<svg height="${height}" width="${width}" version="1.1" xmlns="http://www.w3.org/2000/svg">`,
        //   svgArr.join(''),
        //   '</svg>',
        // ].join('');
        // return callback({ svg, width, height });
        // resolve({ svg, width, height });
        return resolve({ svg: svgArr.join(''), width, height, ...more });
      }
      if (!charts[i] || !charts[i].current) return exportChart(i + 1);
      charts[i].current.chart.getSVGForLocalExport(options, {}, function onError(err) {
          console.log('Failed to get SVG', i, charts);
          console.error(err);
      }, function onSuccess(svg) {
          addSVG(svg);
          // console.log('onSuccess', { i, width, height, charts, options });
          return exportChart(i + 1); // Export next only when this SVG is received
      });
  };
  exportChart(0);
  return promise;
};
