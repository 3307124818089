import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'cpcs-reconnect';
import { connect as reduxConnect } from 'react-redux';
import { compose } from 'redux';
import injectSheet from 'lib/sheet';
import styles from './sheet.js';
import { onFacebookSignInAction } from 'domain/env/EnvActions';
import facebookApiDecorator from './facebookApiDecorator';
import config from 'config';

const APP_ID = config.auth.providers.facebook.appId;

class FbLoginButton extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    authAction: PropTypes.func.isRequired,
    FB: PropTypes.object,
  };

  openFBLogin = () => {
    this.props.FB.login(this.onFBLogin, { scope: 'public_profile,email' });
  }

  onFBLogin = (response) => {
    const { authAction } = this.props;
    authAction({ response });
  }

  render() {
    const { classes, FB } = this.props;
    return (
      <button
        className={classes.FbLoginButton}
        type="button"
        onClick={this.openFBLogin}
        disabled={!FB}
      />
    );
  }
}

export {
  FbLoginButton,
};

export default compose(
  connect({
    authAction: onFacebookSignInAction,
  }),
  reduxConnect(() => ({
    // pass APP_ID to facebookApiDecorator as prop
    APP_ID,
  })),
  facebookApiDecorator,
  injectSheet(styles),
)(FbLoginButton);
