import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'cpcs-reconnect';
import I from 'immutable';
import ItemList from 'pages/common/ItemList';
import { lnk } from 'lib/routes';
import { query_sel } from 'domain/router/RouterModel';
import { sharedArtworkAppendPageAction } from 'domain/shares/ShareActions';
import {
  artworksPagination_sel,
  getSharedArtworkList_sel,
} from 'domain/shares/ShareModel';

class ArtworkList extends React.PureComponent {
  static propTypes = {
    list: PropTypes.shape({
      toList: PropTypes.func.isRequired,
    }).isRequired,
    match: PropTypes.shape({
      params: PropTypes.object.isRequired,
    }).isRequired,
    pagination: PropTypes.instanceOf(I.Record),
    loadMore: PropTypes.func.isRequired,
    query: PropTypes.shape({
      prevPage: PropTypes.string,
    }),
  };

  render() {
    const { pagination, loadMore, match: { params: { shareId } }, list, query: { prevPage }, ...props } = this.props;
    const query = prevPage ? { prevPage } : {};
    return (
      <ItemList
        linkBuilder={(item) => lnk('sharedArtwork', { shareId, artworkId: item.get('id'), query })}
        list={list.toList()}
        pagination={pagination}
        loadMore={loadMore}
        {...props}
      />
    );
  }
}

export default connect({
  list: getSharedArtworkList_sel,
  pagination: artworksPagination_sel,
  loadMore: sharedArtworkAppendPageAction,
  query: query_sel,
})(ArtworkList);
