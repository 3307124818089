import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { connect } from 'cpcs-reconnect';
import { compose } from 'redux';
import { loading as loadingSelector } from 'domain/ui/UIModel';
import injectSheet from 'lib/sheet';
import styles from './sheet.js';

class Loader extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    loading: PropTypes.bool,
    loadingAuto: PropTypes.bool.isRequired,
    children: PropTypes.node,
    modifier: PropTypes.string,
  };

  render() {
    const { classes, loadingAuto, modifier, children } = this.props;
    const { loading = loadingAuto } = this.props;
    if (!loading) return children;
    return (
      <div
        className={cx(classes.Loader, modifier)}
      >
        <div className={cx(classes.loader, modifier)} />
      </div>
    );
  }
}

export default compose(
  injectSheet(styles),
  connect({
    loadingAuto: loadingSelector,
  }),
)(Loader);
