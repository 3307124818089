import { DEFAULT_BUTTON_COLOR, DARK_BLUE_COLOR, DEFAULT_FONTFAMILY } from 'theme/theme';

export default {
  ShareComment: {
    // root
    whiteSpace: 'normal',
    color: DARK_BLUE_COLOR,
    fontFamily: DEFAULT_FONTFAMILY,
    fontSize: 14,
    fontStyle: 'italic',
    fontWeight: 400,
    display: 'block',
  },
  text: {
    
  },
  expandBtnColumn: {
    
  },
  toggleCollapseBtn: {
    display: 'block',
    color: DEFAULT_BUTTON_COLOR,
    fontFamily: DEFAULT_FONTFAMILY,
    fontSize: 14,
    fontWeight: 400,
    cursor: 'pointer',
  },
};
