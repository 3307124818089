import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';

import { Definition } from 'components/reports/Definition';

import injectSheet from 'lib/sheet';

const AFPMDef = 'artistFinancialPerformanceMetricsDefinition';

function ArtistFinancialPerformanceMetricsDefinitions_NoTheme({ classes }) {
  return (
    <div className={classes.ArtistFinancialPerformanceMetricsDefinitions}>
      <div className={classes.column}>
        <Definition modifier={AFPMDef} id="T001_LIQUIDITY_LEVEL" />
        <Definition modifier={AFPMDef} id="T001_ARTIST_MEDIAN_COMPOUND_GROWTH_RATE" />
        <Definition modifier={AFPMDef} id="T001_REPEAT_SALES_RANKING" />
      </div>
      <div className={classes.column}>
        <Definition modifier={AFPMDef} id="T001_ARTIST_SELL_THROUGH_RATE" />
        <Definition modifier={AFPMDef} id="T001_ARTIST_VOLATILITY_RISK" />
        <Definition modifier={AFPMDef} id="T001_ARTIST_RETURN_RANKING" />
      </div>
    </div>
  );
}

ArtistFinancialPerformanceMetricsDefinitions_NoTheme.propTypes = {
  classes: PropTypes.shape({
    ArtistFinancialPerformanceMetricsDefinitions: PropTypes.string.isRequired,
    column: PropTypes.string.isRequired,
  }).isRequired,
};

const sheet = {
  ArtistFinancialPerformanceMetricsDefinitions: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 390px)',
    columnGap: '16px',
    '@media screen and (max-width: 900px)': {
      gridTemplateColumns: '390px',
      maxHeight: 484,
      overflow: 'hidden',
      overflowY: 'auto',
    },
    '@media screen and (max-width: 610px)': {
      gridTemplateColumns: '1fr',
      maxHeight: '100%',
    },
  },
  column: {
    // pass
  },
};

const ArtistFinancialPerformanceMetricsDefinitions = compose(
  injectSheet(sheet),
)(ArtistFinancialPerformanceMetricsDefinitions_NoTheme);

export {
  ArtistFinancialPerformanceMetricsDefinitions,
};
