import React from 'react';
import PropTypes from 'prop-types';
import I from 'immutable';
import cx from 'classnames';
// components
import FormattedMessageTag from './FormattedMessageTag';
import { getMoreClassNames } from './helpers';

class DataTableTh extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.shape({
      th: PropTypes.string.isRequired,
      thAsc: PropTypes.string,
      thDesc: PropTypes.string,
    }).isRequired,
    columnDef: PropTypes.instanceOf(I.Map).isRequired,
    cellProps: PropTypes.object,
    orderTableByColumn: PropTypes.func,
    orderedAsc: PropTypes.bool,
    orderedDesc: PropTypes.bool,
  };

  getThClassNames() {
    const { classes, orderedAsc, orderedDesc } = this.props;
    return cx(
      classes.th,
      getMoreClassNames(this.props),
      {
        [classes.thAsc]: orderedAsc,
        [classes.thDesc]: orderedDesc,
      },
    );
  }

  render() {
    const { classes, columnDef, orderTableByColumn, cellProps = {} } = this.props;
    if (typeof columnDef.get('getValueTh') === 'function') {
      const value = columnDef.get('getValueTh')({
        classes,
        columnDef,
        cellProps,
        orderTableByColumn,
      });
      return (
        <div
          className={this.getThClassNames()}
          children={value}
        />
      );
    }
    return columnDef.get('titleId') ?
      <FormattedMessageTag
        id={columnDef.get('titleId')}
        defaultMessage={columnDef.get('titleDefaultMessage')}
        tagName="div"
        className={this.getThClassNames()}
        onClick={() => (orderTableByColumn && orderTableByColumn(columnDef.get('orderBy')))}
      />
      :
      <div
        className={this.getThClassNames()}
      />;
  }
}

export default DataTableTh;
