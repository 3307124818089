import I from 'immutable';
import record, { enhancedType, integer, listOf, setOf } from 'cpcs-recordjs';
import { field, selector } from 'cpcs-reconnect';
import { $replaceById } from 'lib/record-helpers/list';
import * as A from 'domain/ownArtwork/OwnArtworkActions';
import * as env from 'domain/env/EnvActions';
import { Artwork, Pagination } from 'model';
import { removeAction, TYPE_ARTWORK } from 'domain/const';
import { orderRtvAction } from 'pages/common/buyRtv/buyRtvActions';
import { createConsiderationAction } from 'domain/considering/ConsideringActions';
import {
  similarSearchAction,
  clearSuggestionsAction,
} from 'pages/common/artworkForm/ArtworkFormActions';
import 'lib/clean-code-lib';

const integerOrSlug = enhancedType({
  typeName: 'IntegerOrSlug',
  parse(value) {
    return parseInt(value, 10) || ('' + value);
  },
});

function onRemove(state, { type, params }) {
  if (type !== TYPE_ARTWORK) return state;
  return state.update('selected', v => v.filter(i => !params.ids.includes(i)));
}

/**
 * State
**/
export const State = record('OwnArtworks', {
  list: listOf(Artwork),
  listPagination: Pagination.parse({}),
  total: integer(),
  galleryId: integerOrSlug,
  similar: listOf(Artwork),
  selected: setOf(integer),
});

/**
 * selectors
**/
const base = field('ownArtworks');
export const total = base.then(State.$total);
export const artworksSimilar = base.then(State.$similar);
export const similarArtworksSel = artworksSimilar;


const artworksList_sel = base.then(State.$list);
export const artworksById = artworksList_sel.then(
  list =>
    list.reduce((res, value) => res.set(value.get('id'), value), new I.Map()),
);
const artworkId_sel = (state) => state.router.location.match.params.artworkId;

export const currentArtworkSelector = selector(
  artworksList_sel, artworkId_sel, (list, id) =>
    list.find(item => item.id === parseInt(id, 10)) || new Artwork({ id, loading: true }),
);

export const pageArtworkNoMock = selector(
  artworksList_sel, artworkId_sel, (list, id) =>
    list.find(item => item.id === parseInt(id, 10)),
);

export const artworkList = artworksList_sel;
export const selectedArtworkIds = base.then(State.$selected);
export const selected = base.then(State.$selected);
export const hasSelectedDrafts = base.then(
  state => {
    const list = State.$list(state).reduce((res, value) => res.set(value.get('id'), value), new I.Map());
    const selected = State.$selected(state);
    return selected.reduce((previous, id) => previous || list.getIn([id,'isDraft'], false), false);
  },
);
export const selectedArtworksSelector = selector(selectedArtworkIds, artworksList_sel, (ids, list) => list.filter(v => ids.includes(v.get('id'))));
export const artworksPagination = base.then(State.$listPagination);

/**
 * reducer
**/
export const reducer = {
  ownArtworks(state = new State(), action) { //NOSONAR
    switch (action.type) {

      case '@@DEV/SET_RTV_STATUS':
        return state.update(State.$list.update(list => list.map(w => w.apply(
            Artwork.$rtvStatus.parsed(action.payload),
        ))));

      case orderRtvAction.success:
        return state.update(State.$list.update(list => list.map(w => (
          w.id !== action.payload.artworkId ? w : w.apply(
            Artwork.$rtvPrice.parsed(action.payload.rtvPrice),
            Artwork.$rtvCurrency.parsed(action.payload.rtvCurrency),
            Artwork.$rtvStatus.parsed(action.payload.artwork.rtvStatus),
            Artwork.$purchasedDate.parsed(action.payload.purchasedDate),
          )
        ))));
      case env.logOut.type:
        return new State();

      case clearSuggestionsAction.type:
        return state.apply(State.$similar.parsed([]));

      case similarSearchAction.success:
        return state.apply(
          State.$similar.parsed(action.payload.content),
        );

      case A.ownArtworkAppendPageAction.failure:
        return state.apply(
          State.$listPagination.update(v => v ? v.set('loading', false) : v),
        );
      case A.ownArtworkAppendPageAction.request:
      case A.fetchOwnArtworksAction.request:
        return state.apply(
          State.$listPagination.update(v => v.set('loading', true)),
        );
      case A.ownArtworkAppendPageAction.success:
        return state.apply(
          State.$list.parsedBy('concat', action.payload.content),
          State.$listPagination.parsed(action.payload),
          State.$galleryId.parsed(action.galleryId),
          State.$total.parsed(action.payload.total),
        );
      case A.fetchOwnArtworksAction.success:
        return state.apply(
          State.$list.parsed(action.payload.content),
          State.$listPagination.parsed(action.payload),
          State.$galleryId.parsed(action.galleryId),
          State.$total.parsed(action.payload.total),
        );

      case A.getOwnArtworkAction.success:
        return state.apply(
          State.$list.update(
            $replaceById(Artwork.parse(action.payload)),
          ),
        );

      case A.setSelectedAction.type:
        return state.apply(
          State.$selected.set(action.payload),
        );

      case removeAction.success:
        return onRemove(state, action.payload);

      case createConsiderationAction.success:
        return state.update(State.$list.update(list => list.map(w => (
          // @note we use w.title here, because id of considered artwork differ then id of current AO
          w.title !== action.payload.title ? w : w.update(
            Artwork.$isConsideration.set(true),
          )
        ))));

      default:
        return state;
    }
  },
};
