import { SharedByMe } from 'domain/shares';
import img1 from './images/1.png';
import img2 from './images/2.png';
import img3 from './images/3.png';
import img4 from './images/4.png';

export default SharedByMe.$list.type.parse([
  {
    id: 1,
    picture: img1,
    amount: 1,
    type: 'gallery',
    title: 'Impressionism',
    created: '2017-05-25T13:52:34.987Z',
    sharedTo: [
      'mdewhirst@gmail.com',
      'michael.dewhirst@artbnk.com',
    ],
  },
  {
    id: 2,
    picture: img2,
    amount: 21,
    type: 'art_work',
    created: '2017-05-25T13:52:34.987Z',
    sharedTo: [
      'mdewhirst@gmail.com',
    ],
  },
  {
    id: 3,
    picture: img3,
    amount: 22,
    type: 'gallery',
    created: '2017-04-30T13:52:34.987Z',
    sharedTo: [
      'michael.dewhirst@artbnk.com',
      'jamie.lafleur@artbnk.com',
    ],
  },
  {
    id: 4,
    picture: img4,
    amount: 1,
    type: 'art_work',
    created: '2017-04-19T13:52:34.987Z',
    title: 'Snow Squall',
    sharedTo: [
      'mdewhirst@gmail.com',
      'michael.dewhirst@artbnk.com',
      'jamie.lafleur@artbnk.com',
      'mwister@gmail.com',
    ],
  },
]);
