import chevronRight from './images/chevron-right.svg';
import chevronLeft from './images/chevron-left.svg';
import { DARK_BLUE_COLOR, DEFAULT_FONTFAMILY } from 'theme/theme';
import { FOCUSED_STYLE_OFFSET } from 'theme/focus';


export default {
  Breadcrumbs: {
    padding: 0,
  },
  item: {
    height: 24,
    display: 'inline-block',
    lineHeight: '24px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    verticalAlign: 'top',
    paddingRight: 20,
    marginRight: 12,
    background: {
      image: `url("${chevronRight}")`,
      repeat: 'no-repeat',
      position: 'right center',
    },
    textDecoration: 'none',
    color: DARK_BLUE_COLOR,
    fontFamily: DEFAULT_FONTFAMILY,
    fontSize: 13,
    fontWeight: 400,
    '&:focus': {
      ...FOCUSED_STYLE_OFFSET,
    },
    '&:first-child:last-child': {
      paddingLeft: 20,
      background: {
        image: `url("${chevronLeft}")`,
        repeat: 'no-repeat',
        position: 'left center',
        size: '9px 9px',
      },
    },
    '&:last-child': {
      paddingRight: 0,
      marginRight: 0,
      fontWeight: 500,
      backgroundImage: 'none',
    },
  },
  to: {
    '@media screen and (max-width: 515px)': {
      display: 'none',
    },
  },
  entity: {
    color: '#2e343e',
    fontWeight: 500,
    '@media screen and (max-width: 515px)': {
      display: 'none',
    },
  },
};
