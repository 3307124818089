import { button } from 'theme/button';
import { PageHeader, actions } from 'theme/Dashboard';

export const sheet = {
  PageHeader,
  actions,
  title: {
    display: 'block',
    whiteSpace: 'nowrap',
    width: '40%',
    border: 'none',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    paddingBottom: 0,
    '@media screen and (max-width: 610px)': {
      width: '100%',
      marginTop: 26,
      order: 2,
    },
  },
  totalQuantity: {
    fontSize: 18,
    fontWeight: 400,
  },
  result: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
  button,
};
