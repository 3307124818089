import { takeLatest, call, fork, put, select } from 'redux-saga/effects';
import { saveCardToken } from 'domain/env/sagas';
import { push } from 'connected-react-router';
import { lnk } from 'lib/routes';
import { parseServerError } from 'lib/helpers';
import {
  saveStripeCardAction,
  setCardFormOpenedAction,
  resendInvoiceAction,
  addNotification,
  saveBillingAction,
} from 'domain/env';
import {
  user_sel,
} from 'domain/env/EnvModel';
import {
  getCurrentPlan_apiGen,
} from 'domain/env/sagas';
import { allowLocationChangeAction } from 'domain/router/RouterAction';
import { FORM_BILLING } from 'domain/const';
import Api, { callApi, makeApiGenerator } from 'domain/api';

function* onSaveCardToken({ payload: { token, cardHolderName } }) {
  yield call(saveCardToken, { stripeToken: token, cardHolderName });
}

function* onCardTokenSaved() {
  yield put(allowLocationChangeAction(FORM_BILLING));
}

function* mayBeAllowLocationChange(action) {
  if (!action.payload) {
    yield put(allowLocationChangeAction(FORM_BILLING));
  }
}

function* onResendInvoice(action) {
  const { payload: { id } } = action;
  try {
    yield callApi(Api.sendInvoiceEmail, { invoiceId: id });
    yield put(addNotification({ title: 'invoice send successfully' }));
  } catch (err) {
    console.error(err);
  }
}

// { data }
const updateBilling_apiGen = makeApiGenerator({
  actionCreator: saveBillingAction,
  api: Api.updateBilling,
  getSuccessData: ({ data, headers }) => ({ payload: { ...data, token: headers['x-token'] } }),
});

function* onSaveBilling({ payload }) {
  try {
    yield call(updateBilling_apiGen, { data: payload });
    yield put(addNotification({ title: 'Billing information saved' }));
  } catch (err) {
    const { message } = parseServerError(err);
    yield put(addNotification({ title: message || 'Billing information error' }));
  }
}

export function* watchSaveStripeCardToken() {
  yield takeLatest(saveStripeCardAction.type, onSaveCardToken);
  yield takeLatest(saveStripeCardAction.success, onCardTokenSaved);
}

export function* watchSaveBilling() {
  yield takeLatest(saveBillingAction.type, onSaveBilling);
}

export default function*() {
  const user = yield select(user_sel);
  if (user.subscriptionType === 'SUBSCRIPTION_TYPE_ENTERPRISE') {
    yield put(push(lnk('homePage')));
    return;
  }
  yield fork(watchSaveStripeCardToken);
  yield takeLatest(setCardFormOpenedAction.type, mayBeAllowLocationChange);
  yield takeLatest(resendInvoiceAction.type, onResendInvoice);
  yield fork(watchSaveBilling);
  yield fork(getCurrentPlan_apiGen.catchError);
}
