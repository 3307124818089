import React from 'react';
import PropTypes from 'prop-types';
import { WaitForFB, mountFBLib } from './helpers';

export default function facebookApiDecorator(WrappedComponent) {
  class FacebookApiDecorator extends React.PureComponent {
    static propTypes = {
      APP_ID: PropTypes.string.isRequired,
    };

    state = {
      FB: null,
    };

    waitForFB = null;

    componentDidMount() {
      const { APP_ID } = this.props;
      mountFBLib(APP_ID);
      this.waitForFB = new WaitForFB(this.onFBReady);
    }

    componentWillUnmount() {
      this.waitForFB.destroy();
      this.unmounted = true;
    }

    safeSetState(state, cb) {
      if (this.unmounted) return;
      this.setState(state, cb);
    }

    onFBReady = () => {
      this.safeSetState({ FB: global.FB });
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          FB={this.state.FB}
        />
      );
    }
  }
  return FacebookApiDecorator;
}
