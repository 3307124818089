import React from 'react';
import PropTypes from 'prop-types';
import asyncComponent from 'lib/asyncComponent';
import { compose } from 'redux';
import { connect } from 'cpcs-reconnect';
import { Route, Switch, Redirect } from 'react-router-dom';
import cx from 'classnames';
import LoginForm from './form/login';
import { links as r } from 'lib/routes';
import AskEmailPopup from 'pages/authPage/form/login/popup/askEmail';
import TermsPopup from 'pages/authPage/form/signup/popups/terms';
import PrivacyPopup from 'pages/authPage/form/signup/popups/privacy';
import ConfirmationPage from 'pages/emailConfirmationPage';
import { extraFooterGap_sel } from 'domain/ui/UIModel';
import SignUpPage from './form/signup';
import AcceptPrivacyAndTermsPopup from 'pages/authPage/form/login/popup/acceptPrivacyAndTerms';
import imgLogin680 from './img-login-680w.jpg';
import imgLogin960 from './img-login-960w.jpg';

import injectSheet from 'lib/sheet';
import sheet from './sheet';

class LoginPage extends React.Component {
  static propTypes = {
    classes: PropTypes.shape({
      LoginPage: PropTypes.string,
      bg: PropTypes.string,
      wrapper: PropTypes.string,
      form: PropTypes.string,
      active: PropTypes.string,
      link: PropTypes.string,
      content: PropTypes.string,
      logo: PropTypes.string,
      linkGroup: PropTypes.string,
      title: PropTypes.string,
      pic: PropTypes.string,
    }).isRequired,
    extraFooterGap: PropTypes.bool,
  };

  state = {
    isMaxthan50: false,
  }

  renderSwitch = () => {
    const { classes } = this.props;
    return <div className={cx(classes.link, classes.active, classes.title)}>Login</div>;
  };

  renderReset = () => {
    const { classes } = this.props;
    return <div className={cx(classes.link, classes.active)}>Reset Password</div>;
  };

  renderSignUpSuccess = () => {
    const { classes } = this.props;
    return <div className={cx(classes.link, classes.active)}>Thank You!</div>;
  };

  loadImage = () => {
    const containerRect = document.querySelector('div[class*=LoginPage]').getBoundingClientRect();
    const imgRect = document.querySelector('img[class*=pic]').getBoundingClientRect();
    this.setState({
      isMaxthan50: containerRect.width / 2 > imgRect.width,
    });
  };

  componentDidMount() {
    window.addEventListener('resize', this.loadImage);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.loadImage);
  }

  render() {
    const { classes, extraFooterGap } = this.props;
    return (
      <div className={classes.LoginPage}>
        <div className={cx(classes.content, { extraFooterGap })}>
          <div className={classes.logo} />
          <div className={classes.form}>
            <div>
              <Switch>
                <Route exact path={r.forgot} render={this.renderReset} />
                <Route path={r.signUpSuccess} render={this.renderSignUpSuccess} />
                <Route path={r.reset} render={this.renderReset} />
                <Route path="/auth" render={this.renderSwitch} />
              </Switch>
            </div>
            <Switch>
              <Route exact path={r.signUp} component={SignUpPage} />
              <Route exact path={r.signUpSuccess} component={ConfirmationPage} />
              <Route
                exact
                path={r.forgot}
                component={asyncComponent(() => import('pages/forgotPage'))}
              />
              <Route path={r.reset} component={asyncComponent(() => import('pages/resetPage'))} />
              <Route path={r.auth} component={LoginForm} />
              <Redirect to={r.auth} />
            </Switch>
          </div>
          <AskEmailPopup />
          <TermsPopup />
          <PrivacyPopup />
          <AcceptPrivacyAndTermsPopup />
        </div>
        <div className={classes.bg}>
          <picture>
            <source media="(max-width: 1919px)" srcSet={imgLogin680} />
            <source media="(min-width: 1920px)" srcSet={imgLogin960} />
            <img
              src={imgLogin680}
              className={cx(classes.pic)}
              style={this.state.isMaxthan50 ? { float: 'right' } : {}}
              onLoad={this.loadImage}
              alt=""
            />
          </picture>
        </div>
      </div>
    );
  }
}

export default compose(
  connect({
    extraFooterGap: extraFooterGap_sel,
  }),
  injectSheet(sheet),
)(LoginPage);
