import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { connect } from 'cpcs-reconnect';
import { compose } from 'redux';
import { lnk } from 'lib/routes';
import { allowToRemoveAlert_sel } from 'domain/env/EnvModel';
import RemoveButton from 'pages/common/RemoveButton';
import { TYPE_ALERT } from 'domain/const';

import injectSheet from 'lib/sheet';
import { sheet } from './sheet';

function Header({ classes, modifier, title, id, seeAll, allowToRemoveAlert, total = 0 }) {
  return (
    <header className={classes.header}>
      <div className={classes.title}>
        <Link
          className={classes.titleLink}
          to={lnk('artistPage', { authorId: id })}
          children={title}
        />
      </div>

      <div className={classes.buttonsBox}>
        {
          !seeAll ? null: (
            <Link
              className={cx(classes.seeAll, modifier)}
              to={lnk('artistPage', { authorId: id })}
              children={`SEE all (${total})`}
            />
          )
        }
      </div>
      {
        allowToRemoveAlert &&
          <RemoveButton
            type={TYPE_ALERT}
            title="Delete Alert"
            modifier={cx(modifier, 'discoverPage')}
            entityTitle={title}
            params={{ alertId: parseInt(id, 10) }}
            link="homePage"
          />
      }
    </header>
  );
}

Header.propTypes = {
  classes: PropTypes.shape({
    header: PropTypes.string,
    title: PropTypes.string,
    seeAll: PropTypes.string,
    link: PropTypes.string,
    titleLink: PropTypes.string,
    buttonsBox: PropTypes.string,
  }).isRequired,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  id: PropTypes.number,
  seeAll: PropTypes.number,
  total: PropTypes.number,
  modifier: PropTypes.string,
  allowToRemoveAlert: PropTypes.bool,
};

export default compose(
  injectSheet(sheet),
  connect({
    allowToRemoveAlert: allowToRemoveAlert_sel,
  }),
)(Header);
