import uncheckedBg from 'pages/common/subscriptionType/images/unchecked.svg';
import checkedBg from 'pages/common/subscriptionType/images/checked.svg';
import { DEFAULT_FONTFAMILY, NEW_STYLE } from 'theme/theme';

export default {
  ReportOption: {
    textAlign: 'left',
    border: '1px solid #D2D3D5',
    padding: [30, 15, 0, 55],
    marginTop: 20,
    font: `400 14px/16px ${DEFAULT_FONTFAMILY}`,
    cursor: 'pointer',
    width: '100%',
    minWidth: 330,
    display: 'grid',
    gridTemplateColumns: '155px 1fr',
    gridTemplateRows: '30px max-content 30px',
    gridTemplateAreas: `
      "name price"
      "description description"
      ". mostPopular"
    `,
    background: {
      image: `url("${uncheckedBg}")`,
      repeat: 'no-repeat',
      position: '18px center',
    },
  },
  ReportOptionActive: {
    background: {
      image: `url("${checkedBg}")`,
      repeat: 'no-repeat',
      position: '18px center',
    },
  },
  name: {
    textTransform: 'uppercase',
    lineHeight: '19px',
    font: `600 16px/19px ${DEFAULT_FONTFAMILY}`,
    whiteSpace: 'nowrap',
  },
  price: {
    textAlign: 'right',
    font: `600 16px/19px ${DEFAULT_FONTFAMILY}`,
  },
  period: {
    font: `400 14px/19px ${DEFAULT_FONTFAMILY}`,
  },
  description: {
    gridArea: 'description',
    fontSize: '13px',
    '& > b': {
      fontWeight: 600,
    },
  },
  mostPopular: {
    gridArea: 'mostPopular',
    color: NEW_STYLE,
    textAlign: 'right',
    paddingTop: 6,
    // padding:
  },
};
