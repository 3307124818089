import { formatPrice } from 'lib/units';

export function formatEstimationValue(artwork) {
  const start = artwork.getIn(['lot', 'estimatedPriceStart']);
  const end = artwork.getIn(['lot', 'estimatedPriceEnd']);
  const currency = artwork.getIn(['lot', 'currency']);
  if (start && start.value && end && end.value) {
    return `${formatPrice(start, currency)} - ${formatPrice(end, currency)} ${currency}`;
  }
  if (start && start.value) {
    return `${formatPrice(start, currency)} ${currency}`;
  }
  if (end && end.value) {
    return `${formatPrice(end, currency)} ${currency}`;
  }
  return null;
}

function estimateSolver(val, data, name, props) {
  if (props.expiredTrialUser) return ' ';
  return formatEstimationValue(data);
};

export default estimateSolver;
