import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'cpcs-reconnect';
import { compose } from 'redux';
import injectSheet from 'lib/sheet';
import sheet from './sheet';
import debounce from 'debounce';
import { lnk } from 'lib/routes';
import { fetchArtistListAction } from 'domain/globalSearch/GlobalSearchAction';
import { artistsListSelector } from 'domain/globalSearch/GlobalSearchModel';
import { Autocomplete } from 'components/form/autocomplete';
import autocompleteOption from 'components/form/autocomplete/option';
import I from 'immutable';
import { artistName } from 'domain/artist/helpers';

const AutocompleteOption = autocompleteOption({ valueNormalize: artistName });

class GlobalSearch extends React.Component {
  static propTypes = {
    classes: PropTypes.shape({
      Search: PropTypes.string.isRequired,
      form: PropTypes.string.isRequired,
      submit: PropTypes.string.isRequired,
    }).isRequired,
    history: PropTypes.object.isRequired,
    fetchArtistList: PropTypes.func.isRequired,
    optionList: PropTypes.instanceOf(I.Collection),
  };

  state = {
    selectedArtistId: null,
  };

  onSearch = debounce((term) => this.props.fetchArtistList({ term }), 500);


  onArtistSelected = (value) => {
    const { history } = this.props;

    this.setState({ selectedArtistId: value });
    history.push(lnk(
      'artistPage',
      { authorId: value },
    ));
    setTimeout(() => {
      this.setState({ selectedArtistId: '' });
    }, 30);
  };

  render(){
    const { classes } = this.props;
    return (
      <div
        className={classes.Search}
      >
        <form className={classes.form}>
          <input
            type="submit"
            className={classes.submit}
            value=""
          />
          <Autocomplete
            comboBox
            showClear
            onSearch={this.onSearch}
            optionList={this.props.optionList}
            valueNormalize={artistName}
            meta={{}}
            name="search"
            placeholder="Search by artist name"
            callOnChangeWhileTyping={false}
            ownSortingAndFiltration={false}
            modifier="GlobalSearch"
            input={{
              onChange: this.onArtistSelected,
              value: this.state.selectedArtistId,
            }}
            children={AutocompleteOption}
          />
        </form>
      </div>
    );
  }
}

export default compose(
  injectSheet(sheet),
  withRouter,
  connect({
    fetchArtistList: fetchArtistListAction,
    optionList: artistsListSelector,
  }),
)(GlobalSearch);
