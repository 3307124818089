import record, { integer, string, listOf } from 'cpcs-recordjs';
import { field, selector } from 'cpcs-reconnect';
import { listToMap, toGroupedList } from 'lib/serialize';
import * as A from './SurfaceActions';

const Surface = record('Surface', {
  id: integer(),
  title: string(),
});

const State = record('Surfaces', {
  content: listOf(Surface),
  common: listOf(integer),
});

export const surfaces = field('surfaces');
export const surfaceList = surfaces.then(State.$content);
export const surfaceCommonList = surfaces.then(State.$common);
export const groupedSurfaces = selector(surfaceList, surfaceCommonList, toGroupedList);
export const surfacesById = selector(surfaceList, listToMap);
export const surfacesById_sel = selector(surfaceList, listToMap);

export const reducer = {
  surfaces(state = new State(), action) { //NOSONAR
    switch (action.type) { //NOSONAR

      case A.fetchSurfacesAction.success:
        return state.apply(
          State.$content.parsed(action.payload),
        );

      case A.setCommonSurfacesAction.type:
        return state.apply(
          State.$common.parsed(action.payload),
        );

      default:
        return state;
    }
  },
};
