import React from 'react';
import PropTypes from 'prop-types';
import I from 'immutable';

import { connectEventsContainer } from 'pages/highcharts/helpers/EventsContext';
import { RefSize } from 'pages/highcharts/helpers/refSize';
import { DEFAULT_FONTFAMILY, themes } from 'pages/highcharts/helpers/PDFConstants';

const ITEM_HEIGHT = 60;
const TABLE_MARGIN_LEFT = 24;
const MARGIN_TOP = 64;
const ITEM_WIDTH = 400;

class TableOfContents extends RefSize {
  static propTypes = {
    contentSections: PropTypes.array,
    PDFState: PropTypes.shape({
      contentSections: PropTypes.instanceOf(I.Collection),
    }).isRequired,
  };

  /**
   * page = { contentSection: 'intro', page: 2 }
   * itemRenderProps = { top: 1098.4375, left: 0, page: 2, itemTop: 276.4375, itemLeft: 0, width: 500, height: 420 }
  **/
  // eslint-disable-next-line no-unused-vars
  customAppendToPDF = ({ pdf, item, itemRenderProps, page, resolve }) => {
    const { contentSections } = this.props;
    const left = TABLE_MARGIN_LEFT;
    contentSections.filter(v => !this.contentSectionGet('hideFromContentTable', v.name)).forEach((v, index) => {
      /**
       * v.name
       * x TABLE_MARGIN_LEFT
       * y MARGIN_TOP + index * ITEM_HEIGHT
       * v.pageStart, v.pageEnd
      **/
      const top = Math.round(itemRenderProps.itemTop) + ITEM_HEIGHT * index;
      // pdf.setFillColor(100, 100, 100);
      // pdf.rect(left, top, ITEM_WIDTH, ITEM_HEIGHT - 10, 'F');
      // pdf.rect(left, top, ITEM_WIDTH, ITEM_HEIGHT - 10, 'S');
      pdf.link(left, top, ITEM_WIDTH, ITEM_HEIGHT - 10, { pageNumber: v.pageStart });
    });
    resolve();
  }

  contentSectionGet(prop, name) {
    const { PDFState } = this.props;
    const section = PDFState.contentSections.find(v => v.name === name);
    if (!section) {
      return null;
    }
    return section.get(prop);
  }

  render() {
    let { contentSections, PDFState } = this.props;
    const height = contentSections.length * ITEM_HEIGHT;
    contentSections = contentSections.filter(v => !this.contentSectionGet('hideFromContentTable', v.name));
    const theme = themes[PDFState.reportType];
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="500"
        height={height}
        ref={this.ref}
        data-margin-top={MARGIN_TOP}
        data-margin-left="0"
      >
        <style>
          {`
          .TableOfContents_pages { font: 400 10px ${DEFAULT_FONTFAMILY}; fill: #333; }
          .TableOfContents_title { font: 500 12px ${DEFAULT_FONTFAMILY}; fill: #333; }
          .TableOfContents_index { font: 500 16px ${DEFAULT_FONTFAMILY}; fill: #FFF; }
          .TableOfContents_descr { font: 400 12px ${DEFAULT_FONTFAMILY}; fill: #888; }
          `}
        </style>
        {/** /}
        <rect
          x="0"
          y="0"
          width="88"
          height="16"
          rx="0"
          ry="0"
          fill="#304C95"
        />
        {/**/}
        {
          contentSections.map((v, index) => (
            <g key={v.name} transform={`translate(${TABLE_MARGIN_LEFT}, ${index * ITEM_HEIGHT})`}>
              <text y="25" className="TableOfContents_pages">
                Pages {v.pageStart -1 }{v.pageStart === 2 ? null : `-${v.pageEnd - 1}`}
              </text>
              <circle cx="120" cy="20" r="16" fill={this.contentSectionGet('bg', v.name) || theme.mainColor} />
              {
                index !== (contentSections.length - 1) &&
                  <path d="M120 35 v30" stroke={this.contentSectionGet('bg', v.name) || theme.mainColor} strokeWidth="2" />
              }
              <text x="120" y="25" className="TableOfContents_index" textAnchor="middle">
                {index + 1}
              </text>
              <text x="153" y={this.contentSectionGet('description', v.name) ? 10 : 23} className="TableOfContents_title">
                {this.contentSectionGet('title', v.name) || v.name}
              </text>
              <text x="153" y="30" className="TableOfContents_descr">
                {this.contentSectionGet('description', v.name)}
              </text>
            </g>
          ))
        }
      </svg>
    );
  }
}

TableOfContents = connectEventsContainer(TableOfContents);

export { TableOfContents };
