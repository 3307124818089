import { useEffect, useState } from 'react';
import { useTooltip } from 'components/reports/Chart/useTooltip';
import { usePDFEvents } from 'pages/highcharts/helpers/EventsContext';
import { EVENT_COMPONENT_READY, EVENT_COMPONENT_UPDATED } from 'pages/highcharts/helpers/PDFConstants';
import { DEFAULT_FONTFAMILY_3 } from 'theme/theme';

const COLORS = ['#2f7ed8', '#0d233a', '#8bbc21', '#910000', '#1aadce',
  '#492970', '#f28f43', '#77a1e5', '#c42525', '#a6c96a'];

const CHART_DEFAULT_SPACING_BOTTOM = 15;
const CHART_DEFAULT_MARGIN_BOTTOM = 75;
const LEGEND_TOP_MARGIN = 20;

export function useOptions(ref, resp, chart, chartId, props) {
  const [options, setOptions] = useState();
  const { tooltip, onTootipClose, onTooltipShow, onTooltipFreeze, onMouseOut } = useTooltip(ref);
  const PDFEvents = usePDFEvents();
  useEffect(() => {
    if (!resp || !resp.series) {
      return;
    }
    const { current } = ref;
    const { chartProps = {} } = chart;
    let newOptions = {
      colors: COLORS,
      ...chartProps,
      credits: {
        enabled: false,
      },
      // tooltip: {
      //   enabled: false
      // },
      // annotations: {
      //   labelOptions: {
      //     borderWidth: 1,
      //     borderColor: '#FF0000',
      //     backgroundColor: '#00FF00',
      //   },
      // },
      title: {
        text: chart.title,
        align: 'left',
        style: {
          color: '#000000',
          fontWeight: '500',
          fontSize: '16px',
          fontFamily: DEFAULT_FONTFAMILY_3,
        },
      },
      exporting: {
        enabled: false,
      },
      // subtitle: { text: 'foo <b>bar</b>' },
      subtitle: {
        text: chart.subtitle,
        align: 'left',
        style: {
          color: '#313545',
          fontWeight: '400',
          fontSize: '12px',
          fontFamily: DEFAULT_FONTFAMILY_3,
        },
      },
      legend: {
        enabled: true,
        align: 'left',
        layout: 'horizontal',
        verticalAlign: 'bottom',
        y: 0,
        style: {
          color: '#313545',
          fontSize: '14px',
          fontWeight: 500,
          fontFamily: DEFAULT_FONTFAMILY_3,
        },
        ...((chart.chartProps || {}).legend || {}),
      },
      // subtitle: 'Today’s value based on past financial performance at auction.',
      series: resp.series,
      drilldown: resp.drilldown,
      rangeSelector: {
        verticalAlign: 'top',
        allButtonsEnabled: true,
        buttons: [
          {
            type: 'year',
            count: 5,
            text: '5y',
            title: 'View 5 year',
          },
          {
            type: 'year',
            count: 10,
            text: '10y',
            title: 'View 10 year',
          },
          {
            type: 'all',
            text: 'All',
            title: 'View All',
          },
        ],
        buttonPosition: {
          align: 'left',
          y: -40,
          x: -20,
        },
        inputPosition: {
          y: -40,
          align: 'right',
        },
        // labelStyle: {
        //   display: 'none',
        // },
        ...((chart.chartProps || {}).rangeSelector || {}),
      },
      chart: {
        type: chart.type,
        spacingTop: 1,
        marginTop: chart.stockChart ? 40 : 100,
        marginBottom: CHART_DEFAULT_MARGIN_BOTTOM + LEGEND_TOP_MARGIN,
        spacingLeft: 20,
        height: 430 + (chart.stockChart ? 20 : 0) + LEGEND_TOP_MARGIN,
        spacingBottom: CHART_DEFAULT_SPACING_BOTTOM,
        style: {
          fontFamily: DEFAULT_FONTFAMILY_3,
        },
        ...((chart.chartProps || {}).chart || {}),
        events: {
          ...(((chart.chartProps || {}).chart || {}).events || {}),
          load: function(...args) {
            if (PDFEvents) {
              PDFEvents.dispatch(EVENT_COMPONENT_READY, { id: chartId, type: 'chart' });
            }
            const { load } = (((chart.chartProps || {}).chart || {}).events || {});
            if (load) {
              load.call(this, ...args);
            }
          },
          render: function(...args) {
            if (PDFEvents) {
              PDFEvents.dispatch(EVENT_COMPONENT_UPDATED, { id: chartId, type: 'chart' });
            }
            const { render } = (((chart.chartProps || {}).chart || {}).events || {});
            if (render) {
              render.call(this, ...args);
            }
          },
        },
      },
    };
    if (chart.updateOptions) {
      newOptions = chart.updateOptions({ options: newOptions, series: resp.series, props, onTooltipShow, onMouseOut, onTooltipFreeze, refCurrent: current });
    }
    if (ref.current.unmounted) return;
    setOptions(newOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resp, chart, chartId, onTooltipFreeze, onMouseOut, onTooltipShow, ref, PDFEvents]);
  return { options, onTootipClose, tooltip };
}
