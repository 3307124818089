import options1Data from './CAGR-vs-Holding-Period';

export const options1 = {
  chart: {
    type: 'scatter',
    zoomType: 'xy',
  },
  title: {
    text: 'CAR vs HOLDING PERIOD',
  },
  subtitle: {
      text: 'My custom subtitle',
  },
  xAxis: {
    title: {
      enabled: true,
      text: 'Holding Period',
    },
    startOnTick: true,
    endOnTick: true,
    showLastLabel: true,
    plotBands: [{
      from: 18,
      to: 22,
      color: 'rgba(68, 170, 213, .2)',
    }],
    plotLines: [{
      color: 'red', // Color value
      dashStyle: 'longdashdot', // Style of the plot line. Default to solid
      value: 20, // Value of where the line will appear
      width: 2, // Width of the line
    }],
    // tick
    tickInterval: 5,
    // tickPixelInterval: 10,
    tickWidth: 2,
    minorTickInterval: 5,
    minorTickLength: 5,
    minorTickWidth: 0.5,
  },
  yAxis: {
    title: {
      text: 'CAR',
    },
    plotBands: [{
      from: 4,
      to: 17,
      color: 'rgba(68, 170, 213, .2)',
    }],
    plotLines: [{
      color: 'red', // Color value
      dashStyle: 'longdashdot', // Style of the plot line. Default to solid
      value: 40, // Value of where the line will appear
      width: 2, // Width of the line
    }],
  },
  plotOptions: {
    scatter: {
      marker: {
        radius: 4,
        states: {
          hover: {
            enabled: true,
            lineColor: 'rgb(100,100,100)',
          },
        },
      },
      states: {
        hover: {
          marker: {
            enabled: false,
          },
        },
      },
      tooltip: {
        // headerFormat: '<b>{series.name}</b><br>',
        // headerFormat: '',
        // pointFormat: '{point.x} years, {point.y} car {point.name}',
        formatter: function() {
          return (
            `<b>${this.series.name}</b><br />` +
            `${this.x} years, ${this.y} car` +
            this.name ? ` (${this.name})` : ''
          );
        },
      },
    },
  },
  series: [{
    color: 'rgba(57, 153, 153, .5)',
    name: 'CAR',
    zones: [
      {
        value: 4,
        color: 'rgba(57, 153, 153, .5)',
      },
      {
        value: 17,
        color: '#FF990077',
      },
      {
        color: '#FF000077',
      },
    ],
    data: options1Data || [
      [0, 1], [0.1, -20], [0.3, -24], [10, 20],
      [3, -10], [2, 11], [10, 60], [13, -40],
      [10, 13], [15, -43], [0.3, -24], [10, 20],
      [3, -10], [2, 11], [10, 60], [13, -40],
      [4, 21], [2, -12], [7, 50], [5, 12],
      [3, 10], [2, -11], [10, -60], [13, 40],
      [13, 43], [14, -11], [12, -44], [20, 20],
      [23, -12], [11, 11], [2, 2], [7, -48],
    ],

  }],
};

export const options2 = {
  title: {
    text: 'ARTBNK SEMIANNUAL ART MARKET INDEX',
  },

  yAxis: {
  },

  xAxis: {
  },

  legend: {
    layout: 'vertical',
    align: 'right',
    verticalAlign: 'middle',
  },

  plotOptions: {
    series: {
      label: {
        connectorAllowed: false,
      },
      pointStart: 2010,
    },
  },

  series: [{
    name: 'Liquid Artists',
    data: [[1980, 0.01], [1981, 0.07], [1982, 4], [1983, 2], [1984, 4]],
  },
  {
    name: 'All Artists',
    data: [[1980, 0.01], [1981, 0.07], [1982, 3], [1983, 2], [1984, 4]],
  }],
};
export const options3 = {
  title: {
    text: 'SOLD PRICE vs ARTBnk VALUE',
  },
  yAxis: {
  },
  xAxis: {
    categories: [
      'Q1 2021',
      'Q2 2021',
      'Q3 2021',
      'Q4 2021',
    ],
  },

  legend: {
    layout: 'vertical',
    align: 'right',
    verticalAlign: 'middle',
  },

  plotOptions: {
    series: {
      label: {
        connectorAllowed: false,
      },
      pointStart: 2010,
    },
  },

  series: [{
    name: 'Liquid Artists',
    data: [-50, 100, -50, 50],
  }],
};
export const options4 = {
  chart: {
    type: 'column',
  },
  title: {
    text: 'SELF-THROUGH RATE',
  },
  xAxis: {
    categories: [
      '2011',
      '2012',
      '2013',
      '2014',
      '2015',
      '2016',
      '2017',
      '2018',
      '2019',
      '2020',
      '2021',
    ],
    crosshair: true,
  },
  yAxis: {
    min: 0,
    max: 100,
  },
  tooltip: {
    headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
    pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
      '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
    footerFormat: '</table>',
    shared: true,
    useHTML: true,
  },
  plotOptions: {
    column: {
      pointPadding: 0.2,
      borderWidth: 0,
    },
  },
  series: [{
    name: 'all noland artworks',
    data: [83.33, 100, 93.75, 93.33, 100, 90.91, 89.47, 93.10, 84.85, 87.50, 86.96],

  }, {
    name: 'noland works of comparable medium',
    data: [81.82, 100, 93.33, 93.33, 100, 90, 93.75, 92.86, 86.67, 92.31, 90.00],
  }],
};

export const options5 = {
  chart: {
    type: 'column',
  },
  title: {
    text: 'REPEAT SALE COUNT by AUCTION YEAR',
  },
  xAxis: {
    categories: [
      '2011',
      '2012',
      '2013',
      '2014',
      '2015',
      '2016',
      '2017',
      '2018',
      '2019',
      '2020',
      '2021',
    ],
    crosshair: true,
  },
  yAxis: {
  },
  tooltip: {
    headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
    pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
      '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
    footerFormat: '</table>',
    shared: true,
    useHTML: true,
  },
  series: [{
    data: [3, 1, 7, 4, 7, 1, 7, 7, 9, 4, 4],
  }],
};
