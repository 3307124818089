import { call, fork, put, select, take, takeEvery, takeLatest, all } from 'redux-saga/effects';
import { reduceCallFrequency } from 'lib/saga-helpers';
import { Artwork } from 'model';
import I from 'immutable';

import { arrayRemove, change, getFormValues, initialize } from 'redux-form';
import { hidePopupAction, addPopupAction } from 'domain/ui/UIActions';
import {
  removeArtworkImageAction,
  addPictureAction,
  setArtworkDefaultImageAction,
} from 'domain/ownArtwork/OwnArtworkActions';
import { watchUploadPicture } from 'domain/ownArtwork/sagas';
import {
  fillArtworkFormAction,
  similarSearchAction,
  clearSuggestionsAction,
  setImageCropAction,
  replacePictureInFormAction,
} from 'pages/common/artworkForm/ArtworkFormActions';
import {
  similarSearchFieldArr,
  fieldsValuesForSimilarSearch_sel,
  artworkFormPictures_sel,
  fromSuggestions_sel,
  is3D_sel,
  privateArtworkPictures_sel,
} from 'pages/common/artworkForm/ArtworkFormModel';
import watchSavers from 'pages/common/artworkForm/sagas/artworkFormSavers';
import {
  matchParamsSel,
  // routeName_sel,
} from 'domain/router/RouterModel';
import { FORM_ADD_ART_ITEM, CROP_IMAGE_POPUP } from 'domain/const';
import {
  INIT_DATA,
  mapToForm,
  mapToServer,
} from 'domain/ownArtwork/artworkSerializer';
import { artistById } from 'domain/artist';
import {
  ensureArtist_apiGen,
  watchSearchArtist,
  watchOwnArtistActions,
} from 'domain/artist/sagas';
import { watchFailSubmit } from 'sagas/common';
import { dateStructToString, strIs2D, strIsEdition2D } from 'lib/helpers';
import { categoryById, categoryList as categoryListSel } from 'domain/category/CategoryModel';
import { fetchCategoryAction } from 'domain/category/CategoryAction';
import { setCommonSurfacesAction } from 'domain/surface/SurfaceActions';
import { setCommonSubstratesAction } from 'domain/substrate/SubstrateActions';
import { setCommonMediumsAction } from 'domain/medium/MediumActions';
import Api, { makeApiGenerator } from 'domain/api';
// import { get2DId } from 'pages/common/newPage/form/artObject/helpers';
import { UNITS } from 'lib/units';

const PICTURES = 'pictures';

// const similarSearch_apiGen = makeApiGenerator({
//   api: Api.similarSearch,
//   actionCreator: similarSearchAction,
// });

const similarSearchConsideration_apiGen = makeApiGenerator({
  api: Api.similarSearchConsideration,
  actionCreator: similarSearchAction,
});

function* ensureAOFormArtist({ payload }) { // #NOSONAR
  if ('artist' in payload && payload.artist) {
    yield call(ensureArtist_apiGen.catchError, { id: payload.artist });
  }
}

function* onCategoryChange(categoryId) {
  const category = (yield select(categoryById)).get(parseInt(categoryId, 10)) || I.Map();
  const { title = '', commonSubstrates, commonSurfaces, commonMediums } = category.toJS();
  yield put(setCommonSurfacesAction(commonSurfaces || [], 'artworkForm saga onCategoryChange'));
  yield put(setCommonSubstratesAction(commonSubstrates || [], 'artworkForm saga onCategoryChange'));
  yield put(setCommonMediumsAction(commonMediums || [], 'artworkForm saga onCategoryChange'));
  const is2DEdition = strIsEdition2D(title);
  if (is2DEdition) {
    yield put(change(FORM_ADD_ART_ITEM, 'sheetUnit', UNITS.INCH.id));
  }
  const isAny3D = title.includes('3D');
  const data = yield select(getFormValues(FORM_ADD_ART_ITEM));
  if ((isAny3D || is2DEdition) && data.isCircular) {
    yield put(change(FORM_ADD_ART_ITEM, 'isCircular', false));
  }
}

function* onRemovePicture({ payload: index }) {
  const fromSuggestions = yield select(fromSuggestions_sel);
  const pictures = (yield select(artworkFormPictures_sel)) || [];
  const file = pictures[index];
  if (file && file.croppedUrl) {
    try {
      window.URL.revokeObjectURL(file.croppedUrl);
    } catch (err) {
      console.error(err);
    }
  }
  const privatePictures = yield select(privateArtworkPictures_sel);
  if (fromSuggestions && (pictures.length === 1 || index === 0 || privatePictures.includes(file))) {
    // const categoryList = yield select(categoryListSel);
    // const categoryId = get2DId(categoryList);
    yield put(initialize(FORM_ADD_ART_ITEM, {
      ...INIT_DATA,
      // category: categoryId,
    }));
    // yield call(onCategoryChange, categoryId);
  } else {
    yield put(
      change(
        FORM_ADD_ART_ITEM,
        PICTURES,
        pictures.filter((f, i) => i !== index),
      ),
    );
  }
}

const similarSearch = reduceCallFrequency(function* similarSearch() {
  let data = yield select(fieldsValuesForSimilarSearch_sel);
  const is3D = yield select(is3D_sel);
  if (is3D) {
    const { substrates, surfaces, ...more } = data;
    data = more;
  } else {
    const { mediums, ...more } = data;
    data = more;
  }
  yield call(similarSearchConsideration_apiGen.catchError, { data: mapToServer(data) });
}, 1850);

function* callSimilarSearch(changeAction) {
  const { meta: { field, form } } = changeAction;
  const { artworkId } = yield select(matchParamsSel);
  if (artworkId) {
    // do not search similar for edit AO/track forms
    return;
  }
  if (form !== FORM_ADD_ART_ITEM) {
    return;
  }
  if (!similarSearchFieldArr.includes(field)) {
    return;
  }
  yield fork(similarSearch);
}

export function* fillArtworkForm({ payload } = { payload: new Artwork() }, artworkId = null) {
  const data = mapToForm(payload, artworkId);
  /**
   * acquisition only for AO
   * upcomingSale only for track
  **/
  // new from suggestion: !data.id data.artWork !data.editable
  // edit from suggestion data.id data.artwork !data.editable
  // edit own data.id !data.artwork data.editable
  // edit, new not from suggestions
  // const pageName = yield select(routeName_sel);
  // let acquisition = [];
  // (new, from suggestions) OR edit artwork page
  // if ((!data.id && data.artWork) || pageName === 'artEdit') {
  //   acquisition = [emptySalesHistory()];
  // }
  const upcomingSale = [];
  let salesHistory = data.salesHistory || INIT_DATA.salesHistory;
  let userPrice = data.userPrice || INIT_DATA.userPrice;
  if (salesHistory.length === 0) {
    salesHistory = INIT_DATA.salesHistory;
  }
  let categoryList = yield select(categoryListSel);
  if (categoryList.size === 0) {
    yield take(fetchCategoryAction.success);
    categoryList = yield select(categoryListSel);
  }
  yield put(
    initialize(
      FORM_ADD_ART_ITEM,
      {
        ...INIT_DATA,
        // acquisition,
        upcomingSale,
        ...data,
        salesHistory,
        userPrice,
        category: data.category, // || get2DId(categoryList),
      },
    ),
  );
}

const sensitiveFields = ['artist', 'title'];

/**
 * rm empty note
 * reset artist fields
 * set category dependent fields
**/
export function* checkChanges({ meta: { field, form }, payload }, reset = true) { //NOSONAR
  if (form !== FORM_ADD_ART_ITEM) {
    return;
  }
  const data = (yield select(getFormValues(FORM_ADD_ART_ITEM)));
  const isSalesHistoryCurrency = field.match(/^salesHistory\.(\d)\.currency$/);
  // userPrice.currency
  // userPrice.priceSold.value
  const { userPrice: { priceSold: { value: userPriceValue } = {} } = {} } = data;
  if (field === 'userPrice.currency' && payload !== 'BTC' && userPriceValue % 1) {
    yield put(change(FORM_ADD_ART_ITEM, 'userPrice.priceSold.value', Math.round(userPriceValue)));
  } else if (isSalesHistoryCurrency && payload !== 'BTC') {
    const n = isSalesHistoryCurrency[1];
    const { salesHistory: { [n]: {
      priceSold: { value: priceSoldValue } = {},
      estimatedPriceStart: { value: priceStartValue } = {},
      estimatedPriceEnd: { value: priceEndValue } = {},
    } = {} } = {} } = data;
    // salesHistory.0.priceSold.value
    if (priceSoldValue % 1) {
      yield put(change(FORM_ADD_ART_ITEM, `salesHistory.${n}.priceSold.value`, Math.round(priceSoldValue)));
    }
    // salesHistory.0.estimatedPriceStart.value
    if (priceStartValue % 1) {
      yield put(change(FORM_ADD_ART_ITEM, `salesHistory.${n}.estimatedPriceStart.value`, Math.round(priceStartValue)));
    }
    // salesHistory.0.estimatedPriceEnd.value
    if (priceEndValue % 1) {
      yield put(change(FORM_ADD_ART_ITEM, `salesHistory.${n}.estimatedPriceEnd.value`, Math.round(priceEndValue)));
    }
  }
  const isNoteField = field.match(/^notes\.(\d+)$/);
  // rm empty note
  if (isNoteField && payload === '') {
    const { notes } = data;
    if (notes && notes.length > 1) {
      yield put(arrayRemove(FORM_ADD_ART_ITEM, 'notes', isNoteField[1]));
    }
  }
  // reset artist fields
  if (sensitiveFields.indexOf(field) > -1) {
    const fromSuggestions = yield select(fromSuggestions_sel);
    let dataChangeSet = {};
    if (field === 'artist') {
      const artist = (yield select(artistById)).get(parseInt(payload, 10));
      if (artist) {
        dataChangeSet = {
          bornIn: dateStructToString(artist.bornIn),
          diedIn: dateStructToString(artist.diedIn),
          residence: artist.residence.toJS(),
        };
      } else {
        dataChangeSet = {
          bornIn: null,
          diedIn: null,
          residence: null,
        };
      }
    }
    if (fromSuggestions && reset) {
      // const categoryList = yield select(categoryListSel);
      // const categoryId = get2DId(categoryList);
      yield put(initialize(FORM_ADD_ART_ITEM, {
        ...INIT_DATA,
        [field]: payload,
        ...dataChangeSet,
        // category: categoryId,
      }));
      // yield call(onCategoryChange, categoryId);
    } else {
      const changes = Object.keys(dataChangeSet).map(
        key => put(change(form, key, dataChangeSet[key])),
      );
      yield all(changes);
    }
  }
  // set category dependent fields
  if (field === 'category') {
    yield call(onCategoryChange, payload);
  }
}

/**
 * set category dependent materials
 * or default category materials
**/
export function* setMaterials(artwork) {
  yield put(setCommonSurfacesAction([], 'artworkForm sagas setMaterials clear'));
  yield put(setCommonSubstratesAction([], 'artworkForm sagas setMaterials clear'));
  yield put(setCommonMediumsAction([], 'artworkForm sagas setMaterials clear'));
  let category;
  if (artwork) {
    const categoryId = artwork.get('category');
    category = (yield select(categoryById)).get(parseInt(categoryId, 10)) || I.Map();
  } else {
    let categoryList = yield select(categoryListSel);
    if (categoryList.size === 0) {
      yield take(fetchCategoryAction.success);
      categoryList = yield select(categoryListSel);
    }
    if (!categoryList.size) return;
    category = categoryList.find(category => strIs2D(category.get('title')));
  }
  if (!category) return;
  const { commonSubstrates, commonSurfaces, commonMediums } = category.toJS();
  yield put(setCommonSurfacesAction(commonSurfaces || [], 'artworkForm sagas setMaterials for category'));
  yield put(setCommonSubstratesAction(commonSubstrates || [], 'artworkForm sagas setMaterials for category'));
  yield put(setCommonMediumsAction(commonMediums || [], 'artworkForm sagas setMaterials for category'));
}

function* onAddPicture({ payload: files }) {
  if (!files || !files.length) return;
  const pictures = (yield select(artworkFormPictures_sel)) || [];
  yield put(change(FORM_ADD_ART_ITEM, PICTURES, pictures.concat(files)));
  const file = files[0];
  if (!file) return;
  yield put(addPopupAction({
    name: CROP_IMAGE_POPUP,
    params: {
      index: pictures.length,
    },
  }));
}

function* onReplaceImage({ payload: { index, file } }) {
  const pictures = (yield select(artworkFormPictures_sel)) || [];
  const newPictures = pictures.map((f, i) => i === index ? file : f);
  yield put(change(FORM_ADD_ART_ITEM, PICTURES, newPictures));
}

function* onSetDefaultImage({ payload: index }) {
  const pictures = (yield select(artworkFormPictures_sel)) || [];
  if (!pictures[index]) return;
  const newVal = [
    pictures[index],
    ...pictures.slice(0, index),
    ...pictures.slice(index + 1),
  ];
  yield put(change(FORM_ADD_ART_ITEM, PICTURES, newVal));
}

function* onSetImageCrop({ payload: { x1, x2, y1, y2, naturalWidth, naturalHeight, index, croppedUrl, rotationTimes } }) {
  const pictures = (yield select(artworkFormPictures_sel)) || [];
  if (!pictures[index]) return;
  const newVal = [
    ...pictures.slice(0, index),
    {
      // name, ...
      ...pictures[index],
      x1, x2, y1, y2, naturalWidth, naturalHeight,
      croppedUrl, rotationTimes,
    },
    ...pictures.slice(index + 1),
  ];
  yield put(change(FORM_ADD_ART_ITEM, PICTURES, newVal));
  yield put(hidePopupAction(CROP_IMAGE_POPUP));
}

export function* artworkFormInit() {
  // for new AO/track pages only (similar/suggestions)
  yield fork(watchUploadPicture);
  yield takeLatest(fillArtworkFormAction.type, fillArtworkForm);
  yield takeEvery('@@redux-form/INITIALIZE', ensureAOFormArtist);
  yield takeEvery('@@redux-form/CHANGE', checkChanges);
  yield takeEvery(removeArtworkImageAction.type, onRemovePicture);
  yield takeEvery('@@redux-form/CHANGE', callSimilarSearch);
  yield fork(watchSearchArtist);
  yield fork(watchOwnArtistActions);
  yield fork(watchFailSubmit);
  yield put(clearSuggestionsAction());
  yield takeEvery(addPictureAction.type, onAddPicture);
  yield takeEvery(replacePictureInFormAction.type, onReplaceImage);
  yield takeEvery(setArtworkDefaultImageAction.type, onSetDefaultImage);
  yield takeEvery(setImageCropAction.type, onSetImageCrop);
  yield fork(watchSavers);
}
