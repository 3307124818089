import { put, takeEvery } from 'redux-saga/effects';

function* onCastMultipleActions({ payload: { type, actions } }) {
  for (let payload of actions) {
    if (payload.type) {
      yield put(payload);
    } else {
      yield put({ type, payload });
    }
  }
}

export default function*() {
  yield takeEvery('debug/cast-multiple-actions', onCastMultipleActions);
}
