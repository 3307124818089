import React, { useRef, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import infoIcon from 'components/Tooltip/images/info.svg';

import injectSheet from 'lib/sheet';
import styles from './sheet.js';

const getDocSize = () => {
  const width  = window.innerWidth || document.documentElement.clientWidth || 
  document.body.clientWidth;
  const height = window.innerHeight|| document.documentElement.clientHeight || 
  document.body.clientHeight;
  return { width, height };
};

function Tooltip_NoTheme({ classes, hoverItem, content, modifier }) {
  const [opened, setOpened] = useState(false);
  const [fixed, setFixed] = useState(false);
  const [style, setStyle] = useState({});
  const ref = useRef({ timeout: null });
  const refRoot = useRef();
  const refPopover = useRef();

  const updateStyle = useCallback(() => {
    const root = refRoot.current.getBoundingClientRect();
    const popover = refPopover.current.getBoundingClientRect();
    const w = getDocSize();
    const right = w.width - root.x;
    let top = Math.max(root.y - 120, 0);
    top = Math.min(popover.height, top);
    let left = 43;
    if ((left + popover.width) > right) {
      left = right - popover.width - 40;
    }
    if (w.width <= 610) {
      const newStyle = {
        // top: 80 - root.y,
        top: 30,
        left: 15 - root.x,
        right: root.x - w.width + root.width + 15,
        // bottom: root.y - w.height + 30,
        height: 484,
      };
      setStyle(newStyle);
      return;
    }
    setStyle({ top: -top, left });
  }, []);

  const onMouseEnter = useCallback(() => {
    if (!opened) {
      setOpened(true);
      updateStyle();
    }
    if (ref.current.timeout) {
      clearTimeout(ref.current.timeout);
      ref.current.timeout = null;
    }
  }, [opened, updateStyle]);

  const onMouseLeave = useCallback(() => {
    if (ref.current.timeout) {
      clearTimeout(ref.current.timeout);
      ref.current.timeout = null;
    }
    ref.current.timeout = setTimeout(setOpened, 500, false);
  }, []);

  const toggleFixed = useCallback(() => {
    setFixed(!fixed);
    if (!opened && !fixed) {
      updateStyle();
    }
    if (fixed && opened) {
      setOpened(false);
    }
  }, [fixed, opened, updateStyle]);

  useEffect(() => {
    window.addEventListener('resize', updateStyle, { capture: false, once: false, passive: true });
    return () => {
      window.removeEventListener('resize', updateStyle, { capture: false, once: false, passive: true });
    };
  }, [updateStyle]);

  return (
    <div
      className={cx(classes.Tooltip, modifier, { opened: opened || fixed })}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={toggleFixed}
      ref={refRoot}
    >
      {hoverItem}
      <div className={classes.popoverWrapper}>
        <div
          className={cx(classes.popover, modifier)}
          ref={refPopover}
          style={style}
        >
          {content}
        </div>
      </div>
    </div>
  );
}

Tooltip_NoTheme.propTypes = {
  classes: PropTypes.shape({
    Tooltip: PropTypes.string,
    popover: PropTypes.string,
    popoverWrapper: PropTypes.string,
  }).isRequired,
  hoverItem: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]).isRequired,
  content: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]).isRequired,
  modifier: PropTypes.string,
};

const Tooltip = injectSheet(styles)(Tooltip_NoTheme);

function Icon_NoTheme({ type, classes, ...props }) {
  return <i className={cx(classes.Icon, type)} {...props} />;
}
Icon_NoTheme.propTypes = {
  type: PropTypes.string,
  classes: PropTypes.shape({
    Icon: PropTypes.string.isRequired,
  }).isRequired,
};

const iconSheet = {
  Icon: {
    width: 20,
    height: 20,
    display: 'inline-block',
    background: `transparent url("${infoIcon}") no-repeat scroll left top / 20px`,
    cursor: 'pointer',
    verticalAlign: 'top',
    marginLeft: 5,
  },
};

const Icon = injectSheet(iconSheet)(Icon_NoTheme);

function InfoTooltip({ children, modifier }) {
  const icon = <Icon type="info" />;
  return <Tooltip content={children} hoverItem={icon} modifier={modifier} />;
}

InfoTooltip.propTypes = {
  children: PropTypes.node,
  modifier: PropTypes.string,
};

export {
  Tooltip,
  InfoTooltip,
};
