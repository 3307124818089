import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import cx from 'classnames';
import { connect } from 'cpcs-reconnect';
import { advancedFilterOpenState_sel } from 'domain/ui/UIModel';
import { filterToggleAction } from 'domain/ui/UIActions';
import { routeName_sel } from 'domain/router/RouterModel';
import AdvancedFilter from 'pages/common/advancedFilter';

import injectSheet from 'lib/sheet';
import { sheet } from './sheet';

// clean-code-lib: Array.prototype.includes

const pages = ['artistPage', 'collectionListPage', 'saleLotsListPage'];

class SideBar extends React.PureComponent {

  static propTypes = {
    classes: PropTypes.shape({
      SideBar: PropTypes.string,
      overlay: PropTypes.string,
    }).isRequired,
    filterToggle: PropTypes.func.isRequired,
    filterState: PropTypes.string,
    routeName: PropTypes.string,
  };

  onFilterToggle = () => {
    const { filterToggle } = this.props;
    filterToggle();
  }

  render() {
    const { classes, filterState, routeName } = this.props;
    if (!pages.includes(routeName)) return null;
    // if (filterState === 'AFClosed') {
    //   return <div className="SIDEBAR" />;
    // }
    return (
      <aside className={cx('SIDEBAR', classes.SideBar, filterState, routeName)}>
        <div className={classes.overlay} onClick={this.onFilterToggle} />
        <AdvancedFilter
          toggle={this.onFilterToggle}
        />
      </aside>
    );
  }
}

export { SideBar as SideBarTest };

export default compose(
  connect({
    filterState: advancedFilterOpenState_sel,
    filterToggle: filterToggleAction,
    routeName: routeName_sel,
  }),
  injectSheet(sheet),
)(SideBar);
