/* eslint no-unused-vars: warn */
import React from 'react';
import PropTypes from 'prop-types';
import I from 'immutable';
import { compose } from 'redux';
import cx from 'classnames';
import { connect } from 'cpcs-reconnect';

import { delayRender } from 'lib/envGlobals';
import { artistSelector } from 'domain/artwork/ArtworkModel';
import { LIQUIDITY_TYPES } from 'domain/const';

import { PDFModal } from 'pages/highcharts/PDFModal';
import { PDFRemovedItemsSidebar } from 'pages/highcharts/PDFRemovedItemsSidebar';

import { connectEventsContainer } from 'pages/highcharts/helpers/EventsContext';
import { connectPDFStore } from 'pages/highcharts/PDFModel';
import { PDFComponents } from 'pages/highcharts/PDFComponents';
import { pages as PageComponent } from 'pages/highcharts/pages';
import {
  EVENT_ITEM_DID_MOUNT,
  EVENT_ITEM_DID_UPDATE,
  EVENT_LIST_RENDER_PROPS,
  PAGE_GAP,
  PAGE_WIDTH,
  PAGE_HEIGHT,
  EVENT_PRINT_PDF,
  EVENT_PDF_GENERATED,
  REPORT_TYPE_ARTIST,
  REPORT_TYPE_ARTWORK,
} from 'pages/highcharts/helpers/PDFConstants';
import ToggleItemHidden from 'pages/highcharts/components/ToggleItemHidden';
import { getKey, sortPDFComponents, itemSVGProps, itemAvailableByLiquidity } from 'pages/highcharts/helpers/helpers';
import { runOncePerPeriod, debounce } from 'lib/envGlobals';
import { placeItemsToPages } from 'pages/highcharts/helpers/placeItemsToPages';

import injectSheet from 'lib/sheet';

const sheet = {
  PDFPreview: {
    display: 'grid',
    gridTemplateColumns: '185px auto 400px',
    justifyItems: 'center',
    backgroundColor: '#3D3D3D',
    padding: 50,
    color: '#FFF',
    position: 'relative',
    width: '100vw',
  },
  pages: {
    display: 'grid',
    rowGap: PAGE_GAP,
  },
  pageItem: {
    display: 'flex',
  },
  previewSidebar: {
  },
  content: {
    position: 'relative',
  },
  removedItemsSidebar: {
    position: 'relative',
  },
};


class PDFPreview extends React.Component {
  static propTypes = {
    PDFEvents: PropTypes.shape({
      dispatch: PropTypes.func.isRequired,
      addEventListener: PropTypes.func.isRequired,
      removeEventListener: PropTypes.func.isRequired,
    }).isRequired,
    classes: PropTypes.shape({
      pageItem: PropTypes.string.isRequired,
      PDFPreview: PropTypes.string.isRequired,
      pages: PropTypes.string.isRequired,
      removedItemsSidebar: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired,
      previewSidebar: PropTypes.string.isRequired,
    }).isRequired,
    PDFStore: PropTypes.shape({
      dispatch: PropTypes.func.isRequired,
    }).isRequired,
    PDFState: PropTypes.shape({
      items: PropTypes.instanceOf(I.List),
      liquidity: PropTypes.oneOf(LIQUIDITY_TYPES),
      reportType: PropTypes.oneOf([REPORT_TYPE_ARTIST, REPORT_TYPE_ARTWORK]),
      rtvShown: PropTypes.bool,
    }).isRequired,
    togglePDFReport: PropTypes.func.isRequired,
    onPDFSaved: PropTypes.func,
    artist: PropTypes.shape({
      fullName: PropTypes.string,
    }),
    artwork: PropTypes.shape({
      artist: PropTypes.shape({}).isRequired,
    }),
  }
  state = {
    pages: [],
    positions: {},
    parts: {},
    contentSections: [],
    previewMode: true,
  }
  safeSetState = (...args) => {
    if (this.unmounted) return;
    return this.setState(...args);
  }
  UNSAFE_componentWillMount() {
    const { PDFEvents } = this.props;
    PDFEvents.addEventListener(EVENT_ITEM_DID_MOUNT, this.reRender);
    PDFEvents.addEventListener(EVENT_ITEM_DID_UPDATE, this.reRender);
    PDFEvents.addEventListener(EVENT_PDF_GENERATED, this.onPDFRendered);
  }
  onGeneratePDF = () => {
    if (this.state.previewMode) {
      this.safeSetState({ previewMode: false });
      this.generatePdfDebounced();
    }
  }
  generatePdfDebounced = debounce(() => {
    const { PDFEvents, artist, artwork } = this.props;
    PDFEvents.dispatch(EVENT_PRINT_PDF, { artist, artwork });
  }, 300)
  onPDFRendered = () => {
    const { onPDFSaved } = this.props;
    this.safeSetState({ previewMode: true });
    if (onPDFSaved) {
      onPDFSaved();
    }
  }
  componentWillUnmount() {
    this.generatePdfDebounced.cancel();
    this.reRender.cancel();
    this.unmounted = true;
    const { PDFEvents } = this.props;
    PDFEvents.removeEventListener(EVENT_ITEM_DID_MOUNT, this.reRender);
    PDFEvents.removeEventListener(EVENT_ITEM_DID_UPDATE, this.reRender);
    PDFEvents.removeEventListener(EVENT_PDF_GENERATED, this.onPDFRendered);
  }
  itemAvailableByLiquidity = (item) => {
    const { PDFState } = this.props;
    return itemAvailableByLiquidity({ PDFState, item });
  }
  reRender = runOncePerPeriod(() => {
    const { PDFEvents, PDFState } = this.props;
    if (!PDFState.liquidity) {
      console.error(`PDFPreview::reRender unknown liquidity "${PDFState.liquidity}"`);
      return;
    }
    const reportItems = PDFEvents.dispatch(EVENT_LIST_RENDER_PROPS, { collect: true })
      .sort(sortPDFComponents)
      .filter(this.itemAvailableByLiquidity);
    const { pages, items, contentSections, parts } = placeItemsToPages(reportItems);
    this.safeSetState({ pages, positions: items, contentSections, parts });
  }, 1000, !!'asyncOnly');
  renderItemPreviewParts = (item) => {
    const { parts } = this.state;
    if (parts[item.name]) return this.renderItemPreview(item, 0);
    return this.renderItemPreview(item);
  }
  renderItemPreview = (item, part) => {
    if (!this.itemAvailableByLiquidity(item)) return;
    if (item.hidden) return null;
    const itemKey = getKey({ name: item.name, part });
    // const { classes, PDFState } = this.props;
    let left, top;
    let transform = {};
    let position = this.state.positions[itemKey];

    if (position) {
      left = position.left;
      top =  position.top;
      transform = { transform: `translate(${left}, ${top})` };
    }
    return (
      <g key={itemKey} {...transform}>
        <rect
          x="0"
          y="0"
          width={PAGE_WIDTH - 2}
          height={PAGE_HEIGHT - 2}
          rx="0"
          ry="0"
          fill={`url(#${itemKey})`}
        />
      </g>
    );
  }
  renderPagePreview = (page, index) => {
    return (
      <g
        key={`page-${index}`}
        transform={`translate(0, ${index * (PAGE_HEIGHT + PAGE_GAP)})`}
      >
        <rect
          x="0"
          y="0"
          width={PAGE_WIDTH -2}
          height={PAGE_HEIGHT -2}
          rx="0"
          ry="0"
          fill={`url(#page-${index})`}
        />
      </g>
    );
  }
  renderComponentParts = (item) => {
    const { parts } = this.state;
    if (parts[item.name]) {
      return new Array(parts[item.name]).fill(null)
        .filter((v, index) => item.hidden ? index === 0 : true)
        .map((v, index) => this.renderComponent(item, index));
    }
    return this.renderComponent(item);
  }
  renderComponent = (item, part) => {
    const itemKey = getKey({ name: item.name, part });
    if (!this.itemAvailableByLiquidity(item)) return;
    if (!!item.hidden && item.parent && !item.removable) return;
    const { classes, PDFState, artwork, PDFStore } = this.props;
    // PDFComponents
    const Component = PDFComponents[item.component] || PDFComponents[item.name] || PDFComponents.default;
    let position = this.state.positions[itemKey];
    if (!position) {
      position = {
        width: 0,
        height: 0,
        left: 0,
        top: 0,
        hidden: true,
      };
      this.reRender();
    }
    const itemStyle = {
      left: item.hidden ? 0 : position.left,
      top: position.top,
      position: 'absolute',
      zIndex: 1,
    };
    const { viewBox } = itemSVGProps({ position, item });
    return (
      <div
        key={itemKey}
        className={cx(classes.pageItem)}
        style={itemStyle}
        data-item-name={itemKey}
      >
        <svg
          width={viewBox.width}
          height={viewBox.height}
          xmlSpace="preserve"
          data-item-name={itemKey}
          viewBox={`0 0 ${viewBox.width} ${viewBox.height}`}
        >
          <defs>
            <pattern
              id={itemKey}
              x="0"
              y="0"
              width={PAGE_WIDTH}
              height={PAGE_HEIGHT}
              // patternUnits="objectBoundingBox"
              viewBox={`0 0 ${PAGE_WIDTH} ${PAGE_HEIGHT}`}
              patternUnits="userSpaceOnUse"
            >
              <Component
                item={item}
                contentSections={this.state.contentSections}
                PDFState={PDFState}
                previewMode={this.state.previewMode}
                artwork={artwork}
                part={part}
              />
            </pattern>
          </defs>
          {
            !item.hidden && !position.hidden &&
              <rect
                x="0"
                y="0"
                width={position.width || PAGE_WIDTH}
                height={position.height || PAGE_HEIGHT}
                rx="0"
                ry="0"
                fill={`url(#${itemKey})`}
              />
          }
          {
            item.removable && !item.hidden && !position.hidden &&
              <ToggleItemHidden
                item={item}
                viewBox={viewBox}
              />
          }
          {
            item.name === 'AOMainPDF' &&
              <ToggleItemHidden
                item={item}
                viewBox={viewBox}
                onClick={() => PDFStore.dispatch({ type: 'TOGGLE_RTV' })}
                removed={!PDFState.rtvShown}
              />
          }
        </svg>
      </div>
    );
  }

  renderPage = (page, index) => {
    const { PDFState, artwork } = this.props;
    const { contentSection } = page;
    const Page = PageComponent[contentSection] || PageComponent.default;
    return (
      <svg
        width={PAGE_WIDTH}
        height={PAGE_HEIGHT}
        viewBox={`0 0 ${PAGE_WIDTH} ${PAGE_HEIGHT}`}
        xmlSpace="preserve"
        key={`${contentSection}-${index}`}
      >
        <defs>
          <pattern
            id={`page-${index}`}
            x="0"
            y="0"
            width={PAGE_WIDTH}
            height={PAGE_HEIGHT}
            // patternUnits="objectBoundingBox"
            viewBox={`0 0 ${PAGE_WIDTH} ${PAGE_HEIGHT}`}
            patternUnits="userSpaceOnUse"
          >
            <Page
              page={page}
              total={this.state.pages.length}
              PDFState={PDFState}
              artwork={artwork}
            />
          </pattern>
        </defs>
        <rect
          x="0"
          y="0"
          width={PAGE_WIDTH}
          height={PAGE_HEIGHT}
          rx="0"
          ry="0"
          fill={`url(#page-${index})`}
        />
      </svg>
    );
  }

  onClose = () => {
    this.props.togglePDFReport();
  }

  render() {
    // eslint-disable-next-line no-unused-vars
    const { PDFState, PDFStore, PDFEvents } = this.props;
    const { classes } = this.props;
    return (
      <PDFModal onClose={this.onClose}>
        <div className={classes.PDFPreview}>
          <div className={classes.previewSidebar}>
            <svg width="185" height={Math.round((this.state.pages.length + PAGE_GAP) / 4 * PAGE_HEIGHT)}>
              <g transform="scale(0.25)">
                {this.state.pages.map(this.renderPagePreview)}
                {PDFState.items.map(this.renderItemPreviewParts)}
              </g>
            </svg>
          </div>
          <div className={classes.content}>
            <div className={classes.pages}>
              {
                this.state.pages.map(this.renderPage)
              }
            </div>
            {
              PDFState.items.map(this.renderComponentParts)
            }
          </div>
          <div className={classes.removedItemsSidebar}>
            <PDFRemovedItemsSidebar
              onGeneratePDF={this.onGeneratePDF}
              positions={this.state.positions}
              items={PDFState.items}
              parts={this.state.parts}
              PDFState={PDFState}
              generateDisabled={!this.state.previewMode}
            />
          </div>
        </div>
      </PDFModal>
    );
  }
}

PDFPreview = compose(
  // due to performance reasons (don't render too many components in same time)
  delayRender(2000),
  connect({
    artist: artistSelector,
  }),
  connectEventsContainer,
  connectPDFStore,
  injectSheet(sheet),
)(PDFPreview);

export { PDFPreview };
