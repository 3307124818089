import { asyncAction, action } from 'lib/actions';


export const removeShareAction = asyncAction('shares/REMOVE');
export const removeMeFromShareAction = asyncAction('shares/REMOVE_ME');

export const sharedArtworkAppendPageAction = asyncAction('shares/SHARED_ARTWORK_LIST_APPEND_PAGE');
export const getSharedArtworkListAction = asyncAction('shares/GET_SHARED_ARTWORK_LIST');
export const getSharedArtworkAction = asyncAction('shares/GET_SHARED_ARTWORK');
export const getSharedGalleriesAction = asyncAction('shares/GET_SHARED_GALLERIES');
export const getShareAction = asyncAction('shares/GET_SHARE');

export const getSharedWithMeAction = asyncAction('shares/GET_SHARED_WITH_ME');
export const getSharedWithMeAppendPageAction = asyncAction('shares/GET_SHARED_WITH_ME_APPEND_PAGE');
export const getSharedByMeAction = asyncAction('shares/GET_SHARED_BY_ME');
export const getSharedByMeAppendPageAction = asyncAction('shares/GET_SHARED_BY_ME_APPEND_PAGE');

export const removeEmailsFromShareAction = asyncAction('shares/REMOVE_EMAILS');
export const sortSharedByMeAction = action('shares/SORT_SHARED_BY_ME');
export const sortSharedWithMeAction = action('shares/SORT_SHARED_WITH_ME');

// share popup
export const showSharePopupAction = action('shares/SHOW_SHARE_POUP');
export const shareEntitiesAction = asyncAction('shares/SHARE_ENTITIES');
export const shareShowEditPopupAction = action('shares/SHOW_EDIT_POPUP');
export const saveShareAction = asyncAction('shares/SAVE_SHARE');
export const sharedArtworkViewedAction = asyncAction('shares/SHARED_ARTWORK_VIEWED');
export const shareViewedAction = asyncAction('shares/SHARE_VIEWED');
