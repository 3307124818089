import React from 'react';
import cx from 'classnames';

import { ILLIQUID } from 'domain/const';

import { connectEventsContainer } from 'pages/highcharts/helpers/EventsContext';
import { RefSize } from 'pages/highcharts/helpers/refSize';
import { DEFAULT_FONTFAMILY } from 'pages/highcharts/helpers/PDFConstants';
import { EVENT_GET_PAGE_COMPONENT } from 'pages/highcharts/helpers/PDFConstants';


const PARAMS = [
  { title: 'Liquidity Level', key: 'liquidity_level', width: 93 },
  { title: 'Median Compound Growth Rate (CAGR)', key: 'median_compaund_anual_return_car', width: 180, suffix: '%' },
  { title: 'Artist Sell-Through Rate', key: 'artist_self_throught_rate', width: 132, suffix: '%' },
  { title: 'Repeat Sales Ranking', key: 'artist_liquidity_ranking', width: 116, totalKey: 'artist_total_liquidity_ranking' },
  { title: 'Artist Volatility (Risk)', key: 'risk_rating_cov', width: 123 },
  { title: 'Artist Returns Ranking', key: 'artist_returns_ranking', width: 111, totalKey: 'artist_total_returns_ranking' },
];

const hiddenArtworkReportItems = [
  'median_compaund_anual_return_car',
  'risk_rating_cov',
  'artist_returns_ranking',
];

const VALUE_CHARS_TO_PX = 15;

const formatValue = (param, item) => {
  const firstUpperCase = s => s.charAt(0).toUpperCase() + s.slice(1);
  const { key } = param;
  switch (key) {
    case 'liquidity_level':
      return firstUpperCase(item[key] || '');
    default:
      return item[key];
  }
};

class AFPM extends RefSize {

  state = {
    AFPMItem: null,
  };

  componentDidMount() {
    const { PDFEvents, item } = this.props;
    const { item: AFPMItem } = PDFEvents.dispatch(EVENT_GET_PAGE_COMPONENT, { id: item.name }) || { item: null };
    this.setState({ AFPMItem });
    super.componentDidMount();
  }
  isAvailable(param) {
    const { PDFState } = this.props;
    if (PDFState.liquidity !== ILLIQUID) return true;
    if (hiddenArtworkReportItems.includes(param)) return false;
    return true;
  }
  render() {
    const { AFPMItem } = this.state;
    const { item } = this.props;
    let top = item.hidden ? 0 : 20 + 17 + 17 + 30;
    const height = item.hidden ? 480 : 560;
    const width = 250;
    return (
      <svg width={width} height={height} ref={this.ref} data-top="89" data-left="15" data-allow-top-overlap>
        <style>
          {`
          /**
           * Note that the color of the text is set with the
           * fill property, the color property is for HTML only
          **/
          .AFPM_subTitle { font: 500 13.69px ${DEFAULT_FONTFAMILY}; fill: #000; }
          .AFPM_description { font: 400 10px ${DEFAULT_FONTFAMILY}; fill: #737992; }
          .AFPM_label { font: 700 9px ${DEFAULT_FONTFAMILY}; fill: #454A5F; }
          .AFPM_value { font: 700 16px ${DEFAULT_FONTFAMILY}; fill: #000; }
          .AFPM_value.even { font: 700 16px ${DEFAULT_FONTFAMILY}; fill: #304C95; }
          .AFPM_total { font: 700 16px ${DEFAULT_FONTFAMILY}; fill: #8C92AB; }
          `}
        </style>
        {/* <rect height={height} width={width} fill="#F00" /> */}
        <text x="10" y={top += 37} className="AFPM_subTitle">
          Artist Financial Performance Metrics
        </text>
        <text x="10" y={top += 17} className="AFPM_description">
          An overview of the artist’s key performance indicators
        </text>
        {void (top += -35)}
        {
          AFPMItem && PARAMS.map(({ title, key, width, suffix, totalKey }, index) => (
            this.isAvailable(key) &&
            <g key={key} transform={`translate(0, ${top += 66})`} data-key={key}>
              <rect width={width} height="56" fill="#F0F2F6" />
              {/*<rect width={width} height="56" fill="#CCC" />*/}
              <text x="10" y="21" className="AFPM_label">
                {title}
              </text>
              <text x="10" y="42" className={cx('AFPM_value', { even: !((index + 1) % 2) })}>
                {formatValue(PARAMS[index], AFPMItem)}{suffix ? suffix : null}
              </text>
              {
                totalKey &&
                  <text x={String(AFPMItem[key]).length * VALUE_CHARS_TO_PX} y="42" className={cx('AFPM_total', { even: !((index + 1) % 2) })}>
                    {' / '}{AFPMItem[totalKey]}
                  </text>
              }
            </g>
          ))
        }
      </svg>
    );
  }
}

AFPM = connectEventsContainer(AFPM);

export { AFPM };
