import record, { listOf } from 'cpcs-recordjs';
import { field, selector } from 'cpcs-reconnect';
import { listToMap } from 'lib/serialize';
import * as A from './CategoryAction';
import { isArray } from 'lib/helpers';
import { Category } from 'model';


const normalizeCategoriesList = list => {
  if (!isArray(list)) {
    list = [];
  }
  return list
    .filter(v => !!v && !!v.category && v.category.id)
    .map(v => ({
      id: v.category.id,
      title: v.category.title,
      commonSubstrates: v.commonSubstrates,
      commonMediums: v.commonMediums,
      commonSurfaces: v.commonSurfaces,
    }));
};

const State = record('Categories', {
  content: listOf(Category),
  loading: false,
});

export const categories = field('categories');
export const categoryList = categories.then(State.$content);
export const categoryList_sel = categoryList;
export const categoryById = selector(categoryList_sel, listToMap);
export const categoryById_sel = categoryById;
export const loading_sel = categories.then(State.$loading);

export const reducer = {
  categories(state = new State(), action) { //NOSONAR
    switch (action.type) { //NOSONAR

      case A.fetchCategoryAction.request:
        return state.update(State.$loading.set(true));
      case A.fetchCategoryAction.failure:
        return state.update(State.$loading.set(false));
      case A.fetchCategoryAction.success:
        return state.apply(
          State.$content.set(
            State.$content.type.parse(normalizeCategoriesList(action.payload)),
          ),
        );

      default:
        return state;
    }
  },
};
