import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'cpcs-reconnect';
import { compose } from 'redux';
import I from 'immutable';
import cx from 'classnames';
import AccountNav from 'pages/common/topNav';
import { addNotification as addNotificationAction, saveStripeCardAction } from 'domain/env/EnvActions';
import { subscriptionTypeSelector, navigationBlocked_sel } from 'domain/env/EnvModel';
import { extraFooterGap_sel } from 'domain/ui/UIModel';
import { locationHash_sel, query_sel } from 'domain/router/RouterModel';
import {
  setSelectedPlanTypeAction,
  upgradePlanAction,
} from 'pages/upgradeSubscriptionPage/upgradeSubscriptionActions';
import PageContainer from 'pages/common/pageContainer';
import {
  selectedPlanTypeSelector,
  planTypesSel,
  selectedSubscriptionSel,
  submitInProgressSel,
  planTypesLoading_sel,
} from 'pages/upgradeSubscriptionPage/upgradeSubscriptionModel';
import {
  percentOffSelector,
  amountOffSelector,
} from 'pages/common/couponForm/couponFormModel';
import {
  SUBSCRIPTION_TYPE_TRIAL,
} from 'domain/const';

import injectSheet from 'lib/sheet';
import styles from './sheet.js';

class UpgradeSubscriptionSectionPure extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    planTypes: PropTypes.instanceOf(I.Collection),
    setSelectedPlanType: PropTypes.func.isRequired,
    selectedPlanType: PropTypes.string,
    percentOff: PropTypes.number,
    amountOff: PropTypes.number,
    upgradePlan: PropTypes.func.isRequired,
    submitInProgress: PropTypes.bool,
    selectedSubscription: PropTypes.instanceOf(I.Collection),
    addNotification: PropTypes.func.isRequired,
    modifier: PropTypes.string,
    allowSaveBillingInfo: PropTypes.bool,
    saveStripeCard: PropTypes.func.isRequired,
    showCongratulation: PropTypes.bool,
    planTypesLoading: PropTypes.bool,
    currentSubscriptionType: PropTypes.string,
    locationHash: PropTypes.string,
    query: PropTypes.shape({
      congratulation: PropTypes.string,
    }),
    navigationBlocked: PropTypes.bool,
  };

  state = {
    formsReinitialized: false,
  };

  unmounted = false;
  rootRef = null;

  componentWillUnmount() {
    this.unmounted = true;
    clearTimeout(this.scrolledToBilling);
  }

  scrolledToBilling = null;

  componentDidUpdate() {
    if (this.scrolledToBilling) {
      return;
    }
    const { planTypes } = this.props;
    if (planTypes && !!planTypes.size) {
      this.scrolledToBilling = setTimeout(this.scrollToRoot, 500);
    }
  }

  componentDidMount() {
    setTimeout(() => this.safeSetState({ formsReinitialized: true }), 500);
  }

  scrollToRoot = () => {
    const { locationHash, query } = this.props;
    if (locationHash !== '#billing' && (!query || !query.congratulation) && !this.expired) return;
    if (this.rootRef && !this.unmounted) {
      const y = window.scrollY + this.rootRef.getBoundingClientRect().y - 50;
      window.scrollTo(0, y);
    }
  }

  safeSetState = (state, cb) => {
    if (this.unmounted) return;
    this.setState(state, cb);
  }

  setRootRef = (node) => this.rootRef = node;

  onPlanTypeClick = (v) => () => {
    this.props.setSelectedPlanType(v.get('shortName'));
  }

  onStripeCardToken = ({ autoSubmit, ...payload }) => {
    const { saveStripeCard, allowSaveBillingInfo } = this.props;
    if (!autoSubmit && allowSaveBillingInfo) {
      saveStripeCard(payload);
    }
  };

  upgradePlan = () => {
    const { submitInProgress, selectedPlanType, currentSubscriptionType, planTypes, addNotification } = this.props;
    if (selectedPlanType === currentSubscriptionType) return;
    if (submitInProgress) return;
    if (!selectedPlanType || !planTypes.find(v => v.get('shortName') === selectedPlanType)) {
      addNotification({ title: 'Select subscription plan', type: 'error' });
      return;
    }
    this.props.upgradePlan();
  };

  get priceNum() {
    const { selectedSubscription/*, percentOff, amountOff*/ } = this.props;
    /**
     * selectedSubscription: { subscriptionProratedValue: 1400, subscriptionValue: 1800 }
    **/
    // if (!selectedSubscription) return null;
    // if (percentOff) {
    //   return selectedSubscription.subscriptionProratedValue - selectedSubscription.subscriptionProratedValue * percentOff / 100;
    // }
    // if (amountOff) {
    //   return Math.max(0, selectedSubscription.subscriptionProratedValue - amountOff / 100);
    // }
    return selectedSubscription.subscriptionProratedValue;
  }

  get price() {
    const { classes, currentSubscriptionType, selectedPlanType, percentOff, amountOff, selectedSubscription } = this.props;
    if (!selectedSubscription || (!this.priceNum && !percentOff && !amountOff)) return null;
    if (selectedPlanType === currentSubscriptionType) return null;
    return <span
      className={classes.priceVal}
    >
      Total: ${this.priceNum}
    </span>;
  }

  get upgradeDisabled() {
    const { selectedPlanType, currentSubscriptionType, planTypesLoading, submitInProgress } = this.props;
    return submitInProgress || planTypesLoading || selectedPlanType === currentSubscriptionType;
  }

  get expired() {
    const { currentSubscriptionType, navigationBlocked } = this.props;
    return currentSubscriptionType === SUBSCRIPTION_TYPE_TRIAL && !!navigationBlocked;
  }

  render() {
    return (<div></div>);
  }
}

export const UpgradeSubscriptionSection = compose(
  injectSheet(styles),
  connect({
    planTypes: planTypesSel,
    setSelectedPlanType: setSelectedPlanTypeAction,
    selectedPlanType: selectedPlanTypeSelector,
    selectedSubscription: selectedSubscriptionSel,
    percentOff: percentOffSelector,
    amountOff: amountOffSelector,
    upgradePlan: upgradePlanAction,
    submitInProgress: submitInProgressSel,
    addNotification: addNotificationAction,
    saveStripeCard: saveStripeCardAction,
    currentSubscriptionType: subscriptionTypeSelector,
    query: query_sel,
    locationHash: locationHash_sel,
    planTypesLoading: planTypesLoading_sel,
    navigationBlocked: navigationBlocked_sel,
  }),
)(UpgradeSubscriptionSectionPure);

function UpgradeSubscriptionPage({ classes, extraFooterGap, match: { path } }) {
  return (
    <PageContainer modifier={cx('UpgradeSubscriptionPage', { extraFooterGap })}>
      <div className="PAGE_SECTION UpgradeSubscriptionPage">
        <div className={classes.header}>
          <AccountNav path={path} />
        </div>
        <UpgradeSubscriptionSection modifier="UpgradeSubscriptionPage" />
      </div>
    </PageContainer>
  );
}

UpgradeSubscriptionPage.propTypes = {
  extraFooterGap: PropTypes.bool.isRequired,
  match: PropTypes.shape({
    path: PropTypes.string.isRequired,
  }).isRequired,
  classes: PropTypes.shape({
    header: PropTypes.string.isRequired,
  }).isRequired,
};

export default compose(
  injectSheet(styles),
  connect({
    extraFooterGap: extraFooterGap_sel,
  }),
)(UpgradeSubscriptionPage);

