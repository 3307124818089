import { action, asyncAction } from 'lib/actions';

export const considerationAppendPageAction = asyncAction('considering/APPEND_PAGE');
export const fetchConsiderationsAction = asyncAction('considering/GET_LIST');

export const fetchConsiderationItemAction = asyncAction('considering/GET_ITEM');

export const submitTrackFormAction = asyncAction('track/SUBMIT');
export const createConsiderationAction = asyncAction('considering/CREATE_ITEM');
export const createSuggestedConsiderationAction = asyncAction('considering/ADD_FROM_SUGGESTION');
export const updateConsiderationAction = asyncAction('considering/UPDATE_ITEM');
export const saveDraftConsiderationAction = asyncAction('considering/SAVE_DRAFT');
export const updateSelectedAction = action('considering/UPDATE_SELECTED');
export const setSelectedAction = action('considering/SET_SELECTED');
export const clearSelectedAction = action('considering/CLEAR_SELECTED');
