import { DARK_BLUE_COLOR, DEFAULT_FONTFAMILY } from 'theme/theme';
import { PageHeader } from 'theme/Dashboard';

export const sheet = {
  PageHeader,
  title: {
    color: DARK_BLUE_COLOR,
    fontSize: 24,
    fontWeight: 700,
    lineHeight: 1.25,
    fontFamily: DEFAULT_FONTFAMILY,
    minWidth: 0,
    flex: [0, 0, '50%'],
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    '@media screen and (max-width: 610px)': {
      width: '100%',
      marginTop: 26,
      order: 2,
    },
  },
};
