import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'cpcs-reconnect';
import I from 'immutable';
import {
  considerToRemoveAction,
  TYPE_GALLERY,
  SHARE_TYPE_GALLERY,
  RENAME_GALLERY_POPUP,
} from 'domain/const';
import { showSharePopupAction } from 'domain/shares/ShareActions';
import { addPopupAction } from 'domain/ui/UIActions';


class MenuItem extends React.PureComponent {

  static propTypes = {
    item: PropTypes.instanceOf(I.Map),
    gallery: PropTypes.instanceOf(I.Collection).isRequired,
    className: PropTypes.string,
    onRemove: PropTypes.func.isRequired,
    showSharePopup: PropTypes.func.isRequired,
    addPopupAction: PropTypes.func.isRequired,
  };

  deleteGallery = () => {
    const { onRemove, gallery } = this.props;
    onRemove({
      type: TYPE_GALLERY,
      params: { galleryId: gallery.get('id') },
      thumb: gallery.get('picture'),
      name: gallery.get('title'),
    });
  }

  openShare = () => {
    this.props.showSharePopup({
      shareType: SHARE_TYPE_GALLERY,
      ids: new I.Set([this.props.gallery.get('id')]),
    });
  }

  openRename = () => {
    this.props.addPopupAction({
      name: RENAME_GALLERY_POPUP,
      params: { galleryId: this.props.gallery.get('id') },
    });
  }

  render() {
    const { item, className } = this.props;
    switch (item.get('slug')) {
      case '/art/share/gallery':
        return (
          <button
            type="button"
            onClick={this.openShare}
            children={item.get('title')}
            className={className}
            data-name="share-gallery-menu-button"
          />
        );
      case '/art/delete':
        return (
          <button
            type="button"
            onClick={this.deleteGallery}
            children={item.get('title')}
            className={className}
          />
        );
      case '/art/rename':
        return (
          <button
            type="button"
            onClick={this.openRename}
            children={item.get('title')}
            className={className}
            data-name="rename-gallery-menu-button"
          />
        );
      default:
        return null;
    }
  }
}

export default connect({
  onRemove: considerToRemoveAction,
  showSharePopup: showSharePopupAction,
  addPopupAction,
})(MenuItem);
