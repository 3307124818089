import { call, select } from 'redux-saga/effects';
import { confirmGenCall } from 'sagas/confirm/index';
import { confirmAcceptAction } from 'domain/ui/UIActions';
import { currentGalleryIsDefault_sel } from 'domain/env/EnvModel';

export function* confirmGenCallIfDefaultGallery({ title, description, thumb }, genF, ...args) {
  const isDefault = yield select(currentGalleryIsDefault_sel);
  if (!isDefault) {
    yield call(genF, ...args);
    return { success: confirmAcceptAction.type };
  }
  return yield call(confirmGenCall, { title, description, thumb }, genF, ...args);
};
