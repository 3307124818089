import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'lib/sheet';

const sheet = {
  link: {
    marginTop: 50,
    display: 'inline-block',
    color: 'red',
  },
};

function T({ ticket, classes }) {
  if (Array.isArray(ticket)) {
    return (
      <React.Fragment>
        {
          ticket.map(item => (
            <a href={`https://artbnkdev.atlassian.net/browse/${item}`} className={classes.link} key={item}>{item}</a>
          ))
        }
      </React.Fragment>
    );
  }
  return <a href={`https://artbnkdev.atlassian.net/browse/${ticket}`} className={classes.link}>{ticket}</a>;
}

T.propTypes = {
  ticket: PropTypes.string.isRequired,
  classes: PropTypes.shape({
    link: PropTypes.string.isRequired,
  }).isRequired,
};

T = injectSheet(sheet)(T);

export { T };
