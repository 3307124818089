import { LAYOUT_RANGE_768_MAX, LAYOUT_RANGE_1024_MAX } from 'theme/layout/LAYOUT';

export const PAGE_INNER_COL1 = {
  width: 526,
  marginRight: 40, //artwork page, considering artwork page
  '&.alertDetailPage': {
    flex: '0 1 50%', // alert detail page
  },
  '&.collectionItemPage,&.artworkPage,&.sharedArtWorkPage': {
    flex: '0 0 526px',
    width: 'unset',
    // do not expand flex block by it's children
    minWidth: 0,
    '@media screen and (max-width: 1200px)': {
      flex: '0 0 402px',
      marginRight: 30,
    },
    '@media screen and (max-width: 900px)': {
      flex: 'unset',
      width: 363,
      margin: '0 auto',
    },
    '@media screen and (max-width: 392px)': {
      flex: 'unset',
      width: 343,
      margin: '0 auto',
    },
    '@media screen and (max-width: 373px)': {
      width: '100%',
    },
  },
  '&.newArtPageCol1, &.newConsideringPage': {
    // NEW_ART_PAGE
    flex: [0, 1, '460px'],
    [`@media screen and (max-width: ${LAYOUT_RANGE_1024_MAX}px)`]: {
      flex: [0, 0, '402px'],
      width: 402,
      marginRight: 30,
    },
    [`@media screen and (max-width: ${LAYOUT_RANGE_768_MAX}px)`]: {
      flex: 'unset',
      width: 'auto',
      marginRight: 0,
    },
  },
  '&.ArtItemForm': {
    // NEW_ART_PAGE
    [`@media screen and (max-width: ${LAYOUT_RANGE_1024_MAX}px)`]: {
      flex: [0, 0, '372px'],
      width: 372,
      marginRight: 0,
    },
    [`@media screen and (max-width: ${LAYOUT_RANGE_768_MAX}px)`]: {
      marginTop: 45,
      width: 'auto',
      marginRight: 0,
    },
  },
};
