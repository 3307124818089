import I from 'immutable';
const mm = { id: 'MM', title: 'mm' };
const CM = { id: 'CM', title: 'cm' };
const INCH = { id: 'INCH', title: 'inch' };
const MKM = { id: 'MKM', title: 'mkm' };
const M = { id: 'M', title: 'm' };

export const UNITS = { MKM, MM: mm, CM, INCH, M };

const unitsList = I.fromJS([
  mm,
  CM,
  INCH,
]);

const currencies = {
  USD: '$',
  GBP: '£',
  EUR: '€',
  CHF: '₣',
  CNY: '¥',
  HKD: 'HK$ ',
  CAD: 'CA$ ',
  AUD: 'AU$ ',
  SGD: 'SG$ ',
  BTC: 'BTC',
};

export const currencySymbolMap = currencies;

export const currencyList = I.fromJS(Object.keys(currencies).map(v => ({ id: v, title: v })));
export const preferredCurrencyList = I.fromJS(Object.keys({
  USD: '$',
  GBP: '£',
  EUR: '€',
  CHF: '₣',
  HKD: 'HK$ ',
  BTC: 'BTC',
}).map(key => ({ id: key, title: key })));

export const formatPrice = (price, currency) => {
  const { value } = price || {};
  if (typeof value === 'undefined') return '';
  try {
    return new Intl
      .NumberFormat('en-US', { style: 'decimal', maximumSignificantDigits: 9 })
      .format(currency === 'BTC' ? +(+value || 0).toFixed(3) : Math.round(value));
  } catch (err) {
    console.error({ err, debug: 'not a number?', value, currency, fn: 'formatPrice' });
  }
  return 'ERROR';
};

export const formatCurrency = (v, currency = 'USD', defaultDigits = 2) => new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency,
  minimumFractionDigits: currency === 'BTC' ? 3 : defaultDigits,
  maximumFractionDigits: currency === 'BTC' ? 3 : defaultDigits,
}).format(v);

export const formatCurrencySrv = (v, currency = 'USD') => formatCurrency(v / 100, currency);

export const valuation = ({ value, currency = 'USD' }) => {
  if (typeof value === 'undefined') return '';
  let val;
  try {
    val = new Intl
      .NumberFormat('en-US', { style: 'decimal', maximumSignificantDigits: 9 })
      .format(currency === 'BTC' ? +(+value || 0).toFixed(3) : Math.round(value));
  } catch (err) {
    console.error({ err, debug: 'not a number?', value, currency, fn: 'valuation' });
  }
  // return currencies[currency] ? `${currencies[currency]}${val}` : `${val} ${currency}`;
  return `${val} ${currency}`;
};

export const rtv = ({ rtvPrice = 0, rtvCurrency = 'USD' }, postfix = false) => {
  if (!rtvPrice) return '';
  let formattedPrice = 0;
  try {
    formattedPrice = new Intl
      .NumberFormat('en-US', { style: 'decimal', maximumSignificantDigits: 9 })
      // .format(Math.round(rtvPrice));
      .format(rtvCurrency === 'BTC' ? +(+rtvPrice || 0).toFixed(3) : Math.round(rtvPrice));
  } catch (err) {
    console.error({ err, debug: 'not a number?', rtvPrice, rtvCurrency, fn: 'rtv' });
  }
  return `${formattedPrice}${rtvCurrency ? ' ' + rtvCurrency : ''}${postfix ? ' ARTBnk Value' : ''}`;
};

export const totalRtv = ({ value = 0, unit = 'USD' }, postfix = false) => {
  if (!value) return '';
  let formattedPrice = 'ERROR';
  try {
    formattedPrice = new Intl
      .NumberFormat('en-US', { style: 'currency', currency: unit, maximumSignificantDigits: 9 })
      // .format(Math.round(value));
      .format(unit === 'BTC' ? +(+value || 0).toFixed(3) : Math.round(value));
  } catch (err) {
    console.error({ err, debug: 'not a number?', value, unit, fn: 'totalRtv' });
  }
  return `${formattedPrice}${postfix ? ' ARTBnk Value' : ''}`;
};

export default unitsList;
