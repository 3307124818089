import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import SmartImage from 'components/image/SmartImage';

import injectSheet from 'lib/sheet';
import sheet from 'pages/common/popup/sheet';

function Title({ classes, children, ico, onClose, icoWidth = 65, icoHeight = 40, modifier, actions = null, description = null }) {
  const imgParams = { width: icoWidth, height: icoHeight, cover: true };
  return (
    <div className={cx(classes.titleWrapper, modifier, { noText: !children })}>
      {
        ico &&
          <SmartImage
            src={ico}
            params={imgParams}
            modifier="popupTitleIco"
          />
      }
      <h2 className={cx(classes.title, modifier)}>{children}</h2>
      {
        description &&
          <p className={cx(classes.description, modifier)}>{description}</p>
      }
      {actions}
      {
        onClose &&
          <button
            type="button"
            onClick={onClose}
            className={classes.close}
          />
      }
    </div>
  );
}

Title.propTypes = {
  classes: PropTypes.shape({
    titleWrapper: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    close: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]).isRequired,
  ico: PropTypes.string,
  onClose: PropTypes.func,
  icoWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  icoHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  modifier: PropTypes.string,
  actions: PropTypes.node,
  description: PropTypes.node,
};

export default injectSheet(sheet)(Title);
