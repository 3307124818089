/* eslint no-unused-vars: "warn" */
import React from 'react';

import { connectEventsContainer } from 'pages/highcharts/helpers/EventsContext';
import { RefSize } from 'pages/highcharts/helpers/refSize';
import { EVENT_GET_PAGE_COMPONENT } from 'pages/highcharts/helpers/PDFConstants';
import { cutToFitScalable } from 'pages/highcharts/helpers/imgToDataURL';


class AOImage extends RefSize {
  static propTypes = {
    //
  };

  state = {
    img: null,
  };

  componentDidMount() {
    const { PDFEvents, item } = this.props;
    const ret = PDFEvents.dispatch(EVENT_GET_PAGE_COMPONENT, { id: item.name });
    const { item: { img } } = ret || { item: {} };
    if (img) {
      this.setImgBlob(img);
    }
    super.componentDidMount();
  }

  setImgBlob = async (img) => {
    const { metadata: { width: maxWidth, height: maxHeight } } = this.props.item.toJS();
    const state = {
      img,
      // src, width, height
      ...(await cutToFitScalable({ img, maxWidth, maxHeight, cut: false, vAlign: 'top' })),
    };
    this.safeSetState(state);
  }

  render() {
    const { item } = this.props;
    let { metadata: { width, height, left, top } = {} } = item.toJS();
    if (this.state.img) {
      width = this.state.width;
      height = this.state.height;
    }
    return (
      <svg ref={this.ref} data-top={top} data-left={left} width={width} height={height}>
        {
          this.state.img &&
            <image href={this.state.src} height={height} width={width} />
        }
      </svg>
    );
  }
}

AOImage = connectEventsContainer(AOImage);

export { AOImage };
