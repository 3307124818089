import queryString from 'query-string';
import { compose } from 'redux';

const isEmpty = (value) => ![value].join('');

export function cleanEmpty(hashTable) {
  return Object
    .keys(hashTable)
    .reduce((collect, key) => (!isEmpty(hashTable[key]) ? { ...collect, [key]: hashTable[key] } : collect), {});
}

function paramsStringify(p) {
  return queryString.stringify(p, { arrayFormat: 'bracket' });
}

export function cleanParams(params) {
  return compose(
    paramsStringify,
    ({ artistName , ...params }) => params,
    cleanEmpty,
  )(params);
}


function filterSearchParams(params) {
  params = { ...params };
  for (let key of ['page', 'currency']) {
    delete params[key];
  }
  return params;
}

export const parseLocationSearch = search => (compose(
  filterSearchParams,
  cleanEmpty,
  (v) => queryString.parse(v, { arrayFormat: 'bracket' }),
)(search));
