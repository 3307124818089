import cardIco from 'pages/billingPage/images/card.svg';
import {
  DARK_BLUE_COLOR,
  DEFAULT_FONTFAMILY,
  DEFAULT_BUTTON_COLOR,
  LOGIN_PLACEHOLDER_COLOR,
} from 'theme/theme';
import { FOCUSED_FIELD_STYLE } from 'theme/focus';

export const field = {
  height: 32,
  backgroundColor: '#FFF',
  border: '1px solid #e6e6e6',
  width: '100%',
  borderRadius: 2,
  appearance: 'none',
  padding: [0, 8],
  fontSize: 14,
  position: 'relative',
  fontFamily: DEFAULT_FONTFAMILY,
  whiteSpace: 'nowrap',
  // color: DARK_BLUE_COLOR,
  '&:focus': {
    // outline: `2px solid ${FOCUS_OUTLINE}`,
    // outline: 'none',
    // boxShadow: '0 0 3px 2px #4990e2',
    ...FOCUSED_FIELD_STYLE,
  },
  '&:disabled': {
    opacity: 0.5,
    userSelect: 'none',
  },
  '&::placeholder': {
    color: '#babcbf',
  },
  '&.error': {
    borderColor: '#d0021b',
  },
  // '&.inputField': {
  //   overflow: 'hidden',
  //   textOwerflow: '',
  //   whiteSpace: 'nowrap',
  //   textOverflow: 'ellipsis',
  // },
  '&.input_with_currency_sign': {
    border: 'none',
    padding: 0,
    float: 'left',
    width: 20,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'transparent',
    paddingLeft: 8,
  },
  '&.input_with_currency_root': {
    padding: 0,
  },
  '&.input_with_currency': {
    border: 'none',
    display: 'inline-block',
    backgroundColor: 'transparent',
    paddingLeft: 28,
    marginLeft: -20,
  },
  '&.Textarea': {
    height: 65,
    resize: 'none',
    padding: [6, 8],
    '&.AOForm': {
      height: 80,
    },
    '&.ShareNote': {
      height: 80,
    },
  },
  '&.Input': {
    
  },
  '&.DateField': {
    
  },
  '&.SelectField': {
    // height: 46,
    /**
     * height: tags can have any height
    **/
    height: 'auto',
    padding: 0,
  },
  '&.provenance-date': {
    flex: [0, 1, '70px'],
  },
  '&.dateField': {
    display: 'flex',
    width: 110,
    '&.acquisition': {
      display: 'block',
    },
    '&.salesHistory': {
      display: 'block',
    },
  },
  '&.passwordField': {
    '&::-ms-reveal': {
      display: 'none',
    },
    '&::-ms-clear': {
      display: 'none',
    },
  },
  '&.advancedFilter-PeriodDate': {
    height: 30,
    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      appearance: 'none',
      margin: 0,
    },
  },
  '&.artistDiedIn': {
    marginLeft: 15,
  },
  '&.rtv-form-coupon': {
    padding: [6, 8],
    height: 44,
  },
  '&.SignUpPageField--Select': {
    height: 46,
  },
  '&.AccountFormField': {
    height: 46,
    padding: 15,
    lineHeight: '16px',
    color: DARK_BLUE_COLOR,
    fontWeight: 400,
  },
  '&.ChangePasswordForm.passwordField': {
    height: 46,
    padding: 15,
    lineHeight: '16px',
    color: DARK_BLUE_COLOR,
    fontWeight: 400,
  },
  '&.ResetPage.passwordField, &.ForgotPageEmail, &.authPageField, &.SignUpPageField': {
    height: 34,
    padding: 15,
    lineHeight: '16px',
    color: DARK_BLUE_COLOR,
    fontWeight: 400,
    borderRadius: '4px',
    '&::placeholder': {
      fontSize: '12px',
      color: LOGIN_PLACEHOLDER_COLOR,
      opacity: 0.8,
    },
  },
  '&.CreatePassword': {
    width: 253,
    height: 44,
    border: '2px solid #000000',
  },
  '&.billingPageField--Country': {
    height: 46,
  },
  '&.billingPageField': {
    display: 'block',
    padding: [15, 16],
    height: 46,
    lineHeight: '16px',
    '&.cardField': {},
    '&.expired': {},
    '&.ccv': {
      '&:after': {
        position: 'absolute',
        borderRadius: '50%',
        border: `1px solid ${DEFAULT_BUTTON_COLOR}`,
        padding: [1, 3],
        left: 35,
        top: 20,
        content: '"CSV"',
        display: 'block',
        height: 6,
        lineHeight: '8px',
        color: DARK_BLUE_COLOR,
        fontFamily: DEFAULT_FONTFAMILY,
        fontSize: '4px',
        fontWeight: '400',
      },
    },
  },
  '&.Tags': {
    display: 'inline-block',
    border: '0 none',
    flex: [1, 1, '28%'],
    minWidth: '20%',
    backgroundColor: 'unset',
    '&::placeholder': {
      color: '#babcbf',
    },
    '&.shareForm': {
      height: 45,
      '&.emails': {
        '&:focus': {
          outline: 'none',
          boxShadow: 'none',
        },
      },
    },
  },
  '&.cardIconedField': {
    paddingLeft: 60,
    background: {
      image: `url("${cardIco}")`,
      repeat: 'no-repeat',
      position: '18px center',
      size: '34px 21px',
      color: '#FFF',
    },
  },
  '&.advancedFilter-Range': {
    color: DARK_BLUE_COLOR,
    fontSize: 13,
  },
  '&.priceField.PriceUnsold': {
    textAlign: 'center',
  },
  '&.BuyRtvBillingField': {
    height: 46,
    padding: 15,
    fontWeight: 400,
    lineHeight: '16px',
    color: DARK_BLUE_COLOR,
    '&.Country': {
      padding: 0,
    },
  },
  '&.AskEmailPopup': {
    height: 46,
    padding: 15,
    lineHeight: '16px',
    color: DARK_BLUE_COLOR,
    fontWeight: 400,
    whiteSpace: 'normal',
  },
  '&.Gallery-Title': {
    height: 46,
  },
};
