import { ARTWORK } from 'components/dnd/types';

export const GalleryTarget = {
  canDrop: (props, monitor) => {
    return [ARTWORK].includes(monitor.getItemType());
  },

  drop(props) {
    props.onDrop();
  },
};
