import record, { listOf, enhancedType, decimal, integer } from 'cpcs-recordjs';
import { field, at } from 'cpcs-reconnect';
import * as A from 'domain/advancedFilter/AdvancedFilterActions';
import { Category, Auction, Artist, Substrate, Surface, Medium, Pagination } from 'model';


/* */
const listWithPagination = (name, model) => {
  const ListFactory = record(`ListFactory [${name}]`, {
    entity: model,
    count: decimal,
  });
  const Factory = record(name, {
    list: listOf(ListFactory),
    pagination: Pagination,
  });
  return enhancedType({
    // type: Factory,
    typeName: `listWithPagination of ${name}`,
    parse: v => Factory.parse(v),
    defaultValue: Factory.parse({ list: [] }),
  });
};
/* */

/* * /
const listWithPagination = (name, model) => record(name, {
  list: listOf(model),
  pagination: Pagination,
});
/* */

// State
const State = record('AdvancedFilter', {
  artistList: listWithPagination('artistList', Artist).parse({ list: [] }),
  auctionList: listWithPagination('auctionList', Auction).parse({ list: [] }),
  categoryList: listWithPagination('categoryList', Category).parse({ list: [] }),
  substrateList: listWithPagination('substrateList', Substrate).parse({ list: [] }),
  surfaceList: listWithPagination('surfaceList', Surface).parse({ list: [] }),
  mediumsList: listWithPagination('surfaceList', Medium).parse({ list: [] }),
  pathname: '',
  total: integer,
});

const defaultState = (new State());

// selectors
const base = field('advancedFilter');
export const artistListSelector = base.then(State.$artistList).then(at('list'));
export const artistListPaginationSelector = base.then(State.$artistList).then(at('pagination'));

export const auctionListSelector = base.then(State.$auctionList).then(at('list'));
export const auctionListPaginationSelector = base.then(State.$auctionList).then(at('pagination'));

export const categoryListSelector = base.then(State.$categoryList).then(at('list'));
export const categoryListPaginationSelector = base.then(State.$categoryList).then(at('pagination'));

export const substrateListSelector = base.then(State.$substrateList).then(at('list'));
export const substrateListPaginationSelector = base.then(State.$substrateList).then(at('pagination'));

export const surfaceListSelector = base.then(State.$surfaceList).then(at('list'));
export const surfaceListPaginationSelector = base.then(State.$surfaceList).then(at('pagination'));

export const mediumsListSelector = base.then(State.$mediumsList).then(at('list'));
export const mediumsListPaginationSelector = base.then(State.$mediumsList).then(at('pagination'));
export const pathname_sel = base.then(State.$pathname);

export const total_sel = base.then(State.$total);

// modifiers
const $updatePagination = ({ data, filterName }) => state => {
  const append = $prop => state => {
    const filter = (data[filterName] || {});
    const parsed = $prop.type.parse((filter && filter.content) ? { list: filter.content, pagination: filter } : {});
    const oldState = $prop(state);
    // if used guessType by pasting instance to record instead of Type, type.type appears
    let newState;
    if (filter.page === 0) {
      newState = parsed;
    } else {
      const $list = $prop.type.$list || $prop.type.type.$list;
      const newList = oldState.apply(
        $list.updateBy('concat', parsed.list),
      ).list;
      newState = parsed.set('list', newList);
    }
    return state.apply($prop.set(newState));
  };
  switch (filterName) {
    case 'artists':
      return state.apply(append(State.$artistList));
    case 'auctions':
      return state.apply(append(State.$auctionList));
    case 'categories':
      return state.apply(append(State.$categoryList));
    case 'substrates':
      return state.apply(append(State.$substrateList));
    case 'surfaces':
      return state.apply(append(State.$surfaceList));
    case 'mediums':
      return state.apply(append(State.$mediumsList));
    default:
      return state;
  }
};

// reducer
export const reducer = {
  advancedFilter(state = defaultState, action) { //NOSONAR
    switch (action.type) {
      case A.fetchAdvancedFilterOptionsAction.request:
        return state.apply(
          State.$artistList.parsed({ list: [] }),
          State.$auctionList.parsed({ list: [] }),
          State.$categoryList.parsed({ list: [] }),
          State.$substrateList.parsed({ list: [] }),
          State.$surfaceList.parsed({ list: [] }),
        );
      case A.advancedFilterFetchTotalAction.success:
        return state.apply(
          State.$total.parsed(action.payload),
        );
      case A.fetchAdvancedFilterOptionsAction.success:
        return state.apply(
          State.$pathname.set(action.payload.pathname),
          State.$artistList.parsed({
            list: (action.payload.artists || {}).content,
            pagination: action.payload.artists,
          }),
          State.$auctionList.parsed({
            list: (action.payload.auctions || {}).content,
            pagination: action.payload.auctions,
          }),
          State.$categoryList.parsed({
            list: (action.payload.categories || {}).content,
            pagination: action.payload.categories,
          }),
          State.$substrateList.parsed({
            list: (action.payload.substrates || {}).content,
            pagination: action.payload.substrates,
          }),
          State.$surfaceList.parsed({
            list: (action.payload.surfaces || {}).content,
            pagination: action.payload.surfaces,
          }),
          State.$mediumsList.parsed({
            list: (action.payload.mediums || {}).content,
            pagination: action.payload.mediums,
          }),
        );
      case A.fetchAdvancedFilterPaginationAction.success:
        return state.apply($updatePagination(action.payload));
      default:
        return state;
    }
  },
};
