import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import injectSheet from 'lib/sheet';
import { btnGroup } from 'pages/common/popupForms/sheet';
import { button } from 'theme/button';

const sheet = {
  button,
  btnGroup,
};


function Submit({ submit, classes, modifier, disabled }) {
  const disabledProp = disabled ? { disabled } : {};
  return (
    <button
      type="submit"
      className={cx(classes.button, modifier, 'send')}
      {...disabledProp}
    >{submit}</button>
  );
}

Submit.propTypes = {
  submit: PropTypes.string.isRequired,
  classes: PropTypes.shape({
    button: PropTypes.string,
  }).isRequired,
  disabled: PropTypes.bool,
  modifier: PropTypes.string,
};

function Buttons({ classes, modifier, cancel, disabled = false, submit = 'SEND', children = Submit }) {
  return (
    <div className={cx(classes.btnGroup, modifier)}>
      <button
        className={cx(classes.button, modifier, 'cancel')}
        type="button"
        onClick={() => cancel()}
        children="Cancel"
        data-name="cancel-button"
      />
      {
        children({ submit, classes, disabled, modifier })
      }
    </div>
  );
}

Buttons.propTypes = {
  classes: PropTypes.shape({
    btnGroup: PropTypes.string,
    cancel: PropTypes.string,
    button: PropTypes.string,
  }).isRequired,
  cancel: PropTypes.func.isRequired,
  submit: PropTypes.string,
  modifier: PropTypes.string,
  children: PropTypes.node,
  disabled: PropTypes.bool,
};

const ButtonsThemed = injectSheet(sheet)(Buttons);

export { ButtonsThemed as Buttons };
