import { DEFAULT_FONTFAMILY } from 'theme/theme';
import { billingPageLayout } from 'theme/layout/billingPageLayout';
export default {
  content: billingPageLayout.content,
  column1: billingPageLayout.column1,
  column2: billingPageLayout.column2,
  title: {
    color: '#000',
    font: `600 24px/28px ${DEFAULT_FONTFAMILY}`,
    marginTop: 20,
    marginBottom: 10,
  },
};
