import { call, put, select, takeEvery, fork } from 'redux-saga/effects';
import { updateAccountAction, addNotification as addNotificationAction } from 'domain/env/EnvActions';
import { createArtist_apiGen, watchSearchArtist } from 'domain/artist/sagas';
import { accountFormInitialData_sel } from 'domain/env/EnvModel';
import { submitArtistForm, searchArtistAction } from 'domain/artist/ArtistAction';
import { redirectToPage } from 'lib/redirectToPage';
import { query_sel } from 'domain/router/RouterModel';
import { onUpdateAccount } from 'domain/env/sagas';
import { getPopupParamsSelector } from 'domain/ui/UIModel';
import { hidePopupAction } from 'domain/ui/UIActions';
// import { push } from 'connected-react-router';
// import { lnk } from 'lib/routes';

function* updateAccount(payload) {
  const initialData = yield select(accountFormInitialData_sel);
  const { occupations } = payload;
  const oldOccupations = (initialData.occupations || []).sort().join(',');
  const newOccupations = (occupations || []).sort().join(',');
  if (!(newOccupations === oldOccupations && !payload.favoriteArtists)) {
    const data = {
      payload: {
        ...initialData,
        ...payload,
        sendMail: false,
        createAlerts: true,
      },
    };
    yield call(onUpdateAccount, data, false);
  }
}

function* onPreferences({ payload }) {
  yield call(updateAccount, payload);
  const { step = 'occupations' } = yield select(query_sel);
  if (step === 'occupations') {
    yield call(redirectToPage, 'preferencesPage', { query: { step: 'artists' } });
  } else {
    yield call(redirectToPage, 'homePage');
  }
}

function* onSubmitArtist({ payload: { id, ...data } }) {
  try {
    const artist = yield call(createArtist_apiGen, { data });
    if (artist) {
      yield put(addNotificationAction({ title: 'Your artist has been added', type: 'success' }));
      // extract favorite artists params
      const params = yield select(getPopupParamsSelector);
      const { favoriteArtists = [] } = params.toJS();
      // hide popup before saving artists (will cause redirect by react-router)
      yield put(hidePopupAction());
      // save favorite artists
      if (favoriteArtists && favoriteArtists.length) {
        yield call(updateAccount, { favoriteArtists });
      }
      // finish
      yield call(redirectToPage, 'homePage');
    }
  } catch (err) {
    
  }
}

export function* navigator() {
  yield takeEvery(updateAccountAction.type, onPreferences);
  yield takeEvery(submitArtistForm.type, onSubmitArtist);
  yield fork(watchSearchArtist);
  yield put(searchArtistAction({ term: '' }));
}
