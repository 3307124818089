import ReactGA from 'react-ga';

const trackingId = 'UA-179712626-1';
ReactGA.initialize(trackingId);

function initGa(userData) {
  ReactGA.set({
    userId: userData.id,
  });
}
export { initGa };
