import { fork, takeEvery, put } from 'redux-saga/effects';
import { stopSubmit } from 'redux-form';
import { FORM_ACCOUNT, FORM_CHANGE_PASSWORD } from 'domain/const';
import translate from 'lib/translate.js';

import {
  updateAccountAction,
  updatePreferredCurrencyAction,
  changePasswordAction,
  uploadPictureAction,
  removePictureAction,
} from 'domain/env';
import { watchSaveBilling, watchSaveStripeCardToken } from 'pages/billingPage/sagas';
import {
  onUpdateAccount,
  onChangePassword,
  onUploadPicture,
  removePicture,
  updatePreferredCurrency,
} from 'domain/env/sagas';
import upgradeSubscriptionSagas from 'pages/upgradeSubscriptionPage/sagas';

function* onSubmitServerError(action) {
  const { err: { response: { data: { message = '' } = {} } = {} } = {} } = action;
  if (message.match(/The email.*is already in use/)) {
    const message = translate('accountForm.email.error.alreadyInUse');
    yield put(stopSubmit(FORM_ACCOUNT, { email: message }));
  }
}

function* onChangePasswordFailure(action) {
  let err;
  try {
    if (action.err.response.data.fieldErrors) {
      err = action.err.response.data.fieldErrors;
    }
  } catch (e) {}
  if (!err) return;
  yield put(stopSubmit(FORM_CHANGE_PASSWORD, err));
}

export default function* watchNavigate() {
  yield takeEvery(updateAccountAction.type, onUpdateAccount);
  yield takeEvery(updatePreferredCurrencyAction.type, updatePreferredCurrency);
  yield takeEvery(changePasswordAction.type, onChangePassword);
  yield takeEvery(changePasswordAction.failure, onChangePasswordFailure);
  yield takeEvery(uploadPictureAction.type, onUploadPicture);
  yield takeEvery(removePictureAction.type, removePicture);
  yield takeEvery(updateAccountAction.failure, onSubmitServerError);
  yield fork(upgradeSubscriptionSagas);
  yield fork(watchSaveBilling);
  yield fork(watchSaveStripeCardToken);
}
