import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'lib/sheet';
import styles from './sheet.js';

class NothingFound extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
  };

  render() {
    const { classes } = this.props;
    return (
      <div
        className={classes.NothingFound}
      >
        You have no shared objects yet
      </div>
    );
  }
}

NothingFound = injectSheet(styles)(NothingFound);
export default NothingFound;
