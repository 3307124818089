import record, { string, listOf, indexedSetOf } from 'cpcs-recordjs';
import { field, selector } from 'cpcs-reconnect';
import * as A from './ArtistAction';
import * as env from 'domain/env/EnvActions';
import { artistName as artistNormalize } from 'domain/artist/helpers';
import { Artist } from 'model';

export { artistNormalize };

const State = record('Artists', {
  term: string(''),
  list: listOf(Artist),
  dictionary: indexedSetOf(Artist, Artist.$id),
  liquidity: 'LOADING',
});

export const toOption = (v) => ({ label: artistNormalize(v), value: v.get('id').toString(), rtv: v.get('showRtv') });
export function toGroupedList(list, common) {
  return [
    { label: 'ARTBnk VALUE', options: common.map(id => toOption(list.find(v => v.id === id))).toJS() },
    { label: 'General', options: list.filter(v => !common.includes(v.id)).map(toOption).toJS() },
  ];
}

export const artists = field('artists');
export const artistsList = artists.then(State.$list);
export const artistsCommonList = artistsList.then(l => l.filter(v => v.showRtv).map(v => v.id));
export const groupedArtists = selector(artistsList, artistsCommonList, toGroupedList);
export const artistById = artists.then(State.$dictionary);
export const artistLiquidity_sel = artists.then(State.$liquidity);

const parseArtistLiquidity = action => {
  return ((((action || {}).payload || [])[0] || {}).data || {}).is_illiquid ? 'ILLIQUID' : 'LIQUID';
};

export const reducer = {
  artists(state = new State(), action) { //NOSONAR
    switch (action.type) {

      case env.logOut.type:
        return new State();

      case A.searchArtistAction.request:
        return state.apply(
          State.$term.set(action.payload.term),
        );

      case A.searchArtistAction.success:
        return state.apply(
          State.$list.set(State.$list.type.parse(action.payload.content)),
          State.$dictionary.updateBy('merge', State.$dictionary.type.parse(action.payload.content)),
        );

      case A.fetchArtistAction.success:
        return state.apply(
          State.$list.set(State.$list.type.parse([action.payload])),
          State.$dictionary.updateBy('merge', State.$dictionary.type.parse([action.payload])),
        );

      case A.addArtistToDictionary.success:
        return state.apply(
          State.$dictionary.updateBy('merge', State.$dictionary.type.parse(action.payload)),
        );

      case A.createArtistAction.success:
        return (a => state.apply(
          State.$list.push(a),
          State.$dictionary.put(a),
        ))(Artist.parse(action.payload));

      case A.removeArtistAction.success:
        return state.apply(
          State.$list.updateBy('filter', v => v.get('id') !== action.payload),
          State.$dictionary.updateBy('filter', v => v.get('id') !== action.payload),
        );

      case A.updateArtistAction.success:
        return (a => state.apply(
          State.$list.updateBy('filter', v => v.get('id') !== a.get('id')),
          State.$list.push(a),
          State.$dictionary.updateBy('filter', v => v.get('id') !== a.get('id')),
          State.$dictionary.put(a),
        ))(Artist.parse(action.payload));

      case A.fetchArtistLiquidityAction.request:
        return state.apply(
          State.$liquidity.set('LOADING'),
        );

      case A.fetchArtistLiquidityAction.success:
        return state.apply(
          State.$liquidity.set(parseArtistLiquidity(action)),
        );

      default:
        return state;
    }
  },
};
