import record, { listOf } from 'cpcs-recordjs';
import { field } from 'cpcs-reconnect';
import * as A from 'domain/artwork/ArtworkActions';
import * as env from 'domain/env/EnvActions';
import { orderRtvAction } from 'pages/common/buyRtv/buyRtvActions';
import { Artist, Artwork, Pagination } from 'model';
import { manageAlertItem } from 'domain/alerts/AlertsActions';
import { buyAnalyticsReportAction } from 'pages/common/buyAnalytics/buyAnalyticsActions';

/**
 * models
**/

export const State = record('Artworks', {
  list: listOf(Artwork),
  artist: Artist,
  pagination: Pagination.parse({}),
});

/*
export const State = record('Artworks', {
  list: listOf(Artwork),
  artist: Artist,
  pagination: Pagination.parse({}),
});
State.$artist.property // 'artist'
State.$artist.owner // State
Artworks.$artist.owner.typeName // 'Artworks'
State.$artist.defaultValue // undefined
State.$artist.get(state) // state.artist
// return fn; if call fn(state) it returns state2 where state2.artist === artist2
State.$artist.set(artist2) // fn(state) => state2.artist === artist2
State.$artist.parsed(artist3JS) // fn(state) => state2.artist === artist3
State.$artist.clear() // fn(state) => state2.artist === State.$artist.defaultValue
State.$artist.then(Artist.$name) // fn(state) => artist.name
State.$artist.update(fn1) // fn(state) => state.update('artist', fn1)
State.$artist.updateBy(method: string, ...args: any[]) // fn(state) => state.update('artist', a => a[method](...args))
State.$artist.updateWith((...args) => artist => artist.set('years', sum(args)), 1, 2)(3, 4)(state)

State.$list.parsedBy('concat', otherList) // fn(state) => state2
*/

/**
 * selectors
**/
const base = field('artwork');
export const artworkListSelector = base.then(State.$list);
export const artworkPaginationSelector = base.then(State.$pagination);
export const totalSelector = artworkPaginationSelector.then(pagination => pagination.total);
export const artistSelector = base.then(State.$artist);

/**
 * reducer
**/
export const reducer = {
  artwork(state = new State(), action) { // NOSONAR
    switch (action.type) {

      case env.logOut.type:
        return new State();
      case orderRtvAction.success:
        return state.update(State.$list.update(list => list.map(w => (
          w.id !== action.payload.artworkId ? w : w.apply(
            Artwork.$rtvPrice.parsed(action.payload.rtvPrice),
            Artwork.$rtvCurrency.parsed(action.payload.rtvCurrency),
            Artwork.$rtvStatus.parsed(action.payload.artwork.rtvStatus),
            Artwork.$purchasedDate.parsed(action.payload.purchasedDate),
          )
        ))));
      case A.artworkAppendPageAction.failure:
      return state.apply(
        State.$pagination.update(v => v ? v.set('loading', false) : v),
      );
      case buyAnalyticsReportAction.success:
        return state.update(
          State.$artist.update(
            Artist.$analyticAvailable.set(true),
          ),
        );
      case A.artworkAppendPageAction.request:
      case A.fetchArtworkListAction.request:
        return state.apply(
          State.$pagination.update(v => v.set('loading', true)),
        );
      case A.artworkAppendPageAction.success:
        return state.apply(
          State.$list.parsedBy('concat', action.payload.lots.content),
          State.$pagination.parsed(action.payload.lots),
          State.$artist.parsed(action.payload.artist),
        );
      case A.fetchArtworkListAction.success:
        return state.apply(
          State.$list.parsed(action.payload.lots.content),
          State.$pagination.parsed(action.payload.lots),
          State.$artist.parsed(action.payload.artist),
        );
      case manageAlertItem.success:
        return state.update(
          State.$artist.update((artist => {
            if (!artist) return artist;
            return artist.id !== action.payload.artist.id ? artist : Artist.$alertExist.parsed(true)(artist);
          })),
        );
      default:
        return state;
    }
  },
};
