import record, { integer, string, listOf } from 'cpcs-recordjs';
import I from 'immutable';
import { field, selector } from 'cpcs-reconnect';
import { listToMap, listToLabelValue } from 'lib/serialize';
import * as A from './SignaturesActions';

const Signature = record('Signature', {
  id: integer(),
  title: string(),
});

const State = record('Signatures', {
  content: listOf(Signature),
});

export const signatures = field('signatures');
export const signatureList = signatures.then(State.$content);
export const signatureById = selector(signatureList, listToMap);
export const signatureById_sel = selector(signatureList, listToMap);
export const signatureValueLabel = selector(signatureList, listToLabelValue).then(list => (list || I.List()).filter(v => v.get('label') !== 'Any'));

export const reducer = {
  signatures(state = new State(), action) { //NOSONAR
    switch (action.type) { //NOSONAR

      case A.fetchSignaturesAction.success:
        return state.apply(
          State.$content.set(
            State.$content.type.parse(action.payload),
          ),
        );

      default:
        return state;
    }
  },
};
