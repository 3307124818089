import rules, { addError } from 'components/form/validation';
import { compose } from 'redux';

export const MIN_6 = 'Password must be 6 characters or more';
const GDPR = "Accept ARTBnk's Privacy Policy and Subscription Terms to use our service";

const loginFields = [
  'email',
  'password',
];

const loginValidator = values => (errors, fieldName) => {
  const val = values[fieldName];
  switch (fieldName) {
    case 'email':
      return compose(
        e => addError(e, fieldName, rules.email(val)),
        e => addError(e, fieldName, rules.cmRequired('Email is required')(val)),
      )(errors);
    case 'password':
      return compose(
        e => addError(e, fieldName, rules.cmRequired('Password is required')(val)),
      )(errors);
    default:
      return errors;
  }
};

export const loginValidate = values => loginFields.reduce(loginValidator(values), {});
export const newPasswordValidate = values => ['password'].reduce(loginValidator(values), {});

const signUpFields = [
  'firstname',
  'lastname',
  'email',
  'password',
  'privacyTermsAccept',
  'marketingEmailConsent',
];

const signUpValidator = values => (errors, fieldName) => {
  const val = values[fieldName];
  switch (fieldName) {
    case 'firstname':
      return addError(errors, fieldName, rules.cmRequired('First name is required')(val));
    case 'lastname':
      return addError(errors, fieldName, rules.cmRequired('Last name is required')(val));
    case 'email':
      return compose(
        e => addError(e, fieldName, rules.email(val)),
        e => addError(e, fieldName, rules.cmRequired('Email is required')(val)),
      )(errors);
    case 'password':
      return compose(
        e => addError(e, fieldName, rules.strMinLength(6, MIN_6)(val)),
        e => addError(e, fieldName, rules.cmRequired('Password is required')(val)),
      )(errors);
    case 'privacyTermsAccept':
      return compose(
        e => addError(e, fieldName, rules.cmRequired(GDPR)(val)),
        e => addError(e, fieldName, rules.cmIsTrue(GDPR)(val)),
      )(errors);
    default:
      return errors;
  }
};

export const signupValidate = values => signUpFields.reduce(signUpValidator(values), {});

