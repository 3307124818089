import { fork, takeEvery, put, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import queryString from 'query-string';
import { onGoBack } from 'lib/redirectToPage';

import { location_sel, query_sel } from 'domain/router/RouterModel';
import {
  updateQueryAction,
  goBackAction,
} from 'domain/router/RouterAction';

function* updateQuery({ payload }) {
  const location = yield select(location_sel);
  const query = yield select(query_sel);
  delete query.page;
  yield put(
    push({
      pathname: location.pathname,
      search: queryString.stringify({ ...query, ...payload }, { arrayFormat: 'bracket' }),
    }),
  );
}

function* watchUpdateQuery() {
  yield takeEvery(updateQueryAction.type, updateQuery);
}

export default function* router() {
  yield fork(watchUpdateQuery);
  yield takeEvery(goBackAction.type, onGoBack);
}
