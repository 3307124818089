import { call, fork, put, select, takeEvery, cancel } from 'redux-saga/effects';
import { getFormValues, getFormSyncErrors, touch, isValid } from 'redux-form';
import Api, { makeApiGenerator } from 'domain/api';
import { push } from 'connected-react-router';
import { lnk } from 'lib/routes';
import { hasSubscription, parseServerError } from 'lib/helpers';
import {
  fetchPlanTypesAction,
  upgradePlanAction,
  setSelectedPlanTypeAction,
} from 'pages/upgradeSubscriptionPage/upgradeSubscriptionActions';
import {
  selectedPlanTypeSelector,
  planTypesSel,
  planTypesTimestamp_sel,
} from 'pages/upgradeSubscriptionPage/upgradeSubscriptionModel';
import {
  closeCouponFormAction,
  checkCouponAction,
} from 'pages/common/couponForm/couponFormActions';
import {
  validatedCouponSelector,
} from 'pages/common/couponForm/couponFormModel';
import {
  onFieldErrorAction,
  setStripeCardTokenInvalidAction,
} from 'pages/billingPage/form/cardFormActions';
import {
  fieldsSel,
  fieldNames,
  stripeCardToken_sel,
} from 'pages/billingPage/form/cardFormModel';
import {
  cardNumberSelector,
  cardHolderNameSel,
  subscriptionTypeSelector,
  cardFormOpened_sel,
  isAuthorized as isAuthorized_sel,
} from 'domain/env/EnvModel';
import {
  addNotification,
  currentPlanAction,
  logOut as logOutAction,
  checkAuthAction,
  loginAction,
} from 'domain/env/EnvActions';
import { getCurrentPlan_apiGen } from 'domain/env/sagas';
import { watchCouponForm } from 'pages/common/couponForm/saga';
import { FORM_CARD, FORM_BILLING, SINGLE_RTV, SUBSCRIPTION_TYPE_ENTERPRISE } from 'domain/const';
import { responseTimestamp, unixSeconds } from 'lib/envGlobals';

export const backgroundFetchPlanTypes = (() => {
  let seconds = 0;
  let enabled = false;
  class BackgroundFetchPlanTypes {
    update() {
      seconds = unixSeconds();
    }
    get seconds() {
      return seconds;
    }
    reset() {
      seconds = 0;
    }
    enable() {
      enabled = true;
    }
    disable() {
      enabled = false;
    }
    get isEnabled() {
      return enabled;
    }
  }
  return new BackgroundFetchPlanTypes();
})();

const fetchPlanTypes_apiGen = makeApiGenerator({
  actionCreator: fetchPlanTypesAction,
  api: Api.fetchPlanTypes,
  patchRequestData: function* ({ requestData }) {
    const coupon = yield select(validatedCouponSelector);
    if (coupon) {
      return {
        ...requestData,
        coupon,
      };
    }
    return requestData;
  },
  getSuccessData: ({ data, headers }) => {
    backgroundFetchPlanTypes.update();
    return { payload: data, timestamp: responseTimestamp({ headers }) };
  },
});

const upgradePlan_apiGen = makeApiGenerator({
  actionCreator: upgradePlanAction,
  api: Api.upgradePlan,
  getSuccessData: ({ data, headers }) => ({ payload: { ...data, token: headers['x-token'] } }),
});

export function* upgradePlan({ stripeCardToken, billingData, planType, discount }) {
  const { cardHolderName } = (yield select(getFormValues(FORM_CARD))) || {};
  const cardNumber = yield select(cardNumberSelector);
  const cardFormOpened = yield select(cardFormOpened_sel);
  if (cardFormOpened ? !stripeCardToken : !cardNumber) {
    const fields = yield select(fieldsSel);
    for (let name of fieldNames) {
      let field = fields.get(name);
      if (field.complete) continue;
      yield put(onFieldErrorAction({ name, error: { message: `Please enter a valid ${field.label}` } }));
    };
    yield put(addNotification({ title: 'Please enter a valid credit card', type: 'error' }));
    return;
  }
  if (!cardHolderName && !(yield select(cardHolderNameSel))) {
    yield put(addNotification({ title: 'Please enter cardholder name', type: 'error' }));
    return;
  }
  if (!planType) {
    yield put(addNotification({ title: 'Please select subscription plan', type: 'error' }));
  }
  const plansListTimestamp = yield select(planTypesTimestamp_sel);
  const requestData = {
    cardHolderName: cardHolderName || undefined,
    stripeToken: stripeCardToken || undefined,
    discount: discount || undefined,
    country: billingData.country,
    code: billingData.code,
    email: billingData.email,
    subscriptionPlan: planType,
    saveCard: true,
    timestamp: plansListTimestamp,
  };
  try {
    const data = yield call(upgradePlan_apiGen, { data: requestData });
    if (data) {
      yield put(addNotification({ title: 'Thank you for subscribing to ARTBnk!' }));
      yield put(closeCouponFormAction());
      yield call(fetchPlanTypes_apiGen.catchError);
      yield put(push(lnk('accountPage', { query: { congratulation: 'thankForSubscribing' } })));
      const plansList = yield select(planTypesSel);
      const plan = plansList && plansList.find(v => v.get('shortName') === planType);
      if (plan) {
        yield put({
          type: currentPlanAction.success,
          payload: plan.toJS(),
        });
      }
    }
  } catch (err) {
    if (stripeCardToken) {
      yield put(setStripeCardTokenInvalidAction(true));
    }
    const { message } = parseServerError(err);
    if (message === 'Upgrading from non Member subscription is not supported') {
      yield put(push(lnk('accountPage')));
    }
    yield put(addNotification({ title: message, type: 'error' }));
  }
}

function* onUpgradePlan() {
  const stripeCardToken = yield select(stripeCardToken_sel);
  const billingData = yield select(getFormValues(FORM_BILLING));
  const planType = yield select(selectedPlanTypeSelector);
  const discount = yield select(validatedCouponSelector);
  if (!(yield select(isValid(FORM_BILLING)))) {
    const billingFormErrors = yield select(getFormSyncErrors(FORM_BILLING));
    const firstErrorKey = Object.keys(billingFormErrors)[0];
    const firstErrorMessage = billingFormErrors[firstErrorKey];
    yield put(touch(FORM_BILLING, firstErrorKey));
    yield put(addNotification({ title: firstErrorMessage, type: 'error' }));
    return;
  }
  yield fork(upgradePlan, {
    stripeCardToken,
    billingData,
    planType,
    discount,
  });
}

const FETCH_SUBSCRIPTIONS_INTERVAL = 60 /* sec */ * 30 /* min */;
function* intervalFetchSubscriptions() {
  if (!backgroundFetchPlanTypes.isEnabled) {
    return;
  }
  if (unixSeconds() - backgroundFetchPlanTypes.seconds < FETCH_SUBSCRIPTIONS_INTERVAL) {
    return;
  }
  const isAuthorized = yield select(isAuthorized_sel);
  if (!isAuthorized) return;
  backgroundFetchPlanTypes.update();
  yield fork(fetchPlanTypes_apiGen.catchError);
}


function* upgradeSubscriptionWatcherTask() {
  const subscriptionType = yield select(subscriptionTypeSelector);
  if (SUBSCRIPTION_TYPE_ENTERPRISE === subscriptionType) {
    return;
  }
  yield takeEvery(fetchPlanTypesAction.type, fetchPlanTypes_apiGen.catchError);
  // yield takeEvery('@@router/LOCATION_CHANGE', backgroundFetchPlanTypes.disable);
  yield takeEvery(() => true, intervalFetchSubscriptions);
  // yield takeEvery(logOutAction.type, backgroundFetchPlanTypes.reset);
  // yield takeEvery(fetchPlanTypesAction.failure, backgroundFetchPlanTypes.reset);
  yield takeEvery(
    action => ((checkCouponAction.success === action.type) && action.payload.more.isSubscription),
    fetchPlanTypes_apiGen.catchError,
  );
  yield takeEvery(
    action => (
      closeCouponFormAction.type === action.type && (
        !action.payload || !action.payload.product || action.payload.product.get('shortName') !== SINGLE_RTV
      )
    ),
    fetchPlanTypes_apiGen.catchError,
  );
}

let task = null;

export function* upgradeSubscriptionWatcher() {
  yield takeEvery('@@router/LOCATION_CHANGE', backgroundFetchPlanTypes.disable);
  // yield takeEvery(logOutAction.type, backgroundFetchPlanTypes.reset);
  yield takeEvery(fetchPlanTypesAction.failure, backgroundFetchPlanTypes.reset);
  // -- 
  // task = yield fork(upgradeSubscriptionWatcherTask);
  yield takeEvery(logOutAction.type, function*() {
    backgroundFetchPlanTypes.reset();
    if (task) {
      yield cancel(task);
      task = null;
    }
  });
  yield takeEvery([checkAuthAction.success, loginAction.success], function* () {
    if (task) return;
    task = yield fork(upgradeSubscriptionWatcherTask);
  });
}

export default function* () {
  backgroundFetchPlanTypes.enable();
  yield fork(watchCouponForm);
  yield takeEvery(upgradePlanAction.type, onUpgradePlan);
  yield fork(getCurrentPlan_apiGen.catchError);
  const subscriptionType = yield select(subscriptionTypeSelector);
  if (subscriptionType && hasSubscription(subscriptionType)) {
    yield put(setSelectedPlanTypeAction(subscriptionType));
    // yield put(push(lnk('billing')));
  }
}
