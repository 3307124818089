import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'cpcs-reconnect';
import cx from 'classnames';

import { routeName_sel } from 'domain/router/RouterModel';

import { DEFAULT_FONTFAMILY_3 } from 'theme/theme';
import injectSheet from 'lib/sheet';


const sheet = {
  Tabs: {
    display: 'flex',
    marginTop: 52,
    '&.ReportsArtist__subTabs': {
      marginTop: 64,
    },
    '@media screen and (max-width: 1200px)': {
      '&.ReportsArtist__subTabs': {
        flexDirection: 'column',
      },
    },
  },
  tab: {
    color: '#5B6178',
    textDecoration: 'none',
    font: `400 24px/40px ${DEFAULT_FONTFAMILY_3}`,
    flex: '1 1 0px',
    textAlign: 'center',
    borderBottom: '2px solid #A6ACC4',
    cursor: 'pointer',
    padding: [0, 33, 4],
    whiteSpace: 'nowrap',
    '&:hover': {
      color: '#1E202C',
    },
    '&.ReportsArtist__subTabs': {
      font: `400 20px/40px ${DEFAULT_FONTFAMILY_3}`,
    },
    '&.active': {
      color: '#304C95',
      fontWeight: 700,
      borderBottom: '2px solid #1E202C',
      '&.ReportsArtist__subTabs': {
        fontWeight: 500,
      },
    },
    '@media screen and (max-width: 1200px)': {
      '&.ReportsArtist__subTabs': {
        font: `400 18px/24px ${DEFAULT_FONTFAMILY_3}`,
        marginTop: 15,
      },
    },
    '@media screen and (max-width: 900px)': {
      font: `400 18px/24px ${DEFAULT_FONTFAMILY_3}`,
    },
    '@media screen and (max-width: 425px)': {
      padding: [0, 0, 4],
    },
  },
};

class Tabs extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.shape({
      Tabs: PropTypes.string.isRequired,
      tab: PropTypes.string.isRequired,
    }).isRequired,
    items: PropTypes.arrayOf(PropTypes.shape({
      link: PropTypes.string,
      title: PropTypes.string.isRequired,
    })).isRequired,
    onTabSelect: PropTypes.func,
    // eslint-disable-next-line react/no-unused-prop-types
    routeName: PropTypes.string,
    defatultTab: PropTypes.shape({
      title: PropTypes.string.isRequired,
    }),
    modifier: PropTypes.string,
  };

  state = {
    activeTab: this.props.defatultTab || null,
  };

  // isActive = (item) => () => {
  //   if (item.routeName === routeName) return true;
  //   return false;
  // };

  setActiveTab = (item) => () => {
    const { onTabSelect } = this.props;
    if (onTabSelect) {
      onTabSelect(item);
    }
    this.setState({ activeTab: item });
  }

  renderLinkTab = (item, index) => {
    const { classes, modifier } = this.props;
    return (
      <NavLink
        to={item.link}
        activeClassName="active"
        className={cx(classes.tab, modifier)}
        exact
        // isActive={this.isActive(item)}
        key={`${item.link}-${index}`}
        onClick={this.setActiveTab(item)}
      >
        {item.title}
      </NavLink>
    );
  }

  renderButtonTab = (item, index) => {
    const { classes, modifier } = this.props;
    const active = this.state.activeTab === item;
    return (
      <button
        onClick={this.setActiveTab(item)}
        className={cx(classes.tab, modifier, { active })}
        key={`${item.link}-${index}`}
      >
        {item.title}
      </button>
    );
  }

  renderTab = (item, index) => {
    if (item.link) {
      return this.renderLinkTab(item, index);
    } else {
      return this.renderButtonTab(item, index);
    }
  };

  render() {
    const { classes, items, modifier } = this.props;
    return (
      <div
        className={cx(classes.Tabs, modifier)}
      >
        {
          items.map(this.renderTab)
        }
      </div>
    );
  }
}

export default compose(
  injectSheet(sheet),
  connect({
    routeName: routeName_sel,
  }),
)(Tabs);
