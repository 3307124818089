import React from 'react';
import I from 'immutable';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'cpcs-reconnect';
import cx from 'classnames';
import Badge from 'pages/common/ItemList/Badges/Artwork';
import { comparableListSelector } from 'domain/comparables/ComparablesModel';
import { lnk } from 'lib/routes';
import { showAvailableComparablesAction } from 'domain/comparables/ComparablesModel';
import { SelectComparablesPopup } from 'pages/common/popup/SelectComparablesPopup/SelectComparablesPopup';
import { subscriptionTypeSelector } from 'domain/env/EnvModel';
import { SUBSCRIPTION_TYPE_ENTERPRISE } from 'domain/const';

import injectSheet from 'lib/sheet';
import sheet from './sheet';

const artworkLinkBuilder = artwork => lnk('artworkPage', {
  artworkId: artwork.get('id'),
});

function ArtworkMock({ classes }) {
  return (
    <div className={classes.ArtworkMock}>
      <div className={classes.imgMock} />
      <div className={cx(classes.txtMock, 'index1')} />
      <div className={classes.txtMock} />
      <div className={cx(classes.txtMock, 'index3 last2')} />
      <div className={cx(classes.txtMock, 'last2')} />
    </div>
  );
}
ArtworkMock.propTypes = {
  classes: PropTypes.shape({
    ArtworkMock: PropTypes.string.isRequired,
    imgMock: PropTypes.string.isRequired,
    txtMock: PropTypes.string.isRequired,
  }).isRequired,
};

function PageSectionSalesComparablesDollNoTheme({ classes }) {
  return (
    <div className="PAGE_SECTION divider">
      <div className={classes.sectionHeader}>
        <div className={cx(classes.sectionHeaderTitle, 'sales-comparables')}>
          Sales Comparables
        </div>
      </div>
      <div className={classes.sectionDescription}>
        Up to three sales comparables will be displayed with the purchase of an ARTBnk Value. <br/>
        Data from comparables represents a selection of the thousands of data points used to compute an ARTBnk Value.
      </div>
      <div className={cx(classes.comparableList, 'comparableList', 'doll')}>
        <ArtworkMock classes={classes} />
        <ArtworkMock classes={classes} />
        <ArtworkMock classes={classes} />
      </div>
    </div>
  );
}
PageSectionSalesComparablesDollNoTheme.propTypes = {
  classes: PropTypes.shape({
    sectionHeader: PropTypes.string.isRequired,
    sectionHeaderTitle: PropTypes.string.isRequired,
    sectionDescription: PropTypes.string.isRequired,
    comparableList: PropTypes.string.isRequired,
  }).isRequired,
};

export const PageSectionSalesComparablesDoll = injectSheet(sheet)(PageSectionSalesComparablesDollNoTheme);

function PageSectionComparables(props) {
  const { classes, list, showAvailableComparables, subscriptionType } = props;
  if (!list.size) return null;
  return (
    <div className="PAGE_SECTION divider">
      <div className={cx(classes.sectionHeader, 'sales-comparables')}>
        <div className={cx(classes.sectionHeaderTitle, 'sales-comparables')}>
          Sales Comparables
        </div>
        <div className={classes.sectionDescription}>
          Data from comparables represents a selection of the thousands of data points used to compute an ARTBnk Value.
        </div>
        <div className={classes.sectionActions}>
          {
            (subscriptionType === SUBSCRIPTION_TYPE_ENTERPRISE) &&
              <button
                className={cx(classes.button, 'secondary', 'showMoreComparablesModal')}
                type="button"
                onClick={showAvailableComparables}
                children="View Other Comparables"
              />
          }
        </div>
      </div>
      <div className={cx(classes.comparableList, 'comparableList')}>
        {
          list.map((artwork, i) => (
            <div
              className={classes.item}
              key={artwork.get('id') + `${i}`}
            >
              <Badge
                artwork={artwork}
                linkBuilder={artworkLinkBuilder}
                PDFComponentName={`comparables-${i}`}
              />
            </div>
          ))
        }
      </div>
      <SelectComparablesPopup />
    </div>
  );
}

PageSectionComparables.propTypes = {
  classes: PropTypes.shape({
    sectionHeader: PropTypes.string.isRequired,
    sectionHeaderTitle: PropTypes.string.isRequired,
    sectionDescription: PropTypes.string.isRequired,
    comparableList: PropTypes.string.isRequired,
    item: PropTypes.string.isRequired,
    sectionActions: PropTypes.string.isRequired,
    button: PropTypes.string.isRequired,
  }).isRequired,
  list: PropTypes.instanceOf(I.Collection).isRequired,
  showAvailableComparables: PropTypes.func.isRequired,
  subscriptionType: PropTypes.string,
};

export default compose(
  injectSheet(sheet),
  connect({
    list: comparableListSelector,
    showAvailableComparables: () => showAvailableComparablesAction(),
    subscriptionType: subscriptionTypeSelector,
  }),
)(PageSectionComparables);
