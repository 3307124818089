import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import FormRowField from 'pages/common/newPage/form/elements/FormRowField';
import { rules, validator } from 'components/form/validation';

import DateField from 'components/form/DateField';
import injectSheet from 'lib/sheet';
import sheet from './sheet';

const onBlur = (event) => event.preventDefault();

/**
 * <Period
 *   onChange={this.onSubmit}
 *   period={{ from: 'periodFrom', to: 'periodTo' }}
 *   minLabel="left limit"
 *   placeholder="YYYY"
 *   mask={[/\d/, /\d/, /\d/, /\d/]}
 *   validateMask="YYYY-MM-DD"
 *   momentMask="YYYY"
 *   momentParseMasks={['YYYY']}
 *   maxDetail="decade"
 *   // default params
 *   // normalize={v => v ? moment(v, 'YYYY').format('YYYY'): ''}
 *   // format={v => v ? moment(v, 'YYYY-MM-DD').format('MM/DD/YYYY'): ''}
 * />
 *
 *
 * <Period
 *   onChange={this.onSubmit}
 *   period={{ from: 'auctionDateFrom', to: 'auctionDateTo' }}
 *   minLabel="left limit"
 *   normalize={v => v ? moment(v, 'MM/DD/YYYY').format('YYYY-MM-DD'): ''}
 *   format={v => v ? moment(v, 'YYYY-MM-DD').format('MM/DD/YYYY'): ''}
 *   validateMask="YYYY-MM-DD"
 *   // default params
 *   // momentMask="MM/DD/YYYY"
 *   // momentParseMasks={['MM/DD/Y', 'MM/DD/YY', 'MM/DD/YYY', 'MM/DD/YYYY']}
 *   // maxDetail="month"
 * />
**/
class Period extends React.PureComponent {
  static propTypes = {
    period: PropTypes.shape({
      from: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired,
    }).isRequired,
    classes: PropTypes.shape({
      Period: PropTypes.string.isRequired,
      separator: PropTypes.string.isRequired,
      rangeWrapper: PropTypes.string.isRequired,
      errorMessage: PropTypes.string.isRequired,
    }).isRequired,
    onChange: PropTypes.func.isRequired,
    normalize: PropTypes.func,
    format: PropTypes.func,
    momentMask: PropTypes.string,
    momentParseMasks: PropTypes.array,
    maxDetail: PropTypes.oneOf(['month', 'decade']),
    validateMask: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    mask: PropTypes.array,
    // used in error messages, to show field name with min limit
    minLabel: PropTypes.string,
  };

  static defaultProps = {
    placeholder: 'MM/DD/YYYY',
  };

  constructor(props) {
    super(props);
    const { classes } = props;
    this.errorMessagePortal1 = document.createElement('div');
    this.errorMessagePortal1.classList.add(classes.errorMessage);
    this.errorMessagePortal2 = document.createElement('div');
    this.errorMessagePortal2.classList.add(classes.errorMessage);
  }

  onRootRef = (node) => {
    if (!node) return;
    node.appendChild(this.errorMessagePortal1);
    node.appendChild(this.errorMessagePortal2);
  }


  validate = {
    from: (v, values, props, name) => (rules.dateFormat(this.props.validateMask)(v, values, props, name)),
    to: validator(
      rules.dateFormat(this.props.validateMask),
      rules.greaterThanDateFormValue({
        minKey: this.props.period.from,
        minLabel: this.props.minLabel,
      }, this.props.validateMask),
    ),
  };

  render() {
    const { classes, period: { from, to }, onChange } = this.props;
    const { normalize, format, momentMask, momentParseMasks, maxDetail, placeholder, mask } = this.props;
    return (
      <div className={classes.Period} ref={this.onRootRef}>
        <div className={classes.rangeWrapper}>
          <Field
            name={from}
            component={FormRowField}
            Field={DateField}
            portal
            type="text"
            modifier="advancedFilter-PeriodDate"
            placeholder={placeholder}
            onChange={onChange}
            validate={this.validate.from}
            rootTag="div"
            normalize={normalize}
            format={format}
            momentMask={momentMask}
            momentParseMasks={momentParseMasks}
            maxDetail={maxDetail}
            onBlur={onBlur}
            mask={mask}
            errorMessagePortal={this.errorMessagePortal1}
          />
          <div className={classes.separator}>-</div>
          <Field
            name={to}
            component={FormRowField}
            Field={DateField}
            portal
            type="text"
            modifier="advancedFilter-PeriodDate"
            placeholder={placeholder}
            onChange={onChange}
            validate={this.validate.to}
            rootTag="div"
            normalize={normalize}
            format={format}
            momentMask={momentMask}
            momentParseMasks={momentParseMasks}
            maxDetail={maxDetail}
            onBlur={onBlur}
            mask={mask}
            errorMessagePortal={this.errorMessagePortal2}
          />
        </div>
        {/* div.errorMessage x2 see onRootRef */}
      </div>
    );
  }
}

export default injectSheet(sheet)(Period);
