/* eslint no-unused-vars: warn */
import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'cpcs-reconnect';
import I from 'immutable';
import moment from 'moment';


import { soldPriceStr } from 'pages/common/artworkView/solvers/soldPriceSolver';
import { sizeSolver } from 'pages/common/artworkView/solvers/sizeSolver';
import { comparableListSelector } from 'domain/comparables/ComparablesModel';
import { surfacesById_sel } from 'domain/surface/SurfaceModel';
import { substratesById_sel } from 'domain/substrate/SubstrateModel';
import { signatureById_sel } from 'domain/signatures/SignaturesModel';
import { categoryById_sel } from 'domain/category/CategoryModel';
import { formatEstimationValue } from 'pages/common/artworkView/solvers/estimateSolver';
import { rtvText } from 'pages/common/artworkView/solvers/rtvSolver';

import { cutToFitScalable } from 'pages/highcharts/helpers/imgToDataURL';
import { cutStr } from 'pages/highcharts/helpers/helpers';
import { connectEventsContainer } from 'pages/highcharts/helpers/EventsContext';
import { RefSize } from 'pages/highcharts/helpers/refSize';
import { DEFAULT_FONTFAMILY, EVENT_GET_PAGE_COMPONENT } from 'pages/highcharts/helpers/PDFConstants';


class ComparablesPDF extends RefSize {
  static propTypes = {
    PDFState: PropTypes.shape({
      // contentSections: PropTypes.instanceOf(I.Collection),
    }).isRequired,
    list: PropTypes.instanceOf(I.Collection),
  }

  state = {
    items: [{}, {}, {}],
  }

  componentDidMount() {
    const { PDFEvents } = this.props;
    const items = [0, 1, 2].map(v =>
      PDFEvents.dispatch(EVENT_GET_PAGE_COMPONENT, { id: `comparables-${v}` }),
    );
    const promises = items.map(async (ret) => {
      const { item: { img } } = ret || { item: {} };
      if (img) {
        const maxWidth = 140;
        const maxHeight = 113;
        return {
          img,
          // src, width, height
          ...(await cutToFitScalable({ img, maxWidth, maxHeight, cut: true })),
        };
      } else {
        return {};
      }
    });
    Promise.all(promises).then((newItems) => {
      if (this.unmounted) return;
      this.setState({ items: newItems });
    });
    super.componentDidMount();
  }

  dict = (v, list) => list.getIn([v, 'title']) || list.getIn([`${v}`, 'title']) || 'undefined';

  dicts = (vs = [], list) => vs.map(v => this.dict(v, list)).filter(Boolean).join(', ');

  render() {
    const { list, surface, substrate, signature, category } = this.props;
    const categoryById = category;
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="600"
        height="370"
        ref={this.ref}
        data-top="372"
        data-left="24"
      >
        <style>
          {`
          .ComparablesPDF_Title { font: 700 16px ${DEFAULT_FONTFAMILY}; fill: #1E202C; }
          .ComparablesPDF_artistName { font: 500 10px ${DEFAULT_FONTFAMILY}; fill: #1E202C; }
          .ComparablesPDF_AOName { font: 400 10px ${DEFAULT_FONTFAMILY}; fill: #1E202C; }
          .ComparablesPDF_label { font: 400 9px ${DEFAULT_FONTFAMILY}; fill: #1E202C; }
          .ComparablesPDF_value { font: 500 9px ${DEFAULT_FONTFAMILY}; fill: #1E202C; }
          `}
        </style>
        <g>
          <text y="20" className="ComparablesPDF_Title">
            Sales Comparables
          </text>
          {
            list && list.map((artwork, i, arr, line = 0, TOP = 175) => (
              <g key={artwork.id} transform={`translate(${177 * i}, 50)`}>
                <rect width="140" height="113" fill="#EEE" />
                {
                  (i < 3 && !!(this.state.items[i] || {}).src) &&
                    <image
                      href={this.state.items[i].src}
                      width={this.state.items[i].width}
                      height={this.state.items[i].height}
                    />
                }
                <text y="133" className="ComparablesPDF_artistName">
                  {artwork.artist.fullName}
                </text>
                <text y="153" className="ComparablesPDF_AOName">
                  {cutStr(artwork.title, 33)}
                </text>
                <text y={TOP + 11 * line} className="ComparablesPDF_label">Category:</text>
                <text y={TOP + 11 * line++} x="45" className="ComparablesPDF_value">{this.dict(artwork.category, categoryById)}</text>
                <text y={TOP + 11 * line} className="ComparablesPDF_label">Year Created:</text>
                <text y={TOP + 11 * line++} x="60" className="ComparablesPDF_value">{moment(artwork.createdDate).format('YYYY')}</text>
                <text y={TOP + 11 * line} className="ComparablesPDF_label">Surface:</text>
                <text y={TOP + 11 * line++} x="38" className="ComparablesPDF_value">{this.dicts(artwork.surfaces, surface)}</text>
                <text y={TOP + 11 * line} className="ComparablesPDF_label">Substrate:</text>
                <text y={TOP + 11 * line++} x="46" className="ComparablesPDF_value">{this.dicts(artwork.substrates, substrate)}</text>
                <text y={TOP + 11 * line} className="ComparablesPDF_label">Signature:</text>
                <text y={TOP + 11 * line++} x="45" className="ComparablesPDF_value">{this.dict(artwork.signature, signature)}</text>
                <text y={TOP + 11 * line} className="ComparablesPDF_label">Size:</text>
                <text y={TOP + 11 * line++} x="23" className="ComparablesPDF_value">{sizeSolver(null, artwork, '', { category })}</text>

                {(TOP += 8) && null}

                <text y={TOP + 11 * line} className="ComparablesPDF_label">Auction House:</text>
                <text y={TOP + 11 * line++} x="65" className="ComparablesPDF_value">{cutStr(artwork.getIn(['lot', 'seller'], 'Undefined'), 20)}</text>
                {
                  artwork.getIn(['lot', 'lotNumber']) &&
                  <React.Fragment>
                    <text y={TOP + 11 * line} className="ComparablesPDF_label">Lot:</text>
                    <text y={TOP + 11 * line++} x="20" className="ComparablesPDF_value">{artwork.getIn(['lot', 'lotNumber'])}</text>
                  </React.Fragment>
                }
                <text y={TOP + 11 * line} className="ComparablesPDF_label">Sale Date:</text>
                <text y={TOP + 11 * line++} x="45" className="ComparablesPDF_value">{moment(artwork.getIn(['lot', 'saleAt'])).format('MMM DD, YYYY')}</text>

                {(TOP += 8) && null}

                <text y={TOP + 11 * line} className="ComparablesPDF_label">Sold:</text>
                <text y={TOP + 11 * line++} x="25" className="ComparablesPDF_value">{soldPriceStr(artwork)}</text>
                <text y={TOP + 11 * line} className="ComparablesPDF_label">Est:</text>
                <text y={TOP + 11 * line++} x="25" className="ComparablesPDF_value">{formatEstimationValue(artwork)}</text>
                <text y={TOP + 11 * line} className="ComparablesPDF_label">ARTBnk Value:</text>
                <text y={TOP + 11 * line++} x="65" className="ComparablesPDF_value">{rtvText({ artwork, categoryById })}</text>
              </g>
            ))
          }
        </g>
      </svg>
    );
  }
}

ComparablesPDF = compose(
  connectEventsContainer,
  connect({
    list: comparableListSelector,
    surface: surfacesById_sel,
    substrate: substratesById_sel,
    signature: signatureById_sel,
    category: categoryById_sel,
  }),
)(ComparablesPDF);

export { ComparablesPDF };
