import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { connect } from 'cpcs-reconnect';
import { compose } from 'redux';
import I from 'immutable';

import {
  // onItemShareAction,
  onItemTrackAction,
  onItemCollectAction,
} from 'pages/common/ItemActions/saga';
import { SUBSCRIPTION_TYPE_ENTERPRISE } from 'domain/const';
import { subscriptionTypeSelector } from 'domain/env/EnvModel';
import { downloadRtvPdfAction } from 'domain/rtvValueListPage/RtvValueListPageActions';

import injectSheet from 'lib/sheet';
import styles from './sheet.js';

class ItemActions extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    hideCollect: PropTypes.bool,
    hideTrack: PropTypes.bool,
    // onShare: PropTypes.func.isRequired,
    onTrack: PropTypes.func.isRequired,
    onCollect: PropTypes.func.isRequired,
    tracked: PropTypes.bool,
    collected: PropTypes.bool,
    artwork: PropTypes.instanceOf(I.Record),
    downloadPdf: PropTypes.func.isRequired,
    subscriptionType: PropTypes.string.isRequired,
  };

  downloadHandler = (event) => {
    event.preventDefault();
    const { downloadPdf, artwork } = this.props;
    downloadPdf({ artwork });
  }

  get itemsCount() {
    const { hideCollect, hideTrack, artwork } = this.props;
    return 1 + (!hideCollect * 1) + (!hideTrack * 1) + ((artwork && !artwork.isDraft) * 1);
  }

  render() {
    // const { classes, hideCollect, hideTrack, onShare, onTrack, onCollect, tracked, collected, artwork } = this.props;
    const { classes, hideCollect, hideTrack, onTrack, onCollect, tracked, collected, artwork, subscriptionType } = this.props;
    const isEnterprise = subscriptionType === SUBSCRIPTION_TYPE_ENTERPRISE;
    return (
      <div className={cx(classes.ItemActions, `items-${this.itemsCount}`)}>
        {
          !hideCollect &&
            <button
              className={cx(classes.button, 'btnCollect', { collected })}
              onClick={() => onCollect({ collected })}
              children="My Collection"
            />
        }
        {
          !hideTrack &&
            <button
              type="button"
              className={cx(classes.button, 'btnTrack', { tracked })}
              onClick={() => onTrack()}
              children="Watchlist"
            />
        }
        {/*<button*/}
        {/*  className={cx(classes.button, 'ShareButton')}*/}
        {/*  type="button"*/}
        {/*  onClick={() => onShare()}*/}
        {/*  id="idShareButton"*/}
        {/*  children="Share"*/}
        {/*/>*/}
        {
          // @todo due to new not approved requirements should depend on analytics visibility, not RTV
          !!artwork && !artwork.isDraft && !( isEnterprise && artwork.rtvPrice ) &&
            <button
              className={cx(classes.button, 'downloadPdf')}
              type="button"
              onClick={this.downloadHandler}
              children="Download"
            />
        }
      </div>
    );
  }
}

export default compose(
  injectSheet(styles),
  connect({
    // onShare: onItemShareAction,
    onTrack: onItemTrackAction,
    onCollect: onItemCollectAction,
    downloadPdf: downloadRtvPdfAction,
    subscriptionType: subscriptionTypeSelector,
  }),
)(ItemActions);
