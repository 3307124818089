import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { Link } from 'react-router-dom';
import I from 'immutable';
import cx from 'classnames';
import { connect } from 'cpcs-reconnect';
import DraftLabel from 'pages/common/DraftLabel';
import selectableItem from 'pages/common/selectableItem';
import Due from 'pages/common/ItemList/Badges/Alert/dueDiligenceRisk';
import DayLabel from 'pages/common/dayLabel';

import Property from 'pages/common/property';
import { serializer } from 'pages/common/artworkView';

import SmartImage from 'components/image/SmartImage';

import injectSheet from 'lib/sheet';
import sheet from './sheet';
import { navigationBlocked_sel } from 'domain/env/EnvModel';

const doNothing = () => null;

function WrapAction({ className, children, onClick }) {
  const Tag = onClick ? 'button' : 'div';
  const props = onClick ? { type: 'button' } : {};
  return (
    <Tag onClick={onClick} className={className} {...props}>
      {children}
    </Tag>
  );
}
WrapAction.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.node,
};

const propertyFields = serializer.selectOrdered(
  'artist',
  'title',
  'category',
  'createdAt',
  'surfaces',
  'substrates',
  'mediums',
  'signature',
  'height',
  'seller',
  'lotNumber',
  'saleAt',
  'itemListSoldPrice',
  'itemListEstimation',
  'rtv',
);


class Artwork extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.shape({
      badge: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired,
      fillet: PropTypes.string.isRequired,
    }).isRequired,
    children: PropTypes.node,
    artwork: PropTypes.instanceOf(I.Record),
    linkBuilder: PropTypes.func.isRequired,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    modifier: PropTypes.string,
    navigationBlocked: PropTypes.bool,
    selectionChildren: PropTypes.shape({
      controls: PropTypes.node,
      selected: PropTypes.node,
    }),
    PDFComponentName: PropTypes.string,
  };

  static defaultProps = {
    selectionChildren: { controls: null, selected: null },
  };

  render() {
    const { classes, artwork, children, linkBuilder, width, modifier,
      selectionChildren, navigationBlocked, PDFComponentName } = this.props;
    const date = artwork.getIn(['lot', 'saleAt']);
    const picture = artwork.getIn(['pictures', 0, 'name']);
    const serializerProps = {
      noPurchase: true,
      onListBadge: true,
      hideRtvButton: true,
      noArtistLink: true,
      noLotLink: true,
      hideAristLifeDates: true,
      expiredTrialUser: navigationBlocked,
    };
    const link = linkBuilder(artwork);
    const Action = link ? Link : WrapAction;
    const expiredTrialUser = navigationBlocked ? 'expired' : '';
    return (
      <div style={{ width }} className={cx(classes.badge, 'selectable-item', modifier)}>
        <Action
          className={classes.link}
          to={link}
        >
          <SmartImage
            modifier="AOBadge"
            src={picture}
            thumbSize="L"
            onClick={doNothing}
            PDFComponentName={PDFComponentName}
          >
            <div className={classes.fillet}>
              {
                artwork.get('isDraft') ?
                  <DraftLabel modifier={modifier}/> :
                  <DayLabel date={date} modifier={modifier} />
              }
            </div>
            {
              artwork.get('isDueDiligenceRisk') &&
              <Due />
            }
            {selectionChildren.selected}
          </SmartImage>
          <div className={classes.content}>
            { artwork.get('isDueDiligenceRisk') && <Due /> }
            <Property data={artwork} serializer={propertyFields} props={serializerProps} modifier={cx('ItemList', expiredTrialUser )} />
          </div>
        </Action>
        {children}
        {selectionChildren.controls}
      </div>
    );
  }
}

export const ArtworkPure = injectSheet(sheet)(Artwork);

export default compose(
  injectSheet(sheet),
  connect({
    navigationBlocked: navigationBlocked_sel,
  }),
  selectableItem,
)(ArtworkPure);
