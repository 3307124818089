import record, { integer, string, listOf, setOf, bool, enhancedType } from 'cpcs-recordjs';
import { field, select, selector } from 'cpcs-reconnect';
import { getStorageItem, setStorageItem } from 'lib/storage';
import { getExternalToken } from 'lib/envGlobals';
import {
  preventLocationChangeAction,
  allowLocationChangeAction,
} from 'domain/router/RouterAction';
import { USAIdSelector } from 'domain/country/CountryModel';
import { Card, Plan } from 'model';
import { SUBSCRIPTION_TYPE_TRIAL, SUBSCRIPTION_TYPE_ENTERPRISE } from 'domain/const';

import * as A from 'domain/env/EnvActions';
import {
  hideRtvFormAction,
  orderRtvAction,
  updateCardAction,
  orderFreeRtvAction,
  noFreeRtvLeftAction,
} from 'pages/common/buyRtv/buyRtvActions';
import { buyAnalyticsReportAction } from 'pages/common/buyAnalytics/buyAnalyticsActions';
import { upgradePlanAction } from 'pages/upgradeSubscriptionPage/upgradeSubscriptionActions';
import { location_sel } from 'domain/router/RouterModel';
import { getFormValues } from 'redux-form';
import { lnk } from 'lib/routes';
import 'core-js/features/array/includes';

export const Stats = record('stats', {
  arts: integer(0),
  considerations: integer(0),
  alerts: integer(0),
  alertWasCreated: bool(false),
  artWasCreated: bool(false),
  considerationWasCreated: bool(false),
});

const Obj = enhancedType({
  typeName: 'obj',
  parse(value) {
    return value;
  },
});

const Link = record('notificationAction', {
  title: string('VIEW'),
  route: string,
  params: Obj(),
});

const Notification = record('notification', {
  title: string,
  type: string('default'),
  showTime: integer(5000),
  timestamp: integer(),
  restore: Obj(),
  link: Link(),
});

export const User = record('user', {
  token: '',
  id: integer(0),
  email: string(''),
  firstname: string(''),
  lastname: string(''),
  picture: string,
  // tracks + artworks
  defaultNewGallery: integer,
  // tracks
  defaultNewConsiderationGallery: integer(),
  // artworks
  defaultNewArtWorkGallery: integer(),
  resetPasswordInProgress: bool(false),
  resetPasswordTokenIsValid: bool(),
  resetPasswordDone: bool(false),
  marketingEmailConsent: bool(false),
  withPassword: bool(true),
  tempPassword: bool(false),
  favoriteArtists: listOf(integer),
  card: Card(),
  street: string(),
  street2: string(),
  city: string(),
  state: string(),
  code: string(),
  country: integer(),
  company: string(),
  occupations: listOf(integer),
  navigationBlocked: bool(false),
  cardHolderName: string(),
  freeRtvPurchaseCount: integer(0),
  totalPurchasesCount: integer(0),
  subscriptionType: SUBSCRIPTION_TYPE_TRIAL,
  renewalDate: string(),
  preferredCurrency: string(),
  createdDate: string(),
});

export const BreadCrumb = record('BreadCrumb', {
  title: string,
  link: string,
  entity: string,
});

const State = record('Env', {
  isAuthorized: false,
  isEnterpriseUser: false,
  user: User(),
  newArtTipVisible: getStorageItem('newArtTipVisible') === 'hidden' ? false : true,
  locale: 'en-US',
  currency: 'USD',
  avatarUploadError: string(''),
  breadcrumbs: listOf(BreadCrumb),
  stats: Stats(),
  notifications: listOf(Notification),
  stripeApiToken: string(''),
  cardFormOpened: bool(false),
  unsavedForms: setOf(string),
  trialPeriod: string(''),
  termsAccepted: false,
  privacyAccepted: false,
  currentPlan: Plan(),
  planTypes: listOf(Plan),
});

export function initialState() {
  const state = State();
  let token = getExternalToken();
  if (token) {
    setStorageItem('token', token);
  } else {
    token = getStorageItem('token');
  }
  const notificationsFromStorage = JSON.parse(getStorageItem('notifications') || '[]');
  return token ? state.apply(
    State.$user.update(User.$token.set(token)),
    State.$notifications.parsed(notificationsFromStorage),
  ) : state;
}

export const env = field('env');
export const isAuthorized = env.then(State.$isAuthorized);
export const isEnterpriseUser = env.then(State.$isEnterpriseUser);
export const user_sel = env.then(State.$user);
export const resetPasswordInProgress = user_sel.then(User.$resetPasswordInProgress);
export const resetPasswordTokenIsValid = user_sel.then(User.$resetPasswordTokenIsValid);
export const resetPasswordDone = user_sel.then(User.$resetPasswordDone);
export const hasPassword = user_sel.then(User.$withPassword);
export const tempPassword = user_sel.then(User.$tempPassword);
export const userProfileEmail = user_sel.then(User.$email);
export const token = user_sel.then(User.$token);
export const token_sel = user_sel.then(User.$token);
export const preferredCurrency_sel = user_sel.then(user => user ? user.get('preferredCurrency') : '');
export const locale = env.then(State.$locale);
export const currency = env.then(State.$currency);
export const newArtTipVisible = env.then(State.$newArtTipVisible);
export const getPicture = user_sel.then(User.$picture);
export const avatarUploadError = env.then(State.$avatarUploadError);
export const getBreadcrumbs = env.then(State.$breadcrumbs);
export const stats = env.then(State.$stats);
export const notifications = env.then(State.$notifications);
export const firstNotification = notifications.then((list) => list.get(0));
export const freeRtvLeftSel = user_sel.then(User.$freeRtvPurchaseCount);
export const totalPurchasesCountSel = user_sel.then(User.$totalPurchasesCount);
export const subscriptionTypeSelector = user_sel.then(User.$subscriptionType);
export const onboardingPassed_sel = user_sel.then(User.$occupations).then(list => !!list && list.size > 0);
export const currentPlanSel = env.then(State.$currentPlan);

/**
 * trial users, registered before 17-08-2020 have unexpired trial subscription
 * all other trial subscriptions are temporary and can expire
 * 
 * active trial users - users regisered before 17-08-2020 (they have unlimited subscription period)
 * 
 * remove alert button shown for: (#MVP-7002)
 *   NOT active trial users (registered before 17-08-2020 and have not blocked navigation)
 * Build 201 - need to remove the ability to delete alert for trial active users with not blocked navigation
**/
// const tempActiveTrialUser_sel = user_sel.then(profile =>
//   profile.subscriptionType === SUBSCRIPTION_TYPE_TRIAL &&
//   !profile.navigationBlocked &&
//   moment(profile.createdDate).isBefore('2020-08-17'),
// );

export const allowToRemoveAlert_sel = selector(user_sel, (profile) => {
  const { navigationBlocked, subscriptionType } = profile;
  if (subscriptionType !== SUBSCRIPTION_TYPE_TRIAL) return true;
  if (subscriptionType === SUBSCRIPTION_TYPE_TRIAL && navigationBlocked) return true;
  return false;
});

export const billingData = select(state => getFormValues('billing')(state) || {});

export const allWorksGalleryId_sel = user_sel.then(({ defaultNewGallery }) => defaultNewGallery);

export const ownWorksGalleryId_sel = user_sel.then(({ defaultNewArtWorkGallery }) => defaultNewArtWorkGallery);

export const currentGalleryId_sel = selector(
  allWorksGalleryId_sel,
  location_sel,
  (allWorksGalleryId, { match: { params: { gId } = {} } }) => {
    if (gId && gId !== 'all') return parseInt(gId, 10);
    return allWorksGalleryId;
  },
);

export const currentGalleryIsDefault_sel = selector(
  user_sel,
  currentGalleryId_sel,
  ({ defaultNewGallery, defaultNewConsiderationGallery, defaultNewArtWorkGallery }, currentGalleryId) => [defaultNewGallery, defaultNewConsiderationGallery, defaultNewArtWorkGallery].includes(parseInt(currentGalleryId, 10)),
);

export const stripeApiTokenSelector = env.then(State.$stripeApiToken);
const cardInfoSelector = user_sel.then(User.$card);
export const cardHolderNameSel = user_sel.then(User.$cardHolderName);
export const navigationBlocked_sel = user_sel.then(User.$navigationBlocked);
export const homePageLink_sel = navigationBlocked_sel.then(v => v ? lnk('homePage', { query: { acct: 'upgrade' } }) : lnk('homePage'));
export const intelligencePageLink_sel = navigationBlocked_sel.then(v => v ? lnk('intelligencePage', { query: { acct: 'upgrade' } }) : lnk('intelligencePage'));
export const cardNumberSelector = cardInfoSelector.then(c => c ? c.cardNumber : void '');
export const cardFormOpened_sel = selector(env.then(State.$cardFormOpened), cardNumberSelector, (cardFormOpened, cardNumber) => (cardFormOpened || !cardNumber));
export const unsavedFormsSelector = env.then(State.$unsavedForms);
export const termsAcceptedSelector = env.then(State.$termsAccepted);
export const privacyAcceptedSelector = env.then(State.$privacyAccepted);
export const trialPeriodSelector = env.then(State.$trialPeriod);
export const accountFormInitialData_sel = selector(user_sel, USAIdSelector, (v, USA_ID) => ({
  firstname: v.firstname,
  lastname: v.lastname,
  occupations: v.occupations,
  email: v.email,
  phone: v.phone,
  country: v.country || USA_ID,
}));
export const currencyFormInitialData_sel = user_sel.then(v => ({
  preferredCurrency: v.preferredCurrency,
}));

const asIs = v => v;

export const reducer = {
  env(state = new State(), action) { //NOSONAR
    let newState = {};
    switch (action.type) {

      case A.addBreadCrumbs.type:
        return state.apply(
          State.$breadcrumbs.parsed(action.payload),
        );

      case A.addNotification.type:
        newState = state.apply(
          State.$notifications.updateBy('unshift', Notification.parse(action.payload)),
        );
        setStorageItem('notifications', JSON.stringify(newState.get('notifications').toJSON()));
        return newState;

      case A.clearOutdatedNotifications.type:
        newState = state.apply(
          State.$notifications.filter(n => n.get('timestamp') + n.get('showTime') > action.payload),
        );
        setStorageItem('notifications', JSON.stringify(newState.get('notifications').toJSON()));
        return newState;

      case A.removeNotification.type:
        newState = state.apply(
          State.$notifications.filter(item => item.get('timestamp') !== parseInt(action.payload, 10)),
        );
        setStorageItem('notifications', JSON.stringify(newState.get('notifications').toJSON()));
        return newState;

      case A.changePasswordAction.success:
        return state.apply(
          State.$user.parsed({ ...action.payload, token: state.user.token }),
        );

      case A.getUserStatsAction.success:
        return state.apply(
          State.$stats.parsed(action.payload),
        );
      case A.registerAction.success:
        return state.apply(
          State.$user.parsed(action.payload),
        );

      case A.loginAction.success:
        return state.apply(
          State.$isEnterpriseUser.set(action.payload.subscriptionType === SUBSCRIPTION_TYPE_ENTERPRISE),
          State.$isAuthorized.set(true),
          State.$user.set(
            User.parse(action.payload)
              .apply(User.$token.set(action.headers['x-token'])),
          ),
        );

      case A.saveStripeCardAction.success:
        return state.apply(
          State.$user.update(User.$card.parsed(action.payload.card)),
          State.$cardFormOpened.set(false),
        );

      case A.saveBillingAction.success:
      case A.subscribeAction.success:
      case A.updateAccountAction.success:
      case A.updatePreferredCurrencyAction.success:
      case upgradePlanAction.success:
      case A.checkAuthAction.success:
        return state.apply(
          State.$isAuthorized.set(true),
          State.$user.set(
            User.parse({
              ...action.payload,
              token: action.payload.token || state.user.token,
            }),
          ),
        );

      case A.hideNewArtTip.type:
        return state.apply(
          State.$newArtTipVisible.set(false),
        );

      case A.logOut.type:
        return new State();

      case A.removePictureAction.type:
      case A.uploadPictureAction.request:
        return state.apply(
          State.$avatarUploadError.set(''),
        );

      case A.uploadPictureAction.failure:
        return state.apply(
          State.$avatarUploadError.set(action.err),
        );

      case A.uploadErrorAction.type:
        return state.apply(
          State.$avatarUploadError.set(action.payload),
        );

      case A.fetchStripePropertiesAction.success:
        return state.apply(
          State.$stripeApiToken.set(action.payload.publicKey),
        );
      case updateCardAction.type:
        return state.update(
          State.$user.update(User.$card.parsed(action.payload)),
        );
      case hideRtvFormAction.type:
        return state.update(
          State.$cardFormOpened.set(false),
        );
      case buyAnalyticsReportAction.success:
      case orderRtvAction.success:
        return state.apply(
          State.$cardFormOpened.set(false),
          State.$user.update(u => u.apply(
            (action.headers && action.headers['x-token']) ? User.$token.set(action.headers['x-token']) : asIs,
            action.requestData.cardHolderName ? User.$cardHolderName.set(action.requestData.cardHolderName) : asIs,
            User.$email.update(old => action.requestData.email || old),
            User.$code.update(old => action.requestData.code || old),
            User.$country.update(old => action.requestData.country || old),
            User.$cardHolderName.update(old => action.requestData.cardHolderName || old),
          )),
        );
      case A.setCardFormOpenedAction.type:
        return state.apply(
          State.$cardFormOpened.set(action.payload),
        );
      case noFreeRtvLeftAction.type:
        return state.apply(
          State.$user.update(User.$freeRtvPurchaseCount.set(0)),
        );
      case orderFreeRtvAction.success:
        return state.apply(
          State.$user.update(User.$freeRtvPurchaseCount.update(v => Math.max(0, v - 1))),
        );
      case '@@router/LOCATION_CHANGE':
        return state.apply(
          State.$cardFormOpened.set(false),
          State.$unsavedForms.clear(),
          State.$user.update(User.$resetPasswordInProgress.set(false)),
          State.$user.update(User.$resetPasswordDone.set(false)),
        );
      case preventLocationChangeAction.type:
        return state.apply(
          State.$unsavedForms.push(action.payload),
        );
      case allowLocationChangeAction.type:
        return state.apply(
          State.$unsavedForms.updateBy('filter', form => form !== action.payload),
        );
      case A.forgotPasswordAction.success:
        return state.apply(
          State.$user.update(User.$resetPasswordInProgress.set(true)),
        );
      case A.resetPasswordAction.success:
        return state.apply(
          State.$user.update(User.$resetPasswordDone.set(true)),
        );
      case A.forgotPasswordAction.failure:
        return state.apply(
          State.$user.update(User.$resetPasswordInProgress.set(false)),
        );
      case A.checkResetPasswordToken.success:
        return state.apply(
          State.$user.update(User.$resetPasswordTokenIsValid.set(true)),
        );
      case A.termsAcceptAction.type:
        return state.apply(
          State.$termsAccepted.set(action.payload),
        );
      case A.privacyAcceptAction.type:
        return state.apply(
          State.$privacyAccepted.set(action.payload),
        );
      case '@@DEBUG/USER/SET_TOKEN':
        return state.apply(
          State.$user.update(
            User.$token.set(action.payload),
          ),
        );
      /**
       * { type: '@@DEBUG/USER/SET_SUBSCRIPTION', payload: 'SUBSCRIPTION_TYPE_TRIAL' }
       * { type: '@@DEBUG/USER/SET_SUBSCRIPTION', payload: 'SUBSCRIPTION_TYPE_ENTERPRISE' }
      **/
      case '@@DEBUG/USER/SET_SUBSCRIPTION':
        return state.apply(
          State.$user.update(
            User.$subscriptionType.parsed(action.payload),
          ),
        );
      case '@@DEBUG/USER/SET_FREE_RTV':
        return state.apply(
          State.$user.update(
            User.$freeRtvPurchaseCount.parsed(action.payload),
          ),
        );
      case '@@DEBUG/USER/SET_EXPIRED':
        return state.apply(
          State.$user.update(
            User.$navigationBlocked.parsed(action.payload),
          ),
        );
      case 'USER/SUBSCRIPTION/EXPIRED':
        return state.apply(
          State.$user.update(
            User.$navigationBlocked.parsed(true),
          ),
        );
      case A.currentPlanAction.success:
        return state.apply(
          State.$currentPlan.parsed(action.payload),
          State.$user.update(
            User.$subscriptionType.parsed(action.payload.shortName),
          ),
        );
      case A.fetchTrialPlanPurchasesCountAction.success:
        return state.apply(
          State.$trialPeriod.set(action.payload.trialPeriod),
        );
      default:
        return state;
    }
  },
};
