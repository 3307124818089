import { takeEvery, put, call, fork, select } from 'redux-saga/effects';
import { sagaDelay } from 'lib/envGlobals';
import queryString from 'query-string';
import {
  authSocialNetworkAction,
  authSocialNetworkEmailProvidedAction,
  addNotification,
  onGoogleSignInAction,
  onFacebookSignInAction,
  getTwitterOauthTokenAction,
  snAuthPrivacyAndTermsAcceptedAction,
  changePasswordAction,
  loginAction,
} from 'domain/env/EnvActions';
import { intelligencePageLink_sel } from 'domain/env/EnvModel';
import { addPopupAction } from 'domain/ui/UIActions';
import { replace as routerReplace } from 'connected-react-router';
import { lnk } from 'lib/routes';
import { uriByPath, openAuthWindow } from 'lib/helpers';
import translate from 'lib/translate.js';
import api, { callApi } from 'domain/api';
import config from 'config';
import { onAuthSuccess, onChangePasswordAction } from 'domain/env/sagas';
import Api, { makeApiGenerator } from 'domain/api';
import {
  AUTH_ASK_EMAIL_POPUP,
  AUTH_ACCEPT_PRIVACY_AND_USAGE_TERMS_POPUP,
} from 'domain/const';
import { redirectToURL } from 'lib/redirectToPage';
import { setStorageItem } from 'lib/storage';

import watchAcceptInPopups from 'pages/authPage/sagas/watchAcceptInPopups';

const HTTP_FORBIDDEN = 403;
const HTTP_UNPROCESSABLE_ENTITY = 422;

/**
 * dispatch action in window opener or in current window
**/
function* broadcast_channel(obj) {
  if (!global.opener) {
    yield put(obj);
    return;
  }
  const { broadcastToken } = global.opener;
  localStorage.setItem('BroadcastChannel', JSON.stringify({
    broadcastToken,
    action: obj,
  }));
}

function* onAuthSocialNetwork(action) {
  try {
    const resp = yield callApi(api.authSocialNetwork, {
      providerId: action.payload.providerId,
      data: action.payload.data,
    });
    yield onAuthSuccess(resp);
  } catch (err) {
    if (err && err.response && err.response.status === HTTP_FORBIDDEN) {
      yield put(addNotification({
        type: 'error',
        title: translate('notification.snAuth.tryAgainLater'),
        showTime: 10000,
      }));
      yield put(routerReplace(lnk('auth')));
    } else if (err && err.response && err.response.status === HTTP_UNPROCESSABLE_ENTITY) {
      let popup = AUTH_ASK_EMAIL_POPUP;
      const m = err.response.data.message;
      if (m === 'Please accept privacy and terms' || m === 'Please accept GDPR politics') {
        popup = AUTH_ACCEPT_PRIVACY_AND_USAGE_TERMS_POPUP;
      }
      const accessToken = err.response.headers['x-access-token'];
      const secret = err.response.headers['x-secret'];
      yield put(addPopupAction({
        name: popup,
        params: {
          providerId: action.payload.providerId,
          data: {
            ...action.payload.data,
            accessToken,
            secret,
          },
        },
      }));
    }
  }
}

function* onSNAuthEmailProvided(action) {
  try {
    yield callApi(api.authSocialNetwork, {
      providerId: action.payload.get('providerId'),
      data: action.payload.get('data').toJS(),
    });
    const confirmEmailMessage = translate('notification.confirmEmail.success');
    yield put(addNotification({ title: confirmEmailMessage, showTime: 10000 }));
  } catch (err) {
    console.error('onSNAuthEmailProvided', { err });
  }
}

function* onSNPrivacyAndTermsAccepted(action) {
  const payload = {
    providerId: action.payload.get('providerId'),
    data: action.payload.get('data').toJS(),
  };
  yield call(onAuthSocialNetwork, { payload });
}

/**
 * dispatch action in window opener and close window
**/
function* onLinkedinAuth(payload) {
  if (payload.query.error) {
    global.close();
    // redirect to 'auth' page if can't close window
    yield sagaDelay(300);
    yield put(routerReplace(lnk('auth')));
    return;
  }
  const PROVIDER_ID = config.auth.providers.linkedin.provider;
  const REDIRECT_PATH = config.auth.providers.linkedin.redirectPath;
  const REDIRECT_URL = uriByPath(REDIRECT_PATH);
  yield call(broadcast_channel, authSocialNetworkAction({
    providerId: PROVIDER_ID,
    data: {
      code: payload.query.code,
      redirectUrl: REDIRECT_URL,
    },
  }));
  if (!global.opener) return;
  yield sagaDelay(500);
  yield call(() => global.close());
}

/**
 * { accessToken, email, fullName, id, idToken, lastName, name }
*/
function* onGoogleAuth({ payload }) {
  if (payload.error) {
    return;
  }

  const PROVIDER_ID = config.auth.providers.google.provider;
  yield put(authSocialNetworkAction({
    providerId: PROVIDER_ID,
    data: {
      accessToken: payload.accessToken,
    },
  }));
}

/**
 * dispatch action in window opener and close window
**/
function* onInstagramAuth(payload) {
  if (!payload.hash) {
    global.close();
    // redirect to 'auth' page if can't close window
    yield sagaDelay(300);
    yield put(routerReplace(lnk('auth')));
    return;
  }
  const params = queryString.parse(payload.hash, { arrayFormat: 'bracket' });
  if (!params || !params.access_token) {
    global.close();
    // redirect to 'auth' page if can't close window
    yield sagaDelay(300);
    yield put(routerReplace(lnk('auth')));
    return;
  }
  const PROVIDER_ID = config.auth.providers.instagram.provider;
  yield call(broadcast_channel, authSocialNetworkAction({
    providerId: PROVIDER_ID,
    data: {
      accessToken: params.access_token,
    },
  }));
  if (!global.opener) return;
  yield sagaDelay(500);
  yield call(() => global.close());
}

/**
 * dispatch action in window opener and close window
**/
function* onPinterestAuth(payload) {
  if (payload.query.state === 'None') {
    global.close();
    // redirect to 'auth' page if can't close window
    yield sagaDelay(300);
    yield put(routerReplace(lnk('auth')));
    return;
  }
  const PROVIDER_ID = config.auth.providers.pinterest.provider;
  const REDIRECT_PATH = config.auth.providers.pinterest.redirectPath;
  const REDIRECT_URL = uriByPath(REDIRECT_PATH);
  yield call(broadcast_channel, authSocialNetworkAction({
    providerId: PROVIDER_ID,
    data: {
      code: payload.query.code,
      redirectUrl: REDIRECT_URL,
    },
  }));
  if (!global.opener) return;
  yield sagaDelay(500);
  yield call(() => global.close());
}

function* onFacebookAuth({ payload: { response } }) {
  if (response.status !== 'connected' || !response.authResponse) {
    return;
  }
  const PROVIDER = config.auth.providers.facebook.provider;
  yield put(authSocialNetworkAction({
    providerId: PROVIDER,
    data: {
      accessToken: response.authResponse.accessToken,
    },
  }));
}

function* onGetTwitterOauthToken() {
  try {
    const REDIRECT_PATH = config.auth.providers.twitter.redirectPath;
    const redirectUrl = uriByPath(REDIRECT_PATH);
    const { data } = yield callApi(api.twitterOauthToken, { data: { redirectUrl } });
    openAuthWindow(data.redirectUrl);
  } catch (err) {
    yield put(addNotification({
      type: 'error',
      title: translate('notification.snAuth.tryAgainLater'),
      showTime: 10000,
    }));
    console.error(err);
  }
}

/**
 * dispatch action in window opener and close window
**/
function* onTwitterAuth(payload) {
  if (!payload.query.oauth_token || !payload.query.oauth_verifier) {
    global.close();
    // redirect to 'auth' page if can't close window
    yield sagaDelay(300);
    yield put(routerReplace(lnk('auth')));
    return;
  }
  const PROVIDER_ID = config.auth.providers.twitter.provider;
  yield call(broadcast_channel, authSocialNetworkAction({
    providerId: PROVIDER_ID,
    data: {
      oauthToken: payload.query.oauth_token,
      secret: payload.query.oauth_verifier,
    },
  }));
  if (!global.opener) return;
  yield sagaDelay(500);
  yield call(() => global.close());
}

const login_apiGen = makeApiGenerator({
  actionCreator: loginAction,
  api: Api.login,
  getSuccessData: ({ data, headers }) => ({ payload: data, headers }),
  returnValue: ({ data, headers }) => ({ data, headers }),
});

function* onLogin(action) {
  const { payload: { resolve, reject, ...payload } } = action;
  try {
    const { data, headers } = yield call(login_apiGen, { data: payload });
    setStorageItem('token', headers['x-token']);
    resolve(data);
    const url = yield select(intelligencePageLink_sel);
    yield call(redirectToURL, url);
  } catch (err) {
    console.error(err);
    reject(err);
  }
}

/*
 * @arguments payload, match
 * match.params.sn
 * payload.query.foo // ='bar'
 * payload.hash
 * payload.search // = '?foo=bar'
*/
export default function* (payload, match) {
  yield takeEvery(loginAction.type, onLogin);
  yield fork(watchAcceptInPopups);
  yield takeEvery(changePasswordAction.type, onChangePasswordAction);
  yield takeEvery(authSocialNetworkAction.type, onAuthSocialNetwork);
  yield takeEvery(snAuthPrivacyAndTermsAcceptedAction.type, onSNPrivacyAndTermsAccepted);
  yield takeEvery(authSocialNetworkEmailProvidedAction.type, onSNAuthEmailProvided);
  yield takeEvery(onGoogleSignInAction.type, onGoogleAuth);
  yield takeEvery(onFacebookSignInAction.type, onFacebookAuth);
  yield takeEvery(getTwitterOauthTokenAction.type, onGetTwitterOauthToken);
  /**
   * all these cases (excluding default)
   * would be runned in a new window
   * it should call broadcast_channel and close window
  **/
  switch (match.params.sn) {
    case 'linkedin':
      yield onLinkedinAuth(payload);
      return;
    case 'instagram':
      yield onInstagramAuth(payload);
      return;
    case 'pinterest':
      yield onPinterestAuth(payload);
      return;
    case 'twitter':
      yield onTwitterAuth(payload);
      break;
    default:
      break;
  }
}
