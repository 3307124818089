import bg from './images/404-page-bg.png';
import { DARK_BLUE_COLOR, DEFAULT_FONTFAMILY } from 'theme/theme';

export default {
  content: {
    height: 590,
    paddingTop: 500,
    background: {
      image: `url("${bg}")`,
      repeat: 'no-repeat',
      position: 'center 20px',
      size: '456px 442px',
    },
  },
  text: {
    color: DARK_BLUE_COLOR,
    fontFamily: DEFAULT_FONTFAMILY,
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '24px',
    textAlign: 'center',
    marginTop: 30,
  },
  textLink: {
    color: '#dd4a3e',
  },
  title: {
    textAlign: 'center',
    color: DARK_BLUE_COLOR,
    fontFamily: DEFAULT_FONTFAMILY,
    fontSize: 24,
    fontWeight: 700,
    lineHeight: '29px',
  },
};
