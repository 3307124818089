export const createAlertFields = {
  alertTitle: { //check
    name: 'alertTitle',
    value: '',
  },
  artists: { // check
    name: 'artists',
    value: [],
  },
  bornIn: { // check
    name: 'artistBornYearFrom',
    value: '',
  },
  diedIn: { // check
    name: 'artistBornYearTo',
    value: '',
  },
  residence: { // check
    name: 'artistResidenceCountries',
    value: [],
  },
  artTitle: {
    name: 'artTitles',
    value: '',
  },
  yearCreatedFrom: { // check
    name: 'yearCreatedFrom',
    value: '',
  },
  yearCreatedTo: { // check
    name: 'yearCreatedTo',
    value: '',
  },
  categories: { // check
    name: 'categories',
    value: [],
  },
  substrates: { // check
    name: 'substrates',
    value: [],
  },
  mediums: { // check
    name: 'mediums',
    value: [],
  },
  surfaces: { // check
    name: 'surfaces',
    value: [],
  },
  width: {
    name: 'widthFrom',
    value: '',
  },
  height: {
    name: 'heightFrom',
    value: '',
  },
  depth: {
    name: 'depthFrom',
    value: '',
  },
  widthTo: {
    name: 'widthTo',
    value: '',
  },
  heightTo: {
    name: 'heightTo',
    value: '',
  },
  depthTo: {
    name: 'depthTo',
    value: '',
  },
  signature: { // check
    name: 'signature',
    value: 'No',
  },
  provenance: { // check
    name: 'provenances',
    value: '',
  },
  catalogRaisonne: { // check
    name: 'catalogRaisonnes',
    value: '',
  },
  exhibition: { // check
    name: 'exhibitions',
    value: '',
  },
  unit: {
    name: 'sizeUnits',
    value: 'cm',
  },
};
