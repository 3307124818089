let counter = 0;

const addChart = (id, contentSection) => [
  {
    title: `Chart ${id}`,
    component: 'ChartPDF',
    name: id,
    order: counter++,
    removable: true,
    contentSection,
    useAsBg: true,
    customMarginBottom: 20,
  },
  {
    title: `${id.toUpperCase()}_DEFINITION`,
    component: 'Definition',
    name: `${id.toUpperCase()}_DEFINITION`,
    parent: id,
    order: counter++,
    removable: false,
    contentSection,
    customMarginTop: 30,
  },
  {
    title: `${id.toUpperCase()}_INTERPRETATION`,
    component: 'Definition',
    name: `${id.toUpperCase()}_INTERPRETATION`,
    parent: id,
    order: counter++,
    removable: false,
    contentSection,
  },
];

const items = [
  {
    title: 'Cover Page',
    name: 'CoverPageContent',
    order: counter++,
    removable: false,
    contentSection: 'coverPage',
  },
  {
    title: 'Introduction',
    name: 'intro',
    component: 'SectionHeader',
    order: counter++,
    removable: false,
    contentSection: 'intro',
  },
  {
    title: 'Table of Contents',
    name: 'tableOfContents',
    order: counter++,
    removable: false,
    contentSection: 'intro',
  },
  {
    title: 'AOImage main info block',
    name: 'AOImage',
    component: 'AOImage',
    metadata: {
      width: 207,
      height: 245,
      top: 94,
      left: 24,
    },
    order: counter++,
    removable: false,
    contentSection: 'AODetails',
    useAsBg: true,
  },
  {
    title: 'Artwork main info',
    name: 'AOMainPDF',
    component: 'AOMainPDF',
    parent: 'AOImage',
    order: counter++,
    removable: false,
    contentSection: 'AODetails',
  },
  {
    title: 'Comparables',
    name: 'ComparablesPDF',
    component: 'ComparablesPDF',
    order: counter++,
    removable: true,
    contentSection: 'AODetails',
  },
  {
    title: 'AOInfoSidebar',
    name: 'AOInfoSidebar',
    component: 'AOInfoSidebar',
    order: counter++,
    removable: false,
    contentSection: 'AODetails',
    useAsBg: true,
  },
  {
    title: 'Sales History',
    name: 'SalesHistoryPDF',
    component: 'SalesHistoryPDF',
    order: counter++,
    removable: false,
    contentSection: 'AODetails',
    parent: 'AOInfoSidebar',
  },
  {
    title: 'Provenance',
    name: 'ProvenancePDF',
    component: 'ProvenancePDF',
    order: counter++,
    removable: true,
    contentSection: 'AODetails',
    parent: 'AOInfoSidebar',
  },
  {
    title: 'Literature',
    name: 'LiteraturePDF',
    component: 'LiteraturePDF',
    order: counter++,
    removable: true,
    contentSection: 'AODetails',
    parent: 'AOInfoSidebar',
  },
  {
    title: 'Financial Performance',
    name: 'financialPerformance',
    component: 'SectionHeader',
    order: counter++,
    removable: true,
    contentSection: 'financialPerformance',
  },
  // AFPM
  {
    title: 'Artist Financial Performance Metrics',
    component: 'AFPM',
    name: 'AFPM',
    order: counter++,
    removable: true,
    contentSection: 'financialPerformance',
    useAsBg: true,
  },
  {
    title: 'T001_LIQUIDITY_LEVEL',
    component: 'Definition',
    name: 'T001_LIQUIDITY_LEVEL',
    parent: 'AFPM',
    order: counter++,
    removable: false,
    contentSection: 'financialPerformance',
    customMarginTop: 56 - 35,
    customMarginLeft: 429 - 29,
    customWidth: 134 + 29 + 30,
  },
  {
    title: 'T001_ARTIST_MEDIAN_COMPOUND_GROWTH_RATE',
    component: 'Definition',
    name: 'T001_ARTIST_MEDIAN_COMPOUND_GROWTH_RATE',
    parent: 'AFPM',
    order: counter++,
    removable: false,
    contentSection: 'financialPerformance',
    customMarginLeft: 429 - 29,
    customWidth: 134 + 29 + 30,
  },
  {
    title: 'T001_REPEAT_SALES_RANKING',
    component: 'Definition',
    name: 'T001_REPEAT_SALES_RANKING',
    parent: 'AFPM',
    order: counter++,
    removable: false,
    contentSection: 'financialPerformance',
    customMarginLeft: 429 - 29,
    customWidth: 134 + 29 + 30,
  },
  {
    title: 'T001_ARTIST_SELL_THROUGH_RATE',
    component: 'Definition',
    name: 'T001_ARTIST_SELL_THROUGH_RATE',
    parent: 'AFPM',
    order: counter++,
    removable: false,
    contentSection: 'financialPerformance',
    customMarginLeft: 429 - 29,
    customWidth: 134 + 29 + 30,
  },
  {
    title: 'T001_ARTIST_VOLATILITY_RISK',
    component: 'Definition',
    name: 'T001_ARTIST_VOLATILITY_RISK',
    parent: 'AFPM',
    order: counter++,
    removable: false,
    contentSection: 'financialPerformance',
    customMarginLeft: 429 - 29,
    customWidth: 134 + 29 + 30,
  },
  {
    title: 'T001_ARTIST_RETURN_RANKING',
    component: 'Definition',
    name: 'T001_ARTIST_RETURN_RANKING',
    parent: 'AFPM',
    order: counter++,
    removable: false,
    contentSection: 'financialPerformance',
    customMarginLeft: 429 - 29,
    customWidth: 134 + 29 + 30,
  },
  // charts financialPerformance
  ...addChart('p001', 'financialPerformance'),
  ...addChart('p002', 'financialPerformance'),
  ...addChart('p009', 'financialPerformance'),
  ...addChart('p004', 'financialPerformance'),
  ...addChart('p011', 'financialPerformance'),
  ...addChart('p012', 'financialPerformance'),
  // charts marketMovement
  {
    title: 'Market Movements',
    name: 'marketMovement',
    component: 'SectionHeader',
    order: counter++,
    removable: true,
    contentSection: 'marketMovement',
  },
  ...addChart('m006', 'marketMovement'),
  ...addChart('m014', 'marketMovement'),
  ...addChart('m015', 'marketMovement'),
  ...addChart('m018', 'marketMovement'),
  // charts ARTBnkIndexes
  {
    title: 'ARTBnk Indexes',
    name: 'ARTBnkIndexes',
    component: 'SectionHeader',
    order: counter++,
    removable: true,
    contentSection: 'ARTBnkIndexes',
  },
  ...addChart('x001', 'ARTBnkIndexes'),

  // Contacts
  {
    title: 'Contacts',
    component: 'ContactsPDF',
    name: 'ContactsPDF',
    order: counter++,
    removable: false,
    contentSection: 'contacts',
  },
  {
    title: 'Disclaimer',
    component: 'DisclaimerPDF',
    name: 'DisclaimerPDF',
    order: counter++,
    removable: false,
    contentSection: 'contacts',
  },
  {
    title: 'LastCoverPDF',
    component: 'LastCoverPDF',
    name: 'LastCoverPDF',
    order: counter++,
    removable: false,
    contentSection: 'contacts',
  },
];
export { items };
