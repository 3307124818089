import footerLogo from './img/artbnk_logo.png';
import fbLogo from './img/fb.svg';
import twitterLogo from './img/twitter.svg';
import instagramLogo from './img/instagram.svg';
import { DARK_BLUE_COLOR } from 'theme/theme';
import { FOOTER_Z_INDEX } from 'theme/zIndex';

export default {
  Footer: {
    width: '100%',
    height: 50,
    background: DARK_BLUE_COLOR,
    boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.2)',
    padding: [0, 25, 0, 48],
    zIndex: FOOTER_Z_INDEX,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: 12,
    color: '#FFF',
    '@media screen and (max-width: 610px)': {
      height: 210,
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
  termsAndConditionsLinks: {
    '@media screen and (max-width: 610px)': {
      display: 'flex',
      flexWrap: 'wrap',
      width: 290,
      margin: [0, 0, 50, 0],
    },
  },
  termsAndConditionsLink: {
    margin: [0, 30, 0, 0],
    fontSize: 12,
    color: '#FFF',
    cursor: 'pointer',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
    '@media screen and (max-width: 900px)': {
      marginRight: 15,
    },
    '@media screen and (max-width: 610px)': {
      marginBottom: 20,
      flex: '1 1 auto',
    },
  },
  copy: {
    position: 'relative',
    '&:before': {
      content: "''",
      position: 'absolute',
      top: -4,
      left: -45,
      width: 26,
      height: 26,
      background: `url("${footerLogo}") left top no-repeat`,
      backgroundSize: 'cover',
    },
  },
  textBold: {
    fontWeight: 600,
  },
  socials: {

  },
  socialLink: {
    display: 'inline-block',
    height: 16,
    margin: [0, 30, 0, 0],
    cursor: 'pointer',
    textIndent: -9999,
  },
  fbLink: {
    width: 9,
    background: `url("${fbLogo}") left top no-repeat`,
  },
  twitterLink: {
    width: 19,
    background: `url("${twitterLogo}") left top no-repeat`,
  },
  instagramLink: {
    width: 16,
    background: `url("${instagramLogo}") left top no-repeat`,
  },
};
