import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import injectSheet from 'lib/sheet';
import sheet from './sheet';

class AdvancedFilterAccordion extends React.Component {
  static propTypes = {
    title: PropTypes.string,
    amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    children: PropTypes.node,
    defaultOpened: PropTypes.bool,
    classes: PropTypes.shape({
      AdvancedFilterAccordion: PropTypes.string,
      head: PropTypes.string,
      title: PropTypes.string,
      amount: PropTypes.string,
      collapseCell: PropTypes.string,
      collapseBtn: PropTypes.string,
      content: PropTypes.string,
    }).isRequired,
    hideToggleButton: PropTypes.bool,
    modifier: PropTypes.string,
  };

  state = {
    opened: !!this.props.defaultOpened,
  };

  onToggle = () => {
    if (this.props.hideToggleButton) return;
    this.setState({ opened: !this.state.opened });
  };

  render() {
    const { children, title, amount, classes, hideToggleButton, modifier } = this.props;
    const { opened } = this.state;
    return (
      <div className={cx(classes.AdvancedFilterAccordion, modifier, { opened })}>
        <div className={cx(classes.head, modifier, { opened })} onClick={this.onToggle}>
          <span className={cx(classes.title, modifier)} children={title} />
          <span className={classes.amount} children={amount ? `(${amount})` : ''} />
          <span className={classes.collapseCell}>
            {
              !hideToggleButton &&
                <button
                  type="button"
                  className={cx(classes.collapseBtn, { opened })}
                  children={opened ? 'x' : 'v'}
                />
            }
          </span>
        </div>
        <div className={cx(classes.content, modifier, { opened })}>{children}</div>
      </div>
    );
  }
}

export default injectSheet(sheet)(AdvancedFilterAccordion);
