import { button } from 'theme/button';
import {
  NEW_STYLE,
  ORANGE_COLOR,
  DEFAULT_FONTFAMILY_3,
} from 'theme/theme';

export const AuthPageSheet = {
  names: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    columnGap: '15px',
    justifyContent: 'space-between',
    '@media screen and (max-width: 375px)': {
      display: 'block',
    },
  },
  link: {
    color: NEW_STYLE,
    fontSize: 12,
    marginLeft: 8,
    textDecoration: 'none',
    fontFamily: DEFAULT_FONTFAMILY_3,
    fontWeight: 500,
  },
  signUpLoginBtn: {
    color: NEW_STYLE,
  },
  item: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: [14, 0, 30],
  },
  noAcc: {
    textAlign: 'center',
    fontSize: 12,
    marginTop: 33,
    fontFamily: DEFAULT_FONTFAMILY_3,
  },
  button,
  titleTrial: {
    color: NEW_STYLE,
    fontSize: 22,
    fontWeight: 500,
    marginBottom: 7,
  },
  buttonGroup: {
    marginTop: 30,
  },
  rememberMeLabel: {
    position: 'relative',
    whiteSpace: 'nowrap',
    lineHeight: '17px',
    fontFamily: DEFAULT_FONTFAMILY_3,
    fontSize: '12px',
  },
};

export const emailSheet = {
  EmailError: {
    whiteSpace: 'normal',
  },
  email: {
    color: ORANGE_COLOR,
  },
};
