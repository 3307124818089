import I from 'immutable';
import record, { integer, string, listOf, setOf, decimal } from 'cpcs-recordjs';
import { field, selector } from 'cpcs-reconnect';
import * as env from 'domain/env/EnvActions';
import { Artwork, Artist, Pagination } from 'model';
import { orderRtvAction } from 'pages/common/buyRtv/buyRtvActions';
import { createConsiderationAction } from 'domain/considering/ConsideringActions';

import * as A from './AlertsActions';

import { removeAction, restoreAction, TYPE_ALERT_REMOVE, TYPE_ALERT_ARTWORK } from 'domain/const';

const LOADING = 'LOADING';
const FALSE = 'FALSE';
const TRUE = 'TRUE';
const SUBMITTING = 'SUBMITTING';

function onRemove(state, { type, params }) {
  if (type !== TYPE_ALERT_ARTWORK) return state;
  return state.update('selected', v => v.filter(i => !params.ids.includes(i)));
}

function onRestore(state, { type }) {
  if (type !== TYPE_ALERT_REMOVE) return state;
  return state.set('artistInAlert', FALSE);
}

/**
 * Schemas
**/
export const meta = {
  page: integer(0),
  pages: integer(0),
  pageSize: integer(0),
  total: integer(0),
  loading: false,
};

const Dict = record('Dict', {
  id: integer,
  title: string,
});

const Lots = record('Lots', {
  content: listOf(Artwork),
  ...meta,
});

export const AlertItem = record('AlertItem', {
  title: string,
  artist: Artist,
  lots: Lots(),
});

const Filter = record('Filter', {
  widthTo: decimal,
  heightTo: decimal,
  depthTo: decimal,
  yearCreatedTo: integer,
  artistBornYearTo: integer,
  artists: listOf(integer),
  sizeUnits: string,
  widthFrom: decimal,
  heightFrom: decimal,
  depthFrom: decimal,
  artistResidenceCountries: listOf(Dict),
  yearCreatedFrom: integer,
  artistBornYearFrom: integer,
  artTitles: listOf(string),
  categories: listOf(Dict),
  surfaces: listOf(Dict),
  substrates: listOf(Dict),
  signature: integer,
});

const AlertDetail = record('AlertDetail', {
  id: integer,
  title: string,
  createdAt: string,
  filter: Filter,
  lots: Lots(),
  artist: Artist,
});

/**
 * State
**/
const State = record('Alerts', {
  list: listOf(AlertItem),
  listPagination: Pagination.parse({}),
  alertDetail: AlertDetail(),
  seeAllPagination: Pagination.parse({}),
  selected: setOf(integer),
  subitems: integer(0),
  artistInAlert: LOADING,
});

/**
 * selectors
**/
const base = field('alerts');

export const alertsList = base.then(State.$list);
export const alertsDetailSelector = base.then(State.$alertDetail);
export const selected = base.then(State.$selected);
export const getAlertLotsSelector = alertsDetailSelector.then(alertDetail => alertDetail ? alertDetail.getIn(['lots', 'content'], I.List()) : I.List());
export const currentPageArtworksSelector = getAlertLotsSelector.then(lots =>
  lots.reduce((prev, l) => prev.set(l.get('id'), l), I.Map()),
);
export const listPagination = base.then(State.$listPagination);
export const listPaginationSelector = listPagination;
export const seeAllPagination = base.then(State.$seeAllPagination);
export const seeAllPaginationSelector = seeAllPagination;
export const totalLots = base.then(State.$subitems);
export const artistInAlertSel = base.then(State.$artistInAlert);
export const selectedArtworksSel = selector(
  selected,
  currentPageArtworksSelector,
  (ids, lots) => lots.filter(v => ids.includes(v.id)),
);

/**
 * reducer
**/
export const reducer = {
  alerts(state = new State(), action) { //NOSONAR
    switch (action.type) {

      case createConsiderationAction.success:
        return state.update(
          State.$alertDetail.update(AlertDetail.$lots.update(Lots.$content.update(
            list => {
              if (!list) return list;
              // @note we use w.title here, because id of considered artwork differ then id of current AO
              return list.map(w => w.title !== action.payload.title ? w : w.update(
                Artwork.$isConsideration.set(true),
              ));
            },
          ))),
        );
      case orderRtvAction.success:
        return state.apply(
          State.$alertDetail.update(AlertDetail.$lots.update(Lots.$content.update(
            list => {
              if (!list) return list;
              return list.map(w => w.id !== action.payload.artworkId ? w : w.apply(
                Artwork.$rtvPrice.parsed(action.payload.rtvPrice),
                Artwork.$rtvCurrency.parsed(action.payload.rtvCurrency),
                Artwork.$rtvStatus.parsed(action.payload.artwork.rtvStatus),
                Artwork.$purchasedDate.parsed(action.payload.purchasedDate),
              ));
            },
          ))),
          State.$list.update(list => list.map(AlertItem.$lots.update(Lots.$content.update(lots => lots.map(
            w => w.id !== action.payload.artwork.id ? w : w.apply(
              Artwork.$rtvPrice.parsed(action.payload.rtvPrice),
              Artwork.$rtvCurrency.parsed(action.payload.rtvCurrency),
              Artwork.$rtvStatus.parsed(action.payload.artwork.rtvStatus),
              Artwork.$purchasedDate.parsed(action.payload.purchasedDate),
            ),
          ))))),
        );

      case env.logOut.type:
        return new State();

      case A.alertListAppendPageAction.failure:
      case A.fetchAlertsAction.failure:
        return state.apply(
          State.$listPagination.update(v => v ? v.set('loading', false) : v),
        );
      case A.alertListAppendPageAction.request:
      case A.fetchAlertsAction.request:
        return state.apply(
          State.$listPagination.update(v => v.set('loading', true)),
        );
      case A.fetchAlertsAction.success:
        return state.apply(
          State.$list.parsed(action.payload.content),
          State.$listPagination.parsed(action.payload),
          State.$subitems.parsed(action.payload.subitems),
        );
      case A.alertListAppendPageAction.success:
        return state.apply(
          State.$list.parsedBy('concat', action.payload.content),
          State.$listPagination.parsed(action.payload),
          State.$subitems.parsed(action.payload.subitems),
        );

      case A.alertItemsAppendPageAction.failure:
      case A.fetchAlertItem.failure:
        return state.apply(
          State.$seeAllPagination.update(v => v ? v.set('loading', false) : v),
        );
      case A.alertItemsAppendPageAction.request:
      case A.fetchAlertItem.request:
        return state.apply(
          State.$seeAllPagination.update(v => v.set('loading', true)),
        );
      case A.alertItemsAppendPageAction.success:
        return state.apply(
          State.$alertDetail.update(alertDetail => {
            const oldList = alertDetail.getIn(['lots', 'content']) || Lots.$content.type.parse([]);
            const payload = {
              ...action.payload,
              lots: {
                ...(action.payload.lots || {}),
                content: [...oldList.toJS(), ...((action.payload.lots || {}).content || [])],
              },
            };
            return AlertDetail.parse(payload);
          }),
          State.$seeAllPagination.parsed(action.payload.lots),
        );
      case A.fetchAlertItem.success:
        return state.apply(
          State.$alertDetail.parsed(action.payload),
          State.$seeAllPagination.parsed(action.payload.lots),
        );

      case A.setSelectedAction.type:
        return state.apply(
          State.$selected.set(action.payload),
        );
      case A.clearSelectedAction.type:
        return state.apply(
          State.$selected.set(I.Set()),
        );
      case removeAction.success:
        return onRemove(state, action.payload);

      case A.createByArtistIdAction.request:
        return state.update(State.$artistInAlert.set(SUBMITTING));

      case A.createByArtistIdAction.success:
        return state.update(State.$artistInAlert.set(TRUE));

      case A.createByArtistIdAction.failure:
        return state.update(State.$artistInAlert.set(FALSE));

      case restoreAction.success:
        return onRestore(state, action.payload);

      case A.artistAddedToAlertNeedRecheckAction.type:
      case A.searchAlertByArtistIdAction.request:
        return state.update(State.$artistInAlert.set(LOADING));

      case A.searchAlertByArtistIdAction.success:
        return state.update(State.$artistInAlert.set(action.payload.alertExist ? TRUE : FALSE));

      case A.searchAlertByArtistIdAction.failure:
        return state.update(State.$artistInAlert.set(FALSE));

      default:
        return state;
    }
  },
};
