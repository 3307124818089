/**
 * cut fraction part in float numbers to necessary length
**/
export const limitFractionLength = length => (value, prevValue = '') => {
  const floatVal = parseFloat(value);
  if (/[^0-9,.]+/.test(value)) return prevValue;
  if (isNaN(floatVal)) return value;
  const m = value.toString().match(/^([\d\s]+)([,.])([\d\s]+)$/);
  if (!m) return value;
  // remove strOrigin from [strOrigin, intPart, delimiter, fraction];
  const [intPart, delimiter, fraction] = m.slice(1, 4);
  return [intPart, delimiter, (fraction + '').substr(0, length)].join('');
};

// price
export const normalize = (v/*, prev = ''*/) => {
  // return /[^0-9,]+/.test(v) ? prev : v.replace(/\D/g, '');
  return (v + '').replace(/\D/g, '');
};
export const normalizeDecimal = (v, prev = '') => {
  return /[^0-9,.]+/.test(v) ? prev : v.replace(/[^0-9.]/g, '');
};
// price field
export const formatPrice = (v, fieldName) => `${v}` === '0' ? 'Unsold' : formatEditable(v, fieldName);
export const formatPriceDecimal = (v, fieldName) => `${v}` === '0' ? 'Unsold' : formatEditableDecimal(v, fieldName);


export const formatEditable = (v = '') => v ? new Intl.NumberFormat('en-EN').format(v) : v;
export const formatEditableDecimal = (v = '') => {
  if (!v) return v;
  let suffix = '';
  if (/\.0*$/.test(v)) suffix = v.replace(/.*(\.0*$)/, '$1');
  const formatter = new Intl.NumberFormat('en-EN', { style: 'decimal', maximumSignificantDigits: 9 });
  return formatter.format(+(+v || 0).toFixed(3)) + suffix;
};
