export default {
  DraftLabel: {
    position: 'absolute',
    top: 10,
    width: 70,
    height: 24,
    backgroundColor: '#9d9fa1',
    opacity: 0.7,
    left: -10,
    zIndex: 5,
    color: '#ffffff',
    fontSize: 12,
    lineHeight: '24px',
    textAlign: 'center',
    fontWeight: 700,
  },
};
