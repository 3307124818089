import { popupComponents } from 'pages/common/popup/sheet';

export default {
  ...popupComponents,
  container: {
    ...popupComponents.container,
    width: 440,
    padding: [45, 40, 20],
  },
};
