import React from 'react';
import PropTypes from 'prop-types';
import { getEmptyImage } from 'react-dnd-html5-backend';
import cx from 'classnames';

export function draggableComponent(WrappedComponent) {
  class DraggableComponent extends React.Component {
    static propTypes = {
      connectDragSource: PropTypes.func.isRequired,
      isDragging: PropTypes.bool.isRequired,
      connectDragPreview: PropTypes.func.isRequired,
      classes: PropTypes.shape({
        DraggableComponent: PropTypes.string.isRequired,
        drag: PropTypes.string.isRequired,
      }),
    };

    componentDidMount() {
      const { connectDragPreview } = this.props;
      connectDragPreview(getEmptyImage(), { captureDraggingState: true });
    }

    render() {
      const { isDragging, connectDragSource, classes, ...props } = this.props;
      return connectDragSource(
        <div className={cx(classes.DraggableComponent, { [classes.drag]: isDragging })}>
          <WrappedComponent {...props} />
        </div>,
      );
    }
  }
  return DraggableComponent;
}

export function droppableComponent(WrappedComponent) {
  class DroppableComponent extends React.Component {
    static propTypes = {
      connectDropTarget: PropTypes.func.isRequired,
      isOver: PropTypes.bool.isRequired,
      canDrop: PropTypes.bool.isRequired,
      classes: PropTypes.shape({
        DroppableComponent: PropTypes.string.isRequired,
        dragOver: PropTypes.string.isRequired,
        canDrop: PropTypes.string.isRequired,
      }),
    };

    render() {
      const { connectDropTarget, canDrop, isOver, classes, ...props } = this.props;
      return connectDropTarget(
        <div className={cx(
          classes.DroppableComponent,
          {
            [classes.dragOver]: isOver && canDrop,
            [classes.canDrop]: !isOver && canDrop,
          },
        )}>
          <WrappedComponent {...props} />
        </div>,
      );
    }
  }
  return DroppableComponent;
}
