import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ReactReduxContext } from 'react-redux';
// import { connect } from 'cpcs-reconnect';
import { isAuthorized as isAuthorized_sel } from 'domain/env';
import { checkAuthAction } from 'domain/env/EnvActions';
import { getStorageItem } from 'lib/storage';
import { dispatchPromise } from 'lib/helpers';

const style = {
  display: 'flex',
  justifyContent: 'space-around',
  alignItems: 'center',
  height: '100vh',
  width: '100%',
};

export default function checkAuthenticated(WrappedComponent) {
  class AuthenticatedRoute extends React.Component {
    static propTypes = {
    //   checkAuth: PropTypes.func.isRequired,
    //   isAuthorized: PropTypes.bool,
      store: PropTypes.object,
    };
    // static contextTypes = {
    //   store: PropTypes.object,
    // }

    state = {
      loading: true,
    }

    shouldComponentUpdate() {
      return true;
    }

    async componentDidMount() {
      // const { store: { dispatch, getState } } = this.context;
      const { store: { dispatch, getState } } = this.props;
      const isAuthorized = isAuthorized_sel(getState());
      const checkAuth = (...args) => dispatch(checkAuthAction(...args));
      // const { isAuthorized, checkAuth } = this.props;
      const token = getStorageItem('token');
      try {
        if (!isAuthorized && token) {
          await dispatchPromise(checkAuth);
          this.setState({ loading: false });
        } else {
          this.setState({ loading: false });
        }
      } catch (err) {
        if (err && err.unblock) {
          this.setState({ loading: false });
        }
        console.error(err);
      }
    }

    render() {
      return !this.state.loading ? (
        <WrappedComponent {...this.props} />
      ) : (
        <span style={style}>Loading...</span>
      );
    }
  }

  return function WithStore(props) {
    const { store } = useContext(ReactReduxContext);
    return <AuthenticatedRoute {...props} store={store} />;
  };
  // return AuthenticatedRoute;
  // return connect({
  //   checkAuth: checkAuthAction,
  //   isAuthorized: isAuthorized_sel,
  // })(AuthenticatedRoute);
}
