import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'cpcs-reconnect';
import { compose } from 'redux';
import I from 'immutable';
import cx from 'classnames';
import CardForm from 'pages/billingPage/form/cardForm';
import {
  buyRtvPopupOpenedSelector,
  buyRtvPopupArtworkSelector,
  submittingSelector,
  gettingFreeSelector,
  selectedProduct_sel,
} from 'pages/common/buyRtv/buyRtvModel';
import {
  validatedCouponSelector,
  couponFormOpenedSelector,
  couponInValidationSelector,
  priceSelector,
  // percentOffSelector,
  // amountOffSelector,
} from 'pages/common/couponForm/couponFormModel';
import { removeCheckedCouponAction } from 'pages/common/couponForm/couponFormActions';
import { isFormValidSel, stripeCardToken_sel } from 'pages/billingPage/form/cardFormModel';
import { resetCardFormAction } from 'pages/billingPage/form/cardFormActions';
import {
  hideRtvFormAction,
  orderFreeRtvAction,
  buyRtvFormSubmitAction,
  setSelectedProductNameAction,
} from 'pages/common/buyRtv/buyRtvActions';
import { fullName } from 'domain/artist/helpers';
import {
  user_sel,
  subscriptionTypeSelector,
  currentPlanSel,
} from 'domain/env/EnvModel';
import {
  SUBSCRIPTION_TYPE_ENTERPRISE,
  SINGLE_RTV,
  SUBSCRIPTION_TYPE_PROFESSIONAL,
} from 'domain/const';
import { USAIdSelector } from 'domain/country/CountryModel';
import BillingForm from 'pages/common/buyRtv/billingForm';
import { CouponFormBuyRtv } from 'pages/common/couponForm';
import { setPropTypes } from 'lib/helpers';
import { extraFooterGap_sel } from 'domain/ui/UIModel';
import { planTypesSel, planTypesLoading_sel } from 'pages/upgradeSubscriptionPage/upgradeSubscriptionModel';
import { SingleRtvProduct } from 'pages/common/buyRtv/productOption';
import translate from 'lib/translate';

import injectSheet from 'lib/sheet';
import sheet from './sheet';

const ArtworkTitle = compose(
  injectSheet(sheet),
  setPropTypes({
    classes: PropTypes.shape({
      ArtworkTitle: PropTypes.string.isRequired,
      artistName: PropTypes.string.isRequired,
      artworkTitle: PropTypes.string.isRequired,
    }).isRequired,
    title: PropTypes.node,
    fullName: PropTypes.node,
  }),
)(({ classes, title, fullName }) => {
  if (!title && !fullName) return null;
  return (
    <div className={classes.ArtworkTitle}>
      <span
        className={classes.artistName}
        children={fullName}
      />
      {(title && fullName) && ' - '}
      <span
        className={classes.artworkTitle}
        children={title}
      />
    </div>
  );
});

class BuyRtv extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.shape({
      BuyRtv: PropTypes.string.isRequired,
      overlay: PropTypes.string.isRequired,
      sidebar: PropTypes.string.isRequired,
      header: PropTypes.string.isRequired,
      backToPlans: PropTypes.string.isRequired,
      closeBtn: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired,
      titleWrap: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      titleVal: PropTypes.string.isRequired,
      productSelectionWrapper: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      btnConfirmFreeRTV: PropTypes.string.isRequired,
      rtvLeft: PropTypes.string.isRequired,
      loadingOverlay: PropTypes.string.isRequired,
      loadingTxt: PropTypes.string.isRequired,
      spinner: PropTypes.string.isRequired,
    }).isRequired,
    stripeCardToken: PropTypes.string,
    isVisible: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    user: PropTypes.instanceOf(I.Record).isRequired,
    USA_ID: PropTypes.number,
    artwork: PropTypes.instanceOf(I.Record),
    submitInProgress: PropTypes.bool,
    couponFormOpened: PropTypes.bool,
    couponInValidation: PropTypes.bool,
    validatedCoupon: PropTypes.string,
    couponPrice: PropTypes.number,
    subscriptionType: PropTypes.string,
    orderFreeRtv: PropTypes.func.isRequired,
    getFreeSubmitInProgress: PropTypes.bool,
    onBuyRtvFormSubmit: PropTypes.func.isRequired,
    subscription: PropTypes.instanceOf(I.Collection),
    cardFormValid: PropTypes.bool,
    resetCardForm: PropTypes.func.isRequired,
    extraFooterGap: PropTypes.bool,
    // React.Component or function
    CouponFormBuyRtv: PropTypes.any,
    // React.Component or function
    CardForm: PropTypes.any,
    // React.Component or function
    BillingForm: PropTypes.any,
    planTypes: PropTypes.instanceOf(I.Collection),
    clearCoupon: PropTypes.func.isRequired,
    // percentOff: PropTypes.number,
    // amountOff: PropTypes.number,
    setSelectedProductName: PropTypes.func.isRequired,
    planTypesLoading: PropTypes.bool,
    /**
     * one of
     * SINGLE_RTV or SUBSCRIPTION_TYPE_...
    **/
    selectedProductName: PropTypes.string,
  };

  static defaultProps = {
    CouponFormBuyRtv,
    CardForm,
    BillingForm,
  };

  state = {};

  static getDerivedStateFromProps(props) {
    const { subscription, selectedProductName, setSelectedProductName } = props;
    if (!subscription) return null;
    const isProfi = subscription.get('shortName') === SUBSCRIPTION_TYPE_ENTERPRISE ||
      subscription.get('shortName') === SUBSCRIPTION_TYPE_PROFESSIONAL;
    if (!isProfi) return null;
    if (selectedProductName) return null;
    setSelectedProductName(SINGLE_RTV);
    return null;
  }

  get title() {
    const { selectedProductName } = this.props;
    const product = this.searchProduct(selectedProductName);
    if (!product || this.hasFreeRtvs) {
      return translate('rtvSidebar.title.SINGLE_RTV');
    }
    return translate('rtvSidebar.title.' + product.get('shortName'));
  }

  get productPrice() {
    const { couponFormOpened, validatedCoupon } = this.props;
    const { couponPrice/*, percentOff, amountOff*/ } = this.props;
    let productPrice;
    const { selectedProductName, subscription } = this.props;
    const product = this.searchProduct(selectedProductName);
    if (this.hasFreeRtvs || !product) return null;
    const rtvSelected = !product || product.get('shortName') === SINGLE_RTV;
    if (rtvSelected) {
      if (!couponPrice && couponFormOpened && validatedCoupon) {
        return 'FREE';
      }
      if (couponPrice) {
        productPrice = couponPrice;
      } else {
        productPrice = subscription && subscription.get('rtvDefaultValue') * 100;
      }
      // productPrice = Math.round(productPrice / 100);
      productPrice = Math.round(productPrice);
    } else {
      // productPrice = product.get('subscriptionValue') * 100;
      // if (percentOff) {
      //   productPrice = productPrice - (productPrice / 100) * percentOff;
      // } else if (amountOff) {
      //   productPrice = productPrice - amountOff;
      // }
      productPrice = product.get('subscriptionProratedValue') * 100;
    }
    productPrice = Math.max(0, productPrice);
    const newPrice = new Intl
      .NumberFormat('en-US', { style: 'decimal', maximumSignificantDigits: 9 })
      .format(productPrice / 100);
    return `$${newPrice}`;
  }

  get usedFreeCoupon() {
    const { couponFormOpened, validatedCoupon, couponPrice, couponInValidation } = this.props;
    return couponFormOpened && validatedCoupon && !couponPrice && !couponInValidation;
  }

  get hasFreeRtvs() {
    const { subscriptionType, user } = this.props;
    return subscriptionType === SUBSCRIPTION_TYPE_ENTERPRISE || !!user.freeRtvPurchaseCount;
  }

  onConfirmFreeRtv = () => {
    const { orderFreeRtv, artwork } = this.props;
    orderFreeRtv({ artwork });
  };

  onClose = () => {
    const { onClose, resetCardForm, setSelectedProductName } = this.props;
    resetCardForm();
    setSelectedProductName(null);
    onClose();
  }

  onBuyRtvFormSubmit = (payload) => {
    const { onBuyRtvFormSubmit } = this.props;
    let { selectedProductName } = this.props;
    let product = this.searchProduct(selectedProductName);
    if (!product || product.get('shortName') === SINGLE_RTV) {
      product = undefined;
    }
    onBuyRtvFormSubmit({ ...payload, product });
  };

  searchProduct = shortName => {
    const { planTypes } = this.props;
    if (shortName === SINGLE_RTV) {
      return SingleRtvProduct;
    }
    if (!planTypes) return null;
    return planTypes.find(v => v.get('shortName') === shortName);
  };

  selectProductName = (shortName) => {
    if (this.props.selectedProductName) {
      this.props.clearCoupon();
    }
    this.props.setSelectedProductName(shortName || '');
  };

  get artworkTitleVisible() {
    const { selectedProductName } = this.props;
    return this.hasFreeRtvs || (selectedProductName === SINGLE_RTV);
  }

  render() {
    const {
      classes, isVisible, artwork, USA_ID, user,
      submitInProgress, couponInValidation, stripeCardToken,
      // [#rtvDiscount]
      getFreeSubmitInProgress,
      subscription, subscriptionType,
      extraFooterGap,
      selectedProductName,
      planTypesLoading,
      // components
      CouponFormBuyRtv, CardForm, BillingForm,
    } = this.props;
    if (!isVisible || !artwork || !user || !subscription) return null;
    const billingFormInitialValues = {
      email: user.email,
      code: user.code,
      country: user.country || USA_ID,
      saveCard: true,
    };
    const { productPrice } = this;
    const valuation = subscription.freeRtvCountIncluded === 1 ? 'valuation' : 'valuations';
    const isProfi = subscription.get('shortName') === SUBSCRIPTION_TYPE_ENTERPRISE ||
      subscription.get('shortName') === SUBSCRIPTION_TYPE_PROFESSIONAL;
    const selectedProduct = this.searchProduct(selectedProductName);
    return (
      <div
        className={classes.BuyRtv}
      >
        <div className={classes.overlay} onClick={this.onClose} />
        <div className={cx(classes.sidebar, { extraFooterGap })}>
          <div className={classes.header}>
            {
              !this.hasFreeRtvs && selectedProduct && !isProfi &&
                <button
                  className={classes.backToPlans}
                  children="Back to Plans"
                  type="button"
                  onClick={() => this.selectProductName()}
                  value=""
                />
            }
            <button
              type="button"
              className={classes.closeBtn}
              onClick={this.onClose}
            />
          </div>
          <div className={classes.content}>
            <div className={classes.titleWrap}>
              <span
                className={classes.title}
                children={this.title}
              />
              {
                productPrice &&
                  <span
                    className={classes.titleVal}
                    children={productPrice}
                  />
              }
            </div>
            {
              this.artworkTitleVisible &&
                <ArtworkTitle
                  title={artwork && artwork.title}
                  fullName={fullName(artwork.artist)}
                />
            }
            {
              !this.hasFreeRtvs && !selectedProduct &&
                <div className={classes.productSelectionWrapper}>
                  <p>ARTBnk Value not available at the moment</p>
                </div>
            }
            {
              !this.hasFreeRtvs && selectedProduct &&
                <CouponFormBuyRtv
                  useGetFreeButton={!selectedProduct || selectedProduct.get('shortName') === SINGLE_RTV}
                  product={selectedProduct}
                />
            }
            {
              !this.hasFreeRtvs && !this.usedFreeCoupon && selectedProduct &&
                <CardForm
                  title="Card info"
                  titleModifier="rtvForm"
                  changeCardBtnModifier="buyForm"
                  autoSubmit
                  hideActionButtons
                />
            }
            {
              !this.hasFreeRtvs && !this.usedFreeCoupon && selectedProduct &&
                <BillingForm
                  initialValues={billingFormInitialValues}
                  stripeCardToken={stripeCardToken}
                  cardFormValid={this.props.cardFormValid}
                  submitInProgress={submitInProgress}
                  onFormSubmit={this.onBuyRtvFormSubmit}
                  titleModifier="rtvForm"
                />
            }
            {
              this.hasFreeRtvs &&
                <button
                  type="button"
                  children="Confirm"
                  className={classes.btnConfirmFreeRTV}
                  onClick={this.onConfirmFreeRtv}
                />
            }
            {
              this.hasFreeRtvs && subscriptionType !== SUBSCRIPTION_TYPE_ENTERPRISE &&
                <div
                  className={classes.rtvLeft}
                  children={`You have used ${Math.max(0, subscription.freeRtvCountIncluded - user.freeRtvPurchaseCount)} of ${subscription.freeRtvCountIncluded} ${valuation} in your plan`}
                />
            }
          </div>
          {
            (couponInValidation || planTypesLoading) &&
              <div className={classes.loadingOverlay}>
                <div children={couponInValidation ? 'Checking...' : 'Loading...'} className={classes.loadingTxt} />
                <div className={classes.spinner} />
              </div>
          }
          {
            getFreeSubmitInProgress &&
              <div className={classes.loadingOverlay}>
                <div className={classes.spinner} />
              </div>
          }
        </div>
      </div>
    );
  }
}

export const PureBuyRtv = BuyRtv;

export default compose(
  injectSheet(sheet),
  connect({
    isVisible: buyRtvPopupOpenedSelector,
    artwork: buyRtvPopupArtworkSelector,
    onClose: hideRtvFormAction,
    user: user_sel,
    USA_ID: USAIdSelector,
    submitInProgress: submittingSelector,
    couponFormOpened: couponFormOpenedSelector,
    validatedCoupon: validatedCouponSelector,
    couponInValidation: couponInValidationSelector,
    planTypesLoading: planTypesLoading_sel,
    couponPrice: priceSelector,
    // percentOff: percentOffSelector,
    // amountOff: amountOffSelector,
    subscriptionType: subscriptionTypeSelector,
    orderFreeRtv: orderFreeRtvAction,
    getFreeSubmitInProgress: gettingFreeSelector,
    onBuyRtvFormSubmit: buyRtvFormSubmitAction,
    subscription: currentPlanSel,
    cardFormValid: isFormValidSel,
    resetCardForm: resetCardFormAction,
    extraFooterGap: extraFooterGap_sel,
    planTypes: planTypesSel,
    clearCoupon: removeCheckedCouponAction,
    setSelectedProductName: setSelectedProductNameAction,
    selectedProductName: selectedProduct_sel,
    stripeCardToken: stripeCardToken_sel,
  }),
)(BuyRtv);
