import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ORANGE_COLOR } from 'theme/theme';
import { lnk } from 'lib/routes';

import injectSheet from 'lib/sheet';

export const sheet = {
  EmailAlreadyInUse: {
    whiteSpace: 'normal',
  },
  link: {
    color: ORANGE_COLOR,
  },
};

function EmailAlreadyInUse({ classes }) {
  return (
    <div className={classes.EmailAlreadyInUse}>
      {'This email is already registered. '}
      <Link
        className={classes.link}
        to={lnk('auth')}
        children="Log in"
      />
      {' or '}
      <Link
        className={classes.link}
        to={lnk('forgot')}
        children="Recover password"
      />
    </div>
  );
}

EmailAlreadyInUse.propTypes = {
  classes: PropTypes.shape({
    EmailAlreadyInUse: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
  }),
};
export const EmailAlreadyInUseMessage = injectSheet(sheet)(EmailAlreadyInUse);
