import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Field } from 'redux-form';
import { getId } from 'pages/common/newPage/form/utils';
import { APP_FORMAT } from 'lib/helpers';

import injectSheet from 'lib/sheet';
import sheet from './sheet';

class Life extends React.PureComponent {

  static propTypes = {
    classes: PropTypes.shape({
      formItem: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      fieldWrapper: PropTypes.string.isRequired,
      field: PropTypes.string.isRequired,
    }).isRequired,
    modifier: PropTypes.string,
    disabled: PropTypes.bool,
  }

  render() {
    const { classes, disabled, modifier } = this.props;
    const name = 'artist-life';
    const id = getId(name);
    return (
      <li className={cx(classes.formItem, modifier)}>
        <label
          htmlFor={id}
          className={classes.label}
        >Artist Life Dates</label>
        <div className={cx(classes.fieldWrapper, modifier, 'artistLifeDates')}>
          <Field
            type="text"
            id={id}
            name="bornIn"
            component="input"
            className={cx(classes.field, modifier)}
            placeholder={APP_FORMAT}
            disabled={disabled}
          />
          <Field
            type="text"
            name="diedIn"
            component="input"
            className={cx(classes.field, modifier, 'artistDiedIn')}
            placeholder={APP_FORMAT}
            disabled={disabled}
          />
        </div>
      </li>
    );
  }
}


export default injectSheet(sheet)(Life);
