import record, { listOf, integer } from 'cpcs-recordjs';
import { field, selector } from 'cpcs-reconnect';
import {
  setSelectedPlanTypeAction,
  fetchPlanTypesAction,
  upgradePlanAction,
} from 'pages/upgradeSubscriptionPage/upgradeSubscriptionActions';
import { logOut as logOutAction } from 'domain/env/EnvActions';
import { Plan } from 'model';
const State = record('upgradeSubscriptionPage', {
  selectedPlanType: '',
  planTypes: listOf(Plan),
  planTypesTimestamp: integer(0),
  planTypesLoading: false,
  submitInProgress: false,
});

const base = field('upgradeSubscriptionPage');
export const selectedPlanTypeSelector = base.then(State.$selectedPlanType);
export const planTypesSel = base.then(State.$planTypes);
export const planTypesLoading_sel = base.then(State.$planTypesLoading);
export const planTypesTimestamp_sel = base.then(State.$planTypesTimestamp);
export const selectedSubscriptionSel = selector(selectedPlanTypeSelector, planTypesSel, (name, list) => list.find(v => v.shortName === name));
export const submitInProgressSel = base.then(State.$submitInProgress);

export const reducer = {
  upgradeSubscriptionPage(state = new State(), action) {
    switch (action.type) {
      case upgradePlanAction.request:
        return state.apply(
          State.$submitInProgress.parsed(true),
        );
      case upgradePlanAction.success:
      case upgradePlanAction.failure:
        return state.apply(
          State.$submitInProgress.parsed(false),
        );
      case setSelectedPlanTypeAction.type:
        return state.apply(
          State.$selectedPlanType.parsed(action.payload),
        );
      case '@@router/LOCATION_CHANGE':
        return state.apply(
          State.$selectedPlanType.parsed(''),
          State.$planTypesLoading.parsed(false),
        );
      case fetchPlanTypesAction.request:
        return state.update(State.$planTypesLoading.set(true));
      case fetchPlanTypesAction.success:
        return state.apply(
          State.$planTypes.parsed(action.payload),
          State.$planTypesTimestamp.parsed(action.timestamp),
          State.$planTypesLoading.parsed(false),
        );
      case fetchPlanTypesAction.failure:
        return state.update(State.$planTypesLoading.set(false));
      case logOutAction.type:
        return state.apply(
          State.$planTypes.parsed([]),
          State.$planTypesTimestamp.parsed(0),
          State.$planTypesLoading.parsed(false),
        );
      default:
        return state;
    }
  },
};
