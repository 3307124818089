import {
  solver,
  dict,
  dictList,
  separator,
} from 'pages/common/property/solvers';
import artistSolver from 'pages/common/artworkView/solvers/artistSolver';
import auctionSolver from 'pages/common/artworkView/solvers/auctionSolver';
import lotNumberSolver from 'pages/common/artworkView/solvers/lotNumberSolver';
import saleNumberSolver from 'pages/common/artworkView/solvers/saleNumberSolver';
import estimateSolver from 'pages/common/artworkView/solvers/estimateSolver';
import soldPriceSolver from 'pages/common/artworkView/solvers/soldPriceSolver';
import saleAtSolver from 'pages/common/artworkView/solvers/saleAtSolver';
import createdAtYearSolver from 'pages/common/artworkView/solvers/createdAtYearSolver';
import provenanceSolver from 'pages/common/artworkView/solvers/provenanceSolver';
import salesHistorySolver, { upcomingSaleSolver } from 'pages/common/artworkView/solvers/salesHistorySolver';
import notesSolver from 'pages/common/artworkView/solvers/notesSolver';
import editionSolver from 'pages/common/artworkView/solvers/editionSolver';
import countriesListSolver from 'pages/common/artworkView/solvers/countriesListSolver';
import sheetSizeSolver from 'pages/common/artworkView/solvers/sheetSizeSolver';
import stampsSolver from 'pages/common/artworkView/solvers/stampsSolver';
import authenticityLetterSolver from 'pages/common/artworkView/solvers/authenticityLetterSolver';
import rtvSolver from 'pages/common/artworkView/solvers/rtvSolver';
import appraisalSolver from 'pages/common/artworkView/solvers/appraisalSolver';
import { sizeSolver } from 'pages/common/artworkView/solvers/sizeSolver';


import { serializerAddSelect, dateStructToString } from 'lib/helpers';

/**
 * each solver: (value, data, name, props) => ...
 *
 * template of this field described in
 * https://artbnk.myjetbrains.com/youtrack/issue/MVP-288
**/

export const serializer = [
  { name: 'title', title: 'Title', solver, modifier: 'titleSolver' },
  { name: 'seller', title: 'Auction House', solver: auctionSolver, modifier: 'sellerSolver' },
  { name: 'itemListSoldPrice', title: 'Sold', solver: soldPriceSolver, modifier: 'soldPriceSolver' },
  { name: 'itemListEstimation', title: 'Est', solver: estimateSolver, modifier: 'estimateSolver' },
  // group 1
  { name: 'rtv', title: 'ARTBnk Value', solver: rtvSolver, classNames: ['veryStrong'], modifier: 'rtvSolver' },
  { name: 'priceSold', title: 'Sold Price', solver: soldPriceSolver, classNames: ['strong'] },
  { name: 'estimatedPrice', title: 'Estimate', solver: estimateSolver, classNames: ['strong'] },
  { name: 'lot', title: 'Auction House', solver: auctionSolver, classNames: ['strong'] },
  { name: 'saleAt', title: 'Sale Date', solver: saleAtSolver, classNames: ['strong'], modifier: 'dateOfSaleSolver' },
  { name: 'lotNumber', title: 'Lot', solver: lotNumberSolver, classNames: ['strong'], modifier: 'lotNumberSolver' },
  { name: 'saleNumber', title: 'Sale #', solver: saleNumberSolver, classNames: ['strong'], modifier: 'saleNumberSolver' },
  { name: 'userPrice', title: 'Sales / Appraisal Details', solver: appraisalSolver },
  { name: 'separator#1', solver: separator, modifier: 'separator' },
  // Art + Artist
  { name: 'artist', title: 'Artist Name', solver: artistSolver, modifier: 'artistSolver' },
  { name: 'residence', title: 'Associated Country', solver: countriesListSolver },
  { name: 'category', title: 'Category', solver: dict },
  { name: 'createdAt.Year', title: 'Year Created', solver: createdAtYearSolver },
  { name: 'createdAt', title: 'Year Created', solver: dateStructToString },
  { name: 'conceptionYear', title: 'Conception Date', solver },
  { name: 'edition', title: 'Edition', solver: editionSolver },
  { name: 'separator#2', solver: separator, modifier: 'separator' },
  // group 3
  { name: 'surfaces', title: 'Surface', solver: dictList },
  { name: 'substrates', title: 'Substrate', solver: dictList },
  { name: 'mediums', title: 'Medium', solver: dictList },
  { name: 'signature', title: 'Signature', solver: dict },
  { name: 'height', title: 'Size', solver: sizeSolver, modifier: 'sizeSolver' },
  { name: 'sheetSize', title: 'Sheet Size', solver: sheetSizeSolver },
  // Details
  { name: 'plate', title: 'Plate', solver },
  { name: 'stamps', title: 'Stamps', solver: stampsSolver },
  { name: 'foundry', title: 'Foundry', solver },
  { name: 'studio', title: 'Studio', solver },
  { name: 'printer', title: 'Printer', solver },
  { name: 'publisher', title: 'Publisher', solver },
  { name: 'inscription', title: 'Inscription', solver },
  // Provenance & Sales
  { name: 'conditions', title: 'Condition', solver: dictList },
  { name: 'provenance', title: 'Provenance', solver: provenanceSolver },
  { name: 'location', title: 'Location', solver },
  { name: 'salesHistory', title: 'Sales History', solver: salesHistorySolver },
  { name: 'upcomingSale', title: 'Upcoming sale', solver: upcomingSaleSolver },
  // Exhibitions & Literature
  { name: 'catalogRaisonne', title: 'Catalogue Raisonné', solver },
  { name: 'exhibition', title: 'Exhibitions', solver },
  { name: 'literature', title: 'Literature', solver },
  { name: 'authenticityLetter', title: 'Authenticity Letter', solver: authenticityLetterSolver },
  { name: 'notes', title: 'Notes', solver: notesSolver },
];

serializerAddSelect(serializer);

/**
 *      plate, stamps, foundry, studio, printer, publisher, inscription
 * 2DE    *      *                         *         *           *
 * 3D                     *        *                             *
 * 3DE           *        *        *                             *
**/
const details = {
  '2D Edition': serializer.select('plate', 'stamps', 'printer', 'publisher', 'inscription'),
  '3D Unique': serializer.select('foundry', 'studio', 'inscription'),
  '3D Edition': serializer.select('stamps', 'foundry', 'studio', 'inscription'),
};
export const getDetails = ({ categoryList, category: id }) => {
  if (!id) return null;
  const category = categoryList.find(v => v.get('id') === id);
  if (!category) return null;
  return details[category.get('title')] || null;
};

const serializerGroup1Fields = [
  'rtv', 'priceSold', 'estimatedPrice', 'lot', 'saleAt', 'lotNumber', 'saleNumber', 'userPrice', 'separator#1',
  'artist', 'residence', 'category', 'createdAt.Year', 'conceptionYear', 'edition', 'separator#2',
  'surfaces', 'substrates', 'mediums', 'signature', 'height', 'sheetSize',
];
const serializerGroup1 = serializer.select(...serializerGroup1Fields.filter(v => v !== 'artist'));

const serializerProvenanceFields = ['conditions', 'provenance', 'location', 'salesHistory', 'upcomingSale'];
const serializerProvenance = serializer.select(...serializerProvenanceFields);

const serializerExhibitionFields = ['catalogRaisonne', 'exhibition', 'literature', 'authenticityLetter', 'notes'];
const serializerExhibition = serializer.select(...serializerExhibitionFields);

export const serialGroups = {
  serializerGroup1,
  serializerGroup1Fields,

  serializerProvenance,

  serializerExhibition,
};
