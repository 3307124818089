import {
  LAYOUT_1920_WIDTH,
  LAYOUT_1600_MAX_WIDTH,
  LAYOUT_1600_WIDTH,
  LAYOUT_1024_MAX_WIDTH,
  LAYOUT_1024_WIDTH,
  LAYOUT_768_MAX_WIDTH,
  LAYOUT_768_WIDTH,
  LAYOUT_375_MAX_WIDTH,
  LAYOUT_375_WIDTH,
} from 'theme/layout/responsiveList';

/**
 * these ranges used for non list pages only
**/
// 1024: new 1099 old 1059
export const LAYOUT_RANGE_1024_MAX = 1099; // 1059;
// 1024 (min): new 872 old 805
export const LAYOUT_RANGE_1024_MIN = 872; // 805;
// 768: new 871 old 804
export const LAYOUT_RANGE_768_MAX = 871; // 804;
// 425: new 578 old 556
export const LAYOUT_RANGE_425_MAX = 578; // 556;
// 375: new 423 old null
export const LAYOUT_RANGE_375_MAX = 423; // null;

/**
 * v1 (old):
 *
 * LAYOUT -|- SIDEBAR
 *         |- PAGE_CONTAINER -|- PAGE_HEADER
 *                            |- PAGE_SECTION -|- PAGE_INNER_COL1
 *                                             |- PAGE_INNER_COL2
 *                            |- PAGE_SECTION
 *                            |- PAGE_SECTION
 *                            |- PAGE_SECTION
 *
 * v2 grid (old):
 *
 * LAYOUT -|- HEADER
 *         |- PAGE_HEADER
 *         |- AdvancedFilter
 *         |- PAGE_CONTAINER
 *                           |- PAGE_SECTION -|- PAGE_INNER_COL1
 *                                            |- PAGE_INNER_COL2
 *                           |- PAGE_SECTION
 *                           |- PAGE_SECTION
 *                           |- PAGE_SECTION
 *         |- FOOTER
 *
 * v3 (current):
 *
 * HEADER
 * LAYOUT -|- PAGE_HEADER
 *         |- PAGE_CONTAINER
 *                           |- SIDEBAR_LAYOUT
 *                                 |- SIDEBAR
 *                                 |- PAGE_SIDE
 *                                         |- PAGE_SECTION -|- PAGE_INNER_COL1
 *                                                          |- PAGE_INNER_COL2
 *                                         |- PAGE_SECTION
 *                                         |- PAGE_SECTION
 *                                         |- PAGE_SECTION
 * FOOTER
**/
export const LAYOUT = {
  width: '100%',
  display: 'block',
  margin: '0 auto',
  // alignItems: 'stretch',
  background: '#FFF',
  minHeight: 'calc(100% - 50px)',
  height: 'auto !important',
  position: 'relative',
  paddingTop: 60,
  justifyContent: 'center',
  '&.homePage, &.collectionItemPage, &.sharedArtwork, &.artworkPage, &.artistReportsPage': {
    width: 1638,
    '@media screen and (max-width: 1760px)': {
      width: 1360,
    },
    '@media screen and (max-width: 1360px)': {
      width: 1082,
    },
    '@media screen and (max-width: 1200px)': {
      width: 804,
    },
    '@media screen and (max-width: 900px)': {
      width: 526,
    },
    '@media screen and (max-width: 610px)': {
      paddingLeft: 15,
      paddingRight: 15,
      width: 'auto',
    },
  },
  '&.sharedItemsPage': {
    width: LAYOUT_1920_WIDTH, // 1360 (1638-278)
    [`@media screen and (max-width: ${LAYOUT_1600_MAX_WIDTH}px)`]: { // 1667 (1638 + 29)
      width: LAYOUT_1600_WIDTH, // 1360
    },
    [`@media screen and (max-width: ${LAYOUT_1024_MAX_WIDTH}px)`]: { // 1389 (1360 + 29)
      width: LAYOUT_1024_WIDTH, // 526 (804-278)
    },
    // fixed advancedFilter position
    [`@media screen and (max-width: ${LAYOUT_768_MAX_WIDTH}px)`]: { // 833 (804 + 29)
      width: LAYOUT_768_WIDTH, // 526
    },
    [`@media screen and (max-width: ${LAYOUT_375_MAX_WIDTH}px)`]: { // 555 (526 + 29)
      width: LAYOUT_375_WIDTH, // 343
    },
    [`@media screen and (max-width: ${LAYOUT_375_WIDTH}px)`]: { // 343
      width: 'calc(100% - 10px)',
    },
  },
  '&.artistPage, &.collectionListPage, &.saleLotsListPage': {
    width: LAYOUT_1920_WIDTH, // 1360 (1638-278)
    '& .SIDEBAR_LAYOUT': {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    '& .SIDEBAR': {
      transition: 'all 500ms ease, opacity 200ms ease 300ms',
      width: 278,
      minWidth: 0,
    },
    '& .PAGE_SIDE': {
      // flex: '1 1 0',
      width: 'calc(100% - 278px)',
      transition: 'all 500ms ease',
    },
    '& .PAGE_HEADER': {
      '&.collectionListPage': {
        paddingTop: 20,
      },
    },
    '&.AFClosed': {
      // gridTemplateColumns: `0 ${LAYOUT_1920_WIDTH}px`, // 1638
      // transition: 'all 500ms ease',
      '& .PAGE_SIDE': {
        width: '100%',
      },
      '& .SIDEBAR': {
        transition: 'all 500ms ease, opacity 200ms',
        width: 0,
        overflow: 'hidden',
        opacity: 0,
      },
    },
    [`@media screen and (max-width: ${LAYOUT_1600_MAX_WIDTH}px)`]: { // 1667 (1638 + 29)
      width: LAYOUT_1600_WIDTH, // 1360
    },
    [`@media screen and (max-width: ${LAYOUT_1024_MAX_WIDTH}px)`]: { // 1389 (1360 + 29)
      width: LAYOUT_1024_WIDTH, // 526 (804-278)
    },
    // fixed advancedFilter position
    [`@media screen and (max-width: ${LAYOUT_768_MAX_WIDTH}px)`]: { // 833 (804 + 29)
      '&.AFDefault, &.AFOpened, &.AFClosed': {
        width: LAYOUT_768_WIDTH, // 526
        '& .PAGE_SIDE': {
          width: '100%',
        },
        '& .SIDEBAR': {
          width: 288,
        },
      },
    },
    [`@media screen and (max-width: ${LAYOUT_375_MAX_WIDTH}px)`]: { // 555 (526 + 29)
      '&.AFDefault, &.AFOpened, &.AFClosed': {
        width: LAYOUT_375_WIDTH, // 343
      },
    },
    [`@media screen and (max-width: ${LAYOUT_375_WIDTH}px)`]: { // 343
      '&.AFDefault, &.AFOpened, &.AFClosed': {
        width: 'calc(100% - 10px)',
      },
    },
  },
  '&.preferencesPage': {
    paddingTop: 0,
    width: 'auto',
    backgroundColor: '#F7F7F7',
  },
  '@media screen and (max-width: 610px)': {
    minHeight: 'calc(100% - 210px)',
    '&.preferencesPage': {
      // gridTemplateRows: '1fr 210px',
    },
  },
  '&.insightPage': {
    display: 'flex',
    alignItems: 'center',
  },
};
