import {
  DARK_BLUE_COLOR,
  DIVIDER_COLOR,
} from 'theme/theme';
import { LAYOUT_RANGE_425_MAX } from 'theme/layout/LAYOUT';

export const PageHeader = {
  padding: [0, 0, 20],
  display: 'flex',
  justifyContent: 'space-between',
  position: 'relative',
  '&.artistPage': {
    marginTop: 48,
    paddingBottom: 7,
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  '&.saleLotsListPage': {
    paddingTop: 20,
    paddingBottom: 7,
  },
  '&.AO-Form-PageHeader.newAOForm, &.collectionItemPage, &.rtvValueListPage': {
    alignItems: 'baseline',
  },
  '&.SharedByHeader': {
    flexWrap: 'wrap',
    paddingBottom: 7,
    alignItems: 'center',
  },
  '@media screen and (max-width: 610px)': {
    borderBottom: 'none',
    flexDirection: 'column',
    '&.newArtPage.newAOForm, &.collectionItemPage, &.discoverPage, &.rtvValueListPage': {
      flexDirection: 'row',
    },
    '&.SharedByHeader': {
      flexWrap: 'wrap',
    },
  },
  '&.sharePages': {
    paddingTop: 15,
  },
  '&.AO-Form-PageHeader': {
    paddingBottom: 20,
    borderBottom: `1px solid ${DIVIDER_COLOR}`,
    '&.newArtPage': {
      paddingTop: 30,
    },
  },
  [`@media screen and (max-width: ${LAYOUT_RANGE_425_MAX}px)`]: {
    '&.newArtPage, &.addArtUnifiedPage': {
      display: 'block',
      '&.newAOForm': {
        display: 'flex',
      },
    },
  },
  '@media screen and (max-width: 457px)': {
    '&.newArtPage, &.addArtUnifiedPage': {
      '&.newAOForm': {
        display: 'block',
      },
    },
  },
  '@media screen and (max-width: 300px)': {
    '&.collectionItemPage, &.discoverPage': {
      display: 'block',
    },
  },
};

export const title = {
  fontSize: 24,
  fontWeight: 600,
  '&.newArtPage, &.addArtUnifiedPage': {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: 29,
    marginRight: 20,
  },
  [`@media screen and (max-width: ${LAYOUT_RANGE_425_MAX}px)`]: {
    '&.newArtPage, &.addArtUnifiedPage': {
      marginRight: 0,
    },
  },
};

export const actions = {
  display: 'grid',
  columnGap: '15px',
  rowGap: '10px',
  justifyContent: 'end',
  gridTemplateColumns: '110px 110px',
  gridTemplateAreas: '"cancel save"',
  '&.ArtistPage': {
    marginTop: 15,
    gridTemplateColumns: '120px',
    gridTemplateAreas: '"save"',
  },
  '&.hasDraft': {
    gridTemplateColumns: '110px 125px 110px',
    gridTemplateAreas: '"cancel draft save"',
  },
  [`@media screen and (max-width: ${LAYOUT_RANGE_425_MAX}px)`]: {
    marginTop: 10,
  },
  '@media screen and (max-width: 390px)': {
    '&.ArtistPage': {
      gridTemplateColumns: '1fr',
      gridTemplateAreas: '"save"',
    },
    '&.hasDraft': {
      gridTemplateColumns: '1fr 1fr',
      gridTemplateAreas: '"cancel draft" "save save"',
    },
  },
  '@media screen and (max-width: 350px)': {
    '&.collectionItemPage': {
      gridTemplateColumns: '100px 100px',
      gridTemplateAreas: '"cancel save"',
    },
  },
  '@media screen and (max-width: 249px)': {
    gridTemplateAreas: '"cancel" "save"',
    gridTemplateColumns: 'auto',
    justifyContent: 'stretch',
    '&.ArtistPage': {
      gridTemplateColumns: 'auto',
      gridTemplateAreas: '"save"',
    },
    '&.hasDraft': {
      gridTemplateColumns: 'auto',
      gridTemplateAreas: '"draft" "cancel" "save"',
    },
    '&.collectionItemPage': {
      gridTemplateColumns: 'auto',
      gridTemplateAreas: '"cancel" "save"',
    },
  },
};

export default {
  root: {},
  header: PageHeader,
  result: {
    display: 'flex',
    fontSize: 14,
    alignSelf: 'flex-end',
  },
  title,
  description: {
    color: DARK_BLUE_COLOR,
  },
  count: {
    margin: [0, 5],
    color: DARK_BLUE_COLOR,
    fontWeight: '600',
  },
};
