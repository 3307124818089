/* eslint no-unused-vars: warn */
import React, { useCallback, useMemo, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { ILLIQUID, LIQUID } from 'domain/const';
import { initPDFStore } from 'pages/highcharts/PDFModel';
import { getCreatePDF } from 'pages/highcharts/helpers/generatePDF';
import { EVENT_PRINT_PDF, EVENT_COMPONENT_READY, SET_ARTIST_LIQUIDITY, EVENT_LOADING_STATE } from 'pages/highcharts/helpers/PDFConstants';
import { REPORT_ITEMS, EVENT_PREVIEW_CLOSED } from 'pages/highcharts/helpers/PDFConstants';



const allChartsLoaded = (state, reportType, liquidity = LIQUID, PDFEvents) => {
  const l = liquidity === ILLIQUID ? liquidity : LIQUID;
  if (!reportType || !REPORT_ITEMS[reportType]) throw new Error(`no items defined for report type "${reportType}"`);
  if (!REPORT_ITEMS[reportType][l]) throw new Error(`no items defined for "${liquidity}" report type "${reportType}"`);
  const currentTable = REPORT_ITEMS[reportType][l].reduce((prev, id) => ({ ...prev, [id]: state[id] }), state);
  PDFEvents.dispatch(EVENT_LOADING_STATE, currentTable);
  return REPORT_ITEMS[reportType][l].reduce((prev, id) => prev && state[id], true);
};

export const initPDFGenerator = reportType => (WrappedComponent) => {
  function PDFGeneratorWrapper(props) {
    const { PDFEvents, onReadyToGeneratePDF, PDFStore } = props;
    const ref = useRef({});
    const loadedCharts = ref.current;
    const createPDF = useMemo(() => getCreatePDF(PDFEvents), [PDFEvents]);
    const { liquidity } = PDFStore.state;
    const checkAllChartsLoaded = useCallback(({ id }) => {
      loadedCharts[id] = true;
      if (!liquidity) return;
      if (allChartsLoaded(loadedCharts, reportType, liquidity, PDFEvents)) {
        onReadyToGeneratePDF();
      }
    }, [onReadyToGeneratePDF, liquidity, PDFEvents, loadedCharts]);
    const onClose = useCallback(() => {
      PDFStore.dispatch({ type: EVENT_PREVIEW_CLOSED, payload: { reportType } } );
    }, [PDFStore]);
    useEffect(() => {
      PDFEvents.addEventListener(EVENT_PRINT_PDF, createPDF);
      PDFEvents.addEventListener(EVENT_COMPONENT_READY, checkAllChartsLoaded);
      PDFEvents.addEventListener(EVENT_PREVIEW_CLOSED, onClose);
      return () => {
        PDFEvents.removeEventListener(EVENT_PRINT_PDF, createPDF);
        PDFEvents.removeEventListener(EVENT_COMPONENT_READY, checkAllChartsLoaded);
        PDFEvents.removeEventListener(EVENT_PREVIEW_CLOSED, onClose);
      };
    }, [PDFEvents, createPDF, checkAllChartsLoaded, onClose]);
    useEffect(() => {
      const onLiquidity = (liquidity) => {
        PDFStore.dispatch({ type: SET_ARTIST_LIQUIDITY, payload: { liquidity } });
        if (!liquidity) return;
        if (allChartsLoaded(loadedCharts, reportType, liquidity, PDFEvents)) {
          onReadyToGeneratePDF();
        }
      };
      PDFEvents.addEventListener(SET_ARTIST_LIQUIDITY, onLiquidity);
      return () => {
        PDFEvents.removeEventListener(SET_ARTIST_LIQUIDITY, onLiquidity);
      };
    }, [PDFStore, PDFEvents, onReadyToGeneratePDF, loadedCharts]);
    return <WrappedComponent {...props} />;
  }
  PDFGeneratorWrapper.propTypes = {
    PDFEvents: PropTypes.shape({
      addEventListener: PropTypes.func.isRequired,
      removeEventListener: PropTypes.func.isRequired,
      dispatch: PropTypes.func.isRequired,
    }).isRequired,
    onReadyToGeneratePDF: PropTypes.func.isRequired,
    PDFStore: PropTypes.shape({
      dispatch: PropTypes.func.isRequired,
      state: PropTypes.shape({
        liquidity: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  };
  return compose(
    initPDFStore(reportType),
  )(PDFGeneratorWrapper);
};
