import React from 'react';
import PropTypes from 'prop-types';
import I from 'immutable';
import cx from 'classnames';
import { Field } from 'redux-form';
import rules, { validator } from 'components/form/validation';
// import { connect } from 'react-redux';
import { connect as reconnect } from 'cpcs-reconnect';
import { compose } from 'redux';
import { categoryList } from 'domain/category';
import { groupedSubstrates } from 'domain/substrate';
import { groupedMediums_sel } from 'domain/medium/MediumModel';
import { groupedSurfaces } from 'domain/surface';
import { signatureValueLabel } from 'domain/signatures';
import AppraisalField from 'pages/common/newPage/form/elements/SalesHistory/AppraisalField';
import {
  bornIn_sel,
  category_sel,
  diedIn_sel,
  edition_sel,
  formData_sel,
  notAccurate_sel,
  // appraisalPriceType_sel,
} from 'pages/common/artworkForm/ArtworkFormModel';
import DateField from 'components/form/DateField';
import Checkbox from 'components/form/checkbox';
import {
    is3DEdition,
    isEdition,
    maxCreatedAt,
    minCreatedAt,
    validateCreatedAt,
} from 'pages/common/newPage/form/artObject/helpers';
import { RadioBox } from 'pages/common/newPage/form/elements/RadioBox';
import FormRowField from 'pages/common/newPage/form/elements/FormRowField';
import Select from 'components/form/select';
import Size from 'pages/common/newPage/form/artObject/size';
import SheetSize from 'pages/common/newPage/form/artObject/sheetSize';
import { getId } from 'pages/common/newPage/form/utils';
import { EDITIONS_LIST_2D, EDITIONS_LIST_3D } from 'domain/dictionary';

import injectSheet from 'lib/sheet';
import sheet from './sheet';

const normalizeNumber = (v, p = '') => {
  return /[^0-9,]+/.test(v) ? p : v.replace(/\D/g, '');
};
const formatNumber = (v = '') => v ? new Intl.NumberFormat('en-EN').format(v) : v;
const extractMediumsPlaceholder = (IS_EDITION, list) => {
  const defaultValue = IS_EDITION ? 'Aluminium, bronze, ceramic' : 'Bronze, ceramic, merble';
  const options = (list && list[0] && list[0].options) || [];
  return (options.map(v => v.label).splice(0, 3).join(', ') || defaultValue).concat('...');
};

class ArtObject extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.shape({
      fieldset: PropTypes.string.isRequired,
      formItem: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      fieldWrapper: PropTypes.string.isRequired,
      editionSizeWrapper: PropTypes.string.isRequired,
    }).isRequired,
    categoryList: PropTypes.instanceOf(I.List).isRequired,
    substrateList: PropTypes.array.isRequired,
    surfaceList: PropTypes.array.isRequired,
    mediumList: PropTypes.array.isRequired,
    signatures: PropTypes.instanceOf(I.List).isRequired,
    suggestionSelected: PropTypes.bool,
    isEdit: PropTypes.bool,
    is3D: PropTypes.bool,
    diedIn: PropTypes.number,
    bornIn: PropTypes.number,
    category: PropTypes.number,
    edition: PropTypes.string,
    notAccurate: PropTypes.bool,
    data: PropTypes.shape({
      isCircular: PropTypes.bool,
      category: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      sheetHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      sheetWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    // priceType: PropTypes.string,
  };

  maxDate() {
    const { diedIn, categoryList, category } = this.props;
    return maxCreatedAt({ diedIn, categoryList, category });
  }

  minDate() {
    const { bornIn } = this.props;
    return minCreatedAt({ bornIn });
  }

  /**
   * don't move this function outside this component, because if Field.validation changed
   * redux will reregister field: https://redux-form.com/7.4.2/docs/api/field.md/#-code-validate-value-allvalues-props-name-gt-error-code-optional-
  **/
  validate = {
    createdAt: validator(
      rules.year,
      (value, allValues, ...args) => validateCreatedAt(this.props.categoryList, value, allValues, ...args),
    ),
    title: validator(
      rules.required,
      rules.strMaxLength(255),
    ),
    conceptionYear: validator(
      rules.year,
    ),
    numberedNoRequired: validator(
      rules.required,
      rules.range(0, Number.MAX_SAFE_INTEGER),
    ),
    numberedNo: validator(
      rules.range(0, Number.MAX_SAFE_INTEGER),
    ),
    numberedOfRequired: validator(
      rules.required,
      rules.range(0, Number.MAX_SAFE_INTEGER),
    ),
    numberedOf: validator(
      rules.range(0, Number.MAX_SAFE_INTEGER),
    ),
    editionSizeRequired: validator(
      rules.required,
      rules.range(0, Number.MAX_SAFE_INTEGER),
    ),
    editionSize: validator(
      rules.range(0, Number.MAX_SAFE_INTEGER),
    ),
    artistProof: validator(
      rules.range(0, Number.MAX_SAFE_INTEGER),
    ),
    pressProof: validator(
      rules.range(0, Number.MAX_SAFE_INTEGER),
    ),
  };

  renderEdition() {
    const { categoryList, category, edition, classes, suggestionSelected, notAccurate } = this.props;
    if (!isEdition({ categoryList, category })) return null;
    let labels = [];
    let validators = {};
    let fields;
    let required = true;
    switch (edition) {
      case 'numbered':
        required = (suggestionSelected && notAccurate);
        labels = ['no.', 'of'];
        fields = ['numberedNo', 'numberedOf'];
        validators = {
          numberedNo: required ? this.validate.numberedNo : this.validate.numberedNoRequired,
          numberedOf: required ? this.validate.numberedOf : this.validate.numberedOfRequired,
        };
        break;
      case 'editionSize':
        required = (suggestionSelected && notAccurate);
        fields = ['editionSize'];
        validators = {
          editionSize: required ? this.validate.editionSize : this.validate.editionSizeRequired,
        };
        break;
      case 'artistProof':
        required = false;
        fields = ['artistProof'];
        break;
      case 'pressProof':
        required = false;
        fields = ['pressProof'];
        break;
      case 'unknown':
      default:
        return null;
    }
    return <li className={classes.formItem}>
      <ul className={classes.editionSizeWrapper}>
        {
          fields.map((v, k) => (
            <Field
              key={v}
              name={v}
              placeholder="XX"
              component={FormRowField}
              title={labels[k]}
              required={required}
              modifier="editionSize AOForm"
              disabled={suggestionSelected}
              validate={validators[v] || this.validate[v]}
              normalize={normalizeNumber}
              format={formatNumber}
            />
          ))
        }
      </ul>
    </li>;
  }

  render() {
    const {
      classes, categoryList, substrateList, category,
      surfaceList, mediumList, suggestionSelected, signatures, isEdit, is3D = false,
      notAccurate, data,
      // priceType,
    } = this.props;
    let EDITIONS_LIST = null;
    let IS_EDITION = false;
    if (isEdition({ categoryList, category })) {
      IS_EDITION = true;
      EDITIONS_LIST = is3DEdition({ categoryList, category }) ? EDITIONS_LIST_3D : EDITIONS_LIST_2D;
    }
    const hasEditionSheetSize = !!(IS_EDITION && !is3D && data && data.sheetHeight && data.sheetWidth);
    return (
      <fieldset className={classes.fieldset}>
        <ul>
          <Field
            name="category"
            component={FormRowField}
            modifier="AOForm"
            Field={Select}
            validate={rules.required}
            disabled={suggestionSelected}
            title="Category"
            id="id-category"
            required
            list={categoryList}
            placeholder="2D, 3D..."
          />
          <Field
            title="Title"
            name="title"
            placeholder="Enter title"
            component={FormRowField}
            modifier="AOForm"
            required
            disabled={suggestionSelected && isEdit}
            validate={this.validate.title}
          />
          <li className={cx(classes.formItem, 'AOForm createdAt-and-conceptionYear', { hasConceptionDate: IS_EDITION })}>
            <Field
              id={getId('createdAt')}
              title="Year Created"
              type="text"
              name="createdAt"
              component={FormRowField}
              modifier={cx('AOForm artObjCreatedAt', { hasConceptionDate: IS_EDITION })}
              innerFormItem
              placeholder="YYYY"
              rootTag="div"
              validate={this.validate.createdAt}
              disabled={suggestionSelected}
              Field={DateField}
              maxDate={this.maxDate()}
              minDate={this.minDate()}
              mask={[/\d/, /\d/, /\d/, /\d/]}
              momentMask="YYYY"
              momentParseMasks={['YYYY']}
              maxDetail="decade"
            />
            {
              IS_EDITION &&
                <Field
                  id={getId('conceptionYear')}
                  title="Conception Date"
                  type="text"
                  name="conceptionYear"
                  component={FormRowField}
                  modifier="AOForm artObjConceptionDate"
                  innerFormItem
                  placeholder="YYYY"
                  rootTag="div"
                  validate={this.validate.conceptionYear}
                  disabled={suggestionSelected}
                  Field={DateField}
                  maxDate={this.maxDate()}
                  minDate={this.minDate()}
                  mask={[/\d/, /\d/, /\d/, /\d/]}
                  momentMask="YYYY"
                  momentParseMasks={['YYYY']}
                  maxDetail="decade"
                />
            }
          </li>
          {
            is3D &&
              <Field
                name="mediums"
                component={FormRowField}
                modifier="AOForm"
                Field={Select}
                validate={rules.arrayNotEmpty}
                placeholder={extractMediumsPlaceholder(IS_EDITION, mediumList)}
                title="Medium"
                id="id-mediums"
                required
                list={mediumList}
                isMulti
                grouped
                disabled={suggestionSelected}
              />
          }
          {
            !is3D &&
              <React.Fragment>
                <Field
                  name="surfaces"
                  component={FormRowField}
                  modifier="AOForm"
                  Field={Select}
                  validate={rules.arrayNotEmpty}
                  placeholder="Oil, watercolor, acrylic..."
                  title="Surface"
                  id="id-surface"
                  required
                  list={surfaceList}
                  isMulti
                  grouped
                  disabled={suggestionSelected}
                />
                <Field
                  name="substrates"
                  component={FormRowField}
                  modifier="AOForm"
                  Field={Select}
                  validate={rules.arrayNotEmpty}
                  placeholder="Canvas, paper, panel..."
                  title="Substrate"
                  id="id-substrate"
                  list={substrateList}
                  required
                  isMulti
                  grouped
                  disabled={suggestionSelected}
                />
              </React.Fragment>
          }
          <Field
            component={FormRowField}
            modifier="AOForm signature"
            Field={RadioBox}
            name="signature"
            label="Signature"
            values={signatures.toJS()}
            disabled={suggestionSelected}
            validate={rules.required}
            required
          />
          <Size
            // allow empty size from suggestions and edit artworks in displayStatus
            allowEmpty={notAccurate || hasEditionSheetSize}
            required={!(suggestionSelected && notAccurate)}
            disabled={suggestionSelected}
            is3D={is3D}
            label={(is3D || !category) ? 'Size' : 'Image Size'}
            isCircular={data.isCircular}
            modifier="AOForm"
          />
          {
            ((!is3D && !IS_EDITION) || data.isCircular) &&
              <Field
                name="isCircular"
                component={FormRowField}
                modifier="AOForm isCircular"
                Field={Checkbox}
                label=" "
                labelText="Circular art"
                disabled={suggestionSelected}
              />
          }
          <Field
            component={FormRowField}
            modifier="AOForm appraisal"
            Field={AppraisalField}
            name="appraisal"
            // label={priceType === 'APPRAISAL' ? 'Appraisal Details' : 'Sales'}
            label="Sales / Appraisal Details"
            // validate={rules.required}
          />
          {
            !is3D && IS_EDITION &&
              <SheetSize
                disabled={suggestionSelected}
                modifier="AOForm"
              />
          }
          {
            is3D && !IS_EDITION &&
              <Field
                name="foundry"
                placeholder="Foundry"
                component={FormRowField}
                modifier="AOForm"
                title="Foundry"
                disabled={suggestionSelected}
              />
          }
          {
            is3D && !IS_EDITION &&
              <Field
                name="studio"
                placeholder="Studio"
                component={FormRowField}
                modifier="AOForm"
                title="Studio"
                disabled={suggestionSelected}
              />
          }
          {
            IS_EDITION &&
              <Field
                name="edition"
                component={FormRowField}
                modifier="AOForm"
                Field={Select}
                title="Edition"
                id="id-edition"
                required={!(suggestionSelected && notAccurate)}
                list={EDITIONS_LIST}
                validate={(suggestionSelected && notAccurate) ? undefined : rules.required}
                disabled={suggestionSelected}
                placeholder="Numbered, ..."
              />
          }
          {this.renderEdition()}
          {
            is3D && !IS_EDITION &&
              <Field
                name="inscription"
                placeholder="Inscription"
                component={FormRowField}
                modifier="AOForm"
                title="Inscription"
                disabled={suggestionSelected}
              />
          }
        </ul>
      </fieldset>
    );
  }
}

export const Art = compose(
  injectSheet(sheet),
  reconnect({
    notAccurate: notAccurate_sel,
    categoryList,
    substrateList: groupedSubstrates,
    mediumList: groupedMediums_sel,
    surfaceList: groupedSurfaces,
    signatures: signatureValueLabel,
    category: category_sel,
    edition: edition_sel,
    diedIn: diedIn_sel,
    bornIn: bornIn_sel,
    data: formData_sel,
  }),
  // connect((state, props) => ({
  //   priceType: appraisalPriceType_sel(state, props),
  // })),
)(ArtObject);
