import React from 'react';
import PropTypes from 'prop-types';
import { ORANGE_COLOR } from 'theme/theme';

import injectSheet from 'lib/sheet';

export const emailSheet = {
  ContactSupport: {
    whiteSpace: 'normal',
  },
  link: {
    color: ORANGE_COLOR,
  },
};

function ContactSupport({ classes }) {
  return (
    <div className={classes.ContactSupport}>
      {'There seems to be an issue with this account - please contact '}
      <a
        className={classes.link}
        href="mailto:support@artbnk.com"
        children="support@artbnk.com"
        target="_blank"
        rel="noopener noreferrer"
      />
    </div>
  );
}

ContactSupport.propTypes = {
  classes: PropTypes.shape({
    ContactSupport: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
  }),
};
export const ContactSupportMessage = injectSheet(emailSheet)(ContactSupport);
