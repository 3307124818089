import { call, put, select, takeEvery } from 'redux-saga/effects';
import {
  saveDraftArtworkAction,
  submitArtworkFormAction,
} from 'domain/ownArtwork/OwnArtworkActions';
import * as AOApiGen from 'domain/ownArtwork/sagas';
import * as trackApiGen from 'domain/considering/sagas';
import {
  saveDraftConsiderationAction,
  submitTrackFormAction,
} from 'domain/considering/ConsideringActions';
import {
  submitUnifiedFormAction,
  submitUnifiedDraftFormAction,
} from 'pages/common/artworkForm/ArtworkFormActions';
import { formData_sel } from 'pages/common/artworkForm/ArtworkFormModel';
import {
  // (params, actionCreator, ...args)
  confirmAction,
} from 'domain/ui/UIActions';
import { mapToServerSaga } from 'domain/ownArtwork/sagas/mapToServerSaga';
// import { ensureGalleries_apiGen } from 'domain/galleries/sagas';
import * as RouterModel from 'domain/router/RouterModel';

let taskRun = false;

function* onSaveArtwork({ isTrack }, { payload }) {
  if (taskRun) return;
  try {
    taskRun = true;
    let apiGen = AOApiGen;
    if (isTrack) {
      apiGen = {
        updateArtwork_apiGen: trackApiGen.updateConsiderations_apiGen,
        createSuggestedArtwork_apiGen: trackApiGen.createSuggestedConsideration_apiGen,
        createArtwork_apiGen: trackApiGen.createConsideration_apiGen,
      };
    }
    const { id, ...data } = yield call(mapToServerSaga, payload);
    if (id) {
      yield call(apiGen.updateArtwork_apiGen, { data, id });
    } else {
      const query = yield select(RouterModel.query_sel);
      const galleryId = (query && query.galleryId) || undefined;
      if (data.artWork) {
        yield call(apiGen.createSuggestedArtwork_apiGen, { data: { ...data, galleryId } });
      } else {
        yield call(apiGen.createArtwork_apiGen, { data: { ...data, galleryId } });
      }
    }
  } catch (err) {
    console.error(err);
  } finally {
    taskRun = false;
  }
}


function* onSaveDraft({ isTrack }, { payload }) {
  if (taskRun) return;
  try {
    taskRun = true;
    let apiGen = AOApiGen;
    if (isTrack) {
      apiGen = trackApiGen;
    }
    const { id, ...data } = yield call(mapToServerSaga, payload);
    if (id) {
      yield call(apiGen.updateDraft_apiGen, { data, id });
    } else {
      const query = yield select(RouterModel.query_sel);
      const galleryId = (query && query.galleryId) || undefined;
      if (data.artWork) {
        yield call(apiGen.addDraft_apiGen, { data: { ...data, galleryId } });
      } else {
        yield call(apiGen.createDraft_apiGen, { data: { ...data, galleryId } });
      }
    }
  } catch (err) {
    console.error(err);
  } finally {
    taskRun = false;
  }
  // it looks like we should update galleries on page with galleries and not when AO saved
  // yield call(ensureGalleries_apiGen);
}

function* onUnifiedFormSubmit({ asDraft } = {}) {
  const data = yield select(formData_sel);
  /**
   * artEdit
   * artNew
   * trackingItemEditLink
   * addArtUnifiedPage
  **/
  const pageName = yield select(RouterModel.routeName_sel);
  if (pageName === 'artEdit' || pageName === 'artNew') {
    const actionCreator = asDraft ? saveDraftArtworkAction : submitArtworkFormAction;
    yield put(actionCreator(data));
    return;
  }
  yield put(confirmAction(
    {
      cancelText: 'save to Watchlist',
      acceptText: 'save to My Collection',
      title: 'Select Destination',
      description: 'Stay organized by saving this work of art to the\n appropriate section.',
      modifier: 'unifiedAddArtPage',
      onDeny: asDraft ? saveDraftConsiderationAction : submitTrackFormAction,
      // doNotHide: true,
    },
    (asDraft ? saveDraftArtworkAction : submitArtworkFormAction),
    data,
  ));
}

export default function* savers() {
  // collect
  yield takeEvery(submitArtworkFormAction.type, onSaveArtwork, { isTrack: false });
  yield takeEvery(saveDraftArtworkAction.type, onSaveDraft, { isTrack: false });
  // track
  yield takeEvery(submitTrackFormAction.type, onSaveArtwork, { isTrack: true });
  yield takeEvery(saveDraftConsiderationAction.type, onSaveDraft, { isTrack: true });
  // unified
  yield takeEvery(submitUnifiedFormAction.type, onUnifiedFormSubmit);
  yield takeEvery(submitUnifiedDraftFormAction.type, onUnifiedFormSubmit, { asDraft: true });
}
