/**
 * server to model
 * model to form
 * 
 * form to model
 * form to server
 * 
 * model to server
**/

import I from 'immutable';
import { Artist, Provenance/*, UpcomingSaleBase*/ } from 'model';
import {
  dateStructToString,
  matchDate,
  objectIsNotEmpty,
  sqlDateToApp,
  stringToDateStruct,
} from 'lib/helpers';
import simpleMapToServerFields from 'domain/ownArtwork/simpleMapToServerFields';
import {
  createFieldMappersHashtable,
  createSerializer,
} from 'lib/serialize';
import { UNITS } from 'lib/units';

function provenanceMapToServer(list) {
  const ret = list
    .filter(objectIsNotEmpty)
    .map(item => ({
      city: item.city,
      from: stringToDateStruct(item.from),
      to: stringToDateStruct(item.to),
      owner: item.owner,
      country: parseInt(item.country, 10),
      editable: item.editable,
    }));

  return ['provenance', ret];
}

const salesHistoryItemMapToServer = ({ editable = true, isPrivate, priceSold, estimatedPriceStart, estimatedPriceEnd, currency, priceType, soldDate, ...data }) => ({
  ...data,
  editable,
  url: undefined,
  soldDate: matchDate(soldDate),
  privateLabel: isPrivate === 'yes',
  auction: (priceType ? priceType === 'AUCTION' : isPrivate === 'no') ? data.auction : null,
  seller: (priceType ? priceType !== 'AUCTION' : isPrivate === 'yes') ? data.seller : null,
  priceSold: (priceSold && !isNaN(parseInt(priceSold.value, 10))) ? priceSold : null,
  priceType: priceType || undefined,
  currency: currency || undefined,
  estimatedPriceStart: estimatedPriceStart ?
    {
      ...estimatedPriceStart,
    } : undefined,
  estimatedPriceEnd: estimatedPriceEnd ?
    {
      ...estimatedPriceEnd,
    } : undefined,
});

function salesMapToServer(list, key, single = false) {
  const ret = list
    .filter(objectIsNotEmpty)
    .map(salesHistoryItemMapToServer);
  if (single) return ret[0] && objectIsNotEmpty(ret[0]) ? [key, ret[0]] : [key, null];
  return [key, ret];
}

// const acquisitionMapper = (list) => salesMapToServer(list, 'acquisition', true);
const salesHistoryMapToServer = (list) => salesMapToServer(list, 'salesHistory');
// const upcomingSalesMapper = (list) => salesMapToServer(list, 'upcomingSale', true);

function notesMapToForm(list = []) {
  return list.reduce((prev, note) => prev.push(note.get('text') || ''), new I.List());
}

const salesHistoryItemMapToForm = item => !item ? null : item
  // .update('isPrivate', () => item.get('auction') ? 'no' : 'yes')
  // .update('isPrivate', () => (item.get('auction') && !item.get('privateLabel')) ? 'no' : 'yes')
  .update('isPrivate', () => item.get('privateLabel') ? 'yes' : 'no')
  .update('soldDate', v => sqlDateToApp(v))
  .update('seller', v => item.get('auction') ? null : v);

function salesHistoryMapToForm(list) {
  if (list.size === 0 ) return new I.List();
  return list.map(salesHistoryItemMapToForm);
}

// function upcomingSaleMapToForm(data) {
//   if (!data) return new I.List([new UpcomingSaleBase({ isPrivate: 'yes' })]);
//   return new I.List([data
//     .update('isPrivate', () => data.get('auction') ? 'no' : 'yes')
//     .update('soldDate', v => sqlDateToApp(v))
//     .update('seller', v => data.get('auction') ? null : v),
//   ]);
// }

function provenanceMapToForm(list) {
  if (list.size === 0 ) return new I.List([new Provenance()]);
  return list.map(e => e
    .update('from', d => dateStructToString(d))
    .update('to', d => dateStructToString(d)),
  );
}

const data = {
  createArtObject: true,
  notes: [''],
  unit: UNITS.INCH.id,
  sheetUnit: UNITS.INCH.id,
  currencyPaid: 'USD',
  pictures: [],
  residence: [],
  provenance: [{ editable: true }],
  surfaces: [],
  substrates: [],
  mediums: [],
  conditions: [],
};

export function emptySalesHistory() {
  return { isPrivate: 'yes', currency: 'USD', editable: true };
}

export const INIT_DATA = {
  ...data,
  salesHistory: [emptySalesHistory()],
  userPrice: { currency: 'USD', editable: true },
};

export const CREATE_INIT_DATA = {
  ...data,
  salesHistory: [],
  userPrice: { currency: 'USD', editable: true },
  // upcomingSale: [],
  // acquisition: [emptySalesHistory()],
};

/**
 * form to PUT/POST
**/
const toServerFieldsMappers = {
  ...createFieldMappersHashtable(simpleMapToServerFields),
  authenticityLetter: v => ['authenticityLetter', (v === '' ? null : v)],
  createdAt: v => ['createdAt', stringToDateStruct(v)],
  notes: v => ['notes', (v || []).filter(n => !!n && n.text && n.createdAt)],
  provenance: provenanceMapToServer,
  salesHistory: salesHistoryMapToServer,
  userPrice: v => ['userPrice', salesHistoryItemMapToServer(v)],
  // userPrice: v => ['userPrice', null],
  // upcomingSale: upcomingSalesMapper,
  // no acquisition for tracking
  // acquisition: acquisitionMapper,
};


export const mapToServer = createSerializer(toServerFieldsMappers);

/**
 * model to form
**/
export function mapToForm(data, id) {
  const artist = data.get('artist') || new Artist();
  const upcomingSale = data.get('upcomingSale');
  let salesHistory = data.get('salesHistory');
  if (upcomingSale) {
    salesHistory = salesHistory.unshift(upcomingSale);
  }
  const userPrice = data.get('userPrice');
  return data
    .toMap()
    // reset id for suggestions data (path artworkId || null || undefined)
    .set('id', id)
    .set('artist', artist.get('id'))
    .set('unit', (data.get('unit') || INIT_DATA.unit).toUpperCase())
    .set('bornIn', dateStructToString(artist.get('bornIn').toJS()))
    .set('diedIn', dateStructToString(artist.get('diedIn').toJS()))
    .set('residence', artist.get('residence'))
    .set('artWork', data.get('editable') ? undefined : data.get('id'))
    .set('createdAt', dateStructToString(data.get('createdAt').toJS()))
    // no notes for tracking
    .set('notes', data.get('notes').size > 0 ? notesMapToForm(data.get('notes')) : [''])
    .set('salesHistory', salesHistoryMapToForm(salesHistory))
    .set('userPrice', salesHistoryItemMapToForm(userPrice))
    .set('provenance', provenanceMapToForm(data.get('provenance')))
    // tracking
    .set('upcomingSale', null)
    .set('privatePictures', data.get('privatePictures').size ? data.get('privatePictures') : data.get('pictures').map(file => file.name) )
    .toJS();
}
