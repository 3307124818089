import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'lib/sheet';
import styles from './sheet.js';
import { uriByPath, buildUrl, openAuthWindow } from 'lib/helpers';
import config from 'config';

const CLIENT_ID = config.auth.providers.instagram.appId;
const REDIRECT_PATH = config.auth.providers.instagram.redirectPath;

const REDIRECT_URI = uriByPath(REDIRECT_PATH);
const AUTH_URL = 'https://api.instagram.com/oauth/authorize/';

// redirect_uri http://localhost:3001/auth#access_token=5213273456.8abc24a.a4df59qwer7cvc3adf4f78sa6q7wer89

class InstagramLoginButton extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
  };


  get href() {
    return buildUrl(AUTH_URL, {
      response_type: 'token',
      client_id: CLIENT_ID,
      redirect_uri: REDIRECT_URI,
      /**
       * we can use here state, it will be added to redirec URI as query param
       * state: 'DcEXeWF45A53sdfKef424',
      **/
    });
  }

  onClick = (ev) => {
    ev.preventDefault();
    openAuthWindow(this.href);
  };

  render() {
    const { classes } = this.props;
    return (
      <a
        href={this.href}
        className={classes.InstagramLoginButton}
        onClick={this.onClick}
      >''</a>
    );
  }
}

export default injectSheet(styles)(InstagramLoginButton);
