import record from 'cpcs-recordjs';
import { field } from 'cpcs-reconnect';
import {
  openCouponFormAction,
  closeCouponFormAction,
  checkCouponAction,
  removeCheckedCouponAction,
} from 'pages/common/couponForm/couponFormActions';
import {
  hideRtvFormAction,
  orderRtvAction,
} from 'pages/common/buyRtv/buyRtvActions';


const State = record('couponForm', {
  couponFormOpened: false,
  validatedCoupon: '',
  couponInValidation: false,
  newPrice: 0,
  percentOff: 0,
  amountOff: 0,
});

const base = field('couponForm');
export const couponFormOpenedSelector = base.then(State.$couponFormOpened);
export const couponInValidationSelector = base.then(State.$couponInValidation);
export const validatedCouponSelector = base.then(State.$validatedCoupon);
export const percentOffSelector = base.then(State.$percentOff);
export const amountOffSelector = base.then(State.$amountOff);
export const priceSelector = base.then(State.$newPrice);

export const reducer = {
  couponForm(state = new State(), action) {
    switch (action.type) {
      case checkCouponAction.type:
      case checkCouponAction.request:
        return state.update(State.$couponInValidation.set(true));
      case checkCouponAction.failure:
        return state.update(State.$couponInValidation.set(false));
      case checkCouponAction.success:
        return state.apply(
          State.$validatedCoupon.set(action.payload.coupon.id || action.payload.title),
          State.$couponInValidation.set(false),
          // [#rtvDiscount]
          State.$newPrice.set(action.payload.totalPrice || 0),
          State.$percentOff.set(action.payload.coupon.percentOff || 0),
          State.$amountOff.set(action.payload.coupon.amountOff || 0),
        );
      case '@@router/LOCATION_CHANGE':
      case orderRtvAction.success:
      case hideRtvFormAction.type:
        return state.apply(
          State.$couponFormOpened.set(false),
          State.$validatedCoupon.set(''),
          State.$couponInValidation.set(false),
          // [#rtvDiscount]
          State.$newPrice.set(0),
          State.$percentOff.set(0),
          State.$amountOff.set(0),
        );
      case openCouponFormAction.type:
        return state.update(State.$couponFormOpened.set(true));
      case removeCheckedCouponAction.type:
        return state.apply(
          State.$validatedCoupon.set(''),
          // [#rtvDiscount]
          State.$newPrice.set(0),
          State.$percentOff.set(0),
          State.$amountOff.set(0),
        );
      case closeCouponFormAction.type:
        return state.apply(
          State.$couponFormOpened.set(false),
          State.$validatedCoupon.set(''),
          // [#rtvDiscount]
          State.$newPrice.set(0),
          State.$percentOff.set(0),
          State.$amountOff.set(0),
        );
      default:
        return state;
    }
  },
};
