import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'cpcs-reconnect';
import { compose } from 'redux';
import cx from 'classnames';
import I from 'immutable';

import { alertsList, listPagination as paginationSelector } from 'domain/alerts/AlertsModel';
import { alertListAppendPageAction } from 'domain/alerts/AlertsActions';
import Filters from 'pages/common/filters';
import PageContainer from 'pages/common/pageContainer';
import filtersConfig from 'pages/common/filters/config';

import AlertsList from './list';
import translate from 'lib/translate.js';

import injectSheet from 'lib/sheet';
import { sheet } from './sheet';

class FavoriteArtistsPage extends React.PureComponent {

  static propTypes = {
    classes: PropTypes.shape({
      container: PropTypes.string,
      PageHeader: PropTypes.string,
      title: PropTypes.string,
      total: PropTypes.string,
      totalStart: PropTypes.string,
      totalEnd: PropTypes.string,
      noAlerts: PropTypes.string,
      noAlertsText: PropTypes.string,
      noAlertsTextDefault: PropTypes.string,
      button: PropTypes.string,
      alertsQuantity: PropTypes.string,
    }).isRequired,
    alerts: PropTypes.instanceOf(I.List),
    pagination: PropTypes.shape({
      page: PropTypes.number,
      total: PropTypes.number,
      loading: PropTypes.bool,
    }).isRequired,
    loadMore: PropTypes.func.isRequired,
  };

  get total() {
    const { pagination } = this.props;
    return pagination ? pagination.total : 0;
  }

  render() {
    const { classes, alerts, pagination, loadMore } = this.props;
    return (
      <PageContainer modifier="FavoriteArtistsPage">
        <header className={cx(classes.PageHeader, 'discoverPage')}>
          <h1 className={classes.title}>Discover <span className={classes.alertsQuantity}>({translate('auction_alert', this.total)})</span></h1>
          <Filters sortByList={filtersConfig.homePage} placeholder="" modifier="discoverPage" />
        </header>
        {/**/}
        <AlertsList pagination={pagination} list={alerts} loadMore={loadMore}>
          <div className={classes.noAlerts}>
            <span className={classes.noAlertsTextDefault}>Explore the most exclusive and accurate art auction database.</span>
            <span className={classes.noAlertsTextDefault}>Search for your favorite artists and enable alerts to be notified<br/> when new sales are posted.</span>
            {/*<span className={classes.noAlertsText}>You have no alerts setup yet</span>*/}
          </div>
        </AlertsList>
        {/**/}
      </PageContainer>
    );
  }
}

export default compose(
  connect({
    alerts: alertsList,
    pagination: paginationSelector,
    loadMore: alertListAppendPageAction,
  }),
  injectSheet(sheet),
)(FavoriteArtistsPage);
