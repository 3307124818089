import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { connect } from 'cpcs-reconnect';
import { compose } from 'redux';
import I from 'immutable';
import { Link } from 'react-router-dom';
import { lnk } from 'lib/routes';
import { currentPlanSel, user_sel, isAuthorized, subscriptionTypeSelector, navigationBlocked_sel } from 'domain/env/EnvModel';
import { routeName_sel } from 'domain/router/RouterModel';
import { advancedFilterOpenState_sel } from 'domain/ui/UIModel';
import translate from 'lib/translate';
import {
  SUBSCRIPTION_ORDER,
  SUBSCRIPTION_TYPE_COLLECTOR,
  SUBSCRIPTION_TYPE_ENTERPRISE,
} from 'domain/const';

import injectSheet from 'lib/sheet';
import sheet from './sheet.js';

class AbvCountdownBanner extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    user: PropTypes.instanceOf(I.Record).isRequired,
    subscription: PropTypes.instanceOf(I.Collection),
    isAuthorized: PropTypes.bool,
    routeName: PropTypes.string,
    modifier: PropTypes.string,
    subscriptionType: PropTypes.string,
    navigationBlocked: PropTypes.bool,
    advancedFilterOpenState: PropTypes.string,
  };

  state = {
    showAbvBanner: true,
  }

  onDismiss = () => {
    this.setState({
      showAbvBanner: false,
    });
  }

  render() {
    const { classes, subscription, user, isAuthorized, modifier, subscriptionType, advancedFilterOpenState, navigationBlocked, routeName } = this.props;
    const routes_condition = ['accountPage', 'addArtUnifiedPage', 'artNew', 'preferencesPage'].includes(routeName);
    if (!isAuthorized || routes_condition || navigationBlocked || !this.state.showAbvBanner) return null;
    if (!subscription.freeRtvCountIncluded) return null;
    return (
      <div className={cx(classes.AbvCountdownBanner, modifier, advancedFilterOpenState, routeName)}>
        <button className={cx(classes.abvDismissSmall, subscriptionType)} onClick={this.onDismiss} />
        <div className={cx(classes.abvValues, subscriptionType)}>
          <div className={classes.abvValuesTitle} children="ARTBnk Values" />
          {
            subscriptionType !== SUBSCRIPTION_TYPE_ENTERPRISE &&
              <div
                className={classes.abvValuesRemained}
                children={`${user.freeRtvPurchaseCount} of ${subscription.freeRtvCountIncluded} remaining`}
              />
          }
          {
            subscriptionType === SUBSCRIPTION_TYPE_ENTERPRISE &&
              <div
                className={classes.abvValuesRemained}
                children={translate('abvCountdownBanner.worksValued', user.totalPurchasesCount)}
              />
          }

        </div>
        <div className={cx(classes.abvActions, subscriptionType)}>
          {
            (SUBSCRIPTION_ORDER[subscriptionType] <= SUBSCRIPTION_ORDER[SUBSCRIPTION_TYPE_COLLECTOR]) &&
              <Link
                to={lnk('accountPageBilling')}
                className={cx(classes.abvUpgrade, subscriptionType)}
                children="Upgrade"
              />
          }
          <button
            className={cx(classes.abvDismiss, subscriptionType)}
            children="Dismiss"
            onClick={this.onDismiss}
          />
        </div>
      </div>
    );
  }
}

export default compose(
  injectSheet(sheet),
  connect({
    user: user_sel,
    subscription: currentPlanSel,
    isAuthorized: isAuthorized,
    subscriptionType: subscriptionTypeSelector,
    navigationBlocked: navigationBlocked_sel,
    routeName: routeName_sel,
    advancedFilterOpenState: advancedFilterOpenState_sel,
  }),
)(AbvCountdownBanner);
