import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Field } from 'redux-form';
import rules from 'components/form/validation';
import FormRowField from 'pages/common/newPage/form/elements/FormRowField';
import { provenanceEditable_sel } from 'pages/common/artworkForm/ArtworkFormModel';
import { connect } from 'react-redux';
import Autocomplete from 'components/form/select';
import DateField from 'components/form/DateField';
import { connect as reconnect } from 'cpcs-reconnect';
import { compose } from 'redux';
import { groupedCountries as countryList } from 'domain/country/index';


import injectSheet from 'lib/sheet';
import sheet from './sheet.js';

class ProvenanceItem extends React.PureComponent {
  static propTypes = {
    fields: PropTypes.shape({
      push: PropTypes.func.isRequired,
      remove: PropTypes.func.isRequired,
      length: PropTypes.number.isRequired,
      map: PropTypes.func.isRequired,
    }).isRequired,
    classes: PropTypes.shape({
      ProvenanceFields: PropTypes.string.isRequired,
      ProvenanceItem: PropTypes.string.isRequired,
      fieldsGroup: PropTypes.string.isRequired,
      button: PropTypes.string.isRequired,
      add: PropTypes.string.isRequired,
      remove: PropTypes.string.isRequired,
      Provenance: PropTypes.string.isRequired,
      formItem: PropTypes.string.isRequired,
      buttonContainer: PropTypes.string.isRequired,
    }).isRequired,
    disabled: PropTypes.bool,
    countryList: PropTypes.array.isRequired,
    index: PropTypes.number,
    modifier: PropTypes.string,
  };

  render() {
    const { index, classes, modifier, fields, countryList, disabled } = this.props;
    return (
      <div
        key={`provenance-${index}`}
        className={classes.ProvenanceItem}
      >
        <div className={classes.fieldsGroup}>
          <div className={classes.ProvenanceFields}>
            <Field
              type="text"
              name={`provenance.${index}.owner`}
              component={FormRowField}
              placeholder="Type here…"
              modifier={cx('provenance-title', modifier)}
              validate={rules.provenanceOwner}
              disabled={disabled}
              rootTag="div"
            />
            <div className={cx(classes.buttonContainer, 'provenance desktop')}>
              {
                !disabled &&
                  <button
                    type="button"
                    onClick={() => fields.remove(index)}
                    className={cx(classes.button, classes.remove)}
                    children=" "
                    title="remove provenance"
                  />
              }
            </div>
          </div>
          <div className={cx(classes.formItem, modifier, 'ProvenanceItem period')}>
            <Field
              type="text"
              name={`provenance.${index}.from`}
              component={FormRowField}
              modifier={cx('provenance-date fromDate', modifier)}
              placeholder="YYYY"
              validate={rules.year}
              Field={DateField}
              title="from"
              disabled={disabled}
              rootTag="div"
              mask={[/\d/, /\d/, /\d/, /\d/]}
              momentMask="YYYY"
              momentParseMasks={['YYYY']}
              maxDetail="decade"
            />
            <Field
              type="text"
              name={`provenance.${index}.to`}
              component={FormRowField}
              modifier={cx('provenance-date', modifier)}
              placeholder="YYYY"
              validate={rules.year}
              Field={DateField}
              props={{
                title: 'to',
                disabled,
                rootTag: 'div',
              }}
              mask={[/\d/, /\d/, /\d/, /\d/]}
              momentMask="YYYY"
              momentParseMasks={['YYYY']}
              maxDetail="decade"
            />
          </div>
          <Field
            type="text"
            name={`provenance.${index}.city`}
            component={FormRowField}
            modifier={cx('provenanceCity', modifier)}
            placeholder="City, State / Province"
            validate={rules.strMaxLength255}
            disabled={disabled}
            rootTag="div"
          />
          <div className={cx(classes.formItem, modifier)}>
            <Field
              component={Autocomplete}
              modifier={modifier}
              id={`provenanceCountry_${index}`}
              name={`provenance.${index}.country`}
              placeholder="Select country"
              list={countryList}
              disabled={disabled}
              grouped
            />
            <div className={cx(classes.buttonContainer, 'provenance')}>
              {
                !disabled &&
                  <button
                    type="button"
                    onClick={() => fields.remove(index)}
                    className={cx(classes.button, classes.remove, 'mobile-XS')}
                    children=" "
                    title="remove provenance"
                  />
              }
              {
                index === fields.length -1 &&
                  <button
                    type="button"
                    onClick={() => fields.length < 20 && fields.push({ owner: '', editable: true })}
                    className={cx(classes.button, classes.add)}
                    title="add provenance"
                    children=" "
                  />
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default compose(
  injectSheet(sheet),
  reconnect({
    countryList,
  }),
  connect((state, props) => ({
    disabled: !provenanceEditable_sel(state, props),
  })),
)(ProvenanceItem);
