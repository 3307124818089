import { confirmationComponents } from 'pages/common/popup/confirmationPopup/sheet';
import { button } from 'theme/button';

export const sheet = {
  ...confirmationComponents,
  container: {
    ...confirmationComponents.container,
    width: 390,
  },
  form: {},
  formItem: {
    marginTop: 12,
  },
  btnGroup: {},
  button,
};
