import { DARK_BLUE_COLOR, DEFAULT_FONTFAMILY, DATA_TABLE_BORDER_COLOR } from 'theme/theme';

const cellFont = {
  fontFamily: DEFAULT_FONTFAMILY,
  fontSize: '14px',
  lineHeight: '20px',
  color: DARK_BLUE_COLOR,
  fontWeight: 400,
};

export default {
  DataTable: {
    marginTop: 20,
    border: `1px solid ${DATA_TABLE_BORDER_COLOR}`,
  },
  tr: {
    extend: cellFont,
    padding: [10, 20, 7],
    backgroundColor: '#FFF',
    borderBottom: '1px solid #e6e6e6',
    '&:last-child': {
      borderBottom: 'none',
    },
    display: 'flex',
    alignItems: 'flex-start',
    '@media screen and (min-width: 1001px and max-height: 1000px)': {
      padding: [7, 15, 5],
    },
    '@media screen and (min-width: 1001px and max-height: 940px)': {
      padding: [5, 15, 5],
    },
    '@media screen and (max-width: 1000px)': {
      display: 'grid',
      gridTemplateColumns: '40px 220px 110px 90px',
      gridTemplateRows: 'auto auto auto',
      '-ms-grid-columns': '40px 220px 110px 90px',
      '-ms-grid-rows': 'auto auto auto',
      /*
        gridTemplateAreas:
          thumb title  date right
          thumb emails   .    .
          thumb comment  .    .
      */
    },
    '@media screen and (max-width: 600px)': {
      gridTemplateColumns: '40px 1fr 90px',
      gridTemplateRows: 'auto auto auto auto',
      '-ms-grid-columns': '40px 1fr 90px',
      '-ms-grid-rows': 'auto auto auto auto',
      /*
        gridTemplateAreas:
          thumb title      .
          thumb emails     .
          thumb comment    .
          thumb date     right
      */
    },
  },
  trEven: {},
  trOdd: {},
  th: {},
  thAsc: {},
  thDesc: {},
  td: {
    flex: '1 0 0px',
    marginLeft: 20,
    '&.tdThumb': {
      marginLeft: 0,
      flex: '0 0 40px',
      alignSelf: 'flex-start',
    },
    '&.tdTxt': {
      flex: '0 5 250px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      paddingTop: 8,
      lineHeight: '24px',
    },
    '&.tdEmails': {
      flex: '0 5 250px',
      alignSelf: 'stretch',
    },
    '&.tdDate': {
      flex: '0 0 100px',
      extend: 'tdTxt',
      textAlign: 'right',
      paddingTop: 8,
      whiteSpace: 'nowrap',
    },
    '&.tdRevoke': {
      flex: '0 0 100px',
      marginLeft: 90,
      display: 'flex',
      marginTop: 10,
    },
    '&.tdEmpty': {},
    '@media screen and (max-width: 1000px)': {
      lineHeight: '20px',
      '&.tdThumb': {
        gridRow: '1 / span 3',
        gridColumn: '1 / 2',
        '&.IE': {
          '-ms-grid-row': 1,
          '-ms-grid-column': 1,
          '-ms-grid-row-span': 3,
        },
      },
      '&.title': {
        gridRow: '1 / 2',
        gridColumn: '2 / 3',
        paddingTop: 0,
        '&.IE': {
          '-ms-grid-row': 1,
          '-ms-grid-column': 2,
        },
      },
      '&.emails': {
        marginLeft: 20,
        paddingTop: 8,
        gridRow: '2 / 3',
        gridColumn: '2 / 3',
        '&.IE': {
          '-ms-grid-row': 2,
          '-ms-grid-column': 2,
        },
      },
      '&.comment': {
        gridRow: '3 / 4',
        gridColumn: '2 / 3',
        '&.IE': {
          '-ms-grid-row': 3,
          '-ms-grid-column': 2,
        },
      },
      '&.tdDate': {
        gridRow: '1 / 2',
        gridColumn: '3 / 4',
        paddingTop: 0,
        '&.IE': {
          '-ms-grid-row': 1,
          '-ms-grid-column': 3,
        },
      },
      '&.tdRevoke': {
        paddingTop: 0,
        margin: [0, 0, 0, 10],
        gridRow: '1 / 4',
        gridColumn: '4 / 5',
        '&.IE': {
          '-ms-grid-row': 1,
          '-ms-grid-column': 4,
        },
        '&:before': {
          marginLeft: 0,
        },
      },
    },
    '@media screen and (max-width: 600px)': {
      '&.tdThumb': {
        gridRow: '1 / span 4',
        gridColumn: '1 / 2',
        '&.IE': {
          '-ms-grid-row': 1,
          '-ms-grid-column': 1,
          '-ms-grid-row-span': 3,
        },
      },
      '&.title': {
        gridRow: '1 / 2',
        gridColumn: '2 / 4',
        '&.IE': {
          '-ms-grid-row': 1,
          '-ms-grid-column': 2,
          '-ms-grid-column-span': 2,
        },
      },
      '&.emails': {
        gridRow: '2 / 3',
        gridColumn: '2 / 4',
        '&.IE': {
          '-ms-grid-row': 2,
          '-ms-grid-column': 2,
          '-ms-grid-column-span': 2,
        },
      },
      '&.comment': {
        gridRow: '3 / 4',
        gridColumn: '2 / 4',
        '&.IE': {
          '-ms-grid-row': 3,
          '-ms-grid-column': 2,
          '-ms-grid-column-span': 2,
        },
      },
      '&.tdDate': {
        gridRow: '4 / 5',
        gridColumn: '2 / 3',
        paddingTop: 8,
        '&.IE': {
          '-ms-grid-row': 4,
          '-ms-grid-column': 2,
        },
        textAlign: 'left',
      },
      '&.tdRevoke': {
        margin: 0,
        gridRow: '4 / 5',
        gridColumn: '3 / 4',
        paddingTop: 8,
        '&.IE': {
          '-ms-grid-row': 4,
          '-ms-grid-column': 3,
        },
        '&:before': {
          marginLeft: 0,
        },
      },
    },
  },
  thumb: {
    width: 40,
    height: 40,
    background: {
      repeat: 'no-repeat',
      size: 'cover',
      color: '#F7F7F7',
    },
    '@media screen and (min-width: 1024px and max-height: 890px)': {
      width: 35,
      height: 35,
    },
  },
};
