import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import injectSheet from 'lib/sheet';
import sheet from './sheet';

function DraftLabel({ classes, modifier }) {
  return (
    <div className={cx(classes.DraftLabel, modifier)}>
      Draft
    </div>
  );
}

DraftLabel.propTypes = {
  classes: PropTypes.shape({
    DraftLabel: PropTypes.string,
  }).isRequired,
  modifier: PropTypes.string,
};

export default injectSheet(sheet)(DraftLabel);
