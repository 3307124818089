// import { create as createInjectSheet } from 'react-jss';
// import { create as createJss } from 'jss';
// import jssGlobal from 'jss-global';
// import nested from 'jss-nested';
// import camelCase from 'jss-camel-case';
// import expand from 'jss-expand';
// import extend from 'jss-extend';
// import vendorPrefixer from 'jss-vendor-prefixer';
// import defaultUnit from 'jss-default-unit';

// let jss = createJss();

// if (process.env.NODE_ENV === 'test') {
//   jss = createJss({ generateClassName: (rule) => rule.name });
// }

// jss.use(
//   jssGlobal(),
//   extend(),
//   nested(),
//   camelCase(),
//   defaultUnit({
//     width: 'px',
//     height: 'px',
//     maxWidth: 'px',
//     maxHeight: 'px',
//     fontSize: 'px',
//     padding: 'px',
//     marginRight: 'px',
//     right: 'px',
//     top: 'px',
//     bottom: 'px',
//     left: 'px',
//   }),
//   expand(),
//   vendorPrefixer(),
// );
// const injectSheet = createInjectSheet(jss);
// export default injectSheet;
import React from 'react';
import { createUseStyles, jss } from 'react-jss';

if (process.env.NODE_ENV === 'test') {
  jss.setup({
    createGenerateId: () => (rule) => rule.key,
  });
}

export default function injectSheet(sheet) {
  const useStyles = createUseStyles(sheet);
  return function getComponent(WrappedComponent) {
    return function PassClasses(props) {
      const classes = useStyles();
      return <WrappedComponent {...props} classes={classes} />;
    };
  };
}
