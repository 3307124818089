import arrow from './arrow.svg';
import mobileMenuBtn from './mobileMenuBtn.svg';
import { DARK_BLUE_COLOR } from 'theme/theme';

export default {
  profile: {
    margin: [6, 0, 0],
    position: 'relative',
    height: 34,
    '@media screen and (max-width: 1164px)': {
      display: 'none',
    },
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    '&:after': {
      width: 34,
      height: 34,
      display: 'inline-block',
      content: '""',
      background: `url("${arrow}") scroll no-repeat center`,
    },
  },
  name: {
    display: 'flex',
    fontWeight: 500,
    width: 34,
    height: 34,
    fontSize: 14,
    color: 'white',
    justifyContent: 'center',
    alignItems: 'center',
    background: DARK_BLUE_COLOR,
    letterSpacing: '1.8px',
  },
  ava: {
    width: 34,
    height: 34,
  },

  mobileMenu: {
    display: 'none',
    width: 24,
    height: 17,
    margin: [15, 0, 0, 0],
    outline: 'none',
    background: `url("${mobileMenuBtn}") scroll no-repeat center`,
    '@media screen and (max-width: 1084px)': {
      display: 'block',
    },
  },
};
