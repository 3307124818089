import { action, asyncAction } from 'lib/actions';

export const getRtvAction = asyncAction('buyRtv/GET');
export const showRtvFormAction = action('buyRtv/SHOW_FORM');
export const hideRtvFormAction = action('buyRtv/HIDE_FORM');
export const buyRtvFormSubmitAction = action('buyRtv/FORM_SUBMIT');
export const orderRtvAction = asyncAction('buyRtv/ORDER_REQUEST');
export const setOrderErrorAction = action('buyRtv/SET_ORDER_ERROR');
export const updateCardAction = action('creditCard/UPDATE');
export const orderFreeRtvAction = asyncAction('buyRtv/GET_FREE');
export const noFreeRtvLeftAction = action('buyRtv/NO_FREE_RTV_LEFT');
export const setSelectedProductNameAction = action('buyRtv/SET_SELECTED_PRODUCT_NAME');
