import React from 'react';
import moment from 'moment';
import { momentNow } from 'lib/envGlobals';
import { APP_FORMAT, isArray, objectIsNotEmpty } from 'lib/helpers';

const REQUIRED = 'Required';
const INVALID_EMAIL = 'Fill in valid email';
const INVALID_PHONE = 'Invalid phone number';
const INVALID_DATE = 'Invalid date';
const INVALID_YEAR = 'Invalid year';
const INVALID_YEAR_OR_DATE = 'Invalid date or year';
const INVALID_FLOAT = 'Invalid value';
const INVALID_INT = 'Invalid value';
const TO_SMALL = 'To small';

const isEmpty = v => ![v].join('');

const extractVal = ({ data, key }) => (key || '').split(/\./).reduce((data, k) => data && data[k] ? data[k] : undefined, data);

/**
 * each rule will be called with args:
 * @arg value
 * @arg all values
 * @arg componentProps
 * @arg fieldName
**/

const strLengthMessage = ({ min, max, message }) =>
  message
    .replace('{min}', min)
    .replace('{max}', max);

const salesHistoryFutureDate = (value, data) => {
  if (!(/^\d{2}\/\d{2}\/\d{4}$/.test(value) && moment(value, APP_FORMAT).isValid())) {
    return false;
  }
  if (!moment(value, APP_FORMAT).isSameOrAfter(momentNow(), 'day')) return true;
  // check that future date used only once
  if (!data || !data.salesHistory || data.salesHistory.length === 0) return true;
  let futureDateFound = false;
  for (let i of data.salesHistory) {
    if (!i || !i.soldDate) {
      continue;
    }
    const v = i.soldDate;
    if (!(/^\d{2}\/\d{2}\/\d{4}$/.test(v) && moment(v, APP_FORMAT).isValid())) continue;
    if (moment(v, APP_FORMAT).isSameOrAfter(momentNow(), 'day')) {
      if (futureDateFound) return false;
      futureDateFound = true;
    }
  }
  return true;
};

const priceSold = (value, data, props, name) => {
  // f.e. salesHistory.222.priceSold.value
  const match = name.match(/salesHistory\.(\d+)\./);
  if (!match) return undefined;
  // f.e. ["salesHistory.222.", "222", index: 0, input: "salesHistory.222.priceSold.value", groups: undefined]
  const index = parseInt(match[1], 10);
  const history = (data.salesHistory || [])[index];
  if (!history || !history.soldDate || !history.editable || isEmpty(value)) return undefined;
  if (moment(history.soldDate, APP_FORMAT).isSameOrBefore(momentNow(), 'day')) return undefined;
  return 'This work has not sold yet according to auction date';
};

const isAppDateValid = (value, future = false) => {
  return /^\d{2}\/\d{2}\/\d{4}$/.test(value)
    && moment(value, APP_FORMAT).isValid()
    && ( future ? moment(value, APP_FORMAT).isSameOrAfter(momentNow(), 'day') : moment(value, APP_FORMAT).isSameOrBefore(momentNow(), 'day') );
};

const isYearValid = (value) => {
  return /^[1-9]\d{0,3}$/.test(value)
    && moment(value, 'YYYY').isBefore(momentNow());
};

// const email = v => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(v) ? undefined : INVALID_EMAIL;
const email = v => /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/i.test(v) ? undefined : INVALID_EMAIL;

const arrayEmail = (values = []) => {
  const result = values.filter(email);
  if (result.length) {
    const styles = { textOverflow: 'ellipsis', overflow: 'hidden' };
    return [
        result.map((v, index) => <div style={styles} key={index}><span style={{ fontWeight: 600 }} >{v}</span> - { INVALID_EMAIL }</div>),
    ];
  } else {
    return undefined;
  }
};

const toInteger = (v = '') => parseInt(`${v}`.replace(/\D/g, '') || 0, 10);

const required = value => (!isEmpty(value) ? undefined : REQUIRED);

const strMaxLength = (len) => v => !!v && `${v}`.length > len ? `Should be ${len} chars or less` : undefined;

const provenanceOwner = (value, values, props, name) => {
  const index = name.split('.')[1];
  if (!values || !values['provenance']) return undefined;
  const obj = values['provenance'][index];
  if (objectIsNotEmpty(obj)) return required(value) || strMaxLength(255)(value);
  return strMaxLength(255)(value);
};

/**
 * each rule will be called with args:
 * @arg value
 * @arg all values
 * @arg componentProps
 * @arg fieldName
**/
export const rules = {
  float: value => !value || /^([1-9]\d*|0)(\.\d{1,2}){0,1}$/.test(value) ? undefined : INVALID_FLOAT,
  float3: value => !value || /^([1-9]\d*|0)(\.\d{1,3}){0,1}$/.test(value) ? undefined : INVALID_FLOAT,
  floatAny: value => !value || /^([1-9]\d*|0)(\.\d+){0,1}$/.test(value) ? undefined : INVALID_FLOAT,
  int: value => isEmpty(value) || /^([1-9]\d*|0)$/.test(value) ? undefined : INVALID_INT,
  cmRequired: (message = REQUIRED) => value => (!isEmpty(value) ? undefined : message),
  cmIsTrue: (message = REQUIRED) => value => (value ? undefined : message),
  required,
  arrayNotEmpty: value => ((isArray(value) && value.length) ? undefined : REQUIRED),
  arrayEmail: v => !v ? undefined : arrayEmail(v),
  email,
  match: (v, message) => value => (!v || !value || `${value}` === `${v}` ? undefined : message),
  minLength: v => (`${v}`.length < 6 ? 'Password must be 6 characters or more' : undefined),
  strMinLength: (len, message) => v => (v || '').toString().length < len ? message || `Should be ${len} chars or more` : undefined,
  strMaxLength,
  strMaxLength255: strMaxLength(255),
  strLength: (v, { min, max, message }) =>
    ((v || '').toString().length < min || (v || '').toString().length > max) ?
      strLengthMessage({ message, min, max }) : undefined,
  phone: v => !v || /^[\d\s()+*#-]+$/i.test(v) ? undefined : INVALID_PHONE,
  date: value => !value || isAppDateValid(value) ? undefined : INVALID_DATE,
  dateFormat: momentMask => value => !value || (moment(value, momentMask) && moment(value, momentMask).isValid()) ? undefined : INVALID_DATE,
  futureDate: value => !value || isAppDateValid(value, true) ? undefined : INVALID_DATE,
  salesHistoryFutureDate: (value, ...args) => !value || salesHistoryFutureDate(value, ...args) ? undefined : INVALID_DATE,
  priceSold,
  year: value => !value || isYearValid(value) ? undefined : INVALID_YEAR,
  range: (from, to) => v => (!v || (v >= from && v <= to) ? undefined : `Should be between ${from} and ${to}`),
  greaterThanFormValue: (minKey) => (value, allValues) => {
    const rangeMin = extractVal({ data: allValues, key: minKey });
    return isEmpty(value) || isEmpty(rangeMin) || parseFloat(value, 10) >= parseFloat(rangeMin, 10) ?
      undefined :
      TO_SMALL;
  },
  greaterThanDateFormValue: ({ minKey, minLabel }, parseMask, parseMinMask) => (value, { [minKey]: rangeMin }) => !value || !rangeMin || moment(value, parseMask).unix() >= moment(rangeMin, parseMinMask || parseMask).unix() ? undefined : `Smaller then ${minLabel || minKey}`,
  greaterThan: (minValue) => value => isEmpty(value) || toInteger(value) > toInteger(minValue) ? undefined : TO_SMALL,
  greaterThanOrEqual: (minValue) => value => isEmpty(value) || toInteger(value) >= toInteger(minValue) ? undefined : TO_SMALL,
  yearOrDate: value => !value || isAppDateValid(value) || isYearValid(value) ? undefined : INVALID_YEAR_OR_DATE,
  provenanceOwner,
};

/**
 * createErrorMessage([{ field: 'title', code: 'TOO_LONG' }, ...])
 * returns:
 *   { title: 'TOO_LONG', ... }
**/
export function createErrorMessage(arr) {
  if (!isArray(arr)) throw new Error('Incorrect errors response format');
  return arr.reduce((A, V) => ({ ...A, [V.field]: V.message }), {});
}

/**
 * tool to validate whole forms
 * optionaly adds to object `errors` property `fieldName` with val `err`
 * * if err not empty
 * * if `errors` doesn't already has property `fieldName`
 * example:
 *   addError(errors, fieldName, rules.email(val))
**/
export const addError = (errors, fieldName, err) => {
  if (!errors[fieldName] && err) {
    return { ...errors, [fieldName]: err };
  }
  return errors;
};

export default rules;

export const validator = (...rules) => (...args) =>
  rules.reduce((error, rule) => (error || rule(...args)), void 0);
