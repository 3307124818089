import record, { listOf } from 'cpcs-recordjs';
import { field } from 'cpcs-reconnect';
import { Artist, Artwork, Pagination } from 'model';

import { fetchOtherArtistWorksListAction } from 'domain/otherArtistWorks/otherArtistWorksActions';

/**
 * State
**/
const State = record('OtherArtistWorks', {
  list: listOf(Artwork),
  artist: Artist,
  pagination: Pagination.parse({}),
});

/**
 * selectors
**/
const base = field('otherArtistWorks');

export const otherArtistWorksListSelector = base.then(State.$list);
export const otherArtistWorksArtistSelector = base.then(State.$artist);
export const otherArtistWorksPaginationSelector = base.then(State.$pagination);

/**
 * reducer
**/
export const reducer = {
  otherArtistWorks(state = new State(), action) { //NOSONAR
    switch (action.type) {

      case fetchOtherArtistWorksListAction.request:
        return new State();

      case fetchOtherArtistWorksListAction.failure:
        console.error(action.type);
        return state;
      case fetchOtherArtistWorksListAction.success:
        return state.apply(
          State.$list.parsed(action.payload.lots.content),
          State.$pagination.parsed(action.payload.lots),
          State.$artist.parsed(action.payload.artist),
        );

      default:
        return state;
    }
  },
};
