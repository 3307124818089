import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { setPropTypes } from 'lib/helpers';
import injectSheet from 'lib/sheet';

const sheet = {
  flag: {
    marginRight: 5,
  },
  sLabel: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
};

const Label = compose(
  injectSheet(sheet),
  setPropTypes({
    classes: PropTypes.shape({
      sLabel: PropTypes.string,
      flag: PropTypes.string,
    }),
    data: PropTypes.shape({
      title: PropTypes.string,
      code: PropTypes.string,
    }),
  }),
)(
  ({ classes, data }) => <div className={classes.sLabel} title={data.title}>
    <img className={classes.flag} alt={data.title} src={`/images/flags/${data.code.toLowerCase()}.png`}/>
    {data.title}
  </div>,
);

export const formatOptionLabel = (data) => <Label data={data} />;
