import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Field, reduxForm } from 'redux-form';
import { compose } from 'redux';
import { connect } from 'cpcs-reconnect';
import Select from 'components/form/select';
import rules from 'components/form/validation';
import FormRowField from 'pages/common/newPage/form/elements/FormRowField';
import { updatePreferredCurrencyAction } from 'domain/env/EnvActions';
import { currencyFormInitialData_sel } from 'domain/env/EnvModel';
import { preferredCurrencyList } from 'lib/units';
import { FORM_CURRENCY } from 'domain/const';

import injectSheet from 'lib/sheet';
import sheet from './sheet.js';

class CurrencyForm extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    updateCurrency: PropTypes.func.isRequired,
    valid: PropTypes.bool,
    pristine: PropTypes.bool,
  };

  onSubmit = (data) => {
    this.props.updateCurrency(data);
  };

  render() {
    const { classes } = this.props;
    return (
      <form
        onSubmit={this.props.handleSubmit(this.onSubmit)}
        className={classes.CurrencyForm}
      >
        <h3
          className={classes.title}
          children="Currency Preference"
        />
        <Field
          id="preferredCurrencyId"
          type="text"
          name="preferredCurrency"
          list={preferredCurrencyList}
          component={FormRowField}
          Field={Select}
          modifier="CurrencyFormField"
          placeholder="$ (USD)"
          validate={rules.required}
          isClearable={false}
          isSearchable={false}
          rootTag="div"
          fixDropdownPadding
        />
        <button
          type="submit"
          children="update"
          className={cx(classes.button, 'AccountPage')}
          disabled={!this.props.valid || this.props.pristine}
        />
      </form>
    );
  }
}

export default compose(
  injectSheet(sheet),
  connect({
    updateCurrency: updatePreferredCurrencyAction,
    initialValues: currencyFormInitialData_sel,
  }),
  reduxForm({
    form: FORM_CURRENCY,
  }),
)(CurrencyForm);
