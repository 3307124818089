export function imgToDataURL(img, outputFormat = 'image/png') {
  return new Promise((resolve) => {
    var canvas = document.createElement('CANVAS');
    var ctx = canvas.getContext('2d');
    canvas.height = img.naturalHeight;
    canvas.width = img.naturalWidth;
    ctx.drawImage(img, 0, 0);
    console.log({ canvas });
    resolve(canvas.toDataURL(outputFormat));
  });
}

export function imgToDataURLFetch(img) {
  const url = img.src;
  return fetch(url)
    .then(response => response.blob())
    .then(blob => new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    }));
}

/**
 * @param sourceLength
 * @param destinationLength
 * 
 * return [sourceX, destinationX, sourceLength, destinationLength];
**/
const scaleToAlign = (s, d, scale, align = 'center') => {
  let ret;
  if (s > d) {
    ret = [(s - d) / 2, 0, d, d];
  } else if (s < d) {
    // eslint-disable-next-line default-case
    switch (align) {
      case 'center':
        ret = [0, (d - s) / 2, s, s];
        break;
      case 'top':
        ret = [0, 0, s, s];
        break;
    }
  } else {
    ret = [0, 0, d, d];
  }
  const [sourceX, destinationX, sourceLength, destinationLength] = ret;
  return [
    Math.ceil(sourceX * scale),
    destinationX,
    Math.ceil(sourceLength * scale),
    destinationLength,
  ];
};

export const cutToFitScalable = async ({ img, maxWidth, maxHeight, cut = false, vAlign = 'center' }) => {
  const canvas = document.createElement('CANVAS');
  const { naturalWidth, naturalHeight } = img;

  const wGain = naturalWidth / maxWidth;
  const hGain = naturalHeight / maxHeight;
  const gain = cut ? Math.min(wGain, hGain) : Math.max(wGain, hGain);
  const scaled = {
    width: gain > 1 ? naturalWidth / gain : naturalWidth,
    height: gain > 1 ? naturalHeight / gain : naturalHeight,
    scale: Math.max(1, gain),
  };
  const [sx, dx, sw, dw] = scaleToAlign(scaled.width, maxWidth, scaled.scale);
  const [sy, dy, sh, dh] = scaleToAlign(scaled.height, maxHeight, scaled.scale, vAlign);

  canvas.width = maxWidth;
  canvas.height = maxHeight;
  const ctx = canvas.getContext('2d');
  ctx.drawImage(img, sx, sy, sw, sh, dx, dy, dw, dh);
  const b = await (new Promise(resolve => canvas.toBlob(resolve)));
  return {
    src: URL.createObjectURL(b),
    width: maxWidth,
    height: maxHeight,
  };
};
