import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'cpcs-reconnect';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';
import DateField from 'components/form/DateField';
import { today } from 'lib/envGlobals';
import FormRowField from 'pages/common/newPage/form/elements/FormRowField';
import rules, { validator } from 'components/form/validation';
import { FORM_ADD_AUTHOR } from 'domain/const';
import { Buttons } from 'pages/common/popupForms/element/Buttons';
import { groupedCountries as countryList } from 'domain/country/CountryModel';
import { submitArtistForm } from 'domain/artist/ArtistAction';
import { stringToDateStruct } from 'lib/helpers';
import Select from 'components/form/select';

import injectSheet from 'lib/sheet';
import { sheet } from './sheet.js';

const submit = (data, dispatch) => {
  dispatch(submitArtistForm({
    id: data.id,
    fullName: data.fullName,
    residence: data.residence,
    bornIn: stringToDateStruct(data.bornIn),
    diedIn: stringToDateStruct(data.diedIn),
  }));
};

class NewAuthorForm extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    countries: PropTypes.array.isRequired,
  };

  validate = {
    bornIn: validator(rules.required, rules.year),
  };

  render() {
    const { classes, handleSubmit, countries } = this.props;
    return (
      <form
        className={classes.NewAuthorForm}
        onSubmit={handleSubmit}
      >
        <ul>
          <Field
            name="fullName"
            title="Artist Name"
            placeholder="Enter first and last name"
            component={FormRowField}
            modifier="NewAuthorForm"
            validate={rules.required}
          />
          <Field
            name="bornIn"
            title="Date of Birth"
            placeholder="YYYY"
            component={FormRowField}
            modifier="NewAuthorForm"
            Field={DateField}
            mask={[/\d/, /\d/, /\d/, /\d/]}
            momentMask="YYYY"
            momentParseMasks={['YYYY']}
            maxDetail="decade"
            maxDate={today()}
          />
          <Field
            name="diedIn"
            title="Date of Death"
            placeholder="YYYY"
            component={FormRowField}
            modifier="NewAuthorForm"
            Field={DateField}
            mask={[/\d/, /\d/, /\d/, /\d/]}
            momentMask="YYYY"
            momentParseMasks={['YYYY']}
            maxDetail="decade"
            maxDate={today()}
          />
          <Field
            name="residence"
            title="Associated countries"
            id="id-associated-countries"
            placeholder="Type or select countries"
            component={FormRowField}
            modifier="NewAuthorForm"
            Field={Select}
            list={countries}
            isMulti
            grouped
          />
        </ul>
        <Buttons modifier="NewAuthorForm" cancel={this.props.onCancel} submit="SAVE" />
      </form>
    );
  }
}

export default compose(
  injectSheet(sheet),
  connect({
    countries: countryList,
  }),
  reduxForm({
    form: FORM_ADD_AUTHOR,
    onSubmit: submit,
  }),
)(NewAuthorForm);
