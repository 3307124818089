export function getRotatedImgUrlBySrc(src, times, fileName) {
  return new Promise((resolve, reject) => {
    // don't rotate 0, 360, ... degrees
    if (!((times || 0) % 4)) {
      resolve(src);
      return;
    }
    const image = new Image();
    image.crossOrigin = 'anonymous';
    image.onload = () => {
      getRotatedImgUrl(image, times, fileName).then(resolve).catch(reject);
    };
    image.onerror = () => reject(new Error('Can\'t load image "' + src + '"'));
    image.src = src;
  });
};

function getRotatedImgUrl(image, times, fileName = 'newRotatedFile.jpeg') {
  if (!image) throw new Error('image undefined');
  const deg = (times || 0) * 90 % 360;
  // true: 1, 3, 5, ...; false: 0, 2, 4, ...
  const switchWidthHeight = (times || 0) / 2 % 1;
  return new Promise((resolve, reject) => {
    const canvas = document.createElement('canvas');
    /**
     * ctx.drawImage usage:
     * void ctx.drawImage(image, dx, dy);
     * void ctx.drawImage(image, dx, dy, dWidth, dHeight);
     * void ctx.drawImage(image, sx, sy, sWidth, sHeight, dx, dy, dWidth, dHeight);
    **/
    const width = image.naturalWidth;
    const height = image.naturalHeight;
    canvas.width = switchWidthHeight ? height : width;
    canvas.height = switchWidthHeight ? width : height;
    const ctx = canvas.getContext('2d');
    // fill transparent
    ctx.save();
    ctx.fillStyle = 'rgba(0, 0, 0, 0)';
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    ctx.restore();
    // rotate
    ctx.save();
    ctx.translate(canvas.width / 2, canvas.height / 2);
    // radians
    const rad = deg * Math.PI / 180;
    ctx.rotate(rad);
    ctx.drawImage(
      image,
      -width / 2,
      -height / 2,
    );
    ctx.restore();
    canvas.toBlob(blob => {
      if (!blob) {
        reject(new Error('Canvas is empty'));
        return;
      }
      blob.name = fileName;
      const fileUrl = window.URL.createObjectURL(blob);
      resolve(fileUrl);
    }, 'image/png');
  });
}
