import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import injectSheet from 'lib/sheet';
import sheet from './sheet';

class Password extends React.PureComponent {
  static propTypes = {
    input: PropTypes.shape({
      onChange: PropTypes.func,
      value: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
      name: PropTypes.string,
      onFocus: PropTypes.func,
      onBlur: PropTypes.func,
    }).isRequired,
    Wrapper: PropTypes.string,
    classes: PropTypes.shape({
      Password: PropTypes.string.isRequired,
      field: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      showHidePass: PropTypes.string.isRequired,
      message: PropTypes.string.isRequired,
    }).isRequired,
    modifier: PropTypes.string,
    helper: PropTypes.any,
    meta: PropTypes.shape({}),
    sheet: PropTypes.object,
    externalClasses: PropTypes.object,
  }

  state = { visible: false, focused: false }

  onFocus = (...args) => {
    const { onFocus } = this.props.input;
    onFocus && onFocus(...args);
    this.setState({ focused: true });
  }

  onBlur = (...args) => {
    const { onBlur } = this.props.input;
    onBlur && onBlur(...args);
    this.setState({ focused: false });
  }

  render() {
    const { classes, modifier, input, helper, Wrapper = 'div', meta, sheet, externalClasses, ...props } = this.props;
    const { visible, focused } = this.state;
    return (
      <Wrapper className={classes.Password}>
        <input
          {...props}
          {...input}
          className={cx(classes.field, 'passwordField', modifier)}
          type={visible ? 'text' : 'password'}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
        />
        <button
          type="button"
          onClick={() => this.setState({ visible: !visible })}
          className={cx(classes.showHidePass, { [classes.text]: visible })}
        />
        {helper && focused ? <div className={classes.message}>{helper}</div> : null}
      </Wrapper>
    );
  }
}

export default injectSheet(sheet)(Password);
