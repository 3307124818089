import { action } from 'lib/actions';

export const resetRequestedLocation = action('UI/RESET_LOCATION');
export const filterToggleAction = action('ui/FILTER_TOGGLE');
export const filterOpenAction = action('ui/FILTER_OPEN');
export const addPopupAction = action('ui/ADD_POPUP');
export const hidePopupAction = action('ui/HIDE_POPUP');
export const hidePopupByNamesAction = action('ui/HIDE_POPUP_BY_NAMES', (...names) => ({ payload: names }));
export const setLinkAction = action('ui/SET_LINK');
export const err404Action = action('ui/ERROR/404');
export const updateFilterParamsAction = action('ui/UPDATE_FILTER_PARAMS');
export const resetFilterParamsAction = action('ui/RESET_FILTER_PARAMS');

export const confirmAction = action('ui/CONFIRM', (params, actionCreator, ...args) => ({
  payload: {
    params,
    actionCreator,
    actionCreatorType: actionCreator ? actionCreator.type : null,
    args,
  },
}));
export const confirmAcceptAction = action('ui/CONFIRM/accept');
export const confirmDenyAction = action('ui/CONFIRM/deny');
export const confirmCloseAction = action('ui/CONFIRM/close');
export const toggleMobileMenuAction = action('ui/MOBILE_MENU/toggle');
export const toggleExtraFooterGapAction = action('ui/FOOTER_GAP/toggle');

/**
 * setPageLoadingAction('pageLinkName');
**/
export const setPageLoadingAction = action('ui/SET_PAGE_LOADING');
