import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { lnk } from 'lib/routes';
import { Link } from 'react-router-dom';

import injectSheet from 'lib/sheet';
import sheet from './sheet';

function NoArts({ classes, createRoute, createText, createRouteProps, title = 'No art here', description }) {
  return (
    <div className={classes.NoArts}>
      <div className={classes.title}>{title}</div>
      {
        description &&
          <div className={classes.description}>{description}</div>
      }
      {
        !!createRoute && !!createText &&
        <div>
          <Link
            className={cx(classes.button, 'SharedItemsPage--NoArt-Create')}
            to={lnk(createRoute, createRouteProps)}
            children={createText}
          />
        </div>
      }
    </div>
  );
}

NoArts.propTypes = {
  classes: PropTypes.shape({
    NoArts: PropTypes.string,
    title: PropTypes.string,
    button: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
  createRoute: PropTypes.string,
  createRouteProps: PropTypes.object,
  createText: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
};

export default injectSheet(sheet)(NoArts);
