import { SharedWithMe } from 'domain/shares';
import img1 from './images/img-preview-4.png';
import img2 from './images/img-preview-6.png';
import img3 from './images/img-preview-3.png';
import img4 from './images/img-preview-4.png';
import img5 from './images/img-preview-5.png';
import img6 from './images/img-preview-6.png';

export default SharedWithMe.$list.type.parse([
  {
    id: 1,
    picture: img1,
    amount: 1,
    type: 'gallery',
    title: 'Susan Jane Belton',
    email: 'mdewhirst@gmail.com',
    created: '2017-02-23T13:52:34.987Z',
    message: 'Sending you my latest collection as we’ve discussed. Please review and contact me ASAP',
  },
  {
    id: 2,
    picture: img2,
    amount: 1,
    type: 'art_work',
    title: 'Revisionist IV',
    email: 'mdewhirst@gmail.com',
    created: '2017-02-23T13:52:34.987Z',
    message: 'Sending you my latest collection as we’ve discussed. Please review and contact me ASAP',
  },
  {
    id: 3,
    picture: img3,
    amount: 2,
    type: 'gallery',
    title: '',
    email: 'mdewhirst@gmail.com',
    created: '2017-03-12T13:52:34.987Z',
    message: 'Here is the art I need you to insure',
  },
  {
    id: 4,
    picture: img4,
    amount: 2,
    type: 'art_work',
    title: '',
    email: 'mdewhirst@gmail.com',
    created: '2017-04-02T13:52:34.987Z',
    message: 'Sending you my latest collection. Please review.',
  },
  {
    id: 5,
    picture: img4,
    amount: 1,
    type: 'art_work',
    title: 'Branded',
    email: 'mdewhirst@gmail.com',
    created: '2017-04-02T13:52:34.987Z',
    message: 'Sending you my latest collection as we’ve discussed. Please review and contact me ASAP',
  },
  {
    id: 6,
    picture: img5,
    amount: 2,
    type: 'gallery',
    title: '',
    email: 'mdewhirst@gmail.com',
    created: '2017-05-27T13:52:34.987Z',
  },
  {
    id: 7,
    picture: img6,
    amount: 1,
    type: 'art_work',
    title: 'Ghosts at Noon',
    email: 'mdewhirst@gmail.com',
    created: '2017-05-27T13:52:34.987Z',
  },
]);
