import record, { string, integer } from 'cpcs-recordjs';
import { field } from 'cpcs-reconnect';
import { Artist } from 'model';
import {
  showAnalyticsFormAction,
  hideAnalyticsFormAction,
  orderAnalyticsAction,
  setOrderErrorAction,
  buyAnalyticsFormSubmitAction,
  orderFreeAnalyticsAction,
  setSelectedProductNameAction, buyAnalyticsReportAction,
} from 'pages/common/buyAnalytics/buyAnalyticsActions';
import {
  upgradePlanAction,
} from 'pages/upgradeSubscriptionPage/upgradeSubscriptionActions';


const State = record('buyAnalytics', {
  artist: Artist,
  overlayFormVisible: false,
  orderError: '',
  submitting: false,
  gettingFree: false,
  product: string,
  lastPaidArtistReport: integer,
});

const base = field('buyAnalytics');
export const buyAnalyticsPopupOpenedSelector = base.then(State.$overlayFormVisible);
export const buyAnalyticsPopupArtworkSelector = base.then(State.$artist);
export const orderErrorSelector = base.then(State.$orderError);
export const submittingSelector = base.then(State.$submitting);
export const gettingFreeSelector = base.then(State.$gettingFree);
export const selectedProduct_sel = base.then(State.$product);
export const lastPaidArtistReport_sel = base.then(State.$lastPaidArtistReport);

export const reducer = {
  buyAnalytics(state = new State(), action) {
    switch (action.type) {
      case buyAnalyticsFormSubmitAction.type:
        return state.update(
          State.$submitting.set(true),
        );
      case buyAnalyticsReportAction.success:
        return state.update(
          State.$lastPaidArtistReport.set(action.payload.artist.id),
        );
      case setOrderErrorAction.type:
        return state.apply(
          State.$orderError.set(action.payload.message),
        );
      case orderAnalyticsAction.failure:
      case upgradePlanAction.failure:
        return state.apply(
          State.$submitting.set(false),
        );
      case setSelectedProductNameAction.type:
        return state.apply(
          State.$product.set(action.payload),
        );
      case orderFreeAnalyticsAction.type:
        return state.apply(
          State.$artist.set(action.payload.artist),
          State.$gettingFree.set(true),
        );
      case orderFreeAnalyticsAction.success:
      case orderFreeAnalyticsAction.failure:
        return state.apply(
          State.$gettingFree.set(false),
        );
      case showAnalyticsFormAction.type:
        return state.apply(
          State.$overlayFormVisible.set(true),
          State.$artist.set(action.payload.artist),
        );
      case '@@router/LOCATION_CHANGE':
      case orderAnalyticsAction.success:
      case upgradePlanAction.success:
      case hideAnalyticsFormAction.type:
        return state.apply(
          State.$submitting.set(false),
          State.$orderError.set(''),
          State.$overlayFormVisible.set(false),
          State.$artist.set(null),
          State.$gettingFree.set(false),
          State.$product.set(''),
        );
      default:
        return state;
    }
  },
};
