import { DEFAULT_BUTTON_COLOR } from 'theme/theme';

export default {
  message: {
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    borderRadius: 2,
    position: 'absolute',
    display: 'none',
    width: 142,
    height: 72,
    padding: [8,8],
    fontSize: '10px',
    zIndex: 2,
    left: 30,
    whiteSpace: 'normal',
  },
  box: {
    borderRadius: '50%',
    color: '#fff',
    backgroundColor: DEFAULT_BUTTON_COLOR,
    position: 'absolute',
    width: 23,
    height: 23,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    right: 7,
    zIndex: 100,
    '&:hover $message': {
      display: 'block',
    },
  },
};
