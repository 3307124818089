import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import I from 'immutable';
import { connect } from 'cpcs-reconnect';
import { compose } from 'redux';
// import Highcharts from 'highcharts';
import Highcharts from 'highcharts/highstock';
import e from 'highcharts/modules/exporting';
import eo from 'highcharts/modules/offline-exporting';

// import 'highcharts/modules/timeline';
// import 'highcharts/indicators/indicators-all';

// import 'highcharts/modules/annotations';
// import 'highcharts/modules/drag-panes';
// import 'highcharts/modules/annotations-advanced';
// import 'highcharts/modules/price-indicator';
// import 'highcharts/modules/full-screen';
// import 'highcharts/modules/data';
// import 'highcharts/modules/drilldown';
// import 'highcharts/modules/accessibility';

// import 'highcharts/modules/stock-tools';

import { SET_ARTIST_LIQUIDITY } from 'pages/highcharts/helpers/PDFConstants';
import { usePDFEvents } from 'pages/highcharts/helpers/EventsContext';

import { auctionsById } from 'domain/auction/AuctionModel';
import { subscriptionTypeSelector } from 'domain/env/EnvModel';

import { DEFAULT_FONTFAMILY_3 } from 'theme/theme';

import { CAGRProjectedValue } from 'components/reports/CAGRProjectedValue';
import { TotalLotsSold } from 'components/reports/TotalLotsSold';
import { Chart } from 'components/reports/Chart/Chart';
import ArtistFinancialPerformanceMetrics from 'components/reports/ArtistFinancialPerformanceMetrics';
import PreloadingTabsDefault from 'components/reports/PreloadingTabs';
import { LiquidityIndexes } from 'components/reports/LiquidityIndexes';
import { openedState } from 'components/reports/OpenedState';
import { SUBSCRIPTION_TYPE_ENTERPRISE } from 'domain/const';

import injectSheet from 'lib/sheet';

e(Highcharts);
eo(Highcharts);
Highcharts.setOptions({
  lang: {
    // Pre-v9 legacy settings
    rangeSelectorFrom: 'From',
    rangeSelectorTo: 'To',
    rangeSelectorZoom: '',
    thousandsSep: ',',
  },
});

const PreloadingTabs = openedState(PreloadingTabsDefault);

const sheet = {
  Reports: {
    marginTop: 45,
    borderTop: '1px solid #D8D8D8',
    paddingBottom: 128,
  },
  title: {
    font: `700 24px/32px ${DEFAULT_FONTFAMILY_3}`,
    color: '#000',
    marginTop: 24,
  },
  description: {
    font: `400 16px/24px ${DEFAULT_FONTFAMILY_3}`,
    marginTop: 8,
    lineHeight: '24px',
  },
};

function Reports({
  classes, artworkId, artistId, auctionList,
  subscriptionType,
}) {
  const PDFEvents = usePDFEvents();
  const [liquidity, setLiquidity] = useState({});
  const onSetLiquidity = useCallback((liquidityStr) => {
    setLiquidity({ liquidity: liquidityStr, artworkId, artistId });
    PDFEvents.dispatch(SET_ARTIST_LIQUIDITY, liquidityStr);
  }, [setLiquidity, artworkId, artistId, PDFEvents]);
  const previewProps = useCallback((chartId, title, props) => ({
    props: { artworkId, artistId, chartId, ...props },
    Component: Chart,
    title,
  }), [artistId, artworkId]);
  if (subscriptionType !== SUBSCRIPTION_TYPE_ENTERPRISE || !artworkId || !artistId) {
    return null;
  }
  return (
    <div className={cx('PAGE_SECTION Reports', classes.Reports)}>
      <h3 className={classes.title}>Financial Performance Analysis</h3>
      <p className={classes.description}>This section provides an in-depth look into an artist’s financial market performance through advanced metrics using the repeat sales methodology.</p>
      <ArtistFinancialPerformanceMetrics artworkId={artworkId} artistId={artistId} onSetLiquidity={onSetLiquidity} />
      <CAGRProjectedValue chartId="p001" artworkId={artworkId} artistId={artistId} liquidity={liquidity} />
      <Chart chartId="p002" artworkId={artworkId} artistId={artistId} auctionList={auctionList} liquidity={liquidity} hideIlliquid />
      <PreloadingTabs>
        {
          [
            previewProps('p009', 'Average Price'),
            // previewProps('p009p'  'Average Price (old)'),
            previewProps('p004', 'Market Enthusiasm', { liquidity }),
            previewProps('p011', 'Sell-Through'),
            previewProps('p012', 'Prices Over Time', { auctionList }),
          ]
        }
      </PreloadingTabs>
      <h3 className={classes.title}>Market Movements</h3>
      <p className={classes.description}>This section provides an overview of the artist’s market activity over the past decade. </p>
      {/** /}
      <PreloadingTabs>
        {
          [
            previewProps('m006', 'Total Volume'),
            previewProps('m007', 'By Price Segment'),
          ]
        }
      </PreloadingTabs>
      {/**/}
      <Chart chartId="m006" artworkId={artworkId} artistId={artistId} />
      {/** /}
      <Chart chartId="p006" artistId={artistId} />
      <Chart chartId="p007" artistId={artistId} />
      <Chart chartId="p008" artistId={artistId} />
      <Chart chartId="p012" artistId={artistId} />

      <Chart chartId="m011" artistId={artistId} />
      <Chart chartId="m014" artistId={artistId} />
      {/**/}
      <PreloadingTabs>
        {
          [
            previewProps('m014', 'Total Lots Sold'),
            {
              props: { artworkId, artistId, chartId: 'm015', legend: ['allArtists', 'artworksOfComparableMedium'] },
              Component: TotalLotsSold,
              title: 'By Price Segment',
            },
            previewProps('m018', 'Repeat Sales'),
          ]
        }
      </PreloadingTabs>

      <h3 className={classes.title}>ARTBnk Indexes</h3>
      <p className={classes.description}>ARTBnk Indexes are based on the repeat sales methodology first used in the Case-Shiller Real<br/> Estate Index and then applied to the art market in 2002 by Jianping Mei, PhD and current ARTBnk<br/> Senior Advisor, Michael Moses, PhD. ARTBnk’s suite of indexes can be used to compare the financial<br/> performance of the art market as a whole and its sub-categories against other asset markets.</p>
      <LiquidityIndexes chartId="x001" artworkId={artworkId} artistId={artistId} />
    </div>
  );
}
Reports.propTypes = {
  classes: PropTypes.shape({
    Reports: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
  }),
  artworkId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  artistId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  auctionList: PropTypes.instanceOf(I.Collection),
  subscriptionType: PropTypes.string,
};

export default compose(
  injectSheet(sheet),
  connect({
    auctionList: auctionsById,
    subscriptionType: subscriptionTypeSelector,
  }),
)(Reports);



