import React from 'react';
import I from 'immutable';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { nothing } from 'lib/helpers';
import { Tags } from 'components/form/tags';

const checkEmail = value => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value);

class EmailsTag extends React.PureComponent {

  static propTypes = {
    placeholder: PropTypes.string,
    validate: PropTypes.func,
    modifier: PropTypes.string,
  };

  state = {
    optionList: new I.List(),
  };

  searchHandler = values => {
    if (checkEmail(values)) {
      this.setState({
        optionList: new I.fromJS([{
          id: values,
          title: values,
        }]),
      });
    }
  };

  getValues(...args) {
    return Promise.resolve(args);
  }

  handleBlur = (el, cont) => {
    if (el && el.value) {
      cont.onSelect(el.value.trim());
    }
  };

  render() {
    const fieldProps = {
      onSearch: this.searchHandler,
      optionList: this.state.optionList,
      getValues: this.getValues,
      onHide: this.handleBlur,
      noDropdown: true,
    };
    return (
      <Field
        id="emails"
        name="emails"
        component={Tags}
        props={fieldProps}
        validate={this.props.validate}
        placeholder={this.props.placeholder}
        modifier={this.props.modifier}
      >
        {
          nothing
        }
      </Field>
    );
  }
}

export default EmailsTag;
