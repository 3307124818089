import { formTitle } from 'theme/form/formTitle';
import {
  DEFAULT_FONTFAMILY,
  UPGRADE_PLAN_BTN_BG,
  UPGRADE_PLAN_BTN_COLOR,
  ACTION_CURRENT_COLOR,
  UPGRADE_PLAN_HELP_COLOR,
  TEXT_LINK_COLOR,
} from 'theme/theme';
import { billingPageLayout } from 'theme/layout/billingPageLayout';

export default {
  ...billingPageLayout,
  formTitle,
  p: {
    font: `400 14px/20px ${DEFAULT_FONTFAMILY}`,
    marginTop: 25,
  },
  enterpriseText: {

  },
  supportMailLink: {
    color: TEXT_LINK_COLOR,
  },
  actions: {
    display: 'flex',
    paddingTop: 30,
    '@media screen and (max-width: 374px)': {
      display: 'block',
    },
  },
  priceWrap: {
    flex: '1 1 0',
    padding: [18, 23],
  },
  priceVal: {
    textTransform: 'uppercase',
    font: `500 15px/20px ${DEFAULT_FONTFAMILY}`,
  },
  upgradePlanBtn: {
    margin: [0, 0, 0, 30],
    flex: '1 1 0',
    backgroundColor: UPGRADE_PLAN_BTN_BG,
    color: UPGRADE_PLAN_BTN_COLOR,
    padding: [14, 30, 13],
    font: `500 15px/20px ${DEFAULT_FONTFAMILY}`,
    textTransform: 'uppercase',
    whiteSpace: 'nowrap',
    '&:hover': {
      cursor: 'pointer',
      opacity: 0.9,
    },
    '&.disabled': {
      opacity: 0.4,
      cursor: 'pointer',
      '&:hover': {
        opacity: 0.4,
        cursor: 'default',
      },
    },
    '@media screen and (max-width: 768px)': {
      flex: 'auto',
      width: 200,
      height: 46,
    },
    '@media screen and (max-width: 374px)': {
      width: '100%',
      marginLeft: 0,
    },
  },
  link: {
    color: `${ACTION_CURRENT_COLOR}`,
    textDecoration: 'underline',
  },
  helpMessage: {
    marginTop: 20,
    font: `400 14px/20px ${DEFAULT_FONTFAMILY}`,
    color: UPGRADE_PLAN_HELP_COLOR,
  },
  trialTitle: {
    // marginTop: 20,
    font: `500 32px/40px ${DEFAULT_FONTFAMILY}`,
    color: '#304C95',
  },
  trialDescription: {
    marginTop: 15,
    font: `400 16px/20px ${DEFAULT_FONTFAMILY}`,
  },
};
