import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import headerImage from './images/IPAD-WITH-MIKALENE-THOMAS.png';
import graphImage from './images/graph.png';
import ao1 from './images/AO-1.jpg';
import ao2 from './images/AO-2.jpg';
import ao3 from './images/AO-3.jpg';
import ao4 from './images/AO-4.jpg';
import ao5 from './images/AO-5.jpg';
import ao6 from './images/AO-6.jpg';
import ao7 from './images/AO-7.jpg';

import pdf1 from './pdf/Q3_2021_Market_Report.pdf';
import pdf2 from './pdf/ARTBnk_July_2021_Market_Report.pdf';
import pdf3 from './pdf/May_2021_Sales_Report.pdf';
import pdf4 from './pdf/Picasso_Q1_2021_Market_Report.pdf';
import pdf5 from './pdf/Jean-Michel Basquiat_s _Warrior_- Living_Up_to_Expectations.pdf';
import pdf6 from './pdf/Henri_Matisse_2020_Sales_Analysis-ARTBnk.pdf';
import pdf7 from './pdf/Q4_2021_Art_Market_Report.pdf';

import injectSheet from 'lib/sheet';
import sheet from './sheet';

/* eslint-disable no-multi-str */
const aoList = [
  {
    key: 'ao-1',
    image: ao7,
    title: ['Q4 2021 Market Report'],
    description: 'ARTBnk analyzed 2392 works prior to the sales held at Sotheby’s, Christie’s, and Phillips in October, November and December of 2021 to evaluate the state of the fine art market today. Within this analysis, we break down how these works performed across market sectors by utilizing fair market value—ARTBnk Value—for each individual work of art produced prior to the sale.',
    file: pdf7,
  },
  {
    key: 'ao-2',
    image: ao1,
    title: 'Q3 2021 Market Report',
    description: 'ARTBnk analyzed 696 works from sales held at Sotheby’s, Christie’s, and Phillips in July, August and September to evaluate the state of the fine art market today.',
    file: pdf1,
  },
  {
    key: 'ao-3',
    image: ao2,
    title: 'July 2021 Market Report',
    description: 'With blockbuster sales taking place in recent weeks across the globe, ARTBnk analyzed premiere sales held at Sotheby’s, Christie’s and Phillips to evaluate the state of the fine art market today.',
    file: pdf2,
  },
  {
    key: 'ao-4',
    image: ao3,
    title: ['May 2021 Fine Art Auction', 'Sales Performance Report'],
    description: 'With blockbuster sales taking place this past week in New York, ARTBnk analyzed the major day and evening sales at Sotheby’s and Christie’s to evaluate the state of the fine art market today.',
    file: pdf3,
  },
  {
    key: 'ao-5',
    image: ao4,
    title: ['Pablo Picasso:', 'Spring Market Report'],
    description: 'With newsworthy sales on the horizon, we examine how Picasso’s recent sale results measure against past performance.',
    file: pdf4,
  },
  {
    key: 'ao-6',
    image: ao5,
    title: ['Jean-Michel Basquiat\'s \'Warrior\':', 'Living Up to Expectations'],
    description: 'Basquiat\'s ‘Warrior’ is poised to be a milestone sale for the Western art market in Asia. With a robust sales history, we examine past sale performance against the expected result for this tour-de-force work.',
    file: pdf5,
  },
  {
    key: 'ao-7',
    image: ao6,
    title: ['Claude Monet:', '\'Le Bassin aux nymphéas\''],
    description: 'Get the latest 2020 sale results for blue-chip artist Henri Matisse to see where the artist\'s values may be heading for 2021.',
    file: pdf6,
  },
].map(v => Array.isArray(v.title) ? v : { ...v, title: [v.title] });
/* eslint-enable no-multi-str */


function IntelligencePage({ classes }) {
  return (
    <div className={classes.IntelligencePage}>
      <div className={classes.content}>
        <div className={cx(classes.row, 'header')}>
          <div className={classes.columnHeader}>
            <h1 className={classes.title}>
              The Power Of Artificial Intelligence,<br/> Machine Learning And 100,000,000<br/> Data Points.
            </h1>
            <div className={classes.description}>
              ARTBnk provides objective, real-time fair market art<br/> valuations to enable smart <span className={classes.investment}>investment</span> decisions.
            </div>
          </div>
          <div className={classes.columnThumb}>
            <img src={headerImage} className={classes.ipadImage} width="537" alt="site preview on ipad" />
          </div>
        </div>
        <div className={cx(classes.row, 'advantages')}>
          <div className={classes.columnGraph}>
            <img src={graphImage} className={classes.graphImage} alt="graph preview" width="562" />
          </div>
          <div className={classes.columnAdvantages}>
            <h2 className={classes.advantagesTitle}>Discover the full power of ARTBnk</h2>
            <div className={cx(classes.description, 'advantages')}>
              <p>
                ARTBnk empowers you to make smart financial decisions about<br/> art collecting. Our services include:
              </p>
              <ul className={classes.list}>
                <li>ARTBnk Value</li>
                <li>Collection Performance Reports for Wealth Management</li>
                <li>Artist Market Performance Reports</li>
                <li>Artwork Market Performance Report</li>
                <li>Art Finance</li>
                <li>Auction Sales Database</li>
                <li> Auction Alerts</li>
              </ul>
              <a
                href="https://www.artbnk.com/wealth-management"
                className={cx(classes.demoLink, 'advantages')}
                rel="noopener noreferrer"
                target="_blank"
              >
                Learn more
              </a>
            </div>
          </div>
        </div>
        <h2 className={classes.artworkListHeader}>Featured Market Reports</h2>
        <p className={classes.artworkListDownload}>Click on the image to download a pdf format of the report. </p>
        <ul className={classes.aoList}>
          {
            aoList.map(item => (
              <li className={classes.aoItem} key={item.key}>
                <a href={item.file} className={classes.download} download>
                  <img src={item.image} className={classes.aoImage} alt={item.title.join(' ')} />
                  <h3 className={classes.aoTitle}>
                    {
                      item.title.map((v, index) => (
                        <React.Fragment key={index}>
                          {v}
                          {item.title.length > (index + 1) ? <br /> : null}
                        </React.Fragment>
                      ))
                    }
                  </h3>
                  <p className={classes.descriptionText}>{item.description}</p>
                </a>
              </li>
            ))
          }
        </ul>
      </div>
    </div>
  );
}

IntelligencePage.propTypes = {
  classes: PropTypes.shape({
    IntelligencePage: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    descriptionText: PropTypes.string.isRequired,
    buttonBox: PropTypes.string.isRequired,
    demoLink: PropTypes.string.isRequired,
    ipadImage: PropTypes.string.isRequired,
    row: PropTypes.string.isRequired,
    columnHeader: PropTypes.string.isRequired,
    columnThumb: PropTypes.string.isRequired,
    columnGraph: PropTypes.string.isRequired,
    artworkListDownload: PropTypes.string.isRequired,
    columnAdvantages: PropTypes.string.isRequired,
    graphImage: PropTypes.string.isRequired,
    advantagesTitle: PropTypes.string.isRequired,
    list: PropTypes.string.isRequired,
    artworkListHeader: PropTypes.string.isRequired,
    aoList: PropTypes.string.isRequired,
    aoItem: PropTypes.string.isRequired,
    download: PropTypes.string.isRequired,
    investment: PropTypes.string.isRequired,
    aoImage: PropTypes.string.isRequired,
    aoTitle: PropTypes.string.isRequired,
  }).isRequired,
};

export default injectSheet(sheet)(IntelligencePage);
