import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'lib/sheet';
import { Markup, radioChecked, getNewValueRadio } from 'components/form/checkbox';
import sheet from './sheet';

function Radio({ input, meta, ...props }) {
  return (
    <Markup
      id={`id-${input.name}-${input.value}`}
      input={input}
      meta={meta}
      checkIsChecked={radioChecked}
      rootClassName="Radio"
      getNewValue={getNewValueRadio}
      {...props}
    />
  );
}

Radio.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
    onChange: PropTypes.func,
    name: PropTypes.string.isRequired,
  }).isRequired,
  classes: PropTypes.shape({
    Radio: PropTypes.string.isRequired,
  }).isRequired,
  meta: PropTypes.shape({

  }),
};

export default injectSheet(sheet)(Radio);
