/* eslint no-unused-vars: warn */
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { usePDFStore } from 'pages/highcharts/PDFModel';
import injectSheet from 'lib/sheet';


const ACTION_BUTTON_WIDTH = 84;

const sheet = {
  ToggleItemHidden: {
    cursor: 'pointer',
    pointerEvents: 'painted',
  },
  ToggleItemHiddenButton: {
    cursor: 'pointer',
    pointerEvents: 'painted',
  },
  ToggleItemHiddenButtonPart: {
    cursor: 'pointer',
    pointerEvents: 'painted',
  },
};

function ToggleItemHidden({ item, viewBox, classes, modifier, onClick, removed }) {
  const { dispatch } = usePDFStore();
  const onItemHide = () => {
    dispatch({ type: 'HIDE_ITEM', payload: item.name });
  };
  const onItemShow = () => {
    dispatch({ type: 'SHOW_ITEM', payload: item.name });
  };
  const toggleHidden = onClick || (item.hidden ? onItemShow : onItemHide);
  const scale = Math.min(1, viewBox.width / ACTION_BUTTON_WIDTH, viewBox.height / ACTION_BUTTON_WIDTH);
  const width = ACTION_BUTTON_WIDTH * scale;
  return (
    <g
      data-item-name={item.name}
      transform={`translate(${Math.max(width, viewBox.width) / 2}, ${Math.max(width, viewBox.height) / 2})`}
    >
      <circle
        cx="0"
        cy="0"
        r={width / 2}
        fill="rgba(49, 53, 69, 0.55)"
        onClick={toggleHidden}
        className={cx(classes.ToggleItemHiddenButton, modifier)}
      />
      <g transform={`scale(${scale})`}>
        {
          (removed !== undefined ? removed : item.hidden) &&
            <path
              d="M-14 0 h28 M0 -14 v28"
              stroke="#E3A52C"
              strokeWidth="4"
              onClick={toggleHidden}
              className={cx(classes.ToggleItemHiddenButtonPart, modifier)}
            />
        }
        {
          (removed !== undefined ? !removed : !item.hidden) &&
            <path
              d="M-14 0 h28"
              stroke="#E3A52C"
              strokeWidth="4"
              onClick={toggleHidden}
              className={cx(classes.ToggleItemHiddenButtonPart, modifier)}
            />
        }
      </g>
    </g>
  );
}

ToggleItemHidden.propTypes = {
  classes: PropTypes.shape({
    ToggleItemHidden: PropTypes.string.isRequired,
    ToggleItemHiddenButton: PropTypes.string.isRequired,
    ToggleItemHiddenButtonPart: PropTypes.string.isRequired,
  }).isRequired,
  item: PropTypes.shape({
    hidden: PropTypes.bool,
    name: PropTypes.string.isRequired,
  }).isRequired,
  viewBox: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
  }).isRequired,
  onClick: PropTypes.func,
  removed: PropTypes.bool,
  modifier: PropTypes.string,
};

ToggleItemHidden = injectSheet(sheet)(ToggleItemHidden);

export default ToggleItemHidden;
