import { button } from 'theme/button';
import { PageHeader, actions } from 'theme/Dashboard';
import { DEFAULT_BUTTON_COLOR } from 'theme/theme';

export const sheet = {
  PageHeader,
  actions,
  title: {
    fontSize: '24px',
    fontWeight: '600',
    paddingLeft: '10px',
    display: 'flex',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    alignItems: 'center',
  },
  private: {
    borderRadius: '4px',
    backgroundColor: DEFAULT_BUTTON_COLOR,
    color: '#fff',
    fontSize: '10px',
    marginLeft: '5px',
    padding: '1px 4px 3px 4px',
    fontWeight: '100',
  },
  button,
  dashboardActions: {
    display: 'flex',
  },
};
